import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "app-view-media",
    templateUrl: "./view-media.component.html",
    styleUrls: ["./view-media.component.scss"],
})
export class ViewMediaComponent implements OnInit {
    media_title: any = "";
    img: any;
    constructor(
        public dialogRef: MatDialogRef<ViewMediaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.media_title = data.media_title;
        this.img = data.img;
    }

    ngOnInit(): void {}
}
