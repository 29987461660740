<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
            [formGroup]="firebaseForm">
            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back</mat-icon>
                    <label class="highlight-text-color">{{'Firebase Settings' | translate}}</label>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <button (click)="save()" type="submit" mat-flat-button style="position: absolute;right: 16px;"
                        class="primary-bg-color">{{'Save' | translate}}</button>
                </div>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                [@animate]="{value:'*',params:{duration:'400ms',y:'70px'}}">
                <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 center-container" fxLayoutAlign="start stretch"
                    fxLayout="column" fxLayoutGap="1em">
                    <label class="highlight-text-color">{{'Firebase General Settings' | translate}}</label>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                        <mat-slide-toggle fxFlex="0 1 60%" formControlName="enable_notification_web" class="mb-16">
                            {{'Enable Web Notification' | translate}}
                        </mat-slide-toggle>
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                            fxLayout.lt-md="column" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Firebase API Key'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_Firebase_API_Key' | translate}}"
                                        formControlName="firebase_api_key" matInput name="title">
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Firebase Auth Domain'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_Firebase_auth_domain' | translate}}"
                                        formControlName="firebase_auth_domain" matInput name="title">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                            fxLayout.lt-md="column" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Firebase Messaging Sender ID'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_Firebase_messaging_sender_ID' | translate}}"
                                        formControlName="firebase_messaging_sender_id" matInput name="title">
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Firebase App ID'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_Firebase_app_ID' | translate}}"
                                        formControlName="firebase_app_id" matInput name="title">
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Firebase Measurement ID'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_Firebase_Measurement_ID' | translate}}"
                                        formControlName="firebase_measurement_id" matInput name="title">
                                </mat-form-field>
                            </div>
                        </div>



















                        <!-- <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                            fxLayout.lt-md="column" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Firebase Web ID'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_Firebase_Web_ID' | translate}}"
                                        formControlName="firebase_web_id" matInput name="title">
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Firebase IOS ID'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_Firebase_IOS_ID' | translate}}"
                                        formControlName="firebase_ios_id" matInput name="title">
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Firebase Android ID'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_Firebase_Android_ID' | translate}}"
                                        formControlName="firebase_android_id" matInput name="title">
                                </mat-form-field>
                            </div>
                        </div> -->






                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                            fxLayout.lt-md="column" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Firebase Project ID'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_Firebase_Project_ID' | translate}}"
                                        formControlName="firebase_project_id" matInput name="title">
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Firebase Storage Bucket'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_Firebase_Source_Bucket' | translate}}"
                                        formControlName="firebase_storage_bucket" matInput name="title">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                            fxLayout.lt-md="column" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'FCM Server Key'| translate}}
                                    </mat-label>
                                    <textarea rows="3" matTooltip="{{'help_text_fcm_server_key' | translate}}"
                                        formControlName="fcm_server_key" matInput name="title">
                                    </textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>