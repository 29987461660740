import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "app/services/translationService";
import { RolesPermissionService } from "app/services/user-management/rolesPermission.service";
import { UserService } from "app/services/user-management/userService";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
import { CatalogueService } from "app/services/catalogue/catalogue.service";
import { MediaTypes, MediaTypesAccept } from "app/constants/media-type";
import { navigation } from "app/navigation/navigation";
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from "@angular/animations";
import { SharedService } from "app/services/sharedService";
import { PageService } from "app/services/pageService";
import { CoreService } from "app/services/core-service";
import { ApiService } from "app/services/api.service";
import { CampaignsService } from "app/services/marketing/campaigns.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AppRoutes } from "app/routes";
export const customAnimation = [
    trigger("simpleFadeAnimation", [
        state("in", style({ opacity: 1, transform: "rotate(0)" })),
        transition(":enter", [
            style({ opacity: 0, transform: "rotate(-360deg)" }),
            animate(200),
        ]),
        transition(
            ":leave",
            animate(400, style({ opacity: 0, transform: "rotate(360deg)" }))
        ),
    ]),
];
@Component({
    selector: "app-add-import",
    templateUrl: "./add-import.component.html",
    styleUrls: ["./add-import.component.scss"],
    animations: [fuseAnimations, , customAnimation],
})
export class AddImportComponent implements OnInit {
    // @Input() walletDetailID: any = "";
    @Input() ImportTypeFile: any;

    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    mediaTypesAccept = MediaTypesAccept;
    form: FormGroup;
    importTypes = [
        { value: 1, name: "product" },
        { value: 2, name: "Order" },
    ];
    roleList = [];
    userDetail: any;
    syncConfigList: any;
    file;
    unknownFields: any;
    displayField: boolean = false;
    column_names: any;
    item_count: any;
    dryResponse: any;
    enableIcon: boolean;
    isEnableBack: boolean;
    label: any;
    pageElements: any;
    constructor(
        private catalogueService: CatalogueService,
        private dialog: MatDialog,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        public translationService: TranslationService,
        private campaignsService: CampaignsService,
        private fb: FormBuilder,
        private sharedService: SharedService,
        private pageService: PageService,
        public coreService: CoreService,
        private apiService: ApiService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        console.log(this.ImportTypeFile);
    }

    async formInit() {
        this.form = this.fb.group({
            sync_config: [null],
            file: [null, [Validators.required]],
            model_name: [null, [Validators.required]],
            // logo: [null, [Validators.required]],

            // is_email: [this.userDetail ? this.userDetail.is_email : true],
        });
        if (this.ImportTypeFile) {
            this.form.controls.model_name.setValue(this.ImportTypeFile);
        }
    }
    selectedType() {
        const type =
            this.form.controls.model_name.value == null
                ? ""
                : this.form.controls.model_name.value;
        console.log(type);
    }
    async ngOnInit(): Promise<void> {
        this.formInit();

        await this.getSyncConfig();
    }

    async pushToFomGroup(type, isDelete, files?) {
        if (!isDelete) {
            this.form.controls.file.setValue(files.file);
        } else {
            this.form.controls.file.setValue(null);
        }
    }
    deleteFile(type) {
        this.pushToFomGroup(type, true);
    }

    setFileToForm(files, type) {
        this.form.controls.file.setValue(files.name);

        this.file = files.file;
    }
    async getSyncConfig() {
        this.catalogueService.getSyncConfig().subscribe((result) => {
            this.syncConfigList = result;
        });
    }

    goBack() {
        this.cancelClick.emit();
    }
    save() {
        const file1 = this.form.get("file").value;
        const sync_config1 = this.form.get("sync_config").value;
        const formData = new FormData();
        formData.append("sync_config", sync_config1);
        formData.append("file", this.file);
        const modal_name =
            this.ImportTypeFile != null ? this.ImportTypeFile : "product";
        formData.append("model_name", modal_name);

        if (this.form.valid) {
            this.saveUser(formData);
        }
    }

    saveUser(form) {
        try {
            let content = this.translate.instant(
                "Are you sure, Do you want to save ?"
            );
            let heading = this.translate.instant("Save");
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();

            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp) {
                    this.utilitiesService.startLoader();
                    let success = this.translate.instant(
                        "file uploaded successfully"
                    );
                    console.log(form);
                    await this.catalogueService
                        .uploadDryRun(form)
                        .subscribe(async (data) => {
                            if (data.job_id) {
                                this.dryResponse = data;
                                this.item_count = data.item_count;
                                this.unknownFields = data.unknown_fields;
                                this.column_names = data.column_names;
                                this.displayField = true;
                                this.label = "Import File Details";
                                this.utilitiesService.showSuccessToast(success);
                                this.utilitiesService.stopLoader();
                            }
                        });
                }
            });
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
    // async afterCreateProductPage() {
    //     this.handleCancel();
    //     document.getElementById("dashboard-project").scrollIntoView();
    // }
    async afterImportFile() {
        this.handleCancel();
    }
    async getPageElements() {
        try {
            const pageElements = await this.pageService
                .getElements("page")
                .toPromise();
            if (pageElements) {
                this.pageElements = pageElements;
            }
        } catch {}
    }

    handleCancel() {
        this.enableIcon = false;
        this.isEnableBack = false;

        this.label = "add import";

        this.router.navigate([AppRoutes.SyncJob]);
        this.displayField = false;
    }
}
