<ng-container *ngIf="!isHomePage">
    <div fxFlex="1 1 100%" fxLayout="column" fxLayoutGap="15px">


        <div fxLayout="row" fxLayout.lt-md="column" fxFlex="1 1 100%" fxLayoutGap.lt-md="15px" style="gap:20px">
            <div [fxFlex]="isSingleCampaign ? 79:100" fxFlex.lt-md="100" fxLayoutAlign="space-between start" fxLayoutGap.lt-md="15px"
                fxLayoutAlign.lt-md="center center" fxLayout="row wrap" fxLayout.lt-md="column">

                <div fxFlex="1 1 100%" fxLayout="column">
                    <strong style="font-size: 24px;" class="mt-24">Conversions</strong>


                    <div fxFlex="1 1 100%" fxLayout="row wrap" fxLayout.lt-md="column" >
                        <div fxFlex="1 1 100%" fxLayoutAlign="space-between start" fxLayoutGap.lt-md="15px"
                            fxLayoutAlign.lt-md="center center" fxLayout="row wrap" fxLayout.lt-md="column">
                            <div fxFlex="49" fxFlex.lt-md="100" fxLayoutAlign="space-between start"
                                fxLayoutGap.lt-md="15px" fxLayout.lt-md="column">

                                <div [fxFlex]="isOutBondCampaign(statics?.campaign?.campaign_channel?.id) ? 100:49" fxFlex.lt-md="100" fxFill class="align-charts">
                                    <apx-chart [series]="pieChartOptions.series" [chart]="pieChartOptions.chart"
                                        [legend]="pieChartOptions.legend" [labels]="pieChartOptions.labels"
                                        [responsive]="pieChartOptions.responsive" #platformRevenuePieChart class="mt-24"
                                        [hidden]="(platformRevenue | json) === ({} | json)">
                                    </apx-chart>
                                    <ng-container *ngIf="(platformRevenue | json) === ({} | json)"
                                        [ngTemplateOutlet]="noData">
                                    </ng-container>
                                    <strong class="highlight-text-color cart-label">Platform</strong>
                                </div>

                                <div *ngIf="!isOutBondCampaign(statics?.campaign?.campaign_channel?.id)" fxFlex="49" fxFlex.lt-md="100" fxFill class="align-charts">
                                    <apx-chart [series]="verticleBarChartOptions.series"
                                        [chart]="verticleBarChartOptions.chart"
                                        [dataLabels]="verticleBarChartOptions.dataLabels"
                                        [plotOptions]="verticleBarChartOptions.plotOptions"
                                        [yaxis]="verticleBarChartOptions.yaxis" [xaxis]="verticleBarChartOptions.xaxis"
                                        [fill]="verticleBarChartOptions.fill" [title]="verticleBarChartOptions.title"
                                        #mediumRevenueBarChart [hidden]="(mediumRevenue | json) === ({} | json)">
                                    </apx-chart>
                                    <ng-container *ngIf="(mediumRevenue | json) === ({} | json)"
                                        [ngTemplateOutlet]="noData">
                                    </ng-container>
                                    <strong class="highlight-text-color cart-label">Medium</strong>
                                </div>
                            </div>


                            <div fxFlex="50" fxFlex.lt-md="100" fxFill class="align-charts">
                                <apx-chart [series]="lineChartOptions.series" [chart]="lineChartOptions.chart"
                                    [xaxis]="lineChartOptions.xaxis" [dataLabels]="lineChartOptions.dataLabels"
                                    [grid]="lineChartOptions.grid" [stroke]="lineChartOptions.stroke"
                                    [title]="lineChartOptions.title" [legend]="lineChartOptions.legend" #revenueLineChart
                                    [yaxis]="lineChartOptions.yaxis" class="chart-area" [hidden]="!showRevenueLineChart">
                                </apx-chart>
                                <ng-container *ngIf="!showRevenueLineChart" [ngTemplateOutlet]="noData">
                                </ng-container>
                                <strong class="highlight-text-color cart-label">Total Revenue, Conversions</strong>
                            </div>
                        </div>

                    </div>

                    <strong style="font-size: 24px; margin-top: 20px;">Visits</strong>
                    <div fxFlex="1 1 100%" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap.lt-md="15px">
                        <div fxFlex="1 1 100%" fxLayoutAlign="space-between start" fxLayoutGap.lt-md="15px"
                            fxLayoutAlign.lt-md="center center" fxLayout="row wrap" fxLayout.lt-md="column">
                            <div fxFlex="49" fxFlex.lt-md="100" fxLayoutAlign="space-between start"
                                fxLayoutGap.lt-md="15px" fxLayout.lt-md="column">

                                <div [fxFlex]="isOutBondCampaign(statics?.campaign?.campaign_channel?.id) ? 100:49" fxFlex.lt-md="100" fxFill class="align-charts">
                                    <apx-chart [series]="pieChartOptions.series" [chart]="pieChartOptions.chart"
                                        [labels]="pieChartOptions.labels" [responsive]="pieChartOptions.responsive"
                                        [legend]="pieChartOptions.legend" [plotOptions]="pieChartOptions.plotOptions"
                                        [dataLabels]="pieChartOptions.dataLabels" #pieChart class="mt-24"
                                        [hidden]="(platformViews | json) === ({} | json)">
                                    </apx-chart>
                                    <ng-container *ngIf="(platformViews | json) === ({} | json)"
                                        [ngTemplateOutlet]="noData">
                                    </ng-container>
                                    <strong class="highlight-text-color cart-label">Platform</strong>
                                </div>

                                <div  *ngIf="!isOutBondCampaign(statics?.campaign?.campaign_channel?.id)"  fxFlex="49" fxFlex.lt-md="100" fxFill class="align-charts">
                                    <apx-chart [series]="verticleBarChartOptions.series"
                                        [chart]="verticleBarChartOptions.chart"
                                        [dataLabels]="verticleBarChartOptions.dataLabels"
                                        [plotOptions]="verticleBarChartOptions.plotOptions"
                                        [yaxis]="verticleBarChartOptions.yaxis" [xaxis]="verticleBarChartOptions.xaxis"
                                        [fill]="verticleBarChartOptions.fill" [title]="verticleBarChartOptions.title"
                                        #mediumBarChart [hidden]="(mediums | json) === ({} | json)">
                                    </apx-chart>
                                    <ng-container *ngIf="(mediums | json) === ({} | json)" [ngTemplateOutlet]="noData">
                                    </ng-container>
                                    <strong class="highlight-text-color cart-label">Medium</strong>
                                </div>
                            </div>


                            <div fxFlex="50" fxFlex.lt-md="100" fxFill class="align-charts">
                                <apx-chart [series]="lineChartOptions.series" [chart]="lineChartOptions.chart"
                                    [xaxis]="lineChartOptions.xaxis" [dataLabels]="lineChartOptions.dataLabels"
                                    [grid]="lineChartOptions.grid" [stroke]="lineChartOptions.stroke"
                                    [title]="lineChartOptions.title" [yaxis]="lineChartOptions.yaxis" #lineChart
                                    class="chart-area" [hidden]="!showViewsLineChart">
                                </apx-chart>
                                <ng-container *ngIf="!showViewsLineChart" [ngTemplateOutlet]="noData">
                                </ng-container>
                                <strong class="highlight-text-color cart-label">No Of Views, No Of Orders</strong>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div *ngIf="isSingleCampaign" fxFlex="20" fxFlex.lt-md="100" fxLayoutAlign="space-between start" fxLayoutGap.lt-md="15px">

                <div fxLayout="column" fxLayoutAlign="start stretch" class="gap-2 w-full">
                    <div class="chart-card w-full mt-60 h-auto" fxLayout="column" fxLayoutAlign="start">
                        <strong class="p-10" style="font-size: 24px; margin-bottom: 10px;" >Orders</strong>
                        <ng-container *ngIf="statics?.order_data?.length;else noData">
                            <div (click)="redirectToOrder(order.id)" fxLayout="column" fxLayoutGap="6"  class="order-list p-10" *ngFor="let order of statics.order_data">
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                  <div fxLayout="column">
                                    <strong>{{ order?.customer?.first_name }}</strong>
                                    <span class="text-secondary text-sm"> {{ order?.order_date | date }}</span>
                                  </div>
                                  <div fxLayoutAlign="center center">
                                    <strong>{{ order?.total | number:'2.2-2':'en-US' }} {{order?.currency?.code}} </strong>
                                  </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                  </div>


            </div>

        </div>

        <ng-template [ngIf]="!isSingleCampaign">
            <div fxFlex="1 1 100%" fxLayoutAlign="space-between end" fxLayoutGap="10px" fxLayout="row wrap"
                fxLayoutGap.lt-md="15px" fxLayoutAlign.lt-md="space-between center" fxLayout.lt-md="column"
                class="mt-32">

                <div fxFlex fxFlex.lt-lg="1 1 100%" fxFill class="mt-24 show-bar-chart p-8">
                    <strong class="charts-label">Top 10 Performing Campaigns</strong>
                    <div id="chart">
                        <apx-chart
                          [series]="mixChartOptions.series"
                          [chart]="mixChartOptions.chart"
                          [xaxis]="mixChartOptions.xaxis"
                          [markers]="mixChartOptions.markers"
                          [stroke]="mixChartOptions.stroke"
                          [yaxis]="mixChartOptions.yaxis"
                          [dataLabels]="mixChartOptions.dataLabels"
                          [title]="mixChartOptions.title"
                          [fill]="mixChartOptions.fill"
                          [tooltip]="mixChartOptions.tooltip"
                          [hidden]="allCampaignsViews.length  === 0"
                          [legend]="mixChartOptions.legend" #totalViewsBarChart
                        ></apx-chart>
                      </div>
                    <ng-container *ngIf="allCampaignsViews.length  === 0" [ngTemplateOutlet]="noData">

                    </ng-container>
                </div>

                <!-- <div fxFlex fxFlex.lt-lg="1 1 100%" fxFill class="mt-24 show-bar-chart p-8">
                    <strong class="charts-label">Top 10 Revenue Generating Campaigns </strong>
                    <apx-chart [series]="horizontalBarOptions.series" [chart]="horizontalBarOptions.chart"
                        [dataLabels]="horizontalBarOptions.dataLabels" [plotOptions]="horizontalBarOptions.plotOptions"
                        [xaxis]="horizontalBarOptions.xaxis" [legend]="horizontalBarOptions.legend"
                        #totalRevenueBarChart [hidden]="allCampaignsRevenue.length  === 0"></apx-chart>
                    <ng-container *ngIf="allCampaignsRevenue.length  === 0" [ngTemplateOutlet]="noData">

                    </ng-container>
                </div> -->
            </div>
        </ng-template>
    </div>
</ng-container>

<ng-container [ngTemplateOutlet]="chartTemplateRef">
    <ng-container [ngSwitch]="type">
        <div *ngSwitchCase="'areaChart'">
            <ng-container [ngTemplateOutlet]="areaChartTemplate"></ng-container>
        </div>
        <div *ngSwitchCase="'pieChart'">
            <ng-container [ngTemplateOutlet]="pieChartTemplate"></ng-container>
        </div>
        <div *ngSwitchCase="'horizontalBarChart'">
            <ng-container [ngTemplateOutlet]="horizontalBarChartTemplate"></ng-container>
        </div>
        <div *ngSwitchCase="'verticleBarChart'">
            <ng-container [ngTemplateOutlet]="verticleBarChartTemplate"></ng-container>
        </div>
        <div *ngSwitchCase="'lineChart'">
            <ng-container [ngTemplateOutlet]="lineChartTemplate"></ng-container>
        </div>
        <div *ngSwitchCase="'mapChart'">
            <ng-container [ngTemplateOutlet]="mapChart"></ng-container>
        </div>
        <div *ngSwitchCase="'mixedChart'">
            <ng-container [ngTemplateOutlet]="mixedChartTemplate"></ng-container>
        </div>
    </ng-container>
</ng-container>

<!--All Charts Templates -->
<ng-template #areaChartTemplate>
    <apx-chart [series]="areaChartOptions.series" [chart]="areaChartOptions.chart" [xaxis]="areaChartOptions.xaxis"
        [stroke]="areaChartOptions.stroke" [dataLabels]="areaChartOptions.dataLabels" [yaxis]="areaChartOptions.yaxis"
        [labels]="areaChartOptions.labels" [legend]="areaChartOptions.legend" [subtitle]="areaChartOptions.subtitle"
        #areaChart></apx-chart>
</ng-template>

<ng-template #pieChartTemplate>
    <apx-chart [series]="pieChartOptions.series" [chart]="pieChartOptions.chart" [labels]="pieChartOptions.labels"
        [responsive]="pieChartOptions.responsive" [legend]="pieChartOptions.legend"
        [plotOptions]="pieChartOptions.plotOptions" [dataLabels]="pieChartOptions.dataLabels"
        [tooltip]="pieChartOptions.tooltip" #pieChart2>
    </apx-chart>
</ng-template>

<ng-template #horizontalBarChartTemplate>
    <apx-chart [series]="horizontalBarOptions.series" [chart]="horizontalBarOptions.chart"
        [dataLabels]="horizontalBarOptions.dataLabels" [plotOptions]="horizontalBarOptions.plotOptions"
        [xaxis]="horizontalBarOptions.xaxis" #horizontalBarChart></apx-chart>
</ng-template>

<ng-template #verticleBarChartTemplate>
    <apx-chart [series]="verticleBarChartOptions.series" [chart]="verticleBarChartOptions.chart"
        [dataLabels]="verticleBarChartOptions.dataLabels" [plotOptions]="verticleBarChartOptions.plotOptions"
        [yaxis]="verticleBarChartOptions.yaxis" [xaxis]="verticleBarChartOptions.xaxis"
        [fill]="verticleBarChartOptions.fill" [title]="verticleBarChartOptions.title" #verticleBarChart>
    </apx-chart>
</ng-template>

<ng-template #lineChartTemplate>
    <apx-chart [series]="lineChartOptions.series" [chart]="lineChartOptions.chart" [xaxis]="lineChartOptions.xaxis"
        [dataLabels]="lineChartOptions.dataLabels" [grid]="lineChartOptions.grid" [stroke]="lineChartOptions.stroke"
        [title]="lineChartOptions.title" #lineChart2></apx-chart>
</ng-template>

<ng-template #mixedChartTemplate>
    <apx-chart [series]="mixChartOptions.series" [chart]="mixChartOptions.chart" [xaxis]="mixChartOptions.xaxis"
        [markers]="mixChartOptions.markers" [stroke]="mixChartOptions.stroke" [yaxis]="mixChartOptions.yaxis"
        [dataLabels]="mixChartOptions.dataLabels" [title]="mixChartOptions.title" [fill]="mixChartOptions.fill"
        [tooltip]="mixChartOptions.tooltip" [labels]="mixChartOptions.labels" [legend]="mixChartOptions.legend"
        #mixedChart></apx-chart>
</ng-template>

<ng-template #mapChart>
    <div echarts #map [options]="mapOptions" id="demo-chart"></div>
</ng-template>

<ng-template #noData>
    <div class="fuse-card empty-card">
        {{noDataMessage | translate}}
    </div>
</ng-template>