import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { debounceTime } from "rxjs/operators";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";

@Component({
    selector: "app-email-config",
    templateUrl: "./email-config.component.html",
    styleUrls: ["./email-config.component.scss"],
    animations: fuseAnimations,
})
export class EmailConfigComponent implements OnInit {
    @Input() selectedBlock: any;
    @Input() appDetail: any;
    @Output() back: EventEmitter<void> = new EventEmitter<any>();
    @Output() saveHander: EventEmitter<void> = new EventEmitter<any>();
    form: FormGroup;
    configList = [];
    id: any;
    notes = [];
    constructor(
        public utilitiesService: UtilitiesService,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private appService: AppService,
        private translate: TranslateService
    ) {}

    formInit() {
        this.form = this.fb.group({
            id: [null],
            sender_email: ["", [Validators.required, Validators.email]],
            sender_name: ["", [Validators.required]],
            is_default: [true],
            email_type: [2, [Validators.required]],
            smtp_host: ["", [Validators.required]],
            smtp_username: ["", [Validators.required]],
            smtp_password: ["", [Validators.required]],
            smtp_port: ["", [Validators.required]],
            sendgrid_api_key: ["", [Validators.required]],
            app: [this.appDetail ? this.appDetail.id : null],
        });
    }

    ngOnInit(): void {
        this.notes = [
            {
                title: this.translate.instant("Sender Email"),
                description: this.translate.instant("help_text_email_config_Sender_email"),
            },
            {
                title: this.translate.instant("Sender Name"),
                description: this.translate.instant("help_text_email_config_Sender_name"),
            },
            {
                title: this.translate.instant("Type"),
                description: this.translate.instant("help_text_email_config_Type"),
            },
            {
                title: this.translate.instant("Server"),
                description: this.translate.instant("help_text_email_config_Server"),
            },
            {
                title: this.translate.instant("Username"),
                description: this.translate.instant("help_text_email_config_Username"),
            },
            {
                title: this.translate.instant("Password"),
                description: this.translate.instant("help_text_email_config_Password"),
            },
            {
                title: this.translate.instant("Port"),
                description: this.translate.instant("help_text_email_config_Port"),
            },
            {
                title: this.translate.instant("API Key"),
                description: this.translate.instant("help_text_email_config_APi_key"),
            },
        ];
        this.formInit();
        this.getEmailConfig();
        this.form.controls.is_default.valueChanges
            .pipe(debounceTime(400))
            .subscribe(async (data) => {
                await this.addOrRemoveValidtions(data);
            });
        // if (!this.configList.length) {
        //     this.form.valueChanges.subscribe((data) => {
        //         this.utilitiesService.formChangedOrNot(true);
        //     });
        // }
    }
    removeAllValidations() {
        this.form.clearValidators();
        this.form.updateValueAndValidity();
    }
    setValue() {
        if (this.configList.length) {
            let config = this.configList[0];
            this.id = config.id;
            this.form.patchValue({
                sender_email: config.sender_email ? config.sender_email : "",
                sender_name: config.sender_name ? config.sender_name : "",
                id: config.id ? config.id : "",
                smtp_host: config.smtp_host ? config.smtp_host : "",
                email_type: config.email_type ? config.email_type : "",
                smtp_username: config.smtp_username ? config.smtp_username : "",
                smtp_password: config.smtp_password ? config.smtp_password : "",
                smtp_port: config.smtp_port ? config.smtp_port : "",
                sendgrid_api_key: config.sendgrid_api_key
                    ? config.sendgrid_api_key
                    : "",
            });
        }
        if (
            this.form.controls.smtp_host.value ||
            this.form.controls.sendgrid_api_key.value
        ) {
            this.form.controls.is_default.setValue(false);
        } else {
            this.form.controls.is_default.setValue(true);
        }
        // this.form.valueChanges.subscribe((data) => {
        //     this.utilitiesService.formChangedOrNot(true);
        // });
    }
    async getEmailConfig() {
        this.utilitiesService.startLoader();
        try {
            const configList = await this.appService
                .getEmailConfig()
                .toPromise();
            if (configList) {
                this.configList = configList.results;
                this.setValue();
            }
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }

    removeSMTPValidations() {
        this.form.controls.smtp_password.clearValidators();
        this.form.controls.smtp_password.updateValueAndValidity();
        this.form.controls.smtp_username.clearValidators();
        this.form.controls.smtp_username.updateValueAndValidity();
        this.form.controls.smtp_host.clearValidators();
        this.form.controls.smtp_host.updateValueAndValidity();
        this.form.controls.smtp_port.clearValidators();
        this.form.controls.smtp_port.updateValueAndValidity();
    }
    removeSendgridValidations() {
        this.form.controls.sendgrid_api_key.clearValidators();
        this.form.controls.sendgrid_api_key.updateValueAndValidity();
    }

    async addOrRemoveValidtions(isDefault) {
        if (isDefault) {
            this.form.controls.email_type.clearValidators();
            this.form.controls.email_type.updateValueAndValidity();
            this.removeSMTPValidations();
            this.removeSendgridValidations();
        } else {
            this.typeChanged();
        }
    }

    typeChanged() {
        if (this.form.controls.email_type.value == 2) {
            this.form.controls.smtp_password.setValidators([
                Validators.required,
            ]);
            this.form.controls.smtp_password.updateValueAndValidity();
            this.form.controls.smtp_username.setValidators([
                Validators.required,
            ]);
            this.form.controls.smtp_username.updateValueAndValidity();
            this.form.controls.smtp_password.setValidators([
                Validators.required,
            ]);
            this.form.controls.smtp_password.updateValueAndValidity();
            this.form.controls.smtp_port.setValidators([Validators.required]);
            this.form.controls.smtp_port.updateValueAndValidity();
            this.form.controls.smtp_host.setValidators([Validators.required]);
            this.form.controls.smtp_host.updateValueAndValidity();
            this.removeSendgridValidations();
        } else if (this.form.controls.email_type.value == 3) {
            this.form.controls.sendgrid_api_key.setValidators([
                Validators.required,
            ]);
            this.form.controls.sendgrid_api_key.updateValueAndValidity();
            this.removeSMTPValidations();
        } else {
            this.removeSMTPValidations();
            this.removeSendgridValidations();
        }
    }

    goBack() {
        let fromApp = true;
        if (!this.utilitiesService.formChanged$.value) {
            this.back.emit();
        } else {
            let content = `You have unsaved changes, Please save before changing the page`;
            let heading = "Alert";
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp == "yes") {
                } else if (resp == "no") {
                    this.utilitiesService.formChangedOrNot(false);
                    this.back.emit();
                }
            });
        }
    }
    removeFormValuesBasedOnCondition() {
        if (this.form.controls.is_default.value) {
            this.form.patchValue({
                smtp_host: "",
                email_type: 2,
                smtp_username: "",
                smtp_password: "",
                smtp_port: "",
                sendgrid_api_key: "",
            });
        } else {
            if (this.form.controls.email_type.value == 2) {
                this.form.patchValue({
                    sendgrid_api_key: "",
                });
            } else {
                this.form.patchValue({
                    smtp_host: "",
                    smtp_username: "",
                    smtp_password: "",
                    smtp_port: "",
                });
            }
        }
    }

    async save() {
        if (this.form.valid) {
            this.removeFormValuesBasedOnCondition();
            let form = this.form.value;
            if (this.configList.length) {
                await this.updateConfig(form);
            } else {
                await this.saveConfig(form);
            }
        }
    }
    async updateConfig(form) {
        try {
            let content = `Are you sure, Do you want to update this configuration ? `;
            let heading = "Update";
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp) {
                    this.appService
                        .updateEmailConfig(form)
                        .subscribe((data) => {
                            let successMsg = this.translate.instant(
                                "Configuration updated successfully"
                            );
                            this.utilitiesService.showSuccessToast(successMsg);
                            // this.back.emit();
                        });
                }
            });
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }
    async saveConfig(form) {
        try {
            let content = `Are you sure, Do you want to save this configuration ? `;
            let heading = "Save";
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp) {
                    this.appService.addEmailConfig(form).subscribe((data) => {
                        let successMsg = this.translate.instant(
                            "Configuration created successfully"
                        );
                        this.utilitiesService.showSuccessToast(successMsg);
                    });
                }
            });
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }
}
