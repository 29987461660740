import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CatalogueService } from "app/services/catalogue/catalogue.service";
import { UtilitiesService } from "app/services/utilitiesService";

@Component({
    selector: "app-create-handling-instructions",
    templateUrl: "./create-handling-instructions.component.html",
    styleUrls: ["./create-handling-instructions.component.scss"],
})
export class CreateHandlingInstructionsComponent implements OnInit {
    form: FormGroup;
    id: any;
    oldData: any = null;
    title = null;
    constructor(
        private dialogRef: MatDialogRef<CreateHandlingInstructionsComponent>,
        private fb: FormBuilder,
        private catalogueService: CatalogueService,
        private utilitiesService: UtilitiesService,
        private cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data) {
            this.oldData = data;
            console.log(data);
            this.title = "Edit Handle Instruction";
            this.cdr.markForCheck();
        } else {
            this.title = "Create Handle Instruction";
        }
    }

    ngOnInit(): void {
        this.formInit();
    }

    formInit() {
        this.form = this.fb.group({
            name: [
                this.oldData?.name ? this.oldData.name : "",
                Validators.required,
            ],
            name_ar: [
                this.oldData?.name_ar ? this.oldData.name_ar : "",
                Validators.required,
            ],
        });
    }

    saveOrUpdate() {
        let form = this.form.value;
        if (this.form.valid) {
            if (this.oldData?.id) {
                this.update(form);
            } else {
                this.save(form);
            }
        }
    }
    update(form) {
        this.utilitiesService.startLoader();
        this.catalogueService
            .updateHandling_instructions(form, this.oldData.id)
            .subscribe((res) => {
                if (res) {
                    this.utilitiesService.stopLoader();
                    let msg = "handling-instructions Updated successfully.";
                    this.utilitiesService.showSuccessToast(msg);
                    this.dialogRef.close(true);
                }
            });
        this.utilitiesService.stopLoader();
    }
    save(form) {
        this.utilitiesService.startLoader();
        this.catalogueService
            .addHandling_instructions(form)
            .subscribe((res) => {
                if (res) {
                    this.utilitiesService.stopLoader();
                    let msg = "handling-instructions saved successfully.";
                    this.utilitiesService.showSuccessToast(msg);
                    this.dialogRef.close(true);
                }
            });
        this.utilitiesService.stopLoader();
    }

    handleCancel() {
        this.dialogRef.close();
    }
}
