import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CoreService } from "app/services/core-service";
import { UtilitiesService } from "app/services/utilitiesService";

@Component({
    selector: "app-customer-details-popover",
    templateUrl: "./customer-details-popover.component.html",
    styleUrls: ["./customer-details-popover.component.scss"],
})
export class CustomerDetailsPopoverComponent implements OnInit {
    @Input() row: any;
    @Output() viewCustomerEvent: EventEmitter<void> = new EventEmitter<any>();
    constructor(
        public coreService: CoreService,
        public utilitiesService: UtilitiesService
    ) {}

    ngOnInit(): void {}

    viewCustomer(id) {
        this.viewCustomerEvent.emit(id);
    }
}
