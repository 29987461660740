import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from 'app/services/translationService';
import { findFlagUrlByCountryName } from "country-flags-svg";

@Component({
    selector: 'app-top-customers',
    templateUrl: './top-customers.component.html',
    styleUrls: ['./top-customers.component.scss']
})
export class TopCustomersComponent implements OnInit {
    _header: string = '';
    _data;
    flags = [];
    direction = 'ltr';

    @Input() set header(value: string) {
        this._header = value;
    }

    @Input() set data(value) {
        this._data = value?.top_customers;
    }

    get header(): string {
        return this._header;
    }

    get data() {
        return this._data;
    }

    constructor(private router: Router, private translateService: TranslationService) { }

    ngOnInit(): void { }

    ngOnChanges() {
        this.getFlags(this.data)
        this.translateService._languageBehaviorSubject$.subscribe(data => {
            this.direction = data.lang === 'en' ? 'ltr' : 'rtl';
        })
    }

    getFlags(data) {
        this.flags = [];
        if (data) {
            data.forEach(el => {
                let flag = findFlagUrlByCountryName(`${el?.contact_no_country}`);
                this.flags.push(flag);
            });
        }
    }

    navigateTo(id) {
        this.router.navigate(['/customer-list'], {
            queryParams: {
                id
            }
        });
    }
}
