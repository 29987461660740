import { ApiService } from "app/services/api.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { CampaignsService } from "app/services/marketing/campaigns.service";
import { NotificationService } from "app/services/notification/notification.service";
import { PageService } from "app/services/pageService";
import { PriceRuleService } from "app/services/price-rules/price-rules.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
import { CampaignPreviewComponent } from "../campaign-preview/campaign-preview.component";
import { FullscreenAdditionCssComponent } from "../fullscreen-addition-css/fullscreen-addition-css.component";

export enum CampaignURLTypesEnum {
  PAGE = 1,
  CUSTOM = 2,
}
@Component({
  selector: "app-create-campaign",
  templateUrl: "./create-campaign.component.html",
  styleUrls: ["./create-campaign.component.scss"],
  animations: fuseAnimations,
})
export class CreateCampaignComponent implements OnInit {
  @Input() id: any;
  @Input() detailObject: any;
  @Input() elementTypes = [];
  @Input() page: string;
  @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
  @Output() afterSave: EventEmitter<void> = new EventEmitter<any>();

  codeEditorFormControl = new FormControl();
  loading: boolean = false;
  campaignForm: FormGroup;
  audienceList = [];
  priceRuleList = [];
  mediumsList = [];
  pagesList = [];
  notificationTemplate = [];
  campaignDetail: any;
  templateDetail: any;
  isLoading = false;
  ckeConfig = {
    allowedContent: true,
    versionCheck: false,
    removeButtons: "CreateDiv,BlockQuote,Superscript,Subscript,PasteFromWord,PasteText,Source",
  };
  campaignURLTypesEnum = CampaignURLTypesEnum;
  isAffiliatePage: boolean;
  campaignNameField = "Name";
  campaignCodeField = "Code";
  campaignUrlField = "URL Type";
  baseUrl: string;

  get urlSpecification(): FormGroup {
    return this.campaignForm.get("url_specification") as FormGroup;
  }

  get urlTypeFormControl(): FormControl {
    return this.campaignForm.get("url_specification").get("type") as FormControl;
  }

  get urlValueFormControl(): FormControl {
    return this.campaignForm.get("url_specification").get("value") as FormControl;
  }

  get mediumFormControl(): FormControl {
    return this.campaignForm.get("medium").get("medium") as FormControl;
  }

  constructor(
    private fb: FormBuilder,
    public utilitiesService: UtilitiesService,
    private translate: TranslateService,
    private campaignsService: CampaignsService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private priceRuleService: PriceRuleService,
    private pageService: PageService,
    private apiService: ApiService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.id) {
      this.utilitiesService.startLoader();
    }

    this.isAffiliatePage = await this.checkForPage();
    this.isAffiliatePage ? await this.intializeAffiliatePage() : await this.formInitialize(1);

    await this.getNotificationTemplate();
    await this.getAudience();
    if (this.id) {
      await this.campaignDetails();
      await this.getTemplateDetail();
      await this.ChannelChange(this.campaignDetail.channel);
      await this.setValue();
      this.utilitiesService.stopLoader();
    }
  }

  async checkForPage() {
    return this.page === "affiliate";
  }

  async intializeAffiliatePage() {
    this.campaignCodeField = "Campaign Code";
    this.campaignNameField = "Campaign Name";
    this.campaignUrlField = "Create URL for";
    this.baseUrl = await this.getBaseUrl();
    await this.formInitialize(4);
    await this.ChannelChange(4);
  }

  async getBaseUrl() {
    return this.apiService.BASE_URL;
  }

  async getNotificationTemplate(channel = "") {
    try {
      this.isLoading = true;
      const notificationTemplate = await this.notificationService.getNotificationList(99999, 0, "", channel).toPromise();
      if (notificationTemplate) {
        this.notificationTemplate = notificationTemplate.results;
        this.isLoading = false;
      }
    } catch {
      this.isLoading = false;
    } finally {
    }
  }
  async campaignDetails() {
    try {
      const campaignDetail = await this.campaignsService.campaignDetail(this.id).toPromise();
      if (campaignDetail) {
        this.campaignDetail = campaignDetail;
      }
    } catch {
    } finally {
    }
  }
  async getTemplateDetail() {
    try {
      const templateDetail = await this.campaignsService.getTemplateDetail(this.detailObject.template).toPromise();
      if (templateDetail) {
        this.templateDetail = templateDetail;
      }
    } catch {
    } finally {
    }
  }
  async getAudience() {
    try {
      const audienceList = await this.campaignsService.getAudience().toPromise();
      if (audienceList) {
        this.audienceList = audienceList.results;
      }
    } catch {
    } finally {
    }
  }
  async setValue() {
    const campaign = this.campaignDetail;
    const templateDetail = this.templateDetail;

    this.campaignForm.patchValue({
      name: campaign.name,
      channel: campaign.channel,
      template_format: campaign.template_format || 1,
      template: campaign?.template,
      audience: campaign.audience,
      title: templateDetail.title,
      message_email: campaign.channel == 1 && templateDetail?.body,
      message: campaign.channel != 1 && templateDetail?.body,
      code: campaign?.code,
      medium: campaign?.medium,
      url_specification: {
        type: campaign?.url_specification?.type,
        value: campaign?.url_specification?.value,
      },
      price_rule: campaign?.price_rule,
    });
  }

  async formInitialize(channel) {
    this.campaignForm = this.fb.group({
      name: [null, [Validators.required]],
      channel: [channel],
      audience: [null, [Validators.required]],
      message: [null],
      template: [null],
      message_email: [null],
      title: [null],
      template_format: [1, [Validators.required]],
      use_exist: [false],
      re_usable: [false],
      code: [null],
      medium: [null],
      url_specification: this.fb.group({
        type: [null],
        value: [null],
      }),
      price_rule: [null],
    });
    if (!this.id && !this.isAffiliatePage) {
      await this.ChannelChange(1);
    }
  }

  templateCheckboxChange() {
    document.getElementById("toolbar").scrollIntoView();
    let channel = this.campaignForm.controls.channel.value;
    this.ChannelChange(channel);
  }

  async ChannelChange(value) {
    let channel;
    if (value == 1) {
      channel = "EMAIL";
    }
    if (value == 2) {
      channel = "SMS";
    }

    if (value == 3) {
      channel = "PUSH";
    }

    if (value == 4) {
      channel = "CAMPAIGN";
    }

    if (this.campaignForm.controls.use_exist.value) {
      await this.getNotificationTemplate(channel);
    }

    if (!this.campaignForm.controls.use_exist.value) {
      if (value != 4) {
        this.campaignForm.get("code").clearValidators();
        this.campaignForm.get("code").updateValueAndValidity();

        this.campaignForm.get("medium").clearValidators();
        this.campaignForm.get("medium").updateValueAndValidity();

        this.campaignForm.get("price_rule").clearValidators();
        this.campaignForm.get("price_rule").updateValueAndValidity();

        this.campaignForm.get("url_specification").get("type").clearValidators();
        this.campaignForm.get("url_specification").get("type").updateValueAndValidity();

        this.campaignForm.get("url_specification").get("value").clearValidators();
        this.campaignForm.get("url_specification").get("value").updateValueAndValidity();
      }

      if (value == 1) {
        this.campaignForm.get("message_email").setValidators([Validators.required]);
        this.campaignForm.get("message_email").updateValueAndValidity();
        this.campaignForm.get("message").clearValidators();
        this.campaignForm.get("message").updateValueAndValidity();
      } else {
        this.campaignForm.get("message").setValidators([Validators.required]);
        this.campaignForm.get("message").updateValueAndValidity();
        this.campaignForm.get("message_email").clearValidators();
        this.campaignForm.get("message_email").updateValueAndValidity();
      }
      this.campaignForm.get("template_format").setValidators([Validators.required]);
      this.campaignForm.get("template_format").updateValueAndValidity();
      this.campaignForm.get("template").clearValidators();
      this.campaignForm.get("template").updateValueAndValidity();
    }

    if (this.campaignForm.controls.use_exist.value) {
      this.campaignForm.get("message").clearValidators();
      this.campaignForm.get("message").updateValueAndValidity();
      this.campaignForm.get("message_email").clearValidators();
      this.campaignForm.get("message_email").updateValueAndValidity();
      this.campaignForm.get("template").setValidators([Validators.required]);
      this.campaignForm.get("template").updateValueAndValidity();
      this.campaignForm.get("template_format").clearAsyncValidators();
      this.campaignForm.get("template_format").updateValueAndValidity();
    }

    if (value == 4) {
      this.campaignForm.get("message").clearValidators();
      this.campaignForm.get("message").updateValueAndValidity();
      this.campaignForm.get("message_email").clearValidators();
      this.campaignForm.get("message_email").updateValueAndValidity();
      this.campaignForm.get("template").clearValidators();
      this.campaignForm.get("template").updateValueAndValidity();
      this.campaignForm.get("template_format").clearAsyncValidators();
      this.campaignForm.get("template_format").updateValueAndValidity();

      if (this.isAffiliatePage) {
        this.campaignForm.get("audience").setValidators(null);
        this.campaignForm.get("audience").updateValueAndValidity();
      } else {
        this.campaignForm.get("code").setValidators([Validators.required]);
        this.campaignForm.get("code").updateValueAndValidity();
      }

      this.campaignForm.get("code").setValidators([Validators.required]);
      this.campaignForm.get("code").updateValueAndValidity();

      this.campaignForm.get("medium").clearValidators();
      this.campaignForm.get("medium").updateValueAndValidity();

      // this.campaignForm.get("price_rule").setValidators([Validators.required]);
      // this.campaignForm.get("price_rule").updateValueAndValidity();

      this.campaignForm.get("url_specification").get("type").setValidators([Validators.required]);
      this.campaignForm.get("url_specification").get("type").updateValueAndValidity();

      this.campaignForm.get("url_specification").get("value").setValidators([Validators.required]);
      this.campaignForm.get("url_specification").get("value").updateValueAndValidity();

      this.getCampaignsRequiredData();
    }
  }

  handleCancel() {
    this.cancelClick.emit();
  }

  async saveOrUpdate() {
    if (this.campaignForm.valid) {
      this.utilitiesService.startLoader();
      let form = this.campaignForm.value;
      if (this.id) {
        await this.updateCampaign(form);
      } else {
        await this.saveCampaign(form);
      }
    } else {
      console.log("Form is", this.campaignForm);
      let errorMsg = this.translate.instant("Form is not valid,");
      this.utilitiesService.showErrorToast(errorMsg);
    }
  }
  async updateCampaign(form) {
    let channel: any;
    let title: any = "";
    if (form.channel == 1) {
      channel = "EMAIL";
      title = form.title;
    } else if (form.channel == 2) {
      channel = "SMS";
    } else if (form.channel == 3) {
      channel = "PUSH";
      title = form.title;
    } else if (form.channel == 4) {
      channel = "CAMPAIGN";
      title = form.title;
    }
    try {
      let templateForm = {
        title: title ? title : form.name,
        title_en: title ? title : form.name,
        title_ar: title ? title : form.name,
        body: channel == "EMAIL" ? form.message_email : form.message,
        body_en: channel == "EMAIL" ? form.message_email : form.message,
        body_ar: channel == "EMAIL" ? form.message_email : form.message,
        template_type: channel,
        template_format: form.template_format,
      };
      if (!this.campaignForm.controls.use_exist.value && form.channel != 4) {
        this.campaignsService.updateTemplate(templateForm, this.detailObject.template).subscribe((response) => {
          let campaignForm = form;
          campaignForm.template = response.id;
          this.campaignsService.updateCampaign(campaignForm, this.id).subscribe((data) => {
            this.utilitiesService.stopLoader();
            let content = `Do you want to send it now or later ? `;
            let heading = "Execute";
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
              data: { content, heading, fromApp },
              maxWidth: "",
              width: `${size.width}`,
              height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
              if (resp) {
                this.utilitiesService.startLoader();
                let resp = await this.campaignsService.executeCampaign(data.id).toPromise();
                if (resp) {
                  let successMsg = this.translate.instant(`Campaign " ${data.name} " executed successfully`);
                  this.utilitiesService.showSuccessToast(successMsg);
                  this.utilitiesService.startLoader();
                  this.afterSave.emit();
                }
              } else {
                this.afterSave.emit();
              }
            });
          });
        });
      } else {
        let campaignForm = form;
        this.campaignsService.updateCampaign(campaignForm, this.id).subscribe((data) => {
          this.utilitiesService.stopLoader();
          let content = `Do you want to send it now or later ? `;
          let heading = "Execute";
          let fromApp = false;
          let size = this.utilitiesService.isMobileAlertModal();
          const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
          });
          dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
              this.utilitiesService.startLoader();
              let resp = await this.campaignsService.executeCampaign(data.id).toPromise();
              if (resp) {
                let successMsg = this.translate.instant(`Campaign " ${data.name} " executed successfully`);
                this.utilitiesService.showSuccessToast(successMsg);
                this.utilitiesService.startLoader();
                this.afterSave.emit();
              }
            } else {
              this.afterSave.emit();
            }
          });
        });
      }
    } catch {
      let erroMsg = this.translate.instant("Api general Error");
      this.utilitiesService.showErrorToast(erroMsg);
      this.utilitiesService.stopLoader();
    } finally {
      this.utilitiesService.stopLoader();
    }
  }
  async saveCampaign(form) {
    let channel: any;
    let title: any = "";
    if (form.channel == 1) {
      channel = "EMAIL";
      title = form.title;
    } else if (form.channel == 2) {
      channel = "SMS";
    } else if (form.channel == 3) {
      channel = "PUSH";
      title = form.title;
    } else if (form.channel == 4) {
      channel = "CAMPAIGN";
      title = form.title;
    }

    try {
      let templateForm = {
        title: title ? title : form.name,
        title_en: title ? title : form.name,
        title_ar: title ? title : form.name,
        body: channel == "EMAIL" ? form.message_email : form.message,
        body_en: channel == "EMAIL" ? form.message_email : form.message,
        body_ar: channel == "EMAIL" ? form.message_email : form.message,
        template_type: channel,
        template_format: form.template_format,
      };
      if (!this.campaignForm.controls.use_exist.value && form.channel != 4) {
        this.campaignsService.addTemplate(templateForm).subscribe((response) => {
          let campaignForm = form;
          campaignForm.template = response.id;
          this.campaignsService.addCampaign(campaignForm).subscribe((data) => {
            this.utilitiesService.stopLoader();
            let content = `Do you want to send it now or later ? `;
            let heading = "Execute";
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
              data: { content, heading, fromApp },
              maxWidth: "",
              width: `${size.width}`,
              height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
              if (resp) {
                this.utilitiesService.startLoader();
                let resp = await this.campaignsService.executeCampaign(data.id).toPromise();
                if (resp) {
                  let successMsg = this.translate.instant(`Campaign " ${data.name} " executed successfully`);
                  this.utilitiesService.showSuccessToast(successMsg);
                  this.utilitiesService.startLoader();
                  this.afterSave.emit();
                }
              } else {
                this.afterSave.emit();
              }
            });
          });
        });
      } else {
        let campaignForm = form;
        this.campaignsService.addCampaign(campaignForm).subscribe((data) => {
          this.utilitiesService.stopLoader();
          let content = this.isAffiliatePage ? `The campaign was created successfully` : `Do you want to send it now or later ? `;
          let heading = this.isAffiliatePage ? "Succeful" : "Execute";
          let fromApp = false;
          let isAffiliate = this.isAffiliatePage;
          let size = this.utilitiesService.isMobileAlertModal();
          const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp, isAffiliate },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
          });
          dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
              this.utilitiesService.startLoader();
              let resp = await this.campaignsService.executeCampaign(data.id).toPromise();
              if (resp) {
                let successMsg = this.translate.instant(`Campaign " ${data.name} " executed successfully`);
                this.utilitiesService.showSuccessToast(successMsg);
                this.utilitiesService.startLoader();
                this.afterSave.emit();
              }
            } else {
              this.afterSave.emit();
            }
          });
        });
      }
    } catch {
      let erroMsg = this.translate.instant("Api general Error");
      this.utilitiesService.showErrorToast(erroMsg);
      this.utilitiesService.stopLoader();
    } finally {
      this.utilitiesService.stopLoader();
    }
  }
  async executeCampaign(id, name) {
    let content = `Are you sure, Do you want to execute this campaign " ${name} " ? `;
    let heading = "Execute";
    let fromApp = false;
    let size = this.utilitiesService.isMobileAlertModal();
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: { content, heading, fromApp },
      maxWidth: "",
      width: `${size.width}`,
      height: `${size.height}`,
    });
    dialogRef.afterClosed().subscribe(async (resp) => {
      if (resp) {
        let resp = await this.campaignsService.executeCampaign(id).toPromise();
        if (resp) {
          let successMsg = this.translate.instant(`Campaign " ${name} " executed successfully`);
          this.utilitiesService.showSuccessToast(successMsg);
          this.afterSave.emit();
          // this.getCampaignList();
        }
      }
    });
  }

  getCampaignsRequiredData() {
    this.getPriceRules();
    this.getMediums();
    this.getPages();
  }

  async getPriceRules(limit = 100, offset = 0) {
    if (this.priceRuleList.length) {
      return;
    }

    try {
      const priceRuleList = await this.priceRuleService.getPriceRules(limit, offset).toPromise();

      if (priceRuleList) {
        this.priceRuleList = priceRuleList.results;
      }
    } catch {
    } finally {
    }
  }

  async getMediums(limit = 100, offset = 0) {
    if (this.mediumsList.length) {
      return;
    }

    try {
      const response = await this.campaignsService.getMediums(limit, offset).toPromise();
      if (response) {
        this.mediumsList = response.results;
      }
    } catch {
    } finally {
    }
  }

  async getPages() {
    if (this.pagesList.length) {
      return;
    }

    try {
      const pages = await this.pageService.getPages(999999, 0).toPromise();
      if (pages) {
        this.pagesList = pages.results;
      }
    } catch {}
  }

  preview() {
    let body = "";
    let title = this.campaignForm.controls.title.value;
    let channel = this.campaignForm.controls.channel.value;
    if (!this.campaignForm.get("use_exist").value) {
      if (channel == 1) {
        body = this.campaignForm.controls.message_email.value;
      } else {
        body = this.campaignForm.controls.message.value;
      }
    } else {
      let templateBody = [];
      let template = this.campaignForm.get("template").value;
      templateBody = this.notificationTemplate
        .filter((data) => data.id == template)
        .map((res) => {
          return res.body;
        });
      body = templateBody[0];
    }

    const dialogRef = this.dialog.open(CampaignPreviewComponent, {
      data: { body, title, channel },
      maxWidth: "",
      width: "100%",
      height: "100%",
    });
    dialogRef.afterClosed().subscribe(async (resp) => {
      if (resp) {
      }
    });
  }
  zoomAdditionalCss(formControl) {
    let heading = "HTML";
    const dialogRef = this.dialog.open(FullscreenAdditionCssComponent, {
      data: { formControl, heading },
      maxWidth: "",
      width: "100%",
      height: "100%",
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        this.campaignForm.controls.message_email.setValue(resp.value);
      }
    });
  }
}
