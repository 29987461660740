<div
    fxFlex="1 1 auto"
    fxLayoutAlign="start stretch"
    fxLayout="row"
    fxLayoutGap="1em"
    id="main-page"
>
    <div
        fxFlex="1 1 auto"
        fxLayoutAlign="start stretch"
        fxLayout="column"
        fxLayoutGap="1em"
    >
        <form
            fxFlex="1 1 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="1em"
        >
            <div
                fxFlex="0 0 auto"
                fxLayoutAlign="space-between stretch"
                fxLayout="row"
                fxLayoutGap="10px"
            >
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="center center"
                    fxLayout="row"
                    fxLayoutGap="10px"
                >
                    <mat-icon class="cursor-pointer" (click)="goBack()"
                        >arrow_back</mat-icon
                    >
                    <label class="highlight-text-color">{{
                        "Delivery Scheduling " | translate
                    }}</label>
                </div>
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="center center"
                    fxLayout="row"
                    fxLayoutGap="10px"
                >
                    <button
                        (click)="save()"
                        type="submit"
                        mat-flat-button
                        style="position: absolute; right: 16px"
                        class="primary-bg-color"
                    >
                        {{ "Save" | translate }}
                    </button>
                </div>
            </div>
            <!-- last -->
            <div
                fxFlex="1 1 auto"
                fxLayoutAlign="start stretch"
                fxLayout="row"
                fxLayoutGap="1em"
                id="main-page"
                cdkDropListGroup
            >
                <div
                    fxFlex="1 1 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="1em"
                >
                    <form
                        formGroup="formdays"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="0px"
                    >
                        <div
                            fxFlex="0 0 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout.lt-md="column"
                            fxLayout.gt-sm="row"
                            fxLayoutGap.gt-sm="5px"
                            fxLayoutGap.lt-md="0px"
                        >
                            <mat-form-field appearance="outline" fxFlex="100%">
                                <mat-label>{{
                                    "schedule_days" | translate
                                }}</mat-label>
                                <input
                                    type="text"
                                    [formControl]="
                                        formdays?.get('schedule_days')
                                    "
                                    formControlName="schedule_days"
                                    matInput
                                />
                                <mat-error>
                                    {{
                                        "Schedule Days is required" | translate
                                    }}
                                </mat-error>
                            </mat-form-field>
                            <!-- <div
                            class="default-time-example"
                            fxFlex="1 1 auto"
                            fxLayout="column"
                        > -->
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label class="text-capitalized">
                                    {{ "holidays" | translate }}
                                </mat-label>
                                <input
                                    matTooltip="{{
                                        'help_text_holidays' | translate
                                    }}"
                                    [formControl]="formdays?.get('holidays')"
                                    [matMenuTriggerFor]="appMenu"
                                    formControlName="holidays"
                                    matInput
                                    name="holidays"
                                />
                                <mat-icon
                                    mat-icon-button
                                    [matMenuTriggerFor]="appMenu"
                                    class="cursor-pointer"
                                    matSuffix
                                >
                                    calendar_today</mat-icon
                                >

                                <mat-menu #appMenu="matMenu">
                                    <div (click)="$event.stopPropagation()">
                                        <mat-calendar
                                            #calendar
                                            (selectedChange)="
                                                select($event, calendar)
                                            "
                                            [dateClass]="isSelected"
                                        >
                                        </mat-calendar>
                                    </div>
                                </mat-menu>
                            </mat-form-field>
                            <!-- </div> -->
                        </div>
                    </form>
                    <form
                        fxFlex="1 1 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="1em"
                        [formGroup]="form"
                        (cdkDropListDropped)="drop($event)"
                        cdkDropList
                    >
                        <div
                            fxLayout="column"
                            fxFlex="0 0 auto"
                            fxLayoutAlign="start stretch"
                            class="fuse-card auto-width fuse-box-shadow-none"
                        >
                            <div
                                fxLayout="row"
                                fxFlex="0 0 auto"
                                fxLayoutAlign="space-between start"
                            >
                                <label class="highlight-text-color">{{
                                    "List of Schedule Time slots" | translate
                                }}</label>
                                <app-fab-button
                                    (handleFabButtonClick)="addCustomCountry()"
                                ></app-fab-button>
                            </div>

                            <mat-accordion
                                cdk-drag-preview
                                cdkDrag
                                [cdkDragData]="customCountryFormGroup"
                                class="mb-16"
                                formArrayName="country_rules"
                                *ngFor="
                                    let customCountryFormGroup of form.get(
                                        'country_rules'
                                    )['controls'];
                                    let i = index
                                "
                                [formGroup]="customCountryFormGroup"
                            >
                                <mat-expansion-panel
                                    [expanded]="expandIndex[i] == i"
                                    [ngClass]="{ invalid: !checkInvalid(i) }"
                                >
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <div
                                                fxLayout="row warp"
                                                fxFlex="1 1 auto"
                                                fxLayoutAlign="start start"
                                                fxLayoutGap="5px"
                                            >
                                                <mat-icon
                                                    >drag_indicator</mat-icon
                                                >
                                                <div
                                                    fxLayout="row warp"
                                                    fxFlex="1 1 auto"
                                                    fxLayoutAlign="start start"
                                                    fxLayoutGap="5px"
                                                    *ngIf="
                                                        customCountryFormGroup.get(
                                                            'countries'
                                                        )?.value?.length > 0
                                                    "
                                                >
                                                    <div
                                                        fxLayout="row"
                                                        fxFlex="0 0 auto"
                                                        fxLayoutAlign="start stretch"
                                                        *ngFor="
                                                            let name of customCountryFormGroup
                                                                .get(
                                                                    'countries'
                                                                )
                                                                ?.value?.slice(
                                                                    0,
                                                                    11
                                                                );
                                                            let i = index;
                                                            let last = last
                                                        "
                                                    >
                                                        {{ name?.itemName }}
                                                        <span *ngIf="!last"
                                                            >,</span
                                                        >
                                                        <span
                                                            *ngIf="
                                                                last && i >= 10
                                                            "
                                                            >...</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                style="width: 100%"
                                                *ngIf="
                                                    customCountryFormGroup.get(
                                                        'countries'
                                                    )?.value?.length <= 0
                                                "
                                            >
                                                {{
                                                    "Schedule Delivery  "
                                                        | translate
                                                }}
                                                {{
                                                    customCountryFormGroup?.get(
                                                        "slot_timing"
                                                    )?.value
                                                }}
                                            </div>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div
                                        (mousedown)="$event.stopPropagation()"
                                        (mousemove)="$event.stopPropagation()"
                                        fxFlex="1 1 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <div
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="start stretch"
                                            fxLayout="column"
                                            fxLayoutGap="0px"
                                        >
                                            <mat-slide-toggle
                                                fxFlex="0 1 auto"
                                                (change)="
                                                    showTabbyChange(
                                                        i,
                                                        customCountryFormGroup.get(
                                                            'enabled'
                                                        ).value
                                                    )
                                                "
                                                [formControl]="
                                                    customCountryFormGroup.get(
                                                        'enabled'
                                                    )
                                                "
                                            >
                                                {{ "enabled" | translate }}
                                            </mat-slide-toggle>
                                            <!-- <small class="mb-16">{{
                                                "help_text_EnableDeliveryScheduling"
                                                    | translate
                                            }}</small> -->
                                        </div>

                                        <div
                                            fxFlex="0 0 auto"
                                            fxLayout="column"
                                            fxLayoutAlign="start stretch"
                                            fxLayoutGap="5px"
                                        >
                                            <!-- start n=my code  -->

                                            <div
                                                fxFlex="0 0 auto"
                                                fxLayout="column"
                                                fxLayoutAlign="start stretch"
                                                fxLayoutGap="5px"
                                            >
                                                <!-- start -->
                                                <div
                                                    fxFlex="0 0 auto"
                                                    fxLayoutAlign="start stretch"
                                                    fxLayout.gt-sm="row"
                                                    fxLayout.lt-md="column"
                                                    fxLayoutGap="1em"
                                                >
                                                    <!-- "Delivery cutoff_time" -->
                                                    <div
                                                        class="default-time-example"
                                                        fxFlex="1 1 auto"
                                                        fxLayout="column"
                                                    >
                                                        <mat-form-field
                                                            appearance="outline"
                                                            fxFlex="100"
                                                        >
                                                            <mat-label
                                                                class="text-capitalized"
                                                            >
                                                                {{
                                                                    "Delivery Cut Off Time"
                                                                        | translate
                                                                }}
                                                            </mat-label>
                                                            <input
                                                                [format]="24"
                                                                (input)="
                                                                    defaultValue.open()
                                                                "
                                                                matTooltip="{{
                                                                    'help_text_DeliveryStartTime'
                                                                        | translate
                                                                }}"
                                                                [ngxTimepicker]="
                                                                    cutoff_time
                                                                "
                                                                [formControl]="
                                                                    customCountryFormGroup.get(
                                                                        'cutoff_time'
                                                                    )
                                                                "
                                                                matInput
                                                                name="cutoff_time"
                                                            />
                                                            <mat-icon
                                                                class="cursor-pointer"
                                                                (click)="
                                                                    cutoff_time.open()
                                                                "
                                                                matSuffix
                                                            >
                                                                access_time</mat-icon
                                                            >
                                                            <ngx-material-timepicker
                                                                [controlonly]="
                                                                    true
                                                                "
                                                                [format]="24"
                                                                #cutoff_time
                                                            ></ngx-material-timepicker>
                                                        </mat-form-field>
                                                    </div>
                                                    <!-- reference -->
                                                    <div
                                                        class="default-time-example"
                                                        fxFlex="1 1 auto"
                                                        fxLayout="column"
                                                    >
                                                        <mat-form-field
                                                            appearance="outline"
                                                            fxFlex="100"
                                                        >
                                                            <mat-label
                                                                class="text-capitalized"
                                                            >
                                                                {{
                                                                    "Delivery reference"
                                                                        | translate
                                                                }}
                                                            </mat-label>
                                                            <input
                                                                [format]="24"
                                                                matTooltip="{{
                                                                    'help_text_DeliveryStartTime'
                                                                        | translate
                                                                }}"
                                                                [formControl]="
                                                                    customCountryFormGroup.get(
                                                                        'reference'
                                                                    )
                                                                "
                                                                matInput
                                                                name="reference"
                                                            />
                                                        </mat-form-field>
                                                    </div>
                                                    <!-- "Delivery Start Time" -->
                                                    <div
                                                        class="default-time-example"
                                                        fxFlex="1 1 auto"
                                                        fxLayout="column"
                                                    >
                                                        <mat-form-field
                                                            appearance="outline"
                                                            fxFlex="100"
                                                        >
                                                            <mat-label
                                                                class="text-capitalized"
                                                            >
                                                                {{
                                                                    "Delivery Start Time"
                                                                        | translate
                                                                }}
                                                            </mat-label>
                                                            <input
                                                                [format]="24"
                                                                (input)="
                                                                    defaultValue.open()
                                                                "
                                                                matTooltip="{{
                                                                    'help_text_DeliveryStartTime'
                                                                        | translate
                                                                }}"
                                                                [ngxTimepicker]="
                                                                    defaultValue
                                                                "
                                                                [formControl]="
                                                                    customCountryFormGroup.get(
                                                                        'start_time'
                                                                    )
                                                                "
                                                                matInput
                                                                name="title"
                                                            />
                                                            <mat-icon
                                                                class="cursor-pointer"
                                                                (click)="
                                                                    defaultValue.open()
                                                                "
                                                                matSuffix
                                                            >
                                                                access_time</mat-icon
                                                            >
                                                            <ngx-material-timepicker
                                                                [controlonly]="
                                                                    true
                                                                "
                                                                [format]="24"
                                                                #defaultValue
                                                            ></ngx-material-timepicker>
                                                        </mat-form-field>
                                                    </div>

                                                    <!-- "Delivery End Time" -->
                                                    <div
                                                        class="default-time-example"
                                                        fxFlex="1 1 auto"
                                                        fxLayout="column"
                                                    >
                                                        <mat-form-field
                                                            appearance="outline"
                                                            fxFlex="100"
                                                        >
                                                            <mat-label
                                                                class="text-capitalized"
                                                            >
                                                                {{
                                                                    "Delivery End Time"
                                                                        | translate
                                                                }}
                                                            </mat-label>
                                                            <input
                                                                (input)="
                                                                    picker.open()
                                                                "
                                                                matTooltip="{{
                                                                    'help_text_DeliveryEndTime'
                                                                        | translate
                                                                }}"
                                                                [ngxTimepicker]="
                                                                    picker
                                                                "
                                                                [format]="24"
                                                                [formControl]="
                                                                    customCountryFormGroup.get(
                                                                        'end_time'
                                                                    )
                                                                "
                                                                matInput
                                                                name="title"
                                                            />
                                                            <mat-icon
                                                                class="cursor-pointer"
                                                                (click)="
                                                                    picker.open()
                                                                "
                                                                matSuffix
                                                                >access_time
                                                            </mat-icon>
                                                            <ngx-material-timepicker
                                                                [controlonly]="
                                                                    true
                                                                "
                                                                [format]="24"
                                                                #picker
                                                            ></ngx-material-timepicker>
                                                        </mat-form-field>
                                                    </div>
                                                    <!-- <div
                                                        class="default-time-example"
                                                        fxFlex="1 1 auto"
                                                        fxLayout="column"
                                                    >
                                                        <mat-form-field
                                                            appearance="outline"
                                                            fxFlex="100"
                                                        >
                                                            <mat-label
                                                                class="text-capitalized"
                                                            >
                                                                {{
                                                                    "holidays"
                                                                        | translate
                                                                }}
                                                            </mat-label>
                                                            <input
                                                                [format]="24"
                                                                matTooltip="{{
                                                                    'help_text_DeliveryStartTime'
                                                                        | translate
                                                                }}"
                                                                [matMenuTriggerFor]="
                                                                    appMenu
                                                                "
                                                                [formControl]="
                                                                    customCountryFormGroup.get(
                                                                        'holidays'
                                                                    )
                                                                "
                                                                matInput
                                                                name="holidays"
                                                            />
                                                            <mat-icon
                                                                mat-icon-button
                                                                [matMenuTriggerFor]="
                                                                    appMenu
                                                                "
                                                                class="cursor-pointer"
                                                                matSuffix
                                                            >
                                                                calendar_today</mat-icon
                                                            >

                                                            <mat-menu
                                                                #appMenu="matMenu"
                                                            >
                                                                <div
                                                                    (click)="
                                                                        $event.stopPropagation()
                                                                    "
                                                                >
                                                                    <mat-calendar
                                                                        #calendar
                                                                        (selectedChange)="
                                                                            select(
                                                                                $event,
                                                                                calendar,
                                                                                i,
                                                                                customCountryFormGroup.get(
                                                                                    'holidays'
                                                                                )
                                                                            )
                                                                        "
                                                                        [dateClass]="
                                                                            isSelected(
                                                                                $event,
                                                                                customCountryFormGroup.get(
                                                                                    'holidays'
                                                                                )
                                                                            )
                                                                        "
                                                                    >
                                                                    </mat-calendar>
                                                                </div>
                                                            </mat-menu>
                                                        </mat-form-field>
                                                    </div> -->
                                                    <!-- area -->
                                                    <!-- area -->
                                                </div>

                                                <div
                                                    fxFlex="0 0 auto"
                                                    fxLayoutAlign="start stretch"
                                                    fxLayout.gt-sm="row"
                                                    fxLayout.lt-md="column"
                                                    fxLayoutGap="1em"
                                                >
                                                    <ng-select
                                                        [loading]="isLoading"
                                                        [placeholder]="area"
                                                        fxFlex="100"
                                                        [multiple]="true"
                                                        [formControl]="
                                                            customCountryFormGroup.get(
                                                                'area'
                                                            )
                                                        "
                                                        name="area"
                                                        [formControlName]="area"
                                                    >
                                                        <!-- <ng-container *ngIf="conditionValues?.length && isDetailDone"> -->
                                                        <!-- <ng-template ng-header-tmp>
                                    <div>
                                        <button style="line-height:2 !important" (click)="selectAll()"
                                            mat-stroked-button>Select All</button>
                                    </div>
                                </ng-template> -->
                                                        <!-- </ng-container> -->
                                                        <ng-option
                                                            *ngFor="
                                                                let country of country
                                                            "
                                                            [value]="country.id"
                                                        >
                                                            {{
                                                                country?.name
                                                            }}</ng-option
                                                        >
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <!-- end  -->
                                        </div>
                                    </div>
                                    <mat-action-row>
                                        <app-delete-button
                                            (deleteButtonClick)="
                                                delete(
                                                    i,
                                                    customCountryFormGroup.get(
                                                        'id'
                                                    ).value
                                                )
                                            "
                                        >
                                        </app-delete-button>
                                    </mat-action-row>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </form>
                </div>
            </div>
            <!-- last  -->
        </form>
    </div>
</div>
