<div id="dashboard-project" class="page-layout simple right-sidebar" fxLayout="column">
    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" fusePerfectScrollbar>
        <form [formGroup]="form" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
            fusePerfectScrollbar class="p-16">
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row" fxLayout.lt-md="column"
                    fxLayoutGap="1em">
                    <div fxFlex="1 1 auto" class="fuse-card auto-width p-16" fxLayoutAlign="start stretch"
                        fxLayout="column" fxLayoutGap="0px">
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                            fxLayout.gt-sm="row" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'First Name'| translate}}
                                    </mat-label>
                                    <input matInput name="title_en" formControlName="first_name">
                                    <mat-error *ngIf="form.get('first_name').hasError('required')">
                                        {{'First Name is required' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Last Name'| translate}}
                                    </mat-label>
                                    <input matInput name="title_en" formControlName="last_name">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                            fxLayout.gt-sm="row" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Email'| translate}}
                                    </mat-label>
                                    <input matInput name="title_en" formControlName="email">
                                    <mat-error *ngIf="form.get('email').hasError('required')">
                                        {{'Email is required' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="form.get('email').hasError('email')">
                                        {{'Email is not valid' | translate}}
                                    </mat-error>
                                </mat-form-field>
                                <small class="mb-8">{{'* This email is your login name' | translate}}</small>
                            </div>
                        </div>
                        <div *ngIf="!this.id" fxFlex="1 1 auto" fxFlex.lt-md="0 0 50%" fxLayoutAlign="start stretch"
                            fxLayout="row" fxLayoutGap="0px">
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label class="text-capitalized">
                                    {{'Password'| translate}}
                                </mat-label>
                                <input matInput name="title_en" type="text" formControlName="password" #passwordField>
                                <mat-error *ngIf="form.get('password').hasError('required')">
                                    {{'Password is required' | translate}}
                                </mat-error>
                                <mat-error *ngIf="form.get('password').hasError('minlength')">
                                    {{'Password contain atleast 8 characters' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label class="text-capitalized">
                                    {{'Role'| translate}}
                                </mat-label>
                                <mat-select formControlName="role_id">
                                    <mat-option [value]="role?.id" *ngFor="let role of roleList">
                                        {{translationService.getTranslatedName(role)}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="form.get('role_id').hasError('required')">
                                    {{'Role is required' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="0 0 auto" fxLayoutAlign="flex-end" fxLayout="row" fxLayoutGap="1em">
                            <button class="btn-border" (click)="goBack()" type="submit" mat-flat-button>{{'Cancel' |
                                translate}}</button>
                            <button (click)="save()" type="submit" mat-flat-button class="primary-bg-color">{{'Save' |
                                translate}}</button>
                        </div>
                        <!-- <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                            fxLayout.gt-sm="row" fxLayoutGap="1em">
                            <mat-checkbox formControlName="is_email" class="checkbox-info">
                                {{'Share my crediental via email' | translate}}</mat-checkbox>
                        </div> -->
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>