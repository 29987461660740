import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { ConditionsSections } from "app/main/types";
import { CampaignsService } from "app/services/marketing/campaigns.service";
import { PriceRuleService } from "app/services/price-rules/price-rules.service";
import { SliderService } from "app/services/sliderService";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";

@Component({
    selector: "app-create-price-rules",
    templateUrl: "./create-price-rules.component.html",
    styleUrls: ["./create-price-rules.component.scss"],
    animations: fuseAnimations,
})
export class CreatePriceRulesComponent implements OnInit {
    @Input() id: any = "";
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    @Output() createPriceRule: EventEmitter<void> = new EventEmitter<any>();
    priceRuleForm: FormGroup;
    priceRulesDetail: any;
    audienceList = [];
    products = [];
    brands = [];
    categories = [];
    productSubject: BehaviorSubject<string> = new BehaviorSubject("");
    categorySubject: BehaviorSubject<string> = new BehaviorSubject("");
    brandSubject: BehaviorSubject<string> = new BehaviorSubject("");
    isLoadingBrand: boolean;
    isLoadingProduct: boolean;
    isLoadingCat: boolean;
    paymentMethods = [];
    isSelectAll = false;
    isUnSelectAll = false;

    includeProducts = [];
    includeBrands = [];
    includePaymentMethods = [];
    includeCategories = [];

    bankBrand$ = this.priceRuleService.getBankBrand()
    bankScheme$ = this.priceRuleService.getBankScheme()
    bankList$ = this.priceRuleService.getBankList()

    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private priceRuleService: PriceRuleService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        private campaignsService: CampaignsService,
        private sliderService: SliderService,
        public translationService: TranslationService
    ) {
        this.productSubject.pipe(debounceTime(300)).subscribe((data) => {
            if (data) {
                this.isLoadingProduct = true;
                this.getProducts(data);
            } else {
                this.products = [];
            }
        });
        this.categorySubject.pipe(debounceTime(300)).subscribe((data) => {
            if (data) {
                this.isLoadingCat = true;
                this.getCategories(data);
            } else {
                this.categories = [];
            }
        });
        this.brandSubject.pipe(debounceTime(300)).subscribe((data) => {
            if (data) {
                this.isLoadingBrand = true;
                this.getBrands(data);
            } else {
                this.brands = [];
            }
        });
    }

    async ngOnInit(): Promise<void> {
        if (this.id) {
            this.utilitiesService.startLoader();
        }
        await this.formInitialize();
        await this.getAudiences();
        await this.getPaymentMethods();
        if (this.id) {
            await this.getPriceDetails();
        }

        this.priceRuleForm.get('start_date').valueChanges.subscribe(startDateTime => {
            const expiryDateTime = this.priceRuleForm.get('expiry_date').value;
        
            // Check if both start_date and expiry_date have valid values
            if (startDateTime && expiryDateTime) {
                const startDate = new Date(startDateTime);
                const expiryDate = new Date(expiryDateTime);
        
                // Compare start date with expiry date
                if (startDate > expiryDate) {
                    // If start date is greater than expiry date, set expiry date to null
                    this.priceRuleForm.get('expiry_date').setValue(null);
                }
            }
        });
        
    }

    async getPaymentMethods() {
        try {
            const paymentMethods = await this.priceRuleService
                .getPaymentMethod()
                .toPromise();
            if (paymentMethods) {
                this.paymentMethods = paymentMethods;
            }
        } catch {
        } finally {
        }
    }

    async getCategories(search, ids: any = "") {
        try {
            const category = await this.sliderService
                .getCategory(search, ids)
                .toPromise();
            if (category) {
                this.isLoadingCat = false;
                this.categories = category.results;
            }
        } catch {
            this.isLoadingCat = false;
            this.utilitiesService.stopLoader();
        } finally {
        }
    }

    async getBrands(search, ids: any = "") {
        try {
            const brands = await this.sliderService
                .getBrand(search, ids)
                .toPromise();
            if (brands) {
                this.brands = brands.results;
                this.isLoadingBrand = false;
            }
        } catch {
            this.isLoadingBrand = false;
            this.utilitiesService.stopLoader();
        } finally {
        }
    }

    searchProducts(value) {
        this.productSubject.next(value);
    }

    searchCategories(value) {
        this.categorySubject.next(value);
    }

    searchBrands(value) {
        this.brandSubject.next(value);
    }

    async getProducts(search, id: any = "") {
        try {
            const products = await this.sliderService
                .getProducts(search, id)
                .toPromise();
            if (products) {
                this.isLoadingProduct = false;
                this.products = products.results;
            }
        } catch {
            this.isLoadingProduct = false;
            this.utilitiesService.stopLoader();
        } finally {
        }
    }

    async getAudiences() {
        try {
            const audienceList = await this.campaignsService
                .getAudience()
                .toPromise();
            if (audienceList) {
                this.audienceList = audienceList.results;
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }

    async formInitialize() {
        this.priceRuleForm = this.fb.group({
            code: [null, [Validators.required]],
            type: [1],
            discount: [0.0, [Validators.required]],

            discount_type: [1],
            delivery_discount: [0.0, [Validators.required]],
            delivery_discount_type: [1],
            usage_limit: [0, [Validators.required]],
            description: [null],
            customer_usage_limit: [0, [Validators.required]],

            usage_type: [2],
            start_date: [null, [Validators.required]],
            expiry_date: [null, [Validators.required]],
            minimum_amount: [0.0, [Validators.required]],
            maximum_amount: [0.0, [Validators.required]],
            audience: [null, [Validators.required]],
            product_ids: [null],
            payment_method_ids: [null],
            discount_on: [1],
            minimum_quantity: [1],
            restricted_category_ids: [null],
            restricted_product_ids: [null],
            brand_ids: [null],
            category_ids: [null],
            idSelect: [1],
            enable: [false],
            name: ["", [Validators.required]],
            include_selection: [true],
            exclusive: [true],
            apply_on_striked: [false],
            show_on_checkout: [false],
            discount_cap: [0.0],
            card_banks:[[]],
            card_brands :  [[]],
            card_schemes : [[]] 
        });
        this.priceRuleForm.controls.discount_on.valueChanges.subscribe(
            async (data) => {
                if (data == 2 || data == 4 || data == 5) {
                    this.priceRuleForm.controls.discount.clearValidators();
                    this.priceRuleForm.controls.discount.updateValueAndValidity();
                    this.priceRuleForm.controls.delivery_discount.setValidators(
                        [Validators.required]
                    );
                    this.priceRuleForm.controls.delivery_discount.updateValueAndValidity();
                } else {
                    this.priceRuleForm.controls.discount.setValidators([
                        Validators.required,
                    ]);
                    this.priceRuleForm.controls.discount.updateValueAndValidity();
                    this.priceRuleForm.controls.delivery_discount.clearValidators();
                    this.priceRuleForm.controls.delivery_discount.updateValueAndValidity();
                }
            }
        );
        this.priceRuleForm.controls.type.valueChanges.subscribe(
            async (data) => {
                if (data == 1) {
                    this.priceRuleForm.controls.code.setValidators([
                        Validators.required,
                    ]);
                    this.priceRuleForm.controls.code.updateValueAndValidity();
                } else {
                    this.priceRuleForm.controls.code.clearValidators();
                    this.priceRuleForm.controls.code.updateValueAndValidity();
                }
            }
        );
        this.priceRuleForm.controls.discount_on.setValue(1);
        this.priceRuleForm.controls.code.setValue("");
        this.priceRuleForm.controls.usage_type.valueChanges.subscribe(
            async (data) => {
                await this.setValidators(data);
            }
        );
    }

    async removeUnselectValues(selectedID) {
        console.log(selectedID)
        switch (selectedID) {
            // product
            case 1: {
                this.priceRuleForm.controls.brand_ids.setValue(null);
                this.priceRuleForm.controls.category_ids.setValue(null);
                this.priceRuleForm.controls.payment_method_ids.setValue(null);
                this.priceRuleForm.controls.card_banks.setValue([]);
                this.priceRuleForm.controls.card_schemes.setValue([]);
                this.priceRuleForm.controls.card_brands.setValue([]);

                break;
            }
            // brand
            case 2: {
                this.priceRuleForm.controls.product_ids.setValue(null);
                this.priceRuleForm.controls.category_ids.setValue(null);
                this.priceRuleForm.controls.payment_method_ids.setValue(null);
                this.priceRuleForm.controls.card_banks.setValue([]);
                this.priceRuleForm.controls.card_schemes.setValue([]);
                this.priceRuleForm.controls.card_brands.setValue([]);

                break;
            }
            // category
            case 3: {
                this.priceRuleForm.controls.product_ids.setValue(null);
                this.priceRuleForm.controls.brand_ids.setValue(null);
                this.priceRuleForm.controls.payment_method_ids.setValue(null);
                this.priceRuleForm.controls.card_banks.setValue([]);
                this.priceRuleForm.controls.card_schemes.setValue([]);
                this.priceRuleForm.controls.card_brands.setValue([]);

                break;
            }

            case 4: {
                this.priceRuleForm.controls.product_ids.setValue(null);
                this.priceRuleForm.controls.brand_ids.setValue(null);
                this.priceRuleForm.controls.category_ids.setValue(null);
                this.priceRuleForm.controls.payment_method_ids.setValue(null);
                this.priceRuleForm.controls.card_banks.setValue([]);
                this.priceRuleForm.controls.card_schemes.setValue([]);
                this.priceRuleForm.controls.card_brands.setValue([]);

                break;
            }
            // payment method
            case 5: {
                this.priceRuleForm.controls.product_ids.setValue(null);
                this.priceRuleForm.controls.brand_ids.setValue(null);
                this.priceRuleForm.controls.category_ids.setValue(null);  
                this.priceRuleForm.controls.card_schemes.setValue([]);
                this.priceRuleForm.controls.card_brands.setValue([]);
                this.priceRuleForm.controls.card_banks.setValue([]);
                this.priceRuleForm.controls.card_schemes.setValue([]);
                this.priceRuleForm.controls.card_brands.setValue([]);

                break;
            }

               // bank card
               case 6: {
                this.priceRuleForm.controls.product_ids.setValue(null);
                this.priceRuleForm.controls.brand_ids.setValue(null);
                this.priceRuleForm.controls.category_ids.setValue(null);
                this.priceRuleForm.controls.payment_method_ids.setValue(null);
                break;
            }
        }
    }

    async setValidators(data) {
        if (data == 1) {
            this.priceRuleForm.controls.usage_limit.setValidators([
                Validators.required,
            ]);
            this.priceRuleForm.controls.usage_limit.updateValueAndValidity();
        } else {
            this.priceRuleForm.controls.usage_limit.clearValidators();
            this.priceRuleForm.controls.usage_limit.updateValueAndValidity();
        }
    }

    async getPriceDetails() {
        try {
            const priceRulesDetail = await this.priceRuleService
                .getPriceRulesDetails(this.id)
                .toPromise();
            if (priceRulesDetail) {
                this.priceRulesDetail = priceRulesDetail;
                await this.setValue();
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }

    handleCancel() {
        this.cancelClick.emit();
    }

    async saveOrUpdate() {
        if (this.priceRuleForm.valid) {
            console.log(this.priceRuleForm.value);

            await this.removeUnselectValues(this.priceRuleForm.value.idSelect);
            let form = this.priceRuleForm.value;
            console.log(form)
            console.log(form);
            if (form.product_ids && form.idSelect == 1) {
                form.product_ids = form.product_ids.toString();
            }

            if (form.brand_ids && form.idSelect == 2) {
                form.brand_ids = form.brand_ids.toString();
            }

            if (form.category_ids && form.idSelect == 3) {
                form.category_ids = form.category_ids.toString();
            }
            if (form.restricted_category_ids) {
                form.restricted_category_ids =
                    form.restricted_category_ids.toString();
            }

            if (form.restricted_product_ids) {
                form.restricted_product_ids =
                    form.restricted_product_ids.toString();
            }

            if (form.payment_method_ids && form.idSelect == 5) {
                form.payment_method_ids = form.payment_method_ids.toString();
            }
            // console.log("product ids " + form.product_ids);
            // console.log("brand_ids " + form.brand_ids);
            // console.log("category_ids " + form.category_ids);
            // console.log("payment_method_ids " + form.payment_method_ids);
            // form.start_date = moment(form.start_date).format("YYYY-MM-DD");
            // form.expiry_date = moment(form.expiry_date).format("YYYY-MM-DD");
            if (form.usage_type != 1) {
                form.usage_limit = 0;
            }
            if (!form.discount) {
                form.discount = 0.0;
            }
            if (!form.delivery_discount) {
                form.delivery_discount = 0.0;
            }
            if (form.idSelect == 4) {
                form.minimum_quantity = 0.0;
            }
            if (!this.id) {
                try {
                    let content = `Are you sure, Do you want to save this price rule ? `;
                    let heading = "Save";
                    let fromApp = false;
                    let isMobile = this.utilitiesService.isMobile();
                    let size = {
                        width: "25vw",
                        height: "20vh",
                    };
                    if (isMobile) {
                        size.height = "100%";
                        size.width = "100%";
                    }
                    const dialogRef = this.dialog.open(AlertModalComponent, {
                        data: { content, heading, fromApp },
                        maxWidth: "",
                        width: `${size.width}`,
                        height: `${size.height}`,
                    });
                    dialogRef.afterClosed().subscribe(async (resp) => {
                        if (resp) {
                            this.utilitiesService.startLoader();
                            this.priceRuleService
                                .addPriceRule(form)
                                .subscribe((data) => {
                                    let successMsg = this.translate.instant(
                                        "Price Rule created successfully"
                                    );
                                    this.utilitiesService.showSuccessToast(
                                        successMsg
                                    );
                                    this.createPriceRule.emit();
                                });
                        }
                    });
                } catch {
                    this.utilitiesService.stopLoader();
                } finally {
                }
            } else if (this.id) {
                try {
                    let content = `Are you sure, Do you want to update this price rule ? `;
                    let heading = "Update";
                    let fromApp = false;
                    let isMobile = this.utilitiesService.isMobile();
                    let size = {
                        width: "25vw",
                        height: "20vh",
                    };
                    if (isMobile) {
                        size.height = "100%";
                        size.width = "100%";
                    }
                    const dialogRef = this.dialog.open(AlertModalComponent, {
                        data: { content, heading, fromApp },
                        maxWidth: "",
                        width: `${size.width}`,
                        height: `${size.height}`,
                    });
                    dialogRef.afterClosed().subscribe(async (resp) => {
                        if (resp) {
                            this.utilitiesService.startLoader();
                            this.priceRuleService
                                .updatePriceRule(form, this.id)
                                .subscribe((data) => {
                                    let successMsg = this.translate.instant(
                                        "Price Rule updated successfully"
                                    );
                                    this.utilitiesService.showSuccessToast(
                                        successMsg
                                    );
                                    this.createPriceRule.emit();
                                });
                        }
                    });
                } catch {
                    this.utilitiesService.stopLoader();
                } finally {
                }
            }
        } else {
            let errorMsg = this.translate.instant("Form is not valid");
            this.utilitiesService.showErrorToast(errorMsg);
        }
    }

    async setValue() {
        try {
            this.priceRuleForm
                .get("show_on_checkout")
                .setValue(this.priceRulesDetail.show_on_checkout);
            this.priceRuleForm
                .get("apply_on_striked")
                .setValue(this.priceRulesDetail.apply_on_striked);
            this.priceRuleForm.get("code").setValue(this.priceRulesDetail.code);
            this.priceRuleForm.get("type").setValue(this.priceRulesDetail.type);
            this.priceRuleForm
                .get("discount")
                .setValue(this.priceRulesDetail.discount);
            this.priceRuleForm
                .get("discount_type")
                .setValue(this.priceRulesDetail.discount_type);
            this.priceRuleForm
                .get("delivery_discount")
                .setValue(this.priceRulesDetail.delivery_discount);
            this.priceRuleForm
                .get("delivery_discount_type")
                .setValue(this.priceRulesDetail.delivery_discount_type);
            this.priceRuleForm
                .get("usage_limit")
                .setValue(this.priceRulesDetail.usage_limit);
            this.priceRuleForm
                .get("usage_type")
                .setValue(this.priceRulesDetail.usage_type);
            this.priceRuleForm
                .get("start_date")
                .setValue(this.priceRulesDetail.start_date);
            this.priceRuleForm
                .get("expiry_date")
                .setValue(this.priceRulesDetail.expiry_date);
            this.priceRuleForm
                .get("minimum_amount")
                .setValue(this.priceRulesDetail.minimum_amount);
            this.priceRuleForm
                .get("maximum_amount")
                .setValue(this.priceRulesDetail.maximum_amount);
            this.priceRuleForm
                .get("audience")
                .setValue(this.priceRulesDetail.audience);
            this.priceRuleForm
                .get("discount_on")
                .setValue(this.priceRulesDetail.discount_on);
            this.priceRuleForm
                .get("enable")
                .setValue(this.priceRulesDetail.enable);
            this.priceRuleForm
                .get("minimum_quantity")
                .setValue(this.priceRulesDetail.minimum_quantity);

            this.priceRuleForm.get("name").setValue(this.priceRulesDetail.name);
            this.priceRuleForm
                .get("description")
                .setValue(this.priceRulesDetail.description);
            this.priceRuleForm
                .get("customer_usage_limit")
                .setValue(this.priceRulesDetail.customer_usage_limit);
            this.priceRuleForm
                .get("include_selection")
                .setValue(this.priceRulesDetail?.include_selection);
            this.priceRuleForm
                .get("exclusive")
                .setValue(this.priceRulesDetail?.exclusive);
            this.priceRuleForm
                .get("discount_cap")
                .setValue(this.priceRulesDetail?.discount_cap);
            if (this.priceRulesDetail.restricted_category_ids) {
                // this.priceRuleForm.get("idSelect").setValue(3);
                let restricted_category_IDS = JSON.parse(
                    "[" + this.priceRulesDetail.restricted_category_ids + "]"
                );
                await this.getCategories("", restricted_category_IDS);
                this.priceRuleForm
                    .get("restricted_category_ids")
                    .setValue(restricted_category_IDS);
            }
            if (this.priceRulesDetail.restricted_product_ids) {
                // this.priceRuleForm.get("idSelect").setValue(3);
                let restricted_product_IDS = JSON.parse(
                    "[" + this.priceRulesDetail.restricted_product_ids + "]"
                );
                await this.getProducts("", restricted_product_IDS);
                this.priceRuleForm
                    .get("restricted_product_ids")
                    .setValue(restricted_product_IDS);
            }
            this.priceRuleForm.get("include_selection").setValue(this.priceRulesDetail?.include_selection);
            this.priceRuleForm.get("exclusive").setValue(this.priceRulesDetail?.exclusive);
            this.priceRuleForm.get("customer_usage_limit").setValue(this.priceRulesDetail?.customer_usage_limit);
            this.priceRuleForm.get("discount_cap").setValue(this.priceRulesDetail?.discount_cap);

            console.log('fdxfdvd',this.priceRulesDetail.card_banks?.length ||
                this.priceRulesDetail.card_schemes?.length ||
                this.priceRulesDetail.card_brands?.length)
            if (this.priceRulesDetail.product_ids) {
                this.priceRuleForm.get("idSelect").setValue(1);
                let productIDs = JSON.parse(
                    "[" + this.priceRulesDetail.product_ids + "]"
                );
                await this.getProducts("", productIDs);
                this.priceRuleForm.get("product_ids").setValue(productIDs);
            } else if (this.priceRulesDetail.brand_ids) {
                this.priceRuleForm.get("idSelect").setValue(2);
                let brandIDs = JSON.parse(
                    "[" + this.priceRulesDetail.brand_ids + "]"
                );
                await this.getBrands("", brandIDs);
                this.priceRuleForm.get("brand_ids").setValue(brandIDs);
            } else if (this.priceRulesDetail.category_ids) {
                this.priceRuleForm.get("idSelect").setValue(3);
                let categoryIDs = JSON.parse(
                    "[" + this.priceRulesDetail.category_ids + "]"
                );
                await this.getCategories("", categoryIDs);
                this.priceRuleForm.get("category_ids").setValue(categoryIDs);
            } else if (this.priceRulesDetail.payment_method_ids) {
                this.priceRuleForm.get("idSelect").setValue(5);
                let payment_method_ids = JSON.parse(
                    "[" + this.priceRulesDetail.payment_method_ids + "]"
                );
                this.priceRuleForm
                    .get("payment_method_ids")
                    .setValue(payment_method_ids);
            }
            else if (this.priceRulesDetail.card_banks?.length ||
                     this.priceRulesDetail.card_schemes?.length ||
                     this.priceRulesDetail.card_brands?.length
                ) {
                this.priceRuleForm.get("idSelect").setValue(6);
                let card_banks = JSON.parse(
                    "[" + this.priceRulesDetail.card_banks + "]"
                );
                let card_schemes = JSON.parse(
                    "[" + this.priceRulesDetail.card_schemes + "]"
                );
                let card_brands = JSON.parse(
                    "[" + this.priceRulesDetail.card_brands + "]"
                );
                this.priceRuleForm
                    .get("card_banks")
                    .setValue(card_banks);
                this.priceRuleForm
                    .get("card_schemes")
                    .setValue(card_schemes);
                this.priceRuleForm
                    .get("card_brands")
                    .setValue(card_brands);
            }
            else {
                this.priceRuleForm.get("idSelect").setValue(4);
            }
            this.setValidators(this.priceRulesDetail.usage_typ);
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }

    selectAll(
        controlName: string,
        items: Array<any>,
        from: string,
        section: string
    ) {
        let values = [];
        let selectedItems = [];

        if (items.length > 0) {
            items.forEach((item) => {
                values.push(item?.id);
                selectedItems.push({ id: item?.id, from });
            });

            switch (section) {
                case ConditionsSections.Products:
                    this.includeProducts.forEach((el) => {
                        let selectedItem = selectedItems.filter(
                            (element) => el.from !== element.from
                        )[0];
                        let valueItem = values.filter(
                            (element) => el.id !== element
                        )[0];
                        if (el.from !== from) {
                            selectedItems.splice(
                                selectedItems.indexOf(selectedItem),
                                1
                            );
                            values.splice(values.indexOf(valueItem), 1);
                        }
                    });
                    this.includeProducts = [
                        ...this.includeProducts,
                        ...selectedItems,
                    ];

                    break;

                case ConditionsSections.Brands:
                    this.includeBrands.forEach((el) => {
                        let selectedItem = selectedItems.filter(
                            (element) => el.from !== element.from
                        )[0];
                        let valueItem = values.filter(
                            (element) => el.id !== element
                        )[0];
                        if (el.from !== from) {
                            selectedItems.splice(
                                selectedItems.indexOf(selectedItem),
                                1
                            );
                            values.splice(values.indexOf(valueItem), 1);
                        }
                    });
                    this.includeBrands = [
                        ...this.includeBrands,
                        ...selectedItems,
                    ];

                    break;

                case ConditionsSections.Categories:
                    this.includeCategories.forEach((el) => {
                        let selectedItem = selectedItems.filter(
                            (element) => el.from !== element.from
                        )[0];
                        let valueItem = values.filter(
                            (element) => el.id !== element
                        )[0];
                        if (el.from !== from) {
                            selectedItems.splice(
                                selectedItems.indexOf(selectedItem),
                                1
                            );
                            values.splice(values.indexOf(valueItem), 1);
                        }
                    });

                    this.includeCategories = [
                        ...this.includeCategories,
                        ...selectedItems,
                    ];

                    break;

                case ConditionsSections.PaymentMethods:
                    this.includePaymentMethods.forEach((el) => {
                        let selectedItem = selectedItems.filter(
                            (element) => el.from !== element.from
                        )[0];
                        let valueItem = values.filter(
                            (element) => el.id !== element
                        )[0];
                        if (el.from !== from) {
                            selectedItems.splice(
                                selectedItems.indexOf(selectedItem),
                                1
                            );
                            values.splice(values.indexOf(valueItem), 1);
                        }
                    });
                    this.includePaymentMethods = [
                        ...this.includePaymentMethods,
                        ...selectedItems,
                    ];

                    break;
            }

            this.priceRuleForm.get(controlName).setValue(values);
            this.isSelectAll = true;
            this.isUnSelectAll = false;
        }
    }

    unselectAll(controlName: string, from: string, section: string) {
        this.priceRuleForm.get(controlName).setValue([]);
        let newArr = [];

        switch (section) {
            case ConditionsSections.Products:
                this.includeProducts.forEach((el) => {
                    if (el.from !== from) newArr.push(el);
                });
                this.includeProducts = [...newArr];
                break;

            case ConditionsSections.Brands:
                this.includeBrands.forEach((el) => {
                    if (el.from !== from) newArr.push(el);
                });
                this.includeBrands = [...newArr];
                break;

            case ConditionsSections.Categories:
                this.includeCategories.forEach((el) => {
                    if (el.from !== from) newArr.push(el);
                });
                this.includeCategories = [...newArr];
                break;

            case ConditionsSections.PaymentMethods:
                this.includePaymentMethods.forEach((el) => {
                    if (el.from !== from) newArr.push(el);
                });
                this.includePaymentMethods = [...newArr];
                break;
        }

        this.isUnSelectAll = true;
        this.isSelectAll = false;
    }

    addItemToList(e, from, section) {
        let isSelected;

        if (e) {
            this.isUnSelectAll = false;

            switch (section) {
                case ConditionsSections.Products:
                    isSelected = this.includeProducts.some((el) => e === el);
                    if (!isSelected) this.includeProducts.push({ id: e, from });
                    break;

                case ConditionsSections.Brands:
                    isSelected = this.includeBrands.some((el) => e === el);
                    if (!isSelected) this.includeBrands.push({ id: e, from });
                    break;

                case ConditionsSections.Categories:
                    isSelected = this.includeCategories.some((el) => e === el);
                    if (!isSelected)
                        this.includeCategories.push({ id: e, from });
                    break;

                case ConditionsSections.PaymentMethods:
                    isSelected = this.includePaymentMethods.some(
                        (el) => e === el
                    );
                    if (!isSelected)
                        this.includePaymentMethods.push({ id: e, from });
                    break;
            }
        }
    }

    removeItemFromList(e, section) {
        let isSelected;
        let element;

        if (e) {
            switch (section) {
                case ConditionsSections.Products:
                    isSelected = this.includeProducts.some(
                        (el) => e.value === el.id
                    );
                    element = this.includeProducts.filter(
                        (el) => e.value === el.id
                    );
                    if (isSelected)
                        this.includeProducts.splice(
                            this.includeProducts.indexOf(element[0]),
                            1
                        );
                    break;

                case ConditionsSections.Brands:
                    isSelected = this.includeBrands.some(
                        (el) => e.value === el.id
                    );
                    element = this.includeBrands.filter(
                        (el) => e.value === el.id
                    );
                    if (isSelected)
                        this.includeBrands.splice(
                            this.includeBrands.indexOf(element[0]),
                            1
                        );
                    break;

                case ConditionsSections.Categories:
                    isSelected = this.includeCategories.some(
                        (el) => e.value === el.id
                    );
                    element = this.includeCategories.filter(
                        (el) => e.value === el.id
                    );
                    if (isSelected)
                        this.includeCategories.splice(
                            this.includeCategories.indexOf(element[0]),
                            1
                        );
                    break;

                case ConditionsSections.PaymentMethods:
                    isSelected = this.includePaymentMethods.some(
                        (el) => e.value === el.id
                    );
                    element = this.includePaymentMethods.filter(
                        (el) => e.value === el.id
                    );
                    if (isSelected)
                        this.includePaymentMethods.splice(
                            this.includePaymentMethods.indexOf(element[0]),
                            1
                        );
                    break;
            }

            this.isSelectAll = false;
        }
    }

    checkIfAvailabe(id, section) {
        let item;
        switch (section) {
            case ConditionsSections.Products:
                item = this.includeProducts.filter((el) => id === el.id);
                return {
                    isSelected: this.includeProducts.some((el) => id === el.id),
                    selectedFrom: item[0]?.from,
                };
            case ConditionsSections.Brands:
                item = this.includeBrands.filter((el) => id === el.id);
                return {
                    isSelected: this.includeBrands.some((el) => id === el.id),
                    selectedFrom: item[0]?.from,
                };
            case ConditionsSections.Categories:
                item = this.includeCategories.filter((el) => id === el.id);
                return {
                    isSelected: this.includeCategories.some(
                        (el) => id === el.id
                    ),
                    selectedFrom: item[0]?.from,
                };
            case ConditionsSections.PaymentMethods:
                item = this.includePaymentMethods.filter((el) => id === el.id);
                return {
                    isSelected: this.includePaymentMethods.some(
                        (el) => id === el.id
                    ),
                    selectedFrom: item[0]?.from,
                };
        }
    }

    includeSelection(e: MatRadioChange) {
        console.log(e);
        this.priceRuleForm.controls.include_selection.setValue(e.value);
    }
}
