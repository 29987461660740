import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UtilitiesService } from "app/services/utilitiesService";
import { DomSanitizer } from "@angular/platform-browser";
import { MediaTypes } from "app/constants/media-type";

@Component({
    selector: "app-document-viewer",
    templateUrl: "./document-viewer.component.html",
    styleUrls: ["./document-viewer.component.scss"],
})
export class DocumentViewerComponent implements OnInit {
    url: any = "";
    constructor(
        public dialogRef: MatDialogRef<DocumentViewerComponent>,
        public utilitiesService: UtilitiesService,
        private domSanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.url = data.file || null;
    }

    ngOnInit(): void {}
}
