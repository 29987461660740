import { HttpParams } from "@angular/common/http";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormControlName, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DynamicFieldTypes } from "app/main/types/dynamicField";
import { ApiService } from "app/services/api.service";
import { SliderService } from "app/services/sliderService";
import { UtilitiesService } from "app/services/utilitiesService";
import { BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { CreateStickerComponent } from "../create-sticker/create-sticker.component";
import { CreateTagComponent } from "../create-tag/create-tag.component";
import { CreateOriginComponent } from "../create-origin/create-origin.component";
import { FullscreenAdditionCssComponent } from "../fullscreen-addition-css/fullscreen-addition-css.component";
import { fuseAnimations } from "@fuse/animations";
import { CreateHandlingInstructionsComponent } from "../create-handling-instructions/create-handling-instructions.component";
import { ChangeUserPasswordPopoverComponent } from "app/main/components/change-user-password-popover/change-user-password-popover.component";
@Component({
  selector: "app-form-fields",
  templateUrl: "./form-fields.component.html",
  styleUrls: ["./form-fields.component.scss"],
  animations: fuseAnimations,
})
export class FormFieldsComponent implements OnInit {
  dynamicFieldTypes = DynamicFieldTypes;
  searchedKey$: BehaviorSubject<any> = new BehaviorSubject({});
  isLoading: boolean = false;
  products: any;
  @Input() formData: any;
  @Input() id: any;
  @Output() emitFormValue: EventEmitter<any> = new EventEmitter<any>();
  @Input() customFormGroup: FormGroup;
  form: FormGroup;
  ckeConfig: any;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private sliderService: SliderService,
    private utilitiesService: UtilitiesService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.ckeConfig = {
      sanitize: false,
      allowedContent: true,
      versionCheck: false,
      removeButtons: "CreateDiv,BlockQuote,Superscript,Subscript,PasteFromWord,PasteText,Source",
    };
    this.appendPossibleValues();
    // this.customFormGroup.get('composition').valueChanges.subscribe(res => {
    //   if (res === 2) {
    //     this.formData[1].show = false
    //   } else {
    //     this.formData[1].show = true
    //   }
    // })

    this.searchedKey$.pipe(debounceTime(300), distinctUntilChanged()).subscribe(async (val) => {
      if (val && val?.searchKey !== "" && val?.api) {
        try {
          const data = await this.sliderService.getSearchedData(val).toPromise();
          if (data) {
            this.isLoading = false;

            val.field.possible_values = data?.results ? data?.results : data;
          }
        } catch {
          this.isLoading = false;
          this.utilitiesService.stopLoader();
        } finally {
        }
      }
    });
  }

  validator(control) {
    try {
      if (this.customFormGroup.get(control)?.validator) {
        const validator = this.customFormGroup.get(control).validator({} as AbstractControl);
        if (validator && validator.required) {
          return true;
        }
      }
    } catch (ex) {}
    return false;
  }

  appendPossibleValues() {
    this.formData.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.type === this.dynamicFieldTypes.SearchableSelect) {
          let ids = this.customFormGroup.get(field.name).value || "";
          if (field.api_endpoint) {
            let params = new HttpParams();
            let limits = 9;
            let id = "";
            params = params.set("limit", limits.toString()).set("ids", ids.toString());
            this.apiService.get(field.api_endpoint, params, undefined, false).subscribe((response) => {
              field.possible_values = response?.results ? response?.results : response;
            });
          }
        }
      });
    });
  }

  async inputValue(value, api, field) {
    const obj = {
      api: api,
      searchKey: value,
      field: field,
    };
    this.searchedKey$.next(obj);
  }

  addData(key: string) {
    let componentType: any;
    if (key === "stickers") {
      componentType = CreateStickerComponent;
    } else if (key === "tags") {
      componentType = CreateTagComponent;
    } else if (key === "country_of_origin") {
      componentType = CreateOriginComponent;
    } else if (key === "handling_instructions") {
      componentType = CreateHandlingInstructionsComponent;
    } else if (key === "password") {
      componentType = ChangeUserPasswordPopoverComponent;
      // let userId = this.customFormGroup.get('user').value;
    } else {
      return; // Invalid key, do nothing
    }

    if (key === "password") {
      let userID = this.customFormGroup.get("user")?.value;
      let username = this.customFormGroup.get("first_name").value + " " + this.customFormGroup.get("last_name").value;
      console.log(userID);
      const dialogRef = this.dialog.open(componentType, {
        data: {
          username,
          userID,
        },
        maxWidth: "",
        width: "70vw",
        height: "auto",
      });
      dialogRef.afterClosed().subscribe((res) => {
        console.log(res);
      });
    } else {
      const dialogRef = this.dialog.open(componentType, {
        maxWidth: "",
        width: "70vw",
        height: "auto",
      });
      dialogRef.afterClosed().subscribe((res) => {
        console.log(res);
      });
    }
  }
  checkDisabled(name) {
    console.log(this.customFormGroup.get("id").value);
    return this.customFormGroup.get("id")?.value ? true : false;
  }
  zoomAdditionalCss(field) {
    let heading = "HTML";
    const formControl = this.customFormGroup.get(field.name);

    const dialogRef = this.dialog.open(FullscreenAdditionCssComponent, {
      data: { formControl, heading },
      maxWidth: "",
      width: "100%",
      height: "100%",
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        formControl.setValue(resp.value);
      }
    });
  }

  getFormControl(field) {
    const formControlName = `${field}_control`;
    return formControlName;
  }
}
