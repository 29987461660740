<div
    fxFlex="1 1 100%"
    fxLayoutAlign="start stretch"
    fxLayout="column"
    fxLayoutGap="1em"
>
    <div
        fxFlex="1 1 auto"
        fxLayoutAlign="space-between center"
        fxLayout="row"
        fxLayoutGap="1em"
    >
        <strong class="highlight-text-color">{{
            "Top Up /Deduct Wallet" | translate
        }}</strong>
        <mat-icon (click)="close()" class="cursor-pointer">close</mat-icon>
    </div>

    <form
        fxFlex="1 1 100%"
        fxLayoutAlign="start stretch"
        fxLayout.lt-md="column"
        fxLayout.gt-sm="row"
        fxLayoutGap="1em"
        [formGroup]="form"
    >
        <div
            fxFlex="1 1 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="1em"
        >
            <div
                fxFlex="0 0 auto"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="0px"
            >
                <mat-spinner
                    diameter="50"
                    style="left: 47%; top: 50%; position: relative"
                    *ngIf="loading"
                    strokeWidth="5"
                >
                </mat-spinner>
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="0px"
                >
                    <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout.gt-sm="row"
                        fxLayout.lt-md="column"
                        fxLayoutGap="1em"
                    >
                        <fieldset fxFlex="100%">
                            <div class="toggle">
                                <input
                                    type="radio"
                                    formControlName="type"
                                    [value]="1"
                                    id="Top_up"
                                />
                                <label for="Top_up">{{
                                    "Top up" | translate
                                }}</label>
                                <input
                                    type="radio"
                                    formControlName="type"
                                    [value]="2"
                                    id="Deduct"
                                />
                                <label for="Deduct">{{
                                    "Deduct" | translate
                                }}</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div
                    fxFlex="0 0  auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="1em"
                >
                    <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="0px"
                    >
                        <div
                            fxFlex="0 0 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout.lt-md="column"
                            fxLayout.gt-sm="row"
                            fxLayoutGap.gt-sm="5px"
                            fxLayoutGap.lt-md="0px"
                        >
                            <!-- amount -->
                            <mat-form-field appearance="outline" fxFlex="100%">
                                <mat-label>{{
                                    "Amount" | translate
                                }}</mat-label>
                                <input
                                    formControlName="amount"
                                    type="number"
                                    matInput
                                    name="title"
                                />
                                <mat-error
                                    *ngIf="
                                        form.get('amount').hasError('required')
                                    "
                                >
                                    {{ "Amounts is required" | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- remark -->
                    <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="0px"
                    >
                        <div
                            fxFlex="0 0 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout.lt-md="column"
                            fxLayout.gt-sm="row"
                            fxLayoutGap.gt-sm="5px"
                            fxLayoutGap.lt-md="0px"
                        >
                            <mat-form-field appearance="outline" fxFlex="100%">
                                <mat-label>{{
                                    "Remarks" | translate
                                }}</mat-label>
                                <input
                                    formControlName="remarks"
                                    type="text"
                                    matInput
                                    name="title"
                                />
                                <mat-error
                                    *ngIf="
                                        form.get('remarks').hasError('required')
                                    "
                                >
                                    {{ "Remarks is required" | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div
                        fxFlex="1 1 auto"
                        fxLayoutAlign="flex-end stretch"
                        fxLayout="row"
                        fxLayoutGap="1em"
                    >
                        <button
                            [mat-dialog-close]="false"
                            mat-stroked-button
                            color="warn"
                        >
                            <mat-icon class="save-cancel-btn" matPrefix
                                >close</mat-icon
                            >
                            {{ "Close" | translate }}
                        </button>
                        <button
                            (click)="send()"
                            type="submit"
                            mat-flat-button
                            class="primary-bg-color"
                        >
                            <mat-icon class="save-cancel-btn mr-8" matPrefix
                                >send</mat-icon
                            >{{ "Save" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
