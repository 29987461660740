import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { PageService } from "app/services/pageService";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";

@Component({
    selector: "app-catalog-settings",
    templateUrl: "./catalog-settings.component.html",
    styleUrls: ["./catalog-settings.component.scss"],
    animations: fuseAnimations,
})
export class CatalogSettingsComponent implements OnInit {
    @Input() selectedBlock: any;
    @Input() appDetail: any;
    @Output() back: EventEmitter<void> = new EventEmitter<any>();
    @Output() saveHander: EventEmitter<void> = new EventEmitter<any>();
    text: any;
    pages = [];
    form: FormGroup;
    loading: boolean = true;
    socialShareForm: FormGroup = this.fb.group({});

    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private appService: AppService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        private pageService: PageService
    ) { }

    async ngOnInit(): Promise<void> {
        console.log('hiiii')
        this.utilitiesService.startLoader();
        await this.formInitialize();

        await this.getPages();
        if (this.appDetail) {
            await this.init();
        }
        this.utilitiesService.stopLoader();
    }
    async getPages() {
        try {
            const pages = await this.pageService.getPages().toPromise();
            if (pages) {
                this.pages = pages.results;
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
            this.utilitiesService.stopLoader();
            this.loading = false;
        }
    }
    async formInitialize() {

        this.form = this.fb.group({
            is_home_page: this.appDetail ? this.appDetail.is_home_page : false,
            home_page: null,
            page_size_limit: [
                this.appDetail ? this.appDetail.page_size_limit : 24,
                [Validators.compose([Validators.min(4), Validators.required])],
            ],
            elastic_search: [
                this.appDetail ? this.appDetail.elastic_search : false,
            ],
            account_uid: [this.appDetail ? this.appDetail.account_uid : null],
            brand_filter_layout: [
                this.appDetail ? this.appDetail.brand_filter_layout : 1,
            ],
            category_filter_layout: [
                this.appDetail ? this.appDetail.category_filter_layout : 2,
            ],
            default_page: [this.appDetail ? this.appDetail.default_page : 1],
            custom_home_page: [
                this.appDetail ? this.appDetail.custom_home_page : null,
            ],
            categories_filter: [
                this.appDetail ? this.appDetail.categories_filter : false,
            ],
            enable_product_page: [
                this.appDetail ? this.appDetail.enable_product_page : false,
            ],
            allow_rating: [
                this.appDetail ? this.appDetail.allow_rating : false,
            ],
            approve_ratings_by_default: [
                this.appDetail ? this.appDetail.approve_ratings_by_default : false,
            ],
            brand_filter: [
                this.appDetail ? this.appDetail.brand_filter : false,
            ],
            price_filter: [
                this.appDetail ? this.appDetail.price_filter : false,
            ],
            sub_category: [
                this.appDetail ? this.appDetail.sub_category : false,
            ],
            search_filter: [
                this.appDetail
                    ? this.appDetail.search_filter
                        ? this.appDetail.search_filter
                        : 1
                    : 1,
            ],

            is_gift_enabled: [this.appDetail.is_gift_enabled || false],
        });


        const socialOptions = ['copy', 'facebook', 'whatsapp', 'twitter'];

        socialOptions.forEach((key) => {
            if(this.appDetail?.social_share_options && this.appDetail?.social_share_options[key]){
                this.socialShareForm.addControl(
                    key,
                    this.fb.control(this.appDetail?.social_share_options[key]) || this.fb.control(true)
                );
            }
        });
    }

    async init() {
        if (this.appDetail && this.appDetail.home_page) {
            this.pages.forEach((data) => {
                if (
                    data.title == this.appDetail.home_page ||
                    data.slug == this.appDetail.home_page ||
                    data.title_en == this.appDetail.home_page
                ) {
                    this.form.get("home_page").setValue(data.id);
                }
            });
        }
        this.form.valueChanges.subscribe((data) => {
            this.utilitiesService.formChangedOrNot(true);
        });

        this.socialShareForm.valueChanges.subscribe((data) => {
            this.utilitiesService.formChangedOrNot(true);
        });
    }

    save() {
        if (this.form.valid) {
            let content = `Are you sure, Do you want to update? `;
            let heading = "Update";
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                this.utilitiesService.autoSave.next(0);
                if (resp) {
                    let form = this.form.value;
                    let shareOptionForm = {
                        social_share_options: this.socialShareForm.value,
                    };
                    form.title_en = form.title;
                    form.meta_description_en = form.meta_description;

                    let finalForm = { ...form, ...shareOptionForm };

                    const resp = await this.appService
                        .addAppSettings(finalForm)
                        .toPromise();

                    if (resp) {
                        this.utilitiesService.formChangedOrNot(false);
                        let successMsg = this.translate.instant(
                            "Catalog Settings updated successfully"
                        );
                        this.utilitiesService.showSuccessToast(successMsg);
                        this.saveHander.emit();
                    }
                }
            });
        }
    }
    goBack() {
        let fromApp = true;
        if (!this.utilitiesService.formChanged$.value) {
            this.back.emit();
        } else {
            let content = `You have unsaved changes, Please save before changing the page`;
            let heading = "Alert";
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp == "yes") {
                } else if (resp == "no") {
                    this.utilitiesService.formChangedOrNot(false);
                    this.back.emit();
                }
            });
        }
    }
}
