import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textFormat'
})
export class TextFormatPipe implements PipeTransform {

    transform(value: string, ...args: any[]): string {
        // @ts-ignore
        return value.replaceAll('_', ' ').charAt(0).toUpperCase() + value.replaceAll('_', ' ').slice(1);
    }

}
