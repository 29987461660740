<div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="start center" class="search-form form">
    <label>
        <input id="range-picker" [locale]="{format: 'DD/MM/YYYY'}" (datesUpdated)="datesUpdated($event)"
            [(ngModel)]="selectedRanges" class="search-field" type="search" ngxDaterangepickerMd
            [closeOnAutoApply]="true" [autoApply]="true" [showCustomRangeLabel]="true" [alwaysShowCalendars]="true"
            [ranges]="ranges" [linkedCalendars]="true" [isInvalidDate]="isInvalidDate" [showClearButton]="false"
            placeholder="Date.." />
    </label>
    <div class="search-submit button" (click)="openDatepicker()">
        <mat-icon class="cursor-pointer" matSuffix>date_range</mat-icon>
    </div>
</div>