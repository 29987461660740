<div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
  <form
    fxFlex="1 1 100%"
    fxLayoutAlign="start stretch"
    fxLayout.lt-md="column"
    fxLayout.gt-sm="row"
    fxLayoutGap="1em"
    [formGroup]="priceRuleForm">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
      <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
        <div
          fxFlex="0 0  auto"
          class="fuse-card auto-width p-16 center-container"
          fxLayoutAlign="start stretch"
          fxLayout="column"
          fxLayoutGap="1em">
          <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="16px">
            <div class="mb-0" fxFlex="0 0 auto" fxLayoutAlign="flex-end flex-end" fxLayout="column" fxLayoutGap="0px">
              <fieldset class="toggle-container" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em">
                <div class="toggle" [ngClass]="!priceRuleForm.get('enable').value ? 'disable' : ''">
                  <input type="radio" formControlName="enable" [value]="true" id="enable" />
                  <label for="enable">{{ "Enable" | translate }}</label>
                  <input type="radio" formControlName="enable" [value]="false" id="disable" />
                  <label for="disable">{{ "Disable" | translate }}</label>
                </div>
              </fieldset>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
              <fieldset fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                <legend class="highlight-text-color text-bold">
                  {{ "Information" | translate }}
                  <span class="highlight-text-color font-size-12" *ngIf="priceRuleForm.get('enable').value">
                    {{ " ( Price rule is" | translate }}
                    <strong>{{ "enabled )" | translate }}</strong>
                  </span>
                  <span class="disable-icon font-size-12" *ngIf="!priceRuleForm.get('enable').value">
                    {{ " ( Price rule is" | translate }}
                    <strong>{{ "disabled )" | translate }}</strong>
                  </span>
                </legend>
                <mat-form-field appearance="outline" fxFlex="100">
                  <mat-label class="text-capitalized">
                    {{ "Name" | translate }}
                  </mat-label>
                  <input formControlName="name" matInput name="title" />
                  <mat-error *ngIf="priceRuleForm.get('name').hasError('required')">
                    {{ "Name is required" | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="100">
                  <mat-label class="text-capitalized">
                    {{ "Description" | translate }}
                  </mat-label>
                  <input formControlName="description" matInput name="title" />
                </mat-form-field>
                <div
                  fxFlex="0 0 auto"
                  fxLayoutAlign="start stretch"
                  fxLayout.lt-md="column"
                  fxLayout.gt-sm="row"
                  fxLayoutGap.gt-sm="5px"
                  fxLayoutGap.lt-md="0px">
                  <mat-form-field appearance="outline" fxFlex.gt-sm="50%" fxFlex.lt-md="100%">
                    <mat-label>{{ "Type" | translate }}</mat-label>
                    <mat-select formControlName="type">
                      <mat-option [value]="1">{{ "Coupon Discount" | translate }}</mat-option>
                      <mat-option [value]="2">{{ "Cart Discount" | translate }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field *ngIf="priceRuleForm.get('type').value == '1'" appearance="outline" fxFlex="100">
                    <mat-label class="text-capitalized">
                      {{ "Code" | translate }}
                    </mat-label>
                    <input formControlName="code" matInput name="title" />
                    <mat-error *ngIf="priceRuleForm.get('code').hasError('required')">
                      {{ "Code is required" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div
                  *ngIf="priceRuleForm.get('type').value == '1'"
                  class="mb-12"
                  fxFlex="1 0 auto"
                  fxLayoutAlign="start start"
                  fxLayout="row"
                  fxLayoutGap="3px">
                  <mat-slide-toggle formControlName="show_on_checkout" class="apply_on_striked-price-rule"
                    >Show On Checkout
                  </mat-slide-toggle>
                  <!-- <p class="exclusive-content">
                                If enabled, once this discount is
                                applied on an order and no other
                                discounts will apply. Keep it disabled
                                if you want this discount to apply in
                                addition to other discounts.
                            </p> -->
                </div>
              </fieldset>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
              <fieldset fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                <legend class="highlight-text-color text-bold">
                  {{ "Validity" | translate }}
                </legend>
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="4px">
                  <label class="usage-label">{{ "Usage Type" | translate }}</label>
                  <div fxFlex="1 1 auto" fxLayoutGap="20px" fxLayoutAlign="start center">
                    <fieldset class="toggle-container" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                      <div class="toggle">
                        <input type="radio" formControlName="usage_type" [value]="2" id="unlimited" />
                        <label for="unlimited">{{ "Unlimited" | translate }}</label>
                        <input type="radio" formControlName="usage_type" [value]="1" id="limited" />
                        <label for="limited">{{ "Limited" | translate }}</label>
                      </div>
                    </fieldset>

                    <!-- <mat-radio-group fxFlex="1 1 auto" fxLayout="row" fxLayoutGap="1em"
                                            formControlName="usage_type">
                                            <mat-radio-button [value]="1">{{'Limited' | translate}}</mat-radio-button>
                                            <mat-radio-button [value]="2">{{'Unlimited' | translate}}</mat-radio-button>
                                        </mat-radio-group> -->
                  </div>
                </div>
                <div
                  fxFlex="0 0 auto"
                  fxLayoutAlign="start stretch"
                  fxLayout.lt-md="column"
                  fxLayout.gt-sm="row"
                  fxLayoutGap.gt-sm="5px"
                  fxLayoutGap.lt-md="0px"
                  *ngIf="priceRuleForm.get('usage_type').value == '1'">
                  <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label class="text-capitalized">
                      {{ "Usage Limit" | translate }}
                    </mat-label>
                    <input formControlName="usage_limit" type="number" matInput name="title" />
                    <mat-error *ngIf="priceRuleForm.get('usage_limit').hasError('required')">
                      {{ "Usage Limit is required" | translate }}
                    </mat-error>
                  </mat-form-field>
                  <!-- <mat-form-field appearance="outline" fxFlex="100"> // disable because it two time here
                                        <mat-label class="text-capitalized">
                                            {{
                                            "Customer Usage Limit"
                                            | translate
                                            }}
                                        </mat-label>
                                        <input formControlName="customer_usage_limit" type="number" matInput
                                            name="title" />
                                        <mat-error *ngIf="
                                                priceRuleForm
                                                    .get('customer_usage_limit')
                                                    .hasError('required')
                                            ">
                                            {{
                                            "Customr Usage Limit is required"
                                            | translate
                                            }}
                                        </mat-error>
                                    </mat-form-field> -->
                </div>
                <div
                  fxFlex="0 0 auto"
                  fxLayoutAlign="start stretch"
                  fxLayout.lt-md="column"
                  fxLayout.gt-sm="row"
                  fxLayoutGap.gt-sm="5px"
                  fxLayoutGap.lt-md="0px">
                  <!-- <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label>{{
                                            "Start Date" | translate
                                            }}</mat-label>
                                        <input formControlName="start_date" matInput [matDatepicker]="picker" />
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        <ngx-mat-datetime-picker #picker1></ngx-mat-datetime-picker>
                                        <mat-error *ngIf="
                                                priceRuleForm
                                                    .get('start_date')
                                                    .hasError('required')
                                            ">
                                            {{
                                            "Start Date is required"
                                            | translate
                                            }}
                                        </mat-error>
                                    </mat-form-field> -->

                  <mat-form-field appearance="outline" fxFlex="100">
                    <input matInput [ngxMatDatetimePicker]="picker1" placeholder="Choose a date" formControlName="start_date" />
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker1></ngx-mat-datetime-picker>
                    <mat-error *ngIf="priceRuleForm.get('start_date').hasError('required')">
                      {{ "Start Date is required" | translate }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline" fxFlex="100">
                    <input
                      matInput
                      [ngxMatDatetimePicker]="picker2"
                      placeholder="Choose a date"
                      [min]="priceRuleForm.get('start_date').value"
                      formControlName="expiry_date" />
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker2></ngx-mat-datetime-picker>
                    <mat-error *ngIf="priceRuleForm.get('expiry_date').hasError('required')">
                      {{ "Expiry Date is required" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div
                  fxFlex="0 0 auto"
                  fxLayoutAlign="start stretch"
                  fxLayout.lt-md="column"
                  fxLayout.gt-sm="row"
                  fxLayoutGap.gt-sm="5px"
                  fxLayoutGap.lt-md="0px">
                  <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label class="text-capitalized">
                      {{ "Minimum Cart Amount" | translate }}
                    </mat-label>
                    <input formControlName="minimum_amount" type="number" matInput name="title" />
                    <mat-error *ngIf="priceRuleForm.get('minimum_amount').hasError('required')">
                      {{ "Mininum Cart Amount is required" | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="100%">
                    <mat-label>{{ "Maximum Cart Amount" | translate }}</mat-label>
                    <input formControlName="maximum_amount" type="number" matInput name="title" />
                    <mat-error *ngIf="priceRuleForm.get('maximum_amount').hasError('required')">
                      {{ "Maximum Cart Amount is required" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div
                  fxFlex="0 0 auto"
                  fxLayoutAlign="start stretch"
                  fxLayout.lt-md="column"
                  fxLayout.gt-sm="row"
                  fxLayoutGap.gt-sm="5px"
                  fxLayoutGap.lt-md="0px">
                  <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label class="text-capitalized">
                      {{ "Customer Usage Limit" | translate }}
                    </mat-label>
                    <input formControlName="customer_usage_limit" type="number" matInput name="title" />
                    <mat-error *ngIf="priceRuleForm.get('customer_usage_limit').hasError('required')">
                      {{ "Customer Usage Limit is required" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="1 0 auto" fxLayoutAlign="space-between start" fxLayoutGap="1em">
                  <mat-slide-toggle formControlName="exclusive" class="exclusive-price-rule">Exclusive</mat-slide-toggle>
                  <p class="exclusive-content">
                    If enabled, once this discount is applied on an order and no other discounts will apply. Keep it disabled if you want
                    this discount to apply in addition to other discounts.
                  </p>
                </div>
              </fieldset>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
              <fieldset fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                <legend class="highlight-text-color text-bold">
                  {{ "Conditions" | translate }}
                </legend>
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="5px">
                  <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="5px">
                    <mat-form-field appearance="outline" fxFlex="100%">
                      <mat-label>{{ "Audience" | translate }}</mat-label>
                      <mat-select formControlName="audience">
                        <mat-option *ngFor="let audience of audienceList" [value]="audience?.id"> {{ audience?.name }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="priceRuleForm.get('audience').hasError('required')">
                        {{ "Audience is required" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div
                  fxFlex="0 0 auto"
                  fxLayoutAlign="start stretch"
                  fxLayout="column"
                  fxLayoutGap="1em"
                  [ngClass]="priceRuleForm.get('idSelect').value == '4' ? 'mb-0' : 'mb-16'">
                  <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em">
                    <fieldset class="toggle-container" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em">
                      <div class="toggle">
                        <input type="radio" formControlName="idSelect" [value]="1" id="product" />
                        <label for="product">{{ "Product" | translate }}</label>
                        <input type="radio" formControlName="idSelect" [value]="2" id="brand" />
                        <label for="brand">{{ "Brand" | translate }}</label>
                        <input type="radio" formControlName="idSelect" [value]="3" id="category" />
                        <label for="category">{{ "Category" | translate }}</label>
                        <input type="radio" formControlName="idSelect" [value]="5" id="payment" />
                        <label for="payment">{{ "Payment Methods" | translate }}</label>
                        <input type="radio" formControlName="idSelect" [value]="6" id="bank" />
                        <label for="bank">{{ "Bank Card" | translate }}</label>
                        <input type="radio" formControlName="idSelect" [value]="4" id="none" />
                        <label for="none">{{ "None" | translate }}</label>
                      </div>
                    </fieldset>
                  </div>
                  <ng-container *ngIf="priceRuleForm.get('idSelect').value !== 4 && priceRuleForm.get('idSelect').value !== 6">
                    <div class="include-selection">
                      <mat-radio-group
                        aria-label="Select an option"
                        formControlName="include_selection"
                        (change)="includeSelection($event)">
                        <mat-radio-button [value]="true">Include</mat-radio-button>
                        <mat-radio-button [value]="false">Exclude</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </ng-container>
                  <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="column" fxLayoutGap="5px">
                    <div
                      fxFlex="1 1 auto"
                      fxLayoutAlign="start stretch"
                      fxLayout="column"
                      fxLayoutGap="1em"
                      *ngIf="priceRuleForm.get('idSelect').value == '1'">
                      <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                        <label class="highlight-text-color" for="product"> Product Name </label>
                        <ng-select
                          id="product"
                          [loading]="isLoadingProduct"
                          placeholder="{{ 'Enter product name' | translate }}"
                          id="products"
                          fxFlex="100"
                          (input)="searchProducts($event.target.value)"
                          [multiple]="true"
                          formControlName="product_ids">
                          <ng-option *ngFor="let product of products" [value]="product?.id">
                            {{ translationService.getOnlyTranslatedNameNotName_en(product) }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>
                    <!-- category -->
                    <div
                      fxFlex="1 1 auto"
                      fxLayoutAlign="start stretch"
                      fxLayout.lt-md="column"
                      fxLayout.gt-sm="column"
                      fxLayoutGap="5px"
                      *ngIf="priceRuleForm.get('idSelect').value == '3'">
                      <label class="highlight-text-color" for="category"> Category Name </label>
                      <ng-select
                        id="category"
                        [loading]="isLoadingCat"
                        placeholder="{{ 'Enter category name' | translate }}"
                        fxFlex="100"
                        (input)="searchCategories($event.target.value)"
                        [multiple]="true"
                        formControlName="category_ids">
                        <ng-option *ngFor="let category of categories" [value]="category?.id">
                          {{ translationService.getOnlyTranslatedNameNotName_en(category) }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <!-- brand -->
                    <div
                      fxFlex="1 1 auto"
                      fxLayoutAlign="start stretch"
                      fxLayout.lt-md="column"
                      fxLayout.gt-sm="column"
                      fxLayoutGap="5px"
                      *ngIf="priceRuleForm.get('idSelect').value == '2'">
                      <label class="highlight-text-color" for="brand"> Brand Name </label>
                      <ng-select
                        id="brand"
                        [loading]="isLoadingBrand"
                        placeholder="{{ 'Enter brand name' | translate }}"
                        fxFlex="100"
                        (input)="searchBrands($event.target.value)"
                        [multiple]="true"
                        formControlName="brand_ids">
                        <ng-option *ngFor="let brand of brands" [value]="brand?.id">
                          {{ translationService.getOnlyTranslatedNameNotName_en(brand) }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <div
                      fxFlex="1 1 auto"
                      fxLayoutAlign="start stretch"
                      fxLayout.lt-md="column"
                      fxLayout.gt-sm="column"
                      fxLayoutGap="5px"
                      *ngIf="priceRuleForm.get('idSelect').value == '5'">
                      <label class="highlight-text-color" for="payment"> Payment Method </label>
                      <ng-select
                        id="payment"
                        [loading]="isLoadingBrand"
                        placeholder="{{ 'Enter payment method' | translate }}"
                        fxFlex="100"
                        [multiple]="true"
                        formControlName="payment_method_ids">
                        <ng-option *ngFor="let paymentMethod of paymentMethods" [value]="paymentMethod?.id">
                          {{ translationService.getOnlyTranslatedNameNotName_en(paymentMethod) }}
                        </ng-option>
                      </ng-select>
                    </div>

                    <div
                      fxFlex="1 1 auto"
                      fxLayoutAlign="start stretch"
                      fxLayout.lt-md="column"
                      fxLayout.gt-sm="column"
                      fxLayoutGap="5px"
                      *ngIf="priceRuleForm.get('idSelect').value == '6'">
                      <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout.lt-md="column"
                        fxLayout.gt-sm="row"
                        fxLayoutGap.gt-sm="5px"
                        fxLayoutGap.lt-md="0px">
                        <ng-select
                          [multiple]="true"
                          id="payment"
                          [loading]="isLoadingBrand"
                          placeholder="{{ 'Enter Bank Card' | translate }}"
                          fxFlex="100"
                          formControlName="card_banks">
                          <ng-option *ngFor="let bankCard of bankList$ | async" [value]="bankCard?.id">
                            {{ translationService.getOnlyTranslatedNameNotName_en(bankCard) }}
                          </ng-option>
                        </ng-select>

                        <ng-select
                          [multiple]="true"
                          id="payment"
                          [loading]="isLoadingBrand"
                          placeholder="{{ 'Enter Bank Brand' | translate }}"
                          fxFlex="100"
                          formControlName="card_brands">
                          <ng-option *ngFor="let bankBrand of bankBrand$ | async" [value]="bankBrand?.id">
                            {{ translationService.getOnlyTranslatedNameNotName_en(bankBrand) }}
                          </ng-option>
                        </ng-select>

                        <ng-select
                          [multiple]="true"
                          id="payment"
                          [loading]="isLoadingBrand"
                          placeholder="{{ 'Enter Bank Scheme' | translate }}"
                          fxFlex="100"
                          formControlName="card_schemes">
                          <ng-option *ngFor="let bankScheme of bankScheme$ | async" [value]="bankScheme?.id">
                            {{ translationService.getOnlyTranslatedNameNotName_en(bankScheme) }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>

                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="5px">
                  <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label class="text-capitalized">
                      {{ "Product Mininum Quantity" | translate }}
                    </mat-label>
                    <input formControlName="minimum_quantity" type="number" matInput name="title" />
                  </mat-form-field>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="5px">
                  <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                    <label class="highlight-text-color" for="product"> Enter Restricted Product name </label>
                    <ng-select
                      id="product"
                      [loading]="isLoadingProduct"
                      placeholder="{{ 'Enter Restricted product name' | translate }}"
                      id="products"
                      fxFlex="100"
                      (input)="searchProducts($event.target.value)"
                      [multiple]="true"
                      formControlName="restricted_product_ids">
                      <ng-option *ngFor="let product of products" [value]="product?.id">
                        {{ translationService.getOnlyTranslatedNameNotName_en(product) }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="5px">
                  <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                    <label class="highlight-text-color" for="select"> Restricted Category Name </label>
                    <ng-select
                      id="select"
                      [loading]="isLoadingCat"
                      placeholder="{{ 'Enter Restricted  category name' | translate }}"
                      fxFlex="100"
                      (input)="searchCategories($event.target.value)"
                      [multiple]="true"
                      formControlName="restricted_category_ids">
                      <ng-option *ngFor="let category of categories" [value]="category?.id">
                        {{ translationService.getOnlyTranslatedNameNotName_en(category) }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="mt-9" fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="0px">
                    <mat-icon
                      title="info"
                      [matMenuTriggerFor]="afterMenu"
                      class="highlight-text-color cursor-pointer info-icon"
                      fxLayoutAlign="center center"
                      fxLayout="row"
                      >info
                    </mat-icon>
                    <mat-menu #afterMenu="matMenu" xPosition="after">
                      <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" class="p-8" fxLayout="column" fxLayoutGap="0px">
                        <strong>{{ "Restricted category name" | translate }}</strong>
                        <p class="m-4">
                          {{ "help_text_price_restricted_category_name" | translate }}
                        </p>
                      </div>
                    </mat-menu>
                  </div>
                </div>
                <div class="mb-12" fxFlex="1 0 auto" fxLayoutAlign="start start" fxLayout="row" fxLayoutGap="3px">
                  <mat-slide-toggle formControlName="apply_on_striked" class="apply_on_striked-price-rule"
                    >Apply on discounted products
                  </mat-slide-toggle>
                  <!-- <p class="exclusive-content">
                                    If enabled, once this discount is
                                    applied on an order and no other
                                    discounts will apply. Keep it disabled
                                    if you want this discount to apply in
                                    addition to other discounts.
                                </p> -->
                </div>
              </fieldset>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
              <fieldset fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                <legend class="highlight-text-color text-bold">
                  {{ "Discounts" | translate }}
                </legend>
                <div fxFlex="0 0 auto" fxLayoutAlign="start baseline" fxLayout="row" fxLayoutGap="3px">
                  <mat-form-field appearance="outline" fxFlex="100%">
                    <mat-label>{{ "Discount On" | translate }}</mat-label>
                    <mat-select formControlName="discount_on">
                      <mat-option [value]="1">{{ "Cart Amount" }}</mat-option>
                      <mat-option [value]="2">{{ "Delivery Charges" | translate }}</mat-option>
                      <mat-option [value]="3">{{ "Product Amount" | translate }}</mat-option>
                      <mat-option [value]="4">{{ "International Delivery" | translate }} </mat-option>
                      <mat-option [value]="5">{{ "Local Delivery" | translate }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div fxFlex="0 0 auto" fxLayoutAlign="end end" fxLayout="row" fxLayoutGap="0px">
                    <mat-icon
                      title="info"
                      [matMenuTriggerFor]="afterMenu"
                      class="highlight-text-color cursor-pointer info-icon"
                      fxLayoutAlign="end end"
                      fxLayout="row"
                      >info
                    </mat-icon>
                    <mat-menu #afterMenu="matMenu" xPosition="after">
                      <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" class="p-8" fxLayout="column" fxLayoutGap="0px">
                        <strong>{{ "Discound on" | translate }}</strong>
                        <p *ngIf="priceRuleForm.get('discount_on').value == 1" class="m-4">
                          {{ "help_text_price_rule_cart_amount" | translate }}
                        </p>
                        <p *ngIf="priceRuleForm.get('discount_on').value == 2" class="m-4">
                          {{ "help_text_price_rule_delivery_charges" | translate }}
                        </p>
                        <p *ngIf="priceRuleForm.get('discount_on').value == 3" class="m-4">
                          {{ "help_text_price_rule_product_amount" | translate }}
                        </p>
                        <p *ngIf="priceRuleForm.get('discount_on').value == 4" class="m-4">
                          {{ "help_text_price_rule_international_delivery" | translate }}
                        </p>
                        <p *ngIf="priceRuleForm.get('discount_on').value == 5" class="m-4">
                          {{ "help_text_price_rule_local_delivery" | translate }}
                        </p>
                      </div>
                    </mat-menu>
                  </div>
                </div>
                <div
                  *ngIf="priceRuleForm.get('discount_on').value == '1' || priceRuleForm.get('discount_on').value == '3'"
                  fxFlex="0 0 auto"
                  fxLayoutAlign="start stretch"
                  fxLayout.lt-md="column"
                  fxLayout.gt-sm="row"
                  fxLayoutGap.gt-sm="5px"
                  fxLayoutGap.lt-md="0px">
                  <mat-form-field appearance="outline" fxFlex="100%">
                    <mat-label>{{ "Discount" | translate }}</mat-label>
                    <input formControlName="discount" type="number" matInput name="title" />
                    <mat-error *ngIf="priceRuleForm.get('discount').hasError('required')">
                      {{ "Discount is required" | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="100%">
                    <mat-label>{{ "Discount Type" | translate }}</mat-label>
                    <mat-select formControlName="discount_type">
                      <mat-option [value]="1">%</mat-option>
                      <mat-option [value]="2">{{ "Fixed" | translate }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div
                  *ngIf="
                    priceRuleForm.get('discount_on').value == '2' ||
                    priceRuleForm.get('discount_on').value == '4' ||
                    priceRuleForm.get('discount_on').value == '5'
                  "
                  fxFlex="0 0 auto"
                  fxLayoutAlign="start stretch"
                  fxLayout.lt-md="column"
                  fxLayout.gt-sm="row"
                  fxLayoutGap.gt-sm="5px"
                  fxLayoutGap.lt-md="0px">
                  <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label class="text-capitalized">
                      {{ "Delivery Discount" | translate }}
                    </mat-label>
                    <input formControlName="delivery_discount" type="number" matInput name="title" />
                    <mat-error *ngIf="priceRuleForm.get('delivery_discount').hasError('required')">
                      {{ "Delivery Discount is required" | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="100%">
                    <mat-label>{{ "Delivery Discount Type" | translate }}</mat-label>
                    <mat-select formControlName="delivery_discount_type">
                      <mat-option [value]="1">%</mat-option>
                      <mat-option [value]="2">{{ "Fixed Charges" | translate }}</mat-option>
                      <mat-option [value]="3">{{ "Discount Value" | translate }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div
                  fxFlex="0 0 auto"
                  fxLayoutAlign="start stretch"
                  fxLayout.lt-md="column"
                  fxLayout.gt-sm="row"
                  fxLayoutGap.gt-sm="5px"
                  fxLayoutGap.lt-md="0px">
                  <mat-form-field appearance="outline" fxFlex="100%">
                    <mat-label class="text-capitalized">
                      {{ "Allow Maximum Discount Value" | translate }}
                    </mat-label>
                    <input formControlName="discount_cap" type="number" matInput name="title" />
                  </mat-form-field>
                </div>
              </fieldset>
            </div>
          </div>
          <div
            fxFlex="1 1 auto"
            fxLayoutAlign.gt-sm="space-between center"
            fxLayoutAlign.lt-md="start stretch"
            fxLayout.lt-md="column-reverse"
            fxLayout.gt-sm="row"
            fxLayoutGap="5px">
            <button mat-stroked-button (click)="handleCancel()">
              <mat-icon>clear</mat-icon>
              {{ "Cancel" | translate }}
            </button>
            <button (click)="saveOrUpdate()" type="submit" mat-flat-button class="primary-bg-color">
              <mat-icon>save</mat-icon>
              {{ "Save" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
