import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IconCss } from "app/main/ngxDatatable-Icons/icons";
import { OrderService } from "app/services/orders/order.service";
import { UtilitiesService } from "app/services/utilitiesService";

@Component({
    selector: "app-customer-wishlist-table",
    templateUrl: "./customer-wishlist-table.component.html",
    styleUrls: ["./customer-wishlist-table.component.scss"],
})
export class CustomerWishlistTableComponent implements OnInit {
    @Input() customerID: any = "";
    @Output() wishlistCountHandler: EventEmitter<void> =
        new EventEmitter<any>();
    icons = IconCss;
    iconsCss = {
        pagerLeftArrow: this.icons.left,
        pagerRightArrow: this.icons.right,
        pagerPrevious: this.icons.previous,
        pagerNext: this.icons.next,
    };
    page = {
        size: 10,
        totalElements: 0,
        pageNumber: 0,
    };
    wishlist = [];
    offset = 0;
    isLoading: boolean;
    constructor(
        private orderService: OrderService,
        public utilitiesService: UtilitiesService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.getWishlist();
    }
    async getWishlist(limit = this.page.size, offset = this.offset) {
        try {
            this.isLoading = true;
            const wishlist = await this.orderService
                .getWishList(limit, offset, "", undefined, this.customerID)
                .toPromise();
            if (wishlist) {
                this.wishlist = wishlist.results;
                this.page.totalElements = wishlist.count;
                this.wishlistCountHandler.emit(wishlist.count);
                this.isLoading = false;
            }
        } catch {
            this.isLoading = false;
        } finally {
        }
    }
    pageWishlistChange(event) {
        this.isLoading = true;
        this.page.pageNumber = event.offset;
        this.offset = this.page.pageNumber * this.page.size;
        this.getWishlist();
    }
    getWidth() {
        this.wishlist = [...this.wishlist];
        return 70;
    }
}
