import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { invalid } from "@angular/compiler/src/render3/view/util";
import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";

import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { NavMenuService } from "app/services/nav-menu.service";
import { PageService } from "app/services/pageService";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";
import { IconImageWarningComponent } from "../../icon-image-warning/icon-image-warning.component";
import { ImageSizeWarningComponent } from "../../image-size-warning/image-size-warning.component";

function startWithHttp(
    control: AbstractControl
): { [key: string]: any } | null {
    if (control.value && !control.value.startsWith("http")) {
        return { startWithHttp: true };
    }
    return null;
}

function containHTTP(control: AbstractControl): { [key: string]: any } | null {
    if (control.value && control.value.startsWith("http")) {
        return { containHTTP: true };
    }
    return null;
}
@Component({
    selector: "app-custom-country",
    templateUrl: "./custom-country.component.html",
    styleUrls: ["./custom-country.component.scss"],
})
export class CustomCountryComponent implements OnInit {
    @ViewChild("fileDropRef2", { static: false }) fileDropEl2: ElementRef;
    expandIndex = [];
    countryRuleList = [];
    loading: boolean = true;
    form: FormGroup;
    country = [];
    elementsArray = [];
    typeEnum = [
        { id: "COUNTRY", name: "Default" },
        // { id: "PAGE", name: "Page" },
        // { id: "CUSTOM", name: "Custom" },
        { id: "EXTERNAL", name: "External" },
    ];
    // pageList = [];
    isSumbit: boolean = false;
    countryDropDown = [];
    countryModeSettings = {};
    files = [];
    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private appService: AppService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        private navMenuService: NavMenuService,
        private pageService: PageService,
        public sanitizer: DomSanitizer,
        private ngZone: NgZone,
        private cdr: ChangeDetectorRef
    ) {}

    async ngOnInit(): Promise<void> {
        this.countryModeSettings = {
            text: "Select Country",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            enableSearchFilter: true,
        };
        this.utilitiesService.startLoader();
        await this.formInit();
        await this.getCountries();
        await this.getCountryRules();
        this.cdr.detectChanges();
        // await this.getPages();
    }
    async getCountryRules() {
        try {
            this.utilitiesService.startLoader();
            const countryRuleList = await this.appService
                .getCountryRules()
                .toPromise();
            if (countryRuleList) {
                this.countryRuleList = countryRuleList.results;
                setTimeout(() => {
                    document.getElementById("toolbar").scrollIntoView();
                }, 100);
                await this.setValue();
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
    async setValue() {
        try {
            let count = 0;
            if (this.countryRuleList.length > 0) {
                this.utilitiesService.startLoader();
                (this.form.get("country_rules") as FormArray).clear();
                this.countryRuleList.forEach((data, index) => {
                    count = count + 1;
                    this.addCustomCountry(data, index);
                });
                if (this.countryRuleList.length == count) {
                    this.utilitiesService.stopLoader();
                }
            } else {
                (this.form.get("country_rules") as FormArray).clear();
                this.utilitiesService.stopLoader();
            }
        } catch {
            this.utilitiesService.stopLoader();
        }
    }
    async getCountries() {
        try {
            const country = await this.appService
                .getCountryForManage()
                .toPromise();
            if (country) {
                this.country = country;
                this.country.forEach((data) => {
                    this.countryDropDown.push({
                        id: data.id,
                        itemName: data.name,
                    });
                });
                this.countryDropDown = [...this.countryDropDown];
            }
        } catch {
        } finally {
        }
    }

    getCountryName(id) {
        let countryName = "";
        let country = this.country.filter((data) => data.id == id);
        if (country.length > 0) {
            countryName = country[0].name;
        }
        return countryName;
    }
    async formInit() {
        this.form = this.fb.group({
            country_rules: this.fb.array([this.addCustomCountries()]),
        });
        this.typeChanged(0, "COUNTRY");
    }
    addCustomCountries(countryData?) {
        return this.fb.group({
            id: [countryData ? countryData.id : null],
            countries: [],
            type: [countryData ? countryData.type : "COUNTRY"],
            enable: [countryData ? countryData.enable : false],
            sequence: [countryData ? countryData.sequence : 0],
            icon: [countryData ? countryData.icon : null],
            value: [countryData ? countryData.value : null],
            page: [null],
            name: [countryData ? countryData.name : null],
            name_en: [countryData ? countryData.name_en : null],
            name_ar: [countryData ? countryData.name_ar : null],
            minimum_amount: [countryData ? countryData.minimum_amount : 0],
            enable_tabby: [countryData ? countryData.enable_tabby : false],
            tabby_merchant_code: [
                countryData ? countryData.tabby_merchant_code : null,
            ],
            tabby_public_api_key: [
                countryData ? countryData.tabby_public_api_key : null,
            ],
            show_civil_id: [
                countryData
                    ? countryData.show_civil_id
                        ? true
                        : false
                    : false,
            ],
            civil_id_note_ar: [
                countryData
                    ? countryData.civil_id_note_ar
                        ? countryData.civil_id_note_ar
                        : null
                    : null,
            ],
            civil_id_note_en: [
                countryData
                    ? countryData.civil_id_note_en
                        ? countryData.civil_id_note_en
                        : countryData.civil_id_note
                        ? countryData.civil_id_note
                        : null
                    : null,
            ],
            paci_note_en: [
                countryData
                    ? countryData.paci_note_en
                        ? countryData.paci_note_en
                        : countryData.paci_note
                        ? countryData.paci_note
                        : null
                    : null,
            ],
            paci_note_ar: [
                countryData
                    ? countryData.paci_note_ar
                        ? countryData.paci_note_ar
                        : null
                    : null,
            ],
            is_civil_id_mandatory: [
                countryData
                    ? countryData.is_civil_id_mandatory
                        ? true
                        : false
                    : false,
            ],
            show_paci: [
                countryData ? (countryData.show_paci ? true : false) : false,
            ],
            is_paci_mandatory: [
                countryData
                    ? countryData.is_paci_mandatory
                        ? true
                        : false
                    : false,
            ],
            icon_type: [
                countryData
                    ? countryData.icon_type
                        ? countryData.icon_type
                        : 1
                    : 1,
            ],
            number_language: [countryData ? countryData.number_language : 1],
            icon_image: [countryData ? countryData.icon_image : null],
            validation_id: [
                countryData
                    ? countryData.validation
                        ? countryData.validation.id
                        : 0
                    : 0,
            ],
            app: [
                countryData
                    ? countryData.app
                    : localStorage.getItem("app_id")
                    ? localStorage.getItem("app_id")
                    : null,
            ],
            show_tax_inc_label: [
                countryData ? countryData.show_tax_inc_label : false,
            ],
            show_tax: [countryData ? countryData.show_tax : false],
        });
    }
    async addCustomCountry(countryData?, index?) {
        console.log("countryData");
        console.log(countryData);
        console.log("index");
        console.log(index);
        this.isSumbit = false;
        (this.form.get("country_rules") as FormArray).push(
            this.addCustomCountries(countryData)
        );
        this.elementsArray.splice(index, 0, countryData);
        await this.setCountryValue(countryData, index);
    }
    async setCountryValue(countryData, index) {
        console.log("countryData");
        console.log(countryData);
        console.log("countryData.countries");
        console.log(countryData.countries);
        let countryIDs = [];
        if (countryData.countries && countryData.countries.length > 0) {
            countryData.countries.forEach((data) => {
                countryIDs.push({
                    id: data.id,
                    itemName: data.name,
                });
            });
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("countries")
                .setValue(countryIDs);
        }
    }
    delete(index, id) {
        let content = `Are you sure, Do you want to delete this country rule ? `;
        let heading = "Delete";
        let fromApp = false;
        let size = this.utilitiesService.isMobileAlertModal();
        const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });
        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                if (id) {
                    this.utilitiesService.startLoader();
                    this.appService.deleteCountryRules(id).subscribe((data) => {
                        this.utilitiesService.startLoader();
                        this.getCountryRules();
                    });
                } else {
                    (this.form.get("country_rules") as FormArray).removeAt(
                        index
                    );
                    document.getElementById("toolbar").scrollIntoView();
                }
            }
        });
    }

    checkInvalid(index) {
        if (
            (this.form.get("country_rules") as FormArray).length > 0 &&
            this.isSumbit
        ) {
            return (this.form.get("country_rules") as FormArray).at(index)
                .valid;
        } else {
            return true;
        }
    }
    typeChanged(index, type) {
        if (type == "EXTERNAL") {
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("value")
                .setValidators(
                    Validators.compose([startWithHttp, Validators.required])
                );
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("value")
                .updateValueAndValidity();
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("page")
                .clearValidators();
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("page")
                .updateValueAndValidity();
        } else if (type == "CUSTOM") {
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("value")
                .setValidators(
                    Validators.compose([containHTTP, Validators.required])
                );
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("value")
                .updateValueAndValidity();
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("page")
                .clearValidators();
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("page")
                .updateValueAndValidity();
        } else if (type == "PAGE") {
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("page")
                .setValidators(Validators.compose([Validators.required]));
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("page")
                .updateValueAndValidity();
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("value")
                .clearValidators();
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("value")
                .updateValueAndValidity();
        } else {
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("page")
                .clearValidators();
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("page")
                .updateValueAndValidity();
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("value")
                .clearValidators();
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("value")
                .updateValueAndValidity();
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("value")
                .setValue("");
        }
        this.cdr.detectChanges();
    }

    showPacciChange(index, value) {
        if (!value) {
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("is_paci_mandatory")
                .setValue(false);
        }
        this.cdr.detectChanges();
    }

    showCivilIDChange(index, value) {
        if (!value) {
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("is_civil_id_mandatory")
                .setValue(false);
        }
        this.cdr.detectChanges();
    }

    showTabbyChange(index, value) {
        if (!value) {
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("scheduling")
                .setValue(false);
        }
        this.cdr.detectChanges();
    }
    async checkFormIsValidOrNot() {
        this.isSumbit = true;
        let formValid = false;
        let invlidData = [];
        if (
            (this.form.get("country_rules") as FormArray) &&
            (this.form.get("country_rules") as FormArray).length > 0
        ) {
            (this.form.get("country_rules") as FormArray).controls.forEach(
                (data, index) => {
                    if (data.invalid) {
                        invlidData.push(index);
                    }
                }
            );
            if (invlidData.length > 0) {
                formValid = false;
            } else {
                formValid = true;
            }
            invlidData.forEach((data) => {
                setTimeout(() => {
                    (this.form.get("country_rules") as FormArray)
                        .at(data)
                        .get("countries")
                        .markAsTouched();
                    (this.form.get("country_rules") as FormArray)
                        .at(data)
                        .get("page")
                        .markAsTouched();
                    (this.form.get("country_rules") as FormArray)
                        .at(data)
                        .get("value")
                        .markAsTouched();
                    this.expandIndex[data] = data;
                }, 0);
            });
        } else {
            formValid = true;
        }
        return formValid;
    }

    deleteFile(index) {
        ((this.form.get("country_rules") as FormArray).at(index) as FormGroup)
            .get("icon_image")
            .patchValue(null);
    }

    setFileToForm(file, index) {
        ((this.form.get("country_rules") as FormArray).at(index) as FormGroup)
            .get("icon_image")
            .patchValue(file.file);
    }
    // async pushToFomGroup(index) {
    //     let reader = this.utilitiesService.convertImageToBase64(this.files[0]);
    //     reader.onload = (readerEvt: any) => {
    //         let image = new Image();
    //         let width = 0;
    //         let height = 0;
    //         image.src = readerEvt.target.result;
    //         image.onload = (event) => {
    //             width = image.naturalWidth;
    //             height = image.naturalHeight;
    //             if (width <= 64 && height <= 64) {
    //                 (
    //                     (this.form.get("country_rules") as FormArray).at(
    //                         index
    //                     ) as FormGroup
    //                 )
    //                     .get("icon_image")
    //                     .patchValue(readerEvt.target.result);
    //             } else {
    //                 let size = this.utilitiesService.isMobileAlertModal();
    //                 const dialogRef = this.dialog.open(
    //                     IconImageWarningComponent,
    //                     {
    //                         data: {},
    //                         maxWidth: "",
    //                         width: `${size.width}`,
    //                         height: `${size.height}`,
    //                     }
    //                 );
    //                 dialogRef.afterClosed().subscribe((resp) => {});
    //             }
    //         };
    //     };
    // }

    async drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                this.elementsArray,
                event.previousIndex,
                event.currentIndex
            );
            if (event.previousIndex > event.currentIndex) {
                const formGroup = (
                    this.form.get("country_rules") as FormArray
                ).at(event.previousIndex);
                (this.form.get("country_rules") as FormArray).removeAt(
                    event.previousIndex
                );
                (this.form.get("country_rules") as FormArray).insert(
                    event.currentIndex,
                    formGroup
                );
            }
            if (event.previousIndex < event.currentIndex) {
                const formGroup = (
                    this.form.get("country_rules") as FormArray
                ).at(event.currentIndex);
                (this.form.get("country_rules") as FormArray).removeAt(
                    event.currentIndex
                );
                (this.form.get("country_rules") as FormArray).insert(
                    event.previousIndex,
                    formGroup
                );
            }
        } else {
            await this.addCustomCountry(event.item.data, event.currentIndex);
        }
    }
}
