<!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 1 auto">
    <mat-form-field appearance="outline" fxFlex="100" class="search-text-wrapper">
        <input matInput [(ngModel)]="searchText" (input)="searchTextSubject$.next($event.target.value)"
            placeholder="{{'Search...' | translate}}" fxFlex="0 0 auto">
        <mat-icon (click)="searchTextSubject$.next('');searchText=''" class="cursor-pointer"
            *ngIf="searchTextSubject$.value" matSuffix>close</mat-icon>
    </mat-form-field>
</div> -->

<form fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="start center" class="search-form form">
    <label>
        <input [(ngModel)]="searchText" (input)="searchTextSubject$.next($event.target.value)" type="search"
            class="search-field" placeholder="{{'Type something' | translate}}" value="" name="s" title="" />
    </label>
    <div class="search-submit button" (click)="searchTextSubject$.value ? clearFilter() : ''">
        <mat-icon (click)="searchTextSubject$.next('');searchText=''" class="cursor-pointer"
            *ngIf="searchTextSubject$.value" matSuffix>close</mat-icon>
        <mat-icon *ngIf="!searchTextSubject$.value">search</mat-icon>
    </div>
</form>