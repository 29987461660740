<div
    fxFlex="1 1 100%"
    fxLayoutAlign="start stretch"
    (cdkDropListDropped)="drop($event)"
    cdkDropList
    fxLayout.lt-md="column"
    fxLayout.gt-sm="row"
    fxLayoutGap="1em"
>
    <form
        fxFlex="1 1 100%"
        fxLayoutAlign="start stretch"
        fxLayout.lt-md="column-reverse"
        fxLayout.gt-sm="row"
        fxLayoutGap="1em"
        [formGroup]="sliderForm"
    >
        <div
            fxFlex.lt-md="1 1 auto"
            fxFlex.gt-sm="1 1 75%"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="1em"
        >
            <div
                fxFlex="1 1 auto"
                fxLayout="column"
                fxLayoutAlign="start stretch"
                fxLayoutGap="0px"
            >
                <div
                    fxLayoutAlign="start stretch"
                    fxLayout.lt-md="column"
                    fxLayout.gt-sm="row"
                    fxLayoutGap="1em"
                >
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label class="text-capitalized">
                            {{ "Title (en)" | translate }}
                        </mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="title_en"
                        />
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label class="text-capitalized">
                            {{ "Title (ar)" | translate }}
                        </mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="title_ar"
                        />
                    </mat-form-field>
                </div>
                <div
                    class="example-list"
                    class="dropzone p-16"
                    fxFlex="0 0 auto"
                    fxLayout="column"
                    fxLayoutAlign="start stretch"
                >
                    <div
                        fxFlex="0 0 auto"
                        fxLayout="column"
                        fxLayoutAlign="start end"
                        fxLayoutGap="1em"
                    >
                        <app-fab-button
                            *ngIf="isButtonShow(sliderForm.get('type').value)"
                            (handleFabButtonClick)="addSliders()"
                        ></app-fab-button>
                    </div>
                    <div
                        fxLayout="column"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        *ngIf="typeEnum.BRAND == sliderForm.get('type').value"
                    >
                        <mat-accordion
                            class="mb-16"
                            cdkDropList
                            cdk-drag-preview
                            cdkDrag
                            [cdkDragData]="brandFormGroup"
                            formArrayName="brand"
                            *ngFor="
                                let brandFormGroup of sliderForm.get('brand')[
                                    'controls'
                                ];
                                let i = index
                            "
                            [formGroup]="brandFormGroup"
                        >
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="start center"
                                            fxLayoutGap="10px"
                                        >
                                            <mat-icon>drag_indicator</mat-icon>
                                            <span>{{
                                                brandFormGroup.get("name").value
                                                    ? brandFormGroup.get("name")
                                                          .value
                                                    : sliderForm.get("type")
                                                          .value
                                            }}</span>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div
                                    (mousedown)="$event.stopPropagation()"
                                    (mousemove)="$event.stopPropagation()"
                                    fxFlex="1 1 auto"
                                    fxLayout="column"
                                    fxLayoutAlign="start stretch"
                                    fxLayoutGap="5px"
                                >
                                    <div
                                        fxFlex="1 1 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start end"
                                        fxLayoutGap="5px"
                                    >
                                        <mat-form-field appearance="outline">
                                            <mat-select
                                                (selectionChange)="
                                                    languageChanges($event)
                                                "
                                                [formControl]="
                                                    brandFormGroup.get(
                                                        'language'
                                                    )
                                                "
                                            >
                                                <mat-option value="en"
                                                    >En</mat-option
                                                >
                                                <mat-option value="ar"
                                                    >Ar</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div
                                        fxFlex="1 1 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <div
                                            fxLayout.lt-md="column"
                                            fxLayout.gt-sm="row"
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="start stretch"
                                            fxLayoutGap="1em"
                                        >
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                >
                                                    <mat-label>
                                                        {{
                                                            "Name" | translate
                                                        }}</mat-label
                                                    >
                                                    <input
                                                        [formControl]="
                                                            brandFormGroup.get(
                                                                'name'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                            </div>
                                            <!-- <div fxFlex="1 1 auto">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label class="text-capitalized">{{'Order' | translate}}
                                                    </mat-label>
                                                    <input type="number" [formControl]="brandFormGroup.get('order')" matInput name="title">
                                                </mat-form-field>
                                            </div> -->
                                            <!-- <div fxFlex="1 1 auto">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label class="text-capitalized">{{'URL' | translate}}
                                                    </mat-label>
                                                    <input [formControl]="brandFormGroup.get('url')" matInput
                                                        name="title">
                                                </mat-form-field>
                                            </div> -->
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                    *ngIf="
                                                        brandFormGroup.get(
                                                            'language'
                                                        ).value == 'en'
                                                    "
                                                >
                                                    <mat-label
                                                        class="text-capitalized"
                                                    >
                                                        {{
                                                            "Button Text (en)"
                                                                | translate
                                                        }}
                                                    </mat-label>
                                                    <input
                                                        [formControl]="
                                                            brandFormGroup.get(
                                                                'button_text'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                    *ngIf="
                                                        brandFormGroup.get(
                                                            'language'
                                                        ).value == 'ar'
                                                    "
                                                >
                                                    <mat-label
                                                        class="text-capitalized"
                                                    >
                                                        {{
                                                            "Button Text (ar)"
                                                                | translate
                                                        }}
                                                    </mat-label>
                                                    <input
                                                        [formControl]="
                                                            brandFormGroup.get(
                                                                'button_text_ar'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div
                                            fxFlex="1 1 auto"
                                            fxLayoutAlign="start stretch"
                                            fxLayout="column"
                                            fxLayoutGap="10px"
                                        >
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    fxFlex="100"
                                                    class="example-full-width"
                                                    appearance="outline"
                                                >
                                                    <mat-label>{{
                                                        "Brand" | translate
                                                    }}</mat-label>
                                                    <input
                                                        (keyup)="
                                                            typeValueChanges(
                                                                $event,
                                                                typeEnum.BRAND,
                                                                i
                                                            )
                                                        "
                                                        type="text"
                                                        placeholder="{{
                                                            'Brand' | translate
                                                        }}"
                                                        aria-label="Number"
                                                        matInput
                                                        [formControl]="
                                                            brandFormGroup.get(
                                                                'content_text'
                                                            )
                                                        "
                                                        [matAutocomplete]="auto"
                                                    />
                                                    <mat-spinner
                                                        *ngIf="isLoading"
                                                        matSuffix
                                                        [diameter]="18"
                                                        style="
                                                            float: right;
                                                            margin-left: 8px;
                                                        "
                                                    ></mat-spinner>
                                                    <mat-autocomplete
                                                        (optionSelected)="
                                                            patchTypeIDtoContent(
                                                                $event?.option
                                                                    ?.value,
                                                                typeEnum.BRAND,
                                                                i
                                                            )
                                                        "
                                                        #auto="matAutocomplete"
                                                    >
                                                        <mat-option
                                                            *ngFor="
                                                                let option of brandFormGroup.get(
                                                                    'brands'
                                                                ).value
                                                            "
                                                            [value]="
                                                                option?.name
                                                            "
                                                        >
                                                            <img
                                                                *ngIf="
                                                                    option?.image ||
                                                                    option?.logo
                                                                "
                                                                style="
                                                                    height: 40px !important;
                                                                    width: auto !important;
                                                                "
                                                                [src]="
                                                                    utilitiesService.bypassTrustedURL(
                                                                        option?.logo
                                                                            ? option?.logo
                                                                            : option?.image
                                                                    )
                                                                "
                                                            />
                                                            {{ option?.name }}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <mat-action-row>
                                    <app-delete-button
                                        (deleteButtonClick)="delete(i, 'brand')"
                                    ></app-delete-button>
                                </mat-action-row>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div
                        fxLayout="column"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        *ngIf="typeEnum.PRODUCT == sliderForm.get('type').value"
                    >
                        <mat-accordion
                            class="mb-16"
                            formArrayName="product"
                            cdkDropList
                            cdk-drag-preview
                            cdkDrag
                            [cdkDragData]="productFormGroup"
                            *ngFor="
                                let productFormGroup of sliderForm.get(
                                    'product'
                                )['controls'];
                                let i = index
                            "
                            [formGroup]="productFormGroup"
                        >
                            <mat-expansion-panel>
                                <div class="field-placeholder"></div>
                                <mat-expansion-panel-header>
                                    <mat-panel-title fxFlex="0 0 auto">
                                        <div
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="start center"
                                            fxLayoutGap="10px"
                                        >
                                            <mat-icon>drag_indicator</mat-icon>
                                            <span>{{
                                                productFormGroup.get("name")
                                                    .value
                                                    ? productFormGroup.get(
                                                          "name"
                                                      ).value
                                                    : sliderForm.get("type")
                                                          .value
                                            }}</span>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div
                                    fxFlex="1 1 auto"
                                    fxLayout="column"
                                    fxLayoutAlign="start stretch"
                                    fxLayoutGap="5px"
                                >
                                    <div
                                        fxFlex="1 1 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start end"
                                        fxLayoutGap="5px"
                                    >
                                        <mat-form-field appearance="outline">
                                            <mat-select
                                                (selectionChange)="
                                                    languageChanges($event)
                                                "
                                                [formControl]="
                                                    productFormGroup.get(
                                                        'language'
                                                    )
                                                "
                                            >
                                                <mat-option value="en"
                                                    >En</mat-option
                                                >
                                                <mat-option value="ar"
                                                    >Ar</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div
                                        fxFlex="1 1 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <div
                                            fxLayout.lt-md="column"
                                            fxLayout.gt-sm="row"
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="start stretch"
                                            fxLayoutGap="1em"
                                        >
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                >
                                                    <mat-label>{{
                                                        "Name" | translate
                                                    }}</mat-label>
                                                    <input
                                                        [formControl]="
                                                            productFormGroup.get(
                                                                'name'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                    *ngIf="
                                                        productFormGroup.get(
                                                            'language'
                                                        ).value == 'en'
                                                    "
                                                >
                                                    <mat-label
                                                        class="text-capitalized"
                                                    >
                                                        {{
                                                            "Button Text (en)"
                                                                | translate
                                                        }}
                                                    </mat-label>
                                                    <input
                                                        [formControl]="
                                                            productFormGroup.get(
                                                                'button_text'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                    *ngIf="
                                                        productFormGroup.get(
                                                            'language'
                                                        ).value == 'ar'
                                                    "
                                                >
                                                    <mat-label
                                                        class="text-capitalized"
                                                    >
                                                        {{
                                                            "Button Text (ar)"
                                                                | translate
                                                        }}
                                                    </mat-label>
                                                    <input
                                                        [formControl]="
                                                            productFormGroup.get(
                                                                'button_text_ar'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div
                                            fxFlex="1 1 auto"
                                            fxLayoutAlign="start stretch"
                                            fxLayout="column"
                                            fxLayoutGap="10px"
                                        >
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    fxFlex="100"
                                                    class="example-full-width"
                                                    appearance="outline"
                                                >
                                                    <mat-label>{{
                                                        "Product" | translate
                                                    }}</mat-label>
                                                    <input
                                                        placeholder="{{
                                                            'Product'
                                                                | translate
                                                        }}"
                                                        (keyup)="
                                                            typeValueChanges(
                                                                $event,
                                                                typeEnum.PRODUCT,
                                                                i
                                                            )
                                                        "
                                                        type="text"
                                                        aria-label="Number"
                                                        matInput
                                                        [formControl]="
                                                            productFormGroup.get(
                                                                'content_text'
                                                            )
                                                        "
                                                        [matAutocomplete]="auto"
                                                    />
                                                    <mat-spinner
                                                        *ngIf="isLoading"
                                                        matSuffix
                                                        [diameter]="18"
                                                        style="
                                                            float: right;
                                                            margin-left: 8px;
                                                        "
                                                    ></mat-spinner>
                                                    <mat-autocomplete
                                                        #auto="matAutocomplete"
                                                        (optionSelected)="
                                                            patchTypeIDtoContent(
                                                                $event.option
                                                                    .value,
                                                                typeEnum.PRODUCT,
                                                                i
                                                            )
                                                        "
                                                    >
                                                        <mat-option
                                                            *ngFor="
                                                                let option of productFormGroup.get(
                                                                    'products'
                                                                ).value
                                                            "
                                                            [value]="
                                                                option?.name
                                                            "
                                                        >
                                                            <img
                                                                *ngIf="
                                                                    option?.image
                                                                "
                                                                style="
                                                                    height: 40px !important;
                                                                    width: auto !important;
                                                                "
                                                                [src]="
                                                                    utilitiesService.bypassTrustedURL(
                                                                        option?.image
                                                                    )
                                                                "
                                                            />
                                                            {{ option?.name }}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <mat-action-row>
                                    <app-delete-button
                                        (deleteButtonClick)="
                                            delete(i, 'product')
                                        "
                                    ></app-delete-button>
                                </mat-action-row>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div
                        fxLayout="column"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        *ngIf="
                            typeEnum.POST_CATEGORY ==
                            sliderForm.get('type').value
                        "
                    >
                        <mat-accordion
                            class="mb-16"
                            cdkDropList
                            cdk-drag-preview
                            cdkDrag
                            [cdkDragData]="postCategoryFormGroup"
                            formArrayName="post_category"
                            *ngFor="
                                let postCategoryFormGroup of sliderForm.get(
                                    'post_category'
                                )['controls'];
                                let i = index
                            "
                            [formGroup]="postCategoryFormGroup"
                        >
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="start center"
                                            fxLayoutGap="10px"
                                        >
                                            <mat-icon>drag_indicator</mat-icon>
                                            <span>{{
                                                postCategoryFormGroup.get(
                                                    "name"
                                                ).value
                                                    ? postCategoryFormGroup.get(
                                                          "name"
                                                      ).value
                                                    : sliderForm.get("type")
                                                          .value
                                            }}</span>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div
                                    fxFlex="1 1 auto"
                                    fxLayout="column"
                                    fxLayoutAlign="start stretch"
                                    fxLayoutGap="5px"
                                >
                                    <div
                                        fxFlex="1 1 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <div
                                            fxFlex="1 1 auto"
                                            fxLayoutAlign="start stretch"
                                            fxLayout="column"
                                            fxLayoutGap="10px"
                                        >
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    fxFlex="100"
                                                    class="example-full-width"
                                                    appearance="outline"
                                                >
                                                    <mat-label>{{
                                                        "Post Category"
                                                            | translate
                                                    }}</mat-label>
                                                    <input
                                                        (keyup)="
                                                            typeValueChanges(
                                                                $event,
                                                                typeEnum.POST_CATEGORY,
                                                                i
                                                            )
                                                        "
                                                        type="text"
                                                        placeholder="{{
                                                            'Post Category'
                                                                | translate
                                                        }}"
                                                        aria-label="Number"
                                                        matInput
                                                        [formControl]="
                                                            postCategoryFormGroup.get(
                                                                'content_text'
                                                            )
                                                        "
                                                        [matAutocomplete]="auto"
                                                    />
                                                    <mat-spinner
                                                        *ngIf="isLoading"
                                                        matSuffix
                                                        [diameter]="18"
                                                        style="
                                                            float: right;
                                                            margin-left: 8px;
                                                        "
                                                    ></mat-spinner>
                                                    <mat-autocomplete
                                                        (optionSelected)="
                                                            patchTypeIDtoContent(
                                                                $event.option
                                                                    .value,
                                                                typeEnum.POST_CATEGORY,
                                                                i
                                                            )
                                                        "
                                                        #auto="matAutocomplete"
                                                    >
                                                        <mat-option
                                                            *ngFor="
                                                                let option of postCategoryFormGroup.get(
                                                                    'post_category'
                                                                ).value
                                                            "
                                                            [value]="
                                                                option?.name
                                                            "
                                                        >
                                                            <img
                                                                *ngIf="
                                                                    option?.thumbnail
                                                                "
                                                                style="
                                                                    height: 40px !important;
                                                                    width: auto !important;
                                                                "
                                                                [src]="
                                                                    utilitiesService.bypassTrustedURL(
                                                                        option?.thumbnail
                                                                    )
                                                                "
                                                            />
                                                            {{ option?.name }}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <mat-action-row>
                                    <app-delete-button
                                        (deleteButtonClick)="
                                            delete(i, 'post_category')
                                        "
                                    >
                                    </app-delete-button>
                                </mat-action-row>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div
                        fxLayout="column"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        *ngIf="typeEnum.POST == sliderForm.get('type').value"
                    >
                        <mat-accordion
                            class="mb-16"
                            formArrayName="post"
                            cdkDropList
                            cdk-drag-preview
                            cdkDrag
                            [cdkDragData]="postFormGroup"
                            *ngFor="
                                let postFormGroup of sliderForm.get('post')[
                                    'controls'
                                ];
                                let i = index
                            "
                            [formGroup]="postFormGroup"
                        >
                            <mat-expansion-panel>
                                <div class="field-placeholder"></div>
                                <mat-expansion-panel-header>
                                    <mat-panel-title fxFlex="0 0 auto">
                                        <div
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="start center"
                                            fxLayoutGap="10px"
                                        >
                                            <mat-icon>drag_indicator</mat-icon>
                                            <span>{{
                                                postFormGroup.get("name").value
                                                    ? postFormGroup.get("name")
                                                          .value
                                                    : sliderForm.get("type")
                                                          .value
                                            }}</span>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div
                                    fxFlex="1 1 auto"
                                    fxLayout="column"
                                    fxLayoutAlign="start stretch"
                                    fxLayoutGap="5px"
                                >
                                    <div
                                        fxFlex="1 1 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start end"
                                        fxLayoutGap="5px"
                                    >
                                        <mat-form-field appearance="outline">
                                            <mat-select
                                                (selectionChange)="
                                                    languageChanges($event)
                                                "
                                                [formControl]="
                                                    postFormGroup.get(
                                                        'language'
                                                    )
                                                "
                                            >
                                                <mat-option value="en"
                                                    >En</mat-option
                                                >
                                                <mat-option value="ar"
                                                    >Ar</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div
                                        fxFlex="1 1 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <div
                                            fxLayout.lt-md="column"
                                            fxLayout.gt-sm="row"
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="start stretch"
                                            fxLayoutGap="1em"
                                        >
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                >
                                                    <mat-label>{{
                                                        "Name" | translate
                                                    }}</mat-label>
                                                    <input
                                                        [formControl]="
                                                            postFormGroup.get(
                                                                'name'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                    *ngIf="
                                                        postFormGroup.get(
                                                            'language'
                                                        ).value == 'en'
                                                    "
                                                >
                                                    <mat-label
                                                        class="text-capitalized"
                                                    >
                                                        {{
                                                            "Button Text (en)"
                                                                | translate
                                                        }}
                                                    </mat-label>
                                                    <input
                                                        [formControl]="
                                                            postFormGroup.get(
                                                                'button_text'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                    *ngIf="
                                                        postFormGroup.get(
                                                            'language'
                                                        ).value == 'ar'
                                                    "
                                                >
                                                    <mat-label
                                                        class="text-capitalized"
                                                    >
                                                        {{
                                                            "Button Text (ar)"
                                                                | translate
                                                        }}
                                                    </mat-label>
                                                    <input
                                                        [formControl]="
                                                            postFormGroup.get(
                                                                'button_text_ar'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div
                                            fxFlex="1 1 auto"
                                            fxLayoutAlign="start stretch"
                                            fxLayout="column"
                                            fxLayoutGap="10px"
                                        >
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    fxFlex="100"
                                                    class="example-full-width"
                                                    appearance="outline"
                                                >
                                                    <mat-label>{{
                                                        "Posts" | translate
                                                    }}</mat-label>
                                                    <input
                                                        placeholder="{{
                                                            'Posts' | translate
                                                        }}"
                                                        (keyup)="
                                                            typeValueChanges(
                                                                $event,
                                                                typeEnum.POST,
                                                                i
                                                            )
                                                        "
                                                        type="text"
                                                        aria-label="Number"
                                                        matInput
                                                        [formControl]="
                                                            postFormGroup.get(
                                                                'content_text'
                                                            )
                                                        "
                                                        [matAutocomplete]="auto"
                                                    />
                                                    <mat-spinner
                                                        *ngIf="isLoading"
                                                        matSuffix
                                                        [diameter]="18"
                                                        style="
                                                            float: right;
                                                            margin-left: 8px;
                                                        "
                                                    ></mat-spinner>
                                                    <mat-autocomplete
                                                        #auto="matAutocomplete"
                                                        (optionSelected)="
                                                            patchTypeIDtoContent(
                                                                $event.option
                                                                    .value,
                                                                typeEnum.POST,
                                                                i
                                                            )
                                                        "
                                                    >
                                                        <mat-option
                                                            *ngFor="
                                                                let option of postFormGroup.get(
                                                                    'posts'
                                                                ).value
                                                            "
                                                            [value]="
                                                                option?.title
                                                            "
                                                        >
                                                            <img
                                                                *ngIf="
                                                                    option?.thumbnail
                                                                "
                                                                style="
                                                                    height: 40px !important;
                                                                    width: auto !important;
                                                                "
                                                                [src]="
                                                                    utilitiesService.bypassTrustedURL(
                                                                        option?.thumbnail
                                                                    )
                                                                "
                                                            />
                                                            {{ option?.title }}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <mat-action-row>
                                    <app-delete-button
                                        (deleteButtonClick)="delete(i, 'post')"
                                    ></app-delete-button>
                                </mat-action-row>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div
                        fxLayout="column"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        *ngIf="
                            typeEnum.RELATED_PRODUCTS ==
                            sliderForm.get('type').value
                        "
                    >
                        <mat-accordion
                            class="mb-16"
                            formArrayName="post"
                            *ngFor="
                                let relatedFormGroup of sliderForm.get(
                                    'related_products'
                                )['controls'];
                                let i = index
                            "
                            [formGroup]="relatedFormGroup"
                        >
                            <mat-expansion-panel expanded="true">
                                <div class="field-placeholder"></div>
                                <mat-expansion-panel-header>
                                    <mat-panel-title fxFlex="0 0 auto">
                                        <div
                                            fxLayout="row"
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="space-between"
                                        >
                                            {{
                                                relatedFormGroup.get("name")
                                                    .value
                                                    ? relatedFormGroup.get(
                                                          "name"
                                                      ).value
                                                    : sliderForm.get("type")
                                                          .value
                                            }}
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div
                                    fxFlex="1 1 auto"
                                    fxLayout="column"
                                    fxLayoutAlign="start stretch"
                                    fxLayoutGap="5px"
                                >
                                    <div
                                        fxFlex="1 1 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <div
                                            fxLayout.lt-md="column"
                                            fxLayout.gt-sm="row"
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="start stretch"
                                            fxLayoutGap="1em"
                                        >
                                            <div
                                                fxFlex="1 1 auto"
                                                fxFlex.gt-md="1 1 50%"
                                            >
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                >
                                                    <mat-label>{{
                                                        "Display Count"
                                                            | translate
                                                    }}</mat-label>
                                                    <input
                                                        [formControl]="
                                                            relatedFormGroup.get(
                                                                'number_of_products'
                                                            )
                                                        "
                                                        type="number"
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                            </div>
                                            <div
                                                fxFlex="1 1 auto"
                                                fxFlex.gt-md="1 1 50%"
                                            >
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                >
                                                    <mat-label>{{
                                                        "Relation Types"
                                                            | translate
                                                    }}</mat-label>
                                                    <mat-select
                                                        [formControl]="
                                                            relatedFormGroup.get(
                                                                'relation_type'
                                                            )
                                                        "
                                                    >
                                                        <mat-option
                                                            [value]="
                                                                related?.id
                                                            "
                                                            *ngFor="
                                                                let related of relationTypes
                                                            "
                                                        >
                                                            {{ related?.name }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <mat-action-row>
                                    <!-- <app-delete-button (deleteButtonClick)="delete(i,'RELATED_PRODUCTS')">
                                    </app-delete-button> -->
                                </mat-action-row>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div
                        fxLayout="column"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        *ngIf="
                            typeEnum.RECENTLY_VIEWED_PRODUCTS ==
                            sliderForm.get('type').value
                        "
                    >
                        <mat-accordion
                            class="mb-16"
                            formArrayName="post"
                            *ngFor="
                                let relatedFormGroup of sliderForm.get(
                                    'recently_viewed_products'
                                )['controls'];
                                let i = index
                            "
                            [formGroup]="relatedFormGroup"
                        >
                            <mat-expansion-panel expanded="true">
                                <div class="field-placeholder"></div>
                                <mat-expansion-panel-header>
                                    <mat-panel-title fxFlex="0 0 auto">
                                        <div
                                            fxLayout="row"
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="space-between"
                                        >
                                            {{
                                                relatedFormGroup.get("name")
                                                    .value
                                                    ? relatedFormGroup.get(
                                                          "name"
                                                      ).value
                                                    : sliderForm.get("type")
                                                          .value
                                            }}
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div
                                    fxFlex="1 1 auto"
                                    fxLayout="column"
                                    fxLayoutAlign="start stretch"
                                    fxLayoutGap="5px"
                                >
                                    <div
                                        fxFlex="1 1 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <div
                                            fxLayout.lt-md="column"
                                            fxLayout.gt-sm="row"
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="start stretch"
                                            fxLayoutGap="1em"
                                        >
                                            <div
                                                fxFlex="1 1 auto"
                                                fxFlex.gt-md="1 1 auto"
                                            >
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                >
                                                    <mat-label>{{
                                                        "Display Count"
                                                            | translate
                                                    }}</mat-label>
                                                    <input
                                                        [formControl]="
                                                            relatedFormGroup.get(
                                                                'number_of_products'
                                                            )
                                                        "
                                                        type="number"
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div
                        fxLayout="column"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        *ngIf="
                            typeEnum.PRODUCT_SET == sliderForm.get('type').value
                        "
                    >
                        <mat-accordion
                            class="mb-16"
                            formArrayName="product_set"
                            *ngFor="
                                let productSetFormGroup of sliderForm.get(
                                    'product_set'
                                )['controls'];
                                let i = index
                            "
                            [formGroup]="productSetFormGroup"
                        >
                            <mat-expansion-panel expanded="true">
                                <div class="field-placeholder"></div>
                                <mat-expansion-panel-header>
                                    <mat-panel-title fxFlex="0 0 auto">
                                        <div
                                            fxLayout="row"
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="space-between"
                                        >
                                            {{
                                                productSetFormGroup.get("name")
                                                    .value
                                                    ? productSetFormGroup.get(
                                                          "name"
                                                      ).value
                                                    : sliderForm.get("type")
                                                          .value
                                            }}
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div
                                    fxFlex="1 1 auto"
                                    fxLayout="column"
                                    fxLayoutAlign="start stretch"
                                    fxLayoutGap="5px"
                                >
                                    <div
                                        fxFlex="1 1 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <div
                                            fxLayout.lt-md="column"
                                            fxLayout.gt-sm="row"
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="start stretch"
                                            fxLayoutGap="1em"
                                        >
                                            <div
                                                fxFlex="1 1 auto"
                                                fxFlex.gt-md="1 1 50%"
                                            >
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                >
                                                    <mat-label>{{
                                                        "Display Count"
                                                            | translate
                                                    }}</mat-label>
                                                    <input
                                                        [formControl]="
                                                            productSetFormGroup.get(
                                                                'number_of_products'
                                                            )
                                                        "
                                                        type="number"
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                            </div>
                                            <div
                                                fxFlex="1 1 auto"
                                                fxFlex.gt-md="1 1 50%"
                                            >
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                >
                                                    <mat-label>{{
                                                        "Product Set Types"
                                                            | translate
                                                    }}</mat-label>
                                                    <mat-select
                                                        (selectionChange)="
                                                            changeProductSet(i)
                                                        "
                                                        [formControl]="
                                                            productSetFormGroup.get(
                                                                'product_set_type'
                                                            )
                                                        "
                                                    >
                                                        <mat-option
                                                            [value]="
                                                                productset?.id
                                                            "
                                                            *ngFor="
                                                                let productset of productSet
                                                            "
                                                        >
                                                            {{
                                                                productset?.name
                                                            }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="
                                            productSetFormGroup.get(
                                                'product_set_type'
                                            ).value == '1'
                                        "
                                        fxFlex="1 1 auto"
                                        fxLayout="row"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <ng-select
                                            [appendTo]="'body'"
                                            placeholder="{{
                                                'Enter Tag Name' | translate
                                            }}"
                                            id="products"
                                            fxFlex="100"
                                            (input)="
                                                searchTags(
                                                    $event.target.value,
                                                    i
                                                )
                                            "
                                            [multiple]="true"
                                            [formControl]="
                                                productSetFormGroup.get(
                                                    'content'
                                                )
                                            "
                                        >
                                            <ng-option
                                                *ngFor="
                                                    let tags of productSetFormGroup.get(
                                                        'tags'
                                                    ).value
                                                "
                                                [value]="tags?.id"
                                            >
                                                {{ tags?.name }}
                                            </ng-option>
                                        </ng-select>
                                        <mat-spinner
                                            class="mat-spinner-product-set"
                                            *ngIf="isLoadingProductSet"
                                            [diameter]="18"
                                        ></mat-spinner>
                                    </div>
                                    <div
                                        *ngIf="
                                            productSetFormGroup.get(
                                                'product_set_type'
                                            ).value == '2'
                                        "
                                        fxFlex="1 1 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <mat-form-field
                                            appearance="outline"
                                            fxFlex="100"
                                        >
                                            <mat-label>{{
                                                "Product Types" | translate
                                            }}</mat-label>
                                            <mat-select
                                                [formControl]="
                                                    productSetFormGroup.get(
                                                        'content'
                                                    )
                                                "
                                            >
                                                <mat-option [value]="'1'">
                                                    {{ "Goods" | translate }}
                                                </mat-option>
                                                <mat-option [value]="'2'">
                                                    {{ "Service" | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div
                                        *ngIf="
                                            productSetFormGroup.get(
                                                'product_set_type'
                                            ).value == '3'
                                        "
                                        fxFlex="1 1 auto"
                                        fxLayout="row"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <ng-select
                                            [appendTo]="'body'"
                                            placeholder="{{
                                                'Enter Category' | translate
                                            }}"
                                            id="products"
                                            fxFlex="100"
                                            (input)="
                                                getCategory(
                                                    $event.target.value,
                                                    i,
                                                    true
                                                )
                                            "
                                            [multiple]="true"
                                            [formControl]="
                                                productSetFormGroup.get(
                                                    'content'
                                                )
                                            "
                                        >
                                            <ng-option
                                                *ngFor="
                                                    let category of productSetFormGroup.get(
                                                        'category'
                                                    ).value
                                                "
                                                [value]="category?.id"
                                            >
                                                {{ category?.name }}
                                            </ng-option>
                                        </ng-select>
                                        <mat-spinner
                                            class="mat-spinner-product-set"
                                            *ngIf="isLoadingProductSet"
                                            [diameter]="18"
                                        ></mat-spinner>
                                    </div>
                                    <div
                                        *ngIf="
                                            productSetFormGroup.get(
                                                'product_set_type'
                                            ).value == '5'
                                        "
                                        fxFlex="1 1 auto"
                                        fxLayout="row"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <ng-select
                                            [appendTo]="'body'"
                                            placeholder="{{
                                                'Enter Brand' | translate
                                            }}"
                                            id="products"
                                            fxFlex="100"
                                            (input)="
                                                getBrand(
                                                    $event.target.value,
                                                    i,
                                                    true
                                                )
                                            "
                                            [multiple]="false"
                                            [formControl]="
                                                productSetFormGroup.get(
                                                    'content'
                                                )
                                            "
                                        >
                                            <ng-option
                                                *ngFor="
                                                    let brand of productSetFormGroup.get(
                                                        'brand'
                                                    ).value
                                                "
                                                [value]="brand?.id"
                                            >
                                                {{ brand?.name }}
                                            </ng-option>
                                        </ng-select>
                                        <mat-spinner
                                            class="mat-spinner-product-set"
                                            *ngIf="isLoadingProductSet"
                                            [diameter]="18"
                                        ></mat-spinner>
                                    </div>
                                    <div
                                        *ngIf="
                                            productSetFormGroup.get(
                                                'product_set_type'
                                            ).value == '6'
                                        "
                                        fxLayout.lt-md="column"
                                        fxLayout.gt-sm="row"
                                        fxFlex="0 0 auto"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="1em"
                                    >
                                        <div
                                            fxFlex="1 1 auto"
                                            fxFlex.gt-md="1 1 50%"
                                        >
                                            <mat-form-field
                                                appearance="outline"
                                                fxFlex="100"
                                            >
                                                <mat-label>{{
                                                    "Max Price" | translate
                                                }}</mat-label>
                                                <input
                                                    [formControl]="
                                                        productSetFormGroup.get(
                                                            'max_price'
                                                        )
                                                    "
                                                    type="number"
                                                    matInput
                                                    name="title"
                                                />
                                            </mat-form-field>
                                        </div>
                                        <div
                                            fxFlex="1 1 auto"
                                            fxFlex.gt-md="1 1 50%"
                                        >
                                            <mat-form-field
                                                appearance="outline"
                                                fxFlex="100"
                                            >
                                                <mat-label>{{
                                                    "Min Price" | translate
                                                }}</mat-label>
                                                <input
                                                    [formControl]="
                                                        productSetFormGroup.get(
                                                            'min_price'
                                                        )
                                                    "
                                                    type="number"
                                                    matInput
                                                    name="title"
                                                />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="
                                            productSetFormGroup.get(
                                                'product_set_type'
                                            ).value == '7'
                                        "
                                        fxLayout.lt-md="column"
                                        fxLayout.gt-sm="row"
                                        fxFlex="0 0 auto"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="1em"
                                    >
                                        <div fxFlex="1 1 auto">
                                            <mat-form-field
                                                appearance="outline"
                                                fxFlex="100"
                                            >
                                                <mat-label>{{
                                                    "Name" | translate
                                                }}</mat-label>
                                                <input
                                                    [formControl]="
                                                        productSetFormGroup.get(
                                                            'content'
                                                        )
                                                    "
                                                    type="text"
                                                    matInput
                                                    name="title"
                                                />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <mat-action-row> </mat-action-row>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div
                        fxLayout="column"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        *ngIf="
                            typeEnum.CATEGORY == sliderForm.get('type').value
                        "
                    >
                        <mat-accordion
                            class="mb-16"
                            formArrayName="category"
                            cdkDropList
                            cdk-drag-preview
                            cdkDrag
                            [cdkDragData]="categoryFormGroup"
                            *ngFor="
                                let categoryFormGroup of sliderForm.get(
                                    'category'
                                )['controls'];
                                let i = index
                            "
                            [formGroup]="categoryFormGroup"
                        >
                            <mat-expansion-panel>
                                <div class="field-placeholder"></div>
                                <mat-expansion-panel-header>
                                    <mat-panel-title fxFlex="0 0 auto">
                                        <div
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="start center"
                                            fxLayoutGap="10px"
                                        >
                                            <mat-icon>drag_indicator</mat-icon>
                                            <span>
                                                {{
                                                    categoryFormGroup.get(
                                                        "name"
                                                    ).value
                                                        ? categoryFormGroup.get(
                                                              "name"
                                                          ).value
                                                        : sliderForm.get("type")
                                                              .value
                                                }}</span
                                            >
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div
                                    fxFlex="1 1 auto"
                                    fxLayout="column"
                                    fxLayoutAlign="start stretch"
                                    fxLayoutGap="5px"
                                >
                                    <div
                                        fxFlex="1 1 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start end"
                                        fxLayoutGap="5px"
                                    >
                                        <mat-form-field appearance="outline">
                                            <mat-select
                                                (selectionChange)="
                                                    languageChanges($event)
                                                "
                                                [formControl]="
                                                    categoryFormGroup.get(
                                                        'language'
                                                    )
                                                "
                                            >
                                                <mat-option value="en"
                                                    >En</mat-option
                                                >
                                                <mat-option value="ar"
                                                    >Ar</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div
                                        fxFlex="1 1 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <div
                                            fxLayout.lt-md="column"
                                            fxLayout.gt-sm="row"
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="start stretch"
                                            fxLayoutGap="1em"
                                        >
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                >
                                                    <mat-label>{{
                                                        "Name" | translate
                                                    }}</mat-label>
                                                    <input
                                                        [formControl]="
                                                            categoryFormGroup.get(
                                                                'name'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                    *ngIf="
                                                        categoryFormGroup.get(
                                                            'language'
                                                        ).value == 'en'
                                                    "
                                                >
                                                    <mat-label
                                                        class="text-capitalized"
                                                    >
                                                        {{
                                                            "Button Text (en)"
                                                                | translate
                                                        }}
                                                    </mat-label>
                                                    <input
                                                        [formControl]="
                                                            categoryFormGroup.get(
                                                                'button_text'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                    *ngIf="
                                                        categoryFormGroup.get(
                                                            'language'
                                                        ).value == 'ar'
                                                    "
                                                >
                                                    <mat-label
                                                        class="text-capitalized"
                                                    >
                                                        {{
                                                            "Button Text (ar)"
                                                                | translate
                                                        }}
                                                    </mat-label>
                                                    <input
                                                        [formControl]="
                                                            categoryFormGroup.get(
                                                                'button_text_ar'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div fxFlex="1 1 auto">
                                            <mat-form-field
                                                fxFlex="100"
                                                class="example-full-width"
                                                appearance="outline"
                                            >
                                                <mat-label>{{
                                                    "Category" | translate
                                                }}</mat-label>
                                                <input
                                                    (keyup)="
                                                        typeValueChanges(
                                                            $event,
                                                            typeEnum.CATEGORY,
                                                            i
                                                        )
                                                    "
                                                    type="text"
                                                    placeholder="{{
                                                        'Category' | translate
                                                    }}"
                                                    aria-label="Number"
                                                    matInput
                                                    [formControl]="
                                                        categoryFormGroup.get(
                                                            'content_text'
                                                        )
                                                    "
                                                    [matAutocomplete]="auto"
                                                />
                                                <mat-spinner
                                                    *ngIf="isLoading"
                                                    matSuffix
                                                    [diameter]="18"
                                                    style="
                                                        float: right;
                                                        margin-left: 8px;
                                                    "
                                                ></mat-spinner>
                                                <mat-autocomplete
                                                    (optionSelected)="
                                                        patchTypeIDtoContent(
                                                            $event.option.value,
                                                            typeEnum.CATEGORY,
                                                            i
                                                        )
                                                    "
                                                    #auto="matAutocomplete"
                                                >
                                                    <mat-option
                                                        [value]="option?.name"
                                                        *ngFor="
                                                            let option of categoryFormGroup.get(
                                                                'categories'
                                                            ).value
                                                        "
                                                    >
                                                        <img
                                                            *ngIf="
                                                                option?.image
                                                            "
                                                            style="
                                                                height: 40px !important;
                                                                width: auto !important;
                                                            "
                                                            [src]="
                                                                utilitiesService.bypassTrustedURL(
                                                                    option?.image
                                                                )
                                                            "
                                                        />
                                                        {{ option?.name }}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <mat-action-row>
                                    <app-delete-button
                                        (deleteButtonClick)="
                                            delete(i, 'category')
                                        "
                                    ></app-delete-button>
                                </mat-action-row>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div
                        fxLayout="column"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        *ngIf="typeEnum.HTML == sliderForm.get('type').value"
                    >
                        <mat-accordion
                            class="mb-16"
                            formArrayName="html"
                            cdkDropList
                            cdk-drag-preview
                            cdkDrag
                            [cdkDragData]="htmlFormGroup"
                            *ngFor="
                                let htmlFormGroup of sliderForm.get('html')[
                                    'controls'
                                ];
                                let i = index
                            "
                            [formGroup]="htmlFormGroup"
                        >
                            <mat-expansion-panel>
                                <div class="field-placeholder"></div>
                                <mat-expansion-panel-header>
                                    <mat-panel-title fxFlex="0 0 auto">
                                        <div
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="start center"
                                            fxLayoutGap="10px"
                                        >
                                            <mat-icon>drag_indicator</mat-icon>
                                            <span>
                                                {{
                                                    htmlFormGroup.get("name")
                                                        .value
                                                        ? htmlFormGroup.get(
                                                              "name"
                                                          ).value
                                                        : sliderForm.get("type")
                                                              .value
                                                }}</span
                                            >
                                        </div>
                                        <div
                                            fxLayout="row"
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="space-between"
                                        ></div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div
                                    fxFlex="1 1 auto"
                                    fxLayout="column"
                                    fxLayoutAlign="start stretch"
                                    fxLayoutGap="5px"
                                >
                                    <div
                                        fxFlex="1 1 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <div
                                            fxFlex="1 1 auto"
                                            fxLayout="column"
                                            fxLayoutAlign="start end"
                                            fxLayoutGap="5px"
                                        >
                                            <mat-form-field
                                                appearance="outline"
                                            >
                                                <mat-select
                                                    (selectionChange)="
                                                        languageChanges($event)
                                                    "
                                                    [formControl]="
                                                        htmlFormGroup.get(
                                                            'language'
                                                        )
                                                    "
                                                >
                                                    <mat-option value="en"
                                                        >En</mat-option
                                                    >
                                                    <mat-option value="ar"
                                                        >Ar</mat-option
                                                    >
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div
                                            fxLayout.lt-md="column"
                                            fxLayout.gt-sm="row"
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="start stretch"
                                            fxLayoutGap="1em"
                                        >
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                >
                                                    <mat-label>{{
                                                        "Name" | translate
                                                    }}</mat-label>
                                                    <input
                                                        [formControl]="
                                                            htmlFormGroup.get(
                                                                'name'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                    *ngIf="
                                                        htmlFormGroup.get(
                                                            'language'
                                                        ).value == 'en'
                                                    "
                                                >
                                                    <mat-label
                                                        class="text-capitalized"
                                                    >
                                                        {{
                                                            "Button Text (en)"
                                                                | translate
                                                        }}
                                                    </mat-label>
                                                    <input
                                                        [formControl]="
                                                            htmlFormGroup.get(
                                                                'button_text'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                    *ngIf="
                                                        htmlFormGroup.get(
                                                            'language'
                                                        ).value == 'ar'
                                                    "
                                                >
                                                    <mat-label
                                                        class="text-capitalized"
                                                    >
                                                        {{
                                                            "Button Text (ar)"
                                                                | translate
                                                        }}
                                                    </mat-label>
                                                    <input
                                                        [formControl]="
                                                            htmlFormGroup.get(
                                                                'button_text_ar'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div
                                            fxFlex="1 1 100%"
                                            fxLayout="column"
                                            class="mb-8"
                                            fxLayoutGap="1em"
                                        >
                                            <mat-slide-toggle
                                                class="mb-8 align-self"
                                                *ngIf="
                                                    htmlFormGroup.get(
                                                        'language'
                                                    ).value == 'en'
                                                "
                                                [formControl]="
                                                    htmlFormGroup.get(
                                                        'isCodeEditor'
                                                    )
                                                "
                                            >
                                                {{
                                                    !htmlFormGroup.get(
                                                        "isCodeEditor"
                                                    ).value
                                                        ? ("Switch to Code Editor"
                                                          | translate)
                                                        : ("Switch to HTML Editor"
                                                          | translate)
                                                }}
                                            </mat-slide-toggle>
                                            <mat-slide-toggle
                                                class="mb-8 align-self"
                                                *ngIf="
                                                    htmlFormGroup.get(
                                                        'language'
                                                    ).value == 'ar'
                                                "
                                                [formControl]="
                                                    htmlFormGroup.get(
                                                        'isCodeEditor'
                                                    )
                                                "
                                            >
                                                {{
                                                    !htmlFormGroup.get(
                                                        "isCodeEditor"
                                                    ).value
                                                        ? ("Switch to Code Editor"
                                                          | translate)
                                                        : ("Switch to HTML Editor"
                                                          | translate)
                                                }}
                                            </mat-slide-toggle>
                                            <ckeditor
                                                [@animate]="{
                                                    value: '*',
                                                    params: {
                                                        duration: '300ms',
                                                        y: '50px'
                                                    }
                                                }"
                                                *ngIf="
                                                    htmlFormGroup.get(
                                                        'language'
                                                    ).value == 'en' &&
                                                    !htmlFormGroup.controls
                                                        .isCodeEditor.value
                                                "
                                                [formControl]="
                                                    htmlFormGroup.get('content')
                                                "
                                                ngDefaultControl
                                                name="myckeditor"
                                                [config]="ckeConfig"
                                            >
                                            </ckeditor>
                                            <div
                                                fxFlex="0 0 auto"
                                                fxLayout="column"
                                                *ngIf="
                                                    htmlFormGroup.get(
                                                        'language'
                                                    ).value == 'en' &&
                                                    htmlFormGroup.controls
                                                        .isCodeEditor.value
                                                "
                                            >
                                                <mat-icon
                                                    class="zoom-icon cursor-pointer highlight-text-color"
                                                    (click)="
                                                        zoomHTML(
                                                            htmlFormGroup.get(
                                                                'content'
                                                            ),
                                                            i,
                                                            'content'
                                                        )
                                                    "
                                                >
                                                    zoom_out_map</mat-icon
                                                >
                                                <ace-editor
                                                    [@animate]="{
                                                        value: '*',
                                                        params: {
                                                            duration: '300ms',
                                                            y: '50px'
                                                        }
                                                    }"
                                                    [theme]="'clouds'"
                                                    [mode]="'html'"
                                                    [options]="options"
                                                    [formControl]="
                                                        htmlFormGroup.get(
                                                            'content'
                                                        )
                                                    "
                                                    style="height: 50vh"
                                                >
                                                </ace-editor>
                                            </div>

                                            <div
                                                fxFlex="0 0 auto"
                                                fxLayout="column"
                                                *ngIf="
                                                    htmlFormGroup.get(
                                                        'language'
                                                    ).value == 'ar' &&
                                                    htmlFormGroup.controls
                                                        .isCodeEditor.value
                                                "
                                            >
                                                <mat-icon
                                                    class="zoom-icon highlight-text-color cursor-pointer"
                                                    (click)="
                                                        zoomHTML(
                                                            htmlFormGroup.get(
                                                                'content_ar'
                                                            ),
                                                            i,
                                                            'content_ar'
                                                        )
                                                    "
                                                >
                                                    zoom_out_map</mat-icon
                                                >
                                                <ace-editor
                                                    [@animate]="{
                                                        value: '*',
                                                        params: {
                                                            duration: '300ms',
                                                            y: '50px'
                                                        }
                                                    }"
                                                    [theme]="'clouds'"
                                                    [mode]="'html'"
                                                    [options]="options"
                                                    [formControl]="
                                                        htmlFormGroup.get(
                                                            'content_ar'
                                                        )
                                                    "
                                                    style="height: 50vh"
                                                >
                                                </ace-editor>
                                            </div>

                                            <ckeditor
                                                [@animate]="{
                                                    value: '*',
                                                    params: {
                                                        duration: '300ms',
                                                        y: '50px'
                                                    }
                                                }"
                                                *ngIf="
                                                    htmlFormGroup.get(
                                                        'language'
                                                    ).value == 'ar' &&
                                                    !htmlFormGroup.controls
                                                        .isCodeEditor.value
                                                "
                                                [formControl]="
                                                    htmlFormGroup.get(
                                                        'content_ar'
                                                    )
                                                "
                                                ngDefaultControl
                                                name="myckeditor1"
                                                [config]="ckeConfig"
                                            >
                                            </ckeditor>
                                        </div>
                                    </div>
                                </div>
                                <mat-action-row>
                                    <app-delete-button
                                        (deleteButtonClick)="delete(i, 'html')"
                                    ></app-delete-button>
                                </mat-action-row>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div
                        fxLayout="column"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        *ngIf="typeEnum.IMAGE == sliderForm.get('type').value"
                    >
                        <mat-accordion
                            class="mb-16"
                            formArrayName="image"
                            cdkDropList
                            cdk-drag-preview
                            cdkDrag
                            [cdkDragData]="imageFormGroup"
                            *ngFor="
                                let imageFormGroup of sliderForm.get('image')[
                                    'controls'
                                ];
                                let i = index
                            "
                            [formGroup]="imageFormGroup"
                        >
                            <mat-expansion-panel>
                                <div class="field-placeholder"></div>
                                <mat-expansion-panel-header>
                                    <mat-panel-title fxFlex="0 0 auto">
                                        <div
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="start center"
                                            fxLayoutGap="10px"
                                        >
                                            <mat-icon>drag_indicator</mat-icon>
                                            <span>
                                                {{
                                                    imageFormGroup.get("name")
                                                        .value
                                                        ? imageFormGroup.get(
                                                              "name"
                                                          ).value
                                                        : sliderForm.get("type")
                                                              .value
                                                }}</span
                                            >
                                        </div>
                                        <div
                                            fxLayout="row"
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="space-between"
                                        ></div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div
                                    fxFlex="1 1 auto"
                                    fxLayout="column"
                                    fxLayoutAlign="start stretch"
                                    fxLayoutGap="5px"
                                >
                                    <div
                                        fxFlex="1 1 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <div
                                            fxFlex="1 1 auto"
                                            fxLayout="column"
                                            fxLayoutAlign="start end"
                                            fxLayoutGap="5px"
                                        >
                                            <mat-form-field
                                                appearance="outline"
                                            >
                                                <mat-select
                                                    (selectionChange)="
                                                        languageChanges($event)
                                                    "
                                                    [formControl]="
                                                        imageFormGroup.get(
                                                            'language'
                                                        )
                                                    "
                                                >
                                                    <mat-option value="en"
                                                        >En</mat-option
                                                    >
                                                    <mat-option value="ar"
                                                        >Ar</mat-option
                                                    >
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div
                                            fxLayout.lt-md="column"
                                            fxLayout.gt-sm="row"
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="start stretch"
                                            fxLayoutGap="1em"
                                        >
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                >
                                                    <mat-label>{{
                                                        "Name" | translate
                                                    }}</mat-label>
                                                    <input
                                                        [formControl]="
                                                            imageFormGroup.get(
                                                                'name'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                >
                                                    <mat-label
                                                        class="text-capitalized"
                                                        >{{ "URL" | translate }}
                                                    </mat-label>
                                                    <input
                                                        [formControl]="
                                                            imageFormGroup.get(
                                                                'url'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div
                                            fxLayout.lt-md="column"
                                            fxLayout.gt-sm="row"
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="start stretch"
                                            fxLayoutGap="1em"
                                        >
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                    *ngIf="
                                                        imageFormGroup.get(
                                                            'language'
                                                        ).value == 'en'
                                                    "
                                                >
                                                    <mat-label
                                                        class="text-capitalized"
                                                    >
                                                        {{
                                                            "Button Text (en)"
                                                                | translate
                                                        }}
                                                    </mat-label>
                                                    <input
                                                        [formControl]="
                                                            imageFormGroup.get(
                                                                'button_text'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                    *ngIf="
                                                        imageFormGroup.get(
                                                            'language'
                                                        ).value == 'ar'
                                                    "
                                                >
                                                    <mat-label
                                                        class="text-capitalized"
                                                    >
                                                        {{
                                                            "Button Text (ar)"
                                                                | translate
                                                        }}
                                                    </mat-label>
                                                    <input
                                                        [formControl]="
                                                            imageFormGroup.get(
                                                                'button_text_ar'
                                                            )
                                                        "
                                                        matInput
                                                        name="title"
                                                    />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div
                                            fxFlex="1 1 100%"
                                            fxLayout="column"
                                            fxLayoutAlign="start stretch"
                                            fxLayoutGap="5px"
                                            *ngIf="
                                                imageFormGroup.get('language')
                                                    .value == 'en' &&
                                                !imageFormGroup.get(
                                                    'file_url_en'
                                                ).value
                                            "
                                        >
                                            <div
                                                fxLayout="column"
                                                fxFlex="0 0 auto"
                                                fxLayoutAlign="start stretch"
                                                appDnd
                                                *ngIf="
                                                    !imageFormGroup.get(
                                                        'file_url_en'
                                                    ).value
                                                "
                                            >
                                                <app-cms-upload-media
                                                    (afterUpload)="
                                                        setFileToForm(
                                                            i,
                                                            $event,
                                                            imageFormGroup.get(
                                                                'language'
                                                            ).value
                                                        )
                                                    "
                                                    [isMultiSelect]="false"
                                                    [formcontrol]="
                                                        imageFormGroup.get(
                                                            'file_url_en'
                                                        )
                                                    "
                                                    [index]="i"
                                                >
                                                </app-cms-upload-media>
                                                <!-- <input accept="image/x-png,image/gif,image/jpeg" class="attach-input"
                                                    type="file" id="fileDropRef{{i}}"
                                                    (change)="fileBrowseHandler(i,$event.target.files,imageFormGroup.get('language').value)" />
                                                <label class="attach-label attach-label-file"
                                                    for="fileDropRef{{i}}">{{'Browse for file' | translate}}</label>
                                                <label>{{'Or' | translate}}</label>
                                                <label (click)="openGallery(imageFormGroup.get('file_url_en'),false)"
                                                    class="attach-label">{{'Browse from media' | translate}}</label> -->
                                            </div>
                                        </div>
                                        <div
                                            fxFlex="1 1 100%"
                                            fxLayout="column"
                                            fxLayoutAlign="start stretch"
                                            fxLayoutGap="5px"
                                            *ngIf="
                                                imageFormGroup.get(
                                                    'file_url_en'
                                                ).value &&
                                                imageFormGroup.get('language')
                                                    .value == 'en'
                                            "
                                        >
                                            <div
                                                class="fuse-card auto-width box-shadow-none"
                                                fxFlex="0 0 auto"
                                                fxLayoutAlign="space-between center"
                                                fxLayoutGap="5px"
                                                fxLayout="row"
                                            >
                                                <div class="img-container">
                                                    <app-show-all-type-media
                                                        [url]="
                                                            imageFormGroup.get(
                                                                'file_url_en'
                                                            ).value
                                                        "
                                                    >
                                                    </app-show-all-type-media>
                                                    <!-- <img class="added-img"
                                                        [src]="utilitiesService.bypassTrustedURL(imageFormGroup.get('file_url_en').value)"> -->
                                                </div>
                                                <div
                                                    class="px-16 py-4"
                                                    fxLayout="row"
                                                    fxLayoutAlign="center center"
                                                >
                                                    <button
                                                        mat-icon-button
                                                        aria-label="Delete"
                                                    >
                                                        <mat-icon
                                                            (click)="
                                                                deleteFile(
                                                                    i,
                                                                    imageFormGroup.get(
                                                                        'language'
                                                                    ).value
                                                                )
                                                            "
                                                            class="action-btn-delete"
                                                            >delete</mat-icon
                                                        >
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            fxFlex="1 1 100%"
                                            fxLayout="column"
                                            fxLayoutAlign="start stretch"
                                            fxLayoutGap="5px"
                                            *ngIf="
                                                imageFormGroup.get('language')
                                                    .value == 'ar' &&
                                                !imageFormGroup.get(
                                                    'file_url_ar'
                                                ).value
                                            "
                                        >
                                            <div
                                                fxLayout="column"
                                                fxFlex="0 0 auto"
                                                fxLayoutAlign="start stretch"
                                                *ngIf="
                                                    !imageFormGroup.get(
                                                        'file_url_ar'
                                                    ).value
                                                "
                                            >
                                                <app-cms-upload-media
                                                    (afterUpload)="
                                                        setFileToForm(
                                                            i,
                                                            $event,
                                                            imageFormGroup.get(
                                                                'language'
                                                            ).value
                                                        )
                                                    "
                                                    [isMultiSelect]="false"
                                                    [formcontrol]="
                                                        imageFormGroup.get(
                                                            'file_url_ar'
                                                        )
                                                    "
                                                    [index]="i"
                                                >
                                                </app-cms-upload-media>
                                                <!-- <input accept="image/x-png,image/gif,image/jpeg" class="attach-input"
                                                    type="file" id="fileDropRef{{i}}"
                                                    (change)="fileBrowseHandler(i,$event.target.files,imageFormGroup.get('language').value)" />
                                                <label class="attach-label attach-label-file"
                                                    for="fileDropRef{{i}}">{{'Browse for file' | translate}}</label>
                                                <label>{{'Or' | translate}}</label>
                                                <label (click)="openGallery(imageFormGroup.get('file_url_ar'),false)"
                                                    class="attach-label">{{'Browse from media' | translate}}</label> -->
                                            </div>
                                        </div>
                                        <div
                                            fxFlex="1 1 100%"
                                            fxLayout="column"
                                            fxLayoutAlign="start stretch"
                                            fxLayoutGap="5px"
                                            *ngIf="
                                                imageFormGroup.get(
                                                    'file_url_ar'
                                                ).value &&
                                                imageFormGroup.get('language')
                                                    .value == 'ar'
                                            "
                                        >
                                            <div
                                                class="fuse-card auto-width box-shadow-none"
                                                fxFlex="0 0 auto"
                                                fxLayoutAlign="space-between center"
                                                fxLayoutGap="5px"
                                                fxLayout="row"
                                            >
                                                <div class="img-container">
                                                    <app-show-all-type-media
                                                        [url]="
                                                            imageFormGroup.get(
                                                                'file_url_ar'
                                                            ).value
                                                        "
                                                    >
                                                    </app-show-all-type-media>
                                                    <!-- <img class="added-img"
                                                        [src]="utilitiesService.bypassTrustedURL(imageFormGroup.get('file_url_ar').value)"> -->
                                                </div>
                                                <div
                                                    class="px-16 py-4"
                                                    fxLayout="row"
                                                    fxLayoutAlign="center center"
                                                >
                                                    <button
                                                        mat-icon-button
                                                        aria-label="Delete"
                                                    >
                                                        <mat-icon
                                                            (click)="
                                                                deleteFile(
                                                                    i,
                                                                    imageFormGroup.get(
                                                                        'language'
                                                                    ).value
                                                                )
                                                            "
                                                            class="action-btn-delete"
                                                            >delete</mat-icon
                                                        >
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <mat-action-row>
                                    <app-delete-button
                                        (deleteButtonClick)="delete(i, 'image')"
                                    ></app-delete-button>
                                </mat-action-row>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>
        <div
            fxFlex="1 1 auto"
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="8px"
        >
            <div
                fxFlex="0 0 auto"
                fxLayout="row"
                fxLayoutAlign="space-between"
                fxLayoutGap="8px"
            >
                <button
                    mat-flat-button
                    (click)="handleCancel()"
                    class="secondary-bg-color"
                >
                    {{ "Cancel" | translate }}
                </button>
                <button
                    (click)="save()"
                    type="submit"
                    mat-flat-button
                    class="primary-bg-color"
                >
                    {{ "Save" | translate }}
                </button>
            </div>

            <div
                fxFlex="1 1 auto"
                fxLayout="column"
                fxLayoutAlign="start stretch"
                fxLayoutGap="1em"
            >
                <div
                    fxLayout="column"
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                >
                    <div fxFlex="1 1 auto">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{ "Type" | translate }}</mat-label>
                            <mat-select
                                matTooltip="{{
                                    'help_text_slider_type' | translate
                                }}"
                                [disabled]="id ? true : false"
                                formControlName="type"
                                (selectionChange)="typeChange($event.value)"
                            >
                                <mat-option
                                    *ngFor="let type of types"
                                    [value]="type.name"
                                    >{{ type.name }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="1 1 auto">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{ "Show As" | translate }}</mat-label>
                            <mat-select
                                matTooltip="{{
                                    'help_text_slider_showas' | translate
                                }}"
                                formControlName="show_as"
                            >
                                <mat-option
                                    *ngFor="let showas of showAs"
                                    [value]="showas?.id"
                                    >{{ showas?.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="1 1 auto">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{ "Name" | translate }}</mat-label>
                            <input
                                matTooltip="{{
                                    'help_text_slider_Name' | translate
                                }}"
                                formControlName="name"
                                matInput
                                name="title"
                            />
                            <mat-error
                                *ngIf="
                                    sliderForm.get('name').hasError('required')
                                "
                            >
                                {{ "Name is required" | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div
                        fxFlex="1 1 auto"
                        fxLayout="column"
                        fxLayoutAlign="start stretch"
                        fxLayoutGap="1.5em"
                        *ngIf="sliderForm.get('show_as').value == '1'"
                    >
                        <div
                            fxFlex="1 1 auto"
                            fxLayout="column"
                            fxLayoutAlign="start stretch"
                            fxLayoutGap="0px"
                        >
                            <div
                                fxFlex="1 1 auto"
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                                fxLayoutGap="0px"
                            >
                                <span class="slide-text-color font-size-12">
                                    {{ "Slide Per View Large" | translate }}
                                    <span class="slider-value">{{
                                        sliderForm.get("slides_per_view_large")
                                            .value
                                    }}</span>
                                </span>
                                <mat-icon class="highlight-text-color"
                                    >desktop_mac</mat-icon
                                >
                            </div>
                            <mat-slider
                                formControlName="slides_per_view_large"
                                class="example-margin"
                                [max]="'12'"
                                [min]="'1'"
                                [step]="'1'"
                                [thumbLabel]="'true'"
                                [tickInterval]="tickInterval"
                            >
                            </mat-slider>
                            <div
                                fxFlex="1 1 auto"
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                                fxLayoutGap="0px"
                            >
                                <span class="slide-text-color font-size-12">
                                    {{ "Slides Move Per Click" | translate }}
                                    <span class="slider-value">{{
                                        sliderForm.get("slider_per_click_large")
                                            .value
                                    }}</span>
                                </span>
                            </div>
                            <mat-slider
                                formControlName="slider_per_click_large"
                                class="example-margin"
                                [max]="'12'"
                                [min]="'1'"
                                [step]="'1'"
                                [thumbLabel]="'true'"
                                [tickInterval]="tickInterval"
                            >
                            </mat-slider>
                        </div>
                        <div
                            fxFlex="1 1 auto"
                            fxLayout="column"
                            fxLayoutAlign="start stretch"
                            fxLayoutGap="0px"
                        >
                            <div
                                fxFlex="1 1 auto"
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                                fxLayoutGap="0px"
                            >
                                <span class="slide-text-color font-size-12">
                                    {{ "Slide Per View Small" | translate }}
                                    <span class="slider-value">{{
                                        sliderForm.get("slides_per_view_small")
                                            .value
                                    }}</span>
                                </span>
                                <mat-icon class="highlight-text-color"
                                    >tablet_mac</mat-icon
                                >
                            </div>
                            <mat-slider
                                formControlName="slides_per_view_small"
                                class="example-margin"
                                [max]="'12'"
                                [min]="'1'"
                                [step]="'1'"
                                [thumbLabel]="'true'"
                                [tickInterval]="tickInterval"
                            >
                            </mat-slider>
                            <div
                                fxFlex="1 1 auto"
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                                fxLayoutGap="0px"
                            >
                                <span class="slide-text-color font-size-12">
                                    {{ "Slides Move Per Click" | translate }}
                                    <span class="slider-value">{{
                                        sliderForm.get("slider_per_click_small")
                                            .value
                                    }}</span>
                                </span>
                            </div>
                            <mat-slider
                                formControlName="slider_per_click_small"
                                class="example-margin"
                                [max]="'12'"
                                [min]="'1'"
                                [step]="'1'"
                                [thumbLabel]="'true'"
                                [tickInterval]="tickInterval"
                            >
                            </mat-slider>
                        </div>
                        <div
                            fxFlex="1 1 auto"
                            fxLayout="column"
                            fxLayoutAlign="start stretch"
                            fxLayoutGap="0px"
                        >
                            <div
                                fxFlex="1 1 auto"
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                                fxLayoutGap="0px"
                            >
                                <span class="slide-text-color font-size-12">
                                    {{ "Slide Per View Mobile" | translate }}
                                    <span class="slider-value">{{
                                        sliderForm.get("slides_per_view_mobile")
                                            .value
                                    }}</span>
                                </span>
                                <mat-icon class="highlight-text-color"
                                    >phone_iphone</mat-icon
                                >
                            </div>
                            <mat-slider
                                formControlName="slides_per_view_mobile"
                                class="example-margin"
                                [max]="'12'"
                                [min]="'1'"
                                [step]="'1'"
                                [thumbLabel]="'true'"
                                [tickInterval]="tickInterval"
                            >
                            </mat-slider>
                            <div
                                fxFlex="1 1 auto"
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                                fxLayoutGap="0px"
                            >
                                <span class="slide-text-color font-size-12">
                                    {{'Slides Move Per Click' |translate}}
                                    <span class="slider-value">{{sliderForm.get('slider_per_click_mobile').value}}</span>
                                </span>
                            </div>
                            <mat-slider
                                formControlName="slider_per_click_mobile"
                                class="example-margin"
                                [max]="'12'"
                                [min]="'1'"
                                [step]="'1'"
                                [thumbLabel]="'true'"
                                [tickInterval]="tickInterval"
                            >
                            </mat-slider>
                        </div>
                    </div>
                    <div
                        fxFlex="1 1 auto"
                        fxLayout="column"
                        fxLayoutAlign="start stretch"
                        fxLayoutGap="0px"
                        *ngIf="sliderForm.get('show_as').value == '2'"
                    >
                        <div
                            fxFlex="1 1 auto"
                            fxLayout="column"
                            fxLayoutAlign="start stretch"
                            fxLayoutGap="0px"
                        >
                            <div
                                fxFlex="1 1 auto"
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                                fxLayoutGap="0px"
                            >
                                <span class="slide-text-color font-size-12">
                                    {{ "Content Per Row Large" | translate }}
                                    <span class="slider-value">{{
                                        sliderForm.get("content_per_row_large")
                                            .value
                                    }}</span>
                                </span>
                                <mat-icon class="highlight-text-color"
                                    >desktop_mac</mat-icon
                                >
                            </div>
                            <mat-slider
                                formControlName="content_per_row_large"
                                class="example-margin"
                                [max]="'12'"
                                [min]="'1'"
                                [step]="'1'"
                                [thumbLabel]="'true'"
                                [tickInterval]="tickInterval"
                            >
                            </mat-slider>
                        </div>
                        <div
                            fxFlex="1 1 auto"
                            fxLayout="column"
                            fxLayoutAlign="start stretch"
                            fxLayoutGap="0px"
                        >
                            <div
                                fxFlex="1 1 auto"
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                                fxLayoutGap="0px"
                            >
                                <span class="slide-text-color font-size-12">
                                    {{ "Content Per Row Small" | translate }}
                                    <span class="slider-value">{{
                                        sliderForm.get("content_per_row_medium")
                                            .value
                                    }}</span>
                                </span>
                                <mat-icon class="highlight-text-color"
                                    >tablet_mac</mat-icon
                                >
                            </div>
                            <mat-slider
                                formControlName="content_per_row_medium"
                                class="example-margin"
                                [max]="'12'"
                                [min]="'1'"
                                [step]="'1'"
                                [thumbLabel]="'true'"
                                [tickInterval]="tickInterval"
                            >
                            </mat-slider>
                        </div>
                        <div
                            fxFlex="1 1 auto"
                            fxLayout="column"
                            fxLayoutAlign="start stretch"
                            fxLayoutGap="0px"
                        >
                            <div
                                fxFlex="1 1 auto"
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                                fxLayoutGap="0px"
                            >
                                <span class="slide-text-color font-size-12">
                                    {{ "Content Per Row Mobile" | translate }}
                                    <span class="slider-value">{{
                                        sliderForm.get("content_per_row_small")
                                            .value
                                    }}</span>
                                </span>
                                <mat-icon class="highlight-text-color"
                                    >phone_iphone</mat-icon
                                >
                            </div>
                            <mat-slider
                                formControlName="content_per_row_small"
                                class="example-margin"
                                [max]="'12'"
                                [min]="'1'"
                                [step]="'1'"
                                [thumbLabel]="'true'"
                                [tickInterval]="tickInterval"
                            >
                            </mat-slider>
                        </div>
                    </div>
                    <div
                        fxFlex="1 1 auto"
                        *ngIf="sliderForm.get('show_as').value == '1'"
                    >
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{
                                "Space Between" | translate
                            }}</mat-label>
                            <input
                                matTooltip="{{
                                    'help_text_slider_SpaceBetween' | translate
                                }}"
                                type="number"
                                formControlName="space_between"
                                matInput
                                name="title"
                            />
                        </mat-form-field>
                    </div>
                    <div
                        fxFlex="1 1 auto"
                        *ngIf="sliderForm.get('show_as').value == '1'"
                    >
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{ "Animation" | translate }}</mat-label>
                            <input
                                matTooltip="{{
                                    'help_text_slider_Animation' | translate
                                }}"
                                type="text"
                                formControlName="animation"
                                matInput
                                name="title"
                            />
                        </mat-form-field>
                    </div>
                    <div
                        fxLayout="row"
                        fxLayoutAlign.lt-md="start stretch"
                        fxLayoutAlign.gt-sm="start center"
                        fxLayoutAlign="start center"
                        fxFlex="0 0 auto"
                        fxLayoutGap="1em"
                    >
                        <div
                            fxFlex="1 1 auto"
                            *ngIf="sliderForm.get('show_as').value == '1'"
                        >
                            <mat-checkbox
                                matTooltip="{{
                                    'help_text_slider_Loop' | translate
                                }}"
                                formControlName="loop"
                            >
                                <span class="text-capitalized">{{
                                    "Loop" | translate
                                }}</span>
                            </mat-checkbox>
                        </div>
                        <div fxFlex="1 1 auto">
                            <mat-checkbox
                                matTooltip="{{
                                    'help_text_slider_Zoom' | translate
                                }}"
                                formControlName="zoom"
                            >
                                <span class="text-capitalized">{{
                                    "Zoom" | translate
                                }}</span>
                            </mat-checkbox>
                        </div>
                        <div
                            fxFlex="1 1 auto"
                            *ngIf="sliderForm.get('show_as').value == '1'"
                        >
                            <mat-checkbox
                                matTooltip="{{
                                    'help_text_slider_FreeMode' | translate
                                }}"
                                formControlName="free_mode"
                            >
                                <span class="text-capitalized">{{
                                    "Free Mode" | translate
                                }}</span>
                            </mat-checkbox>
                        </div>
                    </div>
                    <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutAlign.lt-md="start stretch"
                        fxLayoutAlign.gt-sm="start center"
                        fxFlex="0 0 auto"
                        fxLayoutGap="1em"
                    >
                        <div
                            fxFlex="1 1 auto"
                            *ngIf="sliderForm.get('show_as').value == '1'"
                        >
                            <mat-checkbox
                                matTooltip="{{
                                    'help_text_slider_AutoPlay' | translate
                                }}"
                                formControlName="auto_play"
                            >
                                <span class="text-capitalized">{{
                                    "Auto Play" | translate
                                }}</span>
                            </mat-checkbox>
                        </div>

                        <div
                            fxFlex="1 1 auto"
                            fxLayoutAlign="start stretch"
                            *ngIf="
                                !sliderForm.get('auto_play').value &&
                                sliderForm.get('show_as').value == '1'
                            "
                        >
                            <mat-checkbox
                                matTooltip="{{
                                    'help_text_slider_dotted_Navigation'
                                        | translate
                                }}"
                                formControlName="dotted_navigation"
                            >
                                <span class="text-capitalized">{{
                                    "Dotted Navigation" | translate
                                }}</span>
                            </mat-checkbox>
                        </div>
                        <div fxFlex="1 1 auto">
                            <div
                                fxLayout="column"
                                *ngIf="
                                    sliderForm.get('auto_play').value &&
                                    sliderForm.get('show_as').value == '1'
                                "
                                fxLayoutAlign="start start"
                                fxFlex="0 0 auto"
                                fxLayoutGap="0px"
                            >
                                <mat-form-field
                                    appearance="outline"
                                    fxFlex="100"
                                >
                                    <mat-label>{{
                                        "Delay (ms)" | translate
                                    }}</mat-label>
                                    <input
                                        matTooltip="{{
                                            'help_text_slider_Delay' | translate
                                        }}"
                                        type="number"
                                        formControlName="delay"
                                        matInput
                                        name="Delay"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch"
                        *ngIf="!sliderForm.get('auto_play').value && sliderForm.get('show_as').value=='1'">
                        <mat-checkbox matTooltip="{{'help_text_slider_Navigation' | translate}}"
                            formControlName="navigation">
                            <span class="text-capitalized">{{'Navigation' |translate}}</span>
                        </mat-checkbox>
                        <mat-checkbox matTooltip="{{'help_text_slider_Navigation' | translate}}"
                            formControlName="side_navigation">
                            <span class="text-capitalized">{{'Side Navigation' |translate}}</span>
                        </mat-checkbox>


                    </div>
                    <div
                        fxLayout.lt-md="column"
                        fxLayout.gt-sm="row"
                        *ngIf="
                            sliderForm.get('auto_play').value &&
                            sliderForm.get('show_as').value == '1'
                        "
                        fxLayoutAlign="start center"
                        fxFlex="0 0 auto"
                        fxLayoutGap="1em"
                    >
                        <div fxFlex="1 1 auto">
                            <mat-checkbox
                                matTooltip="{{
                                    'help_text_slider_dotted_Navigation'
                                        | translate
                                }}"
                                formControlName="dotted_navigation"
                            >
                                <span class="text-capitalized">{{
                                    "Dotted Navigation" | translate
                                }}</span>
                            </mat-checkbox>
                        </div>
                        <div fxFlex="1 1 auto">
                            <mat-checkbox matTooltip="{{'help_text_slider_Navigation' | translate}}"
                                formControlName="navigation">
                                <span class="text-capitalized">{{'Navigation' |translate}}</span>
                            </mat-checkbox>
                        </div>

                    </div>
                    <div
                        fxFlex="0 0 auto"
                        fxLayout="column"
                        class="fuse-card auto-width"
                    >
                        <div
                            fxFlex="0 0 auto"
                            fxLayout="row"
                            class="p-8"
                            fxLayoutAlign="space-between"
                        >
                            <label class="highlight-text-color">{{
                                "Css" | translate
                            }}</label>
                            <mat-icon
                                class="cursor-pointer highlight-text-color"
                                (click)="
                                    zoomAdditionalCss(sliderForm.controls.css)
                                "
                            >
                                zoom_out_map</mat-icon
                            >
                        </div>
                        <div fxFlex="0 0 auto" fxLayout="column">
                            <ace-editor
                                [@animate]="{
                                    value: '*',
                                    params: { duration: '300ms', y: '50px' }
                                }"
                                [theme]="'clouds'"
                                [mode]="'css'"
                                #editor
                                [formControl]="sliderForm.controls['css']"
                                style="height: 50vh"
                            >
                            </ace-editor>
                        </div>
                    </div>
                    <!-- <div fxFlex="1 1 auto">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>CSS</mat-label>
                            <textarea matTooltip="{{'help_text_slider_Css' | translate}}" formControlName="css" matInput
                                rows="5"></textarea>
                        </mat-form-field>
                    </div> -->
                </div>
            </div>
        </div>
    </form>
</div>
