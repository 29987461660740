<div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
    <form fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column-reverse" fxLayout.gt-sm="row"
        fxLayoutGap="1em" [formGroup]="widgetForm">

        <div fxFlex.lt-md="1 1 auto" fxFlex.gt-sm="1 1 80%" fxLayoutAlign="start stretch" fxLayout="column"
            fxLayoutGap="1em">

            <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="1em">
                <div class="example-list" class="dropzone" fxFlex="0 0 auto" fxLayout="column"
                    fxLayoutAlign="start stretch">
                    <div fxFlex="0 0 auto" class="p-16" fxLayout="column" fxLayoutAlign="start stretch">
                        <app-element-types #mychild [configurationArray]="configurations"
                            [configuration]="this.widgetForm.controls.configurations" [elementName]="elementName">
                        </app-element-types>
                    </div>
                </div>
            </div>
            <div fxFlex="0 0 auto" fxLayout="column" class="fuse-card auto-width">
                <div fxFlex="0 0 auto" fxLayout="row" class="p-8" fxLayoutAlign="space-between">
                    <label class="highlight-text-color">{{'Css' | translate}}</label>
                    <mat-icon class="cursor-pointer highlight-text-color"
                        (click)="zoomAdditionalCss(widgetForm.controls.css)">
                        zoom_out_map</mat-icon>
                </div>
                <div fxFlex="0 0 auto" fxLayout="column">
                    <ace-editor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}" [theme]="'clouds'"
                        [mode]="'css'" #editor [formControl]="widgetForm.controls['css']" style="height:50vh;">
                    </ace-editor>
                </div>

            </div>
        </div>
        <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="8px">
                <button mat-flat-button (click)="handleCancel()"
                    class="secondary-bg-color">{{'Cancel' | translate}}</button>
                <button (click)="save()" type="submit" mat-flat-button
                    class="primary-bg-color">{{'Save' | translate}}</button>
            </div>

            <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="1em">
                <div fxLayout="column" fxFlex="0 0 auto" fxLayoutAlign="start stretch">
                    <div fxFlex="1 1 auto">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{'Name' | translate}}</mat-label>
                            <input matTooltip="{{'help_text_widget_Name' | translate}}" formControlName="name" matInput
                                name="title">
                            <mat-error *ngIf="widgetForm.get('name').hasError('required')">
                                {{'Name is required' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="1 1 auto" fxLayout="column">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{'Type' | translate}}</mat-label>
                            <mat-select matTooltip="{{'help_text_widget_Type' | translate}}" [disabled]="isTypeDisabled"
                                formControlName="element_type_id">
                                <mat-option *ngFor="let type of elementTypes" [value]="type.id">{{type.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="5px" class="mb-16">
                        <label>{{'Zone' | translate}}</label>
                        <ng-select
                            [ngClass]="(widgetForm.get('zone_id').hasError('required') && widgetForm.get('zone_id').touched) ? 'error-field' : ''"
                            [items]="zone" dropdownPosition="bottom" bindLabel="name" bindValue="id"
                            formControlName="zone_id">
                        </ng-select>
                        <small class="action-btn-delete"
                            *ngIf="widgetForm.get('zone_id').hasError('required') && widgetForm.get('zone_id').touched">{{'Zone is required' | translate}}</small>
                        <!-- <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{'Zone' | translate}}</mat-label>
                            <mat-select matTooltip="{{'help_text_widget_Zone' | translate}}" formControlName="zone_id">
                                <mat-option *ngFor="let zoneObject of zone" [value]="zoneObject.id">
                                    {{zoneObject.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="widgetForm.get('zone_id').hasError('required')">
                                {{'Zone is required' | translate}}
                            </mat-error>
                        </mat-form-field> -->
                    </div>
                    <div fxFlex="1 1 auto">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{'Redirection Path' | translate}}</mat-label>
                            <input matTooltip="{{'help_text_redirection_path' | translate}}" formControlName="redirection_path" matInput
                                name="title">
                            <mat-error *ngIf="widgetForm.get('redirection_path').hasError('required')">
                                {{'Redirection path is required' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="1em">
                        <label>{{'Show On' | translate}}</label>
                        <div [ngClass]="!this.widgetForm.controls.show_on.value && submit ? 'show-on-error' : ''"
                            fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="1em"
                            class="mb-16">
                            <div fxFlex="1 1 auto" *ngFor="let show_onObj of show_on;let i =index">
                                <div (click)="addOrRemoveShowOnItems(i)" class="cursor-pointer show-on-icon"
                                    title="{{show_onObj.name}}">
                                    <img style="height: 4em;" [ngClass]="show_onObj.selected" src="{{show_onObj.icon}}">
                                </div>
                            </div>
                        </div>
                        <!-- <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{'Show On' | translate}}</mat-label>
                            <mat-select multiple formControlName="show_on">
                                <mat-option *ngFor="let show_onObj of show_on" [value]="show_onObj.id">
                                    {{show_onObj.name}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="widgetForm.get('order').hasError('required')">
                                {{'Show On is required' | translate}}
                            </mat-error>
                        </mat-form-field> -->
                    </div>

      
                    <div fxLayoutAlign.lt-md="start baseline" fxLayoutAlign.gt-sm="start center" fxLayout="row"
                        fxFlex="0 0 auto" fxLayoutGap="1em">
                        <div fxFlex="1 1 auto">
                            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="0 0 auto" fxLayoutGap="0px">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label>{{'Order' |translate}}</mat-label>
                                    <input matTooltip="{{'help_text_widget_Order' | translate}}" formControlName="order"
                                        matInput name="Delay">
                                    <mat-error *ngIf="widgetForm.get('order').hasError('required')">
                                        {{'Order is required' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="1 1 auto">
                            <mat-checkbox matTooltip="{{'help_text_widget_Enable' | translate}}"
                                formControlName="enabled">
                                <span class="text-capitalized">{{'Enabled' |translate}}</span>
                            </mat-checkbox>
                        </div>
                    </div>
                    <ng-container *ngIf="showCloseButtonSetting">
                        <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="1em">
                            <label>{{'Show Close Button' | translate}}</label>
                            <mat-checkbox matTooltip="{{'help_text_widget_Enable' | translate}}"
                                formControlName="show_close_button">
                            </mat-checkbox>
                        </div>
                    </ng-container>
                
                    <!-- <div fxFlex="1 1 auto">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>CSS</mat-label>
                            <textarea matTooltip="{{'help_text_widget_Css' | translate}}" formControlName="css" matInput
                                rows="5"></textarea>
                        </mat-form-field>
                    </div> -->
                </div>
            </div>

        </div>
    </form>

</div>