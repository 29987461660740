<div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em"
    cdkDropListGroup class="create-page">
    <form [formGroup]="pageForm" fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column-reverse"
        fxLayout.gt-sm="row" fxLayoutGap="1em" cdkDropListGroup>

        <div fxFlex.lt-md="1 1 auto" fxFlex.gt-sm="1 1 70%" fxLayoutAlign="start stretch"
            fxLayout.lt-md="column-reverse" fxLayout.gt-sm="column" fxLayoutGap="1em"
            style="height: fit-content !important;">
            <div fxHide fxShow.lt-md fxFlex="0 0 auto" fxLayout="column" class="fuse-card auto-width">
                <div fxFlex="0 0 auto" fxLayout="row" class="p-8" fxLayoutAlign="space-between">
                    <label class="highlight-text-color">{{'Css' | translate}}</label>
                    <mat-icon class="cursor-pointer highlight-text-color" (click)="zoomPageCss(pageForm.controls.css)">
                        zoom_out_map</mat-icon>
                </div>
                <div fxFlex="0 0 auto" fxLayout="column">
                    <ace-editor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}" [theme]="'clouds'"
                        [mode]="'css'" [options]="options" [formControl]="pageForm.controls['css']"
                        style="height:35vh;">
                    </ace-editor>
                </div>
            </div>

            <mat-accordion>
                <mat-expansion-panel (afterExpand)="isExpand = true" (afterCollapse)="isExpand = false"
                    [expanded]="isExpand">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{'Basic Info' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="0px">
                        <div fxLayout.lt-md="column" fxLayout.gt-sm="column" fxFlex="0 0 auto"
                            fxLayoutAlign="start stretch" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100" *ngIf="!productId;else productCard">
                                    <mat-label>{{'Name' | translate}}</mat-label>
                                    <input formControlName="title" matInput name="title">
                                    <mat-error *ngIf="pageForm.get('title').hasError('required')">
                                        {{'Title is required' | translate}}
                                    </mat-error>
                                </mat-form-field>

                                <ng-template #productCard>

                                    <div fxLayout="row" fxLayoutGap="10px" class="product-card">
                                        <div class="product-image"
                                            [ngClass]="productRowData?.product_images.length !== 0 ? 'image-border':''">
                                            <img *ngIf="productRowData?.product_images.length !== 0;else noImage"
                                                [src]="productRowData?.product_images[0]?.image" alt="">
                                            <ng-template #noImage>
                                                <span>No Image Available</span>
                                            </ng-template>
                                        </div>
                                        <div class="product-content">
                                            <div>
                                                <!-- <span>Product Name: </span> -->
                                                <span class="bold"> {{productRowData?.name || 'Not
                                                    available'}}</span>
                                            </div>
                                            <div>
                                                <div class="w-full bold">
                                                    <span>
                                                        <i *ngIf="productRowData?.composition ===1"
                                                            class="fa fa-cube red-class"></i>
                                                        <i *ngIf="productRowData?.composition ===2"
                                                            class="fa fa-sitemap purple-class"></i>
                                                        <i *ngIf="productRowData?.composition ===3"
                                                            class="fa fa-cubes blue-class"></i>
                                                        <i *ngIf="productRowData?.composition ===4 || productRowData?.composition ===5"
                                                            class="fa fa-cogs yellow-class"></i>
                                                    </span>
                                                    <span *ngIf="productRowData?.composition ===1">{{'Simple Product'}}
                                                    </span>
                                                    <span *ngIf="productRowData?.composition ===2">
                                                        {{'Product with Variants'}}</span>
                                                    <span *ngIf="productRowData?.composition ===3">{{'Fixed Bundle'}}
                                                    </span>
                                                    <span *ngIf="productRowData?.composition ===4">
                                                        {{'Customisable Bundle'}}
                                                    </span>
                                                    <span *ngIf="productRowData?.composition ===5">{{'Flexi Bundle'}}
                                                    </span>
                                                </div>
                                            </div>
                                            <!-- <div>
                                                <span><span class="bold">Category: </span> {{productRowData?.category ||
                                                    'Not available'}}</span>
                                            </div>
                                            <div>
                                                <span><span class="bold">Brand: </span> {{productRowData?.brand?.name ||
                                                    'Not available'}}</span>
                                            </div> -->
                                            <div>
                                                <span><span class="bold">SKU: </span> {{productRowData?.sku || 'Not
                                                    available'}}</span>
                                            </div>
                                            <!-- <div>
                                                <span>Product Available :</span>
                                                <mat-icon class="green-class" *ngIf="!productRowData?.unavailable" matPostfix>check_circle
                                                </mat-icon>
                                                <mat-icon class="red-class" *ngIf="productRowData?.unavailable" matPostfix>cancel
                                                </mat-icon>
                                            </div>
                                            <div>
                                                <span>Product Enable: </span>
                                                <mat-icon class="green-class" *ngIf="productRowData?.enabled" matPostfix>check_circle
                                                </mat-icon>
                                                <mat-icon class="red-class" *ngIf="!productRowData?.enabled" matPostfix>cancel
                                                </mat-icon>
                                            </div> -->
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto"
                            fxLayoutAlign="start stretch" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label>{{'Meta Title (En)' | translate}}</mat-label>
                                    <input formControlName="meta_title_en" matInput name="meta_title_en">
                                    <mat-error *ngIf="pageForm.get('meta_title_en').hasError('required')">
                                        {{'Meta title is required' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label>{{'Meta Title (Ar)' | translate}}</mat-label>
                                    <input formControlName="meta_title_ar" matInput name="meta_title_ar">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto"
                            fxLayoutAlign="start stretch" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label>{{'Meta Description (En)' |translate}}</mat-label>
                                    <input formControlName="meta_description" matInput name="title">
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label>{{'Meta Description (Ar)' |translate}}</mat-label>
                                    <input formControlName="meta_description_ar" matInput name="title">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto" *ngIf="!productId"
                            fxLayoutAlign="start stretch" fxLayoutGap="1em">
                            <div fxFlex.gt-md="1 1 50%" fxFlex.lt-sm="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100%">
                                    <mat-label>{{'Status' |translate}}</mat-label>
                                    <mat-select formControlName="status">
                                        <mat-option [value]="status.id" *ngFor="let status of status">{{status.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex.gt-md="1 1 50%" fxFlex.lt-sm="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label>{{'Visibility' | translate}}</mat-label>
                                    <mat-select formControlName="visibility">
                                        <mat-option [value]="1">{{'Public' | translate}}</mat-option>
                                        <mat-option [value]="2">{{'Registered' | translate}}</mat-option>
                                        <mat-option [value]="3">{{'Guest' | translate}}</mat-option>
                                        <mat-option [value]="4">{{'Guest or Registerd' | translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="showSlug" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto"
                            fxLayoutAlign="start stretch" fxLayoutGap="1em">
                            <div fxFlex.gt-md="1 1 50%" fxFlex.lt-sm="1 1 auto" fxLayout="column">
                                <mat-form-field appearance="outline" fxFlex="100%" floatLabel="always">
                                    <mat-label>{{'URL' |translate}}</mat-label>
                                    <div fxLayout="row">
                                        <input type="text" matInput formControlName="slug">
                                    </div>
                                </mat-form-field>
                                <div fxFlex="100%" class="url">
                                    <span>{{baseUrl}}{{pageForm?.get('slug')?.value}}</span>
                                </div>
                            </div>
                            <!-- <div fxFlex.gt-md="1 1 50%" fxFlex.lt-sm="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label>{{'Template' | translate}}</mat-label>
                                    <mat-select formControlName="template">
                                        <mat-option [value]="template.id" *ngFor="let template of pageTemplate$ | async">
                                            {{template.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div> -->
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <div (mousedown)="$event.stopPropagation()" (mousemove)="$event.stopPropagation()" fxFlex="0 0 auto"
                fxLayout="column" fxLayoutAlign="start stretch">
                <div cdkDropList class="example-list" class="dropzone p-16"
                    [style.border]="!checkFormArrayValidation() ? '2px solid #e74133' : ''"
                    (cdkDropListDropped)="drop($event)" fxFlex="0 0 auto" fxLayout="column"
                    fxLayoutAlign="start stretch">

                    <div *ngIf="getLength()" fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="center center">
                        <div class="mat-title" style="color: #ccc;">{{'Drop Elements Here' | translate}}</div>
                    </div>

                    <mat-accordion class="mb-16"
                        *ngFor="let config of pageForm.controls.configurations['controls'];let configIndex = index"
                        cdkDrag>
                        <mat-expansion-panel [expanded]="expandIndex[configIndex] == configIndex"
                            [ngClass]="{'invalid' : !checkInvalid(configIndex)}" [ngStyle]="{'margin-left' : (showSideClickIcon[configIndex]?.show
                             && showSideClickIcon[configIndex]?.index === configIndex
                             && config.controls.isTabChild.value && configIndex !== 0) ? '30px' : '0px'}">

                            <mat-expansion-panel-header cdkDragHandle>
                                <mat-panel-title>
                                    <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="space-between">
                                        <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="start center"
                                            fxLayoutGap="10px">
                                            <ng-container
                                                *ngIf="configIndex !== 0
                                                    && ( config.controls.parentName.value !== 'TABS' && config.controls.parentName.value !== 'ACCORDION')
                                                    && showSideClickIcon[configIndex]?.show && showSideClickIcon[configIndex]?.index === configIndex">

                                                <ng-container *ngIf="!config.controls.isTabChild.value">

                                                    <img style="height: 17px;" src="assets/images/indentin.svg"
                                                        (click)="$event.stopPropagation();addToTabs(config)">

                                                </ng-container>

                                                <ng-container *ngIf="config.controls.isTabChild.value">

                                                    <img style="height: 17px;" src="assets/images/indentout.svg"
                                                        (click)="$event.stopPropagation();removeFromTabs(config)">

                                                </ng-container>
                                            </ng-container>

                                            <mat-icon>drag_indicator</mat-icon>
                                            <span>{{config?.controls?.parentName?.value}}</span>
                                        </div>
                                        <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="start center"
                                            fxLayoutGap="5px" *ngIf="getShowOn(config)?.length">
                                            <div fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="start center"
                                                fxLayoutGap="2px" *ngFor="let showOn of getShowOn(config)">
                                                <img class="h-28 selected" [src]="showOn">
                                            </div>
                                        </div>
                                        <div class="mt-4" *ngIf="!getShowOn(config)?.length">
                                            <strong>
                                                <i class="fas fa-eye-slash"></i>
                                                {{'Hidden' | translate}}</strong>
                                        </div>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div (mousedown)="$event.stopPropagation()" (mousemove)="$event.stopPropagation()"
                                fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="start stretch"
                                *ngFor="let control of config.controls | keyvalue:originalOrder;let controlIndex = index"
                                fxLayoutGap="1em">
                                <ng-container *ngIf="getFormControlLabel(control.key) as controlLabel">
                                    <ng-container *ngIf="getFormControlType(control.key) as controlType">
                                        <div fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="start stretch">
                                            <div *ngIf="hideLanguage(config.controls)" fxFlex="0 0 auto"
                                                fxLayout="column" fxLayoutAlign="start stretch">
                                                <mat-form-field *ngIf="controlType=='language'" appearance="outline"
                                                    fxLayoutAlign="start flex-end">
                                                    <mat-select [formControl]="control.value">
                                                        <mat-option value="en">{{'EN' | translate}}</mat-option>
                                                        <mat-option value="ar">{{'AR' | translate}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                            <mat-form-field *ngIf="controlType=='label'" appearance="outline"
                                                fxFlex="100">
                                                <mat-label>{{controlLabel}}</mat-label>
                                                <input type="text" [formControl]="control.value" matInput>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="controlType == 'label_ar'" appearance="outline"
                                                fxFlex="100">
                                                <mat-label>{{controlLabel}}</mat-label>
                                                <input type="text" [formControl]="control.value" matInput>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="controlType=='redirection_path'" appearance="outline"
                                                fxFlex="100">
                                                <mat-label>{{controlLabel}}</mat-label>
                                                <input type="text" [formControl]="control.value" matInput>
                                            </mat-form-field>
                                            <ng-container *ngIf="controlType == 'responsive_behaviour'">
                                                <mat-form-field appearance="outline" fxLayoutAlign="start flex-end"
                                                    fxFlex="1 1 auto" class="responsive-behaviour">
                                                    <mat-label>{{'Mobile Design' | translate}}</mat-label>
                                                    <mat-select [formControl]="control.value">
                                                        <ng-container *ngFor="let item of selectValues">
                                                            <mat-option [value]="item.id">
                                                                {{ item.name }}
                                                            </mat-option>
                                                        </ng-container>

                                                    </mat-select>
                                                    <mat-error *ngIf="control.value.hasError('required')">
                                                        {{'Mobile Design is required' | translate}}
                                                    </mat-error>
                                                </mat-form-field>

                                            </ng-container>

                                            <ng-container *ngIf="controlType == 'heading_tag'">
                                                <mat-form-field appearance="outline" fxLayoutAlign="start flex-end">
                                                    <mat-label>{{'Heading Tag' | translate}}</mat-label>
                                                    <mat-select [formControl]="control.value">
                                                        <ng-container *ngFor="let item of selectValues">
                                                            <mat-option [value]="item.id">
                                                                {{ item.name }}
                                                            </mat-option>
                                                        </ng-container>

                                                    </mat-select>
                                                    <mat-error *ngIf="control.value.hasError('required')">
                                                        {{'Heading tag is required' | translate}}
                                                    </mat-error>
                                                </mat-form-field>

                                            </ng-container>


                                            <!-- <div *ngIf="controlType=='show_on'" fxFlex="1 1 auto" fxLayout="column"
                                                fxLayoutAlign="start stretch" fxLayoutGap="1em">
                                                <label>{{'Show On' | translate}}</label>
                                                <div fxFlex="1 1 auto" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                                                    fxLayoutAlign="start stretch" fxLayoutGap="1em" class="mb-16">
                                                    <div fxFlex="1 1 auto"
                                                        *ngFor="let show_onObj of show_on;let i =index">
                                                        <div (click)="addOrRemoveShowOnItems(i,control.value)"
                                                            class="cursor-pointer show-on-icon"
                                                            title="{{show_onObj.name}}">
                                                            <div fxFlex="0 0 auto" fxLayout="column"
                                                                fxLayoutAlign="start center" fxLayoutGap="3px">
                                                                <img style="height: 4em;"
                                                                    [ngClass]="setSelected(show_onObj.id,control.value,false)"
                                                                    src="{{show_onObj.icon}}">
                                                                <label
                                                                    [ngClass]="setSelected(show_onObj.id,control.value,true)"
                                                                    class="mat-caption">{{show_onObj.name}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->


                                            <div *ngIf="controlType=='show_on'" fxFlex="1 1 auto"
                                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                                <div fxFlex="1 1 0px" fxLayout="column" fxLayoutGap="5px">
                                                    <mat-form-field appearance="outline">
                                                        <mat-label>{{'Show On' | translate}}</mat-label>
                                                        <mat-select [formControl]="control.value" multiple>
                                                            <mat-option *ngFor="let show_on_obj of show_on"
                                                                [value]="show_on_obj.id">{{show_on_obj?.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>

                                            <ng-container *ngIf="productId || categorytId || brandId">
                                                <div *ngIf="controlType=='placement'" fxFlex="1 1 auto"
                                                    fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                                    <div fxFlex="1 1 0px" fxLayout="column" fxLayoutGap="5px">
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>{{'Placement' | translate}}</mat-label>
                                                            <mat-select [formControl]="control.value">
                                                                <mat-option [value]=1>
                                                                    {{'Pre list content'}}
                                                                </mat-option>
                                                                <mat-option [value]="2">
                                                                    {{'Post list content'}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </ng-container>

                                            <mat-checkbox class="my-8" *ngIf="typeEnum.BOOLEAN == controlType "
                                                [formControl]="control.value">
                                                <span class="text-capitalized">{{controlLabel}}</span>
                                            </mat-checkbox>

                                            <mat-form-field *ngIf="typeEnum.TEXT == controlType" appearance="outline"
                                                fxFlex="100">
                                                <mat-label>{{controlLabel}}</mat-label>
                                                <input [formControl]="control.value" matInput>
                                            </mat-form-field>

                                            <mat-form-field *ngIf="typeEnum.NUMBER ==controlType" appearance="outline"
                                                fxFlex="100">
                                                <mat-label class="text-capitalized">{{controlLabel}}</mat-label>
                                                <input [formControl]="control.value" type="number" matInput>
                                            </mat-form-field>

                                            <!-- <mat-form-field *ngIf="typeEnum.SELECT ==controlType" fxFlex="100"
                                                appearance="outline">
                                                <mat-label class="text-capitalized">{{controlLabel}}</mat-label>
                                                <mat-select [formControl]="control.value">
                                                    <mat-option *ngFor="let value of [0, 1, 2, 3, 4, 5]">{{value}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field> -->

                                            <div fxFlex="1 1 100%" fxLayout="column" class="mb-8"
                                                *ngIf="typeEnum.HTML ==  controlType">
                                                <mat-slide-toggle class="mb-8 align-self"
                                                    *ngIf="controlLabel=='html' && config.controls.language.value=='en'"
                                                    [formControl]="config.controls.isCodeEditor">
                                                    {{!config.controls.isCodeEditor.value ? ('Switch to Code Editor' |
                                                    translate) : ('Switch to HTML Editor' | translate)}}
                                                </mat-slide-toggle>
                                                <mat-slide-toggle class="mb-8 align-self"
                                                    *ngIf="controlLabel=='html_ar' && config.controls.language.value=='ar' "
                                                    [formControl]="config.controls.isCodeEditor">
                                                    {{!config.controls.isCodeEditor.value ? ('Switch to Code Editor' |
                                                    translate) : ('Switch to HTML Editor' | translate)}}
                                                </mat-slide-toggle>
                                                <ckeditor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
                                                    *ngIf="controlLabel=='html' && config.controls.language.value=='en' && !config.controls.isCodeEditor.value"
                                                    [formControl]="control.value" ngDefaultControl name="myckeditor"
                                                    [config]="ckeConfig">
                                                </ckeditor>
                                                <div fxFlex="0 0 auto" fxLayout="column"
                                                    *ngIf="controlLabel=='html' && config.controls.language.value=='en' && config.controls.isCodeEditor.value">
                                                    <mat-icon class="zoom-icon cursor-pointer highlight-text-color"
                                                        (click)="zoomAdditionalCss(control.value,configIndex,'html')">
                                                        zoom_out_map</mat-icon>
                                                    <ace-editor
                                                        [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
                                                        [theme]="'clouds'" [mode]="'html'" [options]="options"
                                                        [formControl]="control.value" style="height:40vh;">
                                                    </ace-editor>
                                                </div>
                                                <div style="font-size: 10px;color: red;"
                                                    *ngIf="control.value.hasError('required')  && controlLabel=='html' && config.controls.language.value=='en'">
                                                    {{'Html En is required' | translate}}
                                                </div>

                                                <div fxFlex="0 0 auto" fxLayout="column"
                                                    *ngIf="controlLabel=='html_ar' && config.controls.language.value=='ar' && config.controls.isCodeEditor.value">
                                                    <mat-icon class="zoom-icon highlight-text-color cursor-pointer"
                                                        (click)="zoomAdditionalCss(control.value,configIndex,'html_ar')">
                                                        zoom_out_map</mat-icon>
                                                    <ace-editor
                                                        [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
                                                        [theme]="'clouds'" [mode]="'css'" [options]="options"
                                                        [formControl]="control.value" style="height:35vh;">
                                                    </ace-editor>
                                                </div>

                                                <ckeditor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
                                                    *ngIf="controlLabel=='html_ar' && config.controls.language.value=='ar' && !config.controls.isCodeEditor.value"
                                                    [formControl]="control.value" ngDefaultControl name="myckeditor1"
                                                    [config]="ckeConfig">
                                                </ckeditor>
                                                <div style=" font-size: 10px;color: red;"
                                                    *ngIf="control.value.hasError('required') && controlLabel=='html_ar' && config.controls.language.value=='ar'">
                                                    {{'Html Ar is required' | translate}}
                                                </div>
                                            </div>

                                            <div fxFlex="1 1 100%" fxLayout="row" class="mb-8"
                                                *ngIf="typeEnum.CAROUSEL == controlType" fxLayoutGap="1em">

                                                <mat-form-field fxFlex="100" appearance="outline" style="width: 90%;">
                                                    <mat-label class="text-capitalized">{{controlLabel}}</mat-label>
                                                    <mat-select [formControl]="control.value">
                                                        <mat-option [value]="sliderObj.id"
                                                            *ngFor="let sliderObj of slider">
                                                            {{sliderObj.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error *ngIf="control.value.hasError('required')">
                                                        {{'Collection is required' | translate}}
                                                    </mat-error>
                                                </mat-form-field>

                                                <button mat-mini-fab class="ml-10 add-collection-button bgColorClass"
                                                    matTooltip="Add new collection"
                                                    (click)="addNewCollection(control.value)">
                                                    <mat-icon class="cursor-pointer" matPrefix>add</mat-icon>
                                                </button>

                                            </div>

                                            <!-- <div fxFlex="1 1 100%" fxLayout="column" class="mb-8"
                                        *ngIf="typeEnum.SLIDER == controlType" fxLayoutGap="1em">
                                        <mat-form-field fxFlex="100" appearance="outline">
                                            <mat-label class="text-capitalized">{{controlLabel}}</mat-label>
                                            <mat-select [formControl]="control.value">
                                                <mat-option [value]="sliderObj.id" *ngFor="let sliderObj of slider">
                                                    {{sliderObj.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> -->

                                            <div fxFlex="1 1 100%" fxLayout="column" class="mb-8"
                                                *ngIf="typeEnum.MOBILE_TABS == controlType &&  !this.isMenu()"
                                                fxLayoutGap="1em">
                                                <mat-form-field fxFlex="100" appearance="outline">
                                                    <mat-label class="text-capitalized">{{controlLabel}}</mat-label>
                                                    <mat-select [formControl]="control.value">
                                                        <mat-option [value]="menuObj.id" *ngFor="let menuObj of menu">
                                                            {{menuObj.title}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="1 1 100%" fxLayout="column" class="mb-8"
                                                *ngIf="typeEnum.MENU == controlType && this.isMenu()" fxLayoutGap="1em">
                                                <mat-form-field fxFlex="100" appearance="outline">
                                                    <mat-label class="text-capitalized">{{controlLabel}}</mat-label>
                                                    <mat-select [formControl]="control.value">
                                                        <mat-option [value]="menuObj.id" *ngFor="let menuObj of menu">
                                                            {{menuObj.title}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="1 1 100%" fxLayout="column" class="mb-8"
                                                *ngIf="typeEnum.EMBED == controlType" fxLayoutGap="1em">
                                                <div *ngIf="controlLabel=='embed' && config.controls.language.value=='en'"
                                                    fxLayout="column" fxFlex="0 0 auto" fxLayoutAlign="start stretch">
                                                    <mat-icon class="zoom-icon highlight-text-color cursor-pointer"
                                                        (click)="zoomAdditionalCss(control.value,configIndex,'embed')">
                                                        zoom_out_map</mat-icon>
                                                    <ace-editor
                                                        [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
                                                        [mode]="'html'" [theme]="'clouds'" [options]="options"
                                                        [formControl]="control.value" style="height:35vh;">
                                                    </ace-editor>
                                                    <small *ngIf="control.value.hasError('required')"
                                                        style="color: red !important;">{{'Embed En is required' |
                                                        translate}}</small>
                                                </div>
                                                <div *ngIf="controlLabel=='embed_ar' && config.controls.language.value=='ar'"
                                                    fxLayout="column" fxFlex="0 0 auto" fxLayoutAlign="start stretch">
                                                    <mat-icon class="zoom-icon highlight-text-color cursor-pointer"
                                                        (click)="zoomAdditionalCss(control.value,configIndex,'embed')">
                                                        zoom_out_map</mat-icon>
                                                    <ace-editor
                                                        [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
                                                        [mode]="'html'" [theme]="'clouds'" [options]="options"
                                                        [formControl]="control.value" style="height:35vh;">
                                                    </ace-editor>
                                                    <small *ngIf="control.value.hasError('required')"
                                                        style="color: red !important;">{{'Embed Ar is required' |
                                                        translate}}</small>
                                                </div>
                                            </div>

                                            <div fxFlex="1 1 100%" fxLayout="column" class="mb-8"
                                                *ngIf="typeEnum.GALLERY == controlType" fxLayoutGap="1em">
                                                <div *ngIf="control.value.value" class="fuse-card auto-width"
                                                    fxLayout="row wrap" fxLayoutAlign="center center" class="mb-8">
                                                    <div *ngFor="let image of control.value.value"
                                                        style="border: 1px solid #FFC138;margin: 3px;">
                                                        <img style="margin:3px;height: 70px !important;width: 70px;"
                                                            [src]="utilitiesService.bypassTrustedURL(image?.file)">
                                                    </div>
                                                </div>
                                                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row"
                                                    class="mb-8" fxLayoutGap="1em">
                                                    <button style="border-radius: 9999px;line-height: 35px !important;"
                                                        mat-flat-button (click)="openGallery(control.value)"
                                                        class=" selected-bg-color mat-focus-indicator mat-flat-button mat-button-base">
                                                        <mat-icon matPrefix>add</mat-icon>
                                                        {{'Add Media' | translate}}
                                                    </button>
                                                </div>
                                            </div>

                                            <div *ngIf="typeEnum.IMAGE_URL == controlType" fxFlex="1 1 100%"
                                                fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px"
                                                class="mb-16">
                                                <ng-container
                                                    *ngIf="!config.controls[controlLabel].value && !containAr(controlLabel) && config.controls.language.value=='en'">
                                                    <div>{{controlLabel}}</div>
                                                    <app-cms-upload-media [showOnly]="getShowOnly(controlLabel)"
                                                        [accept]="config?.controls?.mediaType?.value"
                                                        (afterUpload)="setFileToForm($event,configIndex,controlLabel)"
                                                        [isMultiSelect]="false" [formcontrol]="control.value"
                                                        [index]="configIndex">
                                                    </app-cms-upload-media>
                                                </ng-container>
                                                <small
                                                    *ngIf="!containAr(controlLabel) && config.controls.language.value=='en' && control.value.hasError('required')"
                                                    style="color: red !important;">
                                                    {{controlLabel+' en is required'}}
                                                </small>

                                                <ng-container
                                                    *ngIf="!config.controls[controlLabel].value && containAr(controlLabel) && config.controls.language.value=='ar'">
                                                    <div>{{controlLabel}}</div>
                                                    <app-cms-upload-media [showOnly]="getShowOnly(controlLabel)"
                                                        [accept]="config?.controls?.mediaType?.value"
                                                        (afterUpload)="setFileToForm($event,configIndex,controlLabel)"
                                                        [isMultiSelect]="false" [formcontrol]="control.value"
                                                        [index]="configIndex">
                                                    </app-cms-upload-media>
                                                </ng-container>
                                                <small
                                                    *ngIf="containAr(controlLabel) && config.controls.language.value=='ar' && control.value.hasError('required')"
                                                    style="color: red !important;">
                                                    {{controlLabel+' ar is required'}}
                                                </small>

                                                <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch"
                                                    fxLayoutGap="5px"
                                                    *ngIf="config.controls[controlLabel].value && !containAr(controlLabel) && config.controls.language.value=='en'">
                                                    <div>{{controlLabel}}</div>
                                                    <div class="fuse-card auto-width box-shadow-none" fxFlex="0 0 auto"
                                                        fxLayoutAlign="space-between center" fxLayoutGap="5px"
                                                        fxLayout="row">
                                                        <div class="img-container">
                                                            <app-show-all-type-media
                                                                [url]="config.controls[controlLabel].value">
                                                            </app-show-all-type-media>
                                                        </div>
                                                        <div class="px-16 py-4" fxLayout="row"
                                                            fxLayoutAlign="center center">
                                                            <button mat-icon-button aria-label="Delete">
                                                                <mat-icon (click)="deleteFile(configIndex,controlLabel)"
                                                                    class="action-btn-delete">
                                                                    delete</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch"
                                                    fxLayoutGap="5px"
                                                    *ngIf="config.controls[controlLabel].value && containAr(controlLabel) && config.controls.language.value=='ar'">
                                                    <div>{{controlLabel}}</div>
                                                    <div class="fuse-card auto-width  box-shadow-none" fxFlex="0 0 auto"
                                                        fxLayoutAlign="space-between center" fxLayoutGap="5px"
                                                        fxLayout="row">

                                                        <div class="img-container">
                                                            <app-show-all-type-media
                                                                [url]="config.controls[controlLabel].value">
                                                            </app-show-all-type-media>
                                                        </div>
                                                        <div class="px-16 py-4" fxLayout="row"
                                                            fxLayoutAlign="center center">
                                                            <button mat-icon-button aria-label="Delete">
                                                                <mat-icon (click)="deleteFile(configIndex,controlLabel)"
                                                                    class="action-btn-delete">
                                                                    delete</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div *ngIf="typeEnum.VIDEO == controlType" fxFlex="1 1 100%"
                                                fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px"
                                                class="mb-16">
                                                <ng-container
                                                    *ngIf="!config.controls[controlLabel].value && !containAr(controlLabel) && config.controls.language.value=='en'">
                                                    <app-cms-upload-media [accept]="mediaTypesAccept.Video"
                                                        [showOnly]="mediaType.Video"
                                                        (afterUpload)="setFileToForm($event,configIndex,controlLabel)"
                                                        [isMultiSelect]="false" [formcontrol]="control.value"
                                                        [index]="configIndex">
                                                    </app-cms-upload-media>
                                                </ng-container>
                                                <small
                                                    *ngIf="!containAr(controlLabel) && config.controls.language.value=='en' && control.value.hasError('required')"
                                                    style="color: red !important;">{{'Video En is required' |
                                                    translate}}</small>

                                                <ng-container
                                                    *ngIf="!config.controls[controlLabel].value && containAr(controlLabel) && config.controls.language.value=='ar'">
                                                    <app-cms-upload-media [accept]="mediaTypesAccept.Video"
                                                        [showOnly]="mediaType.Video"
                                                        (afterUpload)="setFileToForm($event,configIndex,controlLabel)"
                                                        [isMultiSelect]="false" [formcontrol]="control.value"
                                                        [index]="configIndex">
                                                    </app-cms-upload-media>
                                                </ng-container>
                                                <small
                                                    *ngIf="containAr(controlLabel) && config.controls.language.value=='ar' && control.value.hasError('required')"
                                                    style="color: red !important;">{{'Video Ar is required' |
                                                    translate}}</small>

                                                <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch"
                                                    fxLayoutGap="5px"
                                                    *ngIf="config.controls[controlLabel].value && !containAr(controlLabel) && config.controls.language.value=='en'">
                                                    <div class="fuse-card auto-width box-shadow-none" fxFlex="0 0 auto"
                                                        fxLayoutAlign="space-between center" fxLayoutGap="5px"
                                                        fxLayout="row">
                                                        <div class="img-container">
                                                            <app-show-all-type-media
                                                                [url]="config.controls[controlLabel].value">
                                                            </app-show-all-type-media>

                                                        </div>
                                                        <div class="px-16 py-4" fxLayout="row"
                                                            fxLayoutAlign="center center">
                                                            <button mat-icon-button aria-label="Delete">
                                                                <mat-icon (click)="deleteFile(configIndex,controlLabel)"
                                                                    class="action-btn-delete">
                                                                    delete</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch"
                                                    fxLayoutGap="5px"
                                                    *ngIf="config.controls[controlLabel].value && containAr(controlLabel) && config.controls.language.value=='ar'">
                                                    <div class="fuse-card auto-width  box-shadow-none" fxFlex="0 0 auto"
                                                        fxLayoutAlign="space-between center" fxLayoutGap="5px"
                                                        fxLayout="row">

                                                        <div class="img-container">
                                                            <app-show-all-type-media
                                                                [url]="config.controls[controlLabel].value">
                                                            </app-show-all-type-media>
                                                        </div>
                                                        <div class="px-16 py-4" fxLayout="row"
                                                            fxLayoutAlign="center center">
                                                            <button mat-icon-button aria-label="Delete">
                                                                <mat-icon (click)="deleteFile(configIndex,controlLabel)"
                                                                    class="action-btn-delete">
                                                                    delete</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <!-- <div *ngIf="typeEnum.AUDIO == controlType" fxFlex="1 1 100%"
                                                fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px"
                                                class="mb-16">
                                                <ng-container
                                                    *ngIf="!config.controls[controlLabel].value && !containAr(controlLabel) && config.controls.language.value=='en'">
                                                    <app-cms-upload-media [accept]="mediaTypesAccept.Audio"
                                                        [showOnly]="mediaType.Audio"
                                                        (afterUpload)="setFileToForm($event,configIndex,controlLabel)"
                                                        [isMultiSelect]="false" [formcontrol]="control.value"
                                                        [index]="configIndex">
                                                    </app-cms-upload-media>
                                                </ng-container>
                                                <small
                                                    *ngIf="!containAr(controlLabel) && config.controls.language.value=='en' && control.value.hasError('required')"
                                                    style="color: red !important;">{{'Audio En is required' |
                                                    translate}}</small>

                                                <ng-container
                                                    *ngIf="!config.controls[controlLabel].value && containAr(controlLabel) && config.controls.language.value=='ar'">
                                                    <app-cms-upload-media [accept]="mediaTypesAccept.Audio"
                                                        [showOnly]="mediaType.Audio"
                                                        (afterUpload)="setFileToForm($event,configIndex,controlLabel)"
                                                        [isMultiSelect]="false" [formcontrol]="control.value"
                                                        [index]="configIndex">
                                                    </app-cms-upload-media>
                                                </ng-container>
                                                <small
                                                    *ngIf="containAr(controlLabel) && config.controls.language.value=='ar' && control.value.hasError('required')"
                                                    style="color: red !important;">{{'Audio Ar is required' |
                                                    translate}}</small>

                                                <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch"
                                                    fxLayoutGap="5px"
                                                    *ngIf="config.controls[controlLabel].value && !containAr(controlLabel) && config.controls.language.value=='en'">
                                                    <div class="fuse-card auto-width box-shadow-none" fxFlex="0 0 auto"
                                                        fxLayoutAlign="space-between center" fxLayoutGap="5px"
                                                        fxLayout="row">
                                                        <div class="img-container">
                                                            <app-show-all-type-media
                                                                [url]="config.controls[controlLabel].value">
                                                            </app-show-all-type-media>
                                                        </div>
                                                        <div class="px-16 py-4" fxLayout="row"
                                                            fxLayoutAlign="center center">
                                                            <button mat-icon-button aria-label="Delete">
                                                                <mat-icon (click)="deleteFile(configIndex,controlLabel)"
                                                                    class="action-btn-delete">
                                                                    delete</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch"
                                                    fxLayoutGap="5px"
                                                    *ngIf="config.controls[controlLabel].value && containAr(controlLabel) && config.controls.language.value=='ar'">
                                                    <div class="fuse-card auto-width  box-shadow-none" fxFlex="0 0 auto"
                                                        fxLayoutAlign="space-between center" fxLayoutGap="5px"
                                                        fxLayout="row">

                                                        <div class="img-container">
                                                            <app-show-all-type-media
                                                                [url]="config.controls[controlLabel].value">
                                                            </app-show-all-type-media>
                                                        </div>
                                                        <div class="px-16 py-4" fxLayout="row"
                                                            fxLayoutAlign="center center">
                                                            <button mat-icon-button aria-label="Delete">
                                                                <mat-icon (click)="deleteFile(configIndex,controlLabel)"
                                                                    class="action-btn-delete">
                                                                    delete</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <!-- <div *ngIf="typeEnum.PDF == controlType" fxFlex="1 1 100%" fxLayout="column"
                                                fxLayoutAlign="start stretch" fxLayoutGap="5px" class="mb-16">
                                                <ng-container
                                                    *ngIf="!config.controls[controlLabel].value && !containAr(controlLabel) && config.controls.language.value=='en'">
                                                    <app-cms-upload-media [accept]="mediaTypesAccept.PDF"
                                                        [showOnly]="mediaType.PDF"
                                                        (afterUpload)="setFileToForm($event,configIndex,controlLabel)"
                                                        [isMultiSelect]="false" [formcontrol]="control.value"
                                                        [index]="configIndex">
                                                    </app-cms-upload-media>
                                                </ng-container>
                                                <small
                                                    *ngIf="!containAr(controlLabel) && config.controls.language.value=='en' && control.value.hasError('required')"
                                                    style="color: red !important;">{{'PDF En is required' |
                                                    translate}}</small>

                                                <ng-container
                                                    *ngIf="!config.controls[controlLabel].value && containAr(controlLabel) && config.controls.language.value=='ar'">
                                                    <app-cms-upload-media [accept]="mediaTypesAccept.PDF"
                                                        [showOnly]="mediaType.PDF"
                                                        (afterUpload)="setFileToForm($event,configIndex,controlLabel)"
                                                        [isMultiSelect]="false" [formcontrol]="control.value"
                                                        [index]="configIndex">
                                                    </app-cms-upload-media>
                                                </ng-container>
                                                <small
                                                    *ngIf="containAr(controlLabel) && config.controls.language.value=='ar' && control.value.hasError('required')"
                                                    style="color: red !important;">{{'PDF Ar is required' |
                                                    translate}}</small>

                                                <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch"
                                                    fxLayoutGap="5px"
                                                    *ngIf="config.controls[controlLabel].value && !containAr(controlLabel) && config.controls.language.value=='en'">
                                                    <div class="fuse-card auto-width box-shadow-none" fxFlex="0 0 auto"
                                                        fxLayoutAlign="space-between center" fxLayoutGap="5px"
                                                        fxLayout="row">
                                                        <div class="img-container">
                                                            <app-show-all-type-media
                                                                [url]="config.controls[controlLabel].value">
                                                            </app-show-all-type-media>
                                                        </div>
                                                        <div class="px-16 py-4" fxLayout="row"
                                                            fxLayoutAlign="center center">
                                                            <button mat-icon-button aria-label="Delete">
                                                                <mat-icon (click)="deleteFile(configIndex,controlLabel)"
                                                                    class="action-btn-delete">
                                                                    delete</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch"
                                                    fxLayoutGap="5px"
                                                    *ngIf="config.controls[controlLabel].value && containAr(controlLabel) && config.controls.language.value=='ar'">
                                                    <div class="fuse-card auto-width  box-shadow-none" fxFlex="0 0 auto"
                                                        fxLayoutAlign="space-between center" fxLayoutGap="5px"
                                                        fxLayout="row">

                                                        <div class="img-container">
                                                            <app-show-all-type-media
                                                                [url]="config.controls[controlLabel].value">
                                                            </app-show-all-type-media>
                                                        </div>
                                                        <div class="px-16 py-4" fxLayout="row"
                                                            fxLayoutAlign="center center">
                                                            <button mat-icon-button aria-label="Delete">
                                                                <mat-icon (click)="deleteFile(configIndex,controlLabel)"
                                                                    class="action-btn-delete">
                                                                    delete</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <mat-action-row>
                                <app-delete-button (deleteButtonClick)="delete(configIndex,config)"></app-delete-button>
                            </mat-action-row>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>

        <div (mousedown)="$event.stopPropagation()" (mousemove)="$event.stopPropagation()" fxFlex.lt-md="1 1 auto"
            fxFlex.gt-sm="0 0 30%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px" class="mt-10">
            <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="8px" class="pr-8">
                <button fxLayout="row wrap" mat-flat-button (click)="handleCancel()" class="secondary-bg-color h-30">
                    {{'Cancel' | translate}}</button>
                <div fxLayout="row wrap" class="vertical-gap" fxLayoutAlign="end stretch" fxLayoutGap="10px">
                    <button *ngIf="id || productId" (click)="preview()" type="submit" mat-flat-button
                        class="highlight-bg-color">
                        {{'Preview' | translate}}</button>
                    <!-- <button class="m-0" *ngIf="id || productId || categorytId || brandId" (click)="save(false)"
                        type="submit" mat-flat-button class="primary-bg-color">
                        {{'Save & Continue' | translate}}</button> -->
                    <button (click)="save(true)" type="submit" mat-flat-button class="primary-bg-color">
                        {{'Save' | translate}}</button>
                </div>
            </div>
            <div fxFlex="0 0 auto" class="fuse-card auto-width p-16" fxLayout="column" fxLayoutAlign="start stretch"
                fxLayoutGap="8px">
                <div fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
                    <strong class="highlight-text-color">{{'Page Elements' | translate}}</strong>
                </div>
                <div fxFlex="0 0 auto" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="8px" cdkDropList
                    class="example-list">
                    <div fxFlex="0 0 45%" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px"
                        class="example-box" *ngFor="let item of elements" cdk-drag-preview cdkDrag [cdkDragData]="item">
                        <mat-icon>drag_indicator</mat-icon>
                        <span>{{item.name}}</span>
                        <div *cdkDragPreview>
                            <div style="background-color: white; text-align: center;min-height: 30px;padding: 16px;width: 200px;
                                        border: 1px solid #ccc;box-sizing: border-box;border-radius: 4px;
                                        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
                                        0 8px 10px 1px rgba(0, 0, 0, 0.14),
                                        0 3px 14px 2px rgba(0, 0, 0, 0.12);">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </div>
                <small>*{{'Drag the element and drop it into dotted borderd area' | translate}}</small>
            </div>
            <div fxShow fxHide.lt-md fxFlex="0 0 auto" fxLayout="column" class="fuse-card auto-width">
                <div fxFlex="0 0 auto" fxLayout="row" class="p-8" fxLayoutAlign="space-between">
                    <label class="highlight-text-color">{{'Css' | translate}}</label>
                    <mat-icon class="cursor-pointer highlight-text-color" (click)="zoomPageCss(pageForm.controls.css)">
                        zoom_out_map</mat-icon>
                </div>
                <div fxFlex="0 0 auto" fxLayout="column">
                    <ace-editor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}" [theme]="'clouds'"
                        [mode]="'css'" [options]="options" [formControl]="pageForm.controls['css']"
                        style="height:34vh;">
                    </ace-editor>
                </div>
            </div>

        </div>
    </form>
</div>