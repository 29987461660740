import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "../api.service";

@Injectable({
    providedIn: "root",
})
export class RolesPermissionService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    getRoles(limit = 10, offset = 0, search = "", form?) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        return this.apiService.get(
            this.sharedService.roleCURD,
            params,
            undefined
        );
    }
    getAllPermissions() {
        return this.apiService.get(
            this.sharedService.allPermission,
            undefined,
            undefined
        );
    }
    deleteRole(id) {
        return this.apiService.delete(
            this.sharedService.roleCURD + `${id}/`,
            undefined
        );
    }
    addRole(form) {
        return this.apiService.post(
            this.sharedService.roleCURD,
            form,
            undefined
        );
    }
    updateRole(form, id) {
        return this.apiService.put(
            this.sharedService.roleCURD + `${id}/`,
            form,
            undefined
        );
    }
    getRolesDetails(id) {
        return this.apiService.get(
            this.sharedService.roleCURD + `${id}/`,
            undefined,
            undefined
        );
    }

    // refreshPermission() {
    //     return this.apiService.get(
    //         this.sharedService.refreshPermission,
    //         undefined,
    //         undefined
    //     );
    // }
}
