import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
    FormArray,
} from "@angular/forms";
import { CatalogueService } from "app/services/catalogue/catalogue.service";
import { productField } from "app/main/types/productFields";
import { take, takeUntil } from "rxjs/operators";
import { UtilitiesService } from "app/services/utilitiesService";
import { Subject } from "rxjs";
import { DynamicFieldTypes } from "app/main/types/dynamicField";
import { PageService } from "app/services/pageService";
import { fuseAnimations } from "@fuse/animations";

@Component({
    selector: "app-create-product",
    templateUrl: "./create-product.component.html",
    styleUrls: ["./create-product.component.scss"],
    animations: fuseAnimations
})
export class CreateProductComponent implements OnInit {
    @Input() productId: any = "";
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    @Output() createProductEvent: EventEmitter<void> = new EventEmitter<any>();
    form: FormGroup;
    productForm: FormGroup;
    isApiCallDone: boolean = false;
    imgUrls: any[] = [];
    productFields: any = productField.sections;
    unSubscribe$ = new Subject<void>();
    productInfoData: any;
    deleteImageList: any = [];
    pageElements: any;
    rowData: any
    get productInfo(): FormGroup {
        return this.form.get("product") as FormGroup;
    }
    getImageFormArray() {
        return this.form.get("product_images") as FormArray;
    }
    constructor(
        private fb: FormBuilder,
        private catalogueService: CatalogueService,
        private utilitiesService: UtilitiesService,
        private cdr: ChangeDetectorRef,
        private pageService: PageService
    ) { }

    ngOnInit(): void {
        this.formInitialize();
        this.getPageElements()
        if (this.productId) {
            this.ProductDetailByID();
        } else {
            this.isApiCallDone = true;
        }

        this.productInfo.get("allow_oversell").valueChanges.subscribe((val) => {
            if (val) {
                this.productInfo.get("oversell_quantity").enable();
            } else {
                this.productInfo.get("oversell_quantity").disable();
            }
        });
    }

    async formInitialize() {
        this.form = this.fb.group({
            product: this.fb.group({}),
            product_images: this.fb.array([]),
            product_img: [""],
        });
        this.productFields.forEach((section) => {
            section.fields.forEach((field) => {
                this.productInfo.addControl(
                    field.name,
                    new FormControl(
                        {
                            value:
                                typeof field.default_value === "boolean"
                                    ? field?.default_value
                                    : field?.default_value || "",
                            disabled: field?.disable,
                        },
                        field?.required ? Validators.required : null
                    )
                );
                if (field?.type === DynamicFieldTypes.TextEditor) {
                    this.productInfo.addControl(
                        `${field?.name}_control`,
                        new FormControl("")
                    );
                }
            });
        });
    }

    setFormValue(product) {
        for (let key in this.productInfo.getRawValue()) {
            if (product.hasOwnProperty(key)) {
                this.productInfo.get(key).setValue(product[key]);
            }
        }
        product.product_images.forEach((img) => {
            const imageObj = {
                id: img?.id,
                caption: img?.caption,
                image: img?.image,
                cover_image: img?.cover_image,
                flip_image: img?.flip_image,
                order_by: img?.order_by,
                delete: false,
            };
            let formGroup = this.fb.group(imageObj);
            this.getImageFormArray().push(formGroup);
        });
    }

    ProductDetailByID() {
        this.utilitiesService.startLoader();
        this.catalogueService
            .getProductByID(this.productId)
            .pipe(takeUntil(this.unSubscribe$))
            .subscribe((product) => {
                this.rowData = product
                this.setFormValue(product);
                this.productInfoData = product;
                this.isApiCallDone = true;
            });
        this.utilitiesService.stopLoader();
    }

    setFileToForm(file, type) {
        console.log(file);
        const reader = new FileReader();
        reader.readAsDataURL(file.file);
        reader.onload = () => {
            const imageObj = {
                caption: "",
                image: reader.result as string,
                cover_image: false,
                flip_image: false,
                order_by: "0",
                delete: false,
            };
            let formGroup = this.fb.group(imageObj);
            this.getImageFormArray().push(formGroup);
        };
    }

    handleCancel() {
        this.createProductEvent.emit();
    }

    saveOrUpdate() {
        console.log(this.form.value);
        const form = this.form.value;
        delete form.product_img;

        if (this.form.valid) {
            if (this.productId) {
                this.updateProduct(form);
            } else {
                this.saveProduct(form);
            }
        } else {
            this.checkValidations();
        }
    }

    saveProduct(form) {
        this.utilitiesService.startLoader();
        this.catalogueService.saveProduct(form).subscribe((res) => {
            if (res) {
                this.utilitiesService.stopLoader();
                let msg = "Product saved successfully.";
                this.utilitiesService.showSuccessToast(msg);
                this.createProductEvent.emit();
            }
        });
        this.utilitiesService.stopLoader();
    }

    updateProduct(form) {
        this.utilitiesService.startLoader();
        const modifiedForm = this.updateModifiedForm(form);
        this.catalogueService
            .updateProduct(modifiedForm, this.productId)
            .subscribe((res) => {
                if (res) {
                    this.utilitiesService.stopLoader();
                    let msg = "Product upated successfully.";
                    this.utilitiesService.showSuccessToast(msg);
                    this.createProductEvent.emit();
                }
            });
        this.utilitiesService.stopLoader();
    }

    updateModifiedForm(form) {
        if (this.deleteImageList && this.deleteImageList.length) {
            this.deleteImageList.forEach((image) => {
                form.product_images.push(image);
            });

            console.log(form.product_images);

            this.deleteImageList = [];
        }
        return form;
    }

    imageType(key, index, event) {
        if (!event.checked) {
            return; // If the checkbox is unchecked, do nothing.
        }

        const imageFormArray = this.getImageFormArray();
        const controlKey = `${key}_image`; // Create the dynamic control key, e.g., "cover_image" or "flip_image".

        imageFormArray.controls.forEach((control, i) => {
            if (i !== index) {
                (control as FormGroup).controls[controlKey].setValue(false);
            }
        });
    }

    checkValidations() {
        for (const controlName in this.productInfo.controls) {
            if (this.productInfo.controls.hasOwnProperty(controlName)) {
                const control = this.productInfo.get(controlName);
                if (control.invalid) {
                    let msg = `${this.transformControlName(
                        controlName
                    )} is required.`;
                    this.utilitiesService.showErrorToast(msg);
                    break; // Stop the loop as soon as an invalid control is found
                }
            }
        }

        Object.keys(this.productInfo.controls).forEach((field) => {
            const control = this.productInfo.get(field);
            control.markAsTouched({ onlySelf: true });
        });
    }
    transformControlName(controlName: string): string {
        return controlName
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    }
    deleteImage(index) {
        let id: number;
        id = index;
        const arr: FormArray = this.form.get("product_images") as FormArray;
        const grp: FormGroup = arr.controls[id] as FormGroup;
        if (!this.productId) {
            this.getImageFormArray().removeAt(id);
            console.log("no iid");
        } else {
            this.getImageFormArray().at(id).get("delete").setValue(true);
            const deleteImageObj = this.getImageFormArray().at(id).value;
            if (deleteImageObj.id) {
                this.deleteImageList.push(deleteImageObj);
                console.log(this.deleteImageList);
            }
            this.getImageFormArray().removeAt(id);
            console.log("iid");
        }
        //  arr.controls.splice(id, 1);
        console.log(this.form.get("product_images") as FormArray);
        this.cdr.markForCheck();
    }
    ngOnDestroy(): void {
        this.unSubscribe$.next();
        this.unSubscribe$.complete();
    }

    async getPageElements() {
        try {
            const pageElements = await this.pageService
                .getElements("page", '20')
                .toPromise();
            if (pageElements) {
                this.pageElements = pageElements;
            }
        } catch { }
    }
}
