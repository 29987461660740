export enum AppRoutes {
    Home = "home",
    PagesModule = "",
    Shops = "shops",
    ProductsInsights = "products-insights",
    Pages = "pages",
    Media = "media",
    Apps = "apps",
    Widgets = "widgets",
    NavMenu = "navigation-menu",
    DynamicField = "dynamic-fields",
    Sliders = "collection",
    transaction = "transaction",
    ErrorPage = "403",
    Auth = "auth",
    CustomerList = "customer-list",
    Orders = "orders",
    Cart = "cart",
    Notification = "notifications",
    ConfigurationAttribute = "configuration-attribute",
    NotificationTemplate = "notification-templates",
    Campaigns = "campaigns",
    Shopify = "shopify",
    Memberships = "memberships",
    TranslationEditor = "translation-editor",
    Login = "login",
    PriceRules = "price-rules",
    SyncJob = "sync-job-import",
    Wallets = "Wallets",
    Affiliate = "affiliate",
    AffiliateDashboard = "affiliate-dashboard",
    Audience = "audience",
    Posts = "posts/post",
    Wishlist = "wishlist",
    PostCategory = "posts/category",
    PostAppearance = "posts/appearance",
    Wordpress = "wordpress",
    RolesPermission = "roles-permissions",
    Users = "users",
    AnalyticsIntegration = "analytics-integration",
    Catalog = "catalog-integration",
    LoyaltyConfiguration = "loyalty-configuration",
    LoyaltyPointsRule = "loyalty-points-rule",
    pointsSummary = "points-summary",
    reviews = "reviews",
    products = "products",
    categories = "categories",
    brands = "brands",
    attributes = "attributes",
    ConnectInsights = "connect-insights",
    ConnectPage = "connect-page",
    deliveryAgents = "delivery-agents",
    addDeliveryAgents = "delivery-agents/add",
    updateDeliveryAgents = "delivery-agents/:id",
    UnDeliveredReason = "un-delivered-reason",
}
