import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from "@angular/material/dialog";
import { CatalogueService } from "app/services/catalogue/catalogue.service";
import { UtilitiesService } from "app/services/utilitiesService";

@Component({
    selector: "app-create-sticker",
    templateUrl: "./create-sticker.component.html",
    styleUrls: ["./create-sticker.component.scss"],
})
export class CreateStickerComponent implements OnInit {
    form: FormGroup;
    id: any;
    text_color: any;
    bg_color: any;
    oldData: any = null;
    title: any = "Create Sticker";
    constructor(
        private dialogRef: MatDialogRef<CreateStickerComponent>,
        private fb: FormBuilder,
        private utilitiesService: UtilitiesService,
        private catalogueService: CatalogueService,
        private cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data) {
            this.oldData = data.oldData ? data.oldData : null;
            this.cdr.markForCheck();
            this.title = "Edit Sticker";
        } else {
            this.title = "Create Sticker";
        }
    }

    ngOnInit(): void {
        this.formInit();
    }

    formInit() {
        this.form = this.fb.group({
            id: [this.oldData?.id ? this.oldData.id : null],
            name: [
                this.oldData?.name ? this.oldData.name : "",
                Validators.required,
            ],
            name_ar: [this.oldData?.name_ar ? this.oldData?.name_ar : ""],
            order_by: this.oldData?.order_by ? this.oldData?.order_by : "0",
            icon: this.oldData?.icon ? this.oldData?.icon : "",
            bg_color: this.oldData?.bg_color ? this.oldData?.bg_color : "",
            text_color: this.oldData?.text_color
                ? this.oldData?.text_color
                : "",
        });
    }

    saveOrUpdate() {
        let form = this.form.value;

        if (this.form.valid) {
            if (this.id) {
            } else {
                this.save(form);
            }
        }
    }

    save(form) {
        this.utilitiesService.startLoader();
        this.catalogueService.addSticker(form).subscribe((res) => {
            if (res) {
                this.utilitiesService.stopLoader();
                let msg = "Sticker saved successfully.";
                this.utilitiesService.showSuccessToast(msg);
                this.dialogRef.close(true);
            }
        });
        this.utilitiesService.stopLoader();
    }

    handleCancel() {
        this.dialogRef.close();
    }

    setToForm(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file.file);
        reader.onload = () => {
            const img = reader.result as string;
            this.form.controls.icon.setValue(img);
        };
    }
    deleteFile() {
        this.form.controls.icon.setValue("");
    }

    setColor(type) {
        console.log("set color");
        console.log("type is " + type);
        if (type == "text_color") {
            this.form.controls.text_color.setValue(this.text_color);
        }
        if (type == "bg_color") {
            this.form.controls.bg_color.setValue(this.bg_color);
        }
    }
    openOrClose(type) {
        console.log(this.form.controls.text_color.value);
        let testArray = [1, 2]; // color picker issue solved by this temp trick
        if (type == "text") {
            testArray.forEach((data) => {
                this.text_color = this.form.controls.text_color.value;
            });
        }
        if (type == "bg_color") {
            testArray.forEach((data) => {
                this.bg_color = this.form.controls.bg_color.value;
            });
        }
    }
}
