export enum DynamicFieldTypes {
    Text = "text",
    Number = "number",
    Date = "date",
    Select = "select",
    MultiSelect="multi_select",
    TextArea = "textarea",
    Radio = "radio",
    Checkbox = "checkbox",
    Toggle = "toggle",
    Email = "email",
    Password = "password",
    File='file',
    SearchableSelect='searchable_select',
    TextEditor = "text_editor",
}