<div
    fxFlex="1 1 100%"
    fxLayoutAlign="start stretch"
    fxLayout.lt-md="column"
    fxLayout.gt-sm="column"
    fxLayoutGap="1em"
>
    <div class="heading">
        {{ title }}
    </div>
    <form
        fxFlex="1 1 100%"
        fxLayoutAlign="start stretch"
        fxLayout.lt-md="column"
        fxLayout.gt-sm="row"
        fxLayoutGap="1em"
        [formGroup]="form"
    >
        <div
            fxFlex="1 1 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="1em"
        >
            <div
                fxFlex="0 0 auto"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="0px"
            >
                <div
                    fxFlex="0 0  auto"
                    class="fuse-card auto-width p-16 center-container"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="1em"
                >
                    <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="16px"
                    >
                        <div
                            fxFlex="0 0 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout="column"
                            fxLayoutGap="0px"
                        >
                            <div
                                fxFlex="0 0 auto"
                                fxLayoutAlign="start stretch"
                                fxLayout="column"
                                fxLayoutGap.gt-sm="5px"
                                fxLayoutGap.lt-md="0px"
                            >
                                <div
                                    fxFlex="0 0 auto"
                                    fxLayoutAlign="start stretch"
                                    fxLayout.lt-md="column"
                                    fxLayout.gt-sm="row"
                                    fxLayoutGap.gt-sm="5px"
                                    fxLayoutGap.lt-md="0px"
                                >
                                    <mat-form-field
                                        appearance="outline"
                                        fxFlex="100"
                                    >
                                        <mat-label class="text-capitalized">
                                            {{ "Name" | translate }}
                                        </mat-label>
                                        <input
                                            formControlName="name"
                                            matInput
                                            name="title"
                                        />
                                        <mat-error
                                            *ngIf="
                                                form
                                                    .get('name')
                                                    .hasError('required')
                                            "
                                        >
                                            {{ "Name is required" | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field
                                        appearance="outline"
                                        fxFlex="100"
                                    >
                                        <mat-label class="text-capitalized">
                                            {{ "Name(Arabic)" | translate }}
                                        </mat-label>
                                        <input
                                            formControlName="name_ar"
                                            matInput
                                            name="title"
                                        />
                                        <mat-error
                                            *ngIf="
                                                form
                                                    .get('name_ar')
                                                    .hasError('required')
                                            "
                                        >
                                            {{ "Name is required" | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div
                                    fxFlex="0 0 auto"
                                    fxLayoutAlign="start stretch"
                                    fxLayout.lt-md="column"
                                    fxLayout.gt-sm="row"
                                    fxLayoutGap.gt-sm="5px"
                                    fxLayoutGap.lt-md="0px"
                                >
                                    <mat-form-field
                                        appearance="outline"
                                        fxFlex="100"
                                        fxLayout="row"
                                        style="display: flex"
                                    >
                                        <mat-label>
                                            {{
                                                "Text Color" | translate
                                            }}</mat-label
                                        >
                                        <input
                                            matInput
                                            formControlName="text_color"
                                        />
                                        <mat-icon
                                            (cpToggleChange)="
                                                openOrClose('text_color')
                                            "
                                            (colorPickerSelect)="
                                                setColor('text_color')
                                            "
                                            [cpOKButton]="true"
                                            [cpCancelButton]="true"
                                            [cpSaveClickOutside]="false"
                                            [cpPosition]="'bottom'"
                                            [(colorPicker)]="text_color"
                                            class="color-icon cursor-pointer"
                                            [style.color]="text_color"
                                        >
                                            color_lens
                                        </mat-icon>
                                    </mat-form-field>
                                    <mat-form-field
                                        appearance="outline"
                                        fxFlex="100"
                                        fxLayout="row"
                                        style="display: flex"
                                    >
                                        <mat-label>
                                            {{
                                                "background Color" | translate
                                            }}</mat-label
                                        >
                                        <input
                                            matInput
                                            formControlName="bg_color"
                                        />
                                        <mat-icon
                                            (cpToggleChange)="
                                                openOrClose('bg_color')
                                            "
                                            (colorPickerSelect)="
                                                setColor('bg_color')
                                            "
                                            [cpOKButton]="true"
                                            [cpCancelButton]="true"
                                            [cpSaveClickOutside]="false"
                                            [cpPosition]="'bottom'"
                                            [(colorPicker)]="bg_color"
                                            class="color-icon cursor-pointer"
                                            [style.color]="bg_color"
                                        >
                                            color_lens
                                        </mat-icon>
                                    </mat-form-field>
                                </div>

                                <div
                                    fxFlex="0 0 auto"
                                    fxLayoutAlign="start stretch"
                                    fxLayout.lt-md="column"
                                    fxLayout.gt-sm="row"
                                    fxLayoutGap.gt-sm="5px"
                                    fxLayoutGap.lt-md="0px"
                                >
                                    <mat-form-field
                                        appearance="outline"
                                        fxFlex="100"
                                    >
                                        <mat-label class="text-capitalized">
                                            {{ "Order" | translate }}
                                        </mat-label>
                                        <input
                                            formControlName="order_by"
                                            matInput
                                            name="title"
                                        />
                                    </mat-form-field>
                                    <div fxFlex="100">
                                        <ng-container
                                            *ngIf="!form.get('icon').value"
                                        >
                                            <app-cms-upload-media
                                                [formcontrol]="
                                                    form.controls.icon
                                                "
                                                (afterUpload)="
                                                    setToForm($event)
                                                "
                                                saveLocally="true"
                                                [index]="2"
                                                uploadOnly="false"
                                            >
                                            </app-cms-upload-media>
                                        </ng-container>

                                        <div
                                            *ngIf="form.get('icon').value"
                                            class="image-container"
                                        >
                                            <div>
                                                <img
                                                    class="added-img"
                                                    [src]="
                                                        form.get('icon').value
                                                    "
                                                />
                                            </div>
                                            <div
                                                class="px-16 py-4"
                                                fxLayout="row"
                                                fxLayoutAlign="center center"
                                            >
                                                <button
                                                    mat-icon-button
                                                    aria-label="Delete"
                                                >
                                                    <mat-icon
                                                        (click)="deleteFile()"
                                                        class="action-btn-delete"
                                                        >delete</mat-icon
                                                    >
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        fxFlex="1 1 auto"
                        fxLayoutAlign.gt-sm="end center"
                        fxLayoutAlign.lt-md="start stretch"
                        fxLayout.lt-md="column-reverse"
                        fxLayout.gt-sm="row"
                        fxLayoutGap="5px"
                    >
                        <button mat-stroked-button (click)="handleCancel()">
                            <mat-icon>clear</mat-icon>
                            {{ "Cancel" | translate }}
                        </button>
                        <button
                            (click)="saveOrUpdate()"
                            type="submit"
                            mat-flat-button
                            class="primary-bg-color"
                        >
                            <mat-icon>save</mat-icon>
                            {{ "Save" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
