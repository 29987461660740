<div id="dashboard-project" class="page-layout simple right-sidebar" fxLayout="column">
    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" fusePerfectScrollbar>
        <form [formGroup]="form" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
            fusePerfectScrollbar>
            <div fxFlex="0 0 auto" fxLayoutAlign="flex-end" fxLayout="row" fxLayoutGap="1em">
                <button class="btn-border" (click)="goBack()" type="submit"
                    mat-flat-button>{{'Cancel' | translate}}</button>
                <button (click)="save()" type="submit" mat-flat-button
                    class="primary-bg-color">{{'Save' | translate}}</button>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row" fxLayout.lt-md="column"
                    fxLayoutGap="1em">
                    <div fxFlex.lt-md="1 1 auto" class="fuse-card auto-width p-16" fxFlex.gt-sm="1 1 75%"
                        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <label class="highlight-text-color mat-title">{{'Permission List' | translate}}</label>
                        <div fxFlex="0 0 auto" class="fuse-card auto-width p-16" fxLayoutAlign="start stretch"
                            fxLayout="column" fxLayoutGap="1em"
                            *ngFor="let permission of allPermmissionList;let i =index">
                            <div fxFlex="1 1 auto" fxLayoutAlign="space-between center" fxLayout="row wrap"
                                class="highlight-text-color border-2">
                                <strong class="font-size-18 bold mb-4">{{i+1}} )
                                    {{translationService.getTranslatedField(permission,"module_name")}}</strong>
                                <mat-checkbox (change)="checkForModal($event,i)"
                                    [checked]="checkAllChecked(permission?.permissions)" class="mat-primary">All
                                </mat-checkbox>
                            </div>
                            <div class="border-b-secondary-color m-0"></div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row wrap" fxLayoutGap="1em"
                                class="grid">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row wrap"
                                    fxLayoutGap="1em" class="m-2 p-2"
                                    *ngFor="let subPermission of permission?.permissions">
                                    <mat-checkbox class="checkbox-item" [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="checkedOrUnchecked[subPermission?.id]"
                                        (change)="checkBoxChange($event,subPermission?.id)">
                                        {{translationService.getTranslatedName(subPermission)}}</mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxFlex.lt-md="1 1 auto" class="fuse-card auto-width p-16"
                        style="height: fit-content !important;" fxFlex.gt-sm="1 1 25%" fxLayoutAlign="start stretch"
                        fxLayout="column" fxLayoutGap="0px">
                        <label class="highlight-text-color mat-title mb-16">{{'Role Detail' | translate}}</label>
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label class="text-capitalized">
                                    {{'Name (En)'| translate}}
                                </mat-label>
                                <input matInput name="title_en" formControlName="name_en">
                                <mat-error *ngIf="form.get('name_en').hasError('required')">
                                    {{'Name En is required' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label class="text-capitalized">
                                    {{'Name (Ar)'| translate}}
                                </mat-label>
                                <input matInput name="title_en" formControlName="name_ar">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>