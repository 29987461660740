import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "commerce",
        title: "COMMERCE",
        translate: "NAV.Ecommerce",
        type: "group",
        hidden: false,
        children: [
            {
                id: "productsNav",
                title: "Products Insights",
                translate: "NAV.productsNav",
                type: "item",
                icon: "insert_chart_outlined",
                hidden: false,
                url: "/products-insights",
            },
            {
                id: "ordersNav",
                title: "Orders",
                translate: "NAV.ordersNav",
                type: "collapsable",
                icon: "shopping_cart",
                hidden: false,
                children: [
                    {
                        id: "orderSubNav",
                        title: "Orders",
                        translate: "NAV.orderSubNav.TITLE",
                        type: "item",
                        icon: "local_mall",
                        url: "/orders",
                        hidden: false,
                    },
                    {
                        id: "cart",
                        title: "Cart",
                        translate: "NAV.cart.TITLE",
                        type: "item",
                        icon: "shopping_cart",
                        url: "/cart",
                        hidden: false,
                    },
                ],
            },
            {
                id: "customer",
                title: "Customer",
                translate: "NAV.customer",
                type: "collapsable",
                icon: "person_outline",
                hidden: false,
                children: [
                    {
                        id: "customer_list",
                        title: "List",
                        translate: "NAV.customer_list.TITLE",
                        type: "item",
                        icon: "people",
                        url: "/customer-list",
                        hidden: false,
                    },
                    // {
                    //     id: '2',
                    //     title: 'Memberships',
                    //     translate: 'NAV.2.TITLE',
                    //     type: 'item',
                    //     icon: 'card_membership',
                    //     url: '/memberships',
                    //     hidden: false,
                    // },
                    {
                        id: "audience",
                        title: "Audience",
                        translate: "NAV.audience.TITLE",
                        type: "item",
                        icon: "people_outline",
                        url: "/audience",
                        hidden: false,
                    },
                    {
                        id: "wishlist",
                        title: "Wishlist",
                        translate: "NAV.wishlist.TITLE",
                        type: "item",
                        icon: "favorite",
                        url: "/wishlist",
                        hidden: false,
                    },
                    // {
                    //     id: "condition",
                    //     title: "Condition",
                    //     translate: "NAV.condition.TITLE",
                    //     type: "item",
                    //     icon: "wb_auto",
                    //     url: "/condition",
                    // },
                ],
            },
        ],
    },
    {
        id: "marketing",
        title: "MARKETING",
        translate: "NAV.marketing",
        type: "group",
        hidden: false,
        children: [
            {
                id: 'affiliateDashBoard',
                title: 'Dashboard',
                translate: 'NAV.dashboard.TITLE',
                type: 'item',
                icon: 'dashboard',
                url: '/affiliate-dashboard',
                hidden: false,
            },
            {
                id: '3',
                title: 'Campaigns',
                translate: 'NAV.3.TITLE',
                type: 'item',
                icon: 'business_center',
                url: '/campaigns',
                hidden: false,
            },
            {
                id: '4',
                title: 'Price Rules',
                translate: 'NAV.pricerules.TITLE',
                type: 'item',
                icon: 'attach_money',
                url: '/price-rules',
                hidden: false,
            },
            {
                id: 'social-page',
                title: 'Social Page',
                translate: 'NAV.social.TITLE',
                type: 'collapsable',
                icon: 'group',
                hidden: false,
                children: [
                    {
                        id: 'connect-page',
                        title: 'Connect Page',
                        translate: 'NAV.connectPage.TITLE',
                        type: 'item',
                        icon: 'dashboard',
                        url: '/connect-page',
                        hidden: false,
                    },
                    // {
                    //     id: 'connect-insights',
                    //     title: 'Connect Insights',
                    //     translate: 'NAV.connectInsights.TITLE',
                    //     type: 'item',
                    //     icon: 'business_center',
                    //     url: '/connect-insights',
                    //     hidden: false,
                    // }
                ]
            }
        ],
    },
    {
        id: "catalogue",
        title: "CATALOG",
        translate: "NAV.catalogue",
        type: "group",
        hidden: false,
        children: [
            {
                id: "reviews",
                title: "Reviews",
                translate: "NAV.reviews.TITLE",
                type: "item",
                icon: "star",
                url: "/reviews",
                hidden: false,
            },
            {
                id: "products",
                title: "Products",
                translate: "NAV.products.TITLE",
                type: "item",
                icon: "shopping_cart",
                url: "/products",
                hidden: false,
            },
            {
                id: "categories",
                title: "Categories",
                translate: "NAV.category.TITLE",
                type: "item",
                icon: "category",
                url: "/categories",
                hidden: false,
            },
            {
                id: "brands",
                title: "Brands",
                translate: "NAV.brands.TITLE",
                type: "item",
                icon: "shopping_cart",
                url: "/brands",
                hidden: false,
            },
            {
                id: "attribute",
                title: "Attribute",
                translate: "NAV.attribute",
                type: "item",
                icon: "shopping_cart",
                url: "/attributes",
                hidden: false,
            },
            {
                id: "Configuration Attribute",
                title: "Configuration Attribute",
                translate: "NAV.configuration_attribute.TITLE",
                type: "item",
                icon: "shopping_cart",
                url: "/configuration-attribute",
                hidden: false,
            },
        ],
    },
    {
        id: "Delivery",
        title: "Delivery",
        translate: "NAV.Delivery",
        type: "group",
        hidden: false,
        children: [
            {
                id: "DeliveryAgent",
                title: "Delivery Agent",
                translate: "NAV.DeliveryAgent",
                type: "item",
                icon: "people_outline",
                url: "/delivery-agents",
                hidden: false,
            },
            {
                id: "Delivery",
                title: "Delivery ",
                translate: "NAV.Delivery",
                type: "item",
                icon: "local_shipping",
                url: "/delivery",
                hidden: false,
            },
            {
                id: "unDeliveredReason",
                title: "UnDeliveredReason ",
                translate: "NAV.UnDeliveredReason",
                type: "item",
                icon: "broken_image",
                url: "/un-delivered-reason",
                hidden: false,
            },
        ],
    },
    {
        id: "contant",
        title: "CONTENT",
        translate: "NAV.contant",
        type: "group",
        hidden: false,
        children: [
            {
                id: "page",
                title: "Pages",
                translate: "NAV.page.TITLE",
                type: "item",
                icon: "pages",
                url: "/pages",
                hidden: false,
            },
            {
                id: "widgetsNav",
                title: "Widgets",
                translate: "NAV.widgetsNav",
                type: "collapsable",
                icon: "widgets",
                hidden: false,
                children: [
                    {
                        id: "collection",
                        title: "Collection",
                        translate: "NAV.collection.TITLE",
                        type: "item",
                        icon: "slideshow",
                        url: "/collection",
                        hidden: false,
                    },
                    {
                        id: "widgets",
                        title: "Widgets",
                        translate: "NAV.widgets.TITLE",
                        type: "item",
                        icon: "widgets",
                        url: "/widgets",
                        hidden: false,
                    },
                    {
                        id: "navmenu",
                        title: "Navigation Menu",
                        translate: "NAV.navmenu.TITLE",
                        type: "item",
                        icon: "menu",
                        url: "/navigation-menu",
                        hidden: false,
                    },
                ],
            },

            // {
            //     id: "postsNav",
            //     title: "Blog",
            //     translate: "NAV.blogNav",
            //     type: "collapsable",
            //     icon: "local_post_office",
            //     hidden: false,
            //     children: [
            //         {
            //             id: "posts",
            //             title: "Posts",
            //             translate: "NAV.posts.TITLE",
            //             type: "item",
            //             icon: "local_post_office",
            //             url: "posts/post",
            //             hidden: false,
            //         },
            //         {
            //             id: "category",
            //             title: "Categories",
            //             translate: "NAV.category.TITLE",
            //             type: "item",
            //             icon: "category",
            //             url: "posts/category",
            //             hidden: false,
            //         },
            //         {
            //             id: "appearance",
            //             title: "Appearance",
            //             translate: "NAV.appearance.TITLE",
            //             type: "item",
            //             icon: "palette",
            //             url: "posts/appearance",
            //             hidden: false,
            //         },
            //     ],
            // },

            {
                id: "media",
                title: "Media",
                translate: "NAV.media.TITLE",
                type: "item",
                icon: "perm_media",
                url: "/media",
                hidden: false,
            },
        ],
    },
    {
        id: "notification",
        title: "NOTIFICATION",
        translate: "NAV.notification",
        type: "group",
        hidden: false,
        children: [
            {
                id: "templates",
                title: "Templates",
                translate: "NAV.templates.TITLE",
                type: "item",
                icon: "notifications",
                url: "/notification-templates",
                hidden: false,
            },
            {
                id: "notification_configuration",
                title: "Configurations",
                translate: "NAV.notification_configuration.TITLE",
                type: "item",
                icon: "build",
                url: "/notifications",
                hidden: false,
            },
        ],
    },
    {
        id: "salesChannels",
        title: "SALES CHANNELS",
        translate: "NAV.salesChannels",
        type: "group",
        hidden: false,
        children: [
            {
                id: "myshops",
                title: "My Shop",
                translate: "NAV.myshops",
                type: "item",
                icon: "store_mall_directory",
                url: "/apps",
                hidden: false,
            },
            // {
            //     id: "myshops",
            //     title: "My Shop",
            //     translate: "NAV.myshops",
            //     type: "collapsable",
            //     icon: "store_mall_directory",
            //     children: [
            //         {
            //             id: "configuration",
            //             title: "Configurations",
            //             translate: "NAV.configuration.TITLE",
            //             type: "item",
            //             icon: "build",
            //             url: "/apps",
            //         },
            //         {
            //             id: "translation_editor",
            //             title: "Translation Editor",
            //             translate: "NAV.translation_editor.TITLE",
            //             type: "item",
            //             icon: "translate",
            //             url: "/translation-editor",
            //         },
            //         {
            //             id: "5",
            //             title: "Custom Fields",
            //             translate: "NAV.5.TITLE",
            //             type: "item",
            //             icon: "text_fields",
            //             url: "/dynamic-fields",
            //         },
            //     ],
            // },
            // {
            //     id: "Integration",
            //     title: "Integrations",
            //     translate: "NAV.Integration",
            //     type: "collapsable",
            //     hidden: false,
            //     icon: "apps",
            //     children: [
            //         // {
            //         //     id: '1',
            //         //     title: 'Shopify',
            //         //     translate: 'NAV.1',
            //         //     type: 'item',
            //         //     icon: 'apps',
            //         //     url: '/shopify',
            //         //     hidden: false,
            //         // },
            //         // {
            //         //     id: '6',
            //         //     title: 'Wordpress',
            //         //     translate: 'NAV.6.TITLE',
            //         //     type: 'item',
            //         //     icon: 'drag_handle',
            //         //     url: '/wordpress',
            //         //     hidden: false,
            //         // },
            //         {
            //             id: "7",
            //             title: "Analytics",
            //             translate: "NAV.7.TITLE",
            //             type: "item",
            //             icon: "insert_chart_outlined",
            //             url: "/analytics-integration",
            //             hidden: false,
            //         },
            //         {
            //             id: "catalog-integration",
            //             title: "Catalog",
            //             translate: "NAV.8.TITLE",
            //             type: "item",
            //             icon: "book",
            //             url: "/catalog-integration",
            //             hidden: false,
            //         },
            //     ],
            // },
        ],
    },
    {
        id: "usermanagement",
        title: "USER MANAGEMENT",
        translate: "NAV.usermanagement",
        type: "group",
        hidden: false,
        children: [
            {
                id: "users",
                title: "Users",
                translate: "NAV.users.TITLE",
                type: "item",
                icon: "supervised_user_circle",
                url: "/users",
                hidden: false,
            },
            {
                id: "roles_and_permission",
                title: "Roles & Permission",
                translate: "NAV.roles_and_permission.TITLE",
                type: "item",
                icon: "security",
                url: "/roles-permissions",
                hidden: false,
            },
        ],
    },
    {
        id: "loyaltyManagement",
        title: "Loyalty Management",
        translate: "NAV.loyaltyManagement",
        type: "group",
        children: [
            {
                id: "pointsSummary",
                title: "Points Configuration",
                translate: "NAV.pointsSummary.TITLE",
                type: "item",
                icon: "list_alt",
                url: "/points-summary",
            },
            {
                id: "loyaltyConfiguration",
                title: "Loyalty Configuration",
                translate: "NAV.loyaltyConfiguration.TITLE",
                type: "item",
                icon: "display_settings",
                url: "/loyalty-configuration",
            },
            {
                id: "loyaltyPointsRule",
                title: "Loyalty Points Rule",
                translate: "NAV.loyaltyPointsRule.TITLE",
                type: "item",
                icon: "gavel",
                url: "/loyalty-points-rule",
            },
            {
                id: "Wallets",
                title: "Wallets",
                translate: "NAV.wallets.TITLE",
                type: "item",
                icon: "account_balance_wallet",
                url: "/Wallets",
            },
            {
                id: "Sync Job",
                title: "Sync Job",
                // translate: "Sync Job",
                type: "item",
                icon: "gavel",
                url: "/sync-job-import",
            },
        ],
    },
];
