<div id="dashboard-project" class="page-layout" fxLayout="column">
    <div
        *ngIf="!isView && !isEdit"
        fxFlex="0 0 auto"
        fxLayoutAlign="start stretch"
        fxLayout="column"
        fxLayoutGap="1em"
    >
        <div
            fxFlex="0 0 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="0px"
            class="fuse-card auto-width"
        >
            <div
                fxFlex="0 0 auto"
                class="p-16 customer-header"
                fxLayoutAlign="space-between center"
                fxLayout.lt-md="column"
                fxLayout.gt-sm="row"
                fxLayoutGap="4px"
            >
                <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="start">
                    <strong style="font-size: 20px"
                        >{{ customerDetail?.first_name }}
                        {{ customerDetail?.middle_name }}
                        {{ customerDetail?.last_name }}</strong
                    >
                    <div
                        *ngIf="customerDetail?.contact_no"
                        fxFlex="0 0 auto"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                    >
                        <span class="mr-8 ml-8"> - </span>
                        <mat-icon>local_phone</mat-icon>
                        <strong
                            *ngIf="customerDetail?.contact_no"
                            style="font-size: 20px"
                            ><span
                                *ngIf="
                                    customerDetail?.contact_no_country
                                        ?.isd_code &&
                                    !utilitiesService.checkIfISDCodeExist(
                                        customerDetail?.contact_no
                                    )
                                "
                                >+{{
                                    customerDetail?.contact_no_country?.isd_code
                                }} </span
                            >{{ customerDetail?.contact_no }}</strong
                        >
                    </div>
                </div>
                <div
                    fxFlex="0 0 auto"
                    fxLayout="column"
                    fxLayoutAlign="flex-end"
                    [@animate]="{
                        value: '*',
                        params: { duration: '500ms', x: '150px', y: '0px' }
                    }"
                >
                    <span
                        ><span class="font-size-12"
                            >{{ "Total Orders" | translate }} : </span
                        ><strong
                            class="mat-title highlight-text-color cursor-pointer"
                            (click)="gotoOrders()"
                            >{{ customerSummary?.orders }}</strong
                        ></span
                    >
                    <span
                        ><span class="font-size-12"
                            >{{ "Total Amount" | translate }} : </span
                        ><strong class="mat-title highlight-text-color"
                            >{{ customerSummary?.total_amount }}
                            {{ currencyCode }}</strong
                        ></span
                    >
                </div>
            </div>
            <div
                fxFlex="0 0 auto"
                fxLayoutAlign="flex-end stretch"
                fxLayout.lt-md="column"
                fxLayout.gt-sm="row"
                fxLayoutGap="8px"
                class="p-16"
            >
                <button
                    *ngIf="
                        coreService.checkPermission(
                            utilitiesService.Permission.CustomerUpdate
                        )
                    "
                    mat-stroked-button
                    class="action-btn-edit"
                    (click)="editCustomer()"
                >
                    <mat-icon class="action-btn-edit">edit</mat-icon>
                    {{ "Edit" | translate }}
                </button>
                <button
                    *ngIf="
                        coreService.checkPermission(
                            utilitiesService.Permission.SendNotification
                        )
                    "
                    mat-stroked-button
                    class="highlight-text-color"
                    (click)="sendNotification()"
                >
                    <mat-icon class="highlight-text-color"
                        >notifications</mat-icon
                    >
                    {{ "Send Notification" | translate }}
                </button>
                <!-- <button *ngIf="customerDetail?.customer_type==2" mat-stroked-button style="color:#FFC138 !important"
                    (click)="changePassowrd()">{{'Change Password'  | translate}}</button> -->
            </div>
            <div
                fxFlex="0 0 auto"
                fxLayoutAlign="start stretch"
                fxLayout.lt-md="column"
                fxLayout.gt-sm="row"
                fxLayoutGap="8px"
                class="p-16"
            >
                <div
                    class="fuse-card auto-width p-16"
                    fxFlex.gt-sm="1 1 0px"
                    fxFlex.lt-md="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="8px"
                >
                    <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="space-between stretch"
                        fxLayout="row"
                        fxLayoutGap="10px"
                    >
                        <label class="highlight-text-color">{{
                            "Personal Information" | translate
                        }}</label>
                    </div>

                    <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="1em"
                    >
                        <div
                            *ngIf="customerDetail?.gender"
                            fxFlex="1 1 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout="row"
                            fxLayoutGap="8px"
                        >
                            <div fxFlex="1 1 0px">
                                {{ "Gender" | translate }}
                            </div>
                            <div
                                fxFlex="1 1 0px"
                                *ngIf="customerDetail?.gender"
                            >
                                <span *ngIf="customerDetail?.gender == 1">{{
                                    "Male" | translate
                                }}</span>
                                <span *ngIf="customerDetail?.gender == 2">{{
                                    "Female" | translate
                                }}</span>
                                <span *ngIf="customerDetail?.gender == 3">{{
                                    "Others" | translate
                                }}</span>
                            </div>
                        </div>
                        <div
                            *ngIf="customerDetail?.email"
                            fxFlex="1 1 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout="row"
                            fxLayoutGap="8px"
                        >
                            <div fxFlex="1 1 0px">
                                {{ "Email" | translate }}
                            </div>
                            <div fxFlex="1 1 0px">
                                {{ customerDetail?.email }}
                            </div>
                        </div>
                        <div
                            *ngIf="customerDetail?.contact_no"
                            fxFlex="1 1 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout="row"
                            fxLayoutGap="8px"
                        >
                            <div fxFlex="1 1 0px">
                                {{ "Contact Number" | translate }}
                            </div>
                            <div fxFlex="1 1 0px">
                                <span
                                    *ngIf="
                                        customerDetail?.contact_no_country
                                            ?.isd_code &&
                                        !utilitiesService.checkIfISDCodeExist(
                                            customerDetail?.contact_no
                                        )
                                    "
                                    >+{{
                                        customerDetail?.contact_no_country
                                            ?.isd_code
                                    }}
                                </span>
                                {{ customerDetail?.contact_no }}
                            </div>
                        </div>
                        <div
                            *ngIf="customerDetail?.dob"
                            fxFlex="1 1 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout="row"
                            fxLayoutGap="8px"
                        >
                            <div fxFlex="1 1 0px">
                                {{ "Date of Birth" | translate }}
                            </div>
                            <div fxFlex="1 1 0px">
                                {{ customerDetail?.dob | date : "dd/MM/yyyy" }}
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="fuse-card auto-width p-16"
                    fxFlex.gt-sm="1 1 0px"
                    fxFlex.lt-md="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="8px"
                >
                    <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="space-between stretch"
                        fxLayout="row"
                        fxLayoutGap="10px"
                    >
                        <label class="highlight-text-color">{{
                            "Additional Information" | translate
                        }}</label>
                    </div>

                    <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="1em"
                    >
                        <div
                            fxFlex="1 1 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout="row"
                            fxLayoutGap="8px"
                        >
                            <div fxFlex="1 1 0px">
                                {{ "User Verified" | translate }}
                            </div>
                            <div fxFlex="1 1 0px">
                                <span *ngIf="!customerDetail?.verified">
                                    <mat-icon style="color: orangered"
                                        >close</mat-icon
                                    >
                                </span>
                                <span *ngIf="customerDetail?.verified">
                                    <mat-icon style="color: lightgreen"
                                        >check</mat-icon
                                    >
                                </span>
                            </div>
                        </div>
                        <div
                            *ngIf="customerDetail?.customer_type"
                            fxFlex="1 1 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout="row"
                            fxLayoutGap="8px"
                        >
                            <div fxFlex="1 1 0px">
                                {{ "Customer Type" | translate }}
                            </div>
                            <div
                                fxFlex="1 1 0px"
                                *ngIf="customerDetail?.customer_type"
                            >
                                <span
                                    *ngIf="customerDetail?.customer_type == 1"
                                    >{{ "Guest" | translate }}</span
                                >
                                <span
                                    *ngIf="customerDetail?.customer_type == 2"
                                    >{{ "Registered User" | translate }}</span
                                >
                            </div>
                        </div>
                        <div
                            *ngIf="customerDetail?.paci"
                            fxFlex="1 1 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout="row"
                            fxLayoutGap="8px"
                        >
                            <div fxFlex="1 1 0px">
                                {{ "PACCI" | translate }}
                            </div>
                            <div fxFlex="1 1 0px">
                                {{ customerDetail?.paci }}
                            </div>
                        </div>
                        <div
                            *ngIf="customerDetail?.created"
                            fxFlex="1 1 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout="row"
                            fxLayoutGap="8px"
                        >
                            <div fxFlex="1 1 0px">
                                {{ "Created date" | translate }}
                            </div>
                            <div fxFlex="1 1 0px">
                                {{
                                    customerDetail?.created
                                        | date : "dd/MM/yyyy hh mm a"
                                }}
                            </div>
                        </div>
                        <div
                            *ngIf="customerDetail?.updated"
                            fxFlex="1 1 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout="row"
                            fxLayoutGap="8px"
                        >
                            <div fxFlex="1 1 0px">
                                {{ "Updated date" | translate }}
                            </div>
                            <div fxFlex="1 1 0px">
                                {{
                                    customerDetail?.updated
                                        | date : "dd/MM/yyyy hh mm a"
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                fxFlex="0 0 auto"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                class="p-16 pt-0"
                *ngIf="isDynamicEmpty()"
            >
                <div
                    class="fuse-card auto-width p-16"
                    style="height: fit-content"
                    fxFlex.gt-sm="1 1 0px"
                    fxFlex.lt-md="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="8px"
                >
                    <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="space-between stretch"
                        fxLayout="row"
                        fxLayoutGap="10px"
                    >
                        <label class="highlight-text-color">{{
                            "More Information" | translate
                        }}</label>
                    </div>
                    <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="1em"
                    >
                        <div
                            *ngFor="
                                let item of customerDetail?.dynamic_fields
                                    | keyvalue
                            "
                            fxFlex="1 1 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout="row"
                            fxLayoutGap="8px"
                        >
                            <div
                                fxFlex="1 1 0px"
                                style="text-transform: capitalize"
                            >
                                {{ getDynamicFieldName(item?.key) }}
                            </div>
                            <div fxFlex="1 1 0px">
                                <div *ngIf="item?.value">
                                    <div>
                                        {{
                                            getDynamicValues(
                                                item?.key,
                                                item?.value
                                            )
                                        }}
                                    </div>
                                </div>
                                <div style="color: #ccc" *ngIf="!item?.value">
                                    {{ "No value" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                fxFlex="0 0 auto"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                class="p-16 pt-0"
            >
                <div
                    fxFlex="0 0 auto"
                    class="fuse-card auto-width p-16"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="8px"
                    id="orders"
                >
                    <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="space-between stretch"
                        fxLayout="row"
                        fxLayoutGap="10px"
                    >
                        <label class="highlight-text-color">
                            <span
                                *ngIf="
                                    coreService.checkPermission(
                                        utilitiesService.Permission.OrderList
                                    )
                                "
                                >{{ "Orders, " | translate }}</span
                            >
                            <span
                                *ngIf="
                                    coreService.checkPermission(
                                        utilitiesService.Permission.WishlistList
                                    )
                                "
                                >{{ "Wishlist, " | translate }}</span
                            >
                            <span>{{ "Addresses " | translate }}</span>
                            <span
                                *ngIf="
                                    coreService.checkPermission(
                                        utilitiesService.Permission.MembersList
                                    ) &&
                                    coreService.checkFeatures(
                                        featuresConstants.Membership
                                    )
                                "
                                >{{ "& Transaction" | translate }}</span
                            >
                        </label>
                    </div>
                    <mat-tab-group
                        mat-align-tabs="end"
                        (selectedTabChange)="tabChange($event)"
                        [selectedIndex]="selectedIndex"
                    >
                        <mat-tab
                            *ngIf="
                                coreService.checkPermission(
                                    utilitiesService.Permission.OrderList
                                )
                            "
                        >
                            <ng-template mat-tab-label>
                                <span class="badge">{{ orderCount }}</span>
                                {{ "Orders" | translate }}
                            </ng-template>
                            <app-customer-orders-table
                                [customerID]="id"
                                (orderCountHandler)="setOrderCount($event)"
                                (viewOrderHandler)="viewOrder($event)"
                            ></app-customer-orders-table>
                        </mat-tab>

                        <mat-tab
                            *ngIf="
                                coreService.checkPermission(
                                    utilitiesService.Permission.WishlistList
                                )
                            "
                        >
                            <ng-template mat-tab-label>
                                <span class="badge">{{ wishlistCount }}</span>
                                {{ "Wishlist" | translate }}
                            </ng-template>
                            <app-customer-wishlist-table
                                [customerID]="id"
                                (wishlistCountHandler)="
                                    setWishlistCount($event)
                                "
                            ></app-customer-wishlist-table>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <span class="badge">{{ addressCount }}</span>
                                {{ "Addresses" | translate }}
                            </ng-template>
                            <app-customer-address-table
                                [customerID]="id"
                                (addressCountHandler)="setAddressCount($event)"
                            ></app-customer-address-table>
                        </mat-tab>
                        <mat-tab
                            *ngIf="
                                coreService.checkPermission(
                                    utilitiesService.Permission.MembersList
                                ) &&
                                coreService.checkFeatures(
                                    featuresConstants.Membership
                                )
                            "
                        >
                            <ng-template mat-tab-label>
                                <span class="badge">{{ membershipCount }}</span>
                                {{ "Transaction" | translate }}
                            </ng-template>
                            <app-wallet-detail [customerDetailID]="id">
                            </app-wallet-detail>
                            <!-- <app-customer-membership-table (countHandler)="setMemberCount($event)" [customerID]="id">
                            </app-customer-membership-table> -->
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="isView && !isEdit"
        fxFlex="0 0 auto"
        fxLayoutAlign="start stretch"
        fxLayout="column"
        fxLayoutGap="1em"
    >
        <app-view-cart
            [@animate]="{
                value: '*',
                params: { duration: '300ms', y: '50px' }
            }"
            [cart]="false"
            [id]="orderID"
        >
        </app-view-cart>
    </div>
    <div
        *ngIf="!isView && isEdit"
        fxFlex="0 0 auto"
        fxLayoutAlign="start stretch"
        fxLayout="column"
        fxLayoutGap="1em"
    >
        <app-edit-customer
            (afterEdit)="afterEdit()"
            [@animate]="{
                value: '*',
                params: { duration: '300ms', y: '50px' }
            }"
            [dynamicFieldList]="dynamicFieldList"
            [customerDetail]="customerDetail"
        ></app-edit-customer>
    </div>
</div>
