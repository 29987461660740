<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
    <ngx-datatable id="address-table" [loadingIndicator]="isLoading" [externalPaging]="true"
        [count]="page.totalElements" [limit]="page.size" [offset]="page.pageNumber" [virtualization]="false"
        [scrollbarH]="true" [rows]="customerAddresses" [cssClasses]="iconsCss" class="material striped mt-8"
        [columnMode]="'force'" [headerHeight]="'auto'" [rowHeight]="50" [footerHeight]="'auto'">
        <ngx-datatable-column name="{{'Name' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.first_name}} {{row?.middle_name}} {{row?.last_name}}</p>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'Address' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.address}}</p>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'Apartment No' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.apartment_no}}</p>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'City' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.city}}</p>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'Area' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.area?.name}}</p>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'Block' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.block}}</p>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'Street' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.street}}</p>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'Avenue' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.avenue}}</p>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'Building No' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.building_no}}</p>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'House No' | translate}}" [width]="getWidth()">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.house_no}}</p>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>