import { Component, Input, OnInit } from "@angular/core";
import { TranslationService } from "app/services/translationService";

@Component({
    selector: "app-config-notes",
    templateUrl: "./config-notes.component.html",
    styleUrls: ["./config-notes.component.scss"],
})
export class ConfigNotesComponent implements OnInit {
    @Input() notes = [];
    isShow: boolean = true;
    constructor(public transaltionService: TranslationService) {}

    ngOnInit(): void {}

    hideBody() {
        this.isShow = false;
    }
    showBody() {
        this.isShow = true;
    }
}
