<div
    fxFlex="1 1 auto"
    fxLayoutAlign="start stretch"
    fxLayout="row"
    fxLayoutGap="1em"
    id="main-page"
>
    <div
        fxFlex="1 1 auto"
        fxLayoutAlign="start stretch"
        fxLayout="column"
        fxLayoutGap="1em"
    >
        <div
            fxFlex="0 0 auto"
            fxLayoutAlign="space-between stretch"
            fxLayout="row"
            fxLayoutGap="5px"
        >
            <div
                fxLayout="row"
                fxFlex="0 0 auto"
                fxLayoutAlign="space-between start"
            >
                <label class="highlight-text-color">{{
                    "List of product items"
                }}</label>
                <!-- <app-fab-button
                    (handleFabButtonClick)="addProductItem()"
                ></app-fab-button> -->
            </div>
            <!-- <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="center center"
                    fxLayout="row"
                    fxLayoutGap="10px"
                >
                    <button
                        (click)="save()"
                        type="submit"
                        mat-flat-button
                        style="position: absolute; right: 16px"
                        class="primary-bg-color"
                    >
                        {{ "Save" | translate }}
                    </button> -->
            <!-- </div> -->
        </div>
        <!-- last -->
        <div
            class="fuse-card auto-width fuse-box-shadow-none"
            fxFlex="1 1 auto"
            fxLayoutAlign="start stretch"
            fxLayout="row"
            fxLayoutGap="1em"
            id="main-page"
            cdkDropListGroup
        >
            <div
                fxFlex="1 1 auto"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="1em"
            >
                <!-- form array -->
                <form
                    [formGroup]="form"
                    fxFlex="1 1 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="1em"
                >
                    <div
                        fxLayout="column"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                    >
                        <!-- formGroup -->
                        <div
                            [cdkDragData]="customProductFormGroup"
                            class="mb-16"
                            formArrayName="productForm"
                            *ngFor="
                                let customProductFormGroup of form.get(
                                    'productForm'
                                )['controls'];
                                let i = index
                            "
                            [formGroup]="customProductFormGroup"
                        >
                            <div
                                fxFlex="1 1 auto"
                                fxLayout="column"
                                fxLayoutAlign="start stretch"
                                fxLayoutGap="5px"
                            >
                                <div
                                    fxFlex="0 0 auto"
                                    fxLayout="column"
                                    fxLayoutAlign="start stretch"
                                    fxLayoutGap="5px"
                                >
                                    <!-- start   -->

                                    <div
                                        fxFlex="0 0 auto"
                                        fxLayout="column"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <!-- start -->
                                        <div
                                            fxFlex="0 0 auto"
                                            fxLayoutAlign="start stretch"
                                            fxLayout.gt-sm="row"
                                            fxLayout.lt-md="column"
                                            fxLayoutGap="1em"
                                            fxLayoutGap.gt-sm="5px"
                                            fxLayoutGap.lt-md="0px"
                                        >
                                            <!-- name-->
                                            <div
                                                class="default-time-example"
                                                fxFlex="1 1 auto"
                                                fxLayout="column"
                                            >
                                                <app-ng-select
                                                    fxFlex.gt-sm="50%"
                                                    fxFlex.lt-md="100%"
                                                    [showLabel]="false"
                                                    [title]="'Name'"
                                                    [api]="'api/manage/product'"
                                                    [control]="
                                                        customProductFormGroup.get(
                                                            'product_id'
                                                        )
                                                    "
                                                >
                                                </app-ng-select>

                                                <!-- <mat-form-field
                                                    *ngIf="
                                                        customProductFormGroup.get(
                                                            'product_id'
                                                        ).value != null
                                                    "
                                                    appearance="outline"
                                                    fxFlex="100"
                                                >
                                                    <mat-label
                                                        class="text-capitalized"
                                                    >
                                                        {{ "Name" | translate }}
                                                    </mat-label>
                                                    <input
                                                        [formControl]="
                                                            customProductFormGroup.get(
                                                                'name'
                                                            )
                                                        "
                                                        matInput
                                                        name="name"
                                                    />
                                                </mat-form-field> -->
                                            </div>
                                            <!-- "quantity" -->
                                            <div
                                                fxFlex.gt-sm="50%"
                                                fxFlex.lt-md="100%"
                                                class="default-time-example"
                                                fxFlex="1 1 auto"
                                                fxLayout="column"
                                            >
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                >
                                                    <mat-label
                                                        class="text-capitalized"
                                                    >
                                                        {{
                                                            "Quantity"
                                                                | translate
                                                        }}
                                                    </mat-label>
                                                    <input
                                                        type="text"
                                                        [formControl]="
                                                            customProductFormGroup.get(
                                                                'quantity'
                                                            )
                                                        "
                                                        matInput
                                                        name="quantity"
                                                    />
                                                </mat-form-field>
                                            </div>
                                            <!-- base_unit_price -->
                                            <!-- <div
                                                class="default-time-example"
                                                fxFlex="1 1 auto"
                                                fxLayout="column"
                                            >
                                                <mat-form-field
                                                    appearance="outline"
                                                    fxFlex="100"
                                                >
                                                    <mat-label
                                                        class="text-capitalized"
                                                    >
                                                        {{
                                                            "Unit Price"
                                                                | translate
                                                        }}
                                                    </mat-label>
                                                    <input
                                                        type="number"
                                                        [formControl]="
                                                            customProductFormGroup.get(
                                                                'base_unit_price'
                                                            )
                                                        "
                                                        matInput
                                                        name="base_unit_price"
                                                    />
                                                </mat-form-field>
                                            </div> -->
                                            <div>
                                                <button
                                                    [disabled]="
                                                        customProductFormGroup.get(
                                                            'product_id'
                                                        ).value != null
                                                    "
                                                    mat-button
                                                    color="warn"
                                                    (click)="
                                                        deleteProductItem(
                                                            i,
                                                            customProductFormGroup.get(
                                                                'product_id'
                                                            ).value
                                                        )
                                                    "
                                                >
                                                    <mat-icon
                                                        >delete_outline</mat-icon
                                                    >
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end  -->
                                </div>
                            </div>
                        </div>
                        <div
                            fxFlex="1 1 auto"
                            fxLayoutAlign="flex-end flex-end"
                            fxLayout="row"
                        >
                            <button
                                (click)="addProductItem()"
                                type="submit"
                                mat-flat-button
                                class="primary-bg-color"
                            >
                                <mat-icon class="save-cancel-btn" matPrefix
                                    >add</mat-icon
                                >{{ "add product" | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <!-- last  -->
        </div>
        <div
            fxFlex="1 1 auto"
            fxLayoutAlign="flex-end stretch"
            fxLayout="row"
            fxLayoutGap="1em"
        >
            <button [mat-dialog-close]="false" mat-stroked-button color="warn">
                <mat-icon class="save-cancel-btn" matPrefix>close</mat-icon>
                {{ "Close" | translate }}
            </button>
            <button
                (click)="save()"
                type="submit"
                mat-flat-button
                class="primary-bg-color"
            >
                <mat-icon class="save-cancel-btn mr-8" matPrefix>send</mat-icon
                >{{ "Update" | translate }}
            </button>
        </div>
    </div>
</div>
