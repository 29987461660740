import {
    ChangeDetectorRef,
    Component,
    HostListener,
    Inject,
    OnInit,
    ViewChild,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MediaService } from "app/services/media.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { NgxGalleryOptions } from "@kolkov/ngx-gallery";
import { NgxGalleryImage } from "@kolkov/ngx-gallery";
import { NgxGalleryAnimation } from "@kolkov/ngx-gallery";
import { CoreService } from "app/services/core-service";
import { Clipboard } from "@angular/cdk/clipboard";
import { TranslateService } from "@ngx-translate/core";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
export enum KEY_CODE {
    RIGHT_ARROW = 39,
    LEFT_ARROW = 37,
}
@Component({
    selector: "app-gallery-slides",
    templateUrl: "./gallery-slides.component.html",
    styleUrls: ["./gallery-slides.component.scss"],
})
export class GallerySlidesComponent implements OnInit {
    @ViewChild("gallery") gallery: any;
    id: any;
    mediaList = [];
    selectedImageIndex = null;
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];
    selectedImageObject = {
        id: null,
        file: null,
    };
    @HostListener("window:keyup", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
            this.gallery.showNext();
        }

        if (event.keyCode === KEY_CODE.LEFT_ARROW) {
            this.gallery.showPrev();
        }
    }
    constructor(
        public dialogRef: MatDialogRef<GallerySlidesComponent>,
        private formBuilder: FormBuilder,
        private mediaService: MediaService,
        public utilitiesService: UtilitiesService,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        public coreService: CoreService,
        private clipboard: Clipboard,
        private translate: TranslateService,
        private dialog: MatDialog,

        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.galleryImages = [];
        this.id = data.id;
        this.selectedImageIndex = data.index || null;
        this.getMedia();
    }

    copyUrl() {
        if (this.selectedImageObject.file) {
            this.clipboard.copy(this.selectedImageObject.file);
            let copyText = this.translate.instant("Copied to clipboard!");
            this.utilitiesService.showSuccessToast(copyText);
        } else {
            console.log("file is not found");
        }
    }

    ngOnInit(): void {
        this.galleryOptions = [
            {
                preview: false,
                width: "100px",
                height: "100px",
                thumbnailsColumns: 10,
                arrowPrevIcon: "fa fa-chevron-left",
                arrowNextIcon: "fa fa-chevron-right",
                imageAnimation: NgxGalleryAnimation.Slide,
            },
            // max-width 800
            {
                thumbnailsColumns: 2,
                breakpoint: 800,
                width: "100%",
                height: "100px",
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20,
                preview: false,
            },
            // max-width 400
            {
                thumbnailsColumns: 2,
                width: "100%",
                height: "100px",
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20,
                breakpoint: 400,
                preview: false,
            },
        ];
    }
    changeImage(event) {
        this.selectedImageIndex = event.index;
        let imageObject = this.mediaList[event.index];
        this.selectedImageObject = {
            id: imageObject.id,
            file: imageObject.file,
        };
    }
    deleteMedia() {
        let content = `Are you sure, Do you want to delete this media ? `;
        let heading = "Delete";
        let fromApp = false;
        let size = this.utilitiesService.isMobileAlertModal();
        const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });
        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                this.utilitiesService.startLoader();
                let deleteMsg = this.translate.instant(
                    `Media deleted successfully`
                );
                this.mediaService
                    .deleteMedia(this.selectedImageObject.id)
                    .subscribe((data) => {
                        this.utilitiesService.showSuccessToast(deleteMsg);
                        this.getMedia();
                    });
            }
        });
    }
    async getMedia() {
        try {
            this.galleryImages = [];
            this.utilitiesService.startLoader();
            const mediaList = await this.mediaService
                .getMediaList(999999, 0)
                .toPromise();
            if (mediaList) {
                this.mediaList = mediaList.results;
                this.mediaList.forEach((data) => {
                    this.galleryImages.push({
                        big: data.file,
                        small: data.file,
                        medium: data.file,
                    });
                });
                if (!this.selectedImageIndex) {
                    let index = this.mediaList.findIndex(
                        (data) => data.id == this.id
                    );
                    if (index != -1) {
                        this.selectedImageIndex = index;
                    }
                }
                this.galleryImages = [...this.galleryImages];
                this.gallery.show(this.selectedImageIndex);
                this.cdr.markForCheck();
                this.utilitiesService.stopLoader();
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
}
