<div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
    <div fxFlex="1 1 auto" fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="1em">
        <strong class="highlight-text-color">{{'Change Password ' | translate}} <span
                *ngIf="username">{{'for' | translate}}&nbsp;</span><span style="font-style: italic;"
                *ngIf="username">{{username}}</span></strong>
        <mat-icon [mat-dialog-close]="false" class="cursor-pointer">close</mat-icon>
    </div>
    <form fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" [formGroup]="form">
        <div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label class="text-capitalized">
                        {{'New Password'| translate}}
                    </mat-label>
                    <input type="password" matInput name="title_en" formControlName="password" #OldpasswordField>
                    <button mat-icon-button type="button"
                        (click)="OldpasswordField.type === 'password' ? OldpasswordField.type = 'text' : OldpasswordField.type = 'password'"
                        matSuffix>
                        <i class="fa fa-eye" aria-hidden="true" *ngIf="OldpasswordField.type === 'password'"></i>
                        <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="OldpasswordField.type === 'text'"></i>
                    </button>
                    <mat-error *ngIf="form.get('password').hasError('required')">
                        {{'New Password is required' | translate}}
                    </mat-error>
                    <mat-error *ngIf="form.get('password').hasError('minlength')">
                        {{'Password contain atleast 8 characters' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label class="text-capitalized">
                        {{'Confirm Password'| translate}}
                    </mat-label>
                    <input type="password" matInput name="title_en" formControlName="confirm_password"
                        #ConfirmpasswordField>
                    <button mat-icon-button type="button"
                        (click)="ConfirmpasswordField.type === 'password' ? ConfirmpasswordField.type = 'text' : ConfirmpasswordField.type = 'password'"
                        matSuffix>
                        <i class="fa fa-eye" aria-hidden="true" *ngIf="ConfirmpasswordField.type === 'password'"></i>
                        <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="ConfirmpasswordField.type === 'text'"></i>
                    </button>
                    <mat-error *ngIf="form.get('confirm_password').hasError('required')">
                        {{'Confirm Password is required' | translate}}
                    </mat-error>
                    <mat-error *ngIf="form.get('confirm_password').hasError('mustMatch')">
                        {{'Password and Confirm Password must be same' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxFlex="1 1 auto" fxLayoutAlign="flex-end stretch" fxLayout="row" fxLayoutGap="1em">
            <button [mat-dialog-close]="false" mat-stroked-button color="warn">
                <mat-icon class="save-cancel-btn" matPrefix>close</mat-icon> {{'Cancel' | translate}}
            </button>
            <button [disabled]="!form.valid" (click)="save()" type="submit" mat-flat-button class="primary-bg-color">
                <mat-icon class="save-cancel-btn mr-8" matPrefix>save</mat-icon>{{'Save' | translate}}
            </button>
        </div>
    </form>
</div>