<div
    id="dashboard-project"
    class="page-layout simple right-sidebar"
    fxLayout="column"
>
    <ng-container *ngIf="!displayField; else displayFieldTemplate">
        <div
            fxFlex="0 0 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="1em"
            fusePerfectScrollbar
        >
            <form
                [formGroup]="form"
                fxFlex="1 1 auto"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="1em"
                fusePerfectScrollbar
                class="p-16"
            >
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="1em"
                >
                    <div
                        fxFlex="1 1 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout.gt-sm="row"
                        fxLayout.lt-md="column"
                        fxLayoutGap="1em"
                    >
                        <div
                            fxFlex="1 1 auto"
                            class="fuse-card auto-width p-16"
                            fxLayoutAlign="start stretch"
                            fxLayout="column"
                            fxLayoutGap="0px"
                        >
                            <div
                                class="mt-20"
                                fxFlex="1 1 auto"
                                fxLayoutAlign="start stretch"
                                fxLayout="row"
                                fxLayoutGap="10px"
                            >
                                <mat-form-field
                                    appearance="outline"
                                    fxFlex="100"
                                >
                                    <mat-label class="text-capitalized">
                                        {{ "Type" | translate }}
                                    </mat-label>
                                    <mat-select
                                        [disabled]="
                                            ImportTypeFile != null ||
                                            ImportTypeFile != undefined
                                        "
                                        (selectionChange)="selectedType()"
                                        formControlName="model_name"
                                    >
                                        <mat-option
                                            [value]="type?.name"
                                            *ngFor="let type of importTypes"
                                        >
                                            {{ type.name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="
                                            form
                                                .get('model_name')
                                                .hasError('required')
                                        "
                                    >
                                        {{ "Type is required" | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div
                                fxFlex="1 1 75%"
                                fxLayoutAlign="start stretch"
                                fxLayout="column"
                                fxLayoutGap="1em"
                            >
                                <div
                                    class="fuse-card auto-width p-16 max-height"
                                    fxFlex="0 0 auto"
                                    fxLayoutAlign="start stretch"
                                    fxLayout="column"
                                    fxLayoutGap="1em"
                                >
                                    <div
                                        fxLayout="row"
                                        fxFlex="0 0 auto"
                                        fxLayoutAlign="space-between center"
                                        fxLayoutGap="5px"
                                    >
                                        <label class="highlight-text-color">{{
                                            "File" | translate
                                        }}</label>
                                        <mat-icon
                                            (click)="deleteFile('file')"
                                            *ngIf="form.get('file').value"
                                            class="action-btn-delete cursor-pointer"
                                            >close
                                        </mat-icon>
                                    </div>
                                    <div
                                        [@simpleFadeAnimation]="'in'"
                                        fxLayout="column"
                                        fxLayoutGap="8px"
                                        fxFlex="0 0 auto"
                                        fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px"
                                    >
                                        <app-cms-upload-media
                                            saveLocally="true"
                                            [index]="1"
                                            [accept]="mediaTypesAccept.Excel"
                                            [uploadOnly]="true"
                                            (afterUpload)="
                                                setFileToForm($event, 'file')
                                            "
                                            [isMultiSelect]="false"
                                            [formcontrol]="form.controls.file"
                                            [index]="0"
                                        >
                                        </app-cms-upload-media>
                                        <mat-error
                                            *ngIf="
                                                form
                                                    .get('file')
                                                    .hasError('required')
                                            "
                                        >
                                            {{ "file is required" | translate }}
                                        </mat-error>
                                    </div>
                                    <div
                                        class="files-list cursor-pointer"
                                        fxFlex="0 0 auto"
                                        fxLayoutAlign="center center"
                                        fxLayoutGap="5px"
                                        fxLayout="column"
                                        *ngIf="form.get('file').value"
                                    >
                                        <app-show-all-type-media
                                            [@simpleFadeAnimation]="'in'"
                                            [url]="form.get('file').value"
                                        >
                                        </app-show-all-type-media>
                                    </div>
                                </div>
                            </div>
                            <!-- sync config -->
                            <div
                                class="mt-20"
                                fxFlex="1 1 auto"
                                fxLayoutAlign="start stretch"
                                fxLayout="row"
                                fxLayoutGap="10px"
                            >
                                <mat-form-field
                                    appearance="outline"
                                    fxFlex="100"
                                >
                                    <mat-label class="text-capitalized">
                                        {{ "Configuration" | translate }}
                                    </mat-label>
                                    <mat-select formControlName="sync_config">
                                        <mat-option
                                            [value]="syncConfigItem?.id"
                                            *ngFor="
                                                let syncConfigItem of syncConfigList
                                            "
                                        >
                                            {{ syncConfigItem.name }}
                                        </mat-option>
                                    </mat-select>
                                    <!-- <mat-error
                                        *ngIf="
                                            form
                                                .get('sync_config')
                                                .hasError('required')
                                        "
                                    >
                                        {{
                                            "Configuration is required"
                                                | translate
                                        }}
                                    </mat-error> -->
                                </mat-form-field>
                            </div>
                            <div
                                fxFlex="0 0 auto"
                                fxLayoutAlign="flex-end"
                                fxLayout="row"
                                fxLayoutGap="1em"
                            >
                                <button
                                    class="btn-border"
                                    (click)="goBack()"
                                    type="submit"
                                    mat-flat-button
                                >
                                    {{ "Cancel" | translate }}
                                </button>
                                <button
                                    (click)="save()"
                                    type="submit"
                                    mat-flat-button
                                    class="primary-bg-color"
                                >
                                    {{ "Save" | translate }}
                                </button>
                            </div>
                            <!-- <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                            fxLayout.gt-sm="row" fxLayoutGap="1em">
                            <mat-checkbox formControlName="is_email" class="checkbox-info">
                                {{'Share my crediental via email' | translate}}</mat-checkbox>
                        </div> -->
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
    <ng-template #displayFieldTemplate>
        <div
            fxFlex="0 0 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="1em"
        >
            <app-import-details
                [@animate]="{
                    value: '*',
                    params: { duration: '300ms', y: '50px' }
                }"
                [dryResponse]="dryResponse"
                (cancelClick)="handleCancel()"
                [elements]="pageElements?.results"
                (afterImportFile)="afterImportFile()"
            >
            </app-import-details>
        </div>
    </ng-template>
</div>
