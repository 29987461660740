import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { AlertModalComponent } from "app/main/components/alert-modal/alert-modal.component";
import { IconCss } from "app/main/ngxDatatable-Icons/icons";
import { AppRoutes } from "app/routes";
import { CoreService } from "app/services/core-service";
import { TranslationEditorService } from "app/services/extras/translationEditor.service";
import { RedirectionsService } from "app/services/redirections.service";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { CreatePageRedirectionComponent } from "../../create-page-redirection/create-page-redirection.component";

@Component({
    selector: "app-page-redirections",
    templateUrl: "./page-redirections.component.html",
    styleUrls: ["./page-redirections.component.scss"],
    animations: fuseAnimations
})
export class PageRedirectionsComponent implements OnInit {
    icons = IconCss;
    edit = [];
    label = {
        title_en: "Translation Editor",
        title_ar: "محرر الترجمة",
    };
    pageRedirectionList = [];
    iconsCss = {
        pagerLeftArrow: this.icons.left,
        pagerRightArrow: this.icons.right,
        pagerPrevious: this.icons.previous,
        pagerNext: this.icons.next,
    };
    page = {
        size: 0,
        totalElements: 0,
        pageNumber: 0,
    };
    offset = 0;
    searchKey = "";
    changedEnValue = "";
    changedArValeu = "";
    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        // this.dataSource.filter = filterValue;
    }

    constructor(
        private translationEditorService: TranslationEditorService,
        public utilitiesService: UtilitiesService,
        private dialog: MatDialog,
        public translationService: TranslationService,
        public coreService: CoreService,
        private router: Router,
        private redirectionService: RedirectionsService
    ) {
        this.page.pageNumber = 0;
        this.page.size = 10;
        this.page.totalElements = 0;
    }

    async ngOnInit(): Promise<void> {
        this.label.title_en = "Translation Editor";
        this.label.title_ar = "محرر الترجمة";
        await this.getPageRedirections();
    }

    back() {
        this.router.navigate([AppRoutes.Apps]);
    }

    getLanguage() {
        return this.translationService.currentLang;
    }
    async getPageRedirections(limit = this.page.size, offset = this.offset) {
        try {
            this.utilitiesService.startLoader();
            const pageRedirectionList = await this.redirectionService
                .getPageRedirection(limit, offset, this.searchKey)
                .toPromise();
            if (pageRedirectionList) {
                this.pageRedirectionList = pageRedirectionList.results;
                this.page.totalElements = pageRedirectionList.count;
                this.pageRedirectionList.forEach((data, index) => {
                    this.edit[index] = false;
                });
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
            this.utilitiesService.stopLoader();
        }
    }
    pageChange(event) {
        this.page.pageNumber = event.offset;
        this.offset = this.page.pageNumber * this.page.size;
        document.getElementById("dashboard-project").scrollIntoView();
        this.getPageRedirections();
    }
    search(searchKey) {
        this.offset = 0;
        this.page.pageNumber = 0;
        this.searchKey = searchKey;
        this.getPageRedirections();
    }
    editFn(index) {
        this.edit[index] = true;
        setTimeout(() => {
            document.getElementById(`ar${index}`).focus();
        }, 10);
        setTimeout(() => {
            document.getElementById(`en${index}`).focus();
        }, 10);
    }
    cancelChanges(index) {
        console.log('cancel')
        this.getPageRedirections();
    }
    updateValues(event, index, cell) {
        this.pageRedirectionList[index][cell] = event.target.value;
        this.pageRedirectionList = [...this.pageRedirectionList];
        console.log("UPDATED!", this.pageRedirectionList[index][cell]);
    }

    saveValues(index) {
        const id = this.pageRedirectionList[index].id;
        let form = {
            old_path: this.pageRedirectionList[index].old_path,
            new_path: this.pageRedirectionList[index].new_path,
        };
        try {
            this.utilitiesService.startLoader();
            this.redirectionService
                .updatePageRedirection(form, id)
                .subscribe((data) => {
                    this.getPageRedirections();
                });
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
    limitChange() {
        this.getPageRedirections();
    }

    delete(index) {
        const id = this.pageRedirectionList[index].id;
        let content = `Do you want to delete ?`;
        let heading = "Confirm";
        let fromApp = false;
        let size = this.utilitiesService.isMobileAlertModal();
        const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });
        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                try {
                    this.utilitiesService.startLoader();
                    this.redirectionService
                        .deletePageRedirection(id)
                        .subscribe((data) => {
                            this.getPageRedirections();
                        });
                } catch {
                    this.utilitiesService.stopLoader();
                } finally {
                }
            }
        });
    }

    createNewRedirection() {
        let isMobile = this.utilitiesService.isMobile();

        const dialogRef = this.dialog.open(CreatePageRedirectionComponent, {
            maxWidth: "",
            width: "50vw",
            height: "auto",
        });
        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                let deleteMsg =`Redirection added successfully`
                this.getPageRedirections();
                this.utilitiesService.showSuccessToast(deleteMsg);
            }
        });
    }
}
