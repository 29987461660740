<ng-container *ngIf="!selectedBlock">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row wrap">
        <ng-container *ngFor="let app of appItemsArray?.appItems">
            <div *ngIf="!app.hidden" class="card-block p-16" fxFlex.sm="0 1 calc(50%-10px)"
                fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(25%-10px)" (click)="selectBlock(app.id)">
                <div class="card-block-content" fxFlex="1 1 auto" fxLayoutAlign.lt-md="center stretch"
                    fxLayoutAlign="start stretch" fxLayout="column">
                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em">
                            <div class="icon-container">
                                <mat-icon class="app-icon">{{app.icon}}</mat-icon>
                            </div>
                        </div>
                        <div fxFlex="0 0 80%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                            <label
                                class="app-label highlight-text-color hover-color font-bold">{{this.translationService.getTranslatedName(app)}}</label>
                            <label class="description">{{app?.description}}</label>
                        </div>
                    </div>
                    <!-- <div fxFlex="1 1 auto" fxLayoutAlign.lt-md="start center" fxLayoutAlign="start stretch" fxLayout.lt-md="row" fxLayout.gt-sm="column" fxLayoutGap.lt-md="2em">
                    <div fxFlex.lt-md="0 0 auto" fxFlex.gt-sm="1 1 auto" fxLayoutAlign="center center" fxLayoutAlign.lt-md="start start" fxLayout="column">
                       
                    </div>
                    <div fxFlex.lt-md="0 0 auto" fxFlex.gt-sm="1 1 auto" fxLayoutAlign="center center" [ngClass.lt-md]="{'pt-13':true}" fxLayoutAlign.lt-md="start start" fxLayout="column">
                       
                    </div>
                </div> -->
                </div>
            </div>
        </ng-container>


    </div>
</ng-container>
<ng-container *ngIf="selectedBlock">
    <app-app-elements (childSave)="childSave()" [appDetail]='appDetail' (backHandler)="back()"
        [selectedBlock]="selectedBlock">
    </app-app-elements>
</ng-container>