import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { CustomerService } from "app/services/customer/customer.service";
import { CampaignsService } from "app/services/marketing/campaigns.service";
import { SharedService } from "app/services/sharedService";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";

@Component({
    selector: "app-create-condition",
    templateUrl: "./create-condition.component.html",
    styleUrls: ["./create-condition.component.scss"],
    animations: fuseAnimations,
})
export class CreateConditionComponent implements OnInit {
    @Input() id: any = "";
    @Input() audienceID: any;
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    @Output() createCondition: EventEmitter<void> = new EventEmitter<any>();

    form: FormGroup;
    filterList = [];
    conditionObject: any = "";
    operators = [
        { id: "equal", name: "Equal" },
        { id: "icontains", name: "Icontains" },
        { id: "in", name: "In" },
        { id: "gt", name: "Grater than" },
        { id: "gte", name: "Grater than equal" },
        { id: "lt", name: "Less than" },
        { id: "lte", name: "Less than equal" },
        { id: "startswith", name: "Start with" },
        { id: "range", name: "Range" },
        { id: "date", name: "Date" },
        { id: "year", name: "Year" },
        { id: "month", name: "Month" },
        { id: "day", name: "Day" },
        { id: "up_to", name: "Up to" },
    ];
    aggregations = [
        { id: "Avg", name: "Average" },
        { id: "Count", name: "Count" },
        { id: "Max", name: "Max" },
        { id: "Min", name: "Min" },
        { id: "Sum", name: "Sum" },
    ];
    expandIndex = [];
    submitted: boolean = false;
    type: any[];
    constructor(
        private customerService: CustomerService,
        private dialog: MatDialog,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        private fb: FormBuilder,
        private sharedService: SharedService,
        private campaignsService: CampaignsService
    ) {}

    async ngOnInit(): Promise<void> {
        if (this.id) {
            this.utilitiesService.startLoader();
        }
        await this.formInitialize();
        await this.getFilter();
        if (this.id) {
            this.getConditionDetail();
        }
    }
    async getConditionDetail(isClearAll = false) {
        if (isClearAll) {
            (this.form.controls["rules"] as FormArray).clear();
        }
        try {
            const conditionObject = await this.customerService
                .getConditionDetail(this.id)
                .toPromise();
            if (conditionObject) {
                this.conditionObject = conditionObject;
                this.form.controls.condition.setValue(
                    this.conditionObject.condition
                );
                this.form.controls.audience.setValue(
                    this.conditionObject.audience.id
                );
                this.form.get("rules")["controls"].splice(0, 1);
                if (this.conditionObject.rules.length > 0) {
                    this.conditionObject.rules.forEach(async (data, index) => {
                        (this.form.get("rules") as FormArray).push(
                            this.addRulesFormGroup(data)
                        );
                        await this.filterChanges(data.filter, index, true);
                        let value: any;
                        if (isNaN(parseInt(data.value))) {
                            value = data.value;
                        }
                        else {
                            if(Array.isArray(data.value)){
                                value = data.value
                            }else{
                                value = parseInt(data.value);
                            }
                        }
                        ((this.form.get("rules") as FormArray).at(
                            index
                        ) as FormGroup)
                            .get("value")
                            .patchValue(value);
                    });
                }
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
            this.utilitiesService.stopLoader();
        }
    }
    async formInitialize() {
        this.form = this.fb.group({
            condition: ["AND", [Validators.required]],
            audience: [null],
            rules: this.fb.array([this.addRulesFormGroup()]),
        });
    }
    async getFilter() {
        try {
            const filterList = await this.customerService
                .getConditionFilter()
                .toPromise();
            if (filterList) {
                this.filterList = filterList;
            }
        } catch {
        } finally {
        }
    }
    async filterChanges(id, index, isDetail = false) {
        let filter = this.filterList.filter((item) => item.id == id);
        if (filter.length > 0) {
            ((this.form.get("rules") as FormArray).at(index) as FormGroup)
                .get("type")
                .patchValue(filter[0].type);
            ((this.form.get("rules") as FormArray).at(index) as FormGroup)
                .get("multiple")
                .patchValue(filter[0].multiple);
        }
        let filterValues = [];
        if (!isDetail) {
            ((this.form.get("rules") as FormArray).at(index) as FormGroup)
                .get("value")
                .patchValue(null);
            ((this.form.get("rules") as FormArray).at(index) as FormGroup)
                .get("valueArray")
                .patchValue([]);
        }
        try {
            this.filterList.forEach(async (data) => {
                if (data.id == id) {
                    if (data.type != "api") {
                        if (data.possible_values.length > 0) {
                            data.possible_values.forEach((items) => {
                                for (let key in items) {
                                    filterValues.push({
                                        id: items[key],
                                        name: key,
                                    });
                                }
                            });
                        }
                        ((this.form.get("rules") as FormArray).at(
                            index
                        ) as FormGroup)
                            .get("valueArray")
                            .patchValue(filterValues);
                    } else {
                        let url = data.possible_values;
                        const filter = await this.customerService
                            .filterValueAPI(url)
                            .toPromise();
                        if (filter) {
                            filterValues = filter.results ? filter.results:filter;
                            ((this.form.get("rules") as FormArray).at(
                                index
                            ) as FormGroup)
                                .get("valueArray")
                                .patchValue(filterValues);
                        }
                    }
                }
            });
        } catch {
        } finally {
        }
    }
    addRulesFormGroup(data?) {
      if(data){
        const filterObject = this.filterList.find(ele=> ele.id===data.filter)
        if (filterObject.multiple) {
            if (data.value.includes(',')) {
                // Converting into array
                data.value = data.value.split(',').map(value => parseInt(value, 10));
              } else {
                // If there is no comma, create an array with the entire string as a single element
                data.value = [parseInt(data.value, 10)];
              }
          };
      }

        return this.fb.group({
            id: [data ? (data.id ? data.id : null) : null],
            value: [
                data ? (data.value ? data.value : null) : null,
                [Validators.required],
            ],
            operator: [data ? (data.operator ? data.operator : null) : null],
            filter: [
                data ? (data.filter ? data.filter : null) : null,
                [Validators.required],
            ],
            aggregation: [
                data ? (data.aggregation ? data.aggregation : null) : null,
            ],
            valueArray: [],
            type: [null],
            multiple:false
        });
    }

    handleCancel() {
        this.cancelClick.emit();
    }

    checkInvalid(index) {
        if (
            (this.form.get("rules") as FormArray).length > 0 &&
            this.submitted
        ) {
            return (this.form.get("rules") as FormArray).controls[index].valid;
        } else {
            return true;
        }
    }
    async save() {
        let finalForm: any;
        this.submitted = true;
        let invlidData = [];
        this.expandIndex = [];
        (this.form.get("rules") as FormArray).controls.forEach(
            (data, index) => {
                if (data.invalid) {
                    invlidData.push(index);
                }
            }
        );
        invlidData.forEach((data) => {
            setTimeout(() => {
                this.expandIndex[data] = data;
            }, 0);
        });
        if (this.form.valid) {
            finalForm = {
                condition: {
                    id: this.id ? this.id : null,
                    condition: this.form.controls.condition.value,
                    audience: this.audienceID,
                },
                rules: this.form.controls.rules.value.map(ele=>{
                    if(Array.isArray(ele.value)){
                        ele.value = ele.value.join(',')
                    }
                    return ele
                })
            };
            if (!this.id) {
                await this.saveCondition(finalForm);
            } else {
                await this.updateCondition(finalForm);
            }
        }
    }
    async updateCondition(finalForm) {
        try {
            this.utilitiesService.startLoader();
            this.customerService
                .updateCondition(finalForm, this.id)
                .subscribe((data) => {
                    let successMsg = this.translate.instant(
                        "Condition updated successfully"
                    );
                    this.utilitiesService.showSuccessToast(successMsg);
                    this.createCondition.emit();
                });
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
    async saveCondition(finalForm) {
        try {
            this.utilitiesService.startLoader();
            this.customerService.addConditions(finalForm).subscribe((data) => {
                let successMsg = this.translate.instant(
                    "Condition created successfully"
                );
                this.utilitiesService.showSuccessToast(successMsg);
                this.createCondition.emit();
            });
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
    async addCondition() {
        (this.form.get("rules") as FormArray).push(
            await this.addRulesFormGroup()
        );
    }

    delete(index) {
        let content = `Are you sure, Do you want to delete this rule ? `;
        let heading = "Delete";
        let fromApp = false;
        let size = this.utilitiesService.isMobileAlertModal();
        const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });
        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                if (!this.id) {
                    this.removeSlideLoacally(index);
                } else {
                    let id = (this.form.get("rules") as FormArray).controls[
                        index
                    ].value.id;
                    if (id) {
                        this.customerService
                            .deleteRule(id)
                            .subscribe((data) => {
                                this.utilitiesService.startLoader();
                                this.getConditionDetail(true);
                            });
                    } else {
                        this.removeSlideLoacally(index);
                    }
                }
                document.getElementById("toolbar").scrollIntoView();
            }
        });
    }
    removeSlideLoacally(index) {
        (this.form.get("rules") as FormArray).removeAt(index);
    }
}
