export const locale = {
    lang: "ar",
    data: {
        " Add these credentials in the Shopify Store Setup on Paydo":
            " أضف بيانات الاعتماد هذه في Shopify Store Setup على Paydo",
        " Choose Paydo Carrier from the list": "اختر Paydo Carrier من القائمة ",
        " Go to Setting from the side menu on shopify":
            "انتقل إلى الإعداد من القائمة الجانبية في Shopify",
        " Setup the Shipping zones and Products on shopify":
            " قم بإعداد مناطق الشحن والمنتجات في shopify ",
        " Shopify stores can be integrated with Paydo by using the private apps feature":
            " يمكن دمج متاجر Shopify مع Paydo باستخدام ميزة التطبيقات الخاصة",
        " The Paydo Shipping App requires permissions to the following scopes":
            " يتطلب تطبيق Paydo Shipping أذونات للنطاقات التالية",
        " The system will update the tracking status on delivery":
            " سيقوم النظام بتحديث حالة التتبع عند التسليم",
        " To Add Rates for the shipping zone": "لإضافة أسعار لمنطقة الشحن",
        " and click": " وانقر ",
        " button": " زر",
        " check Shipping rates option and save":
            " تحقق من خيار أسعار الشحن وحفظ",
        " go to Apps from the side menu on Shopify and click on the":
            " انتقل إلى التطبيقات من القائمة الجانبية في Shopify وانقر فوق",
        " select option to": "حدد الخيار",
        " the private app credentials will appear":
            " ستظهر بيانات اعتماد التطبيق الخاص",
        " “Manage Rates” ": "“إدارة الأسعار”",
        '"Uninstall"': "“الغاء التثبيت”",
        "& Memberships": "& العضويات",
        "* This email is your login name":
            "* هذا البريد الإلكتروني هو اسم تسجيل الدخول الخاص بك",
        "--Select Page--": "حدد الصفحة",
        "--Select Type--": "حدد النوع",
        AND: "و",
        "API Key": "مفتاح API",
        "API Key is required": "مفتاح API مطلوب",
        "API general error": "API خطأ عام",
        AR: "عربي",
        AWB: "AWB",
        "Abandoned Cart Delay": "مهجورة تأخير عربة التسوق",
        "Abandoned Cart Delay should be greater than 0":
            "يجب أن يكون تأخير سلة التسوق المهجورة أكبر من 0",
        "Abandoned Cart Delay should be less than 101":
            "يجب أن يكون تأخير عربة التسوق المهجورة أقل من 101",
        Account: "الحساب",
        "Account is required": "الحساب مطلوب",
        Action: "إجراء",
        Actions: "الإجراءات",
        "Top up": "تعبئة الرصيد ",
        "Deduct Wallet": "خصم رصيد المحفظة",
        "Top Up /Deduct Wallet": "زيادة / خصم رصيد المحفظة",
        Deduct: "خصم",
        Details: "التفاصيل",
        "Activate Date": "تفعيل التاريخ",
        Active: "نشط",
        Add: "يضيف",
        "Add Custom Template": "أضف قالب مخصص",
        "Add Customers": "أضف العملاء",
        "Add Media": "أضف الوسائط",
        "Add Possible Values": "أضف القيم الممكنة",
        "Add base currency , base country and multiple country rules":
            "أضف العملة الأساسية والبلد الأساسي وقواعد البلدان المتعددة",
        "Add support information": "أضف معلومات الدعم",
        "Add terms and conditions and its setup":
            "إضافة الشروط والأحكام وإعدادها",
        "Add the app details as follows": "أضف تفاصيل التطبيق على النحو التالي",
        "Add to cart caption (ar)":
            "إضافة إلى التسمية التوضيحية لسلة التسوق (باللغة العربية)",
        "Add to cart caption (en)":
            "إضافة التسمية التوضيحية لسلة التسوق (بالإنجليزية)",
        "Additional CSS": "CSS إضافية",
        "Additional Design Settings": "إعدادات تصميم إضافية",
        "Additional Information": "معلومات إضافية",
        Address: "العنوان",
        Addresses: "العناوين",
        "Addresses ": "  عناوين",
        "After saving the permissions": "بعد حفظ الأذونات",
        "Our Top Customers": "أفضل العملاء",

        "After successfully integrating Paydo and Shopify":
            "بعد دمج Paydo و Shopify بنجاح",
        All: "الجميع",
        "All Downloads": "كل التنزيلات",
        "All Visits": "جميع الزيارات",
        Amount: "كمية",
        "Include categories": "تضمين الفئات",
        "Exclude categories": "استبعاد الفئات",
        "Total Quantity Sold": "إجمالى الكمية المباعة",
        "Total Products Sold": "إجمالى المنتجات المباعة",
        "Total Revenue": "إجمالى الارباح",
        "No. Of Orders": "عدد الطلبات",
        "Total Sales": "إجمالى المبيعات",
        "Analytical settings updated successfully":
            "تم تحديث الإعدادات التحليلية بنجاح",
        "Posts Layout settings updated successfully":
            "تم تحديث اعدادات مظهر المقالات بنجاح",
        Animation: "الرسوم المتحركة",
        Subscribers: "المشتركون",
        unsubscribed: "غير مشترك",
        subscribed: "مشترك",
        "Apartment No": "رقم الشقه",
        "Api Key is required": "مطلوب مفتاح Api",
        "Api general Error": "خطأ عام في Api",
        App: "App",
        "App Configurations": "تكوينات التطبيق",
        "App Details are still loading Please wait..":
            "تفاصيل التطبيق لا تزال قيد التحميل الرجاء الانتظار ..",
        Arabic: "العربية",
        Archived: "مؤرشف",
        "Are you Sure, Do you want to upload this image":
            "هل أنت متأكد هل تريد تحميل هذه الصورة",
        "Are you sure, Do you want to save ?": "هل أنت متأكد ، هل تريد الحفظ؟",
        "Are you sure, Do you want to update ?":
            "هل أنت متأكد هل تريد التحديث؟",
        Area: "منطقة",
        "Ask Email": "اسأل البريد الالكتروني",
        Audience: "جمهور",
        "Audience Created Date": "تاريخ إنشاء الجمهور",
        "Audience Name": "اسم الجمهور",
        "Audience Name is required": "اسم الجمهور مطلوب",
        "Audience Updated Date": "تاريخ تحديث الجمهور",
        "Audience added successfully": "تمت إضافة الجمهور بنجاح",
        "Audience is required": "الجمهور مطلوب",
        "Audience updated successfully": "تم تحديث الجمهور بنجاح",
        "Auto Play": "تشغيل تلقائي",
        Avenue: "السبيل",
        "Avenue ": "السبيل",
        "Balance Amount": "إجمالى الرصيد",
        Balance: "الرصيد",
        "file is required": "الملف مطلوب",
        "Base Country": "بلد القاعدة",
        "Base Currency": "العملة الأساسية",
        "Base Currency is required": "العملة الأساسية مطلوبة",
        "Basic Details": "تفاصيل أساسية",
        "Basic Info": "معلومات أساسية",
        "Basic Information": "معلومات اساسية",
        Block: "منع",
        Body: "الجسم",
        "Body (Ar)": "الجسم (بالعربية)",
        "Body (En)": "الجسم (إن)",
        "Body En is required": "مطلوب وضع الجسم",
        Box: "علبة",
        Brand: "ماركة",
        "Brand Filter": "مرشح العلامة التجارية",
        "Brand Filter Layout": "تخطيط مرشح العلامة التجارية",
        "Brand Settings": "إعدادات الماركة",
        "Browse for file": "تصفح بحثًا عن ملف",
        "Browse from media": "تصفح من وسائل الإعلام",
        "Building No": "رقم المبنى",
        "Button Text (ar)": "نص الزر (عربي)",
        "Button Text (en)": "نص الزر (en)",
        CANCELLED: "ألغيت",
        "CDN URL": "عنوان  لـ CDN",
        "Orders By Country": "الطلبات وفقا للدولة",
        Campaign: "حملة",
        CAMPAIGN: "حملة",
        Wallet: "محفظة",
        "Campaign Created Date": "تاريخ إنشاء الحملة",
        "Campaign Code": "كود الحملة",
        "Campaign Information": "معلومات الحملة",
        "Campaign Name": "أسم الحملة",
        "Campaign Statistics": " بيانات الحملة",
        Statistics: "إحصائيات",
        "Campaign Updated Date": "تاريخ تحديث الحملة",
        "Campaign deleted successfully": "تم حذف الحملة بنجاح",
        "Can not find any parent": "لا يمكن العثور على أي والد",
        Cancel: "إلغاء",
        Cancelled: "ألغيت",
        "Cart Amount": "مبلغ عربة التسوق",
        "Cart Discount": "سلة الخصم",
        "Cart Excel Sheet -": "ورقة إكسل عربة التسوق -",
        "Cart Information": "معلومات عربة التسوق",
        "Cart and Wishlist Settings": "إعدادات سلة التسوق وقائمة الرغبات",
        Cashback: "استرداد النقود",
        "Catalog Settings": "إعدادات الكتالوج",
        "Catalog settings updated successfully":
            "تم تحديث إعدادات الكتالوج بنجاح",
        "Catalog settings deleted successfully":
            "تم مسح اعدادات الكتالوج بنجاح",
        "Product Catalog Uploaded": "تم رفع كتالوج المنتجات",
        "Categories Filter": "مرشح الفئات",
        Category: "فئة",
        Composition: "تشكيله",
        "Transaction No": "رقم التحويل",
        "Order Amount": "قيمة الطلب",
        "Category Filter Layout": "تخطيط مرشح الفئة",
        "Category Settings": "إعدادات الفئة",
        "Category created successfully": "تم إنشاء الفئة بنجاح",
        "Category updated successfully": "تم تحديث الفئة بنجاح",
        "Change Password": "تغيير كلمة السر",
        "Change Password ": "تغيير كلمة السر",
        "Changed By :": "تغيير من قبل ",
        Changes: "التغييرات",
        Platform: "منصة",
        "Enable Server Analytics": "تمكين تحليلات الخادم",
        "Enable Google Analytics": "تمكين تحليلات جوجل",
        "Enable Facebook Analytics": "تمكين تحليلات فيس بوك",
        "Enable Tiktok Analytics": "تمكين تحليلات تيك توك",
        "Enable SnapChat Analytics": "تمكين تحليلات سناب شات",
        "Pending Payment": "في انتظار الدفع",
        "AVG Order Amount": "متوسط الطلبات",
        Visits: "الزيارات",
        "Visits and Orders": "الزيارات و الطلبات",
        "Orders Revenue": "عائدات الطلبات",
        "Recent Campaign": "الحملات الحديثة",
        "Platform Visits": "زيارات المنصات",
        "Price Rule Usage": "استخدام قواعد التسعير",
        "Top selling products": "أكثر المنتجات مبيعا",
        "Most abandoned products": "المنتجات الأكثر هجرًا",
        "Trending products": "منتجات رائجة",
        "Most popular products": "المنتجات الأكثر شعبية",
        Sold: "المباع",
        "Total Visits": "إجمالى الزيارات",
        Abandoned: "المؤجل",
        "in last 24 Hours": "أخر 24 ساعة",
        "No data found for selected period.": "لا يوجد بيانات للفترة المحددة",
        Up: "أكثر",
        Down: "أقل",
        Channel: "قناة",
        Charges: "تكلفه",
        "Check Status": "تحقق من حالة",
        Checkbox: "خانة اختيار",
        "Checkout Settings": "إعدادات الخروج",
        "Checkout Type": "نوع الخروج",
        City: "مدينة",
        "Civil ID": "البطاقة المدنية",
        "Civil ID Note (Ar)": "مذكرة البطاقة المدنية (باللغة العربية)",
        "Civil ID Note (En)": "بطاقة الهوية المدنية (بالإنكليزية)",
        "Click here to get all Ionic Icons":
            "انقر هنا للحصول على جميع الرموز الأيونية",
        "Click the row to view details of the order":
            "انقر فوق الصف لعرض تفاصيل الطلب",
        "Click to copy": "انقر للنسخ",
        Close: "اغلاق",
        "Transaction Date": "تاريخ التحويل",
        Code: "رمز",
        "Code is required": "الرمز مطلوب",
        "Collection created successfully": "تم إنشاء المجموعة بنجاح",
        "Collection deleted successfully": "تم حذف المجموعة بنجاح",
        "Collection is required": "المجموعة مطلوبة",
        "Collection updated successfully": "تم تحديث المجموعة بنجاح",
        "Color Palette": "لوحة الألوان",
        Condition: "شرط",
        "Condition Type": "نوع الشرط",
        "Condition created successfully": "تم إنشاء الشرط بنجاح",
        "Condition deleted successfully": "تم حذف الشرط بنجاح",
        "Condition is required": "الشرط مطلوب",
        "Condition updated successfully": "تم تحديث الشرط بنجاح",
        Conditions: "شروط",
        "Config deleted successfully": "تم حذف التكوين بنجاح",
        "Configuration created successfully": "تم إنشاء التكوين بنجاح",
        Configuration: "الأعدادات ",
        "unknown fields in import file :": "حقول غير معروفة في ملف الاستيراد :",
        "known fields in import file :": "حقول  معروفة في ملف الاستيراد :",
        "Import Files": "استيراد الملفات",
        importing: "استرداد",
        "records with the following fields :": "السجلات بالحقول التالية:",
        "Import File Details": "استيراد تفاصيل الملف",
        "Configuration is required": "Configuration is required",
        "Configuration updated successfully": "تم تحديث التهيئة بنجاح",
        "Configure Paydo for fulfillment of the products":
            "تكوين Paydo لاستيفاء المنتجات",
        "Confirm Password": "تأكيد كلمة المرور",
        "Confirm Password is required": "تأكيد كلمة المرور مطلوب",
        "Contact Email": "تواصل بالبريد الاكتروني",
        "Contact No Country": "رقم الاتصال الدولة",
        "Contact Number": "رقم الاتصال",
        "Container Layout": "تخطيط الحاوية",
        "Content Per Row Large": "المحتوى في كل صف كبير",
        "Content Per Row Mobile": "المحتوى لكل صف موبايل",
        "Content Per Row Small": "محتوى في كل صف صغير",
        "Content Type": "نوع المحتوى",
        "Copied to clipboard!": "نسخ إلى الحافظة!",
        "Country ": "دولة ",
        "Country Rule #": "حكم البلد #",
        "Country Rules": "قواعد الدولة",
        "Country is required": "الدولة مطلوبة",
        "Country rules updated successfully": "تم تحديث قواعد البلد بنجاح",
        Coupon: "الخصم",
        "Coupon Discount": "خصم القسيمة",
        "Coupon ID": "رمز الخصم",
        "Create Condition": "خلق الشرط",
        "Create Date": "تاريخ الإنشاء",
        "Create URL for": "إنشاء الرابط من أجل",
        "Create a new shipping zone and choose the allowed shipping countries":
            "أنشئ منطقة شحن جديدة واختر دول الشحن المسموح بها",
        "Created Date": "تاريخ الإنشاء",
        "Created date": "تاريخ الإنشاء",
        "Created By": "تم الإنشاء بواسطة",
        Css: "Css",
        "Css updated successfully": "تم تحديث Css بنجاح",
        Currency: "عملة",
        "Currency is required": "العملة مطلوبة",
        Custom: "مخصص ",
        "Custom Configuration": "التكوين المخصص",
        "Custom Field disable successfully": "تم تعطيل الحقل المخصص بنجاح",
        "Custom Field enabled successfully": "تم تفعيل الحقل المخصص بنجاح",
        "Custom Link": "رابط مخصص",
        "Custom Link should be a relative url.":
            "يجب أن يكون الرابط المخصص عنوان url نسبيًا.",
        "Custom Page": "صفحة مخصص",
        Customer: "عميل",
        "Customer Address": "عنوان العميل",
        "Customer Name": "اسم العميل",

        "Customer Type": "نوع العميل",
        "Customer updated successfully": "تم تحديث العميل بنجاح",
        "Customer(s) added successfully": "تمت إضافة العميل (العملاء) بنجاح",
        "Customers Excel Sheet -": "ورقة إكسل للعملاء -",
        "Customize the app design using css, add logo, fav icons etc":
            "تخصيص تصميم التطبيق باستخدام css وإضافة شعار وأيقونات مفضلة وما إلى ذلك",
        "Customize your homepage settings, elastic search and filter settings etc":
            "تخصيص إعدادات صفحتك الرئيسية والبحث المرن وإعدادات التصفية وما إلى ذلك",
        "Cart Info": "معلومات سلة التسوق",
        "Users and Visits": "المستخدمين و الزيارات",
        "No of Orders": "عدد الطلبات",
        DELIVERED: "تم التوصيل",
        DELIVERY: "توصيل",
        DISCOUNT: "خصم",
        "Order No": "رقم الطلب",
        "Order Date": "تاريخ الطلب",
        DOB: "تاريخ الميلاد",
        "Date Of Birth": "تاريخ الميلاد",
        "Date of Birth": "تاريخ الميلاد",
        Days: "أيام",
        Default: "تقصير",
        "Default Configuration": "التكوين الافتراضي",
        "Default Language": "اللغة الافتراضية",
        "Default Value": "القيمة الافتراضية",
        "Delay (ms)": "تأخير",
        Delete: "حذف",
        "Delivery & Currency Settings": "إعدادات التسليم والعملات",
        "Delivery Charges": "رسوم التوصيل",
        "Delivery Discount": "خصم التوصيل",
        "Delivery Discount Type": "نوع الخصم على التوصيل",
        "Delivery Discount is required": "مطلوب خصم التسليم",
        "Delivery End Time": "وقت انتهاء التسليم",
        "List of Schedule Time slots": "قائمة الفترات الزمنية للتوصيل",
        "End Time": "وقت الانتهاء",
        "Processed Item Count": " عدد العناصر التي تمت معالجتها",
        "Item Count": "عدد العناصر ",
        "Delivery Mode": "وضع التوصيل",
        "Delivery SLA": "توصيل SLA",
        "Delivery Scheduling": "جدولة التسليم",
        "Delivery Scheduling Settings": "إعدادات جدولة التسليم",
        "Delivery Settings": "إعدادات التسليم",
        "Delivery Start Time": "وقت بدء التسليم",
        "Delivery settings updated successfully":
            "تم تحديث إعدادات التسليم بنجاح",
        Description: "وصف",
        Design: "تصميم",
        "Design Settings updated successfully":
            "تم تحديث إعدادات التصميم بنجاح",
        Disable: "إبطال",
        Disabled: "معاق",
        "Discard Changes": "تجاهل التغييرات",
        "Discound on": "خصم على",
        Discount: "خصم",
        "Discount On": "خصم على",
        "Discount Type": "نوع الخصم",
        "Discount Value": "قيمة الخصم",
        "Discount is required": "الخصم مطلوب",
        "order_status is required": "حالة الطلب مطلوبة",
        "Change status": "تغيير الحالة ",
        "Package Option": "خيارات التغليف",
        "packaging charges": "رسوم التغليف",
        "Cash Handling Charge": "ضريبة الدفع النقدي",
        "Delivery Charge": "رسوم التوصيل",
        "Save Invoice Pdf": "تنزيل الفاتورة بصيغة PDF",
        Invoice: "الفاتورة",
        Remark: "ملاحظة",
        Remarks: "ملاحظات",

        "Wallet Pending Balance": "رصيد المحفظة المعلق",
        "Customer orders": "طلبات العميل",
        "Fulfilled orders": "الطلبات المنفذة",
        "Paid Via Wallet": "الدفع باستخدام المحفظة",
        "Expected Delivery Date": "التارخ المتوقع ",
        "Allow Substitutions": "السماح بالتبديل",
        "Save Order Pdf": "تنزيل ملف الطلب  PDF",
        "Slot Timing": "الفترات الزمنية",
        Discounts: "الخصومات",
        "Display Count": "عرض العد",
        "Do you want to save this Template": "هل تريد حفظ هذا النموذج",
        "Dotted Navigation": "التنقل المنقط",
        Download: "تحميل",
        "Download started, Please Check My Downloads":
            "بدأ التنزيل ، يرجى التحقق من التنزيلات الخاصة بي",
        Draft: "مشروع",
        "Drag and drop file here": "سحب وإسقاط الملف هنا",
        "Drag the element and drop it into dotted borderd area":
            "اسحب العنصر وأفلته في منطقة الحدود المنقطة",
        "Drop Elements Here": "إفلات العناصر هنا",
        "Dynamic Field created successfully": "تم إنشاء الحقل الديناميكي بنجاح",
        "Dynamic Field updated successfully": "تم تحديث الحقل الديناميكي بنجاح",
        EMAIL: "البريد الإلكتروني",
        EN: "الإنجليزية",
        Edit: "تعديل",
        "Edit Customer": "تحرير العميل",
        "Eg:": "ه ز",
        "Elastic ID": "معرف مرن",
        "Elastic Search Settings": "إعدادات البحث المرنة",
        "Element Type": "نوع العنصر",
        Email: "بريد ألكتروني",
        "Email Body": "هيئة البريد الإلكتروني",
        "Email Configuration": "تكوين البريد الإلكتروني",
        "Email is invalid": "البريد الإلكتروني غير صالح",
        "Email is not valid": "البريد الإلكتروني غير صالح",
        "Email is required": "البريد الالكتروني مطلوب",
        "Embed Ar is required": "مطلوب تضمين Ar",
        "Embed En is required": "مطلوب تضمين En",
        Enable: "تمكين",
        "Enable Coupon Code": "تفعيل رمز الخصم",
        "Enable Delivery": "تمكين التسليم",
        "Enable Delivery Scheduling": "تفعيل جدولة التسليم",
        "Enable Elastic Search": "تمكين البحث المرن",
        "Enable Location Map": "تمكين خريطة الموقع",
        "Enable Private apps development from Apps -> Manage Private Apps to create the Paydo Fulfillment private app.":
            "قم بتمكين تطوير التطبيقات الخاصة من التطبيقات -> إدارة التطبيقات الخاصة لإنشاء تطبيق Paydo Fulfillment الخاص.",
        "Enable Product Page": "تمكين صفحة المنتج",
        "Enable Support": "تمكين الدعم",
        "Enable Wishlist": "تمكين قائمة الرغبات",
        "Enable delivery , add delivery mode etc":
            "تمكين التسليم وإضافة وضع التسليم وما إلى ذلك",
        "Enable delivery scheduling, set up scheduling time and etc":
            "تمكين جدولة التسليم ، وإعداد وقت الجدولة وما إلى ذلك",
        Enabled: "تمكين",
        English: "الإنجليزية",
        "Enter Brand": "أدخل الماركة",
        "Enter Category": "أدخل الفئة",
        "Enter Tag Name": "أدخل اسم العلامة",
        "Enter brand name": "أدخل اسم العلامة التجارية",
        "Enter category name": "أدخل اسم الفئة",
        "Enter product name": "أدخل اسم المنتج",
        "Event Type": "نوع الحدث",
        "Event Type is required": "نوع الحدث مطلوب",
        "Event Types": "أنواع الأحداث",
        Expired: "منتهي الصلاحية",
        "Expiry Date": "تاريخ الانتهاء",
        "Expiry Date is required": "تاريخ انتهاء الصلاحية مطلوب",
        Explicit: "صريح",
        External: "خارجي",
        FAILED: "باءت بالفشل",
        "FCM Server Key": "مفتاح خادم FCM",
        FULFILLED: "استيفاء",
        Facebook: "Facebook",
        "Facebook Domain Verification": "التحقق من مجال Facebook",
        "Facebook Pixel ID": "معرف Facebook Pixel",
        Fail: "يفشل",
        Failed: "باءت بالفشل",
        "Fav Icon": "الرمز المفضل",
        "Featured Image": "صورة مميزة",
        Female: "أنثى",
        "Field Settings updated successfully": "تم تحديث إلاعدادات بنجاح",
        "File is mandatory": "الملف إلزامي",
        Filter: "منقي",
        "Filter Settings": "إعدادات التصفية",
        "Filter is required": "الفلتر مطلوب",
        "Firebase API Key": "مفتاح فايربيس API",
        "Firebase App ID": "معرف تطبيق فايربيس",
        "Firebase Auth Domain": "مجال مصادقة فايربيس",
        "Firebase General Settings": "إعدادات فايربيس العامة",
        "Firebase Measurement ID": "معرف قياس فايربيس",
        "Firebase Messaging Sender ID": "معرف مرسل رسائل فايربيس",
        "Firebase Project ID": "رقم تعريف مشروع فايربيس",
        "Firebase Settings": "إعدادات Firebase",
        "Firebase Storage Bucket": "دلو تخزين فايربيس",
        "Firebase settings updated successfully":
            "تم تحديث إعدادات فايربيس بنجاح",
        "First Name": "الاسم الاول",
        "First Name is required": "الإسم الأول مطلوب",
        "First Visit": "الزيارة الأولى",
        Fixed: "مثبت",
        "Fixed Charges": "رسوم ثابتة",
        "Fixed Delivery Charges": "Fixed Delivery Charges",
        "Form is not valid": "النموذج غير صالح",
        "Form is not valid,": "النموذج غير صالح",
        "Free Mode": "وضع حر",
        "Fulfillment Status": "حالة الاكتمال",
        "Full Width": "العرض الكامل",
        "GTM Code": "كود GTM",
        Gender: "نوع",
        General: "عام",
        "General Settings": "الاعدادات العامة",
        "General Settings updated successfully":
            "تم تحديث الإعدادات العامة بنجاح",
        "Go to": "اذهب إلى",
        Goods: "بضائع",
        "Google Site Verification": "التحقق من موقع Google",
        Grid: "شبكة",
        Guest: "ضيف",
        "Guest Customer": "الزبون الضيف",
        "Order Status": "حالة الطلب",
        "Reference No": "الرقم المرجعي",
        "Exp Delivery": "التارخ المتوقع ",
        "Guest Only": "ضيف فقط",
        "Guest and Register": "ضيف وتسجيل",
        "Guest or Registerd": "ضيف أو مسجل د",
        "Help Text (Ar)": "نص المساعدة (باللغة العربية)",
        "Help Text (En)": "نص تعليمات (انجليزي)",
        Hidden: "مختفي",
        "Hide Detail": "إخفاء التفاصيل",
        History: "تاريخ",
        "History of": "تاريخ",
        "Home Page": "الصفحة الرئيسية",
        "Home Page Settings": "إعدادات الصفحة الرئيسية",
        "House No": "رقم المنزل",
        "Html Ar is required": "مطلوب Html عربي",
        "Html En is required": "Html الإنجليزية مطلوب",
        Icon: "أيقونة",
        "Icon Ar": "أيقونة Ar",
        "Icon En": "رمز En",
        "Icon Image": "صورة الرمز",
        "Icon Type": "نوع الرمز",
        "If you are on Advanced Shopify, Shopify Plus or an annual plan, you will have this service enabled by default. However,":
            "إذا كنت تستخدم Advanced Shopify أو Shopify Plus أو خطة سنوية ، فسيتم تمكين هذه الخدمة افتراضيًا. ومع ذلك،",
        Image: "صورة",
        image: "صورة",
        "Image Ar is required": "مطلوب صورة Ar",
        "Image En is required": "مطلوب صورة En",
        "Image Size Warning": "تحذير حجم الصورة",
        "Image size should be 64 x 64 pixels , Please rezise your image and upload":
            "يجب أن يكون حجم الصورة 64 × 64 بكسل ، يرجى إعادة تكبير الصورة وتحميلها",
        Images: "الصور",
        Implicit: "ضمني",
        "In active": "غير نشط",
        "In queue": "في قائمة الانتظار",
        Information: "معلومات",
        Initiated: "بدأت",
        Instagram: "انستغرام",
        "Install the Paydo services on Shopify by clicking on the":
            "قم بتثبيت خدمات Paydo على Shopify من خلال النقر فوق",
        "Installing Shopify": "تثبيت Shopify",
        "International Configuration": "التكوين الدولي",
        "International Delivery": "التوصيل دوليا",
        "Invalid email": "بريد إلكتروني خاطئ",
        "Invoice Date": "تاريخ الفاتورة",
        "Invoice No": "رقم الفاتورة",
        "Invoice Number": "رقم الفاتورة",
        "Invoice Status": " حالة الفاتورة",
        "Ion Icon": "ايون ايكون",
        "Is Civil ID Mandatory": "البطاقة المدنية إلزامية",
        "Is Email Mandatory": "هل البريد الإلكتروني إلزامي",
        "Is Enabled": "تم التمكين",
        "Is Guest": "ضيف",
        "Is PACI Mandatory": "هل PACI إلزامي",
        "Is Searchable": "قابل للبحث",
        KD: "دينار كويتي",
        Key: "مفتاح",
        "Kuwait Configuration": "تكوين الكويت",
        "Label (Ar)": "التسمية (بالعربية)",
        "Label (En)": "التسمية (En)",
        "Label Arabic is required": "مطلوب التسمية العربية",
        "Label English is required": "مطلوب تسمية اللغة الإنجليزية",
        "Large Screen": "شاشة كبيرة",
        "Last Name": "الاسم الاخير",
        "Layout Settings": "إعدادات الشكل الظاهري",
        "Posts Layout Settings": "اعدادت مظهر صفحة المقالات",
        Limited: "محدود",
        "List of Conditions": "قائمة الشروط",
        "List of Country Rules": "قائمة قواعد الدولة",
        "Local Delivery": "توصيل محلي",
        Logo: "شعار",
        Logout: "تسجيل خروج",
        Loop: "عقدة",
        Male: "ذكر",
        "Max Price": "أقصى سعر",
        "Maximum Cart Amount": "الحد الأقصى للمبلغ",
        "Maximum Cart Amount is required": "الحد الأقصى لمبلغ سلة التسوق مطلوب",
        "Maximum Length": "الحد الأقصى لطول",
        "Media List": "قائمة الوسائط",
        "Media created successfully": "تم إنشاء الوسائط بنجاح",
        "Media deleted successfully": "تم مسح الوسائط بنجاح",
        "Media updated successfully": "تم تعديل الوسائط بنجاح",
        Medium: "المكان",
        "Membership Discount": "خصم العضوية",
        "Membership Number": "رقم العضوية",
        "Membership Plan": "خطة العضوية",
        Memberships: "العضويات",
        "Menu Items": "عناصر القائمة",
        Message: "رساله",
        "Message is required": "الرسالة مطلوبة",
        "Meta Description (Ar)": "وصف ميتا (عربي)",
        "Meta Description (En)": "وصف ميتا (بالإنجليزية)",
        "Middle Name": "الاسم الوسط",
        "Min Price": "سعر دقيقة",
        "Minimum Amount": "الحد الأدنى للمبلغ",
        "Minimum Cart Amount": "الحد الأدنى للمبلغ",
        "Minimum Length": "الطول الأدنى",
        "Minimum Order Amount": "اقل كمية للطلب",
        "Mininum Cart Amount is required": "مطلوب مبلغ Mininum",
        "Mobile Screen": "شاشة هاتف",
        "More Information": "More Information",
        "My Downloads": "التنزيلات الخاصة بي",
        "MyPaydo General Settings": "إعدادات MyPaydo العامة",
        "MyPaydo Settings": "إعدادات MyPaydo",
        "MyPaydo settings updated successfully":
            "تم تحديث إعدادات MyPaydo بنجاح",
        Name: "الاسم",
        "Name (Ar)": "الاسم (عربي)",
        "Name (En)": "الاسم (انجليزي)",
        "Name En is required": "الاسم En مطلوب",
        "Name English is required": "الاسم باللغة الإنجليزية مطلوب",
        "Name is required": "الاسم المطلوب",
        "Name(Ar)": "الاسم (بالعربية)",
        "Name(En)": "الاسم (بالإنجليزية)",
        Native: "محلي",
        Navigation: "التنقل",
        "Navigation item added successfully": "تمت إضافة عنصر التنقل بنجاح",
        "Navigation item updated successfully": "تم تحديث عنصر التنقل بنجاح",
        "Navigation menu created successfully": "تم إنشاء قائمة التنقل بنجاح",
        "Navigation menu deleted successfully": "تم مسح قائمة التنقل بنجاح",
        "Navigation menu updated successfully": "تم تعديل قائمة التنقل بنجاح",
        "New Password": "كلمة المرور الجديدة",
        "New Password is required": "مطلوب كلمة مرور جديدة",
        No: "الرقم",
        no: "لا",
        "No Of Items": "عدد العناصر",
        "No Validation": "لا يوجد تحقق",
        "No items to display": "لا توجد عناصر للعرض",
        "No of rules": "عدد القواعد",
        "No value": "بلا قيمة",
        None: "لا أحد",
        Notes: "ملاحظات",
        "Nothing to display": "لا شيء لعرضه",
        "Notification send successfully": "إرسال الإخطار بنجاح",
        "Noting to display": "مشيرا للعرض",
        "Number Language": "لغة الرقم",
        "Number of items": "عدد العناصر",
        "Number of slides": "عدد الشرائح",
        OK: "نعم",
        OR: "أو",
        "Only Arabic": "العربية فقط",
        "Only English": "الإنجليزية فقط",
        "Operation successfully completed": "تمت العملية بنجاح",
        "Operation type": "نوع العملية",
        Operator: "المشغل أو العامل",
        Origion: "بلد الأصل",
        Source: "المصدر",
        Or: "أو",
        "Restricted category name": "الفئات المستثناة",
        Order: "طلب",
        "Order By": "ترتيب حسب",
        "Order History": "تاريخ الطلب",
        "Order ID": "رقم الطلب",
        "Order Information": "معلومات الطلب",
        "Order is required": "الطلب مطلوب",
        Orders: "طلبات",
        "Orders Excel Sheet -": "ورقة اكسل للطلبات -",
        "Orders, ": "الطلب، ",
        Others: "أخري",
        "Outgoing Email Settings": "إعدادات البريد الإلكتروني الصادر",
        "Outgoing SMS Settings": "إعدادات الرسائل القصيرة الصادرة",
        "Override Arabic": "تجاوز اللغة العربية",
        "Override English": "تجاوز اللغة الإنجليزية",
        PACCI: "PACCI",
        PACI: "PACI",
        "PACI Note (Ar)": "ملاحظة PACI (باللغة العربية)",
        "PACI Note (En)": "ملاحظة PACI (بالإنجليزية)",
        PENDING: "ريثما",
        PUSH: "يدفع",
        Page: "صفحة",
        "Page Elements": "عناصر الصفحة",
        "Page Limit": "حد الصفحة",
        "Page Limit is required": "حد الصفحة مطلوب",
        "Page Limit should be greater than 4":
            "يجب أن يكون حد الصفحة أكبر من 4",
        "Page Settings": "إعدادات الصفحة",
        "Page Type": "نوع الصفحة",
        "Page URL": "رابط الصفحة",
        "Page created successfully": "تم إنشاء الصفحة بنجاح",
        "Page deleted successfully": "تم مسح الصفحة بنجاح",
        "Page is required": "الصفحة مطلوبة",
        "Page updated successfully": "تم تعديل الصفحة بنجاح",
        Pages: "صفحة",
        Parent: "الأبوين",
        Password: "كلمه السر",
        "Password and Confirm Password must be same":
            "يجب أن تكون كلمة المرور وتأكيد كلمة المرور متطابقتين",
        "Password changed successfully": "تم تغيير الرقم السري بنجاح",
        "Password contain atleast 8 characters":
            "تحتوي كلمة المرور على 8 أحرف على الأقل",
        "Password is required": "كلمة المرور مطلوبة",
        "Paste the name of the icon in the icon text box":
            "الصق اسم الرمز في مربع نص الرمز",
        "Paydo Shipping App": "تطبيق Paydo للشحن",
        "Paydo Shipping App is built to provide fulfillment services for the shopify orders":
            "تم تصميم تطبيق Paydo Shipping لتقديم خدمات التنفيذ لطلبات Shopify",
        Payment: "دفع",
        "Payment Date": "يوم الدفع",
        "Payment Method": "طريقة الدفع",
        "Payment Status": "حالة السداد",
        Pending: "قيد الانتظار",
        "Pending Cart Delay": "في انتظار تأخير سلة التسوق",
        "Pending Cart Delay should be greater than 0":
            "يجب أن يكون تأخير سلة التسوق المعلقة أكبر من 0",
        "Pending Cart Delay should be less than 101":
            "يجب أن يكون تأخير سلة التسوّق المعلقة أقل من 101",
        "Permission List": "قائمة الأذونات",
        "Personal Information": "معلومات شخصية",
        Personalized: "شخصية",
        "Phone Number": "رقم التليفون",
        "Phone Number is required": "رقم الهاتف مطلوب",
        Points: "النقاط",
        "Points Actions": "حالة النقاط",
        "Points Configuration": "إعدادات النقاط",
        Plan: "يخطط",
        "Plan is required": "الخطة مطلوبة",
        "Please fill the required fields": "يرجى تعبئة جميع الحقول المطلوبة",
        "Please save the parent nav item before assigning child to it":
            "يرجى حفظ عنصر التنقل الأصل قبل تخصيص الطفل له",
        Popup: "يظهر فجأة",
        "Popup Behaviour": "السلوك المنبثق",
        "Popup Delay (in ms)": "تأخير ظهور النوافذ المنبثقةبالمللي ثانية ",
        "Popup Settings": "إعدادات منبثقة",
        Port: "ميناء",
        "Port is required": "المنفذ مطلوب",
        "Possible Values": "القيم الممكنة",
        "Post Category": "فئة المشاركة",
        "Post Category deleted successfully": "تم حذف فئة المشاركة بنجاح",
        "Post Elements": "عناصر المشاركة",
        "Post URL": "عنوان URL المنشور",
        "Post deleted successfully": "تم حذف المشاركة بنجاح",
        Posts: "المشاركات",
        Preview: "معاينة",
        "Preview (Arabic)": "معاينة (عربي)",
        "Preview (English)": "معاينة (الإنجليزية)",
        "Preview - Email": "معاينة - البريد الإلكتروني",
        "Preview - PUSH": "معاينة - دفع",
        "Preview - SMS": "معاينة - SMS",
        Price: "السعر",
        "Price Filter": "مرشح السعر",
        "Price Rule Settings": "إعدادات قواعد السعر",
        "Price Rule": " قواعد السعر",
        "Price Rule created successfully": "تم إنشاء قاعدة السعر بنجاح",
        "Price Rule deleted successfully": "تم حذف قاعدة السعر بنجاح",
        "Price Rule updated successfully": "تم تحديث قاعدة السعر بنجاح",
        "Primary Color": "لون أصلي",
        Processed: "معالجتها",
        Processing: "معالجة",
        Product: "معرفات المنتج",
        "Product Amount": "المبلغ المنتج",
        "Product Minimum Quantity": "كمية المنتج الحد الأدنى",
        "Product Name": "اسم المنتج",
        "Product Set Types": "أنواع مجموعة المنتجات",
        "Product Settings": "اعدادات المنتج",
        "Product Types": "أنواع المنتجات",
        Products: "منتجات",
        "Profile Picture": "صورة الملف",
        Public: "عامة",
        Published: "نشرت",
        Quantity: "الكمية",
        "Reach out to Shopify’s customer support to have this service enabled on your existing plan at an extra cost.":
            "تواصل مع دعم عملاء Shopify لتمكين هذه الخدمة على خطتك الحالية بتكلفة إضافية",
        Recipient: "متلقي",
        "Recipient Type is required": "نوع المستلم مطلوب",
        Reference: "المرجعي",
        Refresh: "تحديث",
        Regex: "Regex",
        "Register Only": "سجل فقط",
        Registered: "مسجل",
        "Registered Customer": "عميل مسجل",
        "Registered User": "مستخدم مسجل",
        "Relation Types": "أنواع العلاقة",
        "Request could not completed": "تعذر إكمال الطلب",
        "Request send successfully": "أرسل الطلب بنجاح",
        Required: "مطلوب",
        "Restrict Area": "منطقة الحظر",
        Retry: "أعد المحاولة",
        Role: "دور",
        "Role Detail": "تفاصيل الدور",
        "Role created successfully": "تم إنشاء الدور بنجاح",
        "Role deleted successfully": "تم حذف الدور بنجاح",
        "Role is required": "الدور مطلوب",
        "Role updated successfully": "تم تحديث الدور بنجاح",
        "Root URL": "URL الجذر",
        "Root Url is required": "عنوان URL الجذر مطلوب",
        "Rule #": "القاعدة #",
        "SEO & Analytics": "تحسين محركات البحث والتحليلات",
        "SEO & Analytics Settings": "إعدادات تحسين محركات البحث والتحليلات",
        SKU: "SKU",
        SMS: "رسالة قصيرة",
        "SMS Configuration": "تكوين الرسائل القصيرة",
        SMTP: "SMTP",
        SUBTOTAL: "المجموع الفرعي",
        Save: "حفظ",
        "Schedule After": "جدولة بعد",
        "Schedule Days": "جدولة أيام",
        "Secondary Color": "اللون الثانوي",
        Select: "يختار",
        Send: "إرسال",
        "Send Message": "أرسل رسالة",
        "Send Notification": "إرسال إشعار",
        "Send SMS": "أرسل رسالة نصية قصيرة",
        "Sender Email": "البريد الإلكتروني المرسل",
        "Sender Email is required": "البريد الإلكتروني المرسل مطلوب",
        "Sender ID": "هوية المرسل",
        "Sender ID is required": "معرف المرسل مطلوب",
        "Sender Name": "اسم المرسل",
        "Sender Name is required": "اسم المرسل مطلوب",
        Sendgrid: "Sendgrid",
        Sent: "أرسلت",
        Sequence: "تسلسل",
        Server: "الخادم",
        "Server is required": "الخادم مطلوب",
        Service: "خدمة",
        "Set the Inventory managed by option to Paydo Fulfillment and add the SKU as Paydo Id for the product":
            "عيّن خيار إدارة المخزون إلى Paydo Fulfillment وأضف SKU كمعرّف Paydo للمنتج",
        "Set the required permissions": "قم بتعيين الأذونات المطلوبة",
        Setup: "يثبت",
        "Setup Mypaydo Settings": "إعداد إعدادات Mypaydo",
        "Setup Translation for app": "إعداد الترجمة للتطبيق",
        "Setup analytics Settings": "إعداد إعدادات التحليلات",
        "Setup app statuses": "إعداد حالات التطبيق",
        "Setup custom fields": "إعداد الحقول المخصصة",
        "Setup email configuration": "إعداد تكوين البريد الإلكتروني",
        "Setup firebase Settings": "إعداد إعدادات Firebase",
        "Footer Config": "اعدادات الفوتر",
        "Setup site's general settings like title,language etc": "إعداد الإعدادات العامة للموقع مثلالعنوان واللغة وما إلى ذلك",
        "Setup sms configuration": "إعداد تكوين الرسائل القصيرة",
        "Setup social media": "إعداد الوسائط الاجتماعية",
        "Setup the shipping zones and configure the rates from Paydo":
            "قم بإعداد مناطق الشحن وتكوين الأسعار من Paydo",
        Shipping: "شحن",
        "Shopify Api Key": "Shopify مفتاح Api",
        "Shopify Domain": "Shopify المجال",
        "Shopify Secret Key": "Shopify المفتاح السري",
        "Shopify Settings": "Shopify الإعدادات",
        "Shopify app created successfully": "Shopify تم إنشاء التطبيق بنجاح",
        "Shopify app installed successfully": "Shopify تم تثبيت التطبيق بنجاح",
        "Shopify app uninstalled successfully":
            "Shopify تم إلغاء تثبيت التطبيق بنجاح",
        "Shopify app updated successfully": "Shopify تم تحديث التطبيق بنجاح",
        "Shoppify Api Key is required": "مطلوب Shoppify Api Key",
        "Shoppify Domain is required": "مجال Shoppify مطلوب",
        "Shoppify Secret Key is required": "مفتاح Shoppify السري مطلوب",
        "Show As": "كما تظهر",
        "Show Civil ID": "إظهار البطاقة المدنية",
        "Show Detail": "أظهر تفاصيل",
        "Show Home Page": "عرض الصفحة الرئيسية",
        "Show In Datatable": "إظهار في جدول البيانات",
        "Show On": "عرض على",
        "Show PACI": "عرض PACI",
        "Show as Modal": "تظهر كمشروط",
        "Site Identify": "تحديد الموقع",
        "Site Settings": "إعدادات الموقع",
        "Slide Per View Large": "شريحة عرض كبيرة",
        "Slide Per View Mobile": "شريحة لكل مشاهدة موبايل",
        "Slide Per View Small": "شريحة لكل عرض صغيرة",
        Slider: "المنزلق",
        "Small Screen": "الشاشة الصغيرة",
        "Social Media Settings": "إعدادات الوسائط الاجتماعية",
        "Social media Settings updated successfully":
            "تم تحديث إعدادات الوسائط الاجتماعية بنجاح",
        "Source App": "تطبيق المصدر",
        "Source App is required": "التطبيق المصدر مطلوب",
        "Space Between": "الفضاء بين",
        "Splash Screen BG": "سبلاش سكرين BG",
        "Splash Screen Preview": "معاينة شاشة البداية",
        "Splash Screen Settings": "إعدادات شاشة البداية",
        "Start Date": "تاريخ البدء",
        "Start Date is required": "تاريخ البدء مطلوب",
        Status: "حاله",
        "Status Page": "صفحة الحالة",
        "Status Page Settings": "حالة اعدادات الصفحة",
        "Status updated successfully": "تم تحديث الحالة بنجاح",
        "Stay Here": "ابق هنا",
        "Step 1 ": "الخطوة 1",
        "Step 2 ": "الخطوة 2",
        "Step 3 ": "الخطوه 3",
        "Steps to integrate Paydo into Shopify": "خطوات دمج Paydo في Shopify",
        Street: "شارع",
        "Sub Category": "تصنيف فرعي",
        "Subscriber Label (ar)": "بطاقة المشترك (ar) ",
        "Subscriber Label (en)": "بطاقة المشترك (en)",
        "Success Message (AR)": "رسالة النجاح (ar)",
        "Success Message (EN)": "رسالة النجاح (en)",
        "Success Page Settings": "إعدادات صفحة النجاح",
        "Success page settings updated successfully":
            "تم تحديث إعدادات صفحة النجاح بنجاح",
        "Support Contact Number": "رقم الاتصال بالدعم",
        "Support General Settings": "دعم الإعدادات العامة",
        "Support Organization": "منظمة الدعم",
        "Support Settings": "إعدادات الدعم",
        "Support Token": "رمز الدعم",
        "Support settings updated successfully": "تم تحديث إعدادات الدعم بنجاح",
        "Switch to Code Editor": "قم بالتبديل إلى محرر التعليمات البرمجية",
        "Switch to HTML Editor": "قم بالتبديل إلى محرر HTML",
        "Switch to the annual billing plan with Shopify.":
            "قم بالتبديل إلى خطة الفوترة السنوية مع Shopify.",
        "Sync Products": "منتجات المزامنة",
        "Sync Stocks": "مزامنة الأسهم",
        "Syncing...": "مزامنة....",
        TAX: "ضريبة",
        TOTAL: "الاجمالي",
        Tax: "ضريبة",
        Template: "قالب",
        "Template Design": "تصميم النموذج",
        "Template Information": "معلومات القالب",
        "Template Type": "نوع النموذج",
        "Template Type is required": "نوع النموذج مطلوب",
        "Template created successfully": "تم إنشاء النموذج بنجاح",
        "Template deleted successfully": "تم حذف القالب بنجاح",
        "Template is required": "النموذج مطلوب",
        "Template updated successfully": "تم تحديث القالب بنجاح",
        "Terms and Condition Type": "نوع الشروط والاحكام",
        "Terms and Conditions": "الأحكام والشروط",
        "Terms and Conditions updated successfully":
            "تم تحديث الشروط والأحكام بنجاح",
        "Terms and conditions": "الأحكام والشروط",
        Text: "نص",
        "Text Color": "لون الخط",
        "The Paydo Fulfilment app requires Shopifys": "يتطلب تطبيق Paydo Fulfillment Shopifys",
        "The app provides shipping rates and pushes order to Paydo system for fulfillment": "يوفر التطبيق أسعار الشحن ويدفع الطلب إلى نظام Paydo للوفاء به",
        "The orders will be added to the Paydo Delivery System as soon as they are created on shopify": "ستتم إضافة الطلبات إلى نظام التسليم Paydo بمجرد إنشائها في Shopify",
        "Theme": "الموضوع",
        "Theme 1": "الموضوع 1",
        "Theme 2": "الموضوع 2",
        "Theme 3": "الموضوع 3",
        "Theme Settings": "إعدادات الموضوع",
        "Added To Cart": "تم إضافته لسلة المبيعات",
        Themes: "ثيمات",
        "This background color indicates overridden values":
            "يشير لون الخلفية هذا إلى القيم المتجاوزة",
        "Thumbnail Image": "صورة مصغرة",
        "Time Slot Duration": "مدة الفتحة الزمنية",
        Title: "لقب",
        "Title (Ar)": "العنوان (بالعربية)",
        "Title (Arabic)": "العنوان (عربي)",
        "Title (En)": "العنوان (بالإنجليزية)",
        "Title (English)": "العنوان (الإنجليزية)",
        "Title En is required": "مطلوب العنوان En",
        "Title English is required": "مطلوب عنوان اللغة الإنجليزية",
        "Title is required": "العنوان مطلوب",
        "To create a private app in your Shopify Store":
            "لإنشاء تطبيق خاص في متجر Shopify الخاص بك",
        "To enable the carrier-calculated shipping service, you can choose to either:":
            "لتمكين خدمة الشحن المحسوبة بواسطة شركة النقل ، يمكنك اختيار إما:",
        "To remove the Paydo Shipping Services": "لإزالة خدمات الشحن Paydo",
        "To uninstall the Paydo Services on Shopify":
            "لإلغاء تثبيت خدمات Paydo على Shopify",
        Token: "رمز",
        "Token is required": "الرمز مطلوب",
        Total: "المجموع",
        "Enable Product Review": "اظهار تقييم المنتجات",
        "Total Amount": "المبلغ الإجمالي",
        "Total Audience": "إجمالي الجمهور",
        "Total Count:": "العدد الإجمالي:",
        "Total Files :": "إجمالي الملفات:",
        "Total Orders": "إجمالي الطلبات",
        "Total Earned": "إجمالى المكتسب",
        "Total Redeemed": "إجمالى المستردات",
        "Transaction ID": "رقم المعاملة",
        Translation: "ترجمة",
        Type: "النوع",
        Available: "متاح",

        "Type is required": "النوع مطلوب",
        "Type something...": "اطبع شيئا...",
        Uninstall: "الغاء التثبيت",
        "Uninstalling Shopify": "إلغاء التثبيت Shopify",
        "Unit Price": "سعر الوحده",
        Unlimited: "غير محدود",
        Update: "تحديث",
        "Update Condition": "تحديث الشرط",
        "Update Date": "تاريخ التحديث",
        "Update Media": "تحديث الوسائط",
        "Updated Date": "تاريخ التحديث",
        "Updated date": "تاريخ التحديث",
        "Upgrade to Advanced Shopify plan or Shopify Plus.":
            "قم بالترقية إلى Advanced Shopify خطة أو Shopify Plus",
        Upload: "رفع",
        "Upload Manually": "رفع يدوى",
        Usage: "إستعمال",
        "Usage Limit": "حد الاستخدام",
        "Usage Limit is required": "حد الاستخدام مطلوب",
        "Usage Type": "نوع الاستخدام",
        "Use Existing Template": "استخدم القالب الحالي",
        "Use Other Account": "استخدم حساب آخر",
        "Use Paydo Account": "استخدم حساب Paydo",
        "User Verified": "تم التحقق من المستخدم",
        "User created successfully": "تم إنشاء المستخدم بنجاح",
        "User deleted successfully": "تم حذف المستخدم بنجاح",
        "User updated successfully": "تم تحديث المستخدم بنجاح",
        Username: "اسم المستخدم",
        "Username is required": "اسم المستخدم مطلوب",
        Validator: "المدقق",
        Validity: "صلاحية",
        Value: "قيمة",
        "Value (Ar)": "القيمة (بالعربية)",
        "Value (En)": "القيمة (بالإنجليزية)",
        "Value English is required": "قيمة اللغة الإنجليزية مطلوبة",
        "Value Start with HTTP": "القيمة تبدأ بـ HTTP",
        "Value can not start with HTTP": "لا يمكن أن تبدأ القيمة بـ HTTP",
        "Value is required": "القيمة مطلوبة",
        Verified: "تم التحقق",
        View: "عرض",
        "View Settings": "عرض الإعدادات",
        "View Site": "عرض الموقع",
        Visibility: "الرؤية",
        Whatsapp: "ال WhatsApp",
        "Widget created successfully": "القطعة تم إنشاؤها بنجاح",
        "Widget deleted successfully": "القطعة تم حذفها بنجاح",
        "Widget updated successfully": "القطعة تم تحديثها بنجاح",
        Wishlist: "قائمة الرغبات",
        "Wishlist, ": "قائمة الرغبات",
        Yes: "نعم",
        "You can connect your shop with Shopify for fulfilment":
            "يمكنك توصيل متجرك بـ Shopify للوفاء",
        "You can use any of the below sites to resize the image , and reupload":
            "يمكنك استخدام أي من المواقع أدناه لتغيير حجم الصورة وإعادة التحميل",
        "You cannot change this menu as a child":
            "لا يمكنك تغيير هذه القائمة كطفل",
        "You have already pending downloads, So you can not download at this moment. Please try again later":
            "لديك بالفعل تنزيلات معلقة ، لذا لا يمكنك التنزيل في الوقت الحالي. الرجاء معاودة المحاولة في وقت لاحق",
        Youtube: "موقع يوتيوب",
        Zone: "منطقه",
        "Zone is required": "المنطقه مطلوبه",
        Zoom: "تكبير",
        "at the bottom": "في الأسفل",
        button: "زر",
        "carrier-calculated shipping service to display shipping options on the checkout page.":
            "خدمة الشحن المحسوبة بواسطة شركة النقل لعرض خيارات الشحن على صفحة الخروج.",
        "for ": "إلى عن على",
        "Show Posts Categories": "إظهار فئات المقالات",
        "Show Post Comments": "إظهار قسم التعليقات",
        "Posts Number Per Row": "عدد المقالات فى الصف الواحد",
        "go to the Shopify app settings on Paydo and click on the ":
            "انتقل إلى Shopify إعدادات التطبيق على Paydo وانقر على ",
        "Enable Social Login": "تمكين الدخول باستخدام وسائل التواصل الاجتماعى",
        help_text_Abandoned_cart_days: "help_text_Abandoned_cart_days",
        "Allow Maximum Discount Value": "اقصى قيمة للتخفيض",
        help_text_Account: "help_text_Account",
        help_text_show_categories: "إظهار فئات المقالات",
        help_text_Api_key_email_config: "help_text_Api_key_email_config",
        help_text_BaseCountry: "help_text_BaseCountry",
        help_text_BaseCurrency: "help_text_BaseCurrency",
        help_text_Brand_Filter: "help_text_Brand_Filter",
        help_text_CDN_URL: "help_text_CDN_URL",
        help_text_Categories_Filter: "help_text_Categories_Filter",
        help_text_DeliveryEndTime: "help_text_DeliveryEndTime",
        help_text_DeliverySLA: "help_text_DeliverySLA",
        help_text_DeliveryStartTime: "help_text_DeliveryStartTime",
        help_text_Discount: "help_text_Discount",
        help_text_Discount_Type: "help_text_Discount_Type",
        help_text_Elastic_Search: "help_text_Elastic_Search",
        help_text_EnableDelivery: "help_text_EnableDelivery",
        help_text_EnableDeliveryScheduling:
            "help_text_EnableDeliveryScheduling",
        help_text_Enable_Support: "help_text_Enable_Support",
        help_text_Facebook_Domain_Verification:
            "help_text_Facebook_Domain_Verification",
        help_text_Facebook_pixel_id: "help_text_Facebook_pixel_id",
        help_text_Firebase_API_Key: "help_text_Firebase_API_Key",
        help_text_Firebase_Measurement_ID: "help_text_Firebase_Measurement_ID",
        help_text_Firebase_Project_ID: "help_text_Firebase_Project_ID",
        help_text_Firebase_Source_Bucket: "help_text_Firebase_Source_Bucket",
        help_text_Firebase_app_ID: "help_text_Firebase_app_ID",
        help_text_Firebase_auth_domain: "help_text_Firebase_auth_domain",
        help_text_Firebase_messaging_sender_ID:
            "help_text_Firebase_messaging_sender_ID",
        help_text_GTM_Code: "help_text_GTM_Code",
        help_text_Google_Site_Verification:
            "help_text_Google_Site_Verification",
        help_text_HasDeliveryCharges: "help_text_HasDeliveryCharges",
        help_text_International_Sender_id: "help_text_International_Sender_id",
        help_text_Meta_Description_AR: "help_text_Meta_Description_AR",
        help_text_Meta_Description_EN: "help_text_Meta_Description_EN",
        help_text_Minimum_Order_Amount: "help_text_Minimum_Order_Amount",
        help_text_Pages: "help_text_Pages",
        help_text_Password_email_config: "help_text_Password_email_config",
        help_text_Payment_Method_Shopify: "help_text_Payment_Method_Shopify",
        help_text_Port_email_config: "help_text_Port_email_config",
        help_text_Price_Filter: "help_text_Price_Filter",
        help_text_RestrictArea: "help_text_RestrictArea",
        help_text_Root_URL: "help_text_Root_URL",
        help_text_ScheduleAfter: "help_text_ScheduleAfter",
        help_text_ScheduleDays: "help_text_ScheduleDays",
        help_text_Sender_email: "help_text_Sender_email",
        help_text_Sender_name: "help_text_Sender_name",
        help_text_Server_email_config: "help_text_Server_email_config",
        help_text_Shopify_Api_Key: "help_text_Shopify_Api_Key",
        help_text_Shopify_Domain: "help_text_Shopify_Domain",
        help_text_Shopify_Secret_Key: "help_text_Shopify_Secret_Key",
        help_text_Source_APP: "help_text_Source_APP",
        help_text_Status: "help_text_Status",
        help_text_Sub_Categories_Filter: "help_text_Sub_Categories_Filter",
        help_text_Support_Organization: "help_text_Support_Organization",
        help_text_Support_Token: "help_text_Support_Token",
        help_text_Support_contact_number: "help_text_Support_contact_number",
        help_text_Theme: "help_text_Theme",
        help_text_TimeSlotDuration: "help_text_TimeSlotDuration",
        help_text_Token: "help_text_Token",
        help_text_Username_email_config: "help_text_Username_email_config",
        help_text_ask_email: "help_text_ask_email",
        help_text_condition_filter: "help_text_condition_filter",
        help_text_condition_operator: "help_text_condition_operator",
        help_text_condition_value: "help_text_condition_value",
        help_text_design_LargeScreen: "help_text_design_LargeScreen",
        help_text_design_MobileScreen: "help_text_design_MobileScreen",
        help_text_design_SmallScreen: "help_text_design_SmallScreen",
        help_text_design_TemplateDesign: "help_text_design_TemplateDesign",
        help_text_email_config_APi_key: "أدخل مفتاح Sendgrid API",
        help_text_email_config_Password: "أدخل كلمة المرور لمصادقة الخادم",
        help_text_email_config_Port: "أدخل منفذ SMTP",
        help_text_email_config_Sender_email:
            "أدخل البريد الإلكتروني الذي ترغب في إرسال البريد الإلكتروني باستخدامه",
        help_text_email_config_Sender_name:
            "أدخل الاسم الذي ترغب في إظهاره كمرسل",
        help_text_email_config_Server:
            "أدخل اسم المضيف. تأكد من التحقق من البريد الإلكتروني مع المزود",
        help_text_email_config_Type: "SMTP أو Sendgrid",
        help_text_email_config_Username: "أدخل اسم المستخدم لمصادقة الخادم",
        help_text_enable_coupon_code: "help_text_enable_coupon_code",
        help_text_fcm_server_key: "help_text_fcm_server_key",
        help_text_grid: "help_text_grid",
        help_text_internation_api_key: "help_text_internation_api_key",
        help_text_is_email_mandatory: "help_text_is_email_mandatory",
        help_text_list: "help_text_list",
        help_text_local_Sender_id: "help_text_local_Sender_id",
        help_text_local_api_key: "help_text_local_api_key",
        help_text_location_map: "help_text_location_map",
        help_text_subscribtion_newsLetter:
            "اظهار زر الاشتراك فى النشرة البريدية",
        "Show subscribtion to NewsLetter":
            "اظهار زر الاشتراك فى النشرة البريدية",
        help_text_pending_cart_days: "help_text_pending_cart_days",
        help_text_price_rule_cart_amount: "help_text_price_rule_cart_amount",
        help_text_price_rule_delivery_charges:
            "help_text_price_rule_delivery_charges",
        help_text_price_rule_international_delivery:
            "help_text_price_rule_international_delivery",
        help_text_price_rule_local_delivery:
            "help_text_price_rule_local_delivery",
        help_text_price_rule_product_amount:
            "help_text_price_rule_product_amount",
        help_text_show_home_page: "help_text_show_home_page",
        help_text_slider_Animation: "help_text_slider_Animation",
        help_text_slider_AutoPlay: "help_text_slider_AutoPlay",
        help_text_slider_Delay: "help_text_slider_Delay",
        help_text_slider_FreeMode: "help_text_slider_FreeMode",
        help_text_slider_Loop: "help_text_slider_Loop",
        help_text_slider_Name: "help_text_slider_Name",
        help_text_slider_Navigation: "help_text_slider_Navigation",
        help_text_slider_SpaceBetween: "help_text_slider_SpaceBetween",
        help_text_slider_Zoom: "help_text_slider_Zoom",
        help_text_slider_dotted_Navigation:
            "help_text_slider_dotted_Navigation",
        help_text_slider_showas: "help_text_slider_showas",
        help_text_slider_type: "help_text_slider_type",
        help_text_sms_config_international_API_key:
            "help_text_sms_config_international_API_key",
        help_text_sms_config_international_Sender_ID:
            "help_text_sms_config_international_Sender_ID",
        help_text_sms_config_local_API_Key:
            "help_text_sms_config_local_API_Key",
        help_text_sms_config_local_Password:
            "help_text_sms_config_local_Password",
        help_text_sms_config_local_Sender_ID:
            "help_text_sms_config_local_Sender_ID",
        help_text_sms_config_local_Username:
            "help_text_sms_config_local_Username",
        help_text_terms_disabled: "help_text_terms_disabled",
        help_text_terms_explicit: "help_text_terms_explicit",
        help_text_terms_implicit: "help_text_terms_implicit",
        help_text_widget_Enable: "help_text_widget_Enable",
        help_text_widget_Name: "help_text_widget_Name",
        help_text_widget_Order: "help_text_widget_Order",
        help_text_widget_Type: "help_text_widget_Type",
        here: "هنا",
        "if you are on the Shopify Basic plan, you need to enable the carrier-calculated shipping service before installing the app.":
            "if you are on the Shopify Basic plan, you need to enable the carrier-calculated shipping service before installing the app.",
        "is a relative url": "هو عنوان url نسبي",
        or: "أو",
        read_fulfillments: "قراءة_الإنجازات",
        read_inventory: "قراءة_المخزون",
        read_orders: "قراءة_الطلبات",
        read_products: "قراءة_المنتجات",
        read_shipping: "قراءة_الشحن",
        "to add the shipping zones": "لإضافة مناطق الشحن",
        URL: "الرابط",
        "URL Type": "نوع الرابط",
        "Show Side Menu on Desktop": "اظهار القائمة الجانبية على الديسكتوب",
        "URL Created For": "تم إنشاء الكود لأجل",
        write_fulfillments: "كتابة_الإنجازات",
        write_inventory: "جرد الكتابة",
        write_orders: "كتابة_طلبات",
        write_products: "write_products",
        write_shipping: "الكتابة_الشحن",
        Install: "تثبيت",
        "Shipping and delivery": "الشحن والتسليم",
        "Use the carrier or app to calculate rates":
            "استخدم شركة الجوال أو التطبيق لحساب الأسعار",
        Slug: "وصف",
        id: "الرقم",
        Errors: "الأخطاء",
        error: "الخطأ",
        errors: "الأخطاء",
        name: "الأسم",
        "SMS IID": "SMS IID",
        "These are the Product most sold for the selected date duration": "أكثر المنتجات التى تم بيعها خلال الفترة المحددة",
        "These are the Products that user's added to their cart but didn't check out or complete the purchase": "المنتجات التى تم وضعها فى عربة الشراء و لكن المستخدم لم يتم عملية الشراء",
        "These are the Products bought frequently compared to the last period for the selected date duration": "منتجات يتم شراؤها بشكل متكرر مقارنة بالفترة الأخيرة لمدة التاريخ المحددة",
        "These are the Products most frequently bought by unique customers": "أكثر المنتجات التى  يشتريها عملاء مميزون بصورة متكررة",
        "Sorry there are no products for selected period": "عذرا لا توجد منتجات للفترة المحددة",
        "Main": "الرئيسية",
        "Header": "الهيدر",
        "Product Page": "صفحة المنتج",
        "Product card": "كارت المنتج",
        "Footer": "الفوتر",
        "Show Quick Purchase Pop up": "إظهار نافذة الشراء السريع",
        "Enable review order page": "اظهار صفحة مراجعة المنتجات قبل الدفع",
        "To be able to show quick purchase pop up you should use product card design 2": "لكى تتمكن من اظهار نافذة الشراء السريع يجب عليك اختيار تصميم كارت المنتج رقم 2",
        "View page": "عرض الصفحة",
        "Post Summary Arabic": "ملخص البوست عربى",
        "Post Summary English": "ملخص البوست انجليزى",
        "Set Background Image or Background Color only one of them": "قم بإضافة صورة للخلفية او لون للخلفية فقط واحد منهم",
        "Add Link": "أضف رابط",
        "Add new links": "أضف روابط جديدة",
        "fonts": "الخطوط",
        "English Fonts": "الخطوط الإنجليزى",
        "Arabic Fonts": "الخطوط العربية",
        "background": "الخلفية",
        "color": "لون",
        "links": "الروابط",
        "Links Style": "مظهر الروابط",
        "profile": "الحساب",
        "welcome": "مرحبا",
        "Create and Organize your social links below": "قم بإنشاء الروابط الخاصة بصفحاتك على السوشيال ميديا",
        "Promote your social media and track your social reach": "قم بالترويج للروابط الخاصة بك على السوشيال ميديا",
        "Add Custom Link": "أضف رابط مخصص",
        "Or choose from": "أو قم بالأختيار من",
        "Upload Link Image": "أضف صورة للرابط"
    }
};
