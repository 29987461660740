import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { SharedService } from './sharedService';

@Injectable({
  providedIn: 'root'
})
export class RedirectionsService {

  constructor(
    private apiService:ApiService,
    private sharedService: SharedService,
  ) { }

  getPageRedirection(limit = 10, offset = 0, search = "") {
    let params = new HttpParams();
    params = params
        .set("limit", limit.toString())
        .set("offset", offset.toString())
        .set("search", search);
    return this.apiService.get(
        this.sharedService.pageRedirection,
        params,
        undefined
    );
}

addPageRedirection(form) {
    return this.apiService.post(
        this.sharedService.pageRedirection,
        form,
        undefined
    );
}

deletePageRedirection(id){
  return this.apiService.delete(
    `${this.sharedService.pageRedirection}${id}`,
);
}

updatePageRedirection(form, id){
  return this.apiService.put(
    `${this.sharedService.pageRedirection}${id}/`,
    form
);
}

undoChanges(form) {
    return this.apiService.post(
        this.sharedService.undoChanges,
        form,
        undefined
    );
}
}
