<div
    fxLayout="row"
    fxFlex="0 0 auto"
    fxLayoutAlign="start start"
    fxLayoutGap="0px"
>
    <button
        (click)="export()"
        mat-stroked-button
        class="download-button-export mt-4"
    >
        {{ "My Downloads" | translate }}
    </button>
    <button
        [matMenuTriggerFor]="afterMenu"
        mat-stroked-button
        class="download-button-arrow mt-4"
    >
        <mat-icon class="mr-4" matPostfix>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #afterMenu="matMenu" xPosition="after">
        <button
            [disabled]="!listLength"
            [ngClass]="!listLength ? 'no-download' : ''"
            (click)="download()"
            mat-menu-item
        >
            <mat-icon style="font-family: 'Material Icons' !important"
                >file_download</mat-icon
            >
            {{ "Download" | translate }}
        </button>
        <!-- <button
            [disabled]="!listLength"
            [ngClass]="!listLength ? 'no-download' : ''"
            (click)="bulkPicklist()"
            mat-menu-item
        >
            <mat-icon style="font-family: 'Material Icons' !important"
                >file_download</mat-icon
            >
            {{ "Bulk Picklist" | translate }}
        </button> -->
    </mat-menu>
</div>
