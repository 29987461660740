import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { CampaignsService } from "app/services/marketing/campaigns.service";
import { UtilitiesService } from "app/services/utilitiesService";

@Component({
    selector: "app-campaign-preview",
    templateUrl: "./campaign-preview.component.html",
    styleUrls: ["./campaign-preview.component.scss"],
})
export class CampaignPreviewComponent implements OnInit {
    body: any = "";
    title: any = "";
    channel: any;
    form: FormGroup
    previewDetail: any
    public time: Date = new Date();
    separatorKeysCodes: number[] = [ENTER, COMMA];
    itemCtrl = new FormControl();
    items: any = [];
    constructor(
        private sanitizer: DomSanitizer,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<CampaignPreviewComponent>,
        private campaignsService: CampaignsService,
        private utilitiesService: UtilitiesService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.body = data.body;
        this.title = data.title;
        this.channel = data.channel;
    }

    ngOnInit(): void {
        this.formInit()
        this.getTemplatePreview()
    }

    formInit() {
        this.form = this.fb.group({
            template_id: '',
            recipient: ['', Validators.required],
            language: '',
            channel: this.data.channel,
        })
    }

    getTemplatePreview() {
        this.campaignsService.getTemplatePreview(this.data.templateForm).subscribe(res => {
            this.previewDetail = res
        })
    }

    getBody() {
        return this.sanitizer.bypassSecurityTrustHtml(this.previewDetail?.body);
    }

    sendTestCampaign() {
        const controlValue = this.itemCtrl.value
        if (controlValue && !this.items.includes(controlValue)) {
            if (this.channel === 'EMAIL') {
                if (this.isValidEmail(controlValue)) {
                    this.items.push(controlValue);
                    this.itemCtrl.setValue(null);
                } else {
                    this.utilitiesService.showErrorToast('Invalid email format')
                    return true
                }
            } else {
                this.items.push(controlValue)
            }
        }
        this.form.controls.recipient.setValue(this.items)
        if (this.form.valid) {
            let form = this.form.value
            let formData = { ...this.data.templateForm, recipient: form.recipient }
            this.triggerTest(formData)
            this.dialogRef.close()
        } else {
            this.utilitiesService.showErrorToast('Form invalid')
        }
    }

    triggerTest(form) {
        this.campaignsService
            .sendTestCampaign(form)
            .subscribe(res => {
                this.utilitiesService.showSuccessToast('Sent successfully.')
            })
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = (event.value || "").trim();
        if (this.channel === 'EMAIL') {
            if (this.isValidEmail(value)) {
                this.items.push(value);
            } else {
                this.utilitiesService.showErrorToast('Invalid email format')
            }
        } else {
            this.items.push(value)
        }
        if (input) {
            input.value = "";
        }
        this.itemCtrl.setValue(null);
    }

    isValidEmail(email: string): boolean {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    }

    isValidPhoneNumber(phone: string) {
    }

    remove(item, indx): void {
        this.items.splice(indx, 1);
    }
}
