import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: "root",
})
export class PostsService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    getElements(type = "") {
        let params = new HttpParams();
        params = params.set("type", type);

        return this.apiService.get(
            this.sharedService.postElements,
            params,
            undefined
        );
    }

    getPosts(limit = 10, offset = 0, search = "", form?) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        return this.apiService.get(
            this.sharedService.postList,
            params,
            undefined
        );
    }

    addPost(form) {
        return this.apiService.post(
            this.sharedService.addPost,
            form,
            undefined
        );
    }

    deletePost(id) {
        return this.apiService.delete(
            this.sharedService.deletePost + `${id}/`,
            undefined
        );
    }
    getPostDetail(id) {
        return this.apiService.get(
            this.sharedService.postList + `${id}/`,
            undefined
        );
    }
    updatePost(form, id) {
        return this.apiService.put(
            this.sharedService.updatePost + `${id}/`,
            form,
            undefined
        );
    }
    deletePostItems(id) {
        return this.apiService.delete(
            this.sharedService.deletePostElements + `${id}/`,
            undefined
        );
    }
    getPostsCategory(limit = 10, offset = 0, search = "", form?) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        return this.apiService.get(
            this.sharedService.postCategoryList,
            params,
            undefined
        );
    }

    addCategory(form) {
        return this.apiService.post(
            this.sharedService.postCategoryList,
            form,
            undefined
        );
    }
    deleteCategory(id) {
        return this.apiService.delete(
            this.sharedService.postCategoryList + `${id}/`,
            undefined
        );
    }
    updateCategory(form, id) {
        return this.apiService.put(
            this.sharedService.postCategoryList + `${id}/`,
            form,
            undefined
        );
    }
    getCategoryDetail(id) {
        return this.apiService.get(
            this.sharedService.postCategoryList + `${id}/`,
            undefined
        );
    }
}
