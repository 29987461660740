<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" [formGroup]="navForm">
    <div *ngIf="id" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="8px">
        <button mat-flat-button (click)="handleCancel()" class="secondary-bg-color save-cancel-btn">{{'Close' |
            translate}}</button>
        <button (click)="updateAllSubMenus(true)" type="submit" mat-flat-button
            class="primary-bg-color save-cancel-btn">{{'Save' |
            translate}}</button>
    </div>
    <div class="fuse-card auto-width p-16" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
        fxLayoutGap="1em">
        <label class="highlight-text-color">{{'Basic Details' | translate}}</label>
        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
            fxLayoutGap="1em">
            <div fxFlex="1 1 auto">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>{{'Name (En)' |translate}}</mat-label>
                    <input formControlName="title" matInput name="title">
                    <mat-error *ngIf="navForm.get('title').hasError('required')">
                        {{'Name English is required' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="1 1 auto">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>{{'Name (Ar)' |translate}}</mat-label>
                    <input formControlName="title_ar" matInput name="title">
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="!id" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="8px">
            <button mat-flat-button (click)="handleCancel()" class="secondary-bg-color save-cancel-btn">{{'Cancel' |
                translate}}</button>
            <button (click)="save()" type="submit" mat-flat-button class="primary-bg-color save-cancel-btn">{{'Save' |
                translate}}</button>
        </div>
    </div>
    <div class="fuse-card auto-width p-16" *ngIf="id" fxFlex="1 1 100%" (cdkDropListDropped)="drop($event)" cdkDropList
        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <label class="highlight-text-color">{{'Menu Items' | translate}}</label>
        <form fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column-reverse" fxLayout.gt-sm="row"
            fxLayoutGap="1em" [formGroup]="navForm">
            <div class="dropzone p-16" fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="start stretch">
                <div fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="1em">
                    <app-fab-button (handleFabButtonClick)="addMenu()"></app-fab-button>
                </div>
                <div fxLayout="column" fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                    *ngFor="let navigationItemFormGroup of navForm.get('navigation_item')['controls'];let i = index">
                    <mat-accordion [formGroup]="navigationItemFormGroup" cdkDropList cdk-drag-preview cdkDrag
                        (cdkDragStarted)="addChild($event)" [cdkDragData]="navigationItemFormGroup" class="mb-16"
                        formArrayName="navigation_item">
                        <mat-expansion-panel [ngStyle]="{'margin-left' : getChildValue(i) ? '30px' : '0px'}"
                            [ngClass]="{'invalid' : !checkInvalid(i)}" [expanded]="expandIndex[i] == i">
                            <!-- [ngClass]="{'invalid' : !checkInvalid(i)}" -->
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="start center"
                                        fxLayoutGap="10px">
                                        <img (click)="$event.stopPropagation();addSubmenu(i)"
                                            *ngIf="i!=0 && !getChildValue(i) && navigationItemFormGroup.get('type').value!='PARENT'"
                                            style="height: 17px;" src="assets/images/indentin.svg">
                                        <img (click)="$event.stopPropagation();removeSubmenu(i)"
                                            *ngIf="i!=0 && getChildValue(i) && navigationItemFormGroup.get('type').value!='PARENT'"
                                            style="height: 17px;" src="assets/images/indentout.svg">
                                        <mat-icon>drag_indicator</mat-icon>
                                        <span>
                                            {{navigationItemFormGroup.get('title').value ?
                                            navigationItemFormGroup.get('title').value : "Navigation Item #" + i
                                            }}</span>
                                        <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="end end"
                                            fxLayoutGap="10px">
                                            <mat-slide-toggle (click)="$event.stopPropagation();" fxFlex="0 1 auto"
                                                [formControl]="navigationItemFormGroup.get('enable')">
                                                <span *ngIf="navigationItemFormGroup.get('enable').value" fxHide
                                                    fxShow.gt-md>{{'Disable' | translate}}</span>
                                                <span *ngIf="!navigationItemFormGroup.get('enable').value" fxHide
                                                    fxShow.gt-md>{{'Enable' | translate}}</span>
                                            </mat-slide-toggle>
                                        </div>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div (mousedown)="$event.stopPropagation()" (mousemove)="$event.stopPropagation()"
                                fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                <!-- <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="space-between stretch"
                                    fxLayoutGap="5px">
                                    <mat-slide-toggle fxFlex="0 1 auto"
                                        [formControl]="navigationItemFormGroup.get('enable')">
                                        {{'Enable' | translate}}</mat-slide-toggle>
                                    <div></div>
                                </div> -->
                                <div fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="start stretch"
                                    fxLayoutGap="5px">
                                    <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto"
                                        fxLayoutAlign="start stretch" fxLayoutGap="1em">
                                        <div fxFlex="1 1 auto">
                                            <mat-form-field appearance="outline" fxFlex="100">
                                                <mat-label>
                                                    {{'Name (En)' | translate}}</mat-label>
                                                <input id="title{{i}}"
                                                    [formControl]="navigationItemFormGroup.get('title')" matInput
                                                    name="title">
                                                <mat-error
                                                    *ngIf="navigationItemFormGroup.get('title').hasError('required')">
                                                    {{'Name is required' | translate}}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex="1 1 auto">
                                            <mat-form-field appearance="outline" fxFlex="100">
                                                <mat-label>
                                                    {{'Name (Ar)' | translate}}</mat-label>
                                                <input [formControl]="navigationItemFormGroup.get('title_ar')" matInput
                                                    name="title">
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex="1 1 auto">
                                            <mat-form-field appearance="outline" fxFlex="100">
                                                <mat-label class="text-capitalized">
                                                    {{'Visibility'| translate}}
                                                </mat-label>
                                                <mat-select [formControl]="navigationItemFormGroup.get('visibility')">
                                                    <mat-option *ngFor="let visibility of visibilityEnum"
                                                        [value]="visibility?.id">{{visibility?.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div fxLayout="column" fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px">
                                        <label class="icon-type-label">{{'Icon Type' | translate}}</label>
                                        <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                            fxLayoutGap="0px">
                                            <button (click)="navigationItemFormGroup.get('icon_type').setValue(1)"
                                                [ngClass]="navigationItemFormGroup.get('icon_type').value==1 ? 'active':''"
                                                class="icon-type-text-btn" mat-stroked-button>
                                                <mat-icon matPrefix>text_fields</mat-icon>
                                                {{'Ion Icon' | translate}}
                                            </button>
                                            <button (click)="navigationItemFormGroup.get('icon_type').setValue(2)"
                                                [ngClass]="navigationItemFormGroup.get('icon_type').value==2 ? 'active':''"
                                                class="icon-type-image-btn" mat-stroked-button>
                                                <mat-icon matPrefix>image</mat-icon>
                                                {{'Image' | translate}}
                                            </button>
                                            <!-- <div class="toggle">
                                                <input  type="radio"
                                                    [formControl]="navigationItemFormGroup.get('icon_type')" [value]="1"
                                                    id="default{{i}}" />
                                                <label fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="start center"
                                                    fxLayoutGap="3px" for="default{{i}}">
                                                    <mat-icon>text_fields</mat-icon>
                                                    <span> {{'Text' | translate}}</span>
                                                </label>
                                                <input type="radio"
                                                    [formControl]="navigationItemFormGroup.get('icon_type')" [value]="2"
                                                    id="custom{{i}}" />
                                                <label fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="start center"
                                                    fxLayoutGap="3px" for="custom{{i}}">
                                                    <mat-icon>image</mat-icon>
                                                    <span> {{'Image' | translate}}</span>
                                                </label>
                                            </div> -->
                                        </div>
                                        <div *ngIf="navigationItemFormGroup.get('icon_type').value==1" fxLayout="row"
                                            fxFlex="0 0 auto" fxLayoutAlign="start baseline" fxLayoutGap="5px">
                                            <div fxLayout="row" fxFlex="1 1 auto" fxLayoutAlign="start baseline"
                                                fxLayoutGap="5px">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label> {{'Icon En' | translate}}</mat-label>
                                                    <input [formControl]="navigationItemFormGroup.get('icon')" matInput
                                                        name="title">
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row" fxFlex="1 1 auto" fxLayoutAlign="start baseline"
                                                fxLayoutGap="5px">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label> {{'Icon Ar' | translate}}</mat-label>
                                                    <input [formControl]="navigationItemFormGroup.get('icon_ar')"
                                                        matInput name="title">
                                                </mat-form-field>
                                                <mat-icon [matMenuTriggerFor]="afterMenu" title="info"
                                                    class="highlight-text-color cursor-pointer info-icon"
                                                    fxLayoutAlign="end end" fxLayout="row">info
                                                </mat-icon>
                                                <mat-menu class="add-icon-popover"
                                                    style="min-width: none !important;width: auto !important;"
                                                    #afterMenu="matMenu" xPosition="after">
                                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" class="p-8"
                                                        fxLayout="column" fxLayoutGap="1em">
                                                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" class="p-8"
                                                            fxLayout="column" fxLayoutGap="5px">
                                                            <strong>{{'Step 1 ' | translate}}</strong>
                                                            <div class="border-bottom"></div>
                                                            <div class="mt-8 ml-8">
                                                                <a href="https://ionic.io/ionicons"
                                                                    target="_blank">{{'Click here to get all Ionic
                                                                    Icons' | translate}}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" class="p-8"
                                                            fxLayout="column" fxLayoutGap="5px">
                                                            <strong>{{'Step 2 ' | translate}}</strong>
                                                            <div class="border-bottom"></div>
                                                            <div>
                                                                <img src="assets/images/scrumboard/addBtn-info.png"
                                                                    style="height: 300px;">
                                                            </div>
                                                        </div>
                                                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" class="p-8"
                                                            fxLayout="column" fxLayoutGap="5px">
                                                            <strong>{{'Step 3 ' | translate}}</strong>
                                                            <div class="border-bottom"></div>
                                                            <div class="mt-8 ml-8">
                                                                <span>{{'Paste the name of the icon in the icon text
                                                                    box' | translate}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </mat-menu>
                                            </div>
                                        </div>
                                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                            fxLayout.gt-sm="row" fxLayoutGap="5px"
                                            *ngIf="navigationItemFormGroup.get('icon_type').value==2">
                                            <div fxFlex.gt-sm="0 0 50%" fxFlex.lt-md="1 1 auto"
                                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                                                <label class="icon-type-label">{{'Icon En' | translate}}</label>
                                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                                    fxLayoutGap="1em">
                                                    <div *ngIf="!navigationItemFormGroup.get('image_icon').value"
                                                        fxLayout="column" fxFlex="0 0 auto"
                                                        fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                                        <app-cms-upload-media
                                                            (afterUpload)="setFileToForm($event,i,'en')"
                                                            [isMultiSelect]="false"
                                                            [formcontrol]="navigationItemFormGroup.get('image_icon')"
                                                            [index]="i">
                                                        </app-cms-upload-media>
                                                        <!-- <input accept="image/x-png,image/gif,image/jpeg"
                                                            class="attach-input" type="file" id="fileDropRef"
                                                            (change)="fileBrowseHandlerTumbnail($event.target.files,i,'en')" />
                                                        <label class="attach-label attach-label-file"
                                                            for="fileDropRef">{{'Browse for file' | translate}}</label>
                                                        <label>{{'Or' | translate}}</label>
                                                        <label
                                                            (click)="openGallery(navigationItemFormGroup.get('image_icon'),false)"
                                                            class="attach-label">{{'Browse from media' |
                                                            translate}}</label> -->
                                                    </div>
                                                    <div class="files-list cursor-pointer" fxFlex="0 0 auto"
                                                        fxLayoutAlign="space-between stretch" fxLayout="row"
                                                        *ngIf="navigationItemFormGroup.get('image_icon').value">
                                                        <app-show-all-type-media
                                                            [url]="navigationItemFormGroup.get('image_icon').value">
                                                        </app-show-all-type-media>
                                                        <!-- <img class="thumbnail-img p-16" style="margin: auto !important;"
                                                            [src]="utilitiesService.bypassTrustedURL(navigationItemFormGroup.get('image_icon').value)"> -->
                                                        <mat-icon fxFlex="0 0 auto" fxLayoutAlign="end end"
                                                            fxLayoutGap="5px" fxLayout="row"
                                                            (click)="deleteImage(i,'en')"
                                                            *ngIf="navigationItemFormGroup.get('image_icon').value"
                                                            class="action-btn-delete cursor-pointer">close
                                                        </mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div fxFlex.gt-sm="0 0 50%" fxFlex.lt-md="1 1 auto"
                                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                                                <label class="icon-type-label">{{'Icon Ar' | translate}}</label>
                                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                                    fxLayoutGap="1em">
                                                    <div *ngIf="!navigationItemFormGroup.get('image_icon_ar').value"
                                                        fxLayout="column" fxFlex="0 0 auto"
                                                        fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                                        <app-cms-upload-media
                                                            (afterUpload)="setFileToForm($event,i,'ar')"
                                                            [isMultiSelect]="false"
                                                            [formcontrol]="navigationItemFormGroup.get('image_icon_ar')"
                                                            [index]="i">
                                                        </app-cms-upload-media>
                                                        <!-- <input accept="image/x-png,image/gif,image/jpeg"
                                                            class="attach-input" type="file" id="fileDropRef"
                                                            (change)="fileBrowseHandlerTumbnail($event.target.files,i,'ar')" />
                                                        <label class="attach-label attach-label-file"
                                                            for="fileDropRef">{{'Browse for file' | translate}}</label>
                                                        <label>{{'Or' | translate}}</label>
                                                        <label
                                                            (click)="openGallery(navigationItemFormGroup.get('image_icon_ar'),false)"
                                                            class="attach-label">{{'Browse from media' |
                                                            translate}}</label> -->
                                                    </div>
                                                    <div class="files-list cursor-pointer" fxFlex="0 0 auto"
                                                        fxLayoutAlign="space-between stretch" fxLayout="row"
                                                        *ngIf="navigationItemFormGroup.get('image_icon_ar').value">
                                                        <app-show-all-type-media
                                                            [url]="navigationItemFormGroup.get('image_icon_ar').value">
                                                        </app-show-all-type-media>
                                                        <!-- <img class="thumbnail-img p-16" style="margin: auto !important;"
                                                            [src]="utilitiesService.bypassTrustedURL(navigationItemFormGroup.get('image_icon_ar').value)"> -->
                                                        <mat-icon fxFlex="0 0 auto" fxLayoutAlign="end end"
                                                            fxLayoutGap="5px" fxLayout="row"
                                                            (click)="deleteImage(i,'ar')"
                                                            *ngIf="navigationItemFormGroup.get('image_icon_ar').value"
                                                            class="action-btn-delete cursor-pointer">close
                                                        </mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto"
                                        fxLayoutAlign="start stretch" fxLayoutGap="1em">
                                        <div fxFlex="1 1 auto">
                                            <!-- <div style="display: none;">
                                                {{typeChanged(i,navigationItemFormGroup.get('type').value)}}
                                            </div> -->
                                            <mat-form-field appearance="outline" fxFlex="100">
                                                <mat-label class="text-capitalized">
                                                    {{'Type'| translate}}
                                                </mat-label>
                                                <mat-select
                                                    (selectionChange)="typeChanged(i,navigationItemFormGroup.get('type').value)"
                                                    [formControl]="navigationItemFormGroup.get('type')">
                                                    <mat-option [value]="'PAGE'">
                                                        {{'Page' | translate}}
                                                    </mat-option>
                                                    <mat-option [value]="'CUSTOM'">
                                                        {{'Custom' | translate}}
                                                    </mat-option>
                                                    <mat-option [value]="'EXTERNAL'">
                                                        {{'External' | translate}}
                                                    </mat-option>
                                                    <mat-option *ngIf="!navigationItemFormGroup.get('parent').value"
                                                        [value]="'PARENT'">
                                                        {{'Parent' | translate}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <!-- <div fxFlex="1 1 auto">
                                            <mat-form-field appearance="outline" fxFlex="100">
                                                <mat-label> {{'Icon' | translate}}</mat-label>
                                                <input [formControl]="navigationItemFormGroup.get('icon')" matInput
                                                    name="title">
                                            </mat-form-field>
                                        </div> -->
                                    </div>

                                    <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto"
                                        fxLayoutAlign="start stretch" fxLayoutGap="1em">

                                        <mat-form-field appearance="outline" fxFlex="100"
                                            *ngIf="(navigationItemFormGroup.get('type').value=='CUSTOM' || navigationItemFormGroup.get('type').value=='EXTERNAL')">
                                            <mat-label>
                                                {{'Value' | translate}}</mat-label>
                                            <textarea rows=" 3" [formControl]="navigationItemFormGroup.get('value')"
                                                matInput name="title"></textarea>
                                            <mat-error
                                                *ngIf="navigationItemFormGroup.get('value').hasError('required')">
                                                {{'Value is required' | translate}}
                                            </mat-error>
                                            <mat-error
                                                *ngIf="navigationItemFormGroup.get('value').hasError('startWithHttp') && navigationItemFormGroup.get('type').value=='EXTERNAL'">
                                                {{'Value Start with HTTP' | translate}}
                                            </mat-error>
                                            <mat-error
                                                *ngIf="navigationItemFormGroup.get('value').hasError('containHTTP') && navigationItemFormGroup.get('type').value=='CUSTOM'">
                                                {{'Value can not start with HTTP' | translate}}
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" fxFlex="100"
                                            *ngIf="navigationItemFormGroup.get('type').value=='PAGE'">
                                            <mat-label class="text-capitalized">
                                                {{'Page'| translate}}
                                            </mat-label>
                                            <mat-select [formControl]="navigationItemFormGroup.get('page')">
                                                <mat-option *ngFor="let page of pages" [value]="page?.id">
                                                    {{page?.title}}</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="navigationItemFormGroup.get('page').hasError('required')">
                                                {{'Page is required' | translate}}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <mat-action-row fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="space-between center">
                                <app-delete-button (deleteButtonClick)="delete(i,'brand')"></app-delete-button>
                                <button class="highlight-text-color" mat-button
                                    (click)="saveOrUpdateNavItems(navigationItemFormGroup,i)">
                                    <mat-icon class="mb-4 highlight-text-color" matPrefix>save</mat-icon>
                                    {{'Save' | translate}}
                                </button>
                            </mat-action-row>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </form>
    </div>
</div>