<ng-container *ngFor="let name of productsSectionNames; let i = index">
    <div class="slider-container">
        <div
            class="slider-container__header"
            fxLayoutAlign="space-between start"
            fxLayout="column"
        >
            <h2>
                {{ name | textFormat | translate }}
            </h2>

            <p class="font-medium tracking-tight text-secondary">
                {{ tooltips[i] | translate }}
            </p>
        </div>

        <ng-container
            *ngIf="productsSectionValues[i].length > 0; else noProducts"
        >
            <owl-carousel-o
                [options]="customOptions"
                (change)="getCarouselData($event)"
            >
                <ng-template
                    carouselSlide
                    *ngFor="let slide of productsSectionValues[i]"
                    [id]="slide?.product_id"
                >
                    <div
                        class="slider-container__content fuse-card cursor-pointer"
                        fxFlex="1 1 100%"
                        fxLayout="column"
                        fxLayoutAlign="space-between start"
                        style="width: 270px"
                        [style.height]="
                            name === 'trending_products' ? '157px' : ''
                        "
                        (click)="openProduct(slide.product_id)"
                    >
                        <div
                            fxFlex="1 1 100%"
                            fxLayout="row"
                            fxLayoutAlign="space-between start"
                            style="width: 100%"
                        >
                            <div
                                class="slider-container__content-image"
                                fxFlex="1 1 50%"
                            >
                                <img
                                    src="{{
                                        slide?.image ? slide?.image : appLogo
                                    }}"
                                    alt=""
                                />
                            </div>
                            <div
                                class="slider-container__content-description"
                                fxLayout="column"
                                fxFlex="1 1 50%"
                            >
                                <h3>{{ slide?.product_name }}</h3>
                                <p
                                    class="slider-container__content-description-sku"
                                >
                                    <span>SKU:</span>
                                    <span>{{ slide?.sku }}</span>
                                </p>
                                <div
                                    class="slider-container__content-description-price"
                                >
                                    <ng-container
                                        *ngIf="
                                            name !== 'trending_products';
                                            else trendingProducts
                                        "
                                    >
                                        <sup class="sup-class">{{
                                            baseCurrency
                                        }}</sup>
                                        <span>{{
                                            slide?.base_unit_price
                                                | number : "1.2"
                                        }}</span>
                                    </ng-container>

                                    <ng-template #trendingProducts>
                                        <div
                                            class="trending-price"
                                            fxLayoutAlign="center start"
                                            fxLayout="column"
                                        >
                                            <div>
                                                <sup class="sup-class">{{
                                                    baseCurrency
                                                }}</sup>
                                                <span>{{
                                                    slide?.base_unit_price
                                                        | number : "1.2"
                                                }}</span>
                                            </div>
                                            <p>
                                                <span
                                                    class="material-icons no-border red-arrow"
                                                    *ngIf="slide?.trend < 0"
                                                >
                                                    arrow_downward
                                                </span>
                                                <span
                                                    class="material-icons no-border green-arrow"
                                                    *ngIf="slide?.trend > 0"
                                                >
                                                    arrow_upward
                                                </span>

                                                <span
                                                    [ngStyle]="{
                                                        color:
                                                            slide?.trend < 0
                                                                ? 'red'
                                                                : 'green'
                                                    }"
                                                >
                                                    {{
                                                        slide?.trend
                                                            | number : "1.2"
                                                    }}%
                                                </span>
                                            </p>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="name !== 'trending_products'">
                            <div
                                class="slider-container__content-amount mt-12"
                                fxLayout="row"
                                fxFlex="1 1 100%"
                                fxLayoutAlign="space-between start"
                            >
                                <div class="total" fxFlex="1 1 50%">
                                    <h4>
                                        {{
                                            (name === "most_abandoned_products"
                                                ? "Added To Cart"
                                                : "Total"
                                            ) | translate
                                        }}
                                    </h4>
                                    <p>
                                        <ng-container
                                            *ngIf="
                                                name !==
                                                    'most_abandoned_products';
                                                else abonendProducts
                                            "
                                        >
                                            {{
                                                slide?.total_revenue
                                                    | number : "1.0"
                                            }}
                                            <sup class="sup-class">{{
                                                baseCurrency
                                            }}</sup>
                                        </ng-container>

                                        <ng-template #abonendProducts>
                                            {{
                                                slide?.abandoned
                                                    | number : "1.0"
                                            }}
                                        </ng-template>
                                    </p>
                                </div>
                                <div class="sold" fxFlex="1 1 48%">
                                    <h4>{{ "Sold" | translate }}</h4>
                                    <p>
                                        {{ slide?.total_sold | number : "1.0" }}
                                    </p>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </ng-container>
    </div>
</ng-container>

<ng-template #noProducts>
    <p>
        {{ "Sorry there are no products for selected period" | translate }}
    </p>
</ng-template>
