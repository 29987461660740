import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import {
    CompactType,
    GridsterConfig,
    GridsterItem,
    GridsterItemComponent,
    GridsterPush,
    GridType
} from 'angular-gridster2';
import { AppService } from "app/services/app.service";
import { TranslationService } from 'app/services/translationService';

@Component({
    selector: 'app-dashboard-statistics-card',
    templateUrl: './dashboard-statistics-card.component.html',
    styleUrls: ['./dashboard-statistics-card.component.scss'],
})
export class DashboardStatisticsCardComponent implements OnInit {
    options: GridsterConfig;
    dashboard: GridsterItem[] = [
        { cols: 2, rows: 2, y: 0, x: 0, resizeEnabled: false },
        { cols: 2, rows: 2, y: 0, x: 2, resizeEnabled: false },
        { cols: 2, rows: 2, y: 0, x: 4, resizeEnabled: false }
    ];

    _statics;
    settingsData;
    isDataLoaded = false;
    direction = 'ltr';

    @Input() set statics(value) {
        this._statics = value;
    };

    get statics() {
        return this._statics;
    }

    constructor(
        private appService: AppService,
        private router: Router,
        private translateService: TranslationService
    ) { }

    ngOnInit(): void {

        this.appService.getDashBoardSettings().subscribe(data => {
            this.settingsData = data;
            this.initializeDragGrid();
            this.isDataLoaded = true;
        })
    }

    ngOnChanges(changes: SimpleChange) {
        this.translateService._languageBehaviorSubject$.subscribe(data => {
            this.direction = data.lang === 'en' ? 'ltr' : 'rtl';
        })
    }

    initializeDragGrid() {
        this.options = {
            gridType: GridType.Fit,
            compactType: CompactType.None,
            displayGrid: "none",

            pushItems: false,
            draggable: {
                enabled: true
            },
            resizable: {
                enabled: false
            },
            minItemCols: 2,
            minItemRows: 2,
            setGridSize: true,
            maxCols: 6,
            maxRows: 2,
            disableScrollVertical: true,
            disableScrollHorizontal: true,
            outerMarginRight: 0,
            itemChangeCallback: () => { this.saveGrid() }

        };

        this.dashboard = this.settingsData?.static_grid ? JSON.parse(this.settingsData?.static_grid) : this.dashboard;

    }

    saveGrid() {
        this.appService.setDashBoardSettings({ static_grid: JSON.stringify(this.dashboard) }).subscribe()
    }

    navigateTo(page, type, value) {
        switch (page) {
            case 'orders':
                this.router.navigate([`/${page}`], {
                    queryParams: {
                        page: 'dashboard'
                    }
                });
                break;
            case 'cart':
                this.router.navigate([`/${page}`], {
                    queryParams: {
                        type: type,
                        value: value,
                        page: 'dashboard'
                    }
                });
                break;
            case 'customer-list':
                this.router.navigate([`/${page}`], {
                    queryParams: {
                        type: type,
                        value: value,
                        page: 'dashboard'
                    }
                });
                break;
        }

    }

}
