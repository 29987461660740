import {
    Component,
    ElementRef,
    Inject,
    Input,
    OnInit,
    ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MediaTypes } from "app/constants/media-type";
import { MediaService } from "app/services/media.service";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";

function isAllSelected(element) {
    return !!element;
}

@Component({
    selector: "app-gallery-popup",
    templateUrl: "./gallery-popup.component.html",
    styleUrls: ["./gallery-popup.component.scss"],
})
export class GalleryPopupComponent implements OnInit {
    isMultiSelect: boolean = true;
    @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
    formControl: FormControl;
    mediaList = [];
    mediaForm: FormGroup;
    mediaTypes = MediaTypes;
    files = [];
    checkbox = [];
    clicked = [];
    loading: boolean = true;
    isCopy: boolean = false;
    showOnly: any;

    constructor(
        public dialogRef: MatDialogRef<GalleryPopupComponent>,
        private formBuilder: FormBuilder,
        private mediaService: MediaService,
        public utilitiesService: UtilitiesService,
        private fb: FormBuilder,

        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.isCopy = data.isCopy ? data.isCopy : false;
        this.formControl = data.formControl;
        this.isMultiSelect = data.isMultiSelect;
        this.showOnly = data.showOnly || this.mediaTypes.Image;
    }

    async ngOnInit() {
        await this.formInitialize();
        this.getMedia();
    }

    async formInitialize() {
        this.mediaForm = this.fb.group({
            id: [null],
            file: [null],
            altext: [null],
        });
    }

    deleteFile() {
        this.mediaForm.controls.file.setValue(null);
    }
    getTypeFromURL(url: string) {
        let type = null;
        if (url) {
            type = url.substring(url.lastIndexOf("."));
            type = type.split(".").join("");
            return this.checkFormat(type);
        } else {
            return {
                type: "Unknown",
                color: "#ef8888",
                format: "unknown",
            };
        }
    }

    checkFormat(type: string) {
        if (this.utilitiesService.isAudio(type).type) {
            return this.utilitiesService.isAudio(type);
        } else if (this.utilitiesService.isVideo(type).type) {
            return this.utilitiesService.isVideo(type);
        } else if (this.utilitiesService.isPDF(type).type) {
            return this.utilitiesService.isPDF(type);
        } else if (this.utilitiesService.isExcel(type).type) {
            return this.utilitiesService.isExcel(type);
        } else if (this.utilitiesService.isDoc(type).type) {
            return this.utilitiesService.isDoc(type);
        } else if (this.utilitiesService.isImage(type).type) {
            return this.utilitiesService.isImage(type);
        } else if (this.utilitiesService.isText(type).type) {
            return this.utilitiesService.isText(type);
        } else {
            return {
                type: "Unknown",
                color: "#ef8888",
                format: "unknown",
            };
        }
    }
    async getMedia() {
        try {
            this.loading = true;
            const mediaList = await this.mediaService
                .getMediaList(99999, 0)
                .toPromise();
            if (mediaList) {
                this.mediaList = mediaList.results;
                if (!this.formControl.value) {
                    this.mediaList.forEach((items, index) => {
                        this.checkbox[items.id] = false;
                        this.clicked[items.id] = false;
                        if (items.file) {
                            items["docType"] = this.getTypeFromURL(
                                items.file
                            ).type;
                            items["docColor"] = this.getTypeFromURL(
                                items.file
                            ).color;
                            items["docFormat"] = this.getTypeFromURL(
                                items.file
                            ).format;
                        }
                    });
                } else {
                    let selectedImages = this.formControl.value;
                    this.mediaList.forEach((items, index) => {
                        selectedImages.forEach((data) => {
                            if (items.id == data.id) {
                                this.checkbox[items.id] = true;
                                this.clicked[items.id] = true;
                            }
                        });
                        if (items.file) {
                            items["docType"] = this.getTypeFromURL(
                                items.file
                            ).type;
                            items["docColor"] = this.getTypeFromURL(
                                items.file
                            ).color;
                            items["docFormat"] = this.getTypeFromURL(
                                items.file
                            ).format;
                        }
                    });
                }
            }
        } catch {
        } finally {
            this.loading = false;
        }
    }

    checkboxChange(checked, id) {
        if (this.isMultiSelect) {
            this.clicked[id] = !this.clicked[id];
            if (checked) {
                this.checkbox[id] = true;
                this.setFormControl();
            } else {
                this.checkbox[id] = false;
                this.setFormControl();
            }
        } else {
            this.checkbox.forEach((data, index) => {
                this.checkbox[index] = false;
                if (checked) {
                    if (id == index) {
                        this.checkbox[index] = true;
                        this.setOneValueToControl();
                    } else {
                        this.checkbox[index] = false;
                        this.setOneValueToControl();
                    }
                } else {
                    this.checkbox[index] = false;
                    this.formControl.setValue(null);
                }
            });
        }
    }
    setOneValueToControl() {
        this.checkbox.forEach((value, key) => {
            if (value) {
                let media = this.mediaList.filter(
                    (element) => element.id == key
                );
                let file = media ? media[0].file : "";
                this.formControl.setValue(file);
            }
        });
    }
    setFormControl() {
        let checkbox = [];
        this.checkbox.forEach((value, key) => {
            if (value) {
                let media = this.mediaList.filter(
                    (element) => element.id == key
                );
                let file = media ? media[0].file : "";
                checkbox.push({
                    id: key,
                    file: file,
                });
                this.formControl.setValue(checkbox);
            } else {
                checkbox.forEach((items, index) => {
                    if (items == key) {
                        checkbox.splice(index, 1);
                    }
                });
                this.formControl.setValue(checkbox);
            }
        });
    }

    trimMediaName(file, index) {
        if (file) {
            const fileNameIndex = file.lastIndexOf("/")
                ? file.lastIndexOf("/")
                : "";
            if (fileNameIndex) {
                const finalName = file.substr(fileNameIndex + 1);
                return finalName;
            } else {
                return `Media ${index}`;
            }
        }
    }
    add() {
        this.utilitiesService.selectedImage.next(this.formControl.value)
        this.dialogRef.close(true);
    }

    copyImage(file) {
        this.dialogRef.close(file);
    }
}
