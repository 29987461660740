import { Injectable } from "@angular/core";
import { SharedService } from "../sharedService";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "../api.service";
@Injectable({
  providedIn: "root",
})
export class CatalogueService {
  apiDomain: any;
  constructor(private sharedService: SharedService, private http: HttpClient, private apiService: ApiService) {
    this.apiDomain = this.sharedService.apiDomain;
  }

  changeReviewStatus(id, form) {
    return this.apiService.patch(this.sharedService.customerReviews + `${id}/`, form);
  }

  getCustomerReviews(limit: number = 10, offset: number = 0, search: any = "", form: any = null) {
    let params = new HttpParams();
    params = params.set("limit", limit.toString()).set("offset", offset.toString()).set("search", search);
    if (form) {
      for (const key of Object.keys(form)) {
        params = params.set(`${key}`, `${form[key].value ? form[key].value : ""}`);
      }
    }
    return this.apiService.get(this.sharedService.customerReviews, params, undefined);
  }

  getProducts(limit: number = 10, offset: number = 0, search: any = "", form: any = null, isDownalod = false) {
    let params = new HttpParams();
    params = params.set("limit", limit.toString()).set("offset", offset.toString()).set("search", search);
    if (form) {
      for (const key of Object.keys(form)) {
        params = params.set(`${key}`, `${form[key].value ? form[key].value : ""}`);
      }
    }
    if (isDownalod) {
      params = params.set("download", "true");
    }
    console.log(this.sharedService.products, params, undefined);
    return this.apiService.get(this.sharedService.products, params, undefined);
  }

  getCategories(limit: number = 10, offset: number = 0, search: any = "", form: any = null) {
    let params = new HttpParams();
    params = params.set("limit", limit.toString()).set("offset", offset.toString()).set("search", search);
    if (form) {
      for (const key of Object.keys(form)) {
        params = params.set(`${key}`, `${form[key].value ? form[key].value : ""}`);
      }
    }
    return this.apiService.get(this.sharedService.sliderCategory, params, undefined);
  }

  getBrands(limit: number = 10, offset: number = 0, search: any = "", form: any = null) {
    let params = new HttpParams();
    params = params.set("limit", limit.toString()).set("offset", offset.toString()).set("search", search);
    if (form) {
      for (const key of Object.keys(form)) {
        params = params.set(`${key}`, `${form[key].value ? form[key].value : ""}`);
      }
    }
    return this.apiService.get(this.sharedService.sliderBrand, params, undefined);
  }

  getAttributes(limit: number = 10, offset: number = 0, search: any = "", form: any = null) {
    let params = new HttpParams();
    params = params.set("limit", limit.toString()).set("offset", offset.toString()).set("search", search);
    if (form) {
      for (const key of Object.keys(form)) {
        params = params.set(`${key}`, `${form[key].value ? form[key].value : ""}`);
      }
    }
    return this.apiService.get(this.sharedService.attribute, params, undefined);
  }

  saveAttribute(form) {
    return this.apiService.post(this.sharedService.attribute, form);
  }

  updateAttribute(form, id) {
    return this.apiService.put(this.sharedService.attribute + `${id}/`, form);
  }

  getAttributeById(id) {
    return this.apiService.get(this.sharedService.attribute + `${id}/`, undefined);
  }

  deleteAttribute(id) {
    return this.apiService.delete(this.sharedService.attribute + `${id}/`);
  }

  saveProduct(form) {
    return this.apiService.post(this.sharedService.products, form);
  }
  deleteProduct(id) {
    return this.apiService.delete(this.sharedService.products + `${id}/`);
  }

  updateProduct(form, id) {
    return this.apiService.put(this.sharedService.products + `${id}/`, form);
  }
  bulkUploadImage(form) {
    return this.apiService.post(this.sharedService.productUploadImage, form,{"x-api-key": "cbe806aa-e93a-4a7e-8484-c0cac8e69cad"},true,false,true);
  }

  getProductByID(id) {
    return this.apiService.get(this.sharedService.products + `${id}/`, undefined);
  }

  saveCategory(form) {
    return this.apiService.post(this.sharedService.sliderCategory, form);
  }

  updateCategory(form, id) {
    return this.apiService.put(this.sharedService.sliderCategory + `${id}/`, form);
  }

  deleteCategory(id) {
    return this.apiService.delete(this.sharedService.sliderCategory + `${id}/`);
  }

  deleteBrand(id) {
    return this.apiService.delete(this.sharedService.sliderBrand + `${id}/`);
  }

  getCategoryById(id) {
    return this.apiService.get(this.sharedService.sliderCategory + `${id}/`, undefined);
  }

  saveBrand(form) {
    return this.apiService.post(this.sharedService.sliderBrand, form);
  }

  updateBrand(form, id) {
    return this.apiService.put(this.sharedService.sliderBrand + `${id}/`, form);
  }

  getBrandById(id) {
    return this.apiService.get(this.sharedService.sliderBrand + `${id}/`, undefined);
  }

  addTag(form) {
    return this.apiService.post(this.sharedService.tags, form);
  }
  addOrigin(form) {
    return this.apiService.post(this.sharedService.origins, form);
  }
  updateTag(form, id) {
    return this.apiService.put(this.sharedService.tags + `${id}/`, form);
  }
  updateOrigin(form, id) {
    return this.apiService.put(this.sharedService.origins + `${id}/`, form);
  }
  updateHandling_instructions(form, id) {
    return this.apiService.put(this.sharedService.handlingInstructions + `${id}/`, form);
  }
  deleteTag(id) {
    return this.apiService.delete(this.sharedService.tags + `${id}/`);
  }
  getTags(limit: number = 10, offset: number = 0, search: any = "", form: any = null) {
    let params = new HttpParams();
    params = params.set("limit", limit.toString()).set("offset", offset.toString()).set("search", search);
    if (form) {
      for (const key of Object.keys(form)) {
        params = params.set(`${key}`, `${form[key].value ? form[key].value : ""}`);
      }
    }
    return this.apiService.get(this.sharedService.tags, params, undefined);
  }
  deleteStickers(id) {
    return this.apiService.delete(this.sharedService.sticker + `${id}/`);
  }
  unbindProductsStickers(id) {
    return this.apiService.post(this.sharedService.sticker + `${id}/` + this.sharedService.unbindProducts);
  }
  getImportfromFacts() {
    return this.apiService.get(this.sharedService.ImportfromFacts, undefined);
  }
  getStickers(limit: number = 10, offset: number = 0, search: any = "", form: any = null) {
    let params = new HttpParams();
    params = params.set("limit", limit.toString()).set("offset", offset.toString()).set("search", search);
    if (form) {
      for (const key of Object.keys(form)) {
        params = params.set(`${key}`, `${form[key].value ? form[key].value : ""}`);
      }
    }
    return this.apiService.get(this.sharedService.sticker, params, undefined);
  }
  addSticker(form) {
    return this.apiService.post(this.sharedService.sticker, form);
  }
  addHandling_instructions(form) {
    return this.apiService.post(this.sharedService.handlingInstructions, form);
  }
  getHandling_instructions(limit: number = 10, offset: number = 0, search: any = "", form: any = null) {
    return this.apiService.get(this.sharedService.handlingInstructions, form);
  }
  deleteHandling_instructions(id) {
    return this.apiService.delete(this.sharedService.handlingInstructions + `${id}/`);
  }
  getSyncJob(limit: number = 10, offset: number = 0, search, form) {
    let params = new HttpParams();
    params = params.set("limit", limit.toString()).set("offset", offset.toString()).set("search", search);
    if (form) {
      for (const key of Object.keys(form)) {
        params = params.set(`${key}`, `${form[key].value ? form[key].value : ""}`);
      }
    }

    return this.apiService.get(this.sharedService.syncJob, params, undefined);
  }
  getSyncJobByjobId(id) {
    return this.apiService.get(this.sharedService.syncJob + `${id}/execute/`, undefined);
  }
  getSyncConfig() {
    return this.apiService.get(this.sharedService.syncConfig, undefined);
  }
  clearCache() {
    return this.apiService.get(this.sharedService.clearCache, undefined);
  }
  uploadDryRun(form: FormData) {
    console.log(form);
    const token = localStorage.getItem("access_token");
    const headersObject = {
      Authorization: `Bearer ${token}`,
      "x-api-key": "cbe806aa-e93a-4a7e-8484-c0cac8e69cad",
    };
    return this.apiService.post(this.sharedService.dry_run, form, headersObject, true, false, true);
  }
}
