export enum FeaturesConstants {
    Shopify = "1",
    Membership = "2",
    Campaigns = "3",
    PriceRule = "4",
    DynamicFields = "5",
    Wordpress = "6",
    ServerAnalytics = "7",
    LoyaltyWallet = "8",
    Wallet = "9",
    Rating = "10"
}
