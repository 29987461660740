<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
            [formGroup]="generalForm">
            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back</mat-icon>
                    <label class="highlight-text-color">{{'General Settings' | translate}}</label>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <button (click)="save()" type="submit" mat-flat-button style="position: absolute;right: 16px;"
                        class="primary-bg-color">{{'Save' | translate}}</button>
                </div>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                fxLayoutGap="1em">
                <!-- <div fxFlex.lt-md="1 1 auto" fxFlex.gt-sm="0 0 50%" class="fuse-card auto-width p-16 "
                    fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                        <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="0px">
                            <label class="highlight-text-color">{{'Home Page Settings' | translate}}</label>
                        </div>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                <mat-slide-toggle formControlName="is_home_page">
                                    {{'Show Home Page' | translate}}
                                </mat-slide-toggle>
                                <small>{{'help_text_show_home_page' | translate}}</small>
                            </div>
                            <ng-container *ngIf="generalForm.get('is_home_page').value">
                                <mat-form-field appearance="outline" fxFlex="100%">
                                    <mat-label>{{'Page Type' | translate}}</mat-label>
                                    <mat-select formControlName="default_page">
                                        <mat-option [value]='1'>{{'Default' | translate}}</mat-option>
                                        <mat-option [value]='2'>{{'Page' | translate}}</mat-option>
                                        <mat-option [value]='3'>{{'Custom Page' | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="100%"
                                    *ngIf="generalForm.get('default_page').value == 2">
                                    <mat-label>{{'Home Page' | translate}}</mat-label>
                                    <mat-select formControlName="home_page">
                                        <mat-option value=''>{{'--Select Page--' | translate}}</mat-option>
                                        <mat-option *ngFor="let page of pages" [value]="page?.id">{{page?.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="100"
                                    *ngIf="generalForm.get('default_page').value == 3">
                                    <mat-label class="text-capitalized">
                                        {{'Custom Page'| translate}}
                                    </mat-label>
                                    <textarea formControlName="custom_home_page" matInput name="title"></textarea>
                                </mat-form-field>
                            </ng-container>
                        </div>
                    </div>
                </div> -->
                <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                    <div fxFlex="1 1 auto" class="fuse-card auto-width p-16 " fxLayoutAlign="start stretch"
                        fxLayout="column" fxLayoutGap="1em">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row"
                                fxLayoutGap="0px">
                                <label class="highlight-text-color">{{'Page Settings' | translate}}</label>
                            </div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Page Limit'| translate}}
                                    </mat-label>
                                    <input type="number" formControlName="page_size_limit" matInput name="title">
                                    <mat-error *ngIf="generalForm.get('page_size_limit').hasError('required')">
                                        {{'Page Limit is required' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="generalForm.get('page_size_limit').hasError('min')">
                                        {{'Page Limit should be greater than 4' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                [@animate]="{value:'*',params:{duration:'400ms',y:'70px'}}">
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                    fxLayoutGap="1em">
                    <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 " fxLayoutAlign="start stretch"
                        fxLayout="column" fxLayoutGap="1em">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row"
                                fxLayoutGap="0px">
                                <label class="highlight-text-color">{{'General Settings' | translate}}</label>
                            </div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                fxLayout.gt-sm="row" fxLayoutGap="1em">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Name (En)'| translate}}
                                    </mat-label>
                                    <input formControlName="title" matInput name="title">
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Name (Ar)'| translate}}
                                    </mat-label>
                                    <input formControlName="title_ar" matInput name="title">
                                </mat-form-field>
                            </div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                fxLayout.gt-sm="row" fxLayoutGap="1em">
                                <mat-form-field appearance="outline" fxFlex="100%">
                                    <mat-label>{{'Default Language' | translate}}</mat-label>
                                    <mat-select formControlName="language">
                                        <mat-option value="en">{{'English' | translate}}</mat-option>
                                        <mat-option value="ar">{{'Arabic' | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!-- <mat-form-field appearance="outline" fxFlex="100%">
                                    <mat-label>{{'Enable Product Page' | translate}}</mat-label>
                                    <mat-select formControlName="enable_product_page">
                                        <mat-option [value]="true">{{'Yes' | translate}}</mat-option>
                                        <mat-option [value]="false">{{'No' | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field> -->

                            </div>

                        </div>
                    </div>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                    <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 " fxLayoutAlign="start stretch"
                        fxLayout="column" fxLayoutGap="1em">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row"
                                fxLayoutGap="0px">
                                <label class="highlight-text-color">{{'Checkout Settings' | translate}}</label>
                            </div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <mat-form-field appearance="outline" fxFlex="100%">
                                    <mat-label>{{'Checkout Type' | translate}}</mat-label>
                                    <mat-select formControlName="checkout_type">
                                        <mat-option [value]="1">{{'Guest and Register' | translate}}
                                        </mat-option>
                                        <mat-option [value]="2">{{'Guest Only' | translate}}
                                        </mat-option>
                                        <mat-option [value]="3">{{'Register Only' | translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="1em">
                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="0px">
                                        <mat-slide-toggle formControlName="enable_coupon_code" class="">
                                            {{'Enable Coupon Code' | translate}}</mat-slide-toggle>
                                        <small>{{'help_text_enable_coupon_code' | translate}}</small>
                                    </div>

                                </div>
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
                                    <div fxFlex="1 1 50%" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="0px">
                                        <mat-slide-toggle fxFlex="0 1 60%" formControlName="show_email" class="">
                                            {{'Ask Email' | translate}}
                                        </mat-slide-toggle>
                                        <small>{{'help_text_ask_email' | translate}}</small>
                                    </div>
                                    <div fxFlex="1 1 50%" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="0px">
                                        <mat-slide-toggle fxFlex="0 1 auto"
                                            [disabled]="!generalForm.get('show_email').value"
                                            formControlName="is_email_mandatory" class="">
                                            {{'Is Email Mandatory' | translate}}</mat-slide-toggle>
                                        <small>{{'help_text_is_email_mandatory' | translate}}</small>
                                    </div>
                                </div>
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
                                    <div fxFlex="1 1 50%" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="0px">
                                        <mat-slide-toggle formControlName="location_map">
                                            {{'Enable Location Map' | translate}}
                                        </mat-slide-toggle>
                                        <small>{{'help_text_location_map' | translate}}</small>
                                    </div>
                                    <div fxFlex="1 1 50%" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="0px">
                                        <mat-slide-toggle formControlName="enable_social_media_login">
                                            {{'Enable Social Login' | translate}}
                                            <img src="assets/icons/social-media/google.svg" alt="" width="20"
                                                height="20" style="margin-left: 10px">
                                            <img src="assets/icons/social-media/apple.svg" alt="" width="20"
                                                height="20" style="margin-left: 10px">
                                        </mat-slide-toggle>
                                        <small>{{'help_text_social_login' | translate}}</small>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                    fxLayoutGap="1em">
                    <div fxFlex.lt-md="1 1 auto" fxFlex.gt-sm="0 0 50%" class="fuse-card auto-width p-16 "
                        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row"
                                fxLayoutGap="0px">
                                <label class="highlight-text-color">{{'Popup Settings' | translate}}</label>
                            </div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                    fxLayout.gt-sm="row" fxLayoutGap="1em">
                                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="0px">
                                        <mat-slide-toggle formControlName="popup">
                                            {{'Popup' | translate}}
                                        </mat-slide-toggle>
                                    </div>
                                </div>
                                <div *ngIf="generalForm.get('popup').value" fxFlex="0 0 auto"
                                    fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                                    fxLayoutGap="1em">
                                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="0px">
                                        <mat-slide-toggle formControlName="popup_modal">
                                            {{'Show as Modal' | translate}}
                                        </mat-slide-toggle>
                                    </div>
                                </div>
                                <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px"
                                    class="mb-0">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Popup Delay (in ms)'| translate}}
                                        </mat-label>
                                        <input formControlName="popup_delay" matInput name="title">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="0px">
                                    <mat-form-field appearance="outline" fxFlex="100%">
                                        <mat-label>{{'Popup Behaviour' | translate}}</mat-label>
                                        <mat-select formControlName="popup_show_type">
                                            <mat-option [value]="1">{{'First Visit' | translate}}
                                            </mat-option>
                                            <mat-option [value]="2">{{'All Visits' | translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div fxFlex="1 1 auto" class="fuse-card auto-width p-16 " fxLayoutAlign="start stretch"
                        fxLayout="column" fxLayoutGap="1em">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row"
                                fxLayoutGap="0px">
                                <label class="highlight-text-color">{{'Cart and Wishlist Settings' | translate}}</label>
                            </div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="0px">
                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{'Abandoned Cart Delay'| translate}}
                                            </mat-label>
                                            <input min="1" max="100" type="number"
                                                matTooltip="{{'help_text_Abandoned_cart_days' | translate}}"
                                                formControlName="abandoned_cart_days" matInput name="title">
                                            <strong class="suffix" matSuffix>{{'Days' | translate}}</strong>
                                            <mat-error *ngIf="generalForm.get('abandoned_cart_days').hasError('min')">
                                                {{'Abandoned Cart Delay should be greater than 0' | translate}}
                                            </mat-error>
                                            <mat-error *ngIf="generalForm.get('abandoned_cart_days').hasError('max')">
                                                {{'Abandoned Cart Delay should be less than 101' | translate}}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{'Pending Cart Delay'| translate}}
                                            </mat-label>
                                            <input min="1" max="100" type="number"
                                                matTooltip="{{'help_text_pending_cart_days' | translate}}"
                                                formControlName="pending_cart_days" matInput name="title">
                                            <strong class="suffix" matSuffix>{{'Days' | translate}}</strong>
                                            <mat-error *ngIf="generalForm.get('pending_cart_days').hasError('min')">
                                                {{'Pending Cart Delay should be greater than 0' | translate}}
                                            </mat-error>
                                            <mat-error *ngIf="generalForm.get('pending_cart_days').hasError('max')">
                                                {{'Pending Cart Delay should be less than 101' | translate}}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row"
                                        fxLayoutGap="1em">
                                        <mat-slide-toggle formControlName="enable_wishlist">
                                            {{'Enable Wishlist' | translate}}
                                        </mat-slide-toggle>
                                        <mat-slide-toggle formControlName="is_gift_enabled" class="">
                                            {{'Enable Gift' | translate}}
                                        </mat-slide-toggle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                    fxLayoutGap="1em">
                    <div fxFlex.lt-md="1 1 auto" fxFlex.gt-sm="0 0 50%" class="fuse-card auto-width p-16 "
                        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row"
                                fxLayoutGap="0px">
                                <label class="highlight-text-color">{{'NewsLetter Settings' | translate}}</label>
                            </div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                    fxLayout.gt-sm="row" fxLayoutGap="1em">
                                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="0px">
                                        <mat-slide-toggle formControlName="enable_subscribe_popover">
                                            {{'Popup' | translate}}
                                        </mat-slide-toggle>
                                    </div>
                                </div>
                                <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="0px">
                                    <mat-form-field appearance="outline" fxFlex="100%">
                                        <mat-label>{{'Popup Behaviour' | translate}}</mat-label>
                                        <mat-select formControlName="subscribe_popover_show_type">
                                            <mat-option [value]="1">{{'First Visit' | translate}}
                                            </mat-option>
                                            <mat-option [value]="2">{{'All Visits' | translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="1 1 50%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                    <mat-slide-toggle formControlName="enable_newsletter" class="">
                                        {{'Show subscribtion to NewsLetter' | translate}}
                                    </mat-slide-toggle>
                                    <small>{{'help_text_subscribtion_newsLetter' | translate}}</small>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>