<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
    <ngx-datatable [loadingIndicator]="isLoading" [externalPaging]="true" [count]="page.totalElements"
        [limit]="page.size" [offset]="page.pageNumber" [virtualization]="false" [scrollbarH]="true"
        [rows]="membershipList" [cssClasses]="iconsCss" class="material striped mt-8" [columnMode]="'force'"
        [headerHeight]="90" [rowHeight]="50" [footerHeight]="'auto'">
        <ngx-datatable-column name="{{'No' | translate}}" [width]="50">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                {{(page.size * page.pageNumber) + rowIndex + 1}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="70" name="{{'Customer' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <strong class="pt-8" style="display: flex;align-items: center" mat-stroked-button mat-button
                    [matMenuTriggerFor]="afterMenu">
                    {{row?.customer?.first_name}} {{row?.customer?.middle_name}}
                    {{row?.customer?.last_name}}
                    <!-- <mat-icon *ngIf="showAction == row?.id && (row?.customer?.first_name || row?.customer?.last_name) "
                        matPostfix>
                        arrow_drop_down</mat-icon> -->
                </strong>
                <!-- <mat-menu #afterMenu="matMenu" xPosition="after">
                    <div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="8px"
                            class="p-8">

                            <div fxFlex="0 0 auto" fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="8px"
                                class="p-8" style="background-color: #f3c036;color: white;">

                                <strong class="mat-title mb-0"> {{row?.customer?.first_name}}
                                    {{row?.customer?.middle_name}}
                                    {{row?.customer?.last_name}}</strong>
                                <mat-icon
                                    *ngIf="coreService.checkPermission(utilitiesService.Permission.CustomerDetail)"
                                    (click)="viewCustomer(row?.customer?.id,row?.customer?.first_name,row?.customer?.last_name)"
                                    class="cursor-pointer" title="View Customer" matPrefix>
                                    remove_red_eye
                                </mat-icon>
                            </div>

                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px"
                                *ngIf="row?.customer?.email" class="p-8">
                                <label style="color: #f3c036; font-size: 13px !important;">{{'Email'}}</label>
                                <strong>{{row?.customer?.email}}</strong>
                            </div>
                            <div fxFlex="0 0 auto" *ngIf="row?.customer?.contact_no">
                                <strong
                                    *ngIf="row?.customer?.contact_no_country?.isd_code && !utilitiesService.checkIfISDCodeExist(row?.customer?.contact_no)">+{{row?.customer?.contact_no_country?.isd_code}}
                                    {{row?.customer?.contact_no}}
                                </strong>
                            </div>
                            <div fxFlex="0 0 auto" *ngIf="row?.status" fxLayoutAlign="start stretch" fxLayout="column"
                                fxLayoutGap="5px">
                                <div *ngIf="row?.status ==1" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="5px">
                                    <label style="color: #f3c036; font-size: 13px !important;">
                                        {{'Status' | translate}}
                                    </label>
                                    <strong>
                                        {{'Active' | translate}}
                                    </strong>
                                </div>
                                <div *ngIf="row?.status ==2" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="5px">
                                    <label style="color: #f3c036; font-size: 13px !important;">
                                        {{'Status' | translate}}
                                    </label>
                                    <strong>
                                        {{'In active' | translate}}
                                    </strong>
                                </div>
                                <div *ngIf="row?.status ==3" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="5px">
                                    <label style="color: #f3c036; font-size: 13px !important;">
                                        {{'Status' | translate}}
                                    </label>
                                    <strong>
                                        {{'Expired' | translate}}
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-menu> -->
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="70" name="{{'Membership Plan' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.membership_plan?.name}}</p>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="70" name="{{'Membership Number' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.membership_id}}</p>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="70" name="{{'Status' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p class="active" *ngIf="row?.status ==1">{{'Active' | translate}}</p>
                <p class="inactive" *ngIf="row?.status ==2">{{'In active' | translate}}</p>
                <p class="expired" *ngIf="row?.status ==3">{{'Expired' | translate}}</p>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="70" name="{{'Activate Date' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.active_date | date: 'dd/MM/yyyy hh:mm a'}}</p>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="getWidth()" name="{{'Expiry Date' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.expiry_date | date: 'dd/MM/yyyy hh:mm a'}}</p>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>