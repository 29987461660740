import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "app-fab-button",
    templateUrl: "./fab-button.component.html",
    styleUrls: ["./fab-button.component.scss"],
})
export class FabButtonComponent implements OnInit {
    @Input() bgColorClass: string = "primary-bg-color";
    @Input() label: string = "";

    @Input() icon;
    @Input() clear: boolean = false;
    @Input() tooltip: string = "";
    @Input() bottomMargin: boolean = true;
    @Output()
    handleFabButtonClick: EventEmitter<void> = new EventEmitter<any>();
    constructor() {
        console.log("clear" + this.clear);
    }

    ngOnInit(): void {}

    fabButtonClick() {
        this.handleFabButtonClick.emit();
    }
}
