<ng-container *ngIf="controlType">
    <div fxFlex="1 1 100%" fxLayout="column" class="mb-8" *ngIf="typeEnum.HTML ==  controlType">
        <mat-slide-toggle class="mb-8 align-self" *ngIf="controlLabel=='html' && config.controls.language.value=='en'"
            [formControl]="config.controls.isCodeEditor">
            {{!config.controls.isCodeEditor.value ? ('Switch to Code Editor' | translate) : ('Switch to HTML Editor' | translate)}}
        </mat-slide-toggle>
        <mat-slide-toggle class="mb-8 align-self"
            *ngIf="controlLabel=='html_ar' && config.controls.language.value=='ar' "
            [formControl]="config.controls.isCodeEditor">
            {{!config.controls.isCodeEditor.value ? ('Switch to Code Editor' | translate) : ('Switch to HTML Editor' | translate)}}
        </mat-slide-toggle>
        <ckeditor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
            *ngIf="controlLabel=='html' && config.controls.language.value=='en' && !config.controls.isCodeEditor.value"
            [formControl]="control.value" ngDefaultControl name="myckeditor" [config]="ckeConfig">
        </ckeditor>
        <div fxFlex="0 0 auto" fxLayout="column"
            *ngIf="controlLabel=='html' && config.controls.language.value=='en' && config.controls.isCodeEditor.value">
            <mat-icon class="zoom-icon cursor-pointer highlight-text-color"
                (click)="zoomAdditionalCss(control.value,configIndex,'html')">
                zoom_out_map</mat-icon>
            <ace-editor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}" [theme]="'clouds'" [mode]="'html'"
                [options]="options" [formControl]="control.value" style="height:50vh;">
            </ace-editor>
        </div>
        <div style="font-size: 10px;color: red;"
            *ngIf="control.value.hasError('required')  && controlLabel=='html' && config.controls.language.value=='en'">
            {{'Html En is required' | translate}}
        </div>

        <div fxFlex="0 0 auto" fxLayout="column"
            *ngIf="controlLabel=='html_ar' && config.controls.language.value=='ar' && config.controls.isCodeEditor.value">
            <mat-icon class="zoom-icon highlight-text-color cursor-pointer"
                (click)="zoomAdditionalCss(control.value,configIndex,'html_ar')">
                zoom_out_map</mat-icon>
            <ace-editor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}" [theme]="'clouds'" [mode]="'css'"
                [options]="options" [formControl]="control.value" style="height:50vh;">
            </ace-editor>
        </div>

        <ckeditor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
            *ngIf="controlLabel=='html_ar' && config.controls.language.value=='ar' && !config.controls.isCodeEditor.value"
            [formControl]="control.value" ngDefaultControl name="myckeditor1" [config]="ckeConfig">
        </ckeditor>
        <div style=" font-size: 10px;color: red;"
            *ngIf="control.value.hasError('required') && controlLabel=='html_ar' && config.controls.language.value=='ar'">
            {{'Html Ar is required' | translate}}
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!controlType">
    <div fxFlex="1 1 100%" fxLayout="column" class="mb-8" *ngIf="typeEnum.HTML ==  controlType">
        <mat-slide-toggle class="mb-8 align-self" *ngIf="controlLabel=='html' && lang.value=='en'"
            [formControl]="codeEditor">
            {{!codeEditor.value ? ('Switch to Code Editor' | translate) : ('Switch to HTML Editor' | translate)}}
        </mat-slide-toggle>
        <mat-slide-toggle class="mb-8 align-self" *ngIf="controlLabel=='html_ar' && lang.value=='ar' "
            [formControl]="codeEditor">
            {{!codeEditor.value ? ('Switch to Code Editor' | translate) : ('Switch to HTML Editor' | translate)}}
        </mat-slide-toggle>
        <ckeditor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
            *ngIf="controlLabel=='html' && lang.value=='en' && !codeEditor.value" [formControl]="control.value"
            ngDefaultControl name="myckeditor" [config]="ckeConfig">
        </ckeditor>
        <div fxFlex="0 0 auto" fxLayout="column" *ngIf="controlLabel=='html' && lang.value=='en' && codeEditor.value">
            <mat-icon class="zoom-icon cursor-pointer highlight-text-color"
                (click)="zoomAdditionalCss(control.value,configIndex,'html')">
                zoom_out_map</mat-icon>
            <ace-editor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}" [theme]="'clouds'" [mode]="'html'"
                [options]="options" [formControl]="control.value" style="height:50vh;">
            </ace-editor>
        </div>
        <div style="font-size: 10px;color: red;"
            *ngIf="control.value.hasError('required')  && controlLabel=='html' && lang.value=='en'">
            {{'Html En is required' | translate}}
        </div>

        <div fxFlex="0 0 auto" fxLayout="column"
            *ngIf="controlLabel=='html_ar' && lang.value=='ar' && codeEditor.value">
            <mat-icon class="zoom-icon highlight-text-color cursor-pointer"
                (click)="zoomAdditionalCss(control.value,configIndex,'html_ar')">
                zoom_out_map</mat-icon>
            <ace-editor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}" [theme]="'clouds'" [mode]="'css'"
                [options]="options" [formControl]="control.value" style="height:50vh;">
            </ace-editor>
        </div>

        <ckeditor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
            *ngIf="controlLabel=='html_ar' && lang.value=='ar' && !codeEditor.value" [formControl]="control.value"
            ngDefaultControl name="myckeditor1" [config]="ckeConfig">
        </ckeditor>
        <div style=" font-size: 10px;color: red;"
            *ngIf="control.value.hasError('required') && controlLabel=='html_ar' && lang.value=='ar'">
            {{'Html Ar is required' | translate}}
        </div>
    </div>
</ng-container>