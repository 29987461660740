<div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch">
    <app-breadcrumbs
        (back)="handleCancel()"
        [isEnableBack]="true"
        [enableIcon]="true"
        [label]="label"
    >
    </app-breadcrumbs>
    <div
        id="dashboard-project"
        class="page-layout simple right-sidebar"
        fxLayout="column"
        [@animate]="{ value: '*', params: { duration: '400ms', y: '70px' } }"
    >
        <div
            class="center"
            fxFlex="1 1 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="1em"
            fusePerfectScrollbar
        >
            <div
                class="content p-16 remove-scroll-bar"
                fxFlex="1 1 auto"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="1em"
            >
                <div
                    class="create-product auto-width center-container margin-auto"
                    fxLayoutAlign="start stretch"
                    fxLayout.lt-md="column"
                    fxLayout.gt-sm="row"
                    fxLayoutGap="1em"
                >
                    <form
                        *ngIf="isApiCallDone"
                        fxFlex="1 1 100%"
                        fxLayoutAlign="start stretch"
                        fxLayout.lt-md="column"
                        fxLayout.gt-sm="column"
                        fxLayoutGap="1em"
                        [formGroup]="form"
                    >
                        <div fxFlex="0 0 auto" fxLayoutAlign="center">
                            <div
                                fxFlex="0 0  auto"
                                class="fuse-card auto-width p-16"
                                fxLayoutAlign="start stretch"
                                fxLayout="row"
                                fxLayout.lt-md="column"
                                fxLayoutGap="1em"
                            >
                                <div
                                    fxLayout="column"
                                    fxFlex="70"
                                    class="h-fulll"
                                >
                                    <div
                                        fxFlex="0 0 auto"
                                        fxLayoutAlign="start stretch"
                                        fxLayout="column"
                                        fxLayoutGap="16px"
                                    >
                                        <app-form-fields
                                            [formData]="productFields"
                                            [customFormGroup]="form"
                                            [id]="productId"
                                        ></app-form-fields>
                                    </div>
                                </div>
                                <div fxFlex="30">
                                    <div
                                        class="fuse-card p-16"
                                        style="
                                            min-width: 100%;
                                            margin-bottom: 10px;
                                        "
                                    >
                                        <span>profile_pic</span>
                                        <app-cms-upload-media
                                            [formcontrol]="
                                                form.controls.profile_pic
                                            "
                                            (afterUpload)="
                                                setFileToForm(
                                                    $event,
                                                    'profile_pic'
                                                )
                                            "
                                            saveLocally="true"
                                            showOnly="profile_pic"
                                            [isMultiSelect]="true"
                                            [index]="1"
                                            uploadOnly="true"
                                        >
                                        </app-cms-upload-media>
                                        <div
                                            *ngIf="
                                                form.get('profile_pic').value
                                            "
                                            fxLayout="row"
                                            fxLayoutAlign="space-between stretch"
                                            class="category-images my-10"
                                        >
                                            <img
                                                style="width: 100px"
                                                [src]="
                                                    form.get('profile_pic')
                                                        .value
                                                "
                                                alt=""
                                            />
                                            <button
                                                mat-icon-button
                                                aria-label="Delete"
                                            >
                                                <mat-icon
                                                    (click)="
                                                        deleteFile(
                                                            form.get(
                                                                'profile_pic'
                                                            )
                                                        )
                                                    "
                                                    class="action-btn-delete"
                                                    >delete</mat-icon
                                                >
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        class="fuse-card p-16"
                                        style="
                                            min-width: 100%;
                                            margin-bottom: 10px;
                                        "
                                    >
                                        <span>civil_id</span>
                                        <app-cms-upload-media
                                            [formcontrol]="
                                                form.controls.civil_id
                                            "
                                            (afterUpload)="
                                                setFileToForm(
                                                    $event,
                                                    'civil_id'
                                                )
                                            "
                                            saveLocally="true"
                                            showOnly="image"
                                            [isMultiSelect]="true"
                                            [index]="2"
                                            uploadOnly="true"
                                        >
                                        </app-cms-upload-media>
                                        <div
                                            *ngIf="form.get('civil_id').value"
                                            fxLayout="row"
                                            fxLayoutAlign="space-between stretch"
                                            class="category-images my-10"
                                        >
                                            <img
                                                style="width: 100px"
                                                [src]="
                                                    form.get('civil_id').value
                                                "
                                                alt=""
                                            />
                                            <button
                                                mat-icon-button
                                                aria-label="Delete"
                                            >
                                                <mat-icon
                                                    (click)="
                                                        deleteFile(
                                                            form.get('civil_id')
                                                        )
                                                    "
                                                    class="action-btn-delete"
                                                    >delete</mat-icon
                                                >
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            fxFlex="1 1 auto"
                            fxLayoutAlign.gt-sm="end center"
                            fxLayoutAlign.lt-md="start stretch"
                            fxLayout.lt-md="column-reverse"
                            fxLayout.gt-sm="row"
                            fxLayoutGap="5px"
                        >
                            <button mat-stroked-button (click)="handleCancel()">
                                <mat-icon>clear</mat-icon>
                                {{ "Cancel" | translate }}
                            </button>
                            <button
                                (click)="saveOrUpdate()"
                                type="submit"
                                mat-flat-button
                                class="primary-bg-color"
                            >
                                <mat-icon>save</mat-icon>
                                {{ "Save" | translate }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
