<div fxFlex="1 1 100%" fxLayout="column">
    <!-- <app-breadcrumbs
        (back)="back()"
        [permissionKey]="utilitiesService.Permission.HistoryTranslation"
        [isEnableBack]="true"
        [label]="label"
        [enableIcon]="false"
    ></app-breadcrumbs> -->
    <div id="dashboard-project" class="page-layout simple right-sidebar" fxLayout="column"
        [@animate]="{ value: '*', params: { duration: '400ms', y: '70px' } }">
        <div class="center" fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
            fusePerfectScrollbar>
            <div class="content p-16 remove-scroll-bar" fxFlex="1 1 auto" fxLayoutAlign="start stretch"
                fxLayout="column" fxLayoutGap="1em">
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <div class="" fxLayoutAlign="start stretch" fxLayout="column">
                            <div class="p-16 pb-0" fxLayoutAlign="space-between center" fxLayout="row"
                                fxLayoutGap="1em">
                                <div class="mat-title highlight-text-color">
                                    {{ "Page Redirection" | translate }}
                                </div>
                                <!-- <div
                                    style="align-items: self-end !important"
                                    fxLayout="row"
                                    fxLayoutGap="1em"
                                >
                                    <mat-form-field appearance="outline">
                                        <mat-select
                                            [(ngModel)]="page.size"
                                            (selectionChange)="limitChange()"
                                        >
                                            <mat-option [value]="5"
                                                >5</mat-option
                                            >
                                            <mat-option [value]="10"
                                                >10</mat-option
                                            >
                                            <mat-option [value]="25"
                                                >25</mat-option
                                            >
                                            <mat-option [value]="50"
                                                >50</mat-option
                                            >
                                            <mat-option [value]="100"
                                                >100</mat-option
                                            >
                                        </mat-select>
                                    </mat-form-field>
                                    <app-common-filter
                                        (searchInput)="search($event)"
                                        fxHide.lt-md
                                    ></app-common-filter>
                                    <app-fab-button
                                        [bottomMargin]="false"
                                        [bgColorClass]="'primary-bg-color'"
                                        (handleFabButtonClick)="
                                            createNewRedirection()
                                        "
                                        [tooltip]="'Create Price Rule'"
                                    >
                                    </app-fab-button>
                                </div> -->
                                <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="end center" fxLayoutGap="1em">
                                    <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="start start" fxLayoutGap="1em">
                                        <!-- <app-clear-filter class="mt-4" [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
                                            [form]="form">
                                        </app-clear-filter> -->

                                        <mat-form-field appearance="outline">
                                            <mat-select [(ngModel)]="page.size" (selectionChange)="
                                                    limitChange()
                                                ">
                                                <mat-option [value]="5">5</mat-option>
                                                <mat-option [value]="10">10</mat-option>
                                                <mat-option [value]="25">25</mat-option>
                                                <mat-option [value]="50">50</mat-option>
                                                <mat-option [value]="100">100</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <app-common-filter (searchInput)="search($event)" fxHide.lt-md>
                                        </app-common-filter>
                                        <app-fab-button [bottomMargin]="false" [bgColorClass]="'primary-bg-color'"
                                            (handleFabButtonClick)="
                                                createNewRedirection()
                                            " [tooltip]="'Create Price Rule'">
                                        </app-fab-button>
                                    </div>
                                </div>
                            </div>
                            <!-- <div
                                class="p-16 pb-0"
                                fxLayout="row"
                                fxFlex="1 1 auto"
                                fxLayoutAlign="start start"
                                fxLayoutGap="1em"
                            >
                                <app-common-filter
                                    (searchInput)="search($event)"
                                    fxHide
                                    fxShow.lt-md
                                >
                                </app-common-filter>
                            </div> -->
                            <div fxFlex="0 0 auto" class="p-16 p-0" fxLayoutAlign="start stretch" fxLayout="column"
                                fxLayoutGap="1em">
                                <ngx-datatable [externalPaging]="true" [count]="page.totalElements" [limit]="page.size"
                                    (page)="pageChange($event)" [offset]="page.pageNumber" [virtualization]="false"
                                    [scrollbarH]="true" [rows]="pageRedirectionList" [cssClasses]="iconsCss"
                                    class="material striped mt-8" [columnMode]="'force'" [headerHeight]="90"
                                    [rowHeight]="'auto'" [footerHeight]="'auto'">
                                    <ngx-datatable-column name="{{ 'No' | translate }}" [width]="50">
                                        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                            ngx-datatable-cell-template>
                                            {{
                                            page.size * page.pageNumber +
                                            rowIndex +
                                            1
                                            }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column [width]="70" name="{{ 'Old URL' | translate }}">
                                        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                            ngx-datatable-cell-template>
                                            <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="row"
                                                fxLayoutGap="16px">
                                                <p *ngIf="
                                                        !row?.old_path &&
                                                        !edit[rowIndex]
                                                    ">
                                                    {{ row?.old_path }}
                                                </p>
                                                <p *ngIf="
                                                        row?.old_path &&
                                                        !edit[rowIndex]
                                                    " class="override">
                                                    {{ row?.old_path }}
                                                </p>
                                                <mat-form-field style="padding-top: 11px" *ngIf="edit[rowIndex]"
                                                    appearance="outline" fxFlex="100">
                                                    <mat-label class="text-capitalized">
                                                        <!-- {{
                                                            "Override English"
                                                                | translate
                                                        }} -->
                                                    </mat-label>
                                                    <input [value]="
                                                            row?.old_path
                                                                ? row?.old_path
                                                                : row?.en
                                                        " id="{{
                                                            'en' + rowIndex
                                                        }}" (blur)="
                                                            updateValues(
                                                                $event,
                                                                rowIndex,
                                                                'old_path'
                                                            )
                                                        " type="text" matInput name="title" />
                                                </mat-form-field>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="{{ 'New Url' }}">
                                        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                            ngx-datatable-cell-template>
                                            <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="row"
                                                fxLayoutGap="16px">
                                                <p *ngIf="
                                                        !row?.new_path &&
                                                        !edit[rowIndex]
                                                    ">
                                                    {{ row?.new_path }}
                                                </p>
                                                <p *ngIf="
                                                        row?.new_path &&
                                                        !edit[rowIndex]
                                                    " class="override">
                                                    {{ row?.new_path }}
                                                </p>
                                                <mat-form-field style="padding-top: 11px" *ngIf="edit[rowIndex]"
                                                    appearance="outline" fxFlex="100">
                                                    <mat-label class="text-capitalized">
                                                        <!-- {{
                                                            "Override Arabic"
                                                                | translate
                                                        }} -->
                                                    </mat-label>
                                                    <input [value]="
                                                            row?.new_path
                                                                ? row?.new_path
                                                                : row?.ar
                                                        " id="{{
                                                            'ar' + rowIndex
                                                        }}" (blur)="
                                                            updateValues(
                                                                $event,
                                                                rowIndex,
                                                                'new_path'
                                                            )
                                                        " type="text" matInput name="title" />
                                                </mat-form-field>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="{{ 'Actions' | translate }}">
                                        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                            ngx-datatable-cell-template>
                                            <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="row"
                                                fxLayoutGap="16px">
                                                <button mat-stroked-button *ngIf="
                                                        !edit[rowIndex] &&
                                                        coreService.checkPermission(
                                                            utilitiesService
                                                                .Permission
                                                                .TranslateUpdate
                                                        )
                                                    " class="edit" (click)="editFn(rowIndex)">
                                                    <mat-icon title="Edit">edit
                                                    </mat-icon>
                                                </button>
                                                <button mat-stroked-button *ngIf="edit[rowIndex]" class="save" (click)="
                                                        saveValues(rowIndex)
                                                    ">
                                                    <mat-icon title="Save changes">
                                                        save</mat-icon>
                                                </button>
                                                <button mat-stroked-button *ngIf="edit[rowIndex]" class="cancel"
                                                    (click)="
                                                        cancelChanges(rowIndex)
                                                    ">
                                                    <mat-icon title="Discard changes">
                                                        clear</mat-icon>
                                                </button>
                                                <button mat-stroked-button *ngIf="
                                                        !edit[rowIndex] &&
                                                        (row?.new_path ||
                                                            row?.old_path)
                                                    " class="undo" (click)="delete(rowIndex)">
                                                    <mat-icon class="color-red" title="Restore original values">
                                                        delete</mat-icon>
                                                </button>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                            <div class="pl-16 pr-16 pb-16" fxLayoutAlign="start center" fxLayout="row"
                                fxLayoutGap="2px">
                                <div class="box"></div>
                                <small>{{
                                    "This background color indicates overridden values"
                                    | translate
                                    }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>