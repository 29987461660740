import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { SharedService } from "../sharedService";

@Injectable({
    providedIn: "root",
})
export class WalletService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    getTransactionBycustomerid(
        limit: number = 10,
        offset: number = 0,
        search: any = "",
        form: any = null,
        isDownalod: boolean = false
    ) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (const key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        console.log(params);
        if (isDownalod) {
            params = params.set("download", "true");
        }
        return this.apiService.get(
            this.sharedService.transaction,
            params,
            undefined
        );
    }
    getWalletByID(
        id,
        limit: number = 10,
        offset: number = 0,
        search: any = "",
        form: any = null
    ) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (const key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        return this.apiService.get(
            this.sharedService.wallets + `/${id}/`,
            params,
            undefined
        );
    }
    getAppWallets(
        limit: number = 10,
        offset: number = 0,
        search: any = "",
        form: any = null,
        isDownalod = false
    ) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (const key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        if (isDownalod) {
            params = params.set("download", "true");
        }
        return this.apiService.get(
            this.sharedService.wallets,
            params,
            undefined
        );
    }
    topUpWallet(form: any = null) {
        return this.apiService.post(
            this.sharedService.topup_wallet,
            form,
            undefined
        );
    }
}
