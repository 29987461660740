export const locale = {
    lang: "ar",
    data: {
        NAV: {
            Integration: "تكاملات",
            contant: "المتصل",
            myshops: "متجري",
            customer: "الزبون",
            orders: "طلب",
            notification: "تنبيه",
            salesChannels: "قنوات المبيعات",
            Ecommerce: "التجارة",
            marketing: "تسويق",
            shopify: "Shopify",
            widgetsNav: "الحاجيات",
            reviews: {
                TITLE: "مراجعات",
            },
            products: {
                TITLE: "منتجات",
            },
            categories: {
                TITLE: 'فئات'
            },
            brands: {
                TITLE: 'علامات تجارية'
            },
            "1": "Shopify",
            usermanagement: "إدارةالمستخدم",
            loyaltyManagement: "إدارة برنامج الوفاء",
            extras: "إضافات",
            postsNav: "المشاركات",
            blogNav: "المدونة",
            ordersNav: "طلب",
            productsNav: "نتائج المنتجات",
            catalogue: "فهرس",
            // categories: "فئات",
            // brands: "ماركات",
            attribute: "يصف",
            DeliveryAgent: "عملاء التوصيل",
            Delivery: "التوصيل",
            UnDeliveredReason: "UnDeliveredReason",
            shop: {
                TITLE: "محلات",
            },
            pointsSummary: {
                TITLE: "حالة النقاط",
            },
            loyaltyPointsRule: {
                TITLE: "قواعد النقاط",
            },
            wallets: {
                TITLE: "محفظتي",
            },
            loyaltyConfiguration: {
                TITLE: "اعدادات برنامج الولاء",
            },
            configuration_attribute: {
                TITLE: " اعدادات ",
            },
            page: {
                TITLE: "الصفحات",
            },
            media: {
                TITLE: "وسائل الإعلام",
            },
            widgets: {
                TITLE: "الحاجيات",
            },
            slider: {
                TITLE: "المتزلجون",
            },
            app: {
                TITLE: "التصميم",
            },
            navmenu: {
                TITLE: "قائمة الإبحار",
            },
            customer_list: {
                TITLE: "قائمة",
            },
            orderSubNav: {
                TITLE: "طلب",
            },
            cart: {
                TITLE: "عربة التسوق",
            },
            notifications: {
                TITLE: "إشعارات",
            },
            notifications_template: {
                TITLE: "قوالب الإخطار",
            },
            "3": {
                TITLE: "الحملات",
            },
            configuration: {
                TITLE: "التكوينات",
            },
            "2": {
                TITLE: "عضوية",
            },
            translation_editor: {
                TITLE: "محرر الترجمة",
            },
            pricerules: {
                TITLE: "قواعد السعر",
            },
            affiliate: {
                TITLE: "التسويق بالعمولة",
            },
            dashboard: {
                TITLE: "لوحة التحكم",
            },
            manage_campaigns: {
                TITLE: " الحملات",
            },
            audience: {
                TITLE: "جمهور",
            },
            // condition: {
            //     TITLE: "شرط",
            // },
            "5": {
                TITLE: "الحقول المخصصة ",
            },
            posts: {
                TITLE: "المشاركات",
            },
            "6": {
                TITLE: "وورد",
            },
            collection: {
                TITLE: "مجموعة",
            },
            category: {
                TITLE: "فئة",
            },
            appearance: {
                TITLE: "المظهر",
            },
            wishlist: {
                TITLE: "قائمة الأمنيات",
            },
            templates: {
                TITLE: "القوالب",
            },
            notification_configuration: {
                TITLE: "التكوينات",
            },
            users: {
                TITLE: "المستخدمون",
            },
            roles_and_permission: {
                TITLE: "الأدوار والأذونات",
            },
            "7": {
                TITLE: "تحليلات الخادم",
            },
            "8": {
                TITLE: "كتالوج المنتجات"
            },
            social: {
                TITLE: "صفحة السوشيال"
            },
            connectInsights : {
                TITLE: "تحليلات صفحة السوشيال"
            },
            connectPage: {
                TITLE: "صفحة التواصل"
            }
        },
    },
};
