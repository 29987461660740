import { Component, Input, OnInit } from "@angular/core";
import { TranslationService } from "app/services/translationService";

@Component({
    selector: "app-order-timeline",
    templateUrl: "./order-timeline.component.html",
    styleUrls: ["./order-timeline.component.scss"],
})
export class OrderTimelineComponent implements OnInit {
    @Input() timelineData: any[] = [];
    @Input() timelineDeliveryData: any[] = [];
    constructor(public translationService: TranslationService) {}

    ngOnInit() {}
}
