<div
    fxFlex="1 1 auto"
    fxLayoutAlign="start stretch"
    fxLayout="row"
    fxLayoutGap="1em"
    id="main-page"
>
    <div
        fxFlex="1 1 auto"
        fxLayoutAlign="start stretch"
        fxLayout="column"
        fxLayoutGap="1em"
    >
        <form
            fxFlex="1 1 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="1em"
            [formGroup]="currencyForm"
        >
            <div
                fxFlex="0 0 auto"
                fxLayoutAlign="space-between stretch"
                fxLayout="row"
                fxLayoutGap="10px"
            >
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="center center"
                    fxLayout="row"
                    fxLayoutGap="10px"
                >
                    <mat-icon class="cursor-pointer" (click)="goBack()"
                        >arrow_back</mat-icon
                    >
                    <label class="highlight-text-color">{{
                        "Country Rules" | translate
                    }}</label>
                </div>
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="center center"
                    fxLayout="row"
                    fxLayoutGap="10px"
                >
                    <button
                        (click)="save()"
                        type="submit"
                        mat-flat-button
                        style="position: absolute; right: 16px"
                        class="primary-bg-color"
                    >
                        {{ "Save" | translate }}
                    </button>
                </div>
            </div>
            <div
                fxFlex="0 0 auto"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="1em"
                [@animate]="{
                    value: '*',
                    params: { duration: '400ms', y: '70px' }
                }"
            >
                <div
                    fxFlex="0 0  auto"
                    class="fuse-card auto-width p-16"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="1em"
                >
                    <label class="highlight-text-color">{{
                        "Country Rules" | translate
                    }}</label>
                    <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="1em"
                    >
                        <div
                            fxFlex="0 0 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout.gt-sm="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap="1em"
                        >
                            <div
                                fxFlex="1 1 auto"
                                fxLayoutAlign="start stretch"
                                fxLayout="column"
                                fxLayoutGap="0px"
                            >
                                <div
                                    fxFlex="1 1 auto"
                                    fxLayoutAlign="start stretch"
                                    fxLayout.gt-sm="row"
                                    fxLayout.lt-md="column"
                                    fxLayoutGap="1em"
                                >
                                    <div fxFlex="1 1 0px">
                                        <mat-form-field
                                            appearance="outline"
                                            fxFlex="100"
                                        >
                                            <mat-label class="text-capitalized">
                                                {{
                                                    "Base Currency" | translate
                                                }}
                                            </mat-label>
                                            <mat-select
                                                matTooltip="{{
                                                    'help_text_BaseCurrency'
                                                        | translate
                                                }}"
                                                formControlName="base_currency"
                                            >
                                                <mat-option
                                                    *ngFor="
                                                        let currency of currency
                                                    "
                                                    [value]="currency.id"
                                                >
                                                    {{
                                                        currency.code
                                                    }}</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="1 1 0px">
                                        <mat-form-field
                                            appearance="outline"
                                            fxFlex="100"
                                        >
                                            <mat-label class="text-capitalized">
                                                {{ "Base Country" | translate }}
                                            </mat-label>
                                            <mat-select
                                                matTooltip="{{
                                                    'help_text_BaseCountry'
                                                        | translate
                                                }}"
                                                formControlName="base_country"
                                            >
                                                <mat-option
                                                    *ngFor="
                                                        let country of country
                                                    "
                                                    [value]="country?.id"
                                                >
                                                    {{
                                                        country?.name
                                                    }}</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <!-- <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                                    fxLayout.lt-md="column" fxLayoutGap="1em">
                                    <div fxFlex="1 1 0px" fxLayout="column" fxLayoutGap="5px">
                                        <label class="label-name">{{'Country' | translate}}</label>
                                        <angular2-multiselect
                                            [ngClass]="{'required-country' : currencyForm.get('countries').hasError('required')}"
                                            formControlName="countries" (onSelect)="onItemSelect($event)"
                                            (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                                            (onDeSelectAll)="onDeSelectAll($event)" [data]="countryDropDown"
                                            [settings]="countryModeSettings">
                                        </angular2-multiselect>
                                        <small style="color: red !important;font-size: 9px !important;"
                                            *ngIf="currencyForm.get('countries').hasError('required')">{{'Country is required' | translate}}</small>

                                    </div>
                                    <div fxFlex="1 1 0px" fxLayout="column" fxLayoutGap="5px">
                                        <label class="label-name">{{'Currency' | translate}}</label>
                                        <angular2-multiselect formControlName="currencies" [data]="currencyDropDown"
                                            [settings]="currencySettings">
                                        </angular2-multiselect>
                                        <small>*{{'Currency is auto populate based on the country' | translate}}</small>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    fxFlex="0 0  auto"
                    class="fuse-card auto-width p-16 center-container"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="1em"
                >
                    <app-custom-country></app-custom-country>
                </div>
            </div>
        </form>
    </div>
</div>
