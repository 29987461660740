import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "./api.service";
import { map, shareReplay } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class DeliveryService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    getDeliveryAgentById(id) {
        return this.apiService.get(
            this.sharedService.deliveryAgent + `${id}/`,
            undefined
        );
    }
    updateDeliveryAgent(id, form?) {
        let url = `${this.sharedService.deliveryAgent}${id}/`;
        console.log(url);
        return this.apiService.patch(url, form);
    }
    getDeliveryAgentList(limit = 10, offset = 0, search = "", form?) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }

        return this.apiService.get(
            this.sharedService.deliveryAgent,
            params,
            undefined
        );
    }

    addDeliveryAgent(form) {
        return this.apiService.post(
            this.sharedService.deliveryAgent,
            form,
            undefined
        );
    }
    deleteDeliveryAgent(id) {
        return this.apiService.delete(
            this.sharedService.deliveryAgent + `${id}/`
        );
    }

    // delivery
    getDeliveryById(id) {
        return this.apiService.get(
            this.sharedService.delivery + `${id}/`,
            undefined
        );
    }
    updateDelivery(id, form?) {
        return this.apiService.patch(
            this.sharedService.delivery + `${id}/`,
            form
        );
    }
    assignOrderDelivery(form) {
        return this.apiService.post(this.sharedService.assignDelivery, form);
    }
    getDeliveryList(limit = 10, offset = 0, search = "", form?) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }

        return this.apiService
            .get(this.sharedService.delivery, params, undefined)
            .pipe(shareReplay());
    }

    addDelivery(form) {
        return this.apiService.post(
            this.sharedService.delivery,
            form,
            undefined
        );
    }
    deleteDelivery(id) {
        return this.apiService.delete(this.sharedService.delivery + `${id}/`);
    }
    // deliveryStatus

    getDeliveryStatustList(
        limit = 10,
        offset = 0,
        search = "",
        form?,
        isDownalod = false
    ) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                if (key == "price_rules") {
                    if (form[key].value) {
                        params = params.set(`${key}`, `${form[key].value}`);
                    }
                } else {
                    params = params.set(
                        `${key}`,
                        `${form[key].value ? form[key].value : ""}`
                    );
                }
            }
        }

        return this.apiService.get(
            this.sharedService.deliveryStatus,
            params,
            undefined
        );
    }
    addDeliveryStatus(form) {
        return this.apiService.post(
            this.sharedService.deliveryStatus,
            form,
            undefined
        );
    }
    deleteDeliveryStatus(id) {
        return this.apiService.delete(
            this.sharedService.deliveryStatus + `${id}/`
        );
    }

    // undeliveredReason
    getUnDeliveredReasonList(
        limit = 10,
        offset = 0,
        search = "",
        form?,
        isDownalod = false
    ) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }

        return this.apiService.get(
            this.sharedService.undeliveredReason,
            params,
            undefined
        );
    }

    getUnDeliveredReasonById(id) {
        return this.apiService.get(
            this.sharedService.undeliveredReason + `${id}/`,
            undefined
        );
    }
    updateUnDeliveredReason(id, form?) {
        return this.apiService.patch(
            this.sharedService.undeliveredReason + `${id}/`,
            form
        );
    }

    addUnDeliveredReason(form) {
        return this.apiService.post(
            this.sharedService.undeliveredReason,
            form,
            undefined
        );
    }
    deleteUnDeliveredReason(id) {
        return this.apiService.delete(
            this.sharedService.undeliveredReason + `${id}/`
        );
    }
}
