import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { PageService } from "app/services/pageService";
import { SliderService } from "app/services/sliderService";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { debounceTime } from "rxjs/operators";

enum Condition {
    Product = 1,
    Category = 2,
    Brand = 3,
}
@Component({
    selector: "app-create-dynamic-fields",
    templateUrl: "./create-dynamic-fields.component.html",
    styleUrls: ["./create-dynamic-fields.component.scss"],
    animations: fuseAnimations,
})
export class CreateDynamicFieldsComponent implements OnInit {
    @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
    @Input() id: any = "";
    @Input() editable: boolean = true;
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    @Output() createDynamicField: EventEmitter<void> = new EventEmitter<any>();
    details: any = null;
    form: FormGroup;
    models = [];
    disableSelect: boolean = false;
    types = [
        { id: "text", name: "Text" },
        { id: "select", name: "Select" },
        { id: "textarea", name: "Textarea" },
        { id: "radio", name: "Radio" },
        { id: "checkbox", name: "Checkbox" },
        { id: "number", name: "Number" },
        { id: "date", name: "Date" },
        { id: "phone", name: "Phone" },
    ];
    conditionValues = [];
    isLoading: boolean;
    isDetailDone: boolean = true;
    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        private pageService: PageService,
        public translationService: TranslationService,
        private sliderService: SliderService
    ) {}

    get conditionType() {
        return this.form.get("condition_type").value == Condition.Product
            ? "Products"
            : this.form.get("condition_type").value == Condition.Category
            ? "Categories"
            : "Brands";
    }

    async ngOnInit(): Promise<void> {
        if (this.id) {
            this.utilitiesService.startLoader();
        }
        await this.formInit();
        await this.getModels();
        if (this.id) {
            await this.getDynamicDetail();
        }
    }
    async getDynamicDetail() {
        try {
            await this.deleteFirstFormGroup();
            const detail = await this.pageService
                .getDynamicDetail(this.id)
                .toPromise();
            if (detail) {
                this.details = detail;
                await this.setValues();
            } else {
                this.utilitiesService.stopLoader();
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
    async setValues() {
        try {
            this.isDetailDone = false;
            this.form.patchValue({
                name: this.details.name,
                content_type_id: this.details.content_type.id
                    ? this.details.content_type.id
                    : null,
                label_en: this.details.label_en,
                label_ar: this.details.label_ar,
                help_text: this.details.help_text,
                help_text_ar: this.details.help_text_ar,
                sequence: this.details.sequence ? this.details.sequence : 0,
                type: this.details.type ? this.details.type : null,
                default: this.details.default ? this.details.default : null,
                minimum_length: this.details.minimum_length
                    ? this.details.minimum_length
                    : null,
                maximum_length: this.details.maximum_length
                    ? this.details.maximum_length
                    : null,
                regex: this.details.regex ? this.details.regex : null,
                is_searchable: this.details.is_searchable
                    ? this.details.is_searchable
                    : false,
                show_in_datatable: this.details.show_in_datatable
                    ? this.details.show_in_datatable
                    : false,
                is_enabled: this.details.is_enabled
                    ? this.details.is_enabled
                    : false,
                required: this.details.required ? this.details.required : false,
                is_condition: this.details.is_condition
                    ? this.details.is_condition
                    : false,
                condition_type: this.details.condition_type
                    ? this.details.condition_type
                    : 1,
                id: this.details.id ? this.details.id : null,
            });
            if (this.details.condition_values) {
                let condition_values = JSON.parse(
                    "[" + this.details.condition_values + "]"
                );
                this.form.controls.condition_values.setValue(condition_values);
            }
            if (
                this.details.possible_values &&
                this.details.possible_values.length > 0
            ) {
                this.details.possible_values.forEach((data) => {
                    this.addPossibleValues(data);
                });
            }
            setTimeout(() => {
                this.isDetailDone = true;
            }, 200);
            this.utilitiesService.stopLoader();
        } catch {
            this.utilitiesService.stopLoader();
        }
    }
    async deleteFirstFormGroup() {
        //only for edit
        this.form.get("possible_values")["controls"].splice(0, 1);
    }

    checkType() {
        if (this.form) {
            let type = this.form.controls.type.value;
            if (type == "select" || type == "checkbox" || type == "radio") {
                return true;
            } else {
                return false;
            }
        }
    }
    isDate() {
        if (this.form) {
            let type = this.form.controls.type.value;
            if (type == "date") {
                return true;
            } else {
                return false;
            }
        }
    }

    // selectAll() {
    //     const selected = this.conditionValues.map((item) => item.id);
    //     this.form.get("condition_values").patchValue(selected);
    // }

    async formInit() {
        this.form = this.fb.group({
            id: [null],
            name: [null],
            type: [null, [Validators.required]],
            content_type_id: [null],
            label_ar: [null, [Validators.required]],
            label_en: [null, [Validators.required]],
            required: [false],
            is_searchable: [false],
            show_in_datatable: [false],
            is_enabled: [false],
            possible_values: this.fb.array([this.addPossibleValueFormGroup()]),
            default: [null],
            sequence: [0],
            help_text: [null],
            help_text_ar: [null],
            is_condition: [false],
            condition_type: [1],
            condition_values: [],
            minimum_length: [null],
            maximum_length: [null],
            regex: [null],
        });

        this.form
            .get("condition_type")
            .valueChanges.pipe()
            .subscribe((data) => {
                this.conditionValues = [];
                this.isLoading = true;
                this.form.controls.condition_values.setValue([]);
                switch (data) {
                    case Condition.Product: {
                        this.getProducts();
                        break;
                    }
                    case Condition.Category: {
                        this.getCategory();
                        break;
                    }
                    case Condition.Brand: {
                        this.getBrands();
                        break;
                    }
                    default: {
                        this.getProducts();
                    }
                }
            });
        this.form.get("is_condition").valueChanges.subscribe((data) => {
            if (data) {
                if (!this.details) {
                    this.getProducts();
                }
            }
        });
    }

    clear() {
        this.conditionValues = [];
    }

    searchCondition(value) {
        let searchValue = value.term || "";
        let type = this.form.get("condition_type").value;
        switch (type) {
            case Condition.Product: {
                this.getProducts(searchValue);
                break;
            }
            case Condition.Category: {
                this.getCategory(searchValue);
                break;
            }
            case Condition.Brand: {
                this.getBrands(searchValue);
                break;
            }
            default: {
                this.getProducts();
            }
        }
    }

    async getProducts(searchKey = "") {
        try {
            this.isLoading = true;
            const products = await this.sliderService
                .getProductswithPagination(searchKey, 15, 0)
                .toPromise();
            if (products) {
                this.isLoading = false;
                this.conditionValues = products.results;
            }
        } catch {
            this.isLoading = false;
        } finally {
        }
    }
    async getCategory(searchKey = "") {
        try {
            this.isLoading = true;
            const getCategories = await this.sliderService
                .getCategories(searchKey, 15, 0)
                .toPromise();
            if (getCategories) {
                this.isLoading = false;
                this.conditionValues = getCategories.results;
            }
        } catch {
            this.isLoading = false;
        } finally {
        }
    }
    async getBrands(searchKey = "") {
        try {
            this.isLoading = true;
            const getBrands = await this.sliderService
                .getBrands(searchKey, 15, 0)
                .toPromise();
            if (getBrands) {
                this.isLoading = false;
                this.conditionValues = getBrands.results;
            }
        } catch {
            this.isLoading = false;
        } finally {
        }
    }

    async convertToSlug(Text) {
        return Text.toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "");
    }

    addPossibleValueFormGroup(data?) {
        return this.fb.group({
            id: [data ? data.id : null],
            name_en: [data ? data.name_en : null, [Validators.required]],
            name_ar: [data ? data.name_ar : null, [Validators.required]],
            default: [data ? data.default : false],
        });
    }
    addPossibleValues(data?) {
        (this.form.get("possible_values") as FormArray).push(
            this.addPossibleValueFormGroup(data)
        );
    }
    async getModels() {
        try {
            const models = await this.pageService.getModels().toPromise();
            if (models) {
                this.models = models;
                if (this.models.length > 0) {
                    this.form.controls.content_type_id.setValue(
                        this.models[0].id
                    );
                }
            }
        } catch {
        } finally {
            if (!this.id) {
                this.utilitiesService.stopLoader();
            }
        }
    }

    handleCancel() {
        this.cancelClick.emit();
    }

    deleteValues(index) {
        (this.form.get("possible_values") as FormArray).removeAt(index);
        document.getElementById("toolbar").scrollIntoView();
    }
    defaultCheckboxChange(i) {
        (this.form.get("possible_values") as FormArray).controls.forEach(
            (data, index) => {
                if (index != i) {
                    (
                        (this.form.get("possible_values") as FormArray).at(
                            index
                        ) as FormGroup
                    )
                        .get("default")
                        .patchValue(false);
                }
            }
        );
    }
    async saveOrUpdate() {
        debugger;
        let type = this.form.controls.type.value;
        if (type == "select" || type == "checkbox" || type == "radio") {
            (this.form.get("possible_values") as FormArray).controls.forEach(
                (data, index) => {
                    data.setValidators([Validators.required]);
                    data.updateValueAndValidity();
                    setTimeout(() => {
                        document.getElementById(`en${index}`).focus();
                        document.getElementById(`ar${index}`).focus();
                    }, 100);
                }
            );
        } else {
            (this.form.get("possible_values") as FormArray).clear();
            this.form.controls.possible_values.setValue([]);
        }
        let form = this.form.value;
        console.log(form);
        if (form.possible_values.length > 0) {
            form.possible_values.forEach((data, index) => {
                data.id = index + 1;
            });
        }
        if (!form.sequence) {
            form.sequence = 0;
        }
        try {
            if (
                typeof form.condition_values == "undefined" ||
                typeof form.condition_values == "string"
            ) {
            } else {
                form.condition_values = form.condition_values.toString();
            }
        } catch {}

        if (form.type == "text" || form.type == "number") {
            console.log("type", form.type);
        } else {
            form.minimum_length = null;
            form.maximum_length = null;
            form.regex = null;
        }

        if (this.form.valid) {
            this.utilitiesService.startLoader();
            if (!this.id) {
                try {
                    this.pageService
                        .addDynamicFields(form)
                        .subscribe((data) => {
                            let successMsg = this.translate.instant(
                                "Dynamic Field created successfully"
                            );
                            this.utilitiesService.showSuccessToast(successMsg);
                            this.createDynamicField.emit();
                        });
                } catch {
                    this.utilitiesService.stopLoader();
                } finally {
                }
            } else {
                try {
                    this.pageService
                        .updateDynamicField(form, this.id)
                        .subscribe((data) => {
                            this.utilitiesService.stopLoader();
                            let successMsg = this.translate.instant(
                                "Dynamic Field updated successfully"
                            );
                            this.utilitiesService.showSuccessToast(successMsg);
                            this.createDynamicField.emit();
                        });
                } catch {
                    this.utilitiesService.stopLoader();
                } finally {
                }
            }
        }
    }
}
