import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    ChangeDetectorRef,
} from "@angular/core";
import { ViewCustomersComponent } from "../view-customers/view-customers.component";
import { IconCss } from "../../ngxDatatable-Icons/icons";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CatalogueService } from "../../../services/catalogue/catalogue.service";
import { UtilitiesService } from "../../../services/utilitiesService";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "../../../services/translationService";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomerService } from "../../../services/customer/customer.service";
import { SharedService } from "../../../services/sharedService";
import { CoreService } from "../../../services/core-service";
import { debounceTime } from "rxjs/operators";
import { SendNotificationComponent } from "../send-notification/send-notification.component";
import { fuseAnimations } from "../../../../@fuse/animations";
import { Subject } from "rxjs";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
import { CreateTagComponent } from "../create-tag/create-tag.component";
import { CreateHandlingInstructionsComponent } from "../create-handling-instructions/create-handling-instructions.component";

@Component({
    selector: "app-handling-instructions-list",
    templateUrl: "./handling-instructions-list.component.html",
    styleUrls: ["./handling-instructions-list.component.scss"],
    animations: [fuseAnimations],
})
export class HandlingInstructionsListComponent implements OnInit {
    page = {
        size: 10,
        totalElements: 0,
        pageNumber: 0,
    };
    Handling_instructionsList = [];

    // viewCustomerComponent: ViewCustomersComponent;
    closeOnAutoApply = true;
    paymentStatus = [];

    isView = false;
    isViewCustomer = false;
    @Input() resetFormSubject: Subject<boolean> = new Subject<boolean>();
    icons = IconCss;
    iconsCss = {
        pagerLeftArrow: this.icons.left,
        pagerRightArrow: this.icons.right,
        pagerPrevious: this.icons.previous,
        // pagerNext: this.icons.next,
    };
    isLoading = false;
    offset = 0;
    searchKey: any;
    enableIcon = false;
    isEnableBack = false;
    label = {
        title_en: "Tags",
        title_ar: "المراجعات",
    };
    selectedRanges = { startDate: null, endDate: null };
    form: FormGroup;

    // showAction: any;
    // childOrderID: any;
    // flagImage: any = [];
    constructor(
        private catalogueService: CatalogueService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        public translationService: TranslationService,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private router: Router,
        private customerService: CustomerService,
        private sharedService: SharedService,
        public coreService: CoreService,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    async ngOnInit(): Promise<void> {
        this.form = this.fb.group({
            id: [null],
            name: [null],
            // status: [this.reviewStatus]
        });
        await this.getHandling_instructions();
        this.cdr.markForCheck();

        this.resetFormSubject.subscribe(async (response) => {
            if (response) {
                await this.getHandling_instructions();
                this.cdr.markForCheck();
            }
        });

        this.form.valueChanges
            .pipe(debounceTime(400))
            .subscribe(async (data) => {
                this.page.pageNumber = 0;
                this.page.size = 10;
                this.utilitiesService.startLoader();
                await this.getHandling_instructions();
                this.cdr.markForCheck();
            });
    }

    onActivateViewOrder(event) {
        if (event.type === "mouseenter") {
            // this.showAction = event.row.id;
        }
        if (event.type == "click") {
            if (
                event.event.srcElement.nodeName.toLowerCase() == "strong" ||
                event.event.srcElement.nodeName.toLowerCase() == "mat-icon" ||
                event.event.srcElement.nodeName.toLowerCase() == "button" ||
                event.event.srcElement.nodeName.toLowerCase() == "span"
            ) {
            } else {
                if (
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.OrderDetail
                    )
                ) {
                    // TODO Row click here
                    // this.viewOrder(event.row.id, event.row.invoice_no);
                } else {
                    this.utilitiesService.showPermissionDeinedMessage();
                }
            }
        }
    }

    async getHandling_instructions(
        limit = this.page.size,
        offset = this.offset,
        form = this.form ? this.form.controls : undefined
    ) {
        try {
            this.utilitiesService.startLoader();
            const Handling_instructionsList = await this.catalogueService
                .getHandling_instructions(limit, offset, this.searchKey, form)
                .toPromise();
            if (Handling_instructionsList) {
                this.page.totalElements = Handling_instructionsList.count;
                this.Handling_instructionsList =
                    Handling_instructionsList.results;

                this.utilitiesService.stopLoader();
            } else {
                this.utilitiesService.stopLoader();
            }
        } catch {
        } finally {
        }
    }
    async pageChange(event) {
        this.page.pageNumber = event.offset;
        this.offset = this.page.pageNumber * this.page.size;
        document.getElementById("dashboard-project").scrollIntoView();
        await this.getHandling_instructions();
        this.cdr.markForCheck();
    }
    async limitChange() {
        await this.getHandling_instructions();
        this.cdr.markForCheck();
    }
    getWidth() {
        this.Handling_instructionsList = [...this.Handling_instructionsList];
        return 100;
    }
    isEditEvent(event) {
        // this.label.title_ar = 'مشاهدة الطلب';
        // this.label.title_en =
        //     'Edit Customer - ' + `${event.first_name} ${event.last_name}`;
    }
    editHandleInstruction(row) {
        let isMobile = this.utilitiesService.isMobile();

        const dialogRef = this.dialog.open(
            CreateHandlingInstructionsComponent,
            {
                data: row,
                maxWidth: "",
                width: "70vw",
                height: "auto",
            }
        );
        dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
                let addedMsg = this.translate.instant(
                    `Handling Instructions Updated successfully`
                );

                try {
                    // this.catalogueService.deleteTag(id).subscribe((data) => {
                    this.utilitiesService.showSuccessToast(addedMsg);
                    await this.getHandling_instructions();
                    this.cdr.markForCheck();
                    //  this.selectedRanges.markfor
                    // });
                } catch {
                } finally {
                }
            }
        });
    }
    addHandling_instructions() {
        let isMobile = this.utilitiesService.isMobile();

        const dialogRef = this.dialog.open(
            CreateHandlingInstructionsComponent,
            {
                maxWidth: "",
                width: "70vw",
                height: "auto",
            }
        );
        dialogRef.afterClosed().subscribe(async (resp) => {
            console.log(resp);
            if (resp) {
                let addedMsg = this.translate.instant(
                    `Handling Instructions added successfully`
                );

                try {
                    // this.catalogueService.deleteTag(id).subscribe((data) => {
                    this.utilitiesService.showSuccessToast(addedMsg);
                    await this.getHandling_instructions();
                    this.cdr.markForCheck();
                } catch {
                } finally {
                }
            }
        });
    }
    deleteHandling_instruction(id: number) {
        let content = `Are you sure, Do you want to delete it? `;
        let heading = "Delete";
        let fromApp = false;
        let isMobile = this.utilitiesService.isMobile();
        let size = {
            width: "25vw",
            height: "20vh",
        };
        if (isMobile) {
            size.height = "100%";
            size.width = "100%";
        }
        const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });
        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                let deleteMsg = this.translate.instant(
                    `Tag deleted successfully`
                );
                try {
                    this.catalogueService
                        .deleteHandling_instructions(id)
                        .subscribe(async (data) => {
                            console.log(data);
                            this.utilitiesService.showSuccessToast(deleteMsg);
                            await this.getHandling_instructions();
                            this.cdr.markForCheck();
                        });
                } catch {
                } finally {
                }
            }
        });
    }

    async approve(id) {
        //     const formMap = {
        //         status : 2
        //     };
        //     this.utilitiesService.startLoader();
        //     const response = await this.reviewService.changeReviewStatus(id, formMap).toPromise();
        //     await this.getHandling_instructions();
        this.utilitiesService.stopLoader();
    }
    viewCustomer(customerEditID) {
        // this.customerEditID = customerEditID;
        // this.orderID = '';
        // this.childOrderID = '';
        // this.enableIcon = true;
        // this.isEnableBack = true;
        // this.isView = false;
        // this.isViewCustomer = true;
        // this.label.title_ar = 'تفاصيل العميل';
        // this.label.title_en = 'Customer Details';
        // this.viewCustomerClick.emit(customerEditID);
    }
    async search(searchKey) {
        this.searchKey = searchKey;
        await this.getHandling_instructions();
        this.cdr.markForCheck();
    }
}
