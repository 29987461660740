<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
            [formGroup]="statusForm">
            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back</mat-icon>
                    <label class="highlight-text-color">{{'Status Page' | translate}}</label>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <button (click)="save()" type="submit" mat-flat-button style="position: absolute;right: 16px;"
                        class="primary-bg-color">{{'Save' | translate}}</button>
                </div>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                [@animate]="{value:'*',params:{duration:'400ms',y:'70px'}}">
                <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 center-container" fxLayoutAlign="start stretch"
                    fxLayout="column" fxLayoutGap="1em">
                    <label class="highlight-text-color">{{'Status Page Settings' | translate}}</label>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                        <div fxFlex="1 1 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="0px">
                            <mat-form-field appearance="outline" fxFlex="1 1 auto">
                                <mat-label>{{'Status' | translate}}</mat-label>
                                <mat-select matTooltip="{{'help_text_Status' | translate}}" formControlName="status">
                                    <mat-option *ngFor="let status of status" [value]="status.id">{{status.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- <mat-form-field appearance="outline" fxLayoutAlign="start flex-end">
                                <mat-select formControlName="language">
                                    <mat-option value="en">{{'EN' | translate}}</mat-option>
                                    <mat-option value="ar">{{'AR' | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field> -->
                        </div>
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                            fxLayout.lt-md="column" fxLayoutGap="1em">
                            <!-- <div fxFlex="1 1 auto" *ngIf="statusForm.get('status').value!=2">
                                <mat-form-field *ngIf="statusForm.get('language').value=='en'" appearance="outline"
                                    fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Maintenance Message (EN)'| translate}}
                                    </mat-label>
                                    <textarea matTooltip="{{'help_text_Maintenance_Message' | translate}}"
                                        formControlName="maintenance_message_en" matInput rows="10"></textarea>
                                </mat-form-field>
                                <mat-form-field *ngIf="statusForm.get('language').value=='ar'" appearance="outline"
                                    fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Maintenance Message (AR)'| translate}}
                                    </mat-label>
                                    <textarea matTooltip="{{'help_text_Maintenance_Message' | translate}}"
                                        formControlName="maintenance_message_ar" matInput rows="10"></textarea>
                                </mat-form-field>
                            </div> -->
                            <div fxFlex="1 1 100%" *ngIf="statusForm.get('status').value==2">
                               <div class="fuse-card note-card" >
                                    {{maintenanceNoteMessage}}
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>