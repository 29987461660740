import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { IconCss } from "app/main/ngxDatatable-Icons/icons";
import { AppRoutes } from "app/routes";
import { CoreService } from "app/services/core-service";
import { CustomerService } from "app/services/customer/customer.service";
import { DeliveryService } from "app/services/delivery.service";
import { CampaignsService } from "app/services/marketing/campaigns.service";
import { CartService } from "app/services/orders/cart.service";
import { OrderService } from "app/services/orders/order.service";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { debounceTime, filter } from "rxjs/operators";

import { Location } from "@angular/common";
import { PriceRuleService } from "../../../services/price-rules/price-rules.service";
import { Subject } from "rxjs";
import { AssignDeliveryAgentComponent } from "../assign-delivery-agent/assign-delivery-agent.component";
import { DaterangepickerDirective } from "ngx-daterangepicker-material";
import * as moment from "moment";
import { ChangeDeliveryStatusComponent } from "../change-delivery-status/change-delivery-status.component";
@Component({
    selector: "app-delivery",
    templateUrl: "./delivery.component.html",
    styleUrls: ["./delivery.component.scss"],
    animations: fuseAnimations,
})
export class DeliveryComponent implements OnInit {
    @ViewChild(DaterangepickerDirective, { static: false })
    pickerDirective: DaterangepickerDirective;
    id: number;
    isLoading = true;
    deliveryAgentList: any;

    form: FormGroup;
    searchKey = "";
    offset: number = 0;
    page = {
        size: 10,
        totalElements: 0,
        pageNumber: 0,
    };
    PaymentMethodeList: any;
    enableIcon: boolean = false;
    isEnableBack = false;
    label = {
        title_en: "Delivery Order List",
        title_ar: "Delivery Order List",
    };
    icons = IconCss;
    iconsCss = {
        pagerLeftArrow: this.icons.left,
        pagerRightArrow: this.icons.right,
        pagerPrevious: this.icons.previous,
        pagerNext: this.icons.next,
    };
    orderStatus = [
        { id: 1, value: "Recived" },
        { id: 2, value: "Payment Pending" },
        { id: 3, value: "Confirmed" },
        { id: 4, value: "Packed" },
        { id: 5, value: "Picking Started" },
        { id: 6, value: "Shipped" },
        { id: 7, value: "Delivered " },
        { id: 8, value: "Cancelled " },
        { id: 9, value: "Returned " },
        { id: 10, value: "Callback " },
    ];
    deliveryList: any;
    deliveryStatusList: any;
    selectedDelivery = [];
    selected = [];
    isSelected;
    ranges: any = {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
        ],
    };
    invalidDates: moment.Moment[] = [
        moment().add(2, "days"),
        moment().add(3, "days"),
        moment().add(5, "days"),
    ];

    isInvalidDate = (m: moment.Moment) => {
        return this.invalidDates.some((d) => d.isSame(m, "day"));
    };
    selectedRanges = { startDate: null, endDate: null };
    constructor(
        private router: Router,

        private orderService: OrderService,
        public utilitiesService: UtilitiesService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private campaignsService: CampaignsService,
        private translate: TranslateService,
        public translationService: TranslationService,
        private customerService: CustomerService,
        public coreService: CoreService,
        private deliveryService: DeliveryService,
        private priceRuleService: PriceRuleService,
        private cdr: ChangeDetectorRef,
        public location: Location,
        public fb: FormBuilder
    ) {
        this.label.title_ar = "Delivery Order List";
        this.label.title_en = "Delivery Order List";
        this.initializeForm();
    }
    openDatepicker() {
        setTimeout(() => {
            this.pickerDirective.open();
        }, 200);
    }
    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        this.utilitiesService.startLoader();
        // await this.getDeliveryAgent();
        await this.getDeliveryStatus();
        await this.getPaymentMethodeList();
        await this.getDelivery();

        this.isLoading = false;
        this.utilitiesService.stopLoader();
        this.cdr.detectChanges();
        this.form.valueChanges.pipe(debounceTime(1000)).subscribe((data) => {
            this.page.pageNumber = 0;
            this.page.size = 10;
            this.utilitiesService.startLoader();
            this.getDelivery();
        });
    }
    clearFilter() {
        this.router.navigate([]);
    }
    getPaymentMethodeList() {
        this.priceRuleService
            .getPaymentMethod()
            .subscribe(
                (paymentMethod) => (this.PaymentMethodeList = paymentMethod)
            );
    }
    clearDate() {
        this.pickerDirective.clear();
        this.datesUpdated(this.selectedRanges);
    }
    datesUpdated(event, fromTemplate = false) {
        if (fromTemplate) {
            setTimeout(() => {
                this.pickerDirective.hide();
            }, 200);
        }
        let dates = this.convertDate(event);
        this.form.patchValue({
            created_after: dates.start_date,
            created_before: dates.end_date,
        });
    }
    convertDate(event) {
        let start_date: any = "";
        let end_date: any = "";
        if (event.startDate) {
            start_date = moment(event.startDate).format("MM/DD/YYYY");
        }
        if (event.endDate) {
            end_date = moment(event.endDate).format("MM/DD/YYYY");
        }
        return { start_date, end_date };
    }
    initializeForm() {
        // this.isLoading = true;

        this.form = this.fb.group({
            customer: [null],
            name: [null],
            contact_no: [null],
            external_order_id: [null],
            area_name: [null],
            area_id: [null],
            invoice_no: [null],
            order_date: [null],
            delivery_status: [null],
            order_id: [null],
            order_status: [null],
            delivery_agent_id: [null],
            is_paid: [null],
            payment_method: [null],
            ordering: [null],
            created_after: [],
            created_before: [],
        });
        this.isLoading = false;
    }
    async getDeliveryStatus() {
        // this.isLoading = true;
        await this.deliveryService.getDeliveryStatustList().subscribe((res) => {
            if (res) {
                this.deliveryStatusList = res;
            }
        });
        // this.isLoading = false;
    }
    changeDeliveryStatus(deliveryDetails) {
        let isMobile = this.utilitiesService.isMobile();
        let size = {
            width: "45vw",
            height: "25vh",
        };
        if (isMobile) {
            size.height = "100%";
            size.width = "100%";
        }
        let deliveryId = deliveryDetails.id;
        const dialogRef = this.dialog.open(ChangeDeliveryStatusComponent, {
            data: { deliveryId, deliveryDetails },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });

        dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
                try {
                    this.utilitiesService.showSuccessToast(
                        "the DeliveryStatus Has been updated Successful"
                    );
                    await this.getDelivery();
                } catch {
                    console.log("catch");
                }
                this.isLoading = false;
                this.utilitiesService.stopLoader();
            }
        });
    }
    async getDelivery(
        limit = this.page.size,
        offset = this.offset,
        form = this.form ? this.form.controls : undefined
    ) {
        this.form.patchValue(
            {
                order_date_after: null,
                order_date_before: null,
            },
            { onlySelf: true, emitEvent: false }
        );
        this.isLoading = true;
        this.utilitiesService.startLoader();
        let response = await this.deliveryService
            .getDeliveryList(limit, offset, this.searchKey, form)
            .subscribe((response) => {
                try {
                    this.page.totalElements = response.count;
                    this.deliveryList = response.results;
                    this.deliveryList.forEach(async (element) => {
                        element.selected = false;
                    });
                } catch (e) {
                    console.log("error");
                    console.log(e);
                } finally {
                    console.log("finally");
                    this.isLoading = false;
                    this.utilitiesService.stopLoader();
                    this.cdr.detectChanges();
                }
            });
        this.isLoading = false;
        this.utilitiesService.stopLoader();
        this.cdr.detectChanges();
    }
    sort(sortParam) {
        let sortvalue = "";
        let sortFormValue = this.form.get("ordering").value;

        if (sortFormValue == "order_date") {
            sortvalue = "-order_date";
        } else {
            sortvalue = "order_date";
        }

        this.form.get("ordering").setValue(sortvalue);
    }
    onClickOrder(order_id) {
        this.router.navigate([`/order/${order_id}`]);
    }

    AddDeliveryAgentForSelected() {
        if (this.selected.length != 0) {
            let multiDeliveryId = this.selected;
            let isMobile = this.utilitiesService.isMobile();
            let size = {
                width: "45vw",
                height: "25vh",
            };
            if (isMobile) {
                size.height = "100%";
                size.width = "100%";
            }
            const dialogRef = this.dialog.open(AssignDeliveryAgentComponent, {
                data: { multiDeliveryId },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });

            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp) {
                    try {
                        console.log(resp);
                        this.selected = [];
                        this.utilitiesService.showSuccessToast(resp);
                        await this.getDelivery();
                    } catch (err) {
                        console.log("catch");
                        console.log(err);
                    }
                }
                this.isLoading = false;
                this.utilitiesService.stopLoader();
            });
            this.isLoading = false;
            this.utilitiesService.stopLoader();
        } else {
            let msg =
                "Please Select Orders So we Can assign Delivery Agent to them ";
            this.utilitiesService.showWarningToast(msg);
            // console.log("select some element to add delivery agent for them");
        }
    }

    onCheckboxChangeFn(event) {
        console.log("onCheckboxChangeFn");
        console.log(event);
    }

    clickCheck(row) {
        console.log(row);
        let i = this.selected.findIndex((x) => x == row.id);

        let valid = this.selected.lastIndexOf(row.id) == -1;

        if (!valid) {
            this.selected = this.selected.filter((item) => item !== row.id);
            row.selected = false;

            // we will remove it
        } else {
            this.selected.push(row.id);
            row.selected = true;
        }
        console.log(this.selected);
    }
    assignDeliveryAgent(deliveryDetails) {
        let isMobile = this.utilitiesService.isMobile();
        let size = {
            width: "45vw",
            height: "25vh",
        };
        if (isMobile) {
            size.height = "100%";
            size.width = "100%";
        }
        const dialogRef = this.dialog.open(AssignDeliveryAgentComponent, {
            data: { deliveryDetails },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });

        dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
                try {
                    this.selected = [];
                    this.utilitiesService.showSuccessToast(resp);
                    await this.getDelivery();

                    console.log("resp");
                    console.log(this.selected);
                } catch {
                    console.log("catch");
                    console.log(this.selected);
                }
                this.isLoading = false;
                this.utilitiesService.stopLoader();
            }
        });
    }

    SavePdf(id) {
        this.orderService.getOrderPdf(id);
    }
    SaveInvoicePdf(id) {
        this.orderService.getInvoicePdf(id);
    }

    pageChange(event) {
        this.page.pageNumber = event.offset;
        this.offset = this.page.pageNumber * this.page.size;
        this.cdr.markForCheck();
        document.getElementById("dashboard-project").scrollIntoView();
        this.getDelivery();
    }
    onActivateViewOrder(x) { }

    limitChange() {
        this.getDelivery();
    }

    search(searchKey) {
        this.searchKey = searchKey;
        this.getDelivery();
    }
    viewDelivery(delivery_id) {
        this.router.navigate([`/delivery/${delivery_id}`]);
    }
}
