import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: "root",
})
export class SliderService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    getProducts(search = "", ids: any = "") {
        let params = new HttpParams();
        if (search) {
            params = params.set("search", search);
        }
        if (ids) {
            params = params.set("ids", ids);
        }

        return this.apiService.get(
            this.sharedService.sliderProducts,
            params,
            undefined
        );
    }

    getSearchedData(searchData, ids: any = "") {
        let params = new HttpParams();
        if (searchData.searchKey) {
            params = params.set("search", searchData.searchKey);
        }
        if (ids) {
            params = params.set("ids", ids);
        }

        return this.apiService.get(searchData.api, params, undefined);
    }
    getPosts(search = "", ids: any = "") {
        let params = new HttpParams();
        if (search) {
            params = params.set("search", search);
        }
        if (ids) {
            params = params.set("ids", ids);
        }

        return this.apiService.get(
            this.sharedService.postList,
            params,
            undefined
        );
    }
    getCategory(search = "", ids: any = "") {
        let params = new HttpParams();
        if (search) {
            params = params.set("name", search);
        }
        if (ids) {
            params = params.set("ids", ids);
        }
        return this.apiService.get(
            this.sharedService.sliderCategory,
            params,
            undefined
        );
    }
    getBrand(search = "", ids: any = "") {
        let params = new HttpParams();
        if (search) {
            params = params.set("name", search);
        }
        if (ids) {
            params = params.set("ids", ids);
        }
        return this.apiService.get(
            this.sharedService.sliderBrand,
            params,
            undefined
        );
    }
    getSliders(limit: number = 10, offset: number = 0, search = "", form?) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        return this.apiService.get(
            this.sharedService.getSliders,
            params,
            undefined
        );
    }
    addSliders(form) {
        return this.apiService.post(
            this.sharedService.addSliders,
            form,
            undefined
        );
    }

    updateSlider(form) {
        return this.apiService.put(
            this.sharedService.updateSliders + `${form.slider.id}/`,
            form,
            undefined
        );
    }

    deleteSlider(id) {
        return this.apiService.delete(
            this.sharedService.deleteSlider + `${id}/`,
            undefined
        );
    }

    deleteSlide(id) {
        return this.apiService.delete(
            this.sharedService.deleteSlide + `${id}/`,
            undefined
        );
    }
    getSliderDetail(id) {
        return this.apiService.get(
            this.sharedService.sliderDetail + `${id}/`,
            undefined
        );
    }
    getElementDetail(slider, url, isMultiple = false) {
        let id = "?id";
        if (isMultiple) {
            id = "?ids";
        }
        return this.apiService.get(url + `${id}=${slider.content}`, undefined);
    }

    getCategories(search = "", limit = 10, offset = 0) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("name", search);
        return this.apiService.get(
            this.sharedService.sliderCategory,
            params,
            undefined
        );
    }
    getBrands(search = "", limit = 10, offset = 0) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("name", search);
        return this.apiService.get(
            this.sharedService.sliderBrand,
            params,
            undefined
        );
    }
    getProductswithPagination(search = "", limit = 10, offset = 0) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        return this.apiService.get(
            this.sharedService.sliderProducts,
            params,
            undefined
        );
    }
    getTags(name) {
        let params = new HttpParams();
        params = params.set("search", name);
        return this.apiService.get(this.sharedService.tags, params, undefined);
    }

    getHandleInstructions(name) {
        let params = new HttpParams();
        params = params.set("search", name);
        return this.apiService.get(
            this.sharedService.handlingInstructions,
            params,
            undefined
        );
    }
}
