import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnInit,
    ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { MediaService } from "app/services/media.service";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";

@Component({
    selector: "app-fullscreen-addition-css",
    templateUrl: "./fullscreen-addition-css.component.html",
    styleUrls: ["./fullscreen-addition-css.component.scss"],
})
export class FullscreenAdditionCssComponent implements OnInit, AfterViewInit {
    css: FormControl;
    inlineSave = false;
    @ViewChild("editor") editor;
    heading = "";
    constructor(
        public dialogRef: MatDialogRef<FullscreenAdditionCssComponent>,
        private formBuilder: FormBuilder,
        private mediaService: MediaService,
        public utilitiesService: UtilitiesService,
        private fb: FormBuilder,
        private appService: AppService,
        private translate: TranslateService,
        private changeRef:ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.css = data.formControl;
        this.heading = data.heading;
        if (data && data.inlineSave) {
            this.inlineSave = true;
        } else {
            this.inlineSave = false;
        }
    }

    ngOnInit(): void {
        this.data.formControl.valueChanges.subscribe(res=>{
            this.changeRef.detectChanges()
        })
    }

    close() {
        this.dialogRef.close(this.css);
    }
    async ngAfterViewInit(): Promise<void> {
        this.editor.getEditor().setOptions({
            showLineNumbers: true,
            tabSize: 2,
        });
        if (this.heading == "HTML") {
            this.editor.mode = "html";
        } else {
            this.editor.mode = "css";
        }
    }
    async save() {
        let form = {
            css: this.css.value,
        };
        if(this.data.formControl.valid){
            const resp = await this.appService.addAppSettings(form).toPromise();

            if (resp) {
                this.utilitiesService.formChangedOrNot(false);
                let successMsg = this.translate.instant("Css updated successfully");
                this.utilitiesService.showSuccessToast(successMsg);
                // this.close();
            }
        }else{
            this.utilitiesService.showErrorToast('Css should not be more than 500 character.')
        }
    }
}
