import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "../api.service";
import { DownloadTypes } from "app/downloadTypes";

@Injectable({
    providedIn: "root",
})
export class CustomerService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    getElements() {
        return this.apiService.get(
            this.sharedService.pageElements,
            undefined,
            undefined
        );
    }
    getCustomerDetail(id: any) {
        return this.apiService.get(
            this.sharedService.customerList + `${id}/`,
            undefined
        );
    }
    getCustomerOrders(id: any, limit: number = 10, offset: number = 0) {
        let params = new HttpParams();
        params = params
            .set("customer", id)
            .set("limit", limit.toString())
            .set("offset", offset.toString());
        return this.apiService.get(
            this.sharedService.customerOrders,
            params,
            undefined
        );
    }

    getCustomerList(
        limit: number = 10,
        offset: number = 0,
        search: any = "",
        form: any = null,
        is_guest: any = null,
        isDownalod: boolean = false
    ) {
        let params = new HttpParams();
        if (is_guest != "") {
            params = params.set("is_guest", is_guest);
        }
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (const key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        if (isDownalod) {
            params = params.set("download", "true");
        }

        return this.apiService.get(
            this.sharedService.customerAddress,
            params,
            undefined
        );
    }

    getCustomerAddresses(id: any) {
        return this.apiService.get(
            this.sharedService.customerAddress + `${id}/`,
            undefined
        );
    }

    changePassword(id: any) {
        return this.apiService.get(
            this.sharedService.customerChangePassword + `${id}`,
            undefined,
            undefined
        );
    }
    changePasswordByAdmin(form: any) {
        return this.apiService.post(
            this.sharedService.customerChangePassword,
            form,
            undefined
        );
    }
    sendNotification(form: any = null) {
        return this.apiService.post(
            this.sharedService.sendNotification,
            form,
            undefined
        );
    }
    getMembershipList(
        limit = 10,
        offset = 0,
        search = "",
        form: any = null,
        customer_id: any = null
    ) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (customer_id) {
            params = params.set("customer_id", customer_id);
        }
        if (form) {
            for (const key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        return this.apiService.get(
            this.sharedService.membership,
            params,
            undefined
        );
    }
    getPlans() {
        const limit = 9999;
        const offset = 0;
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString());
        return this.apiService.get(
            this.sharedService.plans,
            undefined,
            undefined
        );
    }
    addMembership(form) {
        return this.apiService.post(
            this.sharedService.addMembership,
            form,
            undefined
        );
    }

    getMembershipDetails(id) {
        return this.apiService.get(
            this.sharedService.membership + `${id}/`,
            undefined
        );
    }
    updateMembership(id, form) {
        return this.apiService.put(
            this.sharedService.membership + `${id}/`,
            form,
            undefined
        );
    }

    getOrderCount(id) {
        return this.apiService.get(
            this.sharedService.customerOrderCount + `?customer_id=${id}`,
            undefined
        );
    }
    updateCustomer(id, form) {
        return this.apiService.patch(
            this.sharedService.customerAddress + `${id}/`,
            form,
            undefined
        );
    }

    getAudience(limit = 10, offset = 0, search = "", form?) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (const key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        return this.apiService.get(
            this.sharedService.audience,
            params,
            undefined
        );
    }
    getAudienceDetail(id) {
        return this.apiService.get(
            this.sharedService.audience + `${id}/`,
            undefined
        );
    }
    addAudience(form) {
        return this.apiService.post(
            this.sharedService.audience,
            form,
            undefined
        );
    }
    updateAudience(form, id) {
        return this.apiService.put(
            this.sharedService.audience + `${id}/`,
            form,
            undefined
        );
    }

    getConditions(limit = 10, offset = 0, search = "", form?, audience?) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (const key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        if (audience) {
            params = params.set("audience_id", audience);
        }
        return this.apiService.get(
            this.sharedService.conditionList,
            params,
            undefined
        );
    }

    getConditionDetail(id) {
        return this.apiService.get(
            this.sharedService.conditionList + `${id}/`,
            undefined
        );
    }

    addConditions(form) {
        return this.apiService.post(
            this.sharedService.createCondition,
            form,
            undefined
        );
    }
    updateCondition(form, id) {
        return this.apiService.put(
            this.sharedService.updateCondition + `${id}/`,
            form,
            undefined
        );
    }
    deleteCondition(id) {
        return this.apiService.delete(
            this.sharedService.conditionList + `${id}/`,
            undefined
        );
    }
    deleteAudience(id) {
        return this.apiService.delete(
            this.sharedService.audience + `${id}/`,
            undefined
        );
    }
    deleteRule(id) {
        return this.apiService.delete(
            this.sharedService.deleteRule + `${id}/`,
            undefined
        );
    }

    getConditionFilter() {
        return this.apiService.get(
            this.sharedService.conditionFilter,
            undefined
        );
    }

    filterValueAPI(path) {
        const limit = 9999;
        const offset = 0;
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString());
        return this.apiService.get(path, params);
    }

    blockCustomer(customer, reason?) {

        const form = {
            'contact_no_country_id': customer.contact_no_country_id,
            'contact_no': customer.contact_no,
            'email': customer.email,
            'reason': reason
        }

        return this.apiService.post(this.sharedService.blockCustomer, form);

    }

    unblockCustomer(id) {
        return this.apiService.delete(this.sharedService.blockCustomer + `${id}/`, undefined);
    }


    getAllBlockedCustomers(
        limit: number = 10,
        offset: number = 0,
        search: any = '',
        form: any = null,
        is_guest: any = null,
        isDownalod: boolean = false
    ) {
        let params = new HttpParams();
        if (is_guest != '') {
            params = params.set('is_guest', is_guest);
        }
        params = params
            .set('limit', limit.toString())
            .set('offset', offset.toString())
            .set('search', search);
        if (form) {
            for (const key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ''}`
                );
            }
        }
        
        return this.apiService.get(
            this.sharedService.blockCustomer,
            params,
            undefined
        );
    }
}
