import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
    selector: "app-delete-button",
    templateUrl: "./delete-button.component.html",
    styleUrls: ["./delete-button.component.scss"],
})
export class DeleteButtonComponent implements OnInit {
    @Output() deleteButtonClick: EventEmitter<void> = new EventEmitter<any>();
    constructor() {}

    ngOnInit(): void {}

    delete() {
        this.deleteButtonClick.emit();
    }
}
