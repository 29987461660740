<div class="order_detail" fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch">
    <app-breadcrumbs [label]="title" (back)="handleCancel()" [isEnableBack]="'true'" [enableIcon]="'true'"
        [order]="'true'" [order_id]="deliveryDetails?.order?.id" [order_status]="deliveryDetails?.order?.order_status">
    </app-breadcrumbs>

    <div id="dashboard-project" class="page-layout simple right-sidebar" fxLayout="column"
        [@animate]="{ value: '*', params: { duration: '400ms', y: '70px' } }">
        <div class="center" fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
            fusePerfectScrollbar>
            <div class="content p-16 remove-scroll-bar" fxFlex="1 1 auto" fxLayoutAlign="start stretch"
                fxLayout="column" fxLayoutGap="1em">
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <div id="dashboard-project" class="page-layout" fxLayout="column">
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                                fusePerfectScrollbar>
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="0px">
                                    <div fxFlex="0 0 auto" fxLayoutAlign="flex-end stretch" fxLayout.lt-md="column"
                                        fxLayout.gt-sm="row" fxLayoutGap="8px" class="pb-8">
                                        <!-- <button
                                            *ngIf="
                                                !cart &&
                                                !deliveryDetails?.customer
                                                    ?.contact_no &&
                                                coreService.checkPermission(
                                                    utilitiesService.Permission
                                                        .SendNotification
                                                )
                                            "
                                            mat-stroked-button
                                            class="highlight-text-color"
                                            (click)="sendNotification()"
                                        >
                                            <mat-icon
                                                class="highlight-text-color"
                                                title="Send Message"
                                                matPrefix
                                            >
                                                send </mat-icon
                                            >{{ "Send Message" | translate }}
                                        </button> -->
                                        <button *ngIf="!cart" mat-stroked-button class="action-change-password" (click)="
                                                checkStatus(deliveryDetails?.id)
                                            ">
                                            <mat-icon class="action-change-password">cached</mat-icon>
                                            {{ "Check Status" | translate }}
                                        </button>
                                    </div>
                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                        fxLayout.gt-sm="row" fxLayoutGap="1em">
                                        <div fxFlex.gt-sm="1 1 50%" fxFlex.lt-md="0 0 auto"
                                            fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                            <!-- first section -->
                                            <div class="fuse-card auto-width card-height" fxFlex.gt-sm="0 0 auto"
                                                fxFlex.lt-md="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                                fxLayoutGap="0px">
                                                <div fxFlex="0 0 auto" class="p-8 customer-header"
                                                    fxLayoutAlign="space-between center" fxLayout="row"
                                                    fxLayoutGap="4px">
                                                    <div class="mat-subheading-2 mb-0">
                                                        {{
                                                        "Customer"
                                                        | translate
                                                        }}
                                                    </div>
                                                </div>
                                                <div fxFlex="0 0 auto" class="p-16" id="customer"
                                                    fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="8px">
                                                    <div *ngIf="
                                                            deliveryDetails
                                                                ?.order
                                                                ?.customer
                                                                ?.first_name ||
                                                            deliveryDetails
                                                                ?.order
                                                                ?.customer
                                                                ?.middle_name ||
                                                            deliveryDetails
                                                                ?.order
                                                                ?.customer
                                                                ?.last_name
                                                        " class="customer-name p-8" fxFlex="0 0 auto"
                                                        fxLayoutAlign="space-between center" fxLayout="row"
                                                        fxLayoutGap="4px">
                                                        <!-- <mat-icon>perm_contact_calendar</mat-icon> -->
                                                        <div style="
                                                                text-transform: uppercase;
                                                            ">
                                                            {{
                                                            deliveryDetails
                                                            ?.order
                                                            ?.customer
                                                            ?.first_name
                                                            }}
                                                            {{
                                                            deliveryDetails
                                                            ?.order
                                                            ?.customer
                                                            ?.middle_name
                                                            }}
                                                            {{
                                                            deliveryDetails
                                                            ?.order
                                                            ?.customer
                                                            ?.last_name
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div *ngIf="
                                                            deliveryDetails
                                                                ?.order
                                                                ?.customer
                                                                ?.email ||
                                                            deliveryDetails
                                                                ?.order
                                                                ?.customer
                                                                ?.contact_no
                                                        " fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                        fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="4px">
                                                        <div *ngIf="
                                                                deliveryDetails
                                                                    ?.order
                                                                    ?.customer
                                                                    ?.email
                                                            " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Email"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item">
                                                                {{
                                                                deliveryDetails
                                                                ?.order
                                                                ?.customer
                                                                ?.email
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Contact Number"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item" fxFlex="1 1 0px"
                                                                fxLayoutAlign="start center" fxLayout="row"
                                                                fxLayoutGap="4px">
                                                                <img *ngIf="
                                                                        countryFlag
                                                                    " [src]="
                                                                        countryFlag
                                                                    " style="
                                                                        height: 9px !important;
                                                                    " />
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.order
                                                                            ?.customer
                                                                            ?.contact_no_country
                                                                            ?.isd_code &&
                                                                        !utilitiesService.checkIfISDCodeExist(
                                                                            deliveryDetails
                                                                                ?.order
                                                                                ?.customer
                                                                                ?.contact_no
                                                                        )
                                                                    ">
                                                                    +{{
                                                                    deliveryDetails
                                                                    ?.order
                                                                    ?.customer
                                                                    ?.contact_no_country
                                                                    ?.isd_code
                                                                    }}
                                                                </div>
                                                                <div>
                                                                    {{
                                                                    deliveryDetails
                                                                    ?.order
                                                                    ?.customer
                                                                    ?.contact_no
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                        fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="4px">
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div class="text-bold">
                                                                {{
                                                                "Address"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <!-- house block -->
                                                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                                fxLayout.lt-md="column" fxLayout.gt-sm="row"
                                                                fxLayoutGap="4px">
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.house_no
                                                                    " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "House No"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.house_no
                                                                        ? deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.house_no
                                                                        : " - "
                                                                        }}
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.block
                                                                    " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "Block"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.block
                                                                        ? deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.block
                                                                        : " - "
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- hotel room -->
                                                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                                fxLayout.lt-md="column" fxLayout.gt-sm="row"
                                                                fxLayoutGap="4px">
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.hotel
                                                                    " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "Hotel"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.hotel
                                                                        ? deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.hotel
                                                                        : " - "
                                                                        }}
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.room_no
                                                                    " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "Room Number"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.room_no
                                                                        ? deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.room_no
                                                                        : " - "
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- floor appartment -->
                                                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                                fxLayout.lt-md="column" fxLayout.gt-sm="row"
                                                                fxLayoutGap="4px">
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.floor
                                                                    " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "Floor"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.floor
                                                                        ? deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.floor
                                                                        : " - "
                                                                        }}
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.apartment_no
                                                                    " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "Apartment Number"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.apartment_no
                                                                        ? deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.apartment_no
                                                                        : " - "
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!--  "hospital"  "wing": "" -->
                                                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                                fxLayout.lt-md="column" fxLayout.gt-sm="row"
                                                                fxLayoutGap="4px">
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.hospital
                                                                    " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "Hospital"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.hospital
                                                                        ? deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.hospital
                                                                        : " - "
                                                                        }}
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.wing
                                                                    " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "Wing"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.wing
                                                                        ? deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.wing
                                                                        : " - "
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!--  ""post_code"    "location"-->

                                                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                                fxLayout.lt-md="column" fxLayout.gt-sm="row"
                                                                fxLayoutGap="4px">
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.post_code
                                                                    " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "Post Code"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.post_code
                                                                        ? deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.post_code
                                                                        : " - "
                                                                        }}
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.location
                                                                    " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "location"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.location
                                                                        ? deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.location
                                                                        : " - "
                                                                        }}
                                                                        <a (click)="
                                                                                openLink(
                                                                                    deliveryDetails
                                                                                        ?.delivery_address
                                                                                        ?.location
                                                                                )
                                                                            " class="location_on">
                                                                            <mat-icon
                                                                                class="location_on">location_on</mat-icon></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- PACI -->
                                                            <div *ngIf="
                                                                    deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.paci
                                                                " fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                                fxLayout.lt-md="column" fxLayout.gt-sm="row"
                                                                fxLayoutGap="4px">
                                                                <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "PACI"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.paci
                                                                        ? deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.paci
                                                                        : "-"
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- building no and address -->
                                                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                                fxLayout.lt-md="column" fxLayout.gt-sm="row"
                                                                fxLayoutGap="4px">
                                                                <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "Building No"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.building_no
                                                                        ? deliveryDetails
                                                                        ?.delivery_address
                                                                        .building_no
                                                                        : " - "
                                                                        }}
                                                                    </div>
                                                                </div>
                                                                <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "Address"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.address
                                                                        ? deliveryDetails
                                                                        ?.delivery_address
                                                                        .address
                                                                        : " - "
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- STREET AVENU -->
                                                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                                fxLayout.lt-md="column" fxLayout.gt-sm="row"
                                                                fxLayoutGap="4px">
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.street
                                                                    " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "Street"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.street
                                                                        }}
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.avenue
                                                                    " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "Avenue "
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.avenue
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- AREA -->
                                                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                                fxLayout.lt-md="column" fxLayout.gt-sm="row"
                                                                fxLayoutGap="4px">
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.area
                                                                            ?.name ||
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.area
                                                                            ?.name_en ||
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.area
                                                                            ?.name_ar
                                                                    " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "Area"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        translationService.getTranslatedArea(
                                                                        deliveryDetails
                                                                        ?.delivery_address
                                                                        ?.area
                                                                        )
                                                                        }}
                                                                        <span *ngIf="
                                                                                deliveryDetails
                                                                                    ?.delivery_address
                                                                                    ?.area2
                                                                            ">
                                                                            {{
                                                                            "," +
                                                                            translationService.getTranslatedArea(
                                                                            deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.area2
                                                                            )
                                                                            }}
                                                                        </span>
                                                                        <span *ngIf="
                                                                                deliveryDetails
                                                                                    ?.delivery_address
                                                                                    ?.area3
                                                                            ">
                                                                            {{
                                                                            "," +
                                                                            translationService.getTranslatedArea(
                                                                            deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.area3
                                                                            )
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.country
                                                                            ?.name_en ||
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.country
                                                                            ?.name_ar ||
                                                                        deliveryDetails
                                                                            ?.delivery_address
                                                                            ?.country
                                                                            ?.name
                                                                    " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "Country "
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        translationService.getTranslatedArea(
                                                                        deliveryDetails
                                                                        ?.order
                                                                        ?.customer
                                                                        ?.contact_no_country
                                                                        )
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <ng-container
                                                    *ngIf="
                                                        isCustomerDynamicEmpty()
                                                    "
                                                >
                                                    <mat-divider
                                                        class="mx-16"
                                                    ></mat-divider>
                                                    <div
                                                        fxFlex="0 0 auto"
                                                        class="p-16"
                                                        fxLayoutAlign="start stretch"
                                                        fxLayout="column"
                                                        fxLayoutGap="5px"
                                                    >
                                                        <div
                                                            *ngFor="
                                                                let item of deliveryDetails
                                                                    ?.customer
                                                                    ?.dynamic_fields
                                                                    | keyvalue
                                                            "
                                                            fxFlex="1 1 auto"
                                                            fxLayoutAlign="start stretch"
                                                            fxLayout="row"
                                                            fxLayoutGap="8px"
                                                        >
                                                            <div
                                                                fxFlex="1 1 0px"
                                                                style="
                                                                    text-transform: capitalize;
                                                                "
                                                            >
                                                                {{
                                                                    getDynamicFieldName(
                                                                        item?.key
                                                                    )
                                                                }}
                                                            </div>
                                                            <div
                                                                fxFlex="1 1 0px"
                                                                class="text-end"
                                                            >
                                                                <div
                                                                    *ngIf="
                                                                        item?.value
                                                                    "
                                                                >
                                                                    <div>
                                                                        {{
                                                                            getDynamicValues(
                                                                                item?.key,
                                                                                item?.value
                                                                            )
                                                                        }}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="text-end"
                                                                    style="
                                                                        color: #ccc;
                                                                    "
                                                                    *ngIf="
                                                                        !item?.value
                                                                    "
                                                                >
                                                                    {{
                                                                        "No value"
                                                                            | translate
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container> -->
                                            </div>
                                            <!-- second section -->
                                            <div class="fuse-card auto-width card-height" fxFlex.gt-sm="0 0 auto"
                                                fxFlex.lt-md="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                                fxLayoutGap="0px">
                                                <div fxFlex="0 0 auto" class="p-8 customer-header"
                                                    fxLayoutAlign="space-between center" fxLayout="row"
                                                    fxLayoutGap="4px">
                                                    <div class="mat-subheading-2 mb-0">
                                                        {{
                                                        "Delivery Agent Details"
                                                        | translate
                                                        }}
                                                    </div>
                                                    <div *ngIf="
                                                            deliveryDetails
                                                                ?.delivery_status_obj
                                                                ?.can_assign_agent
                                                        " class="mat-subheading-2 mb-0">
                                                        <button title="{{
                                                                'Edit'
                                                                    | translate
                                                            }}" *ngIf="
                                                                deliveryDetails
                                                                    ?.delivery_status_obj
                                                                    ?.can_assign_agent
                                                            " mat-stroked-button class="highlight-text-color send-sms"
                                                            (click)="
                                                                assignDeliveryAgent(
                                                                    deliveryDetails
                                                                )
                                                            ">
                                                            <mat-icon class="send-sms-icon">edit</mat-icon>
                                                            {{
                                                            "Assign Delivery Agent "
                                                            | translate
                                                            }}
                                                        </button>
                                                        <!-- <button
                                                            *ngIf="
                                                                deliveryDetails
                                                                    ?.delivery_status_obj
                                                                    ?.can_assign_agent
                                                            "
                                                            class="action-send"
                                                            mat-menu-item
                                                            (click)="
                                                                assignDeliveryAgent(
                                                                    deliveryDetails
                                                                )
                                                            "
                                                        >
                                                            <mat-icon
                                                                class="action-send"
                                                                title="Assign Delivery Agent "
                                                                matPrefix
                                                            >
                                                                local_shipping
                                                            </mat-icon>
                                                            {{
                                                                "Assign Delivery Agent "
                                                                    | translate
                                                            }}
                                                        </button> -->
                                                    </div>
                                                </div>
                                                <div *ngIf="
                                                        !deliveryDetails?.delivery_agent_detail
                                                    " fxFlex="0 0 auto" class="p-16" id="customer"
                                                    fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="8px">
                                                    <div style="
                                                            text-transform: uppercase;
                                                        ">
                                                        No dlivery Assign Yet
                                                    </div>
                                                </div>
                                                <div *ngIf="
                                                        deliveryDetails?.delivery_agent_detail
                                                    " fxFlex="0 0 auto" class="p-16" id="customer"
                                                    fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="8px">
                                                    <div *ngIf="
                                                            deliveryDetails
                                                                ?.delivery_agent_detail
                                                                ?.first_name ||
                                                            deliveryDetails
                                                                ?.delivery_agent_detail
                                                                ?.middle_name ||
                                                            deliveryDetails
                                                                ?.delivery_agent_detail
                                                                ?.last_name
                                                        " class="customer-name p-8" fxFlex="0 0 auto"
                                                        fxLayoutAlign="space-between center" fxLayout="row"
                                                        fxLayoutGap="4px">
                                                        <!-- <mat-icon>perm_contact_calendar</mat-icon> -->
                                                        <div style="
                                                                text-transform: uppercase;
                                                            ">
                                                            {{
                                                            deliveryDetails
                                                            ?.delivery_agent_detail
                                                            ?.first_name
                                                            }}
                                                            {{
                                                            deliveryDetails
                                                            ?.delivery_agent_detail
                                                            ?.middle_name
                                                            }}
                                                            {{
                                                            deliveryDetails
                                                            ?.delivery_agent_detail
                                                            ?.last_name
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div *ngIf="
                                                            deliveryDetails
                                                                ?.delivery_agent_detail
                                                                ?.email ||
                                                            deliveryDetails
                                                                ?.delivery_agent_detail
                                                                ?.contact_no
                                                        " fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                        fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="4px">
                                                        <div *ngIf="
                                                                deliveryDetails
                                                                    ?.delivery_agent_detail
                                                                    ?.email
                                                            " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Email"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item">
                                                                {{
                                                                deliveryDetails
                                                                ?.delivery_agent_detail
                                                                ?.email
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Contact Number"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item" fxFlex="1 1 0px"
                                                                fxLayoutAlign="start center" fxLayout="row"
                                                                fxLayoutGap="4px">
                                                                <img *ngIf="
                                                                        countryFlag
                                                                    " [src]="
                                                                        countryFlag
                                                                    " style="
                                                                        height: 9px !important;
                                                                    " />
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_agent_detail
                                                                            ?.contact_no_country
                                                                            ?.isd_code &&
                                                                        !utilitiesService.checkIfISDCodeExist(
                                                                            deliveryDetails
                                                                                ?.delivery_agent_detail
                                                                                ?.contact_no
                                                                        )
                                                                    ">
                                                                    +{{
                                                                    deliveryDetails
                                                                    ?.delivery_agent_detail
                                                                    ?.contact_no_country
                                                                    ?.isd_code
                                                                    }}
                                                                </div>
                                                                <div>
                                                                    {{
                                                                    deliveryDetails
                                                                    ?.delivery_agent_detail
                                                                    ?.contact_no
                                                                    }}
                                                                </div>
                                                                <!-- <button
                                                            title="{{
                                                                'Send SMS'
                                                                    | translate
                                                            }}"
                                                            *ngIf="
                                                                deliveryDetails
                                                                    ?.customer
                                                                    ?.contact_no &&
                                                                coreService.checkPermission(
                                                                    utilitiesService
                                                                        .Permission
                                                                        .SendNotification
                                                                )
                                                            "
                                                            mat-stroked-button
                                                            class="highlight-text-color send-sms"
                                                            (click)="
                                                                sendNotification()
                                                            "
                                                        >
                                                            <mat-icon
                                                                class="send-sms-icon"
                                                                >message</mat-icon
                                                            >
                                                        </button> -->
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                        fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="4px">
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <!-- <div
                                                            class="text-bold"
                                                        >
                                                            {{
                                                                "Preferred Language"
                                                                    | translate
                                                            }}
                                                        </div> -->
                                                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                                fxLayout.lt-md="column" fxLayout.gt-sm="row"
                                                                fxLayoutGap="4px">
                                                                <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "Preferred Language"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_agent_detail
                                                                        ?.preferred_language
                                                                        ? deliveryDetails
                                                                        ?.delivery_agent_detail
                                                                        ?.preferred_language
                                                                        : " - "
                                                                        }}
                                                                    </div>
                                                                </div>
                                                                <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                                    fxLayout="column" fxLayoutGap="4px">
                                                                    <div>
                                                                        {{
                                                                        "Status"
                                                                        | translate
                                                                        }}
                                                                    </div>
                                                                    <div class="item">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_agent_detail
                                                                        ?.status ==
                                                                        1
                                                                        ? "Active"
                                                                        : "Inactive"
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- building no and address -->
                                                            <!-- actual_delivery_date -->
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Fulfillment Status -->
                                            </div>
                                            <div *ngIf="deliveryHistoreyValid" class="fuse-card auto-width card-height"
                                                fxFlex.gt-sm="0 0 auto" fxFlex.lt-md="0 0 auto"
                                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                                <div fxFlex="0 0 auto" class="p-8 customer-header"
                                                    fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="4px">
                                                    <div class="mat-subheading-2 mb-0">
                                                        {{
                                                        "Delivery History"
                                                        | translate
                                                        }}
                                                    </div>
                                                </div>
                                                <div fxFlex="0 0 auto" class="p-16" fxLayoutAlign="start stretch"
                                                    fxLayout="column" fxLayoutGap="8px">
                                                    <app-order-timeline [timelineDeliveryData]="
                                                            deliveryDetails?.delivery_history
                                                        "></app-order-timeline>
                                                </div>
                                            </div>
                                            <!-- third -->
                                        </div>
                                        <!-- product section -->
                                        <div fxFlex.gt-sm="1 1 50%" fxFlex.lt-md="0 0 auto"
                                            fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                            <!-- first section -->
                                            <div class="fuse-card auto-width card-height" fxFlex.gt-sm="0 0 auto"
                                                fxFlex.lt-md="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                                fxLayoutGap="0px">
                                                <div fxFlex="0 0 auto" class="p-8 customer-header"
                                                    fxLayoutAlign="space-between center" fxLayout="row"
                                                    fxLayoutGap="4px">
                                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row"
                                                        fxLayoutGap="4px" *ngIf="!cart" class="mat-subheading-2 mb-0">
                                                        {{
                                                        "Order Information"
                                                        | translate
                                                        }}
                                                    </div>
                                                    <div *ngIf="
                                                            deliveryDetails
                                                                ?.delivery_status_obj
                                                                ?.can_assign_agent
                                                        " class="mat-subheading-2 mb-0">
                                                        <button title="{{
                                                                'Edit'
                                                                    | translate
                                                            }}" *ngIf="
                                                                deliveryDetails
                                                                    ?.delivery_status_obj
                                                                    ?.can_assign_agent
                                                            " mat-stroked-button class="highlight-text-color send-sms"
                                                            (click)="
                                                                toOrderDetail(
                                                                    deliveryDetails
                                                                        ?.order
                                                                        ?.id
                                                                )
                                                            ">
                                                            {{
                                                            "order Page"
                                                            | translate
                                                            }}
                                                        </button>
                                                        <!-- <button
                                                        *ngIf="
                                                            deliveryDetails
                                                                ?.delivery_status_obj
                                                                ?.can_assign_agent
                                                        "
                                                        class="action-send"
                                                        mat-menu-item
                                                        (click)="
                                                            assignDeliveryAgent(
                                                                deliveryDetails
                                                            )
                                                        "
                                                    >
                                                        <mat-icon
                                                            class="action-send"
                                                            title="Assign Delivery Agent "
                                                            matPrefix
                                                        >
                                                            local_shipping
                                                        </mat-icon>
                                                        {{
                                                            "Assign Delivery Agent "
                                                                | translate
                                                        }}
                                                    </button> -->
                                                    </div>
                                                </div>
                                                <div fxFlex="0 0 auto" id="additional-info" class="p-16"
                                                    fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="8px">
                                                    <!-- delivery_mode & Fulfillment Status -->
                                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                        fxLayout.lt-md="column" fxLayoutGap="4px">
                                                        <!-- Fulfillment Status -->
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Delivery Status"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item" fxFlex="1 1 0px"
                                                                fxLayoutAlign="start center" fxLayout="row"
                                                                fxLayoutGap="4px">
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_status_obj
                                                                            ?.id
                                                                    ">
                                                                    <div [style]="{
                                                                            backgroundColor:
                                                                                deliveryDetails
                                                                                    ?.delivery_status_obj
                                                                                    ?.background_color,
                                                                            color: deliveryDetails
                                                                                ?.delivery_status_obj
                                                                                ?.font_color
                                                                        }" class="status">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.delivery_status_obj
                                                                        ?.name
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- delivery_mode -->
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Change Delivery Status"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <!-- change delivery Status -->
                                                            <div>
                                                                <button title="{{
                                                                        'Edit'
                                                                            | translate
                                                                    }}" *ngIf="
                                                                        deliveryDetails
                                                                            ?.delivery_status_obj
                                                                            ?.can_assign_agent
                                                                    " mat-stroked-button
                                                                    class="highlight-text-color send-sms" (click)="
                                                                        changeDeliveryStatus(
                                                                            deliveryDetails
                                                                        )
                                                                    ">
                                                                    <mat-icon class="send-sms-icon">edit</mat-icon>
                                                                </button>
                                                                <strong *ngIf="
                                                                        !deliveryDetails
                                                                            ?.delivery_status_obj
                                                                            ?.can_assign_agent
                                                                    ">We Can't
                                                                    Change the
                                                                    delivery
                                                                    Status at
                                                                    this
                                                                    point</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- change status & Fulfillment Status -->
                                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                        fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="4px">
                                                        <!-- change status -->
                                                        <div *ngIf="
                                                                deliveryDetails
                                                                    ?.order
                                                                    ?.order_status
                                                            " fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Order Status"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item" fxFlex="1 1 0px"
                                                                fxLayoutAlign="start center" fxLayout="row"
                                                                fxLayoutGap="4px">
                                                                <div *ngIf="
                                                                        deliveryDetails
                                                                            ?.order
                                                                            ?.order_status
                                                                    ">
                                                                    <div [style]="{
                                                                            backgroundColor:
                                                                                deliveryDetails
                                                                                    ?.order
                                                                                    ?.order_status_detail
                                                                                    ?.background_color,
                                                                            color: deliveryDetails
                                                                                ?.order
                                                                                ?.order_status_detail
                                                                                ?.font_color
                                                                        }" class="status">
                                                                        {{
                                                                        deliveryDetails
                                                                        ?.order
                                                                        ?.order_status_detail
                                                                        ?.name
                                                                        }}
                                                                    </div>
                                                                </div>
                                                                <button title="{{
                                                                        'Edit'
                                                                            | translate
                                                                    }}" *ngIf="
                                                                        deliveryDetails
                                                                            ?.order
                                                                            ?.order_status
                                                                    " mat-stroked-button
                                                                    class="highlight-text-color send-sms" (click)="
                                                                        changeOrderStatus(
                                                                            deliveryDetails
                                                                                ?.order
                                                                                ?.id,
                                                                            deliveryDetails
                                                                                ?.order
                                                                                ?.order_status
                                                                        )
                                                                    ">
                                                                    <mat-icon class="send-sms-icon">edit</mat-icon>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <!-- Remark -->
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Remark"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item" fxFlex="1 1 0px"
                                                                fxLayoutAlign="start center" fxLayout="row"
                                                                fxLayoutGap="4px">
                                                                <div>
                                                                    {{
                                                                    deliveryDetails?.remarks
                                                                    ? deliveryDetails?.remarks
                                                                    : "-"
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                        fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="4px">
                                                        <!-- change status -->

                                                        <!-- undelivered_reason -->
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "undelivered Reason"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item" fxFlex="1 1 0px"
                                                                fxLayoutAlign="start center" fxLayout="row"
                                                                fxLayoutGap="4px">
                                                                <div>
                                                                    {{
                                                                    deliveryDetails?.undelivered_reason?.name
                                                                    ? deliveryDetails?.undelivered_reason?.name
                                                                    : "-"
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Coupon &  Coupon ID  -->
                                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                        fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="4px">
                                                        <!-- Coupon -->
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Total"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item">
                                                                {{
                                                                deliveryDetails
                                                                ?.order
                                                                ?.total
                                                                }}
                                                                {{
                                                                deliveryDetails
                                                                ?.order
                                                                ?.currency
                                                                ?.code
                                                                }}
                                                            </div>
                                                        </div>

                                                        <!-- Coupon ID -->
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "is Paid"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item">
                                                                {{
                                                                deliveryDetails?.is_paid ==
                                                                true
                                                                ? "Yes"
                                                                : "No"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- order Date & delivery Date -->
                                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                        fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="4px">
                                                        <!-- order Date -->
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Order Date"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item">
                                                                {{
                                                                deliveryDetails
                                                                ?.order
                                                                ?.order_date
                                                                | date
                                                                : "dd/MM/yyyy hh mm a"
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Actual Delivery Date"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item">
                                                                <span *ngIf="
                                                                        deliveryDetails?.actual_delivery_date
                                                                    ">
                                                                    {{
                                                                    deliveryDetails?.actual_delivery_date
                                                                    | date
                                                                    : "dd/MM/yyyy hh mm a"
                                                                    }}</span>
                                                                <span *ngIf="
                                                                        !deliveryDetails?.actual_delivery_date
                                                                    ">
                                                                    Not
                                                                    Available
                                                                    yet
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- payment_method & Slot Timing -->
                                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                        fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="4px">
                                                        <!-- payment methode -->
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "payment_method"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item">
                                                                {{
                                                                deliveryDetails
                                                                ?.order
                                                                ?.payment_method
                                                                ?.name
                                                                }}
                                                            </div>
                                                        </div>
                                                        <!-- actual payment methode -->
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Actual Payment Method"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item">
                                                                {{
                                                                deliveryDetails?.actual_payment_method
                                                                ? deliveryDetails?.actual_payment_method
                                                                : " - "
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- invoice_no & Notes  -->
                                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                        fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="4px">
                                                        <!-- Invoice Number -->
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Invoice Number"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item">
                                                                <div class="item">
                                                                    {{
                                                                    deliveryDetails
                                                                    ?.order
                                                                    ?.invoice_no
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- external_order_id -->
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "External Order Id"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item">
                                                                {{
                                                                deliveryDetails
                                                                ?.order
                                                                ?.external_order_id
                                                                ? deliveryDetails
                                                                ?.order
                                                                ?.external_order_id
                                                                : "-"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Civil ID -->
                                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                        fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="4px">
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Cash Collected"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item">
                                                                {{
                                                                deliveryDetails?.cash_collected
                                                                ? deliveryDetails?.cash_collected
                                                                : "-"
                                                                }}
                                                            </div>
                                                        </div>
                                                        <!-- package_option -->
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Pending Amount"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item">
                                                                {{
                                                                deliveryDetails?.pending_amount
                                                                ? deliveryDetails?.pending_amount
                                                                : " - "
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- pickup_date -->
                                                    <!-- package_count -->
                                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                        fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="4px">
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Pickup Date"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item">
                                                                <span *ngIf="
                                                                        deliveryDetails?.pickup_date
                                                                    ">
                                                                    {{
                                                                    deliveryDetails?.pickup_date
                                                                    | date
                                                                    : "dd/MM/yyyy hh mm a"
                                                                    }}</span>
                                                                <span *ngIf="
                                                                        !deliveryDetails?.pickup_date
                                                                    ">
                                                                    Not
                                                                    Available
                                                                    yet
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <!-- package_option -->
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "Package Count"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item">
                                                                {{
                                                                deliveryDetails?.package_count
                                                                ? deliveryDetails?.package_count
                                                                : " - "
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--undelivered Reason -->
                                                    <!-- <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                        fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="4px">
                                                    
                                                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="4px">
                                                            <div>
                                                                {{
                                                                "unDelivered Reason"
                                                                | translate
                                                                }}
                                                            </div>
                                                            <div class="item">
                                                                <div class="item">
                                                                    {{
                                                                    deliveryDetails?.undelivered_reason
                                                                    ? deliveryDetails?.undelivered_reason
                                                                    : "-"
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>