<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
        <ngx-datatable (activate)="onActivateViewOrders($event)" [loadingIndicator]="isLoading" [externalPaging]="true"
            [count]="page.totalElements" [limit]="page.size" (page)='pageChange($event)' [offset]="page.pageNumber"
            [virtualization]="false" [scrollbarH]="true" [rows]="orders" [cssClasses]="iconsCss"
            class="material striped mt-8" [columnMode]="'force'" [headerHeight]="'auto'" [rowHeight]="50"
            [footerHeight]="'auto'">
            <ngx-datatable-column name="{{'Order ID' | translate}}">
                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                    <p>{{row?.id}}</p>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'Payment Method' | translate}}">
                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                    <p>{{row?.payment_method?.name}}</p>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'Delivery Mode' | translate}}">
                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                    <p>{{row?.delivery_mode?.name}}</p>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'No Of Items' | translate}}">
                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                    <p>{{row?.items?.length}}</p>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'Discount' | translate}}">
                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                    <p>{{row?.discount}}</p>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'Tax' | translate}}">
                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                    <p>{{row?.tax_amount}}</p>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'Shipping' | translate}}">
                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                    <p>{{row?.shipping}}</p>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'Total' | translate}}" [width]="getWidth()">
                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                    <p>{{row?.total}}</p>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
    <div *ngIf="orders?.length > 0">
        <small>* {{'Click the row to view details of the order' | translate}}</small>
    </div>
</div>