<div fxLayout="column" fxFlex="0 0 100%">
    <div
        class="secondary-bg-color p-16 cursor-pointer bread-crumb"
        fxLayout="row"
        fxFlex="0 0 100%"
        fxLayoutAlign="space-between center"
    >
        <div
            fxFlex="0 0 auto"
            fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="1em"
            [@animate]="{
                value: '*',
                params: { duration: '400ms', y: '70px' }
            }"
        >
            <div
                fxFlex="0 0 auto"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="10px"
            >
                <mat-icon *ngIf="isEnableBack" (click)="goBack()"
                    >arrow_back</mat-icon
                >
                <span class="label">
                    {{ translationService.getTranslatedTitle(label) }}
                    <ng-container *ngIf="shortUrl && isEnableBack">
                        <button mat-icon-button (click)="copyUrl()">
                            <mat-icon matPrefix>file_copy</mat-icon>
                        </button>
                    </ng-container>
                </span>
            </div>
        </div>
        <div fxFlex="0 0 auto">
            <div
                fxFlex="0 0 auto"
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="1em"
            >
                <div
                    fxFlex="0 0 auto"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    fxLayoutGap="0px"
                >
                    <mat-icon
                        title="{{ 'History' }}"
                        (click)="openHistory()"
                        *ngIf="
                            !order &&
                            enableIcon &&
                            coreService.checkPermission(permissionKey)
                        "
                        matPostfix
                        >access_time
                    </mat-icon>
                    <button
                        *ngIf="order"
                        mat-stroked-button
                        mat-button
                        [matMenuTriggerFor]="afterMenu"
                    >
                        {{ "Actions" | translate }}
                        <mat-icon title="View Detail" matPostfix
                            >keyboard_arrow_down</mat-icon
                        >
                    </button>
                    <mat-menu #afterMenu="matMenu" xPosition="after">
                        <!-- SAVE PDF ORDER -->
                        <button
                            class="action-btn-delete"
                            mat-menu-item
                            (click)="SavePdf(order_id)"
                        >
                            <mat-icon
                                class="action-btn-delete"
                                title="Edit Slider"
                                matPrefix
                                >print</mat-icon
                            >
                            {{ "Order" | translate }}
                        </button>

                        <!-- SaveInvoicePdf -->
                        <button
                            class="action-btn-edit"
                            mat-menu-item
                            (click)="SaveInvoicePdf(order_id)"
                        >
                            <mat-icon
                                class="action-btn-edit"
                                title="Edit Slider"
                                matPrefix
                                >print</mat-icon
                            >
                            {{ "Invoice" | translate }}
                        </button>
                        <!-- SavePicklistPdf -->
                        <button
                            class="action-btn-delete"
                            mat-menu-item
                            (click)="SavePicklistPdf(order_id)"
                        >
                            <mat-icon
                                class="action-btn-delete"
                                title="Edit Slider"
                                matPrefix
                                >print</mat-icon
                            >
                            {{ "Picklist" | translate }}
                        </button>

                        <!-- change status -->
                        <button
                            *ngIf="
                                coreService.checkPermission(
                                    utilitiesService.Permission
                                        .UpdateManageOrderDetail
                                )
                            "
                            class="action-btn-edit"
                            mat-menu-item
                            (click)="ChangeStatus(order_id, order_status)"
                        >
                            <mat-icon
                                class="action-btn-edit"
                                title="Edit Slider"
                                matPrefix
                                >sync</mat-icon
                            >
                            {{ "Change Status" | translate }}
                        </button>
                    </mat-menu>
                    <div *ngIf="campaignLogo"  class="flex justify-center logo-img">
                        <img style="height: 25px;margin: 10px;" [src]="campaignLogo" />
                    </div>
                </div>
                <!-- <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0px">
                    <div class="fuse-card fuse-card-view-site" fxLayout="row" fxLayoutAlign="space-between center"
                        fxLayoutGap="0px">
                        <div fxFlex="0 0 auto">
                            <mat-icon matPostfix>remove_red_eye</mat-icon>
                        </div>
                        <div fxFlex="0 0 auto">
                            <span>{{'View on site' | translate}}</span>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <ng-content></ng-content>
    </div>
    <mat-progress-bar color="accent" *ngIf="isLoading" mode="indeterminate">
    </mat-progress-bar>
</div>
