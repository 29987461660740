import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { AppRoutes } from "app/routes";
import { AppElements } from "appElementConstants";

@Component({
    selector: "app-app-elements",
    templateUrl: "./app-elements.component.html",
    styleUrls: ["./app-elements.component.scss"],
    animations: fuseAnimations,
})
export class AppElementsComponent implements OnInit {
    @Input() appDetail: any;
    @Input() selectedBlock: any;
    @Output() backHandler: EventEmitter<void> = new EventEmitter<any>();
    @Output() childSave: EventEmitter<void> = new EventEmitter<any>();
    appElements = AppElements;
    constructor(private router: Router) {}
    back() {
        this.backHandler.emit();
    }
    ngOnInit(): void {
        console.log(this.selectedBlock)
    }
    selectedElementFn(id) {
        if (AppElements.Translation == id) {
            this.router.navigate([AppRoutes.TranslationEditor]);
        } else if (AppElements.CustomFields == id) {
            this.router.navigate([AppRoutes.DynamicField]);
        } else {
            this.selectedBlock = id;
        }
    }
    saveHander() {
        this.childSave.emit();
    }
}
