import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: "root",
})
export class WidgetService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    getWidgetList(limit = 10, offset = 0, search = '', form?) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : typeof form[key] !== 'object' ? form[key] : ''}`
                );
            }
        }
        return this.apiService.get(
            this.sharedService.wigetCURDUrl,
            params,
            undefined
        );
    }

    addWidget(form) {
        return this.apiService.post(
            this.sharedService.wigetCURDUrl,
            form,
            undefined
        );
    }
    updateWidget(form) {
        return this.apiService.put(
            this.sharedService.wigetCURDUrl + `${form.id}/`,
            form,
            undefined
        );
    }

    getWidgetDetail(id) {
        return this.apiService.get(
            this.sharedService.wigetCURDUrl + `${id}/`,
            undefined
        );
    }

    getZones() {
        return this.apiService.get(
            this.sharedService.getZones,
            undefined,
            undefined
        );
    }
    deleteWidget(id) {
        return this.apiService.delete(
            this.sharedService.wigetCURDUrl + `${id}/`,
            undefined
        );
    }
}
