import { UtilitiesService } from "app/services/utilitiesService";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
    ActivatedRoute,
    NavigationEnd,
    PRIMARY_OUTLET,
    Router,
} from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { CoreService } from "app/services/core-service";
import { TranslationService } from "app/services/translationService";
import { filter } from "rxjs/operators";
import { Clipboard } from "@angular/cdk/clipboard";
import { ApiService } from "app/services/api.service";
import { TranslateService } from "@ngx-translate/core";
import { OrderService } from "app/services/orders/order.service";
import { MatDialog } from "@angular/material/dialog";
import { ChangeOrderStatusComponent } from "../change-order-status/change-order-status.component";
export interface Breadcrumb {
    label: string;
    url: string;
}
@Component({
    selector: "app-breadcrumbs",
    templateUrl: "./breadcrumbs.component.html",
    styleUrls: ["./breadcrumbs.component.scss"],
    animations: fuseAnimations,
})
export class BreadcrumbsComponent implements OnInit {
    @Input() isLoading: boolean = false;
    @Input() label: any = "";
    @Input() order: boolean = false;
    @Input() order_status: any = "";
    @Input() order_id: any = "";
    @Input() isEnableBack: boolean = false;
    @Input() enableIcon: boolean = false;
    @Input() permissionKey:any=""
    @Input() shortUrl: string;
    @Input() campaignLogo: any
    @Output() back: EventEmitter<void> = new EventEmitter<any>();
    @Output() history: EventEmitter<void> = new EventEmitter<any>();
    language: any;
    baseUrl: string;

    public breadcrumbs: Breadcrumb[];

    constructor(
        private dialog: MatDialog,
        private orderService: OrderService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public translationService: TranslationService,
        public coreService: CoreService,
        private clipboard: Clipboard,
        private apiService: ApiService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService
    ) {
        this.language = localStorage.getItem("language")
            ? localStorage.getItem("language")
            : "en";
        let breadcrumb: Breadcrumb = {
            label: "Home",
            url: "",
        };
        //this.baseUrl =  this.getBaseUrl();
        this.baseUrl = 'http://l.paydo.me/';

    }

    ngOnInit(): void {

        console.log(this.label)

    }

    goBack() {
        this.back.emit();
    }

    getBaseUrl() {
        return this.apiService.BASE_URL;
    }

    openHistory() {
        this.history.emit();
    }

    copyUrl() {
        this.clipboard.copy(this.shortUrl);
        this.showCopyMessage();
    }

    showCopyMessage() {
        let copyText = this.translate.instant("Url Copied to clipboard!");
        this.utilitiesService.showSuccessToast(copyText);
    }
    SavePdf(id) {
        this.orderService.getOrderPdf(id);
    }
    SaveInvoicePdf(id) {
        this.orderService.getInvoicePdf(id);
    }
    SavePicklistPdf(id) {
        this.orderService.getPicklistPdf(id);
    }

    ChangeStatus(id, order_status) {
        console.log(id);
        console.log(order_status);
        let orderId = id;

        // let itemList = this.cartList;
        let isMobile = this.utilitiesService.isMobile();
        let size = {
            width: "45vw",
            height: "35vh",
        };
        if (isMobile) {
            size.height = "100%";
            size.width = "100%";
        }
        const dialogRef = this.dialog.open(ChangeOrderStatusComponent, {
            data: { orderId, order_status },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });

        dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
                console.log("resp");
                console.log(resp);
            }
        });
    }
}
