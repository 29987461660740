<div
    fxFlex="1 1 100%"
    fxLayoutAlign="start stretch"
    fxLayout="column"
    fxLayoutGap="1em"
>
    <div
        fxFlex="1 1 auto"
        fxLayoutAlign="space-between center"
        fxLayout="row"
        fxLayoutGap="1em"
    >
        <!-- <strong class="highlight-text-color">{{
            "Change Order Status" | translate
        }}</strong> -->
        <strong class="highlight-text-color"
            >Assign Delivery Agent
            <strong *ngIf="count"> [ {{ count }} Deliveries ]</strong></strong
        >
        <mat-icon (click)="close()" class="cursor-pointer">close</mat-icon>
    </div>

    <form
        fxFlex="1 1 100%"
        fxLayoutAlign="start stretch"
        fxLayout.lt-md="column"
        fxLayout.gt-sm="row"
        fxLayoutGap="1em"
        [formGroup]="form"
    >
        <div
            fxFlex="1 1 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="1em"
        >
            <div
                fxFlex="0 0 auto"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="0px"
            >
                <mat-spinner
                    diameter="50"
                    style="left: 47%; top: 50%; position: relative"
                    *ngIf="loading"
                    strokeWidth="5"
                >
                </mat-spinner>

                <div
                    fxFlex="0 0  auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="3em"
                >
                    <div
                        fxFlex="1 1 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="1em"
                    >
                        <div
                            fxFlex="1 1 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout="column"
                            fxLayoutGap="5px"
                        >
                            <label class="highlight-text-color mb-5">
                                Delivery Agents
                            </label>
                            <ng-select
                                [placeholder]="'Delivery Agent'"
                                formControlName="delivery_agent"
                            >
                                <ng-option
                                    *ngFor="let agent of agentsList"
                                    [value]="agent.id"
                                >
                                    {{ agent?.first_name }}
                                    {{ agent?.middle_name }}
                                    {{ agent?.last_name }}
                                </ng-option>
                            </ng-select>
                            <mat-error
                                *ngIf="
                                    form
                                        .get('delivery_agent')
                                        .hasError('required')
                                "
                            >
                                {{
                                    "Delivery Agent is required" | translate
                                }}</mat-error
                            >
                        </div>
                    </div>

                    <!-- action -->

                    <div
                        fxFlex="1 1 auto"
                        fxLayoutAlign="flex-end stretch"
                        fxLayout="row"
                        fxLayoutGap="1em"
                    >
                        <button
                            [mat-dialog-close]="false"
                            mat-stroked-button
                            color="warn"
                        >
                            <mat-icon class="save-cancel-btn" matPrefix
                                >close</mat-icon
                            >
                            {{ "Close" | translate }}
                        </button>
                        <button
                            (click)="updateAgentStatus()"
                            type="submit"
                            mat-flat-button
                            class="primary-bg-color"
                        >
                            <mat-icon class="save-cancel-btn mr-8" matPrefix
                                >send</mat-icon
                            >{{ "Assign " | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
