<div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
  <div fxFlex="1 1 auto" fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="1em">
    <strong class="highlight-text-color">{{ "Send Notification" | translate }}</strong>
    <mat-icon (click)="close()" class="cursor-pointer">close</mat-icon>
  </div>

  <form fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em" [formGroup]="form">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
      <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
        <mat-spinner diameter="50" style="left: 47%; top: 50%; position: relative" *ngIf="loading" strokeWidth="5"> </mat-spinner>
        <div fxFlex="0 0  auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
          <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
              <mat-label style="color: #939393">{{ "Channel" | translate }}</mat-label>
              <mat-radio-group formControlName="channel" (change)="channelChange($event.value)">
                <mat-radio-button class="mr-8" [value]="1">{{ "EMAIL" | translate }}</mat-radio-button>
                <mat-radio-button class="mr-8" [value]="2">{{ "SMS" | translate }}</mat-radio-button>
                <mat-radio-button [value]="3">{{ "PUSH" | translate }}</mat-radio-button>
              </mat-radio-group>
              <!-- <mat-form-field appearance="outline" fxFlex="100%">
                                <mat-label>{{'Channel' | translate}}</mat-label>
                                <mat-select (selectionChange)="channelChange($event.value)">
                                    <mat-option>{{'EMAIL' | translate}}</mat-option>
                                    <mat-option [value]="2">{{'SMS' | translate}}</mat-option>
                                    <mat-option [value]="3">{{'PUSH' | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field> -->
            </div>
            <div fxFlex="0 0 auto" class="mb-4" fxLayoutAlign="flex-end flex-end" fxLayout="row" fxLayoutGap="0px">
              <mat-checkbox (change)="templateCheckboxChange()" formControlName="use_exist" class="example-margin">
                {{ "Add Custom Template" | translate }}</mat-checkbox
              >
            </div>

            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start stretch">
              <!-- Template Select Column -->
              <div *ngIf="!form.get('use_exist').value" fxFlex="93%">
                <mat-form-field appearance="outline" fxFlex="100%">
                  <mat-label>{{ "Template" | translate }}</mat-label>
                  <mat-select formControlName="template">
                    <mat-option *ngFor="let template of notificationTemplate" [value]="template?.id">
                      {{ template?.title }}
                    </mat-option>
                  </mat-select>
                  <mat-spinner *ngIf="isLoading" diameter="20" matSuffix></mat-spinner>
                  <mat-error *ngIf="form.get('template').hasError('required')">
                    {{ "Template is required" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Template Limit Select Column -->
              <div *ngIf="!form.get('use_exist').value" fxFlex="7%">
                <mat-form-field appearance="outline">
                  <mat-select formControlName="templateLimit" (selectionChange)="templatelimitChange()">
                    <mat-option [value]="5">5</mat-option>
                    <mat-option [value]="10">10</mat-option>
                    <mat-option [value]="25">25</mat-option>
                    <mat-option [value]="50">50</mat-option>
                    <mat-option [value]="100">100</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div
              fxFlex="0 0 auto"
              fxLayoutAlign="start stretch"
              fxLayout.lt-md="column"
              fxLayout.gt-sm="row"
              fxLayoutGap="1em"
              *ngIf="form.get('use_exist').value">
              <div style="height: fit-content" fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                <mat-form-field appearance="outline" fxFlex="100%">
                  <mat-label>{{ "Template Type" | translate }}</mat-label>
                  <mat-select formControlName="template_format">
                    <mat-option [value]="1">
                      {{ "General" | translate }}
                    </mat-option>
                    <mat-option [value]="2">
                      {{ "Personalized" | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="100" *ngIf="form.get('channel').value != 2">
                  <mat-label class="text-capitalized">
                    {{ "Title" | translate }}
                  </mat-label>
                  <input formControlName="title" matInput name="title" />
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="100%" *ngIf="form.get('channel').value != 1">
                  <mat-label>{{ "Message" | translate }}</mat-label>
                  <textarea formControlName="message" rows="8" matInput></textarea>
                  <mat-error *ngIf="form.get('message').hasError('required')">
                    {{ "Message is required" | translate }}
                  </mat-error>
                </mat-form-field>
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column">
                  <ckeditor
                    *ngIf="form.get('channel').value == 1"
                    formControlName="message_email"
                    ngDefaultControl
                    name="myckeditor1"
                    [config]="ckeConfig">
                  </ckeditor>
                  <small
                    style="margin-left: 0px !important; color: #f44336 !important"
                    *ngIf="form.get('message_email').hasError('required') && form.get('channel').value == 1">
                    {{ "Message is required" | translate }}
                  </small>
                </div>
                <div
                  fxFlex="1 1 auto"
                  fxLayoutAlign="start stretch"
                  fxLayout="column"
                  fxLayoutGap="1em"
                  *ngIf="form.get('channel').value == 3"
                  class="mt-8">
                  <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                    <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                      <label class="highlight-text-color">{{ "Image" | translate }}</label>
                    </div>
                    <div
                      *ngIf="!form.get('image_url').value"
                      fxLayout="column"
                      fxFlex="0 0 auto"
                      fxLayoutAlign="start stretch"
                      fxLayoutGap="5px">
                      <app-cms-upload-media
                        (afterUpload)="setFileToForm($event)"
                        [isMultiSelect]="false"
                        [formcontrol]="form.get('image_url')"
                        [index]="0">
                      </app-cms-upload-media>
                      <!-- <input accept="image/x-png,image/gif,image/jpeg" class="attach-input"
                                                type="file" id="fileDropRef"
                                                (change)="fileBrowseHandlerTumbnail($event.target.files)" />
                                            <label class="attach-label attach-label-file" for="fileDropRef">{{'Browse                                                for file' | translate}}</label>
                                            <label>{{'Or' | translate}}</label>
                                            <label (click)="openGallery(form.get('image_url'),false)"
                                                class="attach-label">{{'Browse from media' | translate}}</label> -->
                    </div>
                    <div
                      class="files-list cursor-pointer"
                      fxFlex="0 0 auto"
                      fxLayoutAlign="space-between stretch"
                      fxLayout="row"
                      *ngIf="form.get('image_url').value">
                      <app-show-all-type-media [url]="form.get('image_url').value"> </app-show-all-type-media>
                      <!-- <img class="thumbnail-img p-16" style="margin: auto !important;"
                                                [src]="utilitiesService.bypassTrustedURL(form.get('image_url').value)"> -->
                      <mat-icon
                        fxFlex="0 0 auto"
                        fxLayoutAlign="end end"
                        fxLayoutGap="5px"
                        fxLayout="row"
                        (click)="deleteImage()"
                        *ngIf="form.get('image_url').value"
                        class="action-btn-delete cursor-pointer"
                        >close
                      </mat-icon>
                    </div>
                  </div>
                  <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
                    <mat-form-field appearance="outline" fxFlex="100">
                      <mat-label class="text-capitalized">
                        {{ "Custom Link" | translate }}
                      </mat-label>
                      <input matInput name="title_en" formControlName="trigger_url" />
                    </mat-form-field>
                  </div>
                  <small class="note"
                    >* {{ "Custom Link should be a relative url" | translate }} {{ "Eg:" | translate }}
                    <strong>https://domain.com/abc</strong> {{ "here" | translate }} <strong>"/abc"</strong>
                    {{ "is a relative url" | translate }}
                  </small>
                </div>
              </div>
              <div
                style="height: fit-content"
                *ngIf="codes.length"
                fxFlex.lt-md="1 1 auto"
                fxFlex.gt-sm="0 0 30%"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                class="mb-8 variable-container-height fuse-card auto-width p-16">
                <label class="mb-8 highlight-text-color text-center">{{ "Click to copy" | translate }}</label>
                <div
                  *ngFor="let code of codes; let i = index"
                  fxFlex="1 1 auto"
                  style="width: 100%"
                  fxLayoutAlign="start stretch"
                  fxLayout="column"
                  fxLayoutGap="1em">
                  <button
                    (mouseover)="codeIndex[i] = true"
                    (mouseout)="codeIndex[i] = false"
                    style="text-align: start !important; overflow: hidden"
                    [cdkCopyToClipboard]="code.code"
                    (click)="showCopyMessage()"
                    mat-stroked-button
                    type="button"
                    class="mb-4">
                    <mat-icon matPrefix>file_copy</mat-icon>
                    <span>
                      {{ translationService.getTranslatedName(code) }}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div fxFlex="1 1 auto" fxLayoutAlign="flex-end stretch" fxLayout="row" fxLayoutGap="1em">
            <button [mat-dialog-close]="false" mat-stroked-button color="warn">
              <mat-icon class="save-cancel-btn" matPrefix>close</mat-icon> {{ "Close" | translate }}
            </button>
            <button (click)="send()" type="submit" mat-flat-button class="primary-bg-color">
              <mat-icon class="save-cancel-btn mr-8" matPrefix>send</mat-icon>{{ "Send" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
