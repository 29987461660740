import { KeyValue } from "@angular/common";
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { MediaTypes, MediaTypesAccept } from "app/constants/media-type";
import { PageElements } from "app/constants/types";
import { MediaService } from "app/services/media.service";
import { NavMenuService } from "app/services/nav-menu.service";
import { SliderService } from "app/services/sliderService";
import { UtilitiesService } from "app/services/utilitiesService";
import { FullscreenAdditionCssComponent } from "../fullscreen-addition-css/fullscreen-addition-css.component";
import { GalleryPopupComponent } from "../gallery-popup/gallery-popup.component";
import { ImageSizeWarningComponent } from "../image-size-warning/image-size-warning.component";
import { CreateSliderComponent } from "../create-sliders/create-sliders.component";
import { MatTabGroup } from "@angular/material/tabs";

@Component({
  selector: "app-element-types",
  templateUrl: "./element-types.component.html",
  styleUrls: ["./element-types.component.scss"],
  animations: fuseAnimations,
})
export class ElementTypesComponent implements OnInit {
  @Output() formChange: EventEmitter<void> = new EventEmitter<any>();
  typeEnum = PageElements;
  @Input() configuration: FormArray;
  @Input() formGroup: FormGroup;
  @Input() elementName?: any;
  @Input("configurationArray") config: {
    input: string;
    name: string;
    required: boolean;
    type: any;
    media_type?: any;
    show_only?: any;
  }[] = [];
  options: any = {
    showLineNumbers: true,
    tabSize: 2,
  };
  language = new FormControl();
  files = [];
  menu = [];
  slider = [];
  myFormGroup: FormGroup;
  ckeConfig;
  imageObject: any;
  mediaType = MediaTypes;
  mediaTypesAccept = MediaTypesAccept;
  newCollection;

  constructor(
    private formBuilder: FormBuilder,
    private sliderService: SliderService,
    public utilitiesService: UtilitiesService,
    private mediaService: MediaService,
    private navMenuService: NavMenuService,
    private dialog: MatDialog
  ) {
    this.language.setValue("en");
  }
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  containAr(key: string) {
    return key.includes("_ar");
  }

  getFormControlType(key: string) {
    if (key) {
      let controlType = "";
      if (key) {
        if (key == "language") {
          controlType = "language";
        } else if (key == "order") {
          controlType = "order";
        } else if (key == "show_on") {
          controlType = "show_on";
        } else if (key == "id") {
          controlType = "";
        } else if (key == "label") {
          controlType = "label";
        } else if (key == "label_ar") {
          controlType = "label_ar";
        } else if (key == "isCodeEditor") {
          controlType = "";
        } else if (key == "parentName") {
          controlType = "";
        } else if (key == "parentID") {
          controlType = "";
        } else {
          controlType = this.config.find((element) => element.name == key).input;
        }
        return controlType;
      }
    }
  }
  getFormControlLabel(key: string) {
    let controlName = "";
    if (key) {
      if (key == "language") {
        controlName = "Language";
      } else if (key == "order") {
        controlName = "Order";
      } else if (key == "show_on") {
        controlName = "Show On";
      } else if (key == "id") {
        controlName = "";
      } else if (key == "label") {
        controlName = "Label";
      } else if (key == "label_ar") {
        controlName = "Label Ar";
      } else if (key == "isCodeEditor") {
        controlName = "";
      } else if (key == "parentName") {
        controlName = "";
      } else if (key == "parentID") {
        controlName = "";
      } else {
        controlName = this.config.find((element) => element.name == key).name;
      }
      return controlName;
    }
  }

  hideLanguage(config) {
    let isShow = false;
    for (let key in config) {
      if (!isShow) {
        if (
          key == "html" ||
          key == "html_ar" ||
          key == "image" ||
          key == "image_ar" ||
          key == "embed" ||
          key == "embed_ar" ||
          key == "video" ||
          key == "video_ar" ||
          key == "pdf" ||
          key == "pdf_ar" ||
          key == "audio" ||
          key == "audio_ar"
        ) {
          isShow = true;
        } else {
          isShow = false;
        }
      }
    }
    return isShow;
  }
  async ngOnInit(): Promise<void> {
    this.ckeConfig = {
      allowedContent: true,
      forcePasteAsPlainText: true,
      versionCheck: false,
      removeButtons: "CreateDiv,BlockQuote,SuperScript,SubScript,PasteFromWord,PasteText,Source",
    };
    await this.getSliderList();
    await this.getMenuList();
  }
  async getSliderList() {
    try {
      const sliderList = await this.sliderService.getSliders(99999, 0).toPromise();
      if (sliderList) {
        this.slider = sliderList.results;
      }
    } catch {
    } finally {
    }
  }
  async getMenuList() {
    try {
      const menuList = await this.navMenuService.getMenuList(99999, 0).toPromise();
      if (menuList) {
        this.menu = menuList.results;
      }
    } catch {
    } finally {
    }
  }

  drop(event) {}

  setFileToForm($event, configIndex, type) {
    ((this.configuration as FormArray).at(configIndex) as FormGroup).get(type).setValue($event.file);
  }

  getShowOnly(controlLabel) {
    return this.config.filter((data) => data.name == controlLabel)[0].show_only || null;
  }

  getMediaType(controlLabel) {
    return this.config.filter((data) => data.name == controlLabel)[0].media_type || null;
  }

  viewImage() {}
  deleteFile(index: number, type) {
    ((this.configuration as FormArray).at(index) as FormGroup).get(type).setValue(null);
  }
  uploadFilesSimulator(index: number) {}

  zoomAdditionalCss(formControl, i, type) {
    let heading = "";
    if (type == "embed" || type == "embed_ar") {
      heading = "EMBED";
    } else {
      heading = "HTML";
    }

    const dialogRef = this.dialog.open(FullscreenAdditionCssComponent, {
      data: { formControl, heading },
      maxWidth: "",
      width: "100%",
      height: "100%",
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        ((this.configuration as FormArray).at(i) as FormGroup).get(type).setValue(resp.value);
      }
    });
  }

  addNewCollection(control: FormControl) {
    const dialogRef = this.dialog.open(CreateSliderComponent, {
      data: { isPopUp: true },
      position: { right: "0", top: "10px" },
      width: "90%",
      height: "100%",
      closeOnNavigation: true,
      panelClass: "left-to-right-dialog",
    });

    setTimeout(() => {
      dialogRef.componentInstance.dialogRef.addPanelClass("open");
    }, 100);

    dialogRef.afterClosed().subscribe(async (resp) => {
      if (resp) {
        await this.getSliderList();

        control.setValue(resp.id);
      }
    });
  }
}
