<div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch">
    <div id="dashboard-project" class="page-layout simple right-sidebar" fxLayout="column"
        [@animate]="{value:'*',params:{duration:'400ms',y:'70px'}}">
        <div class="center" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
            <div class="content p-16 remove-scroll-bar" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                fxLayoutGap="1em">
                <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="space-between">
                    <span class="label">{{'History of' | translate}} {{pageTitle}}</span>
                    <mat-icon [mat-dialog-close]="true" class="cursor-pointer">close</mat-icon>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="10px">
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column">
                        <div infiniteScroll [infiniteScrollDistance]="'0.5'" [scrollWindow]="false"
                            (scrolled)="loadMoreHistory()" fxFlex="0 0 auto"
                            class="fuse-card auto-width p-16 max-height-custom" fxLayoutAlign="start stretch"
                            fxLayout="column" fxLayoutGap="8px">
                            <div fxFlex="0 0 auto" fxLayoutAlign="space-between center" fxLayout="row">
                                <label class="highlight-text-color">{{'Changes' | translate}}</label>
                                <mat-form-field appearance="outline" *ngIf="historyList.length > 4">
                                    <mat-select [(ngModel)]="page.size" (selectionChange)="limitChange()">
                                        <mat-option [value]="5">5</mat-option>
                                        <mat-option [value]="10">10</mat-option>
                                        <mat-option [value]="25">25</mat-option>
                                        <mat-option [value]="50">50</mat-option>
                                        <mat-option [value]="100">100</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="8px">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="8px">
                                    <ul class="sessions" fxLayoutGap="5px" fxFlex="0 0 auto"
                                        fxLayoutAlign="start stretch" fxLayout="column" *ngIf="historyList.length > 0">
                                        <li *ngFor="let item of historyList;let i= index" fxFlex="0 0 auto"
                                            fxLayoutAlign="start stretch" fxLayout="column" class="cursor-pointer"
                                            fxLayoutGap="10px">
                                            <div fxFlex="0 0 auto" fxLayoutAlign="space-between center"
                                                fxLayout.gt-sm="row" fxLayout.lt-md="column" class="cursor-pointer">
                                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                                    fxLayoutGap="5px">
                                                    <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="row"
                                                        fxLayoutGap="20px">
                                                        <span
                                                            class="mat-title">#{{(page.size * page.pageNumber) + i + 1}}</span>
                                                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                                            fxLayout="column" fxLayoutGap="5px">
                                                            <div class="time">
                                                                <span>{{item?.history?.history_date | date: 'dd/MM/yyyy hh mm a'}}</span>
                                                            </div>
                                                            <span>{{'Changed By :'  | translate}}
                                                                <strong>{{item?.history?.history_user}}</strong></span>
                                                            <p *ngIf="item?.history?.history_reason">
                                                                {{item?.history?.history_reason}}
                                                            </p>
                                                            <span *ngIf="!item?.history?.history_reason">{{'No Reason'}}
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                                    fxLayoutGap="5px">
                                                    <button *ngIf="panelToggle[i]==false" (click)="openPanel(i)"
                                                        mat-stroked-button>
                                                        <mat-icon>keyboard_arrow_down</mat-icon>
                                                        {{'Show Detail' | translate}}
                                                    </button>
                                                    <button *ngIf="panelToggle[i]==true" (click)="closePanel(i)"
                                                        mat-stroked-button>
                                                        <mat-icon>keyboard_arrow_up</mat-icon>
                                                        {{'Hide Detail' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                            <div *ngIf="panelToggle[i]==true" fxFlex="0 0 auto"
                                                fxLayoutAlign="start stretch" class="fuse-card auto-width p-16"
                                                fxLayout="column" fxLayoutGap="5px">
                                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                                    fxLayoutGap="5px" *ngFor="let field of item | keyvalue">
                                                    <div *ngIf="field?.key!='history'" fxFlex="1 1 auto"
                                                        fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="8px">
                                                        <div fxFlex="1 1 0px"
                                                            style="text-transform: capitalize !important;">
                                                            {{getKey(field?.key,field?.value)}}
                                                        </div>
                                                        <div fxFlex="1 1 0px"
                                                            style="overflow: hidden;word-wrap: break-word;">
                                                            <span
                                                                [ngClass]="{'text-color-empty-value' : getValue(field?.key,field?.value) == 'Empty'}">
                                                                {{getValue(field?.key,field?.value)}}
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div *ngIf="historyList.length <= 0" fxFlex="0 0 auto" fxLayoutAlign="center center"
                                        fxLayout="column" fxLayoutGap="8px">
                                        <span
                                            class="mat-title highlight-text-color">{{'Nothing to display' | translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="fuse-card auto-width p-16 total-card" fxFlex="0 0 auto"
                            fxLayoutAlign="start stretch" fxLayout="column">
                            <span class="total-count">{{'Total Count:' | translate}} {{page.totalElements}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>