import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
    selector: "app-code-editor",
    templateUrl: "./code-editor.component.html",
    styleUrls: ["./code-editor.component.scss"],
})
export class CodeEditorComponent implements OnInit, AfterViewInit {
    @Input() formControl: FormControl;
    @ViewChild("editor") editor;
    constructor() {}

    ngOnInit(): void {}

    async ngAfterViewInit(): Promise<void> {
        this.editor.getEditor().setOptions({
            showLineNumbers: true,
            tabSize: 2,
        });
        this.editor.mode = "css";
    }
}
