<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
            [formGroup]="supportForm">
            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back</mat-icon>
                    <label class="highlight-text-color">{{'Support Settings' | translate}}</label>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <button (click)="save()" type="submit" mat-flat-button style="position: absolute;right: 16px;"
                        class="primary-bg-color">{{'Save' | translate}}</button>
                </div>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                [@animate]="{value:'*',params:{duration:'400ms',y:'70px'}}">
                <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 center-container" fxLayoutAlign="start stretch"
                    fxLayout="column" fxLayoutGap="1em">
                    <label class="highlight-text-color">{{'Support General Settings' | translate}}</label>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                            <mat-slide-toggle formControlName="enable_support">
                                {{'Enable Support' | translate}}
                            </mat-slide-toggle>
                            <small class="mb-16">{{'help_text_Enable_Support' | translate}}</small>
                        </div>
                        <div *ngIf="supportForm.get('enable_support').value" fxFlex="1 1 auto"
                            fxLayoutAlign="start stretch" fxLayout="column">
                            <!-- <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" class="mb-8">
                                <mat-radio-group aria-label="Select an option" [(ngModel)]="isPaydo_Account"
                                    [ngModelOptions]="{standalone: true}">
                                    <mat-radio-button (change)="radioChange($event)" [value]="true">
                                        {{'Use Paydo Account' | translate}}
                                    </mat-radio-button>
                                    <mat-radio-button (change)="radioChange($event)" [value]="false">
                                        {{'Use Other Account' | translate}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div> -->
                            <div fxLayout.gt-sm="row" fxLayout.lt-md="column"
                                *ngIf="supportForm.get('enable_support').value" fxFlex="1 1 auto"
                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxFlex="1 1 auto">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Support Email'| translate}}
                                        </mat-label>
                                        <input matTooltip="{{'help_text_Support_Email' | translate}}"
                                            formControlName="support_email" matInput name="support_email">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="1 1 auto">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Support Contact Number'| translate}}
                                        </mat-label>
                                        <input matTooltip="{{'help_text_Support_contact_number' | translate}}"
                                            formControlName="support_contact_no" matInput name="support_contact_no">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="1 1 auto">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Support Address'| translate}}
                                        </mat-label>
                                        <input matTooltip="{{'help_text_Support_Address' | translate}}"
                                            formControlName="support_address" matInput name="support_address">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div fxLayout.gt-sm="row" fxLayout.lt-md="column"
                                *ngIf="supportForm.get('enable_support').value" fxFlex="1 1 auto"
                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxFlex="1 1 auto">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Support Message EN'| translate}}
                                        </mat-label>
                                        <textarea rows="3" formControlName="support_message_en"
                                            matInput name="title"></textarea>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="1 1 auto">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Support Message AR'| translate}}
                                        </mat-label>
                                        <textarea rows="3" formControlName="support_message_ar"
                                            matInput name="title"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div fxLayout.gt-sm="row" fxLayout.lt-md="column"
                                *ngIf="supportForm.get('enable_support').value" fxFlex="1 1 auto"
                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxFlex="1 1 auto">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Support Message'| translate}}
                                        </mat-label>
                                        <textarea rows="3" formControlName="support_message"
                                            matInput name="title"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>