import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable()
export class SharedService {
  public apiDomain: any;
  public Domain: any;
  // public checkStatus: any;
  //page
  public pageElements: any;
  public pageTemplate: any;
  public pageList: any;
  public addPage: any;
  public pageDetail: any;
  public updatePage: any;
  public deletePageItems: any;

  //posts

  public postElements: any;
  public postList: any;
  public addPost: any;
  public deletePost: any;
  public updatePost: any;
  public deletePostElements: any;

  //postCategory

  public postCategoryList: any;

  //slider
  public sliderProducts: any;
  public sliderCategory: any;
  public sliderBrand: any;
  public getSliders: any;
  public addSliders: any;
  public deleteSlider: any;
  public sliderDetail: any;
  public updateSliders: any;
  public deleteSlide: any;
  public sliderBrandDetail: any;
  public sliderProductDetail: any;
  public sliderCategoryDetail: any;
  //widgets
  public wigetCURDUrl: any;
  public getZones: any;
  //media
  public mediaCURDUrl: any;
  public s3UploadURL: any;
  public uploadFileName: any;
  public addMediaFile: string;
  //menu
  public menuList: any;
  public menuItem: any;
  public menuCreate: any;
  public menuUpdate: any;

  public addNavItems: any;

  //auth
  public auth: any;
  public login: any;

  //app
  public appDetail: any;
  public appCURDUrl: any;
  public appUpdate: any;
  public socialMediaListDelete: any;
  public socialMediaBulk: any;
  public deliveryMode: any;
  public getFeatures: any;
  public getPermission: any;
  public currency: any;
  public countries: any;
  public manageCountry: any;
  public areas: any;
  public addCustomCountry: any;

  // customer

  public customerList: any;
  public customerChangePassword: any;
  public customerAddress: any;
  public customerOrders: any;
  public sendNotification: any;
  public sendTestCampaign: string;
  public plans: any;
  public membership: any;
  public addMembership: any;
  public customerOrderCount: any;
  public conditionList: any;
  public blockCustomer: any;

  //cart

  public cartList: any;
  public orderList: any;
  public getOrderPdf: any;
  public getInvoicePdf: any;
  public paymentStatus: any;
  public wishList: any;
  public track: any;
  public checkStatus: any;
  // wallet
  public wallets: any;
  public topup_wallet: any;
  public transaction: any;
  //notification
  public notificationList: any;
  public notificationConfiguration: any;
  public notificationVariables: any;

  //campaigns

  public campaignsList: any;
  public audience: any;
  public addCampaign: any;
  public deleteCampaign: any;
  public cancelCampaign: any;
  public updateCampaign: any;
  public addTemplate: any;
  public executeCampaign: any;
  public updateTemplate: any;
  public mediums: string;
  public campaignDashBoard: string;
  public templatePreview: string;

  //shopify

  public shopifyInstall: any;
  public shopifyunInstall: any;
  public shopifyActions: any;
  public shopifyExistOrNot: any;
  public syncProducts: any;
  public syncStocks: any;
  public syncProductsProgress: any;

  //price rules
  public priceRuleCURD: any;

  public paymentMethod: any;

  public bankList: any;
  public bankBrand: any;
  public bankScheme;

  //translatorEditor

  public getLanguages: any;
  public updateLanguage: any;
  public undoChanges: any;

  //history

  public pageHistory: any;
  public appHistory: any;
  public navHistory: any;
  public widgetHistory: any;
  public sliderHistory: any;
  public mediaHistory: any;
  public orderHistory: any;
  public membershipHistory: any;
  public customerHistory: any;
  public cartHistory: any;
  public audienceHistory: any;
  public campaignHistory: any;
  public priceRuleHistory: any;
  public conditionHistory: any;
  public dynamicFieldHistory: any;
  public postHistory: any;
  public roleHistory: any;

  //condition

  public conditionFilter: any;
  public createCondition: any;
  public updateCondition: any;
  public deleteRule: any;

  //dynamicFields

  public getDynamicList: any;
  public getDynamicModels: any;
  public disableDynamicField: any;
  public enableDynamicField: any;

  public countryRuleList: any;

  //downloads

  public downloadList: any;
  public downloadBulkPicklist: any;
  //wordpress

  public wordpress: any;

  public tags: any;
  public origins: any;
  public handlingInstructions: any;
  public shopifyAppEdit: any;

  //roles-permissions

  public roleCURD: any;
  public allPermission: any;
  public refreshPermission: any;

  //users

  public userList: any;
  public userDetail: any;
  public userCreate: any;
  public userUpdate: any;
  public deleteUser: any;
  public changePassword: any;
  public changeAdminPassword: any;

  //email config

  public getEmailConfig: any;
  public getSMSConfig: any;

  // Loyalty

  public loyaltyConfiguration: string;
  public pointsRule: string;
  public loyaltyTransactions: string;
  public loyaltyCustomerTransactions: string;

  // Main Dashboard
  public mainDashBoard: string;
  public dashBoardCustomers: string;
  public dashBoardVisits: string;
  public dashBoardRevenue: string;
  public dashBoardMisc: string;
  public dashBoardClearCache: string;

  //products insight
  public productInsights: string;

  //facebook catalog
  public activateFbScheduleEn: string;
  public activateFbScheduleAr: string;
  public manualCatalogUploadEn: string;
  public manualCatalogUploadAr: string;
  public checkFbScheduleStatus: string;

  //catalogue
  public customerReviews: string;
  public products: string;
  public productUploadImage: string;
  public categories: string;
  public brands: string;
  public validateProductsCatalog: string;
  public productsCatalogType: string;
  public productsCatalogValidataionType: string;
  public productCatalogLinkType: string;
  public productCatalogLinkTypeCSV: string;
  public sticker: string;
  public unbindProducts: string;
  public attribute: string;

  //product Page
  public productPageDetails: string;
  public createProductPage: string;
  public updateProductPage: string;
  public deleteProductPageElement: string;
  // syncJob
  public dry_run: string;
  public syncJob: string;
  syncConfig: string;
  addDeliverySlot: string;
  deliverySlot: string;
  ImportfromFacts: string;
  clearCache: string;
  deliveryStatus: string;
  delivery: string;
  undeliveredReason: string;
  deliveryAgent: string;
  assignDelivery: string;

  //socail page
  public socialPage: string;

  pageRedirection: string;

  constructor() {
    let appID = localStorage.getItem("app_id") ? localStorage.getItem("app_id") : 1;
    this.apiDomain = environment.server_url;
    this.Domain = environment.server_url;

    this.getFeatures = "api/features/";
    this.getPermission = "api/get-feature-permission/";

    this.tags = `api/manage/tag/`;
    this.origins = `api/manage/origin/`;
    this.handlingInstructions = `api/manage/handling_instruction/`;
    // this.tags = `api/app/${appID}/product-tags/`;
    this.pageTemplate = "api/page-template/";

    //slider
    this.pageElements = "api/element-type/";
    this.sliderProductDetail = `api/manage/product/`;
    this.sliderProducts = `api/manage/product/`;
    this.sliderCategory = `api/manage/category/`;
    this.sliderBrand = `api/manage/brand/`;
    this.getSliders = "api/cms/manage/slider/";
    this.addSliders = "api/cms/manage/sliders/create/";
    this.deleteSlider = "api/cms/manage/slider/";
    this.sliderDetail = "api/cms/manage/slider/";
    this.updateSliders = "api/cms/manage/sliders/update/";
    this.deleteSlide = "api/cms/manage/slide/";
    //widget

    //posts

    this.postElements = "api/element-type/";
    this.postList = "api/cms/manage/posts/";
    this.addPost = "api/cms/manage/post/create/";
    this.deletePost = "api/cms/manage/posts/";
    this.updatePost = "api/cms/manage/post/update/";
    this.deletePostElements = "api/cms/manage/post-element/";

    //postCategory

    this.postCategoryList = "api/cms/manage/post-category/";

    //whisList

    this.wishList = "api/get-wishlist/";

    //othrs

    this.wigetCURDUrl = "api/cms/widget/";
    this.getZones = "api/zone/";

    //media
    this.mediaCURDUrl = "api/cms/media/";
    this.s3UploadURL = "api/cms/manage/media/get-presigned-url/";
    this.uploadFileName = "api/cms/manage/media/add/";
    this.addMediaFile = "api/cms/manage/media/add/";

    //nav-menu
    this.menuList = "api/cms/manage/menu/";
    this.menuItem = "api/cms/navigation-item";
    this.menuCreate = "api/cms/manage/navigation/create/";
    this.menuUpdate = "api/cms/manage/navigation/update/";
    this.addNavItems = "api/cms/navigation-item/";

    //auth
    this.auth = "api/manage/auth/";
    this.login = "api/user/auth/";

    //app
    this.appCURDUrl = "api/manage/app/";
    this.appUpdate = "api/manage/app/";
    this.socialMediaListDelete = "api/manage/custom-link/";
    this.socialMediaBulk = "api/manage/bulk-custom-link/";
    this.deliveryMode = "api/delivery-mode/";
    this.addDeliverySlot = "api/manage/bulk-delivery-slots/";
    this.deliverySlot = "api/manage/delivery-slot/";
    this.currency = "api/currency/";
    this.countries = "api/country/";
    this.manageCountry = "api/manage/country/";
    this.areas = "api/area/";
    this.appDetail = "api/manage/app/";
    this.shopifyAppEdit = "api/manage/app/";
    this.addCustomCountry = "api/manage/bulk-country-rules/";
    this.countryRuleList = "/api/manage/country-rules/";

    //page

    this.pageList = "api/cms/manage/pages/";
    this.addPage = "api/cms/manage/page/create/";
    this.pageDetail = "api/cms/manage/pages/";
    this.updatePage = "api/cms/manage/page/update/";
    this.deletePageItems = "api/cms/manage/page-element/";

    //customer

    this.customerList = "api/customer/";
    this.customerChangePassword = "api/change-password-customer/";
    this.customerAddress = "api/manage/customer/";
    this.sendNotification = "api/send-notification/";
    this.plans = "api/manage/membership_plan/";
    this.membership = "api/members/";
    this.addMembership = "api/member/bulk-create/";
    this.customerOrderCount = "api/customer-summery/";
    this.sendTestCampaign = "api/send-campaign-test/";
    this.blockCustomer = "api/manage/block-customer/";

    //cart

    this.cartList = "api/manage/cart/";
    this.orderList = "api/manage/order/";
    this.getOrderPdf = "api/get-order-pdf/";
    this.getInvoicePdf = "api/get-invoice-pdf/";
    this.customerOrders = "api/manage/order/";
    this.paymentStatus = "api/payment-status";
    this.track = "api/order-tracking/";
    // this.checkStatus = "api/refresh-order/";
    this.checkStatus = "api/check-order-status/";
    // transactions
    this.wallets = "api/manage/wallet";
    this.topup_wallet = "api/manage/topup-customer-wallet/";
    this.transaction = "api/manage/transaction";
    //condition
    this.conditionList = "api/conditions/";
    this.conditionFilter = "api/filter/";
    this.createCondition = "api/manage/condition/create/";
    this.updateCondition = "api/manage/condition/update/";
    this.deleteRule = "api/rule/";

    //notification

    this.notificationList = "api/messaging/notifications-template/";
    this.notificationConfiguration = "api/messaging/notifications-configuration/";
    this.notificationVariables = "api/messaging/notification-variables/";

    //campaigns

    this.campaignsList = "api/manage/campaign/";
    this.audience = "api/manage/audience/";
    this.addCampaign = "api/manage/campaign/";
    this.deleteCampaign = "api/manage/campaign/";
    this.cancelCampaign = "api/cancel-campaign/";
    this.updateCampaign = "api/manage/campaign/";
    this.addTemplate = "api/messaging/notifications-template/";
    this.updateTemplate = "api/messaging/notifications-template/";
    this.executeCampaign = "api/execute-campaign/";
    this.mediums = "api/manage/campaign-medium/";
    (this.campaignDashBoard = "api/campaign-dashboard/"), (this.templatePreview = "api/messaging/preview-template/");

    //dynamicFields

    this.getDynamicList = "api/manage/dynamicfield/";
    this.getDynamicModels = "api/get-customizable-models/";
    this.enableDynamicField = "api/enable-dynamic-field/";
    this.disableDynamicField = "api/disable-dynamic-field/";

    //shopify

    this.shopifyInstall = "api/shopify/install/";
    this.shopifyunInstall = "api/shopify/uninstall/";
    this.shopifyActions = "api/shopify/action/";
    this.shopifyExistOrNot = "api/shopify/exist/";
    this.syncProducts = `api/shopify/syncproducts/`;
    this.syncProductsProgress = `api/shopify/syncproducts/progress/`;
    this.syncStocks = "api/shopify/syncproductstocks/";

    //price rules

    this.priceRuleCURD = "api/manage/price_rule/";
    this.paymentMethod = "api/app-payment_methods/";
    (this.bankBrand = "api/manage/card-brand/"), (this.bankList = "api/manage/card-bank/"), (this.bankScheme = "api/manage/card-scheme/");

    //roles

    this.roleCURD = "api/role/";
    this.allPermission = "api/custom-permission/";
    this.refreshPermission = "api/user/permissions/";

    //users

    this.userList = "api/users/";
    this.userDetail = "api/user/detail/";
    this.userCreate = "api/user/create/";
    this.userUpdate = "api/user/update/";
    this.deleteUser = "api/user/delete/";
    this.changePassword = "api/change-password/";
    this.changeAdminPassword = "api/change-forgot-password/";

    //translatorEditor

    this.updateLanguage = `api/app/${appID}/languages/overide/`;
    this.getLanguages = `api/app/${appID}/languages/`;
    this.undoChanges = `api/app/${appID}/languages/reset/`;

    //history

    this.pageHistory = "api/history/page/";
    this.appHistory = "api/history/app/";
    this.navHistory = "api/history/navigationmenu/";
    this.sliderHistory = "api/history/slide/";
    this.widgetHistory = "api/history/widget/";
    this.mediaHistory = "api/history/attachment/";
    this.orderHistory = "api/history/order/";
    this.membershipHistory = "api/history/membershiplan/";
    this.customerHistory = "api/history/customer/";
    this.cartHistory = "api/history/cart/";
    this.audienceHistory = "api/history/audience/";
    this.campaignHistory = "api/history/campaign/";
    this.priceRuleHistory = "api/history/pricerule/";
    this.conditionHistory = "api/history/condition/";
    this.dynamicFieldHistory = "api/history/dynamic-field/";
    this.postHistory = "api/history/post/";
    this.roleHistory = "api/history/role/";

    //downloads

    this.downloadList = "api/manage/download/";
    this.downloadBulkPicklist = "api/manage/order/download-bulk-picklist/";
    //email config

    this.getEmailConfig = "api/manage/configurations/";
    this.getSMSConfig = "api/manage/configurations/";

    //wordpress
    this.wordpress = "https://www.mypaydo.shop/api/docs/wordpress/";

    //    Loyalty
    this.loyaltyConfiguration = "api/loyalty/manage-loyalty-configuration/";
    this.pointsRule = "api/loyalty/manage-point-rule/";
    this.loyaltyTransactions = "api/loyalty/manage-point-transaction/";
    this.loyaltyCustomerTransactions = "api/loyalty/customer-transactions/";

    //main dashboard
    this.mainDashBoard = "api/dashboard/manage/";
    this.dashBoardCustomers = "api/dashboard/customer-details/";
    this.dashBoardVisits = "api/dashboard/visit-details/";
    this.dashBoardRevenue = "api/dashboard/revenue-details/";
    this.dashBoardMisc = "api/dashboard/misc-details/";
    this.dashBoardClearCache = "api/dashboard/clear-cache/";

    //products-insights
    this.productInsights = "api/dashboard/product-insight/";

    //facebook catalog
    this.activateFbScheduleEn = "api/facebook-catalog-feed/en/schedule/";
    this.activateFbScheduleAr = "api/facebook-catalog-feed/ar/schedule/";
    this.manualCatalogUploadEn = "api/facebook-catalog-feed/en/upload/";
    this.manualCatalogUploadAr = "api/facebook-catalog-feed/ar/upload/";
    this.checkFbScheduleStatus = "api/facebook-catalog-feed/status/";

    //Catalogue
    this.customerReviews = "api/manage/product-reviews/";
    this.products = "api/manage/product/";
    this.productUploadImage = "api/upload-bulk-images/";
    this.validateProductsCatalog = "api/catalog-feed/";
    this.productsCatalogValidataionType = "go/csv/validate/";
    this.productCatalogLinkType = "xml_data_feed.xml/";
    this.productCatalogLinkTypeCSV = "csv_data_feed.csv/";
    this.sticker = "api/manage/sticker/";
    this.unbindProducts = "unbind-products/";
    this.attribute = "api/manage/attribute/";
    this.ImportfromFacts = "api/integrations/import-fq-products/";
    //product page
    this.productPageDetails = "api/cms/manage/product-pages/";
    this.createProductPage = "api/cms/manage/product-page/create/";
    this.updateProductPage = "api/cms/manage/product-page/update/";
    this.deleteProductPageElement = "api/cms/manage/product-pages-element/";
    this.clearCache = "api/clear-cache/";
    //syncjob/dry-run/
    this.syncJob = "api/manage/syncjob/";
    this.dry_run = "api/manage/syncjob/dry-run/";
    this.syncConfig = "/api/manage/sync-config/";
    //social page
    this.socialPage = "api/cms/manage/social-page/";

    // delivery
    this.deliveryAgent = "api/manage/delivery-agent/";
    this.undeliveredReason = "api/manage/undelivered-reason/";
    this.delivery = "api/manage/delivery/";
    this.deliveryStatus = "api/delivery-status/";
    this.assignDelivery = "api/assign-deliveries/";

    this.pageRedirection = "api/manage/redirection-url/";
  }
}
