<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
  <div *ngIf="!isCreateCondition" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
    <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="end center" fxLayoutGap="1em">
      <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="start start" fxLayoutGap="1em">
        <mat-form-field appearance="outline">
          <mat-select [(ngModel)]="page.size" (selectionChange)="limitChange()">
            <mat-option [value]="5">5</mat-option>
            <mat-option [value]="10">10</mat-option>
            <mat-option [value]="25">25</mat-option>
            <mat-option [value]="50">50</mat-option>
            <mat-option [value]="100">100</mat-option>
          </mat-select>
        </mat-form-field>
        <app-common-filter (searchInput)="search($event)" fxHide.lt-md></app-common-filter>
        <app-fab-button
          [bottomMargin]="false"
          [bgColorClass]="'primary-bg-color'"
          (handleFabButtonClick)="createCondition()"
          [tooltip]="'Create Condition'">
        </app-fab-button>
      </div>
    </div>
    <div fxLayout="row" fxFlex="1 1 auto" fxLayoutAlign="start start" fxLayoutGap="1em">
      <app-common-filter (searchInput)="search($event)" fxHide fxShow.lt-md></app-common-filter>
    </div>
    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" [formGroup]="form">
      <ngx-datatable
        (activate)="onActivate($event)"
        [loadingIndicator]="isLoading"
        [externalPaging]="true"
        [count]="page.totalElements"
        [limit]="page.size"
        (page)="pageChange($event)"
        [offset]="page.pageNumber"
        [virtualization]="false"
        [scrollbarH]="true"
        [rows]="conditionList"
        [cssClasses]="iconsCss"
        class="material striped mt-8"
        [columnMode]="'force'"
        [headerHeight]="90"
        [rowHeight]="50"
        [footerHeight]="'auto'">
        <ngx-datatable-column name="{{ 'No' | translate }}" [width]="100">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            {{ page.size * page.pageNumber + rowIndex + 1 }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{ 'Condition' | translate }}">
          <ng-template let-column="column" ngx-datatable-header-template>
            <div fxFlex="1 1 auto" fxLayout="column">
              <p (click)="sort()" style="cursor: pointer">{{ column.name }}</p>
              <input formControlName="condition" type="text" class="form-control" />
            </div>
          </ng-template>
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <p>{{ row?.condition }}</p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{ 'Audience' | translate }}">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <p>{{ row?.audience?.name }}</p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{ 'No of rules' | translate }}">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <p>{{ row?.rules?.length }}</p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{ 'Create Date' | translate }}">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <p>{{ row?.created | date : "dd/MM/yyyy hh:mm" }}</p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{ 'Update Date' | translate }}">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <p>{{ row?.updated | date : "dd/MM/yyyy hh:mm" }}</p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="getWidth()" name="{{ 'Actions' | translate }}" [width]="100">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <button mat-stroked-button mat-button [matMenuTriggerFor]="afterMenu">
              {{ "Actions" | translate }}
              <mat-icon title="View Detail" matPostfix>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #afterMenu="matMenu" xPosition="after">
              <!-- *ngIf="checkPermission('system.delete_user')" -->
              <button class="action-btn-delete" mat-menu-item (click)="deleteCondition(row?.id, row?.audience?.name)">
                <mat-icon class="action-btn-delete" title="Delete Menu" matPrefix>delete </mat-icon>
                {{ "Delete" | translate }}
              </button>
            </mat-menu>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
  <div *ngIf="isCreateCondition" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
    <app-create-condition
      [audienceID]="audienceID"
      [@animate]="{ value: '*', params: { duration: '300ms', y: '50px' } }"
      [id]="conditionID"
      (cancelClick)="handleCancel()"
      (createCondition)="handleCancel()"></app-create-condition>
  </div>
</div>
