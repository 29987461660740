import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DeliveryService } from "app/services/delivery.service";
import { OrderService } from "app/services/orders/order.service";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { WalletService } from "app/services/wallet/wallet.service";
import { ChangeDeliveryAgentStatusComponent } from "../change-delivery-agent-status/change-delivery-agent-status.component";
import { fuseAnimations } from "@fuse/animations";
import { Console } from "console";

@Component({
    selector: "app-assign-delivery-agent",
    templateUrl: "./assign-delivery-agent.component.html",
    styleUrls: ["./assign-delivery-agent.component.scss"],
    animations: fuseAnimations,
})
export class AssignDeliveryAgentComponent implements OnInit {
    loading: boolean = true;

    form: FormGroup;

    agentsList: any;
    deliveryId: any;
    multiDeliveryId: any;
    count: any = null;

    constructor(
        public dialogRef: MatDialogRef<AssignDeliveryAgentComponent>,
        private formBuilder: FormBuilder,
        private utilitiesService: UtilitiesService,

        private translationService: TranslationService,
        private deliveryService: DeliveryService,
        public cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data) {
            if (data?.deliveryDetails) {
                console.log(data);
                this.deliveryId = data?.deliveryDetails?.id;
                console.log(this.deliveryId);
            } else if (data?.multiDeliveryId) {
                this.multiDeliveryId = data?.multiDeliveryId;
                this.count = this.multiDeliveryId.length;
                console.log(this.count);
            }
            this.formInitializemulti();
        }
    }

    async ngOnInit(): Promise<void> {
        this.utilitiesService.startLoader();
        this.loading = true;
        await this.getAllAgent();
        this.loading = false;
    }
    async getAllAgent() {
        await this.deliveryService.getDeliveryAgentList().subscribe((res) => {
            this.agentsList = res.results;
        });
        this.cdr.markForCheck();
        this.loading = false;
        this.utilitiesService.stopLoader();
    }

    async formInitializemulti() {
        this.loading = true;
        console.log("ini wrong");
        this.form = this.formBuilder.group({
            delivery_ids: [
                this.multiDeliveryId ? this.multiDeliveryId : [this.deliveryId],
            ],
            delivery_agent: [
                this.deliveryId
                    ? this.data?.deliveryDetails?.delivery_agent?.id
                    : null,
                Validators.required,
            ],
        });
        this.loading = false;
    }
    async updateAgentStatus() {
        const form = this.form.value;
        console.log("form");
        console.log(form);
        if (this.form.valid) {
            console.log("valid");
            console.log(this.form.valid);
            this.utilitiesService.startLoader();
            this.loading = true;
            try {
                await this.deliveryService
                    .assignOrderDelivery(form)
                    .subscribe((res) => {
                        if (res) {
                            this.loading = false;
                            this.utilitiesService.stopLoader();
                            this.cdr.markForCheck();
                            this.dialogRef.close(res.message_en);
                        }
                        this.loading = false;
                        this.utilitiesService.stopLoader();
                    });
            } catch {
            } finally {
            }
        }
    }

    close() {
        this.dialogRef.close(null);
    }
}
