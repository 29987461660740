<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
            [formGroup]="paydoForm">
            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back</mat-icon>
                    <label class="highlight-text-color">{{'MyPaydo Settings' | translate}}</label>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <button (click)="save()" type="submit" mat-flat-button style="position: absolute;right: 16px;"
                        class="primary-bg-color">{{'Save' | translate}}</button>
                </div>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                [@animate]="{value:'*',params:{duration:'400ms',y:'70px'}}">
                <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 center-container" fxLayoutAlign="start stretch"
                    fxLayout="column" fxLayoutGap="1em">
                    <label class="highlight-text-color">{{'MyPaydo General Settings' | translate}}</label>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                            fxLayout.lt-md="column" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Account'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_Account' | translate}}" formControlName="account"
                                        matInput name="title">
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Source App'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_Source_APP' | translate}}"
                                        formControlName="source_app" matInput name="title">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                            fxLayout.lt-md="column" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Root URL'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_Root_URL' | translate}}" formControlName="root_url"
                                        matInput name="title">
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'CDN URL'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_CDN_URL' | translate}}" formControlName="cdn_url"
                                        matInput name="title">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayoutGap="1em" fxLayout.gt-sm="row"
                            fxLayout.lt-md="column">

                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Token'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_Token' | translate}}" formControlName="token"
                                        matInput name="title">
                                </mat-form-field>
                            </div>
                            <!-- <div fxFlex="1 1 auto"  fxLayoutGap="1em">
                                <mat-slide-toggle formControlName="show_tax_inc_label">
                                    {{'Show Tax inc label' | translate}}
                                </mat-slide-toggle>
                                <mat-slide-toggle formControlName="show_tax">
                                    {{'Show Tax' | translate}}
                                </mat-slide-toggle>
                            </div> -->

                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>