import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { CustomerService } from "app/services/customer/customer.service";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";

@Component({
    selector: "app-edit-customer",
    templateUrl: "./edit-customer.component.html",
    styleUrls: ["./edit-customer.component.scss"],
})
export class EditCustomerComponent implements OnInit {
    @Input() customerDetail: any;
    @Input() dynamicFieldList = [];
    @Output() afterEdit: EventEmitter<any> = new EventEmitter<any>();
    checkboxModel: any[][];
    editForm: FormGroup;
    isReady: boolean = false;
    checkBoxValues: any[][];
    constructor(
        private formBuilder: FormBuilder,
        private translationService: TranslationService,
        public utilitiesService: UtilitiesService,
        private dialog: MatDialog,
        private customerService: CustomerService,
        private translate: TranslateService
    ) {
        this.checkBoxValues = [];
        this.checkboxModel = [];
    }

    ngOnInit(): void {
        this.editForm = this.formBuilder.group({
            first_name: [
                this.customerDetail.first_name
                    ? this.customerDetail.first_name
                    : null,
                [Validators.compose([Validators.required])],
            ],
            middle_name: [
                this.customerDetail.middle_name
                    ? this.customerDetail.middle_name
                    : null,
            ],
            last_name: [
                this.customerDetail.last_name
                    ? this.customerDetail.last_name
                    : null,
            ],
            gender: [
                this.customerDetail.gender ? this.customerDetail.gender : null,
            ],
            contact_no: [
                this.customerDetail.contact_no
                    ? this.customerDetail.contact_no
                    : null,
                [Validators.compose([Validators.required])],
            ],
            email: [
                this.customerDetail.email ? this.customerDetail.email : null,
                [Validators.compose([Validators.required, Validators.email])],
            ],
        });
        let count = 0;
        this.dynamicFieldList.forEach((data) => {
            if (data.type == "checkbox") {
                if (data.possible_values.length > 0) {
                    this.checkboxModel[data.name] = [];
                    this.checkBoxValues[data.name] = [];
                    data.possible_values.forEach((items) => {
                        this.checkboxModel[data.name][items.id] = false;
                    });
                    let type = typeof this.customerDetail.dynamic_fields[
                        data.name
                    ];
                    if (
                        this.customerDetail.dynamic_fields[data.name] &&
                        this.customerDetail.dynamic_fields[data.name].length >
                            0 &&
                        this.customerDetail.dynamic_fields[data.name] instanceof
                            Array
                    ) {
                        this.customerDetail.dynamic_fields[data.name].forEach(
                            (field) => {
                                this.checkBoxValues[data.name].push(field);
                                this.checkboxModel[data.name][field] = true;
                            }
                        );
                    }
                }
            }
            count = count + 1;
            this.editForm.addControl(
                data.name,
                new FormControl(
                    this.customerDetail.dynamic_fields[data.name]
                        ? this.customerDetail.dynamic_fields[data.name]
                        : ""
                )
            );
        });
        if (count == this.dynamicFieldList.length) {
            this.isReady = true;
        }
    }
    checkboxChange(id) {
    }

    checkboxChanged(formControlName, event, id) {
        if (event.checked) {
            this.checkBoxValues[formControlName].push(id);
            this.checkboxModel[formControlName][id] = true;
            this.editForm.controls[formControlName].setValue(
                this.checkBoxValues[formControlName]
            );
        } else {
            this.checkBoxValues[formControlName] = this.checkBoxValues[
                formControlName
            ].filter((data) => data != id);
            this.checkboxModel[formControlName][id] = false;
            this.editForm.controls[formControlName].setValue(
                this.checkBoxValues[formControlName]
            );
        }
    }
    cancel() {
        this.afterEdit.emit();
    }

    save() {
        if (this.editForm.valid) {
            let content = `Are you sure, Do you want to update? `;
            let heading = "Update";
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe((resp) => {
                if (resp) {
                    let form = this.editForm.value;
                    let dynamic_fields = {};
                    this.dynamicFieldList.forEach((data) => {
                        dynamic_fields = {
                            ...dynamic_fields,
                            [data.name]: form[data.name],
                        };
                        delete form[data.name];
                    });
                    form["dynamic_fields"] = dynamic_fields;
                    let successMsg = this.translate.instant(
                        `Customer updated successfully`
                    );
                    this.customerService
                        .updateCustomer(this.customerDetail.id, form)
                        .subscribe(async (data) => {
                            this.utilitiesService.showSuccessToast(successMsg);
                        });
                    this.afterEdit.emit();
                }
            });
        }
    }
}
