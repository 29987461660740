import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { WalletService } from "app/services/wallet/wallet.service";
import { TopupWalletComponent } from "../topup-wallet/topup-wallet.component";
import { OrderStatus } from "app/main/types";
import { OrderService } from "app/services/orders/order.service";
import { fuseAnimations } from "@fuse/animations";
@Component({
    selector: "app-change-order-status",
    templateUrl: "./change-order-status.component.html",
    styleUrls: ["./change-order-status.component.scss"],
    animations: fuseAnimations,
})
export class ChangeOrderStatusComponent implements OnInit {
    loading: boolean = false;
    offset: number = 0;
    last_status: any;
    searchKey: any;
    orderStatus = [
        { id: 1, value: "Recived" },
        { id: 2, value: "Payment Pending" },
        { id: 3, value: "Confirmed" },
        { id: 4, value: "Packed" },
        { id: 5, value: "Picking Started" },
        { id: 6, value: "Shipped" },
        { id: 7, value: "Delivered " },
        { id: 8, value: "Cancelled " },
        { id: 9, value: "Returned " },
        { id: 10, value: "Callback " },
    ];
    page = {
        size: 10,
        totalElements: 0,
        pageNumber: 0,
    };
    // order_status: any;
    form: FormGroup;
    orderId: any = "";

    constructor(
        private orderService: OrderService,
        public dialogRef: MatDialogRef<ChangeOrderStatusComponent>,
        private formBuilder: FormBuilder,
        private utilitiesService: UtilitiesService,
        private walletService: WalletService,
        private translationService: TranslationService,

        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data) {
            this.orderId = data.orderId ? data.orderId : null;
            this.last_status = data.order_status ? data.order_status : "";
        }
    }

    async ngOnInit(): Promise<void> {
        await this.formInitialize();
    }

    async formInitialize() {
        this.form = this.formBuilder.group({
            id: [this.orderId],
            order_status: [this.last_status, Validators.required],
            remarks: [null],
        });
    }
    async updateOrderStatus() {
        const form = this.form.value;
        try {
            this.utilitiesService.startLoader();
            const orderList = await this.orderService
                .updateOrderStatus(this.orderId, form)
                .toPromise();
            if (orderList) {
                this.utilitiesService.stopLoader();
            } else {
                this.utilitiesService.stopLoader();
            }
        } catch {
        } finally {
        }
    }
    async send() {
        if (this.form.valid) {
            const form = this.form.value;

            await this.utilitiesService.startLoader();
            const orderList = await this.orderService
                .updateOrderStatus(this.orderId, form)
                .toPromise();
            if (orderList) {
                console.log(orderList);
                this.dialogRef.close(true);
                //     this.utilitiesService.stopLoader();
            } else {
                this.dialogRef.close(false);
                //     this.utilitiesService.stopLoader();
            }

            this.utilitiesService.stopLoader();
        }
        this.utilitiesService.stopLoader();
    }

    close() {
        this.dialogRef.close(null);
    }
}
