<div id="fab-dismiss" *ngIf="fabTogglerState==='active'" (click)="onToggleFab()">
</div>
<div class='fab-container' [style.flexDirection]="reverseColumnDirection?'column-reverse':'column'">
    <button mat-fab class="fab-toggler" (click)="onToggleFab()">
        <mat-icon [@fabToggler]="{value: fabTogglerState}">settings</mat-icon>
    </button>
    <div [className]="reverseColumnDirection?'column-reverse':'column'" [ngClass]="{'p-8':fabTogglerState=='active'}"
        class="button-container" [@speedDialStagger]="buttons.length">
        <!-- *ngIf="isCancelVis ?  btn.tooltip  : btn.tooltip !='Cancel Order'" -->
        <ng-container *ngFor="let btn of buttons">
            <button *ngIf="!btn.hidden" mat-mini-fab class="fab-secondary" color="secondary" (click)="onClickFab(btn)"
                [ngClass]="btn.selected">
                {{translationService.getTranslatedName(btn)}}
                <!-- <i class="material-icons material-icons-inner"></i> -->
            </button>
        </ng-container>
    </div>
</div>