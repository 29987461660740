import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { first } from "rxjs/operators";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";

@Component({
    selector: "app-price-rules",
    templateUrl: "./price-rules.component.html",
    styleUrls: ["./price-rules.component.scss"],
    animations: fuseAnimations,
})
export class PriceRulesComponent implements OnInit, AfterViewInit {
    @Input() selectedBlock: any;
    @Input() appDetail: any;
    @Output() back: EventEmitter<void> = new EventEmitter<any>();
    @Output() saveHander: EventEmitter<void> = new EventEmitter<any>();
    priceForm: FormGroup;
    discountType = [
        { name: "Percentage", id: 1 },
        { name: "Value", id: 2 },
    ];
    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private appService: AppService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService
    ) {
        // this.utilitiesService.autoSave.pipe(first()).subscribe((data) => {
        //     if (data == 10) {
        //         this.save();
        //     }
        // });
    }

    ngOnInit(): void {
        this.formInitialize();
    }

    formInitialize() {
        this.priceForm = this.fb.group({
            discount: [this.appDetail.discount ? this.appDetail.discount : 0.0],
            discount_type: [
                this.appDetail.discount_type ? this.appDetail.discount_type : 1,
            ],
            minimum_order_amount: [
                this.appDetail.minimum_order_amount
                    ? this.appDetail.minimum_order_amount
                    : 0.0,
            ],
        });
        this.priceForm.valueChanges.subscribe((data) => {
            this.utilitiesService.formChangedOrNot(true);
        });
    }
    goBack() {
        let fromApp = true;
        if (!this.utilitiesService.formChanged$.value) {
            this.back.emit();
        } else {
            let content = `You have unsaved changes, Please save before changing the page`;
            let heading = "Alert";
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp == "yes") {
                } else if (resp == "no") {
                    this.utilitiesService.formChangedOrNot(false);
                    this.back.emit();
                }
            });
        }
    }
    save() {
        let content = `Are you sure, Do you want to update? `;
        let heading = "Update";
        let fromApp=false
        let size = this.utilitiesService.isMobileAlertModal();
        const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading ,fromApp},
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });
        dialogRef.afterClosed().subscribe(async (resp) => {
            this.utilitiesService.autoSave.next(0)
            if (resp) {
                let form = this.priceForm.value;
                const resp = await this.appService
                    .addAppSettings(form)
                    .toPromise();

                if (resp) {
                  
                    this.utilitiesService.formChangedOrNot(false);
                    let successMsg = this.translate.instant(
                        "Field Settings updated successfully"
                    );
                    this.utilitiesService.showSuccessToast(successMsg);
                    this.saveHander.emit();
                }
            }
        });
    }
    ngAfterViewInit(): void {
        document.getElementById("toolbar").scrollIntoView();
    }
}
