import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sectionfilter'
})
export class ContainSectionPipe implements PipeTransform {

    transform(value: Array<any>, section: string): unknown {
        return value.some(el => el === section); 
    }

}
