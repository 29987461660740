<div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign.lt-md="center center" class="top-customers mt-12">
    <div class="top-customers__heading">
        <h2 [dir]="direction">
            {{header | translate}}
        </h2>
    </div>
    <div class="top-customers__cards" fxFlex="1 1 100%" fxLayout.lt-md="column" fxLayout.gt-md="row wrap"
        fxLayoutGap.gt-md="3.5px" fxLayoutAlign.lt-md="start center">
        <ng-template [ngIf]="data?.length > 0">
            <ng-container *ngFor="let customer of data; let i = index">
                <div class="top-customers__cards-card fuse-card" fxLayout="column" fxFlex.gt-md="1 1 19.7%"
                    (click)="navigateTo(customer?.customer_id)">
                    <div class="top-customers__cards-card__heading mb-8" fxFlex="1 1 100%" fxLayout="row"
                        fxLayoutAlign="space-between center">
                        <h3 class="text-bold">{{customer?.full_name}}</h3>
                        <div class="top-customers__cards-card__content-phone" fxLayout="row"
                            fxLayoutAlign="space-between baseline">
                            <img src="{{flags[i]}}" alt="flag">
                            <span>{{customer?.contact_no}}</span>
                        </div>
                    </div>
                    <div class="top-customers__cards-card__content" fxFlex="1 1 100%" fxLayout="row"
                        fxLayoutAlign="space-between center">
                        <div class="top-customers__cards-card__content-orders" fxLayout="column"
                            fxLayoutAlign="center center">
                            <h6>{{'No. Of Orders' | translate}}</h6>
                            <span class="text-bold">{{customer?.total_count}}</span>
                        </div>
                        <div class="top-customers__cards-card__content-sales" fxLayout="column"
                            fxLayoutAlign="center center">
                            <h6>{{'Total Sales' | translate}}</h6>
                            <span class="text-bold">{{customer?.total_revenue}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-template>

        <ng-container *ngIf="data?.length === 0">
            <div class="fuse-card empty-card">
                {{'No data found for selected period.' | translate}}
            </div>

        </ng-container>

    </div>
</div>