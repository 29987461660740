import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { PageElements } from "app/constants/types";

@Component({
  selector: "app-element-dropzone",
  templateUrl: "./element-dropzone.component.html",
  styleUrls: ["./element-dropzone.component.scss"],
})
export class ElementDropzoneComponent implements OnInit {
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  @Output() formValueChanged: EventEmitter<void> = new EventEmitter<any>();
  typeEnum = PageElements;
  formGroup: FormGroup;
  files = [];
  ckeConfig: any;
  elements = [];
  public arrayItems: any = [];
  constructor(private formBuilder: FormBuilder) {}

  get demoArray() {
    return this.formGroup.get("formArray") as FormArray;
  }
  ngOnInit(): void {
    this.ckeConfig = {
      allowedContent: true,
      versionCheck: false,
      removeButtons: "CreateDiv,BlockQuote,Superscript,Subscript,PasteFromWord,PasteText",
      // forcePasteAsPlainText: true,
      // toolbarGroups: [
      //     { name: "document", groups: ["mode", "document",] },
      //     { name: "clipboard", groups: ["clipboard", "undo"] },
      //     "/",
      //     { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
      //     {
      //         name: "paragraph",
      //         groups: ["list", "indent", "blocks", "align",],
      //     },
      //     "/",
      //     { name: "styles" },
      //     { name: "colors" },
      //     { name: "tools" },
      //     { name: "others" },
      // ],
      // toolbar: [["Source", "-", "Bold", "Italic"]],
    };
    this.formGroup = this.formBuilder.group({
      elementArray: this.formBuilder.array([]),
    });
  }
  drop(event) {}
  itemDropped(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.elements, event.previousIndex, event.currentIndex);
    } else {
      this.addField(event.item.data, event.currentIndex);
    }
    this.initializeFormArray();
  }

  addField(fieldType: any, index: number) {
    this.elements.splice(index, 0, fieldType);
  }

  delete(fieldIndex) {
    this.elements.splice(fieldIndex, 1);
  }

  save(fieldIndex) {}

  formChange(event) {
    this.formValueChanged.emit(event);
  }
  initializeFormArray() {
    (<FormArray>this.formGroup.get("elementArray")).push(this.addFormArray());
  }

  addFormArray() {
    let formObject = {};
    this.elements.forEach((data) => {
      data.configurations.forEach((items) => {
        formObject[items.name] = new FormControl("");
      });
    });
    let form = new FormGroup(formObject);
    return form;
  }
}
