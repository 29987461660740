import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
    _baseCurrency
    get baseCurrency(): boolean {
        return this._baseCurrency;
    }

    @Input() set baseCurrency(value: boolean) {
        this._baseCurrency = value;
    }

    constructor() { }

    ngOnInit(): void {
    }

}
