import { Component, Input, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { CampaignsService } from "app/services/marketing/campaigns.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { Clipboard } from '@angular/cdk/clipboard';
import { ApiService } from "app/services/api.service";
import { tap } from "rxjs/operators";
import { AppService } from "app/services/app.service";

enum DateType {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
}
@Component({
    selector: "app-view-campaign",
    templateUrl: "./view-campaign.component.html",
    styleUrls: ["./view-campaign.component.scss"]
})

export class ViewCampaignComponent implements OnInit {
    @Input() id: any;
    @Input() page: string;
    campaignDetail: any;
    templateDetail: any;
    isAffiliatePage: boolean;
    baseUrl: string;
    dateType: DateType = DateType.Daily;
    campaignStatics = {};
    dateRange = { start_date: '', end_date: '' }
    monthlyStatics;
    baseCurrency: number;
    previewDetail: any

    constructor(
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        private campaignsService: CampaignsService,
        private dialog: MatDialog,
        private sanitizer: DomSanitizer,
        private clipboard: Clipboard,
        private apiService: ApiService,
        private appService: AppService
    ) { }

    async ngOnInit(): Promise<void> {
        if (this.id) {

            this.utilitiesService.startLoader();
            this.isAffiliatePage = await this.checkForPage();
            this.baseUrl = await this.getBaseUrl();

            await this.viewCampaign();
            await this.templateDetails();
            await this.setInitialDate();
            this.getBaseCurrency();
        }

    }

    async getBaseUrl() {
        return this.apiService.BASE_URL;
    }

    async checkForPage() {
        return this.page === 'affiliate' ? true : false;
    }

    getBaseCurrency() {
        this.appService.getBaseCurrency().subscribe(currency => this.baseCurrency = currency.code);
    }

    async viewCampaign() {
        try {
            const campaignDetail = await this.campaignsService
                .viewCampaign(this.id)
                .toPromise();
            if (campaignDetail) {
                campaignDetail.statusInfo = this.campaignsService.getCampaignStatusInfo(campaignDetail.status)
                const filteredCampaign = this.campaignsService.campaigns.filter(campaign => campaign.id === campaignDetail?.campaign_channel?.id)
                if(filteredCampaign && filteredCampaign?.length > 0){
                    campaignDetail.campaign_logo=filteredCampaign[0]?.logo
                }
                this.campaignDetail = campaignDetail;
            }
        } catch {
        } finally {
        }
    }

    getDate(days) {

        var today = new Date(Date.now() - days * 24 * 60 * 60 * 1000);
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return (yyyy + '-' + mm + '-' + dd);
    }

    async setInitialDate() {
        this.dateRange = {
            start_date: this.getDate(29), //initialize the date for a whole month
            end_date: this.getDate(0)
        }
        this.getCampaignStatics();
    }

    async changeDate(dateRange) {
        this.dateRange = { ...dateRange };
        this.dateRange.start_date !== '' && this.dateRange.end_date !== '' ? await this.getCampaignStatics() : null;
    }

    async getCampaignStatics() {
        let type = "";
        let searchDate = '';
        let startDate = this.dateRange.start_date;
        let endDate = this.dateRange.end_date;

        this.campaignsService.getCampaignAnalysis(this.id, type, searchDate, startDate, endDate).pipe(
            tap({next: (data) => this.monthlyStatics = data?.campaign?.campaign_stats})
            ).subscribe(data => this.campaignStatics = { ...data })

    }

    async templateDetails() {
        try {
            if (this.campaignDetail && this.campaignDetail.template) {
                const templateDetail = await this.campaignsService
                    .getTemplateDetail(this.campaignDetail.template)
                    .toPromise();
                if (templateDetail) {
                    this.templateDetail = templateDetail;
                    this.getTemplatePreview()
                }
            }
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }

    getTemplatePreview() {
        this.campaignsService.getTemplatePreview({template_id:this.campaignDetail.template}).subscribe(res => {
            this.previewDetail = res
        })
    }

    getBody() {
        return this.sanitizer.bypassSecurityTrustHtml(this.previewDetail?.body);
    }

    copyUrl(urlValue) {
        this.clipboard.copy(urlValue);
        this.showCopyMessage();
    }

    showCopyMessage() {
        let copyText = this.translate.instant("Url Copied to clipboard!");
        this.utilitiesService.showSuccessToast(copyText);
    }

    isOutBondCampaign(channel_id) {
        let otherThanSocial_ids = [1, 2, 3]
        let value = false
        if (otherThanSocial_ids.includes(channel_id)) {
            value = true
        } else {
            value = false
        }
        return value
    }

}
