import { OrderStatusPipe } from "./../pipes/order-status.pipe";
import { TransactionStatusPipe } from "./../pipes/transaction-status.pipe";
import { TransactionTypePipe } from "./../pipes/transaction-type.pipe";
import { CreatePointRulesComponent } from "./create-point-rules/create-point-rules.component";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { FuseSharedModule } from "../../../@fuse/shared.module";
import { BreadcrumbsComponent } from "./breadcrumbs/breadcrumbs.component";
import { MatIconModule } from "@angular/material/icon";
import { CreatePageComponent } from "./create-page/create-page.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ElementDropzoneComponent } from "./element-dropzone/element-dropzone.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { ElementTypesComponent } from "./element-types/element-types.component";
import { CKEditorModule } from "ng2-ckeditor";
import { CreateSliderComponent } from "./create-sliders/create-sliders.component";
import { FabButtonComponent } from "./fab-button/fab-button.component";
import { SliderElementsComponent } from "./slider-elements/slider-elements.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DeleteButtonComponent } from "./delete-button/delete-button.component";
import { MatDialogModule } from "@angular/material/dialog";
import { AlertModalComponent } from "../components/alert-modal/alert-modal.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { CreateWidgetComponent } from "./create-widget/create-widget.component";
import { CreateMediaComponent } from "./create-media/create-media.component";
import { ViewMediaComponent } from "./view-media/view-media.component";
import { MatCardModule } from "@angular/material/card";
import { CreateAppComponent } from "./create-app/create-app.component";
import { CodemirrorModule } from "@ctrl/ngx-codemirror";
import { CommonFilterComponent } from "./common-filter/common-filter.component";
import { TermsConditionComponent } from "./app-components/terms-conditions/terms-conditions.component";
import { DesignComponent } from "./app-components/design/design.component";
import { DeliverySettingsComponent } from "./app-components/delivery-settings/delivery-settings.component";
import { CurrencyComponent } from "./app-components/currency/currency.component";
import { MypadydoSettingsComponent } from "./app-components/mypadydo-settings/mypadydo-settings.component";
import { AnalyticsComponent } from "./app-components/analytics/analytics.component";
import { SupportSettingsComponent } from "./app-components/support-settings/support-settings.component";
import { SuccessPageComponent } from "./app-components/success-page/success-page.component";
import { PopupComponent } from "./app-components/popup/popup.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { AppRightSideMenuComponent } from "./app-components/app-right-side-menu/app-right-side-menu.component";
import { MatSliderModule } from "@angular/material/slider";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { FormsModule } from "@angular/forms";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from "@angular-material-components/color-picker";
import { AppElementsComponent } from "./app-elements/app-elements.component";
import { DeliverySchedulingComponent } from "./app-components/delivery-scheduling/delivery-scheduling.component";
import { FirebaseSettingsComponent } from "./app-components/firebase-settings/firebase-settings.component";
import { PriceRulesComponent } from "./app-components/price-rules/price-rules.component";
import { StatusComponent } from "./app-components/status/status.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { GeneralSettingsComponent } from "./app-components/general-settings/general-settings.component";
import { CreateMenuComponent } from "./create-menu/create-menu.component";
import { GalleryPopupComponent } from "./gallery-popup/gallery-popup.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { ViewCustomersComponent } from "./view-customers/view-customers.component";
import { ViewCartComponent } from "./view-cart/view-cart.component";
import { MatTabsModule } from "@angular/material/tabs";
import { CreateNotificationComponent } from "./create-notification/create-notification.component";
import { AceEditorModule } from "ngx-ace-editor-wrapper";
import { FullscreenAdditionCssComponent } from "./fullscreen-addition-css/fullscreen-addition-css.component";
import { CreateCampaignComponent } from "./create-campaign/create-campaign.component";
import { ViewCampaignComponent } from "./view-campaign/view-campaign.component";
import { CampaignPreviewComponent } from "./campaign-preview/campaign-preview.component";
import { ShopifyDocumentationComponent } from "./shopify-documentation/shopify-documentation.component";
import { SendNotificationComponent } from "./send-notification/send-notification.component";
import { AddMembershipComponent } from "./add-membership/add-membership.component";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import { NgxMatDateAdapter, NgxMatDatetimePickerModule, NgxMatTimepickerModule } from "@angular-material-components/datetime-picker";
import { CodeEditorComponent } from "./app-components/code-editor/code-editor.component";
import { ElementHistoryComponent } from "./element-history/element-history.component";
import { MatRadioModule } from "@angular/material/radio";
import { FabComponent } from "./fab/fab.component";
import { ChatPanelModule } from "app/layout/components/chat-panel/chat-panel.module";
import { EditCustomerComponent } from "./edit-customer/edit-customer.component";
import { CreatePriceRulesComponent } from "./create-price-rules/create-price-rules.component";
import { MzdTimelineModule } from "ngx-mzd-timeline";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { SendMessageComponent } from "./send-message/send-message.component";
import { CreateAudienceComponent } from "./create-audience/create-audience.component";
import { CreateConditionComponent } from "./create-condition/create-condition.component";
import { ImageSizeWarningComponent } from "./image-size-warning/image-size-warning.component";
import { OrderTimelineComponent } from "./order-timeline/order-timeline.component";
import { CreateDynamicFieldsComponent } from "./create-dynamic-fields/create-dynamic-fields.component";
import { CustomCountryComponent } from "./app-components/custom-country/custom-country.component";
// import { NgxVerticalTimelineModule } from 'ngx-vertical-timeline';
import { NgSelectModule } from "@ng-select/ng-select";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { ConditionsListComponent } from "./conditions-list/conditions-list.component";
import { ColorPickerModule } from "ngx-color-picker";
import { from } from "rxjs";
import { IconImageWarningComponent } from "./icon-image-warning/icon-image-warning.component";
import { CreatePostComponent } from "./create-post/create-post.component";
import { ExportButtonComponent } from "./export-button/export-button.component";
import { DownloadListComponent } from "./download-list/download-list.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { ProgressBarComponent } from "../components/progress-bar/progress-bar.component";
import { CreatePostsCategoryComponent } from "./create-posts-category/create-posts-category.component";
import { CreateNotificationConfigComponent } from "./create-notification-config/create-notification-config.component";
import { CommonEditorComponent } from "./common-editor/common-editor.component";
import "brace/theme/clouds";
import { ConfigNotesComponent } from "./config-notes/config-notes.component";
import { CreateRolesPermissionsComponent } from "./create-roles-permissions/create-roles-permissions.component";
import { CreateUserComponent } from "./create-user/create-user.component";
import { EmailConfigComponent } from "./app-components/email-config/email-config.component";
import { SmsConfigComponent } from "./app-components/sms-config/sms-config.component";
import { CustomerDetailsPopoverComponent } from "./customer-details-popover/customer-details-popover.component";
import { ChangeUserPasswordPopoverComponent } from "./change-user-password-popover/change-user-password-popover.component";
import { CustomerOrdersTableComponent } from "./customer-orders-table/customer-orders-table.component";
import { CustomerMembershipTableComponent } from "./customer-membership-table/customer-membership-table.component";
import { CustomerWishlistTableComponent } from "./customer-wishlist-table/customer-wishlist-table.component";
import { CustomerAddressTableComponent } from "./customer-address-table/customer-address-table.component";
import { ClearFilterComponent } from "./clear-filter/clear-filter.component";
import { GallerySlidesComponent } from "./gallery-slides/gallery-slides.component";
import { NgxGalleryModule } from "@kolkov/ngx-gallery";
import { MatTableModule } from "@angular/material/table";
import { CatalogSettingsComponent } from "./app-components/catalog-settings/catalog-settings.component";
import { MatDividerModule } from "@angular/material/divider";
import { DocumentViewerComponent } from "./document-viewer/document-viewer.component";
import { VideoPlayComponent } from "./video-play/video-play.component";
import { CmsUploadMediaComponent } from "./cms-upload-media/cms-upload-media.component";
import { ShowAllTypeMediaComponent } from "./show-all-type-media/show-all-type-media.component";
import { OrderByPipe } from "../pipes/order-by.pipe";
import { TextFormatPipe } from "../pipes/text-format.pipe";
import { MobileAppComponent } from "./app-components/mobile-app/mobile-app.component";
import { ChartsComponent } from "./charts/charts.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { DatePickerComponent } from "./date-picker/date-picker.component";
import { SummaryComponent } from "./summary/summary.component";
import { DashboardStatisticsCardComponent } from "./dashboard-statistics-card/dashboard-statistics-card.component";
import { TopCustomersComponent } from "./top-customers/top-customers.component";
import { NgxEchartsModule } from "ngx-echarts";
import { GridsterModule } from "angular-gridster2";
import { ContainSectionPipe } from "../pipes/contain-section.pipe";
import { CheckAvailabilityPipe } from "../pipes/check-availability.pipe";
import { CarouselComponent } from "./carousel/carousel.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { ReviewListComponent } from "./review-list/review-list.component";
import { HeaderLessTabsDirective } from "app/main/directives/header-less-tabs.directive";
import { CreateProductComponent } from "./create-product/create-product.component";
import { CreateBulkUploadComponent } from "./create-product-bulk-upload-img/create-bulkupload.component";
import { FormFieldsComponent } from "./form-fields/form-fields.component";
import { CreateTagComponent } from "./create-tag/create-tag.component";
import { CreateOriginComponent } from "./create-origin/create-origin.component";
import { CreateStickerComponent } from "./create-sticker/create-sticker.component";
import { CreateCategoryComponent } from "./create-category/create-category.component";
import { CreateBrandComponent } from "./create-brand/create-brand.component";
import { CreateProductAttributeComponent } from "./create-product-attribute/create-product-attribute.component";
// import { HandlingInstructionsComponent } from './handling-instructions/handling-instructions.component';
import { CreateHandlingInstructionsComponent } from "./create-handling-instructions/create-handling-instructions.component";
import { WalletDetailComponent } from "./wallet-detail/wallet-detail.component";
import { AddImportComponent } from "./add-import/add-import.component";
import { ImportDetailsComponent } from "./import-details/import-details.component";
import { TopupWalletComponent } from "./topup-wallet/topup-wallet.component";
import { ChangeOrderStatusComponent } from "./change-order-status/change-order-status.component";
import { EditProductComponent } from "./edit-product/edit-product.component";
import { AppNgSelectComponent } from "./app-ng-select/app-ng-select.component";
import { OrderDetailsComponent } from "./order-details/order-details.component";
import { TagListComponent } from "./tag-list/tag-list.component";
import { StickerListComponent } from "./sticker-list/sticker-list.component";
import { HandlingInstructionsListComponent } from "./handling-instructions-list/handling-instructions-list.component";
import { TitleFormatComponent } from "./app-components/title-format/title-format.component";
import { MatChipsModule } from "@angular/material/chips";
import { AddLinkComponent } from "./add-link/add-link.component";
import { AddCampaignComponent } from "./add-campaign/add-campaign.component";
import { CampaignModalComponent } from "./campaign-modal/campaign-modal.component";
import { ChipsAutocompleteComponent } from "./chips-autocomplete/chips-autocomplete.component";
import { CustomDatePipe } from "../pipes/custom-date-format.pipe";
import { FooterConfigComponent } from "./footer-config/footer-config.component";
import { DeliveryAgentsComponent } from "./delivery-agents/delivery-agents.component";
import { ChangeDeliveryAgentStatusComponent } from "./change-delivery-agent-status/change-delivery-agent-status.component";
import { AddUpdateDeliveryAgentComponent } from "./add-update-delivery-agent/add-update-delivery-agent.component";
import { DeliveryComponent } from "./delivery/delivery.component";
import { UndeliveredReasonComponent } from "./undelivered-reason/undelivered-reason.component";
import { DeliveryDetailsComponent } from "./delivery-details/delivery-details.component";

import { AddUpdateUndeliveryReasonComponent } from "./add-update-undelivery-reason/add-update-undelivery-reason.component";
import { AssignDeliveryAgentComponent } from "./assign-delivery-agent/assign-delivery-agent.component";
import { ChangeDeliveryStatusComponent } from "./change-delivery-status/change-delivery-status.component";
import { PageRedirectionsComponent } from "./app-components/page-redirections/page-redirections.component";
import { CreatePageRedirectionComponent } from "./create-page-redirection/create-page-redirection.component";

// import { MatChipsModule } from "@angular/material/chips";
@NgModule({
  declarations: [
    HeaderLessTabsDirective,
    OrderByPipe,
    TextFormatPipe,
    ContainSectionPipe,
    CheckAvailabilityPipe,
    OrderStatusPipe,

    BreadcrumbsComponent,
    CreatePageComponent,
    ElementDropzoneComponent,
    ElementTypesComponent,
    CreateSliderComponent,
    FabButtonComponent,
    SliderElementsComponent,
    DeleteButtonComponent,
    AlertModalComponent,
    CreateWidgetComponent,
    CreateMediaComponent,
    ViewMediaComponent,
    CreateAppComponent,
    CommonFilterComponent,
    TermsConditionComponent,
    DesignComponent,
    DeliverySettingsComponent,
    CurrencyComponent,
    MypadydoSettingsComponent,
    AnalyticsComponent,
    SupportSettingsComponent,
    SuccessPageComponent,
    PopupComponent,
    AppRightSideMenuComponent,
    AppElementsComponent,
    DeliverySchedulingComponent,
    FirebaseSettingsComponent,
    PriceRulesComponent,
    StatusComponent,
    GeneralSettingsComponent,
    CreateMenuComponent,
    GalleryPopupComponent,
    ViewCustomersComponent,
    ViewCartComponent,
    CreateNotificationComponent,
    FullscreenAdditionCssComponent,
    CreateCampaignComponent,
    ViewCampaignComponent,
    CampaignPreviewComponent,
    ShopifyDocumentationComponent,
    SendNotificationComponent,
    AddMembershipComponent,
    CodeEditorComponent,
    ElementHistoryComponent,
    FabComponent,
    EditCustomerComponent,
    CreatePriceRulesComponent,
    SendMessageComponent,
    CreateAudienceComponent,
    CreateConditionComponent,
    ImageSizeWarningComponent,
    OrderTimelineComponent,
    CreateDynamicFieldsComponent,
    CustomCountryComponent,
    ConditionsListComponent,
    IconImageWarningComponent,
    CreatePostComponent,
    ExportButtonComponent,
    DownloadListComponent,
    ProgressBarComponent,
    CreatePostsCategoryComponent,
    CreateNotificationConfigComponent,
    CommonEditorComponent,
    CreateRolesPermissionsComponent,
    CreateUserComponent,
    EmailConfigComponent,
    SmsConfigComponent,
    CustomerDetailsPopoverComponent,
    ConfigNotesComponent,
    ChangeUserPasswordPopoverComponent,
    CustomerOrdersTableComponent,
    CustomerMembershipTableComponent,
    CustomerWishlistTableComponent,
    CustomerAddressTableComponent,
    ClearFilterComponent,
    GallerySlidesComponent,
    CatalogSettingsComponent,
    DocumentViewerComponent,
    VideoPlayComponent,
    CmsUploadMediaComponent,
    ShowAllTypeMediaComponent,
    MobileAppComponent,
    ChartsComponent,
    DatePickerComponent,
    CreatePointRulesComponent,
    SummaryComponent,
    DashboardStatisticsCardComponent,
    TopCustomersComponent,
    CarouselComponent,
    ReviewListComponent,
    CreateProductComponent,
    CreateBulkUploadComponent,
    FormFieldsComponent,
    CreateTagComponent,
    CreateOriginComponent,
    CreateStickerComponent,
    CreateCategoryComponent,
    CreateBrandComponent,
    CreateProductAttributeComponent,
    CreateHandlingInstructionsComponent,
    WalletDetailComponent,
    AddImportComponent,
    ImportDetailsComponent,
    TopupWalletComponent,
    ChangeOrderStatusComponent,
    EditProductComponent,
    AppNgSelectComponent,
    OrderDetailsComponent,
    TagListComponent,

    StickerListComponent,
    HandlingInstructionsListComponent,
    TitleFormatComponent,
    AddLinkComponent,
    AddCampaignComponent,
    CampaignModalComponent,
    ChipsAutocompleteComponent,
    CustomDatePipe,
    FooterConfigComponent,
    TitleFormatComponent,
    DeliveryAgentsComponent,
    ChangeDeliveryAgentStatusComponent,
    AddUpdateDeliveryAgentComponent,
    DeliveryComponent,
    UndeliveredReasonComponent,
    DeliveryDetailsComponent,
    AssignDeliveryAgentComponent,
    AddUpdateUndeliveryReasonComponent,
    ChangeDeliveryStatusComponent,
    PageRedirectionsComponent,
    CreatePageRedirectionComponent,
  ],
  imports: [
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    MatProgressSpinnerModule,
    NgApexchartsModule,
    NgxDocViewerModule,
    ColorPickerModule,
    NgOptionHighlightModule,
    NgSelectModule,
    TranslateModule,
    FuseSharedModule,
    MatIconModule,
    DragDropModule,
    MatExpansionModule,
    CKEditorModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatProgressBarModule,
    MatCardModule,
    CodemirrorModule,
    MatSlideToggleModule,
    NgxMatColorPickerModule,
    MatSliderModule,
    AngularMultiSelectModule,
    MatButtonToggleModule,
    NgxMaterialTimepickerModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatTabsModule,
    AceEditorModule,
    NgxMatMomentModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatRadioModule,
    ChatPanelModule,
    MzdTimelineModule,
    InfiniteScrollModule,
    NgxSkeletonLoaderModule,
    NgxGalleryModule,
    MatTableModule,
    MatDividerModule,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
    GridsterModule,
    CarouselModule,
    MatChipsModule,
  ],
  exports: [
    AddLinkComponent,
    MatProgressSpinnerModule,
    HeaderLessTabsDirective,
    WalletDetailComponent,
    TagListComponent,
    HandlingInstructionsListComponent,
    StickerListComponent,
    MatIconModule,
    ContainSectionPipe,
    CheckAvailabilityPipe,
    OrderStatusPipe,
    AddImportComponent,
    TopCustomersComponent,
    DashboardStatisticsCardComponent,
    SummaryComponent,
    DatePickerComponent,
    ChartsComponent,
    BreadcrumbsComponent,
    CreatePageComponent,
    CreateProductComponent,
    CreateBulkUploadComponent,
    CreateSliderComponent,
    FabButtonComponent,
    SliderElementsComponent,
    DeleteButtonComponent,
    AlertModalComponent,
    CreateWidgetComponent,
    CreateAppComponent,
    CommonFilterComponent,
    TermsConditionComponent,
    DesignComponent,
    DeliverySettingsComponent,
    CurrencyComponent,
    MypadydoSettingsComponent,
    AnalyticsComponent,
    SupportSettingsComponent,
    SuccessPageComponent,
    PopupComponent,
    AppRightSideMenuComponent,
    AppElementsComponent,
    DeliverySchedulingComponent,
    PriceRulesComponent,
    FirebaseSettingsComponent,
    StatusComponent,
    GeneralSettingsComponent,
    CreateMenuComponent,
    GalleryPopupComponent,
    ViewCustomersComponent,
    ViewCartComponent,
    CreateNotificationComponent,
    CreateCampaignComponent,
    ViewCampaignComponent,
    ShopifyDocumentationComponent,
    CodeEditorComponent,
    FabComponent,
    CreatePriceRulesComponent,
    SendMessageComponent,
    CreateConditionComponent,
    OrderTimelineComponent,
    CreateDynamicFieldsComponent,
    CustomCountryComponent,
    EditCustomerComponent,
    ConditionsListComponent,
    CreateAudienceComponent,
    CreateConditionComponent,
    CreatePostComponent,
    ExportButtonComponent,
    ProgressBarComponent,
    CreatePostsCategoryComponent,
    CreateNotificationConfigComponent,
    CommonEditorComponent,
    CreateRolesPermissionsComponent,
    CreateUserComponent,
    EmailConfigComponent,
    SmsConfigComponent,
    CustomerDetailsPopoverComponent,
    CustomerOrdersTableComponent,
    CustomerMembershipTableComponent,
    CustomerWishlistTableComponent,
    CreateMediaComponent,
    ConfigNotesComponent,
    ClearFilterComponent,
    CatalogSettingsComponent,
    VideoPlayComponent,
    CmsUploadMediaComponent,
    ShowAllTypeMediaComponent,
    MobileAppComponent,
    CreatePointRulesComponent,
    CarouselComponent,
    ReviewListComponent,
    CreateCategoryComponent,
    CreateBrandComponent,
    CreateProductAttributeComponent,
    AddCampaignComponent,
    ChipsAutocompleteComponent,
    CustomDatePipe,
    FooterConfigComponent,
    AddUpdateDeliveryAgentComponent,
    PageRedirectionsComponent,
  ],
  entryComponents: [
    AlertModalComponent,
    ViewMediaComponent,
    GalleryPopupComponent,
    FullscreenAdditionCssComponent,
    CampaignPreviewComponent,
    SendNotificationComponent,
    AddMembershipComponent,
    ElementHistoryComponent,
    EditCustomerComponent,
    ImageSizeWarningComponent,
    IconImageWarningComponent,
    DownloadListComponent,
    ChangeUserPasswordPopoverComponent,
    GallerySlidesComponent,
    DocumentViewerComponent,
  ],
  // providers: [
  //     {
  //         provide: MAT_COLOR_FORMATS,
  //         useValue: NGX_MAT_COLOR_FORMATS,
  //     },
  // ],
})
export class ComponentModule {}
