import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { PageService } from "app/services/pageService";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";
@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.scss'],
  animations: fuseAnimations
})
export class MobileAppComponent implements OnInit {
  @Input() selectedBlock: any;
  @Input() appDetail: any;
  @Output() back: EventEmitter<void> = new EventEmitter<any>();
  @Output() saveHander: EventEmitter<void> = new EventEmitter<any>();

  generalForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private fb1: FormBuilder,
    private dialog: MatDialog,
    private appService: AppService,
    public utilitiesService: UtilitiesService,
    private translate: TranslateService,
    private pageService: PageService
  ) { }

  ngOnInit(): void {
    this.formInitialize()
  }


  async formInitialize() {
    this.generalForm = this.fb.group({
      ask_for_mobile_app_rating: [this.appDetail.ask_for_mobile_app_rating || false],
      enable_pwa: [this.appDetail.enable_pwa || false],

      playstore_id: [
        this.appDetail ? this.appDetail.playstore_id : null,
      ],
      appstore_id: [
        this.appDetail ? this.appDetail.appstore_id : null,
      ],
      fb_client_token: [
        this.appDetail ? this.appDetail.fb_client_token : null,
      ],
      fb_app_id: [
        this.appDetail ? this.appDetail.fb_app_id : null,
      ],
    });
  }

  save() {
    let content = `Are you sure, Do you want to update? `;
    let heading = "Update";
    let fromApp = false;
    let size = this.utilitiesService.isMobileAlertModal();
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: { content, heading, fromApp },
      maxWidth: "",
      width: `${size.width}`,
      height: `${size.height}`,
    });
    dialogRef.afterClosed().subscribe(async (resp) => {
      this.utilitiesService.autoSave.next(0);
      if (resp) {
        let form = this.generalForm.value;
        const resp = await this.appService
          .addAppSettings(form)
          .toPromise();

        if (resp) {
          this.utilitiesService.formChangedOrNot(false);
          let successMsg = this.translate.instant(
            "Mobile App Settings updated successfully"
          );
          this.utilitiesService.showSuccessToast(successMsg);
          this.saveHander.emit();
        }
      }
    });
  }
  goBack() {
    let fromApp = true;
    if (!this.utilitiesService.formChanged$.value) {
      this.back.emit();
    } else {
      let content = `You have unsaved changes, Please save before changing the page`;
      let heading = "Alert";
      let size = this.utilitiesService.isMobileAlertModal();
      const dialogRef = this.dialog.open(AlertModalComponent, {
        data: { content, heading, fromApp },
        maxWidth: "",
        width: `${size.width}`,
        height: `${size.height}`,
      });
      dialogRef.afterClosed().subscribe(async (resp) => {
        if (resp == "yes") {
        } else if (resp == "no") {
          this.utilitiesService.formChangedOrNot(false);
          this.back.emit();
        }
      });
    }
  }
}
