<div fxLayout="column" fxFlex="100">
    <div class="p-16 pl-0" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
        <mat-icon style="color: red">warning</mat-icon>
        <div style="color: red;font-size: 24px;">
            {{'Large File'}}
        </div>
    </div>
    <div class="p-16 pt-0 pb-8" fxLayout="column" fxFlex="100" fxLayoutAlign="center center" fxLayoutGap="5px">
        <img *ngIf="img_url" [src]="utilitiesService.bypassTrustedURL(img_url)" style="height: 130px !important">
        ( Image Size : {{img_size | number : '1.2'}} MB )
        <strong>{{'This Image size is too big (Image size is greater than 3MB)'}}</strong>
    </div>
    <div class="p-16 pt-0" fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
        <strong>{{'Are you Sure, Do you want to upload this image' | translate}} ?</strong>
        <strong class="mt-8 mb-8">{{'Or'}}</strong>
        <strong>{{'You can use any of the below sites to resize the image , and reupload'  | translate}}</strong>
        <ul>
            <li class="cursor-pointer">
                <a (click)="openSite('https://resizeimage.net/')">resizeimage.net</a>
            </li>
            <li class="cursor-pointer">
                <a (click)="openSite('https://picresize.com/')">picresize.com</a>
            </li>
            <li class="cursor-pointer">
                <a (click)="openSite('https://www.img2go.com/resize-image')">IMG2GO.com</a>
            </li>
        </ul>
    </div>
    <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="center" fxLayoutGap="5px">
        <div>
            <button [mat-dialog-close]="false" mat-stroked-button color="warn">
                <mat-icon class="save-cancel-btn" matPrefix>close</mat-icon> {{'Cancel' | translate}}
            </button>
        </div>
        <div>
            <button mat-button color="primary" [mat-dialog-close]="true"
                class="mat-focus-indicator mat-raised-button mat-button-base mat-accent">
                <mat-icon class="save-cancel-btn" matPrefix>cloud_upload</mat-icon>
                {{'Upload' | translate}}
            </button>
        </div>
    </div>
</div>