import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { locale as english } from "assets/i18n/en";
import { locale as arabic } from "assets/i18n/ar";
import { navigation } from "app/navigation/navigation";
import { locale as navigationEnglish } from "app/navigation/i18n/en";
import { locale as navigationArabic } from "app/navigation/i18n/ar";
import { ApiService } from "./services/api.service";
import { environment } from "environments/environment";
import { TranslationService } from "./services/translationService";
import { NgxUiLoaderConfig, NgxUiLoaderService, SPINNER } from "ngx-ui-loader";
import { Router } from "@angular/router";
import { UtilitiesService } from "./services/utilitiesService";
import { CoreService } from "./services/core-service";
import { VersionCheckService } from "./services/version-check.service";

@Component({
    selector: "app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    fullUrl: any;
    // Private
    private _unsubscribeAll: Subject<any>;
    currentLanguage: string;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        private ngxService: NgxUiLoaderService,
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private apiService: ApiService,
        private translationService: TranslationService,
        private router: Router,
        public utilitiesService: UtilitiesService,
        private coreService: CoreService,
        private fuseSplashScreenService: FuseSplashScreenService,
        private versionCheckService: VersionCheckService
    ) {
        this.router.events.subscribe((data) => {
            if (this.utilitiesService.formChanged$.value) {
                this.utilitiesService.formChanged$.next(false);
            }
        });
        this.fullUrl = this.document.URL;
        const url = this.document.location.origin + "/";
        // this.apiService.initializeURLs(
        //     `${environment.production ? url : environment.server_url}`,
        //     `${environment.production ? url : environment.server_url}`
        // );
        console.log("url" + url);
        console.log("environment.server_url" + environment.server_url);
        if (this.coreService.accessToken) {
            this.refreshPermission();
        }

        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register("main", this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation("main");

        // Add languages
        this._translateService.addLangs(["en", "ar"]);
        this._fuseTranslationLoaderService.loadTranslations(english, arabic);

        // Set the default language
        this.currentLanguage = localStorage.getItem("language")
            ? localStorage.getItem("language")
            : "en";
        this._translateService.setDefaultLang(this.currentLanguage);

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(
            navigationEnglish,
            navigationArabic
        );
        this.coreService.isLogin.subscribe(async (data) => {
            if (data) {
                await this.removeHiddenNavLables();
                console.log(navigation);
                this.coreService.currentNavigation.next(navigation);
            }
        });

        this.coreService.currentNavigation.next(navigation);

        setTimeout(() => {
            //this is only the temporary fix
            this.translationService.useLanguage(this.currentLanguage);
            // this.fuseSplashScreenService.show();
        }, 500);

        // Use a language

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         */

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         * setTimeout(() => {
         * this._translateService.setDefaultLang('en');
         * this._translateService.setDefaultLang('tr');
         * });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add("is-mobile");
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    refreshPermission() {
        this.coreService.refreshPermission().subscribe((data) => {
            let resp: any = data;
            this.coreService.setPermission(resp.permissions);
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
                // Boxed
                if (this.fuseConfig.layout.width === "boxed") {
                    this.document.body.classList.add("boxed");
                } else {
                    this.document.body.classList.remove("boxed");
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith("theme-")) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    removeLablesRecursive(parent) {
        for (let innerChild of parent.children) {
            if (innerChild.children) {
                this.removeLablesRecursive(innerChild);
            }
            // let navItem = this._fuseNavigationService.getNavigationItem(
            //     innerChild.id
            // );
            this._fuseNavigationService.updateNavigationItem(
                `${innerChild.id}`,
                {
                    hidden: false,
                }
            );

            this._fuseNavigationService.updateNavigationItem(`${parent.id}`, {
                hidden: false,
            });
        }
    }
    async removeHiddenNavLables() {
        let currentNav = [...navigation];
        currentNav.forEach((parent) => {
            this.removeLablesRecursive(parent);
        });
    }
}
