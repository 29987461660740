<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em">
    <app-general-settings (saveHander)="saveHander()" [appDetail]="appDetail" [selectedBlock]="selectedBlock"
        (back)="back()" *ngIf="selectedBlock==appElements.GeneralSettings">
        <!-- [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}" -->
    </app-general-settings>
    <app-design (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()" [selectedBlock]="selectedBlock"
        *ngIf="selectedBlock==appElements.Design"></app-design>
    <app-delivery-settings (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()"
        [selectedBlock]="selectedBlock" *ngIf="selectedBlock==appElements.DeliverySettings">
    </app-delivery-settings>
    <app-delivery-scheduling (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()"
        [selectedBlock]="selectedBlock" *ngIf="selectedBlock==appElements.DeliveryScheduling">
    </app-delivery-scheduling>
    <app-currency (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()" [selectedBlock]="selectedBlock"
        *ngIf="selectedBlock==appElements.CountryRules"></app-currency>

    <app-mypadydo-settings (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()"
        [selectedBlock]="selectedBlock" *ngIf="selectedBlock==appElements.MyPadyoSettings">
    </app-mypadydo-settings>
    <app-firebase-settings (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()"
        [selectedBlock]="selectedBlock" *ngIf="selectedBlock==appElements.FirebaseSettings"></app-firebase-settings>
    <!-- <app-price-rules (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()" [selectedBlock]="selectedBlock"
        *ngIf="selectedBlock==appElements.GeneralSettings"></app-price-rules> -->
    <app-analytics (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()" [selectedBlock]="selectedBlock"
        *ngIf="selectedBlock==appElements.Analytics">
    </app-analytics>
    <app-support-settings (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()"
        [selectedBlock]="selectedBlock" *ngIf="selectedBlock==appElements.Support">
    </app-support-settings>
    <app-catalog-settings (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()"
        [selectedBlock]="selectedBlock" *ngIf="selectedBlock==appElements.CatalogSettings"></app-catalog-settings>
    <app-footer-config (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()"
        [selectedBlock]="selectedBlock" *ngIf="selectedBlock==appElements.FooterConfig">
    </app-footer-config>
    <!-- <app-success-page (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()"
        [selectedBlock]="selectedBlock" *ngIf="selectedBlock==appElements.GeneralSettings">
    </app-success-page> -->
    <!-- <app-popup (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()" [selectedBlock]="selectedBlock"
        *ngIf="selectedBlock=='14'"></app-popup> -->
    <app-terms-conditions-settings (saveHander)="saveHander()" [appDetail]="appDetail" [selectedBlock]="selectedBlock"
        (back)="back()" *ngIf="selectedBlock==appElements.TermsConditions">
    </app-terms-conditions-settings>
    <app-status (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()" [selectedBlock]="selectedBlock"
        *ngIf="selectedBlock==appElements.Status"></app-status>
    <app-email-config (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()"
        [selectedBlock]="selectedBlock" *ngIf="selectedBlock==appElements.EmailConfiguration"></app-email-config>
    <app-sms-config (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()" [selectedBlock]="selectedBlock"
        *ngIf="selectedBlock==appElements.SMSConfiguration"></app-sms-config>

    <app-mobile-app (saveHander)="saveHander()" [appDetail]="appDetail" (back)="back()" [selectedBlock]="selectedBlock"
        *ngIf="selectedBlock==appElements.MobileApp"></app-mobile-app>

    <app-app-right-side-menu (saveHander)="saveHander()" fxFlex="0 0 auto" (selectedElement)="selectedElementFn($event)"
        [selectedBlock]="selectedBlock"></app-app-right-side-menu>
</div>