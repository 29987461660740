import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "orderBy" })
export class OrderByPipe implements PipeTransform {
    transform(
        ary: any,
        field: string,
        fn: Function = (a, b) => (a[field] > b[field] ? 1 : -1)
    ): any {
        return ary.sort(fn);
    }
}
