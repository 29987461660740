<div
  class="create-product auto-width margin-auto"
  fxLayoutAlign="start stretch"
  fxLayout.lt-md="column"
  fxLayout.gt-sm="row"
  fxLayoutGap="1em">
  <form
    fxFlex="1 1 100%"
    fxLayoutAlign="start stretch"
    fxLayout.lt-md="column"
    fxLayout.gt-sm="column"
    fxLayoutGap="1em"
    [formGroup]="form">
    
    <mat-tab-group>
      <mat-tab label="Product Bulk Upload Image">
<div style="display: flex;align-items: center;justify-content: space-between;">
  <h4 style="color: rgb(240, 87, 87);">Note : The image name must match the product ID.</h4>
  <div
    fxFlex="1 1 auto"
    fxLayoutAlign.gt-sm="end center"
    fxLayoutAlign.lt-md="start stretch"
    fxLayout.lt-md="column-reverse"
    fxLayout.gt-sm="row"
    fxLayoutGap="5px"
    class="my-10">
    <button mat-stroked-button (click)="handleCancel()">
      <mat-icon>clear</mat-icon>
      {{ "Cancel" | translate }}
    </button>
    <button (click)="saveBulkUpload()" type="submit" mat-flat-button class="primary-bg-color">
      <mat-icon>save</mat-icon>
      {{ "Save" | translate }}
    </button>
  </div>
</div>
        <div fxFlex="0 0 auto" fxLayoutAlign="center">
          <div
            fxFlex="0 0  auto"
            class="fuse-card auto-width p-16"
            fxLayoutAlign="start stretch"
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutGap="1em">
            <div>
              <div class="images my-10" formArrayName="product_images">
                <!-- File Input for Multiple Images -->
                 <div>
                   <input type="file" (change)="onFilesSelected($event)" multiple hidden #fileInput />
                   <button mat-flat-button class="primary-bg-color" (click)="fileInput.click()">
                     <mat-icon>cloud_upload</mat-icon> Select Images
                   </button>
                 </div>
           

                <div *ngIf="getImageFormArray().value && getImageFormArray().value.length > 0" style="flex-wrap: wrap;">
                  <div  id="new" style="display: flex; flex-wrap: wrap !important;gap: 20px;" >
                    <div style="flex-wrap: wrap;" *ngFor="let image of form.get('product_images')['controls']; let i = index" >
                      <div [formGroupName]="i" class="image-container fuse-card p-8  my-10  w-1/4 ">
                        <div class="image" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                          <!-- Delete Icon -->
                          <mat-icon
                            (click)="deleteImage(i)"
                            *ngIf="getImageFormArray().at(i).value?.image"
                            class="action-btn-delete cursor-pointer">
                            delete
                          </mat-icon>
                          <!-- Image Preview -->
                          <img style="width: 100px" [src]="getImageFormArray().at(i).value?.image" alt="" />
  
                          <div fxLayout="row" fxLayoutGap="10px">
                            <mat-checkbox class="my-8" formControlName="cover_image" (change)="imageType('cover', i, $event)">
                              <span class="text-capitalized">{{ "Cover" }}</span>
                            </mat-checkbox>
                            <mat-checkbox class="my-8" formControlName="flip_image" (change)="imageType('flip', i, $event)">
                              <span class="text-capitalized">{{ "Flip" }}</span>
                            </mat-checkbox>
                          </div>
                        </div>
  
                        <!-- Additional Fields (Caption, Order By) -->
                        <div fxLayout="column">
                          <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{ "Caption" }}</mat-label>
                            <input matInput formControlName="caption" />
                          </mat-form-field>
                          <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{ "Order by" }}</mat-label>
                            <input matInput formControlName="order_by" />
                          </mat-form-field>
                          <div>
                            Product Id: {{ image.get('productId')?.value }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>
