import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { IconCss } from "app/main/ngxDatatable-Icons/icons";
import { AppRoutes } from "app/routes";
import { CoreService } from "app/services/core-service";
import { CustomerService } from "app/services/customer/customer.service";
import { DeliveryService } from "app/services/delivery.service";
import { CampaignsService } from "app/services/marketing/campaigns.service";
import { CartService } from "app/services/orders/cart.service";
import { OrderService } from "app/services/orders/order.service";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { debounceTime } from "rxjs/operators";
import { ChangeDeliveryAgentStatusComponent } from "../change-delivery-agent-status/change-delivery-agent-status.component";
import { Location } from "@angular/common";
import { AddUpdateUndeliveryReasonComponent } from "../add-update-undelivery-reason/add-update-undelivery-reason.component";

@Component({
    selector: "app-undelivered-reason",
    templateUrl: "./undelivered-reason.component.html",
    styleUrls: ["./undelivered-reason.component.scss"],
    animations: fuseAnimations,
})
export class UndeliveredReasonComponent implements OnInit {
    id: number;
    isLoading = true;

    form: FormGroup;
    searchKey = "";
    offset: number = 0;
    page = {
        size: 10,
        totalElements: 0,
        pageNumber: 0,
    };

    enableIcon: boolean = false;
    isEnableBack = false;
    label = {
        title_en: "un Delivered Reason",
        title_ar: "التوصيل",
    };
    icons = IconCss;
    iconsCss = {
        pagerLeftArrow: this.icons.left,
        pagerRightArrow: this.icons.right,
        pagerPrevious: this.icons.previous,
        pagerNext: this.icons.next,
    };
    statusList = [
        { id: 1, name: "Active" },
        { id: 2, name: "Inactive" },
    ];
    unDeliveredReasonList: any;

    constructor(
        public utilitiesService: UtilitiesService,

        private dialog: MatDialog,

        public translationService: TranslationService,

        public coreService: CoreService,
        private deliveryService: DeliveryService,
        private cdr: ChangeDetectorRef,
        public location: Location,
        public fb: FormBuilder
    ) {
        this.initializeForm();
    }

    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        this.utilitiesService.startLoader();
        await this.getUnDeliveredReasonList();
        this.isLoading = false;
        this.utilitiesService.stopLoader();
        this.form.valueChanges
            .pipe(debounceTime(1000))
            .subscribe(async (data) => {
                this.page.pageNumber = 0;
                this.page.size = 10;
                this.utilitiesService.startLoader();
                await this.getUnDeliveredReasonList();
            });
    }
    initializeForm() {
        this.isLoading = true;

        this.form = this.fb.group({
            name: [null],
            name_ar: [null],
        });
    }

    async getUnDeliveredReasonList(
        limit = this.page.size,
        offset = this.offset,
        form = this.form ? this.form.controls : undefined
    ) {
        this.isLoading = true;
        await this.deliveryService
            .getUnDeliveredReasonList(limit, offset, this.searchKey, form)
            .subscribe((res) => {
                if (res) {
                    this.page.totalElements = res.length;
                    this.unDeliveredReasonList = res;

                    this.cdr.markForCheck();
                }
                this.isLoading = false;
                this.utilitiesService.stopLoader();
            });
    }

    pageChange(event) {
        this.page.pageNumber = event.offset;
        this.offset = this.page.pageNumber * this.page.size;
        this.cdr.markForCheck();
        document.getElementById("dashboard-project").scrollIntoView();
        this.getUnDeliveredReasonList();
    }
    onActivateViewOrder(x) {}

    limitChange() {
        this.getUnDeliveredReasonList();
    }

    search(searchKey) {
        this.searchKey = searchKey;
        this.getUnDeliveredReasonList();
    }
    editReason(reasonDetails) {
        let isMobile = this.utilitiesService.isMobile();
        let size = {
            width: "45vw",
            height: "35vh",
        };
        if (isMobile) {
            size.height = "100%";
            size.width = "100%";
        }
        const dialogRef = this.dialog.open(AddUpdateUndeliveryReasonComponent, {
            data: { reasonDetails },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });

        dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
                if (resp) {
                    await this.getUnDeliveredReasonList();
                    this.utilitiesService.showSuccessToast(
                        "UnDelivered Reason Updated Successfully"
                    );
                }
                console.log("resp");
                console.log(resp);
            }
        });
    }
    addReason() {
        // let itemList = this.cartList;
        let isMobile = this.utilitiesService.isMobile();
        let size = {
            width: "45vw",
            height: "35vh",
        };
        if (isMobile) {
            size.height = "100%";
            size.width = "100%";
        }
        const dialogRef = this.dialog.open(AddUpdateUndeliveryReasonComponent, {
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });

        dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
                if (resp) {
                    await this.getUnDeliveredReasonList();
                    this.utilitiesService.showSuccessToast(
                        "UnDelivered Reason Added Successfully"
                    );
                }
                console.log("resp");
                console.log(resp);
            }
        });
    }
    async deleteReason(id) {
        this.isLoading = true;
        this.utilitiesService.startLoader();
        await this.deliveryService
            .deleteUnDeliveredReason(id)
            .subscribe(async (resp) => {
                this.cdr.markForCheck();
                await this.getUnDeliveredReasonList();
                this.utilitiesService.showSuccessToast(
                    "UnDelivered Reason Deleted Successfully"
                );
                this.utilitiesService.stopLoader();
            });
        this.isLoading = false;
    }
}
