export enum MediaTypes {
    Video = "video",
    Audio = "audio",
    Text = "text",
    Image = "image",
    Doc = "doc",
    PDF = "pdf",
    Excel = "excel",
}

export enum MediaTypesAccept{
    Video ='video/mp4,video/x-m4v,video/*',
    Audio = "audio/ogg,audio/mp3,audio/wav,audio/wpl,audio/mpa,audio/midi,audio/cda",
    Text = "text/*",
    Image = "image/x-png,image/gif,image/jpeg",
    Doc = ".doc,.docx",
    PDF = ".pdf",
    Excel = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
}
