<div
    fxFlex="1 1 auto"
    fxLayoutAlign="start stretch"
    fxLayout.lt-md="column"
    fxLayout.gt-sm="row"
    fxLayoutGap="1em"
>
    <div
        fxFlex.gt-sm="1 1 40%"
        fxFlex.lt-md="0 0 auto"
        fxLayoutAlign="start stretch"
        fxLayout="column"
        fxLayoutGap="1em"
    >
        <div
            class="fuse-card auto-width card-height"
            fxFlex.gt-sm="0 0 auto"
            fxFlex.lt-md="0 0 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="0px"
        >
            <div
                fxFlex="0 0 auto"
                class="p-8 customer-header"
                fxLayoutAlign="space-between center"
                fxLayout="row"
                fxLayoutGap="4px"
            >
                <div class="mat-subheading-2 mb-0">
                    {{ "Customer" | translate }}
                </div>
            </div>
            <div
                fxFlex="0 0 auto"
                class="p-16"
                id="customer"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="8px"
            >
                <div
                    *ngIf="
                        cartDetail?.customer?.first_name ||
                        cartDetail?.customer?.middle_name ||
                        cartDetail?.customer?.last_name
                    "
                    class="customer-name p-8"
                    fxFlex="0 0 auto"
                    fxLayoutAlign="space-between center"
                    fxLayout="row"
                    fxLayoutGap="4px"
                >
                    <!-- <mat-icon>perm_contact_calendar</mat-icon> -->
                    <div style="text-transform: uppercase">
                        {{ cartDetail?.customer?.first_name }}
                        {{ cartDetail?.customer?.middle_name }}
                        {{ cartDetail?.customer?.last_name }}
                    </div>
                    <small *ngIf="cartDetail?.customer?.customer_type == 2">{{
                        "Registered Customer" | translate
                    }}</small>
                    <small *ngIf="cartDetail?.customer?.customer_type == 1">{{
                        "Guest Customer" | translate
                    }}</small>
                </div>
                <div
                    *ngIf="
                        cartDetail?.customer?.email ||
                        cartDetail?.customer?.contact_no
                    "
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout.lt-md="column"
                    fxLayout.gt-sm="row"
                    fxLayoutGap="4px"
                >
                    <div
                        *ngIf="cartDetail?.customer?.email"
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "Email" | translate }}
                        </div>
                        <div class="item">
                            {{ cartDetail?.customer?.email }}
                        </div>
                    </div>
                    <div
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "Contact Number" | translate }}
                        </div>
                        <div
                            class="item"
                            fxFlex="1 1 0px"
                            fxLayoutAlign="start center"
                            fxLayout="row"
                            fxLayoutGap="4px"
                        >
                            <img
                                *ngIf="countryFlag"
                                [src]="countryFlag"
                                style="height: 9px !important"
                            />
                            <div
                                *ngIf="
                                    cartDetail?.customer?.contact_no_country
                                        ?.isd_code &&
                                    !utilitiesService.checkIfISDCodeExist(
                                        cartDetail?.customer?.contact_no
                                    )
                                "
                            >
                                +{{
                                    cartDetail?.customer?.contact_no_country
                                        ?.isd_code
                                }}
                            </div>
                            <div>
                                {{ cartDetail?.customer?.contact_no }}
                            </div>
                            <button
                                title="{{ 'Send SMS' | translate }}"
                                *ngIf="
                                    cartDetail?.customer?.contact_no &&
                                    coreService.checkPermission(
                                        utilitiesService.Permission
                                            .SendNotification
                                    )
                                "
                                mat-stroked-button
                                class="highlight-text-color send-sms"
                                (click)="sendNotification()"
                            >
                                <mat-icon class="send-sms-icon"
                                    >message</mat-icon
                                >
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="
                        cartDetail?.customer?.dob ||
                        cartDetail?.customer?.gender
                    "
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout.lt-md="column"
                    fxLayout.gt-sm="row"
                    fxLayoutGap="4px"
                >
                    <div
                        *ngIf="cartDetail?.customer?.dob"
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "DOB" | translate }}
                        </div>
                        <div class="item">
                            {{
                                cartDetail?.customer?.dob | date : "dd/MM/yyyy"
                            }}
                        </div>
                    </div>
                    <div
                        *ngIf="cartDetail?.customer?.gender"
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "Gender" | translate }}
                        </div>
                        <div class="item">
                            <span *ngIf="cartDetail?.customer?.gender == 1">{{
                                "Male" | translate
                            }}</span>
                            <span *ngIf="cartDetail?.customer?.gender == 2">{{
                                "Female" | translate
                            }}</span>
                            <span *ngIf="cartDetail?.customer?.gender == 3">{{
                                "Others" | translate
                            }}</span>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="cartDetail?.customer?.paci"
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout.lt-md="column"
                    fxLayout.gt-sm="row"
                    fxLayoutGap="4px"
                >
                    <div
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "PACI" | translate }}
                        </div>
                        <div class="item">
                            {{ cartDetail?.customer?.paci }}
                        </div>
                    </div>
                </div>
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout.lt-md="column"
                    fxLayout.gt-sm="row"
                    fxLayoutGap="4px"
                >
                    <div
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div class="text-bold">
                            {{ "Address" | translate }}
                        </div>
                        <div
                            fxFlex="0 0 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout.lt-md="column"
                            fxLayout.gt-sm="row"
                            fxLayoutGap="4px"
                        >
                            <div
                                fxFlex="1 1 0px"
                                fxLayoutAlign="start stretch"
                                fxLayout="column"
                                fxLayoutGap="4px"
                            >
                                <div>
                                    {{ "House No" | translate }}
                                </div>
                                <div class="item">
                                    {{
                                        cartDetail.customer_address?.house_no
                                            ? cartDetail.customer_address
                                                  .house_no
                                            : " - "
                                    }}
                                </div>
                            </div>
                            <div
                                fxFlex="1 1 0px"
                                fxLayoutAlign="start stretch"
                                fxLayout="column"
                                fxLayoutGap="4px"
                            >
                                <div>
                                    {{ "Block" | translate }}
                                </div>
                                <div class="item">
                                    {{
                                        cartDetail.customer_address?.block
                                            ? cartDetail.customer_address.block
                                            : " - "
                                    }}
                                </div>
                                <!-- <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                    fxLayout.gt-sm="row" fxLayoutGap="4px">
                                    <div fxFlex="1 1 0px" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="4px">
                                        <div>{{'Address' | translate}}</div>
                                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                            fxLayout.gt-sm="row" fxLayoutGap="4px">
                                            <div *ngIf="cartDetail?.customer_address?.house_no" fxFlex="1 1 0px"
                                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="4px">
                                                <div>{{'House No' | translate}}</div>
                                                <div class="item">{{cartDetail?.customer_address?.house_no}}</div>
                                            </div>
                                            <div *ngIf="cartDetail?.customer_address?.block" fxFlex="1 1 0px"
                                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="4px">
                                                <div>{{'Block' | translate}}</div>
                                                <div class="item">
                                                    {{cartDetail?.customer_address?.block}}
                                                </div>
                                            </div>
                                        </div>
                                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                            fxLayout.gt-sm="row" fxLayoutGap="4px">
                                            <div *ngIf="cartDetail?.customer_address?.street" fxFlex="1 1 0px"
                                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="4px">
                                                <div>{{'Street' | translate}}</div>
                                                <div class="item">{{cartDetail?.customer_address?.street}}</div>
                                            </div>
                                            <div *ngIf="cartDetail?.customer_address?.avenue" fxFlex="1 1 0px"
                                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="4px">
                                                <div>{{'Avenue ' | translate}}</div>
                                                <div class="item">
                                                    {{cartDetail?.customer_address?.avenue}}
                                                </div>
                                            </div>
                                        </div>
                                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                            fxLayout.gt-sm="row" fxLayoutGap="4px">
                                            <div *ngIf="cartDetail?.customer_address?.area?.name || cartDetail?.customer_address?.area?.name_en || cartDetail?.customer_address?.area?.name_ar"
                                                fxFlex="1 1 0px" fxLayoutAlign="start stretch" fxLayout="column"
                                                fxLayoutGap="4px">
                                                <div>{{'Area' | translate}}</div>
                                                <div class="item">
                                                    {{translationService.getTranslatedName(cartDetail?.customer_address?.area)}}
                                                    <span *ngIf="cartDetail?.customer_address?.area2">
                                                        {{',' +
                                                        translationService.getTranslatedName(cartDetail?.customer_address?.area2)}}
                                                    </span>
                                                    <span *ngIf="cartDetail?.customer_address?.area3">
                                                        {{',' +
                                                        translationService.getTranslatedName(cartDetail?.customer_address?.area3)}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div *ngIf="cartDetail?.country?.name_en || cartDetail?.country?.name_ar || cartDetail?.country?.name"
                                                fxFlex="1 1 0px" fxLayoutAlign="start stretch" fxLayout="column"
                                                fxLayoutGap="4px">
                                                <div>{{'Country ' | translate}}</div>
                                                <div class="item">
                                                    {{translationService.getTranslatedName(cartDetail?.customer?.contact_no_country)}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div
                                fxFlex="0 0 auto"
                                fxLayoutAlign="start stretch"
                                fxLayout.lt-md="column"
                                fxLayout.gt-sm="row"
                                fxLayoutGap="4px"
                            >
                                <div
                                    *ngIf="cartDetail?.customer_address?.street"
                                    fxFlex="1 1 0px"
                                    fxLayoutAlign="start stretch"
                                    fxLayout="column"
                                    fxLayoutGap="4px"
                                >
                                    <div>
                                        {{ "Street" | translate }}
                                    </div>
                                    <div class="item">
                                        {{
                                            cartDetail?.customer_address?.street
                                        }}
                                    </div>
                                </div>
                                <div
                                    *ngIf="cartDetail?.customer_address?.avenue"
                                    fxFlex="1 1 0px"
                                    fxLayoutAlign="start stretch"
                                    fxLayout="column"
                                    fxLayoutGap="4px"
                                >
                                    <div>
                                        {{ "Avenue " | translate }}
                                    </div>
                                    <div class="item">
                                        {{
                                            cartDetail?.customer_address?.avenue
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div
                                fxFlex="0 0 auto"
                                fxLayoutAlign="start stretch"
                                fxLayout.lt-md="column"
                                fxLayout.gt-sm="row"
                                fxLayoutGap="4px"
                            >
                                <div
                                    *ngIf="
                                        cartDetail?.customer_address?.area
                                            ?.name ||
                                        cartDetail?.customer_address?.area
                                            ?.name_en ||
                                        cartDetail?.customer_address?.area
                                            ?.name_ar
                                    "
                                    fxFlex="1 1 0px"
                                    fxLayoutAlign="start stretch"
                                    fxLayout="column"
                                    fxLayoutGap="4px"
                                >
                                    <div>
                                        {{ "Area" | translate }}
                                    </div>
                                    <div class="item">
                                        {{
                                            translationService.getTranslatedArea(
                                                cartDetail?.customer_address
                                                    ?.area
                                            )
                                        }}
                                        <span
                                            *ngIf="
                                                cartDetail?.customer_address
                                                    ?.area2
                                            "
                                        >
                                            {{
                                                "," +
                                                    translationService.getTranslatedArea(
                                                        cartDetail
                                                            ?.customer_address
                                                            ?.area2
                                                    )
                                            }}
                                        </span>
                                        <span
                                            *ngIf="
                                                cartDetail?.customer_address
                                                    ?.area3
                                            "
                                        >
                                            {{
                                                "," +
                                                    translationService.getTranslatedArea(
                                                        cartDetail
                                                            ?.customer_address
                                                            ?.area3
                                                    )
                                            }}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    *ngIf="
                                        cartDetail?.country?.name_en ||
                                        cartDetail?.country?.name_ar ||
                                        cartDetail?.country?.name
                                    "
                                    fxFlex="1 1 0px"
                                    fxLayoutAlign="start stretch"
                                    fxLayout="column"
                                    fxLayoutGap="4px"
                                >
                                    <div>
                                        {{ "Country " | translate }}
                                    </div>
                                    <div class="item">
                                        {{
                                            translationService.getTranslatedArea(
                                                cartDetail?.customer
                                                    ?.contact_no_country
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="isCustomerDynamicEmpty()">
                    <mat-divider class="mx-16"></mat-divider>
                    <div
                        fxFlex="0 0 auto"
                        class="p-16"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="5px"
                    >
                        <div
                            *ngFor="
                                let item of cartDetail?.customer?.dynamic_fields
                                    | keyvalue
                            "
                            fxFlex="1 1 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout="row"
                            fxLayoutGap="8px"
                        >
                            <div
                                fxFlex="1 1 0px"
                                style="text-transform: capitalize"
                            >
                                {{ getDynamicFieldName(item?.key) }}
                            </div>
                            <div fxFlex="1 1 0px" class="text-end">
                                <div *ngIf="item?.value">
                                    <div>
                                        {{
                                            getDynamicValues(
                                                item?.key,
                                                item?.value
                                            )
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="text-end"
                                    style="color: #ccc"
                                    *ngIf="!item?.value"
                                >
                                    {{ "No value" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div
            class="fuse-card auto-width card-height"
            fxFlex.gt-sm="0 0 auto"
            fxFlex.lt-md="0 0 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="0px"
        >
            <div
                fxFlex="0 0 auto"
                class="p-8 customer-header"
                fxLayoutAlign="space-between center"
                fxLayout="row"
                fxLayoutGap="4px"
            >
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="row"
                    fxLayoutGap="4px"
                    *ngIf="!cart"
                    class="mat-subheading-2 mb-0"
                >
                    {{ "Order Information" | translate }}
                </div>
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="row"
                    fxLayoutGap="4px"
                    *ngIf="cart"
                    class="mat-subheading-2 mb-0"
                >
                    {{ "Cart Information" | translate }}
                </div>
                <div
                    *ngIf="cartDetail?.awb"
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="row"
                    fxLayoutGap="4px"
                >
                    <span
                        >{{ "AWB" | translate }}
                        -
                    </span>
                    <strong *ngIf="cartDetail?.awb">{{
                        cartDetail.awb ? cartDetail.awb : "- "
                    }}</strong>
                </div>
            </div>
            <div
                fxFlex="0 0 auto"
                id="additional-info"
                class="p-16"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="8px"
            >
                <!-- delivery_mode & Fulfillment Status -->
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout.lt-md="column"
                    fxLayout.gt-sm="row"
                    fxLayoutGap="4px"
                >
                    <!-- delivery_mode -->
                    <div
                        *ngIf="cartDetail?.delivery_mode?.name"
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "Delivery Mode" | translate }}
                        </div>
                        <div class="item">
                            {{ cartDetail?.delivery_mode?.name }}
                        </div>
                    </div>
                    <!-- Fulfillment Status -->
                    <!-- <div
                                    fxFlex="1 1 0px"
                                    fxLayoutAlign="start stretch"
                                    fxLayout="column"
                                    fxLayoutGap="4px"
                                >
                                    <div>
                                        {{ "Fulfillment Status" | translate }}
                                    </div>
                                    <div
                                        class="item"
                                        *ngIf="
                                            cartDetail?.fulfillment_status ==
                                            '1'
                                        "
                                    >
                                        {{ "PENDING" | translate }}
                                    </div>
                                    <div
                                        class="item"
                                        *ngIf="
                                            cartDetail?.fulfillment_status ==
                                            '2'
                                        "
                                    >
                                        {{ "FULFILLED" | translate }}
                                    </div>
                                    <div
                                        class="item"
                                        *ngIf="
                                            cartDetail?.fulfillment_status ==
                                            '3'
                                        "
                                    >
                                        {{ "DELIVERED" | translate }}
                                    </div>
                                    <div
                                        class="item"
                                        *ngIf="
                                            cartDetail?.fulfillment_status ==
                                            '4'
                                        "
                                    >
                                        {{ "FAILED" | translate }}
                                    </div>
                                    <div
                                        class="item"
                                        *ngIf="
                                            cartDetail?.fulfillment_status ==
                                            '5'
                                        "
                                    >
                                        {{ "CANCELLED" | translate }}
                                    </div>
                                </div> -->
                </div>
                <!-- change status & Fulfillment Status -->
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout.lt-md="column"
                    fxLayout.gt-sm="row"
                    fxLayoutGap="4px"
                >
                    <!-- change status -->
                    <div
                        *ngIf="cartDetail?.order_status"
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "Order Status " | translate }}
                        </div>
                        <div
                            class="item"
                            fxFlex="1 1 0px"
                            fxLayoutAlign="start center"
                            fxLayout="row"
                            fxLayoutGap="4px"
                        >
                            <div *ngIf="cartDetail?.order_status">
                                {{ cartDetail?.order_status | orderstatus }}
                            </div>
                            <button
                                title="{{ 'Edit' | translate }}"
                                *ngIf="cartDetail?.order_status"
                                mat-stroked-button
                                class="highlight-text-color send-sms"
                                (click)="
                                    ChangeStatus(
                                        cartDetail.id,
                                        cartDetail?.order_status
                                    )
                                "
                            >
                                <mat-icon class="send-sms-icon">edit</mat-icon>
                            </button>
                        </div>
                    </div>
                    <!-- Remark -->
                    <div
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "Remark" | translate }}
                        </div>
                        <div
                            class="item"
                            fxFlex="1 1 0px"
                            fxLayoutAlign="start center"
                            fxLayout="row"
                            fxLayoutGap="4px"
                        >
                            <div>
                                {{
                                    cartDetail.remarks
                                        ? cartDetail.remarks
                                        : "-"
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Coupon &  Coupon ID  -->
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout.lt-md="column"
                    fxLayout.gt-sm="row"
                    fxLayoutGap="4px"
                >
                    <!-- Coupon -->
                    <div
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "Coupon" | translate }}
                        </div>
                        <div class="item">
                            {{ cartDetail.coupon ? cartDetail?.coupon : "-" }}
                        </div>
                    </div>
                    <!-- Coupon ID -->
                    <div
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "Coupon ID" | translate }}
                        </div>
                        <div class="item">
                            {{
                                cartDetail.coupon_id
                                    ? cartDetail.coupon_id
                                    : "-"
                            }}
                        </div>
                    </div>
                </div>
                <!-- Created Date & Updated Date -->
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout.lt-md="column"
                    fxLayout.gt-sm="row"
                    fxLayoutGap="4px"
                >
                    <!-- Created Date -->
                    <div
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "Created Date" | translate }}
                        </div>
                        <div class="item">
                            {{
                                cartDetail?.created
                                    | date : "dd/MM/yyyy hh mm a"
                            }}
                        </div>
                    </div>
                    <!-- Updated Date -->
                    <div
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "Updated Date" | translate }}
                        </div>
                        <div class="item">
                            {{
                                cartDetail?.updated
                                    | date : "dd/MM/yyyy hh mm a"
                            }}
                        </div>
                    </div>
                </div>
                <!-- Expected Delivery Date & Slot Timing -->
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout.lt-md="column"
                    fxLayout.gt-sm="row"
                    fxLayoutGap="4px"
                >
                    <!-- Expected Delivery Date -->
                    <div
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "Expected Delivery Date" | translate }}
                        </div>
                        <div class="item">
                            {{
                                cartDetail?.exp_delivery_date
                                    | date : "dd/MM/yyyy"
                            }}
                        </div>
                    </div>
                    <!-- Slot Timing -->
                    <div
                        *ngIf="cartDetail?.exp_delivery_slot?.slot_timing"
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "Slot Timing" | translate }}
                        </div>
                        <div class="item">
                            {{ cartDetail?.exp_delivery_slot?.slot_timing }}
                        </div>
                    </div>
                </div>
                <!-- Currency & Notes  -->
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout.lt-md="column"
                    fxLayout.gt-sm="row"
                    fxLayoutGap="4px"
                >
                    <!-- allow Substitutions -->
                    <div
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "allow Substitutions" | translate }}
                        </div>
                        <div class="item">
                            <div class="item">
                                {{ cartDetail?.allow_substitutions }}
                            </div>
                        </div>
                    </div>
                    <!-- Notes -->
                    <div
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "Notes" | translate }}
                        </div>
                        <div class="item">
                            {{ cartDetail.notes ? cartDetail?.notes : "-" }}
                        </div>
                    </div>
                </div>
                <!-- Civil ID -->
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout.lt-md="column"
                    fxLayout.gt-sm="row"
                    fxLayoutGap="4px"
                >
                    <!-- Civil ID -->
                    <div
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "Civil ID" | translate }}
                        </div>
                        <div class="item">
                            <div class="item">
                                {{
                                    cartDetail.civil_id
                                        ? cartDetail.civil_id
                                        : "-"
                                }}
                            </div>
                        </div>
                    </div>
                    <!-- package_option -->
                    <div
                        *ngIf="cartDetail?.package_option?.name"
                        fxFlex="1 1 0px"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="4px"
                    >
                        <div>
                            {{ "Package Option" | translate }}
                        </div>
                        <div class="item">
                            {{
                                cartDetail?.package_option?.name
                                    ? cartDetail?.package_option?.name
                                    : " - "
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="isDynamicEmpty()">
                <mat-divider class="mx-16"></mat-divider>
                <div
                    fxFlex="0 0 auto"
                    class="p-16"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="5px"
                >
                    <div
                        *ngFor="
                            let item of cartDetail?.dynamic_fields | keyvalue
                        "
                        fxFlex="1 1 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout="row"
                        fxLayoutGap="8px"
                    >
                        <div
                            fxFlex="1 1 0px"
                            style="text-transform: capitalize"
                        >
                            {{ getDynamicFieldName(item?.key) }}
                        </div>
                        <div fxFlex="1 1 0px">
                            <div *ngIf="item?.value" class="text-end">
                                <strong>
                                    {{
                                        getDynamicValues(item?.key, item?.value)
                                    }}
                                </strong>
                            </div>
                            <div
                                class="text-end"
                                style="color: #ccc"
                                *ngIf="!item?.value"
                            >
                                {{ "No value" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <!--payment  -->
        <div
            *ngIf="!cart"
            class="fuse-card auto-width card-height"
            fxFlex.gt-sm="0 0 auto"
            fxFlex.lt-md="0 0 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="0px"
        >
            <div
                fxFlex="0 0 auto"
                class="p-8 customer-header"
                fxLayoutAlign="start stretch"
                fxLayout="row"
                fxLayoutGap="4px"
            >
                <div class="mat-subheading-2 mb-0">
                    {{ "Transaction" | translate }}
                </div>
            </div>
            <div
                fxFlex="0 0 auto"
                class="p-16"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="8px"
            >
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="0px"
                >
                    <div class="content">
                        <table class="simple invoice-table">
                            <thead>
                                <tr>
                                    <th>
                                        {{ "Payment Date" | translate }}
                                    </th>
                                    <th>
                                        {{ "Payment Method" | translate }}
                                    </th>
                                    <th>
                                        {{ "Amount" | translate }}
                                    </th>
                                    <th>
                                        {{ "Transaction ID" | translate }}
                                    </th>
                                    <!-- <th>{{'Delivery' | translate}}</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <!-- *ngFor="let service of invoice.services" -->
                                <tr>
                                    <td>
                                        <div class="title">
                                            {{
                                                cartDetail?.order_date
                                                    | date
                                                        : "dd/MM/yyyy hh mm a"
                                            }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="title">
                                            {{
                                                cartDetail?.payment_method?.name
                                            }}
                                        </div>
                                    </td>
                                    <td>
                                        {{ cartDetail?.total }}
                                    </td>
                                    <td class="title">
                                        {{ cartDetail?.transaction_id }}
                                    </td>
                                    <!-- <td class="title">
                                                  {{cartDetail?.charges}} {{cartDetail?.currency?.code}}
                                              </td> -->
                                </tr>
                                <!-- Double the invoice data for demo purposes -->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- Order History -->
        <div
            *ngIf="!cart"
            class="fuse-card auto-width card-height"
            fxFlex.gt-sm="0 0 auto"
            fxFlex.lt-md="0 0 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="0px"
        >
            <div
                fxFlex="0 0 auto"
                class="p-8 customer-header"
                fxLayoutAlign="start stretch"
                fxLayout="row"
                fxLayoutGap="4px"
            >
                <div class="mat-subheading-2 mb-0">
                    {{ "Order History" | translate }}
                </div>
            </div>
            <div
                fxFlex="0 0 auto"
                class="p-16"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="8px"
            >
                <app-order-timeline
                    [timelineData]="cartDetail?.order_history"
                ></app-order-timeline>
            </div>
        </div>
    </div>
    <div
        fxFlex.gt-sm="1 1 60%"
        fxFlex.lt-md="0 0 auto"
        fxLayoutAlign="start stretch"
        fxLayout="column"
        fxLayoutGap="1em"
    >
        <div
            class="fuse-card auto-width card-height"
            fxFlex.gt-sm="0 0 auto"
            fxFlex.lt-md="0 0 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="8px"
        >
            <div
                fxFlex="0 0 auto"
                class="p-8 customer-header"
                fxLayoutAlign="space-between start"
                fxLayout="row"
                fxLayoutGap="4px"
            >
                <div class="mat-subheading-2 mb-0">
                    {{ "Products" | translate }}
                    <span class="badge-count"
                        ><strong>{{
                            cartDetail?.items?.length
                                ? cartDetail?.items?.length
                                : 0
                        }}</strong></span
                    >
                </div>
                <!-- <div
                                                        [class.disabled]="
                                                            cartDetail.order_status ==
                                                            7
                                                        "
                                                        (click)="
                                                            editProduct(
                                                                cartDetail.id,
                                                                cartDetail.items,
                                                                cartDetail.order_status
                                                            )
                                                        "
                                                        class="mat-subheading-2 mr-6 mb-0"
                                                    >
                                                        <mat-icon
                                                            class="highlight-text-color cursor-pointer"
                                                            matSuffix
                                                            >edit</mat-icon
                                                        >
                                                    </div> -->
            </div>
            <div
                fxFlex="0 0 auto"
                class="px-16"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="8px"
            >
                <div
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="0px"
                >
                    <div
                        class="content"
                        style="overflow: auto !important"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                    >
                        <div
                            fxFlex="0 0 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout="column"
                            fxLayoutGap="0px"
                            class="maxHeight"
                        >
                            <table
                                [multiTemplateDataRows]="true"
                                class="w-full min-w-320 table-fixed md:min-w-full mat-table"
                                mat-table
                                [dataSource]="cartDetail?.items"
                                fixedLayout="true"
                            >
                                <ng-container matColumnDef="image">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td
                                        [ngClass]="
                                            element?.is_bundle &&
                                            element?.bundle_items
                                                ? 'no-border bundle-bg'
                                                : ''
                                        "
                                        mat-cell
                                        *matCellDef="let element"
                                        class="p-0"
                                    >
                                        <div class="title">
                                            <img
                                                (click)="
                                                    openImg(
                                                        element?.product.image
                                                    )
                                                "
                                                style="height: 40px !important"
                                                class="cursor-pointer"
                                                *ngIf="element?.product.image"
                                                [src]="element?.product.image"
                                            />
                                            <img
                                                style="height: 60px !important"
                                                *ngIf="!element?.product.image"
                                                src="assets/images/scrumboard/noImage.png"
                                            />
                                        </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                    <th
                                        mat-header-cell
                                        *matHeaderCellDef
                                        class="text-bold"
                                    >
                                        {{ "Name" | translate }}
                                    </th>
                                    <td
                                        [ngClass]="
                                            element?.is_bundle &&
                                            element?.bundle_items
                                                ? 'no-border bundle-bg'
                                                : ''
                                        "
                                        mat-cell
                                        *matCellDef="let element"
                                        class="py-3"
                                    >
                                        {{ element?.product?.name }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="sku">
                                    <th
                                        mat-header-cell
                                        *matHeaderCellDef
                                        class="text-bold"
                                    >
                                        {{ "SKU" | translate }}
                                    </th>
                                    <td
                                        [ngClass]="
                                            element?.is_bundle &&
                                            element?.bundle_items
                                                ? 'no-border bundle-bg'
                                                : ''
                                        "
                                        mat-cell
                                        *matCellDef="let element"
                                        class="py-3"
                                    >
                                        {{ element?.product?.sku }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="quantity">
                                    <th
                                        mat-header-cell
                                        *matHeaderCellDef
                                        style="text-align: end"
                                        class="text-bold"
                                    >
                                        {{ "Quantity" | translate }}
                                    </th>
                                    <td
                                        style="text-align: end"
                                        [ngClass]="
                                            element?.is_bundle &&
                                            element?.bundle_items
                                                ? 'no-border bundle-bg'
                                                : ''
                                        "
                                        mat-cell
                                        *matCellDef="let element"
                                        class="py-3"
                                    >
                                        {{ element?.total_quantity }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="unit_price">
                                    <th
                                        mat-header-cell
                                        *matHeaderCellDef
                                        style="text-align: end"
                                        class="text-bold"
                                    >
                                        {{ "Unit Price" | translate }}
                                    </th>
                                    <td
                                        [ngClass]="
                                            element?.is_bundle &&
                                            element?.bundle_items
                                                ? 'no-border bundle-bg'
                                                : ''
                                        "
                                        mat-cell
                                        *matCellDef="let element"
                                        class="py-3"
                                        style="text-align: end"
                                    >
                                        {{
                                            element?.unit_price | number : "1.3"
                                        }}
                                        {{ element?.currency }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="price">
                                    <th
                                        mat-header-cell
                                        *matHeaderCellDef
                                        style="text-align: end"
                                        class="text-bold"
                                    >
                                        {{ "Price" | translate }}
                                    </th>
                                    <td
                                        [ngClass]="
                                            element?.is_bundle &&
                                            element?.bundle_items
                                                ? 'no-border bundle-bg'
                                                : ''
                                        "
                                        mat-cell
                                        *matCellDef="let element"
                                        class="py-3"
                                        style="text-align: end"
                                    >
                                        {{ element?.price }}
                                        {{ element?.currency }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="bundleItems">
                                    <td
                                        colspan="6"
                                        class="p-0 no-border"
                                        mat-cell
                                        *matCellDef="let element"
                                    >
                                        <div
                                            class="p-8 mb-4 border-b pl-40"
                                            *ngIf="
                                                element?.is_bundle &&
                                                element?.bundle_items &&
                                                element?.bundle_items?.length
                                            "
                                        >
                                            <!-- <div class="py-4 border-b">{{'Sub items of ' | translate}}
                                                        <strong>{{element?.product_name}}</strong>
                                                    </div> -->
                                            <table class="simple invoice-table">
                                                <thead>
                                                    <tr>
                                                        <td
                                                            colspan="12"
                                                            style="
                                                                border-bottom: 1px
                                                                    solid #ccc;
                                                            "
                                                        >
                                                            <strong
                                                                class="font-size-16"
                                                                >{{
                                                                    "Products"
                                                                }}</strong
                                                            >
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        *ngFor="
                                                            let item of element?.bundle_items;
                                                            let i = index
                                                        "
                                                    >
                                                        <td colspan="4">
                                                            <div
                                                                class="title pt-8"
                                                            >
                                                                <strong>
                                                                    {{
                                                                        i + 1
                                                                    }})</strong
                                                                >
                                                                {{
                                                                    item
                                                                        ?.product
                                                                        ?.name
                                                                }}
                                                            </div>
                                                        </td>
                                                        <td colspan="2">
                                                            <div
                                                                class="text-right pt-8"
                                                            >
                                                                <span>{{
                                                                    "Quantity :"
                                                                }}</span>
                                                                <strong>
                                                                    {{
                                                                        item?.quantity
                                                                    }}
                                                                </strong>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="customFields">
                                    <td
                                        colspan="6"
                                        class="p-0 no-border"
                                        mat-cell
                                        *matCellDef="let element"
                                    >
                                        <div
                                            class="mb-4 border-b pb-8 pl-40"
                                            *ngIf="
                                                element?.dynamic_fields
                                                    .length != undefined
                                            "
                                        >
                                            <table class="simple invoice-table">
                                                <thead>
                                                    <tr>
                                                        <td
                                                            colspan="12"
                                                            style="
                                                                border-bottom: 1px
                                                                    solid #ccc;
                                                            "
                                                        >
                                                            <strong
                                                                class="font-size-16"
                                                                >{{
                                                                    "More Information"
                                                                }}</strong
                                                            >
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        *ngFor="
                                                            let item of element
                                                                ?.dynamic_fields
                                                                .items
                                                                | keyvalue;
                                                            let i = index
                                                        "
                                                    >
                                                        <td colspan="4">
                                                            <div
                                                                class="font-size-14 pt-8"
                                                            >
                                                                <strong>
                                                                    {{
                                                                        i + 1
                                                                    }})</strong
                                                                >
                                                                <span>
                                                                    {{
                                                                        getDynamicFieldName(
                                                                            item?.key
                                                                        )
                                                                    }}</span
                                                                >
                                                            </div>
                                                        </td>
                                                        <td colspan="2">
                                                            <div
                                                                class="text-right pt-8 text-bold"
                                                                *ngIf="
                                                                    item?.value
                                                                "
                                                            >
                                                                {{
                                                                    getDynamicValues(
                                                                        item?.key,
                                                                        item?.value
                                                                    )
                                                                }}
                                                            </div>
                                                            <div
                                                                class="text-end pt-8"
                                                                style="
                                                                    color: #ccc;
                                                                "
                                                                *ngIf="
                                                                    !item?.value
                                                                "
                                                            >
                                                                {{
                                                                    "No value"
                                                                        | translate
                                                                }}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </td>
                                </ng-container>

                                <tr
                                    mat-header-row
                                    *matHeaderRowDef="
                                        displayedColumns;
                                        sticky: true
                                    "
                                ></tr>
                                <tr
                                    class="cursor-pointer"
                                    mat-row
                                    *matRowDef="
                                        let row;
                                        columns: displayedColumns
                                    "
                                ></tr>
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell border-b-0">
                                        No product found
                                    </td>
                                </tr>
                                <tr
                                    class="h-0"
                                    mat-row
                                    *matRowDef="
                                        let row;
                                        columns: ['bundleItems']
                                    "
                                ></tr>
                                <tr
                                    class="h-0"
                                    mat-row
                                    *matRowDef="
                                        let row;
                                        columns: ['customFields']
                                    "
                                ></tr>
                            </table>
                        </div>
                        <div
                            *ngIf="cartDetail?.items?.length <= 0"
                            fxFlex="0 0 auto"
                            fxLayoutAlign="center center"
                            fxLayout="column"
                            fxLayoutGap="0px"
                        >
                            <strong class="p-16">{{
                                "No items to display" | translate
                            }}</strong>
                        </div>
                        <table
                            class="simple invoice-table-footer"
                            *ngIf="cartDetail?.items?.length > 0"
                        >
                            <tbody>
                                <tr class="subtotal">
                                    <td>
                                        {{ "SUBTOTAL" | translate }}
                                    </td>
                                    <td style="color: #6abdd8">
                                        {{
                                            cartDetail?.sub_total
                                                | number : "1.3"
                                        }}
                                        {{ cartDetail?.currency?.code }}
                                    </td>
                                </tr>

                                <tr class="tax">
                                    <td>
                                        {{ "Delivery Charge" | translate }}
                                    </td>
                                    <td style="color: green">
                                        <!-- <span
                                                        *ngIf="
                                                            cartDetail?.shipping ==
                                                            0.0
                                                        "
                                                    >
                                                        free
                                                    </span> -->
                                        <span>
                                            {{
                                                cartDetail?.shipping
                                                    | number : "1.3"
                                            }}
                                            {{ cartDetail?.currency?.code }}
                                        </span>
                                    </td>
                                </tr>
                                <!-- payment_method_charge -->
                                <!-- <tr
                                                                        class="tax"
                                                                    >
                                                                        <td>
                                                                            {{
                                                                                "Cash Handling Charge"
                                                                                    | translate
                                                                            }}
                                                                        </td>
                                                                        <td>
                                                                            {{
                                                                                cartDetail
                                                                                    ?.charges_detail
                                                                                    .payment_method_charge
                                                                            }}
                                                                        </td>
                                                                    </tr> -->

                                <!-- charges -->
                                <!-- <tr class="subtotal">
                                                <td>
                                                    {{ "charges" | translate }}
                                                </td>
                                                <td>
                                                    {{ cartDetail?.charges }}
                                                </td>
                                            </tr> -->
                                <!-- Packaging Charge -->
                                <tr
                                    class="subtotal"
                                    *ngIf="
                                        cartDetail?.charges_detail?.packaging_charge
                                    "
                                >
                                    <td>
                                        {{ "packaging charge" | translate }}
                                    </td>
                                    <td>
                                        {{
                                            cartDetail?.charges_detail?.packaging_charge
                                        }}
                                    </td>
                                </tr>
                                <!-- payment_method_charge -->
                                <tr class="subtotal">
                                    <td>
                                        {{ "Cash Handling Charge" | translate }}
                                    </td>
                                    <td>
                                        {{
                                            cartDetail?.charges_detail
                                                ?.payment_method_charge
                                        }}
                                    </td>
                                </tr>
                                <tr class="subtotal">
                                    <td>
                                        {{ "Discount" | translate }}
                                    </td>
                                    <!-- style="color: green" -->
                                    <td>
                                        {{
                                            cartDetail?.discount
                                                | number : "1.3"
                                        }}
                                        {{ cartDetail?.currency?.code }}
                                    </td>
                                </tr>
                                <!-- TAX AMOUNT -->
                                <tr
                                    class="tax"
                                    *ngIf="cartDetail?.tax_amount != 0"
                                >
                                    <td>
                                        {{ "TAX" | translate }}
                                    </td>
                                    <!--  style="color: #ce9090" -->
                                    <td>
                                        {{
                                            cartDetail?.tax_amount
                                                | number : "1.3"
                                        }}
                                        {{ cartDetail?.currency?.code }}
                                    </td>
                                </tr>
                                <!-- paid_via_wallet -->
                                <tr
                                    class="subtotal"
                                    *ngIf="cartDetail?.use_wallet"
                                >
                                    <td
                                        [ngClass]="{
                                            color_green:
                                                cartDetail?.use_wallet == true
                                        }"
                                    >
                                        {{ "Paid Via Wallet" | translate }}
                                    </td>
                                    <td style="color: green">
                                        {{ cartDetail?.paid_via_wallet }}
                                    </td>
                                </tr>
                                <tr class="total">
                                    <td>
                                        {{ "Total" | translate }}
                                    </td>
                                    <td class="highlight-text-color">
                                        {{ cartDetail?.total | number : "1.3" }}
                                        {{ cartDetail?.currency?.code }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
