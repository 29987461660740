import { Inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

export enum AppElements {
    GeneralSettings = 1,
    Design = 2,
    DeliverySettings = 3,
    DeliveryScheduling = 4,
    CountryRules = 5,
    SocialMedia = 6,
    MyPadyoSettings = 7,
    FirebaseSettings = 8,
    Analytics = 9,
    Support = 10,
    TermsConditions = 11,
    Status = 12,
    EmailConfiguration = 13,
    SMSConfiguration = 14,
    Translation = 15,
    CustomFields = 16,
    CatalogSettings = 17,
    MobileApp = 18,
    FooterConfig = 19
}

export enum NotificationEnum {
    CUSTOMER_WISHLIST_AVAILABILITY = 4,
}

export class NotificationType {
    types = [
        { name: "Email", id: "EMAIL" },
        { name: "SMS", id: "SMS" },
        { name: "Push", id: "PUSH" },
    ];
}

export class NotificationEventType {
    types = [
        {
            name: "CUSTOMER_WISHLIST_AVAILABILITY",
            id: NotificationEnum.CUSTOMER_WISHLIST_AVAILABILITY,
        },
    ];
}

export class Appitems {
    appItems = [
        {
            id: AppElements.GeneralSettings,
            name_en: "General",
            name_ar: "عام",
            icon: "settings",
            selected: "",
            description: "",
            hidden: false,
        },
        {
            id: AppElements.CatalogSettings,
            name_en: "Catalog",
            name_ar: " فهرس ",
            icon: "pages",
            selected: "",
            description: "",
            hidden: false,
        },

        {
            id: AppElements.Design,
            name_en: "Design",
            name_ar: "التصميم",
            icon: "palette",
            selected: "",
            description: "",
            hidden: false,
        },
        {
            id: AppElements.MobileApp,
            name_en: "Mobile App",
            name_ar: "التصميم",
            icon: "phone_iphone",
            selected: "",
            description: "",
            hidden: false,
        },
        {
            id: AppElements.DeliverySettings,
            name_en: "Delivery",
            name_ar: "توصيل",
            icon: "local_shipping",
            selected: "",
            description: "",
            hidden: false,
        },
        {
            id: AppElements.DeliveryScheduling,
            name_en: "Delivery Scheduling",
            name_ar: "جدولة التسليم",
            icon: "schedule",
            selected: "",
            description: "",
            hidden: false,
        },
        {
            id: AppElements.CountryRules,
            name_en: "Country Rules",
            name_ar: "حكم البلد",
            icon: "local_atm",
            selected: "",
            description: "",
            hidden: false,
        },
        {
            id: AppElements.SocialMedia,
            name_en: "Social Media",
            name_ar: "وسائل التواصل الاجتماعي",
            icon: "public",
            selected: "",
            description: "",
            hidden: false,
        },
        // {
        //     id: AppElements.MyPadyoSettings,
        //     name_en: "MyPadyo",
        //     name_ar: "MyPadyo",
        //     icon: "perm_data_setting",
        //     selected: "",
        //     description: "",
        //     hidden: false,
        // },
        {
            id: AppElements.FirebaseSettings,
            name_en: "Firebase",
            name_ar: "Firebase",
            icon: "storage",
            selected: "",
            description: "",
            hidden: false,
        },

        {
            id: AppElements.Analytics,
            name_en: "SEO & Analytics",
            name_ar: "تحسين محركات البحث والتحليلات",
            icon: "graphic_eq",
            selected: "",
            description: "",
            hidden: false,
        },
        {
            id: AppElements.Support,
            name_en: "Support",
            name_ar: "الدعم",
            icon: "contact_support",
            selected: "",
            description: "",
            hidden: false,
        },
        {
            id: AppElements.TermsConditions,
            name_en: "Terms & Conditions",
            name_ar: "الأحكام والشروط",
            icon: "event_note",
            selected: "",
            description: "",
            hidden: false,
        },
        {
            id: AppElements.Status,
            name_en: "Status",
            name_ar: "الحالة",
            icon: "insert_comment",
            selected: "",
            description: "",
            hidden: false,
        },
        {
            id: AppElements.EmailConfiguration,
            name_en: "Email Configuration",
            name_ar: "تكوين البريد الإلكتروني",
            icon: "email",
            selected: "",
            description: "",
            hidden: false,
        },
        {
            id: AppElements.SMSConfiguration,
            name_en: "SMS Configuration",
            name_ar: "تكوين الرسائل القصيرة",
            icon: "sms",
            selected: "",
            description: "",
            hidden: false,
        },
        {
            id: AppElements.Translation,
            name_en: "Translation",
            name_ar: "ترجمة",
            icon: "translate",
            selected: "",
            description: "",
            hidden: false,
        },
        {
            id: AppElements.CustomFields,
            name_en: "Custom Fields",
            name_ar: "الحقول المخصصة",
            icon: "text_fields",
            selected: "",
            description: "",
            hidden: false,
        },
        {
            id: AppElements.FooterConfig,
            name_en: "Footer Configurations",
            name_ar: "تكوينات التذييل",
            icon: "linear_scale",
            selected: "",
            description: "",
            hidden: false,
        },
    ];
}
