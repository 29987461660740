<ng-container *ngIf="!openCustomLink; else customLinkTemplate">

    <ng-container *ngIf="!showButtonDetail; else socialButtonDetails">
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1em" class="add-link-popup">
            <div fxFlex="1 1 auto" fxLayoutAlign="space-between center" class="full-width">
                <h4 class="center-text">{{'Add new links' | translate}}</h4>
                <mat-icon class="cursor-pointer" [mat-dialog-close]="false">clear</mat-icon>
            </div>

            <div class="custom-links" FlexAlign="end" fxFlex="1 1 auto">
                <button class="cursor-pointer" (click)="createCustomLink()">
                    + {{'Add Custom Link' | translate}}
                </button>
            </div>

            <div class="choose-from" fxFlex="1 1 auto" fxLayoutAlign="space-between center">
                <span class="striped-line"></span>
                <span class="choose-from-text">{{'Or choose from' | translate}}</span>
                <span class="striped-line"></span>
            </div>

            <div class="links" fxFlex="1 1 100%" fxLayout="row wrap" fxLayoutGap="1em">
                <ng-container *ngFor="let link of links">
                    <button class="link-button cursor-pointer mb-10" fxFlex="0 1 30%" fxLayoutAlign="start center"
                        (click)="addButtonDetail({name: link?.name, icon: link?.icon, linkIconColor: link.iconColor})">

                        <ng-container *ngIf="!link.icon.includes('bi'); else iconTemplate">
                            <img [src]="link?.icon" width="32" height="32">
                        </ng-container>

                        <ng-template #iconTemplate>
                            <i [class]="link?.icon" [ngStyle]="{'color':link.iconColor}"></i>
                        </ng-template>

                        <span fxFlex="1 1 90%">{{link?.name | titlecase}}</span>
                    </button>
                </ng-container>
            </div>

        </div>
    </ng-container>

</ng-container>

<ng-template #customLinkTemplate>

    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1em" class="add-custom-link">
        <div fxFlex="1 1 auto" fxLayoutAlign="space-between center" class="full-width">
            <div fxLayout="column">
                <h4 class="mb-0 mt-0">{{'Add Custom Link' | translate}}</h4>
                <p class="center-text grey-color datatable-body-cell-label mt-0 mb-0">
                    {{'Add your custom social media page/profile' | translate}}
                </p>
            </div>
            <mat-icon class="cursor-pointer" (click)="closeCustomLink()">clear</mat-icon>
        </div>

        <div class="full-width" fxLayout="column">
            <div class="upload-file mb-30" fxLayout="row" fxLayoutAlign="space-between center">

                <ng-container *ngIf="form.controls.customLinkImage.value; else noImage">
                    <img [src]="form.controls.customLinkImage.value" alt="" width="30" height="30">
                </ng-container>

                <ng-container *ngIf="!form.controls.customLinkImage.value">
                    <div fxFlex="1 1 auto" fxLayoutGap="1em" fxLayoutAlign="end center">
                        <div class="add-logo cursor-pointer ">
                            <input class="attach-input cursor-pointer" type="file"
                                (change)="fileBrowseHandler($event.target?.files)" />
                            <label class="attach-label attach-label-file cursor-pointer">+
                                {{'Upload Link Image' | translate}}
                            </label>
                        </div>
                        <app-cms-upload-media [isMultiSelect]="false" [index]="0"
                            [formcontrol]="form.controls.customLinkImage" (afterUpload)="setFileToForm($event)"
                            [showBrowseOnly]="true">
                        </app-cms-upload-media>
                    </div>
                </ng-container>

                <ng-container *ngIf="form.controls.customLinkImage.value">
                    <div class="remove-logo" fxLayoutAlign="start center" fxLayout="row" (click)="removeCustomImage()">
                        <mat-icon>clear</mat-icon>
                        <label>
                            {{'Remove Image' | translate}}
                        </label>
                    </div>
                </ng-container>
            </div>

            <div fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="0.75em" class="name-section">
                <mat-label>{{'Link Name' | translate}}</mat-label>

                <mat-form-field class="full-width" appearance="outline">
                    <input matInput placeholder="Ex: Facebook, SnapChat, etc..." [(ngModel)]="platformName" required>
                </mat-form-field>

            </div>

            <div fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="0.75em" class="name-section">
                <mat-label>{{'Page Link' | translate}}</mat-label>

                <mat-form-field class="full-width link-input" appearance="outline">
                    <span class="https-prefix">https://</span>
                    <input matInput type="url" placeholder="www.example.com" [(ngModel)]="platformInfo" required>
                </mat-form-field>

            </div>

            <button class="add-button" [class.cursor-pointer]="platformName && platformInfo"
                [disabled]="!platformName || (!platformInfo.includes('.'))"
                [class.button-disable]="!platformName || (!platformInfo.includes('.'))" [mat-dialog-close]="{
                    name: platformName, 
                    icon: form.controls.customLinkImage.value, 
                    url: 'https://' + platformInfo, show: false,
                    linkIconColor: linkIconColor
                }">
                {{'Add' | translate}}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #socialButtonDetails>
    <div fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="1em" class="name-section">
        <div fxFlex="1 1 auto" fxLayoutAlign="space-between center">

            <mat-label fxFlex="1 1 auto" fxLayoutAlign="center center">
                <ng-container *ngIf="platformImg; else noImage">
                    <ng-container *ngIf="!platformImg.includes('bi'); else iconTemplate">
                        <img [src]="platformImg" width="25" height="25">
                    </ng-container>

                    <ng-template #iconTemplate>
                        <i [class]="platformImg" [ngStyle]="{'color':linkIconColor}"></i>
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="customLinkImage;">
                    <i [class]="customLinkImage"></i>
                </ng-container>

                <p class="ml-10">{{ platformName | titlecase}}</p>
            </mat-label>

            <mat-icon class="cursor-pointer" (click)="closeButtonDetails()">clear</mat-icon>

        </div>

        <mat-form-field class="full-width link-input" appearance="outline">
            <span class="https-prefix">{{isTelephone ? 'tel:' : 'https://'}}</span>
            <input matInput
                [placeholder]="isTelephone ? 'Your telphone should be like 0042531522' : 'Your link ex: facebook.com/user1' | translate"
                [(ngModel)]="platformInfo" required type="url" name="urlField">
        </mat-form-field>

        <ng-container *ngIf="!isWebSite">
            <div class="icon-color">
                <span>{{'change icon color' | titlecase | translate}}</span>
                <mat-icon (cpToggleChange)="openOrClose()" (colorPickerSelect)="setColor()" [cpOKButton]="true"
                    [cpCancelButton]="true" [cpSaveClickOutside]="false" [cpPosition]="'up'"
                    [(colorPicker)]="linkIconColor" class="color-icon cursor-pointer"
                    [style.color]="linkIconColor">color_lens</mat-icon>
            </div>
        </ng-container>


        <button class="add-button" [class.button-disable]="!isTelephone && !platformInfo.includes('.')"
            [mat-dialog-close]="
                {name: platformName, 
                icon: customLinkImage ? customLinkImage: platformImg,
                url: (isTelephone? 'tel:' : 'https://')+platformInfo, 
                linkIconColor: linkIconColor,
                show: false}" type="submit" [disabled]="!isTelephone && !platformInfo.includes('.')">
            {{'Add' | translate}}
        </button>
    </div>
</ng-template>


<ng-template #noImage>
    <div class="add-logo" *ngIf="!form.controls.customLinkImage.value">
        <input class="attach-input" type="file" (change)="fileBrowseHandler($event.target?.files)" id="fileInput" />
        <label class="image-placeholder" for="fileInput">
            <mat-icon>camera_alt</mat-icon>
        </label>
    </div>
</ng-template>