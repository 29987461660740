import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { CustomerService } from "app/services/customer/customer.service";
import { NotificationService } from "app/services/notification/notification.service";
import { TranslationService } from "app/services/translationService";
import { UserService } from "app/services/user-management/userService";
import { UtilitiesService } from "app/services/utilitiesService";
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
@Component({
  selector: "app-change-user-password-popover",
  templateUrl: "./change-user-password-popover.component.html",
  styleUrls: ["./change-user-password-popover.component.scss"],
})
export class ChangeUserPasswordPopoverComponent implements OnInit {
  form: FormGroup;
  username: any;
  userID: any;
  isCustomer?: boolean = false;
  isAdmin: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ChangeUserPasswordPopoverComponent>,
    private formBuilder: FormBuilder,
    private translationService: TranslationService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    public utilitiesService: UtilitiesService,
    private userService: UserService,
    private customerService: CustomerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.username = data.username;
    this.userID = data.userID;
    this.isAdmin = data.isAdmin ? data.isAdmin : false;
    this.isCustomer = data?.isCustomer;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        password: ["", [Validators.required, Validators.minLength(8)]],
        confirm_password: ["", Validators.required],
        user_id: [this.userID],
      },
      {
        validator: MustMatch("password", "confirm_password"),
      }
    );
  }

  async save() {
    try {
      if (this.form.valid) {
        this.utilitiesService.startLoader();
         if (this.isCustomer) {
          let form = this.form.value;
          const customerPassword = await this.customerService.changePasswordByAdmin({...form,customer_id:this.userID}).toPromise();
          if (customerPassword) {
            this.utilitiesService.stopLoader();
            let successMsg = this.translate.instant("Customer Password changed successfully");
            this.utilitiesService.showSuccessToast(successMsg);
            this.dialogRef.close(true);
          }
          return
        }
        if (!this.isAdmin) {
          let form = this.form.value;
          const userPassword = await this.userService.changeUserPassword(form).toPromise();
          if (userPassword) {
            this.utilitiesService.stopLoader();
            let successMsg = this.translate.instant("Password changed successfully");
            this.utilitiesService.showSuccessToast(successMsg);
            this.dialogRef.close(true);
          }
        }  else {
          let form = this.form.value;
          delete form.user_id;
          const userPassword = await this.userService.changeAdminPassword(form).toPromise();
          if (userPassword) {
            this.utilitiesService.stopLoader();
            let successMsg = this.translate.instant("Password changed successfully");
            this.utilitiesService.showSuccessToast(successMsg);
            this.dialogRef.close(true);
          }
        }
      }
    } catch {
      this.utilitiesService.stopLoader();
    } finally {
    }
  }
}
