import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { NotificationService } from "app/services/notification/notification.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { NotificationType } from "appElementConstants";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
import { Clipboard } from "@angular/cdk/clipboard";
import { DOCUMENT, Location } from "@angular/common";
import { FullscreenAdditionCssComponent } from "../fullscreen-addition-css/fullscreen-addition-css.component";
import { TranslationService } from "app/services/translationService";
import { GalleryPopupComponent } from "../gallery-popup/gallery-popup.component";
import { MediaService } from "app/services/media.service";

@Component({
  selector: "app-create-notification",
  templateUrl: "./create-notification.component.html",
  styleUrls: ["./create-notification.component.scss"],
  animations: fuseAnimations,
})
export class CreateNotificationComponent implements OnInit {
  @Input() id: any = "";
  @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
  @Output() createNotification: EventEmitter<void> = new EventEmitter<any>();
  notificationType = new NotificationType();
  notificationForm: FormGroup;
  codeEditorFormControl = new FormControl(false);
  details: any;
  ckeConfig: {
    sanitize: boolean;
    allowedContent: boolean;
    removeButtons: string;
    versionCheck: boolean;
  };
  codes = [];
  files = [];
  codeIndex = [];
  constructor(
    private fb: FormBuilder,
    public utilitiesService: UtilitiesService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private clipboard: Clipboard,
    public translationService: TranslationService,
    private mediaService: MediaService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  onPaste(event) {}

  async ngOnInit(): Promise<void> {
    this.formInit();
    await this.getVariables();
    if (this.id) {
      this.utilitiesService.startLoader();
      await this.getDetails();
    }
    this.ckeConfig = {
      sanitize: false,
      allowedContent: true,
      versionCheck: false,
      removeButtons: "CreateDiv,BlockQuote,Superscript,Subscript,PasteFromWord,PasteText,Source",
    };
  }

  showCopyMessage() {
    let copyText = this.translate.instant("Copied to clipboard!");
    this.utilitiesService.showSuccessToast(copyText);
  }
  async getVariables() {
    try {
      const codes = await this.notificationService.getAllNotificationVariables().toPromise();
      if (codes) {
        this.codes = codes;
        this.codes.forEach((data, index) => {
          this.codeIndex[index] = false;
        });
      }
    } catch {
    } finally {
    }
  }
  async getDetails() {
    try {
      const details = await this.notificationService.getNotificationDetail(this.id).toPromise();
      if (details) {
        this.details = details;
        await this.formInit();
        this.utilitiesService.stopLoader();
      }
    } catch {
      this.utilitiesService.stopLoader();
    } finally {
    }
  }
  async formInit() {
    this.notificationForm = this.fb.group({
      title: [this.details ? this.details.title : null],
      title_en: [this.details ? this.details.title_en : null, [Validators.required]],
      title_ar: [this.details ? this.details.title_ar : null],
      body: [this.details ? this.details.body : null],
      body_en: [this.details ? this.details.body_en : null, [Validators.required]],
      body_ar: [this.details ? this.details.body_ar : null],
      template_type: [this.details ? this.details.template_type : "EMAIL"],
      template_format: [this.details ? this.details.template_format : 1],
      lang: ["en"],
      re_usable: [true],
      image_url: [this.details ? this.details.image_url : null],
      trigger_url: [this.details ? this.details.trigger_url : null],
    });
  }

  saveOrUpdate() {
    this.notificationForm.get("body_en").markAllAsTouched();
    if (this.notificationForm.valid) {
      let form = this.notificationForm.value;
      form.title = form.title_en;
      form.body = form.body_en;
      if (!this.id) {
        try {
          let content = `Are you sure, Do you want to save this template ? `;
          let heading = "Save";
          let fromApp = false;
          let size = this.utilitiesService.isMobileAlertModal();
          const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
          });
          dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
              this.notificationService.addTemplate(form).subscribe((data) => {
                let successMsg = this.translate.instant("Template created successfully");
                this.utilitiesService.showSuccessToast(successMsg);
                this.createNotification.emit();
              });
            }
          });
        } catch {
        } finally {
          this.utilitiesService.stopLoader();
        }
      } else if (this.id) {
        try {
          let content = `Are you sure, Do you want to update this template ? `;
          let heading = "Update";
          let fromApp = false;
          let size = this.utilitiesService.isMobileAlertModal();
          const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
          });
          dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
              this.notificationService.updateTemplate(form, this.id).subscribe((data) => {
                let successMsg = this.translate.instant("Template updated successfully");
                this.utilitiesService.showSuccessToast(successMsg);
                this.createNotification.emit();
              });
            }
          });
        } catch {
        } finally {
          this.utilitiesService.stopLoader();
        }
      }
    } else {
      let errorMsg = this.translate.instant("Form is not valid");
      this.utilitiesService.showErrorToast(errorMsg);
    }
  }
  zoomAdditionalCss(formControl, controlName) {
    let heading = "HTML";
    const dialogRef = this.dialog.open(FullscreenAdditionCssComponent, {
      data: { formControl, heading },
      maxWidth: "",
      width: "100%",
      height: "100%",
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        this.notificationForm.get(controlName).setValue(resp.value);
      }
    });
  }

  setFileToForm(file) {
    this.notificationForm.controls.image_url.setValue(file.file);
  }
  deleteImage() {
    this.notificationForm.controls.image_url.setValue("");
  }
  openGalleryForCopy() {
    let isCopy = true;
    let size = {
      height: "70%",
      width: "70%",
    };
    let isMobile = this.utilitiesService.isMobile();
    if (isMobile) {
      size.height = "80%";
      size.width = "100%";
    }
    const dialogRef = this.dialog.open(GalleryPopupComponent, {
      data: { isCopy },
      maxWidth: "",
      width: `${size.width}`,
      height: `${size.height}`,
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        let imgTag = `<img src="${resp}" alt="" >`;
        this.clipboard.copy(imgTag);
        this.showCopyMessage();
      }
    });
  }
}
