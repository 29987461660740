<div id="dashboard-project" class="page-layout simple right-sidebar" fxLayout="column">
  <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" fusePerfectScrollbar>
    <form
      [formGroup]="notificationForm"
      fxFlex="0 0 auto"
      fxLayoutAlign="start stretch"
      fxLayout="column"
      fxLayoutGap="1em"
      fusePerfectScrollbar
      class="p-16">
      <div fxFlex="0 0 auto" fxLayoutAlign="start flex-end" fxLayout="column" fxLayoutGap="1em">
        <button (click)="saveOrUpdate()" type="submit" mat-flat-button class="primary-bg-color">{{ "Save" | translate }}</button>
      </div>
      <div class="fuse-card auto-width p-16" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
          <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
              <mat-form-field fxFlex="100" appearance="outline">
                <mat-label class="text-capitalized">{{ "Event Types" | translate }}</mat-label>
                <mat-select formControlName="event_type">
                  <mat-option [value]="type?.id" *ngFor="let type of notificationType.types">
                    {{ type?.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="notificationForm.get('event_type').hasError('required')">
                  {{ "Event Type is required" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label class="text-capitalized">
                  {{ "Recipient" | translate }}
                </mat-label>
                <input matInput name="title" formControlName="recipient" />
                <mat-error *ngIf="notificationForm.get('recipient').hasError('required')">
                  {{ "Recipient Type is required" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="1 1 auto" fxLayoutGap="10px" fxLayoutAlign="start stretch">
              <mat-form-field fxFlex="100" appearance="outline" fxFlex="85%">
                <mat-label class="text-capitalized">{{ "Template" | translate }}</mat-label>
                <mat-select formControlName="template">
                  <mat-option [value]="type?.id" *ngFor="let type of templates">
                    {{ type?.title }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="notificationForm.get('template').hasError('required')">
                  {{ "Template Type is required" | translate }}
                </mat-error>
              </mat-form-field>

              <!-- Template Limit Select Column -->
              <div fxFlex="12%">
                <mat-form-field appearance="outline">
                  <mat-select formControlName="templateLimit" (selectionChange)="templatelimitChange()">
                    <mat-option [value]="5">5</mat-option>
                    <mat-option [value]="10">10</mat-option>
                    <mat-option [value]="25">25</mat-option>
                    <mat-option [value]="50">50</mat-option>
                    <mat-option [value]="100">100</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
