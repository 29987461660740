import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { CustomerService } from "app/services/customer/customer.service";
import { CampaignsService } from "app/services/marketing/campaigns.service";
import { MediaService } from "app/services/media.service";
import { NotificationService } from "app/services/notification/notification.service";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { GalleryPopupComponent } from "../gallery-popup/gallery-popup.component";

@Component({
  selector: "app-send-notification",
  templateUrl: "./send-notification.component.html",
  styleUrls: ["./send-notification.component.scss"],
})
export class SendNotificationComponent implements OnInit {
  loading: boolean = false;
  isSMS: boolean = false;
  form: FormGroup;
  notificationTemplate = [];
  templateLimit: number = 5;
  itemList = [];
  customerID: any = "";
  codes = [];
  ckeConfig = {
    allowedContent: true,
    versionCheck: false,
    removeButtons: "CreateDiv,BlockQuote,Superscript,Subscript,PasteFromWord,PasteText",
    forcePasteAsPlainText: true,
  };
  isLoading: boolean;
  files = [];
  codeIndex = [];
  constructor(
    public dialogRef: MatDialogRef<SendNotificationComponent>,
    private formBuilder: FormBuilder,
    private translationService: TranslationService,
    private notificationService: NotificationService,
    private customerService: CustomerService,
    private campaignsService: CampaignsService,
    private translate: TranslateService,
    public utilitiesService: UtilitiesService,
    private mediaService: MediaService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.isSMS = data.isSMS ? data.isSMS : false;
      this.itemList = data.itemList ? data.itemList : [];
      this.customerID = data.customerID ? data.customerID : null;
    }
  }

  showCopyMessage() {
    let copyText = this.translate.instant("Copied to clipboard!");
    this.utilitiesService.showSuccessToast(copyText);
  }

  async ngOnInit(): Promise<void> {
    await this.formInitialize();
    await this.getVariables();
  }

  async getVariables() {
    try {
      const codes = await this.notificationService.getAllNotificationVariables().toPromise();
      if (codes) {
        this.codes = codes;
      }
    } catch {
    } finally {
    }
  }

  async getNotificationTemplate(channel = "", templateLimit = 5) {
    try {
      let offset = this?.notificationTemplate?.length || 0;
      this.isLoading = true;
      const notificationTemplate = await this.notificationService.getNotificationList(templateLimit, 0, "", channel, true).toPromise();
      if (notificationTemplate) {
        this.notificationTemplate = [...notificationTemplate.results];
        this.isLoading = false;
      }
    } catch {
      this.isLoading = false;
    } finally {
    }
  }
  templateCheckboxChange() {
    document.getElementById("toolbar").scrollIntoView();
    let channel = this.form.controls.channel.value;
    this.channelChange(channel);
  }
  async formInitialize() {
    this.form = this.formBuilder.group({
      channel: [1],
      message: [null],
      template: [null],
      message_email: [null],
      title: [null],
      use_exist: [false],
      template_format: [2],
      templateLimit: [5],
      image_url: [null],
      trigger_url: [null],
    });
    let channel = 1;
    if (this.isSMS) {
      this.form.controls.channel.setValue(2);
      channel = 2;
    }
    await this.channelChange(channel);
  }
  async channelChange(value) {
    this.notificationTemplate = [];
    let channel = "EMAIL";
    if (value == 2) {
      channel = "SMS";
    }
    if (value == 3) {
      channel = "PUSH";
    }
    if (!this.form.controls.use_exist.value) {
      await this.getNotificationTemplate(channel, this.form.controls.templateLimit.value);
    }
    if (this.form.controls.use_exist.value) {
      if (value == 1) {
        this.form.get("message_email").setValidators([Validators.required]);
        this.form.get("message_email").updateValueAndValidity();
        this.form.get("message").clearValidators();
        this.form.get("message").updateValueAndValidity();
      } else {
        this.form.get("message").setValidators([Validators.required]);
        this.form.get("message").updateValueAndValidity();
        this.form.get("message_email").clearValidators();
        this.form.get("message_email").updateValueAndValidity();
      }
      this.form.get("template").clearValidators();
      this.form.get("template").updateValueAndValidity();
    } else {
      this.form.get("message").clearValidators();
      this.form.get("message").updateValueAndValidity();
      this.form.get("message_email").clearValidators();
      this.form.get("message_email").updateValueAndValidity();
      this.form.get("template").setValidators([Validators.required]);
      this.form.get("template").updateValueAndValidity();
    }
  }

  send() {
    if (this.form.valid) {
      this.saveCampaign(this.form.value);
    }
  }

  async templatelimitChange() {
    let channel = "EMAIL";
    if (this.form.controls.channel.value == 2) {
      channel = "SMS";
    }
    if (this.form.controls.channel.value == 3) {
      channel = "PUSH";
    }
    await this.getNotificationTemplate(channel, this.form.controls.templateLimit.value);
  }

  async saveCampaign(form, id = this.customerID) {
    this.utilitiesService.startLoader();
    let cartID = [];
    if (this.itemList.length) {
      cartID = this.itemList
        .filter((data) => data.customer.id == id)
        .map((resp) => {
          return resp.id;
        });
    }

    let channel: any;
    let title = null;
    if (form.channel == 1) {
      channel = "EMAIL";
      title = form.title ? form.title : "Send Notification via Email";
    } else if (form.channel == 2) {
      channel = "SMS";
      title = form.title ? form.title : "Send Notification via SMS";
    } else if (form.channel == 3) {
      channel = "PUSH";
      title = form.title ? form.title : "Send Notification via Push";
    }
    let templateForm = {
      title: title,
      title_en: title,
      title_ar: title,
      body: channel == "EMAIL" ? form.message_email : form.message,
      body_en: channel == "EMAIL" ? form.message_email : form.message,
      body_ar: channel == "EMAIL" ? form.message_email : form.message,
      template_type: channel,
      template_format: form.template_format,
      trigger_url: form.channel == 3 ? form.trigger_url : null,
      image_url: form.channel == 3 ? form.image_url : null,
    };
    let notificationForm = {
      template_id: form.template,
      customer_id: id,
      channel_type: form.channel,
      cart_id: cartID[0] ? cartID[0] : "",
    };
    if (!this.itemList.length) {
      delete notificationForm.cart_id;
    }

    if (!form.use_exist && form.template) {
      try {
        notificationForm.template_id = form.template;
        this.customerService.sendNotification(notificationForm).subscribe(
          async (data) => {
            this.utilitiesService.stopLoader();
            let successMsg = this.translate.instant("Notification send successfully");
            this.utilitiesService.showSuccessToast(successMsg);
            this.dialogRef.close(true);
          },
          (error) => {
            this.utilitiesService.stopLoader();
          }
        );
      } catch {
        let erroMsg = this.translate.instant("Api general Error");
        this.utilitiesService.showErrorToast(erroMsg);
        this.utilitiesService.stopLoader();
      } finally {
      }
    } else {
      try {
        this.campaignsService.addTemplate(templateForm).subscribe(
          (response) => {
            notificationForm.template_id = response.id;
            this.customerService.sendNotification(notificationForm).subscribe(
              async (data) => {
                this.utilitiesService.stopLoader();
                let successMsg = this.translate.instant("Notification send successfully");
                this.utilitiesService.showSuccessToast(successMsg);
                this.dialogRef.close(true);
              },
              (error) => {
                this.utilitiesService.stopLoader();
              }
            );
          },
          (error) => {
            this.utilitiesService.stopLoader();
          }
        );
      } catch {
        let erroMsg = this.translate.instant("Api general Error");
        this.utilitiesService.showErrorToast(erroMsg);
        this.utilitiesService.stopLoader();
      } finally {
      }
    }
  }

  close() {
    this.dialogRef.close(null);
  }
  setFileToForm(file) {
    this.form.controls.image_url.setValue(file.file);
  }

  deleteImage() {
    this.form.controls.image_url.setValue("");
  }
}
