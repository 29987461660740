<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
    <ngx-datatable [loadingIndicator]="isLoading" [externalPaging]="true" [count]="page.totalElements"
        [limit]="page.size" (page)='pageWishlistChange($event)' [offset]="page.pageNumber" [virtualization]="false"
        [scrollbarH]="true" [rows]="wishlist" [cssClasses]="iconsCss" class="material striped mt-8"
        [columnMode]="'force'" [headerHeight]="'auto'" [rowHeight]="50" [footerHeight]="'auto'">
        <ngx-datatable-column name="{{'Product Name' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.product_name}}</p>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'Quantity' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.quantity}}</p>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'Currency' | translate}}">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.currency}}</p>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'Unit Price' | translate}}" [width]="getWidth()">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <p>{{row?.unit_price}}</p>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>