import { Component, Input, OnInit } from "@angular/core";
import { MediaTypes } from "app/constants/media-type";
import { UtilitiesService } from "app/services/utilitiesService";

@Component({
    selector: "app-show-all-type-media",
    templateUrl: "./show-all-type-media.component.html",
    styleUrls: ["./show-all-type-media.component.scss"],
})
export class ShowAllTypeMediaComponent implements OnInit {
    @Input() url: any;

    mediaTypes = MediaTypes;

    constructor(private utilitiesService: UtilitiesService) {}

    ngOnInit(): void {}

    checkFormat(url) {
        if (url) {
            let type = this.utilitiesService.checkMediaFormat(url);
            return this.utilitiesService.checkFormat(type);
        }
        return null;
    }
}
