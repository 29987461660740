import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { UtilitiesService } from "app/services/utilitiesService";
import { WidgetService } from "app/services/widget.service";
import { ElementTypesComponent } from "../element-types/element-types.component";
import { FullscreenAdditionCssComponent } from "../fullscreen-addition-css/fullscreen-addition-css.component";
import { Subscription } from "rxjs";

@Component({
    selector: "app-create-widget",
    templateUrl: "./create-widget.component.html",
    styleUrls: ["./create-widget.component.scss"],
    animations: fuseAnimations,
})
export class CreateWidgetComponent implements OnInit, AfterViewInit {
    @ViewChild("editor") editor;
    @ViewChild(ElementTypesComponent)
    elementTypeComponent!: ElementTypesComponent;
    @Input() id: any;
    @Input() detailObject: any;
    @Input() elementTypes = [];
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    submit: boolean = false;

    elementName: any = "";
    show_on = [
        {
            id: 1,
            name: "All",
            icon: "assets/icons/show_on/select-all.svg",
            selected: "",
        },
        {
            id: 2,
            name: "Mobile Web",
            icon: "assets/icons/show_on/mobile-web.svg",
            selected: "",
        },
        {
            id: 3,
            name: "Desktop",
            icon: "assets/icons/show_on/desktop.svg",
            selected: "",
        },
        {
            id: 4,
            name: "Mobile App",
            icon: "assets/icons/show_on/mobile-app.svg",
            selected: "",
        },
    ];
    zone = [];
    isLoading: boolean = false;
    widgetForm: FormGroup;
    configurations: any;
    showCloseButtonSetting = false;
    widgetFormSubscribtion: Subscription;

    get isTypeDisabled() {
        return this.id ? true : false;
    }

    constructor(
        private widgetService: WidgetService,
        private translate: TranslateService,
        private fb: FormBuilder,
        public utilitiesService: UtilitiesService,
        private dialog: MatDialog
    ) {
        this.configurations = [];
    }

    async ngOnInit(): Promise<void> {
        if (this.detailObject && this.id) {
            this.utilitiesService.startLoader();
        }
        await this.formInitialize();
        await this.getZones();
        if (this.detailObject && this.id) {
            this.isLoading = true;
            await this.setValues();
        }

        this.widgetFormSubscribtion = this.widgetForm.get('zone_id').valueChanges.subscribe(newValue => {
            this.showCloseButtonSetting = (newValue === 57) && true;
        });
    }

    addOrRemoveShowOnItems(index) {
        if (this.show_on[index].selected) {
            this.show_on[index].selected = "";
        } else {
            this.show_on[index].selected = "selected";
        }
        this.show_on.forEach((data) => {
            if (data.selected) {
                this.submit = false;
            }
        });
    }

    async formInitialize() {
        this.widgetForm = this.fb.group({
            id: [null],
            element_type_id: [null],
            zone_id: [null, [Validators.required]],
            name: ["", [Validators.required]],
            show_on: [null, [Validators.required]],
            value: [null],
            value_ar: [null],
            order: [0, [Validators.required]],
            enabled: [false],
            show_close_button: [false],
            css: [""],
            language: ["en"],
            configurations: this.fb.array([]),
            redirection_path:[""]
        });
        this.widgetForm.controls.element_type_id.valueChanges.subscribe(
            async (id) => {
                if (id) {
                    this.widgetForm.controls.language.setValue("en");
                    const configuration = this.elementTypes.find(
                        (config) => config.id == id
                    );
                    this.elementName = configuration.name;
                    if (configuration) {
                        this.configurations = [];
                        this.configurations = configuration.configurations;
                        (<FormArray>(
                            this.widgetForm.get("configurations")
                        )).clear();
                        (
                            this.widgetForm.get("configurations") as FormArray
                        ).push(this.createFormGroup(configuration));
                    }
                }
            }
        );
        if (!this.id) {
            this.widgetForm.controls.element_type_id.setValue(
                this.elementTypes[0].id
            );
        }
    }

    async setValues() {
        try {
            if (this.detailObject.show_on) {
                this.detailObject.show_on.forEach((data) => {
                    this.show_on.forEach((items, index) => {
                        if (items.id == data) {
                            this.show_on[index].selected = "selected";
                        }
                    });
                });
            }

            this.widgetForm.controls.id.setValue(this.detailObject.id);
            this.widgetForm.controls.element_type_id.setValue(
                this.detailObject.element_type.id
            );
            this.widgetForm.controls.zone_id.setValue(
                this.detailObject.zone.id
            );
            this.widgetForm.controls.name.setValue(this.detailObject.name);
            this.widgetForm.controls.show_on.setValue(
                this.detailObject.show_on
            );
            this.widgetForm.controls.redirection_path.setValue(
                this.detailObject.redirection_path
            );
            this.widgetForm.controls.order.setValue(this.detailObject.order);
            this.widgetForm.controls.enabled.setValue(
                this.detailObject.enabled
            );

            this.widgetForm.controls.show_close_button.setValue(
                this.detailObject.show_close_button
            );
            this.widgetForm.controls.value.setValue(this.detailObject.value);
            this.widgetForm.controls.css.setValue(this.detailObject.css);

            if (this.detailObject.zone.id === 57) this.showCloseButtonSetting = true;

        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }

    createFormGroup(config) {
        let transaltedConfig = [];
        let formGroupObject = {
            order: 0,
            language: "en",
            id: null,
            show_on: [1],
            isCodeEditor: [false],
            label: null,
            label_ar: null,
            parentName: config.name,
            parentID: config.id,
        };
        if (!this.id) {
            config.configurations.forEach((element) => {
                formGroupObject = {
                    ...formGroupObject,
                    [element.name]: [
                        null,
                        Validators.compose(
                            element.required ? [Validators.required] : []
                        ),
                    ],
                };
            });
        } else {
            config.configurations.forEach((configuration) => {
                let stripedKey = configuration.name.replace("_ar", "");
                if (configuration.translate) {
                    transaltedConfig.push(`${configuration.name}_ar`);
                    formGroupObject = {
                        ...formGroupObject,
                        [configuration.name]: [
                            this.detailObject.value[configuration.name]
                                ? this.detailObject.value[configuration.name]
                                : null,
                            Validators.compose(
                                configuration.required
                                    ? [Validators.required]
                                    : []
                            ),
                        ],
                    };
                } else if (
                    transaltedConfig.find((res) => res == configuration.name)
                ) {
                    formGroupObject = {
                        ...formGroupObject,
                        [configuration.name]: [
                            this.detailObject.value_ar[stripedKey]
                                ? this.detailObject.value_ar[stripedKey]
                                : null,
                            Validators.compose(
                                configuration.required
                                    ? [Validators.required]
                                    : []
                            ),
                        ],
                    };
                } else {
                    formGroupObject = {
                        ...formGroupObject,
                        [configuration.name]: [
                            this.detailObject.value[configuration.name]
                                ? this.detailObject.value[configuration.name]
                                : null,
                            Validators.compose(
                                configuration.required
                                    ? [Validators.required]
                                    : []
                            ),
                        ],
                    };
                }
            });
        }
        return this.fb.group(formGroupObject);
    }

    async getZones() {
        this.isLoading = true;
        try {
            const zone = await this.widgetService.getZones().toPromise();
            if (zone) {
                this.zone = zone;
            }
        } catch {
        } finally {
            this.isLoading = false;
        }
    }
    handleCancel() {
        this.cancelClick.emit();
    }
    save() {
        let show_on = [];
        this.show_on.forEach((data) => {
            if (data.selected) {
                show_on.push(data.id);
            }
        });
        this.widgetForm.controls.show_on.setValue(
            show_on.length > 0 ? show_on : null
        );
        this.submit = true;
        this.widgetForm.controls.zone_id.markAllAsTouched();
        if (
            // temp fix needs to add lanugage and validation
            this.widgetForm.controls.zone_id.valid &&
            this.widgetForm.controls.name.valid &&
            this.widgetForm.controls.show_on.valid &&
            this.widgetForm.controls.order.valid
        ) {
            try {
                let formValues =
                    this.elementTypeComponent.configuration.value[0];
                delete formValues.isCodeEditor;
                let value = {};
                let value_ar = {};
                let config = this.elementTypes.filter(
                    (data) => data.id == formValues.parentID
                )[0];
                let transaltedConfig = [];
                if (config) {
                    config.configurations.forEach((data) => {
                        if (data.translate) {
                            transaltedConfig.push(data.name);
                        }
                    });
                }
                for (let key in formValues) {
                    let stripedKey = key.replace("_ar", "");
                    if (transaltedConfig.find((res) => `${res}` == key)) {
                        value[key] = formValues[key];
                    } else if (
                        transaltedConfig.find((res) => res == stripedKey)
                    ) {
                        value_ar[stripedKey] = formValues[key];
                    } else {
                        value[key] = formValues[key];
                        value_ar[key] = formValues[key];
                    }
                }
                delete this.elementTypeComponent.configuration.value[0]
                    .language;
                delete this.elementTypeComponent.configuration.value[0]
                    .isCodeEditor;
                let form = {
                    id: this.widgetForm.controls.id.value,
                    zone_id: this.widgetForm.controls.zone_id.value,
                    element_type_id:
                        this.widgetForm.controls.element_type_id.value,
                    name: this.widgetForm.controls.name.value,
                    enabled: this.widgetForm.controls.enabled.value,
                    show_close_button: this.widgetForm.controls.show_close_button.value,
                    value: value,
                    value_ar: value_ar,
                    order: this.widgetForm.controls.order.value,
                    css: this.widgetForm.controls.css.value,
                    show_on: this.widgetForm.controls.show_on.value,
                    redirection_path:this.widgetForm.controls.redirection_path.value
                };
                if (!this.id) {
                    this.widgetService.addWidget(form).subscribe((data) => {
                        let successMsg = this.translate.instant(
                            "Widget created successfully"
                        );
                        this.utilitiesService.stopLoader();
                        this.utilitiesService.showSuccessToast(successMsg);
                        this.cancelClick.emit();
                    });
                } else if (this.id) {
                    this.widgetService.updateWidget(form).subscribe((data) => {
                        let successMsg = this.translate.instant(
                            "Widget updated successfully"
                        );
                        this.utilitiesService.stopLoader();
                        this.utilitiesService.showSuccessToast(successMsg);
                        this.cancelClick.emit();
                    });
                }
            } catch {
            } finally {
                this.utilitiesService.stopLoader();
            }
        }
    }
    async ngAfterViewInit(): Promise<void> {
        this.editor.getEditor().setOptions({
            showLineNumbers: true,
            tabSize: 2,
        });
        this.editor.mode = "css";
    }
    zoomAdditionalCss(formControl) {
        let heading = "CSS";
        const dialogRef = this.dialog.open(FullscreenAdditionCssComponent, {
            data: { formControl, heading },
            maxWidth: "",
            width: "100%",
            height: "100%",
        });
        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                this.widgetForm.controls.css.setValue(resp.value);
            }
        });
    }

    ngOnDestroy() {
        this.widgetFormSubscribtion.unsubscribe();
    }
}
