<div class="create-product auto-width center-container margin-auto" fxLayoutAlign="start stretch"
    fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
    <form *ngIf="isApiCallDone" fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
        fxLayout.gt-sm="column" fxLayoutGap="1em" [formGroup]="form">

        <mat-tab-group>
            <mat-tab label="Info">
                <div fxFlex="1 1 auto" fxLayoutAlign.gt-sm="end center" fxLayoutAlign.lt-md="start stretch"
                    fxLayout.lt-md="column-reverse" fxLayout.gt-sm="row" fxLayoutGap="5px" class="my">
                    <button mat-stroked-button (click)="handleCancel()">
                        <mat-icon>clear</mat-icon>
                        {{'Cancel' | translate}}
                    </button>
                    <button (click)="saveOrUpdate()" type="submit" mat-flat-button class="primary-bg-color">
                        <mat-icon>save</mat-icon>
                        {{'Save' | translate}}
                    </button>
                </div>

                <div fxFlex="0 0 auto" fxLayoutAlign="center">
                    <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 " fxLayoutAlign="start stretch"
                        fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                        <div fxLayout="column" fxFlex="70" class="h-fulll">
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="16px">
                                <app-form-fields [formData]="productFields" [customFormGroup]="form"
                                    [id]="productId"></app-form-fields>
                            </div>
                        </div>
                        <div fxFlex="30">
                            <div class="fuse-card p-16" style="min-width: 100%;margin-bottom: 10px;">
                                <span>Image</span>
                                <app-cms-upload-media [formcontrol]="form.controls.image"
                                    (afterUpload)="setFileToForm($event,'image')" saveLocally="true" showOnly="image"
                                    [isMultiSelect]="true" [index]="1" uploadOnly="true">
                                </app-cms-upload-media>
                                <div *ngIf="form.get('image').value" fxLayout="row"
                                    fxLayoutAlign="space-between stretch" class="category-images my-10">
                                    <img style="width:100px" [src]="form.get('image').value" alt="">
                                    <button mat-icon-button aria-label="Delete">
                                        <mat-icon (click)="deleteFile(form.get('image'))"
                                            class="action-btn-delete">delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="fuse-card p-16" style="min-width: 100%;margin-bottom: 10px;">
                                <span>Banner</span>
                                <app-cms-upload-media [formcontrol]="form.controls.banner"
                                    (afterUpload)="setFileToForm($event,'banner')" saveLocally="true" showOnly="image"
                                    [isMultiSelect]="true" [index]="2" uploadOnly="true">
                                </app-cms-upload-media>
                                <div *ngIf="form.get('banner').value" fxLayout="row"
                                    fxLayoutAlign="space-between stretch" class="category-images my-10">
                                    <img style="width:100px" [src]="form.get('banner').value" alt="">
                                    <button mat-icon-button aria-label="Delete">
                                        <mat-icon (click)="deleteFile(form.get('banner'))"
                                            class="action-btn-delete">delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Content">
                <ng-container *ngTemplateOutlet="createProductTemplate"></ng-container>
            </mat-tab>
        </mat-tab-group>
    </form>
</div>

<ng-template #createProductTemplate>
    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <app-create-page [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}" (cancelClick)="handleCancel()"
            [elements]="pageElements?.results" [brandId]="id" (createPageEvent)="afterCreateProductPage()"
            [brandRowData]="rowData">
        </app-create-page>
    </div>
</ng-template>