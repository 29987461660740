<button
    *ngIf="label == ''"
    class="button-theme-color"
    (click)="fabButtonClick()"
    mat-mini-fab
    [style.margin-bottom]="bottomMargin ? '16px' : '0px'"
    [ngClass]="{ 'mb-16 bgColorClass': true }"
    title="{{ tooltip }}"
>
    <mat-icon>{{ icon ? icon : "add" }}</mat-icon>
</button>
<button
    *ngIf="label != ''"
    class="button-theme-color1"
    (click)="fabButtonClick()"
    mat-stroked-button
    [style.margin-bottom]="bottomMargin ? '16px' : '0px'"
    [ngClass]="{ 'mb-16 bgColorClass': true }"
    title="{{ tooltip }}"
>
    <mat-icon>{{ clear ? "cached" : "add" }}</mat-icon> {{ label }}
</button>
