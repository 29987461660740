import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { WalletService } from "app/services/wallet/wallet.service";
import { TopupWalletComponent } from "../topup-wallet/topup-wallet.component";
import { OrderStatus } from "app/main/types";
import { OrderService } from "app/services/orders/order.service";
import { DeliveryService } from "app/services/delivery.service";
import { fuseAnimations } from "@fuse/animations";
@Component({
    selector: "app-change-delivery-agent-status",
    templateUrl: "./change-delivery-agent-status.component.html",
    styleUrls: ["./change-delivery-agent-status.component.scss"],
    animations: fuseAnimations,
})
export class ChangeDeliveryAgentStatusComponent implements OnInit {
    loading: boolean = false;
    offset: number = 0;
    last_status: any;
    searchKey: any;
    orderStatus = [
        { id: 1, value: "Active" },
        { id: 2, value: "Inactive" },
    ];
    page = {
        size: 10,
        totalElements: 0,
        pageNumber: 0,
    };
    // order_status: any;
    form: FormGroup;
    orderId: any = "";
    agentDetails: any;
    agentId: any;
    agentName: string;

    constructor(
        private orderService: OrderService,
        public dialogRef: MatDialogRef<ChangeDeliveryAgentStatusComponent>,
        private formBuilder: FormBuilder,
        private utilitiesService: UtilitiesService,
        private walletService: WalletService,
        private translationService: TranslationService,
        private deliveryService: DeliveryService,
        public cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data) {
            this.agentDetails = data?.agentDetails;
            this.agentId = data?.agentDetails?.id;
            this.agentName =
                data?.agentDetails?.first_name +
                data?.agentDetails?.middle_name +
                " " +
                data?.agentDetails?.last_name;
        }
    }

    async ngOnInit(): Promise<void> {
        await this.formInitialize();
    }

    async formInitialize() {
        this.form = this.formBuilder.group({
            id: [this.orderId],
            status: [this.agentDetails.status, Validators.required],
        });
    }
    async updateAgentStatus() {
        const form = this.form.value;
        try {
            this.utilitiesService.startLoader();

            await this.deliveryService
                .updateDeliveryAgent(this.agentId, form)
                .subscribe((res) => {
                    if (res) {
                        this.utilitiesService.stopLoader();
                        this.cdr.markForCheck();
                        this.dialogRef.close(true);
                    }
                    // this.isLoading = false;
                    this.utilitiesService.stopLoader();
                });
        } catch {
        } finally {
        }
    }

    close() {
        this.dialogRef.close(null);
    }
}
