<div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
    <form class="fuse-card auto-width p-16" fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout="column"
        fxLayoutGap="1em" [formGroup]="form">
        <strong class="highlight-text-color title-size">{{'Audience' | translate}}</strong>
        <div fxFlex="0 0  auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column">
                <div fxFlex="1 1 0px" fxLayoutAlign="start flex-start" fxLayout="row">
                    <div fxLayout="row" fxFlex.lt-md="1 1 auto" fxFlex.gt-sm="0 0 50%">
                        <mat-form-field [ngClass]="isHideSaveCancel ? 'disabled-input' : ''" appearance="outline"
                            fxFlex="100%">
                            <mat-label class="text-capitalized">
                                {{'Audience Name'| translate}}
                            </mat-label>
                            <input matInput formControlName="name">
                            <mat-error *ngIf="form.get('name').hasError('required')">
                                {{'Audience Name is required' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <button class="edit-button" *ngIf="isHideSaveCancel" (click)="edit()" mat-flat-button>
                        <mat-icon class="highlight-text-color" matPrefix>edit
                        </mat-icon>
                    </button>
                </div>
            </div>
            <div *ngIf="!isHideSaveCancel" fxFlex="1 1 auto" fxLayoutAlign="flex-end stretch" fxLayout="row"
                fxLayoutGap="1em">
                <button (click)="cancel()" mat-stroked-button color="warn">
                    <mat-icon class="save-cancel-btn" matPrefix>close</mat-icon> {{'Cancel' | translate}}
                </button>
                <button (click)="saveOrUpdate()" [ngClass]="{'disabled-btn' : !form.valid}" [disabled]="!form.valid"
                    type="submit" mat-flat-button class="primary-bg-color">
                    <mat-icon class="save-cancel-btn mr-4" matPrefix>save</mat-icon>
                    {{'Save' | translate}}
                </button>
            </div>
        </div>
    </form>
    <div *ngIf="isAudienceCreated" fxFlex="1 1 100%" class="fuse-card auto-width p-16" fxLayoutAlign="start stretch"
        fxLayout="column" fxLayoutGap="1em">
        <div fxFlex="1 1 100%" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px">
            <mat-icon class="cursor-pointer" (click)="closeCreateCondition()"
                *ngIf="checkIsConditionCreating() || checkIsConditionEditing()">
                arrow_back</mat-icon>
            <strong class="highlight-text-color title-size"
                *ngIf="!checkIsConditionCreating() && !checkIsConditionEditing()">
                {{'List of Conditions' | translate}}</strong>
            <strong class="highlight-text-color title-size"
                *ngIf="checkIsConditionCreating() && !checkIsConditionEditing()">
                {{'Create Condition' | translate}}</strong>
            <strong class="highlight-text-color title-size"
                *ngIf="!checkIsConditionCreating() && checkIsConditionEditing()">
                {{'Update Condition' | translate}}</strong>
        </div>
        <app-conditions-list *ngIf="id" [audienceID]="id" [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}">
        </app-conditions-list>
    </div>

</div>