import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IconCss } from "app/main/ngxDatatable-Icons/icons";
import { CustomerService } from "app/services/customer/customer.service";

@Component({
    selector: "app-customer-address-table",
    templateUrl: "./customer-address-table.component.html",
    styleUrls: ["./customer-address-table.component.scss"],
})
export class CustomerAddressTableComponent implements OnInit {
    @Input() customerID: any = "";
    @Output() addressCountHandler: EventEmitter<void> = new EventEmitter<any>();
    icons = IconCss;
    iconsCss = {
        pagerLeftArrow: this.icons.left,
        pagerRightArrow: this.icons.right,
        pagerPrevious: this.icons.previous,
        pagerNext: this.icons.next,
    };
    page = {
        size: 10,
        totalElements: 0,
        pageNumber: 0,
    };
    customerAddresses = [];
    offset = 0;
    isLoading: boolean;
    constructor(private customerService: CustomerService) {}

    async ngOnInit(): Promise<void> {
        await this.getcustomerAddresses();
    }
  getWidth() {
    this.customerAddresses = [...this.customerAddresses]
    return 70
  }

    async getcustomerAddresses() {
        try {
            this.isLoading = true;
            const customerAddresses = await this.customerService
                .getCustomerAddresses(this.customerID)
                .toPromise();
            if (customerAddresses) {
                this.customerAddresses = customerAddresses.addresses;
                this.page.totalElements = this.customerAddresses.length;
                let count: any = this.customerAddresses.length;
                this.addressCountHandler.emit(count);
                this.isLoading = false;
            }
        } catch {
            this.isLoading = false;
        } finally {
        }
    }
}
