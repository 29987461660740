<div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch">
    <div id="dashboard-project" class="page-layout simple right-sidebar" fxLayout="column"
         [@animate]="{value:'*',params:{duration:'400ms',y:'70px'}}">
        <div class="center" fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
             fusePerfectScrollbar>
            <div class="content p-16" fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column"
                 fxLayoutGap="1em">
                <div *ngIf="!isView && !isViewCustomer" fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                     fxLayout="column" fxLayoutGap="0px">
                    <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="end end" fxLayoutGap="1em">

                        <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="start start" fxLayoutGap="1em"
                             [form]="form">
                            <app-clear-filter (clearFilterHandler)="clearDate()" [form]="form"
                                              (clearFilterHandler)="clearFilter()" class="mt-4"
                                              [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}">
                            </app-clear-filter>

                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="page.size" (selectionChange)="limitChange()">
                                    <mat-option [value]="5">5</mat-option>
                                    <mat-option [value]="10">10</mat-option>
                                    <mat-option [value]="25">25</mat-option>
                                    <mat-option [value]="50">50</mat-option>
                                    <mat-option [value]="100">100</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <app-common-filter (searchInput)="search($event)" fxHide.lt-md></app-common-filter>
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="1 1 auto" fxLayoutAlign="start start" fxLayoutGap="1em">
                        <app-common-filter (searchInput)="search($event)" fxHide fxShow.lt-md></app-common-filter>
                    </div>

                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                         [formGroup]="form">
                        <ngx-datatable (activate)="onActivateViewOrder($event)" [loadingIndicator]="isLoading"
                                       [externalPaging]="true" [count]="page.totalElements" [limit]="page.size"
                                       (page)='pageChange($event)' [offset]="page.pageNumber" [virtualization]="false"
                                       [scrollbarH]="true" [rows]="reviewList" [cssClasses]="iconsCss" class="material striped mt-8"
                                       [columnMode]="'force'" [headerHeight]="90" [rowHeight]="50" [footerHeight]="'auto'">
                            <ngx-datatable-column name="{{'No' | translate}}" [width]="70">
                                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                             ngx-datatable-cell-template>
                                    {{(page.size * page.pageNumber) + rowIndex + 1}}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column [width]="100" name="{{'Customer Name' | translate}}">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    <div fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer;">{{column.name}}
                                        </p>
                                        <input formControlName="name" type="text" class="form-control">
                                    </div>
                                </ng-template>
                                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                             ngx-datatable-cell-template>
                                    <strong class="pt-8" style="display: flex;align-items: center" mat-stroked-button
                                            mat-button [matMenuTriggerFor]="afterMenu">
                                        {{row?.customer?.first_name}} {{row?.customer?.middle_name}}
                                        {{row?.customer?.last_name}}
                                        <mat-icon
                                            *ngIf="showAction == row?.id && (row?.customer?.first_name || row?.customer?.last_name) "
                                            matPostfix>arrow_drop_down</mat-icon>
                                    </strong>
                                    <mat-menu #afterMenu="matMenu" xPosition="after">
                                        <app-customer-details-popover [row]="row"
                                                                      (viewCustomerEvent)="viewCustomer($event)">
                                        </app-customer-details-popover>
                                    </mat-menu>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column [width]="100" name="{{'Product Name' | translate}}">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    <div fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer;">{{column.name}}
                                        </p>
                                    </div>
                                </ng-template>
                                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                             ngx-datatable-cell-template>

                                    <p>{{row?.product_name}}</p>

                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column [width]="100" name="{{'Rating' | translate}}">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    <div fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer;">{{column.name}}
                                        </p>
                                    </div>
                                </ng-template>
                                <div style="display:flex">
                                    <ng-template  let-row="row" let-rowIndex="rowIndex" let-value="value"
                                                  ngx-datatable-cell-template >
                                        <!--                                    <p>{{row?.rating}}</p>-->
                                        <div *ngFor="let rate of [].constructor(row?.rating)">
                                            <mat-icon matPostfix>star
                                            </mat-icon>
                                        </div>
                                    </ng-template>
                                </div>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'Review' | translate}}">
                                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                             ngx-datatable-cell-template>
                                    {{row?.comment}}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column [width]="120" name="{{'Review Date' | translate}}">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    <div fxFlex="1 1 auto" fxLayout="column">
                                        <p  style="cursor: pointer;">{{column.name}}
                                    </div>
                                </ng-template>
                                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                             ngx-datatable-cell-template>
                                    <p>{{row?.updated | date: 'dd/MM/yyyy hh:mm a'}}</p>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column [width]="140" name="{{'Actions' | translate}}">
                                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                             ngx-datatable-cell-template>
                                    <ng-container *ngIf="reviewStatus!== 2">
                                        <button
                                            class="action-btn-view" mat-menu-item
                                            (click)="approve(row?.id)">
                                            <mat-icon class="action-btn-view" title="Approve" matPrefix>check_circle
                                            </mat-icon>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="reviewStatus!== 3">
                                        <button
                                    class="action-send" mat-menu-item
                                    (click)="reject(row?.id)">
                                    <mat-icon class="action-btn-delete" title="Reject" matPrefix>
                                        cancel
                                    </mat-icon>
                                </button>

                                    </ng-container>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>