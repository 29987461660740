<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" [formGroup]="form">
            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back</mat-icon>
                    <label class="highlight-text-color">{{'Email Configuration' | translate}}</label>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <button (click)="save()" type="submit" mat-flat-button style="position: absolute;right: 16px;"
                        class="primary-bg-color">{{'Save' | translate}}</button>
                </div>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                [@animate]="{value:'*',params:{duration:'400ms',y:'70px'}}">
                <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 center-container" fxLayoutAlign="start stretch"
                    fxLayout="column" fxLayoutGap="1em">
                    <label class="highlight-text-color">{{'Outgoing Email Settings' | translate}}</label>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                            fxLayout.lt-md="column" fxLayoutGap="1em">
                            <fieldset>
                                <div class="toggle">
                                    <input type="radio" formControlName="is_default" [value]="true" id="default" />
                                    <label for="default">{{'Default Configuration' | translate}}</label>
                                    <input type="radio" formControlName="is_default" [value]="false" id="custom" />
                                    <label for="custom">{{'Custom Configuration' | translate}}</label>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row" fxLayout.lt-md="column"
                        fxLayoutGap="1em">
                        <div class="min-height" fxFlex.lt-md="0 0 auto" fxFlex.gt-sm="0 0 50%"
                            fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                <div fxFlex="1 1 auto">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Sender Email'| translate}}
                                        </mat-label>
                                        <input matTooltip="{{'help_text_Sender_email' | translate}}"
                                            formControlName="sender_email" matInput name="title">
                                        <mat-error *ngIf="form.get('sender_email').hasError('required')">
                                            {{'Sender Email is required' | translate}}
                                        </mat-error>
                                        <mat-error *ngIf="form.get('sender_email').hasError('email')">
                                            {{'Email is not valid' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="1 1 auto">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Sender Name'| translate}}
                                        </mat-label>
                                        <input matTooltip="{{'help_text_Sender_name' | translate}}"
                                            formControlName="sender_name" matInput name="title">
                                        <mat-error *ngIf="form.get('sender_name').hasError('required')">
                                            {{'Sender Name is required' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div *ngIf="!form.get('is_default').value" fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                fxLayout="column" fxLayoutGap="0px">
                                <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                                    fxLayout.lt-md="column" fxLayoutGap="1em">
                                    <mat-form-field appearance="outline" fxFlex="100%">
                                        <mat-label>{{'Type' | translate}}</mat-label>
                                        <mat-select (selectionChange)="typeChanged()" formControlName="email_type">
                                            <!-- <mat-option [value]="1">{{'Default' | translate}}</mat-option> -->
                                            <mat-option [value]="2">{{'SMTP' | translate}}</mat-option>
                                            <mat-option [value]="3">{{'Sendgrid' | translate}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="form.get('email_type').hasError('required')">
                                            {{'Type is required' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="form.get('email_type').value==2" fxFlex="1 1 auto"
                                    fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{'Server'| translate}}
                                            </mat-label>
                                            <input matTooltip="{{'help_text_Server_email_config' | translate}}"
                                                formControlName="smtp_host" matInput name="title">
                                            <mat-error *ngIf="form.get('smtp_host').hasError('required')">
                                                {{'Server is required' | translate}}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                                        fxLayout.lt-md="column" fxLayoutGap="1em">
                                        <div fxFlex="1 1 auto">
                                            <mat-form-field appearance="outline" fxFlex="100">
                                                <mat-label class="text-capitalized">
                                                    {{'Username'| translate}}
                                                </mat-label>
                                                <input matTooltip="{{'help_text_Username_email_config' | translate}}"
                                                    formControlName="smtp_username" matInput name="title">
                                                <mat-error *ngIf="form.get('smtp_username').hasError('required')">
                                                    {{'Username is required' | translate}}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex="1 1 auto">
                                            <mat-form-field appearance="outline" fxFlex="100">
                                                <mat-label class="text-capitalized">
                                                    {{'Password'| translate}}
                                                </mat-label>
                                                <input matTooltip="{{'help_text_Password_email_config' | translate}}"
                                                    formControlName="smtp_password" matInput name="title">
                                                <mat-error *ngIf="form.get('smtp_password').hasError('required')">
                                                    {{'Password is required' | translate}}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                                        fxLayout.lt-md="column" fxLayoutGap="1em">
                                        <div fxFlex="1 1 auto">
                                            <mat-form-field appearance="outline" fxFlex="100">
                                                <mat-label class="text-capitalized">
                                                    {{'Port'| translate}}
                                                </mat-label>
                                                <input matTooltip="{{'help_text_Port_email_config' | translate}}"
                                                    formControlName="smtp_port" matInput name="title">
                                                <mat-error *ngIf="form.get('smtp_port').hasError('required')">
                                                    {{'Port is required' | translate}}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="form.get('email_type').value==3" fxFlex="1 1 auto"
                                    fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{'API Key'| translate}}
                                            </mat-label>
                                            <input matTooltip="{{'help_text_Api_key_email_config' | translate}}"
                                                formControlName="sendgrid_api_key" matInput name="title">
                                            <mat-error *ngIf="form.get('sendgrid_api_key').hasError('required')">
                                                {{'Api Key is required' | translate}}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <app-config-notes [notes]="notes"></app-config-notes>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>