import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";
import { NgxMatColorPickerInput, Color } from "@angular-material-components/color-picker";
import { AppService } from "app/services/app.service";
import { TranslateService } from "@ngx-translate/core";
import { FullscreenAdditionCssComponent } from "../../fullscreen-addition-css/fullscreen-addition-css.component";
import { GalleryPopupComponent } from "../../gallery-popup/gallery-popup.component";
import { first } from "rxjs/operators";
import { fuseAnimations } from "@fuse/animations";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ImageSizeWarningComponent } from "../../image-size-warning/image-size-warning.component";
import { DomSanitizer } from "@angular/platform-browser";
import { WidgetService } from "app/services/widget.service";
import { NavigationExtras, Router } from "@angular/router";
import { AppRoutes } from "app/routes";
import { CoreService } from "app/services/core-service";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Clipboard } from "@angular/cdk/clipboard";
export const customAnimation = [
  trigger("simpleFadeAnimation", [
    state("in", style({ opacity: 1, transform: "rotate(0)" })),
    transition(":enter", [style({ opacity: 0, transform: "rotate(-360deg)" }), animate(200)]),
    transition(":leave", animate(400, style({ opacity: 0, transform: "rotate(360deg)" }))),
  ]),
];
// var isColor = require("is-color");
@Component({
  selector: "app-design",
  templateUrl: "./design.component.html",
  styleUrls: ["./design.component.scss"],
  animations: [fuseAnimations, customAnimation],
})
export class DesignComponent implements OnInit, AfterViewInit {
  @ViewChild("editor") editor;
  @ViewChild("fileDropRef1", { static: false }) fileDropEl1: ElementRef;
  @ViewChild("fileDropRef2", { static: false }) fileDropEl2: ElementRef;
  @ViewChild("fileDropRef3", { static: false }) fileDropEl3: ElementRef;
  @Input() selectedBlock: any;
  @Input() appDetail: any;
  @Output() back: EventEmitter<void> = new EventEmitter<any>();
  @Output() selectedElement: EventEmitter<void> = new EventEmitter<any>();
  @Output() saveHander: EventEmitter<void> = new EventEmitter<any>();
  loading: boolean = true;
  designForm: FormGroup;
  public touchUi = false;
  templateDesign = [
    { name: "Segregated", id: 1, img: "assets/images/layouts/segregated.jpg" }, //default 2
    { name: "Combined", id: 2, img: "assets/images/layouts/combined.jpg" },
  ];
  productLayout = [
    { name: "grid", id: 1 }, //default 1
    { name: "list", id: 2 },
  ];
  categoryLayout = [
    { name: "grid", id: 1 }, //default 1
    { name: "list", id: 2 },
  ];
  brandLayout = [
    { name: "grid", id: 1 }, //default 1
    { name: "list", id: 2 },
  ];
  tickInterval = 1;
  columns = [
    { name: 1 },
    { name: 2 },
    { name: 3 },
    { name: 4 },
    { name: 5 },
    { name: 6 },
    { name: 7 },
    { name: 8 },
    { name: 9 },
    { name: 10 },
    { name: 11 },
    { name: 12 },
  ];

  files = [];
  isViewInit: boolean = false;
  text_color: any;
  secondary_color: any;
  primary_color: any;
  splash_screen_bg: any;
  ckeConfig: {
    versionCheck: boolean;
    sanitize: boolean;
    allowedContent: boolean;
    removeButtons: string;
  };

  footerWidgets: any;
  footerWidgetForm: any;
  qrLink: string;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private appService: AppService,
    public utilitiesService: UtilitiesService,
    private translate: TranslateService,
    public sanitizer: DomSanitizer,
    private widgetService: WidgetService,
    private router: Router,
    public coreService: CoreService,
    private clipboard: Clipboard,
    private changeRef: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    await this.formInitialize();
    this.ckeConfig = {
      sanitize: false,
      allowedContent: true,
      versionCheck: false,
      removeButtons: "CreateDiv,BlockQuote,Superscript,Subscript,PasteFromWord,PasteText,Source",
    };

    this.getFooterWidget();
    this.qrLink = this.appDetail.paydo_network_qr;

    this.designForm.controls.css.valueChanges.subscribe((res) => {
      this.changeRef.detectChanges();
    });
  }

  async formInitialize() {
    this.designForm = this.fb.group({
      language: [this.appDetail.language ? this.appDetail.language : "en"],
      template_design: [this.appDetail.template_design ? this.appDetail.template_design : 2],
      category_layout: [this.appDetail.category_layout ? this.appDetail.category_layout : 1],
      product_layout: [this.appDetail.product_layout ? this.appDetail.product_layout : 1],
      brand_layout: [this.appDetail.brand_layout ? this.appDetail.brand_layout : 1],
      logo: [this.appDetail.logo ? this.appDetail.logo : null],
      logo_url: [this.appDetail.logo_url ? this.appDetail.logo_url : null],
      favicon: [this.appDetail.favicon ? this.appDetail.favicon : null],
      // banner: [this.appDetail.banner ? this.appDetail.banner : null],
      // banner_en: [
      //     this.appDetail.banner_en ? this.appDetail.banner_en : null,
      // ],
      // banner_ar: [
      //     this.appDetail.banner_ar ? this.appDetail.banner_ar : null,
      // ],
      splash_screen_logo: [this.appDetail.splash_screen_logo ? this.appDetail.splash_screen_logo : null],
      splash_screen_logo_en: [this.appDetail.splash_screen_logo_en ? this.appDetail.splash_screen_logo_en : null],
      splash_screen_logo_ar: [this.appDetail.splash_screen_logo_ar ? this.appDetail.splash_screen_logo_ar : null],
      splash_screen_bg: [this.appDetail.splash_screen_bg ? this.appDetail.splash_screen_bg : null],
      primary_color: [this.appDetail.primary_color ? this.appDetail.primary_color : null],
      secondary_color: [this.appDetail.secondary_color ? this.appDetail.secondary_color : null],
      text_color: [this.appDetail.text_color ? this.appDetail.text_color : null],
      category_cols_md: [this.appDetail.category_cols_md ? this.appDetail.category_cols_md : 4],
      category_cols_xs: [this.appDetail.category_cols_xs ? this.appDetail.category_cols_xs : 2],
      category_cols_sm: [this.appDetail.category_cols_sm ? this.appDetail.category_cols_sm : 2],
      brands_cols_md: [this.appDetail.brands_cols_md ? this.appDetail.brands_cols_md : 4],
      brands_cols_xs: [this.appDetail.brands_cols_xs ? this.appDetail.brands_cols_xs : 2],
      brands_cols_sm: [this.appDetail.brands_cols_sm ? this.appDetail.brands_cols_sm : 2],
      container_layout: [this.appDetail.container_layout ? this.appDetail.container_layout : 1],
      desktop_layout: [this.appDetail.desktop_layout ? this.appDetail.desktop_layout : "side-view"],
      add_to_cart_caption_en: [this.appDetail.add_to_cart_caption_en ? this.appDetail.add_to_cart_caption_en : "Add to Cart"],
      add_to_cart_caption_ar: [this.appDetail.add_to_cart_caption_ar ? this.appDetail.add_to_cart_caption_ar : "Add to Cart"],
      subscriber_label_ar: [this.appDetail.subscriber_label_ar ? this.appDetail.subscriber_label_ar : ""],
      subscriber_label_en: [this.appDetail.subscriber_label_en ? this.appDetail.subscriber_label_en : ""],
      has_vault: [this.appDetail.has_vault ? this.appDetail.has_vault : false],
      terms: [this.appDetail.terms ? this.appDetail.terms : null],
      terms_condition: [this.appDetail.terms_condition ? this.appDetail.terms_condition : 1],
      cols_md: [this.appDetail.cols_md ? this.appDetail.cols_md : 4],
      cols_xs: [this.appDetail.cols_xs ? this.appDetail.cols_xs : 2],
      cols_sm: [this.appDetail.cols_sm ? this.appDetail.cols_sm : 2],
      css: [this.appDetail.css ? this.appDetail.css : null, [this.characterCountValidator(500)]],

      theme: [this.appDetail.theme ? this.appDetail.theme : 1],
      header_layout: [this.appDetail.header_layout ? this.appDetail.header_layout : 1],
      product_page_layout: [this.appDetail.product_page_layout ? this.appDetail.product_page_layout : 1],
      product_card_layout: [this.appDetail.product_card_layout ? this.appDetail.product_card_layout : 1],
      footer_layout: [this.appDetail.footer_layout ? this.appDetail.footer_layout : 1],
      checkout_footer_layout: [this.appDetail.checkout_footer_layout ? this.appDetail.checkout_footer_layout : 1],
      search_bar_layout: [this.appDetail.search_bar_layout ? this.appDetail.search_bar_layout : 1],
      menu_layout: [this.appDetail.menu_layout ? this.appDetail.menu_layout : 1],
      enable_side_menu_on_desktop: [this.appDetail.enable_side_menu_on_desktop ? this.appDetail.enable_side_menu_on_desktop : false],
      enable_review_page: [this.appDetail.enable_review_page ? this.appDetail.enable_review_page : false],
      enable_quick_purchase_popover: [this.appDetail.enable_quick_purchase_popover ? this.appDetail.enable_quick_purchase_popover : false],
      show_paydo_network_qr: [this.appDetail.show_paydo_network_qr ? this.appDetail.show_paydo_network_qr : false],
    });
    this.designForm.controls.terms_condition.valueChanges.subscribe((data) => {
      if (data == 3) {
        this.designForm.controls.terms.setValue("");
      }
    });
    this.primary_color = this.appDetail.primary_color ? this.appDetail.primary_color : "";
    this.secondary_color = this.appDetail.secondary_color ? this.appDetail.secondary_color : "";
    this.text_color = this.appDetail.text_color ? this.appDetail.text_color : "";
    this.splash_screen_bg = this.appDetail.splash_screen_bg ? this.appDetail.splash_screen_bg : "";
    this.loading = false;
  }

  getFooterWidget() {
    this.widgetService
      .getWidgetList(undefined, undefined, undefined, {
        zone__code: 2,
      })
      .subscribe((res) => {
        this.footerWidgets = res.results && res.results;
        const selectedFooterWidget = this.designForm.controls.footer_layout.value;
        this.selectFooter(this.footerWidgets[selectedFooterWidget - 1]);
      });
  }

  setColor(type) {
    if (type == "text") {
      this.designForm.controls.text_color.setValue(this.text_color);
    }
    if (type == "secondary") {
      this.designForm.controls.secondary_color.setValue(this.secondary_color);
    }
    if (type == "primary") {
      this.designForm.controls.primary_color.setValue(this.primary_color);
    }
    if (type == "splash") {
      this.designForm.controls.splash_screen_bg.setValue(this.splash_screen_bg);
    }
  }

  openOrClose(type) {
    let testArray = [1, 2]; // color picker issue solved by this temp trick
    if (type == "text") {
      testArray.forEach((data) => {
        this.text_color = this.designForm.controls.text_color.value;
      });
    }
    if (type == "secondary") {
      testArray.forEach((data) => {
        this.secondary_color = this.designForm.controls.secondary_color.value;
      });
    }
    if (type == "primary") {
      testArray.forEach((data) => {
        this.primary_color = this.designForm.controls.primary_color.value;
      });
    }
    if (type == "splash") {
      testArray.forEach((data) => {
        this.splash_screen_bg = this.designForm.controls.splash_screen_bg.value;
      });
    }
  }

  goBack() {
    let fromApp = true;
    if (!this.utilitiesService.formChanged$.value) {
      this.back.emit();
    } else {
      let content = `You have unsaved changes, Please save before changing the page`;
      let heading = "Alert";
      let size = this.utilitiesService.isMobileAlertModal();
      const dialogRef = this.dialog.open(AlertModalComponent, {
        data: { content, heading, fromApp },
        maxWidth: "",
        width: `${size.width}`,
        height: `${size.height}`,
      });
      dialogRef.afterClosed().subscribe(async (resp) => {
        if (resp == "yes") {
        } else if (resp == "no") {
          this.utilitiesService.formChangedOrNot(false);
          this.back.emit();
        }
      });
    }
  }

  async ngAfterViewInit(): Promise<void> {
    this.isViewInit = true;
    document.getElementById("toolbar").scrollIntoView();

    this.editor.getEditor().setOptions({
      showLineNumbers: true,
      tabSize: 2,
    });
    this.editor.mode = "css";
    this.designForm.valueChanges.subscribe((data) => {
      this.utilitiesService.formChangedOrNot(true);
    });
  }

  save() {
    let content = `Are you sure, Do you want to update? `;
    let heading = "Update";
    let fromApp = false;
    let size = this.utilitiesService.isMobileAlertModal();
    if (this.designForm.valid) {
      const dialogRef = this.dialog.open(AlertModalComponent, {
        data: { content, heading, fromApp },
        maxWidth: "",
        width: `${size.width}`,
        height: `${size.height}`,
      });
      dialogRef.afterClosed().subscribe(async (resp) => {
        this.utilitiesService.autoSave.next(0);
        if (resp) {
          let form = this.designForm.value;
          const resp = await this.appService.addAppSettings(form).toPromise();

          if (resp) {
            this.utilitiesService.formChangedOrNot(false);
            let successMsg = this.translate.instant("Design Settings updated successfully");
            this.utilitiesService.showSuccessToast(successMsg);
            this.saveHander.emit();
          }
        }
      });
    } else {
      console.log("not valid");
      this.utilitiesService.checkValidations(this.designForm);
    }
  }

  deleteFile(type) {
    this.pushToFomGroup(type, true);
  }

  setFileToForm(files, type) {
    console.log("setFileTo Form");
    this.pushToFomGroup(type, false, files);
  }

  selectedElementFn(id) {
    this.selectedElement.emit(id);
  }

  async pushToFomGroup(type, isDelete, files?) {
    if (type == "logo") {
      if (!isDelete) {
        this.designForm.controls.logo.setValue(files.file);
      } else {
        this.designForm.controls.logo.setValue(null);
      }
    } else if (type == "fav") {
      if (!isDelete) {
        this.designForm.controls.favicon.setValue(files.file);
      } else {
        this.designForm.controls.favicon.setValue(null);
      }
    } else if (type == "splash") {
      if (this.designForm.controls.language.value == "en") {
        if (!isDelete) {
          console.log(files);

          this.designForm.controls.splash_screen_logo.setValue(files.file);
          this.designForm.controls.splash_screen_logo_en.setValue(files.file);
        } else {
          this.designForm.controls.splash_screen_logo_en.setValue(null);
          this.designForm.controls.splash_screen_logo.setValue(null);
        }
      } else if (this.designForm.controls.language.value == "ar") {
        if (!isDelete) {
          this.designForm.controls.splash_screen_logo_ar.setValue(files.file);
        } else {
          this.designForm.controls.splash_screen_logo_ar.setValue(null);
        }
      }
    }
  }

  zoomAdditionalCss(formControl) {
    let inlineSave = true;
    let heading = "Additional CSS";
    const dialogRef = this.dialog.open(FullscreenAdditionCssComponent, {
      data: { formControl, heading, inlineSave },
      maxWidth: "",
      width: "100%",
      height: "100%",
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        this.designForm.get("css").setValue(resp.value);
      }
    });
  }

  openGallery(formControl) {
    let isMultiSelect = false;
    const dialogRef = this.dialog.open(GalleryPopupComponent, {
      data: { formControl, isMultiSelect },
      maxWidth: "",
      width: "50vw",
      height: "50vh",
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
      }
    });
  }

  selectFooter(footerWidget) {
    let form = {
      id: footerWidget.id,
      zone_id: footerWidget.zone_id,
      element_type_id: footerWidget.element_type_id,
      name: footerWidget.name,
      enabled: true,
      value: footerWidget.value,
      value_ar: footerWidget.value_ar,
      order: footerWidget.order,
      css: footerWidget.css,
      show_on: footerWidget.show_on,
    };
    this.footerWidgetForm = { ...form };
  }

  editFooter(footerWidget) {
    const navigationExtras: NavigationExtras = {
      state: footerWidget,
    };

    this.router.navigate([AppRoutes.Widgets], navigationExtras);
  }

  saveFooterWidgetValue() {
    let form;

    const selectdWidgetId = this.footerWidgetForm.id;
    this.widgetService.updateWidget(this.footerWidgetForm).subscribe();

    this.footerWidgets.forEach((widget) => {
      if (widget.id === selectdWidgetId) return;
      else {
        form = {
          id: widget.id,
          zone_id: widget.zone_id,
          element_type_id: widget.element_type_id,
          name: widget.name,
          enabled: false,
          value: widget.value,
          value_ar: widget.value_ar,
          order: widget.order,
          css: widget.css,
          show_on: widget.show_on,
        };
        this.widgetService.updateWidget(form).subscribe();
      }
    });
  }

  async showQrCode(event: MatSlideToggleChange) {
    let form = this.designForm.value;

    if (event.checked) {
      const resp = await this.appService.addAppSettings(form).toPromise();
      this.qrLink = resp.paydo_network_qr;
    }
  }

  copyUrl(url) {
    this.clipboard.copy(url);
    this.showCopyMessage();
  }

  showCopyMessage() {
    let copyText = this.translate.instant("Url Copied to clipboard!");
    this.utilitiesService.showSuccessToast(copyText);
  }

  characterCountValidator(maxCount: number) {
    return (control) => {
      const value = control.value;
      if (value) {
        if (value.length > maxCount) {
          return { error: "Css should not be more than 500 character." };
        }
      }
      return null;
    };
  }
}
