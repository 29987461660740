import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
    selector: "app-common-filter",
    templateUrl: "./common-filter.component.html",
    styleUrls: ["./common-filter.component.scss"],
})
export class CommonFilterComponent implements OnInit {
    searchTextSubject$: BehaviorSubject<any> = new BehaviorSubject(undefined);
    @Output() searchInput: EventEmitter<any> = new EventEmitter<any>();
    searchText: any = "";
    constructor() {
        this.searchTextSubject$.pipe(debounceTime(600)).subscribe((data) => {
            if (data != undefined) {
                    this.searchInput.emit(data);
            }
        });
    }

    ngOnInit(): void {

    }

    search(event) {}

    clearFilter() {
        this.searchTextSubject$.next("")
        this.searchText = ''
    }
}
