import { AppService } from 'app/services/app.service';
import {
    Component,
    Input,
    OnInit,
    ViewChild,
    SimpleChanges,
    OnChanges,
    ElementRef,
    ContentChild,
    TemplateRef,
    Renderer2
} from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
    ChartComponent,
    ApexOptions,
    ApexNonAxisChartSeries,
    ApexResponsive,
    ApexAxisChartSeries,
    ApexChart,
    ApexXAxis,
    ApexDataLabels,
    ApexTitleSubtitle,
    ApexStroke,
    ApexGrid
} from "ng-apexcharts";
import {
    PieChartOptions,
    LineChartOptions,
    VerticleBarChartOptions,
    HorizontalBarOptions,
    AreaChartOptions,
    Platforms,
    MixedChartOptions
} from 'app/main/types';
import * as moment from "moment";
import { ChartsType } from 'app/main/types';
import * as echarts from 'echarts';
import { Router } from '@angular/router';

@Component({
    selector: 'app-charts',
    templateUrl: './charts.component.html',
    styleUrls: ['./charts.component.scss']
})

export class ChartsComponent implements OnInit, OnChanges {
    listenerFn: () => void;

    @ViewChild('pieChart') pieChart: ChartComponent;
    @ViewChild('lineChart') lineChart: ChartComponent;
    @ViewChild('revenueLineChart') revenueLineChart: ChartComponent;
    @ViewChild('mediumPieChart') mediumPieChart: ChartComponent;
    @ViewChild('mediumRevenueBarChart') mediumRevenueBarChart: ChartComponent;
    @ViewChild('platformRevenuePieChart') platformRevenuePieChart: ChartComponent;
    @ViewChild('totalViewsBarChart') totalViewsBarChart: ChartComponent;
    @ViewChild('totalRevenueBarChart') totalRevenueBarChart: ChartComponent;
    @ViewChild('mediumBarChart') mediumBarChart: ChartComponent;
    @ViewChild('totalVisitsChart') totalVisitsChart: ChartComponent;

    @ContentChild("chartTemplateRef", { static: false })
    chartTemplateRef: TemplateRef<any>;

    @ViewChild("pieChart2") pieChart2: ChartComponent;
    @ViewChild("areaChart") areaChart: ChartComponent;
    @ViewChild("horizontalBarChart") horizontalBarChart: ChartComponent;
    @ViewChild("verticleBarChart") verticleBarChart: ChartComponent;
    @ViewChild("lineChart2") lineChart2: ChartComponent;
    @ViewChild('mixedChart') mixedChart: ChartComponent;
    @ViewChild('map') map: ElementRef;

    @Input() statics;
    @Input() page;
    @Input() date;
    @Input() type;
    @Input() seriesName;
    @Input() mixedSeriesNames;
    @Input() chartTypes;
    @Input() baseCurrency;

    public pieChartOptions: Partial<PieChartOptions>;
    public lineChartOptions: Partial<LineChartOptions>;
    public verticleBarChartOptions: Partial<VerticleBarChartOptions>;
    public horizontalBarOptions: Partial<HorizontalBarOptions>;
    public areaChartOptions: Partial<AreaChartOptions>;
    public mixChartOptions: any;
    public ChartsType: typeof ChartsType = ChartsType;


    platformViews = {};
    mediums = {};
    platformRevenue = {};
    mediumRevenue = {};

    totalViews = [];
    totalPurchase = [];
    categories = [];
    allCampaignsViews = [];
    allCampaignsRevenue = [];
    totalOrders = 0;

    isSingleCampaign = false;
    isHomePage = false;
    isPlatformViews = true;
    isPlatformRevenue = true;
    numberOfDays = 7;

    showRevenueLineChart = false;
    showViewsLineChart = false;

    data: any;
    mapOptions: echarts.EChartsOption;
    horizontalBarData = [];

    noDataMessage = 'No data found.';

    constructor(
        private http: HttpClient,
        private renderer: Renderer2,
        private router: Router,
        private appService: AppService
    ) {
    }

     async getBaseCurrency(){
        this.baseCurrency= this.appService.getCurrency()
    }

    ngOnInit(): void {
        this.initializeLineChart();
        this.initializeHorizontalBarChart();
        this.initializeVerticleBarChart();
        this.initializePieChart();
        this.initializeAreaChart();
        this.initializeMixChart();
        this.initializeMixedChart()
    }

    ngAfterViewInit() {
        if (this.map) this.listenerFn = this.renderer.listen(this.map.nativeElement, 'mouseup', this.onChartClick.bind(this));
    }

    async ngOnChanges(changes: SimpleChanges) {
        let statics = changes?.statics?.currentValue;
        this.data = statics;
        this.isSingleCampaign = this.getPage() === 'singleCampaign' ? true : false;
        this.isHomePage = this.getPage() === 'homePage' ? true : false;
        this.getNumberOfDays();
        if (statics && Object.keys(statics).length !== 0 && !this.isHomePage) {
            await this.getBaseCurrency()
            this.getPlatformViews(statics);
            this.getMediumsViews(statics);
            this.getCampaignTotalViews(statics);
            this.getCampignTotalRevenue(statics);
            this.getMediumsRevenue(statics);
            this.getPlatformRevenue(statics);
        }

        if (statics && Object.keys(statics).length !== 0 && !this.isSingleCampaign && !this.isHomePage) {
            this.getTotalViews(statics);
            this.getTotalRevenue(statics);
        };

        if (statics) {
            this.getChartType(this.type);
        }
    }

    getPage(): any {
        return this.page;
    }


    initializeLineChart() {
        this.lineChartOptions = {
            series: [{
                name: 'name',
                data: [1, 2, 3, 5, 6]
            }],
            chart: {
                height: 250,
                type: "line",
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: true,
                    tools: {
                        download: false
                    }
                }
            },
            legend: {
                show: true,
                position: 'bottom',
                showForSingleSeries: true
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: "straight",
                width: 3
            },
            grid: {
                row: {
                    colors: ["#f3f3f3", "transparent"],
                    opacity: 0.5
                }
            }
        };
    }

    initializePieChart(): void {
        this.pieChartOptions = {
            series: [],
            chart: {
                width: 280,
                type: "pie"
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        offset: -10,
                        minAngleToShowLabel: 10
                    },
                }
            },
            dataLabels: {
                enabled: true,
                textAnchor: 'start',
                style: {
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    colors: ['#fff']
                }
            },
            legend: {
                show: true,
                position: 'bottom',
                showForSingleSeries: true
            },
            labels: [],
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 300,

                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                }
            ]
        };

    }

    initializeVerticleBarChart(): void {
        this.verticleBarChartOptions = {
            series: [{
                name: 'name',
                data: [1, 2, 3, 5, 6]
            }],
            chart: {
                height: 250,
                type: "bar",
                toolbar: {
                    show: false,
                    tools: {
                        download: false
                    }
                }
            },
            legend: {
                show: true,
                position: 'bottom',
                showForSingleSeries: true,
                markers: {
                    fillColors: [
                        "#33b2df",
                        "#546E7A",
                        "#d4526e",
                    ]
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '15%',
                    barHeight: "100%",
                    distributed: true,

                    dataLabels: {
                        position: "top" // top, center, bottom
                    },
                    colors: {
                        ranges: [{
                            from: 100,
                            to: 100,
                            color: "#2e5ea8"
                        }]
                    }
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val;
                },
                offsetY: -20,
                style: {
                    fontSize: "12px",
                    colors: ["#304758"]
                }
            },
            xaxis: {
                categories: [],
                labels: {
                    show: false
                },
                position: "bottom",
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: "gradient",
                        gradient: {
                            colorFrom: "#D8E3F0",
                            colorTo: "#BED1E6",
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5
                        }
                    }
                },
                tooltip: {
                    enabled: false,
                    offsetY: -35
                }
            },
            fill: {},
            yaxis: {}

        };
    }

    initializeHorizontalBarChart(): void {
        this.horizontalBarOptions = {
            series: [{
                name: '',
                data: []
            }],
            chart: {
                type: "bar",
                height: 280,
                toolbar: {
                    show: true,
                    tools: {
                        download: false
                    }
                },
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                        let label = config.w.globals.labels[config.dataPointIndex];
                        let id
                        this.horizontalBarData.forEach(el => {
                            (el.name === label) ? id = el.id : null;
                        })
                        if (id) this.navigateTo(id);
                    },

                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: []
            },
            legend: {
                show: true,
                position: 'bottom',
                showForSingleSeries: true
            },

        };
    }

    initializeAreaChart(): void {
        this.areaChartOptions = {
            series: [{
                name: '',
                data: []
            }],
            chart: {
                type: "area",
                height: 250,
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: true,
                    tools: {
                        download: false
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: "straight"
            },
            subtitle: {},
            labels: [],
            xaxis: {
                type: "category"
            },
            yaxis: {
                opposite: true
            },
            legend: {
                horizontalAlign: "center",
                showForSingleSeries: true
            }
        }
    }

    initializeMixedChart(){
        this.mixChartOptions = {
            series: [
                {
                    name: "Revenue",
                    type: "column",
                    data: [20, 29, 37, 36, 44, 45, 50, 58]
                },
                {
                    name: "Income",
                    type: "column",
                    data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
                },
                {
                    name: "Cashflow",
                    type: "column",
                    data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
                },
            ],
            chart: {
              height: 350,
              type: "line",
              stacked: false
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: [4, 4, 4]
            },
            title: {
              align: "left",
              offsetX: 110
            },
            xaxis: {
              categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
            },
            yaxis: [
              {
                axisTicks: {
                  show: true
                },
                axisBorder: {
                  show: true,
                  color: "#008FFB"
                },
                labels: {
                  style: {
                    color: "#008FFB"
                  }
                },
                title: {
                  text: "Income (thousand crores)",
                  style: {
                    color: "#008FFB"
                  }
                },
                tooltip: {
                  enabled: true
                }
              },
              {
                seriesName: "Income",
                opposite: true,
                axisTicks: {
                  show: true
                },
                axisBorder: {
                  show: true,
                  color: "#00E396"
                },
                labels: {
                  style: {
                    color: "#00E396"
                  }
                },
                title: {
                  text: "Operating Cashflow (thousand crores)",
                  style: {
                    color: "#00E396"
                  }
                }
              },
              {
                seriesName: "Revenue",
                opposite: true,
                axisTicks: {
                  show: true
                },
                axisBorder: {
                  show: true,
                  color: "#FEB019"
                },
                labels: {
                  style: {
                    color: "#FEB019"
                  }
                },
                title: {
                  text: "Revenue (thousand crores)",
                  style: {
                    color: "#FEB019"
                  }
                }
              }
            ],
            tooltip: {
              fixed: {
                enabled: false,
              }
            },
            legend: {
              horizontalAlign: "left",
              offsetX: 40
            }
          };
    }

    initializeMixChart(): void {
        this.mixChartOptions = {
            series: [],
            chart: {
                height: 300,
                type: "line",
                toolbar: {
                    show: false,
                    tools: {
                        download: false
                    }
                }
            },
            stroke: {
                curve: "smooth"
            },
            fill: {
                type: "solid"
            },
            labels: [],
            markers: {
                size: 0
            },
            yaxis: [
                {
                    title: {
                        text: "Series A"
                    }
                },
                {
                    opposite: true,
                    title: {
                        text: "Series B"
                    }
                }
            ],
            xaxis: {
                labels: {
                    trim: false
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0);
                        }
                        return y;
                    }
                }
            }
        };
    }


    updatePieChart(updates, chart: ChartComponent) {
        chart.updateOptions({
            series: [...Object.values(updates)],
            labels: [...Object.keys(updates)]
        });
    }

    updateLineChart(updates, chart: ChartComponent) {
        chart.updateOptions({ ...updates });

        this.resizeWindow();
    }

    updateBarChart(updates, chart: ChartComponent) {
        chart?.updateOptions({ ...updates });
        // this.resizeWindow();
    }

    updateHorizontalChart(updates, chart: ChartComponent) {
        chart?.updateOptions({ ...updates });
    }

    updateMixedChart(updates, chart: ChartComponent) {
        chart?.updateOptions({ ...updates });
    }

    getPlatformViews(statics) {

        if (statics?.visit_platform.length > 0) {
            this.platformViews = {};
            for (const key of statics?.visit_platform) {
                this.platformViews[this.getPlatform(key[0])] = key[1];
            }
            this.updatePieChart(this.platformViews, this.pieChart);
        } else {
            this.platformViews = {}
            this.updatePieChart({}, this.pieChart);
        }
    }

    getMediumsViews(statics) {
        if (statics?.visit_channel.length > 0) {
            this.mediums = {};
            for (const key of statics?.visit_channel) {
                this.mediums[key[0]] = key[1];
            }

            let categories = Object.keys(this.mediums);
            let data = Object.values(this.mediums);
            this.updateBarChart({
                xaxis: {
                    categories: categories
                },
                series: [
                    {
                        name: 'Medium Views',
                        data: data
                    }
                ],
            }, this.mediumBarChart);
        } else {
            this.mediums = {}
            this.updateBarChart({}, this.mediumBarChart);
        }
    }

    getCampaignTotalViews(statics) {
        if (Object.keys(statics?.visit_count_per_day).length > 0 || Object.keys(statics?.order_count_per_day).length > 0) {
            this.totalViews = Object.entries(statics?.visit_count_per_day).map(el => el[1]);
            let totalOrders = Object.entries(statics?.order_count_per_day).map(el => el[1]);
            this.categories = Object.keys(statics?.visit_count_per_day).length > 0 ?
                Object.entries(statics?.visit_count_per_day).map(el => el[0].substring(5)) :
                Object.entries(statics?.order_count_per_day).map(el => el[0].substring(5))

            this.showViewsLineChart = Object.values(statics?.visit_count_per_day).some((el:any) => el > 0) ||
                Object.values(statics?.order_count_per_day).some((el:any) => el > 0)

            this.updateLineChart({
                xaxis: {
                    categories: [...this.categories.sort()]
                },
                yaxis: [
                    {
                        seriesName: 'Views',

                        title: {
                            text: "Views"
                        }
                    },
                    {
                        seriesName: 'Orders',
                        opposite: true,
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: true,
                        },
                        title: {
                            text: "Orders"
                        }
                    }
                ],
                series: [
                    {
                        name: "Views",
                        type: 'line',

                        data: [...this.totalViews]
                    },
                    {
                        name: "Orders",
                        type: 'column',

                        data: [...totalOrders]
                    }
                ],

            }, this.lineChart);
        } else {
            this.totalViews = [];
            this.categories = [];
            this.totalOrders = 0;
            this.updateLineChart({}, this.lineChart);
        }
    }

    getCampignTotalRevenue(statics) {
        if (Object.keys(statics?.order_total_per_day).length > 0) {

            this.totalPurchase = [];
            this.totalPurchase = Object.entries(statics?.order_total_per_day).map(el => el[1]);
            this.categories = Object.entries(statics?.order_total_per_day).map(el => el[0].substr(5));
            let totalOrders = Object.entries(statics?.order_count_per_day).map(el => el[1]);

            this.showRevenueLineChart = Object.values(statics?.order_total_per_day).some((el:any) => el > 0)
            const name = this.baseCurrency ? `Revenue in ${this.baseCurrency}`: 'Revenue'

            this.updateLineChart({
                xaxis: {
                    categories: [...this.categories]
                },
                series: [
                    {
                        name: name,
                        type: 'line',
                        data: [...this.totalPurchase]
                    },
                    {
                        name: 'Conversions',
                        type: 'column',
                        data: [...totalOrders]
                    }
                ],
                yaxis: [
                    {
                        seriesName: 'Revenue',

                        title: {
                            text: "Revenue"
                        }
                    },
                    {
                        seriesName: 'Conversions',
                        opposite: true,
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: true,
                        },
                        title: {
                            text: "Conversions"
                        }
                    }
                ]
            }, this.revenueLineChart);

        } else {
            this.totalPurchase = [];
            this.categories = [];
            this.updateLineChart({}, this.revenueLineChart);
        }
    }

    getMediumsRevenue(statics) {
        if (statics?.order_channel_total.length > 0) {
            this.mediumRevenue = {};
            for (const key of statics?.order_channel_total) {
                this.mediumRevenue[key[0]] = key[1]
            }

            let categories = Object.keys(this.mediumRevenue);
            let data = Object.values(this.mediumRevenue)
            let name = this.baseCurrency ? `Medium Revenue in ${this.baseCurrency}`:`Medium Revenue`
            this.updateBarChart({
                xaxis: {
                    categories: categories
                },
                series: [
                    {
                        name: name,
                        data: data
                    }
                ],
            }, this.mediumRevenueBarChart);

        } else {
            this.mediumRevenue = {};
            this.updateBarChart({}, this.mediumRevenueBarChart);
        }



    }

    getPlatformRevenue(statics) {
        if (statics?.order_platform_total.length > 0) {
            this.platformRevenue = {};
            for (const key of statics?.order_platform_total) {
                this.platformRevenue[this.getPlatform(key[0])] = key[1];
            }
            this.updatePieChart(this.platformRevenue, this.platformRevenuePieChart);
        } else {
            this.platformRevenue = {};
            this.updatePieChart({}, this.platformRevenuePieChart);
        }

    }

    getTotalViews(statics) {
        if (statics?.top_campaign_stats.length > 0) {
            this.allCampaignsViews = statics?.visit_campaign_count.map(el => el[1])
            const categories = statics?.top_campaign_stats.map(el => el.campaign__name);
            const orderCount = statics?.top_campaign_stats.map(el => el.order_count);
            const totalRevenue = statics?.top_campaign_stats.map(el => el.total_revenue);
            const visitCount = statics?.top_campaign_stats.map(el => el.visit_count);
            console.log(this.totalOrders)

            this.updateMixedChart({
                xaxis: {
                    categories: [...categories]
                },
                series: [
                    {
                        name: "Visit",
                        data: [...visitCount]
                    },
                    {
                        name: "Revenue",
                        data: [...totalRevenue]
                    },
                    {
                        name: "Orders",
                        data: [...orderCount]
                    }
                ],
                yaxis: [
                    {
                      axisTicks: {
                        show: true
                      },
                      axisBorder: {
                        show: true,
                        color: "#008FFB"
                      },
                      labels: {
                        style: {
                          color: "#008FFB"
                        }
                      },
                      title: {
                        text: "Visit",
                        style: {
                          color: "#008FFB"
                        }
                      },
                      tooltip: {
                        enabled: true
                      }
                    },
                    {
                      seriesName: "Revenue",
                      opposite: true,
                      axisTicks: {
                        show: true
                      },
                      axisBorder: {
                        show: true,
                        color: "#00E396"
                      },
                      labels: {
                        style: {
                          color: "#00E396"
                        }
                      },
                      title: {
                        text: "Revenue",
                        style: {
                          color: "#00E396"
                        }
                      }
                    },
                    {
                      seriesName: "Orders",
                      opposite: true,
                      axisTicks: {
                        show: true
                      },
                      axisBorder: {
                        show: true,
                        color: "#FEB019"
                      },
                      labels: {
                        style: {
                          color: "#FEB019"
                        }
                      },
                      title: {
                        text: "Orders",
                        style: {
                          color: "#FEB019"
                        }
                      }
                    }
                  ],

            }, this.totalViewsBarChart);
        } else {
            this.allCampaignsViews = [];
            this.categories = [];
            this.totalOrders = 0;

            this.updateHorizontalChart({}, this.totalViewsBarChart);
        }
    }

    getTotalRevenue(statics) {
        if (statics?.order_campaign_total.length > 0) {
            this.allCampaignsRevenue = statics?.order_campaign_total.map(el => el[1])
            this.categories = statics?.order_campaign_total.map(el => el[0] + '');
            this.totalOrders = statics?.order_count;
            this.updateHorizontalChart({
                xaxis: {
                    categories: [...this.categories]
                },
                series: [
                    {
                        name: "Products Purcahed",
                        data: [...this.allCampaignsRevenue]
                    }
                ],
                plotOptions: {
                    bar: {
                        barHeight: '30px'
                    }
                }
            }, this.totalRevenueBarChart);
        } else {
            this.allCampaignsRevenue = [];
            this.categories = [];
            this.totalOrders = 0;
            this.updateHorizontalChart({}, this.totalRevenueBarChart);
        }
    }

    changeViews(event) {
        this.isPlatformViews = !event?.checked;
    }

    changeRevenue(event) {
        this.isPlatformRevenue = !event?.checked;
    }

    getNumberOfDays() {
        let start = moment(this.date.start_date, "YYYY-MM-DD");
        let end = moment(this.date.end_date, "YYYY-MM-DD");

        //Difference in number of days
        let numOfDays = Math.abs(moment.duration(start.diff(end)).asDays());

        !isNaN(numOfDays) ? this.numberOfDays = numOfDays + 1 : null;
    }

    resizeWindow() {
        /**
         * use this event beacuse apexchart has an issue that when chart disappear because there is no data
         * chart will not appear again if we get new data until you resize the browser
         */
        window.dispatchEvent(new Event("resize"));
    }

    getChartType(type) {
        let chart;
        switch (type) {
            case ChartsType.Pie:
                chart = this.pieChart2;
                this.updateChart(this.data, this.seriesName, chart);
                break;
            case ChartsType.Line:
                chart = this.lineChart2;
                this.updateChart(this.data, this.seriesName, chart);
                break;
            case ChartsType.Area:
                chart = this.areaChart;
                this.updateChart(this.data, this.seriesName, chart);
                break;
            case ChartsType.HorizontalBar:
                chart = this.horizontalBarChart;
                this.updateChart(this.data, this.seriesName, chart);
                this.getChartData(this.data);
                break;
            case ChartsType.VerticalBar:
                chart = this.verticleBarChart;
                this.updateChart(this.data, this.seriesName, chart);
                break;
            case ChartsType.Mixed:
                chart = this.mixedChart;
                this.updateChart(this.data, this.mixedSeriesNames, chart);
                break;
            case ChartsType.Map:
                this.loadMap(this.data);
                break;
            default:
                break;
        }
    }

    getPlatform(id) {
        let platformName;

        switch (id) {
          case Platforms.IOS:
            platformName = 'ios';
            break;
          case Platforms.ANDROID:
            platformName = 'android';
            break;
          case Platforms.WEBSITE:
            platformName = 'web';
            break;
          case Platforms.IOS_WEB:
            platformName = 'ios web';
            break;
          case Platforms.ANDROID_WEB:
            platformName = 'android web';
            break;
          default:
            platformName = 'unknown';
            break;
        }
        return platformName
    }

    getChartData(data) {
        data.forEach(el => {
            ('id' in el) ? this.horizontalBarData.push({ id: el?.id, name: el?.name }) : null;
        });
    }

    updateChart(statics, seriesName: string, chart: ChartComponent) {

        let series = {};
        if (statics?.length > 0) {
            statics.forEach((el, i) => {

                if (!isNaN(el[0])) el[0] = this.getPlatform(el[0]); //change platform numbers into platform names

                if (Array.isArray(el)) series[el[0]] = el[1];
                else {
                    //@ts-ignore
                    if (typeof Object.values(el)[0] === 'string') series[Object.values(el)[0]] = Object.values(el)[1];
                    //@ts-ignore
                    else series[Object.values(el)[1]] = Object.values(el)[2];
                }
            });
        }

        let categories: string[] = Object.keys(series) as string[];
        let data: number[] = Object.values(series) as number[];
        let name = seriesName;

        if (this.areaChart && chart === this.areaChart) {
            this.areaChartOptions = {
                ...this.areaChartOptions, ...{
                    labels: categories,
                    series: [{
                        name: seriesName,
                        data: data
                    }]
                }
            }

            chart.updateSeries([{
                name: seriesName,
                data: data
            }])

            chart.updateOptions({
                labels: categories
            })

            this.resizeWindow();

        } else if (this.pieChart2 && chart === this.pieChart2) {
            let currency;

            if (this.baseCurrency) {
                currency = this.baseCurrency;
            }

            chart.updateOptions({
                series: data,
                labels: categories,
                chart: {
                    width: 350
                },
                tooltip: {
                    y: {
                        formatter: function (value) {
                            if (currency) return `${value} ${currency}`;
                            else return `${value}`

                        }
                    }
                }
            })
        } else if (this.lineChart && chart === this.lineChart) {
            this.lineChartOptions = {
                ...this.lineChartOptions, ...{
                    series: [{
                        data: data,
                        name: seriesName
                    }],
                    xaxis: {
                        categories: categories
                    }
                }
            }
            this.resizeWindow();
        } else if (this.horizontalBarChart && chart === this.horizontalBarChart) {
            this.horizontalBarOptions = {
                ...this.horizontalBarOptions, ...{
                    series: [{
                        data: data,
                        name: seriesName
                    }],
                    xaxis: {
                        categories: categories
                    }
                }
            }
            chart.updateSeries([{
                data: data,
                name: seriesName
            }])
            chart.updateOptions({
                xaxis: {
                    categories: categories
                }
            })
            this.resizeWindow();
        } else if (this.verticleBarChart && chart === this.verticleBarChart) {
            this.verticleBarChartOptions = {
                ...this.verticleBarChartOptions, ...{
                    series: [{
                        data: data,
                        name: seriesName
                    }],
                    xaxis: {
                        categories: categories
                    }
                }
            }
        } else if (this.mixedChart && chart === this.mixedChart) {
            let seriesNames = [];
            let series = [];
            let seriresData = statics[0];
            let labels = [];
            let mixedData = [];
            let yaxis = [];
            let isData = false;

            isData = Object.values(seriresData).some(el => el === undefined);
            if (!isData) {

                Object.keys(seriresData).forEach(name => {
                    seriesNames.push(name)
                })

                //@ts-ignore
                Object.values(seriresData)[0].forEach(el => {
                    labels.push(el[0])
                });

                Object.values(seriresData).forEach(data => {
                    let arr = []
                    //@ts-ignore
                    data.forEach(el => {
                        arr.push(el[1]);
                    });
                    mixedData.push(arr)
                })

                seriesNames.forEach((name, i) => {
                    series.push({
                        name: name,
                        data: mixedData[i],
                        type: this.chartTypes[i],
                    })


                    if (i === seriesNames.length - 1) {
                        yaxis.push({
                            opposite: true,

                            title: {
                                text: name
                            }
                        })
                    } else {
                        yaxis.push(
                            {
                                title: {
                                    text: name
                                }
                            }
                        )
                    }

                })

                this.mixChartOptions = {
                    ...this.mixChartOptions, ...{
                        series: [...series],
                        labels: [...labels],
                        yaxis
                    }
                }

                chart.updateSeries(series)
                chart.updateOptions({
                    labels: [...labels],
                    yaxis
                })
                this.resizeWindow();
            }


        }
    }

    loadMap(data) {
        let seriesData = [];
        let toolTipData = data;
        if (data) {
            data.forEach(el => {
                let countryData = { name: el.country_name, value: [el.total_count, el.total_revenue], code: el.country_code };
                seriesData.push(countryData);
            });
        }

        this.http.get('assets/maps/map.json').subscribe((geoJson: any) => {
            echarts.registerMap('world', geoJson);

            this.mapOptions = {
                series: [{
                    name: 'Orders',
                    type: 'map',
                    map: 'world',
                    data: [...seriesData],
                    roam: 'move',
                    zoom: 4
                }],
                tooltip: {
                    showContent: true,
                    triggerOn: 'mousemove|click',
                    enterable: true,
                    position: 'right',
                    formatter: (params: any) => {
                        localStorage.setItem('order-country', params?.data?.code)
                        if (params?.data?.value[0]) {
                            return [
                                params?.name,
                                'No.Orders:' + ' ' + params?.data?.value[0],
                                'Revenue:' + ' ' + params?.data?.value[1] + ' ' + this.baseCurrency
                            ].join('<br>');
                        }
                    }
                },
                visualMap: {
                    left: 'right',
                    min: 0,
                    max: 1000,
                    show: false,
                    inRange: {
                        color: [
                            '#bacbd9',
                            '#95c2e7',
                            '#6f8ea7',
                            '#4f799b',
                            '#005da8'
                        ]
                    }
                },

            };

        })
    }

    onChartClick() {
        if (localStorage.getItem('order-country') !== 'undefined') this.router.navigate(['orders'], {
            queryParams: {
                page: 'dashboard',
                country_code: localStorage.getItem('order-country')
            }
        });
    }

    navigateTo(id) {
        this.router.navigate(['orders'], {
            queryParams: {
                price_rule_id: id
            }
        })
    }

    ngOnDestroy() {
        if (this.listenerFn) {
            this.listenerFn();
        }
    }

     isOutBondCampaign(channel_id) {
        let otherThanSocial_ids = [1, 2, 3]
        let value = false
        if (otherThanSocial_ids.includes(channel_id)) {
            value = true
        } else {
            value = false
        }
        return value
    }

    redirectToOrder(order_id){
        const queryParams = { order_id: order_id,campaign_id:this.statics.campaign.id,campaign_name:this.statics.campaign.name};

        // Navigate to the '/orders' route with query parameters
        this.router.navigate(['/orders'], { queryParams: queryParams });
    }
}





