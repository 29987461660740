import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { SharedService } from "../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "./api.service";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class PageService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    getPageTemplate(): Observable<any> {
        return this.apiService.get(this.sharedService.pageTemplate)
    }

    getElements(type = "", limit = '10') {
        let params = new HttpParams();
        params = params
            .set("type", type)
            .set("limit", limit)


        return this.apiService.get(
            this.sharedService.pageElements,
            params,
            undefined
        );
    }
    // page methodes
    getPages(limit = 999, offset = 0, search = "", form?) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        return this.apiService.get(
            this.sharedService.pageList,
            params,
            undefined
        );
    }

    addPages(form) {
        return this.apiService.post(
            this.sharedService.addPage,
            form,
            undefined
        );
    }
    deletePage(id) {
        return this.apiService.delete(
            this.sharedService.pageList + `${id}/`,
            undefined
        );
    }
    getPageDetail(id) {
        return this.apiService.get(
            this.sharedService.pageDetail + `${id}/`,
            undefined
        );
    }
    updatePage(form, id) {
        return this.apiService.put(
            this.sharedService.updatePage + `${id}/`,
            form,
            undefined
        );
    }
    deletePageItems(id) {
        return this.apiService.delete(
            this.sharedService.deletePageItems + `${id}/`,
            undefined
        );
    }
    // DynamicField
    getDynamicFieldList(limit = 10, offset = 0, search = "", form: any = "") {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        return this.apiService.get(
            this.sharedService.getDynamicList,
            params,
            undefined
        );
    }
    disableDynamicField(id) {
        return this.apiService.get(
            this.sharedService.disableDynamicField + `${id}/`,
            undefined
        );
    }
    deleteDynamicField(id) {
        return this.apiService.delete(
            this.sharedService.disableDynamicField + `${id}/`,
            undefined
        );
    }
    enableDynamicField(id) {
        return this.apiService.get(
            this.sharedService.enableDynamicField + `${id}/`,
            undefined
        );
    }

    getDynamicDetail(id) {
        return this.apiService.get(
            this.sharedService.getDynamicList + `${id}/`,
            undefined
        );
    }

    addDynamicFields(form) {
        return this.apiService.post(
            this.sharedService.getDynamicList,
            form,
            undefined
        );
    }
    updateDynamicField(form, id) {
        return this.apiService.put(
            this.sharedService.getDynamicList + `${id}/`,
            form,
            undefined
        );
    }
    getModels() {
        return this.apiService.get(
            this.sharedService.getDynamicModels,
            undefined
        );
    }
    //   product page methodes

    getProductPageDetails(id, object_type) {

        let params = new HttpParams();
        params = params
            .set(object_type, id)


        return this.apiService.get(
            this.sharedService.productPageDetails,
            params
        );
    }

    getProductPageInfo(id, object_type) {

        let params = new HttpParams();
        params = params
            .set("id", id)

        return this.apiService.get(this.sharedService.products, params);
    }

    addProductPage(form, id, object_type) {
        let updatedForm = { ...form }; // Create a copy of the original form

        // Determine the object type and update the page accordingly
        if (object_type === 'category') {
            updatedForm.page = {
                ...updatedForm.page,
                category: id
            };
        } else if (object_type === 'brand') {
            updatedForm.page = {
                ...updatedForm.page,
                brand: id
            };
        } else {
            updatedForm.page = {
                ...updatedForm.page,
                product: id
            };
        }

        return this.apiService.post(
            this.sharedService.createProductPage,
            updatedForm,
            undefined
        );
    }

    updateProductPage(form, id, productPageID, object_type) {
        let updatedForm = { ...form }; // Create a copy of the original form

        // Determine the object type and update the page accordingly
        if (object_type === 'category') {
            updatedForm.page = {
                ...updatedForm.page,
                category: id
            };
        } else if (object_type === 'brand') {
            updatedForm.page = {
                ...updatedForm.page,
                brand: id
            };
        } else {
            updatedForm.page = {
                ...updatedForm.page,
                product: id
            };
        }

        return this.apiService.put(
            this.sharedService.updateProductPage + `${productPageID}/`,
            updatedForm,
            undefined
        );
    }

    deleteProductPageItems(id) {
        return this.apiService.delete(
            this.sharedService.deleteProductPageElement + `${id}/`,
            undefined
        );
    }
}
