import { Pipe, PipeTransform } from '@angular/core';
import { ConditionsSections } from '../types';

@Pipe({
    name: 'checkAvailability'
})
export class CheckAvailabilityPipe implements PipeTransform {

    transform(value: Array<any>, ...args: unknown[]): unknown {
        let item;
        let id = args[0];
        let section = args[1];

        switch (section) {
            case ConditionsSections.Products:
                item = value.filter(el => id === el.id);
                console.log(item, value, id)
                return value.some(el => id === el.id) && item[0]?.from;
        }
    }

}
