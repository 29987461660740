import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslationService } from "app/services/translationService";
import { FabAnimations } from "./fab.animation";

export interface FabButton {
    icon: string;
    tooltip: string;
}

export enum SpeedDialFabPosition {
    Top = "top",
    Bottom = "bottom",
    Left = "left",
    Right = "right",
}
@Component({
    selector: "app-fab",
    templateUrl: "./fab.component.html",
    styleUrls: ["./fab.component.scss"],
    animations: FabAnimations,
})
export class FabComponent implements OnInit {
    @Input() selectedElement: any = "";
    @Input("reverse-column-direction") reverseColumnDirection: boolean = false;
    @Input("buttons") fabButtons = [];
    @Output("fabClick") fabClick = new EventEmitter();
    buttons = [];
    fabTogglerState = "inactive";

    constructor(public translationService: TranslationService) {}

    ngOnInit(): void {
        this.selectButtons(this.selectedElement);
    }

    selectButtons(id) {
        if (this.fabButtons.length > 0) {
            this.fabButtons.forEach((data, index) => {
                if (data.id == id) {
                    this.fabButtons[index].selected = "highlight-bg-color";
                }
            });
        }
    }

    private showItems() {
        this.fabTogglerState = "active";
        this.buttons = this.fabButtons;
        
    }

    private hideItems() {
        this.fabTogglerState = "inactive";
        this.buttons = [];
    }

    public onToggleFab() {
        this.buttons.length ? this.hideItems() : this.showItems();
    }

    public onClickFab(btn: { icon: string }) {
        this.hideItems();
        this.fabClick.emit(btn);
    }
}
