import { ChangeDetectorRef, Component, Inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppService } from "app/services/app.service";
import { CatalogueService } from "app/services/catalogue/catalogue.service";
import { UtilitiesService } from "app/services/utilitiesService";
interface Currency {
  value: string; // Unique value for the currency (e.g., 'USD', 'EUR')
  label: string; // Display name for the currency (e.g., 'US Dollar', 'Euro')
}

@Component({
  selector: "app-create-origin",
  templateUrl: "./create-origin.component.html",
  styleUrls: ["./create-origin.component.scss"],
})
export class CreateOriginComponent implements OnInit {
  form: FormGroup;
  currencyList: Currency[] = [];
  id: any;
  title: string;
  oldData: any = null;

  country: any;
  currencyForm: any;
  constructor(
    private dialogRef: MatDialogRef<CreateOriginComponent>,
    private fb: FormBuilder,
    private catalogueService: CatalogueService,
    private appService: AppService,
    private utilitiesService: UtilitiesService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.oldData = data;
      this.title = "Edit Country of Origin";
      this.cdr.markForCheck();
      console.log(data);
    } else {
      this.title = "Create Country of Origin";
    }
  }

  ngOnInit(): void {
    this.formInit();
    this.getCurrencies();
  }

  formInit() {
    this.form = this.fb.group({
      id: this.oldData?.id ? this.oldData.id : "",
      name: [this.oldData?.name ? this.oldData.name : "", Validators.required],
      name_ar: this.oldData?.name_ar ? this.oldData.name_ar : "",
      code: ["", Validators.required], // Add code field with required validation
      isd_code: ["", Validators.required], // Add ISD code field with required validation
      phone_digits: ["", Validators.required], // Add phone digits field with required validation
      currency: ["", Validators.required], // Add currency field with required validation
    });
  }

  async getCurrencies() {
    try {
      const currency = await this.appService.getCurrencies().toPromise();
      if (currency) {
        this.currencyList = currency.map((c) => {
          return {
            label: c.code,
            value: c.id,
          };
        });
      }
    } catch {
    } finally {
    }
  }

  async saveOrUpdate() {
    let form = this.form.value;
    if (this.form.valid) {
      if (this.oldData?.id) {
        console.log("update");
        await this.update(form);
      } else {
        await this.save(form);
      }
    }
  }
  async update(form) {
    this.utilitiesService.startLoader();
    await this.catalogueService.updateOrigin(form, this.oldData.id).subscribe((res) => {
      console.log("update");
      console.log(res);
      if (res) {
        this.utilitiesService.stopLoader();
        let msg = "Origin Updated successfully.";
        this.utilitiesService.showSuccessToast(msg);
        this.dialogRef.close(true);
      }
    });
    this.utilitiesService.stopLoader();
  }
  async save(form) {
    this.utilitiesService.startLoader();
    await this.catalogueService.addOrigin(form).subscribe((res) => {
      console.log(res);
      if (res) {
        this.utilitiesService.stopLoader();
        let msg = "Origin saved successfully.";
        this.utilitiesService.showSuccessToast(msg);
        this.dialogRef.close(true);
      }
    });
    this.utilitiesService.stopLoader();
  }

  handleCancel() {
    this.dialogRef.close();
  }
}
