<div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
    <form fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em"
        [formGroup]="form">
        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                <mat-spinner diameter="50" style="left: 47%;top: 50%;position: relative;" *ngIf="loading"
                    strokeWidth="5">
                </mat-spinner>
                <div fxFlex="0 0  auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                            <mat-form-field appearance="outline" fxFlex="100%">
                                <mat-label>{{'Channel' | translate}}</mat-label>
                                <mat-select (selectionChange)="ChannelChange($event.value)" formControlName="channel">
                                    <mat-option [value]="1">{{'EMAIL' | translate}}</mat-option>
                                    <mat-option [value]="2">{{'SMS' | translate}}</mat-option>
                                    <mat-option [value]="3">{{'PUSH' | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                            <mat-form-field appearance="outline" fxFlex="100" *ngIf="form.get('channel').value!=2">
                                <mat-label class="text-capitalized">
                                    {{'Title'| translate}}
                                </mat-label>
                                <input formControlName="title" matInput name="title">
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="100%" *ngIf="form.get('channel').value!=1">
                                <mat-label>{{'Message' | translate}}</mat-label>
                                <textarea formControlName="message" rows="8" matInput></textarea>
                                <mat-error *ngIf="form.get('message').hasError('required')">
                                    {{'Message is required' | translate}}
                                </mat-error>
                            </mat-form-field>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column">
                                <ckeditor id="ck" *ngIf="form.get('channel').value==1" formControlName="message_email"
                                    ngDefaultControl name="myckeditor1" [config]="ckeConfig">
                                </ckeditor>
                                <small style="margin-left: 0px !important;color: #f44336 !important;"
                                    *ngIf="form.get('message_email').hasError('required') && form.get('channel').value==1">
                                    {{'Message is required' | translate}}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="1 1 auto" fxLayoutAlign="flex-end stretch" fxLayout="row" fxLayoutGap="1em">
                        <button (click)="send()" type="submit" mat-flat-button class="primary-bg-color">
                            <mat-icon class="save-cancel-btn mr-8" matPrefix>send</mat-icon>{{'Send' | translate}}
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </form>
</div>
