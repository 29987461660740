import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ChangeDetectorRef,
} from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { first } from "rxjs/operators";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";

@Component({
    selector: "app-delivery-settings",
    templateUrl: "./delivery-settings.component.html",
    styleUrls: ["./delivery-settings.component.scss"],
    animations: fuseAnimations,
})
export class DeliverySettingsComponent implements OnInit, AfterViewInit {
    @Input() selectedBlock: any;
    @Input() appDetail: any;
    @Output() back: EventEmitter<void> = new EventEmitter<any>();
    @Output() saveHander: EventEmitter<void> = new EventEmitter<any>();
    deliveryForm: FormGroup;
    area = [];
    deliveryMode = [];
    deliveryModeDropDown = [];
    countryDropDown = [];
    currencyDropDown = [];
    areaDropDown = [];
    currency = [];
    country = [];
    deliveryModeSettings = {};
    areaSettings = {};
    currencySettings = {};
    countryModeSettings = {};
    numRegex = /^-?\d*[.,]?\d{0,2}$/;
    loading: boolean = true;
    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private appService: AppService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService
    ) {
        // this.utilitiesService.autoSave.pipe(first()).subscribe((data) => {
        //     if (data == 4) {
        //         this.save();
        //     }
        // });
        this.getDropdowndata();
    }
    formInitialize() {
        this.deliveryForm = this.fb.group({
            delivery_modes: [[]],
            has_delivery: [
                this.appDetail.has_delivery
                    ? this.appDetail.has_delivery
                    : false,
            ],
            has_delivery_charges: [
                this.appDetail.has_delivery_charges
                    ? this.appDetail.has_delivery_charges
                    : false,
            ],
            charges: [this.appDetail.charges ? this.appDetail.charges : 0.0],
            // countries: [null, [Validators.required]],
            areas: [[]],
            restrict_area: [
                this.appDetail.restrict_area
                    ? this.appDetail.restrict_area
                    : false,
            ],
        });
    }
    ngAfterViewInit(): void {
        document.getElementById("toolbar").scrollIntoView();
    }

    ngOnInit(): void {
        this.formInitialize();
        this.deliveryModeSettings = {
            text: "Select Delivery Mode",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            enableSearchFilter: true,
            classes: "myclass custom-class",
        };
        this.areaSettings = {
            text: "Select Area",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            enableSearchFilter: true,
            classes: "myclass custom-class",
        };
        this.countryModeSettings = {
            text: "Select Country",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            enableSearchFilter: true,
            classes: "myclass custom-class",
        };
    }

    async getDropdowndata() {
        this.utilitiesService.startLoader()
        await this.getArea();
        // await this.getCountries();
        await this.getDeliveryMode();
        this.utilitiesService.stopLoader()
    }
    async getArea() {
        try {
            const area = await this.appService.getArea().toPromise();
            if (area) {
                this.area = area;
                this.area.forEach((data) => {
                    this.areaDropDown.push({
                        id: data.id,
                        itemName: data.name,
                    });
                });
                this.areaDropDown = [...this.areaDropDown];
                if (this.appDetail.selected_areas.length > 0) {
                    let areaIDs = [];
                    const areas = this.appDetail.selected_areas;
                    areas.forEach((items) => {
                        areaIDs.push({
                            id: items.id,
                            itemName: items.name,
                        });
                    });
                    this.deliveryForm.controls.areas.setValue(areaIDs);
                }
            }
        } catch {
        } finally {
        }
    }
    async getCountries() {
        try {
            const country = await this.appService.getCountries().toPromise();
            if (country) {
                this.country = country;
                this.country.forEach((data) => {
                    this.countryDropDown.push({
                        id: data.id,
                        itemName: data.name,
                    });
                });
                this.countryDropDown = [...this.countryDropDown];
                if (this.appDetail.countries.length > 0) {
                    let countryIDs = [];
                    const country = this.appDetail.countries;
                    country.forEach((items) => {
                        countryIDs.push({
                            id: items.id,
                            itemName: items.name,
                        });
                    });
                    this.deliveryForm.controls.countries.setValue(countryIDs);
                }
            }
        } catch {
        } finally {
        }
    }
    async getDeliveryMode() {
        try {
            const delivery = await this.appService
                .getDeliveryMode()
                .toPromise();
            if (delivery) {
                this.deliveryMode = delivery.results;
                this.deliveryMode.forEach((data) => {
                    this.deliveryModeDropDown.push({
                        id: data.id,
                        itemName: data.name,
                    });
                });

                this.deliveryModeDropDown = [...this.deliveryModeDropDown];
                if (this.appDetail.selected_delivery_modes.length > 0) {
                    let delivery_modesIDs = [];
                    const delivery_modes = this.appDetail.selected_delivery_modes;
                    delivery_modes.forEach((items) => {
                        delivery_modesIDs.push({
                            id: items.id,
                            itemName: items.name,
                        });
                    });
                    this.deliveryForm.controls.delivery_modes.setValue(
                        delivery_modesIDs
                    );
                }
            }
        } catch {
            this.utilitiesService.stopLoader()
        } finally {
            this.deliveryForm.valueChanges.subscribe((data) => {
                this.utilitiesService.formChangedOrNot(true);
            });
            this.utilitiesService.stopLoader()
        }
    }

    goBack() {
        let fromApp = true;
        if (!this.utilitiesService.formChanged$.value) {
            this.back.emit();
        } else {
            let content = `You have unsaved changes, Please save before changing the page`;
            let heading = "Alert";
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp == "yes") {
                } else if (resp == "no") {
                    this.utilitiesService.formChangedOrNot(false);
                    this.back.emit();
                }
            });
        }
    }
    save() {
        if (this.deliveryForm.valid) {
            let content = `Are you sure, Do you want to update? `;
            let heading = "Update";
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                this.utilitiesService.autoSave.next(0);
                if (resp) {
                    let form = this.deliveryForm.value;
                    let areaIDs = [];
                    let deliveryModeIDs = [];
                    let countryIDs = [];
                    if (form.areas) {
                        Object.keys(form.areas).forEach((key) => {
                            areaIDs.push(form.areas[key].id);
                        });
                        form.areas = areaIDs;
                    }
                    if (form.delivery_modes) {
                        Object.keys(form.delivery_modes).forEach((key) => {
                            deliveryModeIDs.push(form.delivery_modes[key].id);
                        });
                        form.delivery_modes = deliveryModeIDs;
                    }
                    // if (form.countries) {
                    //     Object.keys(form.countries).forEach((key) => {
                    //         countryIDs.push(form.countries[key].id);
                    //     });
                    //     form.countries = countryIDs;
                    // }
                    const resp = await this.appService
                        .addAppSettings(form)
                        .toPromise();

                    if (resp) {
                        this.utilitiesService.formChangedOrNot(false);
                        let successMsg = this.translate.instant(
                            "Field Settings updated successfully"
                        );
                        this.utilitiesService.showSuccessToast(successMsg);
                        this.saveHander.emit();
                    }
                }
            });
        }
    }
}
