import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { ChatPanelComponent } from "app/layout/components/chat-panel/chat-panel.component";
import { CampaignsService } from "app/services/marketing/campaigns.service";
import { CartService } from "app/services/orders/cart.service";
import { OrderService } from "app/services/orders/order.service";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { ViewMediaComponent } from "../view-media/view-media.component";
import { findFlagUrlByCountryName } from "country-flags-svg";
import { TranslateService } from "@ngx-translate/core";
import { SendNotificationComponent } from "../send-notification/send-notification.component";
import { CustomerService } from "app/services/customer/customer.service";
import { CoreService } from "app/services/core-service";
import { PageService } from "app/services/pageService";
import * as moment from "moment";
import { DynamicFieldsComponent } from "../../pages/my-shops/dynamic-fields/dynamic-fields.component";
import { OrderStatus } from "app/main/types";
import { ChangeOrderStatusComponent } from "../change-order-status/change-order-status.component";
import { EditProductComponent } from "../edit-product/edit-product.component";
import { fuseAnimations } from "@fuse/animations";

@Component({
    selector: "app-order-details",
    templateUrl: "./order-details.component.html",
    styleUrls: ["./order-details.component.scss"],
    animations: fuseAnimations,
})
export class OrderDetailsComponent implements OnInit {
    @ViewChild(ChatPanelComponent)
    ChatPanelComponent: ChatPanelComponent;
    @Input() cart: boolean = true;
    // @Input() id: any = "";
    id: any;
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    cartDetail: any = "";
    countryFlag = "";
    isLinear = true;
    title = {
        title_en: "Order Detail",
        title_ar: "بيانات الطلب",
    };
    opt: boolean = false;
    optShowRemark: boolean = false;
    trackingData: any;
    displayedColumns = [
        "image",
        "name",
        "sku",
        "quantity",
        "unit_price",
        "price",
    ];
    selectedBundle: any = null;
    dynamicFieldList = [];
    isEditProduct: boolean = false;
    isDisabled: boolean = false;
    selectedTabIndex = 0;
    isDisabledCart: boolean;
    
    constructor(
        private router: Router,
        private cartService: CartService,
        private orderService: OrderService,
        public utilitiesService: UtilitiesService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private campaignsService: CampaignsService,
        private translate: TranslateService,
        public translationService: TranslationService,
        private customerService: CustomerService,
        public coreService: CoreService,
        private pageService: PageService,
        private cdref: ChangeDetectorRef
    ) {
        this.route.paramMap.subscribe((params: ParamMap) => {
            this.id = +params.get("id");
            this.cart = false;
        });
        this.title.title_en = "Order Details";
        this.title.title_ar = "بيانات الطلب";
        // this.id = this.route.snapshot.paramMap.get("id");
        console.log("route");
        console.log(this.id);
    }

    async ngOnInit(): Promise<void> {
        this.utilitiesService.startLoader();

        await this.getDynamicFieldList();
        // await this.getPaymentStatus();
        if (this.cart) {
            await this.getCartDetail();
            this.selectedTabIndex = this.isDisabled ? 1 : 0;
            this.cdref.detectChanges();
        } else {
            await this.getOrdersDetail();
            this.selectedTabIndex = this.isDisabled ? 1 : 0;
            this.cdref.detectChanges();
        }
    }
    handleCancel() {
        this.router.navigate([`orders`]);
    }
    SavePdf(id) {
        this.orderService.getOrderPdf(id);
    }
    SaveInvoicePdf(id) {
        this.orderService.getInvoicePdf(id);
    }
    SavePicklistPdf(id) {
        this.orderService.getPicklistPdf(id);
    }

    ChangeStatus(id, order_status) {
        console.log(id);
        console.log(order_status);
        let orderId = id;

        // let itemList = this.cartList;
        let isMobile = this.utilitiesService.isMobile();
        let size = {
            width: "45vw",
            height: "35vh",
        };
        if (isMobile) {
            size.height = "100%";
            size.width = "100%";
        }
        const dialogRef = this.dialog.open(ChangeOrderStatusComponent, {
            data: { orderId, order_status },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });

        dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
                console.log("resp");
                console.log(resp);
                try {
                    if (this.cart) {
                        await this.getCartDetail();
                    } else {
                        await this.getOrdersDetail();
                    }
                } catch {
                    console.log("somthing went wrong");
                } finally {
                }
            }
        });
    }
    editProduct(Id, items, status) {
        if (status != 7) {
            let orderId = Id;

            // let itemList = this.cartList;
            let isMobile = this.utilitiesService.isMobile();
            let size = {
                width: "75vw",
                height: "75vh",
            };
            if (isMobile) {
                size.height = "100%";
                size.width = "100%";
            }
            const dialogRef = this.dialog.open(EditProductComponent, {
                data: { orderId, items },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });

            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp) {
                    console.log("resp");
                    console.log(resp);
                    try {
                        if (this.cart) {
                            await this.getCartDetail();
                        } else {
                            await this.getOrdersDetail();
                        }
                    } catch {
                        console.log("somthing went wrong");
                    } finally {
                    }
                }
            });
        }
    }
    async trackOrder() {
        console.log("in  trackOrder");
        if (this.cartDetail && this.cartDetail.awb) {
            try {
                console.log("in  trackOrder try");
                let awb = this.cartDetail.awb;
                let app_id = localStorage.getItem("app_id")
                    ? localStorage.getItem("app_id")
                    : 1;

                let trackingData = await this.cartService
                    .orderTracking(app_id, awb)
                    .toPromise();

                this.trackingData = trackingData;
                console.log(this.trackingData.order);
            } catch {
                this.trackingData = null;
            } finally {
            }
        }
    }
    async checkStatus(id) {
        try {
            // this.utilitiesService.startLoader();
            const refresh = await this.orderService.checkStatus(id).toPromise();
            if (refresh) {
                let successMsg = refresh.message_en;
                this.utilitiesService.showSuccessToast(successMsg);
                this.utilitiesService.stopLoader();
                // this.getCartList();
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
    showRoleOpt(cartDetail: any) {
        if (cartDetail.adjusted_balance != 0) {
            this.opt = !this.opt;
            cartDetail.showRoleOptions = this.opt;
        }

        // console.log(cartDetail);
    }
    showTransactionRemark(transaction: any) {
        this.optShowRemark = !this.optShowRemark;
        transaction.showRemark = this.optShowRemark;

        console.log(transaction);
    }
    async getOrdersDetail() {
        try {
            console.log("in get order details");
            const cartDetail = await this.orderService
                .getOrderDetail(this.id)
                .toPromise();
            if (cartDetail) {
                this.cartDetail = cartDetail;
                const orderId = this.cartDetail.external_order_id
                    ? this.cartDetail.external_order_id
                    : "";
                const slash = orderId ? " / " : "";
                this.title.title_en =
                    "Order Details # " +
                    this.cartDetail?.invoice_no +
                    slash +
                    orderId;
                this.title.title_ar =
                    " بيانات الطلب # " +
                    this.cartDetail?.invoice_no +
                    slash +
                    orderId;
                await this.setFlag();
                this.isDisabled = cartDetail.items.length <= 0 ? true : false;
                this.isDisabledCart =
                    cartDetail.cart_items.length <= 0 ? true : false;
                this.selectedTabIndex = this.isDisabled ? 1 : 0;
                this.cdref.detectChanges();
                console.log(this.isDisabled);
                console.log("in get order details call trackOrder");
                await this.trackOrder();
            }
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }
    async getCartDetail() {
        try {
            const cartDetail = await this.cartService
                .getCartDetail(this.id)
                .toPromise();
            if (cartDetail) {
                this.cartDetail = cartDetail;
                const orderId = this.cartDetail.external_order_id
                    ? this.cartDetail.external_order_id
                    : "";
                const slash = orderId ? " / " : "";

                this.title.title_en =
                    " Order Details #" +
                    this.cartDetail?.invoice_no +
                    slash +
                    orderId;
                this.title.title_ar =
                    "بيانات الطلب #" +
                    this.cartDetail?.invoice_no +
                    slash +
                    orderId;
                this.selectedTabIndex = this.isDisabled ? 1 : 0;
                this.isDisabled = cartDetail.items.length <= 0 ? true : false;
                this.isDisabledCart =
                    cartDetail.cart_items.length <= 0 ? true : false;
                console.log(this.isDisabled);
                this.cdref.detectChanges();
                this.setFlag();
            }
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }
    tabchanged(event) {
        console.log(event);
        this.selectedTabIndex = event.index;
        this.cdref.detectChanges();
    }
    async setFlag() {
        if (this.cartDetail.country.name || this.cartDetail.country.name_en) {
            let name: string = this.cartDetail.customer
                ? this.cartDetail.customer.contact_no_country
                    ? this.cartDetail.customer.contact_no_country.name
                    : null
                : null;

            let name1 = name.toLowerCase();
            this.countryFlag = findFlagUrlByCountryName(name1);
        }
    }

    getDynamicValues(name, value) {
        try {
            let values = [];
            if (this.dynamicFieldList.length > 0 && name) {
                let dynamicArray = this.dynamicFieldList.filter(
                    (data) => data.name == name
                );
                if (dynamicArray.length > 0) {
                    if (
                        dynamicArray[0].type == "checkbox" ||
                        dynamicArray[0].type == "radio" ||
                        dynamicArray[0].type == "select"
                    ) {
                        let possibleValues = dynamicArray[0].possible_values;
                        if (
                            possibleValues.length > 0 &&
                            value instanceof Array
                        ) {
                            value.forEach((item) => {
                                let itemArray = possibleValues.filter(
                                    (items) => items.id == item
                                );
                                if (itemArray.length > 0) {
                                    if (
                                        this.translationService.currentLang ==
                                        "en"
                                    ) {
                                        values.push(itemArray[0].name_en);
                                    } else {
                                        values.push(itemArray[0].name_ar);
                                    }
                                }
                            });
                        } else if (possibleValues.length > 0 && !isNaN(value)) {
                            let selectArray = possibleValues.filter(
                                (items) => items.id == value
                            );
                            if (selectArray.length > 0) {
                                if (
                                    this.translationService.currentLang == "en"
                                ) {
                                    values.push(selectArray[0].name_en);
                                } else {
                                    values.push(selectArray[0].name_ar);
                                }
                            }
                        }
                    } else if (dynamicArray[0].type == "date") {
                        if (value) {
                            let date = new Date(value);
                            values.push(moment(date).format("DD/MM/YYYY"));
                        } else {
                            values.push(value);
                        }
                    } else {
                        values.push(value);
                    }
                }
            }
            return values;
        } catch {
        } finally {
        }

        // return this.sanitizer.bypassSecurityTrustHtml(values.toString());
    }
    getDynamicFieldName(name) {
        let label = "";
        if (this.dynamicFieldList.length > 0 && name) {
            let labelArray = this.dynamicFieldList.filter(
                (data) => data.name == name
            );
            if (labelArray.length > 0) {
                label =
                    this.translationService.currentLang == "en"
                        ? labelArray[0].label_en
                        : labelArray[0].label_ar;
            }
        }
        return label ? label : name;
    }

    isDynamicEmpty() {
        if (this.cartDetail) {
            return Object.keys(this.cartDetail.dynamic_fields).length === 0
                ? false
                : true;
        }
    }

    isCustomerDynamicEmpty() {
        if (this.cartDetail && this.cartDetail.customer) {
            return Object.keys(this.cartDetail.customer.dynamic_fields)
                .length === 0
                ? false
                : true;
        }
    }

    async getDynamicFieldList(limit = 999999, offset = 0) {
        try {
            const dynamicField = await this.pageService
                .getDynamicFieldList(limit, offset)
                .toPromise();
            if (dynamicField) {
                this.dynamicFieldList = dynamicField.results;
            }
        } catch {
        } finally {
            // this.utilitiesService.stopLoader();
        }
    }
    openImg(url) {
        let img = url;
        let media_title = "";
        const dialogRef = this.dialog.open(ViewMediaComponent, {
            data: { media_title, img },
            maxWidth: "100%",
            width: "100%",
            height: "100%",
        });
        dialogRef.afterClosed().subscribe((resp) => {});
    }
    open() {
        this.ChatPanelComponent.unfoldSidebarTemporarily();
    }

    async sendNotification() {
        let customerID = this.cartDetail.customer.id;
        let isSMS = true;
        let isMobile = this.utilitiesService.isMobile();
        let size = {
            width: "60vw",
            height: "60vh",
        };
        if (isMobile) {
            size.height = "100%";
            size.width = "100%";
        }
        const dialogRef = this.dialog.open(SendNotificationComponent, {
            data: { isSMS, customerID },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });
        dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
                try {
                } catch {
                } finally {
                }
            }
        });
    }
}
