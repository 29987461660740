import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MediaTypes } from "app/constants/media-type";
import { GallerySlidesComponent } from "../gallery-slides/gallery-slides.component";

@Component({
    selector: "app-video-play",
    templateUrl: "./video-play.component.html",
    styleUrls: ["./video-play.component.scss"],
})
export class VideoPlayComponent implements OnInit {
    @Input() type: any = "video";
    @Input() id: any;
    @Input() index = 0;
    @Input() src: any;
    @Input() isEnableOperations: boolean = true;
    @Input() name:any

    audio = new Audio();
    mediaType = MediaTypes;
    constructor(private dialog: MatDialog) {}

    get isPlayed() {
        return !this.audio.paused;
    }
    ngOnInit(): void {}

    viewMedia() {
        if (this.isEnableOperations) {
            let id = this.id;
            let index = this.index;
            const dialogRef = this.dialog.open(GallerySlidesComponent, {
                data: { id, index },
                maxWidth: "100%",
                width: "100%",
                height: "100%",
            });
            dialogRef.afterClosed().subscribe((resp) => {});
        }
    }

    startAudio() {
        if (this.isEnableOperations) {
            this.audio.src = this.src;
            this.audio.load();
            this.audio.play();
        }
    }

    pauseAudio() {
        if (this.isEnableOperations) {
            this.audio.pause();
        }
    }
}
