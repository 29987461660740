import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { first } from "rxjs/operators";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";

@Component({
    selector: "app-mypadydo-settings",
    templateUrl: "./mypadydo-settings.component.html",
    styleUrls: ["./mypadydo-settings.component.scss"],
    animations: fuseAnimations,
})
export class MypadydoSettingsComponent implements OnInit, AfterViewInit {
    @Input() selectedBlock: any;
    @Input() appDetail: any;
    @Output() back: EventEmitter<void> = new EventEmitter<any>();
    @Output() saveHander: EventEmitter<void> = new EventEmitter<any>();
    paydoForm: FormGroup;
    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private appService: AppService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService
    ) {
        // this.utilitiesService.autoSave.pipe(first()).subscribe((data) => {
        //     if (data == 8) {
        //         this.save();
        //     }
        // });
    }

    ngOnInit(): void {
        this.formInitialize();
    }

    formInitialize() {
        this.paydoForm = this.fb.group({
            token: [this.appDetail.token ? this.appDetail.token : null],
            account: [this.appDetail.account ? this.appDetail.account : null],
            source_app: [
                this.appDetail.source_app ? this.appDetail.source_app : null,
            ],
            root_url: [
                this.appDetail.root_url ? this.appDetail.root_url : null,
            ],
            cdn_url: [this.appDetail.cdn_url ? this.appDetail.cdn_url : null],
            show_tax_inc_label: [this.appDetail.show_tax_inc_label ? this.appDetail.show_tax_inc_label : false],
            show_tax: [this.appDetail.show_tax ? this.appDetail.show_tax : false]

        });
        this.paydoForm.valueChanges.subscribe((data) => {
            this.utilitiesService.formChangedOrNot(true);
        });
    }
    goBack() {
        let fromApp = true;
        if (!this.utilitiesService.formChanged$.value) {
            this.back.emit();
        } else {
            let content = `You have unsaved changes, Please save before changing the page`;
            let heading = "Alert";
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp == "yes") {
                } else if (resp == "no") {
                    this.utilitiesService.formChangedOrNot(false);
                    this.back.emit();
                }
            });
        }
    }
    save() {
        let content = `Are you sure, Do you want to update? `;
        let heading = "Update";
        let fromApp=false
        let size = this.utilitiesService.isMobileAlertModal();
        const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading ,fromApp},
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });
        dialogRef.afterClosed().subscribe(async (resp) => {
            this.utilitiesService.autoSave.next(0)
            if (resp) {
                let form = this.paydoForm.value;
                const resp = await this.appService
                    .addAppSettings(form)
                    .toPromise();

                if (resp) {
                   
                    this.utilitiesService.formChangedOrNot(false);
                    let successMsg = this.translate.instant(
                        "MyPaydo settings updated successfully"
                    );
                    this.utilitiesService.showSuccessToast(successMsg);
                    this.saveHander.emit();
                }
            }
        });
    }
    ngAfterViewInit(): void {
        document.getElementById("toolbar").scrollIntoView();
    }
}
