<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" style="margin-top: 40px;">
    <div fxShow fxHide.lt-md fxFlex="0 0 auto" class="fuse-card fuse-card-container primary-bg-color"
        style="border-radius: 0px !important;" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <div fxFlex="0 0 auto" class="p-16 pb-0 pl-8" fxLayoutAlign="start stretch" fxLayout="column">
            <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                <mat-icon class="config-icon">settings</mat-icon>
                <label>{{'App Configurations' | translate}}</label>
            </div>
        </div>
        <ng-container *ngFor="let app of this.appItemsArray.appItems;let last =last;let first = first">
            <div *ngIf="!app.hidden" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                class="card-divider m-0" [ngClass]="last ? 'no-border-bottom' :  first ? 'no-border-top' : ''">
                <div fxFlex="0 0 auto" class="pt-16 pb-16" fxLayoutAlign="start stretch" fxLayout="column"
                    [ngClass]="app.selected" (click)="selectElement(app.id)">
                    <div fxFlex="0 0 auto" class="pl-8" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="10px">
                        <mat-icon>{{app.icon}}</mat-icon>
                        <label class="font-weight-900">{{this.translationService.getTranslatedName(app)}}</label>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <app-fab fxHide fxShow.lt-md class="speed-dial-container float" [selectedElement]="selectElementForFab"
        (fabClick)="fabClick($event)" [ngClass]="speedDialFabPosition"
        [reverse-column-direction]="speedDialFabPosition===SpeedDialFabPosition.Bottom" [buttons]="speedDialFabButtons">
    </app-fab>
</div>