<div fxLayout="column" fxFlex="100" *ngIf="heading != 'Reject'">
    <div
        class="m-0 p-0 pb-3 mb-3"
        fxFlex="0 0 auto"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="5px"
    >
        <mat-icon style="color: red" *ngIf="fromApp">warning</mat-icon>
        <div style="color: red; font-size: 24px">
            {{ heading }}
        </div>
    </div>
    <div class="mb-6 mt-6 pt-6 pb-6 text-center">
        <span>{{ content }} </span>
    </div>

    <ng-container *ngIf="!isCustomer; else customerDialog">
        <ng-container *ngIf="!fromApp && !isAffiliatePage">
            <ng-container [ngTemplateOutlet]="closTemplate" [ngTemplateOutletContext]="{ closeData: true }">
            </ng-container>
        </ng-container>

        <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="center" fxLayoutGap="5px"
            *ngIf="!fromApp && isAffiliatePage">
            <div>
                <button mat-button color="primary" [mat-dialog-close]="true"
                    class="mat-focus-indicator mat-raised-button mat-button-base mat-accent">
                    <mat-icon class="save-cancel-btn" matPrefix>check</mat-icon>
                    {{'Ok' | translate}}
                </button>
            </div>
        </div>
    </ng-container>

    <div
        fxLayout.gt-sm="row"
        fxLayout.lt-md="column"
        fxFlex="0 0 auto"
        fxLayoutAlign.gt-sm="center"
        fxLayoutAlign.lt-md="stretch center"
        fxLayoutGap="10px"
        *ngIf="fromApp"
    >
        <div>
            <button (click)="no()" mat-stroked-button color="warn">
                {{ "Discard Changes" | translate }}
                <mat-icon class="save-cancel-btn" matSuffix
                    >navigate_next</mat-icon
                >
            </button>
        </div>
        <div>
            <button
                mat-button
                color="primary"
                (click)="yes()"
                class="mat-focus-indicator mat-raised-button mat-button-base mat-accent"
            >
                <mat-icon class="save-cancel-btn" matPrefix>check</mat-icon>
                {{ "Stay Here" | translate }}
            </button>
        </div>
    </div>
</div>

<ng-template #customerDialog>
    <input matInput placeholder="Block Reason" [(ngModel)]="reason" class="reason-input" required>
    <ng-container [ngTemplateOutlet]="closTemplate" [ngTemplateOutletContext]="{ closeData: reason }">
    </ng-container>
</ng-template>

<ng-template #closTemplate let-closeData="closeData">
    <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="center" fxLayoutGap="5px">
        <button [mat-dialog-close]="false" mat-stroked-button color="warn">
            <mat-icon class="save-cancel-btn" matPrefix>close</mat-icon> {{'No' | translate}}
        </button>
        <button mat-button color="primary" [mat-dialog-close]="closeData" [disabled]="!closeData"
            class="mat-focus-indicator mat-raised-button mat-button-base mat-accent">
            <mat-icon class="save-cancel-btn" matPrefix>check</mat-icon>
            {{'Yes' | translate}}
        </button>
    </div>
</ng-template>
