import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    OnInit,
    Output,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { NavMenuService } from "app/services/nav-menu.service";
import { PageService } from "app/services/pageService";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
import { SliderService } from "app/services/sliderService";
import { OrderService } from "app/services/orders/order.service";

@Component({
    selector: "app-edit-product",
    templateUrl: "./edit-product.component.html",
    styleUrls: ["./edit-product.component.scss"],
})
export class EditProductComponent implements OnInit {
    oldItems: any;
    orderId: any;
    form: any;
    expandIndex = [];
    last_status: any;
    productForm: any;
    isSumbit: boolean;
    isLoading: boolean;
    allProducts: any;
    name = "Name";
    request: any = { items: [] };

    get productFormArray() {
        return this.form.get("productForm") as FormArray;
    }
    constructor(
        private dialog: MatDialog,
        private appService: AppService,
        private sliderService: SliderService,
        private translate: TranslateService,
        private navMenuService: NavMenuService,
        private pageService: PageService,
        public sanitizer: DomSanitizer,
        private orderService: OrderService,
        private ngZone: NgZone,
        private cdr: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private utilitiesService: UtilitiesService,

        private translationService: TranslationService,
        public dialogRef: MatDialogRef<EditProductComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data) {
            this.orderId = data.orderId ? data.orderId : null;
            this.oldItems = data.items ? data.items : "";
        }
    }
    setProductFormArrayValue(orderItem) {}
    async ngOnInit(): Promise<void> {
        await this.formInitialize();
    }
    async formInitialize() {
        this.form = this.formBuilder.group({
            id: [this.orderId],
            productForm: this.formBuilder.array([]),
        });
        this.setValue();
    }
    async setValue() {
        try {
            let count = 0;
            if (this.oldItems.length > 0) {
                this.utilitiesService.startLoader();
                (this.form.get("productForm") as FormArray).clear();
                this.oldItems.forEach((data, index) => {
                    count = count + 1;

                    this.addProductItem(data, index);
                    this.utilitiesService.stopLoader();
                });
                if (this.oldItems.length == count) {
                    this.utilitiesService.stopLoader();
                }
            } else {
                (this.form.get("productForm") as FormArray).clear();
            }
            this.utilitiesService.stopLoader();
        } catch {
            this.utilitiesService.stopLoader();
        }
    }
    InitializeFormproduct(productData?) {
        var disabled: boolean = false;
        console.log(productData);
        return this.formBuilder.group({
            quantity: [
                productData?.total_quantity
                    ? productData?.total_quantity
                    : null,
                [Validators.required],
            ],
            product_id: [
                productData?.product?.id ? productData?.product?.id : null,

                [Validators.required],
            ],
        });
    }

    addProductItem(productData?, index?) {
        // if (!productData) {
        //     console.log("addProductItem");
        //     (this.form.get("productForm") as FormArray).push(
        //         this.InitializeFormproduct()
        //     );
        //     console.log(this.form.get("productForm"));
        // }

        // this.isSumbit = false;
        (this.form.get("productForm") as FormArray).push(
            this.InitializeFormproduct(productData)
        );

        // this.utilitiesService.stopLoader();
    }
    deleteProductItem(index, id) {
        (this.form.get("productForm") as FormArray).removeAt(index);
        document.getElementById("toolbar").scrollIntoView();
    }
    checkInvalid(index) {
        if (
            (this.form.get("productForm") as FormArray).length > 0 &&
            this.isSumbit
        ) {
            return (this.form.get("productForm") as FormArray).at(index).valid;
        } else {
            return true;
        }
    }
    async checkFormIsValidOrNot() {
        this.isSumbit = true;
        let formValid = false;
        let invalidDataIndex = [];

        if (
            (this.form.get("productForm") as FormArray) &&
            (this.form.get("productForm") as FormArray).length > 0
        ) {
            (this.form.get("productForm") as FormArray).controls.forEach(
                (data, index) => {
                    if (data.invalid) {
                        invalidDataIndex.push(index);
                    }
                }
            );
            if (invalidDataIndex.length > 0) {
                formValid = false;
            } else {
                formValid = true;
            }
            invalidDataIndex.forEach((index) => {
                console.log(index);

                this.productFormArray.at(index).get("quantity").markAsTouched();
                if (this.productFormArray.at(index).get("product_id").invalid) {
                    this.utilitiesService.showErrorToast(
                        "Product name is required"
                    );
                }

                this.expandIndex[index] = index;
            });
        } else {
            formValid = true;
        }
        return formValid;
    }
    async save() {
        let productRuleForm = this.form.value;
        let formValid = await this.checkFormIsValidOrNot();
        console.log("productRuleForm");
        console.log(productRuleForm);
        console.log(formValid);
        if (formValid) {
            let rules = [];
            if (productRuleForm.productForm.length > 0) {
                rules = productRuleForm.productForm.map((data, index) => {
                    this.request.items.push({ ...data });
                    // return { ...data };
                });
            }
            // console.log(this.request);
            let content = `Are you sure, Do you want to Save it ? `;
            let heading = "Save";
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                this.utilitiesService.autoSave.next(0);

                if (resp) {
                    this.utilitiesService.startLoader();
                    if (formValid) {
                        const respsonse = await this.orderService
                            .updateOrderStatus(this.orderId, this.request)
                            .toPromise();
                        console.log("response");
                        console.log(respsonse);
                        if (respsonse) {
                            console.log("updateOrderStatus");
                            console.log(respsonse);
                            // rules = [];
                            this.request.items = [];
                            this.utilitiesService.showSuccessToast(
                                "successMsg updateOrderStatus"
                            );
                            this.dialogRef.close(true);
                        } else if (respsonse.error) {
                            this.utilitiesService.showErrorToast(
                                respsonse.message
                            );
                            this.dialogRef.close(false);
                        }
                    }

                    // this.getCountryRules();
                    this.utilitiesService.stopLoader();
                }
            });
            this.utilitiesService.stopLoader();
        }
    }

    close() {
        this.dialogRef.close(null);
    }
}
