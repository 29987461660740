
import {
    ChartComponent,
    ApexOptions,
    ApexNonAxisChartSeries,
    ApexResponsive,
    ApexAxisChartSeries,
    ApexChart,
    ApexXAxis,
    ApexDataLabels,
    ApexTitleSubtitle,
    ApexStroke,
    ApexGrid,
    ApexFill,
    ApexYAxis,
    ApexPlotOptions,
    ApexLegend,
    ApexTooltip,
    ApexMarkers
} from "ng-apexcharts";

export type PieChartOptions = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    responsive: ApexResponsive[];
    labels: any;
    legend: ApexLegend;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    tooltip: ApexTooltip;
};

export type LineChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    dataLabels: ApexDataLabels;
    grid: ApexGrid;
    stroke: ApexStroke;
    title: ApexTitleSubtitle;
    legend: ApexLegend;
    yaxis: ApexYAxis;
};

export type VerticleBarChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    yaxis: ApexYAxis;
    xaxis: ApexXAxis;
    fill: ApexFill;
    title: ApexTitleSubtitle;
    legend: ApexLegend;
    colors: string[]
};

export type HorizontalBarOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    xaxis: ApexXAxis;
    legend: ApexLegend;

};


export type AreaChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    stroke: ApexStroke;
    dataLabels: ApexDataLabels;
    yaxis: ApexYAxis;
    title: ApexTitleSubtitle;
    labels: string[];
    legend: ApexLegend;
    subtitle: ApexTitleSubtitle;
}

export type MixedChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    yaxis: ApexYAxis | ApexYAxis[];
    labels: any;
    stroke: ApexStroke;
    markers: ApexMarkers;
    fill: ApexFill;
    tooltip: ApexTooltip;
}

export enum Platforms {
    UNKNOWN = 0,
    IOS = 1,
    ANDROID = 2,
    WEBSITE = 3,
    IOS_WEB = 4,
    ANDROID_WEB = 5,
}

export enum ChartsType {
    Area = 'areaChart',
    HorizontalBar = 'horizontalBarChart',
    Line = 'lineChart',
    Pie = 'pieChart',
    VerticalBar = 'verticleBarChart',
    Map = 'mapChart',
    Mixed = 'mixedChart'
}
