<div [@animate]="{ value: '*', params: { duration: '400ms', y: '70px' } }">
    <div>
        <div class="ace_editor">
            <div
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="0px"
            >
                <div
                    fxLayoutAlign="start center"
                    class="mt-12 mb-12 pb-8 border-bottom"
                    fxLayout="row"
                    fxLayoutGap="4px"
                >
                    <div
                        fxLayoutAlign="center center"
                        fxLayout="row"
                        fxLayoutGap="4px"
                    >
                        <label
                            class="highlight-text-color pl-5 text-4xl text-bold"
                            >{{ "Import File Details" | translate }}</label
                        >
                    </div>
                </div>
                <ng-container>
                    <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        class="p-16"
                        fxLayout="column"
                        fxLayoutGap="1em"
                    >
                        <div
                            fxFlex="0 0 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout="column"
                            fxLayoutGap="1em"
                        >
                            <div
                                fxFlex="0 0 auto"
                                fxLayoutAlign="start self-start"
                                fxLayout="row"
                                fxLayoutGap="0px"
                            >
                                <i
                                    class="fas fa-check-circle highlight-text-color"
                                ></i>
                                <div>
                                    {{ "importing" | translate }}
                                    {{ dryResponse.item_count }}
                                    {{
                                        "records with the following fields :"
                                            | translate
                                    }}
                                </div>
                            </div>
                            <div
                                class="grey-text-color ml-5"
                                fxFlex="0 0 auto"
                                fxLayoutAlign="start self-start"
                                fxLayout="column"
                                fxLayoutGap="0px"
                                *ngIf="
                                    dryResponse.unknown_fields.length != 0 ||
                                    dryResponse.unknown_fields != undefined
                                "
                            >
                                <label
                                    class="mb-15 ml-7 pb-15 action-btn-delete"
                                >
                                    {{ dryResponse.unknown_fields.length }}
                                    {{
                                        "unknown fields in import file :"
                                            | translate
                                    }}</label
                                >

                                <div
                                    *ngFor="
                                        let unknown_fields of dryResponse.unknown_fields
                                    "
                                    class="ml-8 pl-4"
                                    fxFlex="0 0 auto"
                                    fxLayoutAlign="start self-start"
                                    fxLayout="row"
                                    fxLayoutGap="0px"
                                >
                                    <i
                                        class="fas fa-times grey-text-color"
                                        style="color: red !important"
                                    ></i>
                                    <div class="action-btn-delete">
                                        {{ unknown_fields }}
                                    </div>
                                </div>
                            </div>
                            <div
                                class="grey-text-color ml-5"
                                fxFlex="0 0 auto"
                                fxLayoutAlign="start self-start"
                                fxLayout="column"
                                fxLayoutGap="0px"
                                *ngIf="
                                    dryResponse.column_names.length != 0 ||
                                    dryResponse.column_names != undefined
                                "
                            >
                                <label class="mb-15 ml-7 pb-15 grey-text-color">
                                    {{ dryResponse.column_names.length }}
                                    {{
                                        "known fields in import file :"
                                            | translate
                                    }}</label
                                >

                                <div
                                    *ngFor="
                                        let column_names of dryResponse.column_names
                                    "
                                    class="ml-8 pl-4"
                                    fxFlex="0 0 auto"
                                    fxLayoutAlign="start self-start"
                                    fxLayout="row"
                                    fxLayoutGap="0px"
                                >
                                    <i
                                        class="fas fa-check-circle highlight-text-color"
                                    ></i>
                                    <div class="highlight-text-color">
                                        {{ column_names }}
                                    </div>
                                </div>
                            </div>
                            <!-- <div
                                class="grey-text-color ml-5"
                                fxFlex="0 0 auto"
                                fxLayoutAlign="start self-start"
                                fxLayout="column"
                                fxLayoutGap="0px"
                                *ngIf="
                                    dryResponse.column_names.length != 0 ||
                                    dryResponse.column_names != undefined
                                "
                            >
                                <div
                                    fxFlex="0 0 auto"
                                    fxLayoutAlign="start self-start"
                                    fxLayout="row"
                                    fxLayoutGap="0px"
                                >
                                    <div
                                        class="ml-8 pl-4"
                                        fxFlex="0 0 auto"
                                        fxLayoutAlign="start self-start"
                                        fxLayout="row"
                                        fxLayoutGap="0px"
                                        *ngFor="
                                            let column_names of dryResponse.column_names
                                        "
                                    >
                                        <i
                                            class="fas fa-check-circle highlight-text-color"
                                        ></i>
                                        <div>{{ column_names }}</div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </ng-container>
                <ng-container class="mt-12 mb-12 pb-8">
                    <div
                        class="m-12"
                        fxFlex="0 0 auto"
                        fxLayout="row"
                        fxLayoutAlign="flex-end center"
                        fxLayoutGap="1em"
                    >
                        <button
                            (click)="back()"
                            mat-flat-button
                            class="secondary-bg-color save-cancel-btn"
                        >
                            {{ "Reset" | translate }}
                        </button>
                        <button
                            (click)="excute()"
                            type="submit"
                            mat-flat-button
                            class="primary-bg-color save-cancel-btn"
                        >
                            {{ "Save" | translate }}
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
