import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { first } from "rxjs/operators";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";

@Component({
    selector: "app-support-settings",
    templateUrl: "./support-settings.component.html",
    styleUrls: ["./support-settings.component.scss"],
    animations: fuseAnimations,
})
export class SupportSettingsComponent implements OnInit, AfterViewInit {
    @Input() selectedBlock: any;
    @Input() appDetail: any;
    @Output() back: EventEmitter<void> = new EventEmitter<any>();
    @Output() saveHander: EventEmitter<void> = new EventEmitter<any>();
    supportForm: FormGroup;
    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private appService: AppService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService
    ) {
        // this.utilitiesService.autoSave.pipe(first()).subscribe((data) => {
        //     if (data == 12) {
        //         this.save();
        //     }
        // });
    }

    ngOnInit(): void {
        this.formInitialize();
    }

    formInitialize() {
        this.supportForm = this.fb.group({
            enable_support: [
                this.appDetail.enable_support
                    ? this.appDetail.enable_support
                    : false,
            ],
            support_message: [
                this.appDetail.support_message
                    ? this.appDetail.support_message
                    : null,
            ],
            support_message_en: [
                this.appDetail.support_message_en
                    ? this.appDetail.support_message_en
                    : null,
            ],
            support_message_ar: [
                this.appDetail.support_message_ar
                    ? this.appDetail.support_message_ar
                    : null,
            ],
            support_contact_no: [
                this.appDetail.support_contact_no
                    ? this.appDetail.support_contact_no
                    : null,
            ],
            support_email: [
                this.appDetail.support_email
                    ? this.appDetail.support_email
                    : null,
            ],
            support_address: [
                this.appDetail.support_address
                    ? this.appDetail.support_address
                    : null,
            ],
        });

        this.supportForm.valueChanges.subscribe((data) => {
            this.utilitiesService.formChangedOrNot(true);
        });
    }
    radioChange(event) {
        const value = event.value;
        this.supportForm.controls.isPaydo_Account.setValue(value);
    }
    goBack() {
        let fromApp = true;
        if (!this.utilitiesService.formChanged$.value) {
            this.back.emit();
        } else {
            let content = `You have unsaved changes, Please save before changing the page`;
            let heading = "Alert";
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp == "yes") {
                } else if (resp == "no") {
                    this.utilitiesService.formChangedOrNot(false);
                    this.back.emit();
                }
            });
        }
    }
    save() {
        let content = `Are you sure, Do you want to update? `;
        let heading = "Update";
        let fromApp = false;
        let size = this.utilitiesService.isMobileAlertModal();
        const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });
        dialogRef.afterClosed().subscribe(async (resp) => {
            this.utilitiesService.autoSave.next(0);
            if (resp) {
                let form = this.supportForm.value;
                const resp = await this.appService
                    .addAppSettings(form)
                    .toPromise();

                if (resp) {
                    this.utilitiesService.formChangedOrNot(false);
                    let successMsg = this.translate.instant(
                        "Support settings updated successfully"
                    );
                    this.utilitiesService.showSuccessToast(successMsg);
                    this.saveHander.emit();
                }
            }
        });
    }
    ngAfterViewInit(): void {
        document.getElementById("toolbar").scrollIntoView();
    }
}
