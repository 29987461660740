<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" [formGroup]="form">
            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back</mat-icon>
                    <label class="highlight-text-color">{{'Terms and Conditions' | translate}}</label>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <button (click)="save()" type="submit" mat-flat-button style="position: absolute;right: 16px;"
                        class="primary-bg-color">{{'Save' | translate}}</button>
                </div>
            </div>
            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                [@animate]="{value:'*',params:{duration:'400ms',y:'70px'}}">
                <div fxFlex="0 0 auto" class="fuse-card auto-width p-16" fxLayoutAlign="start stretch" fxLayout="column"
                    fxLayoutGap="0px">
                    <label class="highlight-text-color">{{'Terms and Conditions' | translate}}</label>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label class="text-capitalized">
                                {{'Terms and Condition Type'| translate}}
                            </mat-label>
                            <mat-select formControlName="terms_condition">
                                <mat-option [value]="1">{{'Explicit' | translate}}</mat-option>
                                <mat-option [value]="2">{{'Implicit' | translate}}</mat-option>
                                <mat-option [value]="3">{{'Disabled' | translate}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div fxFlex="1 1 auto" *ngIf="form.get('terms_condition').value!='3'" fxLayout="column"
                            fxLayoutGap="5px">
                            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between center">
                                <label>{{'Terms and conditions' | translate}}</label>
                                <mat-form-field appearance="outline" fxLayoutAlign="flex-end flex-end">
                                    <mat-select formControlName="language">
                                        <mat-option value="en">{{'EN' | translate}}</mat-option>
                                        <mat-option value="ar">{{'AR' | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <ckeditor *ngIf="form.get('language').value =='en'"
                                [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}" formControlName="terms"
                                ngDefaultControl name="myckeditor1" [config]="ckeConfig">
                            </ckeditor>
                            <ckeditor *ngIf="form.get('language').value =='ar'"
                                [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}" formControlName="terms_ar"
                                ngDefaultControl name="myckeditor1" [config]="ckeConfig">
                            </ckeditor>
                        </div>
                        <small>*{{'Explicit' | translate}} : {{'help_text_terms_explicit' | translate}}</small>
                        <small>*{{'Implicit' | translate}} : {{'help_text_terms_implicit' | translate}}</small>
                        <small>*{{'Disabled' | translate}} : {{'help_text_terms_disabled' | translate}}</small>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>