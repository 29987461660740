import { Component, OnInit } from "@angular/core";
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    Router,
} from "@angular/router";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { navigation } from "app/navigation/navigation";
import { AuthService } from "app/services/auth.service";
import { CoreService } from "app/services/core-service";
import { UtilitiesService } from "app/services/utilitiesService";
import { locale as english } from "assets/i18n/en";
import { locale as arabic } from "assets/i18n/ar";
import { AppRoutes } from "app/routes";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";

@Component({
    selector: "app-auth",
    templateUrl: "./auth.component.html",
    styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit {
    constructor(
        private _fuseConfigService: FuseConfigService,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        public utilitiesService: UtilitiesService,
        private router: Router,
        private coreService: CoreService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private fuseSplashScreenService: FuseSplashScreenService
    ) {
        let snapshot = activatedRoute.snapshot;
        let tokenObject = snapshot.queryParams;
        let token = tokenObject.token;
        // this.utilitiesService.setAuthorizationToken(token);
        console.log(token);
        if (token) {
            this.validateToken(token);
        }
    }
    removeLablesRecursive(parent) {
        for (let innerChild of parent.children) {
            if (innerChild.children) {
                this.removeLablesRecursive(innerChild);
            }
            this._fuseNavigationService.updateNavigationItem(
                `${innerChild.id}`,
                {
                    hidden: false,
                }
            );
            this._fuseNavigationService.updateNavigationItem(`${parent.id}`, {
                hidden: false,
            });
        }
    }
    async revertNavigation() {
        let currentNav = [...navigation];
        currentNav.forEach((parent) => {
            this.removeLablesRecursive(parent);
        });
    }

    async validateToken(token) {
        this.authService
            .validateToken(token)
            .then(async (response) => {
                this.coreService.isLogin.next(true);
                // this.fuseSplashScreenService.show()
                let res: any = response;
                await this.revertNavigation();
                await this.utilitiesService.setAuthorizationToken(
                    res.access_token
                );
                await this.refreshPermission();
                if (res.app_id) {
                    localStorage.setItem("app_id", res.app_id);
                }
                this.router.navigate([AppRoutes.Home]);
            })
            .catch((err) => {
                this.utilitiesService.showErrorToast(
                    "Authentication Failed, Please login"
                );
                this.router.navigate([AppRoutes.Login]);
            });
    }

    async refreshPermission() {
        this.coreService.refreshPermission().subscribe((data) => {
            let resp: any = data;
            this.coreService.setPermission(resp.permissions);
        });
    }
    ngOnInit(): void {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }
}
