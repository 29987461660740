import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-progress-bar",
    templateUrl: "./progress-bar.component.html",
    styleUrls: ["./progress-bar.component.scss"],
})
export class ProgressBarComponent implements OnInit {
    @Input() progress: any = 0;
    barProperties = {
        barType: "linear",
        color: "#0e90d2",
        secondColor: "#D3D3D3",
        linear: {
            depth: 22,
            stripped: true,
            active: true,
            label: {
                enable: false,
                value: "Linear Bar",
                color: "#fff",
                fontSize: 10,
                showPercentage: true,
            },
        },
    };

    constructor() {}

    ngOnInit(): void {}

    getPercentage() {
        return +this.progress != 100 ? true : false;
    }
}
