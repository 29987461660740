<div
    fxFlex="1 1 100%"
    fxLayoutAlign="start stretch"
    fxLayout.lt-md="column"
    fxLayout.gt-sm="column"
    fxLayoutGap="1em"
>
    <div class="heading">
        {{ "Add Page Redirection" }}
    </div>
    <form
        fxFlex="1 1 100%"
        fxLayoutAlign="start stretch"
        fxLayout.lt-md="column"
        fxLayout.gt-sm="row"
        fxLayoutGap="1em"
        [formGroup]="form"
    >
        <div
            fxFlex="1 1 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="1em"
        >
            <div
                fxFlex="0 0 auto"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="0px"
            >
                <div
                    fxFlex="0 0  auto"
                    class="fuse-card auto-width p-16 center-container"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="1em"
                >
                    <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="16px"
                    >
                        <div
                            fxFlex="0 0 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout="column"
                            fxLayoutGap="0px"
                        >
                            <div
                                fxFlex="0 0 auto"
                                fxLayoutAlign="start stretch"
                                fxLayout="column"
                                fxLayoutGap.gt-sm="5px"
                                fxLayoutGap.lt-md="0px"
                            >
                                <div
                                    fxFlex="0 0 auto"
                                    fxLayoutAlign="start stretch"
                                    fxLayout.lt-md="column"
                                    fxLayout.gt-sm="row"
                                    fxLayoutGap.gt-sm="5px"
                                    fxLayoutGap.lt-md="0px"
                                >
                                    <mat-form-field
                                        appearance="outline"
                                        fxFlex="100"
                                    >
                                        <mat-label class="text-capitalized">
                                            {{ "Old Path" | translate }}
                                        </mat-label>
                                        <input
                                            matInput
                                            name="title"
                                            formControlName="old_path"
                                        />
                                        <!-- <mat-error
                                            *ngIf="
                                                form
                                                    .get('old_path')
                                                    .hasError('required')
                                            "
                                        >
                                            {{
                                                "Old path is required"
                                                    | translate
                                            }}
                                        </mat-error> -->
                                    </mat-form-field>
                                    <mat-form-field
                                        appearance="outline"
                                        fxFlex="100"
                                    >
                                        <mat-label class="text-capitalized">
                                            {{ "New Path" | translate }}
                                        </mat-label>
                                        <input
                                            matInput
                                            name="title"
                                            formControlName="new_path"
                                        />
                                        <!-- <mat-error
                                            *ngIf="
                                                form
                                                    .get('new_path)
                                                    .hasError('required')
                                            "
                                        >
                                            {{
                                                "New path is required"
                                                    | translate
                                            }}
                                        </mat-error> -->
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        fxFlex="1 1 auto"
                        fxLayoutAlign.gt-sm="end center"
                        fxLayoutAlign.lt-md="start stretch"
                        fxLayout.lt-md="column-reverse"
                        fxLayout.gt-sm="row"
                        fxLayoutGap="5px"
                    >
                        <button mat-stroked-button (click)="handleCancel()">
                            <mat-icon>clear</mat-icon>
                            {{ "Cancel" | translate }}
                        </button>
                        <button
                            (click)="saveOrUpdate()"
                            type="submit"
                            mat-flat-button
                            class="primary-bg-color"
                        >
                            <mat-icon>save</mat-icon>
                            {{ "Save" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
