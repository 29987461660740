<div class="navbar-top primary-bg-color">

    <div class="logo">
        <img class="logo-icon"  src="assets/images/natureCart/nature-logo.png">
        <!-- <span class="logo-text">{{translationService.getTranslatedTitle(getTitle())}}</span> -->
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon>menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon>arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container primary-bg-color" fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <!-- <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

        <div class="h3 username">Charlie Adams</div>
        <div class="h5 email hint-text mt-8">adams.charlie@mail.com</div>
        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <img class="avatar" src="assets/images/avatars/Velazquez.jpg">
        </div>

    </div> -->

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>