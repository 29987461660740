<div *ngIf="!timelineDeliveryData" fxLayout="column" fxFlex="1 1 auto">
    <div
        fxLayout="column"
        fxFlex="1 1 auto"
        *ngFor="let timeline of timelineData; let last = last"
    >
        <div
            fxLayout="row"
            fxFlex="0 0 auto"
            fxLayoutAlign="space-between"
            fxLayoutGap="1em"
        >
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2em">
                <div
                    class="badge"
                    fxFlex="0 0 auto"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                >
                    <mat-icon>check</mat-icon>
                </div>
                <ion-label
                    fxFlex="0 0 auto"
                    class="text-body-2 text-bold text-server"
                >
                    {{
                        translationService.getTranslatedField(timeline, "name")
                    }}</ion-label
                >
            </div>

            <div
                fxLayout="column"
                fxFlex="0 0 auto"
                fxLayoutAlign="start flex-end"
            >
                <ion-label
                    fxFlex="0 0 auto"
                    class="text-caption-xl text-bold text-server"
                    color="medium"
                >
                    {{ timeline?.order_status | orderstatus }}
                </ion-label>
                <ion-label
                    fxFlex="0 0 auto"
                    class="text-caption-xl text-bold text-server"
                    color="medium"
                >
                    {{ timeline?.history_date | date : "HH:mm" }}
                </ion-label>

                <ion-label
                    fxFlex="0 0 auto"
                    class="text-body-2 text-server"
                    color="dark"
                >
                    {{
                        timeline?.history_date | date : "dd/MM/yyyy"
                    }}</ion-label
                >
            </div>
        </div>
        <div fxFlex="0 0 auto" *ngIf="!last" class="dotted-line"></div>
    </div>
</div>
<div *ngIf="timelineDeliveryData" fxLayout="column" fxFlex="1 1 auto">
    <div
        fxLayout="column"
        fxFlex="1 1 auto"
        *ngFor="let timeline of timelineDeliveryData; let last = last"
    >
        <div
            fxLayout="row"
            fxFlex="0 0 auto"
            fxLayoutAlign="space-between"
            fxLayoutGap="1em"
        >
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2em">
                <div
                    class="badge"
                    fxFlex="0 0 auto"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                >
                    <mat-icon>check</mat-icon>
                </div>
                <ion-label
                    fxFlex="0 0 auto"
                    class="text-body-2 text-bold text-server"
                >
                    {{ timeline?.delivery_status }}
                </ion-label>
            </div>

            <div
                fxLayout="column"
                fxFlex="0 0 auto"
                fxLayoutAlign="start flex-end"
            >
                <ion-label
                    fxFlex="0 0 auto"
                    class="text-caption-xl text-bold text-server"
                    color="medium"
                >
                    {{ timeline?.history_change_reason }}
                </ion-label>
                <ion-label
                    fxFlex="0 0 auto"
                    class="text-caption-xl text-bold text-server"
                    color="medium"
                >
                    {{ timeline?.history_date | date : "HH:mm" }}
                </ion-label>

                <ion-label
                    fxFlex="0 0 auto"
                    class="text-body-2 text-server"
                    color="dark"
                >
                    {{
                        timeline?.history_date | date : "dd/MM/yyyy"
                    }}</ion-label
                >
            </div>
        </div>
        <div fxFlex="0 0 auto" *ngIf="!last" class="dotted-line"></div>
    </div>
</div>
