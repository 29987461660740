<div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch">
    <div
        id="dashboard-project"
        class="page-layout simple right-sidebar"
        fxLayout="column"
        [@animate]="{ value: '*', params: { duration: '400ms', y: '70px' } }"
    >
        <div
            class="center"
            fxFlex="1 1 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="1em"
            fusePerfectScrollbar
        >
            <div
                class="content p-16"
                fxFlex="1 1 auto"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="1em"
            >
                <div
                    *ngIf="!isView && !isViewCustomer"
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="0px"
                >
                    <div
                        fxLayout="row"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="end end"
                        fxLayoutGap="1em"
                    >
                        <div
                            fxLayout="row"
                            fxFlex="0 0 auto"
                            fxLayoutAlign="start start"
                            fxLayoutGap="1em"
                            [form]="form"
                        >
                            <app-clear-filter
                                (clearFilterHandler)="clearDate()"
                                [form]="form"
                                (clearFilterHandler)="clearFilter()"
                                class="mt-4"
                                [@animate]="{
                                    value: '*',
                                    params: { duration: '300ms', y: '50px' }
                                }"
                            >
                            </app-clear-filter>

                            <mat-form-field appearance="outline">
                                <mat-select
                                    [(ngModel)]="page.size"
                                    (selectionChange)="limitChange()"
                                >
                                    <mat-option [value]="5">5</mat-option>
                                    <mat-option [value]="10">10</mat-option>
                                    <mat-option [value]="25">25</mat-option>
                                    <mat-option [value]="50">50</mat-option>
                                    <mat-option [value]="100">100</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <app-common-filter
                                (searchInput)="search($event)"
                                fxHide.lt-md
                            ></app-common-filter>
                            <app-fab-button
                                [bottomMargin]="false"
                                [bgColorClass]="'primary-bg-color'"
                                (handleFabButtonClick)="addSticker()"
                                [tooltip]="'Add Sticker'"
                                [label]="'Add Sticker'"
                            >
                            </app-fab-button>
                        </div>
                    </div>
                    <div
                        fxLayout="row"
                        fxFlex="1 1 auto"
                        fxLayoutAlign="start start"
                        fxLayoutGap="1em"
                    >
                        <app-common-filter
                            (searchInput)="search($event)"
                            fxHide
                            fxShow.lt-md
                        ></app-common-filter>
                    </div>

                    <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="1em"
                        [formGroup]="form"
                    >
                        <ngx-datatable
                            (activate)="onActivateViewOrder($event)"
                            [loadingIndicator]="isLoading"
                            [externalPaging]="true"
                            [count]="page.totalElements"
                            [limit]="page.size"
                            (page)="pageChange($event)"
                            [offset]="page.pageNumber"
                            [virtualization]="false"
                            [scrollbarH]="true"
                            [rows]="stickersList"
                            [cssClasses]="iconsCss"
                            class="material striped mt-8"
                            [columnMode]="'force'"
                            [headerHeight]="90"
                            [rowHeight]="50"
                            [footerHeight]="'auto'"
                        >
                            <ngx-datatable-column
                                name="{{ 'No' | translate }}"
                                [width]="70"
                            >
                                <ng-template
                                    let-row="row"
                                    let-rowIndex="rowIndex"
                                    let-value="value"
                                    ngx-datatable-cell-template
                                >
                                    {{
                                        page.size * page.pageNumber +
                                            rowIndex +
                                            1
                                    }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                [width]="40"
                                name="{{ 'Thumbnail' | translate }}"
                                prop="icon"
                            >
                                <ng-template
                                    let-column="column"
                                    ngx-datatable-header-template
                                >
                                    <div fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer">
                                            {{ column.name }}
                                        </p>
                                    </div>
                                </ng-template>
                                <ng-template
                                    let-column="column"
                                    let-row="row"
                                    let-rowIndex="rowIndex"
                                    let-value="value"
                                    ngx-datatable-cell-template
                                >
                                    <div
                                        fxFlex="1 1 auto"
                                        fxLayoutAlign="start center"
                                        fxLayout="row"
                                    >
                                        <div class="img-container">
                                            <img
                                                *ngIf="row?.icon; else noImage"
                                                src="{{ row?.icon }}"
                                                alt=""
                                            />
                                            <ng-template #noImage
                                                >No Image</ng-template
                                            >
                                        </div>
                                        <!-- <button mat-icon-button (click)="openProduct(row?.id, row?.slug)">
                                        <mat-icon style="font-size: 20px" title="View Detail" matPostfix>visibility
                                        </mat-icon>
                                    </button> -->
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                [width]="100"
                                name="{{ 'Name' | translate }}"
                            >
                                <ng-template
                                    let-column="column"
                                    ngx-datatable-header-template
                                >
                                    <div fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer">
                                            {{ column.name }}
                                        </p>
                                        <input
                                            formControlName="name"
                                            type="text"
                                            class="form-control"
                                        />
                                    </div>
                                </ng-template>
                                <ng-template
                                    let-row="row"
                                    let-rowIndex="rowIndex"
                                    let-value="value"
                                    ngx-datatable-cell-template
                                >
                                    {{ row?.name }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                [width]="100"
                                name="{{ 'Arabic Name' | translate }}"
                            >
                                <ng-template
                                    let-column="column"
                                    ngx-datatable-header-template
                                >
                                    <div fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer">
                                            {{ column.name }}
                                        </p>
                                    </div>
                                </ng-template>
                                <ng-template
                                    let-row="row"
                                    let-rowIndex="rowIndex"
                                    let-value="value"
                                    ngx-datatable-cell-template
                                >
                                    {{ row?.name_ar }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                [width]="100"
                                name="{{ 'Text Color' | translate }}"
                            >
                                <ng-template
                                    let-row="row"
                                    let-rowIndex="rowIndex"
                                    let-value="value"
                                    ngx-datatable-cell-template
                                >
                                    <mat-icon
                                        *ngIf="row?.text_color"
                                        [cpPosition]="'bottom'"
                                        class="color-icon cursor-pointer p-3"
                                        [style.color]="row?.text_color"
                                    >
                                        color_lens
                                    </mat-icon>
                                    <span class="ml-5 mt-5 mb-5 p-3">
                                        {{
                                            row.text_color
                                                ? row.text_color
                                                : " - "
                                        }}
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                [width]="100"
                                name="{{ 'Background Color' | translate }}"
                            >
                                <ng-template
                                    let-row="row"
                                    let-rowIndex="rowIndex"
                                    let-value="value"
                                    ngx-datatable-cell-template
                                >
                                    <mat-icon
                                        *ngIf="row?.bg_color"
                                        [cpPosition]="'bottom'"
                                        class="color-icon cursor-pointer p-3"
                                        [style.color]="row?.bg_color"
                                    >
                                        color_lens
                                    </mat-icon>
                                    <span class="ml-5 mt-5 mb-5 p-3">
                                        {{
                                            row.bg_color ? row.bg_color : " - "
                                        }}
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                [width]="100"
                                name="{{ 'Product count' | translate }}"
                            >
                                <ng-template
                                    let-row="row"
                                    let-rowIndex="rowIndex"
                                    let-value="value"
                                    ngx-datatable-cell-template
                                >
                                    {{
                                        row.product_count
                                            ? row.product_count
                                            : " 0 "
                                    }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                                [width]="getWidth()"
                                name="{{ 'Actions' | translate }}"
                                [width]="100"
                            >
                                <ng-template
                                    let-row="row"
                                    let-rowIndex="rowIndex"
                                    let-value="value"
                                    ngx-datatable-cell-template
                                >
                                    <button
                                        mat-stroked-button
                                        mat-button
                                        [matMenuTriggerFor]="afterMenu"
                                    >
                                        {{ "Actions" | translate }}
                                        <mat-icon title="View Detail" matPostfix
                                            >keyboard_arrow_down</mat-icon
                                        >
                                    </button>
                                    <mat-menu
                                        #afterMenu="matMenu"
                                        xPosition="after"
                                    >
                                        <button
                                            class="action-btn-view"
                                            mat-menu-item
                                            (click)="editStickers(row)"
                                        >
                                            <mat-icon
                                                class="action-btn-view"
                                                title="Edit Slider"
                                                matPrefix
                                                >edit
                                            </mat-icon>
                                            {{ "Edit" | translate }}
                                        </button>
                                        <button
                                            class="action-btn-edit"
                                            mat-menu-item
                                            (click)="
                                                unbindProductsStickers(row?.id)
                                            "
                                        >
                                            <mat-icon
                                                class="action-btn-edit"
                                                title="Edit Slider"
                                                matPrefix
                                                >link_off
                                            </mat-icon>
                                            {{ "Unbind Stickers" | translate }}
                                        </button>

                                        <button
                                            class="action-btn-delete"
                                            mat-menu-item
                                            (click)="deleteStickers(row?.id)"
                                        >
                                            <mat-icon
                                                class="action-btn-delete"
                                                title="Delete Slider"
                                                matPrefix
                                                >delete
                                            </mat-icon>
                                            {{ "Delete" | translate }}
                                        </button>
                                    </mat-menu>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
