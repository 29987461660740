<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page footer-config">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form [formGroup]="form" fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">

            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back</mat-icon>
                    <label class="highlight-text-color">{{'Footer Configuration' | translate}}</label>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <label class="highlight-text-color">{{'Enable custom footer' | translate}}</label>
                    <mat-slide-toggle formControlName="show_custom_footer">

                    </mat-slide-toggle>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <button (click)="save()" type="submit" mat-flat-button style="position: absolute;right: 16px;"
                        class="primary-bg-color">{{'Save' | translate}}</button>
                </div>
            </div>

            <ng-container *ngIf="form.get('show_custom_footer').value">
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                    fxLayoutGap="1em">

                    <div fxFlex.lt-md="1 1 auto" fxFlex.gt-sm="0 0 50%" class="fuse-card auto-width p-16 "
                        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row"
                                fxLayoutGap="0px">
                                <label class="highlight-text-color">{{'Payment Icon' | translate}}</label>
                            </div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="0px">
                                    <div class="payment-container" *ngFor="let item of paymentIconDetails"
                                        fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="1em">
                                        <img [src]="item.icon" width="40">
                                        <mat-slide-toggle [formControlName]="item.controlName">
                                            <span>{{item.name}}</span>
                                        </mat-slide-toggle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <div fxFlex="1 1 auto" class="fuse-card auto-width p-16 " fxLayoutAlign="start stretch"
                            fxLayout="column" fxLayoutGap="1em">
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                                <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row"
                                    fxLayoutGap="0px">
                                    <label class="highlight-text-color">{{'Social Icon' | translate}}</label>
                                </div>
                                <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="10px">
                                    <div *ngFor="let icon of socialIconDetails" fxFlex="0 0 auto"
                                        fxLayoutAlign="start center" fxLayout="row" style="gap:10px;height: 35px;">

                                        <div class="social-icon">
                                            <i class="bi" [class]="icon.icon" [ngStyle]="{'color':icon.iconColor}"></i>
                                        </div>

                                        <mat-slide-toggle (change)="socialSlideToggleChange($event,icon)"
                                            [formControlName]="icon.toggleControl">
                                        </mat-slide-toggle>

                                        <mat-form-field *ngIf="form.get(icon.toggleControl).value" appearance="outline"
                                            fxFlex="100">
                                            <mat-label></mat-label>
                                            <input [formControlName]="icon.name" type="text" matInput>
                                        </mat-form-field>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

        </form>
    </div>
</div>