<div class="center fuse-card auto-width p-16 center-container" fxFlex="1 1 auto" fxLayoutAlign="start stretch"
    fxLayout="column" fxLayoutGap="0px">
    <div fxFlex="0 0 auto" fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="0px">
        <div *ngIf="channel=='EMAIL'" class="mat-title highlight-text-color">{{'Preview - Email' | translate}}</div>
        <div *ngIf="channel=='SMS'" class="mat-title highlight-text-color">{{'Preview - SMS' | translate}}</div>
        <div *ngIf="channel=='PUSH'" class="mat-title highlight-text-color">{{'Preview - PUSH' | translate}}</div>
        <mat-icon class="cursor-pointer" [mat-dialog-close]="false">close </mat-icon>
    </div>

    <div [formGroup]="form" fxFlex="1 1 auto" fxLayoutAlign="end end" fxLayout="column" fxLayoutGap="10px"
        style="margin:30px 0">
        <mat-form-field class="example-chip-list" appearance="outline" style="width:100%">
            <mat-chip-list #chipList>
                <mat-chip *ngFor="let item of items;let indx=index;" [selectable]="false" [removable]="true"
                    (removed)="remove(item,indx)">
                    {{item}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input placeholder="Type" #itemInput [formControl]="itemCtrl" [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="false"
                    (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
        </mat-form-field>
        <div fxFlex="1 1 auto" fxLayoutAlign="flex-end stretch" style="margin-top:20px" fxLayout.lt-md="column-reverse"
            fxLayout.gt-sm="row" fxLayoutGap="5px">
            <button (click)="sendTestCampaign()" mat-stroked-button type="button" mat-flat-button
                class="primary-bg-color" style="margin-bottom:30px">
                <mat-icon>send</mat-icon>
                {{'Send Test' | translate}}
            </button>
        </div>
    </div>
    <div *ngIf="channel=='EMAIL'" fxFlex="0 0 auto" class="fuse-card auto-width mail-custom p-16"
        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
        <div class="title">{{title ? title : "Subject"}}</div>
        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
            <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="16px"
                class="card-diver-bottom pb-8">
                <mat-icon class="user-icon">account_circle</mat-icon>
                <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="column" class="mt-8">
                    <strong>"From Address"</strong>
                    <small style="margin-left: -12px;">to "To Address"</small>
                </div>
            </div>
            <div style="padding: 10em;" *ngIf="!body" fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="column"
                fxLayoutGap="0px">
                <div *ngIf="!body">
                    <div class="mat-title" style="color: #ccc;">{{'Email Body' | translate}}</div>
                </div>
            </div>
            <div *ngIf="body" [innerHTML]="getBody()"></div>
        </div>
    </div>
    <div *ngIf="channel=='SMS'" fxFlex="0 0 auto" class="mail-custom p-16" fxLayoutAlign="start stretch"
        fxLayout="column" fxLayoutGap="0px">
        <div class="smartphone" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                <div class="mobile-top-bar" fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row"
                    fxLayoutGap="0px">
                    <div class="top-border-design"></div>
                    <div class="mobile-top-bar"></div>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row">
                        <mat-icon class="phone-icon">wifi</mat-icon>
                        <mat-icon class="phone-icon">
                            battery_charging_full</mat-icon>
                    </div>
                </div>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" style="padding: 2px;" fxLayout="column"
                fxLayoutGap="0px">
                <div fxFlex="0 0 auto" style="background: #b3b3b30f;padding: 6px;" fxLayoutAlign="start stretch"
                    fxLayout="row" fxLayoutGap="0px">
                    <div fxFlexAlign="start" fxFlex="0 0 auto" fxLayout="row" fxLayoutGap="0px">
                        <mat-icon class="highlight-text-color">arrow_back_ios</mat-icon>
                    </div>
                    <div fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayout="row">
                        <div style="margin-left: -25px;">Nature's Cart</div>
                    </div>
                </div>
            </div>
            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" style="padding: 2px;" fxLayout="column-reverse"
                fxLayoutGap="0px">
                <div class="top-nav-bar" fxFlex="0 0 auto" fxLayoutAlign="space-between center" fxLayout="row"
                    fxLayoutGap="3px">
                    <mat-icon>add_circle_outline</mat-icon>
                    <div>Text message</div>
                    <mat-icon>insert_emoticon</mat-icon>
                    <mat-icon>send</mat-icon>
                </div>
                <div class="message-blue">
                    <div class="message-content" *ngIf="body" [innerHTML]="getBody()"></div>
                    <div class="message-timestamp-left">{{time | date:'h:mm a'}}</div>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="3px">
                    <small style="color: #ccc;">Today</small>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="channel=='PUSH'" fxFlex="0 0 auto" class="mail-custom p-16" fxLayoutAlign="start stretch"
        fxLayout="column" fxLayoutGap="0px">
        <div class="smartphone"
            style="background-image: url('assets/images/backgrounds/bg-phone.jpg') !important;background-size: cover;"
            fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column">
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" style="padding: 2px;" fxLayout="column"
                style="color: white;">
                <div class="mobile-top-bar" fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row"
                    fxLayoutGap="0px">
                    <div class="top-border-design"></div>
                    <div class="mobile-top-bar"></div>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row">
                        <mat-icon class="phone-icon" style="color: white !important;">wifi</mat-icon>
                        <mat-icon class="phone-icon" style="color: white !important;">battery_charging_full</mat-icon>
                    </div>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" style="margin: 3px;">
                    <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="0px">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="column" fxLayoutGap="0px">
                            <div class="time">{{time | date:'h:mm a'}}</div>
                        </div>
                    </div>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                        <div fxFlex="0 0 auto" fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="0px">
                            <div>Notification Center</div>
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div fxFlex="0 0 auto" class="fuse-card auto-width" fxLayoutAlign="start stretch" fxLayout="column"
                        fxLayoutGap="0px"
                        style="border-radius: 5px !important;padding: 5px;background: #ffffff78 !important">
                        <div style="font-size: 10px;" fxFlex="0 0 auto" fxLayoutAlign="space-between center"
                            fxLayout="row" fxLayoutGap="0px">
                            <div style="color: white !important; ">Nature's Cart</div>
                            <small style="color: white !important; ">Now</small>
                        </div>
                        <div fxFlex="1 1 0px" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                            <div style="font-weight: bold !important;color: black !important">{{title}}</div>
                            <div style="color: black !important;" *ngIf="body" [innerHTML]="getBody()"></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>