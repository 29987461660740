import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IconCss } from "app/main/ngxDatatable-Icons/icons";
import { CoreService } from "app/services/core-service";
import { CustomerService } from "app/services/customer/customer.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { debounceTime } from "rxjs/operators";

@Component({
    selector: "app-customer-membership-table",
    templateUrl: "./customer-membership-table.component.html",
    styleUrls: ["./customer-membership-table.component.scss"],
})
export class CustomerMembershipTableComponent implements OnInit {
    @Input() customerID: any = "";
    @Output() countHandler: EventEmitter<void> = new EventEmitter<any>();
    isLoading: boolean = false;
    membershipList = [];
    icons = IconCss;
    isView: boolean = false;
    iconsCss = {
        pagerLeftArrow: this.icons.left,
        pagerRightArrow: this.icons.right,
        pagerPrevious: this.icons.previous,
        pagerNext: this.icons.next,
    };
    page = {
        size: 0,
        totalElements: 0,
        pageNumber: 0,
    };
    constructor(
        public utilitiesService: UtilitiesService,
        private customerService: CustomerService,
        public coreService: CoreService
    ) {
        this.page.pageNumber = 0;
        this.page.size = 10;
        this.page.totalElements = 0;
    }

    async ngOnInit(): Promise<void> {
        if (this.customerID) {
            // this.utilitiesService.startLoader();
            await this.getMembershipList();
        }
    }
    getWidth() {
        this.membershipList = [...this.membershipList];
        return 70;
    }

    async getMembershipList(
        limit = this.page.size,
        offset = 999999,
        form = undefined
    ) {
        try {
            this.isLoading = true;
            const membershipList = await this.customerService
                .getMembershipList(limit, offset, "", form, this.customerID)
                .toPromise();
            if (membershipList) {
                this.page.totalElements = membershipList.count;
                this.membershipList = membershipList.results;
                this.countHandler.emit(membershipList.count);
                this.isLoading = false;
            }
        } catch {
            this.isLoading = false;
        } finally {
        }
    }
}
