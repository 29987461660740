<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page" cdkDropListGroup>
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" [formGroup]="form"
            (cdkDropListDropped)="drop($event)" cdkDropList>
            <div fxLayout="column" fxFlex="0 0 auto" fxLayoutAlign="start stretch">
                <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="space-between start">
                    <label class="highlight-text-color">{{'List of Country Rules' | translate}}</label>
                    <app-fab-button (handleFabButtonClick)="addCustomCountry()"></app-fab-button>
                </div>
                <mat-accordion cdk-drag-preview cdkDrag [cdkDragData]="customCountryFormGroup" class="mb-16"
                    formArrayName="country_rules"
                    *ngFor="let customCountryFormGroup of form.get('country_rules')['controls'];let i = index"
                    [formGroup]="customCountryFormGroup">
                    <mat-expansion-panel [expanded]="expandIndex[i] == i" [ngClass]="{'invalid' : !checkInvalid(i)}">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div fxLayout="row warp" fxFlex="1 1 auto" fxLayoutAlign="start start"
                                    fxLayoutGap="5px">
                                    <mat-icon>drag_indicator</mat-icon>
                                    <div fxLayout="row warp" fxFlex="1 1 auto" fxLayoutAlign="start start"
                                        fxLayoutGap="5px"
                                        *ngIf="customCountryFormGroup.get('countries')?.value?.length > 0">
                                        <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                            *ngFor="let name of customCountryFormGroup.get('countries')?.value?.slice(0,11);let i=index;let last = last">
                                            {{name?.itemName}}
                                            <span *ngIf="!last">,</span>
                                            <span *ngIf="last && i >= 10">...</span>
                                        </div>
                                    </div>
                                </div>

                                <div style="width: 100%;"
                                    *ngIf="customCountryFormGroup.get('countries')?.value?.length <=0">
                                    {{'Country Rule #' | translate}} {{i+1}}
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div (mousedown)="$event.stopPropagation()" (mousemove)="$event.stopPropagation()"
                            fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
                            <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px"
                                class="pb-16">
                                <mat-slide-toggle fxFlex="0 1 auto"
                                    [formControl]="customCountryFormGroup.get('enable')">
                                    {{'Enable' | translate}}
                                </mat-slide-toggle>
                            </div>
                            <div fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto"
                                    fxLayoutAlign="start stretch" fxLayoutGap="1em" class="pb-16">
                                    <div fxFlex="1 1 0px" fxLayout="column" fxLayoutGap="5px">
                                        <angular2-multiselect [formControl]="customCountryFormGroup.get('countries')"
                                            [data]="countryDropDown" [settings]="countryModeSettings">
                                        </angular2-multiselect>
                                    </div>
                                </div>
                                <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto"
                                    fxLayoutAlign="start stretch" fxLayoutGap="1em">
                                    <div fxFlex="1 1 0px">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label>
                                                {{'Name(En)' | translate}}</mat-label>
                                            <input matInput name="title"
                                                [formControl]="customCountryFormGroup.get('name_en')">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="1 1 0px">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label>
                                                {{'Name(Ar)' | translate}}</mat-label>
                                            <input matInput name="title"
                                                [formControl]="customCountryFormGroup.get('name_ar')">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto"
                                    fxLayoutAlign="start stretch" fxLayoutGap="1em">
                                    <div fxFlex="1 1 0px">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label>
                                                {{'Minimum Amount' | translate}}</mat-label>
                                            <input type="number"
                                                [formControl]="customCountryFormGroup.get('minimum_amount')" matInput
                                                name="title">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="1 1 0px">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{'Number Language'| translate}}
                                            </mat-label>
                                            <mat-select [formControl]="customCountryFormGroup.get('number_language')">
                                                <mat-option [value]="1">
                                                    {{'English' | translate}}
                                                </mat-option>
                                                <mat-option [value]="2">
                                                    {{'Native' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto"
                                    fxLayoutAlign="start stretch" fxLayoutGap="1em">
                                    <div fxFlex="1 1 0px">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{'Validator'| translate}}
                                            </mat-label>
                                            <mat-select [formControl]="customCountryFormGroup.get('validation_id')">
                                                <mat-option [value]="0">
                                                    {{'No Validation' | translate}}
                                                </mat-option>
                                                <mat-option [value]="1">
                                                    {{'Only English' | translate}}
                                                </mat-option>
                                                <mat-option [value]="2">
                                                    {{'Only Arabic' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="1 1 0px">
                                        <!-- <div style="display: none;">
                                            {{typeChanged(i,customCountryFormGroup.get('type').value)}}</div> -->
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{'Type'| translate}}
                                            </mat-label>
                                            <mat-select
                                                (selectionChange)="typeChanged(i,customCountryFormGroup.get('type').value)"
                                                [formControl]="customCountryFormGroup.get('type')">
                                                <mat-option *ngFor="let type of typeEnum" [value]="type?.id">
                                                    {{type?.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto"
                                    fxLayoutAlign="start stretch" fxLayoutGap="1em">
                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100"
                                            *ngIf="customCountryFormGroup.get('type').value=='CUSTOM' || customCountryFormGroup.get('type').value=='EXTERNAL'">
                                            <mat-label>
                                                {{'Value' | translate}}</mat-label>
                                            <textarea rows="3" [formControl]="customCountryFormGroup.get('value')"
                                                matInput name="title"></textarea>
                                            <mat-error *ngIf="customCountryFormGroup.get('value').hasError('required')">
                                                {{'Value is required' | translate}}
                                            </mat-error>
                                            <mat-error
                                                *ngIf="customCountryFormGroup.get('value').hasError('startWithHttp') && customCountryFormGroup.get('type').value=='EXTERNAL'">
                                                {{'Value Start with HTTP' | translate}}
                                            </mat-error>
                                            <mat-error
                                                *ngIf="customCountryFormGroup.get('value').hasError('containHTTP') && customCountryFormGroup.get('type').value=='CUSTOM'">
                                                {{'Value can not start with HTTP' | translate}}
                                            </mat-error>
                                        </mat-form-field>

                                        <mat-form-field [appearance]="'outline'" fxFlex="100"
                                            *ngIf="customCountryFormGroup.get('type').value=='PARENT'">
                                            <mat-label class="text-capitalized">
                                                {{'Parent'| translate}}
                                            </mat-label>
                                            <mat-select [formControl]="customCountryFormGroup.get('parent')">
                                                <mat-option *ngFor="let item of navItemList" [value]="item?.id">
                                                    {{item?.title}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <!-- <mat-form-field appearance="outline" fxFlex="100"
                                            *ngIf="customCountryFormGroup.get('type').value=='PAGE'">
                                            <mat-label class="text-capitalized">
                                                {{'Page'| translate}}
                                            </mat-label>
                                            <mat-select (selectionChange)="pageChanged($event.value,i)"
                                                [formControl]="customCountryFormGroup.get('page')">
                                                <mat-option *ngFor="let page of pageList" [value]="page?.id">
                                                    {{page?.title}}</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="customCountryFormGroup.get('page').hasError('required')">
                                                {{'Page is required' | translate}}
                                            </mat-error>
                                        </mat-form-field> -->
                                    </div>
                                </div>


                                <div fxLayout.lt-md="column" fxLayout="row" fxFlex.lt-md="0 0 auto" fxFlex.gt-sm="0 0 auto"
                                     fxLayoutAlign="space-between center" fxLayoutGap="1em">
                                    <mat-slide-toggle fxFlex="0 1 auto"
                                        (change)="showTabbyChange(i,customCountryFormGroup.get('enable_tabby').value)"
                                        [formControl]="customCountryFormGroup.get('enable_tabby')">
                                        {{'Enable Tabby' | translate}}
                                    </mat-slide-toggle>
                                    <div [ngClass]="!customCountryFormGroup.get('enable_tabby').value ? 'disabled' : ''"
                                          fxLayout="row"
                                         fxFlex="0.25 1 auto" fxLayoutAlign="start center" fxLayoutGap="1em">
                                        <div fxFlex="1 1 auto">
                                            <mat-form-field appearance="outline" fxFlex="100">
                                                <mat-label>
                                                    {{'Tabby Public Api Key' | translate}}</mat-label>
                                                <input
                                                          [formControl]="customCountryFormGroup.get('tabby_public_api_key')"
                                                          matInput name="title">
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex="0 0 auto">
                                            <mat-form-field appearance="outline" fxFlex="100">
                                                <mat-label>
                                                    {{'Tabby Merchant Code' | translate}}</mat-label>
                                                <input
                                                          [formControl]="customCountryFormGroup.get('tabby_merchant_code')"
                                                          matInput name="title">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>




                                <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto"
                                    fxLayoutAlign="start stretch" fxLayoutGap="1em" class="pb-16">
                                    <div fxLayout="column" fxFlex.lt-md="1 1 auto" fxFlex.gt-sm="0 0 50%"
                                        fxLayoutAlign="start stretch" fxLayoutGap="1em">
                                        <div fxFlex="1 1 auto">
                                            <mat-slide-toggle
                                                (change)="showCivilIDChange(i,customCountryFormGroup.get('show_civil_id').value)"
                                                [formControl]="customCountryFormGroup.get('show_civil_id')">
                                                {{'Show Civil ID' | translate}}
                                            </mat-slide-toggle>
                                        </div>
                                        <div [ngClass]="!customCountryFormGroup.get('show_civil_id').value ? 'disabled' : ''"
                                            class="fuse-card auto-width fuse-box-shadow-none" fxLayout="column"
                                            fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayoutGap="1em">
                                            <div fxFlex="1 1 auto">
                                                <mat-slide-toggle
                                                    [formControl]="customCountryFormGroup.get('is_civil_id_mandatory')">
                                                    {{'Is Civil ID Mandatory' | translate}}
                                                </mat-slide-toggle>
                                            </div>
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label>
                                                        {{'Civil ID Note (En)' | translate}}</mat-label>
                                                    <textarea rows="3"
                                                        [formControl]="customCountryFormGroup.get('civil_id_note_en')"
                                                        matInput name="title"></textarea>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label>
                                                        {{'Civil ID Note (Ar)' | translate}}</mat-label>
                                                    <textarea rows="3"
                                                        [formControl]="customCountryFormGroup.get('civil_id_note_ar')"
                                                        matInput name="title"></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="column" fxFlex.lt-md="1 1 auto" fxFlex.gt-sm="0 0 50%"
                                        fxLayoutAlign="start stretch" fxLayoutGap="1em">
                                        <div fxFlex="1 1 0px">
                                            <mat-slide-toggle
                                                (change)="showPacciChange(i,customCountryFormGroup.get('show_paci').value)"
                                                [formControl]="customCountryFormGroup.get('show_paci')">
                                                {{'Show PACI' | translate}}
                                            </mat-slide-toggle>
                                        </div>
                                        <div [ngClass]="!customCountryFormGroup.get('show_paci').value ? 'disabled' : ''"
                                            class="fuse-card auto-width fuse-box-shadow-none" fxLayout="column"
                                            fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayoutGap="1em">
                                            <div fxFlex="1 1 auto">
                                                <mat-slide-toggle
                                                    [formControl]="customCountryFormGroup.get('is_paci_mandatory')">
                                                    {{'Is PACI Mandatory' | translate}}
                                                </mat-slide-toggle>
                                            </div>
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label>
                                                        {{'PACI Note (En)' | translate}}</mat-label>
                                                    <textarea rows="3"
                                                        [formControl]="customCountryFormGroup.get('paci_note_en')"
                                                        matInput name="title"></textarea>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="1 1 auto">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label>
                                                        {{'PACI Note (Ar)' | translate}}</mat-label>
                                                    <textarea rows="3"
                                                        [formControl]="customCountryFormGroup.get('paci_note_ar')"
                                                        matInput name="title"></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto"
                                    fxLayoutAlign="start stretch" fxLayoutGap="1em">
                                    <div fxFlex="1 1 0px">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label>
                                                {{'Icon Type' | translate}}</mat-label>
                                            <mat-select [formControl]="customCountryFormGroup.get('icon_type')">
                                                <mat-option [value]="1">{{'Text' | translate}} </mat-option>
                                                <mat-option [value]="2">{{'Image' | translate}} </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="1 1 0px" *ngIf="customCountryFormGroup.get('icon_type').value=='1'">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label>
                                                {{'Icon' | translate}}</mat-label>
                                            <input matInput [formControl]="customCountryFormGroup.get('icon')"
                                                name="title">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div *ngIf="customCountryFormGroup.get('icon_type').value=='2'" fxLayout.lt-md="column"
                                    fxLayout.gt-sm="row" fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                    fxLayoutGap="1em">
                                    <div class="fuse-card auto-width p-16 max-height" fxFlex="1 1 auto"
                                        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                        <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="space-between center"
                                            fxLayoutGap="5px">
                                            <label class="highlight-text-color">{{'Icon Image' | translate}}</label>
                                            <mat-icon (click)="deleteFile(i)"
                                                *ngIf="customCountryFormGroup.get('icon_image').value"
                                                class="action-btn-delete cursor-pointer">close
                                            </mat-icon>
                                        </div>
                                        <div *ngIf="!customCountryFormGroup.get('icon_image').value" fxLayout="column"
                                            fxLayoutGap="8px" fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                            fxLayoutGap="5px">
                                            <app-cms-upload-media (afterUpload)="setFileToForm($event,i)"
                                                [isMultiSelect]="false"
                                                [formcontrol]="customCountryFormGroup.get('icon_image')" [index]="i">
                                            </app-cms-upload-media>
                                        </div>
                                        <div class="files-list cursor-pointer" fxFlex="0 0 auto"
                                            fxLayoutAlign="center center" fxLayoutGap="5px" fxLayout="column"
                                            *ngIf="customCountryFormGroup.get('icon_image').value">
                                            <app-show-all-type-media
                                                [url]="customCountryFormGroup.get('icon_image').value">
                                            </app-show-all-type-media>
                                            <!-- <img style="padding: 2px;"
                                                [src]="utilitiesService.bypassTrustedURL(customCountryFormGroup.get('icon_image').value)"> -->
                                        </div>
                                    </div>
                                </div>

                                <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto"
                                    fxLayoutAlign="start stretch" fxLayoutGap="1em">

                                    <mat-slide-toggle [formControl]="customCountryFormGroup.get('show_tax')">
                                        {{'Show Tax' | translate}}
                                    </mat-slide-toggle>
                                    <mat-slide-toggle [formControl]="customCountryFormGroup.get('show_tax_inc_label')">
                                        {{'Show Tax inc label' | translate}}
                                    </mat-slide-toggle>

                                </div>
                            </div>
                        </div>
                        <mat-action-row>
                            <app-delete-button (deleteButtonClick)="delete(i,customCountryFormGroup.get('id').value)">
                            </app-delete-button>
                        </mat-action-row>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </form>
    </div>
</div>