<div fxFlex="1 1 100%" fxLayoutAlign="start stretch" class="popup-container" fxLayout="row" fxLayoutGap="1em">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="8px" class="p-8">

        <div fxFlex="0 0 auto" fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="0px"
            class="p-8 highlight-bg-color border-radius-sm">
            <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="8px">
                <strong class="mat-title mb-0">
                    {{row?.customer?.first_name}}
                    {{row?.customer?.middle_name}}
                    {{row?.customer?.last_name}}</strong>
                <!-- <mat-icon *ngIf="!row?.customer?.is_guest">check</mat-icon> -->
            </div>

            <mat-icon *ngIf="coreService.checkPermission(utilitiesService.Permission.CustomerDetail)"
                (click)="viewCustomer(row?.customer?.id)" class="cursor-pointer" title="View Customer" matPrefix>
                remove_red_eye
            </mat-icon>
        </div>
        <div style="text-align: end !important;" class="pr-4 mb-0">
            <span class="badge-type-not-verified" *ngIf="row?.customer?.is_guest">{{'Guest' | translate}}</span>
            <span class="badge-type-verified" *ngIf="!row?.customer?.is_guest">{{'Registered' | translate}}</span>
        </div>

        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px"
            *ngIf="row?.customer?.email" class="p-8 pb-0">
            <label style="color: #f3c036; font-size: 13px !important;">{{'Email'}}</label>
            <strong>{{row?.customer?.email}}</strong>
        </div>
        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px"
            *ngIf="row?.customer?.contact_no_country?.name" class="p-8 pt-0">
            <label style="color: #f3c036; font-size: 13px !important;">{{'Country'}}</label>
            <strong>{{row?.customer?.contact_no_country?.name}}</strong>
        </div>
    </div>
</div>