import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'customDate' })
export class CustomDatePipe implements PipeTransform {
  transform(dateRange: string): string {
    if (!dateRange) return '';

    const dateParts = dateRange.split(' - ');
    if (dateParts.length !== 2) return '';

    const startDate = this.formatDate(dateParts[0]);
    const endDate = this.formatDate(dateParts[1]);

    return `${startDate} - ${endDate}`;
  }

  private formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = this.getMonthName(date.getMonth());
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  private getMonthName(monthIndex: number): string {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return months[monthIndex];
  }
}
