<ng-container *ngIf="!isAffiliatePage;else affiliate">
    <div id="dashboard-project" class="page-layout" fxLayout="column">
        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" fusePerfectScrollbar>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px"
                class="fuse-card auto-width">
                <div fxFlex="0 0 auto" class="p-16 customer-header" fxLayoutAlign="start center" fxLayout="row"
                    fxLayoutGap="4px">
                    <div fxFlex="1 1 0px" fxLayout="row" fxLayoutAlign="start">
                        <strong style="font-size: 20px;">{{campaignDetail?.name}}</strong>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="0 0 50%" fxLayout="column" class="campaign&Temp" [ngClass]="templateDetail ? '':'w-full'">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                            fxLayoutGap="8px" class="p-16 pb-0">
                            <div class="fuse-card auto-width p-16" fxFlex.gt-sm="1 1 0px" fxFlex.lt-md="0 0 auto"
                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="8px">
                                <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row"
                                    fxLayoutGap="10px">
                                    <label class="highlight-text-color">{{'Campaign Information' | translate}}</label>
                                </div>
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                    <div *ngIf="campaignDetail?.name" fxFlex="1 1 auto" fxLayoutAlign="start stretch"
                                        fxLayout="row" fxLayoutGap="8px">
                                        <div fxFlex="1 1 0px">
                                            {{'Name' | translate}}
                                        </div>
                                        <div fxFlex="1 1 0px">
                                            <strong>{{campaignDetail?.name}}</strong>
                                        </div>
                                    </div>
                                    <div *ngIf="campaignDetail?.campaign_channel" fxFlex="1 1 auto"
                                        fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="8px">
                                        <div fxFlex="1 1 0px">
                                            {{'Channel' | translate}}
                                        </div>
                                        <div fxFlex="1 1 0px">
                                            <span><strong>{{campaignDetail?.campaign_channel?.name}}</strong></span>
                                        </div>
                                    </div>
                                    <div *ngIf="campaignDetail?.code" fxFlex="1 1 auto" fxLayoutAlign="start stretch"
                                        fxLayout="row" fxLayoutGap="8px">
                                        <div fxFlex="1 1 0px">
                                            {{'Code' | translate}}
                                        </div>
                                        <div fxFlex="1 1 0px">
                                            <span><strong>{{campaignDetail?.code}}</strong></span>
                                        </div>
                                    </div>
                                    <div *ngIf="campaignDetail?.status" fxFlex="1 1 auto" fxLayoutAlign="start stretch"
                                        fxLayout="row" fxLayoutGap="8px">
                                        <div fxFlex="1 1 0px">
                                            {{'Status' | translate}}
                                        </div>
                                        <div fxFlex="1 1 0px" *ngIf="campaignDetail?.status">
                                            <p class="bg-color campaign-status"
                                            [ngStyle]="{ 'background-color': campaignDetail?.statusInfo?.backgroundColor,'color':campaignDetail?.statusInfo?.color}">
                                            {{campaignDetail?.statusInfo?.status}}
                                        </p>
                                        </div>
                                    </div>

                                    <div *ngIf="campaignDetail?.total_audience" fxFlex="1 1 auto"
                                        fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="8px">
                                        <div fxFlex="1 1 0px">
                                            {{'Total Audience' | translate}}
                                        </div>
                                        <div fxFlex="1 1 0px">
                                            <strong>{{campaignDetail?.total_audience}}</strong>
                                        </div>
                                    </div>
                                    <div *ngIf="campaignDetail?.audience" fxFlex="1 1 auto" fxLayoutAlign="start stretch"
                                        fxLayout="row" fxLayoutGap="8px">
                                        <div fxFlex="1 1 0px">
                                            {{'Audience' | translate}}
                                        </div>
                                        <div fxFlex="1 1 0px">
                                            <strong>{{campaignDetail?.audience?.name}}</strong>
                                        </div>
                                    </div>
                                    <div *ngIf="campaignDetail?.created" fxFlex="1 1 auto" fxLayoutAlign="start stretch"
                                        fxLayout="row" fxLayoutGap="8px">
                                        <div fxFlex="1 1 0px">
                                            {{'Campaign Created Date' | translate}}
                                        </div>
                                        <div fxFlex="1 1 0px">
                                            <strong>{{campaignDetail?.created | date: 'dd/MM/yyyy hh mm a'}}</strong>
                                        </div>
                                    </div>
                                    <div *ngIf="campaignDetail?.updated" fxFlex="1 1 auto" fxLayoutAlign="start stretch"
                                        fxLayout="row" fxLayoutGap="8px">
                                        <div fxFlex="1 1 0px">
                                            {{'Campaign Updated Date' | translate}}
                                        </div>
                                        <div fxFlex="1 1 0px">
                                            <strong> {{campaignDetail?.updated | date: 'dd/MM/yyyy hh mm a'}}</strong>
                                        </div>
                                    </div>
                                    <div *ngIf="campaignDetail?.content_language" fxFlex="1 1 auto"
                                        fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="8px">
                                        <div fxFlex="1 1 0px">
                                            {{'Default Language' | translate}}
                                        </div>
                                        <div fxFlex="1 1 0px" *ngIf="campaignDetail?.content_language">
                                            <span *ngIf="campaignDetail?.content_language =='en'"><strong>{{'English' |
                                                    translate}}</strong></span>
                                            <span *ngIf="campaignDetail?.content_language =='ar'"><strong>{{'Arabic' |
                                                    translate}}</strong></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div fxFlex="0 0 auto" *ngIf="templateDetail" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                            fxLayout.gt-sm="row" fxLayoutGap="8px" class="p-16">
                            <div class="fuse-card auto-width p-16" fxFlex.gt-sm="1 1 0px" fxFlex.lt-md="0 0 auto"
                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row"
                                    fxLayoutGap="10px">
                                    <label class="highlight-text-color">{{'Template Information' | translate}}</label>
                                </div>
                                <div *ngIf="templateDetail?.title" fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                    fxLayout="column" fxLayoutGap="1em">
                                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="8px">
                                        <div fxFlex="1 1 0px">
                                            {{'Title (English)' | translate}}
                                        </div>
                                        <div fxFlex="1 1 0px">
                                            <strong>
                                                {{templateDetail?.title_en ? templateDetail?.title_en :
                                                templateDetail?.title}}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="templateDetail?.title_ar" fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                    fxLayout="column" fxLayoutGap="1em">
                                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="8px">
                                        <div fxFlex="1 1 0px">
                                            {{'Title (Arabic)' | translate}}
                                        </div>
                                        <div fxFlex="1 1 0px">
                                            <strong> {{templateDetail?.title_ar}}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="templateDetail?.created" fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                    fxLayout="column" fxLayoutGap="1em">
                                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="8px">
                                        <div fxFlex="1 1 0px">
                                            {{'Created Date' | translate}}
                                        </div>
                                        <div fxFlex="1 1 0px">
                                            <strong>{{templateDetail?.created | date: 'dd/MM/yyyy hh mm a'}}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="templateDetail?.updated" fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                    fxLayout="column" fxLayoutGap="1em">
                                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="8px">
                                        <div fxFlex="1 1 0px">
                                            {{'Updated Date' | translate}}
                                        </div>
                                        <div fxFlex="1 1 0px">
                                            <strong>{{templateDetail?.updated | date: 'dd/MM/yyyy hh mm a'}}</strong>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div *ngIf="previewDetail" fxFlex="0 0 50%" fxLayout="column" class="campaignPreview">
                        <!-- <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="8px">
                                <div fxFlex="1 1 0px">
                                    {{'Preview' | translate}}
                                </div>
                                <div *ngIf="templateDetail?.body_en || templateDetail?.body" fxFlex="1 1 auto" fxLayoutAlign="start stretch"
                                    fxLayout="row" fxLayoutGap="8px">
                                    <div fxFlex="1 1 0px" style="padding: 10px;min-height: 100px;border: 1px solid #ccc;"
                                        [innerHTML]="getBody(templateDetail?.body_en ? templateDetail?.body_en : templateDetail?.body)">
                                    </div>
                                </div>
                                <div *ngIf="!templateDetail?.body_en || !templateDetail?.body" fxFlex="1 1 auto"
                                    fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="8px">
                                    <div class="mat-title" style="color: #ccc;">{{'Body English is empty'}}</div>
                                </div>
                            </div>
                        </div> -->
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="8px">

                                <div fxFlex="1 1 0px">
                                    {{'Preview ' | translate}}
                                </div>
                                <div *ngIf="templateDetail?.body"class="fuse-card auto-width p-16" fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="8px">
                                    <p *ngIf="previewDetail.title">{{'Subject : '}}{{previewDetail.title}}</p>
                                    <div fxFlex="1 1 0px" style="padding: 10px;min-height: 100px;border: 1px solid #ccc;"
                                        [innerHTML]="getBody()">
                                    </div>
                                </div>
                                <div *ngIf="!templateDetail?.body_ar" fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayout="row"
                                    fxLayoutGap="8px">
                                    <div class="mat-title" style="color: #ccc;">{{'Body is empty'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #affiliate>
    <div id="affiliate-project" class="page-layout affiliate" fxLayout="column">
        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">

            <div fxFlex fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px" class="auto-width">

                <div  fxFlex="1 1 100%" fxLayoutAlign="space-between center" fxLayout="row wrap" fxLayout.lt-md="column"
                    fxLayoutGap.lt-md="15px">

                    <div class="flex" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <div class="fuse-card min-width-auto w-170 h-100 w-full  highlight-only-bg-color p-16 min-width-custom" fxFlex="0 0 auto"
                            fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="8px">
                            <div class="mt-1 display-none" style="font-weight: 400">{{'Campaign Channel' | translate}}</div>
                            <div class="text-3xl font-bold leading-none tracking-tight kanz-text-color">
                                <img width="30px" [src]="campaignDetail?.campaign_logo" alt="">
                            </div>
                        </div>

                        <div class="fuse-card min-width-auto w-170 h-100 w-full  highlight-only-bg-color p-16 min-width-custom" fxFlex="0 0 auto"
                            fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="8px">
                            <div class="mt-1 display-none" style="font-weight: 400">{{'Campaign Code' | translate}}</div>
                            <div class="text-3xl font-bold leading-none tracking-tight kanz-text-color">
                                {{campaignDetail?.code}}
                            </div>
                        </div>

                        <div *ngIf="campaignDetail?.start_date" class="fuse-card min-width-auto w-170 h-100 w-full  highlight-only-bg-color p-16 min-width-custom" fxFlex="0 0 auto"
                            fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="8px">
                            <div class="mt-1" style="font-weight: 400">{{'Start Date' | translate}}</div>
                            <div class="text-3xl font-bold leading-none tracking-tight kanz-text-color">
                                {{
                                    campaignDetail?.start_date
                                }}
                            </div>
                        </div>

                        <div *ngIf="campaignDetail?.end_date" class="fuse-card min-width-auto w-170 h-100 w-full  highlight-only-bg-color p-16 min-width-custom" fxFlex="0 0 auto" fxLayoutAlign="center center"
                            fxLayout="column" fxLayoutGap="8px">
                            <div class="mt-1" style="font-weight: 400">{{'End Date' | translate}}</div>
                            <div class="text-3xl font-bold leading-none tracking-tight kanz-text-color">
                                {{
                                    campaignDetail?.end_date
                                }}
                            </div>
                        </div>

                    </div>

                    <!-- <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
                        <div fxFlex="1 1 0px" fxLayoutAlign="start center" *ngIf="campaignDetail?.created_by_name">
                            <mat-icon>account_box</mat-icon>
                            <strong style="margin-left: 5px">{{campaignDetail?.created_by_name }}</strong>
                        </div>
                        <app-date-picker (dateRangeEvent)="changeDate($event)"></app-date-picker>
                    </div> -->
                </div>
                <div fxflex class="mt-5">
                    <div class="flex" fxLayout="row wrap" fxLayoutAlign="start center"  fxLayoutGap="10px">
                        <!-- <div *ngIf="isOutBondCampaign(campaignDetail?.campaign_channel?.id)" class="number-card highlight-only-bg-color p-16 min-width-custom" fxFlex="0 0 auto" fxLayoutAlign="center center"
                            fxLayout="column" fxLayoutGap="8px">
                            <div class="text-5xl font-semibold leading-none tracking-tight kanz-text-color">
                                {{
                                    campaignDetail?.total_audience
                                }}
                            </div>
                            <div class="mt-1" style="font-weight: 400">{{'Total Audience' | translate}}</div>
                        </div> -->
                        <div class="number-card highlight-only-bg-color p-16 min-width-custom" fxFlex="0 0 auto"
                            fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="8px">
                            <div class="text-5xl font-semibold leading-none tracking-tight kanz-text-color">
                                {{
                                monthlyStatics?.visit_count
                                }}
                            </div>
                            <div class="mt-1" style="font-weight: 400">{{'Total Views' | translate}}</div>
                        </div>

                        <div class="number-card highlight-only-bg-color p-16 min-width-custom" fxFlex="0 0 auto" fxLayoutAlign="center center"
                            fxLayout="column" fxLayoutGap="8px">
                            <div class="text-5xl font-semibold leading-none tracking-tight kanz-text-color">
                                {{monthlyStatics?.order_count}}
                            </div>
                            <div class="mt-1" style="font-weight: 400">{{'Total orders' | translate}}</div>
                        </div>

                        <div class="number-card highlight-only-bg-color p-16 min-width-custom" fxFlex="0 0 auto"
                            fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="8px">
                            <div class="text-5xl font-semibold leading-none tracking-tight kanz-text-color">
                                {{monthlyStatics?.orders_total}}
                                <sup style="font-weight: 400; font-size: 12px;">{{baseCurrency}}</sup>
                            </div>
                            <div class="mt-1" style="font-weight: 400">{{'Total Revenue' | translate}}</div>
                        </div>

                        <div class="number-card highlight-only-bg-color p-16 min-width-custom" fxFlex="0 0 auto" fxLayoutAlign="center center"
                            fxLayout="column" fxLayoutGap="8px">
                            <div class="text-5xl font-semibold leading-none tracking-tight kanz-text-color">
                                {{campaignStatics?.abandoned_cart_count || 0}}
                            </div>
                            <div class="mt-1" style="font-weight: 400">{{'Abandoned Cart' | translate}}</div>
                        </div>


                        <div *ngIf="!isOutBondCampaign(campaignDetail?.campaign_channel?.id)" class="number-card highlight-only-bg-color p-16 min-width-custom" fxFlex="0 0 auto" fxLayoutAlign="center center"
                            fxLayout="column" fxLayoutGap="8px">
                            <div class="text-5xl font-semibold leading-none tracking-tight kanz-text-color">
                                {{(campaignDetail?.actual_spend | number:'1.2-2') || 0}}
                                <sup style="font-weight: 400; font-size: 12px;">{{baseCurrency}}</sup>
                            </div>
                            <div class="mt-1" style="font-weight: 400">{{'Actual Spend' | translate}}</div>
                        </div>

                        <div *ngIf="!isOutBondCampaign(campaignDetail?.campaign_channel?.id)" class="number-card highlight-only-bg-color p-16 min-width-custom" fxFlex="0 0 auto"
                            fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="8px">
                            <div class="text-5xl font-semibold leading-none tracking-tight kanz-text-color">
                                {{monthlyStatics?.roas}}
                                <sup style="font-weight: 400; font-size: 12px;">{{'%'}}</sup>
                            </div>
                            <div class="mt-1" style="font-weight: 400">{{'ROAS' | translate}}</div>
                        </div>

                        <!-- <div *ngIf="!isOutBondCampaign(campaignDetail?.campaign_channel?.id)" class="number-card highlight-only-bg-color p-16 min-width-custom" fxFlex="0 0 auto" fxLayoutAlign="center center"
                            fxLayout="column" fxLayoutGap="8px">
                            <div class="text-5xl font-semibold leading-none tracking-tight kanz-text-color">
                                {{campaignDetail?.reach}}
                            </div>
                            <div class="mt-1" style="font-weight: 400">{{'Reach' | translate}}</div>
                        </div> -->

                        <!-- <div *ngIf="!isOutBondCampaign(campaignDetail?.campaign_channel?.id)" class="number-card highlight-only-bg-color p-16 min-width-custom" fxFlex="0 0 auto" fxLayoutAlign="center center"
                            fxLayout="column" fxLayoutGap="8px">
                            <div class="text-5xl font-semibold leading-none tracking-tight kanz-text-color">
                                {{campaignDetail?.clicks}}
                            </div>
                            <div class="mt-1" style="font-weight: 400">{{'Clicks' | translate}}</div>
                        </div> -->

                        <!-- <div *ngIf="!isOutBondCampaign(campaignDetail?.campaign_channel?.id)" class="number-card highlight-only-bg-color p-16 min-width-custom" fxFlex="0 0 auto" fxLayoutAlign="center center"
                            fxLayout="column" fxLayoutGap="8px">
                            <div class="text-5xl font-semibold leading-none tracking-tight kanz-text-color">
                                {{campaignDetail?.cpc | number:'1.2-2'}}
                            </div>
                            <div class="mt-1" style="font-weight: 400">{{'CPC' | translate}}</div>
                        </div> -->

                    </div>
                </div>
                <app-charts [statics]="campaignStatics" [page]="'singleCampaign'" [date]="dateRange"></app-charts>

            </div>
        </div>
    </div>


</ng-template>