<div class="action-container">
    <mat-icon *ngIf="selectedImageObject.file" title="Copy URL" (click)="copyUrl()"
        class="cursor-pointer action-icon  sub-title-color border-color-view">
        file_copy
    </mat-icon>
    <mat-icon title="Delete Image" *ngIf="coreService.checkPermission(utilitiesService.Permission.MediaDelete) && id"
        (click)="deleteMedia()" class="cursor-pointer action-btn-delete action-icon border-color-delete ">
        delete
    </mat-icon>
    <mat-icon [mat-dialog-close]="false" title="Close"
        class="cursor-pointer action-icon highlight-text-color border-color-edit">
        close
    </mat-icon>
</div>

<ngx-gallery #gallery [options]="galleryOptions" (change)="changeImage($event)" [images]="galleryImages"
    class="ngx-gallery"></ngx-gallery>