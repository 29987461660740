import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { CustomerService } from "app/services/customer/customer.service";
import { SharedService } from "app/services/sharedService";
import { UtilitiesService } from "app/services/utilitiesService";
import { ConditionsListComponent } from "../conditions-list/conditions-list.component";

@Component({
    selector: "app-create-audience",
    templateUrl: "./create-audience.component.html",
    styleUrls: ["./create-audience.component.scss"],
    animations: fuseAnimations,
})
export class CreateAudienceComponent implements OnInit {
    @ViewChild(ConditionsListComponent)
    ConditionsListComponent: ConditionsListComponent;
    @Input() id: any;
    @Input() isHideSaveCancel: boolean = false;
    isAudienceCreated: boolean = false;
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    audienceDetail: any = "";
    form: FormGroup;
    constructor(
        private formBuilder: FormBuilder,
        public utilitiesService: UtilitiesService,
        private customerService: CustomerService,
        private sharedService: SharedService,
        private translate: TranslateService
    ) {}

    async ngOnInit(): Promise<void> {
        this.form = this.formBuilder.group({
            name: [null, [Validators.required]],
        });
        await this.getAudienceDetail();
    }
    async getAudienceDetail() {
        if (this.id) {
            try {
                this.utilitiesService.startLoader();
                const audienceDetail = await this.customerService
                    .getAudienceDetail(this.id)
                    .toPromise();
                if (audienceDetail) {
                    this.audienceDetail = audienceDetail;
                    this.form.controls.name.setValue(this.audienceDetail.name);
                    this.utilitiesService.stopLoader();
                }
                this.isAudienceCreated = true;
                this.isHideSaveCancel = true;
            } catch {
            } finally {
            }
        }
    }
    async saveOrUpdate() {
        if (this.form.valid) {
            if (this.id) {
                await this.update();
            } else {
                await this.save();
            }
        }
    }
    async save() {
        try {
            this.utilitiesService.startLoader();
            this.isAudienceCreated = true;
            this.customerService
                .addAudience(this.form.value)
                .subscribe(async (data) => {
                    this.id = data.id;
                    let successMsg = this.translate.instant(
                        "Audience added successfully"
                    );
                    this.utilitiesService.showSuccessToast(successMsg);
                    await this.getAudienceDetail();
                    await this.ConditionsListComponent.getConditionsList();
                });
        } catch {
            this.utilitiesService.startLoader();
        } finally {
        }
    }
    async update() {
        try {
            this.utilitiesService.startLoader();
            this.isAudienceCreated = true;
            this.customerService
                .updateAudience(this.form.value, this.id)
                .subscribe(async (data) => {
                    let successMsg = this.translate.instant(
                        "Audience updated successfully"
                    );
                    this.utilitiesService.showSuccessToast(successMsg);
                    await this.getAudienceDetail();
                    await this.ConditionsListComponent.getConditionsList();
                });
        } catch {
            this.utilitiesService.startLoader();
        } finally {
        }
    }
    edit() {
        this.isHideSaveCancel = false;
    }
    closeCreateCondition() {
        this.ConditionsListComponent.handleCancel();
    }

    cancel() {
        this.id = "";
        this.cancelClick.emit();
    }
    checkIsConditionCreating() {
        if (
            this.ConditionsListComponent &&
            this.ConditionsListComponent.isCreateCondition &&
            !this.ConditionsListComponent.conditionID
        ) {
            return true;
        } else {
            return false;
        }
    }
    checkIsConditionEditing() {
        if (
            this.ConditionsListComponent &&
            this.ConditionsListComponent.isCreateCondition &&
            this.ConditionsListComponent.conditionID
        ) {
            return true;
        } else {
            return false;
        }
    }
}
