import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
// import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { AlertModalComponent } from "app/main/components/alert-modal/alert-modal.component";
import { ElementHistoryComponent } from "app/main/components/element-history/element-history.component";
import { IconCss } from "app/main/ngxDatatable-Icons/icons";
import { ApiService } from "app/services/api.service";
import { CatalogueService } from "app/services/catalogue/catalogue.service";
import { CoreService } from "app/services/core-service";
import { CampaignsService } from "app/services/marketing/campaigns.service";
import { PageService } from "app/services/pageService";
import { SharedService } from "app/services/sharedService";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { TransactionType } from "../../types/type";
import { TransactionStatus } from "../../types/status";
import { TransactionSource } from "../../types/source";
import { fuseAnimations } from "@fuse/animations";
import { WalletService } from "app/services/wallet/wallet.service";
import { DownloadTypes } from "app/downloadTypes";
import { DownloadListComponent } from "../download-list/download-list.component";

@Component({
    selector: "app-wallet-detail",
    templateUrl: "./wallet-detail.component.html",
    styleUrls: ["./wallet-detail.component.scss"],
    animations: fuseAnimations,
})
export class WalletDetailComponent implements OnInit {
    @Input() walletDetailID: any = "";
    @Input() customerDetailID: any = "";

    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    @Output() walletDetailEvent: EventEmitter<void> = new EventEmitter<any>();
    unSubscribe$ = new Subject<void>();
    isApiCallDone: boolean = false;
    transactionList: any;
    resultTransactionList: any;
    walletDetails: any;
    form: FormGroup;
    offset = 0;
    customerDetails: any;
    page = { size: 10, totalElements: 0, pageNumber: 0 };
    label = {
        title_en: "Wallet",
        title_ar: "المحفظة",
    };
    isLoading = true;
    icons = IconCss;
    iconsCss = {
        pagerLeftArrow: this.icons.left,
        pagerRightArrow: this.icons.right,
        pagerPrevious: this.icons.previous,
        pagerNext: this.icons.next,
    };
    searchKey: any;
    transicitionType = [
        { value: 1, name: "CREDIT" },
        { value: 2, name: "DEBIT" },
    ];
    sourceType = [
        { value: 1, name: "ORDER" },
        { value: 2, name: "TOPUP" },
    ];
    statusType = [
        { value: 1, name: "PENDING" },
        { value: 2, name: "CREATED" },
        { value: 3, name: "SUCCESSFUL" },
        { value: 4, name: "CANCELLED" },
        { value: 5, name: "REFUNDED" },
    ];
    constructor(
        private catalogueService: CatalogueService,
        private walletService: WalletService,
        private dialog: MatDialog,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        public translationService: TranslationService,
        private campaignsService: CampaignsService,
        private fb: FormBuilder,
        private sharedService: SharedService,
        private pageService: PageService,
        public coreService: CoreService,
        private apiService: ApiService,
        private route: ActivatedRoute
    ) {
        this.page.pageNumber = 0;
        this.page.size = 10;
    }
    handleCancel() {}
    async ngOnInit(): Promise<void> {
        this.form = this.fb.group({
            customer_id: this.customerDetailID,
            transaction_no: [null],
            source: [null],
            type: [null],
            remarks: [null],
            status: [null],
            amount: [null],
            created: [null],
        });

        this.label.title_ar = "المحفظة";
        this.label.title_en = "Wallets";
        this.utilitiesService.startLoader();
        if (this.walletDetailID) {
            await this.getWalletDetailByID(this.walletDetailID);
        }

        await this.getTransactionDetails();
        await this.getPageElements();
        this.form.valueChanges.pipe(debounceTime(1000)).subscribe((data) => {
            this.getTransactionDetails();
        });
    }
    async limitChange(): Promise<void> {
        await this.getTransactionDetails();
    }
    getWidth(): number {
        return 100;
    }

    async search(searchKey): Promise<void> {
        this.searchKey = searchKey;
        await this.getTransactionDetails();
    }

    async pageChange(event): Promise<void> {
        this.page.pageNumber = event.offset;
        this.offset = this.page.pageNumber * this.page.size;
        document.getElementById("dashboard-project").scrollIntoView();
        if (this.walletDetailID) {
            await this.getWalletDetailByID(this.walletDetailID);
        }
    }
    myDownloads() {
        let isMobile = this.utilitiesService.isMobile();
        let size = {
            width: "30%",
            height: "100%",
        };
        if (isMobile) {
            size.height = "100%";
            size.width = "100%";
        }
        const dialogRef = this.dialog.open(DownloadListComponent, {
            data: { type: DownloadTypes.transactions },
            position: { right: "0%", left: "70%" },
            maxWidth: "",
            width: size.width,
            height: size.height,
            panelClass: [!isMobile ? "mat-dialog-height-transition" : ""],
        });
        dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
            }
        });
    }
    async download() {
        this.walletService;

        const download = await this.walletService
            .getTransactionBycustomerid(
                this.page.size,
                this.offset,
                this.searchKey,
                this.form.controls,
                true
            )
            .toPromise();
        console.log("transaction is " + download);
    }
    async getWalletDetailByID(
        walletDetailID,
        limit = this.page.size,
        offset = this.offset,
        form = this.form.controls
    ) {
        this.utilitiesService.startLoader();
        this.isLoading = true;
        this.walletService
            .getWalletByID(this.walletDetailID)
            .pipe(takeUntil(this.unSubscribe$))
            .subscribe((result) => {
                this.walletDetails = result;
                this.customerDetails = result.customer;
                this.isApiCallDone = true;
                this.label.title_ar =
                    "تفاصيل المحفظة - " + ` ${this.customerDetails.first_name}`;
                this.label.title_en =
                    "Wallet Detail Page - " +
                    ` ${this.customerDetails.first_name}`;
                this.isLoading = false;
            });
        this.utilitiesService.stopLoader();
    }
    async getTransactionDetails(
        limit = this.page.size,
        offset = this.offset,
        form = this.form.controls
    ): Promise<void> {
        try {
            this.isLoading = true;
            this.utilitiesService.startLoader();

            await this.walletService
                .getTransactionBycustomerid(limit, offset, this.searchKey, form)
                .pipe(takeUntil(this.unSubscribe$))
                .subscribe((result) => {
                    if (result) {
                        this.page.totalElements = result.count;
                        this.transactionList = result.results;

                        this.transactionList.forEach(async (element) => {
                            element.typeName = await this.getTypeName(
                                element.type
                            );
                            element.statusName = await this.getStatusName(
                                element.status
                            );
                            element.sourceName = await this.getSourceName(
                                element.source
                            );
                        });
                    }
                    this.isLoading = false;
                    this.utilitiesService.stopLoader();
                });
        } catch {
            this.isLoading = false;
            this.utilitiesService.stopLoader();
        } finally {
        }
    }

    async getPageElements() {
        try {
            const pageElements = await this.pageService
                .getElements("page")
                .toPromise();
            // if (pageElements) {
            //     this.pageElements = pageElements;
            // }
        } catch {}
    }
    ngOnDestroy(): void {
        this.unSubscribe$.next();
        this.unSubscribe$.complete();
    }

    getTypeName(id) {
        if (TransactionType.CREDIT === id) {
            return "CREDIT";
        } else if (TransactionType.DEBIT === id) {
            return "DEBIT";
        } else {
            return " - ";
        }
    }

    getSourceName(id) {
        if (id) {
            if (TransactionSource.ORDER === id) {
                return "ORDER";
            } else if (TransactionSource.TOPUP === id) {
                return "TOPUP";
            }
        } else return " - ";
    }

    getStatusName(id) {
        switch (id) {
            case TransactionStatus.PENDING: {
                return "PENDING";
                break;
            }
            case TransactionStatus.CREATED: {
                return "CREATED";
                break;
            }
            case TransactionStatus.SUCCESSFUL: {
                return "SUCCESSFUL";
                break;
            }
            case TransactionStatus.CANCELLED: {
                return "CANCELLED";
                break;
            }
            case TransactionStatus.REFUNDED: {
                return "REFUNDED";
                break;
            }
            default: {
                return " - ";
                break;
            }
        }
    }
}
