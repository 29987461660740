<div
    fxFlex="1 1 100%"
    fxLayoutAlign="start stretch"
    fxLayout="column"
    fxLayoutGap="1em"
>
    <div
        fxFlex="1 1 auto"
        fxLayoutAlign="space-between center"
        fxLayout="row"
        fxLayoutGap="1em"
    >
        <!-- <strong class="highlight-text-color">{{
            "Change Order Status" | translate
        }}</strong> -->
        <strong class="highlight-text-color"> {{ label }} </strong>
        <mat-icon (click)="close()" class="cursor-pointer">close</mat-icon>
    </div>

    <form
        fxFlex="1 1 100%"
        fxLayoutAlign="start stretch"
        fxLayout.lt-md="column"
        fxLayout.gt-sm="row"
        fxLayoutGap="1em"
        [formGroup]="form"
    >
        <div
            fxFlex="1 1 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="1em"
        >
            <div
                fxFlex="0 0 auto"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="0px"
            >
                <mat-spinner
                    diameter="50"
                    style="left: 47%; top: 50%; position: relative"
                    *ngIf="isLoading"
                    strokeWidth="5"
                >
                </mat-spinner>

                <div
                    fxFlex="0 0  auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="1em"
                >
                    <div
                        class="field"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="0px"
                    >
                        <div
                            fxFlex="0 0 auto"
                            fxLayoutAlign="start stretch"
                            fxLayout.lt-md="column"
                            fxLayout.gt-sm="row"
                            fxLayoutGap.gt-sm="5px"
                            fxLayoutGap.lt-md="0px"
                        >
                            <!-- change status -->

                            <fieldset fxFlex="100%">
                                <mat-label>
                                    {{ "Name" | translate }}

                                    <span class="text-red-600">*</span>
                                </mat-label>

                                <input
                                    formControlName="name"
                                    type="text"
                                    class="form-control"
                                />

                                <!-- <mat-error
                                    *ngIf="
                                        form.get('name').hasError('required')
                                    "
                                >
                                    {{ "Name is required" | translate }}
                                </mat-error> -->
                            </fieldset>
                            <fieldset fxFlex="100%">
                                <mat-label>
                                    {{ "Name(Ar)" | translate }}

                                    <span class="text-red-600">*</span>
                                </mat-label>

                                <input
                                    formControlName="name_ar"
                                    type="text"
                                    class="form-control"
                                />

                                <!-- <mat-error
                                    *ngIf="
                                        form.get('name_ar').hasError('required')
                                    "
                                >
                                    {{ "Name Arabic is required" | translate }}
                                </mat-error> -->
                            </fieldset>
                        </div>
                    </div>

                    <!-- action -->

                    <div
                        fxFlex="1 1 auto"
                        fxLayoutAlign="flex-end stretch"
                        fxLayout="row"
                        fxLayoutGap="1em"
                    >
                        <button
                            [mat-dialog-close]="false"
                            mat-stroked-button
                            color="warn"
                        >
                            <mat-icon class="save-cancel-btn" matPrefix
                                >close</mat-icon
                            >
                            {{ "Close" | translate }}
                        </button>
                        <button
                            (click)="submit()"
                            type="submit"
                            mat-flat-button
                            class="primary-bg-color"
                        >
                            <mat-icon class="save-cancel-btn mr-8" matPrefix
                                >send</mat-icon
                            >{{ "Update" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
