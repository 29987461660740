import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";

@Component({
    selector: "app-success-page",
    templateUrl: "./success-page.component.html",
    styleUrls: ["./success-page.component.scss"],
})
export class SuccessPageComponent implements OnInit, AfterViewInit {
    @Input() selectedBlock: any;
    @Input() appDetail: any;
    @Output() back: EventEmitter<void> = new EventEmitter<any>();
    @Output() saveHander: EventEmitter<void> = new EventEmitter<any>();
    successForm: FormGroup;
    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private appService: AppService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.formInitialize();
    }

    formInitialize() {
        this.successForm = this.fb.group({
            language: [this.appDetail.language ? this.appDetail.language : "en"],
            success_message: [
                this.appDetail.success_message
                    ? this.appDetail.success_message
                    : null,
            ],
            success_message_en: [
                this.appDetail.success_message_en
                    ? this.appDetail.success_message_en
                    : null,
            ],
            success_message_ar: [
                this.appDetail.success_message_ar
                    ? this.appDetail.success_message_ar
                    : null,
            ],
        });
    }
    goBack() {
        let fromApp = true;
        if (!this.utilitiesService.formChanged$.value) {
            this.back.emit();
        } else {
            let content = `You have unsaved changes, Please save before changing the page`;
            let heading = "Alert";
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp == "yes") {
                } else if (resp == "no") {
                    this.utilitiesService.formChangedOrNot(false);
                    this.back.emit();
                }
            });
        }
    }
    save() {
        let content = `Are you sure, Do you want to update? `;
        let heading = "Update";
        let fromApp=false
        let size = this.utilitiesService.isMobileAlertModal();
        const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading ,fromApp},
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });
        dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
                let form = this.successForm.value;
                const resp = await this.appService
                    .addAppSettings(form)
                    .toPromise();

                if (resp) {
                    let successMsg = this.translate.instant(
                        "Success page settings updated successfully"
                    );
                    this.utilitiesService.showSuccessToast(successMsg);
                    this.saveHander.emit();
                }
            }
        });
    }
    ngAfterViewInit(): void {
        document.getElementById("main-page").scrollIntoView();
    }
}
