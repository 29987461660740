import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "../api.service";

@Injectable({
    providedIn: "root",
})
export class PriceRuleService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }
    getPriceRules(limit = 10, offset = 0, search = "", form = {},type?) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        if(type){
            params=params.set('type',type.toString())
        }
        return this.apiService.get(
            this.sharedService.priceRuleCURD,
            params,
            undefined
        );
    }
    getPriceRulesDetails(id) {
        return this.apiService.get(
            this.sharedService.priceRuleCURD + `${id}/`,
            undefined
        );
    }

    getPaymentMethod() {
        return this.apiService.get(this.sharedService.paymentMethod, undefined);
    }

    getBankList() {
        return this.apiService.get(this.sharedService.bankList, undefined);
    }

    getBankBrand() {
        return this.apiService.get(this.sharedService.bankBrand, undefined);
    }

    getBankScheme() {
        return this.apiService.get(this.sharedService.bankScheme, undefined);
    }

    addPriceRule(form) {
        return this.apiService.post(
            this.sharedService.priceRuleCURD,
            form,
            undefined
        );
    }
    deletePriceRule(id) {
        return this.apiService.delete(
            this.sharedService.priceRuleCURD + `${id}/`,
            undefined
        );
    }
    updatePriceRule(form, id) {
        return this.apiService.put(
            this.sharedService.priceRuleCURD + `${id}/`,
            form,
            undefined
        );
    }
}
