import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { PageService } from "app/services/pageService";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { first } from "rxjs/operators";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";

@Component({
    selector: "app-status",
    templateUrl: "./status.component.html",
    styleUrls: ["./status.component.scss"],
    animations: fuseAnimations,
})
export class StatusComponent implements OnInit, AfterViewInit {
    @Input() selectedBlock: any;
    @Input() appDetail: any;
    @Output() back: EventEmitter<void> = new EventEmitter<any>();
    @Output() saveHander: EventEmitter<void> = new EventEmitter<any>();
    statusForm: FormGroup;
    pages = [];
    status = [
        { name: "Active", id: 1 },
        { name: "Under Maintenance", id: 2 },
        { name: "Disabled", id: 3 },
        { name: "Catalog Mode", id: 4 },
    ];

    maintenanceNoteMessage = 'You Should Create Widget From Widgets Page and choose Maintenance Zone';

    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private appService: AppService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        private pageService: PageService,
        public translationService: TranslationService
    ) {
        // this.utilitiesService.autoSave.pipe(first()).subscribe((data) => {
        //     if (data == 15) {
        //         this.save();
        //     }
        // });
    }

    ngOnInit(): void {
        this.formInitialize();
        this.getPages();
    }
    async getPages() {
        const pages = await this.pageService.getPages(99999, 0).toPromise();
        if (pages) {
            this.pages = pages.results;
        }
    }

    formInitialize() {
        this.statusForm = this.fb.group({
            // language: ["en"],
            status: [this.appDetail.status ? this.appDetail.status : 1],
            maintenance_message: [
                this.appDetail.maintenance_message
                    ? this.appDetail.maintenance_message
                    : null,
            ],
            maintenance_message_en: [
                this.appDetail.maintenance_message_en
                    ? this.appDetail.maintenance_message_en
                    : null,
            ],
            maintenance_message_ar: [
                this.appDetail.maintenance_message_ar
                    ? this.appDetail.maintenance_message_ar
                    : null,
            ],
            maintenance_mode: [
                this.appDetail.maintenance_mode
                    ? this.appDetail.maintenance_mode
                    : false,
            ],
        });

        if (this.appDetail.status == 2) {
            let msg = this.statusForm.get("maintenance_message").value;
            this.statusForm.get("maintenance_message").setValue(+msg);
        }
        this.statusForm.valueChanges.subscribe((data) => {
            this.utilitiesService.formChangedOrNot(true);
        });
    }
    goBack() {
        let fromApp = true;
        if (!this.utilitiesService.formChanged$.value) {
            this.back.emit();
        } else {
            let content = `You have unsaved changes, Please save before changing the page`;
            let heading = "Alert";
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp == "yes") {
                } else if (resp == "no") {
                    this.utilitiesService.formChangedOrNot(false);
                    this.back.emit();
                }
            });
        }
    }
    save() {
        let content = `Are you sure, Do you want to update? `;
        let heading = "Update";
        let fromApp = false;
        let size = this.utilitiesService.isMobileAlertModal();
        const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });
        dialogRef.afterClosed().subscribe(async (resp) => {
            this.utilitiesService.autoSave.next(0);
            if (resp) {
                let form = this.statusForm.value;
                if (form.status == 2) {
                    form.maintenance_message = form.maintenance_message.toString();
                    form.maintenance_message_en = form.maintenance_message;
                }

                const resp = await this.appService
                    .addAppSettings(form)
                    .toPromise();

                if (resp) {
                    this.utilitiesService.formChangedOrNot(false);
                    let successMsg = this.translate.instant(
                        "Status updated successfully"
                    );
                    this.utilitiesService.showSuccessToast(successMsg);
                    this.saveHander.emit();
                }
            }
        });
    }
    ngAfterViewInit(): void {
        document.getElementById("toolbar").scrollIntoView();
    }
}
