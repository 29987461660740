import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class SocialPageService {
  apiDomain: any;

  constructor(
    private sharedService: SharedService,
    private http: HttpClient,
    private apiService: ApiService
  ) {
    this.apiDomain = this.sharedService.apiDomain;

  }


  savePageSettings(settings) {
   return  this.apiService.post(this.sharedService.socialPage, settings, undefined)
  }
  
  getPageSettings() {
    return  this.apiService.get(this.sharedService.socialPage)

  }

}
