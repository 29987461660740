export enum AnalyticsPlatformEnum {
    Google = 1,
    Facebook = 2,
    Tiktok = 3,
    SnapChat = 4
}


export enum AnalyticsPlatformSelected {
    Google = 'enable_server_analytics_ga',
    FaceBook = 'enable_server_analytics_snapchat',
    Tiktok = 'enable_server_analytics_tiktok',
    SnapChat = 'enable_server_analytics_snapchat',
    All = 'enable_server_analytics'
}

export enum FeedIntervalTime {
    HOURLY = 1,
    DAILY = 2,
    WEEKLY = 3,
    MONTHLY = 4
}