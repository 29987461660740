<div fxFlex="0 0 100%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
    <div fxFlex="0 0 auto" fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="1em">
        <label class="highlight-text-color">{{'View Documents' | translate}}</label>
        <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="1em">
            <mat-icon [mat-dialog-close]="false" class="cursor-pointer">close</mat-icon>
        </div>
    </div>
    <div fxFlex="0 0 100%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <ng-container >
            <ngx-doc-viewer [url]="url" viewer="google" style="width:100%;height:80vh;"></ngx-doc-viewer>
        </ng-container>
    </div>
</div>

<ng-template #noURL>
    <strong>{{'No Preview loaded' | translate}}</strong>
</ng-template>