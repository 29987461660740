<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
    <div fxFlex="0 0 auto" fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="1em">
        <label class="highlight-text-color">{{heading}}</label>
        <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="1em">
            <span style="font-size:11px" [ngClass]="data.formControl.value.length > 500 ? 'color-red':'' ">
                {{'Character Count :'}} {{data.formControl.value.length}} {{'/ 500'}}
            </span>
            <button *ngIf="inlineSave" (click)="save()" type="submit" mat-flat-button
                class="primary-bg-color">{{'Save' | translate}}</button>
            <mat-icon class="highlight-text-color cursor-pointer" (click)="close()">close</mat-icon>
        </div>
    </div>
    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
        <!-- <ngx-codemirror formControlName="css" fxFlex="1 1 auto" #codemirror
                            [options]="codeMirrorOptions">
                        </ngx-codemirror> -->
        <ace-editor [theme]="'clouds'" [formControl]="css" #editor class="max-height">
        </ace-editor>
    </div>
</div>