<div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch">
    <app-breadcrumbs [isEnableBack]="isEnableBack" [enableIcon]="enableIcon" [label]="label">
    </app-breadcrumbs>
    <div id="dashboard-project" class="page-layout simple right-sidebar" fxLayout="column">
        <div class="center" fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
            fusePerfectScrollbar>
            <div class="content p-16" fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column"
                fxLayoutGap="1em">
                <ng-container>
                    <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="end center" fxLayoutGap="1em">
                        <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="start start" fxLayoutGap="1em">
                            <app-clear-filter class="mt-4" [@animate]="{
                                    value: '*',
                                    params: { duration: '300ms', y: '50px' }
                                }" [form]="form">
                            </app-clear-filter>

                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="page.size" (selectionChange)="limitChange()">
                                    <mat-option [value]="5">5</mat-option>
                                    <mat-option [value]="10">10</mat-option>
                                    <mat-option [value]="25">25</mat-option>
                                    <mat-option [value]="50">50</mat-option>
                                    <mat-option [value]="100">100</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <app-common-filter (searchInput)="search($event)" fxHide.lt-md>
                            </app-common-filter>

                            <app-fab-button [bottomMargin]="false" [bgColorClass]="'primary-bg-color'"
                                (handleFabButtonClick)="AddDeliveryAgent()" [tooltip]="'Add Delivery Agent'"
                                [label]="'Add Delivery Agent'">
                            </app-fab-button>
                        </div>
                    </div>


                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                        [formGroup]="form">
                        <ngx-datatable (activate)="onActivateViewOrder($event)" [loadingIndicator]="isLoading"
                            [externalPaging]="true" [count]="page.totalElements" [limit]="page.size"
                            (page)="pageChange($event)" [offset]="page.pageNumber" [virtualization]="false"
                            [scrollbarH]="true" [rows]="deliveryAgentList" [cssClasses]="iconsCss"
                            class="material striped mt-8" [columnMode]="'force'" [headerHeight]="90" [rowHeight]="50"
                            [footerHeight]="'auto'">
                            <ngx-datatable-column name="{{ 'No' | translate }}" [width]="70">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer">
                                            {{ column.name }}
                                        </p>
                                    </div>
                                </ng-template>
                                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                    ngx-datatable-cell-template>
                                    <!-- <p>{{ row?.id }}</p> -->
                                    {{
                                    page.size * page.pageNumber +
                                    rowIndex +
                                    1
                                    }}
                                </ng-template>
                            </ngx-datatable-column>
                            <!-- 'invoice No -->
                            <ngx-datatable-column [width]="140" name="{{ 'Name' | translate }}">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer">
                                            {{ column.name }}
                                        </p>
                                        <input formControlName="name" type="text" class="form-control" />
                                    </div>
                                </ng-template>
                                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                    ngx-datatable-cell-template>
                                    <p>
                                        {{ row?.first_name }}
                                        {{ row?.last_name }}
                                    </p>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Contact Number -->
                            <ngx-datatable-column [width]="120" name="{{ 'Contact Number' | translate }}">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer">
                                            {{ column.name }}
                                        </p>
                                        <input formControlName="contact_no" type="text" class="form-control" />
                                    </div>
                                </ng-template>
                                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                    ngx-datatable-cell-template>
                                    <p>
                                        {{ row?.contact_no }}
                                    </p>
                                </ng-template>
                            </ngx-datatable-column>
                            <!-- email -->
                            <ngx-datatable-column [width]="120" name="{{ 'Email' | translate }}">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer">
                                            {{ column.name }}
                                        </p>
                                        <input formControlName="email" type="text" class="form-control" />
                                    </div>
                                </ng-template>
                                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                    ngx-datatable-cell-template>
                                    <p>
                                        {{ row?.email }}
                                    </p>
                                </ng-template>
                            </ngx-datatable-column>
                            <!-- statusName -->

                            <ngx-datatable-column [width]="90" name="{{ ' Status' | translate }}">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer">
                                            {{ column.name }}
                                        </p>
                                        <select formControlName="status" class="form-control">
                                            <option value=""></option>
                                            <option *ngFor="
                                                    let status of statusList
                                                " [value]="status?.id">
                                                {{ status?.name }}
                                            </option>
                                        </select>
                                    </div>
                                </ng-template>
                                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                    ngx-datatable-cell-template>
                                    <p>
                                        {{ row?.statusName }}
                                    </p>
                                </ng-template>
                            </ngx-datatable-column>

                            <!-- Actions -->
                            <ngx-datatable-column [width]="100" name="{{ 'Actions' | translate }}">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer">
                                            {{ "Actions" | translate }}
                                        </p>
                                    </div>
                                </ng-template>

                                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                    ngx-datatable-cell-template>
                                    <button mat-stroked-button mat-button [matMenuTriggerFor]="afterMenu">
                                        {{ "Actions" | translate }}
                                        <mat-icon title="View Detail" matPostfix>keyboard_arrow_down</mat-icon>
                                    </button>
                                    <mat-menu #afterMenu="matMenu" xPosition="after">
                                        <button class="action-btn-view" mat-menu-item
                                            (click)="editDeliveryAgent(row.id)">
                                            <mat-icon class="action-btn-view" title="Edit Delivery Agent" matPrefix>
                                                edit
                                            </mat-icon>
                                            {{ "Edit" | translate }}
                                        </button>

                                        <!-- change status -->
                                        <button class="action-btn-edit" mat-menu-item (click)="ChangeStatus(row)">
                                            <mat-icon class="action-btn-edit" title="Edit Slider"
                                                matPrefix>sync</mat-icon>
                                            {{ "Change Status" | translate }}
                                        </button>


                                        <button class="action-change-password" mat-menu-item
                                            (click)="resetPassword(row.user, row)">
                                            <mat-icon class="action-change-password" title="Edit Slider"
                                                matPrefix>lock_open</mat-icon>
                                            {{ "Reset Password" | translate }}
                                        </button>
                                    </mat-menu>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>