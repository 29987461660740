<div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
    <form fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em"
        [formGroup]="form">
        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 center-container" fxLayoutAlign="start stretch"
                    fxLayout="column" fxLayoutGap="1em">
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="16px">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap.gt-sm="5px"
                            fxLayoutGap.lt-md="0px">
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                fxLayout.gt-sm="row" fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="100%">
                                    <mat-label>{{'Type' | translate}}</mat-label>
                                    <mat-select [disabled]="!editable" formControlName="type">
                                        <mat-option *ngFor="let type of types" [value]="type?.id">{{type?.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="form.get('type').hasError('required')">
                                        {{'Type is required' | translate}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="100%">
                                    <mat-label>{{'Content Type' | translate}}</mat-label>
                                    <mat-select [disabled]="!editable" formControlName="content_type_id">
                                        <mat-option *ngFor="let model of models" [value]="model?.id">
                                            {{model?.model}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                fxLayout.gt-sm="row" fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">

                                <div fxFlex="1 1 auto">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Label (En)'| translate}}
                                        </mat-label>
                                        <input formControlName="label_en" matInput name="title">
                                        <mat-error *ngIf="form.get('label_en').hasError('required')">
                                            {{'Label English is required' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="1 1 auto">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Label (Ar)'| translate}}
                                        </mat-label>
                                        <input formControlName="label_ar" matInput name="title">
                                        <mat-error *ngIf="form.get('label_ar').hasError('required')">
                                            {{'Label Arabic is required' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="1 1 auto">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Sequence'| translate}}
                                        </mat-label>
                                        <input formControlName="sequence" matInput name="title">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div *ngIf="!checkType() && !isDate() && form.get('type').value!='hidden'" fxFlex="0 0 auto"
                                fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                                fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Default Value'| translate}}
                                    </mat-label>
                                    <input formControlName="default" matInput name="title">
                                </mat-form-field>
                            </div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                fxLayout.gt-sm="row" fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px"
                                *ngIf="form.get('type').value=='text' || form.get('type').value=='number'">
                                <div fxFlex="1 1 auto">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Minimum Length'| translate}}
                                        </mat-label>
                                        <input formControlName="minimum_length" type="number" matInput name="title">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="1 1 auto">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Maximum Length'| translate}}
                                        </mat-label>
                                        <input formControlName="maximum_length" type="number" matInput name="title">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="1 1 auto">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Regex'| translate}}
                                        </mat-label>
                                        <input formControlName="regex" matInput name="title">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                fxLayout.gt-sm="row" fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">

                                <div fxFlex="1 1 auto">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Help Text (En)'| translate}}
                                        </mat-label>
                                        <input formControlName="help_text" matInput name="title">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="1 1 auto">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Help Text (Ar)'| translate}}
                                        </mat-label>
                                        <input formControlName="help_text_ar" matInput name="title">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="pb-8" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                fxLayout.gt-sm="row" fxLayoutGap.gt-sm="2em" fxLayoutGap.lt-md="1em">
                                <mat-checkbox formControlName="required" class="example-margin">
                                    {{'Required' | translate}}</mat-checkbox>
                                <mat-checkbox formControlName="is_searchable" class="example-margin">
                                    {{'Is Searchable' | translate}}</mat-checkbox>
                                <mat-checkbox formControlName="show_in_datatable" class="example-margin">
                                    {{'Show In Datatable' | translate}}</mat-checkbox>
                                <mat-checkbox formControlName="is_enabled" class="example-margin">
                                    {{'Is Enabled' | translate}}</mat-checkbox>
                            </div>
                            <div class="pb-8" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                fxLayout.gt-sm="row" fxLayoutGap.gt-sm="2em" fxLayoutGap.lt-md="1em">
                                <mat-checkbox formControlName="is_condition" class="example-margin">
                                    {{'Condition' | translate}}</mat-checkbox>
                            </div>
                            <div *ngIf="form.get('is_condition').value" class="pb-8" fxFlex="0 0 auto"
                                fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                                fxLayoutGap="5px">
                                <mat-form-field appearance="outline" fxFlex="100%">
                                    <mat-label>{{'Condition Type' | translate}}</mat-label>
                                    <mat-select formControlName="condition_type">
                                        <mat-option [value]="1"> {{'Product' | translate}} </mat-option>
                                        <mat-option [value]="2"> {{'Category' | translate}} </mat-option>
                                        <mat-option [value]="3"> {{'Brand' | translate}} </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <ng-select (clear)="clear()" (search)="searchCondition($event)" [loading]="isLoading" [placeholder]="conditionType" fxFlex="100"
                                    [multiple]="true" formControlName="condition_values">
                                    <!-- <ng-container *ngIf="conditionValues?.length && isDetailDone"> -->
                                        <!-- <ng-template ng-header-tmp>
                                            <div>
                                                <button style="line-height:2 !important" (click)="selectAll()"
                                                    mat-stroked-button>Select All</button>
                                            </div>
                                        </ng-template> -->
                                    <!-- </ng-container> -->
                                    <ng-option *ngFor="let condition of conditionValues" [value]="condition?.id">
                                        {{condition?.name}}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div *ngIf="checkType() && !isDate() && form.get('type').value!='hidden'" fxFlex="1 1 auto"
                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                                <div fxFlex="1 1 auto" fxLayoutAlign="space-between center" fxLayout="row"
                                    fxLayoutGap="5px" style="padding-bottom: 20px;">
                                    <label>{{'Possible Values' | translate}}</label>
                                    <div class="add-btn cursor-pointer" fxFlex="0 0 auto" fxLayoutAlign="center center">
                                        <mat-icon class="add-icon" (click)="addPossibleValues()"
                                            title="{{'Add Possible Values' | translate}}">add
                                        </mat-icon>
                                    </div>
                                </div>
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                    fxLayout.gt-sm="row" fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px"
                                    *ngFor="let possibleFormGroup of form.get('possible_values')['controls'];let i = index"
                                    [formGroup]="possibleFormGroup">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Value (En)'| translate}}
                                        </mat-label>
                                        <input id="{{'en'+i}}" matInput name="title"
                                            [formControl]="possibleFormGroup.get('name_en')">
                                        <mat-error *ngIf="possibleFormGroup.get('name_en').hasError('required')">
                                            {{'Value English is required' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Value (Ar)'| translate}}
                                        </mat-label>
                                        <input id="{{'ar'+i}}" matInput name="title"
                                            [formControl]="possibleFormGroup.get('name_ar')">
                                        <mat-error *ngIf="possibleFormGroup.get('name_ar').hasError('required')">
                                            {{'Value English is required' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-checkbox (change)="defaultCheckboxChange(i)"
                                        [formControl]="possibleFormGroup.get('default')"
                                        [ngClass.gt-sm]="{'pt-8':true}">{{'Default' | translate}}
                                    </mat-checkbox>
                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="1em">
                                        <mat-icon (click)="deleteValues(i)" class="action-btn-delete cursor-pointer"
                                            [ngClass.gt-sm]="{'pt-8':true}">delete</mat-icon>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="1 1 auto" fxLayoutAlign="flex-end stretch" fxLayout.lt-md="column-reverse"
                        fxLayout.gt-sm="row" fxLayoutGap="5px">
                        <button mat-flat-button (click)="handleCancel()" class="secondary-bg-color">
                            <mat-icon>clear</mat-icon>
                            {{'Cancel' | translate}}
                        </button>
                        <button (click)="saveOrUpdate()" type="submit" mat-flat-button class="primary-bg-color">
                            <mat-icon>save</mat-icon>
                            {{'Save' | translate}}
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </form>
</div>