import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleFontsService {
  private renderer: Renderer2;

  apiUrl = 'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDrOThQsc1dIfZ1YTFQYt5dAX6B64kf3Rk';
  arabicApiUrl = 'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDrOThQsc1dIfZ1YTFQYt5dAX6B64kf3Rk&subset=arabic';
  fontUrl: string;

  constructor(
    private http: HttpClient,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  getFonts(): Observable<any> {
    return this.http.get(this.apiUrl);
  }

  getArabicFonts(): Observable<any> {
    return this.http.get(this.arabicApiUrl);
  }

  addFont(fontFamily: string): void {
    const link = this.renderer.createElement('link');
    this.renderer.setAttribute(link, 'rel', 'stylesheet');
    this.renderer.setAttribute(link, 'href', `https://fonts.googleapis.com/css?family=${fontFamily}`);
    this.renderer.appendChild(document.head, link);
  }

  getFontUrl(fontFamily): string {
    return this.fontUrl = `https://fonts.googleapis.com/css?family=${fontFamily}`;
  }
}
