<div
    fxFlex="0 0  auto"
    class="fuse-card auto-width p-16 center-container"
    fxLayoutAlign="start stretch"
    fxLayout="column"
    fxLayoutGap="1em"
>
    <label class="highlight-text-color">{{ title }}</label>
    <div
        fxFlex="0 0 auto"
        fxLayoutAlign="start stretch"
        fxLayout="column"
        fxLayoutGap="0px"
    >
        <div
            fxFlex="1 1 auto"
            fxLayoutAlign="start stretch"
            fxLayout.gt-sm="column"
            fxLayout.lt-md="column"
            fxLayoutGap="1em"
        >
            <div fxFlex="1 1 auto">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label class="text-capitalized">
                        {{ title }}
                    </mat-label>
                    <input
                        matTooltip="{{ 'help_text_page_title' | translate }}"
                        [formControl]="contrl"
                        matInput
                        name="meta_tag"
                    />
                </mat-form-field>
            </div>

            <div fxFlex="1 1 auto" class="keyword-selector">
                <mat-chip-list #chipList>
                    <mat-chip
                        (click)="onKeywordSelection(item.value)"
                        *ngFor="let item of pageTitleKeyword; let indx = index"
                        [selectable]="true"
                        [removable]="false"
                    >
                        {{ item.name }}
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </div>
</div>
