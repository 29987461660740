import { HttpParams } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ApiService } from "app/services/api.service";
import { UtilitiesService } from "app/services/utilitiesService";

import { BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
    selector: "app-ng-select",
    templateUrl: "./app-ng-select.component.html",
    styleUrls: ["./app-ng-select.component.scss"],
})
export class AppNgSelectComponent implements OnInit {
    @Input() api: string;
    @Input() control: FormControl;
    @Input() title: string;
    @Input() enableAdd: boolean = false;
    @Input() showLabel: boolean = true;

    @Output() add = new EventEmitter();

    form: FormGroup;
    searchedKey$: BehaviorSubject<any> = new BehaviorSubject({});
    isLoading = false;
    items: any;

    constructor(
        private apiService: ApiService,
        private utilitiesService: UtilitiesService,
        private fb: FormBuilder
    ) {
        console.log("title is" + this.title);
    }

    ngOnInit(): void {
        const value = this.control?.value;
        this.form = this.fb.group({
            selected_item: value ? Number(value) : value,
        });
        const obj = {
            api: this.api,
            searchKey: "",
            id: value,
        };
        this.getData(obj);
        this.form.controls.selected_item.valueChanges.subscribe((res) => {
            this.control.setValue(res);
        });

        this.searchedKey$
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(async (val) => {
                console.log("this.searchedKey$");
                console.log(val);
                if (val && val?.searchKey !== "" && val?.api) {
                    try {
                        this.getData(val);
                    } catch {
                        this.isLoading = false;
                        this.utilitiesService.stopLoader();
                    } finally {
                    }
                }
            });
    }

    async getData(val) {
        const data = await this.getSearchedData(val).toPromise();
        // console.log("getData");
        // console.log(data);
        if (data) {
            this.isLoading = false;
            this.items = data?.results ? data?.results : data;
            // console.log(this.items);
        }
    }

    getSearchedData(searchData) {
        let params = new HttpParams();
        if (searchData.searchKey) {
            params = params.set("name", searchData.searchKey);
        }
        if (searchData.id) {
            params = params.set("ids", searchData.id);
        }
        params = params.set("limit", "9");

        return this.apiService.get(searchData.api, params, undefined);
    }

    async inputValue(value, api, id?) {
        const obj = {
            api: api,
            searchKey: value,
            id: id ? id : null,
        };

        this.searchedKey$.next(obj);
    }

    addNewItem() {
        this.add.emit();
    }
}
