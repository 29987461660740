<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
            [formGroup]="priceForm">
            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back</mat-icon>
                    <label class="highlight-text-color">{{'Price Rule'}}</label>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <button (click)="save()" type="submit" mat-flat-button style="position: absolute;right: 16px;"
                        class="primary-bg-color">{{'Save' | translate}}</button>
                </div>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                [@animate]="{value:'*',params:{duration:'400ms',y:'70px'}}">
                <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 center-container" fxLayoutAlign="start stretch"
                    fxLayout="column" fxLayoutGap="1em">
                    <label class="highlight-text-color">{{'Price Rule Settings' | translate}}</label>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row" fxLayout.lt-md="column"
                            fxLayoutGap="1em">
                            <div fxFlex="1 0 0px">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Discount'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_Discount' | translate}}" type="number"
                                        formControlName="discount" matInput name="title">
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 0 0px">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Discount Type'| translate}}
                                    </mat-label>
                                    <mat-select matTooltip="{{'help_text_Discount_Type' | translate}}"
                                        formControlName="discount_type">
                                        <mat-option *ngFor="let type of discountType" [value]="type.id">
                                            {{type.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 0 0px">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Minimum Order Amount'| translate}}
                                    </mat-label>
                                    <input matTooltip="{{'help_text_Minimum_Order_Amount' | translate}}" type="number"
                                        formControlName="minimum_order_amount" matInput name="title">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>