<h3 class="section-heading" [dir]="direction">{{'Statistics' | translate}}</h3>

<div fxFlex="1 1 100%" fxLayout.lt-md="column" fxLayoutAlign="space-between center" class="statics-gridster">

    <gridster [options]="options" *ngIf="isDataLoaded">
        <gridster-item [item]="dashboard[0]">
            <div class="fuse-card p-8" fxLayout="column">
                <h4 class="main-heading" [dir]="direction">{{'Users and Visits' | translate}}</h4>
                <div fxLayout="row" fxLayoutAlign="space-between center" class="statistics-container">
                    <div class="inner-card" fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="space-between start">
                        <h6>{{'Visits' | translate}}</h6>
                        <p class="main-value disable-click">
                            {{statics?.visit_count | number:'1.0'}}
                        </p>
                    </div>
                    <div class="inner-card" fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="space-between start">
                        <h6>{{'Guest' | translate}}</h6>
                        <p class="main-value" (click)="navigateTo('customer-list','guest', statics?.guest_customers)">
                            {{statics?.guest_customers | number:'1.0'}}
                        </p>
                    </div>
                    <div class="inner-card" fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="space-between start">
                        <h6>{{'Registered' | translate}}</h6>
                        <p class="main-value"
                            (click)="navigateTo('customer-list','registered', statics?.registered_customers)">
                            {{statics?.registered_customers | number:'1.0'}}
                        </p>
                    </div>
                </div>
            </div>
        </gridster-item>

        <gridster-item [item]="dashboard[1]">
            <div class="fuse-card p-8" fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="main-heading" style="margin-top: -5px;">
                    <h4>{{'Orders' | translate}}</h4>

                    <div class="inner-card" fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="end center"
                        style="margin-top: 0; padding-top:0;">
                        <h6 class="mr-8">{{'No of Orders' | translate}}</h6>
                        <p class="main-value" (click)="navigateTo('orders', null,  statics?.total_orders)">
                            {{statics?.total_orders | number:'1.0'}}
                        </p>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" class="order-statitcs-container">

                    <div class="inner-card" fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="space-between start">
                        <h6>{{'AVG Order Amount' | translate}}</h6>
                        <p class="main-value disable-click">
                            {{statics?.average_amount | number:'1.3-3'}} <sup>{{statics?.currency?.code}}</sup>
                        </p>
                    </div>
                    <div class="inner-card" fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="space-between start">

                        <h6>{{'Total Sales' | translate}}</h6>
                        <p class="main-value disable-click">
                            {{statics?.total_sales | number:'1.3-3'}} <sup>{{statics?.currency?.code}}</sup>
                        </p>
                    </div>
                </div>
            </div>
        </gridster-item>

        <gridster-item [item]="dashboard[2]">
            <div class="fuse-card p-8" fxLayout="column">
                <h4 class="main-heading" [dir]="direction">{{'Cart Info' | translate}}</h4>
                <div fxLayout="row" fxLayoutAlign="space-between center" class=" statistics-container ">
                    <div class="inner-card" fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="space-between start">
                        <h6>{{'Pending Payment' | translate}}</h6>
                        <p class="main-value" (click)="navigateTo('cart', 'pending', statics?.payment_pending_carts)">
                            {{statics?.payment_pending_carts | number:'1.0'}}
                        </p>

                    </div>
                    <div class="inner-card" fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="space-between start">
                        <h6>{{'Abandoned' | translate}}</h6>
                        <p class="main-value" (click)="navigateTo('cart','abandoned', statics?.abandoned_carts)">
                            {{statics?.abandoned_carts | number:'1.0'}}
                        </p>
                    </div>
                    <div class="inner-card" fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="space-between start">
                        <h6>{{'Active' | translate}}</h6>
                        <p class="main-value" (click)="navigateTo('cart', 'active',statics?.active_carts)">
                            {{statics?.active_carts | number:'1.0'}}
                        </p>
                    </div>
                </div>
            </div>
        </gridster-item>
    </gridster>

</div>