<mat-spinner diameter="50" style="left: 47%;top: 50%;position: absolute;" *ngIf="loading" strokeWidth="5">
</mat-spinner>
<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form id="fuse-container" fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
            [formGroup]="designForm">
            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back</mat-icon>
                    <label class="highlight-text-color">{{'Design' | translate}}</label>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <button (click)="save()" type="submit" mat-flat-button style="position: absolute;right: 16px;"
                        class="primary-bg-color">{{'Save' | translate}}</button>
                </div>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                [@animate]="{value:'*',params:{duration:'400ms',y:'70px'}}">

                <div class="fuse-card auto-width p-16" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                    fxLayoutGap="1em">
                    <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                        <label class="highlight-text-color">{{'Site Identify' | translate}}</label>
                    </div>
                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                        fxLayoutGap="1em">
                        <div fxFlex="1 1 50%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                            <div class="fuse-card auto-width p-16 max-height" fxFlex="0 0 auto"
                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="space-between center"
                                    fxLayoutGap="5px">
                                    <label class="highlight-text-color">{{'Logo' | translate}}</label>
                                    <mat-icon (click)="deleteFile('logo')" *ngIf="designForm.get('logo').value"
                                        class="action-btn-delete cursor-pointer">close
                                    </mat-icon>
                                </div>
                                <div [@simpleFadeAnimation]="'in'" *ngIf="!designForm.get('logo').value"
                                    fxLayout="column" fxLayoutGap="8px" fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                    fxLayoutGap="5px">
                                    <app-cms-upload-media (afterUpload)="setFileToForm($event,'logo')"
                                        [isMultiSelect]="false" [formcontrol]="designForm.controls.logo" [index]="0">
                                    </app-cms-upload-media>
                                    <!-- <input accept="image/x-png,image/gif,image/jpeg" class="attach-input" type="file"
                                        #fileDropRef2 id="fileDropRef2"
                                        (change)="fileBrowseHandler($event.target.files,'logo')" />
                                    <label class="attach-label attach-label-file"
                                        for="fileDropRef2">{{'Browse for file' | translate}}</label> -->
                                    <!-- <label>{{'Or' | translate}}</label> -->
                                    <!-- <label (click)="openGallery(designForm.get('logo'))" class="attach-label">{{'Browse from media' | translate}}</label> -->
                                </div>
                                <div class="files-list cursor-pointer" fxFlex="0 0 auto" fxLayoutAlign="center center"
                                    fxLayoutGap="5px" fxLayout="column" *ngIf="designForm.get('logo').value">
                                    <app-show-all-type-media [@simpleFadeAnimation]="'in'"
                                        [url]="designForm.get('logo').value">
                                    </app-show-all-type-media>
                                    <!-- <img [@simpleFadeAnimation]="'in'" style="padding: 2px;"
                                        [src]="utilitiesService.bypassTrustedURL(designForm.get('logo').value)"> -->
                                </div>
                            </div>
                        </div>
                        <div fxFlex="1 1 50%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                            <div class="fuse-card auto-width p-16 max-height" fxFlex="0 0 auto"
                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="space-between center"
                                    fxLayoutGap="5px">
                                    <label class="highlight-text-color">{{'Fav Icon' | translate}}</label>
                                    <mat-icon (click)="deleteFile('fav')" *ngIf="designForm.get('favicon').value"
                                        class="action-btn-delete cursor-pointer">close
                                    </mat-icon>
                                </div>
                                <div [@simpleFadeAnimation]="'in'" *ngIf="!designForm.get('favicon').value"
                                    fxLayout="column" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                    <app-cms-upload-media (afterUpload)="setFileToForm($event,'fav')"
                                        [isMultiSelect]="false" [formcontrol]="designForm.controls.favicon" [index]="1">
                                    </app-cms-upload-media>
                                    <!-- <input accept="image/x-png,image/gif,image/jpeg" class="attach-input" type="file"
                                        #fileDropRef3 id="fileDropRef3"
                                        (change)="fileBrowseHandler($event.target.files,'fav')" />
                                    <label class="attach-label attach-label-file" for="fileDropRef3">{{'Browse for file'
                                        | translate}}</label> -->
                                    <!-- <label>{{'Or' | translate}}</label> -->
                                    <!-- <label (click)="openGallery(designForm.get('favicon'))" class="attach-label">{{'Browse from media' | translate}}</label> -->
                                </div>
                                <div class="files-list cursor-pointer" fxFlex="0 0 auto" fxLayoutAlign="center center"
                                    fxLayoutGap="5px" fxLayout="column" *ngIf="designForm.get('favicon').value">
                                    <app-show-all-type-media [@simpleFadeAnimation]="'in'"
                                        [url]="designForm.get('favicon').value">
                                    </app-show-all-type-media>
                                    <!-- <img [@simpleFadeAnimation]="'in'" style="padding: 2px;"
                                        [src]="utilitiesService.bypassTrustedURL(designForm.get('favicon').value)"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fuse-card auto-width p-16" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                    fxLayoutGap="1em">
                    <div fxFlex="0 0 auto" class="mb-0" fxLayoutAlign="space-between stretch" fxLayout="row"
                        fxLayoutGap="0px">
                        <label class="highlight-text-color">{{'Splash Screen Settings' | translate}}</label>
                        <mat-form-field appearance="outline" fxLayoutAlign="start flex-end">
                            <mat-select formControlName="language">
                                <mat-option value="en">{{'EN' | translate}}</mat-option>
                                <mat-option value="ar">{{'AR' | translate}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                        fxLayoutGap="1em">
                        <div fxFlex="1 1 50%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">

                            <mat-form-field fxFlex="0 0 auto" appearance="outline" fxFlex="100">
                                <mat-label>
                                    {{'Splash Screen BG' | translate}}</mat-label>
                                <input matInput formControlName="splash_screen_bg" />
                                <mat-icon (cpToggleChange)="openOrClose('splash')"
                                    (colorPickerSelect)="setColor('splash')" [cpOKButton]="true" [cpCancelButton]="true"
                                    [cpSaveClickOutside]="false" [cpPosition]="'bottom'"
                                    [(colorPicker)]="splash_screen_bg" class="color-icon cursor-pointer"
                                    [style.color]="splash_screen_bg">
                                    color_lens
                                </mat-icon>
                                <!-- <input formControlName="splash_screen_bg" matInput name="title"> -->
                            </mat-form-field>
                            <div *ngIf="designForm.get('language').value=='en'" fxFlex="0 0 auto"
                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div class="fuse-card auto-width p-16 custom-width max-height" fxFlex="0 0 auto"
                                    fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                    <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="space-between center"
                                        fxLayoutGap="5px">
                                        <label class="highlight-text-color">{{'Image' | translate}}</label>
                                        <mat-icon (click)="deleteFile('splash')"
                                            *ngIf="designForm.get('splash_screen_logo_en').value"
                                            class="action-btn-delete cursor-pointer">close</mat-icon>
                                    </div>
                                    <div [@simpleFadeAnimation]="'in'"
                                        *ngIf="!designForm.get('splash_screen_logo_en').value" fxLayout="column"
                                        fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                        <app-cms-upload-media (afterUpload)="setFileToForm($event,'splash')"
                                            [isMultiSelect]="false"
                                            [formcontrol]="designForm.controls.splash_screen_logo_en" [index]="2">
                                        </app-cms-upload-media>
                                        <!-- <input accept="image/x-png,image/gif,image/jpeg" class="attach-input"
                                            type="file" #fileDropRef1 id="fileDropRef1"
                                            (change)="fileBrowseHandler($event.target.files,'splash')" />
                                        <label class="attach-label attach-label-file" for="fileDropRef1">{{'Browse for                                            file' | translate}}</label> -->
                                        <!-- <label>{{'Or' | translate}}</label> -->
                                        <!-- <label (click)="openGallery(designForm.get('splash_screen_logo'))" class="attach-label">{{'Browse from media' | translate}}</label> -->
                                    </div>
                                    <div class="files-list cursor-pointer" fxFlex="0 0 auto"
                                        fxLayoutAlign="center center" fxLayoutGap="5px" fxLayout="column"
                                        *ngIf="designForm.get('splash_screen_logo_en').value">
                                        <app-show-all-type-media [@simpleFadeAnimation]="'in'"
                                            [url]="designForm.get('splash_screen_logo_en').value">
                                        </app-show-all-type-media>
                                        <!-- <img [@simpleFadeAnimation]="'in'" style="padding: 2px;"
                                            [src]="utilitiesService.bypassTrustedURL(designForm.get('splash_screen_logo').value)"> -->
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="designForm.get('language').value=='ar'" fxFlex="0 0 auto"
                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div class="fuse-card auto-width p-16 custom-width max-height" fxFlex="0 0 auto"
                                    fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                    <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="space-between center"
                                        fxLayoutGap="5px">
                                        <label class="highlight-text-color">{{'Image' | translate}}</label>
                                        <mat-icon (click)="deleteFile('splash')"
                                            *ngIf="designForm.get('splash_screen_logo_ar').value"
                                            class="action-btn-delete cursor-pointer">close</mat-icon>
                                    </div>
                                    <div [@simpleFadeAnimation]="'in'"
                                        *ngIf="!designForm.get('splash_screen_logo_ar').value" fxLayout="column"
                                        fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                        <app-cms-upload-media (afterUpload)="setFileToForm($event,'splash')"
                                            [isMultiSelect]="false"
                                            [formcontrol]="designForm.controls.splash_screen_logo_ar" [index]="3">
                                        </app-cms-upload-media>
                                        <!-- <input accept="image/x-png,image/gif,image/jpeg" class="attach-input"
                                            type="file" #fileDropRef1 id="fileDropRef1"
                                            (change)="fileBrowseHandler($event.target.files,'splash')" />
                                        <label class="attach-label attach-label-file" for="fileDropRef1">{{'Browse for                                            file' | translate}}</label> -->
                                        <!-- <label>{{'Or' | translate}}</label> -->
                                        <!-- <label (click)="openGallery(designForm.get('splash_screen_logo_ar'))" class="attach-label">{{'Browse from media' | translate}}</label> -->
                                    </div>
                                    <div class="files-list cursor-pointer" fxFlex="0 0 auto"
                                        fxLayoutAlign="center center" fxLayoutGap="5px" fxLayout="column"
                                        *ngIf="designForm.get('splash_screen_logo_ar').value">
                                        <app-show-all-type-media [@simpleFadeAnimation]="'in'"
                                            [url]="designForm.get('splash_screen_logo_ar').value">
                                        </app-show-all-type-media>
                                        <!-- <img [@simpleFadeAnimation]="'in'" style="padding: 2px;"
                                            [src]="utilitiesService.bypassTrustedURL(designForm.get('splash_screen_logo_ar').value)"> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="1 1 50%" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                            fxLayoutGap="0px">
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                                *ngIf="designForm.get('language').value=='en'">
                                <label class="highlight-text-color  mt-8 splash-screen-preview">
                                    {{'Splash Screen Preview' | translate}}</label>
                                <div class="smartphone">
                                    <div class="top-border-design"></div>
                                    <div class="smartphone-inner" fxFlex="1 1 auto" fxLayoutAlign="center center"
                                        fxLayout="column" fxLayoutGap="0px"
                                        [ngStyle]="{'background-color': designForm.get('splash_screen_bg').value }"
                                        style="border-radius: 9px;border: 1px solid black;">
                                        <div fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayout="column"
                                            fxLayoutGap="0px">
                                            <img class="img-in-mobile"
                                                *ngIf="designForm.get('splash_screen_logo').value"
                                                [src]="utilitiesService.bypassTrustedURL(designForm.get('splash_screen_logo').value)">
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                                *ngIf="designForm.get('language').value=='ar'">
                                <label class="highlight-text-color mt-8 splash-screen-preview">
                                    {{'Splash Screen Preview' | translate}}</label>
                                <div class="smartphone">
                                    <div class="top-border-design"></div>
                                    <div class="smartphone-inner" fxFlex="1 1 auto" fxLayoutAlign="center center"
                                        fxLayout="column" fxLayoutGap="0px"
                                        [ngStyle]="{'background-color': designForm.get('splash_screen_bg').value }"
                                        style="border-radius: 9px;border: 1px solid black;">
                                        <div fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayout="column"
                                            fxLayoutGap="0px">
                                            <img class="img-in-mobile"
                                                *ngIf="designForm.get('splash_screen_logo_ar').value"
                                                [src]="utilitiesService.bypassTrustedURL(designForm.get('splash_screen_logo_ar').value)">
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="fuse-card auto-width p-16" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                    fxLayoutGap="0px">
                    <label class="highlight-text-color">{{'Color Palette' | translate}}</label>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                        fxLayoutGap="1em">
                        <mat-form-field fxFlex="0 0 auto" appearance="outline" fxFlex="100">
                            <mat-label>
                                {{'Primary Color' | translate}}</mat-label>
                            <input matInput formControlName="primary_color" />
                            <mat-icon (cpToggleChange)="openOrClose('primary')"
                                (colorPickerSelect)="setColor('primary')" [cpOKButton]="true" [cpCancelButton]="true"
                                [cpSaveClickOutside]="false" [cpPosition]="'bottom'" [(colorPicker)]="primary_color"
                                class="color-icon cursor-pointer" [style.color]="primary_color">
                                color_lens
                            </mat-icon>
                        </mat-form-field>
                        <mat-form-field fxFlex="0 0 auto" appearance="outline" fxFlex="100">
                            <mat-label>
                                {{'Secondary Color' | translate}}</mat-label>
                            <input matInput formControlName="secondary_color" />
                            <mat-icon (cpToggleChange)="openOrClose('secondary')"
                                (colorPickerSelect)="setColor('secondary')" [cpOKButton]="true" [cpCancelButton]="true"
                                [cpSaveClickOutside]="false" [cpPosition]="'bottom'" [(colorPicker)]="secondary_color"
                                class="color-icon cursor-pointer" [style.color]="secondary_color">color_lens</mat-icon>
                        </mat-form-field>
                        <mat-form-field fxFlex="0 0 auto" appearance="outline" fxFlex="100">
                            <mat-label>
                                {{'Text Color' | translate}}</mat-label>
                            <input matInput formControlName="text_color" />
                            <mat-icon (cpToggleChange)="openOrClose('text')" (colorPickerSelect)="setColor('text')"
                                [cpOKButton]="true" [cpCancelButton]="true" [cpSaveClickOutside]="false"
                                [cpPosition]="'bottom'" [(colorPicker)]="text_color" class="color-icon cursor-pointer"
                                [style.color]="text_color">color_lens</mat-icon>
                        </mat-form-field>
                        <!-- <mat-form-field fxFlex="0 0 auto" appearance="outline" fxFlex="100">
                            <mat-label>
                                {{'Primary Color' | translate}}</mat-label>
                            <input formControlName="primary_color" matInput name="title">
                        </mat-form-field>
                        <mat-form-field fxFlex="0 0 auto" appearance="outline" fxFlex="100">
                            <mat-label>
                                {{'Secondary Color' | translate}}</mat-label>
                            <input formControlName="secondary_color" matInput name="title">
                        </mat-form-field>
                        <mat-form-field fxFlex="0 0 auto" appearance="outline" fxFlex="100">
                            <mat-label>
                                {{'Text Color' | translate}}</mat-label>
                            <input formControlName="text_color" matInput name="title">
                        </mat-form-field> -->
                    </div>
                </div>
                <div class="fuse-card auto-width p-16" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                    fxLayoutGap="1em">
                    <label class="highlight-text-color">{{'Layout Settings' | translate}}</label>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                        fxLayoutGap="1em">

                        <mat-tab-group class="remove-border-bottom" animationDuration="700ms" fxFlex="1 1 100%">

                            <mat-tab [label]="'Theme' | translate">
                                <!-- <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" >
                                    <div class="mb-10">
                                        <mat-label class="medium-color">{{'Theme Settings' | translate}}</mat-label>
                                    </div>

                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Themes'| translate}}
                                        </mat-label>
                                        <mat-select matTooltip="{{'help_text_Theme' | translate}}"
                                            formControlName="theme">
                                            <mat-option [value]="1">
                                                {{'Theme 1' | translate}} </mat-option>
                                            <mat-option [value]="2">
                                                {{'Theme 2' | translate}} </mat-option>
                                            <mat-option [value]="3">
                                                {{'Theme 3' | translate}} </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div> -->

                                <div fxFlex="1 1 auto" class="mb-20 mt-5">
                                    <div class="mb-10">
                                        <mat-label class="medium-color">
                                            {{'Template Design' | translate}}
                                        </mat-label>
                                    </div>

                                    <mat-radio-group aria-label="Select an option" formControlName="template_design"
                                        fxLayoutGap="1em">
                                        <ng-container *ngFor="let temp of templateDesign">
                                            <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                                <img [src]="temp.img" width="150" height="150">
                                                <mat-radio-button [value]="temp.id">
                                                    {{temp.name}}
                                                </mat-radio-button>
                                            </div>
                                        </ng-container>
                                    </mat-radio-group>

                                </div>

                                <div fxFlex="1 1 auto">
                                    <div class="mb-10">
                                        <mat-label class="medium-color">
                                            {{'Container Layout' | translate}}
                                        </mat-label>
                                    </div>
                                    <mat-radio-group aria-label="Select an option" formControlName="container_layout"
                                        fxLayoutGap="1em">
                                        <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                            <img src="assets/images/layouts/box.jpg" width="150" height="150">
                                            <mat-radio-button [value]="1">
                                                {{'Box' | translate}}
                                            </mat-radio-button>
                                        </div>

                                        <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                            <img src="assets/images/layouts/full-width.jpg" width="150" height="150">
                                            <mat-radio-button [value]="2">
                                                {{'Full Width' | translate}}
                                            </mat-radio-button>
                                        </div>
                                    </mat-radio-group>

                                </div>

                                <!-- <div fxFlex="1 0 auto" fxLayoutAlign="space-between stretch" class="mt-20">
                                    <mat-label class="text-bold text-header">
                                        {{'Show Side Menu on Desktop' | translate}}
                                    </mat-label>
                                    <mat-slide-toggle formControlName="enable_side_menu_on_desktop" class="big-button">
                                    </mat-slide-toggle>
                                </div> -->

                                <div fxFlex="1 0 auto" fxLayoutAlign="space-between stretch" class="mt-20">
                                    <div fxFlex="0 0 auto" fxLayout="column">
                                        <mat-label class="text-bold text-header">
                                            {{'Show Quick Purchase Pop up' | translate}}
                                        </mat-label>
                                        <mat-label class="medium-color">
                                            {{'To be able to show quick purchase pop up you should use product card design 2' | translate}}
                                        </mat-label>
                                    </div>
                                    <mat-slide-toggle formControlName="enable_quick_purchase_popover"
                                        class="big-button">
                                    </mat-slide-toggle>
                                </div>

                                <div fxFlex="1 0 auto" fxLayoutAlign="space-between stretch" class="mt-20">
                                    <mat-label class="text-bold text-header">
                                        {{'Enable review order page' | translate}}
                                    </mat-label>
                                    <mat-slide-toggle formControlName="enable_review_page" class="big-button">
                                    </mat-slide-toggle>
                                </div>
                            </mat-tab>

                            <mat-tab [label]="'Header' | translate">

                                <mat-radio-group aria-label="Select an option" formControlName="header_layout"
                                    fxLayoutGap="1em">
                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                        <img src="assets/images/layouts/header1.jpg" width="150" height="150">
                                        <mat-radio-button [value]="1">
                                            {{'Design' | translate}} 1
                                        </mat-radio-button>
                                    </div>

                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                        <img src="assets/images/layouts/header2.jpg" width="150" height="150">
                                        <mat-radio-button [value]="2">
                                            {{'Design' | translate}} 2
                                        </mat-radio-button>
                                    </div>

                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                        <img src="assets/images/layouts/header3.jpg" width="150" height="150">
                                        <mat-radio-button [value]="3">
                                            {{'Design' | translate}} 3
                                        </mat-radio-button>
                                    </div>

                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                        <img src="assets/images/layouts/header4.jpg" width="150" height="150">
                                        <mat-radio-button [value]="4">
                                            {{'Design' | translate}} 4
                                        </mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </mat-tab>

                            <mat-tab [label]="'Product Page' | translate">

                                <mat-radio-group aria-label="Select an option" formControlName="product_page_layout"
                                    fxLayoutGap="1em">
                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                        <img src="assets/images/layouts/ProductPage1.jpg" width="150" height="150">
                                        <mat-radio-button [value]="1">
                                            {{'Design' | translate}} 1
                                        </mat-radio-button>
                                    </div>

                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                        <img src="assets/images/layouts/ProductPage2.jpg" width="150" height="150">
                                        <mat-radio-button [value]="2">
                                            {{'Design' | translate}} 2
                                        </mat-radio-button>
                                    </div>

                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                        <img src="assets/images/layouts/ProductPage3.jpg" width="150" height="150">
                                        <mat-radio-button [value]="3">
                                            {{'Design' | translate}} 3
                                        </mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </mat-tab>

                            <mat-tab [label]="'Product card' | translate">

                                <mat-radio-group aria-label="Select an option" formControlName="product_card_layout"
                                    fxLayoutGap="1em">
                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                        <img src="assets/images/layouts/Productcard1.jpg" width="150" height="150">
                                        <mat-radio-button [value]="1">
                                            {{'Design' | translate}} 1
                                        </mat-radio-button>
                                    </div>

                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                        <img src="assets/images/layouts/Productcard2.jpg" width="150" height="150">
                                        <mat-radio-button [value]="2">
                                            {{'Design' | translate}} 2
                                        </mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </mat-tab>

                            <!-- <mat-tab [label]="'Footer' | translate">
                                <mat-radio-group aria-label="Select an option" formControlName="footer_layout"
                                    fxLayoutGap="1em">
                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em" class="footer-design">
                                        <img src="https://preview.keenthemes.com/keen/demo1/assets/media/misc/layout/dark-sidebar.png"
                                            width="150" height="150">
                                        <mat-radio-button [value]="1" (click)="selectFooter(footerWidgets[0])">
                                            {{'Design' | translate}} 1
                                        </mat-radio-button>
                                        <ng-container
                                            *ngIf="coreService.checkPermission(utilitiesService.Permission.WidgetUpdate)">
                                            <mat-icon title="Edit" class="edit-footer-icon"
                                                (click)="editFooter(footerWidgets[0])">edit
                                            </mat-icon>
                                        </ng-container>

                                    </div>

                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em" class="footer-design">
                                        <img src="https://preview.keenthemes.com/keen/demo1/assets/media/misc/layout/dark-sidebar.png"
                                            width="150" height="150">
                                        <mat-radio-button [value]="2" (click)="selectFooter(footerWidgets[1])">
                                            {{'Design' | translate}} 2
                                        </mat-radio-button>
                                        <ng-container
                                            *ngIf="coreService.checkPermission(utilitiesService.Permission.WidgetUpdate)">
                                            <mat-icon class="edit-footer-icon"
                                                (click)="editFooter(footerWidgets[1])">edit</mat-icon>
                                        </ng-container>

                                    </div>


                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em" class="footer-design">
                                        <img src="https://preview.keenthemes.com/keen/demo1/assets/media/misc/layout/dark-sidebar.png"
                                            width="150" height="150">
                                        <mat-radio-button [value]="3" (click)="selectFooter(footerWidgets[2])">
                                            {{'Design' | translate}} 3
                                        </mat-radio-button>
                                        <ng-container
                                            *ngIf="coreService.checkPermission(utilitiesService.Permission.WidgetUpdate)">
                                            <mat-icon class="edit-footer-icon"
                                                (click)="editFooter(footerWidgets[2])">edit</mat-icon>
                                        </ng-container>
                                    </div>


                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em" class="footer-design">
                                        <img src="https://preview.keenthemes.com/keen/demo1/assets/media/misc/layout/dark-sidebar.png"
                                            width="150" height="150">
                                        <mat-radio-button [value]="4" (click)="selectFooter(footerWidgets[3])">
                                            {{'Design' | translate}} 4
                                        </mat-radio-button>
                                        <ng-container
                                            *ngIf="coreService.checkPermission(utilitiesService.Permission.WidgetUpdate)">
                                            <mat-icon class="edit-footer-icon"
                                                (click)="editFooter(footerWidgets[3])">edit</mat-icon>
                                        </ng-container>

                                    </div>
                                </mat-radio-group>


                                <div fxFlex="1 0 auto" fxLayout="column" class="mt-20">
                                    <div fxLayoutAlign="space-between center" fxFlex="1 0 auto">
                                        <div fxFlex="0 0 auto" fxLayout="column">
                                            <mat-label class="text-bold text-header">
                                                {{'Show Qr Code' | translate}}
                                            </mat-label>

                                        </div>
                                        <mat-slide-toggle formControlName="show_paydo_network_qr" class="big-button"
                                            (change)="showQrCode($event)">
                                        </mat-slide-toggle>
                                    </div>

                                    <ng-container *ngIf="qrLink && designForm.get('show_paydo_network_qr').value">
                                        <div fxFlex="1 0 100" fxLayoutAlign="space-between center" class="mt-10">
                                            <mat-label style="font-size: 1em" fxLayoutAlign="start center"
                                                fxLayoutGap="0.5em">
                                                <span>{{qrLink}}</span>
                                                <mat-icon class="cursor-pointer"
                                                    matTooltip="use this link in footer widget as img src to show qr code">
                                                    error_outline
                                                </mat-icon>
                                            </mat-label>
                                            <button mat-icon-button (click)="copyUrl(qrLink)">
                                                <mat-icon matPrefix>file_copy</mat-icon>
                                            </button>
                                        </div>

                                    </ng-container>
                                </div>
                            </mat-tab> -->

                            <mat-tab [label]="'Search Bar' | translate">
                                <mat-radio-group aria-label="Select an option" formControlName="search_bar_layout"
                                    fxLayoutGap="1em">
                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                        <img src="assets/images/layouts/searchbar1.jpg" width="150" height="150">
                                        <mat-radio-button [value]="1">
                                            {{'Design' | translate}} 1
                                        </mat-radio-button>
                                    </div>

                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                        <img src="assets/images/layouts/searchbar2.jpg" width="150" height="150">
                                        <mat-radio-button [value]="2">
                                            {{'Design' | translate}} 2
                                        </mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </mat-tab>

                            <mat-tab [label]="'Checkout Footer Bar' | translate">
                                <mat-radio-group aria-label="Select an option" formControlName="checkout_footer_layout"
                                    fxLayoutGap="1em">
                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                        <img src="assets/images/layouts/checkoutdesign1.jpg" width="150" height="150">
                                        <mat-radio-button [value]="1">
                                            {{'Design' | translate}} 1
                                        </mat-radio-button>
                                    </div>

                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                        <img src="assets/images/layouts/checkoutdesign2.jpg" width="150" height="150">
                                        <mat-radio-button [value]="2">
                                            {{'Design' | translate}} 2
                                        </mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </mat-tab>

                            <mat-tab [label]="'Menu' | translate">
                                <mat-radio-group aria-label="Select an option" formControlName="menu_layout"
                                    fxLayoutGap="1em">
                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                        <img src="assets/images/layouts/menu1.jpg" width="150" height="150">
                                        <mat-radio-button [value]="1">
                                            {{'Design' | translate}} 1
                                        </mat-radio-button>
                                    </div>

                                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="0.5em">
                                        <img src="assets/images/layouts/menu2.jpg" width="150" height="150">
                                        <mat-radio-button [value]="2">
                                            {{'Design' | translate}} 2
                                        </mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </mat-tab>
                        </mat-tab-group>

                    </div>
                </div>
                <div class="fuse-card auto-width p-16" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                    fxLayoutGap="1em">
                    <label class="highlight-text-color">{{'View Settings' | translate}}</label>

                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                        <div fxFlex="0 0 auto" fxLayoutAlign="space-between" fxLayout="row" fxLayoutGap="1em">
                            <label class="highlight-text-color">{{'Product Settings' | translate}}</label>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start start" fxLayout="row" fxLayoutGap="10px">
                                <mat-button-toggle-group formControlName="product_layout" name="fontStyle"
                                    aria-label="Font Style">
                                    <mat-button-toggle [value]="1">
                                        <mat-icon matTooltipPosition="below"
                                            matTooltip="{{'help_text_grid' | translate}}">grid_on</mat-icon>
                                    </mat-button-toggle>
                                    <mat-button-toggle [value]="2">
                                        <mat-icon matTooltipPosition="below"
                                            matTooltip="{{'help_text_list' | translate}}">list</mat-icon>
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                            fxLayout.gt-sm="row" fxLayoutGap="1em" *ngIf="designForm.get('product_layout').value=='1'">
                            <div fxFlex="1 1 auto">
                                <span class="highlight-text-color">
                                    <mat-icon class="highlight-text-color  mr-8">desktop_mac</mat-icon>
                                    {{'Large Screen' | translate}}
                                </span>

                                <mat-slider formControlName="cols_md" class="example-margin" [max]="'12'" [min]="'1'"
                                    [step]="'1'" [thumbLabel]="'true'" [tickInterval]="tickInterval">
                                </mat-slider>
                            </div>
                            <div fxFlex="1 1 auto">
                                <span class="highlight-text-color">
                                    <mat-icon class="highlight-text-color  mr-8">tablet_mac</mat-icon>
                                    {{'Small Screen' | translate}}
                                </span>

                                <mat-slider formControlName="cols_sm" class="example-margin" [max]="'12'" [min]="'1'"
                                    [step]="'1'" [thumbLabel]="'true'" [tickInterval]="tickInterval">
                                </mat-slider>

                            </div>
                            <div fxFlex="1 1 auto">
                                <span class="highlight-text-color">
                                    <mat-icon class="highlight-text-color  mr-8">phone_iphone</mat-icon>
                                    {{'Mobile Screen' | translate}}
                                </span>
                                <mat-slider formControlName="cols_xs" class="example-margin" [max]="'12'" [min]="'1'"
                                    [step]="'1'" [thumbLabel]="'true'" [tickInterval]="tickInterval">
                                </mat-slider>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                        <div fxFlex="0 0 auto" fxLayoutAlign="space-between" fxLayout="row" fxLayoutGap="1em">
                            <label class="highlight-text-color">{{'Category Settings' | translate}}</label>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start start" fxLayout="row" fxLayoutGap="10px">
                                <mat-button-toggle-group formControlName="category_layout" name="fontStyle"
                                    aria-label="Font Style">
                                    <mat-button-toggle [value]="1">
                                        <mat-icon matTooltipPosition="below"
                                            matTooltip="{{'help_text_grid' | translate}}">grid_on</mat-icon>
                                    </mat-button-toggle>
                                    <mat-button-toggle [value]="2">
                                        <mat-icon matTooltipPosition="below"
                                            matTooltip="{{'help_text_list' | translate}}">list</mat-icon>
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                            fxLayout.gt-sm="row" fxLayoutGap="1em" *ngIf="designForm.get('category_layout').value=='1'">
                            <div fxFlex="1 1 auto">
                                <span class="highlight-text-color">
                                    <mat-icon matTooltip="{{'help_text_design_LargeScreen' | translate}}"
                                        class="highlight-text-color mr-8">desktop_mac</mat-icon>
                                    {{'Large Screen' | translate}}
                                </span>
                                <mat-slider formControlName="category_cols_md" class="example-margin" [max]="'12'"
                                    [min]="'1'" [step]="'1'" [thumbLabel]="'true'" [tickInterval]="tickInterval">
                                </mat-slider>
                            </div>
                            <div fxFlex="1 1 auto">
                                <span class="highlight-text-color">
                                    <mat-icon matTooltip="{{'help_text_design_SmallScreen' | translate}}"
                                        class="highlight-text-color  mr-8">tablet_mac</mat-icon>
                                    {{'Small Screen' | translate}}
                                </span>

                                <mat-slider formControlName="category_cols_sm" class="example-margin" [max]="'12'"
                                    [min]="'1'" [step]="'1'" [thumbLabel]="'true'" [tickInterval]="tickInterval">
                                </mat-slider>

                            </div>
                            <div fxFlex="1 1 auto">
                                <span class="highlight-text-color">
                                    <mat-icon matTooltip="{{'help_text_design_MobileScreen' | translate}}"
                                        class="highlight-text-color  mr-8">phone_iphone</mat-icon>
                                    {{'Mobile Screen' | translate}}
                                </span>
                                <mat-slider formControlName="category_cols_xs" class="example-margin" [max]="'12'"
                                    [min]="'1'" [step]="'1'" [thumbLabel]="'true'" [tickInterval]="tickInterval">
                                </mat-slider>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                        <div fxFlex="0 0 auto" fxLayoutAlign="space-between" fxLayout="row" fxLayoutGap="1em">
                            <label class="highlight-text-color">{{'Brand Settings' | translate}}</label>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start start" fxLayout="row" fxLayoutGap="10px">
                                <mat-button-toggle-group formControlName="brand_layout" name="fontStyle"
                                    aria-label="Font Style">
                                    <mat-button-toggle [value]="1">
                                        <mat-icon matTooltipPosition="below"
                                            matTooltip="{{'help_text_grid' | translate}}">grid_on</mat-icon>
                                    </mat-button-toggle>
                                    <mat-button-toggle [value]="2">
                                        <mat-icon matTooltipPosition="below"
                                            matTooltip="{{'help_text_list' | translate}}">list</mat-icon>
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                            fxLayout.gt-sm="row" fxLayoutGap="1em" *ngIf="designForm.get('brand_layout').value=='1'">
                            <div fxFlex="1 1 auto">
                                <span class="highlight-text-color">
                                    <mat-icon matTooltip="{{'help_text_design_LargeScreen' | translate}}"
                                        class="highlight-text-color mr-8">desktop_mac</mat-icon>
                                    {{'Large Screen' | translate}}
                                </span>

                                <mat-slider formControlName="brands_cols_md" class="example-margin" [max]="'12'"
                                    [min]="'1'" [step]="'1'" [thumbLabel]="'true'" [tickInterval]="tickInterval">
                                </mat-slider>
                            </div>
                            <div fxFlex="1 1 auto">
                                <span class="highlight-text-color">
                                    <mat-icon matTooltip="{{'help_text_design_SmallScreen' | translate}}"
                                        class="highlight-text-color mr-8">tablet_mac</mat-icon>
                                    {{'Small Screen' | translate}}
                                </span>
                                <mat-slider formControlName="brands_cols_sm" class="example-margin" [max]="'12'"
                                    [min]="'1'" [step]="'1'" [thumbLabel]="'true'" [tickInterval]="tickInterval">
                                </mat-slider>

                            </div>
                            <div fxFlex="1 1 auto">
                                <span class="highlight-text-color">
                                    <mat-icon matTooltip="{{'help_text_design_MobileScreen' | translate}}"
                                        class="highlight-text-color mr-8">phone_iphone</mat-icon>
                                    {{'Mobile Screen' | translate}}
                                </span>
                                <mat-slider formControlName="brands_cols_xs" class="example-margin" [max]="'12'"
                                    [min]="'1'" [step]="'1'" [thumbLabel]="'true'" [tickInterval]="tickInterval">
                                </mat-slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fuse-card auto-width p-16" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                    fxLayoutGap="1em">
                    <div fxFlex="0 0 auto" fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="1em">
                        <label class="highlight-text-color">{{'Additional CSS' | translate}}</label>
                        <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="1em">
                            <span style="font-size:11px"
                                [ngClass]="designForm.get('css')?.value?.length > 500 ? 'color-red':'' ">
                                {{'Character Count :'}} {{designForm.get('css')?.value?.length}} {{'/ 500'}}
                            </span>
                            <mat-icon class="highlight-text-color cursor-pointer"
                                (click)="zoomAdditionalCss(this.designForm.controls.css)">zoom_out_map</mat-icon>
                        </div>
                    </div>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                        fxLayoutGap="1em">
                        <!-- <ngx-codemirror formControlName="css" fxFlex="1 1 auto" #codemirror
                            [options]="codeMirrorOptions">
                        </ngx-codemirror> -->
                        <ace-editor [formControl]="designForm.controls['css']" [theme]="'clouds'" #editor
                            style="height:20vh;">
                        </ace-editor>
                    </div>
                </div>
                <div class="fuse-card auto-width p-16" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                    fxLayoutGap="1em">
                    <label class="highlight-text-color">{{'Additional Design Settings' | translate}}</label>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em">
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                    fxLayout.gt-sm="row" fxLayoutGap="1em">
                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{'Add to cart caption (en)'| translate}}
                                            </mat-label>
                                            <input formControlName="add_to_cart_caption_en" matInput name="title">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{'Add to cart caption (ar)'| translate}}
                                            </mat-label>
                                            <input formControlName="add_to_cart_caption_ar" matInput name="title">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                    fxLayout.gt-sm="row" fxLayoutGap="1em">
                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{'Subscriber Label (en)'| translate}}
                                            </mat-label>
                                            <input formControlName="subscriber_label_en" matInput name="title">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{'Subscriber Label (ar)'| translate}}
                                            </mat-label>
                                            <input formControlName="subscriber_label_ar" matInput name="title">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <!-- <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                    fxLayout.gt-sm="row" fxLayoutGap="1em">
                                    <mat-slide-toggle formControlName="enable_side_menu_on_desktop">
                                        {{'Show Side Menu on Desktop' | translate}}
                                    </mat-slide-toggle>
                                </div>
                            </div> -->

                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>