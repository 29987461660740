import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";

@Component({
    selector: "app-image-size-warning",
    templateUrl: "./image-size-warning.component.html",
    styleUrls: ["./image-size-warning.component.scss"],
})
export class ImageSizeWarningComponent implements OnInit {
    img: any;
    img_url: any;
    img_size = 0;
    constructor(
        public dialogRef: MatDialogRef<ImageSizeWarningComponent>,
        private translationService: TranslationService,
        public utilitiesService: UtilitiesService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.img = data.img;
    }

    ngOnInit(): void {
        this.img_size = this.img.size / 1048576;
        this.convertImgToBase64();
    }

    convertImgToBase64() {
        if (this.img) {
            let reader = this.utilitiesService.convertImageToBase64(this.img);
            reader.onload = (readerEvt: any) => {
                this.img_url = readerEvt.target.result;
            };
        }
    }
    openSite(url) {
        window.open(url, "_blank");
    }
}
