import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-campaign-modal',
  templateUrl: './campaign-modal.component.html',
  styleUrls: ['./campaign-modal.component.scss']
})
export class CampaignModalComponent implements OnInit {

  form:FormGroup

  constructor(
    public dialogRef: MatDialogRef<CampaignModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb:FormBuilder
  ) { }

  ngOnInit(): void {

    this.form=this.fb.group({
      id:this.data.campaign_id || '',
      schedule_date:null,
      is_schedule:''

    })

    if(this.data.isSchedule){
      this.form.controls.schedule_date.setValidators(Validators.required)
      this.form.controls.schedule_date.updateValueAndValidity()
    }
  }

  yes(){

    let form=this.form.value
    delete form.is_schedule
    if(this.form.valid){
    this.dialogRef.close(form)
    }
  }

  no(){
   this.dialogRef.close()
  }



}
