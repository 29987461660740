<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" [formGroup]="form">
            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                [@animate]="{value:'*',params:{duration:'400ms',y:'70px'}}">
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                    <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 center-container"
                        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                fxLayout.gt-sm="row" fxLayoutGap="1em">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Name (En)'| translate}}
                                    </mat-label>
                                    <input formControlName="name" matInput name="title">
                                    <mat-error *ngIf="form.get('name_en').hasError('required')">
                                        {{'Name En is required' | translate}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Name (Ar)'| translate}}
                                    </mat-label>
                                    <input formControlName="name_ar" matInput name="title">
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div class="fuse-card auto-width p-16 max-height" style="box-shadow: none !important;"
                                    fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                    <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="space-between center"
                                        fxLayoutGap="5px">
                                        <label class="highlight-text-color">{{'Featured Image' | translate}}</label>
                                        <mat-icon (click)="deleteFileThumbail()" *ngIf="form.get('thumbnail').value"
                                            class="action-btn-delete cursor-pointer">close
                                        </mat-icon>
                                    </div>
                                    <div *ngIf="!form.get('thumbnail').value" fxLayout="column" fxFlex="0 0 auto"
                                        fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                        <app-cms-upload-media (afterUpload)="setFileToFormThumbail($event)"
                                            [isMultiSelect]="false" [formcontrol]="form.get('thumbnail')" [index]="0">
                                        </app-cms-upload-media>
                                        <!-- <input accept="image/x-png,image/gif,image/jpeg" class="attach-input" type="file" id="fileDropRef" (change)="fileBrowseHandlerTumbnail($event.target.files)" />
                                        <label class="attach-label attach-label-file" for="fileDropRef">{{'Browse for file' | translate}}</label>
                                        <label>{{'Or' | translate}}</label>
                                        <label (click)="openGallery(form.get('thumbnail'),false)" class="attach-label">{{'Browse from media' | translate}}</label> -->
                                    </div>
                                    <div class="files-list cursor-pointer" fxFlex="0 0 auto"
                                        fxLayoutAlign="center center" fxLayoutGap="5px" fxLayout="column"
                                        *ngIf="form.get('thumbnail').value">
                                        <app-show-all-type-media [url]="form.get('thumbnail').value">
                                        </app-show-all-type-media>
                                        <!-- <img class="thumbnail-img" style="padding: 2px;"
                                            [src]="utilitiesService.bypassTrustedURL(form.get('thumbnail').value)"> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="8px">
                            <button mat-flat-button (click)="handleCancel()" class="secondary-bg-color">{{'Cancel' |
                                translate}}</button>
                            <button (click)="save()" type="submit" mat-flat-button class="primary-bg-color">{{'Save' |
                                translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>