import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
import { ImageSizeWarningComponent } from "../image-size-warning/image-size-warning.component";

@Component({
    selector: "app-create-media",
    templateUrl: "./create-media.component.html",
    styleUrls: ["./create-media.component.scss"],
})
export class CreateMediaComponent implements OnInit {
    @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
    @Output() saveHander: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateHander: EventEmitter<any> = new EventEmitter<any>();
    label = "";
    mediaForm: FormGroup;
    isLoading: boolean = false;
    constructor(
        // public dialogRef: MatDialogRef<CreateMediaComponent>,
        private fb: FormBuilder,
        public utilitiesService: UtilitiesService,
        public translationService: TranslationService,
        private translate: TranslateService,
        public sanitizer: DomSanitizer,
        private dialog: MatDialog // @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    get isImage() {
        return false;
        // let img = this.files ? this.files[0] : null;
        // return img ? img.type.includes("image") : null || null;
    }
    async ngOnInit() {
        await this.formInitialize();
    }
    async formInitialize() {
        this.mediaForm = this.fb.group({
            name: [null],
            id: [null],
            file: [null, [Validators.required]],
            altext: [null],
            type: [null],
        });
    }

    clear() {
        this.mediaForm.reset();
    }
    save() {
        let form = this.mediaForm.value;
        if (form.file) {
            this.saveHander.emit(form);
        } else {
            let errorMsg = this.translate.instant(`File is mandatory`);
            this.utilitiesService.showErrorToast(errorMsg);
        }
    }
    onFileDropped($event) {
        this.prepareFilesList($event);
    }
    fileBrowseHandler(files) {
        this.prepareFilesList(files);
    }
    deleteFile() {
        this.mediaForm.reset();
    }
    uploadFilesSimulator(index: number) {}
    prepareFilesList(files: Array<any>) {
        let img = files[0] || null;
        this.mediaForm.patchValue({
            name: img.name || "No Name",
            file: img,
            type: img.type,
        });

        // if (img.size >= 3145728) {
        //     let isMobile = this.utilitiesService.isMobile();
        //     let size = {
        //         width: "60vw",
        //         height: "60vh",
        //     };
        //     if (isMobile) {
        //         size.height = "60%";
        //         size.width = "100%";
        //     }
        //     const dialogRef = this.dialog.open(ImageSizeWarningComponent, {
        //         data: { img },
        //         maxWidth: "",
        //         width: `${size.width}`,
        //         height: `${size.height}`,
        //     });
        //     dialogRef.afterClosed().subscribe((resp) => {
        //         if (resp) {
        //             for (let item of files) {
        //                 this.files.push(item);
        //             }
        //             let reader = this.utilitiesService.convertImageToBase64(
        //                 this.files[0]
        //             );
        //             this.mediaForm.controls.name.setValue(this.files[0].name);
        //             reader.onload = (readerEvt: any) => {
        //                 this.mediaForm.controls.file.setValue(
        //                     readerEvt.target.result
        //                 );
        //             };
        //             this.files = [];
        //             this.fileDropEl.nativeElement.value = "";
        //             this.uploadFilesSimulator(0);
        //         } else {
        //             this.fileDropEl.nativeElement.value = "";
        //             this.uploadFilesSimulator(0);
        //         }
        //     });
        // } else {
        //     for (let item of files) {
        //         this.files.push(item);
        //     }
        //     this.mediaForm.controls.name.setValue(this.files[0].name);
        //     let reader = this.utilitiesService.convertImageToBase64(
        //         this.files[0]
        //     );
        //     reader.onload = (readerEvt: any) => {
        //         this.mediaForm.controls.file.setValue(readerEvt.target.result);
        //     };
        //     this.fileDropEl.nativeElement.value = "";
        //     this.uploadFilesSimulator(0);
        // }
    }
}
