<div fxLayout="column" fxFlex="100" [formGroup]="form">
    <div class="p-16 pl-0" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
        <mat-icon>warning</mat-icon>
        <div style="font-size: 24px;">
            {{data.heading}}
        </div>
    </div>
    <div fxLayout="column">
        <!-- <div *ngIf="!data?.schedule_date" class="padding-10" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
            <mat-checkbox formControlName="is_schedule" class="font-size-12">
                {{'Do you want to schedule' | translate}}</mat-checkbox>
        </div> -->
        <div *ngIf="!data?.schedule_date && data.isSchedule">
            <mat-form-field appearance="outline" fxFlex="100%">
                <mat-label>
                    {{'Schedule'}}
                </mat-label>
                <input type="datetime-local" formControlName="schedule_date" matInput>
            </mat-form-field>
        </div>
    </div>
    <div class="p-16 pt-0">
        <span>{{data.content}} </span>
    </div>

    <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="center" fxLayoutGap="5px">

        <div>
            <button  (click)="no()" mat-stroked-button color="warn">
                <mat-icon class="save-cancel-btn" matPrefix>close</mat-icon> {{'No' | translate}}
            </button>
        </div>
        <div>
            <button mat-button color="primary" (click)="yes()"
                class="mat-focus-indicator mat-raised-button mat-button-base mat-accent">
                <mat-icon class="save-cancel-btn" matPrefix>check</mat-icon>
                {{'Yes' | translate}}
            </button>
        </div>
    </div>


</div>