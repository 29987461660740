import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ThemePalette } from "@angular/material/core";
import { fuseAnimations } from "@fuse/animations";
import { NotificationService } from "app/services/notification/notification.service";
import { CampaignURLTypesEnum } from "../create-campaign/create-campaign.component";
import { CampaignsService } from "app/services/marketing/campaigns.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { TranslationService } from "app/services/translationService";
import { TranslateService } from "@ngx-translate/core";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { ApiService } from "app/services/api.service";
import { PageService } from "app/services/pageService";
import { PriceRuleService } from "app/services/price-rules/price-rules.service";
import { includes } from "lodash";
import * as moment from "moment";
import { Clipboard } from "@angular/cdk/clipboard";
import { CampaignModalComponent } from "../campaign-modal/campaign-modal.component";
import { CampaignPreviewComponent } from "../campaign-preview/campaign-preview.component";
import { FullscreenAdditionCssComponent } from "../fullscreen-addition-css/fullscreen-addition-css.component";
import { distinctUntilChanged } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";

export interface campaign {
  id: number;
  name: string;
  title: string;
  key: string;
  selected: boolean;
  logo: string;
  params?: string;
}
enum campaignIDEnum {
  EMAIL = 1,
  SMS = 2,
  PUSH = 3,
}
@Component({
  selector: "app-add-campaign",
  templateUrl: "./add-campaign.component.html",
  styleUrls: ["./add-campaign.component.scss"],
  animations: fuseAnimations,
})
export class AddCampaignComponent implements OnInit {
  @Input() id: any;
  @Input() detailObject: any;
  @Input() elementTypes = [];
  @Input() page: string;
  @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
  @Output() afterSave: EventEmitter<void> = new EventEmitter<any>();
  campaignIDEnum = campaignIDEnum;
  campaignCreatingStage: boolean = true;
  successApiResponse: any;
  campaignDe: any;
  campaignForm: FormGroup;
  isLoading: boolean = false;
  campaigns: campaign[] = this.campaignsService.campaigns;
  campaignFieldsData = this.campaignsService.campaignFieldsData();
  selectedCampaign: campaign;
  notificationTemplate = [];
  codeEditorFormControl = new FormControl();
  loading: boolean = false;
  audienceList = [];
  priceRuleList = [];
  mediumsList = [];
  pagesList = [];
  campaignDetail: any;
  templateDetail: any;
  templateLimit: number = 5;
  ckeConfig = {
    versionCheck: false,
    allowedContent: true,
    removeButtons: "CreateDiv,BlockQuote,Superscript,Subscript,PasteFromWord,PasteText,Source",
  };
  campaignURLTypesEnum = CampaignURLTypesEnum;
  isAffiliatePage: boolean;
  campaignNameField = "Name";
  campaignCodeField = "Code";
  campaignUrlField = "URL Type";
  baseUrl: string;
  previewDetail: any;
  templateType = [
    {
      id: 1,
      name: "General",
    },
    {
      id: 2,
      name: "Personalized",
    },
  ];
  template_id: any;

  get urlSpecification(): FormGroup {
    return this.campaignForm.get("url_specification") as FormGroup;
  }

  get urlTypeFormControl(): FormControl {
    return this.campaignForm.get("url_specification").get("type") as FormControl;
  }

  get urlValueFormControl(): FormControl {
    return this.campaignForm.get("url_specification").get("value") as FormControl;
  }

  get mediumFormControl(): FormControl {
    return this.campaignForm.get("medium").get("medium") as FormControl;
  }

  constructor(
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private campaignsService: CampaignsService,
    private utilitiesService: UtilitiesService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private apiService: ApiService,
    private pageService: PageService,
    private priceRuleService: PriceRuleService,
    private clipboard: Clipboard,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit(): Promise<void> {
    this.formInit();
    if (this.id) {
      await this.getCampaignDetail();
    }
    await this.getAudience();
    this.baseUrl = await this.getBaseUrl();
    this.campaignForm.controls.template.valueChanges.pipe(distinctUntilChanged()).subscribe((template_id) => {
      if (template_id !== null) {
        this.getTemplateDetail(template_id);
      }
    });
    this.campaignForm.controls.use_existing_template.valueChanges.subscribe((val) => {
      if (val) {
        this.campaignForm.controls.title.disable();
      } else {
        this.campaignForm.controls.title.enable();
      }
    });

    // this.campaignForm.controls.templateLimit.setValue(5);
  }

  formInit() {
    this.campaignForm = this.fb.group({
      name: [null, [Validators.required]],
      campaign_channel_id: [1, [Validators.required]],
      audience: [null],
      templateLimit: [5],
      message: [null],
      template: [null],
      message_email: [null],
      title: [null],
      template_format: [1],
      use_existing_template: [false],
      re_usable: [false],
      code: [null],
      medium: [null],
      url_specification: this.fb.group({
        type: [null],
        value: [null],
        landing_page: [null],
      }),
      price_rule: [null],
      start_date: null,
      schedule_date: [""],
      is_schedule: [false],
      end_date: [""],
      budget: [""],
      actual_spend: [""],
      actual_start_date: [""],
      actual_end_date: [""],
      channel_reference: [""],
      actual_total_order: [null],
      template_name: [""],
    });
  }

  getCampaignsRequiredData() {
    this.getPriceRules();
    this.getPages();
  }

  async limitChange() {
    await this.getNotificationTemplate(this.selectedCampaign.params, this.campaignForm.controls.templateLimit.value);
  }

  async getCompaignField(key) {
    this.clearFormValidation();
    this.campaigns.forEach((campaign) => {
      if (campaign.key === key) {
        campaign.selected = true;
      } else {
        campaign.selected = false;
      }
    });
    this.selectedCampaign = this.campaigns.filter((campaign) => campaign.selected)[0];
    this.addValidation();
    this.campaignForm.patchValue({
      campaign_channel_id: this.selectedCampaign.id,
    });
    if (this.isOutBondCampaign()) {
      await this.getNotificationTemplate(this.selectedCampaign.params);
    } else {
      this.getCampaignsRequiredData();
    }
    this.getPriceRules();
  }

  clearFormValidation() {
    this.campaignForm.reset();
    Object.keys(this.campaignForm.controls).forEach((field) => {
      const control = this.campaignForm.get(field);
      control.clearValidators();
      control.updateValueAndValidity();
    });
  }

  addValidation() {
    const selectedMapping = this.campaignFieldsData[this.selectedCampaign?.key];
    if (selectedMapping) {
      let { fieldsToDisplay, mandatoryFields } = selectedMapping;

      if (mandatoryFields.length > 0) {
        mandatoryFields.forEach((field) => {
          const control = this.campaignForm.get(field);
          control.setValidators(Validators.required);
          control.updateValueAndValidity();
        });
      }
      let inboundkey = ["email", "sms", "push_notification"];
      if (inboundkey.includes(this.selectedCampaign?.key)) {
        let messgaeControl =
          this.selectedCampaign.key === "email" ? this.campaignForm.controls.message_email : this.campaignForm.controls.message;
        let templateControl = this.campaignForm.controls.template;

        if (this.campaignForm.controls.use_existing_template.value) {
          messgaeControl.clearValidators();
          messgaeControl.updateValueAndValidity();
          templateControl.setValidators(Validators.required);
          templateControl.updateValueAndValidity();
        } else {
          messgaeControl.setValidators(Validators.required);
          messgaeControl.updateValueAndValidity();
          templateControl.clearValidators();
          templateControl.updateValueAndValidity();
        }
      }
    }
  }

  async getBaseUrl() {
    return this.apiService.BASE_URL;
  }

  back() {
    this.cancelClick.emit();
  }

  backToDashboard() {
    if (this.id) {
      this.cancelClick.emit();
    } else {
      this.selectedCampaign.selected = false;
      this.campaignCreatingStage = true;
    }
  }

  templateCheckboxChange() {
    document.getElementById("toolbar").scrollIntoView();
  }

  async getNotificationTemplate(channel = "", templateLimit = 5) {
    let offset = this?.notificationTemplate?.length || 0;
    let limit = 5
      if(Number(templateLimit)  === 5){
        offset = 0;
        limit = 5
      }else if(Number(templateLimit) === 10){
        offset = 5;
        limit = 10
      }else if(Number(templateLimit) === 25){
        offset = 10;
        limit = 25
      } else if (Number(templateLimit) === 50){
        offset = 25;
        limit = 50
      } else if (Number(templateLimit) === 100){
        offset = 50;
        limit = 100
      } else {
        offset = 0;
        limit = 9999
      }
    try {
      this.isLoading = true;
      const notificationTemplate = await this.notificationService.getNotificationList(limit, offset, "", channel, true).toPromise();
      if (notificationTemplate) {
        this.notificationTemplate = [...notificationTemplate.results];
        this.isLoading = false;
      }
    } catch {
      this.isLoading = false;
    } finally {
    }
  }

  shouldDisplayContent(selectedCampaign, key) {
    const selectedMapping = this.campaignFieldsData[selectedCampaign.key];
    const { fieldsToDisplay, mandatoryFields } = selectedMapping;
    return fieldsToDisplay.includes(key);
  }

  async getAudience() {
    try {
      const audienceList = await this.campaignsService.getAudience().toPromise();
      if (audienceList) {
        this.audienceList = audienceList.results;
      }
    } catch {
    } finally {
    }
  }

  handleCancel() {
    this.back();
  }

  isOutBondCampaign() {
    let channel_id = this.campaignForm.controls.campaign_channel_id.value;
    let otherThanSocial_ids = [1, 2, 3];
    let value = false;
    if (otherThanSocial_ids.includes(channel_id)) {
      value = true;
    } else {
      value = false;
    }
    return value;
  }

  modifiedFormValue() {
    let form = this.campaignForm.getRawValue();
    let start_date = form.start_date ? this.utilitiesService.formatDate(form.start_date) : null;
    let end_date = form.end_date ? this.utilitiesService.formatDate(form.end_date) : null;
    let actual_start_date = form.actual_start_date ? this.utilitiesService.formatDate(form.actual_start_date) : null;
    let actual_end_date = form.actual_end_date ? this.utilitiesService.formatDate(form.actual_end_date) : null;
    this.campaignForm.patchValue({
      start_date: start_date,
      end_date: end_date,
      actual_start_date: actual_start_date,
      actual_end_date: actual_end_date,
      use_existing_template: form.use_existing_template || false,
    });
    if (form.use_existing_template) {
      this.campaignForm.patchValue({
        template_format: "",
        message: "",
        message_email: "",
        template_name: "",
      });
    } else {
      this.campaignForm.controls.template.setValue("");
    }
  }

  getCampaignDetail() {
    this.utilitiesService.startLoader();
    this.campaignsService.campaignDetail(this.id).subscribe((res) => {
      this.campaignDetail = res;
      this.campaigns.forEach((ele) => {
        if (ele.id === res.campaign_channel?.id) {
          this.getCompaignField(ele.key);
        }
      });
      // this.getTemplateDetail(res?.template)
      this.setFormValue(res);
      this.utilitiesService.stopLoader();
    });
  }

  setFormValue(campaignDetail) {
    this.campaignForm.patchValue({
      name: campaignDetail?.name,
      campaign_channel_id: campaignDetail?.campaign_channel?.id,
      audience: campaignDetail?.audience?.id,
      templateLimit: campaignDetail.templateLimit,
      message: [null],
      template: campaignDetail?.template,
      message_email: [null],
      title: "",
      template_format: "",
      use_existing_template: campaignDetail?.use_existing_template || false,
      re_usable: "",
      code: campaignDetail?.code,
      medium: campaignDetail?.medium,
      price_rule: campaignDetail?.price_rule?.id,
      start_date: campaignDetail?.start_date,
      schedule_date: campaignDetail?.schedule_date,
      end_date: campaignDetail?.end_date,
      budget: campaignDetail?.budget,
      actual_spend: campaignDetail?.actual_spend,
      actual_start_date: campaignDetail?.actual_start_date,
      actual_end_date: campaignDetail?.actual_end_date,
      channel_reference: campaignDetail?.channel_reference || null,
      actual_total_order: campaignDetail?.actual_total_order || null,
    });
    this.urlTypeFormControl.setValue(campaignDetail?.url_specification?.type);
    this.urlValueFormControl.setValue(campaignDetail?.url_specification?.value);

    if (campaignDetail.use_existing_template) {
      this.campaignForm.controls.use_existing_template.disable();
    } else {
      this.campaignForm.controls.use_existing_template.enable();
    }
    this.getTemplateDetail(campaignDetail?.template);
  }

  getTemplateDetail(template_id) {
    if (template_id) {
      this.campaignsService.getTemplateDetail(template_id).subscribe((res) => {
        if (res.template_type == "EMAIL") {
          this.campaignForm.patchValue({
            template: template_id,
            title: res?.title,
            template_format: res?.template_format,
            message_email: res?.body,
            template_name: res?.name,
            re_usable: res?.re_usable,
          });
        } else {
          this.campaignForm.patchValue({
            template: template_id,
            title: res?.title,
            template_format: res?.template_format,
            message: res?.body,
            template_name: res?.name,
            re_usable: res?.re_usable,
          });
        }
      });
    }
  }

  async saveOrUpdate() {
    this.addValidation();
    if (this.campaignForm.valid) {
      this.modifiedFormValue();
      this.utilitiesService.startLoader();
      let form = this.campaignForm.getRawValue();
      if (this.id) {
        await this.updateCampaign(form);
      } else {
        await this.saveCampaign(form);
      }
    } else {
      this.checkValidations();
    }
  }

  async updateCampaign(form) {
    let channel: any;
    let title: any = "";
    if (form.campaign_channel_id == campaignIDEnum.EMAIL) {
      channel = "EMAIL";
      title = form.title;
    } else if (form.campaign_channel_id == campaignIDEnum.SMS) {
      channel = "SMS";
    } else if (form.campaign_channel_id == campaignIDEnum.PUSH) {
      channel = "PUSH";
      title = form.title;
    }
    try {
      let templateForm = {
        title: title ? title : form.name,
        title_en: title ? title : form.name,
        title_ar: title ? title : form.name,
        body: channel == "EMAIL" ? form.message_email : form.message,
        body_en: channel == "EMAIL" ? form.message_email : form.message,
        body_ar: channel == "EMAIL" ? form.message_email : form.message,
        template_type: channel,
        template_format: form.template_format || 1,
        name: form.template_name || "",
        re_usable: form.re_usable,
      };
      if (!this.campaignForm.controls.use_existing_template.value && this.isOutBondCampaign()) {
        this.campaignsService.updateTemplate(templateForm, this.detailObject.template).subscribe((response) => {
          let campaignForm = form;
          campaignForm.template = response.id;
          this.campaignsService.updateCampaign(campaignForm, this.id).subscribe((data) => {
            this.successApiResponse = data;
            this.getTemplatePreview(data.template);
            this.campaignCreatingStage = false;
            this.utilitiesService.stopLoader();
            let successMsg = this.translate.instant(`Campaign is updated successfully`);
            this.utilitiesService.showSuccessToast(successMsg);
          });
        });
      } else {
        let campaignForm = form;
        this.campaignsService.updateCampaign(campaignForm, this.id).subscribe((data) => {
          this.successApiResponse = data;
          this.getTemplatePreview(data.template);
          this.campaignCreatingStage = false;
          this.utilitiesService.stopLoader();
          let successMsg = this.translate.instant(`Campaign is updated successfully`);
          this.utilitiesService.showSuccessToast(successMsg);
        });
      }
    } catch {
      let erroMsg = this.translate.instant("Api general Error");
      this.utilitiesService.showErrorToast(erroMsg);
      this.utilitiesService.stopLoader();
    } finally {
      this.utilitiesService.stopLoader();
    }
  }

  async saveCampaign(form) {
    let channel: any;
    let title: any = "";
    if (form.campaign_channel_id == campaignIDEnum.EMAIL) {
      channel = "EMAIL";
      title = form.title;
    } else if (form.campaign_channel_id == campaignIDEnum.SMS) {
      channel = "SMS";
    } else if (form.campaign_channel_id == campaignIDEnum.PUSH) {
      channel = "PUSH";
      title = form.title;
    }
    try {
      let templateForm = {
        title: title ? title : form.name,
        title_en: title ? title : form.name,
        title_ar: title ? title : form.name,
        body: channel == "EMAIL" ? form.message_email : form.message,
        body_en: channel == "EMAIL" ? form.message_email : form.message,
        body_ar: channel == "EMAIL" ? form.message_email : form.message,
        template_type: channel,
        template_format: form.template_format || 1,
        name: form.template_name || "",
        re_usable: form.re_usable || false,
      };
      if (!this.campaignForm.controls.use_existing_template.value && this.isOutBondCampaign()) {
        if (this.template_id) {
          this.campaignsService.updateTemplate(templateForm, this.template_id).subscribe((res) => {
            let campaignForm = form;
            campaignForm.template = res.id;
            this.campaignsService.addCampaign(campaignForm).subscribe((data) => {
              this.successApiResponse = data;
              this.getTemplatePreview(data.template);
              this.campaignCreatingStage = false;
              this.utilitiesService.stopLoader();
              let successMsg = this.translate.instant(`Campaign is created successfully`);
              this.utilitiesService.showSuccessToast(successMsg);
            });
          });
          this.template_id = null;
        } else {
          this.campaignsService.addTemplate(templateForm).subscribe((response) => {
            let campaignForm = form;
            campaignForm.template = response.id;
            this.campaignsService.addCampaign(campaignForm).subscribe((data) => {
              this.successApiResponse = data;
              this.getTemplatePreview(data.template);
              this.campaignCreatingStage = false;
              this.utilitiesService.stopLoader();
              let successMsg = this.translate.instant(`Campaign is created successfully`);
              this.utilitiesService.showSuccessToast(successMsg);
            });
          });
        }
      } else {
        let campaignForm = form;
        this.campaignsService.addCampaign(campaignForm).subscribe((data) => {
          this.successApiResponse = data;
          this.getTemplatePreview(data.template);
          this.campaignCreatingStage = false;
          this.utilitiesService.stopLoader();
          let successMsg = this.translate.instant(`Campaign is created successfully`);
          this.utilitiesService.showSuccessToast(successMsg);
        });
      }
    } catch {
      let erroMsg = this.translate.instant("Api general Error");
      this.utilitiesService.showErrorToast(erroMsg);
      this.utilitiesService.stopLoader();
    } finally {
      this.utilitiesService.stopLoader();
    }
  }

  async executeCampaign() {
    let name = this.successApiResponse?.name;
    let schedule_date = this.successApiResponse?.schedule_date ? new Date(this.successApiResponse?.schedule_date).toLocaleString() : "";
    let content = `Are you sure you want to execute this campaign " ${name} "
    ${this.successApiResponse?.schedule_date ? `on ${schedule_date}` : ""} ? `;
    let heading = "Execute";
    let fromApp = false;
    let campaign_id = this.successApiResponse?.id;
    let size = this.utilitiesService.isMobileAlertModal();
    const dialogRef = this.dialog.open(CampaignModalComponent, {
      data: { content, heading, fromApp, campaign_id, schedule_date },
      maxWidth: "",
      width: `${size.width}`,
      height: `auto`,
    });
    dialogRef.afterClosed().subscribe(async (resp) => {
      let form = resp;
      if (resp) {
        let resp = await this.campaignsService.executeTheCampaign(campaign_id, form).toPromise();
        if (resp) {
          let successMsg = this.translate.instant(`Campaign " ${name} " executed successfully`);
          this.utilitiesService.showSuccessToast(successMsg);
          this.back();
        }
      }
    });
  }

  cancelCampaign() {
    let campaign_id = this.successApiResponse.id;
    let name = this.successApiResponse.name;
    let content = `Are you sure, Do you want to cancel  ${name} ? `;
    let heading = "cancel";
    let fromApp = false;
    let size = this.utilitiesService.isMobileAlertModal();
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: { content, heading, fromApp },
      maxWidth: "",
      width: `${size.width}`,
      height: `${size.height}`,
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        let cancelMsg = this.translate.instant(`Campaign cancel successfully`);
        let requestBody = { id: campaign_id };
        this.campaignsService.cancelCampaign(requestBody).subscribe((data) => {
          this.utilitiesService.showSuccessToast(cancelMsg);
          this.back();
        });
      }
    });
  }

  async getPages() {
    if (this.pagesList.length) {
      return;
    }
    try {
      const pages = await this.pageService.getPages(999999, 0).toPromise();
      if (pages) {
        this.pagesList = pages.results;
      }
    } catch {}
  }

  async getPriceRules(limit = 100, offset = 0, type = 1) {
    if (this.priceRuleList.length) {
      return;
    }
    try {
      const priceRuleList = await this.priceRuleService.getPriceRules(limit, offset, undefined, undefined, type).toPromise();

      if (priceRuleList) {
        this.priceRuleList = priceRuleList.results;
      }
    } catch {
    } finally {
    }
  }

  copyUrl(urlValue) {
    this.clipboard.copy(urlValue);
    this.showCopyMessage();
  }

  showCopyMessage() {
    let copyText = this.translate.instant("Url Copied to clipboard!");
    this.utilitiesService.showSuccessToast(copyText);
  }

  preview() {
    let form = this.campaignForm.getRawValue();
    let channel: any;
    let title: any = "";
    let template_id = null;
    if (this.id) {
      template_id = form.template;
    } else {
      template_id = form.use_existing_template ? form.template : null;
    }
    if (form.campaign_channel_id == campaignIDEnum.EMAIL) {
      channel = "EMAIL";
      title = form.title;
    } else if (form.campaign_channel_id == campaignIDEnum.SMS) {
      channel = "SMS";
    } else if (form.campaign_channel_id == campaignIDEnum.PUSH) {
      channel = "PUSH";
      title = form.title;
    }
    let templateForm = {
      template_id: form.template || "",
      title: title ? title : form.name,
      title_en: title ? title : form.name,
      title_ar: title ? title : form.name,
      body: channel == "EMAIL" ? form.message_email : form.message,
      body_en: channel == "EMAIL" ? form.message_email : form.message,
      body_ar: channel == "EMAIL" ? form.message_email : form.message,
      template_type: channel,
      template_format: form.template_format || 1,
    };
    let body = "";

    let channel_id = this.campaignForm.controls.campaign_channel_id.value;
    if (!this.campaignForm.get("use_existing_template").value) {
      if (channel_id == 1) {
        body = this.campaignForm.controls.message_email.value;
      } else {
        body = this.campaignForm.controls.message.value;
      }
    } else {
      let templateBody = [];
      let template = this.campaignForm.get("template").value;
      templateBody = this.notificationTemplate
        .filter((data) => data.id == template)
        .map((res) => {
          return res.body;
        });
      body = templateBody[0];
    }

    const dialogRef = this.dialog.open(CampaignPreviewComponent, {
      data: { body, title, channel, templateForm, template_id },
      maxWidth: "",
      width: "100%",
      height: "100%",
    });
    dialogRef.afterClosed().subscribe(async (resp) => {
      if (resp) {
        // this.campaignForm.controls.template.setValue(resp.id)
        //  this.template_id = resp.id
      }
    });
  }

  zoomAdditionalCss(formControl) {
    let heading = "HTML";
    const dialogRef = this.dialog.open(FullscreenAdditionCssComponent, {
      data: { formControl, heading },
      maxWidth: "",
      width: "100%",
      height: "100%",
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        this.campaignForm.controls.message_email.setValue(resp.value);
      }
    });
  }

  checkValidations() {
    for (const controlName in this.campaignForm.controls) {
      if (this.campaignForm.controls.hasOwnProperty(controlName)) {
        const control = this.campaignForm.get(controlName);
        if (control.invalid) {
          let msg = `${this.transformControlName(controlName)} is required.`;
          this.utilitiesService.showErrorToast(msg);
          break; // Stop the loop as soon as an invalid control is found
        }
      }
    }

    Object.keys(this.campaignForm.controls).forEach((field) => {
      const control = this.campaignForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  transformControlName(controlName: string): string {
    return controlName
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  getTemplatePreview(template_id) {
    this.campaignsService.getTemplatePreview({ template_id: template_id }).subscribe((res) => {
      this.previewDetail = res;
    });
  }

  getBody() {
    return this.sanitizer.bypassSecurityTrustHtml(this.previewDetail?.body);
  }
}
