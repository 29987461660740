import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "../api.service";
import { Stats } from "fs";
import { campaign } from "app/main/components/add-campaign/add-campaign.component";
import { shareReplay } from "rxjs/operators";

export enum CampaignStatusEnum {
  DRAFT = 1,
  INITIATED = 2,
  COMPLETED = 3,
  FAILED = 4,
  PAUSED = 5,
  ARCHIVED = 6,
  SCHEDULED = 7,
  PUBLISHED = 8
}
interface CampaignStatusInfo {
  status: string;
  backgroundColor: string;
  color: string
}

@Injectable({
  providedIn: "root",
})
export class CampaignsService {
  apiDomain: any;
  campaigns: campaign[] = [
    {
      id: 1,
      name: "Email Campaign",
      title: "Email",
      key: 'email',
      selected: false,
      logo: 'assets/images/campaign/emailLogo.png',
      params: 'EMAIL'
    },

    {
      id: 4,
      name: "Affiliate Campaign",
      title: "Affiliate",
      key: 'affiliate',
      selected: false,
      logo: 'assets/images/campaign/affiliateLogo.png',
    },
    {
      id: 2,
      name: "SMS Campaign",
      title: "SMS",
      key: 'sms',
      selected: false,
      logo: 'assets/images/campaign/messageLogo.png',
      params: 'SMS'
    },
    {
      id: 3,
      name: "Push Notification Campaign",
      title: "Push Notification",
      key: 'push_notification',
      selected: false,
      logo: 'assets/images/campaign/smartphoneLogo.png',
      params: 'PUSH'
    },
    {
      id: 6,
      name: "Instagram Campaign",
      title: "Instagram",
      key: 'instagram',
      selected: false,
      logo: 'assets/images/campaign/instagramLogo.png',
    },
    {
      id: 5,
      name: "Facebook Campaign",
      title: "Facebook",
      key: 'facebook',
      selected: false,
      logo: 'assets/images/campaign/facebookLogo.png',
    },
    {
      id: 8,
      name: "Tiktok Campaign",
      title: "Tiktok",
      key: 'tiktok',
      selected: false,
      logo: 'assets/images/campaign/tiktokLogo.png',
    },
    {
      id: 7,
      name: "Snapchat Campaign",
      title: "Snapchat",
      key: 'snapchat',
      selected: false,
      logo: 'assets/images/campaign/snapchatLogo.png',
    },
  ]
  campaignStatus = [
    { name: 'DRAFT', id: 1 },
    { name: 'INITIATED', id: 2 },
    { name: 'SENT', id: 3 },
    { name: 'FAIL', id: 4 },
    { name: 'PAUSED', id: 5 },
    { name: 'ARCHIVED', id: 6 },
    { name: 'SCHEDULED', id: 7 }
  ];

  constructor(
    private sharedService: SharedService,
    private http: HttpClient,
    private apiService: ApiService
  ) {
    this.apiDomain = this.sharedService.apiDomain;
  }

  getCampaignStatusInfo(status: CampaignStatusEnum): CampaignStatusInfo {
    let statusInfo: CampaignStatusInfo;

    switch (status) {
      case CampaignStatusEnum.DRAFT:
        statusInfo = { status: 'Draft', backgroundColor: 'lightgray', color: 'black' };
        break;
      case CampaignStatusEnum.INITIATED:
        statusInfo = { status: 'Initiated', backgroundColor: 'yellow', color: 'black' };
        break;
      case CampaignStatusEnum.COMPLETED:
        statusInfo = { status: 'Completed', backgroundColor: 'green', color: 'white' };
        break;
      case CampaignStatusEnum.FAILED:
        statusInfo = { status: 'Failed', backgroundColor: 'red', color: 'white' };
        break;
      case CampaignStatusEnum.PAUSED:
        statusInfo = { status: 'Paused', backgroundColor: 'orange', color: 'black' };
        break;
      case CampaignStatusEnum.ARCHIVED:
        statusInfo = { status: 'Archived', backgroundColor: 'gray', color: 'white' };
        break;
      case CampaignStatusEnum.SCHEDULED:
        statusInfo = { status: 'Scheduled', backgroundColor: 'blue', color: 'white' };
        break;
      case CampaignStatusEnum.PUBLISHED:
        statusInfo = { status: 'Published', backgroundColor: 'green', color: 'white' };
        break;
      default:
        statusInfo = { status: 'Unknown', backgroundColor: 'white', color: 'black' };
        break;
    }
    return statusInfo;
  }

  getCampaignsList(limit = 0, offset = 0, search = "", form) {
    let params = new HttpParams();
    params = params
      .set("limit", limit.toString())
      .set("offset", offset.toString())
      .set("search", search);
    for (let key of Object.keys(form)) {
      params = params.set(
        `${key}`,
        `${form[key].value ? form[key].value : ""}`
      );
    }
    return this.apiService.get(
      this.sharedService.campaignsList,
      params,
      undefined
    );
  }

  getAudience() {
    let limit = 9999;
    let offset = 0;
    let params = new HttpParams();
    params = params
      .set("limit", limit.toString())
      .set("offset", offset.toString());
    return this.apiService.get(
      this.sharedService.audience,
      params,
      undefined
    );
  }

  getMediums(limit = 9999, offset = 0) {
    let params = new HttpParams();
    params = params
      .set("limit", limit.toString())
      .set("offset", offset.toString());
    return this.apiService.get(
      this.sharedService.mediums,
      params,
      undefined
    );
  }

  viewCampaign(id) {
    return this.apiService.get(
      this.sharedService.campaignsList + `${id}/`,
      undefined
    );
  }

  campaignDetail(id) {
    return this.apiService.get(
      this.sharedService.addCampaign + `${id}/`,
      undefined
    );
  }

  getCampaignAnalysis(id, type, date, startDate, endDate) {
    let params = new HttpParams();
    params = params
      .set("campaign_id", id)
      .set("type", type)



    return this.apiService.get(
      this.sharedService.campaignDashBoard,
      params,
      undefined
    );
  }

  getAllCampaignsAnalysis(type, date, startDate, endDate) {
    let params = new HttpParams();
    params = params
      .set("type", type)

    return this.apiService.get(
      this.sharedService.campaignDashBoard,
      params,
      undefined
    );
  }

  getTemplateDetail(id) {
    return this.apiService.get(
      this.sharedService.addTemplate + `${id}/`,
      undefined
    )
  }

  getTemplatePreview(form) {
    return this.apiService.post(
      this.sharedService.templatePreview,
      form,
      undefined
    )
  }

  addCampaign(form) {
    return this.apiService.post(
      this.sharedService.addCampaign,
      form,
      undefined
    );
  }

  deleteCampaign(id) {
    return this.apiService.delete(
      this.sharedService.deleteCampaign + `${id}/`,
      undefined
    );
  }

  cancelCampaign(form) {
    return this.apiService.post(
      this.sharedService.cancelCampaign,
      form,
      undefined
    );
  }

  updateCampaign(form, id) {
    return this.apiService.put(
      this.sharedService.updateCampaign + `${id}/`,
      form,
      undefined
    );
  }

  updateTemplate(form, id) {
    return this.apiService.put(
      this.sharedService.addTemplate + `${id}/`,
      form,
      undefined
    );
  }

  addTemplate(form) {
    return this.apiService.post(
      this.sharedService.addTemplate,
      form,
      undefined
    );
  }

  sendTestCampaign(form) {
    return this.apiService.post(
      this.sharedService.sendTestCampaign,
      form,
      undefined
    );
  }

  executeCampaign(id) {
    return this.apiService.get(
      this.sharedService.executeCampaign + `?campaign_id=${id}`,
      undefined
    );
  }

  executeTheCampaign(id, form) {
    return this.apiService.post(
      this.sharedService.executeCampaign + `?campaign_id=${id}`,
      form
    );
  }

  campaignFieldsData() {
    const campaignMappings = {
      email: {
        fieldsToDisplay: ['audience', 'campaign_code', 'price_rule', 'template_format',],
        mandatoryFields: ['name', 'campaign_channel_id', 'audience', 'message_email', 'title', 'code']
      },
      push_notification: {
        fieldsToDisplay: ['audience', 'campaign_code', 'price_rule', 'start_date', 'schedule_time', 'template', 'template_format'],
        mandatoryFields: ['name', 'campaign_channel_id', 'audience', 'message', 'title', 'code']
      },
      sms: {
        fieldsToDisplay: ['audience', 'campaign_code', 'price_rule', 'start_date', 'schedule_time', 'template', 'template_format'],
        mandatoryFields: ['name', 'campaign_channel_id', 'audience', 'message', 'title', 'code']
      },
      affiliate: {
        fieldsToDisplay: ['start_date', 'end_date', 'budget', 'landing_page', 'url', 'campaign_code', 'price_rule', 'status'],
        mandatoryFields: ['name', 'campaign_channel_id', 'code']
      },
      instagram: {
        fieldsToDisplay: ['start_date', 'end_date', 'budget', 'url', 'campaign_code', 'price_rule', 'status'],
        mandatoryFields: ['name', 'campaign_channel_id', 'code']
      },
      facebook: {
        fieldsToDisplay: ['start_date', 'end_date', 'budget', , 'url', 'campaign_code', 'price_rule', 'status'],
        mandatoryFields: ['name', 'campaign_channel_id', 'code']
      },
      tiktok: {
        fieldsToDisplay: ['start_date', 'end_date', 'budget', 'url', 'campaign_code', 'price_rule', 'status'],
        mandatoryFields: ['name', 'campaign_channel_id', 'code']
      },
      snapchat: {
        fieldsToDisplay: ['start_date', 'end_date', 'budget', 'url', 'campaign_code', 'price_rule', 'status'],
        mandatoryFields: ['name', 'campaign_channel_id', 'code']
      }
    };
    return campaignMappings
  }
}
