import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { CatalogueService } from 'app/services/catalogue/catalogue.service';
import { productAttribute } from 'app/main/types/productFields';
import { take, takeUntil } from 'rxjs/operators';
import { UtilitiesService } from 'app/services/utilitiesService';
import { Subject } from 'rxjs';
import { DynamicFieldTypes } from 'app/main/types/dynamicField';

@Component({
  selector: 'app-create-product-attribute',
  templateUrl: './create-product-attribute.component.html',
  styleUrls: ['./create-product-attribute.component.scss']
})
export class CreateProductAttributeComponent implements OnInit {

  @Input() id: any = "";
  @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
  @Output() createProductAttribute: EventEmitter<void> = new EventEmitter<any>();
  form: FormGroup;
  productForm: FormGroup
  isApiCallDone: boolean = false
  imgUrls: any[] = []
  productFields: any = productAttribute.sections
  unSubscribe$ = new Subject<void>()
  attributeDetail:any

  color: any
  get choicesFormArray() {
    return this.form.get('choices') as FormArray
  }

  constructor(
    private fb: FormBuilder,
    private catalogueService: CatalogueService,
    private utilitiesService: UtilitiesService
  ) {
  }

  ngOnInit(): void {
    this.formInitialize()
    if (this.id) {
      this.getAttributeById ()
    } else {
      this.isApiCallDone = true
    }

  }

  async formInitialize() {
    this.form = this.fb.group({
      choices: this.fb.array([])
    });
    this.productFields.forEach(section => {
      section.fields.forEach(field => {
        this.form.addControl(
          field.name,
          new FormControl({
            value: typeof field.default_value === 'boolean' ?
              field?.default_value :
              (field?.default_value || ''),
            disabled: field?.disable
          },
            field?.required ? Validators.required : null
          ));
        if (field?.type === DynamicFieldTypes.TextEditor) {
          this.form.addControl(`${field?.name}_control`, new FormControl(''))
        }
      });
    });
    if(!this.id){
      this.addChoice()
    }
  }

  addChoice() {
    const obj = {
      name: '',
      name_ar: '',
      color: ''
    }
    const choiceFormGroup = this.fb.group(obj)
    choiceFormGroup.get('name').setValidators(Validators.required)
    choiceFormGroup.get('name').updateValueAndValidity()
    this.choicesFormArray.push(choiceFormGroup)
  }

  setFormValue(product) {
    this.form.addControl('id',new FormControl(''))
    for (let key in this.form.getRawValue()) {
      if (key !== 'choices') {
        if (product.hasOwnProperty(key)) {
          this.form.get(key).setValue(product[key])
        }
      }
    }
    if(product.choices && product.choices.length){
      product.choices.forEach(ele=>{
        const obj = {
          id:ele?.id,
          name: ele?.name,
          name_ar: ele?.name_ar,
          color: ele?.color,
          delete:false
        }
        const choiceFormGroup = this.fb.group(obj)
        choiceFormGroup.get('name').setValidators(Validators.required)
        choiceFormGroup.get('name').updateValueAndValidity()
        this.choicesFormArray.push(choiceFormGroup)
      })
    }
  }

  getAttributeById () {
    this.utilitiesService.startLoader()
    this.catalogueService.getAttributeById (this.id)
      .pipe(
        takeUntil(this.unSubscribe$)
      )
      .subscribe(product => {
        this.attributeDetail=product
        this.setFormValue(product)
        this.isApiCallDone = true
      })
    this.utilitiesService.stopLoader()

  }

  setFileToForm(file, type) {
    console.log(file)
    const control = type === 'image' ? this.form.get('image') : this.form.get('banner')
    const reader = new FileReader();
    reader.readAsDataURL(file.file);
    reader.onload = () => {
      control.setValue(reader.result as string)
    };
  }

  deleteFile(control: FormControl) {
    control.setValue('')
  }

  handleCancel() {
  }

  saveOrUpdate() {
    const form = this.form.value
    const {choices,...attribute} = form

    if(this.id){
      let choiceIdsSet = new Set(choices.map((ele) => ele.id));
      console.log(choiceIdsSet)

      this.attributeDetail.choices.forEach((item) => {
        if (!item.id || !choiceIdsSet.has(item.id)) {
          item.delete=true
          choices.push(item);
          if (item.id) {
            choiceIdsSet.add(item.id);
          }
        }
      });
    }
    const updatedForm={choices,attribute:{...attribute}}
    if (this.form.valid) {
      if (this.id) {
        this.updateAttribute(updatedForm)
      } else {
        this.saveAttribute(updatedForm)
      }
    } else {
      this.checkValidations()
    }
  }

  saveAttribute(form) {
    this.utilitiesService.startLoader()
    this.catalogueService.saveAttribute(form).subscribe(res => {
      if (res) {
        this.utilitiesService.stopLoader()
        let msg = 'Attribute saved successfully.'
        this.utilitiesService.showSuccessToast(msg)
        this.createProductAttribute.emit();
      }
    })
    this.utilitiesService.stopLoader()
  }

  updateAttribute(form) {
    this.utilitiesService.startLoader()
    this.catalogueService.updateAttribute(form, this.id).subscribe(res => {
      if (res) {
        this.utilitiesService.stopLoader()
        let msg = 'Attribute upated successfully.'
        this.utilitiesService.showSuccessToast(msg)
        this.createProductAttribute.emit();
      }
    })
    this.utilitiesService.stopLoader()
  }

  checkValidations() {
    for (const controlName in this.form.controls) {
      if (this.form.controls.hasOwnProperty(controlName)) {
        const control = this.form.get(controlName);
        if (control.invalid) {
          let msg = `${this.transformControlName(controlName)} is required.`
          this.utilitiesService.showErrorToast(msg)
          break; // Stop the loop as soon as an invalid control is found
        }
      }
    }

    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control.markAsTouched({ onlySelf: true });
    })
  }

  transformControlName(controlName: string): string {
    return controlName
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next()
    this.unSubscribe$.complete()
  }

  openOrClose(type, index) {
    let testArray = [1, 2]; // color picker issue solved by this temp trick
    if (type == "color") {
      testArray.forEach((data) => {
        this.color = (this.choicesFormArray.at(index) as FormGroup).controls.color.value
      });
    }
  }

  setColor(type, index) {
    if (type == "color") {
      (this.choicesFormArray.at(index) as FormGroup).controls.color.setValue(this.color);
    }
  }

  getColor(index) {
    return (this.choicesFormArray.at(index) as FormGroup).controls.color.value || ''
  }

  removeChoice(index){
   this.choicesFormArray.removeAt(index)
  }

}



