<div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
    <div fxFlex="0 0 auto" fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="1em">
        <strong class="highlight-text-color" *ngIf="!id">{{'Add Customers' | translate}}</strong>
        <strong class="highlight-text-color" *ngIf="id">{{'Edit Customer' | translate}} - {{firstName}}
            {{lastName}}</strong>
        <mat-icon *ngIf="id && coreService.checkPermission(utilitiesService.Permission.HistoryMember)" class="highlight-text-color cursor-pointer" (click)="openHistory()"
            title="{{'History' | translate}}">
            access_time </mat-icon>
    </div>

    <form fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em"
        [formGroup]="form">
        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                <div fxFlex="0 0  auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column">
                        <div fxFlex="1 1 0px">
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label class="text-capitalized">
                                    {{'Plan'| translate}}
                                </mat-label>
                                <mat-select formControlName="plan_id">
                                    <mat-option *ngFor="let plan of plan" [value]="plan?.id">
                                        {{plan?.name}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="form.get('plan_id').hasError('required')">
                                    {{'Plan is required' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="1 1 0px" *ngIf="!id" class="mb-16">
                            <angular2-multiselect formControlName="customer_id" [data]="customerDropDown"
                                [settings]="customerSettings">
                            </angular2-multiselect>
                        </div>

                        <div fxFlex="1 1 0px" *ngIf="id">
                            <mat-form-field appearance="outline" fxFlex="100" class="disabled-btn">
                                <mat-label class="text-capitalized">
                                    {{'Customer'| translate}}
                                </mat-label>
                                <mat-select formControlName="customer_id">
                                    <mat-option *ngFor="let customer of customerList" [value]="customer?.id">
                                        {{customer?.first_name}} {{customer?.middle_name}} {{customer?.last_name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxFlex="1 1 0px" *ngIf="id">
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label class="text-capitalized">
                                    {{'Status'| translate}}
                                </mat-label>
                                <mat-select formControlName="status">
                                    <mat-option [value]="1">{{'Active' | translate}}</mat-option>
                                    <mat-option [value]="2">{{'In active' | translate}}</mat-option>
                                    <mat-option [value]="3">{{'Expired' | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxFlex="1 1 0px" *ngIf="id" fxLayoutAlign="start stretch" fxLayout="column"
                            fxLayoutGap="3px">
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label class="text-capitalized">
                                    {{'Expiry Date'| translate}}
                                </mat-label>
                                <input formControlName="expiry_date" matInput [ngxMatDatetimePicker]="picker"
                                    placeholder="Expiry Date" [min]="minDate" [max]="maxDate" [disabled]="disabled">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners"
                                    [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute"
                                    [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
                                    [enableMeridian]="enableMeridian">
                                </ngx-mat-datetime-picker>
                                <mat-error *ngIf="form.get('expiry_date').hasError('required')">
                                    {{'Expiry Date is required' | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxFlex="1 1 auto" fxLayoutAlign="flex-end stretch" fxLayout="row" fxLayoutGap="1em">
                        <button [mat-dialog-close]="false" mat-stroked-button color="warn">
                            <mat-icon class="save-cancel-btn" matPrefix>close</mat-icon> {{'Cancel' | translate}}
                        </button>
                        <button *ngIf="!id" (click)="add()" [ngClass]="{'disabled-btn' : !form.valid}"
                            [disabled]="!form.valid" type="submit" mat-flat-button class="primary-bg-color">
                            <mat-icon class="save-cancel-btn mr-4" matPrefix>add</mat-icon>
                            {{'Add' | translate}}
                        </button>
                        <button *ngIf="id" (click)="update()" [ngClass]="{'disabled-btn' : !form.valid}"
                            [disabled]="!form.valid" type="submit" mat-flat-button class="primary-bg-color">
                            <mat-icon class="save-cancel-btn mr-4" matPrefix>save</mat-icon>
                            {{'Save' | translate}}
                        </button>

                    </div>
                </div>
            </div>

        </div>
    </form>
</div>