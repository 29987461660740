import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { NotificationService } from "app/services/notification/notification.service";
import { TranslationService } from "app/services/translationService";
import { RolesPermissionService } from "app/services/user-management/rolesPermission.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";

@Component({
    selector: "app-create-roles-permissions",
    templateUrl: "./create-roles-permissions.component.html",
    styleUrls: ["./create-roles-permissions.component.scss"],
    animations: fuseAnimations,
})
export class CreateRolesPermissionsComponent implements OnInit {
    @Input() id: any = "";
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    @Output() createNotification: EventEmitter<void> = new EventEmitter<any>();
    form: FormGroup;
    allPermmissionList = [];
    checkedOrUnchecked = [];
    checkBoxArray = [];
    rolesDetails: any = "";
    details: any;
    ckeConfig: {
        sanitize: boolean;
        allowedContent: boolean;
        removeButtons: string;
    };
    constructor(
        private fb: FormBuilder,
        public utilitiesService: UtilitiesService,
        private dialog: MatDialog,
        private rolesService: RolesPermissionService,
        private translate: TranslateService,
        public translationService: TranslationService
    ) {}

    async ngOnInit(): Promise<void> {
        if (this.id) {
            this.utilitiesService.startLoader();
        }
        await this.formInit();
        await this.getAllPermission();
        if (this.id) {
            await this.getDetails();
        }
    }
    goBack() {
        this.cancelClick.emit();
    }
    async formInit() {
        this.form = this.fb.group({
            name: [this.rolesDetails ? this.rolesDetails.name : null],
            name_en: [
                this.rolesDetails ? this.rolesDetails.name_en : null,
                [Validators.required],
            ],
            name_ar: [this.rolesDetails ? this.rolesDetails.name_ar : null],
            permissions: [""],
        });
    }
    async getDetails() {
        try {
            const customerDetails = await this.rolesService
                .getRolesDetails(this.id)
                .toPromise();
            if (customerDetails) {
                this.rolesDetails = customerDetails;
                await this.checkboxPrefilled();
                await this.formInit();
                this.utilitiesService.stopLoader();
            }
        } catch {
        } finally {
        }
    }
    async checkboxPrefilled() {
        if (this.allPermmissionList) {
            this.allPermmissionList.forEach((per) => {
                per.permissions.forEach((items) => {
                    this.checkedOrUnchecked[items.id] = false;
                    this.rolesDetails.permission.forEach((data) => {
                        if (items.codename == data) {
                            this.checkedOrUnchecked[items.id] = true;
                            this.checkBoxArray.push(items.id);
                            this.checkBoxArray = this.checkBoxArray.filter(
                                function (elem, index, self) {
                                    return index === self.indexOf(elem);
                                }
                            );
                        }
                    });
                });
            });
        }
    }
    checkForModal(event, index) {
        this.allPermmissionList.forEach((permission, innerIndex) => {
            if (event.checked) {
                if (index == innerIndex) {
                    permission.permissions.forEach((data) => {
                        this.checkedOrUnchecked[data.id] = true;
                        this.checkBoxArray.push(data.id);
                        this.checkBoxArray = this.checkBoxArray.filter(
                            function (elem, index, self) {
                                return index === self.indexOf(elem);
                            }
                        );
                    });
                }
            } else {
                if (innerIndex == index) {
                    permission.permissions.forEach((data) => {
                        this.checkedOrUnchecked[data.id] = false;
                        this.checkBoxArray = this.checkBoxArray.filter(
                            (items) => items != data.id
                        );
                    });
                }
            }
            this.checkedOrUnchecked = [...this.checkedOrUnchecked];
        });
    }
    checkAllChecked(permissions) {
        let checked = false;
        let allChecked = 0;
        if (permissions) {
            if (this.checkBoxArray) {
                this.checkBoxArray.forEach((data) => {
                    permissions.forEach((items) => {
                        if (items.id == data) {
                            allChecked = allChecked + 1;
                        }
                    });
                });
                if (permissions.length == allChecked) {
                    checked = true;
                } else {
                    checked = false;
                }
            }
        }
        return checked;
    }
    checkBoxChange(event, id) {
        if (event.checked) {
            this.checkedOrUnchecked[id] = true;
            this.checkBoxArray.push(id);
            this.checkBoxArray = this.checkBoxArray.filter(function (
                elem,
                index,
                self
            ) {
                return index === self.indexOf(elem);
            });
        } else {
            this.checkedOrUnchecked[id] = false;
            this.checkBoxArray = this.checkBoxArray.filter(
                (data) => data != id
            );
        }
    }
    async getAllPermission() {
        try {
            this.utilitiesService.startLoader();
            let allPermmissionList = await this.rolesService
                .getAllPermissions()
                .toPromise();
            if (allPermmissionList) {
                this.allPermmissionList = allPermmissionList;
                this.utilitiesService.stopLoader();
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
    save() {
        if (this.form.valid) {
            this.checkBoxArray = this.checkBoxArray.filter(function (
                elem,
                index,
                self
            ) {
                return index === self.indexOf(elem);
            });
            let permissionArray = [];
            if (this.checkBoxArray.length && this.allPermmissionList.length) {
                this.checkBoxArray.forEach((id) => {
                    this.allPermmissionList.forEach((items) => {
                        items.permissions.forEach((permission) => {
                            if (permission.id == id) {
                                permissionArray.push(permission.codename);
                            }
                        });
                    });
                });
            }
            let form = this.form.value;
            form.permissions = permissionArray;
            console.log(form.permissions);
            form.name = form.name_en;
            if (this.id) {
                this.upatePermission(form);
            } else {
                this.savePermission(form);
            }
        } else {
            for (const key of Object.keys(this.form.controls)) {
                this.form.controls[key].markAllAsTouched();
            }
        }
    }
    upatePermission(form) {
        try {
            let content = this.translate.instant(
                "Are you sure, Do you want to update ?"
            );
            let heading = this.translate.instant("Update");
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();

            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp) {
                    this.utilitiesService.startLoader();
                    let success = this.translate.instant(
                        "Role updated successfully"
                    );
                    const customer = await this.rolesService
                        .updateRole(form, this.id)
                        .toPromise();
                    if (customer) {
                        this.utilitiesService.showSuccessToast(success);
                        this.utilitiesService.stopLoader();
                        this.goBack();
                        // this.route.navigate([AppRoutes.Roles]);
                    }
                }
            });
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
    savePermission(form) {
        try {
            let content = this.translate.instant(
                "Are you sure, Do you want to save ?"
            );
            let heading = this.translate.instant("Save");
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();

            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp) {
                    this.utilitiesService.startLoader();
                    let success = this.translate.instant(
                        "Role created successfully"
                    );
                    const customer = await this.rolesService
                        .addRole(form)
                        .toPromise();
                    if (customer) {
                        this.utilitiesService.showSuccessToast(success);
                        this.utilitiesService.stopLoader();
                        this.goBack();
                        // this.route.navigate([AppRoutes.Roles]);
                    }
                }
            });
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
}
