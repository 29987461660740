import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "../api.service";

@Injectable({
    providedIn: "root",
})
export class NotificationService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    getNotificationDetail(id) {
        return this.apiService.get(
            this.sharedService.notificationList + `${id}/`,
            undefined
        );
    }

    getConfigurationsDetails(id) {
        return this.apiService.get(
            this.sharedService.notificationConfiguration + `${id}/`,
            undefined
        );
    }

    getConfigurations(limit = 10, offset = 0, search = "") {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        return this.apiService.get(
            this.sharedService.notificationConfiguration,
            params,
            undefined
        );
    }

    getNotificationList(
        limit = 10,
        offset = 0,
        search = "",
        templateType = "",
        re_usable: boolean = false
    ) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (templateType) {
            params = params.set("template_type", templateType);
        }
        if (re_usable) {
            params = params.set("re_usable", re_usable.toString());
        }
        return this.apiService.get(
            this.sharedService.notificationList,
            params,
            undefined
        );
    }

    addTemplate(form) {
        return this.apiService.post(
            this.sharedService.notificationList,
            form,
            undefined
        );
    }

    addConfig(form) {
        return this.apiService.post(
            this.sharedService.notificationConfiguration,
            form,
            undefined
        );
    }
    updateConfig(form, id) {
        return this.apiService.put(
            this.sharedService.notificationConfiguration + `${id}/`,
            form,
            undefined
        );
    }

    updateTemplate(form, id) {
        return this.apiService.put(
            this.sharedService.notificationList + `${id}/`,
            form,
            undefined
        );
    }

    deleteNotification(id) {
        return this.apiService.delete(
            this.sharedService.notificationList + `${id}/`,
            undefined
        );
    }

    deleteConfig(id) {
        return this.apiService.delete(
            this.sharedService.notificationConfiguration + `${id}/`,
            undefined
        );
    }

    getAllNotificationVariables() {
        return this.apiService.get(
            this.sharedService.notificationVariables,
            undefined,
            undefined
        );
    }
}
