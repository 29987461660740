import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CoreService } from "app/services/core-service";
import { CustomerService } from "app/services/customer/customer.service";
import { CampaignsService } from "app/services/marketing/campaigns.service";
import { CartService } from "app/services/orders/cart.service";
import { OrderService } from "app/services/orders/order.service";
import { PageService } from "app/services/pageService";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { DeliveryService } from "../../../services/delivery.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Location } from "@angular/common";
import { IconCss } from "app/main/ngxDatatable-Icons/icons";
import { fuseAnimations } from "@fuse/animations";
import { debounceTime } from "rxjs/operators";
import { ChangeDeliveryAgentStatusComponent } from "../change-delivery-agent-status/change-delivery-agent-status.component";
import { AppRoutes } from "app/routes";
import { ChangeUserPasswordPopoverComponent } from "app/main/components/change-user-password-popover/change-user-password-popover.component";
@Component({
    selector: "app-delivery-agents",
    templateUrl: "./delivery-agents.component.html",
    styleUrls: ["./delivery-agents.component.scss"],
    animations: fuseAnimations,
})
export class DeliveryAgentsComponent implements OnInit {
    id: number;
    isLoading = true;
    deliveryAgentList: any;

    form: FormGroup;
    searchKey = "";
    offset: number = 0;
    page = {
        size: 10,
        totalElements: 0,
        pageNumber: 0,
    };

    enableIcon: boolean = false;
    isEnableBack = false;
    label = {
        title_en: "Orders",
        title_ar: "الطلبات",
    };
    icons = IconCss;
    iconsCss = {
        pagerLeftArrow: this.icons.left,
        pagerRightArrow: this.icons.right,
        pagerPrevious: this.icons.previous,
        pagerNext: this.icons.next,
    };
    statusList = [
        { id: 1, name: "Active" },
        { id: 2, name: "Inactive" },
    ];

    constructor(
        private router: Router,
        private cartService: CartService,
        private orderService: OrderService,
        public utilitiesService: UtilitiesService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private campaignsService: CampaignsService,
        private translate: TranslateService,
        public translationService: TranslationService,
        private customerService: CustomerService,
        public coreService: CoreService,
        private deliveryService: DeliveryService,
        private cdr: ChangeDetectorRef,
        public location: Location,
        public fb: FormBuilder
    ) {
        // this.route.paramMap.subscribe((params: ParamMap) => {
        //     this.id = +params.get("id");
        // });
        this.label.title_ar = "Delivery Agents";
        this.label.title_en = "Delivery Agents";
        this.initializeForm();
    }

    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        this.utilitiesService.startLoader();
        await this.getDeliveryAgents();
        this.isLoading = false;
        this.utilitiesService.stopLoader();
        this.form.valueChanges.pipe(debounceTime(1000)).subscribe((data) => {
            this.page.pageNumber = 0;
            this.page.size = 10;
            this.utilitiesService.startLoader();
            this.getDeliveryAgents();
        });
    }
    initializeForm() {
        this.isLoading = true;

        this.form = this.fb.group({
            first_name: [null],
            middle_name: [null],
            last_name: [null],
            contact_no: [null],
            email: [null],
            name: [null],

            civil_id: [null],
            profile_pic: [null],
            preferred_language: [null],
            status: [null],

            created_by: [null],
            updated_by: [null],
        });
    }
    resetPassword(userID, row) {
        let username = row?.first_name + ' ' + row?.last_name;
        const dialogRef = this.dialog.open(ChangeUserPasswordPopoverComponent, {
            data: {
                username,
                userID
            },
            maxWidth: "",
            width: "70vw",
            height: "auto",
        });
        dialogRef.afterClosed().subscribe((res) => {
            console.log(res);
        });


    }
    async getDeliveryAgents(
        limit = this.page.size,
        offset = this.offset,
        form = this.form ? this.form.controls : undefined
    ) {
        this.isLoading = true;
        await this.deliveryService
            .getDeliveryAgentList(limit, offset, this.searchKey, form)
            .subscribe((res) => {
                if (res) {
                    this.page.totalElements = res.count;
                    this.deliveryAgentList = res.results;
                    this.deliveryAgentList.forEach((element) => {
                        element.statusName =
                            element.status == 1 ? "Active" : "Inactive";
                    });
                    this.cdr.markForCheck();
                }
                this.isLoading = false;
                this.utilitiesService.stopLoader();
            });
    }

    pageChange(event) {
        this.page.pageNumber = event.offset;
        this.offset = this.page.pageNumber * this.page.size;
        this.cdr.markForCheck();
        document.getElementById("dashboard-project").scrollIntoView();
        this.getDeliveryAgents();
    }
    onActivateViewOrder(x) { }

    limitChange() {
        this.getDeliveryAgents();
    }

    search(searchKey) {
        this.searchKey = searchKey;
        this.getDeliveryAgents();
    }
    ChangeStatus(agentDetails) {
        // let itemList = this.cartList;
        let isMobile = this.utilitiesService.isMobile();
        let size = {
            width: "45vw",
            height: "35vh",
        };
        if (isMobile) {
            size.height = "100%";
            size.width = "100%";
        }
        const dialogRef = this.dialog.open(ChangeDeliveryAgentStatusComponent, {
            data: { agentDetails },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });

        dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
                if (resp) {
                    await this.getDeliveryAgents();
                }
                console.log("resp");
                console.log(resp);
            }
        });
    }
    AddDeliveryAgent() {
        console.log("clicked");
        try {
            this.router.navigate([AppRoutes.addDeliveryAgents]);
        } catch {
            console.log("test failed");
        }
        // this.router.navigate([AppRoutes.addDeliveryAgents]);
    }
    editDeliveryAgent(id) {
        console.log("clicked");
        try {
            this.router.navigate([`${AppRoutes.deliveryAgents}/${id}`]);
        } catch {
            console.log("test failed");
        }
        // this.router.navigate([AppRoutes.addDeliveryAgents]);
    }

}

// getStatusName(id){

// }
