import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { SharedService } from "../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { TranslationService } from "./translationService";
import { ApiService } from "./api.service";
import { TranslateService } from "@ngx-translate/core";
import { catchError } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class CoreService {
    apiDomain: any;
    public currentNavigation = new BehaviorSubject([]);
    public isHome = new BehaviorSubject(false);
    public isLogin = new BehaviorSubject(false);
    constructor(
        private sharedService: SharedService,
        private httpClient: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    get accessToken(): string {
        return localStorage.getItem("access_token") ?? "";
    }

    getHistory(limit = 10, offset = 0, search = "", url: string, form?) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        return this.apiService.get(url, params, undefined);
    }
    getBulkPicklist(limit = 10, offset = 0, search = "", form?) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);

        if (form) {
            for (let key of Object.keys(form)) {
                if (key == "price_rules") {
                    if (form[key].value) {
                        params = params.set(`${key}`, `${form[key].value}`);
                    }
                } else {
                    params = params.set(
                        `${key}`,
                        `${form[key]?.value ? form[key]?.value : ""}`
                    );
                }
            }
        }

        console.log("params");
        console.log(params);
        return this.apiService.get(
            this.sharedService.downloadBulkPicklist,
            params,
            undefined
        );
    }
    getPaginatedHistory(next: string): Observable<any> {
        let token = localStorage.getItem("access_token");
        next = next.replace("http://", "https://");
        let headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-api-key": "cbe806aa-e93a-4a7e-8484-c0cac8e69cad",
        });
        return this.httpClient
            .get(next, { headers: headers })
            .pipe(catchError((response) => throwError(response)));
    }

    getDownloadsList(limit = 10, offset = 0, type = 1) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("download_type", type.toString());
        return this.apiService.get(
            this.sharedService.downloadList,
            params,
            undefined
        );
    }

    retryDownload(id) {
        let params = new HttpParams();
        params = params.set("retry", "true");
        return this.apiService.get(
            this.sharedService.downloadList + `${id}/`,
            params,
            undefined
        );
    }

    cancelDownload(id) {
        let params = new HttpParams();
        params = params.set("cancel", "true");
        return this.apiService.get(
            this.sharedService.downloadList + `${id}/`,
            params,
            undefined
        );
    }

    checkPermission(permissionKeyword) {
        let permissionArray = [];
        let hasPermission = false;
        let permission = localStorage.getItem("permissions")
            ? localStorage.getItem("permissions")
            : "";
        let permissions: any;
        if (permission) {
            permissions = JSON.parse(permission) ? JSON.parse(permission) : [];
            if (permissions) {
                permissionArray = permissions.filter(
                    (per) => per == permissionKeyword
                );
            }
        }
        if (permissionArray.length > 0) {
            hasPermission = true;
        } else {
            hasPermission = false;
        }
        return hasPermission;
    }

    async setPermission(permission) {
        localStorage.setItem("permissions", JSON.stringify(permission));
    }
    refreshPermission() {
        return this.apiService.get(
            this.sharedService.refreshPermission,
            undefined,
            undefined
        );
    }

    checkFeatures(id) {
        let isShow = false;
        let featuresList = localStorage.getItem("features")
            ? localStorage.getItem("features")
            : "";
        let features = [];
        if (featuresList) {
            features = JSON.parse(featuresList) ? JSON.parse(featuresList) : [];
        }
        if (features.length) {
            isShow = !!features.find((data) => data.id == id);
        }
        return isShow;
    }
}
