<div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
    <form fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column-reverse" fxLayout.gt-sm="row"
        fxLayoutGap="1em" [formGroup]="form">
        <div fxFlex.lt-md="1 1 auto" fxFlex.gt-sm="1 1 75%" fxLayoutAlign="start stretch" fxLayout="column"
            fxLayoutGap="1em">
            <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="1em">
                <div class="dropzone p-16 example-list" fxFlex="0 0 auto" fxLayout="column"
                    fxLayoutAlign="start stretch">
                    <div fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="1em">
                        <app-fab-button (handleFabButtonClick)="addCondition()"></app-fab-button>
                    </div>
                    <div fxLayout="column" fxFlex="0 0 auto" fxLayoutAlign="start stretch">
                        <mat-accordion class="mb-16" formArrayName="rules"
                            *ngFor="let rulesFormGroup of form.get('rules')['controls'];let i = index"
                            [formGroup]="rulesFormGroup">
                            <mat-expansion-panel [expanded]="expandIndex[i] == i"
                                [ngClass]="{'invalid' : !checkInvalid(i)}">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="space-between">
                                            {{'Rule #' | translate}}{{i+1}}
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch"
                                    fxLayoutGap="5px">
                                    <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch"
                                        fxLayoutGap="5px">
                                        <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto"
                                            fxLayoutAlign="start stretch" fxLayoutGap="1em">
                                            <div fxFlex="1 1 0px">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label>{{'Filter' |translate}}</mat-label>
                                                    <mat-select (selectionChange)="filterChanges($event?.value,i)"
                                                        [formControl]="rulesFormGroup.get('filter')"
                                                        matTooltip="{{'help_text_condition_filter' | translate}}">
                                                        <mat-option *ngFor="let filter of filterList"
                                                            [value]="filter?.id">
                                                            {{filter?.title ? filter?.title : filter?.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                        *ngIf="rulesFormGroup.get('filter').hasError('required')">
                                                        {{'Filter is required' | translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="1 1 0px">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label>{{'Operator' |translate}}</mat-label>
                                                    <mat-select [formControl]="rulesFormGroup.get('operator')"
                                                        matTooltip="{{'help_text_condition_operator' | translate}}">
                                                        <mat-option *ngFor="let operator of operators"
                                                            [value]="operator?.id">
                                                            {{operator?.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="1 1 0px"
                                                *ngIf="rulesFormGroup.get('type').value !== 'text' && rulesFormGroup.get('type').value !== 'number'">
                                            <ng-container *ngIf="!rulesFormGroup.get('multiple').value">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label>{{'Value' |translate}}</mat-label>
                                                    <mat-select [formControl]="rulesFormGroup.get('value')"
                                                        matTooltip="{{'help_text_condition_value' | translate}}">
                                                        <mat-option *ngFor="let filter of rulesFormGroup.get('valueArray').value" [value]="filter?.id">
                                                            {{filter?.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error *ngIf="rulesFormGroup.get('value').hasError('required')">
                                                        {{'Value is required' | translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </ng-container>
                                                <ng-container *ngIf="rulesFormGroup.get('multiple').value">
                                                    <mat-form-field appearance="outline" fxFlex="100">
                                                        <mat-label>{{'Value' |translate}}{{rulesFormGroup.get('value').value}}</mat-label>
                                                        <mat-select [formControl]="rulesFormGroup.get('value')" [multiple]="true"
                                                            matTooltip="{{'help_text_condition_value' | translate}}">
                                                            <mat-option *ngFor="let filter of rulesFormGroup.get('valueArray').value" [value]="filter?.id">
                                                                {{filter?.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <mat-error *ngIf="rulesFormGroup.get('value').hasError('required')">
                                                            {{'Value is required' | translate}}
                                                        </mat-error>
                                                    </mat-form-field>
                                                </ng-container>
                                            </div>

                                            <div fxFlex="1 1 0px" *ngIf="rulesFormGroup.get('type').value === 'number'">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label>{{'Value' |translate}}</mat-label>
                                                    <input [formControl]="rulesFormGroup.get('value')" matInput
                                                        type="number" name="title">
                                                    <mat-error *ngIf="rulesFormGroup.get('value').hasError('required')">
                                                        {{'Value is required' | translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                            <div fxFlex="1 1 0px" *ngIf="rulesFormGroup.get('type').value=='text'">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label>{{'Value' |translate}}</mat-label>
                                                    <input [formControl]="rulesFormGroup.get('value')" matInput
                                                        name="title">
                                                    <mat-error *ngIf="rulesFormGroup.get('value').hasError('required')">
                                                        {{'Value is required' | translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <!-- <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxFlex="0 0 auto"
                                            fxLayoutAlign="start stretch" fxLayoutGap="1em">

                                            <div fxFlex="1 1 0px">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label>{{'Aggregation' |translate}}</mat-label>
                                                    <mat-select [formControl]="rulesFormGroup.get('aggregation')"
                                                        matTooltip="{{'help_text_condition_aggregation' | translate}}">
                                                        <mat-option *ngFor="let aggregation of aggregations"
                                                            [value]="aggregation?.id">
                                                            {{aggregation?.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <mat-action-row>
                                    <app-delete-button (deleteButtonClick)="delete(i)"></app-delete-button>
                                </mat-action-row>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="8px">
                <button mat-flat-button (click)="handleCancel()" class="secondary-bg-color">{{'Cancel' |
                    translate}}</button>
                <button (click)="save()" type="submit" mat-flat-button class="primary-bg-color">{{'Save' |
                    translate}}</button>
            </div>

            <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="1em">
                <div fxLayout="column" fxFlex="0 0 auto" fxLayoutAlign="start stretch">
                    <div fxFlex="1 1 auto">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{'Condition' | translate}}</mat-label>
                            <mat-select formControlName="condition"
                                matTooltip="{{'help_text_condition_filter' | translate}}">
                                <mat-option value="AND">{{'AND' | translate}}</mat-option>
                                <mat-option value="OR">{{'OR' | translate}}</mat-option>
                                <!-- <mat-option *ngFor="let filter of filterList" [value]="filter?.id">
                                    {{filter?.title ? filter?.title : filter?.name}}
                                </mat-option> -->
                            </mat-select>
                            <mat-error *ngIf="form.get('condition').hasError('required')">
                                {{'Condition is required' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>