import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CoreService } from "app/services/core-service";
import { UtilitiesService } from "app/services/utilitiesService";
import { DownloadStatus } from "app/downloadStatusEnum";

@Component({
    selector: "app-export-button",
    templateUrl: "./export-button.component.html",
    styleUrls: ["./export-button.component.scss"],
})
export class ExportButtonComponent implements OnInit {
    @Input() type: any = "1";
    @Input() listLength: number = 0;

    @Input() limit;
    @Input() offset;
    @Input() search;
    @Input() form;
    @Output()
    exportHandle: EventEmitter<void> = new EventEmitter<any>();

    @Output()
    downloadHandle: EventEmitter<void> = new EventEmitter<any>();
    constructor(
        public coreService: CoreService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {}

    async export() {
        this.exportHandle.emit();
    }
    async bulkPicklist() {
        this.utilitiesService.startLoader();
        let search = this.search ? this.search : "";

        let form = this.form ? this.form.controls : undefined;

        try {
            const bulkPicklistDownload = await this.coreService
                .getBulkPicklist(this.limit, this.offset, this.search, form)
                .toPromise();
            if (bulkPicklistDownload) {
                let downloadList = bulkPicklistDownload;
                this.callDownloadBulk(downloadList.message_en);

                this.utilitiesService.stopLoader();
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
    async download() {
        this.utilitiesService.startLoader();
        try {
            const download = await this.coreService
                .getDownloadsList(10, 0, this.type)
                .toPromise();
            if (download) {
                let fullProccessed = true;
                let count = 0;
                let downloadList = download.results;
                if (downloadList.length > 0) {
                    downloadList.forEach((data) => {
                        count = count + 1;
                        if (
                            data.status == DownloadStatus.InQueue ||
                            data.status == DownloadStatus.Processing
                        ) {
                            fullProccessed = false;
                        }
                    });
                    if (count == downloadList.length) {
                        this.utilitiesService.stopLoader();
                        let error = this.translate.instant(
                            "You have already pending downloads, So you can not download at this moment. Please try again later"
                        );
                        fullProccessed
                            ? this.callDownload()
                            : this.utilitiesService.showWarningToast(error);
                    }
                } else {
                    this.utilitiesService.stopLoader();
                    this.callDownload();
                }
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
    callDownloadBulk(message) {
        let downloading = message;
        this.utilitiesService.showSuccessToast(downloading);
        // this.downloadHandle.emit();
    }
    callDownload() {
        let downloading = this.translate.instant(
            "Download started, Please Check My Downloads"
        );
        this.utilitiesService.showSuccessToast(downloading);
        this.downloadHandle.emit();
    }
}
