<div fxLayout="column" fxFlex="100">
    <div class="p-16 pl-0" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
        <mat-icon style="color: red">warning</mat-icon>
        <div style="color: red;font-size: 24px;">
            {{'Image Size Warning' | translate}}
        </div>
    </div>
    <div class="p-16 pt-0">
        <span>{{'Image size should be 64 x 64 pixels , Please rezise your image and upload' | translate}}</span>
    </div>
    <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayoutGap="5px">
        <div>
            <button [mat-dialog-close]="false" mat-stroked-button
                class="mat-focus-indicator mat-raised-button mat-button-base mat-accent">
                <mat-icon class="save-cancel-btn" matPrefix>check</mat-icon>
                {{'OK' | translate}}
            </button>
        </div>
    </div>
</div>