<div fxFlex="1 1 auto" class="fuse-card auto-width p-16" style="height: fit-content !important;"
    fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
    <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em"
        [formGroup]="mediaForm">
        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
            <div fxFlex="0 0 auto" fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="1em">
                <label *ngIf="!mediaForm.controls.id.value" class="highlight-text-color mat-title mb-0">{{'Add Media' |
                    translate}}</label>
                <label *ngIf="mediaForm.controls.id.value" class="highlight-text-color mat-title mb-0">{{'Update Media'
                    | translate}}</label>
                <!-- <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px">
                    <mat-icon title="Clear Image" *ngIf="mediaForm.controls.file.value || mediaForm.controls.id.value" (click)="clear()"
                        class="cursor-pointer action-btn-delete action-icon border-color-delete ">
                        clear
                    </mat-icon>
                    <mat-icon title="Save Image" *ngIf="mediaForm.controls.file.value" (click)="save()"
                        class="cursor-pointer action-icon highlight-text-color border-color-edit">
                        save
                    </mat-icon>
                </div> -->
                <!-- <mat-icon *ngIf="item?.id" class="highlight-text-color cursor-pointer" (click)="openHistory()"
                    title="{{'History' | translate}}">
                    access_time </mat-icon> -->
            </div>
            <div fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
                <div fxLayout="column" fxFlex="1 1 auto" fxLayoutAlign="start center" fxLayoutGap="5px"
                    class="attachment" appDnd (fileDropped)="onFileDropped($event)"
                    *ngIf="!mediaForm.get('file').value">
                    <!-- accept="image/x-png,image/gif,image/jpeg,image/svg" -->
                    <input class="attach-input" type="file" #fileDropRef id="fileDropRef"
                        (change)="fileBrowseHandler($event.target.files)" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="64" viewBox="0 0 63 64">
                        <g fill="#3B454F" fill-rule="nonzero">
                            <path
                                d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z" />
                            <path
                                d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z" />
                            <path
                                d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z" />
                        </g>
                    </svg>

                    <h3 class="attach-h3">{{'Drag and drop file here' | translate}}</h3>
                    <h3 class="attach-h3">{{'or' | translate}}</h3>
                    <label class="attach-label" for="fileDropRef">{{'Browse for file' | translate}}</label>
                </div>

                <div fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px"
                    *ngIf="mediaForm.get('file').value">
                    <div class="fuse-card auto-width  box-shadow-none p-16" fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch" fxLayoutGap="5px" fxLayout="column">
                        <div class="img-container text-center" style="width: 100%;">
                            <img *ngIf="isImage;else noImage" class="added-img"
                                [src]="utilitiesService.bypassTrustedURL(mediaForm.get('file').value)">
                        </div>
                        <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                            <div>
                                <div>{{'File Name' | translate}}</div>
                                <strong>{{mediaForm.get('name').value}}</strong>
                            </div>
                            <div>
                                <button mat-icon-button aria-label="Delete">
                                    <mat-icon (click)="deleteFile()" class="action-btn-delete">
                                        delete</mat-icon>
                                </button>
                            </div>
                        </div>
                        <!-- <div class="px-16 py-4" fxLayout="row" fxLayoutAlign="center center">

                        </div> -->
                    </div>
                </div>
                <div fxFlex="1 1 auto">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>
                            {{'Text' | translate}}</mat-label>
                        <input formControlName="altext" matInput name="title">
                    </mat-form-field>
                </div>
                <div fxFlex="0 0 auto" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                    fxLayoutAlign.gt-sm="space-between center" fxLayoutAlign.lt-md="start stretch" fxLayoutGap="5px">

                    <button (click)="clear()" mat-stroked-button>
                        <mat-icon matPrefix title="Clear Image">
                            clear
                        </mat-icon>
                        {{'Cancel' | translate}}
                    </button>
                    <button (click)="save()" [disabled]="!mediaForm.valid" mat-stroked-button
                        class="highlight-bg-color">
                        <mat-icon matPrefix title="Upload Image">
                            cloud_upload
                        </mat-icon>
                        {{'Upload' | translate}}
                    </button>

                </div>
            </div>

        </div>
    </form>
</div>

<ng-template #noImage>
    <img class="w-84" src="assets/images/file.png">
</ng-template>