import { ChangeDetectorRef, Component, OnInit } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppRoutes } from "app/routes";
import { AppService } from "app/services/app.service";
import { CustomerService } from "app/services/customer/customer.service";
import { TranslationService } from "app/services/translationService";

import * as moment from "moment";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
import { fuseAnimations } from "@fuse/animations";
import { DeliveryService } from "app/services/delivery.service";
import { Location } from "@angular/common";
import { DeliveryAgent } from "app/main/types/productFields";

import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
    FormArray,
} from "@angular/forms";
import { CatalogueService } from "app/services/catalogue/catalogue.service";
import { productCategory, productField } from "app/main/types/productFields";
import { take, takeUntil } from "rxjs/operators";
import { UtilitiesService } from "app/services/utilitiesService";
import { Subject } from "rxjs";
import { DynamicFieldTypes } from "app/main/types/dynamicField";
@Component({
    selector: "app-add-update-delivery-agent",
    templateUrl: "./add-update-delivery-agent.component.html",
    styleUrls: ["./add-update-delivery-agent.component.scss"],
    animations: fuseAnimations,
})
export class AddUpdateDeliveryAgentComponent implements OnInit {
    label = {
        title_en: "Add Delivery Agent",
        title_ar: "Add Delivery Agent",
    };

    form: FormGroup;
    productForm: FormGroup;
    isApiCallDone: boolean = false;
    imgUrls: any[] = [];
    productFields: any = DeliveryAgent.sections;
    unSubscribe$ = new Subject<void>();
    id: number;

    constructor(
        private fb: FormBuilder,
        private deliveryService: DeliveryService,
        private utilitiesService: UtilitiesService,
        private router: Router,
        private route: ActivatedRoute,
        public location: Location
    ) {
        try {
            this.route.paramMap.subscribe((params: ParamMap) => {
                this.id = +params.get("id");
            });
        } catch (err) {
            this.label.title_ar = " اضافة عميل ";
            this.label.title_en = " Add delivery agent";
        }
    }

    ngOnInit(): void {
        this.formInitialize();
        if (this.id) {
            this.getDeliveryAgentById();
        } else {
            this.isApiCallDone = true;
        }
    }

    async formInitialize() {
        this.form = this.fb.group({});
        this.productFields.forEach((section) => {
            section.fields.forEach((field) => {
                this.form.addControl(
                    field.name,
                    new FormControl(
                        {
                            value:
                                typeof field.default_value === "boolean"
                                    ? field?.default_value
                                    : field?.default_value || "",
                            disabled: field?.disable,
                        },
                        field?.required ? Validators.required : null
                    )
                );
                if (field?.type === DynamicFieldTypes.TextEditor) {
                    this.form.addControl(
                        `${field?.name}_control`,
                        new FormControl("")
                    );
                }
                if (field?.type === DynamicFieldTypes.Password) {
                    this.form.addControl(
                        `${field?.name}_control`,
                        new FormControl("******")
                    );
                }
            });
        });
        console.log('this.form.value');
        console.log(this.form.value);
    }

    setFormValue(product) {
        product.contact_no_country_id = product?.contact_no_country?.id;
        product.password = "*******";

        for (let key in this.form.getRawValue()) {
            if (product.hasOwnProperty(key)) {
                this.form.get(key).setValue(product[key]);
            }
        }
    }

    getDeliveryAgentById() {
        this.utilitiesService.startLoader();
        this.deliveryService
            .getDeliveryAgentById(this.id)
            .pipe(takeUntil(this.unSubscribe$))
            .subscribe((product) => {
                this.label.title_ar = " اضافة عميل ";
                this.label.title_en = " Edit delivery agent # " + product.first_name + ' ' + product.last_name;
                this.setFormValue(product);
                this.isApiCallDone = true;
            });
        this.utilitiesService.stopLoader();
    }

    setFileToForm(file, type) {
        console.log(file);

        const control =
            type === "profile_pic"
                ? this.form.get("profile_pic")
                : this.form.get("civil_id");
        const reader = new FileReader();
        reader.readAsDataURL(file.file);
        reader.onload = () => {
            control.setValue(reader.result as string);
        };
    }

    deleteFile(control: FormControl) {
        control.setValue("");
    }

    handleCancel() {
        this.location.back();
    }

    saveOrUpdate() {
        console.log(this.form.value);
        const form = this.form.value;
        delete form.product_img;

        if (this.form.valid) {
            if (this.id) {
                this.updateDeliveryAgent(form);
            } else {
                this.saveDeliveryAgent(form);
            }
        } else {
            this.checkValidations();
        }
    }

    saveDeliveryAgent(form) {
        this.utilitiesService.startLoader();
        this.deliveryService.addDeliveryAgent(form).subscribe((res) => {
            if (res) {
                this.utilitiesService.stopLoader();
                let msg = "Delivery Agent saved successfully.";
                this.utilitiesService.showSuccessToast(msg);
                // this.createProductCategory.emit();
                this.location.back();
            }
        });
        this.utilitiesService.stopLoader();
    }

    updateDeliveryAgent(form) {
        this.utilitiesService.startLoader();
        this.deliveryService
            .updateDeliveryAgent(this.id, form)
            .subscribe((res) => {
                if (res) {
                    this.utilitiesService.stopLoader();
                    let msg = "Category upated successfully.";
                    this.utilitiesService.showSuccessToast(msg);
                    // this.createProductCategory.emit();
                    this.location.back();
                }
            });
        this.utilitiesService.stopLoader();
    }

    checkValidations() {
        for (const controlName in this.form.controls) {
            if (this.form.controls.hasOwnProperty(controlName)) {
                const control = this.form.get(controlName);
                if (control.invalid) {
                    let msg = `${this.transformControlName(
                        controlName
                    )} is required.`;
                    this.utilitiesService.showErrorToast(msg);
                    break; // Stop the loop as soon as an invalid control is found
                }
            }
        }
        Object.keys(this.form.controls).forEach((field) => {
            const control = this.form.get(field);
            control.markAsTouched({ onlySelf: true });
        });
    }

    transformControlName(controlName: string): string {
        return controlName
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    }

    ngOnDestroy(): void {
        this.unSubscribe$.next();
        this.unSubscribe$.complete();
    }
}
