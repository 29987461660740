<div class="create-product auto-width center-container margin-auto auto-width" fxLayoutAlign="start stretch"
    fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
    <form *ngIf="isApiCallDone" fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
        fxLayout.gt-sm="column" fxLayoutGap="1em" [formGroup]="form">
        <div fxFlex="0 0 auto" fxLayoutAlign="center">
            <div fxFlex="0 0  auto" class="fuse-card  margin-auto auto-width p-16 " fxLayoutAlign="start stretch"
                fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                <div fxLayout="column" fxFlex="100" class="auto-width">
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="16px">
                        <app-form-fields [formData]="productFields" [customFormGroup]="form"
                            [id]="productId"></app-form-fields>

                        <div formArrayName="choices" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                            fxLayoutGap="0px">
                            <ng-container *ngIf="choicesFormArray.value.length">
                                <fieldset>
                                    <legend class="highlight-text-color text-bold">{{'Attribute Choices' | translate}}
                                    </legend>
                                    <ng-container *ngFor="let item of choicesFormArray['controls'];let i=index">
                                        <div [formGroupName]="i" >
                                            <div fxFlex="0 0 100%" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap.gt-sm="5px"
                                                fxLayoutGap.lt-md="0px" fxLayout.lt-md="column">
                                                <mat-form-field appearance="outline" fxFlex.md="50" fxFlex.lg="50" fxFlex="100">
                                                    <mat-label class="text-capitalized">
                                                        {{'Name'| translate}}
                                                    </mat-label>
                                                    <input formControlName="name" matInput name="title">
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" fxFlex.md="50" fxFlex.lg="50" fxFlex="100">
                                                    <mat-label class="text-capitalized">
                                                        {{'Name (Arabic)'| translate}}
                                                    </mat-label>
                                                    <input formControlName="name_ar" matInput name="title">
                                                </mat-form-field>
                                                <mat-form-field *ngIf="form.get('type').value===2" appearance="outline" fxFlex.md="50" fxFlex.lg="50" fxFlex="100">
                                                    <mat-label>
                                                        {{'Color' | translate}}</mat-label>
                                                    <input matInput formControlName="color" />
                                                    <mat-icon (cpToggleChange)="openOrClose('color',i)" (colorPickerSelect)="setColor('color',i)"
                                                        [cpOKButton]="true" [cpCancelButton]="true" [cpSaveClickOutside]="false"
                                                        [cpPosition]="'bottom'" [(colorPicker)]="color" class="color-icon cursor-pointer"
                                                        [style.color]="getColor(i)">
                                                        color_lens
                                                    </mat-icon>
                                                </mat-form-field>
                                                <div fxLayout="row" fxLayoutAlign="center center">
                                                    <mat-icon (click)="removeChoice(i)" class="color-red">delete</mat-icon>
                                                </div>

                                            </div>
                                        </div>
                                    </ng-container>
                                </fieldset>
                            </ng-container>
                            <div fxFlex="1 1 auto" fxLayoutAlign.gt-sm="end center" fxLayoutAlign.lt-md="start stretch"
                                fxLayout.lt-md="column-reverse" fxLayout.gt-sm="row" fxLayoutGap="5px">
                                <button mat-stroked-button (click)="addChoice()">
                                    <mat-icon>add</mat-icon>
                                    {{'Add' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="1 1 auto" fxLayoutAlign.gt-sm="end center" fxLayoutAlign.lt-md="start stretch"
            fxLayout.lt-md="column-reverse" fxLayout.gt-sm="row" fxLayoutGap="5px">
            <button mat-stroked-button (click)="handleCancel()">
                <mat-icon>clear</mat-icon>
                {{'Cancel' | translate}}
            </button>
            <button (click)="saveOrUpdate()" type="submit" mat-flat-button class="primary-bg-color">
                <mat-icon>save</mat-icon>
                {{'Save' | translate}}
            </button>
        </div>
    </form>
</div>