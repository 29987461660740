import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { first } from "rxjs/operators";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";

@Component({
    selector: "app-analytics",
    templateUrl: "./analytics.component.html",
    styleUrls: ["./analytics.component.scss"],
    animations: fuseAnimations,
})
export class AnalyticsComponent implements OnInit, AfterViewInit {
    @Input() appDetail: any;
    @Input() selectedBlock: any;
    @Output() back: EventEmitter<void> = new EventEmitter<any>();
    @Output() saveHander: EventEmitter<void> = new EventEmitter<any>();
    analyticForm: FormGroup;

    titleFormat = [
        {
            title: "Page Title EN",
            control: "page_title_format_en",
        },
        {
            title: "Page Title AR",
            control: "page_title_format_ar",
        },

        {
            title: "Page Description Format EN",
            control: "page_description_format_en",
        },
        {
            title: "Page Description Format AR",
            control: "page_description_format_ar",
        },


        {
            title: "Post Title EN",
            control: "post_title_format_en",
        },
        {
            title: "Post Title AR",
            control: "post_title_format_ar",
        },


        {
            title: "Post Description Format EN",
            control: "post_description_format_en",
        },
        {
            title: "Post Description Format  AR",
            control: "post_description_format_ar",
        },
        {
            title: "Category Title EN",
            control: "category_title_format_en",
        },
        {
            title: "Category Title AR",
            control: "category_title_format_ar",
        },
        {
            title: "Category Description Format EN",
            control: "category_description_format_en",
        },
        {
            title: "Category Description Format  AR",
            control: "category_description_format_ar",
        },
        {
            title: "Brand Title EN",
            control: "brand_title_format_en",
        },
        {
            title: "Brand Title AR",
            control: "brand_title_format_ar",
        },

        {
            title: "Brand description format EN",
            control: "brand_description_format_en",
        },
        {
            title: "Brand description format  AR",
            control: "brand_description_format_ar",
        },

        {
            title: "Product Title EN",
            control: "product_title_format_en",
        },
        {
            title: 'Product Title AR',
            control: 'product_title_format_ar'
        },
        {
            title: "Product description format EN",
            control: "product_description_format_en",
        },
        {
            title: "Product description format  AR",
            control: "product_description_format_ar",
        },
    ]

    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private appService: AppService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService
    ) {
        // this.utilitiesService.autoSave.pipe(first()).subscribe((data) => {
        //     if (data == 11) {
        //         this.save();
        //     }
        // });
    }

    ngOnInit(): void {
        this.formInitialize();
    }

    async formInitialize() {
        this.analyticForm = this.fb.group({
            title: [this.appDetail.title ? this.appDetail.title : null],
            gtm_code: [
                this.appDetail.gtm_code ? this.appDetail.gtm_code : null,
            ],
            seo_title_ar: [''],
            seo_title_en: [''],

            meta_description_ar: [""],
            meta_description_en: [""],
            facebook_domain_verification: [null],
            google_site_verification: [null],
            fb_pixel_value: [null],

            page_title_format_ar: [''],
            page_description_format_en: [""],
            page_description_format_ar: [""],
            post_title_format_ar: [''],
            post_description_format_en: [""],
            post_description_format_ar: [""],
            category_title_format_ar: [''],
            category_description_format_en: [""],
            category_description_format_ar: [""],
            brand_title_format_ar: [''],
            brand_description_format_en: [""],
            brand_description_format_ar: [""],
            product_title_format_ar: [''],
            product_description_format_en: [""],
            product_description_format_ar: [""],
            page_title_format_en: [''],
            post_title_format_en: [''],
            category_title_format_en: [''],
            brand_title_format_en: [''],
            product_title_format_en: ['']

        });
        await this.setValue();
    }

    async setValue() {
        Object.keys(this.analyticForm.value).forEach((key) => {
            if (this.appDetail.hasOwnProperty(key)) {
                this.analyticForm?.get(key).setValue(this.appDetail[key]);
            }
        });

        this.analyticForm.valueChanges.subscribe((data) => {
            this.utilitiesService.formChangedOrNot(true);
        });
    }

    goBack() {
        this.back.emit();
    }

    async save() {
        let content = `Are you sure, Do you want to update? `;
        let heading = "Update";
        let fromApp = false;
        let size = this.utilitiesService.isMobileAlertModal();
        const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });

        dialogRef.afterClosed().subscribe(async (resp) => {
            this.utilitiesService.autoSave.next(0);
            if (resp) {
                let form = this.analyticForm.value;
                this.utilitiesService.formChangedOrNot(false);
                const resp = await this.appService
                    .addAppSettings(form)
                    .toPromise();

                if (resp) {
                    this.utilitiesService.formChanged$.next(false);
                    let successMsg = this.translate.instant(
                        "Analytical settings updated successfully"
                    );
                    this.utilitiesService.showSuccessToast(successMsg);
                    this.saveHander.emit();
                }
            }
        });
    }

    ngAfterViewInit(): void {
        document.getElementById("toolbar").scrollIntoView();
    }

    onKeywordSelection(keyword) {
        const page_title_format = `${this.analyticForm.get("page_title_format").value
            }${keyword}`;
        this.analyticForm.get("page_title_format").setValue(page_title_format);

    }
}