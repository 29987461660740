import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "../api.service";

@Injectable({
    providedIn: "root",
})
export class TranslationEditorService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    getLanguages(limit = 10, offset = 0, search = "") {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        return this.apiService.get(
            this.sharedService.getLanguages,
            params,
            undefined
        );
    }

    addLanguages(form) {
        return this.apiService.post(
            this.sharedService.updateLanguage,
            form,
            undefined
        );
    }

    undoChanges(form) {
        return this.apiService.post(
            this.sharedService.undoChanges,
            form,
            undefined
        );
    }
}
