import {
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CatalogueService } from "app/services/catalogue/catalogue.service";
import { UtilitiesService } from "app/services/utilitiesService";

@Component({
    selector: "app-create-tag",
    templateUrl: "./create-tag.component.html",
    styleUrls: ["./create-tag.component.scss"],
})
export class CreateTagComponent implements OnInit {
    form: FormGroup;
    id: any;
    title: string;
    oldData: any = null;
    constructor(
        private dialogRef: MatDialogRef<CreateTagComponent>,
        private fb: FormBuilder,
        private catalogueService: CatalogueService,
        private utilitiesService: UtilitiesService,
        private cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data) {
            this.oldData = data;
            this.title = "Edit Tag";
            this.cdr.markForCheck();
            console.log(data);
        } else {
            this.title = "Create Tag";
        }
    }

    ngOnInit(): void {
        this.formInit();
    }

    formInit() {
        this.form = this.fb.group({
            id: this.oldData?.id ? this.oldData.id : "",
            name: [
                this.oldData?.name ? this.oldData.name : "",
                Validators.required,
            ],
            name_ar: this.oldData?.name_ar ? this.oldData.name_ar : "",
            order_by: this.oldData?.order_by ? this.oldData.order_by : "0",
        });
    }

    async saveOrUpdate() {
        let form = this.form.value;
        if (this.form.valid) {
            if (this.oldData?.id) {
                console.log("update");
                await this.update(form);
            } else {
                await this.save(form);
            }
        }
    }
    async update(form) {
        this.utilitiesService.startLoader();
        await this.catalogueService
            .updateTag(form, this.oldData.id)
            .subscribe((res) => {
                console.log("update");
                console.log(res);
                if (res) {
                    this.utilitiesService.stopLoader();
                    let msg = "Tag Updated successfully.";
                    this.utilitiesService.showSuccessToast(msg);
                    this.dialogRef.close(true);
                }
            });
        this.utilitiesService.stopLoader();
    }
    async save(form) {
        this.utilitiesService.startLoader();
        await this.catalogueService.addTag(form).subscribe((res) => {
            console.log(res);
            if (res) {
                this.utilitiesService.stopLoader();
                let msg = "Tag saved successfully.";
                this.utilitiesService.showSuccessToast(msg);
                this.dialogRef.close(true);
            }
        });
        this.utilitiesService.stopLoader();
    }

    handleCancel() {
        this.dialogRef.close();
    }
}
