import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { KeyValue } from "@angular/common";
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { MediaTypes, MediaTypesAccept } from "app/constants/media-type";
import { PageElements } from "app/constants/types";
import { MediaService } from "app/services/media.service";
import { NavMenuService } from "app/services/nav-menu.service";
import { PostsService } from "app/services/posts.service";
import { SliderService } from "app/services/sliderService";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { isNumber } from "lodash";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
import { FullscreenAdditionCssComponent } from "../fullscreen-addition-css/fullscreen-addition-css.component";
import { GalleryPopupComponent } from "../gallery-popup/gallery-popup.component";
import { ImageSizeWarningComponent } from "../image-size-warning/image-size-warning.component";
import { CreateSliderComponent } from "../create-sliders/create-sliders.component";

@Component({
  selector: "app-create-post",
  templateUrl: "./create-post.component.html",
  styleUrls: ["./create-post.component.scss"],
  animations: fuseAnimations,
})
export class CreatePostComponent implements OnInit {
  @Input() elements: any;
  @Input() id: any = "";
  @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
  @Output() createPostEvent: EventEmitter<void> = new EventEmitter<any>();

  selectedShow_on = [];
  expandIndex = [];
  isExpand = true;
  status = [
    { id: "DRAFT", name: "Draft" },
    { id: "PUBLISHED", name: "Published" },
    { id: "ARCHIVED", name: "Archived" },
  ];
  show_on = [
    {
      id: 1,
      name: "All",
      icon: "assets/icons/show_on/select-all.svg",
      selected: "",
    },
    {
      id: 2,
      name: "Mobile Web",
      icon: "assets/icons/show_on/mobile-web.svg",
      selected: "",
    },
    {
      id: 3,
      name: "Desktop",
      icon: "assets/icons/show_on/desktop.svg",
      selected: "",
    },
    {
      id: 4,
      name: "Mobile App",
      icon: "assets/icons/show_on/mobile-app.svg",
      selected: "",
    },
  ];
  options: any = {
    showLineNumbers: true,
    tabSize: 2,
  };
  postForm: FormGroup;
  postCategories = [];
  configurations: {
    input: string;
    name: string;
    required: boolean;
    type: any;
    media_type?: any;
    show_only?: any;
  }[] = [];
  typeEnum = PageElements;
  ckeConfig: any;
  postDetail: any;
  files = [];
  slider = [];
  menu = [];
  elementsArray = [];
  submitted = false;
  isLoading: boolean = false;
  mediaTypesAccept = MediaTypesAccept;
  mediaType = MediaTypes;

  constructor(
    private postService: PostsService,
    private translate: TranslateService,
    private fb: FormBuilder,
    public utilitiesService: UtilitiesService,
    private dialog: MatDialog,
    private sliderService: SliderService,
    private navMenuService: NavMenuService,
    public translationService: TranslationService
  ) {
    this.configurations = [];
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  getFormControlType(key: string) {
    if (key) {
      let controlType = "";
      if (key) {
        if (key == "language") {
          controlType = "language";
        } else if (key == "order") {
          controlType = "order";
        } else if (key == "show_on") {
          controlType = "show_on";
        } else if (key == "id") {
          controlType = "";
        } else if (key == "label") {
          controlType = "label";
        } else if (key == "label_ar") {
          controlType = "label_ar";
        } else if (key == "isCodeEditor") {
          controlType = "";
        } else if (key == "parentName") {
          controlType = "";
        } else if (key == "parentID") {
          controlType = "";
        } else {
          controlType = this.configurations.find((element) => element.name == key).input;
        }
        return controlType;
      }
    }
  }
  getFormControlLabel(key: string) {
    let controlName = "";
    if (key) {
      if (key == "language") {
        controlName = "Language";
      } else if (key == "order") {
        controlName = "Order";
      } else if (key == "show_on") {
        controlName = "Show On";
      } else if (key == "id") {
        controlName = "";
      } else if (key == "label") {
        controlName = "Label";
      } else if (key == "label_ar") {
        controlName = "Label Ar";
      } else if (key == "isCodeEditor") {
        controlName = "";
      } else if (key == "parentName") {
        controlName = "";
      } else if (key == "parentID") {
        controlName = "";
      } else {
        controlName = this.configurations.find((element) => element.name == key).name;
      }
      return controlName;
    }
  }
  containAr(key: string) {
    return key.includes("_ar");
  }

  isMenu(key: string) {
    if (key && key != "language") {
      const controlName = this.configurations.find((element) => element.name == key).type;
      return controlName ? false : true;
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.id) {
      this.utilitiesService.startLoader();
    }
    await this.formInitialize();
    this.ckeConfig = {
      sanitize: false,
      allowedContent: true,
      versionCheck: false,
      removeButtons: "CreateDiv,BlockQuote,Superscript,Subscript,PasteFromWord,PasteText,Source",
    };
    await this.getSliderList();
    await this.getMenuList();
    await this.getPostCategories();
    if (this.id) {
      await this.getPostDetail();
    }
  }

  getShowOnly(controlLabel) {
    return this.configurations.filter((data) => data.name == controlLabel)[0].show_only || null;
  }

  getMediaType(controlLabel) {
    return this.configurations.filter((data) => data.name == controlLabel)[0].media_type || null;
  }
  async getPostCategories() {
    try {
      const categoryList = await this.postService.getPostsCategory(99999, 0).toPromise();
      if (categoryList) {
        this.postCategories = categoryList.results;
      }
    } catch {
    } finally {
    }
  }
  async getPostDetail(isDelete = false) {
    if (isDelete) {
      (this.postForm.controls["configurations"] as FormArray).clear();
    }
    try {
      const postDetail = await this.postService.getPostDetail(this.id).toPromise();
      if (postDetail) {
        this.postDetail = postDetail;
        this.setValue();
        if (this.postDetail.post_element.length > 0) {
          let formArray = await this.createFormGroupForEdit();
          formArray.forEach((data) => {
            let formGroup = this.fb.group(data);
            (this.postForm.get("configurations") as FormArray).push(formGroup);
          });
        } else {
          (this.postForm.get("configurations") as FormArray).clear();
        }
      }
    } catch {
    } finally {
      this.utilitiesService.stopLoader();
    }
  }
  async getSliderList() {
    try {
      const sliderList = await this.sliderService.getSliders(99999, 0).toPromise();
      if (sliderList) {
        this.slider = sliderList.results;
      }
    } catch {
    } finally {
    }
  }
  async getMenuList() {
    try {
      const menuList = await this.navMenuService.getMenuList(99999, 0).toPromise();
      if (menuList) {
        this.menu = menuList.results;
      }
    } catch {
    } finally {
    }
  }

  async drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.elementsArray, event.previousIndex, event.currentIndex);
      if (event.previousIndex > event.currentIndex) {
        const formGroup = (this.postForm.get("configurations") as FormArray).at(event.previousIndex);
        (this.postForm.get("configurations") as FormArray).removeAt(event.previousIndex);
        (this.postForm.get("configurations") as FormArray).insert(event.currentIndex, formGroup);
      }
      if (event.previousIndex < event.currentIndex) {
        const formGroup = (this.postForm.get("configurations") as FormArray).at(event.currentIndex);
        (this.postForm.get("configurations") as FormArray).removeAt(event.currentIndex);
        (this.postForm.get("configurations") as FormArray).insert(event.previousIndex, formGroup);
      }
    } else {
      await this.addField(event.item.data, event.currentIndex);
    }
    await this.pushConfig();
  }

  async addField(fieldType: any, index: number) {
    (this.postForm.get("configurations") as FormArray).insert(index, this.createFormGroup(fieldType));
    this.elementsArray.splice(index, 0, fieldType);
  }

  async pushConfig() {
    this.elementsArray.forEach((data) => {
      data.configurations.forEach((items) => {
        this.configurations.push(items);
      });
    });
  }

  handleCancel() {
    this.cancelClick.emit();
  }
  async formInitialize() {
    this.postForm = this.fb.group({
      id: [null],
      status: ["DRAFT"],
      title: [null, [Validators.required]],
      title_en: [null],
      title_ar: [null],
      slug: [null],
      meta_description: [null],
      meta_description_en: [null],
      meta_description_ar: [null],
      language: ["en"],
      visibility: [1],
      css: [null],
      thumbnail: [null],
      category: [null],
      configurations: this.fb.array([]),
      summary_en: [null],
      summary_ar: [null],
    });
  }

  checkFormArrayValidation() {
    if ((this.postForm.get("configurations") as FormArray).length <= 0 && this.submitted) {
      return false;
    } else {
      return true;
    }
  }

  setValue() {
    this.postForm.controls.id.setValue(this.postDetail.id ? this.postDetail.id : null);
    this.postForm.controls.visibility.setValue(this.postDetail.visibility ? this.postDetail.visibility : null);

    this.postForm.controls.status.setValue(this.postDetail.status ? this.postDetail.status : null);
    this.postForm.controls.title.setValue(this.postDetail.title ? this.postDetail.title : null);
    this.postForm.controls.thumbnail.setValue(this.postDetail.thumbnail ? this.postDetail.thumbnail : null);
    this.postForm.controls.title_ar.setValue(this.postDetail.title_ar ? this.postDetail.title_ar : null);
    this.postForm.controls.slug.setValue(this.postDetail.slug ? this.postDetail.slug : null);
    this.postForm.controls.meta_description.setValue(this.postDetail.meta_description ? this.postDetail.meta_description : null);
    this.postForm.controls.meta_description_en.setValue(this.postDetail.meta_description_en ? this.postDetail.meta_description_en : null);
    this.postForm.controls.css.setValue(this.postDetail.css ? this.postDetail.css : null);
    this.postForm.controls.meta_description_ar.setValue(this.postDetail.meta_description_ar ? this.postDetail.meta_description_ar : null);
    this.postForm.controls.summary_en.setValue(this.postDetail.summary_en ? this.postDetail.summary_en : null);
    this.postForm.controls.summary_ar.setValue(this.postDetail.summary_ar ? this.postDetail.summary_ar : null);
    this.postForm.controls.category.setValue(this.postDetail.category ? this.postDetail.category : null);
  }
  async createFormGroupForEdit() {
    let finalArray = [];
    let transaltedConfig = [];
    this.postDetail.post_element.forEach((data) => {
      let formGroupObject = {
        order: data.order,
        language: "en",
        id: data.id,
        show_on: [data.show_on],
        isCodeEditor: [false],
        label_ar: [data.label_ar],
        label: [data.label],
        parentName: data.element_type.name,
        parentID: data.element_type.id,
      };

      data.element_type.configurations.forEach(async (configuration) => {
        let stripedKey = configuration.name.replace("_ar", "");
        if (configuration.translate) {
          transaltedConfig.push(`${configuration.name}_ar`);
          formGroupObject = {
            ...formGroupObject,
            [configuration.name]: [
              data.value[configuration.name] ? data.value[configuration.name] : null,
              Validators.compose(configuration.required ? [Validators.required] : []),
            ],
          };
        } else if (transaltedConfig.find((res) => res == configuration.name)) {
          formGroupObject = {
            ...formGroupObject,
            [configuration.name]: [
              data.value_ar[stripedKey] ? data.value_ar[stripedKey] : null,
              Validators.compose(configuration.required ? [Validators.required] : []),
            ],
          };
        } else {
          formGroupObject = {
            ...formGroupObject,
            [configuration.name]: [
              data.value[configuration.name] ? data.value[configuration.name] : null,
              Validators.compose(configuration.required ? [Validators.required] : []),
            ],
          };
        }
        this.configurations.push(configuration);
      });
      finalArray.push(formGroupObject);
    });
    return finalArray;
  }

  createFormGroup(elementType) {
    let formGroupObject = {
      order: 0,
      language: "en",
      id: null,
      show_on: [1],
      isCodeEditor: [false],
      label: null,
      label_ar: null,
      parentName: elementType.name,
      parentID: elementType.id,
    };
    elementType.configurations.forEach((element) => {
      formGroupObject = {
        ...formGroupObject,
        [element.name]: [null, Validators.compose(element.required ? [Validators.required] : [])],
      };
    });
    return this.fb.group(formGroupObject);
  }
  async getElementType(configObject) {
    let elementType = "";
    let CAROUSEL = "COLLECTION";
    for (let key in configObject) {
      this.elements.forEach((data) => {
        if (key == "slider") {
          if (data.name.toLowerCase() == CAROUSEL.toLowerCase()) {
            elementType = data.id;
          }
        } else {
          if (data.name.toLowerCase() == key.toLowerCase()) {
            elementType = data.id;
          }
        }
      });
    }
    return elementType;
  }

  checkInvalid(index) {
    if ((this.postForm.get("configurations") as FormArray).length > 0 && this.submitted) {
      return (this.postForm.get("configurations") as FormArray).controls[index].valid;
    } else {
      return true;
    }
  }
  async save() {
    this.submitted = true;
    let invlidData = [];
    this.expandIndex = [];
    (this.postForm.get("configurations") as FormArray).controls.forEach((data, index) => {
      if (data.invalid) {
        invlidData.push(index);
      }
    });
    invlidData.forEach((data) => {
      setTimeout(() => {
        this.expandIndex[data] = data;
      }, 0);
    });
    if (this.postForm.valid && (this.postForm.get("configurations") as FormArray).length > 0) {
      // try {
      this.utilitiesService.startLoader();
      let form = this.postForm.value;
      let postElementArray = [];
      let configLength = form.configurations.length;
      for (let i = 0; i < configLength; i++) {
        const formValues = form.configurations[i];
        delete formValues.isCodeEditor;
        const order = i;
        let value = {};
        let value_ar = {};

        let selectedKey: string = null;
        selectedKey = formValues.parentName.toLocaleLowerCase();
        let config = this.elements.filter((data) => data.id == formValues.parentID)[0];
        let transaltedConfig = [];
        if (config) {
          config.configurations.forEach((data) => {
            if (data.translate) {
              transaltedConfig.push(data.name);
            }
          });
        }
        for (let key in formValues) {
          let stripedKey = key.replace("_ar", "");
          if (transaltedConfig.find((res) => `${res}` == key)) {
            value[key] = formValues[key];
          } else if (transaltedConfig.find((res) => res == stripedKey)) {
            value_ar[stripedKey] = formValues[key];
          } else {
            value[key] = formValues[key];
            value_ar[key] = formValues[key];
          }
        }
        let showOn = form.configurations[i].show_on;
        if (isNumber(showOn)) {
          showOn = [showOn ? showOn : 5];
        }
        postElementArray.push({
          id: form.configurations[i].id,
          language: formValues.language,
          value: value,
          value_ar: value_ar,
          order: order,
          element_type: formValues.parentID,
          show_on: showOn.length ? showOn : [5],
          label: formValues.label,
          label_ar: formValues.label_ar,
        });
      }
      postElementArray.forEach((data) => {
        if (data.value) {
          if (!data.value.id) {
            delete data.value.id;
          }
        }
        if (data.value_ar) {
          if (!data.value_ar.id) {
            delete data.value_ar.id;
          }
        }
      });
      let finalForm = {
        post: {
          visibility: form.visibility,
          status: form.status,
          title: form.title,
          title_en: form.title,
          title_ar: form.title_ar,
          slug: form.slug,
          meta_title: null,
          meta_title_en: null,
          meta_title_ar: null,
          meta_description: form.meta_description,
          meta_description_en: form.meta_description,
          meta_description_ar: form.meta_description_ar,
          summary_ar: form.summary_ar,
          summary_en: form.summary_en,
          css: form.css,
          thumbnail: form.thumbnail,
          category: form.category,
        },
        post_element: postElementArray,
      };
      if (this.id) {
        this.updatePost(finalForm);
      } else {
        this.savePost(finalForm);
      }
      // } catch {
      //     this.utilitiesService.stopLoader();
      // } finally {
      // }
    } else {
      if (!this.isExpand && !this.postForm.valid) {
        this.isExpand = true;
      }
    }
  }
  savePost(finalForm) {
    try {
      this.postService.addPost(finalForm).subscribe((data) => {
        let successMsg = this.translate.instant("Post created successfully");
        this.utilitiesService.showSuccessToast(successMsg);
        this.createPostEvent.emit();
      });
    } catch {
      this.utilitiesService.stopLoader();
    } finally {
      // this.isLoading = false;
    }
  }
  updatePost(finalForm) {
    try {
      this.postService.updatePost(finalForm, this.id).subscribe((data) => {
        let successMsg = this.translate.instant("Post updated successfully");
        this.utilitiesService.showSuccessToast(successMsg);
        this.createPostEvent.emit();
      });
    } catch {
      this.utilitiesService.stopLoader();
    } finally {
      // this.utilitiesService.stopLoader();
      // this.isLoading = false;
    }
  }

  setFileToForm($event, controlIndex, configIndex, type) {
    ((this.postForm.get("configurations") as FormArray).at(configIndex) as FormGroup).get(type).setValue($event.file);
  }
  delete(index, config) {
    let content = `Are you sure, Do you want to delete this post ? `;
    let heading = "Delete";
    let fromApp = false;
    let size = this.utilitiesService.isMobileAlertModal();
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: { content, heading, fromApp },
      maxWidth: "",
      width: `${size.width}`,
      height: `${size.height}`,
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        if (!this.id) {
          this.removePageLoacally(index);
        } else {
          let id = config.controls.id.value;
          if (id) {
            this.utilitiesService.startLoader();
            this.postService.deletePostItems(id).subscribe((data) => {
              this.utilitiesService.startLoader();
              this.getPostDetail(true);
            });
          } else {
            this.removePageLoacally(index);
          }
        }
        document.getElementById("toolbar").scrollIntoView();
      }
    });
  }
  removePageLoacally(index) {
    (this.postForm.get("configurations") as FormArray).removeAt(index);
  }

  deleteFile(index: number, type) {
    ((this.postForm.get("configurations") as FormArray).at(index) as FormGroup).get(type).setValue(null);
  }

  hideLanguage(config) {
    let isShow = false;
    for (let key in config) {
      if (!isShow) {
        if (
          key == "html" ||
          key == "html_ar" ||
          key == "image" ||
          key == "image_ar" ||
          key == "embed" ||
          key == "embed_ar" ||
          key == "video" ||
          key == "video_ar" ||
          key == "pdf" ||
          key == "pdf_ar" ||
          key == "audio" ||
          key == "audio_ar"
        ) {
          isShow = true;
        } else {
          isShow = false;
        }
      }
    }
    return isShow;
  }
  getShowOn(pageElementFormGroup) {
    let showOnImg = [];
    let showOn = pageElementFormGroup.controls.show_on ? pageElementFormGroup.controls.show_on.value : [];
    if (showOn && showOn.length) {
      showOn.forEach((data) => {
        this.show_on.forEach((item) => {
          if (item.id == data) {
            showOnImg.push(item.icon);
          }
        });
      });
    }

    return showOnImg;
  }
  getElementName(config) {
    let keyName = "";
    if (config && ((config.label && config.label.value) || (config.label_ar && config.label_ar.value))) {
      keyName = config.label.value ? config.label.value : config.label_ar.value;
    } else {
      for (let key in config) {
        this.elements.forEach((data) => {
          if (key == "slider") {
            keyName = "COLLECTION";
          } else {
            if (data.name.toLowerCase() == key.toLowerCase()) {
              keyName = data.name;
            }
          }
        });
      }
    }

    return keyName;
  }

  addOrRemoveShowOnItems(index, formGroup) {
    const localIndex = this.selectedShow_on.indexOf(this.show_on[index].id);
    if (localIndex != -1) {
      this.selectedShow_on.splice(localIndex, 1);
    } else {
      this.selectedShow_on.push(this.show_on[index].id);
    }

    formGroup.setValue(this.selectedShow_on.length > 0 ? this.selectedShow_on : []);
  }
  setSelected(id, formGroup, isLabel) {
    let selected = "";
    let value = formGroup.value;
    if (value && value.length > 0) {
      value.forEach((data) => {
        if (data == id) {
          isLabel ? (selected = "selected-text") : (selected = "selected");
        }
      });
    }
    return selected;
  }
  zoomAdditionalCss(formControl, index, type) {
    let heading = "";
    if (type == "embed" || type == "embed_ar") {
      heading = "EMBED";
    } else {
      heading = "HTML";
    }
    const dialogRef = this.dialog.open(FullscreenAdditionCssComponent, {
      data: { formControl, heading },
      maxWidth: "",
      width: "100%",
      height: "100%",
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        ((this.postForm.get("configurations") as FormArray).at(index) as FormGroup).get(type).setValue(resp.value);
      }
    });
  }
  getLength() {
    if ((this.postForm.get("configurations") as FormArray).length == 0) {
      return true;
    } else {
      return false;
    }
  }
  zoomPageCss(formControl) {
    let heading = "CSS";
    const dialogRef = this.dialog.open(FullscreenAdditionCssComponent, {
      data: { formControl, heading },
      maxWidth: "",
      width: "100%",
      height: "100%",
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        this.postForm.controls.css.setValue(resp.value);
      }
    });
  }

  deleteFileThumbail() {
    this.postForm.controls.thumbnail.setValue(null);
  }

  setFileToFormThumbail(file) {
    this.postForm.controls.thumbnail.setValue(file.file);
  }

  openGallery(formControl, isMultiSelect = true) {
    let size = {
      height: "70%",
      width: "70%",
    };
    let isMobile = this.utilitiesService.isMobile();
    if (isMobile) {
      size.height = "80%";
      size.width = "100%";
    }
    const dialogRef = this.dialog.open(GalleryPopupComponent, {
      data: { formControl, isMultiSelect },
      maxWidth: "",
      width: `${size.width}`,
      height: `${size.height}`,
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
      }
    });
  }

  addNewCollection(control: FormControl) {
    const dialogRef = this.dialog.open(CreateSliderComponent, {
      data: { isPopUp: true },
      position: { right: "0", top: "10px" },
      width: "90%",
      height: "100%",
      closeOnNavigation: true,
      panelClass: "left-to-right-dialog",
    });

    setTimeout(() => {
      dialogRef.componentInstance.dialogRef.addPanelClass("open");
    }, 100);

    dialogRef.afterClosed().subscribe(async (resp) => {
      if (resp) {
        await this.getSliderList();

        control.setValue(resp.id);
      }
    });
  }
}
