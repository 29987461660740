import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import {
    FormGroup,
    FormControl,
    FormBuilder,
    Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { first } from "rxjs/operators";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";
import { CustomCountryComponent } from "../custom-country/custom-country.component";

@Component({
    selector: "app-currency",
    templateUrl: "./currency.component.html",
    styleUrls: ["./currency.component.scss"],
    animations: fuseAnimations,
})
export class CurrencyComponent implements OnInit {
    @ViewChild(CustomCountryComponent, { static: false })
    customCountryComponent: CustomCountryComponent;
    @Input() selectedBlock: any;
    @Input() appDetail: any;
    @Output() back: EventEmitter<void> = new EventEmitter<any>();
    @Output() saveHander: EventEmitter<void> = new EventEmitter<any>();
    currencyForm: FormGroup;
    currencyDropDown = [];
    countryModeSettings = {};
    country = [];
    currency = [];
    currencySettings = {};
    countryDropDown = [];
    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private appService: AppService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
    ) {
        this.getDropdowndata();
    }
    formInitialize() {
        this.currencyForm = this.fb.group({
            base_currency: [
                this.appDetail.base_currency
                    ? this.appDetail.base_currency
                    : null,
                [Validators.required],
            ],
            base_country: [
                this.appDetail.base_country
                    ? this.appDetail.base_country
                    : null,
            ],
        });
    }
    ngAfterViewInit(): void {
        document.getElementById("toolbar").scrollIntoView();
    }

    ngOnInit(): void {
        this.formInitialize();
    }

    async getDropdowndata() {
        this.utilitiesService.startLoader();
        await this.getCurrencies();
        await this.getCountries();
    }
    async getCountries() {
        try {
            const country = await this.appService.getCountries().toPromise();
            if (country) {
                this.country = country;
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
            this.currencyForm.valueChanges.subscribe((data) => {
                this.utilitiesService.formChangedOrNot(true);
            });
        }
    }
    async getCurrencies() {
        try {
            const currency = await this.appService.getCurrencies().toPromise();
            if (currency) {
                this.currency = currency;
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }

    goBack() {
        let fromApp = true;
        if (!this.utilitiesService.formChanged$.value) {
            this.back.emit();
        } else {
            let content = `You have unsaved changes, Please save before changing the page`;
            let heading = "Alert";
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp == "yes") {
                } else if (resp == "no") {
                    this.utilitiesService.formChangedOrNot(false);
                    this.back.emit();
                }
            });
        }
    }
    async save() {
        let formValid =
            await this.customCountryComponent.checkFormIsValidOrNot();
        if (this.currencyForm.valid && formValid) {
            try {
                let content = `Are you sure, Do you want to update? `;
                let heading = "Update";
                let fromApp = false;
                let size = this.utilitiesService.isMobileAlertModal();
                const dialogRef = this.dialog.open(AlertModalComponent, {
                    data: { content, heading, fromApp },
                    maxWidth: "",
                    width: `${size.width}`,
                    height: `${size.height}`,
                });
                dialogRef.afterClosed().subscribe(async (resp) => {
                    this.utilitiesService.autoSave.next(0);
                    if (resp) {
                        this.utilitiesService.startLoader();
                        let form = this.currencyForm.value;
                        const resp = await this.appService
                            .addAppSettings(form)
                            .toPromise();

                        if (resp) {
                            this.utilitiesService.formChangedOrNot(false);
                            let successMsg = this.translate.instant(
                                "Country rules updated successfully"
                            );
                            let countryRuleForm =
                                this.customCountryComponent.form.value;
                            let rules = [];
                            if (countryRuleForm.country_rules.length > 0) {
                                rules = countryRuleForm.country_rules.map(
                                    (data, index) => {
                                        return { ...data };
                                    }
                                );
                            }
                            let countryIDs: any[][];
                            countryIDs = [];
                            let count = 0;
                            rules.forEach(async (rule, index) => {
                                rule.sequence = index;
                                if (rule.validation_id == 0) {
                                    rule.validation_id = null;
                                }
                                if (rule.icon_type == 1) {
                                    rule.icon_image = null;
                                }
                                if (rule.icon_type == 2) {
                                    rule.icon = "";
                                }
                                if (!rule.show_paci) {
                                    rule.paci_note_ar = "";
                                    rule.paci_note_en = "";
                                    rule.is_paci_mandatory = false;
                                }
                                if (!rule.show_civil_id) {
                                    rule.is_civil_id_mandatory = false;
                                    rule.civil_id_note_en = "";
                                    rule.civil_id_note_ar = "";
                                }
                                count = count + 1;
                                countryIDs[index] = [];
                                rule.countries.forEach((country) => {
                                    countryIDs[index].push(country.id);
                                });
                            });
                            if (count == rules.length) {
                                rules.forEach((dataRule, index) => {
                                    dataRule.countries = countryIDs[index];
                                });
                            }

                            const respsonse = await this.appService
                                .addCustomCountry(rules)
                                .toPromise();
                            if (respsonse) {
                                rules = [];
                                countryIDs = [];
                                this.utilitiesService.stopLoader();
                                this.utilitiesService.showSuccessToast(
                                    successMsg
                                );
                                this.customCountryComponent.getCountryRules();
                                this.saveHander.emit();
                            }
                        }
                    }
                });
            } catch {
                this.utilitiesService.stopLoader();
            }
        }
    }
}
