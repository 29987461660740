import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SliderElements } from "app/constants/types";
import { SliderService } from "app/services/sliderService";
import { UtilitiesService } from "app/services/utilitiesService";
import { TranslateService } from "@ngx-translate/core";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
import { SharedService } from "app/services/sharedService";
import { ThemePalette } from "@angular/material/core";
import { GalleryPopupComponent } from "../gallery-popup/gallery-popup.component";
import { FullscreenAdditionCssComponent } from "../fullscreen-addition-css/fullscreen-addition-css.component";
import { fuseAnimations } from "@fuse/animations";
import { ImageSizeWarningComponent } from "../image-size-warning/image-size-warning.component";
import { MediaService } from "app/services/media.service";
import { PostsService } from "app/services/posts.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-create-sliders",
  templateUrl: "./create-sliders.component.html",
  styleUrls: ["./create-sliders.component.scss"],
  animations: fuseAnimations,
})
export class CreateSliderComponent implements OnInit {
  @ViewChild("editor") editor;
  color: ThemePalette = "primary";
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  @Input() id: any = "";
  isPopUp = false;

  @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
  @Output() createSliderEvent: EventEmitter<void> = new EventEmitter<any>();
  tickInterval = 1;
  typeEnum = SliderElements;
  productControl = new FormControl("");
  brandControl = new FormControl("");
  categoryControl = new FormControl("");
  isLoading: boolean = false;
  ckeConfig;
  sliderForm: FormGroup;
  sliderDetail: any = "";
  types = [
    { id: 1, name: "IMAGE" },
    { id: 2, name: "PRODUCT" },
    { id: 3, name: "CATEGORY" },
    { id: 4, name: "BRAND" },
    { id: 5, name: "HTML" },
    { id: 6, name: "POST" },
    { id: 7, name: "RELATED PRODUCTS" },
    { id: 8, name: "POST CATEGORY" },
    { id: 9, name: "PRODUCT SET" },
    { id: 10, name: "RECENTLY VIEWED PRODUCTS" },
  ];
  showAs = [
    { id: 1, name: "Slider" },
    { id: 2, name: "Grid" },
    { id: 3, name: "List" },
  ];

  relationTypes = [
    { id: "default", name: "Default" },
    { id: "categories", name: "Category" },
    { id: "tags", name: "Tags" },
  ];

  productSet = [
    { id: 1, name: "Tag" },
    { id: 2, name: "Product Type" },
    { id: 3, name: "Category" },
    // { id: 4, name: "Subcategory" },
    { id: 5, name: "Brand" },
    { id: 6, name: "Price Range" },
    { id: 7, name: "Name" },
  ];
  files = [];
  elementsArray = [];
  isLoadingProductSet: boolean;

  constructor(
    private sliderService: SliderService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public utilitiesService: UtilitiesService,
    private translate: TranslateService,
    private sharedService: SharedService,
    private mediaService: MediaService,
    private postService: PostsService,
    public dialogRef: MatDialogRef<CreateSliderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isPopUp = data.isPopUp;
  }
  async getActiveType() {
    if (this.sliderForm.get("type").value == this.typeEnum.BRAND) {
      return "brand";
    } else if (this.sliderForm.get("type").value == this.typeEnum.CATEGORY) {
      return "category";
    } else if (this.sliderForm.get("type").value == this.typeEnum.POST) {
      return "post";
    } else if (this.sliderForm.get("type").value == this.typeEnum.HTML) {
      return "html";
    } else if (this.sliderForm.get("type").value == this.typeEnum.IMAGE) {
      return "image";
    } else if (this.sliderForm.get("type").value == this.typeEnum.PRODUCT) {
      return "product";
    }
  }
  async addField(fieldType: any, index: number) {
    let formArray = await this.getActiveType();
    let formGroup: any;
    if (formArray == this.typeEnum.PRODUCT) {
      formGroup = this.addProductFormGroup();
    } else if (formArray == this.typeEnum.BRAND) {
      formGroup = this.addBrandFormGroup();
    } else if (formArray == this.typeEnum.CATEGORY) {
      formGroup = this.addCategoryFormGroup();
    } else if (formArray == this.typeEnum.IMAGE) {
      formGroup = this.addImageFormGroup();
    } else if (formArray == this.typeEnum.HTML) {
      formGroup = this.addHtmlFormGroup();
    } else if (formArray == this.typeEnum.POST) {
      formGroup = this.addPostFormGroup();
    } else if (formArray == this.typeEnum.POST_CATEGORY) {
      formGroup = this.addpostCategoryFormGroup();
    }
    (this.sliderForm.get(formArray) as FormArray).insert(index, formGroup);
    this.elementsArray.splice(index, 0, fieldType);
  }

  isButtonShow(type) {
    if (type == this.typeEnum.PRODUCT_SET) {
      return false;
    } else if (type == this.typeEnum.RELATED_PRODUCTS) {
      return false;
    } else if (type == this.typeEnum.RECENTLY_VIEWED_PRODUCTS) {
      return false;
    } else {
      return true;
    }
  }

  async drop(event: CdkDragDrop<string[]>) {
    let formArray = await this.getActiveType();
    if (event.previousContainer === event.container) {
      moveItemInArray(this.elementsArray, event.previousIndex, event.currentIndex);
      if (event.previousIndex > event.currentIndex) {
        const formGroup = (this.sliderForm.get(formArray) as FormArray).at(event.previousIndex);
        (this.sliderForm.get(formArray) as FormArray).removeAt(event.previousIndex);
        (this.sliderForm.get(formArray) as FormArray).insert(event.currentIndex, formGroup);
      }
      if (event.previousIndex < event.currentIndex) {
        const formGroup = (this.sliderForm.get(formArray) as FormArray).at(event.currentIndex);
        (this.sliderForm.get(formArray) as FormArray).removeAt(event.currentIndex);
        (this.sliderForm.get(formArray) as FormArray).insert(event.previousIndex, formGroup);
      }
    } else {
      await this.addField(event.item.data, event.currentIndex);
    }
  }

  async ngOnInit() {
    await this.formInitilizing();
    this.ckeConfig = {
      allowedContent: true,
      versionCheck: false,
      removeButtons: "CreateDiv,BlockQuote,Superscript,Subscript,PasteFromWord,PasteText,Source",
    };
    if (this.id) {
      this.utilitiesService.startLoader();
      await this.getSliderDetails();
    }

    this.sliderForm.controls.auto_play.valueChanges.subscribe((data) => {
      if (!data) {
        this.sliderForm.controls.delay.setValue(null);
      }
    });
  }
  async formInitilizing() {
    this.sliderForm = this.fb.group({
      id: [this.sliderDetail ? this.sliderDetail.id : null],
      type: [this.sliderDetail ? await this.getSliderType(this.sliderDetail.type) : this.id ? "" : "IMAGE"],
      title: [this.sliderDetail ? this.sliderDetail.title_en : null],
      title_en: [this.sliderDetail ? this.sliderDetail.title_en : null],
      title_ar: [this.sliderDetail ? this.sliderDetail.title_ar : null],
      name: [this.sliderDetail ? this.sliderDetail.name : null, [Validators.required]],
      slides_per_view_large: [this.sliderDetail ? this.sliderDetail.slides_per_view_large : 1],
      slides_per_view_mobile: [this.sliderDetail ? this.sliderDetail.slides_per_view_mobile : 1],
      slides_per_view_small: [this.sliderDetail ? this.sliderDetail.slides_per_view_small : 1],
      content_per_row_large: [this.sliderDetail ? this.sliderDetail.content_per_row_large : 1],
      content_per_row_medium: [this.sliderDetail ? this.sliderDetail.content_per_row_medium : 1],
      content_per_row_small: [this.sliderDetail ? this.sliderDetail.content_per_row_small : 1],
      slider_per_click_large: [this.sliderDetail ? this.sliderDetail.slider_per_click_large : 1],
      slider_per_click_small: [this.sliderDetail ? this.sliderDetail.slider_per_click_small : 1],
      slider_per_click_mobile: [this.sliderDetail ? this.sliderDetail.slider_per_click_mobile : 1],
      space_between: [this.sliderDetail ? this.sliderDetail.space_between : 0],
      css: [this.sliderDetail ? this.sliderDetail.css : null],
      delay: [this.sliderDetail ? this.sliderDetail.delay : 0],
      loop: [this.sliderDetail ? this.sliderDetail.loop : false],
      auto_play: [this.sliderDetail ? this.sliderDetail.auto_play : false],
      zoom: [this.sliderDetail ? this.sliderDetail.zoom : false],
      free_mode: [this.sliderDetail ? this.sliderDetail.zoom : false],
      show_as: [this.sliderDetail ? this.sliderDetail.show_as : 1],
      navigation: [this.sliderDetail ? this.sliderDetail.navigation : true],
      top_navigation: [this.sliderDetail ? this.sliderDetail.top_navigation : false],
      side_navigation: [this.sliderDetail ? this.sliderDetail.side_navigation : true],
      animation: [this.sliderDetail ? this.sliderDetail.animation : null],
      dotted_navigation: [this.sliderDetail ? this.sliderDetail.dotted_navigation : false],
      html: this.fb.array([this.addHtmlFormGroup()]),
      product: this.fb.array([this.addProductFormGroup()]),
      category: this.fb.array([this.addCategoryFormGroup()]),
      brand: this.fb.array([this.addBrandFormGroup()]),
      image: this.fb.array([this.addImageFormGroup()]),
      post: this.fb.array([this.addPostFormGroup()]),
      related_products: this.fb.array([this.addRelatedFormGroup()]),
      recently_viewed_products: this.fb.array([this.addRecentlyViewedFormGroup()]),
      product_set: this.fb.array([this.addproductSetFormGroup()]),
      post_category: this.fb.array([this.addpostCategoryFormGroup()]),
    });
  }
  addpostCategoryFormGroup(slide?) {
    return this.fb.group({
      id: [slide ? slide.id : null],
      file: [slide ? slide.file : null],
      file_en: [slide ? slide.file_en : null],
      file_ar: [slide ? slide.file_ar : null],
      file_url: [slide ? (slide.file ? slide.file : slide.file_url) : null],
      file_url_ar: [slide ? (slide.file_ar ? slide.file_ar : slide.file_url_ar) : null],
      file_url_en: [slide ? (slide.file_en ? slide.file_en : slide.file_url_en) : null],
      alt_text: [slide ? slide.alt_text : null],
      show: [slide ? slide.show : false],
      order: [slide ? slide.order : 0],
      content_text: [null],
      content: [slide ? slide.content : null],
      content_ar: [slide ? slide.content_ar : null],
      name: [slide ? slide.name : null],
      url: [slide ? slide.url : null],
      button_text: [slide ? slide.button_text : null],
      button_text_ar: [slide ? slide.button_text_ar : null],
      img_url: [],
      post_category: [],
      language: ["en"],
    });
  }

  addproductSetFormGroup(slide?) {
    return this.fb.group({
      id: [slide ? slide.id : null],
      file: [slide ? slide.file : null],
      file_en: [slide ? slide.file_en : null],
      file_ar: [slide ? slide.file_ar : null],
      file_url: [slide ? (slide.file ? slide.file : slide.file_url) : null],
      file_url_ar: [slide ? (slide.file_ar ? slide.file_ar : slide.file_url_ar) : null],
      file_url_en: [slide ? (slide.file_en ? slide.file_en : slide.file_url_en) : null],
      alt_text: [slide ? slide.alt_text : null],
      max_price: [slide ? slide.max_price : 0],
      min_price: [slide ? slide.min_price : 0],
      show: [slide ? slide.show : false],
      order: [slide ? slide.order : null],
      content_text: [null],
      content: [slide ? slide.content : null],
      content_ar: [slide ? slide.content_ar : null],
      name: [slide ? slide.name : null],
      url: [slide ? slide.url : null],
      button_text: [slide ? slide.button_text : null],
      button_text_ar: [slide ? slide.button_text_ar : null],
      img_url: [],
      language: ["en"],
      isCodeEditor: [false],
      number_of_products: [slide ? slide.number_of_products : 0],
      product_set_type: [slide ? slide.product_set_type : this.productSet[0].id],
      tags: [],
      category: [],
      brand: [],
    });
  }

  addRecentlyViewedFormGroup(slide?) {
    return this.fb.group({
      id: [slide ? slide.id : null],
      file: [slide ? slide.file : null],
      file_en: [slide ? slide.file_en : null],
      file_ar: [slide ? slide.file_ar : null],
      file_url: [slide ? (slide.file ? slide.file : slide.file_url) : null],
      file_url_ar: [slide ? (slide.file_ar ? slide.file_ar : slide.file_url_ar) : null],
      file_url_en: [slide ? (slide.file_en ? slide.file_en : slide.file_url_en) : null],
      alt_text: [slide ? slide.alt_text : null],
      show: [slide ? slide.show : false],
      order: [slide ? slide.order : null],
      content_text: [null],
      content: [slide ? slide.content : null],
      content_ar: [slide ? slide.content_ar : null],
      name: [slide ? slide.name : null],
      url: [slide ? slide.url : null],
      button_text: [slide ? slide.button_text : null],
      button_text_ar: [slide ? slide.button_text_ar : null],
      img_url: [],
      language: ["en"],
      isCodeEditor: [false],
      number_of_products: [slide ? slide.number_of_products : 5],
    });
  }

  addRelatedFormGroup(slide?) {
    return this.fb.group({
      id: [slide ? slide.id : null],
      file: [slide ? slide.file : null],
      file_en: [slide ? slide.file_en : null],
      file_ar: [slide ? slide.file_ar : null],
      file_url: [slide ? (slide.file ? slide.file : slide.file_url) : null],
      file_url_ar: [slide ? (slide.file_ar ? slide.file_ar : slide.file_url_ar) : null],
      file_url_en: [slide ? (slide.file_en ? slide.file_en : slide.file_url_en) : null],
      alt_text: [slide ? slide.alt_text : null],
      show: [slide ? slide.show : false],
      order: [slide ? slide.order : null],
      content_text: [null],
      content: [slide ? slide.content : null],
      content_ar: [slide ? slide.content_ar : null],
      name: [slide ? slide.name : null],
      url: [slide ? slide.url : null],
      button_text: [slide ? slide.button_text : null],
      button_text_ar: [slide ? slide.button_text_ar : null],
      img_url: [],
      language: ["en"],
      isCodeEditor: [false],
      number_of_products: [slide ? slide.number_of_products : 0],
      relation_type: [slide ? slide.relation_type : this.relationTypes[0].id],
    });
  }

  addHtmlFormGroup(slide?) {
    return this.fb.group({
      id: [slide ? slide.id : null],
      file: [slide ? slide.file : null],
      file_en: [slide ? slide.file_en : null],
      file_ar: [slide ? slide.file_ar : null],
      file_url: [slide ? (slide.file ? slide.file : slide.file_url) : null],
      file_url_ar: [slide ? (slide.file_ar ? slide.file_ar : slide.file_url_ar) : null],
      file_url_en: [slide ? (slide.file_en ? slide.file_en : slide.file_url_en) : null],
      alt_text: [slide ? slide.alt_text : null],
      show: [slide ? slide.show : false],
      order: [slide ? slide.order : 0],
      content_text: [null],
      content: [slide ? slide.content : null],
      content_ar: [slide ? slide.content_ar : null],
      name: [slide ? slide.name : null],
      url: [slide ? slide.url : null],
      button_text: [slide ? slide.button_text : null],
      button_text_ar: [slide ? slide.button_text_ar : null],
      img_url: [],
      language: ["en"],
      isCodeEditor: [false],
    });
  }
  addProductFormGroup(slide?) {
    return this.fb.group({
      id: [slide ? slide.id : null],
      file: [slide ? slide.file : null],
      file_en: [slide ? slide.file_en : null],
      file_ar: [slide ? slide.file_ar : null],
      file_url: [slide ? (slide.file ? slide.file : slide.file_url) : null],
      file_url_ar: [slide ? (slide.file_ar ? slide.file_ar : slide.file_url_ar) : null],
      file_url_en: [slide ? (slide.file_en ? slide.file_en : slide.file_url_en) : null],
      alt_text: [slide ? slide.alt_text : null],
      show: [slide ? slide.show : false],
      order: [slide ? slide.order : 0],
      content_text: [null],
      content: [slide ? slide.content : null],
      content_ar: [slide ? slide.content_ar : null],
      name: [slide ? slide.name : null],
      url: [slide ? slide.url : null],
      button_text: [slide ? slide.button_text : null],
      button_text_ar: [slide ? slide.button_text_ar : null],
      img_url: [],
      products: [],
      language: ["en"],
      isCodeEditor: [false],
    });
  }

  addPostFormGroup(slide?) {
    return this.fb.group({
      id: [slide ? slide.id : null],
      file: [slide ? slide.file : null],
      file_en: [slide ? slide.file_en : null],
      file_ar: [slide ? slide.file_ar : null],
      file_url: [slide ? (slide.file ? slide.file : slide.file_url) : null],
      file_url_ar: [slide ? (slide.file_ar ? slide.file_ar : slide.file_url_ar) : null],
      file_url_en: [slide ? (slide.file_en ? slide.file_en : slide.file_url_en) : null],
      alt_text: [slide ? slide.alt_text : null],
      show: [slide ? slide.show : false],
      order: [slide ? slide.order : 0],
      content_text: [null],
      content: [slide ? slide.content : null],
      content_ar: [slide ? slide.content_ar : null],
      name: [slide ? slide.name : null],
      url: [slide ? slide.url : null],
      button_text: [slide ? slide.button_text : null],
      button_text_ar: [slide ? slide.button_text_ar : null],
      img_url: [],
      posts: [],
      language: ["en"],
      isCodeEditor: [false],
    });
  }
  addCategoryFormGroup(slide?) {
    return this.fb.group({
      id: [slide ? slide.id : null],
      file: [slide ? slide.file : null],
      file_en: [slide ? slide.file_en : null],
      file_ar: [slide ? slide.file_ar : null],
      file_url: [slide ? (slide.file ? slide.file : slide.file_url) : null],
      file_url_ar: [slide ? (slide.file_ar ? slide.file_ar : slide.file_url_ar) : null],
      file_url_en: [slide ? (slide.file_en ? slide.file_en : slide.file_url_en) : null],
      alt_text: [slide ? slide.alt_text : null],
      show: [slide ? slide.show : false],
      order: [slide ? slide.order : 0],
      content_text: [null],
      content: [slide ? slide.content : null],
      content_ar: [slide ? slide.content_ar : null],
      name: [slide ? slide.name : null],
      url: [slide ? slide.url : null],
      button_text: [slide ? slide.button_text : null],
      button_text_ar: [slide ? slide.button_text_ar : null],
      img_url: [],
      categories: [],
      language: ["en"],
      isCodeEditor: [false],
    });
  }
  addBrandFormGroup(slide?) {
    return this.fb.group({
      id: [slide ? slide.id : null],
      file: [slide ? slide.file : null],
      file_en: [slide ? slide.file_en : null],
      file_ar: [slide ? slide.file_ar : null],
      file_url: [slide ? (slide.file ? slide.file : slide.file_url) : null],
      file_url_ar: [slide ? (slide.file_ar ? slide.file_ar : slide.file_url_ar) : null],
      file_url_en: [slide ? (slide.file_en ? slide.file_en : slide.file_url_en) : null],
      alt_text: [slide ? slide.alt_text : null],
      show: [slide ? slide.show : false],
      order: [slide ? slide.order : 0],
      content_text: [null],
      content: [slide ? slide.content : null],
      content_ar: [slide ? slide.content_ar : null],
      name: [slide ? slide.name : null],
      url: [slide ? slide.url : null],
      button_text: [slide ? slide.button_text : null],
      button_text_ar: [slide ? slide.button_text_ar : null],
      img_url: [],
      brands: [],
      language: ["en"],
      isCodeEditor: [false],
    });
  }
  addImageFormGroup(slide?) {
    return this.fb.group({
      id: [slide ? slide.id : null],
      file: [slide ? slide.file : null],
      file_en: [slide ? slide.file_en : null],
      file_ar: [slide ? slide.file_ar : null],
      file_url: [slide ? (slide.file ? slide.file : slide.file_url) : null],
      file_url_ar: [slide ? (slide.file_ar ? slide.file_ar : slide.file_url_ar) : null],
      file_url_en: [slide ? (slide.file_en ? slide.file_en : slide.file_url_en) : null],
      alt_text: [slide ? slide.alt_text : null],
      show: [slide ? slide.show : false],
      order: [slide ? slide.order : 0],
      content_text: [null],
      content: [slide ? slide.content : null],
      content_ar: [slide ? slide.content_ar : null],
      name: [slide ? slide.name : null],
      url: [slide ? slide.url : null],
      button_text: [slide ? slide.button_text : null],
      button_text_ar: [slide ? slide.button_text_ar : null],
      img_url: [],
      language: ["en"],
      isCodeEditor: [false],
    });
  }

  async getSliderType(type_id) {
    let type = "";
    this.types.forEach((data) => {
      if (data.id == type_id) {
        type = data.name;
      }
    });
    return type;
  }

  async getSliderDetails(isDelete = false) {
    if (isDelete) {
      try {
        (this.sliderForm.controls["brand"] as FormArray).clear();
        (this.sliderForm.controls["html"] as FormArray).clear();
        (this.sliderForm.controls["product"] as FormArray).clear();
        (this.sliderForm.controls["category"] as FormArray).clear();
        (this.sliderForm.controls["image"] as FormArray).clear();
        (this.sliderForm.controls["post"] as FormArray).clear();
        (this.sliderForm.controls["post_category"] as FormArray).clear();
        // (this.sliderForm.controls["related_products"] as FormArray).clear();
      } catch {
      } finally {
      }
    }
    try {
      const sliderDetail = await this.sliderService.getSliderDetail(this.id).toPromise();
      if (sliderDetail) {
        this.sliderDetail = sliderDetail;
        await this.formInitilizing();
        await this.deleteFirstRow(await this.getSliderType(this.sliderDetail.type));
        if (this.sliderDetail.slides.length > 0) {
          let type = await this.getSliderType(this.sliderDetail.type);
          let slides = this.sliderDetail.slides;
          slides.forEach(async (data, index) => {
            await this.addSliders(data, type, true, index);
          });
        }
      }
    } catch {
    } finally {
      this.utilitiesService.stopLoader();
    }
  }

  async deleteFirstRow(type) {
    if (type == this.typeEnum.BRAND) {
      this.sliderForm.get("brand")["controls"].splice(0, 1);
    } else if (type == this.typeEnum.HTML) {
      this.sliderForm.get("html")["controls"].splice(0, 1);
    } else if (type == this.typeEnum.PRODUCT) {
      this.sliderForm.get("product")["controls"].splice(0, 1);
    } else if (type == this.typeEnum.CATEGORY) {
      this.sliderForm.get("category")["controls"].splice(0, 1);
    } else if (type == this.typeEnum.IMAGE) {
      this.sliderForm.get("image")["controls"].splice(0, 1);
    } else if (type == this.typeEnum.POST) {
      this.sliderForm.get("post")["controls"].splice(0, 1);
    } else if (type == this.typeEnum.RELATED_PRODUCTS) {
      this.sliderForm.get("related_products")["controls"].splice(0, 1);
    } else if (type == this.typeEnum.RECENTLY_VIEWED_PRODUCTS) {
      this.sliderForm.get("recently_viewed_products")["controls"].splice(0, 1);
    } else if (type == this.typeEnum.PRODUCT_SET) {
      this.sliderForm.get("product_set")["controls"].splice(0, 1);
    } else if (type == this.typeEnum.POST_CATEGORY) {
      this.sliderForm.get("post_category")["controls"].splice(0, 1);
    }
  }

  async typeValueChanges(event, type, index) {
    this.isLoading = true;
    if (type == this.typeEnum.POST_CATEGORY) {
      if (event.target.value) {
        await this.getPostCategory(event.target.value, index);
      } else {
        ((this.sliderForm.get("post_category") as FormArray).at(index) as FormGroup).get("post_category").patchValue([]);
        this.isLoading = false;
      }
    }
    if (type == this.typeEnum.PRODUCT) {
      if (event.target.value) {
        await this.getProducts(event.target.value, index);
      } else {
        ((this.sliderForm.get("product") as FormArray).at(index) as FormGroup).get("products").patchValue([]);
        this.isLoading = false;
      }
    } else if (type == this.typeEnum.BRAND) {
      if (event.target.value) {
        await this.getBrand(event.target.value, index);
      } else {
        ((this.sliderForm.get("brand") as FormArray).at(index) as FormGroup).get("brands").patchValue([]);
        this.isLoading = false;
      }
    } else if (type == this.typeEnum.CATEGORY) {
      if (event.target.value) {
        await this.getCategory(event.target.value, index);
      } else {
        ((this.sliderForm.get("category") as FormArray).at(index) as FormGroup).get("categories").patchValue([]);
        this.isLoading = false;
      }
    } else if (type == this.typeEnum.POST) {
      if (event.target.value) {
        await this.getPosts(event.target.value, index);
      } else {
        ((this.sliderForm.get("post") as FormArray).at(index) as FormGroup).get("posts").patchValue([]);
        this.isLoading = false;
      }
    }
  }

  async getPosts(search, indexOfControl) {
    try {
      const posts = await this.sliderService.getPosts(search).toPromise();
      if (posts) {
        ((this.sliderForm.get("post") as FormArray).at(indexOfControl) as FormGroup).get("posts").patchValue(posts.results);
      }
    } catch {
    } finally {
      this.isLoading = false;
    }
  }
  async getPostCategory(search, indexOfControl) {
    try {
      const postCategory = await this.postService.getPostsCategory(9999, 0, search).toPromise();
      if (postCategory) {
        ((this.sliderForm.get("post_category") as FormArray).at(indexOfControl) as FormGroup)
          .get("post_category")
          .patchValue(postCategory.results);
      }
    } catch {
    } finally {
      this.isLoading = false;
    }
  }

  async getProducts(search, indexOfControl) {
    try {
      const products = await this.sliderService.getProducts(search).toPromise();
      if (products) {
        ((this.sliderForm.get("product") as FormArray).at(indexOfControl) as FormGroup).get("products").patchValue(products.results);
      }
    } catch {
    } finally {
      this.isLoading = false;
    }
  }
  async getCategory(search, indexOfControl, isFromProductSet = false) {
    try {
      this.isLoadingProductSet = true;
      const category = await this.sliderService.getCategory(search).toPromise();
      if (category) {
        this.isLoadingProductSet = false;
        if (!isFromProductSet) {
          ((this.sliderForm.get("category") as FormArray).at(indexOfControl) as FormGroup).get("categories").patchValue(category.results);
        } else {
          ((this.sliderForm.get("product_set") as FormArray).at(indexOfControl) as FormGroup).get("category").patchValue(category.results);
        }
      }
    } catch {
    } finally {
      this.isLoading = false;
    }
  }

  patchTypeIDtoContent(name, type, indexOfControl) {
    let elementArray = [];
    if (type == this.typeEnum.POST_CATEGORY) {
      let postCategory = this.sliderForm.get("post_category").value;
      elementArray = postCategory[indexOfControl].post_category;
      let id = elementArray.find((innerTypes) => innerTypes.name === name).id;
      ((this.sliderForm.get("post_category") as FormArray).at(indexOfControl) as FormGroup).get("content").patchValue(id);
    }
    if (type == this.typeEnum.PRODUCT) {
      let products = this.sliderForm.get("product").value;
      elementArray = products[indexOfControl].products;
      let id = elementArray.find((innerTypes) => innerTypes.name === name).id;
      ((this.sliderForm.get("product") as FormArray).at(indexOfControl) as FormGroup).get("content").patchValue(id);
    }
    if (type == this.typeEnum.BRAND) {
      let products = this.sliderForm.get("brand").value;
      elementArray = products[indexOfControl].brands;
      let id = elementArray.find((innerTypes) => innerTypes.name === name).id;
      ((this.sliderForm.get("brand") as FormArray).at(indexOfControl) as FormGroup).get("content").patchValue(id);
    }
    if (type == this.typeEnum.CATEGORY) {
      let products = this.sliderForm.get("category").value;
      elementArray = products[indexOfControl].categories;
      let id = elementArray.find((innerTypes) => innerTypes.name === name).id;
      ((this.sliderForm.get("category") as FormArray).at(indexOfControl) as FormGroup).get("content").patchValue(id);
    }
    if (type == this.typeEnum.POST) {
      let posts = this.sliderForm.get("post").value;
      elementArray = posts[indexOfControl].posts;
      let id = elementArray.find((innerTypes) => innerTypes.title === name).id;
      ((this.sliderForm.get("post") as FormArray).at(indexOfControl) as FormGroup).get("content").patchValue(id);
    }
    this.isLoading = false;
  }

  async getBrand(search, indexOfControl, isFromProductSet = false) {
    this.isLoadingProductSet = true;
    try {
      const brand = await this.sliderService.getBrand(search).toPromise();
      if (brand) {
        this.isLoadingProductSet = false;
        if (!isFromProductSet) {
          ((this.sliderForm.get("brand") as FormArray).at(indexOfControl) as FormGroup).get("brands").patchValue(brand.results);
        } else {
          ((this.sliderForm.get("product_set") as FormArray).at(indexOfControl) as FormGroup).get("brand").patchValue(brand.results);
        }
      }
    } catch {
    } finally {
      this.isLoadingProductSet = false;
      this.isLoading = false;
    }
  }

  async getElementDetail(slides, url, isMultiple = false) {
    //each slides detail
    let details = await this.sliderService.getElementDetail(slides, url, isMultiple).toPromise();
    return details;
  }

  async addSliders(slides?, type_name?, isDetail = false, index?) {
    //add slides and if detail view prepopulate the values
    let url = "";
    let type = type_name ? type_name : this.sliderForm.controls.type.value;
    if (type == this.typeEnum.BRAND) {
      (this.sliderForm.get("brand") as FormArray).push(this.addBrandFormGroup(slides));
      if (isDetail) {
        let brand = await this.getElementDetail(slides, this.sharedService.sliderBrand);
        let brandArray = [];
        brandArray.push(brand.results[0]);
        ((this.sliderForm.get("brand") as FormArray).at(index) as FormGroup).get("brands").patchValue(brandArray);

        ((this.sliderForm.get("brand") as FormArray).at(index) as FormGroup).get("content_text").patchValue(brandArray[0].name);
      }
    } else if (type == this.typeEnum.POST_CATEGORY) {
      (this.sliderForm.get("post_category") as FormArray).push(this.addpostCategoryFormGroup(slides));
      if (isDetail) {
        let postCategory = await this.getElementDetail(slides, this.sharedService.postCategoryList);
        let postCategoryArray = [];
        postCategoryArray = postCategory.results;
        ((this.sliderForm.get("post_category") as FormArray).at(index) as FormGroup).get("post_category").patchValue(postCategoryArray);

        ((this.sliderForm.get("post_category") as FormArray).at(index) as FormGroup)
          .get("content_text")
          .patchValue(postCategoryArray[0].name);
      }
    } else if (type == this.typeEnum.HTML) {
      (this.sliderForm.get("html") as FormArray).push(this.addHtmlFormGroup(slides));
    } else if (type == this.typeEnum.PRODUCT) {
      (this.sliderForm.get("product") as FormArray).push(this.addProductFormGroup(slides));
      if (isDetail) {
        let product = await this.getElementDetail(slides, this.sharedService.sliderProductDetail);
        let productArray = [];
        productArray.push(product);
        ((this.sliderForm.get("product") as FormArray).at(index) as FormGroup).get("products").patchValue(productArray);
        ((this.sliderForm.get("product") as FormArray).at(index) as FormGroup).get("content_text").patchValue(productArray[0].name);
      }
    } else if (type == this.typeEnum.CATEGORY) {
      (this.sliderForm.get("category") as FormArray).push(this.addCategoryFormGroup(slides));
      if (isDetail) {
        let category = await this.getElementDetail(slides, this.sharedService.sliderCategory);
        let categoryArray = [];
        categoryArray.push(category.results[0]);
        ((this.sliderForm.get("category") as FormArray).at(index) as FormGroup).get("categories").patchValue(categoryArray);

        ((this.sliderForm.get("category") as FormArray).at(index) as FormGroup)
          .get("content_text")
          .patchValue(categoryArray[0].name || null);
      }
    } else if (type == this.typeEnum.POST) {
      (this.sliderForm.get("post") as FormArray).push(this.addPostFormGroup(slides));
      if (isDetail) {
        const posts = await this.postService.getPostDetail(slides.content).toPromise();

        let postsArray = [];
        postsArray.push(posts);
        ((this.sliderForm.get("post") as FormArray).at(index) as FormGroup).get("posts").patchValue(postsArray);

        ((this.sliderForm.get("post") as FormArray).at(index) as FormGroup).get("content_text").patchValue(postsArray[0].title || null);
      }
    } else if (type == this.typeEnum.IMAGE) {
      (this.sliderForm.get("image") as FormArray).push(this.addImageFormGroup(slides));
    } else if (type == this.typeEnum.RELATED_PRODUCTS) {
      (this.sliderForm.get("related_products") as FormArray).push(this.addRelatedFormGroup(slides));
    } else if (type == this.typeEnum.RECENTLY_VIEWED_PRODUCTS) {
      (this.sliderForm.get("recently_viewed_products") as FormArray).push(this.addRecentlyViewedFormGroup(slides));
    } else if (type == this.typeEnum.PRODUCT_SET) {
      (this.sliderForm.get("product_set") as FormArray).push(this.addproductSetFormGroup(slides));
      if (slides.product_set_type == 1) {
        let category = await this.getElementDetail(slides, this.sharedService.tags, true);
        ((this.sliderForm.get("product_set") as FormArray).at(index) as FormGroup).get("tags").patchValue(category.results);
        if (slides.content) {
          let content = JSON.parse("[" + slides.content + "]");
          ((this.sliderForm.get("product_set") as FormArray).at(index) as FormGroup).get("content").patchValue(content);
        }
      }
      if (slides.product_set_type == 3) {
        if (isDetail) {
          let category = await this.getElementDetail(slides, this.sharedService.sliderCategory, true);
          ((this.sliderForm.get("product_set") as FormArray).at(index) as FormGroup).get("category").patchValue(category.results);
          if (slides.content) {
            let content = JSON.parse("[" + slides.content + "]");
            ((this.sliderForm.get("product_set") as FormArray).at(index) as FormGroup).get("content").patchValue(content);
          }
        }
      } else if (slides.product_set_type == 5) {
        if (isDetail) {
          let brand = await this.getElementDetail(slides, this.sharedService.sliderBrand);
          ((this.sliderForm.get("product_set") as FormArray).at(index) as FormGroup).get("brand").patchValue(brand.results);
          if (slides.content) {
            ((this.sliderForm.get("product_set") as FormArray).at(index) as FormGroup).get("content").patchValue(+slides.content);
          }
        }
      }
    }
  }

  async changeProductSet(index) {
    ((this.sliderForm.get("product_set") as FormArray).at(index) as FormGroup).get("content").patchValue("");
  }

  async typeChange(event) {
    await this.formInitilizing();
    this.sliderForm.controls.type.setValue(event);
  }

  handleCancel() {
    this.isPopUp ? this.close() : this.cancelClick.emit();
  }

  save() {
    if (this.sliderForm.valid) {
      this.utilitiesService.startLoader();
      try {
        let type_id = 0;
        let type = this.sliderForm.controls.type.value;
        this.types.forEach((data) => {
          if (data.name == type) {
            type_id = data.id;
          }
        });
        let slides = [];
        if (type == this.typeEnum.PRODUCT) {
          slides = this.sliderForm.controls.product.value;
        } else if (type == this.typeEnum.CATEGORY) {
          slides = this.sliderForm.controls.category.value;
        } else if (type == this.typeEnum.BRAND) {
          slides = this.sliderForm.controls.brand.value;
        } else if (type == this.typeEnum.IMAGE) {
          slides = this.sliderForm.controls.image.value;
        } else if (type == this.typeEnum.HTML) {
          slides = this.sliderForm.controls.html.value;
        } else if (type == this.typeEnum.POST) {
          slides = this.sliderForm.controls.post.value;
        } else if (type == this.typeEnum.RELATED_PRODUCTS) {
          slides = this.sliderForm.controls.related_products.value;
        } else if (type == this.typeEnum.RECENTLY_VIEWED_PRODUCTS) {
          slides = this.sliderForm.controls.recently_viewed_products.value;
        } else if (type == this.typeEnum.PRODUCT_SET) {
          slides = this.sliderForm.controls.product_set.value;
        } else if (type == this.typeEnum.POST_CATEGORY) {
          slides = this.sliderForm.controls.post_category.value;
        }
        if (type != this.typeEnum.RELATED_PRODUCTS) {
          slides.forEach((data, index) => {
            data.order = index;
          });
        }
        if (type == this.typeEnum.PRODUCT_SET) {
          let content = "";
          slides.forEach((data) => {
            if (data.content) {
              content = data.content.toString();
              data.content = content;
            }
          });
        }
        let form = {
          slider: {
            id: this.sliderForm.controls.id.value,
            name: this.sliderForm.controls.name.value,
            type: type_id,
            loop: this.sliderForm.controls.loop.value,
            title_en: this.sliderForm.controls.title_en.value,
            title_ar: this.sliderForm.controls.title_ar.value,
            title: this.sliderForm.controls.title_en.value,
            auto_play: this.sliderForm.controls.auto_play.value,
            zoom: this.sliderForm.controls.zoom.value,
            free_mode: this.sliderForm.controls.free_mode.value,
            delay: this.sliderForm.controls.delay.value ? this.sliderForm.controls.delay.value : 0,
            slides_per_view_large: this.sliderForm.controls.slides_per_view_large.value,
            slides_per_view_mobile: this.sliderForm.controls.slides_per_view_mobile.value,
            slides_per_view_small: this.sliderForm.controls.slides_per_view_small.value,
            content_per_row_large: this.sliderForm.controls.content_per_row_large.value,
            content_per_row_medium: this.sliderForm.controls.content_per_row_medium.value,
            content_per_row_small: this.sliderForm.controls.content_per_row_small.value,
            slider_per_click_large: this.sliderForm.controls.slider_per_click_large.value,
            slider_per_click_small: this.sliderForm.controls.slider_per_click_small.value,
            slider_per_click_mobile: this.sliderForm.controls.slider_per_click_mobile.value,
            space_between: this.sliderForm.controls.space_between.value,
            css: this.sliderForm.controls.css.value,
            show_as: this.sliderForm.controls.show_as.value,
            navigation: this.sliderForm.controls.navigation.value,
            top_navigation: this.sliderForm.controls.top_navigation.value,
            side_navigation: this.sliderForm.controls.side_navigation.value,
            dotted_navigation: this.sliderForm.controls.dotted_navigation.value,
            animation: this.sliderForm.controls.animation.value,
          },
          slides: slides,
        };

        this.id ? this.updateSlider(form) : this.saveSlider(form);
      } catch {
        this.utilitiesService.stopLoader();
      } finally {
        this.utilitiesService.stopLoader();
      }
    }
  }

  saveSlider(form) {
    try {
      this.sliderService.addSliders(form).subscribe((data) => {
        let successMsg = this.translate.instant("Collection created successfully");
        this.utilitiesService.showSuccessToast(successMsg);
        this.isPopUp ? this.close(data) : this.createSliderEvent.emit();
      });
    } catch {
      this.utilitiesService.stopLoader();
    } finally {
    }
  }

  updateSlider(form) {
    try {
      form.slides.forEach((data) => {
        data.file_en = null;
        data.file = null;
        data.file_ar = null;
      });
      this.sliderService.updateSlider(form).subscribe((data) => {
        let successMsg = this.translate.instant("Collection updated successfully");
        this.utilitiesService.showSuccessToast(successMsg);
        this.createSliderEvent.emit();
      });
    } catch {
      this.utilitiesService.stopLoader();
    } finally {
    }
  }

  delete(index, formArrayName) {
    let content = `Are you sure, Do you want to delete this slider ? `;
    let heading = "Delete";
    let fromApp = false;
    let size = this.utilitiesService.isMobileAlertModal();
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: { content, heading, fromApp },
      maxWidth: "",
      width: `${size.width}`,
      height: `${size.height}`,
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        if (!this.id) {
          this.removeSlideLoacally(index, formArrayName);
        } else {
          let slide = this.sliderForm.get(formArrayName).value;
          let id = slide[index].id;
          if (id) {
            this.sliderService.deleteSlide(id).subscribe((data) => {
              this.utilitiesService.startLoader();
              this.getSliderDetails(true);
            });
          } else {
            this.removeSlideLoacally(index, formArrayName);
          }
        }
        document.getElementById("toolbar").scrollIntoView();
      }
    });
  }

  removeSlideLoacally(index, formArrayName) {
    (this.sliderForm.get(formArrayName) as FormArray).removeAt(index);
  }

  deleteFile(formArrayIndex, language) {
    if (language == "en") {
      ((this.sliderForm.get("image") as FormArray).at(formArrayIndex) as FormGroup).get("file_url").patchValue(null);

      ((this.sliderForm.get("image") as FormArray).at(formArrayIndex) as FormGroup).get("file_url_en").patchValue(null);
    } else {
      ((this.sliderForm.get("image") as FormArray).at(formArrayIndex) as FormGroup).get("file_url_ar").patchValue(null);
    }
  }

  setFileToForm(index, files, language) {
    if (language == "en") {
      ((this.sliderForm.get("image") as FormArray).at(index) as FormGroup).get("file_url").patchValue(files.file);

      ((this.sliderForm.get("image") as FormArray).at(index) as FormGroup).get("file_url_en").patchValue(files.file);
    } else {
      ((this.sliderForm.get("image") as FormArray).at(index) as FormGroup).get("file_url_ar").patchValue(files.file);
    }
  }

  languageChanges(language) {}

  async ngAfterViewInit(): Promise<void> {
    this.editor.getEditor().setOptions({
      showLineNumbers: true,
      tabSize: 2,
    });
    this.editor.mode = "css";
  }
  zoomAdditionalCss(formControl) {
    let heading = "CSS";
    const dialogRef = this.dialog.open(FullscreenAdditionCssComponent, {
      data: { formControl, heading },
      maxWidth: "",
      width: "100%",
      height: "100%",
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        this.sliderForm.controls.css.setValue(resp.value);
      }
    });
  }
  zoomHTML(formControl, index, type) {
    let heading = "HTML";
    const dialogRef = this.dialog.open(FullscreenAdditionCssComponent, {
      data: { formControl, heading },
      maxWidth: "",
      width: "100%",
      height: "100%",
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        ((this.sliderForm.get("html") as FormArray).at(index) as FormGroup).get(type).setValue(resp.value);
      }
    });
  }

  async searchTags(name, index) {
    this.isLoadingProductSet = true;
    try {
      const tags = await this.sliderService.getTags(name).toPromise();
      if (tags) {
        this.isLoadingProductSet = false;
        ((this.sliderForm.get("product_set") as FormArray).at(index) as FormGroup).get("tags").patchValue(tags.results);
      }
    } catch {
    } finally {
      this.isLoadingProductSet = false;
    }
  }

  close(data?) {
    this.animateClose(data);
  }

  animateClose(data?): void {
    const dialogRefContainer = document.querySelector(".left-to-right-dialog");
    if (dialogRefContainer) {
      dialogRefContainer.classList.remove("open");
      setTimeout(() => {
        data ? this.dialogRef.close(data) : this.dialogRef.close();
      }, 300);
    }
  }
}
