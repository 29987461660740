import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "../api.service";
import { DownloadTypes } from "app/downloadTypes";

@Injectable({
    providedIn: "root",
})
export class CartService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    getCartDetail(id) {
        return this.apiService.get(
            this.sharedService.cartList + `${id}/`,
            undefined
        );
    }

    checkStatus() {
        return this.apiService.get(
            this.sharedService.checkStatus,
            undefined
        );
    }

    getCartList(
        limit = 10,
        offset = 0,
        search = "",
        type,
        form?,
        isDownload = false,
    ) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search)
            .set("type", type);
        if (form) {
            for (let key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        if (isDownload) {
            params = params
                .set("download", "true")
        }

        return this.apiService.get(
            this.sharedService.cartList,
            params,
            undefined
        );
    }
    orderTracking(app_id: any, awb: string) {
        const params = new HttpParams({
            fromObject: {
                app_id: app_id.toString(),
                awb: awb,
            },
        });

        return this.apiService.get(this.sharedService.track, params);
    }
}
