import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { PageElements } from "app/constants/types";

@Component({
    selector: "app-common-editor",
    templateUrl: "./common-editor.component.html",
    styleUrls: ["./common-editor.component.scss"],
    animations: fuseAnimations,
})
export class CommonEditorComponent implements OnInit {
    @Input() formControl: FormControl;
    @Input() lang: FormControl;
    @Input() controlType: any = "";
    @Input() controlLabel: any = "";
    typeEnum = PageElements;
    codeEditor = new FormControl(false);
    constructor() {}

    ngOnInit(): void {}
}
