import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: "root",
})
export class MediaService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    getMediaList(limit: number = 0, offset: number = 0, search = "") {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        return this.apiService.get(
            this.sharedService.mediaCURDUrl,
            params,
            undefined
        );
    }
    getMediaDetail(id) {
        return this.apiService.get(
            this.sharedService.mediaCURDUrl + `${id}/`,
            undefined
        );
    }
    addMedia(form) {
        return this.apiService.post(
            this.sharedService.mediaCURDUrl,
            form,
            undefined
        );
    }

    updateMedia(form) {
        return this.apiService.patch(
            this.sharedService.mediaCURDUrl + `${form.id}/`,
            form,
            undefined
        );
    }

    uploadToS3(form, url) {
        const blob = new Blob([form.file], { type: form.type });
        let headersObject = {};
        return this.apiService.put(
            url,
            blob,
            undefined,
            headersObject,
            true,
            true,
            true
        );
    }

    getS3UploadURL(filename: string) {
        let params = new HttpParams();
        params = params.append("file_name", filename);
        return this.apiService.get(this.sharedService.s3UploadURL, params);
    }

    updateName(fileName) {
        let form = {
            name: fileName,
        };
        return this.apiService.post(this.sharedService.uploadFileName, form);
    }

    addMediafile(form) {
        const token = localStorage.getItem("access_token");
        const headersObject = {
            Authorization: `Bearer ${token}`,
            "x-api-key": "cbe806aa-e93a-4a7e-8484-c0cac8e69cad",
        };
        return this.apiService.post(this.sharedService.addMediaFile, form,headersObject,true,false,true);
    }

    deleteMedia(id) {
        return this.apiService.delete(
            this.sharedService.mediaCURDUrl + `${id}/`,
            undefined
        );
    }

    convertFormToFormData(form: any) {
        let formData = new FormData();
        Object.keys(form).forEach(key=>{
            formData.append(`${key}`, `${form[key] ? form[key] : ''}`);
        })
        return formData;
      }
}
