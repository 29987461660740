export const locale = {
    lang: "en",
    data: {
        NAV: {
            Integration: "Integrations",
            marketing: "MARKETING",
            contant: "CONTENT",
            myshops: "My Shop",
            customer: "Customers",
            orders: "Orders",
            notification: "NOTIFICATION",
            salesChannels: "SALES CHANNELS",
            Ecommerce: "ECOMMERCE",
            shopify: "Shopify",
            "1": "Shopify",
            usermanagement: "USER MANAGEMENT",
            loyaltyManagement: "LOYALTY MANAGEMENT",
            DeliveryAgent: "Delivery Agent",
            Delivery: "Delivery Order List",
            UnDeliveredReason: "UnDelivered Reason",
            extras: "EXTRAS",
            widgetsNav: "Widgets",
            postsNav: "Posts",
            blogNav: "Blog",
            ordersNav: "Orders",
            productsNav: "Products Insights",
            catalogue: "Catalog",
            // categories: "Categories",
            // brands: "Brands",
            attribute: "Attributes",
            pointsSummary: {
                TITLE: "Points Statement",
            },
            loyaltyPointsRule: {
                TITLE: "Points Rule",
            },
            loyaltyConfiguration: {
                TITLE: "Loyalty Configuration",
            },
            wallets: {
                TITLE: "Wallets",
            },
            shop: {
                TITLE: "Shops",
            },
            page: {
                TITLE: "Pages",
            },
            media: {
                TITLE: "Media",
            },
            widgets: {
                TITLE: "Widgets",
            },
            slider: {
                TITLE: "Sliders",
            },
            configuration: {
                TITLE: "Configurations",
            },
            navmenu: {
                TITLE: "Navigation Menu",
            },
            customer_list: {
                TITLE: "List",
            },
            configuration_attribute: {
                TITLE: "Configuration Attribute",
            },
            orderSubNav: {
                TITLE: "Orders",
            },
            cart: {
                TITLE: "Cart",
            },
            notifications: {
                TITLE: "Notifications",
            },
            notifications_template: {
                TITLE: "Notification Templates",
            },
            categories: {
                TITLE: 'categories'
            },
            brands: {
                TITLE: 'brands'
            },
            '3': {
                TITLE: 'Campaigns',
            },
            "2": {
                TITLE: "Memberships",
            },
            translation_editor: {
                TITLE: "Translation Editor",
            },
            pricerules: {
                TITLE: "Price Rules",
            },
            reviews: {
                TITLE: "Reviews",
            },
            products: {
                TITLE: "Products",
            },
            affiliate: {
                TITLE: "Affiliate",
            },
            manage_campaigns: {
                TITLE: "Campaigns",
            },
            dashboard: {
                TITLE: "Dashboard",
            },
            audience: {
                TITLE: "Audience",
            },
            // condition:{
            //     TITLE:"Condition"
            // },
            "5": {
                TITLE: "Custom Fields",
            },
            posts: {
                TITLE: "Posts",
            },
            "6": {
                TITLE: "Wordpress",
            },
            collection: {
                TITLE: "Collection",
            },
            category: {
                TITLE: "Categories",
            },
            appearance: {
                TITLE: "Appearance",
            },
            wishlist: {
                TITLE: "Wishlist",
            },
            templates: {
                TITLE: "Templates",
            },
            notification_configuration: {
                TITLE: "Configurations",
            },
            users: {
                TITLE: "Users",
            },
            roles_and_permission: {
                TITLE: "Roles & Permissions",
            },
            "7": {
                TITLE: "Server Analytics",
            },
            "8": {
                TITLE: "Catalog",
            },
            social: {
                TITLE: "Social Page"
            },
            connectInsights: {
                TITLE: "Connect Insights"
            },
            connectPage: {
                TITLE: "Connect Page"
            }
        },
    },
};
