import { Component, Input, OnInit } from "@angular/core";
import { SliderElements } from "app/constants/types";

@Component({
    selector: "app-slider-elements",
    templateUrl: "./slider-elements.component.html",
    styleUrls: ["./slider-elements.component.scss"],
})
export class SliderElementsComponent implements OnInit {
    @Input() config: any;
 
    constructor() {}

    ngOnInit(): void {}
}
