export const productField = {
  sections: [
    {
      name: "Product Information",
      fxFlex: "1",
      padding: "0 20px",
      fxFlexOrder: 1,
      show: true,
      default_value: "",
      disable: false,
      required: false,
      fields: [
        {
          name: "name",
          label: "Name",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 1,
          show: true,
          default_value: "",
          disable: false,
          required: true,
        },
        {
          name: "name_ar",
          label: "Name (Arabic)",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 2,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },

        // {
        //     name: "meta_title_en",
        //     label: "Meta Title (EN)",
        //     type: "text",
        //     fxFlex: "0 0 50%",
        //     padding: "0 10px",
        //     fxFlexOrder: 1,
        //     show: true,
        //     default_value: "",
        //     disable: false,
        //     required: false,
        // },
        // {
        //     name: "meta_title_ar",
        //     label: "Meta title (Ar)",
        //     type: "text",
        //     fxFlex: "0 0 50%",
        //     padding: "0 10px",
        //     fxFlexOrder: 2,
        //     show: true,
        //     default_value: "",
        //     disable: false,
        //     required: false,
        // },
        // {
        //     name: "meta_description_en",
        //     label: "Meta Description (En)",
        //     type: "text",
        //     fxFlex: "0 0 50%",
        //     padding: "0 10px",
        //     fxFlexOrder: 1,
        //     show: true,
        //     default_value: "",
        //     disable: false,
        //     required: false,
        // },
        // {
        //     name: "meta_description_ar",
        //     label: "Meta Description (Arabic)",
        //     type: "text",
        //     fxFlex: "0 0 50%",
        //     padding: "0 10px",
        //     fxFlexOrder: 2,
        //     show: true,
        //     default_value: "",
        //     disable: false,
        //     required: false,
        // },
        {
          name: "description",
          label: "Description",
          type: "text_editor",
          fxFlex: "0 0 100%",
          padding: "0 10px",
          fxFlexOrder: 3,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "description_ar",
          label: "Description (Arabic)",
          type: "text_editor",
          fxFlex: "0 0 100%",
          padding: "0 10px",
          fxFlexOrder: 4,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "hs_code",
          label: "HS Code",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 3,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "reference",
          label: "Reference",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 5,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "country_of_origin",
          label: "Country of Origin",
          type: "searchable_select",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 18,
          show: true,
          default_value: "",
          disable: false,
          required: false,
          api_endpoint: "api/country/",
          possible_values: [],
          add: true,
        },
        {
          name: "order_by",
          label: "Order By",
          type: "number",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 6,
          show: true,
          default_value: "0",
          disable: false,
          required: false,
        },
        {
          name: "category",
          label: "Category",
          type: "searchable_select",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 5,
          show: true,
          default_value: null,
          disable: false,
          required: false,
          api_endpoint: "api/manage/category/",
          possible_values: [],
          multiple: false,
        },
        {
          name: "brand",
          label: "Brand",
          type: "searchable_select",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 8,
          show: true,
          default_value: null,
          disable: false,
          required: false,
          api_endpoint: "api/manage/brand/",
          possible_values: [],
          multiple: false,
        },
        {
          name: "other_categories",
          label: "Other Categories",
          type: "searchable_select",
          fxFlex: "0 0 100%",
          padding: "0 10px",
          fxFlexOrder: 6,
          show: true,
          default_value: [],
          disable: false,
          required: false,
          api_endpoint: "api/manage/category/",
          possible_values: [],
          multiple: true,
        },
        {
          name: "tags",
          label: "Tags",
          type: "searchable_select",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 9,
          show: true,
          default_value: [],
          disable: false,
          required: false,
          api_endpoint: "api/manage/tag/",
          possible_values: [],
          multiple: true,
          add: true,
        },
        {
          name: "stickers",
          label: "Stickers",
          type: "searchable_select",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 9,
          show: true,
          default_value: [],
          disable: false,
          required: false,
          api_endpoint: "api/manage/sticker/",
          possible_values: [],
          multiple: true,
          add: true,
        },
        {
          name: "enabled",
          label: "Enabled",
          type: "toggle",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 10,
          show: true,
          default_value: true,
          disable: false,
          required: false,
        },
        {
          name: "availability",
          label: "Availability",
          type: "select",
          possible_values: [
            { id: 1, name: "Available" },
            { id: 2, name: "Unavailable" },
            { id: 3, name: "Limited" },
          ],
          api_endpoint: "",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 11,
          show: true,
          default_value: 1,
          disable: false,
          required: false,
        },
        {
          name: "unavailable_caption",
          label: "Unavailable Caption",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 13,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "unavailable_caption_ar",
          label: "Unavailable Caption (Arabic)",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 14,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "highlight",
          label: "Highlight",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 15,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "highlight_ar",
          label: "Highlight (Arabic)",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 16,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "highlight_color",
          label: "Highlight Color",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 17,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "composition",
          label: "Composition",
          type: "select",
          possible_values: [
            { id: 1, name: "Simple Product" },
            { id: 2, name: "Product with Variants" },
            { id: 3, name: "Fixed Bundle" },
            { id: 4, name: "Customizable Bundle" },
            { id: 5, name: "Flexi Bundle" },
          ],
          api_endpoint: "",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 19,
          show: true,
          default_value: 1,
          disable: false,
          required: false,
        },
      ],
    },
    {
      name: "Product Properties",
      fxFlex: "0 0 50%",
      padding: "0 20px",
      fxFlexOrder: 2,
      show: true,
      default_value: "",
      disable: false,
      required: false,
      fields: [
        {
          name: "sku",
          label: "SKU",
          type: "text",
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 1,
          show: true,
          default_value: "",
          disable: false,
          required: false,
          width: "33.33%",
        },
        {
          name: "barcode",
          label: "Barcode",
          type: "text",
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 2,
          show: true,
          default_value: "",
          disable: false,
          required: false,
          width: "33.33%",
        },
        {
          name: "inventory_threshold",
          label: "Inventory Threshold",
          type: "text",
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 22,
          show: true,
          default_value: "0",
          disable: false,
          required: true,
          width: "33.33%",
        },
        {
          name: "stock_quantity",
          label: "Stock Quantity",
          type: "number",
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 8,
          show: true,
          default_value: "0",
          disable: false,
          required: false,
          width: "33.33%",
        },

        {
          name: "min_quantity",
          label: "Minimum Quantity",
          type: "number",
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 8,
          show: true,
          default_value: "0",
          disable: false,
          required: false,
          width: "33.33%",
        },
        {
          name: "max_quantity",
          label: "Maximum Quantity",
          type: "number",
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 9,
          show: true,
          default_value: "0",
          disable: false,
          required: false,
          width: "33.33%",
        },
        {
          name: "is_stock_managed",
          label: "Is Stock Managed",
          type: "toggle",
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 10,
          show: true,
          default_value: true,
          disable: false,
          required: false,
        },
        {
          name: "allow_oversell",
          label: "Allow Oversell",
          type: "toggle",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 10,
          show: true,
          default_value: false,
          disable: false,
          required: false,
        },
        {
          name: "oversell_quantity",
          label: "Oversell Quantity",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 11,
          show: true,
          default_value: "",
          disable: true,
          required: false,
        },
        {
          name: "sale_price",
          label: "Sale Price",
          type: "text",
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 12,
          show: true,
          default_value: "0",
          disable: false,
          required: true,
          width: "33.33%",
        },
        {
          name: "cost_price",
          label: "Cost Price",
          type: "text",
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 13,
          show: true,
          default_value: "0",
          disable: false,
          required: true,
          width: "33.33%",
        },
        {
          name: "striked_price",
          label: "Striked Price",
          type: "text",
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 14,
          show: true,
          default_value: "",
          disable: false,
          required: false,
          width: "33.33%",
        },
        // {
        //   "name": "margin_type",
        //   "label": "Margin Type",
        //   "type": "select",
        //   "fxFlex": "0 0 50%",
        //   "padding": "0 10px",
        //   "fxFlexOrder": 16,
        //   "show": true,
        //   "default_value":1,
        //   "disable":false,
        //   "required":false,
        //   "possible_values": [
        //     { "id": 1, "name": "Fixed" },
        //     { "id": 2, "name": "Percentage" },
        //   ],
        //   "api_endpoint":''
        // },
        // {
        //   "name": "margin",
        //   "label": "Margin",
        //   "type": "text",
        //   "fxFlex": "0 0 50%",
        //   "padding": "0 10px",
        //   "fxFlexOrder": 15,
        //   "show": true,
        //   "default_value":'',
        //   "disable":false,
        //   "required":false
        // },
        {
          name: "weight",
          label: "Weight (kg)",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 17,
          show: true,
          default_value: "0",
          disable: false,
          required: false,
        },
        {
          name: "length",
          label: "Length (cm)",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 18,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "width",
          label: "Width (cm)",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 19,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "height",
          label: "Height (cm)",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 20,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "package_type",
          label: "Package Type",
          type: "select",
          possible_values: [
            { id: 1, name: "Whole" },
            { id: 2, name: "Loose" },
          ],
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 12,
          show: true,
          default_value: "0",
          disable: false,
          required: false,
          width: "33.33%",
        },
        {
          name: "package_unit",
          label: "Package Unit",
          type: "select",
          possible_values: [
            { id: 1, name: "Pack" },
            { id: 2, name: "Kg" },
            { id: 3, name: "Box" },
            { id: 4, name: "Piece" },
            { id: 5, name: "Pot" },
            { id: 6, name: "Bunch" },
            { id: 7, name: "Bag" },
            { id: 8, name: "Tray" },
            { id: 9, name: "Basket" },
            { id: 10, name: "Btl" },
            { id: 11, name: "Jar" },
            { id: 12, name: "Net" },
            { id: 13, name: "Can" },
          ],
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 13,
          show: true,
          default_value: "0",
          disable: false,
          required: false,
          width: "33.33%",
        },
        {
          name: "package_weight",
          label: "Package Weight",
          type: "number",
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 14,
          show: true,
          default_value: "",
          disable: false,
          required: false,
          width: "33.33%",
        },
        {
          name: "show_expire_in",
          label: "Show Expire In",
          type: "toggle",
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 10,
          show: true,
          default_value: true,
          disable: false,
          required: false,
        },
        {
          name: "expires_in",
          label: "Expires In",
          type: "text",
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 14,
          show: true,
          default_value: "",
          disable: false,
          required: false,
          width: "33.33%",
        },
        {
          name: "handling_instructions",
          label: "Handling Instructions",
          type: "searchable_select",
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 9,
          show: true,
          default_value: [],
          disable: false,
          required: false,
          api_endpoint: "api/manage/handling_instruction/",
          possible_values: [],
          multiple: true,
          add: true,
        },
        {
          name: "order_by",
          label: "Order by",
          type: "text",
          fxFlex: "0 0 33.33%",
          padding: "0 10px",
          fxFlexOrder: 14,
          show: true,
          default_value: "",
          disable: false,
          required: false,
          width: "33.33%",
        },
      ],
    },
  ],
};

export const productCategory = {
  sections: [
    {
      name: "Product Category",
      type: "general",
      show: true,
      fields: [
        {
          name: "name",
          label: "Name",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 1,
          show: true,
          default_value: "",
          disable: false,
          required: true,
        },
        {
          name: "name_ar",
          label: "Name (Arabic)",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 2,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "parent",
          label: "Parent Category",
          type: "searchable_select",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 5,
          show: true,
          default_value: null,
          disable: false,
          required: false,
          api_endpoint: "api/manage/category/",
          possible_values: [],
          multiple: false,
        },
        // {
        //     name: "meta_title_en",
        //     label: "Meta Title (EN)",
        //     type: "text",
        //     fxFlex: "0 0 50%",
        //     padding: "0 10px",
        //     fxFlexOrder: 1,
        //     show: true,
        //     default_value: "",
        //     disable: false,
        //     required: false,
        // },
        // {
        //     name: "meta_title_ar",
        //     label: "Meta title (Ar)",
        //     type: "text",
        //     fxFlex: "0 0 50%",
        //     padding: "0 10px",
        //     fxFlexOrder: 2,
        //     show: true,
        //     default_value: "",
        //     disable: false,
        //     required: false,
        // },
        // {
        //     name: "meta_description_en",
        //     label: "Meta Description (En)",
        //     type: "text",
        //     fxFlex: "0 0 50%",
        //     padding: "0 10px",
        //     fxFlexOrder: 1,
        //     show: true,
        //     default_value: "",
        //     disable: false,
        //     required: false,
        // },
        // {
        //     name: "meta_description_ar",
        //     label: "Meta Description (Arabic)",
        //     type: "text",
        //     fxFlex: "0 0 50%",
        //     padding: "0 10px",
        //     fxFlexOrder: 2,
        //     show: true,
        //     default_value: "",
        //     disable: false,
        //     required: false,
        // },
        {
          name: "order_by",
          label: "Order By",
          type: "number",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 6,
          show: true,
          default_value: 1,
          disable: false,
          required: false,
        },
        {
          name: "product_order_by",
          label: "Product Sort",
          type: "number",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 6,
          show: true,
          default_value: 1,
          disable: false,
          required: false,
        },

        {
          name: "image",
          label: "Image",
          type: "img",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 7,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "banner",
          label: "Banner",
          type: "img",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 8,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
      ],
    },
  ],
};

export const productBrand = {
  sections: [
    {
      name: "Product Brand",
      type: "general",
      show: true,
      fields: [
        {
          name: "name",
          label: "Name",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 1,
          show: true,
          default_value: "",
          disable: false,
          required: true,
        },
        {
          name: "name_ar",
          label: "Name (Arabic)",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 2,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        // {
        //     name: "meta_title_en",
        //     label: "Meta Title (EN)",
        //     type: "text",
        //     fxFlex: "0 0 50%",
        //     padding: "0 10px",
        //     fxFlexOrder: 1,
        //     show: true,
        //     default_value: "",
        //     disable: false,
        //     required: false,
        // },
        // {
        //     name: "meta_title_ar",
        //     label: "Meta title (Ar)",
        //     type: "text",
        //     fxFlex: "0 0 50%",
        //     padding: "0 10px",
        //     fxFlexOrder: 2,
        //     show: true,
        //     default_value: "",
        //     disable: false,
        //     required: false,
        // },
        // {
        //     name: "meta_description_en",
        //     label: "Meta Description (En)",
        //     type: "text",
        //     fxFlex: "0 0 50%",
        //     padding: "0 10px",
        //     fxFlexOrder: 1,
        //     show: true,
        //     default_value: "",
        //     disable: false,
        //     required: false,
        // },
        // {
        //     name: "meta_description_ar",
        //     label: "Meta Description (Arabic)",
        //     type: "text",
        //     fxFlex: "0 0 50%",
        //     padding: "0 10px",
        //     fxFlexOrder: 2,
        //     show: true,
        //     default_value: "",
        //     disable: false,
        //     required: false,
        // },
        {
          name: "order_by",
          label: "Order By",
          type: "number",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 6,
          show: true,
          default_value: 1,
          disable: false,
          required: false,
        },
        {
          name: "image",
          label: "Image",
          type: "img",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 7,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "banner",
          label: "Banner",
          type: "img",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 8,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
      ],
    },
  ],
};

export const productAttribute = {
  sections: [
    {
      name: "Basic Information",
      type: "general",
      show: true,
      fields: [
        {
          name: "name",
          label: "Name",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 1,
          show: true,
          default_value: "",
          disable: false,
          required: true,
        },
        {
          name: "name_ar",
          label: "Name (Arabic)",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 2,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "order_by",
          label: "Order By",
          type: "number",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 6,
          show: true,
          default_value: 1,
          disable: false,
          required: false,
        },
        {
          name: "type",
          label: "Type",
          type: "select",
          possible_values: [
            { id: 1, name: "Choices" },
            { id: 2, name: "Color" },
            { id: 3, name: "Size" },
          ],
          api_endpoint: "",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 11,
          show: true,
          default_value: 1,
          disable: false,
          required: false,
        },
      ],
    },
  ],
};
export const DeliveryAgent = {
  sections: [
    {
      name: "Delivery Agent Information",
      fxFlex: "1",
      padding: "0 20px",
      fxFlexOrder: 1,
      show: true,
      default_value: "",
      disable: false,
      required: false,
      fields: [
        {
          name: "first_name",
          label: "First Name",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 1,
          show: true,
          default_value: "",
          disable: false,
          required: true,
        },
        {
          name: "middle_name",
          label: "Middle Name",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 2,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },

        {
          name: "last_name",
          label: "Last Name",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 1,
          show: true,
          default_value: "",
          disable: false,
          required: true,
        },
        {
          name: "status",
          label: "Status",
          type: "select",
          possible_values: [
            { id: 1, name: "Active" },
            { id: 2, name: "InActive" },
          ],
          api_endpoint: "",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 19,
          show: true,
          default_value: 1,
          disable: false,
          required: true,
        },

        {
          name: "email",
          label: "Email",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 5,
          show: true,
          default_value: "",
          disable: false,
          required: true,
        },
        {
          name: "password",
          label: "Password",
          type: "password",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 5,
          show: true,
          default_value: "",
          disable: false,
          required: true,
          add: true,
        },

        {
          name: "contact_no_country_id",
          label: "Contact No Country",
          type: "searchable_select",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 18,
          show: true,
          default_value: "",
          disable: false,
          required: true,
          api_endpoint: "api/country/",
          possible_values: [],
        },
        {
          name: "contact_no",
          label: "Contact No",
          type: "text",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 6,
          show: true,
          default_value: "0",
          disable: false,
          required: true,
        },

        {
          name: "profile_pic",
          label: "Profile Pic",
          type: "img",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 7,
          show: true,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "civil_id",
          label: "Civil Id",
          type: "img",
          fxFlex: "0 0 50%",
          padding: "0 10px",
          fxFlexOrder: 8,
          show: true,
          default_value: "",
          disable: false,
          required: true,
        },
        {
          name: "user",
          label: "user",
          type: "text",
          fxFlex: "0 0 30%",
          padding: "0 10px",
          fxFlexOrder: 1,
          show: false,
          default_value: "",
          disable: false,
          required: false,
        },
        {
          name: "id",
          label: "id",
          type: "text",
          fxFlex: "0 0 30%",
          padding: "0 10px",
          fxFlexOrder: 1,
          show: false,
          default_value: "",
          disable: false,
          required: false,
        },
      ],
    },
  ],
};
