import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { HttpClient } from "@angular/common/http";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseSidebarService } from "../../../../@fuse/components/sidebar/sidebar.service";
import { FusePerfectScrollbarDirective } from "../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";

@Component({
    selector: "chat-panel",
    templateUrl: "./chat-panel.component.html",
    styleUrls: ["./chat-panel.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ChatPanelComponent implements OnInit, AfterViewInit, OnDestroy {
    contacts: any[];
    chat: any;
    selectedContact: any;
    sidebarFolded: boolean;
    user: any;
    @ViewChildren(FusePerfectScrollbarDirective)
    private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

    // Private
    private _chatViewScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChatPanelService} _chatPanelService
     * @param {HttpClient} _httpClient
     * @param {FuseSidebarService} _fuseSidebarService
     */
    constructor(
        private _httpClient: HttpClient,
        private _fuseSidebarService: FuseSidebarService
    ) {
        // Set the defaults
        this.selectedContact = null;
        this.sidebarFolded = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Load the contacts

        // Subscribe to the foldedChanged observable
        this._fuseSidebarService
            .getSidebar("chatPanel")
            .foldedChanged.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((folded) => {
                this.sidebarFolded = folded;
            });
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {}

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Prepare the chat for the replies
     */

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Fold the temporarily unfolded sidebar back
     */
    foldSidebarTemporarily(): void {
        this._fuseSidebarService.getSidebar("chatPanel").foldTemporarily();
    }

    /**
     * Unfold the sidebar temporarily
     */
    unfoldSidebarTemporarily(): void {
        this._fuseSidebarService.getSidebar("chatPanel").unfoldTemporarily();
    }

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpen(): void {
        this._fuseSidebarService.getSidebar("chatPanel").toggleOpen();
    }

    /**
     * Decide whether to show or not the contact's avatar in the message ro

    /**
     * Check if the given message is the first message of a group
     */

    /**
     * Check if the given message is the last message of a group
     *
     */

    /**
     * Toggle chat with the contact
     *

    /**
     * Remove the selected contact and unload the chat
 

    /**
     * Reply
     */
}
