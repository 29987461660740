import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
    FormArray,
} from "@angular/forms";
import { CatalogueService } from "app/services/catalogue/catalogue.service";
import { productCategory, productField } from "app/main/types/productFields";
import { take, takeUntil } from "rxjs/operators";
import { UtilitiesService } from "app/services/utilitiesService";
import { Subject } from "rxjs";
import { DynamicFieldTypes } from "app/main/types/dynamicField";
import { PageService } from "app/services/pageService";
import { fuseAnimations } from "@fuse/animations";

@Component({
    selector: "app-create-category",
    templateUrl: "./create-category.component.html",
    styleUrls: ["./create-category.component.scss"],
    animations: fuseAnimations,
})
export class CreateCategoryComponent implements OnInit {
    @Input() id: any = "";
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    @Output() createProductCategory: EventEmitter<void> =
        new EventEmitter<any>();
    form: FormGroup;
    productForm: FormGroup;
    isApiCallDone: boolean = false;
    imgUrls: any[] = [];
    productFields: any = productCategory.sections;
    unSubscribe$ = new Subject<void>();
    pageElements: any
    rowData = []

    constructor(
        private fb: FormBuilder,
        private catalogueService: CatalogueService,
        private utilitiesService: UtilitiesService,
        private pageService: PageService
    ) { }

    ngOnInit(): void {
        this.formInitialize();
        this.getPageElements()
        if (this.id) {
            this.getCategoryById();
        } else {
            this.isApiCallDone = true;
        }
    }

    async formInitialize() {
        this.form = this.fb.group({});
        this.productFields.forEach((section) => {
            section.fields.forEach((field) => {
                this.form.addControl(
                    field.name,
                    new FormControl(
                        {
                            value:
                                typeof field.default_value === "boolean"
                                    ? field?.default_value
                                    : field?.default_value || "",
                            disabled: field?.disable,
                        },
                        field?.required ? Validators.required : null
                    )
                );
                if (field?.type === DynamicFieldTypes.TextEditor) {
                    this.form.addControl(
                        `${field?.name}_control`,
                        new FormControl("")
                    );
                }
            });
        });
    }

    setFormValue(product) {
        for (let key in this.form.getRawValue()) {
            if (product.hasOwnProperty(key)) {
                this.form.get(key).setValue(product[key]);
            }
        }
    }

    getCategoryById() {
        this.utilitiesService.startLoader();
        this.catalogueService
            .getCategoryById(this.id)
            .pipe(takeUntil(this.unSubscribe$))
            .subscribe((categories) => {
                this.rowData = categories
                this.setFormValue(categories);
                this.isApiCallDone = true;
            });
        this.utilitiesService.stopLoader();
    }

    setFileToForm(file, type) {
        console.log(file);
        const control =
            type === "image" ? this.form.get("image") : this.form.get("banner");
        const reader = new FileReader();
        reader.readAsDataURL(file.file);
        reader.onload = () => {
            control.setValue(reader.result as string);
        };
    }

    deleteFile(control: FormControl) {
        control.setValue("");
    }

    handleCancel() {
        this.createProductCategory.emit();
    }

    saveOrUpdate() {
        console.log(this.form.value);
        const form = this.form.value;
        delete form.product_img;

        if (this.form.valid) {
            if (this.id) {
                this.updateCategory(form);
            } else {
                this.saveCategory(form);
            }
        } else {
            this.checkValidations();
        }
    }

    saveCategory(form) {
        this.utilitiesService.startLoader();
        this.catalogueService.saveCategory(form).subscribe((res) => {
            if (res) {
                this.utilitiesService.stopLoader();
                let msg = "Category saved successfully.";
                this.utilitiesService.showSuccessToast(msg);
                this.createProductCategory.emit();
            }
        });
        this.utilitiesService.stopLoader();
    }

    updateCategory(form) {
        this.utilitiesService.startLoader();
        this.catalogueService.updateCategory(form, this.id).subscribe((res) => {
            if (res) {
                this.utilitiesService.stopLoader();
                let msg = "Category upated successfully.";
                this.utilitiesService.showSuccessToast(msg);
                this.createProductCategory.emit();
            }
        });
        this.utilitiesService.stopLoader();
    }

    checkValidations() {
        for (const controlName in this.form.controls) {
            if (this.form.controls.hasOwnProperty(controlName)) {
                const control = this.form.get(controlName);
                if (control.invalid) {
                    let msg = `${this.transformControlName(
                        controlName
                    )} is required.`;
                    this.utilitiesService.showErrorToast(msg);
                    break; // Stop the loop as soon as an invalid control is found
                }
            }
        }
        Object.keys(this.form.controls).forEach((field) => {
            const control = this.form.get(field);
            control.markAsTouched({ onlySelf: true });
        });
    }

    transformControlName(controlName: string): string {
        return controlName
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    }

    ngOnDestroy(): void {
        this.unSubscribe$.next();
        this.unSubscribe$.complete();
    }

    async getPageElements() {
        try {
            const pageElements = await this.pageService
                .getElements("page", '20')
                .toPromise();
            if (pageElements) {
                this.pageElements = pageElements;
            }
        } catch { }
    }

    afterCreateProductPage() {

    }
}
