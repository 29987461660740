<div fxFlex="0 0 100%" fxLayout="column" fxLayoutAlign="start stretch">
    <div
        *ngIf="walletDetailID"
        fxFlex="0 0 auto"
        fxLayoutAlign="start stretch"
        fxLayout.lt-md="column"
        fxLayout.gt-sm="row"
        fxLayoutGap="8px"
        class="p-16"
    >
        <!-- Personal Information -->
        <div
            class="fuse-card auto-width p-16 hvr-float-shadow cc-primary"
            fxFlex.gt-sm="1 1 0px"
            fxFlex.lt-md="0 0 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="8px"
        >
            <div
                fxFlex="0 0 auto"
                fxLayoutAlign="space-between stretch"
                fxLayout="row"
                fxLayoutGap="10px"
            >
                <label class="highlight-text-color label">{{
                    "Personal Information" | translate
                }}</label>
            </div>

            <div
                fxFlex="0 0 auto"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="1em"
            >
                <!-- Customer Name -->
                <div
                    fxFlex="1 1 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="row"
                    fxLayoutGap="8px"
                >
                    <div fxFlex="1 1 0px">
                        {{ "Customer Name" | translate }} :
                    </div>
                    <div fxFlex="1 1 0px">
                        {{ customerDetails?.first_name }}
                        {{ customerDetails?.last_name }}
                    </div>
                </div>
                <!-- Email -->
                <div
                    fxFlex="1 1 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="row"
                    fxLayoutGap="8px"
                >
                    <div fxFlex="1 1 0px">{{ "Email" | translate }} :</div>
                    <div fxFlex="1 1 0px">
                        {{ customerDetails?.email }}
                    </div>
                </div>
                <div
                    *ngIf="customerDetails?.contact_no"
                    fxFlex="1 1 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="row"
                    fxLayoutGap="8px"
                >
                    <div fxFlex="1 1 0px">
                        {{ "Contact Number" | translate }} :
                    </div>
                    <div fxFlex="1 1 0px">
                        {{ customerDetails?.contact_no }}
                    </div>
                </div>
                <div
                    *ngIf="customerDetails?.dob"
                    fxFlex="1 1 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="row"
                    fxLayoutGap="8px"
                >
                    <div fxFlex="1 1 0px">
                        {{ "Date of Birth" | translate }} :
                    </div>
                    <div fxFlex="1 1 0px">
                        {{ customerDetails?.dob | date : "dd/MM/yyyy" }}
                    </div>
                </div>
            </div>
        </div>
        <!-- Additional Information -->
        <div
            class="fuse-card auto-width p-16 hvr-float-shadow cc-primary"
            fxFlex.gt-sm="1 1 0px"
            fxFlex.lt-md="0 0 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="8px"
        >
            <div
                fxFlex="0 0 auto"
                fxLayoutAlign="space-between stretch"
                fxLayout="row"
                fxLayoutGap="10px"
            >
                <label class="highlight-text-color label">{{
                    "Additional Information" | translate
                }}</label>
            </div>

            <div
                fxFlex="0 0 auto"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="1em"
            >
                <div
                    *ngIf="customerDetails?.customer_type"
                    fxFlex="1 1 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="row"
                    fxLayoutGap="8px"
                >
                    <div fxFlex="1 1 0px">{{ "Balance" | translate }} :</div>
                    <div fxFlex="1 1 0px">
                        {{ walletDetails?.balance }}
                    </div>
                </div>

                <div
                    *ngIf="customerDetails?.created"
                    fxFlex="1 1 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="row"
                    fxLayoutGap="8px"
                >
                    <div fxFlex="1 1 0px">
                        {{ "Created date" | translate }} :
                    </div>
                    <div fxFlex="1 1 0px">
                        {{
                            customerDetails?.created
                                | date : "dd/MM/yyyy hh mm a"
                        }}
                    </div>
                </div>
                <div
                    *ngIf="customerDetails?.updated"
                    fxFlex="1 1 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="row"
                    fxLayoutGap="8px"
                >
                    <div fxFlex="1 1 0px">
                        {{ "Updated date" | translate }} :
                    </div>
                    <div fxFlex="1 1 0px">
                        {{
                            customerDetails?.updated
                                | date : "dd/MM/yyyy hh mm a"
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        id="dashboard-project"
        class="page-layout simple right-sidebar"
        fxLayout="column"
    >
        <div
            class="center"
            fxFlex="1 1 auto"
            fxLayoutAlign="start stretch"
            fxLayout="column"
            fxLayoutGap="1em"
            fusePerfectScrollbar
        >
            <div
                class="content p-16"
                fxFlex="1 1 auto"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="1em"
            >
                <ng-container>
                    <div
                        *ngIf="walletDetailID"
                        fxLayout="row"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="end space-between"
                        fxLayoutGap="1em"
                    >
                        <div
                            *ngIf="walletDetailID"
                            fxLayout="row"
                            fxFlex="0 0 auto"
                            fxLayoutAlign="start start"
                            fxLayoutGap="1em"
                        >
                            <app-clear-filter class="mt-4" [form]="form">
                            </app-clear-filter>
                            <app-export-button
                                [type]="
                                    utilitiesService.getDownloadTypes
                                        .transactions
                                "
                                [listLength]="transactionList?.length"
                                (exportHandle)="myDownloads()"
                                (downloadHandle)="download()"
                            >
                            </app-export-button>
                            <mat-form-field appearance="outline">
                                <mat-select
                                    [(ngModel)]="page.size"
                                    (selectionChange)="limitChange()"
                                >
                                    <mat-option [value]="5">5</mat-option>
                                    <mat-option [value]="10">10</mat-option>
                                    <mat-option [value]="25">25</mat-option>
                                    <mat-option [value]="50">50</mat-option>
                                    <mat-option [value]="100">100</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <app-common-filter
                                (searchInput)="search($event)"
                                fxHide.lt-md
                            >
                            </app-common-filter>
                        </div>
                    </div>
                    <div
                        *ngIf="walletDetailID"
                        fxLayout="row"
                        fxFlex="1 1 auto"
                        fxLayoutAlign="start start"
                        fxLayoutGap="1em"
                    >
                        <app-common-filter
                            (searchInput)="search($event)"
                            fxHide
                            fxShow.lt-md
                        >
                        </app-common-filter>
                    </div>
                    <div
                        fxFlex="0 0 auto"
                        fxLayoutAlign="start stretch"
                        fxLayout="column"
                        fxLayoutGap="1em"
                        [formGroup]="form"
                    >
                        <ngx-datatable
                            [loadingIndicator]="isLoading"
                            [externalPaging]="true"
                            [count]="page.totalElements"
                            [limit]="page.size"
                            (page)="pageChange($event)"
                            [offset]="page.pageNumber"
                            [virtualization]="false"
                            [scrollbarH]="true"
                            [rows]="transactionList"
                            [cssClasses]="iconsCss"
                            class="material striped mt-8"
                            [columnMode]="'force'"
                            [headerHeight]="90"
                            [rowHeight]="50"
                            [footerHeight]="'auto'"
                        >
                            {{ transactionList }}
                            <!-- #id -->
                            <ngx-datatable-column
                                name="No"
                                [maxWidth]="10"
                                [width]="10"
                            >
                                <ng-template
                                    let-row="row"
                                    let-rowIndex="rowIndex"
                                    let-value="value"
                                    ngx-datatable-cell-template
                                >
                                    {{
                                        page.size * page.pageNumber +
                                            rowIndex +
                                            1
                                    }}
                                </ng-template>
                            </ngx-datatable-column>
                            <!-- Transaction Id -->
                            <ngx-datatable-column
                                [width]="20"
                                [maxWidth]="20"
                                name="Id"
                                prop="id"
                            >
                            </ngx-datatable-column>
                            <!-- first name -->
                            <ngx-datatable-column
                                [width]="100"
                                name="Transaction No"
                                prop="transaction_no"
                            >
                                <ng-template
                                    let-column="column"
                                    ngx-datatable-header-template
                                >
                                    <div fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer">
                                            {{ "Transaction No" | translate }}
                                        </p>
                                        <input
                                            formControlName="transaction_no"
                                            type="text"
                                            class="form-control"
                                        />
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <!-- source -->
                            <ngx-datatable-column
                                [width]="60"
                                name="{{ 'Source' | translate }}"
                                prop="sourceName"
                            >
                                <ng-template
                                    let-column="column"
                                    ngx-datatable-header-template
                                >
                                    <div fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer">
                                            {{ "Source" | translate }}
                                        </p>

                                        <select
                                            formControlName="source"
                                            class="form-control"
                                        >
                                            <option value=""></option>
                                            <option
                                                [value]="type?.value"
                                                *ngFor="let type of sourceType"
                                            >
                                                {{ type.name }}
                                            </option>
                                        </select>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <!-- status -->
                            <ngx-datatable-column
                                [width]="40"
                                name="{{ 'Status' | translate }}"
                                prop="statusName"
                            >
                                <ng-template
                                    let-column="column"
                                    ngx-datatable-header-template
                                >
                                    <div fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer">
                                            {{ "Status" | translate }}
                                        </p>

                                        <select
                                            formControlName="status"
                                            class="form-control"
                                        >
                                            <option value=""></option>
                                            <option
                                                [value]="type?.value"
                                                *ngFor="let type of statusType"
                                            >
                                                {{ type.name }}
                                            </option>
                                        </select>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <!-- type -->
                            <ngx-datatable-column
                                [width]="40"
                                name="{{ 'Type' | translate }}"
                                prop="typeName"
                            >
                                <ng-template
                                    let-column="column"
                                    ngx-datatable-header-template
                                >
                                    <div fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer">
                                            {{ "Type" | translate }}
                                        </p>

                                        <select
                                            formControlName="type"
                                            class="form-control"
                                        >
                                            <option value=""></option>
                                            <option
                                                [value]="type?.value"
                                                *ngFor="
                                                    let type of transicitionType
                                                "
                                            >
                                                {{ type.name }}
                                            </option>
                                        </select>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <!-- amount -->
                            <ngx-datatable-column
                                [width]="60"
                                name="{{ 'Amount' }}"
                                prop="amount"
                            >
                                <ng-template
                                    let-column="column"
                                    ngx-datatable-header-template
                                >
                                    <div fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer">
                                            {{ "Balance " | translate }}
                                        </p>
                                        <input
                                            formControlName="amount"
                                            type="text"
                                            class="form-control"
                                        />
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <!-- remarks -->
                            <ngx-datatable-column
                                [width]="60"
                                name="{{ 'remarks' }}"
                                prop="remarks"
                            >
                                <ng-template
                                    let-column="column"
                                    ngx-datatable-header-template
                                >
                                    <div fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer">
                                            {{ "remarks " | translate }}
                                        </p>
                                        <input
                                            formControlName="remarks"
                                            type="text"
                                            class="form-control"
                                        />
                                    </div>
                                </ng-template>
                                <ng-template
                                    let-column="column"
                                    let-row="row"
                                    let-rowIndex="rowIndex"
                                    let-value="value"
                                    ngx-datatable-cell-template
                                >
                                    <p>
                                        {{ row.remarks ? row.remarks : " - " }}
                                    </p>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column
                                [width]="120"
                                name="Transaction Date"
                            >
                                <ng-template
                                    let-column="column"
                                    ngx-datatable-header-template
                                >
                                    <div fxFlex="1 1 auto" fxLayout="column">
                                        <p style="cursor: pointer">
                                            {{ "Transaction Date" | translate }}
                                        </p>
                                        <input
                                            formControlName="created"
                                            type="text"
                                            class="form-control"
                                        />
                                    </div>
                                </ng-template>
                                <ng-template
                                    let-column="column"
                                    let-row="row"
                                    let-rowIndex="rowIndex"
                                    let-value="value"
                                    ngx-datatable-cell-template
                                >
                                    <p>
                                        {{ row.created | date : "short" }}
                                    </p>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
