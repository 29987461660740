import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { CustomerService } from "app/services/customer/customer.service";
import { CampaignsService } from "app/services/marketing/campaigns.service";
import { MediaService } from "app/services/media.service";
import { NotificationService } from "app/services/notification/notification.service";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { SendNotificationComponent } from "../send-notification/send-notification.component";
import { WalletService } from "../../../services/wallet/wallet.service";
@Component({
    selector: "app-topup-wallet",
    templateUrl: "./topup-wallet.component.html",
    styleUrls: ["./topup-wallet.component.scss"],
})
export class TopupWalletComponent implements OnInit {
    loading: boolean = false;
    form: FormGroup;
    walletID: any = "";
    currency: any = "";
    ADDING = 1;
    SUBTRACT = 2;
    type = [
        { id: 1, value: "TOPUP" },
        { id: 2, value: "" },
    ];

    constructor(
        public dialogRef: MatDialogRef<TopupWalletComponent>,
        private formBuilder: FormBuilder,
        private utilitiesService: UtilitiesService,
        private walletService: WalletService,
        private translationService: TranslationService,

        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data) {
            this.walletID = data.walletID ? data.walletID : null;
            this.currency = data.Currrency ? data.Currrency : null;
        }
    }

    async ngOnInit(): Promise<void> {
        await this.formInitialize();
    }

    async formInitialize() {
        this.form = this.formBuilder.group({
            wallet_id: [this.walletID],
            amount: [null, Validators.required],

            remarks: [null],
            type: [1],
        });
    }

    async send() {
        if (this.form.valid) {
            const form = this.form.value;

            await this.walletService.topUpWallet(form).subscribe((res) => {
                if (res.id) {
                    this.utilitiesService.stopLoader();
                    let msg = "Done ...";
                    this.utilitiesService.showSuccessToast(msg);
                    this.dialogRef.close(true);
                }
            });
            this.utilitiesService.stopLoader();
        }
    }

    close() {
        this.dialogRef.close(null);
    }
}
