import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { FeaturesConstants } from "app/featuresEnum";
import { IconCss } from "app/main/ngxDatatable-Icons/icons";
import { CoreService } from "app/services/core-service";
import { CustomerService } from "app/services/customer/customer.service";
import { OrderService } from "app/services/orders/order.service";
import { PageService } from "app/services/pageService";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import * as moment from "moment";
import { EditCustomerComponent } from "../edit-customer/edit-customer.component";

@Component({
    selector: "app-view-customers",
    templateUrl: "./view-customers.component.html",
    styleUrls: ["./view-customers.component.scss"],
    animations: fuseAnimations,
})
export class ViewCustomersComponent implements OnInit {
    isLoading: boolean = false;
    loading: boolean = false;
    icons = IconCss;
    isView: boolean = false;
    isEdit: boolean = false;
    orderID: any = "";
    iconsCss = {
        pagerLeftArrow: this.icons.left,
        pagerRightArrow: this.icons.right,
        pagerPrevious: this.icons.previous,
        pagerNext: this.icons.next,
    };
    offset: number = 0;
    pageWishlist = {
        size: 10,
        totalElements: 0,
        pageNumber: 0,
    };
    pageAddress = {
        size: 10000,
        totalElements: 0,
        pageNumber: 0,
    };
    @Input() id: any = "";
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    @Output()
    sendNotificationEvent: EventEmitter<void> = new EventEmitter<any>();
    @Output() changePasswordEvent: EventEmitter<void> = new EventEmitter<any>();
    @Output() isViewEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() isEditEvent: EventEmitter<any> = new EventEmitter<any>();

    customerDetail: any = "";
    customerAddresses = [];
    selectedIndex = 0;
    wishlist = [];
    orders = [];
    customerSummary = {
        total_amount: 0,
        orders: 0,
    };
    dynamicFieldList = [];
    offsetWishlist: number = 0;
    orderCount: number = 0;
    membershipCount: number = 0;
    wishlistCount: number = 0;
    addressCount: number = 0;
    featuresConstants = FeaturesConstants;
    currencyCode;
    currencyName;
    constructor(
        private customerService: CustomerService,
        private dialog: MatDialog,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        private pageService: PageService,
        private translationService: TranslationService,
        private sanitizer: DomSanitizer,
        private orderService: OrderService,
        public coreService: CoreService
    ) {
        this.pageAddress.pageNumber = 0;
        this.pageAddress.size = 10000;
        this.currencyCode = localStorage.getItem("currencyCode");
    }

    async ngOnInit(): Promise<void> {
        await this.getCustomerDetails();
    }
    async getCustomerDetails() {
        if (this.id) {
            this.utilitiesService.startLoader();
            await this.getOrderCount();
            await this.getDynamicFieldList();
            await this.getDetails();
        }
    }
    getWidth() {
        this.orders = [...this.orders];
        return 100;
    }

    async getOrderCount() {
        try {
            const orderCount = await this.customerService
                .getOrderCount(this.id)
                .toPromise();
            if (orderCount) {
                this.customerSummary.orders = orderCount.total_order;
                this.customerSummary.total_amount = orderCount.total_amount;
            }
        } catch {
        } finally {
        }
    }
    async tabChange(event?) {
        this.selectedIndex = event.index;
    }
    gotoOrders() {
        this.selectedIndex = 0;
        setTimeout(() => {
            document.getElementById("orders").scrollIntoView();
        }, 100);
    }
    getDynamicValues(name, value) {
        let values = [];
        if (this.dynamicFieldList.length > 0 && name) {
            let dynamicArray = this.dynamicFieldList.filter(
                (data) => data.name == name
            );
            if (dynamicArray.length > 0) {
                if (
                    dynamicArray[0].type == "checkbox" ||
                    dynamicArray[0].type == "radio" ||
                    dynamicArray[0].type == "select"
                ) {
                    let possibleValues = dynamicArray[0].possible_values;
                    if (possibleValues.length > 0 && value instanceof Array) {
                        value.forEach((item) => {
                            let itemArray = possibleValues.filter(
                                (items) => items.id == item
                            );
                            if (itemArray.length > 0) {
                                if (
                                    this.translationService.currentLang == "en"
                                ) {
                                    values.push(itemArray[0].name_en);
                                } else {
                                    values.push(itemArray[0].name_ar);
                                }
                            }
                        });
                    } else if (possibleValues.length > 0 && !isNaN(value)) {
                        let selectArray = possibleValues.filter(
                            (items) => items.id == value
                        );
                        if (selectArray.length > 0) {
                            if (this.translationService.currentLang == "en") {
                                values.push(selectArray[0].name_en);
                            } else {
                                values.push(selectArray[0].name_ar);
                            }
                        }
                    }
                } else if (dynamicArray[0].type == "date") {
                    if (value) {
                        let date = new Date(value);
                        values.push(moment(date).format("DD/MM/YYYY"));
                    } else {
                        values.push(value);
                    }
                } else {
                    values.push(value);
                }
            }
        }
        return values;
        // return this.sanitizer.bypassSecurityTrustHtml(values.toString());
    }

    getDynamicFieldName(name) {
        let label = "";
        if (this.dynamicFieldList.length > 0 && name) {
            let labelArray = this.dynamicFieldList.filter(
                (data) => data.name == name
            );
            if (labelArray.length > 0) {
                label =
                    this.translationService.currentLang == "en"
                        ? labelArray[0].label_en
                        : labelArray[0].label_ar;
            }
        }
        return label ? label : name;
    }

    async getDynamicFieldList(limit = 999999, offset = 0) {
        try {
            const dynamicField = await this.pageService
                .getDynamicFieldList(limit, offset)
                .toPromise();
            if (dynamicField) {
                this.dynamicFieldList = dynamicField.results;
            }
        } catch {
        } finally {
            // this.utilitiesService.stopLoader();
        }
    }

    async getDetails() {
        try {
            this.loading = true;
            const customerDetail = await this.customerService
                .getCustomerDetail(this.id)
                .toPromise();
            if (customerDetail) {
                this.customerDetail = customerDetail;
                this.utilitiesService.stopLoader();
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }

    sendNotification() {
        this.sendNotificationEvent.emit(this.customerDetail.id);
    }
    changePassowrd() {
        this.changePasswordEvent.emit(this.customerDetail.id);
    }

    afterEdit() {
        this.cancelClick.emit();
        this.getCustomerDetails();
    }
    setOrderCount(count) {
        this.orderCount = count;
    }
    setMemberCount(count) {
        this.membershipCount = count;
    }
    setWishlistCount(count) {
        this.wishlistCount = count;
    }
    setAddressCount(count) {
        this.addressCount = count;
    }

    viewOrder(id) {
        this.orderID = id;
        this.isViewEvent.emit(this.orderID);
        this.isView = true;
        this.isEdit = false;
    }
    editCustomer() {
        this.customerDetail = this.customerDetail;
        this.dynamicFieldList = [...this.dynamicFieldList];
        this.isEditEvent.emit(this.customerDetail);
        this.isView = false;
        this.isEdit = true;
        // let dynamicFieldList = this.dynamicFieldList;
        // let isMobile = this.utilitiesService.isMobile();
        // let size = {
        //     width: "50vw",
        //     height: "50vh",
        // };
        // if (isMobile) {
        //     size.height = "60%";
        //     size.width = "100%";
        // }
        // const dialogRef = this.dialog.open(EditCustomerComponent, {
        //     data: { row, dynamicFieldList },
        //     maxWidth: "",
        //     width: `${size.width}`,
        //     height: `${size.height}`,
        // });
        // dialogRef.afterClosed().subscribe((resp) => {
        //     if (resp) {
        //         console.log(resp);
        //         let successMsg = this.translate.instant(
        //             `Customer updated successfully`
        //         );
        //         this.customerService
        //             .updateCustomer(row.id, resp)
        //             .subscribe(async (data) => {
        //                 this.utilitiesService.showSuccessToast(successMsg);
        //                 this.getCustomerDetails();
        //             });
        //     }
        // });
    }

    isDynamicEmpty() {
        if (this.customerDetail)
            return Object.keys(this.customerDetail.dynamic_fields).length === 0
                ? false
                : true;
    }
}
