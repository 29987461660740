<div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="column" fxLayoutGap="1em">
  <div class="heading">
    {{ title }}
  </div>
  <form fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em" [formGroup]="form">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
      <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
        <div
          fxFlex="0 0  auto"
          class="fuse-card auto-width p-16 center-container"
          fxLayoutAlign="start stretch"
          fxLayout="column"
          fxLayoutGap="1em">
          <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="16px">
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
              <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                  <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label class="text-capitalized">
                      {{ "Name" | translate }}
                      <span class="text-red-600">*</span>
                    </mat-label>
                    <input formControlName="name" matInput name="title" />
                    <mat-error *ngIf="form.get('name').hasError('required')">
                      {{ "Name is required" | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label class="text-capitalized">
                      {{ "Name(Arabic)" | translate }}
                    </mat-label>
                    <input formControlName="name_ar" matInput name="title" />
                    <mat-error *ngIf="form.get('name_ar').hasError('required')">
                      {{ "Name is required" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                  <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label class="text-capitalized">{{ "Code" | translate }}</mat-label>
                    <input formControlName="code" matInput name="code" />
                    <mat-error *ngIf="form.get('code').hasError('required')">
                      {{ "Code is required" | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label class="text-capitalized">{{ "ISD Code" | translate }}</mat-label>
                    <input formControlName="isd_code" matInput name="isd_code" />
                    <mat-error *ngIf="form.get('isd_code').hasError('required')">
                      {{ "ISD Code is required" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                  <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label class="text-capitalized">{{ "Phone Digits" | translate }}</mat-label>
                    <input formControlName="phone_digits" matInput name="phone_digits" />
                    <mat-error *ngIf="form.get('phone_digits').hasError('required')">
                      {{ "Phone Digits are required" | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label class="text-capitalized">{{ "Currency" | translate }}</mat-label>
                    <mat-select formControlName="currency" required>
                      <mat-option *ngFor="let currency of currencyList" [value]="currency.value">
                        {{ currency.label }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('currency').hasError('required')">
                      {{ "Currency is required" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div
            fxFlex="1 1 auto"
            fxLayoutAlign.gt-sm="end center"
            fxLayoutAlign.lt-md="start stretch"
            fxLayout.lt-md="column-reverse"
            fxLayout.gt-sm="row"
            fxLayoutGap="5px">
            <button mat-stroked-button (click)="handleCancel()">
              <mat-icon>clear</mat-icon>
              {{ "Cancel" | translate }}
            </button>
            <button (click)="saveOrUpdate()" type="submit" mat-flat-button class="primary-bg-color">
              <mat-icon>save</mat-icon>
              {{ "Save" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
