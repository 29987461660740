import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { ServiceWorkerModule } from "@angular/service-worker";

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from '@fuse/components';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { fuseConfig } from 'app/fuse-config';
import { AppRoutes } from './routes';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SharedService } from './services/sharedService';
import { PageService } from './services/pageService';
import { PostsService } from './services/posts.service';
import { SliderService } from './services/sliderService';
import { WidgetService } from './services/widget.service';
import { MediaService } from './services/media.service';
import { NavMenuService } from './services/nav-menu.service';
import { UtilitiesService } from './services/utilitiesService';
import { AppService } from './services/app.service';
import { AuthService } from './services/auth.service';
import { GoogleFontsService } from './services/google-fonts.service';
import { SocialPageService } from './services/social-page.service';
import { GuradService } from './services/auth-guard.service';
import { CustomerService } from './services/customer/customer.service';
import { CartService } from './services/orders/cart.service';
import { OrderService } from './services/orders/order.service';
import { NotFoundGuard } from './services/notfound.guard';
import { AuthComponent } from './auth/auth.component';
import { NotificationService } from './services/notification/notification.service';
import { CampaignsService } from './services/marketing/campaigns.service';
import { TranslationEditorService } from './services/extras/translationEditor.service';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ShopifyService } from './services/shopify/shopify.service';
import { CoreService } from './services/core-service';
import { JwtModule } from '@auth0/angular-jwt';
import { RolesPermissionService } from './services/user-management/rolesPermission.service';
import { UserService } from './services/user-management/userService';
import { VersionCheckService } from './services/version-check.service';
import { PriceRuleService } from './services/price-rules/price-rules.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { environment } from 'environments/environment';

import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { MarkdownModule } from "ngx-markdown";
import { NgxEchartsModule } from "ngx-echarts";
import { GridsterModule } from "angular-gridster2";
import { ComponentModule } from "./main/components/components.module";
// import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ReviewModule } from "./main/pages/catalogue/review/review.module";
// import { TransactionTypePipe } from "./main/pipes/transaction-type.pipe";
// import { TransactionStatusPipe } from "./main/pipes/transaction-status.pipe";

export function tokenGetter() {
    return localStorage.getItem("access_token");
}
const appRoutes: Routes = [
    {
        path: AppRoutes.Auth,
        component: AuthComponent,
    },
    {
        path: AppRoutes.Login,
        canActivate: [NotFoundGuard],
        data: { title: "Login" },
        loadChildren: () =>
            import("./login/login.module").then((m) => m.LoginModule),
    },

    {
        path: AppRoutes.PagesModule,
        loadChildren: () =>
            import("./main/pages/pages.module").then((m) => m.PagesModule),
    },
    {
        path: "review",
        loadChildren: () =>
            import("./main/pages/catalogue/review/review.module").then(
                (m) => m.ReviewModule
            ),
    },
];

@NgModule({
    declarations: [AppComponent, AuthComponent],
    imports: [
        BrowserModule,
        ComponentModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            useHash: true,
            onSameUrlNavigation: "reload",
            relativeLinkResolution: "legacy",
        }),
        NgxUiLoaderModule,
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production,
        }),
        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        NgxDaterangepickerMd.forRoot(),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
            },
        }),
        // App modules
        LayoutModule,
        MarkdownModule.forRoot({ loader: HttpClient }),
        NgxEchartsModule.forRoot({
            echarts: () => import("echarts"),
        }),
        GridsterModule,

        // ReviewModule
    ],
    bootstrap: [AppComponent],
    providers: [
        PostsService,
        PageService,
        SharedService,
        SliderService,
        WidgetService,
        MediaService,
        NavMenuService,
        UtilitiesService,
        AppService,
        AuthService,
        GuradService,
        CustomerService,
        CartService,
        OrderService,
        NotificationService,
        CampaignsService,
        ShopifyService,
        TranslationEditorService,
        PriceRuleService,
        CoreService,
        RolesPermissionService,
        UserService,
        VersionCheckService,
        GoogleFontsService,
        SocialPageService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
    ],
})
export class AppModule {}
