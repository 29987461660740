export enum PageElements {
    HTML = "htmleditor",
    TEXT = "text",
    BOOLEAN = "boolean",
    NUMBER = "number",
    SELECT = "select",
    MENU = "menu",
    IMAGE_URL = "media_url",
    SLIDER = "slider",
    CAROUSEL = "slider",
    EMBED = "codeeditor",
    GALLERY = "gallery",
    MOBILE_TABS = "menu",
    VIDEO = "media_url",
    PDF = "media_url",
    AUDIO = "media_url",
    TABS = "TABS",
    ACCORDION = "ACCORDION",
    HEADING = "HEADING"
}

export enum SliderElements {
    IMAGE = "IMAGE",
    PRODUCT = "PRODUCT",
    CATEGORY = "CATEGORY",
    BRAND = "BRAND",
    HTML = "HTML",
    POST = "POST",
    RELATED_PRODUCTS = "RELATED PRODUCTS",
    POST_CATEGORY = "POST CATEGORY",
    PRODUCT_SET = "PRODUCT SET",
    RECENTLY_VIEWED_PRODUCTS = "RECENTLY VIEWED PRODUCTS"
}

export interface FormGroupObject {
    order: any;
    language: string;
    id: any;
    show_on: any[];
    isCodeEditor: boolean[];
    isTabChild: boolean[];
    label_ar: any[];
    label: any[];
    parentName: any;
    parentID: any;
    placement: any
    tabs?: any[]; // Optional property 'tabs'
}