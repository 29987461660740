<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page">
    <mat-tab-group fxFlex="1 1 auto">
        <mat-tab label="SEO">
            <div fxFlex="1 1 auto" style="margin-top: 10;" fxLayoutAlign="start stretch" fxLayout="column"
                fxLayoutGap="1em">
                <form class="mt-30" fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                    [formGroup]="analyticForm">
                    <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                        <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                            <!-- <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back</mat-icon> -->
                            <label class=" mat-title highlight-text-color">{{
                                "SEO & Analytics" | translate
                                }}</label>

                        </div>
                        <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                            <button (click)="save()" type="submit" mat-flat-button
                                style="position: absolute; right: 16px" class="primary-bg-color">
                                {{ "Save" | translate }}
                            </button>
                        </div>
                    </div>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" [@animate]="{
                        value: '*',
                        params: { duration: '400ms', y: '70px' }
                    }">
                        <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 center-container"
                            fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                            <label class="highlight-text-color">{{
                                "SEO & Analytics Settings" | translate
                                }}</label>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                                    fxLayout.lt-md="column" fxLayoutGap="1em">
                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{'SEO Title (En)'| translate}}
                                            </mat-label>
                                            <input matTooltip="{{
                                                'help_text_seo_title_EN' | translate
                                            }}" formControlName="seo_title_en" matInput name="meta_tag" />
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{'SEO Title (Ar)'| translate}}
                                            </mat-label>
                                            <input matTooltip="{{
                                                'help_text_seo_title_AR' | translate
                                            }}" formControlName="seo_title_ar" matInput name="meta_tag" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                                    fxLayout.lt-md="column" fxLayoutGap="1em">
                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{ "Meta Description (En)" | translate }}
                                            </mat-label>
                                            <input matTooltip="{{
                                            'help_text_meta_description_EN' | translate
                                        }}" formControlName="meta_description_en" matInput
                                                name="meta_description_en" />
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{ "Meta Description (Ar)" | translate }}
                                            </mat-label>
                                            <input matTooltip="{{
                                            'help_text_meta_description_AR' | translate
                                        }}" formControlName="meta_description_ar" matInput
                                                name="meta_description_ar" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                                    fxLayout.lt-md="column" fxLayoutGap="1em">
                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{
                                                "Google Site Verification"
                                                | translate
                                                }}
                                            </mat-label>
                                            <input matTooltip="{{
                                                'help_text_Google_Site_Verification'
                                                    | translate
                                            }}" formControlName="google_site_verification" matInput name="gtm_code" />
                                        </mat-form-field>
                                    </div>

                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{
                                                "Facebook Domain Verification"
                                                | translate
                                                }}
                                            </mat-label>
                                            <input matTooltip="{{
                                                'help_text_Facebook_Domain_Verification'
                                                    | translate
                                            }}" formControlName="facebook_domain_verification" matInput
                                                name="fb_pixel_value" />
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                                    fxLayout.lt-md="column" fxLayoutGap="1em">
                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{ "Facebook Pixel ID" | translate }}
                                            </mat-label>
                                            <input matTooltip="{{
                                                'help_text_Facebook_pixel_id'
                                                    | translate
                                            }}" formControlName="fb_pixel_value" matInput name="fb_pixel_value" />
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="1 1 auto">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{ "GTM Code" | translate }}
                                            </mat-label>
                                            <input matTooltip="{{
                                                'help_text_GTM_Code' | translate
                                            }}" formControlName="gtm_code" matInput name="gtm_code" />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="seo-title-container">
                            <ng-container *ngFor="let item of titleFormat; let i = index">
                                <div class="seo-title-item">
                                    <app-title-format [title]="item.title" [contrl]="analyticForm.get(item.control)">
                                    </app-title-format>
                                </div>
                            </ng-container>
                        </div>


                    </div>
                </form>
            </div>
        </mat-tab>
        <mat-tab label="Redirections">
            <app-page-redirections></app-page-redirections>
        </mat-tab>

    </mat-tab-group>

</div>