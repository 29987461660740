import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { MediaService } from "app/services/media.service";
import { PostsService } from "app/services/posts.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { GalleryPopupComponent } from "../gallery-popup/gallery-popup.component";

@Component({
    selector: "app-create-posts-category",
    templateUrl: "./create-posts-category.component.html",
    styleUrls: ["./create-posts-category.component.scss"],
    animations: fuseAnimations,
})
export class CreatePostsCategoryComponent implements OnInit {
    @Input() id: any = "";
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    form: FormGroup;
    files = [];
    constructor(
        private fb: FormBuilder,
        public utilitiesService: UtilitiesService,
        private mediaService: MediaService,
        private postService: PostsService,
        private translate: TranslateService,
        private dialog: MatDialog
    ) {}

    async ngOnInit(): Promise<void> {
        if (this.id) {
            this.utilitiesService.startLoader();
        }
        this.formInit();
        if (this.id) {
            await this.getCategoryDetail();
        }
    }
    async getCategoryDetail() {
        try {
            const categoryDetails = await this.postService
                .getCategoryDetail(this.id)
                .toPromise();
            if (categoryDetails) {
                await this.setValues(categoryDetails);
                this.utilitiesService.stopLoader();
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }

    openGallery(formControl, multiSelect = false) {
        let isMultiSelect = multiSelect;
        let size = {
            height: "70%",
            width: "70%",
        };
        let isMobile = this.utilitiesService.isMobile();
        if (isMobile) {
            size.height = "80%";
            size.width = "100%";
        }
        const dialogRef = this.dialog.open(GalleryPopupComponent, {
            data: { formControl, isMultiSelect },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });
        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {

            }
        });
    }

    async setValues(catObject) {
        this.form.get("name").setValue(catObject ? catObject.name : null);
        this.form.get("name_en").setValue(catObject ? catObject.name_en : null);
        this.form.get("name_ar").setValue(catObject ? catObject.name_ar : null);
        this.form.get("slug").setValue(catObject ? catObject.slug : null);
        this.form
            .get("thumbnail")
            .setValue(catObject ? catObject.thumbnail : null);
    }

    formInit() {
        this.form = this.fb.group({
            name: ["", [Validators.required]],
            name_en: [""],
            name_ar: [""],
            slug: [""],
            thumbnail: [""],
        });
    }

    setFileToFormThumbail(file){
        this.form.controls.thumbnail.setValue(file.file);
    }


    deleteFileThumbail() {
        this.form.controls.thumbnail.setValue(null);
    }

    handleCancel() {
        this.cancelClick.emit();
    }
    updateCategory(form) {
        try {
            this.postService.updateCategory(form, this.id).subscribe((data) => {
                let successMsg = this.translate.instant(
                    "Category updated successfully"
                );
                this.utilitiesService.showSuccessToast(successMsg);
                this.cancelClick.emit();
            });
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
            // this.isLoading = false;
        }
    }

    saveCategory(form) {
        try {
            this.postService.addCategory(form).subscribe((data) => {
                let successMsg = this.translate.instant(
                    "Category created successfully"
                );
                this.utilitiesService.showSuccessToast(successMsg);
                this.cancelClick.emit();
            });
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
            // this.isLoading = false;
        }
    }

    save() {
        if (this.form.valid) {
            this.utilitiesService.startLoader();
            let form = this.form.value;
            form.name_en = form.name;
            if (!form.thumbnail) {
                delete form.thumbnail;
            }
            if (!this.id) {
                this.saveCategory(form);
            } else {
                this.updateCategory(form);
            }
        }
    }
}
