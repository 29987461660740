<div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
    <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em"
        [formGroup]="editForm" class="center-container">
        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
            <div class="fuse-card auto-width p-16" fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch"
                fxLayoutGap="5px">
                <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                    <label class="highlight-text-color">{{'Basic Information' | translate}}</label>
                </div>
                <div fxFlex="1 1 auto" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutAlign="start stretch"
                    fxLayoutGap="5px">
                    <div fxFlex="1 1 auto">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>
                                {{'First Name' | translate}}</mat-label>
                            <input formControlName="first_name" matInput name="title">
                            <mat-error *ngIf="editForm.get('first_name').hasError('required')">
                                {{'First Name is required' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="1 1 auto">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>
                                {{'Middle Name' | translate}}</mat-label>
                            <input formControlName="middle_name" matInput name="title">
                        </mat-form-field>
                    </div>
                    <div fxFlex="1 1 auto">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>
                                {{'Last Name' | translate}}</mat-label>
                            <input formControlName="last_name" matInput name="title">
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex="1 1 auto" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutAlign="start stretch"
                    fxLayoutGap="5px">
                    <div fxFlex="1 1 100%">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label class="text-capitalized">
                                {{'Gender'| translate}}
                            </mat-label>
                            <mat-select formControlName="gender">
                                <mat-option [value]="1">{{'Male' | translate}}</mat-option>
                                <mat-option [value]="2">{{'Female' | translate}} </mat-option>
                                <mat-option [value]="3">{{'Others' | translate}} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="1 1 100%">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>
                                {{'Phone Number' | translate}}</mat-label>
                            <input formControlName="contact_no" matInput name="title">
                            <mat-error *ngIf="editForm.get('contact_no').hasError('required')">
                                {{'Phone Number is required' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="1 1 100%">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>
                                {{'Email' | translate}}</mat-label>
                            <input formControlName="email" matInput name="title">
                            <mat-error *ngIf="editForm.get('email').hasError('required')">
                                {{'Email is required' | translate}}
                            </mat-error>
                            <mat-error *ngIf="editForm.get('email').hasError('email')">
                                {{'Invalid email' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="fuse-card auto-width p-16" fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch"
                fxLayoutGap="5px">
                <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                    <label class="highlight-text-color">{{'Additional Information' | translate}}</label>
                </div>
                <div *ngIf="dynamicFieldList?.length > 0 && isReady" fxFlex="1 1 auto" fxLayout="column"
                    fxLayoutAlign="start stretch">
                    <div *ngFor="let dynamicField of dynamicFieldList" fxFlex="1 1 auto" fxLayout="column"
                        fxLayoutAlign="start stretch">
                        <div class="pt-8 pb-8" *ngIf="dynamicField?.type =='checkbox'" fxFlex="1 1 auto" fxLayout="row wrap"
                            fxLayoutAlign="start stretch" fxLayoutGap="5px">
                            <ng-container *ngIf="dynamicField?.possible_values?.length > 0">
                                <div fxFlex="1 1 auto" fxLayout="row wrap" fxLayoutAlign="start stretch"
                                    fxLayoutGap="1em">
                                    <ng-container *ngFor="let checkbox of dynamicField?.possible_values">
                                        <mat-checkbox [ngModelOptions]="{standalone: true}" fxFlex="0 0 auto"
                                            fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayoutGap="1em"
                                            [(ngModel)]="checkboxModel[dynamicField?.name][checkbox?.id]"
                                            (change)="checkboxChanged(dynamicField?.name,$event,checkbox?.id)">
                                            {{checkbox?.name_en}}
                                        </mat-checkbox>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="dynamicField?.type =='select'" fxFlex="1 1 auto" fxLayout="row wrap"
                            fxLayoutAlign="start stretch" fxLayoutGap="5px">
                            <ng-container *ngIf="dynamicField?.possible_values?.length > 0">
                                <div fxFlex="1 1 100%">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{dynamicField?.label_en}}
                                        </mat-label>
                                        <mat-select formControlName="{{dynamicField?.name}}">
                                            <mat-option *ngFor="let selectValue of dynamicField?.possible_values"
                                                [value]="selectValue?.id">
                                                {{selectValue?.name_en}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                        </div>
                        <div class="pt-8 pb-8" *ngIf="dynamicField?.type =='radio'" fxFlex="1 1 auto" fxLayout="row wrap"
                            fxLayoutAlign="start stretch" fxLayoutGap="5px">
                            <ng-container *ngIf="dynamicField?.possible_values?.length > 0">
                                <ng-container *ngIf="dynamicField?.possible_values?.length > 0">
                                    <mat-radio-group formControlName="{{dynamicField?.name}}" fxFlex="1 1 auto"
                                        fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayoutGap="1em">
                                        <mat-radio-button fxFlex="0 0 auto" fxLayout="row wrap"
                                            fxLayoutAlign="start stretch" fxLayoutGap="1em"
                                            *ngFor="let radio of dynamicField?.possible_values" [value]="radio?.id">
                                            {{radio?.name_en}}</mat-radio-button>
                                    </mat-radio-group>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div *ngIf="dynamicField?.type =='date'" fxFlex="1 1 auto" fxLayout="row wrap"
                            fxLayoutAlign="start stretch" fxLayoutGap="5px">
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label >{{dynamicField?.label_en}}</mat-label>
                                <input formControlName="{{dynamicField?.name}}" matInput [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div *ngIf="dynamicField?.type =='number'" fxFlex="1 1 auto" fxLayout="row wrap"
                            fxLayoutAlign="start stretch" fxLayoutGap="5px">
                            <div fxFlex="1 1 100%">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label> {{dynamicField?.label_en}}</mat-label>
                                    <input formControlName="{{dynamicField?.name}}" type="number" matInput name="title">
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="dynamicField?.type =='text'" fxFlex="1 1 auto" fxLayout="row wrap"
                            fxLayoutAlign="start stretch" fxLayoutGap="5px">
                            <div fxFlex="1 1 100%">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label> {{dynamicField?.label_en}}</mat-label>
                                    <input formControlName="{{dynamicField?.name}}" type="text" matInput name="title">
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="dynamicField?.type =='textarea'" fxFlex="1 1 auto" fxLayout="row wrap"
                            fxLayoutAlign="start stretch" fxLayoutGap="5px">
                            <div fxFlex="1 1 100%">
                                <mat-form-field appearance="outline" fxFlex="100" class="example-full-width">
                                    <mat-label>{{dynamicField?.label_en}}</mat-label>
                                    <textarea formControlName="{{dynamicField?.name}}" matInput rows="4"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px">
                <div>
                    <button (click)="cancel()" mat-button color="accent"
                        class="mat-focus-indicator mat-raised-button mat-button-base mat-warn">
                        <mat-icon class="save-cancel-btn" matPrefix>close</mat-icon> {{'Cancel' | translate}}
                    </button>
                </div>
                <div>
                    <button (click)="save()" mat-button color="accent"
                        class="mat-focus-indicator mat-raised-button mat-button-base mat-primary">
                        <mat-icon class="save-cancel-btn" matPrefix>save</mat-icon> {{'Save' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>