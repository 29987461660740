<div class="row custom-form" [formGroup]="customFormGroup">
    <ng-container *ngFor="let section of formData">
        <div class="col" *ngIf="section?.show">
            <fieldset style="margin-bottom: 30px">
                <legend class="highlight-text-color text-bold">
                    {{ section.name | translate }}
                </legend>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="m-w-full" [ngStyle]="{
                            padding: dField?.padding,
                            flex: dField?.fxFlex,
                            width: dField?.width || ''
                        }" *ngFor="let dField of section.fields; let i = index">
                        <ng-container>
                            <mat-form-field appearance="outline"
                                *ngIf="dField?.show && dField.type === dynamicFieldTypes.Text"
                                class="dynamic-field-input-w fuse-mat-dense">
                                <mat-label>
                                    {{ dField?.label }}
                                    <span class="text-red-600" *ngIf="validator(dField?.name)">*</span>
                                </mat-label>
                                <input matInput type="text" [placeholder]="dField?.label"
                                    [formControlName]="dField.name" />
                                <mat-error *ngIf="validator(dField?.name)">
                                    {{ dField?.label }} is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline"
                                *ngIf="dField?.show && dField.type === dynamicFieldTypes.Email"
                                class="dynamic-field-input-w fuse-mat-dense">
                                <mat-label>
                                    {{ dField?.label }}
                                    <span class="text-red-600" *ngIf="validator(dField?.name)">*</span></mat-label>
                                <input matInput type="email" [placeholder]="dField?.label"
                                    [formControlName]="dField.name" />
                                <mat-error *ngIf="validator(dField?.name)">
                                    {{ dField?.label }} is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline"
                                *ngIf="dField?.show && dField.type === dynamicFieldTypes.Number"
                                class="dynamic-field-input-w fuse-mat-dense">
                                <mat-label>
                                    {{ dField?.label }}
                                    <span class="text-red-600" *ngIf="validator(dField?.name)">*</span>
                                </mat-label>
                                <input matInput type="number" min="0" [placeholder]="dField?.label"
                                    [formControlName]="dField.name" />
                                <mat-error *ngIf="validator(dField?.name)">
                                    {{ dField?.label }} is required
                                </mat-error>
                            </mat-form-field>
                            <!-- pas 
 -->

                            <div class="select-field-container mt-5" *ngIf="
 dField?.show && dField.type === dynamicFieldTypes.Password
" class="dynamic-field-input-w fuse-mat-dense align-center" fxLayoutAlign="center" fxLayoutGap="10px"
                                style="align-items: center">
                                <div fxLayout="column" class="select-field  " style="width: 100%">
                                    <mat-form-field fxLayout="row" appearance="outline" *ngIf="
                                    dField?.show && dField.type === dynamicFieldTypes.Password
                                " class="dynamic-field-input-w fuse-mat-dense align-center password"
                                        fxLayoutAlign="center" fxLayoutGap="10px" style="align-items: center">
                                        <mat-label>
                                            {{ dField?.label }}
                                            <span class="text-red-600" *ngIf="validator(dField?.name)">*</span>
                                        </mat-label>
                                        <input matTooltip="{{
                                            'the password will not show' | translate
                                        }}" [readonly]="customFormGroup.get('id')?.value?true:false" matInput
                                            type="password" [formControlName]="dField.name"
                                            [placeholder]="dField.label" />
                                        <mat-error *ngIf="validator(dField?.name)">
                                            {{ dField?.label }} is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="reset-password-icon" *ngIf="dField?.add">
                                    <mat-icon style="font-size: 14px" (click)="addData(dField?.name)"
                                        title="Change Password" matPostfix>lock_open
                                    </mat-icon>
                                </div>
                            </div>


                            <mat-form-field appearance="outline"
                                *ngIf="dField?.show && dField.type === dynamicFieldTypes.Select"
                                class="dynamic-field-input-w fuse-mat-dense">
                                <mat-label>
                                    {{ dField?.label }}
                                    <span class="text-red-600" *ngIf="validator(dField?.name)">*</span>
                                </mat-label>
                                <mat-select [formControlName]="dField.name">
                                    <ng-container *ngIf="
                                            dField.possible_values &&
                                                dField.possible_values.length;
                                            else testOption
                                        ">
                                        <mat-option *ngFor="
                                                let obj of dField.possible_values;
                                                let i = index
                                            " [value]="obj.id">
                                            {{ obj?.name }}
                                        </mat-option>
                                    </ng-container>
                                    <ng-template #testOption>
                                        <mat-option>{{ "test1" }}</mat-option>
                                        <mat-option>{{ "test2" }}</mat-option>
                                        <mat-option>{{ "test3" }}</mat-option>
                                    </ng-template>
                                </mat-select>
                                <mat-error *ngIf="validator(dField?.name)">
                                    {{ dField?.label }} is required
                                </mat-error>
                            </mat-form-field>

                            <div class="select-field-container" *ngIf="
                                    dField?.show && dField.type ===
                                        dynamicFieldTypes.SearchableSelect &&
                                    dField?.api_endpoint
                                " class="dynamic-field-input-w fuse-mat-dense align-center" fxLayoutAlign="center"
                                fxLayoutGap="10px" style="align-items: center">
                                <div fxLayout="column" class="select-field" style="width: 100%">
                                    <label>{{ dField?.label }}</label>
                                    <ng-select [formControlName]="dField?.name" [placeholder]="dField.label"
                                        id="products" fxFlex="100" (input)="
                                            inputValue(
                                                $event.target.value,
                                                dField.api_endpoint,
                                                dField
                                            )
                                        " [multiple]="dField?.multiple">
                                        <ng-option *ngFor="
                                                let product of dField?.possible_values
                                            " [value]="product?.id">
                                            {{ product.name }}
                                        </ng-option>
                                    </ng-select>
                                </div>

                                <div class="add-icon" *ngIf="dField?.add">
                                    <mat-icon style="font-size: 20px" (click)="addData(dField?.name)"
                                        title="View Detail" matPostfix>add
                                    </mat-icon>
                                </div>
                            </div>
                            <mat-form-field appearance="outline" *ngIf="
                                    dField?.show && dField.type === dynamicFieldTypes.TextArea
                                " class="dynamic-field-input-w fuse-mat-textarea">
                                <mat-label>
                                    {{ dField?.label }}
                                    <span class="text-red-600" *ngIf="validator(dField?.name)">*</span>
                                </mat-label>
                                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3"
                                    [placeholder]="dField?.label" [formControlName]="dField.name"></textarea>
                                <mat-error *ngIf="validator(dField?.name)">
                                    {{ dField?.label }} is required
                                </mat-error>
                            </mat-form-field>
                            <ng-container *ngIf="
                                    dField?.show && dField.type === dynamicFieldTypes.TextEditor
                                ">
                                <div fxFlex="1 1 100%" fxLayout="column" class="mb-8">
                                    <mat-slide-toggle class="mb-8 align-self" fxFlex="0 0 auto"
                                        fxLayoutAlign="start end" fxLayout="column" [formControlName]="
                                            getFormControl(dField.name)
                                        ">
                                        {{
                                        !customFormGroup.get(
                                        getFormControl(dField.name)
                                        ).value
                                        ? ("Switch to Code Editor"
                                        | translate)
                                        : ("Switch to HTML Editor"
                                        | translate)
                                        }}
                                    </mat-slide-toggle>
                                    <label class="font-11" for="">{{
                                        dField?.label
                                        }}</label>
                                    <ckeditor [@animate]="{
                                            value: '*',
                                            params: {
                                                duration: '300ms',
                                                y: '50px'
                                            }
                                        }" *ngIf="
                                            !customFormGroup.get(
                                                getFormControl(dField.name)
                                            ).value
                                        " [formControlName]="dField.name" ngDefaultControl name="myckeditor"
                                        [config]="ckeConfig">
                                    </ckeditor>
                                    <div fxFlex="0 0 auto" fxLayout="column" *ngIf="
                                            customFormGroup.get(
                                                getFormControl(dField.name)
                                            ).value
                                        ">
                                        <mat-icon style="align-self: flex-end"
                                            class="zoom-icon cursor-pointer highlight-text-color"
                                            (click)="zoomAdditionalCss(dField)">
                                            zoom_out_map</mat-icon>
                                        <ace-editor [@animate]="{
                                                value: '*',
                                                params: {
                                                    duration: '300ms',
                                                    y: '50px'
                                                }
                                            }" [theme]="'clouds'" [mode]="'html'" [options]="options"
                                            [formControlName]="dField.name" style="height: 50vh">
                                        </ace-editor>
                                    </div>
                                    <!-- <div style="font-size: 10px;color: red;"
                                        *ngIf="campaignForm.get('message_email').hasError('required')">
                                        {{'Message is required' | translate}}
                                    </div> -->
                                </div>
                            </ng-container>
                            <div *ngIf="dField?.show && dField.type === dynamicFieldTypes.Radio"
                                class="dynamic-field-input-w fuse-mat-dense flex-auto">
                                <mat-label>{{ dField?.label
                                    }}<span class="text-red-600" *ngIf="validator(dField?.name)">*</span></mat-label>
                                <mat-radio-group aria-label="Select an option" class="flex flex-col space-y-3 mt-1 mb-4"
                                    [formControlName]="dField.name">
                                    <mat-radio-button>
                                        {{ "Select" }}
                                    </mat-radio-button>
                                    <mat-error>
                                        {{ dField?.label }} is required
                                    </mat-error>
                                </mat-radio-group>
                            </div>
                            <div *ngIf="
                                    dField?.show && dField.type === dynamicFieldTypes.Checkbox
                                " class="w-full fuse-mat-dense flex-auto">
                                <mat-checkbox [formControlName]="dField.name" [value]="dField.default">
                                    {{ dField?.label }}
                                    <span class="text-red-600" *ngIf="validator(dField?.name)">*</span>
                                </mat-checkbox>
                                <mat-error *ngIf="validator(dField?.name)">
                                    {{ dField?.label }} is required
                                </mat-error>
                            </div>
                            <div *ngIf="dField?.show && dField.type === dynamicFieldTypes.Toggle"
                                class="w-full fuse-mat-dense flex-auto">
                                <mat-slide-toggle [formControlName]="dField.name">
                                    {{ dField?.label }}</mat-slide-toggle>
                                <mat-error *ngIf="validator(dField?.name)">
                                    {{ dField?.label }} is required
                                </mat-error>
                            </div>
                            <div *ngIf="dField?.show && dField.type === dynamicFieldTypes.File"
                                class="w-full fuse-mat-dense flex-auto">
                                <div class="flex flex-col flex-auto">
                                    <p class="text-lg font-medium mb-4 mt-2 text-primary-500 uppercase">
                                        {{ "Documents" | translate }}
                                    </p>
                                    <div class="flex flex-row flex-auto">
                                        <div class="flex flex-col flex-auto gap-y-1">
                                            <app-documents [id]="id" [documents]="documents" [file]="dField.attachment">
                                            </app-documents>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <mat-form-field appearance="outline" class="dynamic-field-input-w fuse-mat-dense"
                                *ngIf="dField?.show && dField.type === dynamicFieldTypes.Date">
                                <mat-label>
                                    {{ dField?.label }}
                                    <span class="text-red-600" *ngIf="validator(dField?.name)">*</span>
                                </mat-label>
                                <input [min]="dField?.min_date" [max]="dField?.max_date" (focus)="picker.open()"
                                    [formControlName]="dField.name" matInput [matDatepicker]="picker" />
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error *ngIf="validator(dField?.name)">
                                    {{ dField?.label }} is required
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                    </div>
                </div>
            </fieldset>
        </div>
    </ng-container>
</div>