import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { KeyValue } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { MediaTypes, MediaTypesAccept } from "app/constants/media-type";
import { PageElements, FormGroupObject } from "app/constants/types";
import { MediaService } from "app/services/media.service";
import { NavMenuService } from "app/services/nav-menu.service";
import { PageService } from "app/services/pageService";
import { SliderService } from "app/services/sliderService";
import { UtilitiesService } from "app/services/utilitiesService";
import { isNumber } from "lodash";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
import { FullscreenAdditionCssComponent } from "../fullscreen-addition-css/fullscreen-addition-css.component";
import { GalleryPopupComponent } from "../gallery-popup/gallery-popup.component";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CreateSliderComponent } from "../create-sliders/create-sliders.component";
import { ApiService } from "app/services/api.service";

@Component({
  selector: "app-create-page",
  templateUrl: "./create-page.component.html",
  styleUrls: ["./create-page.component.scss"],
  animations: fuseAnimations,
})
export class CreatePageComponent implements OnInit {
  @Input() elements: any;
  @Input() id: any = "";
  @Input() productId: any = "";
  @Input() categorytId: any = "";
  @Input() brandId: any = "";
  @Input() productRowData: any;
  @Input() categoryRowData: any;
  @Input() brandRowData: any;
  @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
  @Output() createPageEvent: EventEmitter<void> = new EventEmitter<any>();

  selectValues = [];
  selectedShow_on = [];
  pageName: string = "";
  expandIndex = [];
  isExpand = true;
  slugChanged$ = new Subject();
  status = [
    { id: "DRAFT", name: "Draft" },
    { id: "PUBLISHED", name: "Published" },
    { id: "ARCHIVED", name: "Archived" },
  ];
  show_on = [
    {
      id: 1,
      name: "All",
      icon: "assets/icons/show_on/select-all.svg",
      selected: "",
    },
    {
      id: 2,
      name: "Mobile Web",
      icon: "assets/icons/show_on/mobile-web.svg",
      selected: "",
    },
    {
      id: 3,
      name: "Desktop",
      icon: "assets/icons/show_on/desktop.svg",
      selected: "",
    },
    {
      id: 4,
      name: "Mobile App",
      icon: "assets/icons/show_on/mobile-app.svg",
      selected: "",
    },
  ];
  options: any = {
    showLineNumbers: true,
    tabSize: 2,
  };
  pageForm: FormGroup;
  configurations: {
    input: string;
    name: string;
    required: boolean;
    type: any;
    show_only?: any;
    media_type?: any;
    translate?: boolean;
    possible_values?: {
      [key: string]: number;
    }[];
  }[] = [];
  typeEnum = PageElements;
  ckeConfig: any;
  pageDetail: any;
  files = [];
  slider = [];
  menu = [];
  elementsArray = [];
  submitted = false;
  isLoading: boolean = false;
  mediaType = MediaTypes;
  mediaTypesAccept = MediaTypesAccept;
  productPageId: number;
  baseUrl = document.location.origin + "/";
  pageTemplate$: Observable<any> = this.pageService.getPageTemplate();
  showSideClickIcon: { show: boolean; index: number }[] = [];
  tabsElements = [];
  finalPageElements = [];
  isUpdate = false;

  get showSlug() {
    return !!this.id;
  }

  constructor(
    private pageService: PageService,
    private translate: TranslateService,
    private fb: FormBuilder,
    public utilitiesService: UtilitiesService,
    private dialog: MatDialog,
    private sliderService: SliderService,
    private navMenuService: NavMenuService,
    private mediaService: MediaService,
    private sanitizer: DomSanitizer,
    private apiService: ApiService
  ) {
    this.configurations = [];
  }

  async ngOnInit(): Promise<void> {
    if (this.id || this.productId || this.brandId || this.categorytId) {
      this.utilitiesService.startLoader();
    }

    await this.formInitialize();
    this.ckeConfig = {
      sanitize: false,
      versionCheck: false,
      allowedContent: true,
      removeButtons: "CreateDiv,BlockQuote,Superscript,Subscript,PasteFromWord,PasteText,Source",
    };
    await this.getSliderList();
    await this.getMenuList();

    if (this.id) {
      await this.getPageDetail();
    } else if (this.productId) {
      await this.getProductPageDetail(this.productId, "product");
    } else if (this.categorytId) {
      await this.getProductPageDetail(this.categorytId, "category");
    } else if (this.brandId) {
      await this.getProductPageDetail(this.brandId, "brand");
    } else {
      // Adding same value to slug field as we have in title field
      this.pageForm
        .get("title")
        .valueChanges.pipe(takeUntil(this.slugChanged$))
        .subscribe((titleValue) => {
          let modifiedTitle = titleValue.trim().replace(/\s+/g, "-");
          this.pageForm.get("slug").setValue(modifiedTitle, { emitEvent: false });
        });

      // Stop adding title value to slug field once slug value get change
      this.pageForm.get("slug").valueChanges.subscribe((slugValue) => {
        this.slugChanged$.next();
        this.slugChanged$.complete();
      });
    }
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  getFormControlType(key: string) {
    if (key) {
      let controlType = "";
      if (key) {
        if (key == "language") {
          controlType = "language";
        } else if (key == "order") {
          controlType = "order";
        } else if (key == "show_on") {
          controlType = "show_on";
        } else if (key == "placement") {
          controlType = "placement";
        } else if (key == "id") {
          controlType = "";
        } else if (key == "label") {
          controlType = "label";
        } else if (key == "label_ar") {
          controlType = "label_ar";
        } else if (key == "isCodeEditor") {
          controlType = "";
        } else if (key == "isTabChild") {
          controlType = "";
        } else if (key == "parentName") {
          controlType = "";
        } else if (key == "parentID") {
          controlType = "";
        } else if (key == "redirection_path") {
          controlType = "redirection_path";
        } else if (key == "responsive_behaviour") {
          controlType = "responsive_behaviour";
        } else if (key == "heading_tag") {
          controlType = "heading_tag";
        } else {
          controlType = this.configurations.find((element) => element.name == key).input;
        }
        return controlType;
      }
    }
  }

  getFormControlLabel(key: string) {
    let controlName = "";
    if (key) {
      if (key == "language") {
        controlName = "Language";
      } else if (key == "order") {
        controlName = "Order";
      } else if (key == "show_on") {
        controlName = "Show On";
      } else if (key == "placement") {
        controlName = "placement";
      } else if (key == "id") {
        controlName = "";
      } else if (key == "label") {
        controlName = "Label";
      } else if (key == "label_ar") {
        controlName = "Label Ar";
      } else if (key == "isCodeEditor") {
        controlName = "";
      } else if (key == "isTabChild") {
        controlName = "";
      } else if (key == "parentName") {
        controlName = "";
      } else if (key == "parentID") {
        controlName = "";
      } else if (key == "redirection_path") {
        controlName = "Redirection Path";
      } else if (key == "heading_tag") {
        controlName = "heading_tag";
      } else {
        controlName = this.configurations.find((element) => element.name == key)?.name;
      }
      return controlName;
    }
  }

  isMenu(key: string) {
    if (key && key != "language") {
      const controlName = this.configurations.find((element) => element.name == key).type;
      return controlName ? false : true;
    }
  }

  getObjectType(type) {
    let object_type;

    switch (type) {
      case "product": {
        object_type = 1;
        break;
      }
      case "category": {
        object_type = 2;
        break;
      }
      case "brand": {
        object_type = 3;
        break;
      }
    }

    return object_type;
  }

  async getProductPageDetail(id, type) {
    try {
      const object_type = this.getObjectType(type);

      const pageDetail = await this.pageService.getProductPageDetails(id, type).toPromise();

      if (pageDetail) {
        this.pageDetail = pageDetail.results[0];
        if (this.pageDetail) {
          this.setValue();
        } else {
          // await this.getProductPageInfo(id, object_type);
          this.pageForm.patchValue({
            title: this.categoryRowData?.name || this.productRowData?.name || this.brandRowData?.name,
          });
          this.pageForm.patchValue({
            title_ar: this.categoryRowData?.name_ar || this.productRowData?.name_ar || this.brandRowData?.name,
          });
        }
        this.productPageId = this.pageDetail.id;

        if (this.pageDetail.page_element.length > 0) {
          await this.getTabsELements();

          let formArray = await this.createFormGroupForEdit();
          formArray.forEach((data) => {
            let formGroup = this.fb.group(data);
            (this.pageForm.get("configurations") as FormArray).push(formGroup);
          });
        } else {
          (this.pageForm.get("configurations") as FormArray).clear();
        }
      } else {
        console.log("now setting " + this.pageName);
      }
    } catch {
    } finally {
      this.utilitiesService.stopLoader();
    }
  }

  async getPageDetail(isDelete = false) {
    if (isDelete) {
      (this.pageForm.controls["configurations"] as FormArray).clear();
    }
    try {
      const pageDetail = await this.pageService.getPageDetail(this.id).toPromise();
      if (pageDetail) {
        this.pageDetail = pageDetail;
        this.setValue();
        if (this.pageDetail.page_element.length > 0) {
          await this.getTabsELements();
          let formArray = await this.createFormGroupForEdit();
          formArray.forEach((data) => {
            let formGroup = this.fb.group(data);
            (this.pageForm.get("configurations") as FormArray).push(formGroup);
          });
        } else {
          (this.pageForm.get("configurations") as FormArray).clear();
        }
      }
    } catch {
    } finally {
      this.utilitiesService.stopLoader();
    }
  }

  async getProductPageInfo(id, object_type) {
    try {
      const pageDetail = await this.pageService.getProductPageInfo(id, object_type).toPromise();
      if (pageDetail) {
        console.log(" hello" + pageDetail.results[0].name);
        this.pageName = pageDetail.results[0].name;
        this.pageForm.patchValue({
          title: this.pageName,
        });
        this.pageForm.patchValue({
          title_ar: this.pageName,
        });
      }
    } catch {
    } finally {
      this.utilitiesService.stopLoader();
    }
  }

  getTabsELements() {
    let pageElements = this.pageDetail.page_element;
    let newArray = [...pageElements];
    let index = 0;
    for (let i = 0; i < pageElements.length; i++) {
      if (pageElements[i].value.parentName === "TABS" || pageElements[i].value.parentName === "ACCORDION") {
        if (index === 0) {
          newArray.splice(i + 1, 0, ...pageElements[i].tabs);
        } else if (i === pageElements.length - 1) {
          newArray.splice(newArray.length, 0, ...pageElements[i].tabs);
        } else {
          newArray.splice(newArray.length - 1, 0, ...pageElements[i].tabs);
        }
        index++;
      }
    }

    this.setTabsClass(newArray);
    this.pageDetail.page_element = [...newArray];
  }

  setTabsClass(elementsArr) {
    for (let i = 0; i < elementsArr.length; i++) {
      if (elementsArr[i].value.isTabChild === true) {
        this.showSideClickIcon.push({ show: true, index: i });
      } else {
        const show = this.isParentIsTab(i, elementsArr);
        this.showSideClickIcon.push({ show: show, index: i });
      }
    }

    this.isUpdate = true;
  }

  async createFormGroupForEdit() {
    let finalArray = [];
    let transaltedConfig = [];
    let selectValues = [];

    this.pageDetail.page_element.forEach((data) => {
      let formGroupObject: FormGroupObject = {
        order: data.order,
        language: "en",
        id: data.id,
        show_on: [data.show_on],
        placement: [data?.placement],
        isCodeEditor: [false],
        isTabChild: [data.value.isTabChild],
        label_ar: [data.label_ar],
        label: [data.label],
        parentName: data.element_type.name,
        parentID: data.element_type.id,
      };

      if (data.element_type.name === PageElements.HEADING) {
        const propertiesToDelete = ["label", "label_ar", "redirection_path"];
        propertiesToDelete.forEach((property) => {
          if (formGroupObject.hasOwnProperty(property)) {
            delete formGroupObject[property];
          }
        });
      }

      if (data.element_type.configurations) {
        data.element_type.configurations.forEach(async (configuration) => {
          let stripedKey = configuration.name.replace("_ar", "");
          if (configuration.translate) {
            transaltedConfig.push(`${configuration.name}_ar`);
            formGroupObject = {
              ...formGroupObject,
              [configuration.name]: [
                data.value[configuration.name] ? data.value[configuration.name] : null,
                Validators.compose(configuration.required ? [Validators.required] : []),
              ],
            };
          } else if (transaltedConfig.find((res) => res == configuration.name)) {
            formGroupObject = {
              ...formGroupObject,
              [configuration.name]: [
                data.value_ar[stripedKey] ? data.value_ar[stripedKey] : null,
                Validators.compose(configuration.required ? [Validators.required] : []),
              ],
            };
          } else {
            if (configuration.input === "select") {
              selectValues = [];
              configuration.possible_values.forEach((el) => {
                selectValues.push(el);
              });

              this.getSelectValues(selectValues);
            }

            formGroupObject = {
              ...formGroupObject,
              [configuration.name]: [
                data.value[configuration.name] ? data.value[configuration.name] : null,
                Validators.compose(configuration.required ? [Validators.required] : []),
              ],
            };
          }

          this.configurations.push(configuration);
          // if (
          //     configuration.name.toLowerCase() == "html" ||
          //     configuration.name.toLowerCase() == "html_ar" ||
          //     configuration.name.toLowerCase() == "image" ||
          //     configuration.name.toLowerCase() == "image_ar" ||
          //     configuration.name.toLowerCase() == "embed" ||
          //     configuration.name.toLowerCase() == "embed_ar" ||
          //     configuration.name.toLowerCase() == "video" ||
          //     configuration.name.toLowerCase() == "video_ar" ||
          //     configuration.name.toLowerCase() == "audio" ||
          //     configuration.name.toLowerCase() == "audio_ar" ||
          //     configuration.name.toLowerCase() == "pdf" ||
          //     configuration.name.toLowerCase() == "pdf_ar"
          // ) {
          //     if (configuration.name.toLowerCase() == "html") {
          //         formGroupObject = {
          //             ...formGroupObject,
          //             [configuration.name]: [
          //                 data.value[configuration.name]
          //                     ? data.value[configuration.name]
          //                     : null,
          //                 Validators.compose(
          //                     configuration.required
          //                         ? [Validators.required]
          //                         : []
          //                 ),
          //             ],
          //         };
          //     }
          //     if (configuration.name.toLowerCase() == "html_ar") {
          //         formGroupObject = {
          //             ...formGroupObject,
          //             [configuration.name]: [
          //                 data.value_ar["html"]
          //                     ? data.value_ar["html"]
          //                     : null,
          //                 Validators.compose(
          //                     configuration.required
          //                         ? [Validators.required]
          //                         : []
          //                 ),
          //             ],
          //         };
          //     }
          //     if (configuration.name.toLowerCase() == "image") {
          //         formGroupObject = {
          //             ...formGroupObject,
          //             [configuration.name]: [
          //                 data.value[configuration.name]
          //                     ? data.value[configuration.name]
          //                     : null,
          //                 Validators.compose(
          //                     configuration.required
          //                         ? [Validators.required]
          //                         : []
          //                 ),
          //             ],
          //         };
          //     }
          //     if (configuration.name.toLowerCase() == "image_ar") {
          //         formGroupObject = {
          //             ...formGroupObject,
          //             [configuration.name]: [
          //                 data.value_ar["image"]
          //                     ? data.value_ar["image"]
          //                     : null,
          //                 Validators.compose(
          //                     configuration.required
          //                         ? [Validators.required]
          //                         : []
          //                 ),
          //             ],
          //         };
          //     }
          //     if (configuration.name.toLowerCase() == "embed") {
          //         formGroupObject = {
          //             ...formGroupObject,
          //             [configuration.name]: [
          //                 data.value[configuration.name]
          //                     ? data.value[configuration.name]
          //                     : null,
          //                 Validators.compose(
          //                     configuration.required
          //                         ? [Validators.required]
          //                         : []
          //                 ),
          //             ],
          //         };
          //     }
          //     if (configuration.name.toLowerCase() == "embed_ar") {
          //         formGroupObject = {
          //             ...formGroupObject,
          //             [configuration.name]: [
          //                 data.value_ar["embed"]
          //                     ? data.value_ar["embed"]
          //                     : null,
          //                 Validators.compose(
          //                     configuration.required
          //                         ? [Validators.required]
          //                         : []
          //                 ),
          //             ],
          //         };
          //     }
          //     if (configuration.name.toLowerCase() == "video") {
          //         formGroupObject = {
          //             ...formGroupObject,
          //             [configuration.name]: [
          //                 data.value[configuration.name]
          //                     ? data.value[configuration.name]
          //                     : null,
          //                 Validators.compose(
          //                     configuration.required
          //                         ? [Validators.required]
          //                         : []
          //                 ),
          //             ],
          //         };
          //     }
          //     if (configuration.name.toLowerCase() == "video_ar") {
          //         formGroupObject = {
          //             ...formGroupObject,
          //             [configuration.name]: [
          //                 data.value_ar["video"]
          //                     ? data.value_ar["video"]
          //                     : null,
          //                 Validators.compose(
          //                     configuration.required
          //                         ? [Validators.required]
          //                         : []
          //                 ),
          //             ],
          //         };
          //     }
          //     if (configuration.name.toLowerCase() == "pdf") {
          //         formGroupObject = {
          //             ...formGroupObject,
          //             [configuration.name]: [
          //                 data.value[configuration.name]
          //                     ? data.value[configuration.name]
          //                     : null,
          //                 Validators.compose(
          //                     configuration.required
          //                         ? [Validators.required]
          //                         : []
          //                 ),
          //             ],
          //         };
          //     }
          //     if (configuration.name.toLowerCase() == "pdf_ar") {
          //         formGroupObject = {
          //             ...formGroupObject,
          //             [configuration.name]: [
          //                 data.value_ar["pdf"]
          //                     ? data.value_ar["pdf"]
          //                     : null,
          //                 Validators.compose(
          //                     configuration.required
          //                         ? [Validators.required]
          //                         : []
          //                 ),
          //             ],
          //         };
          //     }
          //     if (configuration.name.toLowerCase() == "audio") {
          //         formGroupObject = {
          //             ...formGroupObject,
          //             [configuration.name]: [
          //                 data.value[configuration.name]
          //                     ? data.value[configuration.name]
          //                     : null,
          //                 Validators.compose(
          //                     configuration.required
          //                         ? [Validators.required]
          //                         : []
          //                 ),
          //             ],
          //         };
          //     }
          //     if (configuration.name.toLowerCase() == "audio_ar") {
          //         formGroupObject = {
          //             ...formGroupObject,
          //             [configuration.name]: [
          //                 data.value_ar["audio"]
          //                     ? data.value_ar["audio"]
          //                     : null,
          //                 Validators.compose(
          //                     configuration.required
          //                         ? [Validators.required]
          //                         : []
          //                 ),
          //             ],
          //         };
          //     }
          // } else {
          //     formGroupObject = {
          //         ...formGroupObject,
          //         [configuration.name]: [
          //             data.value[configuration.name]
          //                 ? data.value[configuration.name]
          //                 : null,
          //             Validators.compose(
          //                 configuration.required
          //                     ? [Validators.required]
          //                     : []
          //             ),
          //         ],
          //     };
          // }
        });
      }

      finalArray.push(formGroupObject);
    });
    return finalArray;
  }

  async getSliderList() {
    try {
      const sliderList = await this.sliderService.getSliders(99999, 0).toPromise();
      if (sliderList) {
        this.slider = sliderList.results;
      }
    } catch {
    } finally {
    }
  }

  async getMenuList() {
    try {
      const menuList = await this.navMenuService.getMenuList(99999, 0).toPromise();
      if (menuList) {
        this.menu = menuList.results;
      }
    } catch {
    } finally {
    }
  }

  getSelectValues(selectValues) {
    const transformedArray = selectValues.map((obj) => {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      return { id: value, name: key };
    });
    this.selectValues = transformedArray;
  }

  containAr(key: string) {
    return key.includes("_ar");
  }

  async drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.elementsArray, event.previousIndex, event.currentIndex);
      if (event.previousIndex > event.currentIndex) {
        const formGroup = (this.pageForm.get("configurations") as FormArray).at(event.previousIndex);
        (this.pageForm.get("configurations") as FormArray).removeAt(event.previousIndex);
        (this.pageForm.get("configurations") as FormArray).insert(event.currentIndex, formGroup);
      }
      if (event.previousIndex < event.currentIndex) {
        const formGroup = (this.pageForm.get("configurations") as FormArray).at(event.currentIndex);
        (this.pageForm.get("configurations") as FormArray).removeAt(event.currentIndex);
        (this.pageForm.get("configurations") as FormArray).insert(event.previousIndex, formGroup);
      }
    } else {
      await this.addField(event.item.data, event.currentIndex);
    }
    await this.pushConfig();
  }

  async addField(fieldType: any, index: number) {
    (this.pageForm.get("configurations") as FormArray).insert(index, this.createFormGroup(fieldType));
    this.elementsArray.splice(index, 0, fieldType);
  }

  async pushConfig() {
    this.showSideClickIcon = [];

    this.elementsArray.forEach((element, index) => {
      if (!this.isUpdate) this.hasTabsBefore(index);

      if (element.configurations) {
        element.configurations.forEach((items) => {
          this.configurations.push(items);
        });
      }
    });

    if (this.isUpdate) {
      for (let v = 0; v < this.pageForm.get("configurations").value.length; v++) {
        this.hasTabsBefore(v);
      }
    }
  }

  hasTabsBefore(index) {
    const hasParentTab = this.isParentIsTab(index, this.pageForm.get("configurations").value);
    this.showSideClickIcon.splice(index, 0, { show: hasParentTab, index: index });
  }

  removeFromTabs(element) {
    element.controls.isTabChild.setValue(false);
  }

  addToTabs(element) {
    element.controls.isTabChild.setValue(true);
  }

  isParentIsTab(index, configurationsArray) {
    let firstTabsElement = null;

    for (let i = index; i >= 0; i--) {
      if (configurationsArray[i].parentName) {
        if (configurationsArray[i].parentName === "TABS" || configurationsArray[i].parentName === "ACCORDION") {
          firstTabsElement = configurationsArray[i];
          break;
        }
      } else if (configurationsArray[i].value.parentName) {
        if (configurationsArray[i].value.parentName === "TABS" || configurationsArray[i].value.parentName === "ACCORDION") {
          firstTabsElement = configurationsArray[i];
          break;
        }
      }
    }

    if (firstTabsElement !== null) {
      return true;
    } else {
      return false;
    }
  }

  handleCancel() {
    this.cancelClick.emit();
  }

  async formInitialize() {
    this.pageForm = this.fb.group({
      id: [null],
      status: ["DRAFT"],
      title: [null, [Validators.required]],
      title_en: [null],
      title_ar: [null],
      meta_title_en: [null],
      meta_title_ar: [null],
      slug: [null],
      meta_description: [null],
      meta_description_en: [null],
      meta_description_ar: [null],
      language: ["en"],
      visibility: [1],
      css: [null],
      template: [1],
      configurations: this.fb.array([]),
    });

    this.pageForm.get("slug").valueChanges.subscribe((slugVal) => {
      const slugifiedValue = this.slugify(slugVal);
      this.pageForm.get("slug").patchValue(slugifiedValue, { emitEvent: false });
    });
  }

  checkFormArrayValidation() {
    // if (
    //     (this.pageForm.get("configurations") as FormArray).length <= 0 &&
    //     this.submitted
    // ) {
    //     return false;
    // } else {
    //     return true;
    // }
    return true;
  }

  setValue() {
    this.pageForm.controls.id.setValue(this.pageDetail.id ? this.pageDetail.id : null);
    this.pageForm.controls.visibility.setValue(this.pageDetail.visibility ? this.pageDetail.visibility : null);

    this.pageForm.controls.status.setValue(this.pageDetail.status ? this.pageDetail.status : null);
    this.pageForm.controls.title.setValue(this.pageDetail.title ? this.pageDetail.title : null);
    this.pageForm.controls.title_ar.setValue(this.pageDetail.title_ar ? this.pageDetail.title_ar : null);
    this.pageForm.controls.meta_title_ar.setValue(this.pageDetail.meta_title_ar ? this.pageDetail.meta_title_ar : null);
    this.pageForm.controls.meta_title_en.setValue(this.pageDetail.meta_title_en ? this.pageDetail.meta_title_en : null);
    this.pageForm.controls.slug.setValue(this.pageDetail.slug ? this.pageDetail.slug : null);
    this.pageForm.controls.meta_description.setValue(this.pageDetail.meta_description ? this.pageDetail.meta_description : null);
    this.pageForm.controls.meta_description_en.setValue(this.pageDetail.meta_description_en ? this.pageDetail.meta_description_en : null);
    this.pageForm.controls.css.setValue(this.pageDetail.css ? this.pageDetail.css : null);
    this.pageForm.controls.template.setValue(this.pageDetail.template ? this.pageDetail.template : null);
    this.pageForm.controls.meta_description_ar.setValue(this.pageDetail.meta_description_ar ? this.pageDetail.meta_description_ar : null);
  }
  // async createFormGroupForEdit() {
  //     let finalArray = [];
  //     let transaltedConfig = [];
  //     this.pageDetail.page_element.forEach((data) => {
  //         let formGroupObject = {
  //             order: data.order,
  //             language: "en",
  //             id: data.id,
  //             show_on: [data.show_on],
  //             isCodeEditor: [false],
  //             label_ar: [data.label_ar],
  //             label: [data.label],
  //             parentName: data.element_type.name,
  //             parentID: data.element_type.id,
  //             redirection_path:data.redirection_path
  //         };

  //         data.element_type.configurations.forEach(async (configuration) => {
  //             let stripedKey = configuration.name.replace("_ar", "");

  //             if (configuration.translate) {
  //                 transaltedConfig.push(`${configuration.name}_ar`);
  //                 formGroupObject = {
  //                     ...formGroupObject,
  //                     [configuration.name]: [
  //                         data.value[configuration.name]
  //                             ? data.value[configuration.name]
  //                             : null,
  //                         Validators.compose(
  //                             configuration.required
  //                                 ? [Validators.required]
  //                                 : []
  //                         ),
  //                     ],
  //                 };
  //             } else if (
  //                 transaltedConfig.find((res) => res == configuration.name)
  //             ) {
  //                 formGroupObject = {
  //                     ...formGroupObject,
  //                     [configuration.name]: [
  //                         data.value_ar[stripedKey]
  //                             ? data.value_ar[stripedKey]
  //                             : null,
  //                         Validators.compose(
  //                             configuration.required
  //                                 ? [Validators.required]
  //                                 : []
  //                         ),
  //                     ],
  //                 };
  //             } else {
  //                 formGroupObject = {
  //                     ...formGroupObject,
  //                     [configuration.name]: [
  //                         data.value[configuration.name]
  //                             ? data.value[configuration.name]
  //                             : null,
  //                         Validators.compose(
  //                             configuration.required
  //                                 ? [Validators.required]
  //                                 : []
  //                         ),
  //                     ],
  //                 };
  //             }
  //             this.configurations.push(configuration);
  //             // if (
  //             //     configuration.name.toLowerCase() == "html" ||
  //             //     configuration.name.toLowerCase() == "html_ar" ||
  //             //     configuration.name.toLowerCase() == "image" ||
  //             //     configuration.name.toLowerCase() == "image_ar" ||
  //             //     configuration.name.toLowerCase() == "embed" ||
  //             //     configuration.name.toLowerCase() == "embed_ar" ||
  //             //     configuration.name.toLowerCase() == "video" ||
  //             //     configuration.name.toLowerCase() == "video_ar" ||
  //             //     configuration.name.toLowerCase() == "audio" ||
  //             //     configuration.name.toLowerCase() == "audio_ar" ||
  //             //     configuration.name.toLowerCase() == "pdf" ||
  //             //     configuration.name.toLowerCase() == "pdf_ar"
  //             // ) {
  //             //     if (configuration.name.toLowerCase() == "html") {
  //             //         formGroupObject = {
  //             //             ...formGroupObject,
  //             //             [configuration.name]: [
  //             //                 data.value[configuration.name]
  //             //                     ? data.value[configuration.name]
  //             //                     : null,
  //             //                 Validators.compose(
  //             //                     configuration.required
  //             //                         ? [Validators.required]
  //             //                         : []
  //             //                 ),
  //             //             ],
  //             //         };
  //             //     }
  //             //     if (configuration.name.toLowerCase() == "html_ar") {
  //             //         formGroupObject = {
  //             //             ...formGroupObject,
  //             //             [configuration.name]: [
  //             //                 data.value_ar["html"]
  //             //                     ? data.value_ar["html"]
  //             //                     : null,
  //             //                 Validators.compose(
  //             //                     configuration.required
  //             //                         ? [Validators.required]
  //             //                         : []
  //             //                 ),
  //             //             ],
  //             //         };
  //             //     }
  //             //     if (configuration.name.toLowerCase() == "image") {
  //             //         formGroupObject = {
  //             //             ...formGroupObject,
  //             //             [configuration.name]: [
  //             //                 data.value[configuration.name]
  //             //                     ? data.value[configuration.name]
  //             //                     : null,
  //             //                 Validators.compose(
  //             //                     configuration.required
  //             //                         ? [Validators.required]
  //             //                         : []
  //             //                 ),
  //             //             ],
  //             //         };
  //             //     }
  //             //     if (configuration.name.toLowerCase() == "image_ar") {
  //             //         formGroupObject = {
  //             //             ...formGroupObject,
  //             //             [configuration.name]: [
  //             //                 data.value_ar["image"]
  //             //                     ? data.value_ar["image"]
  //             //                     : null,
  //             //                 Validators.compose(
  //             //                     configuration.required
  //             //                         ? [Validators.required]
  //             //                         : []
  //             //                 ),
  //             //             ],
  //             //         };
  //             //     }
  //             //     if (configuration.name.toLowerCase() == "embed") {
  //             //         formGroupObject = {
  //             //             ...formGroupObject,
  //             //             [configuration.name]: [
  //             //                 data.value[configuration.name]
  //             //                     ? data.value[configuration.name]
  //             //                     : null,
  //             //                 Validators.compose(
  //             //                     configuration.required
  //             //                         ? [Validators.required]
  //             //                         : []
  //             //                 ),
  //             //             ],
  //             //         };
  //             //     }
  //             //     if (configuration.name.toLowerCase() == "embed_ar") {
  //             //         formGroupObject = {
  //             //             ...formGroupObject,
  //             //             [configuration.name]: [
  //             //                 data.value_ar["embed"]
  //             //                     ? data.value_ar["embed"]
  //             //                     : null,
  //             //                 Validators.compose(
  //             //                     configuration.required
  //             //                         ? [Validators.required]
  //             //                         : []
  //             //                 ),
  //             //             ],
  //             //         };
  //             //     }
  //             //     if (configuration.name.toLowerCase() == "video") {
  //             //         formGroupObject = {
  //             //             ...formGroupObject,
  //             //             [configuration.name]: [
  //             //                 data.value[configuration.name]
  //             //                     ? data.value[configuration.name]
  //             //                     : null,
  //             //                 Validators.compose(
  //             //                     configuration.required
  //             //                         ? [Validators.required]
  //             //                         : []
  //             //                 ),
  //             //             ],
  //             //         };
  //             //     }
  //             //     if (configuration.name.toLowerCase() == "video_ar") {
  //             //         formGroupObject = {
  //             //             ...formGroupObject,
  //             //             [configuration.name]: [
  //             //                 data.value_ar["video"]
  //             //                     ? data.value_ar["video"]
  //             //                     : null,
  //             //                 Validators.compose(
  //             //                     configuration.required
  //             //                         ? [Validators.required]
  //             //                         : []
  //             //                 ),
  //             //             ],
  //             //         };
  //             //     }
  //             //     if (configuration.name.toLowerCase() == "pdf") {
  //             //         formGroupObject = {
  //             //             ...formGroupObject,
  //             //             [configuration.name]: [
  //             //                 data.value[configuration.name]
  //             //                     ? data.value[configuration.name]
  //             //                     : null,
  //             //                 Validators.compose(
  //             //                     configuration.required
  //             //                         ? [Validators.required]
  //             //                         : []
  //             //                 ),
  //             //             ],
  //             //         };
  //             //     }
  //             //     if (configuration.name.toLowerCase() == "pdf_ar") {
  //             //         formGroupObject = {
  //             //             ...formGroupObject,
  //             //             [configuration.name]: [
  //             //                 data.value_ar["pdf"]
  //             //                     ? data.value_ar["pdf"]
  //             //                     : null,
  //             //                 Validators.compose(
  //             //                     configuration.required
  //             //                         ? [Validators.required]
  //             //                         : []
  //             //                 ),
  //             //             ],
  //             //         };
  //             //     }
  //             //     if (configuration.name.toLowerCase() == "audio") {
  //             //         formGroupObject = {
  //             //             ...formGroupObject,
  //             //             [configuration.name]: [
  //             //                 data.value[configuration.name]
  //             //                     ? data.value[configuration.name]
  //             //                     : null,
  //             //                 Validators.compose(
  //             //                     configuration.required
  //             //                         ? [Validators.required]
  //             //                         : []
  //             //                 ),
  //             //             ],
  //             //         };
  //             //     }
  //             //     if (configuration.name.toLowerCase() == "audio_ar") {
  //             //         formGroupObject = {
  //             //             ...formGroupObject,
  //             //             [configuration.name]: [
  //             //                 data.value_ar["audio"]
  //             //                     ? data.value_ar["audio"]
  //             //                     : null,
  //             //                 Validators.compose(
  //             //                     configuration.required
  //             //                         ? [Validators.required]
  //             //                         : []
  //             //                 ),
  //             //             ],
  //             //         };
  //             //     }
  //             // } else {
  //             //     formGroupObject = {
  //             //         ...formGroupObject,
  //             //         [configuration.name]: [
  //             //             data.value[configuration.name]
  //             //                 ? data.value[configuration.name]
  //             //                 : null,
  //             //             Validators.compose(
  //             //                 configuration.required
  //             //                     ? [Validators.required]
  //             //                     : []
  //             //             ),
  //             //         ],
  //             //     };
  //             // }
  //         });
  //         finalArray.push(formGroupObject);
  //     });
  //     return finalArray;
  // }

  getShowOnly(controlLabel) {
    return this.configurations.filter((data) => data.name == controlLabel)[0].show_only || null;
  }

  getMediaType(controlLabel) {
    return this.configurations.filter((data) => data.name == controlLabel)[0].media_type || null;
  }

  createFormGroup(elementType) {
    let formGroupObject = {
      order: 0,
      language: "en",
      id: null,
      show_on: [[1]],
      placement: null,
      isCodeEditor: [false],
      isTabChild: [false],
      label: null,
      label_ar: null,
      parentName: elementType.name,
      parentID: elementType.id,
      redirection_path: null,
    };

    if (elementType.name === PageElements.HEADING) {
      const propertiesToDelete = ["label", "label_ar", "redirection_path"];
      propertiesToDelete.forEach((property) => {
        if (formGroupObject.hasOwnProperty(property)) {
          delete formGroupObject[property];
        }
      });
    }

    if (elementType.configurations) {
      elementType.configurations.forEach((element) => {
        let selectValues = [];
        let validators = [];
        validators = element.required ? [Validators.required] : [];

        if (element.input === "select") {
          element.possible_values.forEach((el) => {
            selectValues.push(el);
          });

          this.getSelectValues(selectValues);
        }

        formGroupObject = {
          ...formGroupObject,
          [element.name]: [null, Validators.compose(validators)],
        };
      });
    }

    return this.fb.group(formGroupObject);
  }

  async getElementType(configObject) {
    let elementType = "";
    let CAROUSEL = "COLLECTION";
    for (let key in configObject) {
      this.elements.forEach((data) => {
        if (key == "slider") {
          if (data.name.toLowerCase() == CAROUSEL.toLowerCase()) {
            elementType = data.id;
          }
        } else {
          if (data.name.toLowerCase() == configObject.parentName.toLowerCase()) {
            elementType = data.id;
          }
        }
      });
    }
    return elementType;
  }

  checkInvalid(index) {
    if ((this.pageForm.get("configurations") as FormArray).length > 0 && this.submitted) {
      return (this.pageForm.get("configurations") as FormArray).controls[index].valid;
    } else {
      return true;
    }
  }

  saveAndContinue() {}

  async save(isSaveAndCloseClicked) {
    this.submitted = true;
    let invlidData = [];
    this.expandIndex = [];
    (this.pageForm.get("configurations") as FormArray).controls.forEach((data, index) => {
      if (data.invalid) {
        invlidData.push(index);
      }
    });
    invlidData.forEach((data) => {
      setTimeout(() => {
        this.expandIndex[data] = data;
      }, 0);
    });

    if (this.productId) {
      const titleValue = this.pageForm.controls.title.value;
      this.pageForm.controls.title.setValue(titleValue || this.productRowData?.fullname);
    }

    if (this.pageForm.valid) {
      // try {
      this.utilitiesService.startLoader();
      let form = this.pageForm.value;
      let pageElementArray = [];
      let configLength = form.configurations.length;
      for (let i = 0; i < configLength; i++) {
        const formValues = form.configurations[i];
        delete formValues.isCodeEditor;

        const order = i;
        let value = {};
        let value_ar = {};

        let selectedKey: string = null;
        selectedKey = formValues.parentName.toLocaleLowerCase();
        let config = this.elements.filter((data) => data.id == formValues.parentID)[0];
        let transaltedConfig = [];

        if (config && config.configurations) {
          config.configurations.forEach((data) => {
            if (data.translate) {
              transaltedConfig.push(data.name);
            }
          });
        }

        for (let key in formValues) {
          let stripedKey = key.replace("_ar", "");
          if (transaltedConfig.find((res) => `${res}` == key)) {
            value[key] = formValues[key];
          } else if (transaltedConfig.find((res) => res == stripedKey)) {
            value_ar[stripedKey] = formValues[key];
          } else {
            value[key] = formValues[key];
            value_ar[key] = formValues[key];
          }
        }
        let showOn = form.configurations[i].show_on;
        if (isNumber(showOn)) {
          showOn = [showOn ? showOn : 5];
        }
        pageElementArray.push({
          id: form.configurations[i].id,
          language: formValues.language,
          value: value,
          value_ar: value_ar,
          order: order,
          element_type: formValues.parentID,
          show_on: showOn.length ? showOn : [5],
          placement: form.configurations[i]?.placement,
          label: formValues.label,
          label_ar: formValues.label_ar,
          redirection_path: formValues.redirection_path,
        });
      }
      pageElementArray.forEach((data) => {
        if (data.value) {
          if (!data.value.id) {
            delete data.value.id;
          }
        }
        if (data.value_ar) {
          if (!data.value_ar.id) {
            delete data.value_ar.id;
          }
        }
      });

      await this.addElementsToTabs(pageElementArray);
      let finalForm = {
        page: {
          visibility: form.visibility,
          status: form.status,
          title: form.title,
          title_en: form.title,
          title_ar: form.title_ar,
          meta_title_ar: form.meta_title_ar,
          meta_title_en: form.meta_title_en,
          slug: form.slug,
          meta_title: null,
          meta_description: form.meta_description,
          meta_description_en: form.meta_description,
          meta_description_ar: form.meta_description_ar,
          css: form.css,
          template: form.template,
        },
        page_element: this.finalPageElements,
      };

      if (this.id) {
        this.updatePage(finalForm, isSaveAndCloseClicked);
      } else if (!this.id && !this.productId && !this.brandId && !this.categorytId) {
        this.savePage(finalForm, isSaveAndCloseClicked);
      } else if (this.productId || this.brandId || this.categorytId) {
        const id = this.productId || this.brandId || this.categorytId;
        const type = this.productId ? "product" : this.brandId ? "brand" : this.categorytId ? "category" : "";

        const object_type = this.getObjectType(type);

        if (!this.productPageId) {
          this.saveProdcutPage(finalForm, isSaveAndCloseClicked, id, type);
        } else {
          this.updateProductPage(finalForm, isSaveAndCloseClicked, id, type);
        }
      }
    } else {
      console.log("not valid");
      if (!this.isExpand && !this.pageForm.valid) {
        this.isExpand = true;
      }
    }
  }

  addElementsToTabs(elements) {
    let nearestTabsIndex = [];
    let tabIndexToPush = [];

    // Get all tab child elements
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].value.isTabChild === true) {
        tabIndexToPush.push(i);
      }
    }

    // Get all tabs parent
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].value.parentName === "TABS" || elements[i].value.parentName === "ACCORDION") {
        nearestTabsIndex.push(i);
      }
    }

    // Initialize tabs array for tab parent elements
    nearestTabsIndex.forEach((index) => {
      elements[index].tabs = [];
    });

    for (let i = 0; i < tabIndexToPush.length; i++) {
      let index;
      for (let v = 0; v < nearestTabsIndex.length; v++) {
        if (tabIndexToPush[i] > nearestTabsIndex[v]) {
          index = nearestTabsIndex[v];
        }
      }
      elements[index].tabs.push(elements[tabIndexToPush[i]]);
    }

    this.removeTabsElementsFromMainPageElements(elements);
  }

  removeTabsElementsFromMainPageElements(elements) {
    let tabIndexToPush = [];
    let removedElements = 0;

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].value.isTabChild === true) {
        tabIndexToPush.push(i);
      }
    }

    // remove tabs child elements from main page elements array
    if (tabIndexToPush.length !== 0) {
      for (let i = 0; i < tabIndexToPush.length; i++) {
        elements.splice(tabIndexToPush[i] - removedElements, 1);
        removedElements++;
      }
    }

    this.finalPageElements = [...elements];
  }

  slugify(str) {
    if (str) {
      return str
        .toLowerCase()
        .replace(/[^\w\s-]/g, "") // Remove non-word characters (excluding spaces and hyphens)
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .replace(/--+/g, "-") // Replace consecutive hyphens with a single hyphen
        .trim();
    } // Trim leading and trailing spaces (if any)
  }

  savePage(finalForm, isSaveAndCloseClicked) {
    try {
      this.pageService.addPages(finalForm).subscribe((data) => {
        let successMsg = this.translate.instant("Page created successfully");
        this.utilitiesService.showSuccessToast(successMsg);
        if (isSaveAndCloseClicked) {
          this.createPageEvent.emit();
        }
        this.utilitiesService.stopLoader();
      });
    } catch {
      this.utilitiesService.stopLoader();
    } finally {
      // this.isLoading = false;
    }
  }

  saveProdcutPage(finalForm, isSaveAndCloseClicked, id, type) {
    try {
      this.pageService.addProductPage(finalForm, id, type).subscribe((data) => {
        let successMsg = this.translate.instant("Page created successfully");
        this.utilitiesService.showSuccessToast(successMsg);
        if (isSaveAndCloseClicked) {
          this.createPageEvent.emit();
          this.handleCancel();
        }
        this.utilitiesService.stopLoader();
      });
    } catch {
      this.utilitiesService.stopLoader();
    } finally {
      // this.isLoading = false;
    }
  }

  updateProductPage(finalForm, isSaveAndCloseClicked, id, type) {
    try {
      this.pageService.updateProductPage(finalForm, id, this.productPageId, type).subscribe(() => {
        let successMsg = this.translate.instant("Page updated successfully");
        this.utilitiesService.showSuccessToast(successMsg);
        if (isSaveAndCloseClicked) {
          this.createPageEvent.emit();
          this.handleCancel();
        }
        this.utilitiesService.stopLoader();
      });
    } catch {
      this.utilitiesService.stopLoader();
    } finally {
      // this.utilitiesService.stopLoader();
      // this.isLoading = false;
    }
  }

  updatePage(finalForm, isSaveAndCloseClicked) {
    try {
      this.pageService.updatePage(finalForm, this.id).subscribe(() => {
        let successMsg = this.translate.instant("Page updated successfully");
        this.utilitiesService.showSuccessToast(successMsg);
        if (isSaveAndCloseClicked) {
          this.createPageEvent.emit();
        }
        this.utilitiesService.stopLoader();
      });
    } catch {
      this.utilitiesService.stopLoader();
    } finally {
      // this.utilitiesService.stopLoader();
      // this.isLoading = false;
    }
  }

  delete(index, config) {
    let content = `Are you sure, Do you want to delete this page ? `;
    let heading = "Delete";
    let fromApp = false;
    let size = this.utilitiesService.isMobileAlertModal();
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: { content, heading, fromApp },
      maxWidth: "",
      width: `${size.width}`,
      height: `${size.height}`,
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        this.removeFromTabs(config);
        if (!this.id && !this.productId && !this.categorytId && !this.brandId) {
          this.removePageLoacally(index);
        } else if (this.productId) {
          let id = config.controls.id.value;
          if (id) {
            this.pageService.deleteProductPageItems(id).subscribe((data) => {
              const configurationsArray = this.pageForm.get("configurations") as FormArray;
              configurationsArray.clear();
              this.getProductPageDetail(this.productId, "product");
              this.utilitiesService.stopLoader();
            });
          }
        } else if (this.categorytId) {
          let id = config.controls.id.value;
          if (id) {
            this.pageService.deleteProductPageItems(id).subscribe((data) => {
              const configurationsArray = this.pageForm.get("configurations") as FormArray;
              configurationsArray.clear();
              this.getProductPageDetail(this.categorytId, "category");
              this.utilitiesService.stopLoader();
            });
          }
        } else if (this.brandId) {
          let id = config.controls.id.value;
          if (id) {
            this.pageService.deleteProductPageItems(id).subscribe((data) => {
              const configurationsArray = this.pageForm.get("configurations") as FormArray;
              configurationsArray.clear();
              this.getProductPageDetail(this.brandId, "brand");
              this.utilitiesService.stopLoader();
            });
          }
        } else {
          let id = config.controls.id.value;
          if (id) {
            this.pageService.deletePageItems(id).subscribe((data) => {
              this.utilitiesService.startLoader();
              this.getPageDetail(true);
            });
          } else {
            this.removePageLoacally(index);
          }
        }
        document.getElementById("toolbar").scrollIntoView();
      }
    });
  }

  removePageLoacally(index) {
    (this.pageForm.get("configurations") as FormArray).removeAt(index);
  }

  setFileToForm(fileObject, configIndex, type) {
    ((this.pageForm.get("configurations") as FormArray).at(configIndex) as FormGroup).get(type).setValue(fileObject.file);
  }

  deleteFile(index: number, type) {
    ((this.pageForm.get("configurations") as FormArray).at(index) as FormGroup).get(type).setValue(null);
  }

  hideLanguage(config) {
    let isShow = false;
    for (let key in config) {
      if (!isShow) {
        if (
          key == "html" ||
          key == "html_ar" ||
          key == "image" ||
          key == "image_ar" ||
          key == "embed" ||
          key == "embed_ar" ||
          key == "video" ||
          key == "video_ar" ||
          key == "pdf" ||
          key == "pdf_ar" ||
          key == "audio" ||
          key == "audio_ar"
        ) {
          isShow = true;
        } else {
          isShow = false;
        }
      }
    }
    return isShow;
  }

  getElementName(config) {
    let keyName = "";
    if (config && ((config.label && config.label.value) || (config.label_ar && config.label_ar.value))) {
      keyName = config.label.value ? config.label.value : config.label_ar.value;
    } else {
      for (let key in config) {
        this.elements.forEach((data) => {
          if (key == "slider") {
            keyName = "COLLECTION";
          } else {
            if (data.name.toLowerCase() == key.toLowerCase()) {
              keyName = data.name;
            }
          }
        });
      }
    }

    return keyName;
  }

  addOrRemoveShowOnItems(index, formGroup) {
    const localIndex = this.selectedShow_on.indexOf(this.show_on[index].id);
    if (localIndex != -1) {
      this.selectedShow_on.splice(localIndex, 1);
    } else {
      this.selectedShow_on.push(this.show_on[index].id);
    }

    formGroup.setValue(this.selectedShow_on.length > 0 ? this.selectedShow_on : []);
  }

  setSelected(id, formGroup, isLabel) {
    let selected = "";
    let value = formGroup.value;
    if (value && value.length > 0) {
      value.forEach((data) => {
        if (data == id) {
          isLabel ? (selected = "selected-text") : (selected = "selected");
        }
      });
    }
    return selected;
  }

  getShowOn(pageElementFormGroup) {
    let showOnImg = [];
    let showOn = pageElementFormGroup.controls.show_on ? pageElementFormGroup.controls.show_on.value : [];
    if (showOn && showOn.length) {
      showOn.forEach((data) => {
        this.show_on.forEach((item) => {
          if (item.id == data) {
            showOnImg.push(item.icon);
          }
        });
      });
    }

    return showOnImg;
  }

  zoomAdditionalCss(formControl, index, type) {
    let heading = "";
    if (type == "embed" || type == "embed_ar") {
      heading = "EMBED";
    } else {
      heading = "HTML";
    }
    const dialogRef = this.dialog.open(FullscreenAdditionCssComponent, {
      data: { formControl, heading },
      maxWidth: "",
      width: "100%",
      height: "100%",
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        ((this.pageForm.get("configurations") as FormArray).at(index) as FormGroup).get(type).setValue(resp.value);
      }
    });
  }

  getLength() {
    if ((this.pageForm.get("configurations") as FormArray).length == 0) {
      return true;
    } else {
      return false;
    }
  }

  zoomPageCss(formControl) {
    let heading = "CSS";
    const dialogRef = this.dialog.open(FullscreenAdditionCssComponent, {
      data: { formControl, heading },
      maxWidth: "",
      width: "100%",
      height: "100%",
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        this.pageForm.controls.css.setValue(resp.value);
      }
    });
  }

  openGallery(formControl, isMultiSelect = true) {
    let size = {
      height: "70%",
      width: "70%",
    };
    let isMobile = this.utilitiesService.isMobile();
    if (isMobile) {
      size.height = "80%";
      size.width = "100%";
    }
    const dialogRef = this.dialog.open(GalleryPopupComponent, {
      data: { formControl, isMultiSelect },
      maxWidth: "",
      width: `${size.width}`,
      height: `${size.height}`,
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
      }
    });
  }

  addNewCollection(control: FormControl) {
    const dialogRef = this.dialog.open(CreateSliderComponent, {
      data: { isPopUp: true },
      position: { right: "0", top: "10px" },
      width: "90%",
      height: "100%",
      closeOnNavigation: true,
      panelClass: "left-to-right-dialog",
    });

    setTimeout(() => {
      dialogRef.componentInstance.dialogRef.addPanelClass("open");
    }, 100);

    dialogRef.afterClosed().subscribe(async (resp) => {
      if (resp) {
        await this.getSliderList();

        control.setValue(resp.id);
      }
    });
  }

  preview(): void {
    let url: string;

    if (this.productId) {
      url = `${this.apiService.BASE_URL}product/${this.productId}/${this.productRowData?.slug}`;
    } else {
      url = this.pageDetail.url;
    }

    if (url) {
      window.open(url, "_blank");
    }
  }
}
