import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ChatPanelComponent } from "app/layout/components/chat-panel/chat-panel.component";
import { CoreService } from "app/services/core-service";
import { CustomerService } from "app/services/customer/customer.service";
import { CampaignsService } from "app/services/marketing/campaigns.service";
import { CartService } from "app/services/orders/cart.service";
import { OrderService } from "app/services/orders/order.service";
import { PageService } from "app/services/pageService";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import * as moment from "moment";
import { ChangeOrderStatusComponent } from "../change-order-status/change-order-status.component";
import { EditProductComponent } from "../edit-product/edit-product.component";
import { SendNotificationComponent } from "../send-notification/send-notification.component";
import { ViewMediaComponent } from "../view-media/view-media.component";
import { DeliveryService } from "app/services/delivery.service";
import { Location } from "@angular/common";
import { fuseAnimations } from "../../../../@fuse/animations/index";
import { AssignDeliveryAgentComponent } from "../assign-delivery-agent/assign-delivery-agent.component";
import { ChangeDeliveryStatusComponent } from "../change-delivery-status/change-delivery-status.component";
@Component({
    selector: "app-delivery-details",
    templateUrl: "./delivery-details.component.html",
    styleUrls: ["./delivery-details.component.scss"],
    animations: fuseAnimations,
})
export class DeliveryDetailsComponent implements OnInit {
    @ViewChild(ChatPanelComponent)
    ChatPanelComponent: ChatPanelComponent;
    @Input() cart: boolean = true;
    // @Input() id: any = "";
    id: any;
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    cartDetail: any = "";
    countryFlag = "";
    isLinear = true;
    title = {
        title_en: "delivery Detail",
        title_ar: "بيانات الطلب",
    };
    opt: boolean = false;
    optShowRemark: boolean = false;
    trackingData: any;
    displayedColumns = [
        "image",
        "name",
        "sku",
        "quantity",
        "unit_price",
        "price",
    ];
    selectedBundle: any = null;
    dynamicFieldList = [];
    isEditProduct: boolean = false;
    isDisabled: boolean = false;
    selectedTabIndex = 0;
    isDisabledCart: boolean;
    isLoading: boolean;
    deliveryDetails: any;
    deliveryStatusList: any;
    deliveryAgentList: any;
    deliveryHistoreyValid: any = false;
    constructor(
        private router: Router,
        private deliveryService: DeliveryService,
        private orderService: OrderService,
        public utilitiesService: UtilitiesService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private campaignsService: CampaignsService,
        private translate: TranslateService,
        public translationService: TranslationService,
        private customerService: CustomerService,
        public coreService: CoreService,
        private pageService: PageService,
        private cdr: ChangeDetectorRef,
        private location: Location
    ) {
        this.route.paramMap.subscribe((params: ParamMap) => {
            this.id = +params.get("id");
            this.cart = false;
        });
        this.title.title_en = "Delivery Details";
        this.title.title_ar = "بيانات الطلب";
        // this.id = this.route.snapshot.paramMap.get("id");
        console.log("route");
        console.log(this.id);
    }

    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        this.utilitiesService.startLoader();
        await this.getDeliveryAgent();
        await this.getDeliveryStatus();
        await this.getDelivery();

        this.isLoading = false;
        this.utilitiesService.stopLoader();
    }
    handleCancel() {
        this.location.back();
    }
    async getDeliveryStatus() {
        await this.deliveryService.getDeliveryStatustList().subscribe((res) => {
            if (res) {
                this.deliveryStatusList = res;
            }
        });
    }
    changeDeliveryStatus(deliveryDetails) {
        let isMobile = this.utilitiesService.isMobile();
        let size = {
            width: "45vw",
            height: "25vh",
        };
        if (isMobile) {
            size.height = "100%";
            size.width = "100%";
        }
        let deliveryId = deliveryDetails.id;
        const dialogRef = this.dialog.open(ChangeDeliveryStatusComponent, {
            data: { deliveryId, deliveryDetails },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });

        dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
                try {
                    this.utilitiesService.showSuccessToast(
                        "the DeliveryStatus Has been updated Successful"
                    );
                    await this.getDelivery();
                } catch {
                    console.log("catch");
                }
                this.isLoading = false;
                this.utilitiesService.stopLoader();
            }
        });
    }
    changeOrderStatus(id, order_status) {
        console.log(id);
        console.log(order_status);
        let orderId = id;

        // let itemList = this.cartList;
        let isMobile = this.utilitiesService.isMobile();
        let size = {
            width: "45vw",
            height: "35vh",
        };
        if (isMobile) {
            size.height = "100%";
            size.width = "100%";
        }
        const dialogRef = this.dialog.open(ChangeOrderStatusComponent, {
            data: { orderId, order_status },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });

        dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
                console.log("resp");
                console.log(resp);
                if (resp) {
                    try {
                        this.utilitiesService.showSuccessToast(resp);
                        await this.getDelivery();
                    } catch {
                        console.log("catch");
                    }
                    this.isLoading = false;
                    this.utilitiesService.stopLoader();
                }
            }
        });
    }
    assignDeliveryAgent(deliveryDetails) {
        let isMobile = this.utilitiesService.isMobile();
        let size = {
            width: "45vw",
            height: "25vh",
        };
        if (isMobile) {
            size.height = "100%";
            size.width = "100%";
        }
        const dialogRef = this.dialog.open(AssignDeliveryAgentComponent, {
            data: { deliveryDetails },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });

        dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
                try {
                    this.utilitiesService.showSuccessToast(resp);
                    await this.getDelivery();
                } catch {
                    console.log("catch");
                }
                this.isLoading = false;
                this.utilitiesService.stopLoader();
            }
        });
    }
    async getDelivery() {
        this.isLoading = true;
        this.utilitiesService.startLoader();
        await this.deliveryService
            .getDeliveryById(this.id)
            .subscribe(async (res) => {
                if (res) {
                    this.deliveryDetails = res;

                    this.deliveryHistoreyValid =
                        res.delivery_history.length != 0 ? true : false;
                    console.log("this.deliveryHistorey");
                    console.log(this.deliveryHistoreyValid);
                    if (this.deliveryDetails?.delivery_agent?.id) {
                        this.deliveryDetails.delivery_agent_detail =
                            await this.getDeliveryAgentDetail(
                                this.deliveryDetails.delivery_agent.id
                            );
                    } else {
                        this.deliveryDetails.delivery_agent_detail = null;
                    }

                    console.log(this.deliveryDetails.delivery_agent_detail);
                    this.cdr.markForCheck();
                }
                this.isLoading = false;
                this.utilitiesService.stopLoader();
            });
    }
    toOrderDetail(id) {
        // this.fullUrl = this.document.URL;
        // const urlRoute = this.fullUrl.split("/")[3];

        // urlRoute?.[0] == "#";

        window.open(`#/order/${id}`, "_blank");
    }
    async checkStatus(deliveryId) {
        if (deliveryId) {
            await this.getDelivery();
        }
    }
    openLink(url) {
        // [href]="'https://www.google.com/maps/place/'
        let fullUrl = `https://www.google.com/maps/place/${url}`;
        // const urlRoute = this.fullUrl.split("/")[3];

        // urlRoute?.[0] == "#";

        window.open(fullUrl, "_blank");
    }
    getDeliveryAgentDetail(id) {
        if (id) {
            return this.deliveryService.getDeliveryAgentById(id).toPromise();
        }
    }
    onClickOrder(order_id) {
        this.router.navigate([`/order/${order_id}`]);
    }
    SavePdf(id) {
        this.orderService.getOrderPdf(id);
    }
    SaveInvoicePdf(id) {
        this.orderService.getInvoicePdf(id);
    }
    async getDeliveryAgent() {
        this.isLoading = true;
        await this.deliveryService.getDeliveryAgentList().subscribe((res) => {
            if (res) {
                this.deliveryAgentList = res.results;

                this.cdr.markForCheck();
            }
            this.isLoading = false;
            this.utilitiesService.stopLoader();
        });
    }
}

// getStatusName(id){

// }
