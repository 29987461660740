import {
    CdkDragDrop,
    CdkDragStart,
    moveItemInArray,
} from "@angular/cdk/drag-drop";
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    Renderer2,
} from "@angular/core";
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { ThemePalette } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { MediaService } from "app/services/media.service";
import { NavMenuService } from "app/services/nav-menu.service";
import { PageService } from "app/services/pageService";
import { SharedService } from "app/services/sharedService";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
import { GalleryPopupComponent } from "../gallery-popup/gallery-popup.component";

function startWithHttp(
    control: AbstractControl
): { [key: string]: any } | null {
    if (control.value && !control.value.startsWith("http")) {
        return { startWithHttp: true };
    }
    return null;
}

function containHTTP(control: AbstractControl): { [key: string]: any } | null {
    if (control.value && control.value.startsWith("http")) {
        return { containHTTP: true };
    }
    return null;
}

@Component({
    selector: "app-create-menu",
    templateUrl: "./create-menu.component.html",
    styleUrls: ["./create-menu.component.scss"],
})
export class CreateMenuComponent implements OnInit {
    expandIndex = [];
    color: ThemePalette = "primary";
    @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
    @Input() id: any = "";
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    @Output() createMenu: EventEmitter<void> = new EventEmitter<any>();

    visibilityEnum = [
        { id: 1, name: "Public" },
        { id: 2, name: "Registered" },
        { id: 3, name: "Guest" },
        { id: 4, name: "Guest or Registerd" },
    ];

    typeEnum = [
        // { id: "PARENT", name: "Parent" },
        { id: "PAGE", name: "Page" },
        { id: "CUSTOM", name: "Custom" },
        { id: "EXTERNAL", name: "External" },
        { id: "PARENT", name: "Parent" },
    ];
    navForm: FormGroup;
    navMenuList = [];
    navItemList = [];
    pages = [];
    isLoading: boolean = false;
    elementsArray = [];
    submitted = false;
    files = [];
    constructor(
        private navMenuService: NavMenuService,
        private fb: FormBuilder,
        private dialog: MatDialog,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        private pageService: PageService,
        private renderer: Renderer2,
        private mediaService: MediaService
    ) {}

    async addField(fieldType: any, index: number) {
        (this.navForm.get("navigation_item") as FormArray).insert(
            index,
            this.addNavItemFormGroup()
        );
        this.elementsArray.splice(index, 0, fieldType);
    }
    async checkIfParent(index) {
        let parentValue: any = "";
        let isParent: any = "";
        for (let i = index; i >= 0; i--) {
            isParent = (
                (this.navForm.get("navigation_item") as FormArray).at(
                    i
                ) as FormGroup
            ).get("type").value;
            if (isParent != "PARENT" && !this.getChildValue(i)) {
                let errorMessage = this.translate.instant(
                    "Can not find any parent"
                );
                this.utilitiesService.showErrorToast(errorMessage);
                this.setChildValue(i, false);
                break;
            }
            if (isParent == "PARENT") {
                parentValue = (
                    (this.navForm.get("navigation_item") as FormArray).at(
                        i
                    ) as FormGroup
                ).value;
                break;
            }
        }
        if (!parentValue) {
            let errorMessage = this.translate.instant(
                "Can not find any parent"
            );
            this.utilitiesService.showErrorToast(errorMessage);
            this.setChildValue(index, false);
        } else {
            if (!parentValue.id) {
                let errorMessage = this.translate.instant(
                    "Please save the parent nav item before assigning child to it"
                );
                this.utilitiesService.showErrorToast(errorMessage);
                this.setChildValue(index, false);
            } else {
                (
                    (this.navForm.get("navigation_item") as FormArray).at(
                        index
                    ) as FormGroup
                )
                    .get("parent")
                    .setValue(parentValue.id);
                // await this.updateAllSubMenus();
            }
        }
    }
    getChildValue(index) {
        return (
            (this.navForm.get("navigation_item") as FormArray).at(
                index
            ) as FormGroup
        ).get("isChild").value;
    }
    async setChildValue(index, value) {
        (
            (this.navForm.get("navigation_item") as FormArray).at(
                index
            ) as FormGroup
        )
            .get("isChild")
            .setValue(value);
    }

    async addSubmenu(index) {
        this.setChildValue(index, true);
        await this.checkIfParent(index);
    }
    async removeSubmenu(index) {
        (
            (this.navForm.get("navigation_item") as FormArray).at(
                index
            ) as FormGroup
        )
            .get("parent")
            .setValue("");
        await this.setChildValue(index, false);
        // await this.updateAllSubMenus();
    }

    addChild(event: CdkDragStart<string[]>) {}

    async isChildExist(position, index, previousIndex) {
        let formArray = this.navForm.get("navigation_item") as FormArray;
        let parentID = (formArray.at(previousIndex) as FormGroup).get(
            "parent"
        ).value;
        if (position == "down") {
            if (formArray.length == index + 1) {
                return false;
            } else {
                if (
                    (formArray.at(index + 1) as FormGroup).get("parent")
                        .value == parentID
                ) {
                    return false;
                } else {
                    if (
                        (formArray.at(index + 1) as FormGroup).get("parent")
                            .value
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        } else {
            if (formArray.length == index) {
                return false;
            } else {
                if (
                    (formArray.at(index) as FormGroup).get("parent").value ==
                    parentID
                ) {
                    return false;
                } else {
                    if (
                        (formArray.at(index) as FormGroup).get("parent").value
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        }
    }

    async ifChildIsOutSideParent(index, dropIndex) {
        let parentIndex: any = "";
        let lastChildIndex: any = "";
        let childIndex = [];
        let lastParent = false;
        let formArray = this.navForm.get("navigation_item") as FormArray;
        let isChild = await this.getChildValue(index);
        if (isChild) {
            let id = (formArray.at(index) as FormGroup).get("parent").value;
            formArray.controls.forEach((data, innerIndex) => {
                if (data.value.id === id) {
                    parentIndex = innerIndex;
                }
                if (
                    !data.value.parent &&
                    data.value.id != id &&
                    innerIndex > dropIndex
                ) {
                    lastChildIndex = innerIndex;
                }
            });
            if (
                (formArray.at(formArray.length - 1) as FormGroup).get("parent")
                    .value
            ) {
                return true;
            } else {
                if (parentIndex < dropIndex && lastChildIndex > dropIndex) {
                } else {
                    this.removeSubmenu(index);
                }
            }
        }
    }

    async drop(event: CdkDragDrop<string[]>) {
        this.elementsArray = [];
        let data = event.item.data;
        let id = data.value.id;
        if (data.value.type == "PARENT") {
            (this.navForm.get("navigation_item") as FormArray).controls.forEach(
                (data, index) => {
                    let formValue = data.value;
                    if (formValue.parent == id) {
                        this.elementsArray.push(formValue);
                    }
                }
            );
        }
        if (event.previousContainer === event.container) {
            moveItemInArray(
                this.elementsArray,
                event.previousIndex,
                event.currentIndex
            );
            if (event.previousIndex > event.currentIndex) {
                let isChildExist = await this.isChildExist(
                    "up",
                    event.currentIndex,
                    event.previousIndex
                );
                if (!isChildExist) {
                    await this.ifChildIsOutSideParent(
                        event.previousIndex,
                        event.currentIndex
                    );
                    const formGroup = (
                        this.navForm.get("navigation_item") as FormArray
                    ).at(event.previousIndex);
                    (this.navForm.get("navigation_item") as FormArray).removeAt(
                        event.previousIndex
                    );
                    (this.navForm.get("navigation_item") as FormArray).insert(
                        event.currentIndex,
                        formGroup
                    );
                    if (this.elementsArray.length > 0) {
                        this.elementsArray.forEach((data, index) => {
                            let childIndex: any = "";
                            (
                                this.navForm.get("navigation_item") as FormArray
                            ).controls.forEach((item, innerIndex) => {
                                if (item.value.id == data.id) {
                                    childIndex = innerIndex;
                                }
                            });
                            const formGroup = (
                                this.navForm.get("navigation_item") as FormArray
                            ).at(childIndex);
                            (
                                this.navForm.get("navigation_item") as FormArray
                            ).removeAt(childIndex);
                            (
                                this.navForm.get("navigation_item") as FormArray
                            ).insert(
                                event.currentIndex + (index + 1),
                                formGroup
                            );
                        });
                    }
                    // await this.updateAllSubMenus();
                } else {
                    let errorMessage = this.translate.instant(
                        "You cannot change this menu as a child"
                    );
                    this.utilitiesService.showErrorToast(errorMessage);
                }
            }
            if (event.previousIndex < event.currentIndex) {
                let isChildExist = await this.isChildExist(
                    "down",
                    event.currentIndex,
                    event.previousIndex
                );
                if (!isChildExist) {
                    await this.ifChildIsOutSideParent(
                        event.previousIndex,
                        event.currentIndex
                    );
                    const formGroup = (
                        this.navForm.get("navigation_item") as FormArray
                    ).at(event.previousIndex);
                    (this.navForm.get("navigation_item") as FormArray).removeAt(
                        event.previousIndex
                    );
                    (this.navForm.get("navigation_item") as FormArray).insert(
                        event.currentIndex,
                        formGroup
                    );
                    if (this.elementsArray.length > 0) {
                        this.elementsArray.forEach((data, index) => {
                            let childIndex: any = "";
                            (
                                this.navForm.get("navigation_item") as FormArray
                            ).controls.forEach((item, innerIndex) => {
                                if (item.value.id == data.id) {
                                    childIndex = innerIndex;
                                }
                            });
                            const formGroup = (
                                this.navForm.get("navigation_item") as FormArray
                            ).at(childIndex);
                            (
                                this.navForm.get("navigation_item") as FormArray
                            ).removeAt(childIndex);
                            (
                                this.navForm.get("navigation_item") as FormArray
                            ).insert(
                                event.currentIndex + (index + 1),
                                formGroup
                            );
                        });
                        // await this.updateAllSubMenus();
                    }
                } else {
                    let errorMessage = this.translate.instant(
                        "You cannot change this menu as a child"
                    );
                    this.utilitiesService.showErrorToast(errorMessage);
                }
            }
        } else {
            this.elementsArray.forEach(async (data) => {
                await this.addField(data, event.currentIndex);
            });
        }
    }

    async ngOnInit() {
        await this.formInitilizing();
        if (this.id) {
            this.utilitiesService.startLoader();
            await this.getMenuDetail();
        }
        await this.getMenu();
        await this.getPages();
        await this.getNavItemList();
    }
    async formInitilizing() {
        this.navForm = this.fb.group({
            id: [null],
            title: [null, [Validators.required]],
            title_en: [null],
            language: ["en"],
            title_ar: [null],
            navigation_item: this.fb.array([this.addNavItemFormGroup()]),
        });
        this.typeChanged(0, "PAGE");
    }
    addNavItemFormGroup(
        menu = {
            id: null,
            title: null,
            title_en: null,
            title_ar: null,
            type: null,
            visibility: null,
            value: null,
            sequence: null,
            icon: null,
            icon_ar: null,
            parent: null,
            page: null,
            enable: null,
            icon_type: 1,
            image_icon: null,
            image_icon_ar: null,
        }
    ) {
        // this.typeChanged(0, menu.type ? menu.type : "PARENT");
        return this.fb.group({
            id: [menu ? menu.id : null],
            title: [menu.title ? menu.title : null, [Validators.required]],
            title_en: [menu.title_en ? menu.title_en : null],
            title_ar: [menu.title_ar ? menu.title_ar : null],
            type: [menu.type ? menu.type : "PAGE"],
            visibility: [menu.visibility ? menu.visibility : 1],
            value: [menu.value ? menu.value : null],
            sequence: [menu.sequence ? menu.sequence : 0],
            icon: [menu.icon ? menu.icon : "remove-sharp"],
            icon_ar: [menu.icon_ar ? menu.icon_ar : "remove-sharp"],
            parent: [menu.parent ? menu.parent.id : null],
            page: [menu.page ? menu.page : null],
            enable: [menu.enable ? menu.enable : false],
            language: ["en"],
            menu: [this.id],
            isChild: [false],
            icon_type: [menu.icon ? 1 : 2],
            image_icon: [menu.image_icon ? menu.image_icon : null],
            image_icon_ar: [menu.image_icon_ar ? menu.image_icon_ar : null],
        });
    }

    setFileToForm($event, index, lang) {
        if (lang == "en") {
            (
                (this.navForm.get("navigation_item") as FormArray).at(
                    index
                ) as FormGroup
            )
                .get("image_icon")
                .setValue($event.file);
        } else {
            (
                (this.navForm.get("navigation_item") as FormArray).at(
                    index
                ) as FormGroup
            )
                .get("image_icon_ar")
                .setValue($event.file);
        }
    }

    deleteImage(index, lang) {
        if (lang == "en") {
            (
                (this.navForm.get("navigation_item") as FormArray).at(
                    index
                ) as FormGroup
            )
                .get("image_icon")
                .setValue("");
        } else {
            (
                (this.navForm.get("navigation_item") as FormArray).at(
                    index
                ) as FormGroup
            )
                .get("image_icon_ar")
                .setValue("");
        }
    }

    async getMenuDetail(isDelete = false) {
        if (isDelete) {
            (this.navForm.controls["navigation_item"] as FormArray).clear();
        }
        try {
            await this.deleteFirstFormGroup();
            const menuDetail = await this.navMenuService
                .getMenuDetail(this.id)
                .toPromise();
            if (menuDetail) {
                if (menuDetail.items.length > 0) {
                    let menuItems = menuDetail.items;
                    menuItems.forEach(async (data, index) => {
                        await this.addMenu(data);
                        if (data.parent) {
                            this.setChildValue(index, true);
                        }
                    });
                }
                await this.setValues(menuDetail);
            }
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }
    async setValues(menuDetail) {
        this.navForm.get("id").setValue(menuDetail.id);
        this.navForm.get("title").setValue(menuDetail.title);
        this.navForm.get("title_en").setValue(menuDetail.title_en);
        this.navForm.get("title_ar").setValue(menuDetail.title_ar);
    }
    async deleteFirstFormGroup() {
        //only for edit
        this.navForm.get("navigation_item")["controls"].splice(0, 1);
    }
    async getMenu() {
        try {
            const navMenuList = await this.navMenuService
                .getMenuList(999999, 0)
                .toPromise();
            if (navMenuList) {
                this.navMenuList = navMenuList.results;
            }
        } catch {
        } finally {
            this.isLoading = false;
        }
    }
    async getNavItemList() {
        try {
            const navItemList = await this.navMenuService
                .getMenuItemList(999999, 0)
                .toPromise();
            if (navItemList) {
                this.navItemList = navItemList.results;
            }
        } catch {
        } finally {
            this.isLoading = false;
        }
    }
    async getPages() {
        try {
            const pages = await this.pageService
                .getPages(999999, 0)
                .toPromise();
            if (pages) {
                this.pages = pages.results;
            }
        } catch {}
    }

    async addMenu(menu?) {
        (this.navForm.get("navigation_item") as FormArray).push(
            this.addNavItemFormGroup(menu)
        );
    }

    handleCancel() {
        this.cancelClick.emit();
    }
    async save() {
        if (this.navForm.controls.title.valid) {
            try {
                this.utilitiesService.startLoader();
                let form = this.navForm.value;
                let finalForm = {
                    navigation: {
                        title: form.title,
                        title_en: form.title,
                        title_ar: form.title_ar,
                    },
                    navigation_item: [],
                };
                if (!this.id) {
                    this.saveMenu(finalForm);
                } else {
                    this.updateMenu(finalForm);
                }
            } catch {
                this.utilitiesService.stopLoader();
            } finally {
            }
        }
    }
    saveMenu(finalForm) {
        try {
            this.navMenuService
                .addMenuItems(finalForm)
                .subscribe(async (data) => {
                    this.id = data.id;
                    let successMsg = this.translate.instant(
                        "Navigation menu created successfully"
                    );
                    this.utilitiesService.showSuccessToast(successMsg);
                    // await this.getMenuDetail();
                    // this.createMenu.emit();
                });
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }
    updateMenu(finalForm) {
        try {
            this.navMenuService
                .updateMenuItems(finalForm, this.id)
                .subscribe(async (data) => {
                    this.id = data.id;
                    let successMsg = this.translate.instant(
                        "Navigation menu updated successfully"
                    );
                    this.utilitiesService.showSuccessToast(successMsg);
                    // await this.getMenuDetail();
                    // this.createMenu.emit();
                });
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }

    removeMenuLoacally(index) {
        (this.navForm.get("navigation_item") as FormArray).removeAt(index);
    }

    delete(index) {
        let content = `Are you sure, Do you want to delete this menu ? `;
        let heading = "Delete";
        let fromApp = false;
        let size = this.utilitiesService.isMobileAlertModal();
        const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });
        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                if (!this.id) {
                    this.removeMenuLoacally(index);
                } else {
                    let navItem = this.navForm.get("navigation_item").value;
                    let id = navItem[index].id;
                    if (id) {
                        this.navMenuService
                            .deleteNavItems(id)
                            .subscribe((data) => {
                                this.utilitiesService.startLoader();
                                this.getMenuDetail(true);
                            });
                    } else {
                        this.removeMenuLoacally(index);
                    }
                }
                document.getElementById("toolbar").scrollIntoView();
            }
        });
    }
    async isRemoveChild(index, type) {
        let formArray = this.navForm.get("navigation_item") as FormArray;
        let id = (formArray.at(index) as FormGroup).get("id").value;
        let parentID = (formArray.at(index) as FormGroup).get("parent").value;
        if (!this.getChildValue(index) && id) {
            (this.navForm.get("navigation_item") as FormArray).controls.forEach(
                (items, index) => {
                    if (id == items.value.parent) {
                        this.removeSubmenu(index);
                    }
                }
            );
        }
    }
    async typeChanged(index, type) {
        if (type == "EXTERNAL") {
            (this.navForm.get("navigation_item") as FormArray).controls[index]
                .get("value")
                .setValidators(
                    Validators.compose([startWithHttp, Validators.required])
                );
            (this.navForm.get("navigation_item") as FormArray).controls[index]
                .get("value")
                .updateValueAndValidity();
            (this.navForm.get("navigation_item") as FormArray).controls[index]
                .get("page")
                .clearValidators();
            (this.navForm.get("navigation_item") as FormArray).controls[index]
                .get("page")
                .updateValueAndValidity();
        } else if (type == "CUSTOM") {
            (this.navForm.get("navigation_item") as FormArray).controls[index]
                .get("value")
                .setValidators(
                    Validators.compose([containHTTP, Validators.required])
                );
            (this.navForm.get("navigation_item") as FormArray).controls[index]
                .get("value")
                .updateValueAndValidity();
            (this.navForm.get("navigation_item") as FormArray).controls[index]
                .get("page")
                .clearValidators();
            (this.navForm.get("navigation_item") as FormArray).controls[index]
                .get("page")
                .updateValueAndValidity();
        } else if (type == "PAGE") {
            (this.navForm.get("navigation_item") as FormArray).controls[index]
                .get("page")
                .setValidators(Validators.compose([Validators.required]));
            (this.navForm.get("navigation_item") as FormArray).controls[index]
                .get("page")
                .updateValueAndValidity();
            (this.navForm.get("navigation_item") as FormArray).controls[index]
                .get("value")
                .clearValidators();
            (this.navForm.get("navigation_item") as FormArray).controls[index]
                .get("value")
                .updateValueAndValidity();
        } else {
            (this.navForm.get("navigation_item") as FormArray).controls[index]
                .get("page")
                .clearValidators();
            (this.navForm.get("navigation_item") as FormArray).controls[index]
                .get("page")
                .updateValueAndValidity();
            (this.navForm.get("navigation_item") as FormArray).controls[index]
                .get("value")
                .clearValidators();
            (this.navForm.get("navigation_item") as FormArray).controls[index]
                .get("value")
                .updateValueAndValidity();
        }
        await this.isRemoveChild(index, type);
    }
    saveOrUpdateNavItems(formGroup, index) {
        let form = formGroup.value;
        form.menu = this.id;
        form.title_en = form.title;
        form.sequence = index;
        if (form.image_icon) {
            form.icon = null;
        }
        if (form.image_icon_ar) {
            form.icon_ar = null;
        }
        if (formGroup.valid) {
            if (!form.id) {
                this.saveNavItems(form, index);
            } else {
                this.updateNavItems(form);
            }
        } else {
            let errorMsg = this.translate.instant(
                "Please fill the required fields"
            );
            this.utilitiesService.showErrorToast(errorMsg);
            formGroup.get("title").markAsTouched();
            formGroup.get("page").markAsTouched();
            formGroup.get("value").markAsTouched();
        }
    }
    checkInvalid(index) {
        if (
            (this.navForm.get("navigation_item") as FormArray).length > 0 &&
            this.submitted
        ) {
            return (this.navForm.get("navigation_item") as FormArray).controls[
                index
            ].valid;
        } else {
            return true;
        }
    }
    async updateAllSubMenus(isFrombutton = false) {
        this.submitted = true;
        let invlidData = [];
        this.expandIndex = [];
        (this.navForm.get("navigation_item") as FormArray).controls.forEach(
            (data, index) => {
                if (data.invalid) {
                    invlidData.push(index);
                    data.markAllAsTouched();
                }
            }
        );
        invlidData.forEach((data) => {
            setTimeout(() => {
                this.expandIndex[data] = data;
            }, 0);
        });
        if (this.navForm.valid) {
            let form = this.navForm.value;
            let finalForm = {
                navigation: {
                    title: form.title,
                    title_en: form.title,
                    title_ar: form.title_ar,
                },
                navigation_item: form.navigation_item,
            };
            finalForm.navigation_item.forEach((data, index) => {
                data.menu = this.id;
                data.sequence = index;
                data.title_en = data.title;
            });
            try {
                if (isFrombutton) {
                    this.utilitiesService.startLoader();
                }
                this.navMenuService
                    .updateMenuItems(finalForm, this.id)
                    .subscribe(async (data) => {
                        this.id = data.id;
                        if (isFrombutton) {
                            let successMsg = this.translate.instant(
                                "Navigation menu updated successfully"
                            );
                            this.utilitiesService.showSuccessToast(successMsg);
                            this.createMenu.emit();
                        }
                    });
            } catch {
            } finally {
                this.utilitiesService.stopLoader();
            }
        } else {
            let successMsg = this.translate.instant("Form is not valid");
            this.utilitiesService.showErrorToast(successMsg);
        }
    }
    async updateNavItems(form) {
        try {
            const resp = await this.navMenuService
                .updateIndividualMenuItem(form)
                .toPromise();
            if (resp) {
                let successMsg = this.translate.instant(
                    "Navigation item updated successfully"
                );
                this.utilitiesService.showSuccessToast(successMsg);
            }
        } catch {
        } finally {
        }
    }
    async saveNavItems(form, index) {
        try {
            const resp = await this.navMenuService
                .addIndividualMenuItem(form)
                .toPromise();
            if (resp) {
                let successMsg = this.translate.instant(
                    "Navigation item added successfully"
                );
                this.utilitiesService.showSuccessToast(successMsg);
                (
                    (this.navForm.get("navigation_item") as FormArray).at(
                        index
                    ) as FormGroup
                )
                    .get("id")
                    .setValue(resp.id);
            }
        } catch {
        } finally {
        }
    }
}
