import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from "@angular/forms";
import { CatalogueService } from "app/services/catalogue/catalogue.service";
import { productField } from "app/main/types/productFields";
import { take, takeUntil } from "rxjs/operators";
import { UtilitiesService } from "app/services/utilitiesService";
import { Subject } from "rxjs";
import { DynamicFieldTypes } from "app/main/types/dynamicField";
import { PageService } from "app/services/pageService";
import { fuseAnimations } from "@fuse/animations";

@Component({
  selector: "app-bulk-upload-product-image",
  templateUrl: "./create-bulkUpload.component.html",
  styleUrls: ["./create-bulkUpload.component.scss"],
  animations: fuseAnimations,
})
export class CreateBulkUploadComponent implements OnInit {
  @Input() productId: any = "";
  @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
  @Output() createBulkUploadEvent: EventEmitter<void> = new EventEmitter<any>();
  form: FormGroup;

  isApiCallDone: boolean = false;
  unSubscribe$ = new Subject<void>();
  deleteImageList: any = [];
  rowData: any;

  getImageFormArray() {
    return this.form.get("product_images") as FormArray;
  }
  constructor(private fb: FormBuilder,   private catalogueService: CatalogueService,
    private utilitiesService: UtilitiesService,) {
  }

  ngOnInit(): void {
    this.formInitialize();
  }

  async formInitialize() {
    this.form = this.fb.group({
      product_images: this.fb.array([]), // FormArray to handle multiple images
    });
  }


  // Add each image as a new form group with default values
  addImageFormGroup(image: string, name: string,file): void {
    const imageGroup = this.fb.group({
      image: [image],
      cover_image: [false],
      flip_image: [false],
      name:[name],
      caption: [""],
      order_by: ["0"],
      productId: [this.removeFileExtension(name)],
    });
    this.getImageFormArray().push(imageGroup);
  }

  // Handle multiple file selection
  onFilesSelected(event: any): void {
    const files = event.target.files;

    if (files.length > 0) {
      Array.from(files).forEach((file: any) => {
        const isFileExist = this.getImageFormArray().controls.some(
          (imageGroup) => imageGroup.get('name')?.value === file.name
        );
        if(isFileExist){
          this.utilitiesService.showErrorToast('This file has already been added.');
          return
        }
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.addImageFormGroup(e.target.result, file.name,file); // Push each image into the form array
        };
        reader.readAsDataURL(file);
      });
    }
  }

  removeFileExtension(filename: string): string {
    const lastDotIndex = filename.lastIndexOf(".");
    if (lastDotIndex === -1) {
      return filename;
    }
    return filename.substring(0, lastDotIndex);
  }

  handleCancel() {
    this.createBulkUploadEvent.emit();
  }


  base64ToBinary(base64String: string): Blob {
    const byteString = atob(base64String.split(',')[1]); // Remove Base64 header if present
    const byteNumbers = new Array(byteString.length);
    
    for (let i = 0; i < byteString.length; i++) {
      byteNumbers[i] = byteString.charCodeAt(i);
    }
  
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'application/octet-stream' });
  }

  async saveBulkUpload() {
    const imagesArray = this.form.value;
    for (const imageData of imagesArray.product_images) {
      try {
          // Convert base64 string to binary data
        const binaryData = this.base64ToBinary(imageData.image);
        // Create a Blob or File from binary data
        const file = new File([binaryData], imageData.name, { type: 'image/png' });
        // Create FormData and append the image file
        const formData = new FormData();
        formData.append('productId', imageData.productId);  
        formData.append('image', file);  
        formData.append('caption', imageData.caption);  
        formData.append('cover_image', imageData.cover_image);  
        formData.append('flip_image', imageData.flip_image);  
        formData.append('order_by', imageData?.order_by);  
        
        const res = await this.catalogueService.bulkUploadImage(formData).toPromise();
        
        if (res) {
          const msg = "Uploaded product image successfully.";
          this.utilitiesService.showSuccessToast(msg);
        }
        this.getImageFormArray().clear();
      } catch (error) {
        console.error("Image upload failed", error);
        this.utilitiesService.showErrorToast(`Image upload failed with productId ${imageData?.productId}`);
      }
    }
    this.utilitiesService.stopLoader();
  }







  updateModifiedForm(form) {
    if (this.deleteImageList && this.deleteImageList.length) {
      this.deleteImageList.forEach((image) => {
        form.product_images.push(image);
      });

      console.log(form.product_images);

      this.deleteImageList = [];
    }
    return form;
  }

  imageType(key, index, event) {
    if (!event.checked) {
      return; // If the checkbox is unchecked, do nothing.
    }

    const imageFormArray = this.getImageFormArray();
    const controlKey = `${key}_image`; // Create the dynamic control key, e.g., "cover_image" or "flip_image".

    imageFormArray.controls.forEach((control, i) => {
      if (i !== index) {
        (control as FormGroup).controls[controlKey].setValue(false);
      }
    });
  }

  // Handle image deletion
  deleteImage(index: number): void {
    this.getImageFormArray().removeAt(index);
    console.log(this.form.value);

  }
  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }
}
