<div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch">
    <app-breadcrumbs [isEnableBack]="isEnableBack" [enableIcon]="enableIcon" [label]="label">
    </app-breadcrumbs>
    <div id="dashboard-project" class="page-layout simple right-sidebar" fxLayout="column">
        <div class="center" fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
            fusePerfectScrollbar>
            <div class="content p-16" fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column"
                fxLayoutGap="1em">
                <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="space-between start" fxLayoutGap="1em">
                    <div fxFlex="0 0 auto">
                        <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="space-between start"
                            class="search-form form">
                            <label>
                                <input id="range-picker" [locale]="{ format: 'DD/MM/YYYY' }"
                                    (datesUpdated)="datesUpdated($event, true)" [(ngModel)]="selectedRanges"
                                    class="search-field" type="search" ngxDaterangepickerMd [closeOnAutoApply]="true"
                                    [autoApply]="true" [showCustomRangeLabel]="true" [alwaysShowCalendars]="true"
                                    [ranges]="ranges" [linkedCalendars]="true" [isInvalidDate]="isInvalidDate"
                                    [showClearButton]="false" placeholder="Order Date.." />
                            </label>
                            <div class="search-submit button" (click)="openDatepicker()">
                                <mat-icon class="cursor-pointer" matSuffix>date_range</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="end center" fxLayoutGap="1em">
                        <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="start start" fxLayoutGap="1em">
                            <app-clear-filter (clearFilterHandler)="clearDate()" [form]="form"
                                (clearFilterHandler)="clearFilter()" class="mt-4" [@animate]="{
                                    value: '*',
                                    params: { duration: '300ms', y: '50px' }
                                }">
                            </app-clear-filter>

                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="page.size" (selectionChange)="limitChange()">
                                    <mat-option [value]="5">5</mat-option>
                                    <mat-option [value]="10">10</mat-option>
                                    <mat-option [value]="25">25</mat-option>
                                    <mat-option [value]="50">50</mat-option>
                                    <mat-option [value]="100">100</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <app-common-filter (searchInput)="search($event)" fxHide.lt-md>
                            </app-common-filter>

                            <app-fab-button [bottomMargin]="false" [bgColorClass]="'primary-bg-color'"
                                (handleFabButtonClick)="
                                    AddDeliveryAgentForSelected()
                                " [tooltip]="'Assign Delivery Agent'" [label]="'Assign Delivery Agent'">
                            </app-fab-button>
                        </div>
                    </div>
                </div>

                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                    [formGroup]="form">
                    <ngx-datatable (activate)="onActivateViewOrder($event)" [loadingIndicator]="isLoading"
                        [externalPaging]="true" [count]="page.totalElements" [limit]="page.size"
                        (page)="pageChange($event)" [offset]="page.pageNumber" [virtualization]="false"
                        [scrollbarH]="true" [rows]="deliveryList" [cssClasses]="iconsCss" class="material striped mt-8"
                        [columnMode]="'force'" [headerHeight]="90" [rowHeight]="50" [footerHeight]="'auto'">
                        <ngx-datatable-column [width]="50">
                            <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="row"
                                let-onCheckboxChangeFn="onCheckboxChangeFn">
                                <div *ngIf="
                                        isSelected?.delivery_status_obj
                                            ?.can_assign_agent
                                    ">
                                    <label class="checkbox-container">
                                        <input [checked]="isSelected?.selected" type="checkbox"
                                            class="ngx-datatable-checkbox" (click)="clickCheck(isSelected)" (change)="
                                                onCheckboxChangeFn($event)
                                            " />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="{{ 'No' | translate }}" [width]="30">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto" fxLayout="column">
                                    <p style="cursor: pointer">
                                        {{ column.name }}
                                    </p>
                                </div>
                            </ng-template>
                            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                ngx-datatable-cell-template>
                                <!-- <p>{{ row?.id }}</p> -->
                                {{ page.size * page.pageNumber + rowIndex + 1 }}
                            </ng-template>
                        </ngx-datatable-column>
                        <!-- 'Name -->
                        <ngx-datatable-column [width]="100" name="{{ 'Customer' | translate }}">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto" fxLayout="column">
                                    <p style="cursor: pointer">
                                        {{ column.name }}
                                    </p>
                                    <input formControlName="customer" type="text" class="form-control" />
                                </div>
                            </ng-template>
                            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                ngx-datatable-cell-template>
                                <!-- <div> -->

                                <div fxLayoutAlign="center center" fxLayout="column">
                                    <strong>
                                        {{ row?.order?.customer?.first_name }}
                                        {{ row?.order?.customer?.last_name }}
                                    </strong>
                                    <div class="mt-4">
                                        <strong>
                                            {{
                                            row?.order?.customer?.contact_no
                                            }}
                                        </strong>
                                    </div>
                                </div>

                                <!-- </div> -->
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- total -->
                        <ngx-datatable-column [width]="70" name="{{ 'Total' | translate }}">
                            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                ngx-datatable-cell-template>
                                <p>
                                    <span *ngIf="row?.is_paid == true" style="color: green">
                                        {{ row?.order?.total }}
                                        {{ row?.order?.currency.code }}
                                    </span>
                                    <span *ngIf="!row?.is_paid" class="text-bold" style="color: red">
                                        {{ row?.order?.total }}
                                        {{ row?.order?.currency.code }}
                                    </span>
                                </p>
                            </ng-template>
                        </ngx-datatable-column>
                        <!-- payment_method -->
                        <ngx-datatable-column [width]="90" name="{{ 'Payment Method' | translate }}">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto" fxLayout="column">
                                    <p style="cursor: pointer">
                                        {{ column.name }}
                                    </p>
                                    <select formControlName="payment_method" class="form-control">
                                        <option *ngFor="
                                                let paymentMethod of PaymentMethodeList
                                            " [value]="paymentMethod?.id">
                                            {{
                                            translationService.getOnlyTranslatedNameNotName_en(
                                            paymentMethod
                                            )
                                            }}
                                        </option>
                                    </select>
                                </div>
                            </ng-template>
                            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                ngx-datatable-cell-template>
                                <p>
                                    {{ row?.order?.payment_method?.name }}
                                </p>
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- Order Date -->
                        <ngx-datatable-column [width]="120" name="{{ 'Order Date' | translate }}">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto"
                                    style="margin-bottom: 5rem !important" fxLayout="column">
                                    <p (click)="sort('order_date')" style="cursor: pointer">
                                        {{ column.name }}
                                        <i *ngIf="
                                                form.get('ordering').value ==
                                                'order_date'
                                            " class="fas fa-sort-up"></i>
                                        <i *ngIf="
                                                form.get('ordering').value ==
                                                '-order_date'
                                            " class="fas fa-sort-down"></i>
                                        <i *ngIf="
                                                form.get('ordering').value !=
                                                    '-order_date' &&
                                                form.get('ordering').value !=
                                                    'order_date'
                                            " class="fas fa-sort"></i>
                                    </p>
                                </div>
                            </ng-template>
                            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                ngx-datatable-cell-template>
                                <p>
                                    {{
                                    row?.order?.order_date
                                    | date : "dd/MM/yyyy "
                                    }}
                                    <br />
                                    {{
                                    row?.order?.order_date
                                    | date : "hh:mm a"
                                    }}
                                </p>
                            </ng-template>
                        </ngx-datatable-column>
                        <!-- external_order_id -->
                        <ngx-datatable-column [width]="70" name="{{ 'External Order Id' | translate }}">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto" fxLayout="column">
                                    <p style="cursor: pointer">
                                        {{ column.name }}
                                    </p>
                                    <input formControlName="external_order_id" type="text" class="form-control" />
                                </div>
                            </ng-template>
                            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                ngx-datatable-cell-template>
                                <div (click)="onClickOrder(row?.order?.id)">
                                    <p>
                                        {{
                                        row?.order?.external_order_id
                                        ? row?.order?.external_order_id
                                        : " - "
                                        }}
                                    </p>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <!-- invoice_no -->
                        <ngx-datatable-column [width]="70" name="{{ 'Invoice No' | translate }}">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto" fxLayout="column">
                                    <p style="cursor: pointer">
                                        {{ column.name }}
                                    </p>
                                    <input formControlName="invoice_no" type="text" class="form-control" />
                                </div>
                            </ng-template>
                            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                ngx-datatable-cell-template>
                                <div (click)="onClickOrder(row?.order?.id)">
                                    <p>
                                        {{ row?.order?.invoice_no }}
                                    </p>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <!-- customer_address -->
                        <ngx-datatable-column [width]="150" name="{{ 'Delivery Address' | translate }}">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto" fxLayout="column">
                                    <p style="cursor: pointer">
                                        {{ "Delivery Address" | translate }}
                                    </p>
                                    <input formControlName="area_name" type="text" class="form-control" />
                                </div>
                            </ng-template>
                            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                ngx-datatable-cell-template>
                                <div title="{{
                                        row?.delivery_address?.area?.name
                                    }}" [matMenuTriggerFor]="afterMenu" fxLayoutAlign="space-between center"
                                    fxLayout="row">
                                    <div fxLayoutAlign="center center" fxLayout="column">
                                        <strong *ngIf="row?.delivery_address?.area2">
                                            {{
                                            row?.delivery_address?.area2
                                            ?.name
                                            }}
                                            ,
                                        </strong>

                                        <div class="mt-4">
                                            <strong>
                                                {{
                                                row?.delivery_address?.area
                                                ?.name
                                                }}
                                            </strong>
                                        </div>
                                    </div>
                                    <div fxLayoutAlign="start start" fxLayout="column">
                                        <strong>
                                            <mat-icon matPostfix>arrow_drop_down
                                            </mat-icon></strong>
                                    </div>
                                </div>
                                <mat-menu #afterMenu="matMenu" xPosition="after"
                                    class="mat-menu-customize orders-mat-menu">
                                    <div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="1em" class="p-8" style="min-width: 150px">
                                        <strong *ngIf="row?.delivery_address" class="highlight-bg-color">Delivery
                                            Address Details
                                        </strong>

                                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row"
                                            fxLayoutGap="1em" fxLayout="column" class="price-rule-discount">
                                            <!-- discount name  -->
                                            <p>
                                                <label>Delivery Details:
                                                </label>

                                                <span>
                                                    <strong style="
                                                            margin-right: 3px;
                                                        ">{{
                                                        row
                                                        ?.delivery_address
                                                        ?.name
                                                        }}</strong>
                                                </span>
                                            </p>

                                            <!-- COUNTREY -->
                                            <p *ngIf="
                                                    row?.delivery_address
                                                        ?.country !== null
                                                ">
                                                <label>Country Name : </label>
                                                <span>{{
                                                    row?.delivery_address
                                                    ?.country?.name
                                                    }}</span>
                                                <span>
                                                    {{
                                                    row?.delivery_address
                                                    ?.country?.label_en
                                                    }}</span>
                                            </p>
                                            <!-- AREA -->
                                            <p>
                                                <label>Area: </label>

                                                <span>
                                                    <strong style="
                                                            margin-right: 3px;
                                                        ">
                                                        <span *ngIf="
                                                                row
                                                                    ?.delivery_address
                                                                    ?.area2
                                                            ">
                                                            {{
                                                            row
                                                            ?.delivery_address
                                                            ?.area2
                                                            ?.name
                                                            }}
                                                            ,
                                                        </span>

                                                        {{
                                                        row
                                                        ?.delivery_address
                                                        ?.area?.name
                                                        }}
                                                    </strong>
                                                </span>
                                            </p>
                                            <!-- address -->
                                            <p *ngIf="
                                                    row?.delivery_address
                                                        ?.address
                                                ">
                                                <label>Address </label>

                                                <span>
                                                    <strong style="
                                                            margin-right: 3px;
                                                        ">{{
                                                        row
                                                        ?.delivery_address
                                                        ?.address
                                                        }}</strong>
                                                </span>
                                            </p>

                                            <p *ngIf="
                                                    row?.delivery_address
                                                        ?.building_no
                                                ">
                                                <label>Building No: </label>

                                                <span>
                                                    <strong style="
                                                            margin-right: 3px;
                                                        ">{{
                                                        row
                                                        ?.delivery_address
                                                        ?.building_no
                                                        }}</strong>
                                                </span>
                                            </p>
                                            <p *ngIf="
                                                    row?.delivery_address
                                                        ?.house_no
                                                ">
                                                <label>House No: </label>

                                                <span>
                                                    <strong style="
                                                            margin-right: 3px;
                                                        ">{{
                                                        row
                                                        ?.delivery_address
                                                        ?.house_no
                                                        }}</strong>
                                                </span>
                                            </p>
                                            <p *ngIf="
                                                    row?.delivery_address
                                                        ?.contact_no
                                                ">
                                                <label>Contact No: </label>

                                                <span>
                                                    <strong style="
                                                            margin-right: 3px;
                                                        ">{{
                                                        row
                                                        ?.delivery_address
                                                        ?.contact_no
                                                        }}</strong>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </mat-menu>
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- delivery_agent_detail -->
                        <ngx-datatable-column [width]="120" name="{{ ' Delivery Agent' | translate }}">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto" fxLayout="column">
                                    <p style="cursor: pointer">
                                        {{ column.name }}
                                    </p>
                                    <input formControlName="delivery_agent_id" type="text" class="form-control" />
                                </div>
                            </ng-template>
                            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                ngx-datatable-cell-template>
                                <div fxLayoutAlign="center center" fxLayout="column">
                                    <strong>
                                        {{
                                        row?.delivery_agent?.first_name
                                        ? row?.delivery_agent
                                        ?.first_name
                                        : " - "
                                        }}

                                        {{ row?.delivery_agent?.last_name }}
                                    </strong>
                                    <div class="mt-4">
                                        <strong>
                                            {{
                                            row?.delivery_agent?.contact_no
                                            ? row?.delivery_agent
                                            ?.contact_no
                                            : " - "
                                            }}
                                        </strong>
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- statusName -->
                        <ngx-datatable-column [width]="90" name="{{ 'Order Status' | translate }}">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto" fxLayout="column">
                                    <p style="cursor: pointer">
                                        {{ column.name }}
                                    </p>
                                    <select formControlName="order_status" class="form-control">
                                        <option value=""></option>
                                        <option *ngFor="let status of orderStatus" [value]="status?.id">
                                            {{ status?.value }}
                                        </option>
                                    </select>
                                </div>
                            </ng-template>
                            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                ngx-datatable-cell-template>
                                <div [style]="{
                                        backgroundColor:
                                            row?.order?.order_status_detail
                                                ?.background_color,
                                        color: row?.order?.order_status_detail
                                            ?.font_color
                                    }" class="status">
                                    {{ row?.order?.order_status_detail?.name }}
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [width]="120" name="{{ ' Delivery Status' | translate }}">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto" fxLayout="column">
                                    <p style="cursor: pointer">
                                        {{ column.name }}
                                    </p>
                                    <select formControlName="delivery_status" class="form-control">
                                        <option value=""></option>
                                        <option *ngFor="
                                                let status of deliveryStatusList
                                            " [value]="status?.id">
                                            {{ status?.name }}
                                        </option>
                                    </select>
                                </div>
                            </ng-template>
                            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                ngx-datatable-cell-template>
                                <div fxLayoutAlign="start start" fxLayout="row ">
                                    <div [style]="{
                                        backgroundColor:
                                            row?.delivery_status_obj
                                                ?.background_color,
                                        color: row?.delivery_status_obj
                                            ?.font_color
                                    }" class="status">
                                        {{ row?.delivery_status_obj?.name }}


                                    </div>
                                    <div *ngIf="
                                    row?.undelivered_reason
                                        !== null
                                " [matMenuTriggerFor]="afterMenuReason" fxLayoutAlign="start start" fxLayout="column">
                                        <strong>
                                            <mat-icon matPostfix>arrow_drop_down
                                            </mat-icon></strong>
                                    </div>
                                </div>
                                <mat-menu #afterMenuReason="matMenu" xPosition="after"
                                    class="mat-menu-customize orders-mat-menu">
                                    <div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="1em" class="p-8" style="min-width: 150px">
                                        <strong class="highlight-bg-color">
                                            UnDelivered Reason
                                        </strong>

                                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row"
                                            fxLayoutGap="1em" fxLayout="column" class="price-rule-discount">

                                            <p *ngIf="
                                                row?.undelivered_reason
                                                    !== null
                                            ">
                                                {{
                                                row?.undelivered_reason?.name

                                                }}

                                            </p>

                                        </div>
                                    </div>
                                </mat-menu>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column [width]="100" name="{{ 'Actions' | translate }}">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div style="margin-bottom: 5rem !important" fxFlex="1 1 auto" fxLayout="column">
                                    <p style="cursor: pointer">
                                        {{ "Actions" | translate }}
                                    </p>
                                </div>
                            </ng-template>

                            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                                ngx-datatable-cell-template>
                                <button mat-stroked-button mat-button [matMenuTriggerFor]="afterMenu">
                                    {{ "Actions" | translate }}
                                    <mat-icon title="View Detail" matPostfix>keyboard_arrow_down</mat-icon>
                                </button>

                                <mat-menu #afterMenu="matMenu" xPosition="after">
                                    <!-- view delivery -->
                                    <button (click)="viewDelivery(row?.id)" class="action-btn-view" mat-menu-item
                                        [routerLink]="['/delivery', row.id]">
                                        <mat-icon class="action-btn-view" title="View delivery details"
                                            matPrefix>pageview
                                        </mat-icon>
                                        {{ "View" | translate }}
                                    </button>
                                    <button class="action-btn-delete" mat-menu-item (click)="SavePdf(row?.order?.id)">
                                        <mat-icon class="action-btn-delete" title="Edit Slider"
                                            matPrefix>print</mat-icon>
                                        {{ "Order" | translate }}
                                    </button>

                                    <button class="action-btn-edit" mat-menu-item
                                        (click)="SaveInvoicePdf(row?.order?.id)">
                                        <mat-icon class="action-btn-edit" title="Edit Slider" matPrefix>print</mat-icon>
                                        {{ "Invoice" | translate }}
                                    </button>
                                    <!-- change delivery status -->
                                    <button *ngIf="
                                            row?.delivery_status_obj
                                                ?.can_assign_agent
                                        " class="action-change-password" mat-menu-item
                                        (click)="changeDeliveryStatus(row)">
                                        <mat-icon class="action-change-password" title="Assign Delivery Agent "
                                            matPrefix>
                                            track_changes
                                        </mat-icon>
                                        {{
                                        "Change Delivery Status "
                                        | translate
                                        }}
                                    </button>
                                    <!-- "Assign Delivery Agent" -->
                                    <button *ngIf="
                                            row?.delivery_status_obj
                                                ?.can_assign_agent
                                        " class="action-send" mat-menu-item (click)="assignDeliveryAgent(row)">
                                        <mat-icon class="action-send" title="Assign Delivery Agent " matPrefix>
                                            local_shipping
                                        </mat-icon>
                                        {{
                                        "Assign Delivery Agent " | translate
                                        }}
                                    </button>
                                    <!-- change status -->
                                </mat-menu>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
</div>