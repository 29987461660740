<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
            [formGroup]="successForm">
            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back</mat-icon>
                    <label class="highlight-text-color">{{'Success Page'}}</label>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <button (click)="save()" type="submit" mat-flat-button style="position: absolute;right: 16px;"
                        class="primary-bg-color">{{'Save' | translate}}</button>
                </div>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 center-container" fxLayoutAlign="start stretch"
                    fxLayout="column" fxLayoutGap="1em">
                    <label class="highlight-text-color">{{'Success Page Settings' | translate}}</label>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start flex-end" fxLayout="column" fxLayoutGap="0px">
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="language">
                                    <mat-option value="en">{{'EN' | translate}}</mat-option>
                                    <mat-option value="ar">{{'AR' | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row" fxLayout.lt-md="column"
                            fxLayoutGap="1em">
                            <div fxFlex="1 1 auto">
                                <mat-form-field *ngIf="successForm.get('language').value=='en'" appearance="outline"
                                    fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Success Message (EN)'| translate}}
                                    </mat-label>
                                    <textarea formControlName="success_message_en" matInput rows="10"></textarea>
                                </mat-form-field>
                                <mat-form-field *ngIf="successForm.get('language').value=='ar'" appearance="outline"
                                    fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Success Message (AR)'| translate}}
                                    </mat-label>
                                    <textarea formControlName="success_message_ar" matInput rows="10"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>