import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";

import { navigation } from "app/navigation/navigation";
import { ApiService } from "app/services/api.service";
import { TranslationService } from "app/services/translationService";
import { fuseAnimations } from "@fuse/animations";
import { NavigationEnd, Router } from "@angular/router";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { CoreService } from "app/services/core-service";
import { ChangeUserPasswordPopoverComponent } from "app/main/components/change-user-password-popover/change-user-password-popover.component";
import { UtilitiesService } from "app/services/utilitiesService";
import { MatDialog } from "@angular/material/dialog";
import { DOCUMENT } from "@angular/common";
import { environment } from "environments/environment";
import { Location } from "@angular/common";
import { SwUpdate } from "@angular/service-worker";

@Component({
    selector: "toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    viewSite: any;
    language: any;
    url: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private apiService: ApiService,
        private translationService: TranslationService,
        private router: Router,
        public utilitiesService: UtilitiesService,
        private _fuseNavigationService: FuseNavigationService,
        public coreService: CoreService,
        private dialog: MatDialog,
        private location: Location,
    ) {

        this.viewSite = this.apiService.BASE_URL;
        // Set the defaults
        this.userStatusOptions = [
            {
                title: "Online",
                icon: "icon-checkbox-marked-circle",
                color: "#4CAF50",
            },
            {
                title: "Away",
                icon: "icon-clock",
                color: "#FFC107",
            },
            {
                title: "Do not Disturb",
                icon: "icon-minus-circle",
                color: "#F44336",
            },
            {
                title: "Invisible",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#BDBDBD",
            },
            {
                title: "Offline",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#616161",
            },
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    viewSiteFn() {
        window.open(this.viewSite, "_blank");
    }

    async logout() {
        localStorage.clear();
        this.router.navigate(["login"]);
    }
    ngOnInit(): void {
        this.language = localStorage.getItem("language")
            ? localStorage.getItem("language")
            : "en";
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === "top";
                this.rightNavbar = settings.layout.navbar.position === "right";
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    goToHome() {
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#"));
            clean_uri = `${clean_uri}index.html`;
            window.history.replaceState({}, document.title, clean_uri);
            document.location.reload()
        }
    }

    changePassword() {
        let isAdmin = true;
        let isMobile = this.utilitiesService.isMobile();
        let size = {
            width: "50vw",
            height: "50vh",
        };
        if (isMobile) {
            size.height = "100%";
            size.width = "100%";
        }
        const dialogRef = this.dialog.open(ChangeUserPasswordPopoverComponent, {
            data: { isAdmin },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });

        dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
                try {
                    // this.getUsers();
                } catch {
                } finally {
                }
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
    }

    changeLanguage() {
        this.language == "en" ? this.setArbic() : this.setEnglish();
    }
    setArbic() {
        this.language = "ar";
        this.translationService.useLanguage(this.language);
    }
    setEnglish() {
        this.language = "en";
        this.translationService.useLanguage(this.language);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }
}
