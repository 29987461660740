import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IconCss } from "app/main/ngxDatatable-Icons/icons";
import { CoreService } from "app/services/core-service";
import { CustomerService } from "app/services/customer/customer.service";
import { UtilitiesService } from "app/services/utilitiesService";

@Component({
    selector: "app-customer-orders-table",
    templateUrl: "./customer-orders-table.component.html",
    styleUrls: ["./customer-orders-table.component.scss"],
})
export class CustomerOrdersTableComponent implements OnInit {
    @Input() customerID: any = "";
    @Output() viewOrderHandler: EventEmitter<void> = new EventEmitter<any>();
    @Output() orderCountHandler: EventEmitter<void> = new EventEmitter<any>();
    icons = IconCss;
    iconsCss = {
        pagerLeftArrow: this.icons.left,
        pagerRightArrow: this.icons.right,
        pagerPrevious: this.icons.previous,
        pagerNext: this.icons.next,
    };
    page = {
        size: 10,
        totalElements: 0,
        pageNumber: 0,
    };
    orders = [];
    offset = 0;
    isLoading: boolean;
    constructor(
        private customerService: CustomerService,
        public utilitiesService: UtilitiesService,
        private coreService: CoreService
    ) {}

  ngOnInit(): void {
      this.getCustomerOrders()
    }
    getWidth() {
        this.orders = [...this.orders];
        return 70;
    }

    async getCustomerOrders(limit = this.page.size, offset = this.offset) {
        try {
            this.isLoading = true;
            const orders = await this.customerService
                .getCustomerOrders(this.customerID, limit, offset)
                .toPromise();
            if (orders) {
                this.orders = orders.results;
                this.page.totalElements = orders.count;
                this.orderCountHandler.emit(orders.count);
                this.isLoading = false;
            }
        } catch {
            this.isLoading = false;
        } finally {
        }
    }
    pageChange(event) {
        this.page.pageNumber = event.offset;
        this.offset = this.page.pageNumber * this.page.size;
        this.getCustomerOrders();
    }
    onActivateViewOrders(event) {
        if (event.type == "click") {
            if (
                event.event.srcElement.nodeName.toLowerCase() == "span" ||
                event.event.srcElement.nodeName.toLowerCase() == "mat-icon" ||
                event.event.srcElement.nodeName.toLowerCase() == "button"
            ) {
            } else {
                if (
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.OrderDetail
                    )
                ) {
                    this.viewOrderHandler.emit(event.row.id);
                } else {
                    this.utilitiesService.showPermissionDeinedMessage();
                }
            }
        }
    }
}
