<!-- <div *ngIf="!isCopy" fxFlex="0 0 100%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
    <div *ngIf="!loading" fxFlex="0 0 auto" fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="1em">
        <label class="highlight-text-color">{{'Media List' | translate}}</label>
        <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="1em">
            <button *ngIf="formControl.value && formControl.value?.length >0 " (click)="add()" mat-stroked-button
                color="accent">{{'Select' | translate}}</button>
            <mat-icon [mat-dialog-close]="false" class="cursor-pointer">close</mat-icon>
        </div>
    </div>
    <mat-spinner diameter="50" strokeWidth="5" style="position: absolute;left: 50%;top: 50%;" *ngIf="loading">
    </mat-spinner>
    <div *ngIf="!loading" fxFlex="0 0 auto" fxLayoutAlign="start start" fxLayout="column" fxLayoutGap="1em">
        <div fxFlex="0 0 0px" fxLayoutAlign="start start" fxLayout.gt-sm="row wrap" fxLayout.lt-md="column"
            fxLayoutGap="2px">
            <div *ngFor="let item of  mediaList;let i =index" fxFlex="0 0 auto" fxLayoutGap="2px">
                <div style="border: 1px solid #FFC138;" class="p-8 cursor-pointer mb-8" Flex="0 0 auto"
                    fxLayoutAlign="start start">
                    <mat-checkbox (change)="checkboxChange($event.checked,item.id)" [(ngModel)]="checkbox[item.id]"
                        class="example-margin"></mat-checkbox>
                    <img (click)="checkboxChange(!this.clicked[item.id]  ? true : false,item.id)"
                        [src]="utilitiesService.bypassTrustedURL(item?.file)">
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isCopy" fxFlex="0 0 100%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
    <div *ngIf="!loading" fxFlex="0 0 auto" fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="1em">
        <label class="highlight-text-color">{{'Click to copy' | translate}}</label>
        <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="1em">
            <mat-icon [mat-dialog-close]="false" class="cursor-pointer">close</mat-icon>
        </div>
    </div>
    <mat-spinner diameter="50" strokeWidth="5" style="position: absolute;left: 50%;top: 50%;" *ngIf="loading">
    </mat-spinner>
    <div *ngIf="!loading" fxFlex="0 0 auto" fxLayoutAlign="start start" fxLayout="column" fxLayoutGap="1em">
        <div fxFlex="0 0 0px" fxLayoutAlign="start start" fxLayout.gt-sm="row wrap" fxLayout.lt-md="column"
            fxLayoutGap="2px">
            <div *ngFor="let item of  mediaList;let i =index" fxFlex="0 0 auto" fxLayoutGap="2px">
                <div style="border: 1px solid #FFC138;" class="p-8 cursor-pointer mb-8" Flex="0 0 auto"
                    fxLayoutAlign="start start">
                    <img (click)="copyImage(item?.file)" [src]="utilitiesService.bypassTrustedURL(item?.file)">
                </div>
            </div>
        </div>
    </div>
</div> -->

<mat-spinner diameter="50" strokeWidth="5" style="position: absolute;left: 50%;top: 50%;" *ngIf="loading">
</mat-spinner>
<div fxFlex="0 0 100%" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
    <div *ngIf="!loading" fxFlex="0 0 auto" fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="1em">
        <label class="highlight-text-color">{{'Media List' | translate}}</label>
        <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="1em">
            <ng-container *ngIf="!isCopy">
                <button *ngIf="formControl.value && formControl.value?.length >0" (click)="add()" mat-stroked-button
                    color="accent">{{'Select' | translate}}</button>
            </ng-container>
            <mat-icon [mat-dialog-close]="false" class="cursor-pointer">close</mat-icon>
        </div>
    </div>
    <div class="gallery" id="gallery" *ngIf="!isCopy && !loading && showOnly">
        <ng-container *ngFor="let item of  mediaList;let i =index">
            <ng-container *ngIf="item?.docFormat == mediaTypes.Video && showOnly == mediaTypes.Video">
                <div class="gallery-item">
                    <mat-checkbox (change)="checkboxChange($event.checked,item.id)" [(ngModel)]="checkbox[item.id]"
                        class="example-margin">
                    </mat-checkbox>
                    <div class="content">
                        <app-video-play [isEnableOperations]="false"
                            (click)="checkboxChange(!this.clicked[item.id]  ? true : false,item.id)" [id]="item?.id"
                            [src]="item?.file" [index]="i" [type]="item?.docFormat">
                        </app-video-play>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="item?.docFormat == mediaTypes.Audio && showOnly == mediaTypes.Audio">
                <div class="gallery-item">
                    <mat-checkbox (change)="checkboxChange($event.checked,item.id)" [(ngModel)]="checkbox[item.id]"
                        class="example-margin">
                    </mat-checkbox>
                    <div class="p-8 cursor-pointer" fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayout="column">
                        <div class="relative">
                            <app-video-play [isEnableOperations]="false"
                                (click)="checkboxChange(!this.clicked[item.id]  ? true : false,item.id)" [id]="item?.id"
                                [src]="item?.file" [index]="i" [type]="item?.docFormat">
                            </app-video-play>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="item?.docFormat == mediaTypes.Image && showOnly == mediaTypes.Image">
                <div class="gallery-item">
                    <mat-checkbox (change)="checkboxChange($event.checked,item.id)" [(ngModel)]="checkbox[item.id]"
                        class="example-margin">
                    </mat-checkbox>
                    <div class="p-8 cursor-pointer" fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayout="column">
                        <div class="relative">
                            <img (click)="checkboxChange(!this.clicked[item.id]  ? true : false,item.id)" class="w-84"
                                [src]="item?.file">
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="showOnly == mediaTypes.Text && item?.docFormat == mediaTypes.Text">
                <div class="gallery-item">
                    <mat-checkbox (change)="checkboxChange($event.checked,item.id)" [(ngModel)]="checkbox[item.id]"
                        class="example-margin">
                    </mat-checkbox>
                    <div class="p-8 cursor-pointer" fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayout="column">
                        <div class="relative">
                            <img (click)="checkboxChange(!this.clicked[item.id]  ? true : false,item.id)"
                                class="w-84 icon" src="assets/images/file.png">
                            <div *ngIf="item?.docFormat != 'image'" class="icon-text"
                                [ngStyle]="{'background-color':  item?.docColor}">
                                {{item?.docType}}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="showOnly == mediaTypes.Doc && item?.docFormat == mediaTypes.Doc">
                <div class="gallery-item">
                    <mat-checkbox (change)="checkboxChange($event.checked,item.id)" [(ngModel)]="checkbox[item.id]"
                        class="example-margin">
                    </mat-checkbox>
                    <div class="p-8 cursor-pointer" fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayout="column">
                        <div class="relative">
                            <img (click)="checkboxChange(!this.clicked[item.id]  ? true : false,item.id)"
                                class="w-84 icon" src="assets/images/file.png">
                            <div *ngIf="item?.docFormat != 'image'" class="icon-text"
                                [ngStyle]="{'background-color':  item?.docColor}">
                                {{item?.docType}}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="showOnly == mediaTypes.PDF && item?.docFormat == mediaTypes.PDF">
                <div class="gallery-item">
                    <mat-checkbox (change)="checkboxChange($event.checked,item.id)" [(ngModel)]="checkbox[item.id]"
                        class="example-margin">
                    </mat-checkbox>
                    <div class="p-8 cursor-pointer" fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayout="column">
                        <div class="relative">
                            <img (click)="checkboxChange(!this.clicked[item.id]  ? true : false,item.id)"
                                class="w-84 icon" src="assets/images/file.png">
                            <div *ngIf="item?.docFormat != 'image'" class="icon-text"
                                [ngStyle]="{'background-color':  item?.docColor}">
                                {{item?.docType}}
                            </div>
                        </div>

                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="showOnly == mediaTypes.Excel && item?.docFormat == mediaTypes.Excel">
                <div class="gallery-item">
                    <mat-checkbox (change)="checkboxChange($event.checked,item.id)" [(ngModel)]="checkbox[item.id]"
                        class="example-margin">
                    </mat-checkbox>
                    <div class="p-8 cursor-pointer" fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayout="column">
                        <div class="relative">
                            <img (click)="checkboxChange(!this.clicked[item.id]  ? true : false,item.id)"
                                class="w-84 icon" src="assets/images/file.png">
                            <div *ngIf="item?.docFormat != 'image'" class="icon-text"
                                [ngStyle]="{'background-color':  item?.docColor}">
                                {{item?.docType}}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="gallery" id="gallery" *ngIf="!isCopy && !loading && !showOnly">
        <ng-container *ngFor="let item of  mediaList;let i =index">
            <div class="gallery-item"
                *ngIf="item?.docFormat == mediaTypes.Video || item?.docFormat == mediaTypes.Audio">
                <mat-checkbox (change)="checkboxChange($event.checked,item.id)" [(ngModel)]="checkbox[item.id]"
                    class="example-margin">
                </mat-checkbox>
                <div class="content">
                    <app-video-play [isEnableOperations]="false" (click)="copyImage(item?.file)" [id]="item?.id"
                        [src]="item?.file" [index]="i" [type]="item?.docFormat">
                    </app-video-play>
                </div>
            </div>
            <div class="gallery-item" *ngIf="item?.docFormat == mediaTypes.Image">
                <mat-checkbox (change)="checkboxChange($event.checked,item.id)" [(ngModel)]="checkbox[item.id]"
                    class="example-margin">
                </mat-checkbox>
                <div class="content">
                    <div class="p-8 cursor-pointer" fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayout="column">
                        <div class="relative">
                            <img (click)="copyImage(item?.file)" class="w-84"
                                [src]="utilitiesService.bypassTrustedURL(item?.file)">
                        </div>
                    </div>
                </div>
            </div>
            <div class="gallery-item"
                *ngIf="item?.docFormat == mediaTypes.Text || item?.docFormat == mediaTypes.Doc || item?.docFormat == mediaTypes.PDF || item?.docFormat == mediaTypes.Excel">
                <mat-checkbox (change)="checkboxChange($event.checked,item.id)" [(ngModel)]="checkbox[item.id]"
                    class="example-margin">
                </mat-checkbox>
                <div class="content">
                    <div class="p-8 cursor-pointer" fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayout="column">
                        <div class="relative">
                            <img (click)="copyImage(item?.file)" class="w-84 icon" src="assets/images/file.png">
                            <div *ngIf="item?.docFormat != 'image'" class="icon-text"
                                [ngStyle]="{'background-color':  item?.docColor}">
                                {{item?.docType}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>
    <div class="gallery" id="gallery" *ngIf="isCopy && !loading">
        <div class="gallery-item" *ngFor="let item of  mediaList;let i =index">
            <div class="content">
                <ng-container *ngIf="item?.docFormat == mediaTypes.Video || item?.docFormat == mediaTypes.Audio">
                    <app-video-play [isEnableOperations]="false" (click)="copyImage(item?.file)" [id]="item?.id"
                        [src]="item?.file" [index]="i" [type]="item?.docFormat">
                    </app-video-play>
                </ng-container>

                <ng-container *ngIf="item?.docFormat == mediaTypes.Image">
                    <div class="p-8 cursor-pointer" fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayout="column">
                        <div class="relative">
                            <img (click)="copyImage(item?.file)" class="w-84"
                                [src]="utilitiesService.bypassTrustedURL(item?.file)">
                        </div>
                    </div>
                </ng-container>

                <ng-container
                    *ngIf="item?.docFormat == mediaTypes.Text || item?.docFormat == mediaTypes.Doc || item?.docFormat == mediaTypes.PDF || item?.docFormat == mediaTypes.Excel">
                    <div class="p-8 cursor-pointer" fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayout="column">
                        <div class="relative">
                            <img (click)="copyImage(item?.file)" class="w-84 icon" src="assets/images/file.png">
                            <div *ngIf="item?.docFormat != 'image'" class="icon-text"
                                [ngStyle]="{'background-color':  item?.docColor}">
                                {{item?.docType}}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>