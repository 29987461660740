import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { ElementHistoryComponent } from "app/main/components/element-history/element-history.component";
import { IconCss } from "app/main/ngxDatatable-Icons/icons";
import { CustomerService } from "app/services/customer/customer.service";
import { SharedService } from "app/services/sharedService";
import { UtilitiesService } from "app/services/utilitiesService";
import { debounceTime } from "rxjs/operators";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";

@Component({
    selector: "app-conditions-list",
    templateUrl: "./conditions-list.component.html",
    styleUrls: ["./conditions-list.component.scss"],
    animations: fuseAnimations,
})
export class ConditionsListComponent implements OnInit {
    @Input() audienceID: any;
    isLoading: boolean = false;
    isCreateCondition: boolean = false;
    icons = IconCss;
    conditionList = [];
    iconsCss = {
        pagerLeftArrow: this.icons.left,
        pagerRightArrow: this.icons.right,
        pagerPrevious: this.icons.previous,
        pagerNext: this.icons.next,
    };
    page = {
        size: 0,
        totalElements: 0,
        pageNumber: 0,
    };
    enableIcon = false;
    isEnableBack = false;
    conditionID: any = "";
    offset: number = 0;
    label = {
        title_en: "",
        title_ar: " ",
    };
    searchKey: any = "";
    form: FormGroup;
    constructor(
        private customerService: CustomerService,
        private dialog: MatDialog,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {
        this.page.totalElements = 0;
        this.page.pageNumber = 0;
        this.page.size = 10;
    }

    async ngOnInit(): Promise<void> {
        this.form = this.fb.group({
            condition: [null],
        });
        this.label.title_ar = "الظروف ";
        this.label.title_en = "Conditions";
        if (this.audienceID) {
            await this.getConditionsList();
            this.form.valueChanges
                .pipe(debounceTime(1000))
                .subscribe((data) => {
                    this.getConditionsList();
                });
        }
    }

    async getConditionsList(
        limit = this.page.size,
        offset = this.offset,
        form = this.form.controls,
        audience = this.audienceID
    ) {
        this.utilitiesService.startLoader();
        try {
            const conditionList = await this.customerService
                .getConditions(limit, offset, this.searchKey, form, audience)
                .toPromise();
            if (conditionList) {
                this.conditionList = conditionList.results;
                this.page.totalElements = conditionList.count;
            }
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }
    createCondition() {
        this.enableIcon = false;
        this.isEnableBack = true;
        this.label.title_ar = "خلق الظروف";
        this.label.title_en = "Create Condition";
        this.conditionID = "";
        this.isCreateCondition = true;
    }

    pageChange(event) {
        this.page.pageNumber = event.offset;
        this.offset = this.page.pageNumber * this.page.size;
        document.getElementById("toolbar").scrollIntoView();
        this.getConditionsList();
    }

    getWidth() {
        this.conditionList = [...this.conditionList];
        return 100;
    }
    limitChange() {
        this.getConditionsList();
    }
    handleCancel() {
        document.getElementById("toolbar").scrollIntoView();
        this.enableIcon = false;
        this.isEnableBack = false;
        this.label.title_ar = "الظروف";
        this.label.title_en = "Conditions";
        this.conditionID = "";
        this.isCreateCondition = false;
        this.getConditionsList();
    }
    onActivate(event) {
        if (event.type == "click") {
            if (
                event.event.srcElement.nodeName.toLowerCase() == "span" ||
                event.event.srcElement.nodeName.toLowerCase() == "mat-icon" ||
                event.event.srcElement.nodeName.toLowerCase() == "button"
            ) {
            } else {
                this.updateCondition(event.row.id, event.row.condition);
            }
        }
    }

    // deleteMenu(id, name) {
    //     let content = `Are you sure, Do you want to delete  ${name} ? `;
    //     let heading = "Delete";
    //     let fromApp = false;
    //     let size = this.utilitiesService.isMobileAlertModal();
    //     const dialogRef = this.dialog.open(AlertModalComponent, {
    //         data: { content, heading, fromApp },
    //         maxWidth: "",
    //         width: `${size.width}`,
    //         height: `${size.height}`,
    //     });
    //     dialogRef.afterClosed().subscribe((resp) => {
    //         if (resp) {
    //             let deleteMsg = this.translate.instant(
    //                 `Navigation menu deleted successfully`
    //             );
    //             try {
    //                 this.navMenuService.deleteNavMenu(id).subscribe((data) => {
    //                     this.utilitiesService.showSuccessToast(deleteMsg);
    //                     this.getConditionsList();
    //                 });
    //             } catch {
    //             } finally {
    //             }
    //         }
    //     });
    // }

    deleteCondition(id, name) {
        let content = `Are you sure, Do you want to delete  ${name} ? `;
        let heading = "Delete";
        let fromApp = false;
        let size = this.utilitiesService.isMobileAlertModal();
        const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });
        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                let deleteMsg = this.translate.instant(
                    `Condition deleted successfully`
                );
                try {
                    this.customerService
                        .deleteCondition(id)
                        .subscribe((data) => {
                            this.utilitiesService.showSuccessToast(deleteMsg);
                            this.getConditionsList();
                        });
                } catch {
                } finally {
                }
            }
        });
    }
    updateCondition(id, name) {
        this.enableIcon = true;
        this.isEnableBack = true;
        this.label.title_ar = "تحديث الحالة" + ` "${name}"`;
        this.label.title_en = "Update Condition" + ` "${name}"`;
        this.conditionID = id;
        this.isCreateCondition = true;
    }

    search(searchKey) {
        this.searchKey = searchKey;
        this.getConditionsList();
    }
    openHistory() {
        let pageTitle = "Condition";
        let historyURL =
            this.sharedService.conditionHistory + `${this.conditionID}/`;
        let isMobile = this.utilitiesService.isMobile();
        let size = {
            width: "50%",
            maxWidth: "50%",
        };
        if (isMobile) {
            size.width = "100%";
            size.maxWidth = "100%";
        }
        const dialogRef = this.dialog.open(ElementHistoryComponent, {
            data: { historyURL, pageTitle },
            maxWidth: size.maxWidth,
            width: size.width,
            height: "100%",
            // panelClass: "mat-dialog-height-transition",
            position: { right: "0%", left: "50%" },
            // panelClass: "mat-dialog-height-transition",
            panelClass: [!isMobile ? "mat-dialog-height-transition" : ""],
        });
        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
            }
        });
    }
}
