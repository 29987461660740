import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DeliveryService } from "app/services/delivery.service";
import { OrderService } from "app/services/orders/order.service";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { WalletService } from "app/services/wallet/wallet.service";
import { ChangeDeliveryAgentStatusComponent } from "../change-delivery-agent-status/change-delivery-agent-status.component";
import { fuseAnimations } from "@fuse/animations";

@Component({
    selector: "app-add-update-undelivery-reason",
    templateUrl: "./add-update-undelivery-reason.component.html",
    styleUrls: ["./add-update-undelivery-reason.component.scss"],
    animations: fuseAnimations,
})
export class AddUpdateUndeliveryReasonComponent implements OnInit {
    isLoading: boolean = true;
    form: FormGroup;
    reasonDetails: any = null;
    reasonName: any = null;
    label: string = "Add Undelivery Reason ";
    reasonId: any;
    constructor(
        public dialogRef: MatDialogRef<ChangeDeliveryAgentStatusComponent>,
        private formBuilder: FormBuilder,
        private utilitiesService: UtilitiesService,

        private deliveryService: DeliveryService,
        public cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data) {
            this.reasonDetails = data?.reasonDetails;
            this.reasonId = data?.reasonDetails?.id;
            this.reasonName = data?.reasonDetails?.name;
            this.label = `Update ${this.reasonName} Reason`;
        }
        this.formInitialize();
    }

    async ngOnInit() {
        // await this.formInitialize();
    }

    async formInitialize() {
        this.form = this.formBuilder.group({
            id: [this.reasonDetails?.id],
            name: [this.reasonDetails?.name, Validators.required],
            name_ar: [this.reasonDetails?.name_ar, Validators.required],
        });
        this.isLoading = false;
    }
    async updateUnDeliveredReason() {
        const form = this.form.value;
        try {
            this.utilitiesService.startLoader();
            this.isLoading = true;
            await this.deliveryService
                .updateUnDeliveredReason(this.reasonId, form)
                .subscribe((res) => {
                    if (res) {
                        this.utilitiesService.stopLoader();
                        this.cdr.markForCheck();
                        this.dialogRef.close(true);
                    }
                    this.isLoading = false;
                    this.utilitiesService.stopLoader();
                });
        } catch {
            this.isLoading = false;
        } finally {
        }
    }
    async saveUnDeliveredReason() {
        const form = this.form.value;
        try {
            this.utilitiesService.startLoader();
            this.isLoading = true;
            await this.deliveryService
                .addUnDeliveredReason(form)
                .subscribe((res) => {
                    if (res) {
                        this.utilitiesService.stopLoader();
                        this.cdr.markForCheck();
                        this.dialogRef.close(true);
                    }
                    this.isLoading = false;
                    this.utilitiesService.stopLoader();
                });
        } catch {
            this.isLoading = false;
        } finally {
        }
    }
    async submit() {
        if (this.form.valid) {
            if (this.reasonDetails?.id) {
                await this.updateUnDeliveredReason();
            } else {
                await this.saveUnDeliveredReason();
            }
        }
    }
    close() {
        this.dialogRef.close(null);
    }
}
