<div fxLayoutAlign="start stretch" fxFlex="0 0 100%" fxLayout="column">
    <div fxLayoutAlign="start stretch" fxFlex="0 0 100%" fxLayout="column" fxLayoutGap="1em">
        <div class="mat-title highlight-text-color">{{'Paydo Shipping App' | translate}}</div>
        <div fxFlex="0 0 auto">
            <span>
                {{'Paydo Shipping App is built to provide fulfillment services for the shopify orders' | translate}}.
                {{' Shopify stores can be integrated with Paydo by using the private apps feature' | translate}}.
                {{'The app provides shipping rates and pushes order to Paydo system for fulfillment' | translate}}.
                {{'The Paydo Fulfilment app requires Shopifys' | translate}}
                {{'carrier-calculated shipping service to display shipping options on the checkout page' | translate}}
                {{'If you are on Advanced Shopify, Shopify Plus or an annual plan, you will have this service enabled by default However,' | translate}}
                {{'if you are on the Shopify Basic plan, you need to enable the carrier-calculated shipping service before installing the app' | translate}}

            </span>
        </div>

        <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start stretch">
            <div fxFlex="0 0 auto">
                {{'To enable the carrier-calculated shipping service, you can choose to either:' | translate}}
            </div>
            <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="1em">
                <ul>
                    <li>
                        {{'Reach out to Shopify’s customer support to have this service enabled on your existing plan at an extra cost' | translate}}
                    </li>
                    <li>
                        {{'Switch to the annual billing plan with Shopify' | translate}}
                    </li>
                    <li>
                        {{'Upgrade to Advanced Shopify plan or Shopify Plus' | translate}}
                    </li>
                </ul>
            </div>
            <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start stretch">
                <span>{{'Enable Private apps development from Apps -> Manage Private Apps to create the Paydo Fulfillment private app' | translate}}</span>
            </div>
            <div class="img-div">
                <img src="assets/images/shopify_documentation/26.jpeg">
            </div>
            <div fxFlex="0 0 auto">
                {{'Steps to integrate Paydo into Shopify' | translate}}.
            </div>
            <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="1em">
                <ol>
                    <li>
                        {{'To create a private app in your Shopify Store' | translate}},
                        {{' go to Apps from the side menu on Shopify and click on the' | translate}}
                        <strong> “Manage Private Apps”</strong> <span>{{'at the bottom' | translate}}</span>

                        <div class="img-div">
                            <img src="assets/images/shopify_documentation/1.png">
                            <img src="assets/images/shopify_documentation/2.png" style="margin-top: 5px;">
                        </div>

                    </li>
                    <li>
                        {{'Add the app details as follows' | translate}}.
                        <div class="img-div">
                            <img src="assets/images/shopify_documentation/3.png">
                        </div>
                    </li>
                    <li>
                        {{'Set the required permissions' | translate}}.
                        {{' The Paydo Shipping App requires permissions to the following scopes' | translate}} <br>
                        <strong>{{'read_fulfillments' | translate}} ,</strong>
                        <strong>{{'write_fulfillments' | translate}} ,</strong>
                        <strong>{{'read_orders' | translate}} ,</strong>
                        <strong>{{'write_orders' | translate}} ,</strong>
                        <strong>{{'read_shipping' | translate}} ,</strong>
                        <strong>{{'write_shipping' | translate}} ,</strong>
                        <strong>{{'read_products' | translate}} ,</strong>
                        <strong>{{'write_products' | translate}} ,</strong>
                        <strong>{{'read_inventory' | translate}} ,</strong>
                        <strong>{{'write_inventory' | translate}} ,</strong>

                        <div class="img-div">
                            <img src="assets/images/shopify_documentation/4.jpeg">
                        </div>
                    </li>
                    <li>
                        {{'After saving the permissions' | translate}},
                        {{' the private app credentials will appear' | translate}}.
                        {{' Add these credentials in the Shopify Store Setup on Paydo' | translate}}<br>
                        <div class="img-div">
                            <img src="assets/images/shopify_documentation/5.png">
                        </div>
                    </li>
                    <li>
                        {{'Install the Paydo services on Shopify by clicking on the' | translate}}
                        <strong>{{'“Install”' | translate}}</strong> <span>{{'button' | translate}}</span>
                        <!-- <div class="img-div">
                            <img src="assets/images/shopify_documentation/5.png">
                        </div> -->
                    </li>
                </ol>
                <div fxFlex="0 0 auto" fxLayout="column">
                    <div fxFlex="0 0 auto">
                        {{'After successfully integrating Paydo and Shopify' | translate}}.
                        {{' Setup the Shipping zones and Products on shopify' | translate}}
                    </div>
                    <ol>
                        <li>
                            {{'Setup the shipping zones and configure the rates from Paydo' | translate}}.
                            {{' Go to Setting from the side menu on shopify' | translate }}.
                            {{'Go to' | translate}}<span><strong>{{'“Shipping and delivery”' | translate}}</strong>
                                <span>{{' and click' | translate}}</span><strong>{{' “Manage Rates” ' | translate}}</strong>
                                <span>{{'to add the shipping zones' | translate}}</span></span>
                            <div class="img-div">
                                <img src="assets/images/shopify_documentation/20.PNG">
                                <img src="assets/images/shopify_documentation/21.png">
                                <img src="assets/images/shopify_documentation/22.png">
                            </div>
                        </li>
                        <li>
                            {{'Create a new shipping zone and choose the allowed shipping countries' | translate}}
                            {{' To Add Rates for the shipping zone' | translate }},
                            {{' select option to' | translate}}
                            <span><strong>{{'“Use the carrier or app to calculate rates”' | translate}}</strong>.{{' Choose Paydo Carrier from the list' | translate}},{{' check Shipping rates option and save' | translate}}</span>
                            <div class="img-div">
                                <img src="assets/images/shopify_documentation/23.png">
                                <img src="assets/images/shopify_documentation/24.png">
                            </div>
                        </li>
                    </ol>
                </div>
                <div fxFlex="0 0 auto" fxLayout="column">
                    {{'Configure Paydo for fulfillment of the products' | translate}}
                    <ol>
                        <li>
                            {{'Set the Inventory managed by option to Paydo Fulfillment and add the SKU as Paydo Id for the product' | translate}}
                            <div class="img-div">
                                <img src="assets/images/shopify_documentation/25.png">
                            </div>
                        </li>
                    </ol>
                    <div fxLayout="column" fxFlex="0 0 auto">
                        {{'The orders will be added to the Paydo Delivery System as soon as they are created on shopify' | translate}}.
                        {{' The system will update the tracking status on delivery' | translate}}.
                    </div>
                </div>
                <div fxFlex="0 0 auto" fxLayout="column">
                    {{'To uninstall the Paydo Services on Shopify' | translate}}.
                    <ol>
                        <li>
                            {{'To remove the Paydo Shipping Services' | translate}},
                            {{'go to the Shopify app settings on Paydo and click on the ' | translate}}
                            <span><strong>{{'Uninstall' | translate}}</strong>{{' button' | translate}}.</span>

                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>