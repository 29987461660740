import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { debounceTime } from "rxjs/operators";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";

@Component({
    selector: "app-sms-config",
    templateUrl: "./sms-config.component.html",
    styleUrls: ["./sms-config.component.scss"],
    animations: fuseAnimations,
})
export class SmsConfigComponent implements OnInit {
    @Input() selectedBlock: any;
    @Input() appDetail: any;
    @Output() back: EventEmitter<void> = new EventEmitter<any>();
    @Output() saveHander: EventEmitter<void> = new EventEmitter<any>();
    form: FormGroup;
    configList = [];
    notesInternational = [];
    notesLocal = [];
    config: any;
    constructor(
        public utilitiesService: UtilitiesService,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private appService: AppService,
        private translate: TranslateService
    ) { }
    formInit() {
        this.form = this.fb.group({
            id: [null],
            fc_sms_password: [""],
            fc_sms_iid: [""],
            fc_sms_uid: [""],
            sender_local_id: [""],
            sms_international_token: [""],
            sender_international_id: [""]
        });

    }
    ngOnInit(): void {
        this.notesInternational = [
            {
                title: this.translate.instant("Sender ID"),
                description: this.translate.instant(
                    "help_text_sms_config_international_Sender_ID"
                ),
            },
            {
                title: this.translate.instant("API Key"),
                description: this.translate.instant(
                    "help_text_sms_config_international_API_key"
                ),
            },
        ];
        this.notesLocal = [
            {
                title: this.translate.instant("Sender ID"),
                description: this.translate.instant(
                    "help_text_sms_config_local_Sender_ID"
                ),
            },
            {
                title: this.translate.instant("API Key"),
                description: this.translate.instant(
                    "help_text_sms_config_local_API_Key"
                ),
            },
            {
                title: this.translate.instant("Username"),
                description: this.translate.instant(
                    "help_text_sms_config_local_Username"
                ),
            },
            {
                title: this.translate.instant("Password"),
                description: this.translate.instant(
                    "help_text_sms_config_local_Password"
                ),
            },
        ];
        this.formInit();
        this.getSMSConfigList();
        // this.form.valueChanges.subscribe((data) => {
        //     this.utilitiesService.formChangedOrNot(true);
        // });
    }
    setValue() {
        if (this.configList.length) {
            let config = this.configList[0];
            this.config = this.configList[0];
            this.form.patchValue({
                fc_sms_password: config.fc_sms_password
                    ? config.fc_sms_password
                    : "",
                id: config.id ? config.id : "",
                fc_sms_iid: config.fc_sms_iid
                    ? config.fc_sms_iid
                    : "",
                fc_sms_uid: config.fc_sms_uid
                    ? config.fc_sms_uid
                    : "",
                sms_international_token: config.sms_international_token
                    ? config.sms_international_token
                    : "",
                sender_local_id: config.sender_local_id
                    ? config.sender_local_id
                    : "",
                sender_international_id: config.sender_international_id
                    ? config.sender_international_id
                    : "",
                smtp_port: config.smtp_port ? config.smtp_port : "",
                sendgrid_api_key: config.sendgrid_api_key
                    ? config.sendgrid_api_key
                    : "",
            });
        }
    }
    async getSMSConfigList() {
        this.utilitiesService.startLoader();
        try {
            const configList = await this.appService.getSMSConfig().toPromise();
            if (configList) {
                this.configList = configList.results;
                this.setValue();
                this.utilitiesService.stopLoader();
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }

    goBack() {
        let fromApp = true;
        if (!this.utilitiesService.formChanged$.value) {
            this.back.emit();
        } else {
            let content = `You have unsaved changes, Please save before changing the page`;
            let heading = "Alert";
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp == "yes") {
                } else if (resp == "no") {
                    this.utilitiesService.formChangedOrNot(false);
                    this.back.emit();
                }
            });
        }
    }
    removeAllValuesFromLocal() {
        this.form.patchValue({
            sms_local_username: "",
            fc_sms_password: "",
            fc_sms_iid: "",
            fc_sms_uid: "",
            sender_local_id: "",
        });
    }
    removeAllValuesFromInternational() {
        this.form.patchValue({
            sender_international_id: "",
            sms_international_token: "",
        });
    }

    async save() {
        if (this.form.valid) {
            let form = this.form.value;
            if (this.configList.length) {
                await this.updateConfig(form);
            } else {
                await this.saveConfig(form);
            }
        }
    }
    async updateConfig(form) {
        try {
            let content = `Are you sure, Do you want to update this configuration ? `;
            let heading = "Update";
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp) {
                    this.appService.updateSMSConfig(form).subscribe((data) => {
                        let successMsg = this.translate.instant(
                            "Configuration updated successfully"
                        );
                        this.utilitiesService.showSuccessToast(successMsg);
                    });
                }
            });
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }
    async saveConfig(form) {
        try {
            let content = `Are you sure, Do you want to save this configuration ? `;
            let heading = "Save";
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp) {
                    this.appService.addSMSConfig(form).subscribe((data) => {
                        let successMsg = this.translate.instant(
                            "Configuration created successfully"
                        );
                        this.utilitiesService.showSuccessToast(successMsg);
                    });
                }
            });
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }
}
