import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { UtilitiesService } from 'app/services/utilitiesService';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'app/services/app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer-config',
  templateUrl: './footer-config.component.html',
  styleUrls: ['./footer-config.component.scss'],
  animations: fuseAnimations,
})
export class FooterConfigComponent implements OnInit, OnChanges {
  @Input() selectedBlock: any;
  @Input() appDetail: any;
  @Output() back: EventEmitter<void> = new EventEmitter<any>();
  @Output() saveHander: EventEmitter<void> = new EventEmitter<any>();
  form: FormGroup
  formInitialized = false;
  paymentIconDetails = [
    {
      name: 'MasterCard',
      controlName: 'enable_mastercard',
      icon: "assets/payment-images/mastercard.svg"
    },
    {
      name: 'VisaCard',
      controlName: 'enable_visacard',
      icon: "assets/payment-images/visacard.svg"
    },
    {
      name: 'Cash',
      controlName: 'enable_cash',
      icon: "assets/payment-images/cash.svg"
    },
    {
      name: 'Mada',
      controlName: 'enable_mada',
      icon: "assets/payment-images/mada.svg"
    },
    {
      name: 'Samsung Pay',
      controlName: 'enable_samsung_pay',
      icon: "assets/payment-images/samsung_pay.svg"
    },
    {
      name: 'Apple Pay',
      controlName: 'enable_apple_pay',
      icon: "assets/payment-images/apple_pay.svg"
    },
    {
      name: 'STC Pay',
      controlName: 'enable_stc',
      icon: "assets/payment-images/stc.svg"
    },
    {
      name: 'Tabby',
      controlName: 'enable_tabby'
    }
  ]

  socialIconDetails = [
    {
      name: 'facebook',
      toggleControl: 'enable_facebook',
      type: 'text',
      icon: 'bi-facebook',
      iconColor: 'blue'
    },
    {
      name: 'instagram',
      toggleControl: 'enable_instagram',
      type: 'text',
      icon: 'bi-instagram',
      iconColor: 'purple'
    },
    {
      name: 'whatsapp',
      toggleControl: 'enable_whatsapp',
      type: 'text',
      icon: 'bi-whatsapp',
      iconColor: 'green'
    },
    {
      name: 'snapchat',
      toggleControl: 'enable_snapchat',
      type: 'text',
      icon: 'bi-snapchat',
      iconColor: '#ff5838'
    },
    {
      name: 'twitter',
      toggleControl: 'enable_twitter',
      type: 'text',
      icon: 'bi-twitter-x',
      iconColor: 'black'
    },
    {
      name: 'telephone',
      toggleControl: 'enable_telephone',
      type: 'text',
      icon: 'bi-telephone',
      iconColor: 'green'
    },
    {
      name: 'youtube',
      toggleControl: 'enable_youtube',
      type: 'text',
      icon: 'bi-youtube',
      iconColor: 'red'
    }
  ]

  constructor(
    private fb: FormBuilder,
    private utilitiesService: UtilitiesService,
    private dialog: MatDialog,
    private appService: AppService,
    private translate: TranslateService,
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appDetail && !this.formInitialized) {
      this.formInit(changes.appDetail.currentValue)
      this.formInitialized = true; // Set the flag to true after the function call
    }
  }

  ngOnInit(): void {
  }

  formInit(appDetail) {
    this.form = this.fb.group({
      enable_qr_code: false,
      en_copyright_msg: "All rights reserved.",
      ar_copyright_msg: "All rights reserved.",
      show_custom_footer: false
    })
    this.socialIconDetails.forEach(ele => {
      this.form.addControl(ele.name, new FormControl(appDetail.footer_config[ele.name] ? appDetail.footer_config[ele.name] : appDetail[ele.name] ? appDetail[ele.name] : '', ele.type === 'email' ? Validators.email : null))
      this.form.addControl(ele.toggleControl, new FormControl(appDetail.footer_config[ele.toggleControl] ? appDetail.footer_config[ele.toggleControl] : false))
    })

    this.paymentIconDetails.forEach(ele => {
      this.form.addControl(ele.controlName, new FormControl(appDetail.footer_config[ele.controlName] ? appDetail.footer_config[ele.controlName] : true))
    })
    const enable_qr_code = 'enable_qr_code';
    const en_copyright_msg = 'en_copyright_msg';
    const ar_copyright_msg = 'ar_copyright_msg';
    const show_custom_footer = 'show_custom_footer';
    
    this.form.get(enable_qr_code).setValue(appDetail.footer_config[enable_qr_code])
    this.form.get(en_copyright_msg).setValue(appDetail.footer_config[en_copyright_msg])
    this.form.get(ar_copyright_msg).setValue(appDetail.footer_config[ar_copyright_msg])
    this.form.get(show_custom_footer).setValue(appDetail.footer_config[show_custom_footer])
  }

  socialSlideToggleChange(e, item) {
    const control = this.form.get(item.name)
    if (e.checked) {
      control.setValidators([Validators.required])
      control.updateValueAndValidity()
      control.markAsUntouched();
    } else {
      control.clearValidators()
      control.updateValueAndValidity()
    }
  }

  goBack() {
    this.back.emit();
  }

  save() {

    if (this.form.valid) {
      let content = `Are you sure, Do you want to update? `;
      let heading = "Update";
      let fromApp = false;
      let size = this.utilitiesService.isMobileAlertModal();
      const dialogRef = this.dialog.open(AlertModalComponent, {
        data: { content, heading, fromApp },
        maxWidth: "",
        width: `${size.width}`,
        height: `${size.height}`,
      });
      dialogRef.afterClosed().subscribe(async (resp) => {
        this.utilitiesService.autoSave.next(0);
        if (resp) {
          let form = this.form.value;
          const { facebook, instagram, whatsapp, youtube, contact_email } = this.form.value
          const extractedValues = {
            facebook,
            instagram,
            whatsapp,
            youtube,
            contact_email
          };
          const resp = await this.appService
            .addAppSettings({ footer_config: form, ...extractedValues })
            .toPromise();

          if (resp) {
            let successMsg = this.translate.instant(
              "Footer configuration updated successfully"
            );
            this.utilitiesService.showSuccessToast(successMsg);
            this.saveHander.emit();
          }
        }
      });
    } else {
      this.utilitiesService.checkValidations(this.form)
    }
  }

}
