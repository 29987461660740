import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "../api.service";
import { DownloadTypes } from "app/downloadTypes";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root",
})
export class OrderService {
    apiDomain: any;
    BASE_URL = environment.server_url;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    getOrderDetail(id) {
        return this.apiService.get(
            this.sharedService.orderList + `${id}/`,
            undefined
        );
    }
    updateOrderStatus(id, form?) {
        return this.apiService.patch(
            this.sharedService.orderList + `${id}/`,
            form
        );
    }
    checkStatus(id) {
        return this.apiService.get(
            this.sharedService.checkStatus + `${id}/`,
            undefined
        );
    }
    getOrderList(
        limit = 10,
        offset = 0,
        search = "",
        form?,
        isDownalod = false
    ) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                if (key == "price_rules") {
                    if (form[key].value) {
                        params = params.set(`${key}`, `${form[key].value}`);
                    }
                } else {
                    params = params.set(
                        `${key}`,
                        `${form[key].value ? form[key].value : ""}`
                    );
                }
            }
        }
        if (isDownalod) {
            params = params.set("download", "true");
        }

        return this.apiService.get(
            this.sharedService.orderList,
            params,
            undefined
        );
    }
    getOrderPdf(id) {
        let params = new HttpParams();
        const token = localStorage.getItem("access_token");

        let url = `${this.BASE_URL}api/get-order-pdf/?ids=${id}`;
        window.open(url, "_blank");
    }
    getInvoicePdf(id) {
        let url = `${this.BASE_URL}api/get-invoice-pdf/?ids=${id}`;
        window.open(url, "_blank");
    }

    getPicklistPdf(id) {
        let url = `${this.BASE_URL}api/get-picklist-pdf/?ids=${id}`;
        window.open(url, "_blank");
    }
    getPaymentStatus() {
        return this.apiService.get(this.sharedService.paymentStatus, undefined);
    }

    getWishList(limit = 10, offset = 0, search = "", form, id: any = "") {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }

        if (id) {
            params = params.set("customer_id", id.toString());
        }
        return this.apiService.get(
            this.sharedService.wishList,
            params,
            undefined
        );
    }

    getWishListDetail(id) {
        return this.apiService.get(
            this.sharedService.wishList + `${id}/`,
            undefined
        );
    }
}
