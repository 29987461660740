import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { CustomerService } from "app/services/customer/customer.service";
import { CampaignsService } from "app/services/marketing/campaigns.service";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";

@Component({
  selector: "app-send-message",
  templateUrl: "./send-message.component.html",
  styleUrls: ["./send-message.component.scss"],
})
export class SendMessageComponent implements OnInit {
  @Input() customerID: any;
  loading: boolean = false;
  form: FormGroup;
  ckeConfig = {
    allowedContent: true,
    versionCheck: false,
    removeButtons: "CreateDiv,BlockQuote,Superscript,Subscript,PasteFromWord,PasteText",
    forcePasteAsPlainText: true,
  };
  constructor(
    private formBuilder: FormBuilder,
    private translationService: TranslationService,
    private campaignsService: CampaignsService,
    private translate: TranslateService,
    private customerService: CustomerService,
    public utilitiesService: UtilitiesService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.formInitialize();
  }
  async formInitialize() {
    this.form = this.formBuilder.group({
      channel: [1],
      message: [null],
      template: [null],
      message_email: [null],
      title: [null],
    });
    await this.ChannelChange(1);
  }
  async ChannelChange(value) {
    if (value == 1) {
      this.form.get("message_email").setValidators([Validators.required]);
      this.form.get("message_email").updateValueAndValidity();
      this.form.get("message").clearValidators();
      this.form.get("message").updateValueAndValidity();
    } else {
      this.form.get("message").setValidators([Validators.required]);
      this.form.get("message").updateValueAndValidity();
      this.form.get("message_email").clearValidators();
      this.form.get("message_email").updateValueAndValidity();
    }
  }

  async send() {
    if (this.form.valid) {
      await this.saveCampaign(this.form.value, this.customerID);
    }
  }
  async saveCampaign(form, id) {
    let channel: any;
    let title = null;
    if (form.channel == 1) {
      channel = "EMAIL";
      title = form.title ? form.title : "Send Notification via Email";
    } else if (form.channel == 2) {
      channel = "SMS";
      title = form.title ? form.title : "Send Notification via SMS";
    } else if (form.channel == 3) {
      channel = "PUSH";
      title = form.title ? form.title : "Send Notification via Push";
    }
    try {
      let templateForm = {
        title: title,
        title_en: title,
        title_ar: title,
        body: channel == "EMAIL" ? form.message_email : form.message,
        body_en: channel == "EMAIL" ? form.message_email : form.message,
        body_ar: channel == "EMAIL" ? form.message_email : form.message,
        template_type: channel,
      };
      this.campaignsService.addTemplate(templateForm).subscribe(
        (response) => {
          let notificationForm = {
            template_id: response.id,
            customer_id: id,
            channel_type: form.channel,
          };
          this.customerService.sendNotification(notificationForm).subscribe(
            async (data) => {
              let successMsg = this.translate.instant("Notification send successfully");
              this.utilitiesService.stopLoader();
              this.utilitiesService.showSuccessToast(successMsg);
              this.form.controls.channel.setValue(1);
              this.form.controls.template.setValue(null);
              this.form.controls.title.setValue(null);
              setTimeout(() => {
                this.form.controls.message_email.setValue(null);
                this.form.controls.message.setValue(null);
              }, 100);
            },
            (error) => {
              this.utilitiesService.stopLoader();
            }
          );
        },
        (error) => {
          this.utilitiesService.stopLoader();
        }
      );
    } catch {
      let erroMsg = this.translate.instant("Api general Error");
      this.utilitiesService.showErrorToast(erroMsg);
      this.utilitiesService.stopLoader();
    } finally {
    }
  }
}
