import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ThemePalette } from "@angular/material/core";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { CoreService } from "app/services/core-service";
import { CustomerService } from "app/services/customer/customer.service";
import { SharedService } from "app/services/sharedService";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import * as moment from "moment";
import { ElementHistoryComponent } from "../element-history/element-history.component";
import { fuseAnimations } from "@fuse/animations";

@Component({
    selector: "app-add-membership",
    templateUrl: "./add-membership.component.html",
    styleUrls: ["./add-membership.component.scss"],
    animations: fuseAnimations,
})
export class AddMembershipComponent implements OnInit {
    @ViewChild("picker") picker: any;

    public date: moment.Moment;
    public disabled = false;
    public showSpinners = true;
    public showSeconds = false;
    public touchUi = false;
    public enableMeridian = false;
    public minDate: moment.Moment;
    public maxDate: moment.Moment;
    public stepHour = 1;
    public stepMinute = 1;
    public stepSecond = 1;
    public color: ThemePalette = "primary";
    form: FormGroup;
    firstName: any = "";
    lastName: any = "";
    id: any = "";
    customerList = [];
    customerDropDown = [];
    membersDetail: any;
    customerSettings = {
        text: "Select Customers",
        selectAllText: "Select All",
        unSelectAllText: "UnSelect All",
        enableSearchFilter: true,
        classes: "myclass custom-class",
    };
    customerIDs = [];
    plan = [];
    constructor(
        public dialogRef: MatDialogRef<AddMembershipComponent>,
        private formBuilder: FormBuilder,
        private customerService: CustomerService,
        private translationService: TranslationService,
        public utilitiesService: UtilitiesService,
        private sharedService: SharedService,
        public coreService: CoreService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.id = data.id;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
    }

    async ngOnInit(): Promise<void> {
        if (this.id) {
            this.utilitiesService.startLoader();
        }
        this.form = this.formBuilder.group({
            customer_id: [null, [Validators.required]],
            plan_id: [null, [Validators.required]],
            status: [null],
            expiry_date: [null],
        });
        await this.getCustomerList();
        await this.getPlans();
        if (this.id) {
            try {
                const membersDetail = await this.customerService
                    .getMembershipDetails(this.id)
                    .toPromise();
                if (membersDetail) {
                    this.membersDetail = membersDetail;
                    this.form
                        .get("expiry_date")
                        .setValidators([Validators.required]);
                    this.form.get("expiry_date").updateValueAndValidity;

                    this.form.get("status").setValue(membersDetail.status);
                    this.form
                        .get("expiry_date")
                        .setValue(membersDetail.expiry_date);
                    this.form
                        .get("plan_id")
                        .setValue(membersDetail.membership_plan.id);
                    this.form
                        .get("customer_id")
                        .setValue(membersDetail.customer.id);
                }
            } catch {
            } finally {
                this.utilitiesService.stopLoader();
            }
        }
    }
    async getCustomerList() {
        try {
            const customerList = await this.customerService
                .getCustomerList(99999, 0, "", undefined, "")
                .toPromise();
            if (customerList) {
                this.customerList = customerList.results;
                if (this.customerList.length > 0) {
                    this.customerList.forEach((data) => {
                        this.customerDropDown.push({
                            id: data.id,
                            itemName: `${
                                data.first_name ? data.first_name : ""
                            } ${data.last_name ? data.last_name : ""}`,
                        });
                    });
                }
            }
        } catch {
        } finally {
        }
    }
    async getPlans() {
        try {
            const plan = await this.customerService.getPlans().toPromise();
            if (plan) {
                this.plan = plan.results;
            }
        } catch {
        } finally {
        }
    }
    add() {
        let cust_id = this.form.controls.customer_id.value;
        Object.keys(cust_id).forEach((key) => {
            this.customerIDs.push(cust_id[key].id);
        });
        let form = {
            customer_ids: this.customerIDs,
            plan_id: this.form.controls.plan_id.value,
        };
        this.dialogRef.close(form);
    }

    update() {
        this.dialogRef.close(this.form.value);
    }

    openHistory() {
        let pageTitle;
        let historyURL;
        if (this.id) {
            pageTitle = "Membership";
            historyURL = this.sharedService.membershipHistory + `${this.id}/`;
        }
        let isMobile = this.utilitiesService.isMobile();
        let size = {
            width: "50%",
            maxWidth: "50%",
        };
        if (isMobile) {
            size.width = "100%";
            size.maxWidth = "100%";
        }
        const dialogRef = this.dialog.open(ElementHistoryComponent, {
            data: { historyURL, pageTitle },
            maxWidth: size.maxWidth,
            width: size.width,
            height: "100%",
            position: { right: "0%", left: "50%" },
            // panelClass: "mat-dialog-height-transition",
            panelClass: [!isMobile ? "mat-dialog-height-transition" : ""],
        });
        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
            }
        });
    }
}
