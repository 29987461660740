import {SharedService} from '../../../services/sharedService';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ApiService} from '../../../services/api.service';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export  class LoyaltyService {
    apiDomain: any;

    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }
    
    getLoyaltyConfiguration(){
        return this.apiService.get(this.sharedService.loyaltyConfiguration);
    }

    getLoyaltyPointsRule(limit = 10, offset = 0, search = '', form, id: any = '') {
        let params = new HttpParams();
        params = params
            .set('limit', limit.toString())
            .set('offset', offset.toString())
            .set('search', search);
        if (form) {
            for (const key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ''}`
                );
            }
        }
        return this.apiService.get(this.sharedService.pointsRule);
    }
    
    getLoyaltyTransactions(limit = 10, offset = 0, search = '', form, id: any = '') {
        let params = new HttpParams();
        params = params
            .set('limit', limit.toString())
            .set('offset', offset.toString())
            .set('search', search);
        if (form) {
            for (const key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ''}`
                );
            }
        }
        return this.apiService.get(this.sharedService.loyaltyTransactions, params);
    }

    getLoyaltyCustomerTransaction(){
        return this.apiService.get(this.sharedService.loyaltyCustomerTransactions);
    }

    updateLoyaltyConfiguration(form) {
        return this.apiService.post(this.sharedService.loyaltyConfiguration, form);
    }

    enableDisablePointsRule(id, form) {
        return this.apiService.put(this.sharedService.pointsRule + `${id}/`, form);
    }

    addPointsRule(form){
        return this.apiService.post(this.sharedService.pointsRule, form);
    }

    updatePriceRule(form, id){
        return this.apiService.put(this.sharedService.pointsRule + `${id}/`, form);
    }
    
    getPointsRulesDetails(id){
        return this.apiService.get(this.sharedService.pointsRule + `${id}/`);
    }
}
