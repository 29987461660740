import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { FlexLayoutModule } from "@angular/flex-layout";

import { FuseDirectivesModule } from "@fuse/directives/directives";
import { FusePipesModule } from "@fuse/pipes/pipes.module";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    FuseWidgetModule,
} from "@fuse/components";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ClipboardModule } from '@angular/cdk/clipboard'

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule,
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        MatDividerModule,
        MatFormFieldModule,
        MatMenuModule,
        MatSelectModule,
        FuseWidgetModule,
        MatCheckboxModule,
        MatInputModule,
        MatDialogModule,
        FuseProgressBarModule,
        FuseSidebarModule,
        NgxDatatableModule,
        FuseThemeOptionsModule,
        MatSlideToggleModule,
        ClipboardModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule,
        FuseDirectivesModule,
        FusePipesModule,
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        MatDividerModule,
        MatFormFieldModule,
        MatMenuModule,
        MatSelectModule,
        FuseWidgetModule,
        MatCheckboxModule,
        MatInputModule,
        MatDialogModule,
        FuseProgressBarModule,
        FuseSidebarModule,
        NgxDatatableModule,
        FuseThemeOptionsModule,
        MatSlideToggleModule,
        ClipboardModule
    ],
})
export class FuseSharedModule {}
