import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";
import { GeneralSettingsComponent } from "../general-settings/general-settings.component";
import { SpeedDialFabPosition } from "../../fab/fab.component";
import { AppElements, Appitems } from "appElementConstants";
import { CoreService } from "app/services/core-service";

@Component({
    selector: "app-app-right-side-menu",
    templateUrl: "./app-right-side-menu.component.html",
    styleUrls: ["./app-right-side-menu.component.scss"],
})
export class AppRightSideMenuComponent implements OnInit {
    SpeedDialFabPosition = SpeedDialFabPosition;
    speedDialFabColumnDirection = "column";
    speedDialFabPosition = SpeedDialFabPosition.Bottom;
    @Input() selectedBlock: any;
    @Output() selectedElement: EventEmitter<any> = new EventEmitter<any>();
    @HostBinding("class.animations-enabled")
    @Output()
    selectElementForFab: EventEmitter<any> = new EventEmitter<any>();
    @Output() saveHander: EventEmitter<any> = new EventEmitter<any>();

    private _animationsEnabled: boolean;
    selectedID: any;
    speedDialFabButtons = [];
    appItemsArray = new Appitems();
    constructor(
        public translationService: TranslationService,
        public utilitiesService: UtilitiesService,
        private dialog: MatDialog,
        private coreService: CoreService
    ) {
        let isShow = this.coreService.checkPermission(
            this.utilitiesService.Permission.AppConfig
        );

        this.appItemsArray.appItems.forEach((data) => {
            if (!isShow) {
                data.hidden = true;
            }
            if (data.id == AppElements.Translation) {
                if (
                    !this.coreService.checkPermission(
                        this.utilitiesService.Permission.TranslateList
                    )
                ) {
                    data.hidden = true;
                } else {
                    data.hidden = false;
                }
            } else if (data.id == AppElements.CustomFields) {
                if (
                    !this.coreService.checkPermission(
                        this.utilitiesService.Permission.DynamicFieldCreate
                    )
                ) {
                    data.hidden = true;
                } else {
                    data.hidden = false;
                }
            } else if (data.id == AppElements.EmailConfiguration) {
                if (
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.ConfigList
                    ) &&
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.ConfigUpdate
                    ) &&
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.ConfigAdd
                    ) &&
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.ConfigDetail
                    )
                ) {
                    data.hidden = false;
                } else {
                    data.hidden = true;
                }
            } else if (data.id == AppElements.SMSConfiguration) {
                if (
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.ConfigList
                    ) &&
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.ConfigUpdate
                    ) &&
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.ConfigAdd
                    ) &&
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.ConfigDetail
                    )
                ) {
                    data.hidden = false;
                } else {
                    data.hidden = true;
                }
            }
        });
        this.speedDialFabButtons = this.appItemsArray.appItems;
    }

    ngOnInit(): void {
        this.selectedID = this.selectedBlock;
        this.selectElement(this.selectedBlock, true);
        this.selectElementForFab.emit(this.selectedID);
    }

    fabClick(btnObject) {
        this.selectElement(btnObject.id);
    }

    async selectElement(id, onInit = false) {
        
        let fromApp = true;
        if (!this.utilitiesService.formChanged$.value) {
            await this.changeMenu(id, onInit);
        } else {
            let content = `You have unsaved changes, Please save before changing the page`;
            let heading = "Alert";
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp == "yes") {
                } else if (resp == "no") {
                    await this.changeMenu(id, onInit);
                }
            });
        }
    }
    async changeMenu(id, onInit = false) {
        this.appItemsArray.appItems.forEach(async (data, index) => {
            if (data.id == id) {
                this.utilitiesService.formChangedOrNot(false);
                this.appItemsArray.appItems[index].selected =
                    "highlight-bg-color";
                if (!onInit) {
                    this.selectedID = id;
                    this.selectedElement.emit(id);
                    this.selectElementForFab.emit(id);
                    this.saveHander.emit();
                }
            } else {
                this.appItemsArray.appItems[index].selected = "";
            }
        });
    }
    toggle() {
        this._animationsEnabled = true;
    }
}
