<div class="create-product auto-width margin-auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
    fxLayout.gt-sm="row" fxLayoutGap="1em">
    <form *ngIf="isApiCallDone" fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
        fxLayout.gt-sm="column" fxLayoutGap="1em" [formGroup]="form">
        <mat-tab-group>
            <mat-tab label="Product Details">
                <div fxFlex="1 1 auto" fxLayoutAlign.gt-sm="end center" fxLayoutAlign.lt-md="start stretch"
                    fxLayout.lt-md="column-reverse" fxLayout.gt-sm="row" fxLayoutGap="5px" class="my-10">
                    <button mat-stroked-button (click)="handleCancel()">
                        <mat-icon>clear</mat-icon>
                        {{ "Cancel" | translate }}
                    </button>
                    <button (click)="saveOrUpdate()" type="submit" mat-flat-button class="primary-bg-color">
                        <mat-icon>save</mat-icon>
                        {{ "Save" | translate }}
                    </button>
                </div>

                <div fxFlex="0 0 auto" fxLayoutAlign="center">
                    <div fxFlex="0 0  auto" class="fuse-card auto-width p-16" fxLayoutAlign="start stretch"
                        fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                        <div fxLayout="column" fxFlex="70" class="h-fulll">
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="16px">
                                <app-form-fields [formData]="productFields" [customFormGroup]="productInfo"
                                    [id]="productId"></app-form-fields>
                            </div>
                        </div>

                        <div fxFlex="30">
                            <div class="images my-10" formArrayName="product_images">
                                <ng-container *ngIf="
                                        getImageFormArray().value &&
                                        getImageFormArray().value.length > 0
                                    ">
                                    <ng-container *ngFor="
                                            let image of form.get(
                                                'product_images'
                                            )['controls'];
                                            let i = index
                                        ">
                                        <div [formGroupName]="i" class="image-container fuse-card p-8 w-full my-10">
                                            <div class="image" fxLayout="row" fxLayoutGap="10px"
                                                fxLayoutAlign="space-between center">
                                                <mat-icon (click)="deleteImage(i)" *ngIf="
                                                        getImageFormArray().at(
                                                            i
                                                        ).value?.image
                                                    " class="action-btn-delete cursor-pointer">delete
                                                </mat-icon>
                                                <img style="width: 100px" [src]="
                                                        getImageFormArray().at(
                                                            i
                                                        ).value?.image
                                                    " alt="" />

                                                <div fxLayout="row" fxLayoutGap="10px">
                                                    <mat-checkbox class="my-8" formControlName="cover_image" (change)="
                                                            imageType(
                                                                'cover',
                                                                i,
                                                                $event
                                                            )
                                                        ">
                                                        <span class="text-capitalized">{{ "Cover" }}</span>
                                                    </mat-checkbox>
                                                    <mat-checkbox class="my-8" formControlName="flip_image" (change)="
                                                            imageType(
                                                                'flip',
                                                                i,
                                                                $event
                                                            )
                                                        ">
                                                        <span class="text-capitalized">{{ "Flip" }}</span>
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div fxLayout="column">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label>{{
                                                        "Caption"
                                                        }}</mat-label>
                                                    <input matInput formControlName="caption" />
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label>{{
                                                        "Order by"
                                                        }}</mat-label>
                                                    <input matInput formControlName="order_by" />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>

                            <app-cms-upload-media [formcontrol]="form.controls.product_img"
                                (afterUpload)="setFileToForm($event, 'product')" saveLocally="true" showOnly="image"
                                [isMultiSelect]="true" [index]="1" uploadOnly="true">
                            </app-cms-upload-media>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab *ngIf="productInfo.get('composition').value === 2" label="Variants">
                <div fxFlex="0 0  auto" fxLayoutAlign="center">
                    <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 center-container"
                        fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em">
                        <app-form-fields [formData]="productFields" [CustomFormGroup]="productInfo"></app-form-fields>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Content">
                <div fxFlex="0 0  auto" fxLayoutAlign="center">
                    <ng-container *ngTemplateOutlet="createProductTemplate"></ng-container>
                </div>
            </mat-tab>
        </mat-tab-group>
    </form>
</div>

<ng-template #createProductTemplate>
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <app-create-page [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}" (cancelClick)="handleCancel()"
            [elements]="pageElements?.results" [productId]="productId" (createPageEvent)="afterCreateProductPage()"
            [productRowData]="rowData">
        </app-create-page>
    </div>
</ng-template>