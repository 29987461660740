<div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
    <form fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em"
        [formGroup]="campaignForm" *ngIf="campaignForm" style="min-height: 100vh;">
        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                <mat-spinner diameter="50" style="left: 47%;top: 50%;position: relative;" *ngIf="isLoading"
                    strokeWidth="5">
                </mat-spinner>
                <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 center-container" fxLayoutAlign="start stretch"
                    fxLayout="column" fxLayoutGap="1em">
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px"
                        [ngSwitch]="campaignForm.get('channel').value">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label class="text-capitalized">
                                    {{campaignNameField| translate}}
                                </mat-label>
                                <input formControlName="name" matInput name="title">
                                <mat-error *ngIf="campaignForm.get('name').hasError('required')">
                                    {{'Name is required' | translate}}
                                </mat-error>
                            </mat-form-field>
                            <ng-template [ngIf]="!isAffiliatePage">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                    fxLayout.gt-sm="row" fxLayoutGap="1em">
                                    <mat-form-field appearance="outline" fxFlex="100%">
                                        <mat-label>{{'Audience' | translate}}</mat-label>
                                        <mat-select formControlName="audience">
                                            <mat-option *ngFor="let audience of audienceList" [value]="audience?.id">
                                                {{audience?.name}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="campaignForm.get('audience').hasError('required')">
                                            {{'Audience is required' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <mat-form-field appearance="outline" fxFlex="100%">
                                    <mat-label>{{'Channel' | translate}}</mat-label>
                                    <mat-select (selectionChange)="ChannelChange($event.value)"
                                        formControlName="channel">
                                        <mat-option [value]="1">{{'EMAIL' | translate}}</mat-option>
                                        <mat-option [value]="2">{{'SMS' | translate}}</mat-option>
                                        <mat-option [value]="3">{{'PUSH' | translate}}</mat-option>
                                        <mat-option [value]="4">{{'CAMPAIGN' | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-template>

                        </div>

                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px"
                            *ngSwitchCase="4">
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label class="text-capitalized">
                                    {{campaignCodeField | translate}}
                                </mat-label>
                                <input formControlName="code" matInput>
                                <mat-error *ngIf="campaignForm.get('code').hasError('required')">
                                    {{'Code is required' | translate}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100%">
                                <mat-label>{{'Price Rule' | translate}}</mat-label>
                                <mat-select formControlName="price_rule">
                                    <mat-option *ngFor="let rule of priceRuleList" [value]="rule?.id">
                                        {{rule?.name}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="campaignForm.get('price_rule').hasError('required')">
                                    {{'Price rule is required' | translate}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100%">
                                <mat-label>{{'Medium' | translate}}</mat-label>
                                <mat-select formControlName="medium">
                                    <mat-option value="{{null}}"></mat-option>
                                    <mat-option *ngFor="let medium of mediumsList" [value]="medium?.id">
                                        {{medium?.name}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="campaignForm.get('medium').hasError('required') && !isAffiliatePage">
                                    {{'Medium is required' | translate}}
                                </mat-error>
                            </mat-form-field>

                            <ng-container [formGroup]="urlSpecification">
                                <mat-form-field appearance="outline" fxFlex="100%">
                                    <mat-label>{{campaignUrlField | translate}}</mat-label>
                                    <mat-select formControlName="type">
                                        <mat-option [value]="campaignURLTypesEnum.PAGE">{{'Page' | translate}}
                                        </mat-option>
                                        <mat-option [value]="campaignURLTypesEnum.CUSTOM">{{'Custom' | translate}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="urlTypeFormControl.hasError('required')">
                                        {{'URL Type is required' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngIf="urlTypeFormControl.value" [ngSwitch]="urlTypeFormControl.value">
                                <mat-form-field *ngSwitchCase="campaignURLTypesEnum.PAGE" appearance="outline"
                                    fxFlex="100%">
                                    <mat-label>{{'Page' | translate}}</mat-label>
                                    <mat-select [formControl]="urlValueFormControl">
                                        <mat-option *ngFor="let page of pagesList" [value]="page?.id">
                                            {{page?.title}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="urlValueFormControl.hasError('required')">
                                        {{'Page is required' | translate}}
                                    </mat-error>
                                </mat-form-field>

                                <ng-container *ngIf="!isAffiliatePage; else affiliateUrl">
                                    <mat-form-field *ngSwitchDefault appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'URL' | translate}}
                                        </mat-label>
                                        <input [formControl]="urlValueFormControl" matInput fxFlex="100">
                                        <mat-error *ngIf="urlValueFormControl.hasError('required')">
                                            {{'URL is required' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </ng-container>

                                <ng-template #affiliateUrl>
                                    <mat-form-field *ngSwitchDefault appearance="outline" fxFlex="100"
                                        floatLabel="always">
                                        <mat-label class="text-capitalized">
                                            {{'URL' | translate}}
                                        </mat-label>
                                        <div>
                                            <span>{{baseUrl}}</span>
                                            <input [formControl]="urlValueFormControl" matInput fxFlex="50"
                                                placeholder="please enter page name">
                                        </div>
                                        <mat-error *ngIf="urlValueFormControl.hasError('required')">
                                            {{'URL is required' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </ng-template>
                            </ng-container>

                        </div>

                        <ng-container *ngSwitchDefault>
                            <div fxFlex="0 0 auto" class="mb-4" fxLayoutAlign="flex-end flex-end" fxLayout="row"
                                fxLayoutGap="0px">
                                <mat-checkbox (change)="templateCheckboxChange()" formControlName="use_exist"
                                    class="example-margin">
                                    {{'Use Existing Template' | translate}}</mat-checkbox>
                            </div>
                            <div *ngIf="campaignForm.get('use_exist').value" fxFlex="0 0 auto"
                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                <mat-form-field appearance="outline" fxFlex="100%">
                                    <mat-label>{{'Template' | translate}}</mat-label>
                                    <mat-select formControlName="template">
                                        <mat-option *ngFor="let template of notificationTemplate"
                                            [value]="template?.id">
                                            {{template?.title}}</mat-option>
                                    </mat-select>
                                    <mat-spinner *ngIf="isLoading" diameter="20" matSuffix></mat-spinner>
                                    <mat-error *ngIf="campaignForm.get('template').hasError('required')">
                                        {{'Template is required' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div *ngIf="!campaignForm.get('use_exist').value" fxFlex="0 0 auto"
                                fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                <mat-form-field appearance="outline" fxFlex="100%">
                                    <mat-label>{{'Template Type' | translate}}</mat-label>
                                    <mat-select formControlName="template_format">
                                        <mat-option [value]="1">
                                            {{'General' | translate}}
                                        </mat-option>
                                        <mat-option [value]="2">
                                            {{'Personalized' | translate}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="campaignForm.get('template_format').hasError('required')">
                                        {{'Template Type is required' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div *ngIf="campaignForm.get('channel').value!=2 && !campaignForm.get('use_exist').value"
                                fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Title'| translate}}
                                    </mat-label>
                                    <input formControlName="title" matInput name="title">
                                </mat-form-field>
                            </div>
                            <div *ngIf="campaignForm.get('channel').value!=1 && !campaignForm.get('use_exist').value"
                                fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                <mat-form-field appearance="outline" fxFlex="100%">
                                    <mat-label>{{'Message' | translate}}</mat-label>
                                    <textarea formControlName="message" rows="8" matInput></textarea>
                                    <mat-error *ngIf="campaignForm.get('message').hasError('required')">
                                        {{'Message is required' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column">
                                    <div fxFlex="1 1 100%" fxLayout="column" class="mb-8"
                                        *ngIf="campaignForm.get('channel').value==1 && !campaignForm.get('use_exist').value">
                                        <mat-slide-toggle class="mb-8 align-self" fxFlex="0 0 auto"
                                            fxLayoutAlign="start end" fxLayout="column"
                                            [formControl]="codeEditorFormControl">
                                            {{!codeEditorFormControl.value ? ('Switch to Code Editor' | translate) : ('Switch to HTML Editor' | translate)}}
                                        </mat-slide-toggle>
                                        <ckeditor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
                                            *ngIf="!codeEditorFormControl.value" formControlName="message_email"
                                            ngDefaultControl name="myckeditor" [config]="ckeConfig">
                                        </ckeditor>
                                        <div fxFlex="0 0 auto" fxLayout="column" *ngIf="codeEditorFormControl.value">
                                            <mat-icon style="align-self: flex-end;"
                                                class="zoom-icon cursor-pointer highlight-text-color"
                                                (click)="zoomAdditionalCss(campaignForm.get('message_email'))">
                                                zoom_out_map</mat-icon>
                                            <ace-editor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
                                                [theme]="'clouds'" [mode]="'html'" [options]="options"
                                                formControlName="message_email" style="height:50vh;">
                                            </ace-editor>
                                        </div>
                                        <div style="font-size: 10px;color: red;"
                                            *ngIf="campaignForm.get('message_email').hasError('required')">
                                            {{'Message is required' | translate}}
                                        </div>
                                    </div>
                                    <!-- <ckeditor *ngIf="campaignForm.get('channel').value==1" formControlName="message_email"
                                ngDefaultControl name="myckeditor1" [config]="ckeConfig">
                            </ckeditor>
                            <small style="margin-left: 0px !important;color: #f44336 !important;"
                                *ngIf="campaignForm.get('message_email').hasError('required') && campaignForm.get('channel').value==1">
                                {{'Message is required' | translate}}
                            </small> -->
                                </div>
                            </div>

                            <div *ngIf="!campaignForm.get('use_exist').value" fxFlex="0 0 auto"
                                fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
                                <mat-checkbox formControlName="re_usable" class="font-size-12">
                                    {{'Do you want to save this Template' | translate}}</mat-checkbox>
                            </div>
                        </ng-container>

                    </div>
                    <div fxFlex="1 1 auto" fxLayoutAlign="flex-end stretch" fxLayout.lt-md="column-reverse"
                        fxLayout.gt-sm="row" fxLayoutGap="5px">
                        <button mat-stroked-button (click)="preview()" type="button"
                            *ngIf="campaignForm.get('channel').value != 4">
                            <mat-icon>remove_red_eye</mat-icon>
                            {{'Preview' | translate}}
                        </button>
                        <button mat-flat-button (click)="handleCancel()" class="secondary-bg-color">
                            <mat-icon>clear</mat-icon>
                            {{'Cancel' | translate}}
                        </button>
                        <button (click)="saveOrUpdate()" type="submit" mat-flat-button class="primary-bg-color">
                            <mat-icon>save</mat-icon>
                            {{'Save' | translate}}
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </form>

</div>