<div
    fxFlex="0 0 auto"
    fxLayoutAlign="start stretch"
    fxLayoutGap="5px"
    fxLayout="column"
>
    <ng-container
        *ngIf="
            checkFormat(url)?.format == mediaTypes.Video ||
            checkFormat(url)?.format == mediaTypes.Audio
        "
    >
        <app-video-play
            [isEnableOperations]="false"
            [src]="url"
            [type]="checkFormat(url)?.format"
        >
        </app-video-play>
    </ng-container>

    <ng-container *ngIf="checkFormat(url)?.format == mediaTypes.Image">
        <div
            class="p-8 cursor-pointer"
            fxFlex="1 1 auto"
            fxLayoutAlign="center center"
            fxLayout="column"
        >
            <div class="relative">
                <img class="w-84" [src]="url" />
            </div>
        </div>
    </ng-container>

    <ng-container
        *ngIf="
            checkFormat(url)?.format == mediaTypes.Text ||
            checkFormat(url)?.format == mediaTypes.Doc ||
            checkFormat(url)?.format == mediaTypes.PDF ||
            checkFormat(url)?.format == mediaTypes.Excel
        "
    >
        <div
            class="p-8 cursor-pointer"
            fxFlex="1 1 auto"
            fxLayoutAlign="center center"
            fxLayout="column"
        >
            <div class="relative">
                <img class="w-84 icon" src="assets/images/file.png" />
                <div
                    class="icon-text"
                    [ngStyle]="{ 'background-color': checkFormat(url)?.color }"
                >
                    {{ checkFormat(url)?.type }}
                </div>
            </div>
        </div>
    </ng-container>
</div>
