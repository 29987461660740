export enum PermissionConstants {
    CustomerList = "get_manage-customer-list",
    CustomerCreate = "create_manage-customer-list",
    CustomerDetail = "get_manage-customer-detail",
    CustomerUpdate = "update_manage-customer-detail",
    CustomerDelete = "delete_manage-customer-detail",
    CartList = "get_manage-cart-list",
    CartCreate = "create_manage-cart-list",
    CartDetail = "get_manage-cart-detail",
    CartUpdate = "update_manage-cart-detail",
    CartDelete = "delete_manage-cart-detail",
    AudienceList = "get_manage-audience-list",
    AudienceCreate = "create_manage-audience-list",
    AudienceDetail = "get_manage-audience-detail",
    AudienceUpdate = "update_manage-audience-detail",
    AudienceDelete = "delete_manage-audience-detail",
    OrderList = "get_manage-order-list",
    OrderCreate = "create_manage-order-list",
    OrderDetail = "get_manage-order-detail",
    OrderUpdate = "update_manage-order-detail",
    OrderDelete = "delete_manage-order-detail",
    WishlistList = "get_manage-wishlist-list",
    CampaignCreate = "create_manage-campaign-list",
    CampaignList = "get_manage-campaign-list",
    CampaignView = "get_manage-campaign-detail",
    CampaignExecute = "execute_campaign",
    CampaignDelete = "delete_manage-campaign-detail",
    CampaignUpdate = "update_manage-campaign-detail",
    PageCreate = "create_page",
    PageUpdate = "update_page",
    PageList = "get_manage-pages-list",
    PageDetail = "get_manage-pages-detail",
    PageDetele = "delete_manage-pages-detail",
    PostCreate = "create_post",
    PostUpdate = "update_post",
    PostList = "get_manage-posts-list",
    PostDetail = "get_manage-posts-detail",
    PostDelete = "delete_manage-posts-detail",
    PostCategoryList = "get_manage-post-category-list",
    PostCategoryDetail = "get_manage-post-category-detail",
    PostCategoryCreate = "create_manage-post-category-list",
    PostCategoryUpdate = "update_manage-post-category-detail",
    PostCategoryDelete = "delete_manage-post-category-detail",
    PostAppearance = "post_appearance",
    SliderCreate = "create_slider",
    SliderUpdate = "update_slider",
    SliderList = "get_manage-slider-list",
    SliderDetail = "get_manage-slider-detail",
    SliderDelete = "delete_manage-slider-detail",
    NavCreate = "create_navigation",
    NavUpdate = "update_navigation",
    NavDelete = "delete_navigation",
    NavList = "get_navigation-menu-list",
    NavDetail = "get_navigation-menu-detail",
    WidgetList = "get_widget-list",
    WidgetDetail = "get_widget-detail",
    WidgetCreate = "create_widget-list",
    WidgetUpdate = "update_widget-detail",
    WidgetDelete = "delete_widget-detail",
    MediaList = "get_media-list",
    MediaDetail = "get_media-detail",
    MediaCreate = "create_media-list",
    MediaUpdate = "update_media-detail",
    MediaDelete = "delete_media-detail",
    AppConfig = "update_manage-app-detail",
    TranslateList = "translate-listing",
    TranslateUpdate = "translate-update",
    TranslateReset = "translate-reset",
    PriceRuleList = "get_manage_price_rule-list",
    PriceRuleDelete = "delete_manage_price_rule-detail",
    PriceRuleUpdate = "update_manage_price_rule-detail",
    PriceRuleDetail = "get_manage_price_rule-detail",
    PriceRuleCreate = "create_manage_price_rule-list",
    NotificationTemplateList = "get_notification-template-list",
    NotificationTemplateCreate = "create_notification-template-list",
    NotificationTemplateDetail = "get_notification-template-detail",
    NotificationTemplateUpdate = "update_notification-template-detail",
    NotificationTemplateDelete = "delete_notification-template-detail",
    NotificationConfigList = "get_notification-configuration-list",
    NotificationConfigCreate = "create_notification-configuration-list",
    NotificationConfigDetail = "get_notification-configuration-detail",
    NotificationConfigUpdate = "update_notification-configuration-detail",
    NotificationConfigDelete = "delete_notification-configuration-detail",
    UserList = "users_list",
    UserCreate = "user_create",
    UserDetail = "user_detail",
    USerUpdate = "user_update",
    USerDelete = "user_delete",
    RoleList = "get_role-list",
    RoleCreate = "create_role-list",
    RoleDetail = "get_role-detail",
    RoleUpdate = "update_role-detail",
    RoleDelete = "delete_role-detail",
    Shopify = "check_shopify_app",
    ShopifyAction = "get_action",
    ShopifyInstall = "install_shopify",
    ShopifyUninstall = "uninstall_shopify",
    ShopifySyncProducts = "sync_products",
    WordPress = "wordpress_documentation",
    MembersCreate = "create_member",
    MembersList = "get_members-list",
    MembersDetail = "get_members-detail",
    MembersUpdate = "update_members-detail",
    MembersDelete = "delete_members-detail",

    DynamicFieldList = "get_manage-dynamicfield-list",
    DynamicFieldCreate = "create_manage-dynamicfield-list",
    DynamicFieldDetail = "get_manage-dynamicfield-detail",
    DynamicFieldUpdate = "update_manage-dynamicfield-detail",
    DynamicFieldDelete = "delete_manage-dynamicfield-detail",
    DynamicFieldEnable = "enable_dynamic_field",
    DynamicFieldDisable = "disable_dynamic_field",

    SendNotification = "send_notification",

    DownloadCustomer = "customer_download",
    DownloadOrder = "order_download",
    DownloadProduct = "product_download",
    DownloadWallet = "wallet_download",
    DownloadCart = "cart_download",

    HistoryApp = "app_history",
    HistoryPage = "page_history",
    HistoryPost = "post_history",
    HistoryMedia = "attachment_history",
    HistoryWidget = "widget_history",
    HistoryNavMenu = "nmenu_history",
    HistorySlide = "slide_history",
    HistoryAudience = "audience_history",
    HistoryCampaign = "campaign_history",
    HistoryCart = "cart_history",
    HistoryCondition = "condition_history",
    HistoryCustomer = "customer_history",
    HistoryMember = "member_history",
    HistoryOrder = "order_history",
    HistoryPriceRule = "rule_history",
    HistoryTranslation = "translation_history",
    HistoryDynamicField = "dynamic_field_history",
    HistoryNotificationTemplate = "ntemp_history",
    HistoryNotificationConfig = "config_history",
    HistoryRole = "role_history",
    ConfigList = "get_manage-configurations-list",
    ConfigUpdate = "update_manage-configurations-detail",
    ConfigDetail = "get_manage-configurations-detail",
    ConfigAdd = "create_manage-configurations-list",

    ChangePassowrd = "change_password",
    UpdateManageOrderDetail = "update_manage-order-detail",
    CreateProductPage = "create_product_page",
    UpdateProductPage = "update_product_page",
    DeleteProductPage = "delete_manage-product-page-detail",
    ManageProductDetailPage = "get_manage-product-page-detail",
    ClearCache = "clear_cache",

    ProductList = "get_manage-product-page-list",
    ProductInsight = "product-dashboard",
    ProductReviewList = "get_manage-product-reviews-list",

    LoyalyPointRuleList = 'get_manage-point-rule-list',
    loyaltyConfiguration = 'manage-loyalty-configuration',
    LoyaltyTransactionList = 'get_manage-point-transaction-list'


}

export class PermissionWithNavigation {
    permissionDetails = {
        myshops: {
            emailConfig: PermissionConstants.ConfigList,
            dynamicList: PermissionConstants.DynamicFieldCreate,
            translation: PermissionConstants.TranslateList,
            appConfig: PermissionConstants.AppConfig,
        },
        orderSubNav: PermissionConstants.OrderList,
        configuration: PermissionConstants.AppConfig,
        "1": PermissionConstants.Shopify,
        "6": PermissionConstants.WordPress,
        role_permissions: PermissionConstants.RoleList,
        page: PermissionConstants.PageList,
        collection: PermissionConstants.SliderList,
        widgets: PermissionConstants.WidgetList,
        navmenu: PermissionConstants.NavList,
        posts: PermissionConstants.PostList,
        category: PermissionConstants.PostCategoryList,
        media: PermissionConstants.MediaList,
        cart: PermissionConstants.CartList,
        customer_list: PermissionConstants.CustomerList,
        "2": PermissionConstants.MembersList,
        audience: PermissionConstants.AudienceList,
        wishlist: PermissionConstants.WishlistList,
        "3": PermissionConstants.CampaignList,
        pricerules: PermissionConstants.PriceRuleList,
        templates: PermissionConstants.NotificationTemplateList,
        notification_configuration: PermissionConstants.NotificationConfigList,
        users: PermissionConstants.UserList,
        roles_and_permission: PermissionConstants.RoleList,
        products:PermissionConstants.ProductList,
        appearance:PermissionConstants.PostAppearance,
        pointsSummary:PermissionConstants.LoyaltyTransactionList,
        loyaltyConfiguration:PermissionConstants.loyaltyConfiguration,
        loyaltyPointsRule:PermissionConstants.LoyalyPointRuleList,
        reviews:PermissionConstants.ProductReviewList,
        products_insights:PermissionConstants.ProductInsight
    };
    navItems = [
        "myshops",
        "1",
        "6",
        "role_permissions",
        "page",
        "collection",
        "widgets",
        "navmenu",
        "posts",
        "category",
        "media",
        "orderSubNav",
        "cart",
        "customer_list",
        "2",
        "audience",
        "wishlist",
        "3",
        "pricerules",
        "templates",
        "notification_configuration",
        "users",
        "roles_and_permission",
        "products",
        "appearance",
        "pointsSummary",
        "loyaltyConfiguration",
        "loyaltyPointsRule",
        "reviews",
        "products_insights"
    ];
}
