import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fuseAnimations } from '../../../../@fuse/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PriceRuleService } from '../../../services/price-rules/price-rules.service';
import { UtilitiesService } from '../../../services/utilitiesService';
import { TranslateService } from '@ngx-translate/core';
import { CampaignsService } from '../../../services/marketing/campaigns.service';
import { SliderService } from '../../../services/sliderService';
import { TranslationService } from '../../../services/translationService';
import { debounceTime } from 'rxjs/operators';
import * as moment from 'moment';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { LoyaltyService } from '../../pages/loyalty-points/loyaltyService';

@Component({
    selector: 'app-create-point-rules',
    templateUrl: './create-point-rules.component.html',
    styleUrls: ['./create-point-rules.component.scss'],
    animations: fuseAnimations,
})
export class CreatePointRulesComponent implements OnInit {
    @Input() id: any = '';
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    @Output() createPriceRule: EventEmitter<void> = new EventEmitter<any>();
    pointsRuleForm: FormGroup;
    pointsRulesDetail: any;
    audienceList = [];
    products = [];
    brands = [];
    categories = [];
    productSubject: BehaviorSubject<string> = new BehaviorSubject('');
    categorySubject: BehaviorSubject<string> = new BehaviorSubject('');
    brandSubject: BehaviorSubject<string> = new BehaviorSubject('');
    isLoadingBrand: boolean;
    isLoadingProduct: boolean;
    isLoadingCat: boolean;
    paymentMethods = [];
    productTypes = [
        { id: 1, name: 'GOODS' },
        { id: 2, name: 'SERVICE' },
        { id: 3, name: 'ONLINE' },
    ];


    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private priceRuleService: PriceRuleService,
        private pointsService: LoyaltyService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        private campaignsService: CampaignsService,
        private sliderService: SliderService,
        public translationService: TranslationService
    ) {
        this.productSubject.pipe(debounceTime(300)).subscribe((data) => {
            if (data) {
                this.isLoadingProduct = true;
                this.getProducts(data);
            } else {
                this.products = [];
            }
        });
        this.categorySubject.pipe(debounceTime(300)).subscribe((data) => {
            if (data) {
                this.isLoadingCat = true;
                this.getCategories(data);
            } else {
                this.categories = [];
            }
        });
        this.brandSubject.pipe(debounceTime(300)).subscribe((data) => {
            if (data) {
                this.isLoadingBrand = true;
                this.getBrands(data);
            } else {
                this.brands = [];
            }
        });
    }


    async ngOnInit(): Promise<void> {
        if (this.id) {
            this.utilitiesService.startLoader();
        }
        await this.formInitialize();
        await this.getAudiences();
        await this.getPaymentMethods();
        if (this.id) {
            await this.getPriceDetails();
        }
    }

    async getPaymentMethods() {
        try {
            const paymentMethods = await this.priceRuleService
                .getPaymentMethod()
                .toPromise();
            if (paymentMethods) {
                this.paymentMethods = paymentMethods;
            }
        } catch {
        } finally {
        }
    }

    async getCategories(search, ids: any = '') {
        try {
            const category = await this.sliderService
                .getCategory(search, ids)
                .toPromise();
            if (category) {
                this.isLoadingCat = false;
                this.categories = category.results;
            }
        } catch {
            this.isLoadingCat = false;
            this.utilitiesService.stopLoader();
        } finally {
        }
    }

    async getBrands(search, ids: any = '') {
        try {
            const brands = await this.sliderService
                .getBrand(search, ids)
                .toPromise();
            if (brands) {
                this.brands = brands.results;
                this.isLoadingBrand = false;
            }
        } catch {
            this.isLoadingBrand = false;
            this.utilitiesService.stopLoader();
        } finally {
        }
    }

    searchProducts(value) {
        this.productSubject.next(value);
    }
    searchCategories(value) {
        this.categorySubject.next(value);
    }
    searchBrands(value) {
        this.brandSubject.next(value);
    }

    async getProducts(search, id: any = '') {
        try {
            const products = await this.sliderService
                .getProducts(search, id)
                .toPromise();
            if (products) {
                this.isLoadingProduct = false;
                this.products = products.results;
            }
        } catch {
            this.isLoadingProduct = false;
            this.utilitiesService.stopLoader();
        } finally {
        }
    }

    async getAudiences() {
        try {
            const audienceList = await this.campaignsService
                .getAudience()
                .toPromise();
            if (audienceList) {
                this.audienceList = audienceList.results;
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }

    async formInitialize() {
        this.pointsRuleForm = this.fb.group({
            name: ['', [Validators.required]],
            code: [null, [Validators.required]],
            type: [1],
            points_on: [1],
            points: [0, [Validators.required]],
            point_type: [1],
            minimum_amount: [0.0, [Validators.required]],
            maximum_amount: [0.0, [Validators.required]],
            minimum_quantity: [0],
            product_ids: [null],
            category_ids: [null],
            brand_ids: [null],
            product_type_ids: [null],
            payment_method_ids: [null],
            points_on_product_ids: [null],
            points_on_category_ids: [null],
            points_on_brand_ids: [null],
            points_on_product_type_ids: [null],
            audience: [null, [Validators.required]],
            enable: [false],
            idSelect: [1],
        });
        this.pointsRuleForm.controls.points_on.valueChanges.subscribe(
            async (data) => {
                this.pointsRuleForm.controls.points.setValidators([
                    Validators.required,
                ]);
                this.pointsRuleForm.controls.points.updateValueAndValidity();
            }
        );

        this.pointsRuleForm.controls.points_on.setValue(1);
    }

    removeUnselectValues(selectedID) {
        switch (selectedID) {
            case 1: {
                this.pointsRuleForm.controls.brand_ids.setValue([]);
                this.pointsRuleForm.controls.category_ids.setValue([]);
                this.pointsRuleForm.controls.payment_method_ids.setValue([]);
                break;
            }
            case 2: {
                this.pointsRuleForm.controls.product_ids.setValue([]);
                this.pointsRuleForm.controls.category_ids.setValue([]);
                this.pointsRuleForm.controls.payment_method_ids.setValue([]);
                break;
            }
            case 3: {
                this.pointsRuleForm.controls.product_ids.setValue([]);
                this.pointsRuleForm.controls.brand_ids.setValue([]);
                this.pointsRuleForm.controls.payment_method_ids.setValue([]);
                break;
            }
            case 4: {
                this.pointsRuleForm.controls.product_ids.setValue([]);
                this.pointsRuleForm.controls.brand_ids.setValue([]);
                this.pointsRuleForm.controls.category_ids.setValue([]);
                this.pointsRuleForm.controls.payment_method_ids.setValue([]);
                break;
            }
            case 5: {
                this.pointsRuleForm.controls.product_ids.setValue([]);
                this.pointsRuleForm.controls.brand_ids.setValue([]);
                this.pointsRuleForm.controls.category_ids.setValue([]);
                break;
            }
        }
    }

    removeUnselectValues_discont(selectedID) {
        switch (selectedID) {
            case 1: {
                this.pointsRuleForm.controls.discount_on_brand_ids.setValue([]);
                this.pointsRuleForm.controls.discount_on_category_ids.setValue([]);
                this.pointsRuleForm.controls.payment_method_ids.setValue([]);
                break;
            }
            case 2: {
                this.pointsRuleForm.controls.discount_on_product_ids.setValue([]);
                this.pointsRuleForm.controls.discount_on_category_ids.setValue([]);
                this.pointsRuleForm.controls.payment_method_ids.setValue([]);
                break;
            }
            case 3: {
                this.pointsRuleForm.controls.discount_on_product_ids.setValue([]);
                this.pointsRuleForm.controls.discount_on_brand_ids.setValue([]);
                this.pointsRuleForm.controls.payment_method_ids.setValue([]);
                break;
            }
            case 4: {
                this.pointsRuleForm.controls.discount_on_product_ids.setValue([]);
                this.pointsRuleForm.controls.discount_on_category_ids.setValue([]);
                this.pointsRuleForm.controls.discount_on_brand_ids.setValue([]);
                this.pointsRuleForm.controls.payment_method_ids.setValue([]);

                break;
            }
            case 5: {
                this.pointsRuleForm.controls.discount_on_product_ids.setValue([]);

                this.pointsRuleForm.controls.discount_on_brand_ids.setValue([]);
                this.pointsRuleForm.controls.discount_on_category_ids.setValue([]);
                break;
            }
        }
    }

    async setValidators(data) {
        if (data == 1) {
            this.pointsRuleForm.controls.usage_limit.setValidators([
                Validators.required,
            ]);
            this.pointsRuleForm.controls.usage_limit.updateValueAndValidity();
        } else {
            this.pointsRuleForm.controls.usage_limit.clearValidators();
            this.pointsRuleForm.controls.usage_limit.updateValueAndValidity();
        }
    }
    async getPriceDetails() {
        try {
            const pointsRulesDetail = await this.pointsService
                .getPointsRulesDetails(this.id)
                .toPromise();
            if (pointsRulesDetail) {
                this.pointsRulesDetail = pointsRulesDetail;
                await this.setValue();
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
    handleCancel() {
        this.cancelClick.emit();
    }

    saveOrUpdate() {
        const product_ids = this.pointsRuleForm.controls.product_ids.value;
        console.log(product_ids);
        if (this.pointsRuleForm.valid) {
            const form = this.pointsRuleForm.value;
            // this.removeUnselectValues(form.idSelect);
            // this.removeUnselectValues_discont(form.idSelect_discont);
            if (form.product_ids) {
                form.product_ids = form.product_ids.toString();
            }
            if (form.brand_ids) {
                form.brand_ids = form.brand_ids.toString();
            }
            if (form.category_ids) {
                form.category_ids = form.category_ids.toString();
            }
            if (form.product_type_ids) {
                form.product_type_ids = form.product_type_ids.toString();
            }
            if (form.payment_method_ids) {
                form.payment_method_ids = form.payment_method_ids.toString();
            }

            if (form.points_on_product_ids) {
                form.points_on_product_ids = form.points_on_product_ids.toString();
            }
            if (form.points_on_brand_ids) {
                form.points_on_brand_ids = form.points_on_brand_ids.toString();
            }
            if (form.points_on_category_ids) {
                form.points_on_category_ids = form.points_on_category_ids.toString();
            }
            if (form.points_on_product_type_ids) {
                form.points_on_product_type_ids = form.points_on_product_type_ids.toString();
            }

            form.start_date = moment(form.start_date).format('YYYY-MM-DD');
            form.expiry_date = moment(form.expiry_date).format('YYYY-MM-DD');
            if (form.usage_type != 1) {
                form.usage_limit = 0;
            }
            if (!form.points) {
                form.points = 0;
            }

            if (form.idSelect == 4) {
                form.minimum_quantity = 0.0;
            }
            if (!this.id) {
                try {
                    const content = `Are you sure, Do you want to save this points rule ? `;
                    const heading = 'Save';
                    const fromApp = false;
                    const size = this.utilitiesService.isMobileAlertModal();
                    const dialogRef = this.dialog.open(AlertModalComponent, {
                        data: { content, heading, fromApp },
                        maxWidth: '',
                        width: `${size.width}`,
                        height: `${size.height}`,
                    });
                    dialogRef.afterClosed().subscribe(async (resp) => {
                        if (resp) {
                            this.utilitiesService.startLoader();
                            this.pointsService
                                .addPointsRule(form)
                                .subscribe((data) => {
                                    const successMsg = this.translate.instant(
                                        'Points Rule created successfully'
                                    );
                                    this.utilitiesService.showSuccessToast(
                                        successMsg
                                    );
                                    this.createPriceRule.emit();
                                });
                        }
                    });
                } catch {
                    this.utilitiesService.stopLoader();
                } finally {
                }
            } else if (this.id) {
                try {
                    const content = `Are you sure, Do you want to update this points rule ? `;
                    const heading = 'Update';
                    const fromApp = false;
                    const size = this.utilitiesService.isMobileAlertModal();
                    const dialogRef = this.dialog.open(AlertModalComponent, {
                        data: { content, heading, fromApp },
                        maxWidth: '',
                        width: `${size.width}`,
                        height: `${size.height}`,
                    });
                    dialogRef.afterClosed().subscribe(async (resp) => {
                        if (resp) {
                            this.utilitiesService.startLoader();
                            this.pointsService
                                .updatePriceRule(form, this.id)
                                .subscribe((data) => {
                                    const successMsg = this.translate.instant(
                                        'Points Rule updated successfully'
                                    );
                                    this.utilitiesService.showSuccessToast(
                                        successMsg
                                    );
                                    this.createPriceRule.emit();
                                });
                        }
                    });
                } catch {
                    this.utilitiesService.stopLoader();
                } finally {
                }
            }
        } else {
            const errorMsg = this.translate.instant('Form is not valid');
            this.utilitiesService.showErrorToast(errorMsg);
        }
    }
    async setValue() {
        try {

            this.pointsRuleForm
                .get('points')
                .setValue(this.pointsRulesDetail.points);
            this.pointsRuleForm
                .get('point_type')
                .setValue(this.pointsRulesDetail.point_type);

            this.pointsRuleForm
                .get('minimum_amount')
                .setValue(this.pointsRulesDetail.minimum_amount);
            this.pointsRuleForm
                .get('maximum_amount')
                .setValue(this.pointsRulesDetail.maximum_amount);
            this.pointsRuleForm
                .get('audience')
                .setValue(this.pointsRulesDetail.audience);
            this.pointsRuleForm
                .get('points_on')
                .setValue(this.pointsRulesDetail.points_on);
            this.pointsRuleForm
                .get('enable')
                .setValue(this.pointsRulesDetail.enable);
            this.pointsRuleForm
                .get('minimum_quantity')
                .setValue(this.pointsRulesDetail.minimum_quantity);
            this.pointsRuleForm.get('name').setValue(this.pointsRulesDetail.name);
            this.pointsRuleForm.get('type').setValue(this.pointsRulesDetail.type);

            if (this.pointsRulesDetail.product_ids) {
                this.pointsRuleForm.get('idSelect').setValue(1);
                const productIDs = JSON.parse(
                    '[' + this.pointsRulesDetail.product_ids + ']'
                );
                // await this.getProducts("", productIDs);
                this.pointsRuleForm.get('product_ids').setValue(productIDs);

            } else if (this.pointsRulesDetail.product_type_ids) {
                this.pointsRuleForm.get('idSelect').setValue(6);
                const productTypeIDs = JSON.parse(
                    '[' + this.pointsRulesDetail.product_type_ids + ']'
                );
                // await this.getProducts("", productIDs);
                this.pointsRuleForm.get('product_type_ids').setValue(productTypeIDs);
                this.pointsRuleForm.get('product_ids').setValue(null);

            } else if (this.pointsRulesDetail.brand_ids) {
                this.pointsRuleForm.get('idSelect').setValue(2);
                const brandIDs = JSON.parse(
                    '[' + this.pointsRulesDetail.brand_ids + ']'
                );
                await this.getBrands('', brandIDs);
                this.pointsRuleForm.get('brand_ids').setValue(brandIDs);
            } else if (this.pointsRulesDetail.category_ids) {
                this.pointsRuleForm.get('idSelect').setValue(3);
                const categoryIDs = JSON.parse(
                    '[' + this.pointsRulesDetail.category_ids + ']'
                );
                await this.getCategories('', categoryIDs);
                this.pointsRuleForm.get('category_ids').setValue(categoryIDs);
            } else if (this.pointsRulesDetail.payment_method_ids) {
                this.pointsRuleForm.get('idSelect').setValue(5);
                const payment_method_ids = JSON.parse(
                    '[' + this.pointsRulesDetail.payment_method_ids + ']'
                );
                this.pointsRuleForm
                    .get('payment_method_ids')
                    .setValue(payment_method_ids);
            }
            else {
                this.pointsRuleForm.get('idSelect').setValue(4);
            }
            this.setValidators(this.pointsRulesDetail.usage_typ);
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }
}
