import { Component, Inject, OnInit } from '@angular/core';
import { inject } from '@angular/core/testing';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RedirectionsService } from 'app/services/redirections.service';
import { UtilitiesService } from 'app/services/utilitiesService';

@Component({
  selector: 'app-create-page-redirection',
  templateUrl: './create-page-redirection.component.html',
  styleUrls: ['./create-page-redirection.component.scss']
})
export class CreatePageRedirectionComponent implements OnInit {

  form:FormGroup
  constructor(
    private fb: FormBuilder,
    private redirectionService:RedirectionsService,
    private utilitiesService:UtilitiesService,
    private dialogRef: MatDialogRef<CreatePageRedirectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      old_path:['',Validators.required],
      new_path:['', Validators.required]
    })
  }

  saveOrUpdate(){
    const form = this.form.value
    if(this.form.valid){
      this.redirectionService.addPageRedirection(form).subscribe(res=>{
        if(res){
          this.dialogRef.close(res)
        }
      })
    }
  }

  handleCancel(){
    this.dialogRef.close()
  }

}
