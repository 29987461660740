import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { PageService } from "app/services/pageService";
import { UtilitiesService } from "app/services/utilitiesService";
import { first } from "rxjs/operators";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";


@Component({
    selector: "app-general-settings",
    templateUrl: "./general-settings.component.html",
    styleUrls: ["./general-settings.component.scss"],
    animations: fuseAnimations,
})
export class GeneralSettingsComponent implements OnInit {

    pages = [];
    @Input() selectedBlock: any;
    @Input() appDetail: any;
    @Output() back: EventEmitter<void> = new EventEmitter<any>();
    @Output() saveHander: EventEmitter<void> = new EventEmitter<any>();
    generalForm: FormGroup;
    form: FormGroup;
    loading: boolean = true;
    constructor(
        private fb: FormBuilder,
        private fb1: FormBuilder,
        private dialog: MatDialog,
        private appService: AppService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        private pageService: PageService
    ) {
        // this.utilitiesService.autoSave.pipe(first()).subscribe((data) => {
        //     if (data == 1) {
        //         this.save();
        //     }
        // });
    }

    async ngOnInit(): Promise<void> {
        this.utilitiesService.startLoader();
        await this.formInitialize();

        await this.getPages();
        this.utilitiesService.stopLoader();
    }


    async formInitialize() {
        this.generalForm = this.fb.group({
            is_home_page: this.appDetail ? this.appDetail.is_home_page : false,
            home_page: this.appDetail ? this.appDetail.home_page : null,
            custom_home_page: [
                this.appDetail ? this.appDetail.custom_home_page : null,
            ],

            default_page: [this.appDetail ? this.appDetail.default_page : 1],
            page_size_limit: [
                this.appDetail ? this.appDetail.page_size_limit : 24,
                [Validators.compose([Validators.min(4), Validators.required])],
            ],
            is_gift_enabled: [this.appDetail.is_gift_enabled || false],


            language_temp: ["en"],
            title: [this.appDetail ? this.appDetail.title : null],
            title_en: [this.appDetail ? this.appDetail.title_en : null],
            title_ar: [this.appDetail ? this.appDetail.title_ar : null],
            meta_description: [
                this.appDetail ? this.appDetail.meta_description : null,
            ],
            meta_description_en: [
                this.appDetail ? this.appDetail.meta_description_en : null,
            ],
            meta_description_ar: [
                this.appDetail ? this.appDetail.meta_description_ar : null,
            ],
            https: [this.appDetail ? this.appDetail.https : true],
            language: [this.appDetail ? this.appDetail.language : "en"],
            enable_product_page: [
                this.appDetail ? this.appDetail.enable_product_page : false,
            ],
            approve_ratings_by_default: [
                this.appDetail ? this.appDetail.approve_ratings_by_default : false,
            ],
            popup_modal: [this.appDetail ? this.appDetail.popup_modal : false],
            popup: [this.appDetail ? this.appDetail.popup : false],
            show_email: [this.appDetail ? this.appDetail.show_email : false],
            is_email_mandatory: [
                this.appDetail ? this.appDetail.is_email_mandatory : false,
            ],
            location_map: [
                this.appDetail ? this.appDetail.location_map : false,
            ],
            enable_newsletter: [
                this.appDetail ? this.appDetail.enable_newsletter : false
            ],
            enable_social_media_login: [
                this.appDetail ? this.appDetail.enable_social_media_login : false
            ],
            enable_coupon_code: [
                this.appDetail ? this.appDetail.enable_coupon_code : false,
            ],
            enable_wishlist: [
                this.appDetail ? this.appDetail.enable_wishlist : false,
            ],
            checkout_type: [this.appDetail ? this.appDetail.checkout_type : 1],
            popup_show_type: [
                this.appDetail ? this.appDetail.popup_show_type : 1,
            ],
            popup_delay: [this.appDetail ? this.appDetail.popup_delay : 1000],
            abandoned_cart_days: [
                this.appDetail ? this.appDetail.abandoned_cart_days : 7,
                [Validators.compose([Validators.min(1), Validators.max(100)])],
            ],
            pending_cart_days: [
                this.appDetail ? this.appDetail.pending_cart_days : 2,
                [Validators.compose([Validators.min(1), Validators.max(100)])],
            ],
            enable_subscribe_popover: [
                this.appDetail ? this.appDetail.enable_subscribe_popover : false,
            ],
            subscribe_popover_show_type: [
                this.appDetail ? this.appDetail.subscribe_popover_show_type : 1,
            ],
        });

        this.generalForm.controls.show_email.valueChanges.subscribe((data) => {
            if (!data) {
                this.generalForm.controls.is_email_mandatory.setValue(false);
            }
        });

        this.generalForm.controls.popup.valueChanges.subscribe((data) => {
            if (!data) {
                this.generalForm.controls.popup_modal.setValue(false);
            }
        });
    }

    // async init() {
    //     if (this.appDetail && this.appDetail.home_page) {
    //         this.pages.forEach((data) => {
    //             if (
    //                 data.title == this.appDetail.home_page ||
    //                 data.slug == this.appDetail.home_page ||
    //                 data.title_en == this.appDetail.home_page
    //             ) {
    //                 this.form.get("home_page").setValue(data.id);
    //             }
    //         });
    //     }
    //     this.form.valueChanges.subscribe((data) => {
    //         this.utilitiesService.formChangedOrNot(true);
    //     });
    // }

    async getPages() {
        try {
            const pages = await this.pageService.getPages().toPromise();
            if (pages) {
                this.pages = pages.results;
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
            this.utilitiesService.stopLoader();
            this.loading = false;
        }
    }

    save() {
        if (this.generalForm.valid) {
            let content = `Are you sure, Do you want to update? `;
            let heading = "Update";
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                this.utilitiesService.autoSave.next(0);
                if (resp) {
                    let form = this.generalForm.value;
                    form.title_en = form.title;
                    form.meta_description_en = form.meta_description;
                    const resp = await this.appService
                        .addAppSettings(form)
                        .toPromise();

                    if (resp) {
                        localStorage.setItem("appName", form.title);
                        localStorage.setItem("appName_ar", form.title_ar);
                        this.utilitiesService.formChangedOrNot(false);
                        let successMsg = this.translate.instant(
                            "General Settings updated successfully"
                        );
                        this.utilitiesService.showSuccessToast(successMsg);
                        this.saveHander.emit();
                    }
                }
            });
        }
    }
    goBack() {
        let fromApp = true;
        if (!this.utilitiesService.formChanged$.value) {
            this.back.emit();
        } else {
            let content = `You have unsaved changes, Please save before changing the page`;
            let heading = "Alert";
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp == "yes") {
                } else if (resp == "no") {
                    this.utilitiesService.formChangedOrNot(false);
                    this.back.emit();
                }
            });
        }
    }
}
