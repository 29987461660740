import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DaterangepickerDirective } from "ngx-daterangepicker-material";
import * as moment from "moment";

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
    @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
    @Output() dateRangeEvent = new EventEmitter<any>();

    selectedRanges = { startDate: null, endDate: null }
    closeOnAutoApply = true;

    ranges: any = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }

    invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

    constructor() {}

    ngOnInit(): void { 
        this.selectedRanges.startDate = moment().subtract(29, 'days').startOf('day');
        this.selectedRanges.endDate = moment().subtract(0, 'days').startOf('day');
    }

    isInvalidDate = (m: moment.Moment) => {
        return this.invalidDates.some(d => d.isSame(m, 'day'))
    }
    
    openDatepicker() {
        setTimeout(() => {
            this.pickerDirective.open();
        }, 200)
    }

    datesUpdated(event) {
        let dates = this.convertDate(event)
        this.dateRangeEvent.emit(dates);
    }

    convertDate(event) {
        let start_date: any = ""
        let end_date: any = ""
        if (event.startDate) {
            start_date = moment(event.startDate).format('YYYY-MM-DD')
        }
        if (event.endDate) {
            end_date = moment(event.endDate).format('YYYY-MM-DD')
        }
        return { start_date, end_date }
    }
}