<div id="dashboard-project" class="page-layout simple right-sidebar" fxLayout="column">
  <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" fusePerfectScrollbar>
    <form
      [formGroup]="notificationForm"
      fxFlex="0 0 auto"
      fxLayoutAlign="start stretch"
      fxLayout="column"
      fxLayoutGap="1em"
      fusePerfectScrollbar>
      <div fxFlex="0 0 auto" fxLayoutAlign="start flex-end" fxLayout="column" fxLayoutGap="1em">
        <button (click)="saveOrUpdate()" type="submit" mat-flat-button class="primary-bg-color">{{ "Save" | translate }}</button>
      </div>
      <div class="fuse-card auto-width p-16" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
          <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label class="text-capitalized">
                  {{ "Title (En)" | translate }}
                </mat-label>
                <input matInput name="title_en" formControlName="title_en" />
                <mat-error *ngIf="notificationForm.get('title_en').hasError('required')">
                  {{ "Title En is required" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label class="text-capitalized">
                  {{ "Title (Ar)" | translate }}
                </mat-label>
                <input matInput name="title" formControlName="title_ar" />
              </mat-form-field>
            </div>
          </div>
          <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
            <div fxFlex.gt-sm="1 1 50%" fxFlex.lt-md="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
              <mat-form-field fxFlex="100" appearance="outline">
                <mat-label class="text-capitalized">{{ "Type" | translate }}</mat-label>
                <mat-select formControlName="template_type">
                  <mat-option [value]="type?.id" *ngFor="let type of notificationType.types">
                    {{ type?.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex.gt-sm="1 1 50%" fxFlex.lt-md="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
              <mat-form-field appearance="outline" fxFlex="100%">
                <mat-label>{{ "Template Type" | translate }}</mat-label>
                <mat-select formControlName="template_format">
                  <mat-option [value]="1">
                    {{ "General" | translate }}
                  </mat-option>
                  <mat-option [value]="2">
                    {{ "Personalized" | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
              <div
                fxFlex.lt-md="1 1 auto"
                fxFlex.gt-sm="0 0 75%"
                fxLayout="column"
                class="mb-8 fuse-card auto-width p-16"
                *ngIf="notificationForm.get('template_type').value == 'EMAIL'">
                <label class="highlight-text-color mb-8">{{
                  notificationForm.get("lang").value == "en" ? ("Body (En)" | translate) : ("Body (Ar)" | translate)
                }}</label>

                <mat-slide-toggle
                  fxFlex="0 0 auto"
                  fxLayoutAlign="start end"
                  fxLayout="row"
                  class="mb-8 align-self-end"
                  *ngIf="notificationForm.get('lang').value == 'en'"
                  [formControl]="codeEditorFormControl">
                  {{
                    !codeEditorFormControl.value
                      ? ("Switch to Code Editor" | translate)
                      : ("Switch to                                    HTML Editor" | translate)
                  }}
                </mat-slide-toggle>
                <mat-slide-toggle
                  fxFlex="0 0 auto"
                  fxLayoutAlign="start end"
                  fxLayout="row"
                  class="mb-8 align-self-end"
                  *ngIf="notificationForm.get('lang').value == 'ar'"
                  [formControl]="codeEditorFormControl">
                  {{
                    !codeEditorFormControl.value
                      ? ("Switch to Code Editor" | translate)
                      : ("Switch to                                    HTML Editor" | translate)
                  }}
                </mat-slide-toggle>

                <div *ngIf="codeEditorFormControl.value" fxFlex="1 1 auto" fxLayoutAlign="start start" fxLayout="row" class="mb-8">
                  <button class="btn" title="Add image url" mat-stroked-button>
                    <mat-icon class="btn-icon" (click)="openGalleryForCopy()" matPrefix> add_to_photos</mat-icon>
                  </button>
                </div>

                <ckeditor
                  [@animate]="{ value: '*', params: { duration: '300ms', y: '50px' } }"
                  *ngIf="notificationForm.get('lang').value == 'en' && !codeEditorFormControl.value"
                  formControlName="body_en"
                  ngDefaultControl
                  name="myckeditor"
                  [config]="ckeConfig">
                </ckeditor>
                <div
                  fxFlex="0 0 auto"
                  fxLayout="column"
                  style="margin-top: -25px"
                  *ngIf="notificationForm.get('lang').value == 'en' && codeEditorFormControl.value">
                  <mat-icon
                    class="zoom-icon cursor-pointer highlight-text-color align-self-end"
                    (click)="zoomAdditionalCss(notificationForm.get('body_en'), 'body_en')">
                    zoom_out_map</mat-icon
                  >
                  <ace-editor
                    [@animate]="{ value: '*', params: { duration: '300ms', y: '50px' } }"
                    [theme]="'clouds'"
                    [mode]="'html'"
                    [options]="options"
                    formControlName="body_en"
                    style="height: 50vh">
                  </ace-editor>
                </div>
                <div
                  style="font-size: 10px; color: red"
                  *ngIf="notificationForm.get('body_en').hasError('required') && notificationForm.get('lang').value == 'en'">
                  {{ "Html En is required" | translate }}
                </div>

                <div
                  fxFlex="0 0 auto"
                  fxLayout="column"
                  style="margin-top: -25px"
                  *ngIf="notificationForm.get('lang').value == 'ar' && codeEditorFormControl.value">
                  <mat-icon
                    class="zoom-icon highlight-text-color cursor-pointer align-self-end"
                    (click)="zoomAdditionalCss(notificationForm.get('body_ar'), 'body_ar')">
                    zoom_out_map</mat-icon
                  >
                  <ace-editor
                    [@animate]="{ value: '*', params: { duration: '300ms', y: '50px' } }"
                    [theme]="'clouds'"
                    [mode]="'css'"
                    [options]="options"
                    formControlName="body_ar"
                    style="height: 50vh">
                  </ace-editor>
                </div>

                <ckeditor
                  [@animate]="{ value: '*', params: { duration: '300ms', y: '50px' } }"
                  *ngIf="notificationForm.get('lang').value == 'ar' && !codeEditorFormControl.value"
                  formControlName="body_ar"
                  ngDefaultControl
                  name="myckeditor1"
                  [config]="ckeConfig">
                </ckeditor>
              </div>
              <div
                fxFlex.lt-md="1 1 auto"
                fxFlex.gt-sm="0 0 75%"
                fxLayout="column"
                class="mb-8 fuse-card auto-width p-16"
                *ngIf="notificationForm.get('template_type').value != 'EMAIL'">
                <div fxFlex="1 1 auto" fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="1em">
                  <label class="highlight-text-color mb-8">{{
                    notificationForm.get("lang").value == "en" ? ("Body (En)" | translate) : ("Body (Ar)" | translate)
                  }}</label>
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="lang">
                      <mat-option [value]="'en'"> En </mat-option>
                      <mat-option [value]="'ar'"> Ar </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <mat-form-field *ngIf="notificationForm.get('lang').value == 'en'" appearance="outline" fxFlex="100">
                  <textarea id="textareaid" rows="15" matInput name="title" formControlName="body_en"></textarea>
                  <mat-error *ngIf="notificationForm.get('body_en').hasError('required')">
                    {{ "Body En is required" | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="notificationForm.get('lang').value == 'ar'" appearance="outline" fxFlex="100">
                  <textarea rows="15" matInput name="title" formControlName="body_ar"></textarea>
                </mat-form-field>
                <!-- push notification config -->
                <div
                  fxFlex="1 1 auto"
                  fxLayoutAlign="start stretch"
                  fxLayout="column"
                  fxLayoutGap="1em"
                  *ngIf="notificationForm.get('template_type').value == 'PUSH'"
                  class="mt-8">
                  <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                    <div fxLayout="row" fxFlex="0 0 auto" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                      <label class="highlight-text-color">{{ "Image" | translate }}</label>
                    </div>
                    <div
                      *ngIf="!notificationForm.get('image_url').value"
                      fxLayout="column"
                      fxFlex="0 0 auto"
                      fxLayoutAlign="start stretch"
                      fxLayoutGap="5px">
                      <app-cms-upload-media
                        (afterUpload)="setFileToForm($event)"
                        [isMultiSelect]="false"
                        [formcontrol]="notificationForm.get('image_url')"
                        [index]="0">
                      </app-cms-upload-media>
                      <!-- <input accept="image/x-png,image/gif,image/jpeg" class="attach-input"
                                                type="file" id="fileDropRef"
                                                (change)="fileBrowseHandlerTumbnail($event.target.files)" />
                                            <label class="attach-label attach-label-file"
                                                for="fileDropRef">{{'Browse for file' | translate}}</label>
                                            <label>{{'Or' | translate}}</label>
                                            <label (click)="openGallery(notificationForm.get('image_url'),false)"
                                                class="attach-label">{{'Browse from media' | translate}}</label> -->
                    </div>
                    <div
                      class="files-list cursor-pointer"
                      fxFlex="0 0 auto"
                      fxLayoutAlign="space-between stretch"
                      fxLayout="row"
                      *ngIf="notificationForm.get('image_url').value">
                      <app-show-all-type-media [url]="notificationForm.get('image_url').value"> </app-show-all-type-media>
                      <!-- <img class="thumbnail-img p-16" style="margin: auto !important;"
                                                [src]="utilitiesService.bypassTrustedURL(notificationForm.get('image_url').value)"> -->
                      <mat-icon
                        fxFlex="0 0 auto"
                        fxLayoutAlign="end end"
                        fxLayoutGap="5px"
                        fxLayout="row"
                        (click)="deleteImage()"
                        *ngIf="notificationForm.get('image_url').value"
                        class="action-btn-delete cursor-pointer"
                        >close
                      </mat-icon>
                    </div>
                  </div>
                  <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
                    <mat-form-field appearance="outline" fxFlex="100">
                      <mat-label class="text-capitalized">
                        {{ "Custom Link" | translate }}
                      </mat-label>
                      <input matInput name="title_en" formControlName="trigger_url" />
                    </mat-form-field>
                  </div>
                  <small class="note"
                    >* {{ "Custom Link should be a relative url" | translate }} {{ "Eg:" | translate }}
                    <strong>https://domain.com/abc</strong> {{ "here" | translate }} <strong>"/abc"</strong>
                    {{ "is a relative url" | translate }}
                  </small>
                </div>
              </div>
              <div
                fxFlex.lt-md="1 1 auto"
                fxFlex.gt-sm="0 0 24%"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                class="mb-8 variable-container-height fuse-card auto-width p-16">
                <label class="mb-8 highlight-text-color text-center">{{ "Click to copy" | translate }}</label>
                <ng-container *ngIf="codes.length; else emptyblk">
                  <div
                    *ngFor="let code of codes; let i = index"
                    fxFlex="1 1 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxLayoutGap="1em">
                    <button
                      (mouseover)="codeIndex[i] = true"
                      (mouseout)="codeIndex[i] = false"
                      style="text-align: start !important; overflow: hidden"
                      [cdkCopyToClipboard]="code.code"
                      (click)="showCopyMessage()"
                      mat-stroked-button
                      type="button"
                      class="mb-4">
                      <mat-icon matPrefix>file_copy</mat-icon>
                      <span *ngIf="!codeIndex[i]">
                        {{ translationService.getTranslatedName(code) }}
                      </span>
                      <span *ngIf="codeIndex[i]">
                        {{ code?.code }}
                      </span>
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #emptyblk>
  <label class="mt-8" style="color: #ccc; text-align: center">{{ "Noting to display" | translate }}</label>
</ng-template>
