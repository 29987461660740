import { Injectable, Inject } from "@angular/core";
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlSerializer,
} from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from "./auth.service";
import { UtilitiesService } from "./utilitiesService";

@Injectable()
export class GuradService implements CanActivate {
    constructor(
        private urlSerializer: UrlSerializer,
        private authService: AuthService
    ) {}
    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        let isExpired: boolean = false;
        let token = localStorage.getItem("access_token");
        if (token) {
            if (token == "null") {
                return this.urlSerializer.parse("login");
            } else {
                if (token) {
                    const helper = new JwtHelperService();
                    isExpired = helper.isTokenExpired(token);
                }
                if (isExpired) {
                    return this.urlSerializer.parse("login");
                } else {
                    return true;
                }
            }
        } else {
            return this.urlSerializer.parse("login");
        }
    }
}
