import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { IconCss } from "app/main/ngxDatatable-Icons/icons";
import { CoreService } from "app/services/core-service";
import { UtilitiesService } from "app/services/utilitiesService";
import * as moment from "moment";

@Component({
    selector: "app-element-history",
    templateUrl: "./element-history.component.html",
    styleUrls: ["./element-history.component.scss"],
    animations: fuseAnimations,
})
export class ElementHistoryComponent implements OnInit {
    historyList = [];
    historyURL = "";
    icons = IconCss;
    items = [];
    iconsCss = {
        pagerLeftArrow: this.icons.left,
        pagerRightArrow: this.icons.right,
        pagerPrevious: this.icons.previous,
        pagerNext: this.icons.next,
    };
    isLoading: boolean = false;
    page = {
        size: 10,
        totalElements: 0,
        pageNumber: 0,
    };
    pageTitle: any;
    searchKey: any;
    panelToggle = [];
    next = "";
    historyListLength: number;
    constructor(
        private coreService: CoreService,
        public utilitiesService: UtilitiesService,
        public dialogRef: MatDialogRef<ElementHistoryComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.historyURL = data.historyURL;
        this.pageTitle = data.pageTitle;
        this.page.totalElements = 0;
        this.page.size = 10;
        this.page.pageNumber = 0;
    }
    search(searchKey) {
        this.searchKey = searchKey;
    }
    async limitChange() {
        await this.getHistory();
    }
    async ngOnInit(): Promise<void> {
        if (this.historyURL) {
            await this.getHistory();
        }
    }
    async getHistory() {
        try {
            this.utilitiesService.startLoader();
            const historyList = await this.coreService
                .getHistory(this.page.size, 0, this.searchKey, this.historyURL)
                .toPromise();
            if (historyList) {
                this.historyList = historyList.results;
                this.next = historyList.next;
                this.historyListLength = this.historyList.length;
                this.page.totalElements = historyList.count;
                this.historyList.forEach((data, index) => {
                    this.panelToggle[index] = false;
                });
            }
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }
    async loadMoreHistory() {
        if (this.historyListLength <= this.page.totalElements && this.next) {
            try {
                this.utilitiesService.startLoader();
                const historyList = await this.coreService
                    .getPaginatedHistory(this.next)
                    .toPromise();
                if (historyList) {
                    this.historyList = this.historyList.concat(
                        historyList.results
                    );
                    this.next = historyList.next;
                    this.historyList.forEach((data, index) => {
                        this.panelToggle[index] = false;
                    });
                    this.historyListLength = this.historyList.length;
                    this.page.totalElements = historyList.count;
                }
            } catch {
            } finally {
                this.utilitiesService.stopLoader();
            }
        }
    }
    openPanel(i) {
        this.panelToggle[i] = true;
    }
    closePanel(i) {
        this.panelToggle[i] = false;
    }
    getKey(key: string, value) {
        if (key == "css") {
        } else if (value) {
            if (key.indexOf("_") > -1) {
                key = key.replace(/_/g, " ");
            }
            return key;
        } else {
            return key;
        }
    }
    getValue(key, value) {
        var formats = [moment.ISO_8601, "YYYY/MM/DD  :)  HH*mm*ss"];
        let keyValue;
        if (key != "css") {
            if (!value) {
                return `Empty`;
            } else {
                if (typeof value === "object") {
                    if (value.first_name) {
                        keyValue = `${value.first_name} ${value.last_name}`;
                        return keyValue;
                    }
                } else {
                    if (moment(value, formats, true).isValid()) {
                        let date = new Date(value);
                        keyValue = moment(date).format("DD/MM/YYYY HH:mm a");
                        return keyValue;
                    } else {
                        return value;
                    }
                }
            }
        }
    }
}
