<div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
    <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="space-between">
        <label class="highlight-text-color h4">{{elementName}}</label>
    </div>
    <div *ngFor="let config of configuration['controls']; let i =index" class="elements">
        <div *ngFor="let control of config.controls | keyvalue:originalOrder">
            <ng-container *ngIf="getFormControlLabel(control.key) as controlLabel">
                <ng-container *ngIf="getFormControlType(control.key) as controlType">
                    <ng-container>

                        <div *ngIf="hideLanguage(config.controls)" fxFlex="0 0 auto" fxLayout="column"
                            fxLayoutAlign="start stretch">
                            <mat-form-field *ngIf="controlType=='language'" appearance="outline"
                                fxLayoutAlign="start flex-end">
                                <mat-select [formControl]="control.value">
                                    <mat-option value="en">{{'EN' | translate}}</mat-option>
                                    <mat-option value="ar">{{'AR' | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <mat-checkbox *ngIf="typeEnum.BOOLEAN == controlType " [formControl]="control.value">
                            <span class="text-capitalized">{{controlLabel}}</span>
                        </mat-checkbox>

                        <mat-form-field *ngIf="typeEnum.TEXT == controlType" appearance="outline" fxFlex="100">
                            <mat-label>{{controlLabel}}</mat-label>
                            <input [formControl]="control.value" matInput>
                        </mat-form-field>

                        <mat-form-field *ngIf="typeEnum.NUMBER ==controlType" appearance="outline" fxFlex="100">
                            <mat-label class="text-capitalized">{{controlLabel}}</mat-label>
                            <input [formControl]="control.value" type="number" matInput>
                        </mat-form-field>

                        <mat-form-field *ngIf="typeEnum.SELECT ==controlType" fxFlex="100" appearance="outline">
                            <mat-label class="text-capitalized">{{controlLabel}}</mat-label>
                            <mat-select [formControl]="control.value">
                                <mat-option *ngFor="let value of [0, 1, 2, 3, 4, 5]">{{value}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- <div fxFlex="1 1 100%" fxLayout="column" class="mb-8" *ngIf="typeEnum.HTML ==  controlType">
                            <ckeditor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
                                *ngIf="controlLabel=='html' && config.controls.language.value=='en'"
                                [formControl]="control.value" ngDefaultControl name="myckeditor1" [config]="ckeConfig">
                            </ckeditor>
                            <div style=" font-size: 10px;color: red;"
                                *ngIf="control.value.hasError('required')  && controlLabel=='html' && config.controls.language.value=='en'">
                                {{'Html En is required' | translate}}
                            </div>
                            <ckeditor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
                                *ngIf="controlLabel=='html_ar' && config.controls.language.value=='ar' "
                                [formControl]="control.value" ngDefaultControl name="myckeditor1" [config]="ckeConfig">
                            </ckeditor>
                            <div style=" font-size: 10px;color: red;"
                                *ngIf="control.value.hasError('required') && controlLabel=='html_ar' && config.controls.language.value=='ar'">
                                {{'Html Ar is required' | translate}}
                            </div>
                        </div> -->

                        <div fxFlex="1 1 100%" fxLayout="column" class="mb-8" *ngIf="typeEnum.HTML ==  controlType">
                            <mat-slide-toggle class="mb-8 align-self"
                                *ngIf="controlLabel=='html' && config.controls.language.value=='en'"
                                [formControl]="config.controls.isCodeEditor">
                                {{!config.controls.isCodeEditor.value ?
                                ('Switch to Code Editor' | translate)
                                : ('Switch to HTML Editor' | translate)}}
                            </mat-slide-toggle>
                            <mat-slide-toggle class="mb-8 align-self"
                                *ngIf="controlLabel=='html_ar' && config.controls.language.value=='ar' "
                                [formControl]="config.controls.isCodeEditor">
                                {{!config.controls.isCodeEditor.value ?
                                ('Switch to Code Editor' | translate)
                                : ('Switch to HTML Editor' | translate)}}
                            </mat-slide-toggle>
                            <ckeditor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
                                *ngIf="controlLabel=='html' && config.controls.language.value=='en' && !config.controls.isCodeEditor.value"
                                [formControl]="control.value" ngDefaultControl name="myckeditor" [config]="ckeConfig">
                            </ckeditor>
                            <div fxFlex="0 0 auto" fxLayout="column"
                                *ngIf="controlLabel=='html' && config.controls.language.value=='en' && config.controls.isCodeEditor.value">
                                <mat-icon class="zoom-icon cursor-pointer highlight-text-color"
                                    (click)="zoomAdditionalCss(control.value,i,'html')">
                                    zoom_out_map</mat-icon>
                                <ace-editor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
                                    [theme]="'clouds'" [mode]="'html'" [options]="options" [formControl]="control.value"
                                    style="height:50vh;">
                                </ace-editor>
                            </div>
                            <div style="font-size: 10px;color: red;"
                                *ngIf="control.value.hasError('required')  && controlLabel=='html' && config.controls.language.value=='en'">
                                {{'Html En is required' | translate}}
                            </div>
                            <div fxFlex="0 0 auto" fxLayout="column"
                                *ngIf="controlLabel=='html_ar' && config.controls.language.value=='ar' && config.controls.isCodeEditor.value">
                                <mat-icon class="zoom-icon highlight-text-color cursor-pointer"
                                    (click)="zoomAdditionalCss(control.value,i,'html_ar')">
                                    zoom_out_map</mat-icon>
                                <ace-editor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
                                    [theme]="'clouds'" [mode]="'html'" [options]="options" [formControl]="control.value"
                                    style="height:50vh;">
                                </ace-editor>
                            </div>
                            <ckeditor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}"
                                *ngIf="controlLabel=='html_ar' && config.controls.language.value=='ar' && !config.controls.isCodeEditor.value"
                                [formControl]="control.value" ngDefaultControl name="myckeditor1" [config]="ckeConfig">
                            </ckeditor>
                            <div style=" font-size: 10px;color: red;"
                                *ngIf="control.value.hasError('required') && controlLabel=='html_ar' && config.controls.language.value=='ar'">
                                {{'Html Ar is required' | translate}}
                            </div>
                        </div>
                        <!-- <div fxFlex="1 1 100%" fxLayout="column" class="mb-8"
                            *ngIf="typeEnum.HTML ==  controlType && elementName=='HTML'" fxLayoutGap="1em">
                            <ckeditor [formControl]="control.value" ngDefaultControl name="myckeditor1"
                                [config]="ckeConfig">
                            </ckeditor>
                        </div> -->

                        <!-- <div fxFlex="1 1 100%" fxLayout="column" class="mb-8"
                            *ngIf="typeEnum.IFRAME == controlType  && elementName=='IFRAME'" fxLayoutGap="1em">
                            <ckeditor [formControl]="control.value" ngDefaultControl name="myckeditor1"
                                [config]="ckeConfig">
                            </ckeditor>
                        </div> -->

                        <div fxFlex="1 1 100%" fxLayout="row" class="mb-8"
                            *ngIf="typeEnum.CAROUSEL == controlType  && elementName=='COLLECTION'" fxLayoutGap="1em">

                            <mat-form-field fxFlex="100" appearance="outline" style="width: 90%;">
                                <mat-label class="text-capitalized">{{controlLabel}}</mat-label>
                                <mat-select [formControl]="control.value">
                                    <mat-option [value]="sliderObj.id" *ngFor="let sliderObj of slider">
                                        {{sliderObj.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <button mat-mini-fab class="ml-10 add-collection-button bgColorClass"
                                matTooltip="Add new collection" (click)="addNewCollection(control.value)">
                                <mat-icon class="cursor-pointer" matPrefix>add</mat-icon>
                            </button>

                        </div>
                        <div fxFlex="1 1 100%" fxLayout="column" class="mb-8" *ngIf="typeEnum.GALLERY == controlType"
                            fxLayoutGap="1em">
                            <app-gallery-popup [formcontrol]="control.value"></app-gallery-popup>
                        </div>

                        <!-- <div fxFlex="1 1 100%" fxLayout="column" class="mb-8"
                            *ngIf="typeEnum.SLIDER == controlType && elementName=='SLIDER'" fxLayoutGap="1em">
                            <mat-form-field fxFlex="100" appearance="outline">
                                <mat-label class="text-capitalized">{{controlLabel}}</mat-label>
                                <mat-select [formControl]="control.value">
                                    <mat-option [value]="sliderObj.id" *ngFor="let sliderObj of slider">
                                        {{sliderObj.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->

                        <div fxFlex="1 1 100%" fxLayout="column" class="mb-8"
                            *ngIf="typeEnum.MOBILE_TABS == controlType && elementName=='MOBILE-TABS'" fxLayoutGap="1em">
                            <mat-form-field fxFlex="100" appearance="outline">
                                <mat-label class="text-capitalized">{{controlLabel}}</mat-label>
                                <mat-select [formControl]="control.value">
                                    <mat-option [value]="menuObj.id" *ngFor="let menuObj of menu">{{menuObj.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxFlex="1 1 100%" fxLayout="column" class="mb-8"
                            *ngIf="typeEnum.MENU == controlType && elementName=='MENU'" fxLayoutGap="1em">
                            <mat-form-field fxFlex="100" appearance="outline">
                                <mat-label class="text-capitalized">{{controlLabel}}</mat-label>
                                <mat-select [formControl]="control.value">
                                    <mat-option [value]="menuObj.id" *ngFor="let menuObj of menu">{{menuObj.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxFlex="1 1 100%" fxLayout="column" class="mb-8"
                            *ngIf="typeEnum.EMBED == controlType && elementName=='EMBED'">
                            <div fxFlex="0 0 auto" fxLayout="column"
                                *ngIf="controlLabel=='embed' && config.controls.language.value=='en'">
                                <mat-icon class="zoom-icon highlight-text-color cursor-pointer"
                                    (click)="zoomAdditionalCss(control.value,i,'embed')">
                                    zoom_out_map</mat-icon>
                                <ace-editor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}" [mode]="'css'"
                                    [theme]="'clouds'" [options]="options" [formControl]="control.value"
                                    style="height:80vh;">
                                </ace-editor>
                            </div>
                            <div fxFlex="0 0 auto" fxLayout="column"
                                *ngIf="controlLabel=='embed_ar' && config.controls.language.value=='ar'">
                                <mat-icon class="zoom-icon highlight-text-color cursor-pointer"
                                    (click)="zoomAdditionalCss(control.value,i,'embed_ar')">
                                    zoom_out_map</mat-icon>
                                <ace-editor [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}" [mode]="'css'"
                                    [theme]="'clouds'" [options]="options" [formControl]="control.value"
                                    style="height:80vh;">
                                </ace-editor>
                            </div>

                        </div>
                        <div *ngIf="typeEnum.IMAGE_URL == controlType" fxFlex="1 1 100%" fxLayout="column"
                            fxLayoutAlign="start stretch" fxLayoutGap="5px" class="mb-16">
                            <ng-container
                                *ngIf="!config.controls[controlLabel].value && !containAr(controlLabel) && config.controls.language.value=='en'">
                                <div>{{controlLabel}}</div>
                                <app-cms-upload-media [showOnly]="getShowOnly(controlLabel)"
                                    [accept]="getMediaType(controlLabel)"
                                    (afterUpload)="setFileToForm($event,i,controlLabel)"
                                    [isMultiSelect]="false" [formcontrol]="control.value" [index]="i">
                                </app-cms-upload-media>
                            </ng-container>
                            <small
                                *ngIf="!containAr(controlLabel) && config.controls.language.value=='en' && control.value.hasError('required')"
                                style="color: red !important;">
                                {{controlLabel+' en is required'}}
                            </small>

                            <ng-container
                                *ngIf="!config.controls[controlLabel].value && containAr(controlLabel) && config.controls.language.value=='ar'">
                                <div>{{controlLabel}}</div>
                                <app-cms-upload-media [showOnly]="config?.controls?.showOnly?.value"
                                    [accept]="config?.controls?.mediaType?.value"
                                    (afterUpload)="setFileToForm($event,i,controlLabel)"
                                    [isMultiSelect]="false" [formcontrol]="control.value" [index]="i">
                                </app-cms-upload-media>
                            </ng-container>
                            <small
                                *ngIf="containAr(controlLabel) && config.controls.language.value=='ar' && control.value.hasError('required')"
                                style="color: red !important;">
                                {{controlLabel+' ar is required'}}
                            </small>

                            <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px"
                                *ngIf="config.controls[controlLabel].value && !containAr(controlLabel) && config.controls.language.value=='en'">
                                <div>{{controlLabel}}</div>
                                <div class="fuse-card auto-width box-shadow-none" fxFlex="0 0 auto"
                                    fxLayoutAlign="space-between center" fxLayoutGap="5px" fxLayout="row">
                                    <div class="img-container">
                                        <app-show-all-type-media [url]="config.controls[controlLabel].value">
                                        </app-show-all-type-media>
                                    </div>
                                    <div class="px-16 py-4" fxLayout="row" fxLayoutAlign="center center">
                                        <button mat-icon-button aria-label="Delete">
                                            <mat-icon (click)="deleteFile(i,controlLabel)"
                                                class="action-btn-delete">
                                                delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px"
                                *ngIf="config.controls[controlLabel].value && containAr(controlLabel) && config.controls.language.value=='ar'">
                                <div>{{controlLabel}}</div>
                                <div class="fuse-card auto-width  box-shadow-none" fxFlex="0 0 auto"
                                    fxLayoutAlign="space-between center" fxLayoutGap="5px" fxLayout="row">

                                    <div class="img-container">
                                        <app-show-all-type-media [url]="config.controls[controlLabel].value">
                                        </app-show-all-type-media>
                                    </div>
                                    <div class="px-16 py-4" fxLayout="row" fxLayoutAlign="center center">
                                        <button mat-icon-button aria-label="Delete">
                                            <mat-icon (click)="deleteFile(i,controlLabel)"
                                                class="action-btn-delete">
                                                delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div *ngIf="typeEnum.VIDEO == controlType" fxFlex="1 1 100%" fxLayout="column"
                            fxLayoutAlign="start stretch" fxLayoutGap="5px" class="mb-16">
                            <div *ngIf="!config.controls.video.value && controlLabel=='video' && config.controls.language.value=='en'"
                                fxLayout="column" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                <app-cms-upload-media [accept]="mediaTypesAccept.Video" [showOnly]="mediaType.Video"
                                    (afterUpload)="setFileToForm($event,i,'video')" [isMultiSelect]="false"
                                    [formcontrol]="config.controls.video" [index]="i">
                                </app-cms-upload-media>
                            </div>
                            <div *ngIf="!config.controls.video_ar.value && controlLabel=='video_ar' && config.controls.language.value=='ar'"
                                fxLayout="column" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                <app-cms-upload-media [accept]="mediaTypesAccept.Video" [showOnly]="mediaType.Video"
                                    (afterUpload)="setFileToForm($event,i,'video_ar')" [isMultiSelect]="false"
                                    [formcontrol]="config.controls.video_ar" [index]="i">
                                </app-cms-upload-media>
                            </div>
                            <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px"
                                *ngIf="config.controls.video.value && controlLabel=='video' && config.controls.language.value=='en'">
                                <div class="fuse-card auto-width box-shadow-none" fxFlex="0 0 auto"
                                    fxLayoutAlign="space-between center" fxLayoutGap="5px" fxLayout="row">

                                    <div class="img-container">
                                        <app-show-all-type-media [url]="config.controls.video.value">
                                        </app-show-all-type-media>
                                    </div>
                                    <div class="px-16 py-4" fxLayout="row" fxLayoutAlign="center center">
                                        <button mat-icon-button aria-label="Delete">
                                            <mat-icon (click)="deleteFile(i,'video')" class="action-btn-delete">
                                                delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="1 1 100%" fxLayout="column"
                                *ngIf="config.controls.video_ar.value && controlLabel=='video_ar' && config.controls.language.value=='ar'"
                                fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                <div class="fuse-card auto-width box-shadow-none" fxFlex="0 0 auto"
                                    fxLayoutAlign="space-between center" fxLayoutGap="5px" fxLayout="row">
                                    <div class="img-container">
                                        <app-show-all-type-media [url]="config.controls.video_ar.value">
                                        </app-show-all-type-media>
                                    </div>
                                    <div class="px-16 py-4" fxLayout="row" fxLayoutAlign="center center">
                                        <button mat-icon-button aria-label="Delete">
                                            <mat-icon (click)="deleteFile(i,'video_ar')" class="action-btn-delete">
                                                delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="typeEnum.AUDIO == controlType" fxFlex="1 1 100%" fxLayout="column"
                            fxLayoutAlign="start stretch" fxLayoutGap="5px" class="mb-16">
                            <div *ngIf="!config.controls.audio.value && controlLabel=='audio' && config.controls.language.value=='en'"
                                fxLayout="column" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                <app-cms-upload-media [accept]="mediaTypesAccept.Audio" [showOnly]="mediaType.Audio"
                                    (afterUpload)="setFileToForm($event,i,'audio')" [isMultiSelect]="false"
                                    [formcontrol]="config.controls.audio" [index]="i">
                                </app-cms-upload-media>
                            </div>
                            <div *ngIf="!config.controls.audio_ar.value && controlLabel=='audio_ar' && config.controls.language.value=='ar'"
                                fxLayout="column" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                <app-cms-upload-media [accept]="mediaTypesAccept.Audio" [showOnly]="mediaType.Audio"
                                    (afterUpload)="setFileToForm($event,i,'audio_ar')" [isMultiSelect]="false"
                                    [formcontrol]="config.controls.audio_ar" [index]="i">
                                </app-cms-upload-media>
                            </div>
                            <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px"
                                *ngIf="config.controls.audio.value && controlLabel=='audio' && config.controls.language.value=='en'">
                                <div class="fuse-card auto-width box-shadow-none" fxFlex="0 0 auto"
                                    fxLayoutAlign="space-between center" fxLayoutGap="5px" fxLayout="row">

                                    <div class="img-container">
                                        <app-show-all-type-media [url]="config.controls.audio.value">
                                        </app-show-all-type-media>
                                    </div>
                                    <div class="px-16 py-4" fxLayout="row" fxLayoutAlign="center center">
                                        <button mat-icon-button aria-label="Delete">
                                            <mat-icon (click)="deleteFile(i,'audio')" class="action-btn-delete">
                                                delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="1 1 100%" fxLayout="column"
                                *ngIf="config.controls.audio_ar.value && controlLabel=='audio_ar' && config.controls.language.value=='ar'"
                                fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                <div class="fuse-card auto-width box-shadow-none" fxFlex="0 0 auto"
                                    fxLayoutAlign="space-between center" fxLayoutGap="5px" fxLayout="row">
                                    <div class="img-container">
                                        <app-show-all-type-media [url]="config.controls.audio_ar.value">
                                        </app-show-all-type-media>

                                    </div>
                                    <div class="px-16 py-4" fxLayout="row" fxLayoutAlign="center center">
                                        <button mat-icon-button aria-label="Delete">
                                            <mat-icon (click)="deleteFile(i,'audio_ar')" class="action-btn-delete">
                                                delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="typeEnum.PDF == controlType" fxFlex="1 1 100%" fxLayout="column"
                            fxLayoutAlign="start stretch" fxLayoutGap="5px" class="mb-16">
                            <div *ngIf="!config.controls.pdf.value && controlLabel=='pdf' && config.controls.language.value=='en'"
                                fxLayout="column" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                <app-cms-upload-media [accept]="mediaTypesAccept.PDF" [showOnly]="mediaType.PDF"
                                    (afterUpload)="setFileToForm($event,i,'pdf')" [isMultiSelect]="false"
                                    [formcontrol]="config.controls.pdf" [index]="i">
                                </app-cms-upload-media>
                            </div>
                            <div *ngIf="!config.controls.pdf_ar.value && controlLabel=='pdf_ar' && config.controls.language.value=='ar'"
                                fxLayout="column" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                <app-cms-upload-media [accept]="mediaTypesAccept.PDF" [showOnly]="mediaType.PDF"
                                    (afterUpload)="setFileToForm($event,i,'pdf_ar')" [isMultiSelect]="false"
                                    [formcontrol]="config.controls.pdf_ar" [index]="i">
                                </app-cms-upload-media>
                            </div>
                            <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px"
                                *ngIf="config.controls.pdf.value && controlLabel=='pdf' && config.controls.language.value=='en'">
                                <div class="fuse-card auto-width box-shadow-none" fxFlex="0 0 auto"
                                    fxLayoutAlign="space-between center" fxLayoutGap="5px" fxLayout="row">

                                    <div class="img-container">
                                        <app-show-all-type-media [url]="config.controls.pdf.value">
                                        </app-show-all-type-media>
                                    </div>
                                    <div class="px-16 py-4" fxLayout="row" fxLayoutAlign="center center">
                                        <button mat-icon-button aria-label="Delete">
                                            <mat-icon (click)="deleteFile(i,'pdf')" class="action-btn-delete">
                                                delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="1 1 100%" fxLayout="column"
                                *ngIf="config.controls.pdf_ar.value && controlLabel=='pdf_ar' && config.controls.language.value=='ar'"
                                fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                <div class="fuse-card auto-width box-shadow-none" fxFlex="0 0 auto"
                                    fxLayoutAlign="space-between center" fxLayoutGap="5px" fxLayout="row">
                                    <div class="img-container">
                                        <app-show-all-type-media [url]="config.controls.pdf_ar.value">
                                        </app-show-all-type-media>
                                    </div>
                                    <div class="px-16 py-4" fxLayout="row" fxLayoutAlign="center center">
                                        <button mat-icon-button aria-label="Delete">
                                            <mat-icon (click)="deleteFile(i,'pdf_ar')" class="action-btn-delete">
                                                delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>