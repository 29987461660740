import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "../api.service";

@Injectable({
    providedIn: "root",
})
export class ShopifyService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    installShopify(id) {
        let form = {
            app_id: id,
        };
        return this.apiService.post(
            this.sharedService.shopifyInstall,
            form,
            undefined
        );
    }

    unInstallShopify(id) {
        let form = {
            app_id: id,
        };
        return this.apiService.post(
            this.sharedService.shopifyunInstall,
            form,
            undefined
        );
    }

    getActions(id) {
        return this.apiService.get(
            this.sharedService.shopifyActions + `?app_id=${id}`,
            undefined,
            undefined
        );
    }
    appIsExistOrNot() {
        return this.apiService.get(
            this.sharedService.shopifyExistOrNot,
            undefined,
            undefined,
            false
        );
    }

    syncProducts(id) {
        return this.apiService.get(
            this.sharedService.syncProducts+`?app_id=${id}`,
            undefined,
            undefined,
            false
        );
    }
    syncStcoks(id) {
        return this.apiService.get(
            this.sharedService.syncStocks+`?app_id=${id}`,
            undefined,
            undefined,
            false
        );
    }
    syncProductsProgress(id){
        return this.apiService.get(
            this.sharedService.syncProductsProgress+`?app_id=${id}`,
            undefined,
            undefined,
            false
        );
    }
}
