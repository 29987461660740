import {
    animate,
    state,
    style,
    transition,
    trigger,
} from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";

import { CatalogueService } from "app/services/catalogue/catalogue.service";

import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
export const customAnimation = [
    trigger("simpleFadeAnimation", [
        state("in", style({ opacity: 1, transform: "rotate(0)" })),
        transition(":enter", [
            style({ opacity: 0, transform: "rotate(-360deg)" }),
            animate(200),
        ]),
        transition(
            ":leave",
            animate(400, style({ opacity: 0, transform: "rotate(360deg)" }))
        ),
    ]),
];
@Component({
    selector: "app-import-details",
    templateUrl: "./import-details.component.html",
    styleUrls: ["./import-details.component.scss"],
    animations: [fuseAnimations, , customAnimation],
})
export class ImportDetailsComponent implements OnInit {
    @Input() dryResponse: any;

    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    @Output() afterImportFile: EventEmitter<void> = new EventEmitter<any>();
    enableIcon: boolean;
    isEnableBack: boolean;
    label: any;
    displayField: boolean;
    constructor(
        private catalogueService: CatalogueService,

        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        public translationService: TranslationService
    ) {}
    async back() {
        this.displayField = false;
        this.goBack();
    }
    async excute() {
        this.utilitiesService.startLoader();
        await this.catalogueService
            .getSyncJobByjobId(this.dryResponse.job_id)
            .subscribe((data) => {
                this.displayField = false;
                this.utilitiesService.stopLoader();
                this.goBack();
            });
    }
    goBack() {
        this.cancelClick.emit();
    }
    ngOnInit(): void {}
}
