import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { NotificationService } from "app/services/notification/notification.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { NotificationEventType, NotificationType } from "appElementConstants";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";

@Component({
  selector: "app-create-notification-config",
  templateUrl: "./create-notification-config.component.html",
  styleUrls: ["./create-notification-config.component.scss"],
  animations: fuseAnimations,
})
export class CreateNotificationConfigComponent implements OnInit {
  @Input() id: any = "";
  @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
  @Output() createNotification: EventEmitter<void> = new EventEmitter<any>();
  notificationType = new NotificationEventType();
  notificationForm: FormGroup;
  templates = [];
  templateLimit: number = 5;
  details: any;
  ckeConfig: {
    sanitize: boolean;
    allowedContent: boolean;
    removeButtons: string;
    versionCheck?: boolean;
  };
  constructor(
    private fb: FormBuilder,
    public utilitiesService: UtilitiesService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.formInit();
    this.utilitiesService.startLoader();
    await this.getTemplates();
    if (this.id) {
      await this.getDetails();
    }
    this.ckeConfig = {
      sanitize: false,
      allowedContent: true,
      versionCheck: false,
      removeButtons: "CreateDiv,BlockQuote,Superscript,Subscript,PasteFromWord,PasteText,Source",
      // ],
    };
  }

  async templatelimitChange() {
    await this.getTemplates(this.notificationForm.controls.templateLimit.value);
  }

  async getTemplates(templateLimit = 5) {
    try {
      let offset = this?.templates?.length || 0;
      const templates = await this.notificationService.getNotificationList(templateLimit, 0).toPromise();
      if (templates) {
        this.templates = [...templates.results];
      }
      if (!this.id) {
        this.utilitiesService.stopLoader();
      }
    } catch {
      this.utilitiesService.stopLoader();
    } finally {
    }
  }
  async getDetails() {
    try {
      const details = await this.notificationService.getConfigurationsDetails(this.id).toPromise();
      if (details) {
        this.details = details;
        await this.formInit();
        this.utilitiesService.stopLoader();
      }
    } catch {
      this.utilitiesService.stopLoader();
    } finally {
    }
  }
  async formInit() {
    this.notificationForm = this.fb.group({
      recipient: [this.details ? this.details.recipient : null, [Validators.required]],
      template: [this.details ? this.details.template : null, [Validators.required]],
      event_type: [this.details ? this.details.event_type : null, [Validators.required]],
      templateLimit: [5],
    });
  }

  saveOrUpdate() {
    if (this.notificationForm.valid) {
      let form = this.notificationForm.value;
      form.title = form.title_en;
      form.body = form.body_en;
      if (!this.id) {
        try {
          let content = `Are you sure, Do you want to save this configuration ? `;
          let heading = "Save";
          let fromApp = false;
          let size = this.utilitiesService.isMobileAlertModal();
          const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
          });
          dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
              this.notificationService.addConfig(form).subscribe((data) => {
                let successMsg = this.translate.instant("Configuration created successfully");
                this.utilitiesService.showSuccessToast(successMsg);
                this.createNotification.emit();
              });
            }
          });
        } catch {
        } finally {
          this.utilitiesService.stopLoader();
        }
      } else if (this.id) {
        try {
          let content = `Are you sure, Do you want to update this configuration ? `;
          let heading = "Update";
          let fromApp = false;
          let size = this.utilitiesService.isMobileAlertModal();
          const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
          });
          dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
              this.notificationService.updateConfig(form, this.id).subscribe((data) => {
                let successMsg = this.translate.instant("Configuration updated successfully");
                this.utilitiesService.showSuccessToast(successMsg);
                this.createNotification.emit();
              });
            }
          });
        } catch {
        } finally {
          this.utilitiesService.stopLoader();
        }
      }
    } else {
      let errorMsg = this.translate.instant("Form is not valid");
      this.utilitiesService.showErrorToast(errorMsg);
    }
  }
}
