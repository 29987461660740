<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" [formGroup]="form">
            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back</mat-icon>
                    <label class="highlight-text-color">{{'SMS Configuration' | translate}}</label>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <button (click)="save()" type="submit" mat-flat-button style="position: absolute;right: 16px;"
                        class="primary-bg-color">{{'Save' | translate}}</button>
                </div>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                [@animate]="{value:'*',params:{duration:'400ms',y:'70px'}}">
                <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 center-container" fxLayoutAlign="start stretch"
                    fxLayout="column" fxLayoutGap="1em">
                    <label class="highlight-text-color">{{'Outgoing SMS Settings' | translate}}</label>

                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="2em">
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <fieldset class="fieldset" fxFlex="1 1 auto" fxLayoutAlign="start stretch"
                                    fxLayout="column" fxLayoutGap="0px">
                                    <legend class="highlight-text-color">{{'Kuwait Configuration' | translate}}</legend>
                                    <div fxFlex="0 0 auto" fxLayoutAlign="flex-end stretch" fxLayout.lt-md="column"
                                        fxLayout.gt-sm="row" fxLayoutGap="1em">
                                        <div class="min-height" fxFlex.lt-md="0 0 auto" fxFlex.gt-sm="0 0 50%"
                                            fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                            <mat-form-field appearance="outline" fxFlex="100">
                                                <mat-label class="text-capitalized">
                                                    {{'Sender ID'| translate}}
                                                </mat-label>
                                                <input [type]="config?.sender_local_id ? 'password' : 'text'" #senderID
                                                    matTooltip="{{'help_text_local_Sender_id' | translate}}"
                                                    formControlName="sender_local_id" matInput name="title">
                                                <button mat-icon-button type="button"
                                                    (click)="senderID.type === 'password' ? senderID.type = 'text' : senderID.type = 'password'"
                                                    matSuffix>
                                                    <i class="fas fa-eye" *ngIf="senderID.type === 'password'"></i>
                                                    <i class="fas fa-eye-slash" *ngIf="senderID.type === 'text'"></i>
                                                </button>
                                                <mat-error *ngIf="form.get('sender_local_id').hasError('required')">
                                                    {{'Sender ID is required' | translate}}
                                                </mat-error>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" fxFlex="100">
                                                <mat-label class="text-capitalized">
                                                    {{'SMS IID'| translate}}
                                                </mat-label>
                                                <input [type]="config?.fc_sms_iid ? 'password' : 'text'" #smsIid
                                                    matTooltip="{{'help_text_local_smsIid' | translate}}"
                                                    formControlName="fc_sms_iid" matInput name="title">
                                                <button mat-icon-button type="button"
                                                    (click)="smsIid.type === 'password' ? smsIid.type = 'text' : smsIid.type = 'password'"
                                                    matSuffix>
                                                    <i class="fas fa-eye" *ngIf="smsIid.type === 'password'"></i>
                                                    <i class="fas fa-eye-slash" *ngIf="smsIid.type === 'text'"></i>
                                                </button>

                                            </mat-form-field>
                                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                                fxLayoutGap="0px">
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label class="text-capitalized">
                                                        {{'SMS UID'| translate}}
                                                    </mat-label>
                                                    <input [type]="config?.fc_sms_uid ? 'password' : 'text'" #smsUid
                                                        matTooltip="{{'help_text_local_smsUid' | translate}}"
                                                        formControlName="fc_sms_uid" matInput name="title">
                                                    <button mat-icon-button type="button"
                                                        (click)="smsUid.type === 'password' ? smsUid.type = 'text' : smsUid.type = 'password'"
                                                        matSuffix>
                                                        <i class="fas fa-eye" *ngIf="smsUid.type === 'password'"></i>
                                                        <i class="fas fa-eye-slash"
                                                            *ngIf="smsUid.type === 'text'"></i>
                                                    </button>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" fxFlex="100">
                                                    <mat-label class="text-capitalized">
                                                        {{'Password'| translate}}
                                                    </mat-label>
                                                    <input type="password" #smsPassword
                                                        matTooltip="{{'help_text_local_smsPassword' | translate}}"
                                                        formControlName="fc_sms_password" matInput name="title">
                                                    <button mat-icon-button type="button"
                                                        (click)="smsPassword.type === 'password' ? smsPassword.type = 'text' : smsPassword.type = 'password'"
                                                        matSuffix>
                                                        <i class="fas fa-eye"
                                                            *ngIf="smsPassword.type === 'password'"></i>
                                                        <i class="fas fa-eye-slash"
                                                            *ngIf="smsPassword.type === 'text'"></i>
                                                    </button>

                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <app-config-notes [notes]="notesLocal"></app-config-notes>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <fieldset class="fieldset" fxFlex="1 1 auto" fxLayoutAlign="start stretch"
                                    fxLayout="column" fxLayoutGap="0px">
                                    <legend class="highlight-text-color">{{'International Configuration' | translate}}
                                    </legend>
                                    <div fxFlex="0 0 auto" fxLayoutAlign="flex-end stretch" fxLayout.lt-md="column"
                                        fxLayout.gt-sm="row" fxLayoutGap="1em">
                                        <div class="min-height" fxFlex.lt-md="0 0 auto" fxFlex.gt-sm="0 0 50%"
                                            fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                            <mat-form-field appearance="outline" fxFlex="100">
                                                <mat-label class="text-capitalized">
                                                    {{'Sender ID'| translate}}
                                                </mat-label>
                                                <input [type]="config?.sender_international_id ? 'password' : 'text'"
                                                    #InterSenderID
                                                    matTooltip="{{'help_text_International_Sender_id' | translate}}"
                                                    formControlName="sender_international_id" matInput name="title">
                                                <button mat-icon-button type="button"
                                                    (click)="InterSenderID.type === 'password' ? InterSenderID.type = 'text' : InterSenderID.type = 'password'"
                                                    matSuffix>
                                                    <i class="fas fa-eye" *ngIf="InterSenderID.type === 'password'"></i>
                                                    <i class="fas fa-eye-slash"
                                                        *ngIf="InterSenderID.type === 'text'"></i>
                                                </button>
                                         
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" fxFlex="100">
                                                <mat-label class="text-capitalized">
                                                    {{'API Key'| translate}}
                                                </mat-label>
                                                <input [type]="'password'" #InterAPIKey
                                                    matTooltip="{{'help_text_internation_api_key' | translate}}"
                                                    formControlName="sms_international_token" matInput name="title">
                                                <button mat-icon-button type="button"
                                                    (click)="InterAPIKey.type === 'password' ? InterAPIKey.type = 'text' : InterAPIKey.type = 'password'"
                                                    matSuffix>
                                                    <i class="fas fa-eye" *ngIf="InterAPIKey.type === 'password'"></i>
                                                    <i class="fas fa-eye-slash" *ngIf="InterAPIKey.type === 'text'"></i>
                                                </button>
                                             
                                            </mat-form-field>
                                        </div>
                                        <app-config-notes [notes]="notesInternational"></app-config-notes>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>