<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
            [formGroup]="generalForm">
            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back</mat-icon>
                    <label class="highlight-text-color">{{'Mobile App Settings' | translate}}</label>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <button (click)="save()" type="submit" mat-flat-button style="position: absolute;right: 16px;"
                        class="primary-bg-color">{{'Save' | translate}}</button>
                </div>
            </div>
            <div fxFlex="0 0 auto" class="fuse-card auto-width p-16 " fxLayoutAlign="start stretch" fxLayout="column"
                fxLayoutGap="1em">
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                            <mat-slide-toggle formControlName="ask_for_mobile_app_rating">
                                {{'Ask for Mobile Apps Ratings' | translate}}
                            </mat-slide-toggle>

                        </div>
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                            <mat-slide-toggle formControlName="enable_pwa">
                                {{'Enable PWA' | translate}}
                            </mat-slide-toggle>

                        </div>
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                            fxLayout.lt-md="column" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Play Store ID'| translate}}
                                    </mat-label>
                                    <input matInput name="title" formControlName="playstore_id">
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'App Store ID'| translate}}
                                    </mat-label>
                                    <input matInput name="title" formControlName="appstore_id">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                            fxLayout.lt-md="column" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Faceboook APP ID'| translate}}
                                    </mat-label>
                                    <input matInput name="title" formControlName="fb_app_id">
                                </mat-form-field>
                            </div>
                            <div fxFlex="1 1 auto">
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Facebook Client Token'| translate}}
                                    </mat-label>
                                    <input matInput name="title" formControlName="fb_client_token">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>