import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatChipsModule } from "@angular/material/chips";
@Component({
    selector: "app-title-format",
    templateUrl: "./title-format.component.html",
    styleUrls: ["./title-format.component.scss"],
})
export class TitleFormatComponent implements OnChanges {
    @Input() contrl: FormControl;
    @Input() title: string;

    pageTitleKeyword = [
        {
            name: "Name",
            value: "name",
        },
        {
            name: "Shop Title",
            value: "shop_title",
        },
        {
            name: "Meta Description",
            value: "meta_description",
        }
    ];

    ngOnChanges(changes: SimpleChanges): void {
        const titleRequiredFor = [
            "Category Title",
            "Brand Title",
            "Product Title",
        ];
        if (!titleRequiredFor.includes(this.title)) {
            //adding title for page and post
            const obj = {
                name: "Title",
                value: `title`,
            };
            this.pageTitleKeyword.push(obj);

            //removing name keyword for page and post
            this.pageTitleKeyword = this.pageTitleKeyword.filter((ele) => {
                if (ele.name !== "Name") {
                    return ele;
                }
            });
        }
    }

    onKeywordSelection(keyword) {
        //check if title is having AR then add `${keyword}_ar` else add `${keyword}_en`
        const suffix = this.title.includes("AR") ? "_ar" : "_en";
        const page_title_format = this.contrl.value
            ? `${this.contrl.value}{{${keyword}${suffix}}}`
            : `{{${keyword}${suffix}}}`;
        this.contrl.setValue(page_title_format);
    }
}
