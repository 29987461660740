<!-- <mat-spinner diameter="50" style="left: 35%;top: 30%;position: absolute;" *ngIf="loading" strokeWidth="5">
</mat-spinner> -->
<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
            [formGroup]="deliveryForm">
            <!-- (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" -->
            <!-- (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)" -->
            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back</mat-icon>
                    <label class="highlight-text-color">{{'Delivery Settings' | translate}}</label>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <button (click)="save()" type="submit" mat-flat-button style="position: absolute;right: 16px;"
                        class="primary-bg-color">{{'Save' | translate}}</button>
                </div>
            </div>
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                [@animate]="{value:'*',params:{duration:'400ms',y:'70px'}}">
                <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 center-container" fxLayoutAlign="start stretch"
                    fxLayout="column" fxLayoutGap="1em">
                    <label class="highlight-text-color">{{'Delivery & Currency  Settings' | translate}}</label>
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row" fxLayout.lt-md="column"
                            fxLayoutGap="1em">
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                <mat-slide-toggle formControlName="has_delivery">{{'Enable Delivery' | translate}}
                                </mat-slide-toggle>
                                <small>{{'help_text_EnableDelivery' | translate}}</small>
                            </div>
                        </div>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                            <div fxFlex="1 1 0px" fxLayout="column" fxLayoutGap="5px">
                                <label class="label-heading">{{'Delivery Mode' | translate}}</label>
                                <angular2-multiselect formControlName="delivery_modes" [data]="deliveryModeDropDown"
                                    [settings]="deliveryModeSettings">
                                </angular2-multiselect>
                            </div>
                        </div>
                        <div fxFlex="1 0 0px" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px"
                            *ngIf="deliveryForm.get('has_delivery').value">
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                                fxLayout.lt-md="column" fxLayoutGap="0px">
                                <div fxFlex="1 1 0px" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                    <mat-slide-toggle formControlName="has_delivery_charges">
                                        {{'Fixed Delivery Charges' | translate}}
                                    </mat-slide-toggle>
                                    <small>{{'help_text_HasDeliveryCharges' | translate}}</small>
                                </div>
                                <div fxFlex="1 1 0px" *ngIf="deliveryForm.get('has_delivery_charges').value">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Charges'| translate}}
                                        </mat-label>
                                        <input formControlName="charges" matInput name="title">
                                        <!-- <mat-error *ngIf="deliveryForm.get('charges').hasError('pattern')">
                                            {{'Charges must have 2 decimal' | translate}}
                                        </mat-error> -->
                                    </mat-form-field>
                                </div>
                            </div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="0px">
                                        <mat-slide-toggle formControlName="restrict_area">
                                            {{'Restrict Area' | translate}}
                                        </mat-slide-toggle>
                                        <small>{{'help_text_RestrictArea' | translate}}</small>
                                    </div>
                                </div>
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
                                    fxLayout.lt-md="column" fxLayoutGap="10px">
                                    <!-- <div fxFlex="1 1 0px">
                                        <angular2-multiselect formControlName="countries" [data]="countryDropDown"
                                            [settings]="countryModeSettings">
                                        </angular2-multiselect>
                                    </div> -->
                                    <div fxFlex="1 1 0px" fxLayout="column" fxLayoutGap="5px"
                                        *ngIf="deliveryForm.get('restrict_area').value">
                                        <label class="label-heading">{{'Area' | translate}}</label>
                                        <angular2-multiselect formControlName="areas" [data]="areaDropDown"
                                            [settings]="areaSettings">
                                        </angular2-multiselect>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>