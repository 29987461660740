import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { AppRoutes } from "app/routes";
import { AppService } from "app/services/app.service";
import { CoreService } from "app/services/core-service";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { AppElements, Appitems } from "appElementConstants";

@Component({
    selector: "app-create-app",
    templateUrl: "./create-app.component.html",
    styleUrls: ["./create-app.component.scss"],
    animations: fuseAnimations,
})
export class CreateAppComponent implements OnInit {
    @Input() id: any = "";
    appDetail: any;
    selectedBlock = "";
    appItemsArray = new Appitems();
    descriptionArray = [];
    constructor(
        private appService: AppService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        public translationService: TranslationService,
        private router: Router,
        private coreService: CoreService
    ) { }

    async ngOnInit() {
        this.descriptionArray = [
            {
                id: AppElements.GeneralSettings,
                description: this.translate.instant(
                    "Setup site's general settings like title,language etc"
                ),
            },
            {
                id: AppElements.CatalogSettings,
                description: this.translate.instant(
                    "Customize your homepage settings, elastic search and filter settings etc"
                ),
            },
            {
                id: AppElements.Design,
                description: this.translate.instant(
                    "Customize the app design using css, add logo, fav icons etc"
                ),
            },
            {
                id: AppElements.DeliverySettings,
                description: this.translate.instant(
                    "Enable delivery , add delivery mode etc"
                ),
            },
            {
                id: AppElements.DeliveryScheduling,
                description: this.translate.instant(
                    "Enable delivery scheduling, set up scheduling time and etc"
                ),
            },
            {
                id: AppElements.CountryRules,
                description: this.translate.instant(
                    "Add base currency , base country and multiple country rules"
                ),
            },
            {
                id: AppElements.SocialMedia,
                description: this.translate.instant("Setup social media"),
            },
            {
                id: AppElements.MyPadyoSettings,
                description: this.translate.instant("Setup Mypaydo Settings"),
            },
            {
                id: AppElements.FirebaseSettings,
                description: this.translate.instant("Setup firebase Settings"),
            },
            {
                id: AppElements.Analytics,
                description: this.translate.instant("Setup analytics Settings"),
            },
            {
                id: AppElements.Support,
                description: this.translate.instant("Add support information"),
            },
            {
                id: AppElements.TermsConditions,
                description: this.translate.instant(
                    "Add terms and conditions and its setup"
                ),
            },
            {
                id: AppElements.Status,
                description: this.translate.instant("Setup app statuses"),
            },
            {
                id: AppElements.Translation,
                description: this.translate.instant(
                    "Setup Translation for app"
                ),
            },
            {
                id: AppElements.CustomFields,
                description: this.translate.instant("Setup custom fields"),
            },
            {
                id: AppElements.FooterConfig,
                description: this.translate.instant("Footer Config"),
            },
            {
                id: AppElements.EmailConfiguration,
                description: this.translate.instant(
                    "Setup email configuration"
                ),
            },
            {
                id: AppElements.SMSConfiguration,
                description: this.translate.instant("Setup sms configuration"),
            },
            {
                id: AppElements.MobileApp,
                description: this.translate.instant("Set up mobile app settings"),
            },
        ];

        let isShow = this.coreService.checkPermission(
            this.utilitiesService.Permission.AppConfig
        );

        let isShowTranslation = this.coreService.checkPermission(
            this.utilitiesService.Permission.TranslateList
        );

        let isShowDynamic = this.coreService.checkPermission(
            this.utilitiesService.Permission.DynamicFieldCreate
        );

        this.appItemsArray.appItems.forEach((data) => {
            if (!isShow) {
                data.hidden = true;
            }
            if (data.id == AppElements.Translation) {
                if (!isShowTranslation) {
                    data.hidden = true;
                } else {
                    data.hidden = false;
                }
            } else if (data.id == AppElements.CustomFields) {
                if (!isShowDynamic) {
                    data.hidden = true;
                } else {
                    data.hidden = false;
                }
            } else if (data.id == AppElements.EmailConfiguration) {
                if (
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.ConfigList
                    ) &&
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.ConfigUpdate
                    ) &&
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.ConfigAdd
                    ) &&
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.ConfigDetail
                    )
                ) {
                    data.hidden = false;
                } else {
                    data.hidden = true;
                }
            } else if (data.id == AppElements.SMSConfiguration) {
                if (
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.ConfigList
                    ) &&
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.ConfigUpdate
                    ) &&
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.ConfigAdd
                    ) &&
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.ConfigDetail
                    )
                ) {
                    data.hidden = false;
                } else {
                    data.hidden = true;
                }
            }
            this.descriptionArray.forEach((items) => {
                if (data.id == items.id) {
                    data.description = items.description;
                }
            });
        });

        if (!isShow && !isShowTranslation && !isShowDynamic) {
            this.router.navigate([AppRoutes.ErrorPage]);
        }
    }

    selectBlock(id) {
        if (AppElements.Translation == id) {
            this.router.navigate([AppRoutes.TranslationEditor]);
        } else if (AppElements.CustomFields == id) {
            this.router.navigate([AppRoutes.DynamicField]);
        } else {
            if (this.appDetail) {
                this.childSave();
                this.selectedBlock = id;
            } else {
                let errorMgs = this.translate.instant(
                    "App Details are still loading Please wait"
                );
                this.utilitiesService.showWarningToast(errorMgs);
            }
        }
    }
    back() {
        this.selectedBlock = "";
    }
    childSave() {
        this.getAppDetails();
    }
    async getAppDetails() {
        let id = this.id ? this.id : localStorage.getItem("currentAppID");
        try {
            this.appService.getAppDetail(id).subscribe((data) => {
                const appDetail: any = data;
                this.appDetail = appDetail;
            });
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }
}
