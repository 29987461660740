import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { SharedService } from "./sharedService";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    private apiDomain: any;
    private Domain: any;
    private jwt: any;

    constructor(
        private http: HttpClient,
        private ss: SharedService,
        private apiService: ApiService
    ) {
        this.apiDomain = ss.apiDomain;
        this.Domain = ss.Domain;
    }
    validateToken(token) {
        let headers = {};
        let params = new HttpParams();
        params = params.set("token", token);
        return this.http
            .get(this.apiService.BASE_URL + this.ss.auth, {
                headers: headers,
                params: params,
            })
            .toPromise();
    }

    login(loginForm) {
        let headers = {};
        let body = loginForm;
        return this.http
            .post(this.apiService.BASE_URL + this.ss.login, body, {
                headers: headers,
            })
            .toPromise();
    }
}
