import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { MediaTypes, MediaTypesAccept } from "app/constants/media-type";
import { MediaService } from "app/services/media.service";
import { UtilitiesService } from "app/services/utilitiesService";
import { GalleryPopupComponent } from "../gallery-popup/gallery-popup.component";
import { read } from "fs";

@Component({
    selector: "app-cms-upload-media",
    templateUrl: "./cms-upload-media.component.html",
    styleUrls: ["./cms-upload-media.component.scss"],
})
export class CmsUploadMediaComponent {
    showOnlyMedia: any;
    acceptedMedia: any;
    @Input() index = 0;
    @Input() formcontrol: AbstractControl;
    @Input() isMultiSelect: boolean = false;
    @Input() set showOnly(value: any) {
        this.showOnlyMedia = value || null;
    }
    @Input() set accept(value: any) {
        this.acceptedMedia = value || null;
    }
    @Input() uploadOnly: boolean = false;

    @Input() showBrowseOnly: boolean;

    @Output() afterUpload: EventEmitter<any> = new EventEmitter<any>();
    @Input() saveLocally: boolean = false;
    progress = 0;
    constructor(
        private utilitiesService: UtilitiesService,
        private translate: TranslateService,
        private mediaService: MediaService,
        private dialog: MatDialog
    ) { }

    get showOnly() {
        return this.showOnlyMedia;
    }

    get accept() {
        return this.acceptedMedia;
    }

    ngOnInit(): void {
        //  console.log(this.showOnly);
    }

    onFileDropped(event) {
        // console.log(event);
        this.addMedia(event);
    }

    addMedia(files) {
        // console.log(files);
        let form = {
            name: files[0].name || null,
            file: files[0],
        };

        const formData = new FormData();
        formData.append("name", form.name);
        formData.append("file", form.file);
        try {
            this.utilitiesService.startLoader();
            if (!this.saveLocally) {
                this.mediaService.addMediafile(formData).subscribe((res) => {
                    this.afterUpload.emit(res);
                    this.utilitiesService.stopLoader();
                });
            } else {
                console.log(form);
                this.afterUpload.emit(form);
                this.utilitiesService.stopLoader();
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }

    fileBrowseHandler(files) {
        // console.log("fileBrowseHandler");
        // console.log(files);

        this.addMedia(files);
    }

    openGallery() {
        let isMultiSelect = this.isMultiSelect;
        let formControl = this.formcontrol;
        let showOnly = this.showOnly;
        let size = {
            height: "70%",
            width: "70%",
        };
        let isMobile = this.utilitiesService.isMobile();
        if (isMobile) {
            size.height = "80%";
            size.width = "100%";
        }
        const dialogRef = this.dialog.open(GalleryPopupComponent, {
            data: { formControl, isMultiSelect, showOnly },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });
        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                console.log(resp)
            }
        });
    }
}
