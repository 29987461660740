import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { TranslationService } from 'app/services/translationService';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})

export class CarouselComponent implements OnInit {
    @Input() set products(value) {
        this.baseCurrency = value?.currency?.code;
        this.productsSectionNames = [];
        this.productsSectionValues = [];

        for (const property in value) {

            if (Array.isArray(value[property])) {
                this.productsSectionNames.push(property)
                this.productsSectionValues.push(value[property]);
            }

        }

    }

    productsSectionNames: string[] = [];
    tooltips: string[] = [
        "These are the Product most sold for the selected date duration",
        "These are the Products that user's added to their cart but didn't check out or complete the purchase",
        "These are the Products bought frequently compared to the last period for the selected date duration",
        "These are the Products most frequently bought by unique customers"
    ]
    productsSectionValues: Array<any> = [];
    baseCurrency: string;
    direction = 'ltr';
    customOptions: OwlOptions = {
        loop: false,
        center: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
        rewind: false,
        autoWidth: true,
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            740: {
                items: 3
            },
            940: {
                items: 4
            },
            1400: {
                items: 5
            }
        },
        margin: 10,
        navText: ["<span class='material-icons'>arrow_back_ios</span>", "<span class='material-icons'>arrow_forward_ios</span>"],
        nav: true
    }


    get appLogo() {
        return localStorage.getItem('appLogo');
    }

    constructor(private translateService: TranslationService, private apiService: ApiService) { }

    ngOnInit(): void {
    }

    ngAfterViewChecked() {
        this.translateService._languageBehaviorSubject$.subscribe(data => {
            this.direction = data.lang === 'en' ? 'ltr' : 'rtl';
        })
    }


    getCarouselData(e: SlidesOutputData) { }

    
    openProduct(id) {
        const url = this.apiService.BASE_URL + `product/${id}/` ;
        window.open(url, '_blank');
    }

}
