<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" class="ace_editor"
    [ngClass]="!isShow ? 'hide' : ''">
    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
        <div fxFlex="1 1 auto" fxLayoutAlign="start center" class="mt-8 border-bottom" fxLayout="row" fxLayoutGap="4px">
            <mat-icon *ngIf="!isShow" (click)="showBody()" fxLayoutAlign="start start"
                class="highlight-text-color pl-8 cursor-pointer" matPrefix>add</mat-icon>
            <mat-icon *ngIf="isShow" (click)="hideBody()" fxLayoutAlign="start start"
                class="highlight-text-color pl-8 cursor-pointer" matPrefix>remove</mat-icon>
            <div fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="4px">
                <mat-icon class="highlight-text-color" matPrefix>info</mat-icon>
                <label class="mat-title mb-0 highlight-text-color ">
                    {{'Notes' | translate}}
                </label>
            </div>
        </div>
        <ng-container *ngIf="notes?.length">
            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" class="p-16" fxLayout="column" fxLayoutGap="1em">
                <div *ngFor="let note of notes" fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column"
                    fxLayoutGap="1em">
                    <div fxFlex="1 1 auto" fxLayoutAlign="start self-start" fxLayout="row" fxLayoutGap="0px">
                        <i class="fas fa-check-circle  highlight-text-color"></i>
                        <div>{{note?.description}}</div>
                    </div>
                </div>
            </div>
        </ng-container>

        <div fxFlex="0 0 auto" fxLayoutAlign="center center" class="mt-4" fxLayout="row" fxLayoutGap="4px">
            <label *ngIf="!notes?.length" class="gray-shade font-size-16 mt-40">{{'Nothing to display' |
                translate}}</label>
        </div>

    </div>
</div>