import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    OnChanges,
} from "@angular/core";
import { ViewCustomersComponent } from "../view-customers/view-customers.component";
import { IconCss } from "../../ngxDatatable-Icons/icons";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CatalogueService } from "../../../services/catalogue/catalogue.service";
import { UtilitiesService } from "../../../services/utilitiesService";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "../../../services/translationService";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomerService } from "../../../services/customer/customer.service";
import { SharedService } from "../../../services/sharedService";
import { CoreService } from "../../../services/core-service";
import { debounceTime } from "rxjs/operators";
import { SendNotificationComponent } from "../send-notification/send-notification.component";
import { fuseAnimations } from "../../../../@fuse/animations";
import { Subject } from "rxjs";

@Component({
    selector: "app-review-list",
    templateUrl: "./review-list.component.html",
    styleUrls: ["./review-list.component.scss"],
    animations: [fuseAnimations],
})
export class ReviewListComponent implements OnInit {
    // @ViewChild(ViewCustomersComponent)
    page = {
        size: 10,
        totalElements: 0,
        pageNumber: 0,
    };
    reviewList = [];
    @Input() reviewStatus;
    @Input() countS;

    @Output() viewCustomerClick: EventEmitter<number> =
        new EventEmitter<number>();
    @Output() badgeCount: EventEmitter<any> = new EventEmitter<any>();
    viewCustomerComponent: ViewCustomersComponent;
    closeOnAutoApply = true;
    paymentStatus = [];
    orderID: any = "";
    isView = false;
    isViewCustomer = false;
    @Input() resetFormSubject: Subject<boolean> = new Subject<boolean>();
    icons = IconCss;
    iconsCss = {
        pagerLeftArrow: this.icons.left,
        pagerRightArrow: this.icons.right,
        pagerPrevious: this.icons.previous,
        pagerNext: this.icons.next,
    };
    isLoading = false;
    offset = 0;
    searchKey: any;
    enableIcon = false;
    isEnableBack = false;
    label = {
        title_en: "Reviews",
        title_ar: "المراجعات",
    };
    selectedRanges = { startDate: null, endDate: null };
    form: FormGroup;
    customerEditID: string;
    showAction: any;
    childOrderID: any;
    flagImage: any = [];
    constructor(
        private reviewService: CatalogueService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        public translationService: TranslationService,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private router: Router,
        private customerService: CustomerService,
        private sharedService: SharedService,
        public coreService: CoreService,
        private route: ActivatedRoute
    ) {}

    async ngOnInit(): Promise<void> {
        this.form = this.fb.group({
            name: [null],
            status: [this.reviewStatus],
        });
        this.resetFormSubject.subscribe((response) => {
            if (response) {
                this.getCustomerReviews();
            }
        });

        this.form.valueChanges.pipe(debounceTime(1000)).subscribe((data) => {
            this.page.pageNumber = 0;
            this.page.size = 10;
            this.utilitiesService.startLoader();
            this.getCustomerReviews();
        });
    }

    onActivateViewOrder(event) {
        if (event.type === "mouseenter") {
            this.showAction = event.row.id;
        }
        if (event.type == "click") {
            if (
                event.event.srcElement.nodeName.toLowerCase() == "strong" ||
                event.event.srcElement.nodeName.toLowerCase() == "mat-icon" ||
                event.event.srcElement.nodeName.toLowerCase() == "button" ||
                event.event.srcElement.nodeName.toLowerCase() == "span"
            ) {
            } else {
                if (
                    this.coreService.checkPermission(
                        this.utilitiesService.Permission.OrderDetail
                    )
                ) {
                    // TODO Row click here
                    // this.viewOrder(event.row.id, event.row.invoice_no);
                } else {
                    this.utilitiesService.showPermissionDeinedMessage();
                }
            }
        }
    }

    async getCustomerReviews(
        limit = this.page.size,
        offset = this.offset,
        form = this.form ? this.form.controls : undefined
    ) {
        try {
            this.utilitiesService.startLoader();
            const reviewList = await this.reviewService
                .getCustomerReviews(limit, offset, this.searchKey, form)
                .toPromise();
            if (reviewList) {
                this.page.totalElements = reviewList.count;
                this.reviewList = reviewList.results;

                const count = {
                    pending: reviewList.pending_reviews,
                    approved: reviewList.approved_reviews,
                    rejected: reviewList.rejected_reviews,
                };
                this.badgeCount.emit(count);
                this.countS.next(count);
                this.utilitiesService.stopLoader();
            } else {
                this.utilitiesService.stopLoader();
            }
        } catch {
        } finally {
        }
    }
    pageChange(event) {
        this.page.pageNumber = event.offset;
        this.offset = this.page.pageNumber * this.page.size;
        document.getElementById("dashboard-project").scrollIntoView();
        this.getCustomerReviews();
    }
    async limitChange() {
        await this.getCustomerReviews();
    }
    getWidth() {
        this.reviewList = [...this.reviewList];
        return 100;
    }
    isEditEvent(event) {
        this.label.title_ar = "مشاهدة الطلب";
        this.label.title_en =
            "Edit Customer - " + `${event.first_name} ${event.last_name}`;
    }
    handleCancel() {
        this.showAction = "";
        if (this.viewCustomerComponent && this.viewCustomerComponent.isView) {
            this.viewCustomerComponent.isView = false;
            this.label.title_ar = "تفاصيل العميل";
            this.label.title_en = "Customer Details";
            this.childOrderID = "";
        } else if (
            this.viewCustomerComponent &&
            this.viewCustomerComponent.isEdit
        ) {
            this.viewCustomerComponent.isEdit = false;
            this.label.title_ar = "تفاصيل العميل";
            this.label.title_en = "Customer Details";
        } else {
            this.enableIcon = false;
            this.isEnableBack = false;
            this.label.title_ar = "ترتيب";
            this.label.title_en = "Orders";
            this.orderID = "";
            this.isView = false;
            this.isViewCustomer = false;
            this.customerEditID = "";
            this.getCustomerReviews();
        }
    }
    search(searchKey) {
        this.searchKey = searchKey;
        this.getCustomerReviews();
    }
    async reject(id) {
        console.log(id);
        const formMap = {
            status: 3,
        };
        this.utilitiesService.startLoader();
        const response = await this.reviewService
            .changeReviewStatus(id, formMap)
            .toPromise();
        await this.getCustomerReviews();
        this.utilitiesService.stopLoader();
    }
    async approve(id) {
        const formMap = {
            status: 2,
        };
        this.utilitiesService.startLoader();
        const response = await this.reviewService
            .changeReviewStatus(id, formMap)
            .toPromise();
        await this.getCustomerReviews();
        this.utilitiesService.stopLoader();
    }
    viewCustomer(customerEditID) {
        this.customerEditID = customerEditID;
        this.orderID = "";
        this.childOrderID = "";
        this.enableIcon = true;
        this.isEnableBack = true;
        this.isView = false;
        this.isViewCustomer = true;
        this.label.title_ar = "تفاصيل العميل";
        this.label.title_en = "Customer Details";
        this.viewCustomerClick.emit(customerEditID);
    }
    async sendNotification(id) {
        const customerID = id;
        const itemList = this.reviewList;
        const isMobile = this.utilitiesService.isMobile();
        const size = {
            width: "60vw",
            height: "60vh",
        };
        if (isMobile) {
            size.height = "100%";
            size.width = "100%";
        }
        const dialogRef = this.dialog.open(SendNotificationComponent, {
            data: { customerID, itemList },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });

        dialogRef.afterClosed().subscribe(async (resp) => {
            if (resp) {
                try {
                } catch {
                } finally {
                }
            }
        });
    }
    changePasswordEvent(event) {}
    isViewEvent(id) {
        this.childOrderID = id;
        this.label.title_ar = "مشاهدة الطلب";
        this.label.title_en = "View Order";
    }
}
