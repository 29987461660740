import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "app/services/translationService";
import { RolesPermissionService } from "app/services/user-management/rolesPermission.service";
import { UserService } from "app/services/user-management/userService";
import { UtilitiesService } from "app/services/utilitiesService";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";

@Component({
    selector: "app-create-user",
    templateUrl: "./create-user.component.html",
    styleUrls: ["./create-user.component.scss"],
    animations: fuseAnimations,
})
export class CreateUserComponent implements OnInit {
    @Input() id: any = "";
    @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
    @Output() createNotification: EventEmitter<void> = new EventEmitter<any>();

    form: FormGroup;
    roleList = [];
    userDetail: any;
    constructor(
        private fb: FormBuilder,
        public utilitiesService: UtilitiesService,
        private dialog: MatDialog,
        private userService: UserService,
        private translate: TranslateService,
        public translationService: TranslationService,
        private roleService: RolesPermissionService
    ) {}

    async formInit() {
        this.form = this.fb.group({
            user_id: [this.id],
            first_name: [
                this.userDetail ? this.userDetail.first_name : "",
                [Validators.required],
            ],
            last_name: [this.userDetail ? this.userDetail.last_name : ""],
            email: [
                this.userDetail ? this.userDetail.email : "",
                [Validators.required, Validators.email],
            ],
            role_id: [
                this.userDetail
                    ? this.userDetail.groups
                        ? this.userDetail.groups.length
                            ? this.userDetail.groups[0]
                            : null
                        : null
                    : "",
                [Validators.required],
            ],
            // is_email: [this.userDetail ? this.userDetail.is_email : true],
            password: [
                this.userDetail ? this.userDetail.password : "",
                [Validators.required, Validators.minLength(8)],
            ],
            username: [this.userDetail ? this.userDetail.email : ""],
        });
        if (this.id) {
            this.form.controls.email.disable();
            this.form.controls.email.clearValidators();
            this.form.controls.email.updateValueAndValidity();
            this.form.controls.password.disable();
            this.form.controls.password.clearValidators();
            this.form.controls.password.updateValueAndValidity();
        }
    }
    async getRoles() {
        try {
            this.utilitiesService.startLoader();
            const roleList = await this.roleService
                .getRoles(9999999, 0)
                .toPromise();
            if (roleList) {
                this.roleList = roleList.results;
            }
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
            if (!this.id) {
                this.utilitiesService.stopLoader();
            }
        }
    }

    async ngOnInit(): Promise<void> {
        this.formInit();
        await this.getRoles();
        if (this.id) {
            await this.getUserDetails();
        }
    }
    async getUserDetails() {
        try {
            const customerDetails = await this.userService
                .userDeatils(this.id)
                .toPromise();
            if (customerDetails) {
                this.userDetail = customerDetails;
                await this.formInit();
                this.utilitiesService.stopLoader();
            }
        } catch {
        } finally {
            this.utilitiesService.stopLoader();
        }
    }
    goBack() {
        this.cancelClick.emit();
    }
    save() {
        if (this.form.valid) {
            let form = this.form.value;
            if (this.id) {
                this.updateUser(form);
            } else {
                this.saveUser(form);
            }
        }
    }
    updateUser(form) {
        try {
            let content = this.translate.instant(
                "Are you sure, Do you want to update ?"
            );
            let heading = this.translate.instant("Update");
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();

            // const dialogRef = this.dialog.open(AlertModalComponent, {
            //     data: { content, heading, fromApp },
            //     maxWidth: "",
            //     width: `${size.width}`,
            //     height: `${size.height}`,
            // });
            // dialogRef.afterClosed().subscribe(async (resp) => {
            //     if (resp) {
            //         this.utilitiesService.startLoader();
            //         let success = this.translate.instant(
            //             "User updated successfully"
            //         );
            //         const customer = await this.userService
            //             .updateUser(form)
            //             .toPromise();
            //         if (customer) {
            //             this.utilitiesService.showSuccessToast(success);
            //             this.utilitiesService.stopLoader();
            //             this.goBack();
            //             // this.route.navigate([AppRoutes.Roles]);
            //         }
            //     }
            // });
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
    saveUser(form) {
        try {
            let content = this.translate.instant(
                "Are you sure, Do you want to save ?"
            );
            let heading = this.translate.instant("Save");
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();

            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                if (resp) {
                    form.username = form.email;
                    this.utilitiesService.startLoader();
                    let success = this.translate.instant(
                        "User created successfully"
                    );
                    const customer = await this.userService
                        .addUser(form)
                        .toPromise();
                    if (customer) {
                        this.utilitiesService.showSuccessToast(success);
                        this.utilitiesService.stopLoader();
                        this.goBack();
                        // this.route.navigate([AppRoutes.Roles]);
                    }
                }
            });
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
}
