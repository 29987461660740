<div fxFlex="1 1 100%" class="add-campaign" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
  <form
    fxFlex="1 1 100%"
    fxLayoutAlign="start stretch"
    fxLayout.lt-md="column"
    fxLayout.gt-sm="row"
    fxLayoutGap="1em"
    [formGroup]="campaignForm"
    style="min-height: 100vh">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
      <div fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
        <mat-spinner diameter="50" style="left: 47%; top: 50%; position: relative" *ngIf="isLoading" strokeWidth="5"> </mat-spinner>
        <div [ngClass]="selectedCampaign ? 'fuse-card' : ''" class="auto-width center-container social-section p-16">
          <div>
            <div class="bold head-flex px-2" [ngClass]="selectedCampaign?.selected ? 'padding-10' : ''">
              <div style="display: flex; align-items: center">
                <mat-icon *ngIf="selectedCampaign?.selected && !id" class="icon" (click)="backToDashboard()">arrow_back</mat-icon>
                <h2 class="bold" [ngClass]="selectedCampaign?.selected ? 'margin-x-10' : ''">
                  {{ selectedCampaign?.selected ? selectedCampaign?.name : "Select Channels" }}
                </h2>
              </div>
              <div *ngIf="selectedCampaign?.selected">
                <img [src]="selectedCampaign?.logo" alt="" width="35px" />
              </div>
            </div>
          </div>
          <div class="flex" *ngIf="!selectedCampaign?.selected && !id">
            <div class="fuse-card-social center flex-child" *ngFor="let campaign of campaigns" (click)="getCompaignField(campaign.key)">
              <img [src]="campaign?.logo" class="img-size" />
              <p class="bold">{{ campaign?.title }}</p>
            </div>
          </div>
          <ng-container *ngIf="campaignCreatingStage && selectedCampaign?.selected">
            <div *ngIf="selectedCampaign?.selected" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
              <div class="flex-container">
                <div class="flex-item">
                  <mat-form-field appearance="outline" class="w-full">
                    <mat-label>{{ "Campaign Name" | translate }}</mat-label>
                    <input type="text" matInput formControlName="name" />
                  </mat-form-field>
                </div>
                <div class="flex-item" *ngIf="shouldDisplayContent(selectedCampaign, 'campaign_code')">
                  <mat-form-field class="w-full" appearance="outline">
                    <mat-label class="text-capitalized">
                      {{ "Campaign Code" | translate }}
                    </mat-label>
                    <input formControlName="code" matInput name="title" />
                  </mat-form-field>
                </div>
                <div class="flex-item" *ngIf="shouldDisplayContent(selectedCampaign, 'start_date')">
                  <mat-form-field appearance="outline" class="w-full">
                    <mat-label>{{ "Start Date" | translate }}</mat-label>
                    <input formControlName="start_date" matInput [matDatepicker]="picker20" />
                    <mat-datepicker-toggle matSuffix [for]="picker20"></mat-datepicker-toggle>
                    <mat-datepicker #picker20></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="flex-item" *ngIf="shouldDisplayContent(selectedCampaign, 'end_date')">
                  <mat-form-field appearance="outline" class="w-full">
                    <mat-label>{{ "End Date" | translate }}</mat-label>
                    <input formControlName="end_date" matInput [matDatepicker]="picker2" />
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="flex-item" *ngIf="shouldDisplayContent(selectedCampaign, 'schedule_date')">
                  <mat-form-field appearance="outline" class="w-full">
                    <mat-label>Schedule Time</mat-label>
                    <input formControlName="schedule_date" type="time" matInput name="week" min="09:00" max="18:00" />
                  </mat-form-field>
                </div>
                <div class="flex-item" *ngIf="shouldDisplayContent(selectedCampaign, 'budget')">
                  <mat-form-field class="w-full" appearance="outline">
                    <mat-label class="text-capitalized">
                      {{ "Budget" | translate }}
                    </mat-label>
                    <input matInput name="title" formControlName="budget" />
                  </mat-form-field>
                </div>
                <div class="flex-item" *ngIf="shouldDisplayContent(selectedCampaign, 'audience')">
                  <mat-form-field class="w-full" appearance="outline">
                    <mat-label class="text-capitalized">
                      {{ "Audience" | translate }}
                    </mat-label>
                    <mat-select formControlName="audience">
                      <mat-option [value]="audience.id" *ngFor="let audience of audienceList">
                        {{ audience.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="flex-item" *ngIf="shouldDisplayContent(selectedCampaign, 'price_rule')">
                  <mat-form-field appearance="outline" fxFlex="100%">
                    <mat-label>{{ "Price Rule" | translate }}</mat-label>
                    <mat-select formControlName="price_rule">
                      <mat-option *ngFor="let rule of priceRuleList" [value]="rule?.id"> {{ rule?.name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="campaignForm.get('price_rule').hasError('required')">
                      {{ "Price rule is required" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <!-- <div class="flex-item" *ngIf="shouldDisplayContent(selectedCampaign,'landing_page')">
                                        <mat-form-field class="w-full" appearance="outline">
                                            <mat-label class="text-capitalized">
                                                {{ 'Add Landing Page' | translate }}
                                            </mat-label>
                                            <input matInput name="title">
                                        </mat-form-field>
                                    </div> -->

                <ng-container [formGroup]="urlSpecification" *ngIf="shouldDisplayContent(selectedCampaign, 'url')">
                  <div class="flex-item">
                    <mat-form-field appearance="outline" fxFlex="100%">
                      <mat-label>{{ campaignUrlField | translate }}</mat-label>
                      <mat-select formControlName="type">
                        <mat-option [value]="campaignURLTypesEnum.PAGE">{{ "Page" | translate }} </mat-option>
                        <mat-option [value]="campaignURLTypesEnum.CUSTOM">{{ "Custom" | translate }} </mat-option>
                      </mat-select>
                      <mat-error *ngIf="urlTypeFormControl.hasError('required')">
                        {{ "URL Type is required" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="urlTypeFormControl.value && shouldDisplayContent(selectedCampaign, 'url')"
                  [ngSwitch]="urlTypeFormControl.value">
                  <div class="flex-item" *ngSwitchCase="campaignURLTypesEnum.PAGE">
                    <mat-form-field appearance="outline" fxFlex="100%">
                      <mat-label>{{ "Page" | translate }}</mat-label>
                      <mat-select [formControl]="urlValueFormControl">
                        <mat-option *ngFor="let page of pagesList" [value]="page?.id"> {{ page?.title }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="urlValueFormControl.hasError('required')">
                        {{ "Page is required" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <ng-container *ngIf="!isAffiliatePage; else affiliateUrl">
                    <div class="flex-item">
                      <mat-form-field *ngSwitchDefault appearance="outline" fxFlex="100" floatLabel="always">
                        <mat-label class="text-capitalized">
                          {{ "URL" | translate }}
                        </mat-label>
                        <div>
                          <span>{{ baseUrl }}</span>
                          <input [formControl]="urlValueFormControl" matInput fxFlex="50" placeholder="please enter page name" />
                        </div>
                        <mat-error *ngIf="urlValueFormControl.hasError('required')">
                          {{ "URL is required" | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </ng-container>

                  <ng-template #affiliateUrl>
                    <mat-form-field *ngSwitchDefault appearance="outline" fxFlex="100" floatLabel="always">
                      <mat-label class="text-capitalized">
                        {{ "URL" | translate }}
                      </mat-label>
                      <div>
                        <span>{{ baseUrl }}</span>
                        <input [formControl]="urlValueFormControl" matInput fxFlex="50" placeholder="please enter page name" />
                      </div>
                      <mat-error *ngIf="urlValueFormControl.hasError('required')">
                        {{ "URL is required" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </ng-template>
                </ng-container>
                <!-- <ng-container [formGroup]="urlSpecification" *ngIf="shouldDisplayContent(selectedCampaign,'status')">
                                    <div class="flex-item">
                                        <mat-form-field appearance="outline" fxFlex="100%">
                                            <mat-label>{{campaignUrlField | translate}}</mat-label>
                                            <mat-select formControlName="type">
                                                <mat-option [value]="campaignURLTypesEnum.PAGE">{{'Page' | translate}}
                                                </mat-option>
                                                <mat-option [value]="campaignURLTypesEnum.CUSTOM">{{'Custom' |
                                                    translate}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="urlTypeFormControl.hasError('required')">
                                                {{'URL Type is required' | translate}}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </ng-container> -->
              </div>

              <div *ngIf="id && !isOutBondCampaign()">
                <div>
                  <!-- <label class="highlight-text-color">{{'Campaign Statistics' | translate}}</label>
                                    <div class="flex-container">
                                        <div class="flex-item">
                                            <mat-form-field appearance="outline" class="w-full">
                                                <mat-label>{{'Actual Spend' | translate}}</mat-label>
                                                <input formControlName="actual_spend" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="flex-item">
                                            <mat-form-field appearance="outline" class="w-full">
                                                <mat-label>{{'Actual Start Date' | translate}}</mat-label>
                                                <input formControlName="actual_start_date" matInput
                                                    [matDatepicker]="picker21">
                                                <mat-datepicker-toggle matSuffix
                                                    [for]="picker21"></mat-datepicker-toggle>
                                                <mat-datepicker #picker21></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <div class="flex-item">
                                            <mat-form-field appearance="outline" class="w-full">
                                                <mat-label>{{'Actual End Date' | translate}}</mat-label>
                                                <input formControlName="actual_end_date" matInput
                                                    [matDatepicker]="picker22">
                                                <mat-datepicker-toggle matSuffix
                                                    [for]="picker22"></mat-datepicker-toggle>
                                                <mat-datepicker #picker22></mat-datepicker>
                                            </mat-form-field>
                                        </div>

                                    </div> -->

                  <fieldset
                    fxFlex="0 0 auto"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    style="width: 100%"
                    fxLayoutGap.gt-sm="5px"
                    fxLayoutGap.lt-md="0px">
                    <legend class="highlight-text-color text-bold">{{ "Campaign Statistics" | translate }}</legend>

                    <div class="flex-container">
                      <div class="flex-item">
                        <mat-form-field appearance="outline" class="w-full">
                          <mat-label>{{ "Channel Reference" | translate }}</mat-label>
                          <input formControlName="channel_reference" matInput />
                        </mat-form-field>
                      </div>
                      <div class="flex-item">
                        <mat-form-field appearance="outline" class="w-full">
                          <mat-label>{{ "No. of Order" | translate }}</mat-label>
                          <input formControlName="actual_total_order" matInput />
                        </mat-form-field>
                      </div>
                      <div class="flex-item">
                        <mat-form-field appearance="outline" class="w-full">
                          <mat-label>{{ "Actual Spend" | translate }}</mat-label>
                          <input formControlName="actual_spend" matInput />
                        </mat-form-field>
                      </div>
                      <div class="flex-item">
                        <mat-form-field appearance="outline" class="w-full">
                          <mat-label>{{ "Actual Start Date" | translate }}</mat-label>
                          <input formControlName="actual_start_date" matInput [matDatepicker]="picker21" />
                          <mat-datepicker-toggle matSuffix [for]="picker21"></mat-datepicker-toggle>
                          <mat-datepicker #picker21></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="flex-item">
                        <mat-form-field appearance="outline" class="w-full">
                          <mat-label>{{ "Actual End Date" | translate }}</mat-label>
                          <input formControlName="actual_end_date" matInput [matDatepicker]="picker22" />
                          <mat-datepicker-toggle matSuffix [for]="picker22"></mat-datepicker-toggle>
                          <mat-datepicker #picker22></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <ng-container *ngIf="isOutBondCampaign() && selectedCampaign?.selected">
              <div fxFlex="0 0 auto" class="mb-4" fxLayoutAlign="flex-end center" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="0 0 49.5%" class="margin-0 padding-10" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                  <mat-checkbox (change)="templateCheckboxChange()" formControlName="use_existing_template" class="example-margin">
                    <span class="font-size-12">
                      {{ "Use Existing Template" | translate }}
                    </span>
                  </mat-checkbox>
                </div>

                <div
                  class="padding-10"
                  *ngIf="campaignForm.get('use_existing_template').value"
                  fxFlex="0 0 42%"
                  fxLayoutAlign="start stretch"
                  fxLayout="column"
                  fxLayoutGap="0px">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ "Template" | translate }}</mat-label>
                    <mat-select formControlName="template">
                      <mat-option *ngFor="let template of notificationTemplate" [value]="template?.id">
                        {{ template?.title }}
                      </mat-option>
                    </mat-select>
                    <mat-spinner *ngIf="isLoading" diameter="20" matSuffix></mat-spinner>
                    <mat-error *ngIf="campaignForm.get('template').hasError('required')">
                      {{ "Template is required" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div
                  fxFlex="0 0 7%"
                  *ngIf="campaignForm.get('use_existing_template').value"
                  fxLayoutAlign="start stretch"
                  fxLayout="column">
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="templateLimit" (selectionChange)="limitChange()">
                      <mat-option [value]="5">5</mat-option>
                      <mat-option [value]="10">10</mat-option>
                      <mat-option [value]="25">25</mat-option>
                      <mat-option [value]="50">50</mat-option>
                      <mat-option [value]="100">100</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div
                  class="padding-10"
                  *ngIf="!campaignForm.get('use_existing_template').value"
                  fxFlex="0 0 50%"
                  fxLayoutAlign="start stretch"
                  fxLayout="column"
                  fxLayoutGap="0px">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ "Template Type" | translate }}</mat-label>
                    <mat-select formControlName="template_format">
                      <mat-option *ngFor="let item of templateType" [value]="item.id">
                        {{ item.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="campaignForm.get('template_format').hasError('required')">
                      {{ "Template Type is required" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="padding-10" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                <mat-form-field appearance="outline" fxFlex="100">
                  <mat-label class="text-capitalized">
                    {{ "Subject" | translate }}
                  </mat-label>
                  <input formControlName="title" matInput name="title" />
                </mat-form-field>
              </div>
              <div
                class="padding-10"
                *ngIf="
                  (selectedCampaign?.key == 'sms' || selectedCampaign?.key == 'push_notification') &&
                  !campaignForm.get('use_existing_template').value
                "
                fxFlex="0 0 auto"
                fxLayoutAlign="start stretch"
                fxLayout="column"
                fxLayoutGap="0px">
                <mat-form-field appearance="outline" fxFlex="100%">
                  <mat-label>{{ "Message" | translate }}</mat-label>
                  <textarea formControlName="message" rows="8" matInput></textarea>
                  <mat-error *ngIf="campaignForm.get('message').hasError('required')">
                    {{ "Message is required" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="padding-10" fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column">
                  <div fxFlex="1 1 100%" fxLayout="column" class="mb-8" *ngIf="campaignForm.get('campaign_channel_id').value == 1">
                    <mat-slide-toggle
                      class="mb-8 align-self"
                      fxFlex="0 0 auto"
                      *ngIf="!campaignForm.get('use_existing_template').value"
                      fxLayoutAlign="start end"
                      fxLayout="column"
                      [formControl]="codeEditorFormControl">
                      {{ !codeEditorFormControl.value ? ("Switch to Code Editor" | translate) : ("Switch to HTML Editor" | translate) }}
                    </mat-slide-toggle>
                    <ckeditor
                      [@animate]="{ value: '*', params: { duration: '300ms', y: '50px' } }"
                      *ngIf="!codeEditorFormControl.value"
                      formControlName="message_email"
                      ngDefaultControl
                      name="myckeditor"
                      [config]="ckeConfig"
                      [readonly]="campaignForm.get('use_existing_template').value ? true : false">
                    </ckeditor>
                    <div fxFlex="0 0 auto" fxLayout="column" *ngIf="codeEditorFormControl.value">
                      <mat-icon
                        style="align-self: flex-end"
                        class="zoom-icon cursor-pointer highlight-text-color"
                        (click)="zoomAdditionalCss(campaignForm.get('message_email'))">
                        zoom_out_map</mat-icon
                      >
                      <ace-editor
                        [@animate]="{ value: '*', params: { duration: '300ms', y: '50px' } }"
                        [theme]="'clouds'"
                        [mode]="'html'"
                        [options]="options"
                        formControlName="message_email"
                        style="height: 50vh">
                      </ace-editor>
                    </div>
                    <div style="font-size: 10px; color: red" *ngIf="campaignForm.get('message_email').hasError('required')">
                      {{ "Message is required" | translate }}
                    </div>
                  </div>
                  <!-- <ckeditor *ngIf="campaignForm.get('channel').value==1" formControlName="message_email"
                                        ngDefaultControl name="myckeditor1" [config]="ckeConfig">
                                    </ckeditor>
                                    <small style="margin-left: 0px !important;color: #f44336 !important;"
                                        *ngIf="campaignForm.get('message_email').hasError('required') && campaignForm.get('channel').value==1">
                                        {{'Message is required' | translate}}
                                    </small> -->
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="flex-start center" *ngIf="!campaignForm.get('use_existing_template').value && !id">
                <div fxFlex="1 1 50%" class="padding-10" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="0px">
                  <mat-checkbox formControlName="re_usable" class="font-size-12">
                    {{ "Do you want to save this Template?" | translate }}</mat-checkbox
                  >
                </div>
                <div fxFlex="1 1 50%" class="px-10" *ngIf="campaignForm.get('re_usable').value">
                  <mat-form-field appearance="outline" fxFlex="100%">
                    <mat-label>{{ "Name" }}</mat-label>
                    <input formControlName="template_name" type="text" matInput />
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="flex-start center">
                <div
                  fxFlex="1 1 50%"
                  class="padding-10"
                  *ngIf="isOutBondCampaign() && (id ? !campaignDetail?.schedule_date : true)"
                  fxLayoutAlign="start stretch"
                  fxLayout="row"
                  fxLayoutGap="0px">
                  <mat-checkbox formControlName="is_schedule" class="font-size-12">
                    {{ "Do you want to schedule?" | translate }}
                  </mat-checkbox>
                </div>
                <div fxFlex="1 1 50%" class="px-10" *ngIf="campaignForm.get('is_schedule').value">
                  <mat-form-field appearance="outline" fxFlex="100%">
                    <input type="datetime-local" formControlName="schedule_date" matInput />
                  </mat-form-field>
                </div>
              </div>
            </ng-container>

            <div
              *ngIf="selectedCampaign?.selected"
              fxFlex="1 1 auto"
              fxLayoutAlign="flex-end stretch"
              style="margin-top: 20px"
              fxLayout.lt-md="column-reverse"
              fxLayout.gt-sm="row"
              fxLayoutGap="5px">
              <button mat-stroked-button (click)="handleCancel()">
                <mat-icon>clear</mat-icon>
                {{ "Cancel" | translate }}
              </button>
              <!-- <button mat-stroked-button (click)="preview()" type="button" *ngIf="isOutBondCampaign()">
                                <mat-icon>remove_red_eye</mat-icon>
                                {{'Preview' | translate}}
                            </button> -->
              <button mat-flat-button (click)="preview()" class="secondary-bg-color" type="button" *ngIf="isOutBondCampaign()">
                <mat-icon>remove_red_eye</mat-icon>
                {{ "Preview" | translate }}
              </button>
              <button (click)="saveOrUpdate()" type="submit" mat-flat-button class="primary-bg-color">
                <mat-icon>save</mat-icon>
                {{ "Save" | translate }}
              </button>
            </div>
          </ng-container>

          <ng-container *ngIf="!campaignCreatingStage && selectedCampaign?.selected">
            <!-- <div class="thankyou-section">
                            <h2>Thank You</h2>
                        </div> -->

            <div fxLayout="column">
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex>
                <img src="../../../../assets/images/campaign/Thank You.png" width="100px" alt="" />
                <h2 class="bold">Thank You</h2>
                <p class="bold" *ngIf="isOutBondCampaign()">
                  {{ "Your campaign has been" }} {{ successApiResponse?.schedule_date ? "scheduled" : "saved" }}
                </p>
                <div class="message-content outbond" *ngIf="isOutBondCampaign(); else inbound">
                  <p *ngIf="selectedCampaign.key !== 'sms'">
                    {{ selectedCampaign.key !== "email" ? "Title" : "Subject" }} : {{ successApiResponse?.template_details?.title }}
                  </p>
                  <p [innerHTML]="getBody()"></p>
                </div>
                <ng-template #inbound>
                  <div class="inbound">
                    <p class="bold">Your campaign link has been generated.</p>
                  </div>
                  <div *ngIf="successApiResponse?.campaign_links && successApiResponse?.campaign_links.length" class="message-content">
                    <div fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let link of successApiResponse?.campaign_links">
                      <p>{{ link?.short_url }}</p>
                      <button mat-icon-button (click)="copyUrl(link?.short_url)">
                        <mat-icon matPrefix>file_copy</mat-icon>
                      </button>
                    </div>
                  </div>
                </ng-template>
              </div>

              <div class="main-button-section">
                <div
                  fxFlex="1 1 auto"
                  class="action-button"
                  fxLayoutAlign="flex-end stretch"
                  fxLayout.lt-md="column-reverse"
                  fxLayout.gt-sm="row"
                  fxLayoutGap="5px">
                  <button
                    *ngIf="isOutBondCampaign() && !successApiResponse?.schedule_date"
                    (click)="executeCampaign()"
                    mat-flat-button
                    class="secondary-bg-color">
                    <mat-icon>send</mat-icon>
                    {{ "Execute Campaign" | translate }}
                  </button>
                  <button
                    *ngIf="isOutBondCampaign() && successApiResponse?.schedule_date"
                    (click)="cancelCampaign()"
                    mat-flat-button
                    class="secondary-bg-color">
                    <mat-icon>stop</mat-icon>
                    {{ "Cancel Campaign" | translate }}
                  </button>
                  <button (click)="back()" type="submit" mat-flat-button class="primary-bg-color">
                    <mat-icon>close</mat-icon>
                    {{ "Close" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</div>
