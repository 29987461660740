export const locale = {
    lang: "en",
    data: {
        " Add these credentials in the Shopify Store Setup on Paydo":
            " Add these credentials in the Shopify Store Setup on Paydo",
        " Choose Paydo Carrier from the list":
            " Choose Paydo Carrier from the list",
        " Go to Setting from the side menu on shopify":
            " Go to Setting from the side menu on shopify",
        " Setup the Shipping zones and Products on shopify":
            " Setup the Shipping zones and Products on shopify",
        " Shopify stores can be integrated with Paydo by using the private apps feature":
            " Shopify stores can be integrated with Paydo by using the private apps feature",
        " The Paydo Shipping App requires permissions to the following scopes":
            "The Paydo Shipping App requires permissions to the following scopes",
        " The system will update the tracking status on delivery":
            " The system will update the tracking status on delivery",
        " To Add Rates for the shipping zone":
            " To Add Rates for the shipping zone",
        " and click": " and click",
        " button": " button",
        " check Shipping rates option and save":
            " check Shipping rates option and save",
        " go to Apps from the side menu on Shopify and click on the":
            " go to Apps from the side menu on Shopify and click on the",
        " select option to": " select option to",
        " the private app credentials will appear":
            " the private app credentials will appear",
        " “Manage Rates” ": " “Manage Rates” ",
        '"Uninstall"': "“Uninstall”",
        "& Memberships": "& Memberships",
        "* This email is your login name": "* This email is your login name",
        "--Select Page--": "--Select Page--",
        "--Select Type--": "--Select Type--",
        AND: "AND",
        "API Key": "API Key",
        "Change status": "Change status",
        "Save Invoice Pdf": "Save Invoice Pdf",
        "Save Order Pdf": "Save Order Pdf",
        "API Key is required": "API Key is required",
        "order_status is required": "Order Status is required",
        "API general error": "API general error",
        AR: "AR",
        "Exp Delivery": " Exp Delivery",
        "Reference No": "Reference No",
        AWB: "AWB",
        Slug: "Slug",
        "Abandoned Cart Delay": "Abandoned Cart Delay",
        "Abandoned Cart Delay should be greater than 0":
            "Abandoned Cart Delay should be greater than 0",
        "Abandoned Cart Delay should be less than 101":
            "Abandoned Cart Delay should be less than 101",
        Account: "Account",
        "Account is required": "Account is required",
        Actions: "Actions",
        Details: "Details",
        Wallet: "Wallet",
        "Processed Item Count": " Processed Item Count",
        "Item Count": "Item Count",
        "Activate Date": "Activate Date",
        Active: "Active",
        Source: "Source",
        Add: "Add",
        "Add Custom Template": "Add Custom Template",
        "Add Customers": "Add Customers",
        "Add Media": "Add Media",
        "Add Possible Values": "Add Possible Values",
        "Add base currency , base country and multiple country rules":
            "Add base currency, base country and multiple country rules",
        "Add support information": "Add support information",
        "Add terms and conditions and its setup":
            "Add terms and conditions and its setup",
        "Add the app details as follows": "Add the app details as follows",
        "Add to cart caption (ar)": "Add to cart caption (ar)",
        "Add to cart caption (en)": "Add to cart caption (en)",
        "Additional CSS": "Additional CSS",
        "Additional Design Settings": "Additional Design Settings",
        "Additional Information": "Additional Information",
        Address: "Address",
        Origion: "Origin",
        "Restricted category name": "Restricted category name",
        Addresses: "Addresses",
        "Addresses ": "Addresses",
        "After saving the permissions": "After saving the permissions",
        "After successfully integrating Paydo and Shopify":
            "After successfully integrating Paydo and Shopify",
        All: "All",
        "All Downloads": "All Downloads",
        "All Visits": "All Visits",
        Amount: "Amount",
        "Analytical settings updated successfully":
            "Analytical settings updated successfully",
        "Posts Layout settings updated successfully":
            "Posts Layout settings updated successfully",
        Animation: "Animation",
        Subscribers: "Subscribers",
        "Apartment No": "Apartment No",
        "Api Key is required": "Api Key is required",
        "Api general Error": "Api general Error",
        App: "App",
        "App Configurations": "App Configurations",
        "App Details are still loading Please wait..":
            "App Details are still loading Please wait..",
        Arabic: "Arabic",
        Archived: "Archived",
        "Are you Sure, Do you want to upload this image":
            "Are you Sure, Do you want to upload this image",
        "Are you sure, Do you want to save ?":
            "Are you sure, Do you want to save ?",
        "Are you sure, Do you want to update ?":
            "Are you sure, Do you want to update ?",
        Area: "Area",
        "Ask Email": "Ask Email",
        "Catalog settings updated successfully":
            "Catalog settings updated successfully",
        "Catalog settings deleted successfully":
            "Catalog settings deleted successfully",
        "Product Catalog Uploaded": "Product Catalog Uploaded",
        Audience: "Audience",
        "Audience Created Date": "Audience Created Date",
        "Audience Name": "Audience Name",
        "Audience Name is required": "Audience Name is required",
        "Audience Updated Date": "Audience Updated Date",
        "Audience added successfully": "Audience added successfully",
        "Audience is required": "Audience is required",
        "Audience updated successfully": "Audience updated successfully",
        "Auto Play": "Auto Play",
        Avenue: "Avenue",
        "Avenue ": "Avenue",
        "Base Country": "Base Country",
        "Base Currency": "Base Currency",
        "Base Currency is required": "Base Currency is required",
        "Basic Details": "Basic Details",
        "Basic Info": "Basic Info",
        "Basic Information": "Basic Information",
        Block: "Block",
        Body: "Body",
        "Body (Ar)": "Body (Ar)",
        "Body (En)": "Body (En)",
        "Body En is required": "Body En is required",
        Box: "Box",
        Brand: "Brand",
        "Brand Filter": "Brand Filter",
        "Brand Filter Layout": "Brand Filter Layout",
        "Brand Settings": "Brand Settings",
        "Browse for file": "Browse for file",
        "Browse from media": "Browse from media",
        "Building No": "Building No",
        "Button Text (ar)": "Button Text (ar)",
        "Button Text (en)": "Button Text (en)",
        CANCELLED: "CANCELLED",
        "CDN URL": "CDN URL",
        "Campaign Created Date": "Campaign Created Date",
        "Campaign Information": "Campaign Information",
        "Campaign Updated Date": "Campaign Updated Date",
        "Campaign deleted successfully": "Campaign deleted successfully",
        "Can not find any parent": "Can not find any parent",
        Cancel: "Cancel",
        Cancelled: "Cancelled",
        "Cart Amount": "Cart Amount",
        "Cart Discount": "Cart Discount",
        "Cart Excel Sheet -": "Cart Excel Sheet -",
        "Cart Information": "Cart Information",
        "Cart and Wishlist Settings": "Cart and Wishlist Settings",
        Cashback: "Cashback",
        "Categories Filter": "Categories Filter",
        Category: "Category",
        Composition: "Composition",
        "Category Filter Layout": "Category Filter Layout",
        "Category Settings": "Category Settings",
        "Category created successfully": "Category created successfully",
        "Category updated successfully": "Category updated successfully",
        "Change Password": "Change Password",
        "Change Password ": "Change Password ",
        "Changed By :": "Changed By ",
        Changes: "Changes",
        Channel: "Channel",
        Charges: "Charges",
        "Check Status": "Check Status",
        Checkbox: "Checkbox",
        "Checkout Settings": "Checkout Settings",
        "Checkout Type": "Checkout Type",
        City: "City",
        "Civil ID": "Civil ID",
        "Civil ID Note (Ar)": "Civil ID Note (Ar)",
        "Civil ID Note (En)": "Civil ID Note (En)",
        "Click here to get all Ionic Icons":
            "Click here to get all Ionic Icons",
        "Click the row to view details of the order":
            "Click the row to view details of the order",
        "Click to copy": "Click to copy",
        Close: "Close",
        Code: "Code",
        "Code is required": "Code is required",
        "Collection created successfully": "Collection created successfully",
        "Collection deleted successfully": "Collection deleted successfully",
        "Collection is required": "Collection is required",
        "Collection updated successfully": "Collection updated successfully",
        "Color Palette": "Color Palette",
        Condition: "Condition",
        "Condition created successfully": "Condition created successfully",
        "Condition deleted successfully": "Condition deleted successfully",
        "Condition is required": "Condition is required",
        "Condition updated successfully": "Condition updated successfully",
        Conditions: "Conditions",
        "Config deleted successfully": "Configuration deleted successfully",
        Configuration: "Configuration ",
        "Import Files": "Import Files",
        excute: "Excute",
        importing: "Importing",
        "records with the following fields :":
            "Records with the following fields",
        "known fields in import file :": "known fields in import file :",
        "unknown fields in import file :": "unknown fields in import file :",
        "Import File Details": "Import File Details",
        "Configuration is required": "Configuration is required",
        "Configuration created successfully":
            "Configuration created successfully",
        "Configuration updated successfully":
            "Configuration updated successfully",
        "Configure Paydo for fulfillment of the products":
            "Configure Paydo for fulfillment of the products",
        "Confirm Password": "Confirm Password",
        "Confirm Password is required": "Confirm Password is required",
        "Contact Email": "Contact Email",
        "Contact No Country": "Contact No Country",
        "Contact Number": "Contact Number",
        "Container Layout": "Container Layout",
        "Content Per Row Large": "Content Per Row Large",
        "Content Per Row Mobile": "Content Per Row Mobile",
        "Content Per Row Small": "Content Per Row Small",
        "Content Type": "Content Type",
        "Copied to clipboard!": "Copied to clipboard!",
        "Country ": "Country",
        "Country Rule #": "Country Rule #",
        "Country Rules": "Country Rules",
        "Country is required": "Country is required",
        "Country rules updated successfully":
            "Country rules updated successfully",
        Coupon: "Coupon",
        "Coupon Discount": "Coupon Discount",
        "Coupon ID": "Coupon ID",
        "Create Condition": "Create Condition",
        "Create Date": "Create Date",
        "Create a new shipping zone and choose the allowed shipping countries":
            "Create a new shipping zone and choose the allowed shipping countries",
        "Created Date": "Created Date",
        "Created date": "Created date",
        Css: "Css",
        "Css updated successfully": "Css updated successfully",
        Currency: "Currency",
        "Currency is required": "Currency is required",
        Custom: "Custom",
        "Custom Configuration": "Custom Configuration",
        "Custom Field disable successfully":
            "Custom Field disable successfully",
        "Custom Field enabled successfully":
            "Custom Field enabled successfully",
        "Custom Link": "Custom Link",
        "Custom Link should be a relative url.":
            "Custom Link should be a relative url.",
        Customer: "Customer",
        "Customer Name": "Customer Name",
        "Customer Type": "Customer Type",
        "Customer updated successfully": "Customer updated successfully",
        "Customer(s) added successfully": "Customer(s) added successfully",
        "Customers Excel Sheet -": "Customers Excel Sheet -",
        "Customize the app design using css, add logo, fav icons etc":
            "Customize the app design using css, add logo, fav icons etc",
        DELIVERED: "DELIVERED",
        DELIVERY: "DELIVERY",
        DISCOUNT: "DISCOUNT",
        DOB: "DOB",
        "Date Of Birth": "Date Of Birth",
        "Date of Birth": "Date of Birth",
        Days: "Days",
        Default: "Default",
        "Default Configuration": "Default Configuration",
        "Default Language": "Default Language",
        "Default Value": "Default Value",
        "Delay (ms)": "Delay (ms)",
        Delete: "Delete",
        "Delivery & Currency Settings": "Delivery & Currency Settings",
        "Delivery Charges": "Delivery Charges",
        "Delivery Discount": "Delivery Discount",
        "Delivery Discount Type": "Delivery Discount Type",
        "Delivery Discount is required": "Delivery Discount is required",
        "Delivery End Time": "Delivery End Time",
        "List of Schedule Time slots": "List of Schedule Time slots",
        "End Time": "End Time",
        "Delivery Mode": "Delivery Mode",
        "Delivery SLA": "Delivery SLA",
        "Delivery Scheduling": "Delivery Scheduling",
        "Delivery Scheduling Settings": "Delivery Scheduling Settings",
        "Delivery Settings": "Delivery Settings",
        "Delivery Start Time": "Delivery Start Time",
        "Delivery settings updated successfully": "Delivery settings updated successfully",
        "Description": "Description",
        "Design": "Design",
        "Design Settings updated successfully": "Design Settings updated successfully",
        "Disable": "Disable",
        "Disabled": "Disabled",
        "Discard Changes": "Discard Changes",
        "Discound on": "Discound on",
        Discount: "Discount",
        "Discount On": "Discount On",
        "Discount Type": "Discount Type",
        "Discount Value": "Discount Value",
        "Discount is required": "Discount is required",
        Discounts: "Discounts",
        "Display Count": "Display Count",
        "Do you want to save this Template":
            "Do you want to save this Template",
        "Dotted Navigation": "Dotted Navigation",
        Download: "Download",
        "Download started, Please Check My Downloads":
            "Download started, Please Check My Downloads",
        Draft: "Draft",
        "Drag and drop file here": "Drag and drop file here",
        "Drag the element and drop it into dotted borderd area":
            "Drag the element and drop it into dotted borderd area",
        "Drop Elements Here": "Drop Elements Here",
        "Dynamic Field created successfully":
            "Dynamic Field created successfully",
        "Dynamic Field updated successfully":
            "Dynamic Field updated successfully",
        EMAIL: "EMAIL",
        "Enable Social Login": "Enable Social Login",
        "Allow Maximum Discount Value": "Allow Maximum Discount Value",
        EN: "EN",
        Edit: "Edit",
        "Edit Customer": "Edit Customer",
        "Eg:": "Eg",
        "Elastic ID": "Elastic ID",
        "Elastic Search Settings": "Elastic Search Settings",
        "Element Type": "Element Type",
        Email: "Email",
        "Email Body": "Email Body",
        "Email Configuration": "Email Configuration",
        "Email is invalid": "Email is invalid",
        "Email is not valid": "Email is not valid",
        "Email is required": "Email is required",
        "Embed Ar is required": "Embed Ar is required",
        "Embed En is required": "Embed En is required",
        Enable: "Enable",
        "Enable Coupon Code": "Enable Coupon Code",
        "Enable Delivery": "Enable Delivery",
        "Enable Delivery Scheduling": "Enable Delivery Scheduling",
        "Enable Elastic Search": "Enable Elastic Search",
        "Enable Location Map": "Enable Location Map",
        "Enable Private apps development from Apps -> Manage Private Apps to create the Paydo Fulfillment private app.":
            "Enable Private apps development from Apps -> Manage Private Apps to create the Paydo Fulfillment private app.",
        "Enable Product Page": "Enable Product Page",
        "Enable Support": "Enable Support",
        "Enable Wishlist": "Enable Wishlist",
        "Enable delivery , add delivery mode etc":
            "Enable delivery, add delivery mode etc",
        "Enable delivery scheduling, set up scheduling time and etc":
            "Enable delivery scheduling, set up scheduling time and etc",
        Enabled: "Enabled",
        English: "English",
        "Enter Brand": "Enter Brand",
        "Enter Category": "Enter Category",
        "Enter Tag Name": "Enter Tag Name",
        "Enter brand name": "Enter brand name",
        "Enter category name": "Enter category name",
        "Enter product name": "Enter product name",
        "Event Type": "Event Type",
        "Event Type is required": "Event Type is required",
        "Event Types": "Event Types",
        Expired: "Expired",
        "Expiry Date": "Expiry Date",
        "Expiry Date is required": "Expiry Date is required",
        Explicit: "Explicit",
        External: "External",
        FAILED: "FAILED",
        "FCM Server Key": "FCM Server Key",
        FULFILLED: "FULFILLED",
        Facebook: "Facebook",
        "Facebook Domain Verification": "Facebook Domain Verification",
        "Facebook Pixel ID": "Facebook Pixel ID",
        Fail: "Fail",
        Failed: "Failed",
        "Fav Icon": "Fav Icon",
        "Featured Image": "Featured Image",
        Female: "Female",
        "Field Settings updated successfully":
            "Field Settings updated successfully",
        "File is mandatory": "File is mandatory",
        Filter: "Filter",
        "Filter Settings": "Filter Settings",
        "Filter is required": "Filter is required",
        "Firebase API Key": "Firebase API Key",
        "Firebase App ID": "Firebase App ID",
        "Firebase Auth Domain": "Firebase Auth Domain",
        "Firebase General Settings": "Firebase General Settings",
        "Firebase Measurement ID": "Firebase Measurement ID",
        "Firebase Messaging Sender ID": "Firebase Messaging Sender ID",
        "Firebase Project ID": "Firebase Project ID",
        "Firebase Settings": "Firebase Settings",
        "Firebase Storage Bucket": "Firebase Storage Bucket",
        "Firebase settings updated successfully":
            "Firebase settings updated successfully",
        "First Name": "First Name",
        "First Name is required": "First Name is required",
        "First Visit": "First Visit",
        Fixed: "Fixed",
        "Fixed Charges": "Fixed Charges",
        "Fixed Delivery Charges": "Fixed Delivery Charges",
        "Form is not valid": "Form is not valid",
        "Form is not valid,": "Form is not valid",
        "Free Mode": "Free Mode",
        "Fulfillment Status": "Fulfillment Status",
        "Full Width": "Full Width",
        "GTM Code": "GTM Code",
        Gender: "Gender",
        General: "General",
        "General Settings": "General Settings",
        "General Settings updated successfully":
            "General Settings updated successfully",
        "Go to": "Go to",
        Goods: "Goods",
        "Google Site Verification": "Google Site Verification",
        Grid: "Grid",
        Guest: "Guest",
        "Guest Customer": "Guest Customer",
        "Guest Only": "Guest Only",
        "Guest and Register": "Guest and Register",
        "Guest or Registerd": "Guest or Registerd",
        "Help Text (Ar)": "Help Text (Ar)",
        "Help Text (En)": "Help Text (En)",
        Hidden: "Hidden",
        "Hide Detail": "Hide Detail",
        History: "History",
        "History of": "History of",
        "Home Page": "Home Page",
        "Home Page Settings": "Home Page Settings",
        "House No": "House No",
        "Html Ar is required": "Html Ar is required",
        "Html En is required": "Html En is required",
        Icon: "Icon",
        "Icon Ar": "Icon Ar",
        "Icon En": "Icon En",
        "Icon Image": "Icon Image",
        "Icon Type": "Icon Type",
        "If you are on Advanced Shopify, Shopify Plus or an annual plan, you will have this service enabled by default. However,":
            "If you are on Advanced Shopify, Shopify Plus or an annual plan, you will have this service enabled by default. However,",
        Image: "Image",
        "Image Ar is required": "Image Ar is required",
        "Image En is required": "Image En is required",
        "Image Size Warning": "Image Size Warning",
        "Image size should be 64 x 64 pixels , Please rezise your image and upload":
            "Image size should be 64 x 64 pixels , Please rezise your image and upload",
        Images: "Images",
        Implicit: "Implicit",
        "In active": "In active",
        "In queue": "In queue",
        Information: "Information",
        Initiated: "Initiated",
        Instagram: "Instagram",
        Install: "Install",
        "Install the Paydo services on Shopify by clicking on the":
            "Install the Paydo services on Shopify by clicking on the",
        "Installing Shopify": "Installing Shopify",
        "International Configuration": "International Configuration",
        "International Delivery": "International Delivery",
        "Invalid email": "Invalid Email",
        "Invoice Date": "Invoice Date",
        "Invoice No": "Invoice No",
        "Invoice Number": "Invoice Number",
        "Invoice Status": "Invoice Status",
        "Ion Icon": "Ion Icon",
        "Is Civil ID Mandatory": "Is Civil ID Mandatory",
        "Is Email Mandatory": "Is Email Mandatory",
        "Is Enabled": "Is Enabled",
        "Is Guest": "Is Guest",
        "Is PACI Mandatory": "Is PACI Mandator",
        "Is Searchable": "Is Searchable",
        KD: "AED",
        Key: "Key",
        "Kuwait Configuration": "Kuwait Configuration",
        "Label (Ar)": "Label (Ar)",
        "Label (En)": "Label (En)",
        "Label Arabic is required": "Label Arabic is required",
        "Label English is required": "Label English is required",
        "Large Screen": "Large Screen",
        "Last Name": "Last Name",
        "Layout Settings": "Layout Settings",
        "Posts Layout Settings": "Posts Layout Settings",
        Limited: "Limited",
        "List of Conditions": "List of Conditions",
        "List of Country Rules": "List of Country Rules",
        "Local Delivery": "Local Delivery",
        Logo: "Logo",
        Logout: "Logout",
        Loop: "Loop",
        Male: "Male",
        "Max Price": "Max Price",
        "Maximum Cart Amount": "Maximum Amount",
        "Maximum Cart Amount is required": "Maximum Amount is required",
        "Media List": "Media List",
        "Media created successfully": "Media created successfully",
        "Media deleted successfully": "Media deleted successfully",
        "Media updated successfully": "Media updated successfully",
        "Membership Discount": "Membership Discount",
        "Membership Number": "Membership Number",
        "Membership Plan": "Membership Plan",
        Memberships: "Memberships",
        "Menu Items": "Menu Items",
        Message: "Message",
        "Message is required": "Message is required",
        "Meta Description (Ar)": "Meta Description (Ar)",
        "Meta Description (En)": "Meta Description (En)",
        "Middle Name": "Middle Name",
        "Min Price": "Min Price",
        "Minimum Amount": "Minimum Amount",
        "Minimum Cart Amount": "Minimum Cart Amount",
        "Minimum Order Amount": "Minimum Order Amount",
        "Mininum Cart Amount is required": "Mininum Cart Amount is required",
        "Mobile Screen": "Mobile Screen",
        "More Information": "More Information",
        "My Downloads": "My Downloads",
        "MyPaydo General Settings": "MyPaydo General Settings",
        "MyPaydo Settings": "MyPaydo Settings",
        "MyPaydo settings updated successfully":
            "MyPaydo settings updated successfully",
        Name: "Name",
        "Name (Ar)": "Name (Ar)",
        "Name (En)": "Name (En)",
        "Name En is required": "Name En is required",
        "Name English is required": "Name English is required",
        "Name is required": "Name is required",
        "Name(Ar)": "Name(Ar)",
        "Name(En)": "Name(En)",
        Native: "Native",
        Navigation: "Navigation",
        "Navigation item added successfully":
            "Navigation item added successfully",
        "Navigation item updated successfully":
            "Navigation item updated successfully",
        "Navigation menu created successfully":
            "Navigation menu created successfully",
        "Navigation menu deleted successfully":
            "Navigation menu deleted successfully",
        "Navigation menu updated successfully":
            "Navigation menu updated successfully",
        "New Password": "New Password",
        "New Password is required": "New Password is required",
        No: "No",
        "No Of Items": "No Of Items",
        "No Validation": "No Validation",
        "No items to display": "No items to display",
        "No of rules": "No of rules",
        "No value": "No value",
        None: "None",
        Notes: "Notes",
        "Nothing to display": "Nothing to display",
        "Notification send successfully": "Notification send successfully",
        "Noting to display": "Noting to display",
        "Number Language": "Number Language",
        "Number of items": "Number of items",
        "Number of slides": "Number of slides",
        OK: "OK",
        OR: "OR",
        "Only Arabic": "Only Arabic",
        "Only English": "Only English",
        "Operation successfully completed": "Operation successfully completed",
        "Operation type": "Operation type",
        Operator: "Operator",
        Or: "Or",
        Order: "Order",
        "Order By": "Order By",
        "Order History": "Order History",
        "Order ID": "Order ID",
        "Order Information": "Order Information",
        "Order is required": "Order is required",
        Orders: "Orders",
        "Orders Excel Sheet -": "Orders Excel Sheet -",
        "Orders, ": "Orders, ",
        Others: "Others",
        "Outgoing Email Settings": "Outgoing Email Settings",
        "Outgoing SMS Settings": "Outgoing SMS Settings",
        "Override Arabic": "Override Arabic",
        "Override English": "Override English",
        PACCI: "PACCI",
        PACI: "PACI",
        "PACI Note (Ar)": "PACI Note (Ar)",
        "PACI Note (En)": "PACI Note (En)",
        PENDING: "PENDING",
        PUSH: "PUSH",
        Page: "Page",
        "Page Elements": "Page Elements",
        "Page Limit": "Page Limit",
        "Page Limit is required": "Page Limit is required",
        "Page Limit should be greater than 4":
            "Page Limit should be greater than 4",
        "Page Settings": "Page Settings",
        "Page URL": "Page URL",
        "Page created successfully": "Page created successfully",
        "Page deleted successfully": "Page deleted successfully",
        "Page is required": "Page is required",
        "Page updated successfully": "Page updated successfully",
        Pages: "Pages",
        Parent: "Parent",
        Password: "Password",
        "Password and Confirm Password must be same":
            "Password and Confirm Password must be same",
        "Password changed successfully": "Password changed successfully",
        "Password contain atleast 8 characters":
            "Password contain atleast 8 characters",
        "Password is required": "Password is required",
        "Paste the name of the icon in the icon text box":
            "Paste the name of the icon in the icon text box",
        "Paydo Shipping App": "Paydo Shipping App",
        "Paydo Shipping App is built to provide fulfillment services for the shopify orders":
            "Paydo Shipping App is built to provide fulfillment services for the shopify orders",
        Payment: "Payment",
        "Payment Date": "Payment Date",
        "Payment Method": "Payment Method",
        "Payment Status": "Payment Status",
        Pending: "Pending",
        "Pending Cart Delay": "Pending Cart Delay",
        "Pending Cart Delay should be greater than 0":
            "Pending Cart Delay should be greater than 0",
        "Pending Cart Delay should be less than 101":
            "Pending Cart Delay should be less than 101",
        "Permission List": "Permission List",
        "Personal Information": "Personal Information",
        Personalized: "Personalized",
        "Phone Number": "Phone Number",
        "Phone Number is required": "Phone Number is required",
        Plan: "Plan",
        "Plan is required": "Plan is required",
        "Please fill the required fields": "Please fill the required fields",
        "Please save the parent nav item before assigning child to it":
            "Please save the parent nav item before assigning child to it",
        Popup: "Popup",
        "Popup Behaviour": "Popup Behaviour",
        "Popup Delay (in ms)": "Popup Delay (in ms)",
        "Popup Settings": "Popup Settings",
        Port: "Port",
        "Port is required": "Port is required",
        "Possible Values": "Possible Values",
        "Post Category": "Post Category",
        "Post Category deleted successfully":
            "Post Category deleted successfully",
        "Post Elements": "Post Elements",
        "Post URL": "Post URL",
        "Post deleted successfully": "Post deleted successfully",
        Posts: "Posts",
        Preview: "Preview",
        "Preview (Arabic)": "Preview (Arabic)",
        "Preview (English)": "Preview (English)",
        "Preview - Email": "Preview - Email",
        "Preview - PUSH": "Preview - PUSH",
        "Preview - SMS": "Preview - SMS",
        Price: "Price",
        "Price Filter": "Price Filter",
        "Price Rule Settings": "Price Rule Settings",
        "Price Rule created successfully": "Price Rule created successfully",
        "Price Rule deleted successfully": "Price Rule deleted successfully",
        "Price Rule updated successfully": "Price Rule updated successfully",
        "Primary Color": "Primary Color",
        Processed: "Processed",
        Processing: "Processing",
        Product: "Product",
        "Product Amount": "Product Amount",
        "Product Mininum Quantity": "Product Minimum Quantity",
        "Product Name": "Product Name",
        "Product Set Types": "Product Set Types",
        "Product Settings": "Product Settings",
        "Product Types": "Product Types",
        Products: "Products",
        "Profile Picture": "Profile Picture",
        Public: "Public",
        Published: "Published",
        Quantity: "Quantity",
        "Reach out to Shopify’s customer support to have this service enabled on your existing plan at an extra cost.":
            "Reach out to Shopify’s customer support to have this service enabled on your existing plan at an extra cost.",
        Recipient: "Recipient",
        "Recipient Type is required": "Recipient Type is required",
        Reference: "Reference",
        Refresh: "Refresh",
        "Register Only": "Register Only",
        Registered: "Registered",
        "Registered Customer": "Registered Customer",
        "Registered User": "Registered User",
        "Relation Types": "Relation Types",
        "Request could not completed": "Request could not completed",
        "Request send successfully": "Request send successfully",
        Required: "Required",
        "Restrict Area": "Restrict Area",
        Retry: "Retry",
        Role: "Role",
        "Role Detail": "Role Detail",
        "Role created successfully": "Role created successfully",
        "Role deleted successfully": "Role deleted successfully",
        "Role is required": "Role is required",
        "Role updated successfully": "Role updated successfully",
        "Root URL": "Root URL",
        "Root Url is required": "Root Url is required",
        "Rule #": "Rule #",
        "SEO & Analytics": "SEO & Analytics",
        "SEO & Analytics Settings": "SEO & Analytics Settings",
        SKU: "SKU",
        SMS: "SMS",
        "SMS Configuration": "SMS Configuration",
        SMTP: "SMTP",
        SUBTOTAL: "SUBTOTAL",
        Save: "Save",
        "Schedule After": "Schedule After",
        "Schedule Days": "Schedule Days",
        "Secondary Color": "Secondary Color",
        Select: "Select",
        Send: "Send",
        "Send Message": "Send Message",
        "Send Notification": "Send Notification",
        "Send SMS": "Send SMS",
        "Sender Email": "Sender Email",
        "Sender Email is required": "Sender Email is required",
        "Sender ID": "Sender ID",
        "Sender ID is required": "Sender ID is required",
        "Sender Name": "Sender Name",
        "Sender Name is required": "Sender Name is required",
        Sendgrid: "Sendgrid",
        Sent: "Sent",
        Sequence: "Sequence",
        Server: "Server",
        "Server is required": "Server is required",
        Service: "Service",
        "Set the Inventory managed by option to Paydo Fulfillment and add the SKU as Paydo Id for the product":
            "Set the Inventory managed by option to Paydo Fulfillment and add the SKU as Paydo Id for the product",
        "Set the required permissions": "Set the required permissions",
        Setup: "Setup",
        "Setup Mypaydo Settings": "Setup Mypaydo Settings",
        "Setup Translation for app": "Setup Translation for app",
        "Setup analytics Settings": "Setup analytics Settings",
        "Setup app statuses": "Setup app statuses",
        "Setup custom fields": "Setup custom fields",
        "Setup email configuration": "Setup email configuration",
        "Footer Config": "Footer Config",
        "Setup firebase Settings": "Setup firebase Settings",
        "Setup site's general settings like title,language etc":
            "Setup site's general settings like title, language etc",
        "Setup sms configuration": "Setup sms configuration",
        "Setup social media": "Setup social media",
        "Setup the shipping zones and configure the rates from Paydo":
            "Setup the shipping zones and configure the rates from Paydo",
        Shipping: "Shipping",
        "Shopify Api Key": "Shopify Api Key",
        "Shopify Domain": "Shopify Domain",
        "Shopify Secret Key": "Shopify Secret Key",
        "Shopify Settings": "Shopify Settings",
        "Shopify app created successfully": "Shopify app created successfully",
        "Shopify app installed successfully":
            "Shopify app installed successfully",
        "Shopify app uninstalled successfully":
            "Shopify app uninstalled successfully",
        "Shopify app updated successfully": "Shopify app updated successfully",
        "Shoppify Api Key is required": "Shoppify Api Key is required",
        "Shoppify Domain is required": "Shoppify Domain is required",
        "Shoppify Secret Key is required": "Shoppify Secret Key is required",
        "Show As": "Show As",
        "Show Civil ID": "Show Civil ID",
        "Show Detail": "Show Detail",
        "Show Home Page": "Show Home Page",
        "Show In Datatable": "Show In Datatable",
        "Show On": "Show On",
        "Show PACI": "Show PACI",
        "Show as Modal": "Show as Modal",
        "Site Identify": "Site Identify",
        "Site Settings": "Site Settings",
        "Slide Per View Large": "Slide Per View Large",
        "Slide Per View Mobile": "Slide Per View Mobile",
        "Slide Per View Small": "Slide Per View Small",
        Slider: "Slider",
        "Small Screen": "Small Screen",
        "Social Media Settings": "Social Media Settings",
        "Social media Settings updated successfully":
            "Social media Settings updated successfully",
        "Source App": "Source App",
        "Source App is required": "Source App is required",
        "Space Between": "Space Between",
        "Splash Screen BG": "Splash Screen BG",
        "Splash Screen Preview": "Splash Screen Preview",
        "Splash Screen Settings": "Splash Screen Settings",
        "Start Date": "Start Date",
        "Start Date is required": "Start Date is required",
        Status: "Status",
        "Status Page": "Status Page",
        "Status Page Settings": "Status Page Settings",
        "Status updated successfully": "Status updated successfully",
        "Stay Here": "Stay Here",
        "Step 1 ": "Step 1 ",
        "Step 2 ": "Step 2 ",
        "Step 3 ": "Step 3 ",
        "Steps to integrate Paydo into Shopify":
            "Steps to integrate Paydo into Shopify",
        Street: "Street",
        "Sub Category": "Sub Category",
        "Subscriber Label (ar)": "Subscriber Label (ar)",
        "Subscriber Label (en)": "Subscriber Label (en)",
        "Success Message (AR)": "Success Message (AR)",
        "Success Message (EN)": "Success Message (EN)",
        "Success Page Settings": "Success Page Settings",
        "Success page settings updated successfully":
            "Success page settings updated successfully",
        "Support Contact Number": "Support Contact Number",
        "Support General Settings": "Support General Settings",
        "Support Organization": "Support Organization",
        "Support Settings": "Support Settings",
        "Support Token": "Support Token",
        "Support settings updated successfully":
            "Support settings updated successfully",
        "Switch to Code Editor": "Switch to Code Editor",
        "Switch to HTML Editor": "Switch to HTML Editor",
        "Switch to the annual billing plan with Shopify.":
            "Switch to the annual billing plan with Shopify.",
        "Sync Products": "Sync Products",
        "Sync Stocks": "Sync Stocks",
        "Syncing...": "Syncing...",
        TAX: "TAX",
        TOTAL: "TOTAL",
        Tax: "Tax",
        Template: "Template",
        "Template Design": "Template Design",
        "Template Information": "Template Information",
        "Template Type": "Template Type",
        "Template Type is required": "Template Type is required",
        "Template created successfully": "Template created successfully",
        "Template deleted successfully": "Template deleted successfully",
        "Template is required": "Template is required",
        "Template updated successfully": "Template updated successfully",
        "Terms and Condition Type": "Terms and Condition Type",
        "Terms and Conditions": "Terms and Conditions",
        "Terms and Conditions updated successfully":
            "Terms and Conditions updated successfully",
        "Terms and conditions": "Terms and conditions",
        Text: "Text",
        "Text Color": "Text Color",
        "The Paydo Fulfilment app requires Shopifys":
            "The Paydo Fulfilment app requires Shopifys",
        "The app provides shipping rates and pushes order to Paydo system for fulfillment":
            "The app provides shipping rates and pushes order to Paydo system for fulfillment",
        "The orders will be added to the Paydo Delivery System as soon as they are created on shopify":
            "The orders will be added to the Paydo Delivery System as soon as they are created on shopify",
        "Theme 1": "Theme 1",
        "Theme 2": "Theme 2",
        "Theme 3": "Theme 3",
        "Theme Settings": "Theme Settings",
        Themes: "Themes",
        "This background color indicates overridden values":
            "This background color indicates overridden values",
        "Thumbnail Image": "Thumbnail Image",
        "Time Slot Duration": "Time Slot Duration",
        Title: "Title",
        "Title (Ar)": "Title (Ar)",
        "Title (Arabic)": "Title (Arabic)",
        "Title (En)": "Title (En)",
        "Title (English)": "Title (English)",
        "Title En is required": "Title En is required",
        "Title English is required": "Title English is required",
        "Title is required": "Title is required",
        "To create a private app in your Shopify Store":
            "To create a private app in your Shopify Store",
        "To enable the carrier-calculated shipping service, you can choose to either:":
            "To enable the carrier-calculated shipping service, you can choose to either:",
        "To remove the Paydo Shipping Services":
            "To remove the Paydo Shipping Services",
        "To uninstall the Paydo Services on Shopify":
            "To uninstall the Paydo Services on Shopify",
        Token: "Token",
        "Token is required": "Token is required",
        Total: "Total",
        "Total Amount": "Total Amount",
        "Total Audience": "Total Audience",
        "Total Count:": "Total Count:",
        "Total Files :": "Total Files :",
        "Total Orders": "Total Orders",
        "Transaction ID": "Transaction ID",
        Translation: "Translation",
        Type: "Type",
        "Type is required": "Type is required",
        "Type something...": "Type something...",
        URL: "URL",
        Uninstall: "Uninstall",
        "Uninstalling Shopify": "Uninstalling Shopify",
        "Unit Price": "Unit Price",
        Unlimited: "Unlimited",
        Update: "Update",
        "Customer Address": "Customer Address",
        "Update Condition": "Update Condition",
        "Update Date": "Update Date",
        "Update Media": "Update Media",
        "Updated Date": "Updated Date",
        "Updated date": "Updated date",
        "Upgrade to Advanced Shopify plan or Shopify Plus.":
            "Upgrade to Advanced Shopify plan or Shopify Plus.",
        Upload: "Upload",
        Usage: "Usage",
        "Usage Limit": "Usage Limit",
        "Usage Limit is required": "Usage Limit is required",
        "Usage Type": "Usage Type",
        "Use Existing Template": "Use Existing Template",
        "Use Other Account": "Use Other Account",
        "Use Paydo Account": "Use Paydo Account",
        "User Verified": "User Verified",
        "User created successfully": "User created successfully",
        "User deleted successfully": "User deleted successfully",
        "User updated successfully": "User updated successfully",
        Username: "Username",
        "Username is required": "Username is required",
        Validator: "Validator",
        Validity: "Validity",
        Value: "Value",
        "Value (Ar)": "Value (Ar)",
        "Value (En)": "Value (En)",
        "Value English is required": "Value English is required",
        "Value Start with HTTP": "Value Start with HTTP",
        "Value can not start with HTTP": "Value can not start with HTTP",
        "Value is required": "Value is required",
        Verified: "Verified",
        View: "View",
        "View Settings": "View Settings",
        "View Site": "View Site",
        Visibility: "Visibility",
        Whatsapp: "Whatsapp",
        "Widget created successfully": "Widget created successfully",
        "Widget deleted successfully": "Widget deleted successfully",
        "Widget updated successfully": "Widget updated successfully",
        Wishlist: "Wishlist",
        "Wishlist, ": "Wishlist",
        Yes: "Yes",
        "You can connect your shop with Shopify for fulfilment":
            "You can connect your shop with Shopify for fulfilment",
        "You can use any of the below sites to resize the image , and reupload":
            "You can use any of the below sites to resize the image , and reupload",
        "You cannot change this menu as a child":
            "You cannot change this menu as a child",
        "You have already pending downloads, So you can not download at this moment. Please try again later":
            "You have already pending downloads, So you can not download at this moment. Please try again later",
        Youtube: "Youtube",
        Zone: "Zone",
        "Zone is required": "Zone is required",
        Zoom: "Zoom",
        "at the bottom": "at the bottom",
        button: "button",
        "carrier-calculated shipping service to display shipping options on the checkout page.":
            "carrier-calculated shipping service to display shipping options on the checkout page.",
        "for ": "for",
        "go to the Shopify app settings on Paydo and click on the ": "go to the Shopify app settings on Paydo and click on the",
        "help_text_Abandoned_cart_days": "help_text_Abandoned_cart_days",
        "help_text_subscribtion_newsLetter": "Let user subscribe to NewsLetter",
        "help_text_Account": "help_text_Account",
        "help_text_show_categories": "show categories of posts",
        "help_text_Api_key_email_config": "help_text_Api_key_email_config",
        "help_text_BaseCountry": "help_text_BaseCountry",
        "help_text_BaseCurrency": "help_text_BaseCurrency",
        "help_text_Brand_Filter": "help_text_Brand_Filter",
        "help_text_CDN_URL": "help_text_CDN_URL",
        "help_text_Categories_Filter": "help_text_Categories_Filter",
        "help_text_DeliveryEndTime": "help_text_DeliveryEndTime",
        "help_text_DeliverySLA": "help_text_DeliverySLA",
        "help_text_DeliveryStartTime": "help_text_DeliveryStartTime",
        "help_text_Discount": "help_text_Discount",
        "help_text_Discount_Type": "help_text_Discount_Type",
        "help_text_Elastic_Search": "help_text_Elastic_Search",
        "help_text_EnableDelivery": "help_text_EnableDelivery",
        "help_text_EnableDeliveryScheduling": "help_text_EnableDeliveryScheduling",
        "help_text_Enable_Support": "help_text_Enable_Support",
        "help_text_Facebook_Domain_Verification": "help_text_Facebook_Domain_Verification",
        "help_text_Facebook_pixel_id": "help_text_Facebook_pixel_id",
        "help_text_Firebase_API_Key": "help_text_Firebase_API_Key",
        "help_text_Firebase_Measurement_ID": "help_text_Firebase_Measurement_ID",
        "help_text_Firebase_Project_ID": "help_text_Firebase_Project_ID",
        "help_text_Firebase_Source_Bucket": "help_text_Firebase_Source_Bucket",
        "help_text_Firebase_app_ID": "help_text_Firebase_app_ID",
        "help_text_Firebase_auth_domain": "help_text_Firebase_auth_domain",
        "help_text_Firebase_messaging_sender_ID": "help_text_Firebase_messaging_sender_ID",
        "help_text_GTM_Code": "help_text_GTM_Code",
        "help_text_Google_Site_Verification": "help_text_Google_Site_Verification",
        "help_text_HasDeliveryCharges": "help_text_HasDeliveryCharges",
        "help_text_International_Sender_id": "help_text_International_Sender_id",
        "help_text_Meta_Description_AR": "help_text_Meta_Description_AR",
        "help_text_Meta_Description_EN": "help_text_Meta_Description_EN",
        "help_text_Minimum_Order_Amount": "help_text_Minimum_Order_Amount",
        "help_text_Pages": "help_text_Pages",
        "help_text_Password_email_config": "help_text_Password_email_config",
        "help_text_Payment_Method_Shopify": "help_text_Payment_Method_Shopify",
        "help_text_Port_email_config": "help_text_Port_email_config",
        "help_text_Price_Filter": "help_text_Price_Filter",
        "help_text_RestrictArea": "help_text_RestrictArea",
        "help_text_Root_URL": "help_text_Root_URL",
        "help_text_ScheduleAfter": "help_text_ScheduleAfter",
        "help_text_ScheduleDays": "help_text_ScheduleDays",
        "help_text_Sender_email": "help_text_Sender_email",
        "help_text_Sender_name": "help_text_Sender_name",
        "help_text_Server_email_config": "help_text_Server_email_config",
        "help_text_Shopify_Api_Key": "help_text_Shopify_Api_Key",
        "help_text_Shopify_Domain": "help_text_Shopify_Domain",
        "help_text_Shopify_Secret_Key": "help_text_Shopify_Secret_Key",
        "help_text_Source_APP": "help_text_Source_APP",
        "help_text_Status": "help_text_Status",
        "help_text_Sub_Categories_Filter": "help_text_Sub_Categories_Filter",
        "help_text_Support_Organization": "help_text_Support_Organization",
        "help_text_Support_Token": "help_text_Support_Token",
        "help_text_Support_contact_number": "help_text_Support_contact_number",
        "help_text_Theme": "help_text_Theme",
        "help_text_TimeSlotDuration": "help_text_TimeSlotDuration",
        "help_text_Token": "help_text_Token",
        "help_text_Username_email_config": "help_text_Username_email_config",
        "help_text_ask_email": "Allow users to enter email address",
        "help_text_condition_filter": "help_text_condition_filter",
        "help_text_condition_operator": "help_text_condition_operator",
        "help_text_condition_value": "help_text_condition_value",
        "help_text_design_LargeScreen": "help_text_design_LargeScreen",
        "help_text_design_MobileScreen": "help_text_design_MobileScreen",
        "help_text_design_SmallScreen": "help_text_design_SmallScreen",
        "help_text_design_TemplateDesign": "help_text_design_TemplateDesign",
        "help_text_email_config_APi_key": "Enter Sendgrid API key",
        "help_text_email_config_Password": "Enter the password for server authentication",
        "help_text_email_config_Port": "Enter the port of SMTP",
        "help_text_email_config_Sender_email": "Enter the email you would like to send email using",
        "help_text_email_config_Sender_name": "Enter the name you would like to show as sender",
        "help_text_email_config_Server": " Enter the hostname. Make sure Email is verfied with the provider",
        "help_text_email_config_Type": "SMTP or Sendgrid",
        "help_text_email_config_Username": "Enter username for server authentication",
        "help_text_enable_coupon_code": "Allow users to enter a copon code during checkout.",
        "help_text_fcm_server_key": "help_text_fcm_server_key",
        "help_text_grid": "Show as Grid",
        "help_text_internation_api_key": "help_text_internation_api_key",
        "help_text_is_email_mandatory": "Make email madatory.",
        "help_text_list": "Show as List",
        "help_text_local_Sender_id": "help_text_local_Sender_id",
        "help_text_local_api_key": "help_text_local_api_key",
        "help_text_location_map": "Let user select the location using Google Maps",
        "help_text_pending_cart_days": "help_text_pending_cart_days",
        "help_text_price_rule_cart_amount": "help_text_price_rule_cart_amount",
        "help_text_price_rule_delivery_charges": "help_text_price_rule_delivery_charges",
        "help_text_price_rule_international_delivery": "help_text_price_rule_international_delivery",
        "help_text_price_rule_local_delivery": "help_text_price_rule_local_delivery",
        "help_text_price_rule_product_amount": "help_text_price_rule_product_amount",
        "help_text_show_home_page": "Load a Home page first, by default",
        "help_text_slider_Animation": "help_text_slider_Animation",
        "help_text_slider_AutoPlay": "help_text_slider_AutoPlay",
        "help_text_slider_Delay": "help_text_slider_Delay",
        "help_text_slider_FreeMode": "help_text_slider_FreeMode",
        "help_text_slider_Loop": "help_text_slider_Loop",
        "help_text_slider_Name": "help_text_slider_Name",
        "help_text_slider_Navigation": "help_text_slider_Navigation",
        "help_text_slider_SpaceBetween": "help_text_slider_SpaceBetween",
        "help_text_slider_Zoom": "help_text_slider_Zoom",
        "help_text_slider_dotted_Navigation": "help_text_slider_dotted_Navigation",
        "help_text_slider_showas": "help_text_slider_showas",
        "help_text_slider_type": "help_text_slider_type",
        "help_text_sms_config_international_API_key": "help_text_sms_config_international_API_key",
        "help_text_sms_config_international_Sender_ID": "help_text_sms_config_international_Sender_ID",
        "help_text_sms_config_local_API_Key": "help_text_sms_config_local_API_Key",
        "help_text_sms_config_local_Password": "help_text_sms_config_local_Password",
        "help_text_sms_config_local_Sender_ID": "help_text_sms_config_local_Sender_ID",
        "help_text_sms_config_local_Username": "help_text_sms_config_local_Username",
        "help_text_terms_disabled": "help_text_terms_disabled",
        "help_text_terms_explicit": "help_text_terms_explicit",
        "help_text_terms_implicit": "help_text_terms_implicit",
        "help_text_widget_Enable": "help_text_widget_Enable",
        "help_text_widget_Name": "help_text_widget_Name",
        "help_text_widget_Order": "help_text_widget_Order",
        "help_text_widget_Type": "help_text_widget_Type",
        "here": "here",
        "if you are on the Shopify Basic plan, you need to enable the carrier-calculated shipping service before installing the app.": "if you are on the Shopify Basic plan, you need to enable the carrier-calculated shipping service before installing the app.",
        "is a relative url": "is a relative url",
        or: "or",
        read_fulfillments: "read_fulfillments ",
        read_inventory: "read_inventory",
        read_orders: "read_orders ",
        read_products: "read_products",
        read_shipping: "read_shipping ",
        "to add the shipping zones": "to add the shipping zones",
        write_fulfillments: "write_fulfillments ",
        write_inventory: "write_inventory",
        write_orders: "write_orders ",
        write_products: "write_products",
        write_shipping: "write_shipping ",
        "“Install”": "“Install”",
        "“Shipping and delivery”": "“Shipping and delivery”",
        "“Use the carrier or app to calculate rates”":
            "“Use the carrier or app to calculate rates”",
        "Show Side Menu on Desktop": "Show Side Menu on Desktop",
        "Show Post Comments": "Show Posts Comments",
        "Posts Number Per Row": "Posts Number Per Row"
    }
};
