import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DeliveryService } from "app/services/delivery.service";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";
import { WalletService } from "app/services/wallet/wallet.service";

@Component({
    selector: "app-change-delivery-status",
    templateUrl: "./change-delivery-status.component.html",
    styleUrls: ["./change-delivery-status.component.scss"],
})
export class ChangeDeliveryStatusComponent implements OnInit {
    loading: boolean = false;
    offset: number = 0;
    last_status: any;
    searchKey: any;
    orderStatus = [
        { id: 1, value: "Recived" },
        { id: 2, value: "Payment Pending" },
        { id: 3, value: "Confirmed" },
        { id: 4, value: "Packed" },
        { id: 5, value: "Picking Started" },
        { id: 6, value: "Shipped" },
        { id: 7, value: "Delivered " },
        { id: 8, value: "Cancelled " },
        { id: 9, value: "Returned " },
        { id: 10, value: "Callback " },
    ];
    deliveryStatusList: any = null;
    page = {
        size: 10,
        totalElements: 0,
        pageNumber: 0,
    };
    // order_status: any;
    form: FormGroup;
    orderId: any = "";
    deliveryName: string;
    deliveryDetails: any;
    deliveryId: any;

    constructor(
        private deliveryService: DeliveryService,
        public dialogRef: MatDialogRef<ChangeDeliveryStatusComponent>,
        private formBuilder: FormBuilder,
        private utilitiesService: UtilitiesService,
        private walletService: WalletService,
        private translationService: TranslationService,

        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data) {
            this.deliveryId = data.deliveryId ? data.deliveryId : null;
            this.deliveryDetails = data.deliveryDetails
                ? data.deliveryDetails
                : "";
            this.deliveryName =
                this.deliveryDetails?.order?.customer?.first_name +
                " " +
                this.deliveryDetails?.order?.customer?.last_name;
            this.last_status = this.deliveryDetails?.delivery_status_obj?.id;
        }
        this.formInitialize();
    }

    async ngOnInit(): Promise<void> {
        await this.getDeliveryStatus();
    }
    async getDeliveryStatus() {
        await this.deliveryService.getDeliveryStatustList().subscribe((res) => {
            if (res) {
                this.deliveryStatusList = res;
            }
        });
    }

    async formInitialize() {
        this.form = this.formBuilder.group({
            id: [this.orderId],
            delivery_status: [this.last_status, Validators.required],
            remarks: [null],
        });
    }
    async updateDeliveryStatus() {
        const form = this.form.value;
        try {
            this.utilitiesService.startLoader();
            await this.deliveryService
                .updateDelivery(this.deliveryId, form)
                .subscribe((resp) => {
                    if (resp) {
                        this.utilitiesService.stopLoader;
                        this.dialogRef.close(true);
                    }
                });
        } catch {
            this.utilitiesService.stopLoader;
        } finally {
            this.utilitiesService.stopLoader;
        }
    }
    // async send() {
    //     if (this.form.valid) {
    //         const form = this.form.value;

    //         await this.utilitiesService.startLoader();
    //         // const orderList = await this.orderService
    //         //     .updateOrderStatus(this.orderId, form)
    //         //     .toPromise();
    //         // if (orderList) {
    //         //     console.log(orderList);
    //         //     this.dialogRef.close(true);
    //         //     //     this.utilitiesService.stopLoader();
    //         // } else {
    //         //     this.dialogRef.close(false);
    //         //     //     this.utilitiesService.stopLoader();
    //         // }

    //         this.utilitiesService.stopLoader();
    //     }
    //     this.utilitiesService.stopLoader();
    // }

    close() {
        this.dialogRef.close(null);
    }
}
