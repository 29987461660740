<div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em" id="main-page">
    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
        <form fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em" [formGroup]="form">
            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <mat-icon class="cursor-pointer" (click)="goBack()">arrow_back</mat-icon>
                    <label class="highlight-text-color">{{'Catalog Settings' | translate}}</label>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
                    <button (click)="save()" type="submit" mat-flat-button style="position: absolute;right: 16px;"
                        class="primary-bg-color">{{'Save' | translate}}</button>
                </div>
            </div>
            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                [@animate]="{value:'*',params:{duration:'400ms',y:'70px'}}">
                <!--
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                    fxLayoutGap="1em">
                    <div fxFlex.lt-md="1 1 auto" fxFlex.gt-sm="0 0 50%" class="fuse-card auto-width p-16 "
                        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                            <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row"
                                fxLayoutGap="0px">
                                <label class="highlight-text-color">{{'Home Page Settings' | translate}}</label>
                            </div>
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="0px">
                                    <mat-slide-toggle formControlName="is_home_page">
                                        {{'Show Home Page' | translate}}
                                    </mat-slide-toggle>
                                    <small>{{'help_text_show_home_page' | translate}}</small>
                                </div>
                                <ng-container *ngIf="form.get('is_home_page').value">
                                    <mat-form-field appearance="outline" fxFlex="100%">
                                        <mat-label>{{'Page Type' | translate}}</mat-label>
                                        <mat-select formControlName="default_page">
                                            <mat-option [value]='1'>{{'Default' | translate}}</mat-option>
                                            <mat-option [value]='2'>{{'Page' | translate}}</mat-option>
                                            <mat-option [value]='3'>{{'Custom Page' | translate}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="100%"
                                        *ngIf="form.get('default_page').value == 2">
                                        <mat-label>{{'Home Page' | translate}}</mat-label>
                                        <mat-select formControlName="home_page">
                                            <mat-option value=''>{{'--Select Page--' | translate}}</mat-option>
                                            <mat-option [value]="page?.id" *ngFor="let page of pages">{{page?.title}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="100"
                                        *ngIf="form.get('default_page').value == 3">
                                        <mat-label class="text-capitalized">
                                            {{'Custom Page'| translate}}
                                        </mat-label>
                                        <textarea formControlName="custom_home_page" matInput name="title"></textarea>
                                    </mat-form-field>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <div fxFlex="1 1 auto" class="fuse-card auto-width p-16 " fxLayoutAlign="start stretch"
                            fxLayout="column" fxLayoutGap="1em">
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                                <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row"
                                    fxLayoutGap="0px">
                                    <label class="highlight-text-color">{{'Page Settings' | translate}}</label>
                                </div>
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="1em">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Page Limit'| translate}}
                                        </mat-label>
                                        <input type="number" formControlName="page_size_limit" matInput name="title">
                                        <mat-error *ngIf="form.get('page_size_limit').hasError('required')">
                                            {{'Page Limit is required' | translate}}
                                        </mat-error>
                                        <mat-error *ngIf="form.get('page_size_limit').hasError('min')">
                                            {{'Page Limit should be greater than 4' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="1 1 auto" class="fuse-card auto-width p-16 " fxLayoutAlign="start stretch"
                            fxLayout="column" fxLayoutGap="1em">
                            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="5px">
                                <div fxFlex="0 0 auto" fxLayoutAlign="space-between stretch" fxLayout="row"
                                    fxLayoutGap="0px">
                                    <label class="highlight-text-color">{{'Custom Fields Settings' | translate}}</label>
                                </div>
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="1em">
                                    <mat-slide-toggle fxFlex="0 1 60%" formControlName="is_gift_enabled" class="">
                                        {{'Enable Gift' | translate}}
                                    </mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- work area -->
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                    fxLayoutGap="1em">
                    <div fxFlex="1 1 auto" class="fuse-card auto-width p-16 " fxLayoutAlign="start stretch"
                        fxLayout="column" fxLayoutGap="1em">
                        <label class="highlight-text-color">{{'Filter Settings' | translate}}</label>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="5px">
                                    <label>{{'Search Filter' | translate}}</label>
                                    <mat-radio-group formControlName="search_filter">
                                        <mat-radio-button class="pr-16" [value]="1">{{'All' | translate}}
                                        </mat-radio-button>
                                        <mat-radio-button [value]="2">{{'None' | translate}}</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout.gt-sm="row"
                                    fxLayout.lt-md="column" fxLayoutGap="1em">
                                    <div fxFlex.lt-md="0 0 auto" fxFlex.gt-sm="0 0 50%" fxLayoutAlign="start stretch"
                                        fxLayout="column" fxLayoutGap="0px">
                                        <mat-slide-toggle formControlName="categories_filter">
                                            {{'Categories Filter' | translate}}
                                        </mat-slide-toggle>
                                        <small class="help-text">{{'help_text_Categories_Filter' | translate}}</small>
                                    </div>
                                    <div *ngIf="form.get('categories_filter').value" fxFlex="1 1 auto"
                                        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                        <mat-form-field appearance="outline" style="width: 100%;">
                                            <mat-label>{{'Category Filter Layout' | translate}}</mat-label>
                                            <mat-select formControlName="category_filter_layout">
                                                <mat-option [value]="1">{{'All' | translate}}
                                                </mat-option>
                                                <mat-option [value]="2">{{'Checkbox' | translate}}
                                                </mat-option>
                                                <mat-option [value]="3">{{'Images' | translate}}
                                                </mat-option>
                                                <mat-option [value]="4">{{'None' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="0 0 auto" fxLayoutAlign="start center" fxLayout.gt-sm="row"
                                    fxLayout.lt-md="column" fxLayoutGap="1em">
                                    <div fxFlex.lt-md="0 0 auto" fxFlex.gt-sm="0 0 50%" fxLayoutAlign="start stretch"
                                        fxLayout="column" fxLayoutGap="0px">
                                        <mat-slide-toggle formControlName="brand_filter" class="">
                                            {{'Brand Filter' | translate}}</mat-slide-toggle>
                                        <small class="help-text">{{'help_text_Brand_Filter' | translate}}</small>
                                    </div>
                                    <div *ngIf="form.get('brand_filter').value" fxFlex="1 1 auto"
                                        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                        <mat-form-field appearance="outline" style="width: 100%;">
                                            <mat-label>{{'Brand Filter Layout' | translate}}</mat-label>
                                            <mat-select formControlName="brand_filter_layout">
                                                <mat-option [value]="1">{{'All' | translate}}
                                                </mat-option>
                                                <mat-option [value]="2">{{'Checkbox' | translate}}
                                                </mat-option>
                                                <mat-option [value]="3">{{'Images' | translate}}
                                                </mat-option>
                                                <mat-option [value]="4">{{'None' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="0px">
                                    <mat-slide-toggle fxFlex="0 1 60%" formControlName="price_filter" class="">
                                        {{'Price Filter' | translate}}
                                    </mat-slide-toggle>
                                    <small class="help-text">{{'help_text_Price_Filter' | translate}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                    fxLayoutGap="1em">




                    <div class="fuse-card auto-width p-16" fxFlex.lt-md="1 1 auto" fxFlex.gt-sm="0 0 50%"
                        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <label class="highlight-text-color">{{'Category Settings' | translate}}</label>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="1em">
                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="0px">
                                        <mat-slide-toggle formControlName="sub_category">
                                            {{'Sub Category' | translate}}
                                        </mat-slide-toggle>
                                        <small class="help-text">{{'help_text_Sub_Categories_Filter' |
                                            translate}}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="1 1 auto" class="fuse-card auto-width p-16 " fxLayoutAlign="start stretch"
                        fxLayout="column" fxLayoutGap="1em">
                        <label class="highlight-text-color">{{'Elastic Search Settings' | translate}}</label>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="1em">
                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="0px">
                                        <mat-slide-toggle formControlName="elastic_search">
                                            {{'Enable Elastic Search' | translate}}
                                        </mat-slide-toggle>
                                        <small class="help-text">{{'help_text_Elastic_Search' | translate}}</small>
                                    </div>
                                    <div *ngIf="form.get('elastic_search').value" fxFlex="0 0 auto"
                                        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label class="text-capitalized">
                                                {{'Elastic ID'| translate}}
                                            </mat-label>
                                            <input formControlName="account_uid" matInput name="title">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                    fxLayoutGap="1em">

                    <div class="fuse-card auto-width p-16" fxFlex.lt-md="1 1 auto" fxFlex.gt-sm="0 0 50%"
                        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <label class="highlight-text-color">{{'Product Page Settings' | translate}}</label>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="1em">
                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="0px">
                                        <mat-slide-toggle fxFlex="0 1 60%" formControlName="enable_product_page"
                                            class="">
                                            {{'Enable Product Page' | translate}}
                                        </mat-slide-toggle>
                                        <small class="help-text">{{'Enable Product Page' | translate}}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                    fxLayoutGap="1em">

                    <div class="fuse-card auto-width p-16" fxFlex.lt-md="1 1 auto" fxFlex.gt-sm="0 0 50%"
                        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <label class="highlight-text-color">{{'Review Settings' | translate}}</label>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em">
                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                    fxLayoutGap="1em">
                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                        fxLayoutGap="0px">
                                        <mat-slide-toggle fxFlex="0 1 60%" formControlName="allow_rating" class="">
                                            {{'Enable Review & Rating' | translate}}
                                        </mat-slide-toggle>
                                        <small class="help-text">{{'This will enable user to submit ratings and review
                                            for perticular products' | translate}}</small>
                                    </div>
                                    <div *ngIf="form.get('allow_rating').value" fxFlex="0 0 auto"
                                        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                                        <mat-slide-toggle fxFlex="0 1 60%" formControlName="approve_ratings_by_default"
                                            class="">
                                            {{'Approve Ratings By Default' | translate}}
                                        </mat-slide-toggle>
                                        <small class="help-text">{{'This will approve all ratings and review as soon as
                                            users submit' | translate}}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                    [@animate]="{value:'*',params:{duration:'400ms',y:'70px'}}">

                    <div fxFlex="0 0 auto" class="fuse-card auto-width p-16 center-container"
                        fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
                        <label class="highlight-text-color">{{'Social Sharing Options' | translate}}</label>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                            <ng-container *ngIf="socialShareForm">
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                    fxLayout.gt-sm="row wrap" fxLayoutGap="1em">
                                    <ng-container *ngFor="let config of socialShareForm.controls | keyvalue">
                                        <div fxFlex.gt-sm="0 0 30%" class="mb-8" fxFlex.lt-md="0 0 auto"
                                            fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                                            fxLayoutGap="1em">
                                            <mat-slide-toggle [formControl]="config.value">
                                                {{config?.key | uppercase}}
                                            </mat-slide-toggle>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>