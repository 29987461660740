<div class="number-card p-16 min-width-custom" fxFlex="1 1 33%" fxLayoutAlign="space-between center" fxLayout="row"
    fxLayoutGap="10px">
    <p class="number-card__total">3000 <sup>visits</sup></p>
    <p>{{'Total Visits' | translate}}</p>
</div>
<div class="number-card p-16 min-width-custom" fxFlex="0 0 33%" fxLayoutAlign="space-between center" fxLayout="row"
    fxLayoutGap="10px">
    <p class="number-card__total">3000 <sup>orders</sup></p>
    <p> {{'Total Orders' | translate}}</p>
</div>
<div class="number-card p-16 min-width-custom" fxFlex="0 0 33%" fxLayoutAlign="space-between center" fxLayout="row"
    fxLayoutGap="10px">
    <p class="number-card__total">
        3000 <sup>{{_baseCurrency}}</sup>
    </p>
    <p>{{'Total Sales' | translate}}</p>

</div>