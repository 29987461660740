<div
    fxFlex="1 1 100%"
    fxLayoutAlign="start stretch"
    fxLayout="column"
    fxLayoutGap="1em"
>
    <div
        fxFlex="1 1 auto"
        fxLayoutAlign="space-between center"
        fxLayout="row"
        fxLayoutGap="1em"
    >
        <strong class="highlight-text-color mat-title mb-0">{{
            "All Downloads" | translate
        }}</strong>
        <mat-icon [mat-dialog-close]="true" class="cursor-pointer"
            >close</mat-icon
        >
    </div>
    <div
        fxFlex="1 1 auto"
        fxLayoutAlign="space-between center"
        fxLayout="row"
        fxLayoutGap="1em"
    >
        <div
            fxFlex="1 1 auto"
            fxLayoutAlign="start stretch"
            fxLayout="row"
            fxLayoutGap="5px"
        >
            <span class="highlight-text-color">{{
                "Total Files :" | translate
            }}</span>
            <strong class="highlight-text-color">{{ count }}</strong>
        </div>
        <button (click)="refresh()" mat-stroked-button>
            <mat-icon>refresh</mat-icon>
            {{ "Refresh" | translate }}
        </button>
    </div>

    <div
        #conatiner
        infiniteScroll
        [infiniteScrollDistance]="'0.5'"
        [scrollWindow]="false"
        (scrolled)="loadMore()"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayout="column"
        fxLayoutGap="1em"
        class="fuse-card auto-width box-shadow-none p-16 max-height-custom"
        *ngIf="downloads?.length && !isLoading"
    >
        <div
            fxLayout="column"
            fxFlex="1 1 100%"
            fxLayoutAlign="start stretch"
            fxLayoutGap="5px"
            class="fuse-card auto-width p-16 card-box-shadow"
            *ngFor="let download of downloads; let i = index"
        >
            <app-progress-bar
                *ngIf="
                    download?.status == 1 ||
                    download?.status == 2 ||
                    download?.status == 3
                "
                [progress]="download?.complete_percentage"
            ></app-progress-bar>

            <div
                fxLayout="row"
                fxFlex="1 1 auto"
                fxLayoutAlign="space-between end"
                fxLayoutGap="1em"
            >
                <div
                    fxLayout="column"
                    fxFlex="1 1 100%"
                    fxLayoutAlign="start stretch"
                    fxLayoutGap="0px"
                >
                    <div fxFlex="0 0 auto">
                        <strong
                            class="highlight-text-color"
                            *ngIf="download?.name != ''"
                            >{{ download?.name }} {{ i + 1 }}</strong
                        >
                        <strong
                            class="highlight-text-color"
                            *ngIf="
                                download?.download_type == 1 &&
                                download?.name == ''
                            "
                            >{{ "Cart Excel Sheet -" | translate }}
                            {{ i + 1 }}</strong
                        >
                        <strong
                            class="highlight-text-color"
                            *ngIf="
                                download?.download_type == 2 &&
                                download?.name == ''
                            "
                            >{{ "Customers Excel Sheet -" | translate }}
                            {{ i + 1 }}</strong
                        >
                        <strong
                            class="highlight-text-color"
                            *ngIf="
                                download?.download_type == 3 &&
                                download?.name == ''
                            "
                            >{{ "Orders Excel Sheet -" | translate }}
                            {{ i + 1 }}</strong
                        >
                    </div>
                    <div fxFlex="0 0 auto">
                        <span class="download-time">{{
                            getTime(download?.started_at)
                        }}</span>
                    </div>
                </div>
                <div
                    fxLayout="row"
                    fxFlex="1 1 auto"
                    fxLayoutAlign="end end"
                    fxLayoutGap="1em"
                >
                    <div
                        fxLayout="column"
                        fxFlex="0 0 auto"
                        fxLayoutAlign="end end"
                        fxLayoutGap="5px"
                    >
                        <strong
                            class="status-badge initate"
                            *ngIf="download?.status == 1"
                            >{{ "Initiated" | translate }}</strong
                        >
                        <strong
                            class="status-badge highlight-bg-color"
                            *ngIf="download?.status == 2"
                            >{{ "In queue" | translate }}</strong
                        >
                        <strong
                            class="status-badge pending"
                            *ngIf="download?.status == 3"
                            >{{ "Processing" | translate }}</strong
                        >
                        <strong
                            class="status-badge success"
                            *ngIf="download?.status == 4"
                            >{{ "Processed" | translate }}</strong
                        >
                        <strong
                            class="status-badge error"
                            *ngIf="download?.status == 5"
                            >{{ "Failed" | translate }}</strong
                        >
                        <strong
                            class="status-badge cancelled"
                            *ngIf="download?.status == 6"
                            >{{ "Cancelled" | translate }}</strong
                        >
                        <div fxFlex="0 0 auto">
                            <button
                                *ngIf="download?.status == 4"
                                (click)="downloadFn(download?.files)"
                                mat-raised-button
                                class="primary-bg-color download-btn"
                            >
                                <mat-icon class="icons">file_download</mat-icon>
                                <!-- <img style="height: 20px;" class="pr-3 pl-3" src="assets/images/down-arrow.svg"> -->
                                {{ "Download" | translate }}
                            </button>
                            <button
                                (click)="cancel(download?.id)"
                                *ngIf="
                                    download?.status == 1 ||
                                    download?.status == 2 ||
                                    download?.status == 3
                                "
                                mat-raised-button
                                class="retry-bg-color cancel-btn"
                            >
                                {{ "Cancel" | translate }}
                            </button>
                            <button
                                (click)="retry(download?.id)"
                                *ngIf="
                                    download?.status == 5 ||
                                    download?.status == 6
                                "
                                mat-raised-button
                                class="retry-bg-color cancel-btn"
                            >
                                {{ "Retry" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        fxLayout="column"
        fxFlex="1 1 auto"
        fxLayoutAlign="start stretch"
        fxLayoutGap="1em"
        class="fuse-card auto-width p-16 box-shadow-none"
        *ngIf="isLoading"
    >
        <div
            fxLayout="column"
            fxFlex="1 1 auto"
            fxLayoutAlign="start stretch"
            fxLayoutGap="0px"
            class="item fuse-card auto-width p-16 card-box-shadow"
            *ngFor="let loader of [1, 2, 3, 4, 5]"
        >
            <div
                fxLayout="row"
                fxFlex="1 1 auto"
                fxLayoutAlign="space-between stretch"
                fxLayoutGap="0px"
            >
                <ngx-skeleton-loader
                    style="width: 50%"
                    count="1"
                ></ngx-skeleton-loader>
                <ngx-skeleton-loader
                    style="width: 20%; height: 8px"
                    count="1"
                ></ngx-skeleton-loader>
            </div>
            <div
                fxLayout="row"
                fxFlex="1 1 auto"
                fxLayoutAlign="space-between stretch"
                fxLayoutGap="0px"
            >
                <ngx-skeleton-loader
                    class="time-skltn"
                    style="width: 20%"
                    count="1"
                ></ngx-skeleton-loader>
                <ngx-skeleton-loader
                    class="btn-skeln"
                    style="width: 30%"
                    count="1"
                ></ngx-skeleton-loader>
            </div>
        </div>
    </div>

    <div
        *ngIf="!downloads.length && !isLoading"
        fxFlex="0 0 auto"
        fxLayoutAlign="center center"
        fxLayout="column"
        fxLayoutGap="8px"
        class="fuse-card auto-width box-shadow-none p-16"
    >
        <strong class="highlight-text-color">{{
            "Nothing to display" | translate
        }}</strong>
    </div>
</div>
