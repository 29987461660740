<div fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em">
    <form fxFlex="1 1 100%" fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="1em"
          [formGroup]="pointsRuleForm">
        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em">
            <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                <div fxFlex="0 0  auto" class="fuse-card auto-width p-16 center-container" fxLayoutAlign="start stretch"
                     fxLayout="column" fxLayoutGap="1em">
                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="16px">
                        <div class="mb-0" fxFlex="0 0 auto" fxLayoutAlign="flex-end flex-end" fxLayout="column"
                             fxLayoutGap="0px">
                            <fieldset class="toggle-container" fxFlex="0 0 auto" fxLayoutAlign="start stretch"
                                      fxLayout="row" fxLayoutGap="1em">
                                <div class="toggle" [ngClass]="!pointsRuleForm.get('enable').value ? 'disable' : ''">
                                    <input type="radio" formControlName="enable" [value]="true" id="enable" />
                                    <label for="enable">{{'Enable' | translate}}</label>
                                    <input type="radio" formControlName="enable" [value]="false" id="disable" />
                                    <label for="disable">{{'Disable' | translate}}</label>
                                </div>
                            </fieldset>
                        </div>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                            <fieldset fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                      fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                                <legend class="highlight-text-color text-bold">{{'Information' | translate}}
                                    <span class="highlight-text-color font-size-12"
                                          *ngIf="pointsRuleForm.get('enable').value">
                                        {{' ( Points rule is' | translate}} <strong>{{'enabled )' | translate}}</strong>
                                    </span>
                                    <span class="disable-icon font-size-12" *ngIf="!pointsRuleForm.get('enable').value">
                                        {{' ( Points rule is' | translate}} <strong>{{'disabled )' | translate}}</strong>
                                    </span>
                                </legend>
                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label class="text-capitalized">
                                        {{'Name'| translate}}
                                    </mat-label>
                                    <input formControlName="name" matInput name="title">
                                    <mat-error *ngIf="pointsRuleForm.get('name').hasError('required')">
                                        {{'Name is required' | translate}}
                                    </mat-error>
                                </mat-form-field>
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                    fxLayout.gt-sm="row" fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                                    <mat-form-field appearance="outline" fxFlex.gt-sm="50%" fxFlex.lt-md="100%">
                                        <mat-label>{{'Type' | translate}}</mat-label>
                                        <mat-select formControlName="type">
                                            <mat-option [value]="1">{{'Coupon' | translate}}</mat-option>
                                            <mat-option [value]="2">{{'Cart' | translate}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field *ngIf="pointsRuleForm.get('type').value=='1'" appearance="outline"
                                        fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Code'| translate}}
                                        </mat-label>
                                        <input formControlName="code" matInput name="title">
                                        <mat-error *ngIf="pointsRuleForm.get('code').hasError('required')">
                                            {{'Code is required' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                            </fieldset>
                        </div>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                            <fieldset fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                      fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                                <legend class="highlight-text-color text-bold">{{'Validity' | translate}}</legend>

                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                     fxLayout.gt-sm="row" fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Minimum Cart Amount'| translate}}
                                        </mat-label>
                                        <input formControlName="minimum_amount" type="number" matInput name="title">
                                        <mat-error *ngIf="pointsRuleForm.get('minimum_amount').hasError('required')">
                                            {{'Mininum Cart Amount is required' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="100%">
                                        <mat-label>{{'Maximum Cart Amount' | translate}}</mat-label>
                                        <input formControlName="maximum_amount" type="number" matInput name="title">
                                        <mat-error *ngIf="pointsRuleForm.get('maximum_amount').hasError('required')">
                                            {{'Maximum Cart Amount is required' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </fieldset>
                        </div>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                            <fieldset fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                      fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                                <legend class="highlight-text-color text-bold">{{'Conditions' | translate}}</legend>
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                     fxLayout.gt-sm="row" fxLayoutGap="5px">
                                    <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                         fxLayout.gt-sm="row" fxLayoutGap="5px">
                                        <mat-form-field appearance="outline" fxFlex="100%">
                                            <mat-label>{{'Audience' | translate}}</mat-label>
                                            <mat-select formControlName="audience">
                                                <mat-option *ngFor="let audience of audienceList"
                                                            [value]="audience?.id">
                                                    {{audience?.name}}</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="pointsRuleForm.get('audience').hasError('required')">
                                                {{'Audience is required' | translate}}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="1em"
                                     [ngClass]="pointsRuleForm.get('idSelect').value=='4' ? 'mb-0' : 'mb-16'">
                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="row"
                                         fxLayoutGap="1em">
                                        <fieldset class="toggle-container" fxFlex="0 0 auto"
                                                  fxLayoutAlign="start stretch" fxLayout="row" fxLayoutGap="1em">
                                            <div class="toggle">
                                                <input type="radio" formControlName="idSelect" [value]="1"
                                                       id="product" />
                                                <label for="product">{{'Product' |
                                                    translate}}</label>
                                                <input type="radio" formControlName="idSelect" [value]="2" id="brand" />
                                                <label for="brand">{{'Brand' | translate}}</label>
                                                <input type="radio" formControlName="idSelect" [value]="3"
                                                       id="category" />
                                                <label for="category">{{'Category' | translate}}</label>
                                                <input type="radio" formControlName="idSelect" [value]="5"
                                                       id="payment" />
                                                <label for="payment">{{'Payment Methods' | translate}}</label>

                                                <input type="radio" formControlName="idSelect" [value]="6"
                                                       id="product_type" />
                                                <label for="product_type">{{'Product Type' | translate}}</label>


                                                <input type="radio" formControlName="idSelect" [value]="4" id="none" />
                                                <label for="none">{{'None' | translate}}</label>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                         fxLayout.gt-sm="row" fxLayoutGap="5px">
                                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                             fxLayoutGap="1em" *ngIf="pointsRuleForm.get('idSelect').value=='1'">
                                            <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout="row"
                                                 fxLayoutGap="0px">
                                                <ng-select [loading]="isLoadingProduct"
                                                           placeholder="{{'Enter product name' | translate}}" id="products"
                                                           fxFlex="100" (input)="searchProducts($event.target.value)"
                                                           [multiple]="true" formControlName="product_ids">
                                                    <ng-option *ngFor="let product of products" [value]="product?.id">
                                                        {{translationService.getOnlyTranslatedNameNotName_en(product)}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                        </div>

                                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                             fxLayout.gt-sm="row" fxLayoutGap="5px"
                                             *ngIf="pointsRuleForm.get('idSelect').value=='6'">
                                            <ng-select [loading]="isLoadingBrand"
                                                       placeholder="{{'Enter Product Type' | translate}}" fxFlex="100"
                                                       [multiple]="true" formControlName="product_type_ids">
                                                <ng-option *ngFor="let type of productTypes" [value]="type?.id">
                                                    {{translationService.getOnlyTranslatedNameNotName_en(type)}}

                                                </ng-option>

                                            </ng-select>
                                        </div>
                                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                             fxLayout.gt-sm="row" fxLayoutGap="5px"
                                             *ngIf="pointsRuleForm.get('idSelect').value=='3'">
                                            <ng-select [loading]="isLoadingCat"
                                                       placeholder="{{'Enter category name' | translate}}" fxFlex="100"
                                                       (input)="searchCategories($event.target.value)" [multiple]="true"
                                                       formControlName="category_ids">
                                                <ng-option *ngFor="let category of categories" [value]="category?.id">
                                                    {{translationService.getOnlyTranslatedNameNotName_en(category)}}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                             fxLayout.gt-sm="row" fxLayoutGap="5px"
                                             *ngIf="pointsRuleForm.get('idSelect').value=='2'">
                                            <ng-select [loading]="isLoadingBrand"
                                                       placeholder="{{'Enter brand name' | translate}}" fxFlex="100"
                                                       (input)="searchBrands($event.target.value)" [multiple]="true"
                                                       formControlName="brand_ids">
                                                <ng-option *ngFor="let brand of brands" [value]="brand?.id">
                                                    {{translationService.getOnlyTranslatedNameNotName_en(brand)}}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                        <div fxFlex="1 1 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                             fxLayout.gt-sm="row" fxLayoutGap="5px"
                                             *ngIf="pointsRuleForm.get('idSelect').value=='5'">
                                            <ng-select [loading]="isLoadingBrand"
                                                       placeholder="{{'Enter payment method' | translate}}" fxFlex="100"
                                                       [multiple]="true" formControlName="payment_method_ids">
                                                <ng-option *ngFor="let paymentMethod of paymentMethods"
                                                           [value]="paymentMethod?.id">
                                                    {{translationService.getOnlyTranslatedNameNotName_en(paymentMethod)}}
                                                </ng-option>
                                            </ng-select>
                                        </div>


                                    </div>
                                </div>
                                <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout.lt-md="column"
                                     fxLayout.gt-sm="row" fxLayoutGap="5px">
                                    <mat-form-field appearance="outline" fxFlex="100">
                                        <mat-label class="text-capitalized">
                                            {{'Product Mininum Quantity'| translate}}
                                        </mat-label>
                                        <input formControlName="minimum_quantity" type="number" matInput name="title">
                                    </mat-form-field>
                                </div>
                            </fieldset>
                        </div>
                        <div fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column" fxLayoutGap="0px">
                            <fieldset fxFlex="0 0 auto" fxLayoutAlign="start stretch" fxLayout="column"
                                      fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                                <legend class="highlight-text-color text-bold">{{'Points' | translate}}</legend>
                                <div fxFlex="0 0 auto" fxLayoutAlign="start baseline" fxLayout="row" fxLayoutGap="3px">
                                    <mat-form-field appearance="outline" fxFlex="100%">
                                        <mat-label>{{'Points On' | translate}}</mat-label>
                                        <mat-select formControlName="points_on">
                                            <mat-option [value]="1">{{'Cart Amount'}}</mat-option>
                                            <mat-option [value]="2">{{'Product Amount' | translate}}</mat-option>
                                            <mat-option [value]="3">{{'Category Amount' | translate}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="0 0 auto"
                                     fxLayoutAlign="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row"
                                     fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-md="0px">
                                    <mat-form-field appearance="outline" fxFlex="100%">
                                        <mat-label>{{'Points' | translate}}</mat-label>
                                        <input formControlName="points" type="number" matInput name="title">
                                        <mat-error *ngIf="pointsRuleForm.get('points').hasError('required')">
                                            {{'Points is required' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="100%">
                                        <mat-label>{{'Points Type' | translate}}</mat-label>
                                        <mat-select formControlName="point_type">
                                            <mat-option [value]="1">%</mat-option>
                                            <mat-option [value]="2">{{'Fixed' | translate}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div fxFlex="1 1 auto" fxLayoutAlign.gt-sm="space-between center"
                         fxLayoutAlign.lt-md="start stretch" fxLayout.lt-md="column-reverse" fxLayout.gt-sm="row"
                         fxLayoutGap="5px">
                        <button mat-stroked-button (click)="handleCancel()">
                            <mat-icon>clear</mat-icon>
                            {{'Cancel' | translate}}
                        </button>
                        <button (click)="saveOrUpdate()" type="submit" mat-flat-button class="primary-bg-color">
                            <mat-icon>save</mat-icon>
                            {{'Save' | translate}}
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </form>
</div>