<div
    class="select-field-container"
    class="dynamic-field-input-w fuse-mat-dense align-center"
    fxLayoutAlign="start"
    fxLayoutGap="10px"
    style="align-items: center"
>
    <div
        fxLayout="column"
        class="select-field"
        fxFlex="1 1 auto"
        fxLayout="column"
        [formGroup]="form"
    >
        <label *ngIf="showLabel">
            {{ title }} <span class="text-red-600">*</span></label
        >
        <div class="default-time-example" fxFlex="1 1 auto" fxLayout="column">
            <ng-select
                formControlName="selected_item"
                [placeholder]="title ? title : 'search'"
                fxFlex="100"
                (input)="inputValue($event.target.value, api)"
            >
                <ng-option *ngFor="let item of items" [value]="item?.id">
                    {{ item.name }}
                </ng-option>
            </ng-select>
            <!-- *ngIf="enableAdd" -->
            <div class="cursor-pointer">
                <mat-icon
                    class="cursor-pointer"
                    title="Add"
                    (click)="$event.stopPropagation(); addNewItem()"
                    matSuffix
                    class="icon-size-5"
                    [svgIcon]="'heroicons_solid:plus-circle'"
                >
                </mat-icon>
            </div>
        </div>
    </div>
</div>
