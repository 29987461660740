<div
    fxLayout="column"
    fxFlex="1 1 auto"
    fxLayoutAlign="start center"
    fxLayoutGap="5px"
    class="attachment"
    [ngClass]="!uploadOnly ? 'h-165' : ''"
    appDnd
    (fileDropped)="onFileDropped($event)"
>
    <input
        [accept]="accept"
        class="attach-input"
        type="file"
        id="fileDropRef{{ index }}"
        (change)="fileBrowseHandler($event.target.files)"
    />
    <label
        class="attach-label attach-label-file"
        for="fileDropRef{{ index }}"
        >{{ "Browse for file" | translate }}</label
    >
    <label *ngIf="!uploadOnly">{{ "Or" | translate }}</label>
    <label *ngIf="!uploadOnly" (click)="openGallery()" class="attach-label">{{
        "Browse from media" | translate
    }}</label>
</div>
