<!-- radial bar -->

<!-- linear bar -->
<div matTooltip="{{progress}} {{'%'}}" [matTooltipPosition]="'below'" *ngIf="barProperties.barType == 'linear'"
    [style.height.px]='barProperties.linear.depth' [style.background-color]='barProperties.secondColor'
    class="progress-linear cursor-pointer">
    <div class="bar" *ngIf="progress && progress <= 100 && progress >= 0"
        [ngClass]="{stripped: barProperties.linear.stripped, active: getPercentage()}" [style.width.%]='progress'
        [style.height.px]='barProperties.linear.depth' [style.line-height.px]='barProperties.linear.depth'
        [style.background-color]='barProperties.color'>
    </div>
    <div class="bar" *ngIf="!progress || progress > 100 || progress < 0" [style.width.%]='0'></div>
</div>