import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ViewEncapsulation,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormGroup,
    FormGroupName,
    Validator,
    Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/services/app.service";
import { UtilitiesService } from "app/services/utilitiesService";
import * as moment from "moment";
import { first } from "rxjs/operators";
import { AlertModalComponent } from "../../alert-modal/alert-modal.component";
import { NavMenuService } from "app/services/nav-menu.service";
import { PageService } from "app/services/pageService";
import { DomSanitizer } from "@angular/platform-browser";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

function startWithHttp(
    control: AbstractControl
): { [key: string]: any } | null {
    if (control.value && !control.value.startsWith("http")) {
        return { startWithHttp: true };
    }
    return null;
}

function containHTTP(control: AbstractControl): { [key: string]: any } | null {
    if (control.value && control.value.startsWith("http")) {
        return { containHTTP: true };
    }
    return null;
}
@Component({
    selector: "app-delivery-scheduling",
    templateUrl: "./delivery-scheduling.component.html",
    styleUrls: ["./delivery-scheduling.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class DeliverySchedulingComponent implements OnInit, AfterViewInit {
    @ViewChild("fileDropRef2", { static: false }) fileDropEl2: ElementRef;
    @Input() selectedBlock: any;
    @Input() appDetail: any;
    @Output() back: EventEmitter<void> = new EventEmitter<any>();
    @Output() saveHander: EventEmitter<void> = new EventEmitter<any>();
    schedule_days: any;
    holidayDateArray = [];
    delivery_sla = [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
        { id: 7 },
        { id: 8 },
    ];
    sc_slot_duration = [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
    ];
    deliveryForm: FormGroup;

    expandIndex = [];
    countryRuleList = [];
    loading: boolean = true;
    form: FormGroup;
    formdays: FormGroup;
    schedule_daysData: any;
    country = [];
    elementsArray = [];
    typeEnum = [
        { id: "COUNTRY", name: "Default" },
        // { id: "PAGE", name: "Page" },
        // { id: "CUSTOM", name: "Custom" },
        { id: "EXTERNAL", name: "External" },
    ];
    // pageList = [];
    isSumbit: boolean = false;
    countryDropDown = [];

    countryModeSettings = {};
    files = [];
    id: any;
    daysSelected: any[] = [];
    event: any;
    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private appService: AppService,
        public utilitiesService: UtilitiesService,
        private translate: TranslateService,
        private navMenuService: NavMenuService,
        private pageService: PageService,
        public sanitizer: DomSanitizer,

        private ngZone: NgZone,
        private cdr: ChangeDetectorRef
    ) {}
    getDeliveryArray() {
        return this.form.get("country_rules") as FormArray;
    }
    ngAfterViewInit(): void {
        document.getElementById("toolbar").scrollIntoView();
    }
    async ngOnInit(): Promise<void> {
        this.id = localStorage.getItem("currentAppID");
        // this.utilitiesService.startLoader();
        await this.formInit();
        await this.getCountryRules();
        await this.getScheduleDays();
        await this.getCountries();
    }
    // isSelected = (event: any) => {
    //     if (event) {
    //         let deliveryArray = this.getDeliveryArray();
    //         console.log(deliveryArray);
    //         let c_event = new Date(event);
    //         console.log("isSelected event");

    //         const date =
    //             c_event.getFullYear() +
    //             "-" +
    //             ("00" + (c_event.getMonth() + 1)).slice(-2) +
    //             "-" +
    //             ("00" + c_event.getDate()).slice(-2);
    //         if (deliveryArray.length) {
    //             deliveryArray?.controls?.forEach((slot) => {
    //                 // deliveryArray?.controls[0]
    //                 let formSlot = slot as FormGroup;
    //                 let holidays = formSlot.controls["holidays"].value;
    //                 console.log(c_event);
    //                 console.log(holidays);
    //                 if (holidays) {
    //                     console.log(holidays.find((x) => x == date));
    //                     return holidays.find((x) => x == date)
    //                         ? "selected"
    //                         : null;
    //                 }
    //             });
    //         }
    //     }
    //     // const date =
    //     //   event.getFullYear() +
    //     //   "-" +
    //     //   ("00" + (event.getMonth() + 1)).slice(-2) +
    //     //   "-" +
    //     //   ("00" + event.getDate()).slice(-2);
    //     // return this.daysSelected.find(x => x == date) ? "selected" : null;
    // };
    // isSelected = (event) => {
    //     if (event) {
    //         // console.log(array);
    //         // console.log("event");
    //         // console.log(event);
    //         let c_event = new Date(event);
    //         // console.log("isSelected event");
    //         // console.log(c_event);
    //         // console.log(i);
    //         const date =
    //             c_event.getFullYear() +
    //             "-" +
    //             ("00" + (c_event.getMonth() + 1)).slice(-2) +
    //             "-" +
    //             ("00" + c_event.getDate()).slice(-2);
    //         // console.log("date");
    //         // console.log(date);

    //         array.find((x) => {
    //             console.log("x");
    //             console.log(x);
    //             console.log("date");
    //             console.log(date);
    //             console.log(x == date);
    //         });
    //         return array.find((x) => x == date) ? "selected" : null;
    //     }
    // };

    // select(event: any, calendar: any) {
    //     let c_event = new Date(event);
    //     const date =
    //     c_event.getFullYear() +
    //         "-" +
    //         ("00" + (c_event.getMonth() + 1)).slice(-2) +
    //         "-" +
    //         ("00" + c_event.getDate()).slice(-2);
    //     const index = this.daysSelected.findIndex((x) => x == date);
    //     if (index < 0) this.daysSelected.push(date);
    //     else this.daysSelected.splice(index, 1);

    //     calendar.updateTodaysDate();
    // }
    async getScheduleDays() {
        const res = await this.appService.getAppDetail(this.id).toPromise();
        console.log("res");
        console.log(res);
        this.schedule_daysData = res.schedule_days;
        await this.formDays(this.countryRuleList[0]);
    }
    select(event: any, calendar: any) {
        if (event) {
            let controlholiday = this.formdays?.controls["holidays"];

            if (controlholiday) {
                let c_event = new Date(event);
                const date =
                    c_event.getFullYear() +
                    "-" +
                    ("00" + (c_event.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("00" + c_event.getDate()).slice(-2);
                let arrayholiday = controlholiday?.value || [];

                if (arrayholiday) {
                    const index = arrayholiday.findIndex((x) => x == date);

                    if (index < 0) {
                        arrayholiday.push(date);
                    } else arrayholiday.splice(index, 1);
                } else {
                    arrayholiday.push(date);
                }
                calendar.updateTodaysDate();
                controlholiday.setValue(arrayholiday);
            }
        }
    }

    isSelected = (event: any) => {
        if (event && this.formdays) {
            let holidayValue = this.formdays?.controls["holidays"]?.value || [];

            if (holidayValue) {
                let c_event = new Date(event);
                const date =
                    c_event.getFullYear() +
                    "-" +
                    ("00" + (c_event.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("00" + c_event.getDate()).slice(-2);
                return holidayValue.find((x) => x == date) ? "selected" : null;
            }
        }
    };
    goBack() {
        this.back.emit();
    }

    async setValue() {
        try {
            let count = 0;
            if (this.countryRuleList.length > 0) {
                this.utilitiesService.startLoader();
                (this.form.get("country_rules") as FormArray).clear();
                this.countryRuleList.forEach((data, index) => {
                    count = count + 1;
                    this.addCustomCountry(data, index);
                    this.utilitiesService.stopLoader();
                });

                if (this.countryRuleList.length == count) {
                    this.utilitiesService.stopLoader();
                }
            } else {
                (this.form.get("country_rules") as FormArray).clear();
            }
            this.utilitiesService.stopLoader();
        } catch {
            this.utilitiesService.stopLoader();
        }
    }

    async formInit() {
        this.form = this.fb.group({
            country_rules: this.fb.array([this.addCustomCountries()]),
        });
    }
    async formDays(countryRuleList?) {
        console.log("countryRuleList");
        console.log(countryRuleList);
        this.formdays = this.fb.group({
            holidays: [countryRuleList ? countryRuleList.holidays : []],
            schedule_days: [
                this.schedule_daysData ? this.schedule_daysData : null,
                [Validators.required],
            ],
        });
    }

    addCustomCountries(countryData?) {
        return this.fb.group({
            id: [countryData ? countryData.id : null],
            start_time: [
                countryData ? countryData.start_time_value : null,
                [Validators.required],
            ],
            cutoff_time: [
                countryData ? countryData.cutoff_time_value : null,
                [Validators.required],
            ],
            end_time: [
                countryData ? countryData.end_time_value : null,
                [Validators.required],
            ],
            reference: [
                countryData ? countryData.reference : null,
                [Validators.required],
            ],
            area: [countryData ? countryData.area : []],
            slot_timing: [countryData ? countryData.slot_timing : []],
            holidays: [countryData ? countryData.holidays : []],
            enabled: [
                countryData ? countryData.enabled : true,
                [Validators.required],
            ],
        });
    }

    async addCustomCountry(countryData?, index?) {
        this.isSumbit = false;
        (this.form.get("country_rules") as FormArray).push(
            this.addCustomCountries(countryData)
        );
        this.elementsArray.splice(index, 0, countryData);
        if (countryData) {
            this.formdays
                .get("holidays")
                .setValue(this.countryRuleList[0]?.holidays);
        }

        this.utilitiesService.stopLoader();
    }
    async setCountryValue(countryData, index) {
        let countryIDs = [];
        if (countryData.countries && countryData.countries.length > 0) {
            countryData.countries.forEach((data) => {
                countryIDs.push({
                    id: data.id,
                    itemName: data.name,
                });
            });
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("countries")
                .setValue(countryIDs);
        }
    }
    async getCountries() {
        try {
            const country = await await this.appService.getArea().toPromise();
            if (country) {
                this.country = country;
                // console.log(this.country);
            }
        } catch {
            this.utilitiesService.stopLoader();
        }
    }
    async getCountryRules() {
        try {
            var options = {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            };
            this.utilitiesService.startLoader();
            const countryRuleList = await this.appService
                .getDeliverySlot()
                .toPromise();

            if (countryRuleList) {
                this.countryRuleList = countryRuleList.results;
                setTimeout(() => {
                    document.getElementById("toolbar").scrollIntoView();
                }, 100);
                this.utilitiesService.stopLoader();
                await this.setValue();
            }
            this.utilitiesService.stopLoader();
        } catch {
            this.utilitiesService.stopLoader();
        } finally {
        }
    }
    delete(index, id) {
        let content = `Are you sure, Do you want to delete this schedule time ? `;
        let heading = "Delete";
        let fromApp = false;
        let size = this.utilitiesService.isMobileAlertModal();
        const dialogRef = this.dialog.open(AlertModalComponent, {
            data: { content, heading, fromApp },
            maxWidth: "",
            width: `${size.width}`,
            height: `${size.height}`,
        });
        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                if (id) {
                    this.utilitiesService.startLoader();
                    this.appService.deleteDeliverySlot(id).subscribe((data) => {
                        this.utilitiesService.startLoader();
                        this.getCountryRules();
                    });
                } else {
                    (this.form.get("country_rules") as FormArray).removeAt(
                        index
                    );
                    document.getElementById("toolbar").scrollIntoView();
                }
                this.utilitiesService.stopLoader();
            }
        });
    }

    checkInvalid(index) {
        if (
            (this.form.get("country_rules") as FormArray).length > 0 &&
            this.isSumbit
        ) {
            return (this.form.get("country_rules") as FormArray).at(index)
                .valid;
        } else {
            return true;
        }
    }

    showTabbyChange(index, value) {
        if (!value) {
            (this.form.get("country_rules") as FormArray).controls[index]
                .get("enabled")
                .setValue(false);
        }
    }

    setFileToForm(file, index) {
        ((this.form.get("country_rules") as FormArray).at(index) as FormGroup)
            .get("icon_image")
            .patchValue(file.file);
    }

    async drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                this.elementsArray,
                event.previousIndex,
                event.currentIndex
            );
            if (event.previousIndex > event.currentIndex) {
                const formGroup = (
                    this.form.get("country_rules") as FormArray
                ).at(event.previousIndex);
                (this.form.get("country_rules") as FormArray).removeAt(
                    event.previousIndex
                );
                (this.form.get("country_rules") as FormArray).insert(
                    event.currentIndex,
                    formGroup
                );
            }
            if (event.previousIndex < event.currentIndex) {
                const formGroup = (
                    this.form.get("country_rules") as FormArray
                ).at(event.currentIndex);
                (this.form.get("country_rules") as FormArray).removeAt(
                    event.currentIndex
                );
                (this.form.get("country_rules") as FormArray).insert(
                    event.previousIndex,
                    formGroup
                );
            }
        } else {
            await this.addCustomCountry(event.item.data, event.currentIndex);
        }
    }
    async checkFormIsValidOrNot() {
        this.isSumbit = true;
        let formValid = false;
        let invlidData = [];

        if (
            (this.form.get("country_rules") as FormArray) &&
            (this.form.get("country_rules") as FormArray).length > 0
        ) {
            (this.form.get("country_rules") as FormArray).controls.forEach(
                (data, index) => {
                    // console.log(data);
                    // console.log(index);

                    if (data.invalid) {
                        invlidData.push(index);
                    }
                }
            );
            if (invlidData.length > 0) {
                formValid = false;
            } else {
                formValid = true;
            }
            invlidData.forEach((data) => {
                setTimeout(() => {
                    (this.form.get("country_rules") as FormArray)
                        .at(data)
                        .get("enabled")
                        .markAsTouched();

                    (this.form.get("country_rules") as FormArray)
                        .at(data)
                        .get("start_time")
                        .markAsTouched();
                    (this.form.get("country_rules") as FormArray)
                        .at(data)
                        .get("end_time")
                        .markAsTouched();
                    (this.form.get("holidays") as FormArray)
                        .at(data)
                        .get("holidays")
                        .markAsTouched();
                    // (this.form.get("country_rules") as FormArray)
                    //     .at(data)
                    //     .get("area")
                    //     .markAsTouched();
                    this.expandIndex[data] = data;
                }, 0);
            });
        } else {
            formValid = true;
        }
        return formValid;
    }
    // async save() {
    //     let countryRuleForm = this.form.value;
    //     let formValid = await this.checkFormIsValidOrNot();
    //     console.log(this.checkFormIsValidOrNot());
    //     if (this.form.valid && formValid) {
    //         try {
    //             let rules = [];
    //             if (countryRuleForm.country_rules.length > 0) {
    //                 rules = countryRuleForm.country_rules.map((data, index) => {
    //                     return { ...data };
    //                 });
    //             }
    //             let content = `Are you sure, Do you want to update? `;
    //             let heading = "Update";
    //             let fromApp = false;
    //             let size = this.utilitiesService.isMobileAlertModal();
    //             const dialogRef = this.dialog.open(AlertModalComponent, {
    //                 data: { content, heading, fromApp },
    //                 maxWidth: "",
    //                 width: `${size.width}`,
    //                 height: `${size.height}`,
    //             });
    //             dialogRef.afterClosed().subscribe(async (resp) => {
    //                 this.utilitiesService.autoSave.next(0);

    //                 if (resp) {
    //                     const respsonse = await this.appService
    //                         .addDeliverySlot(rules)
    //                         .toPromise();
    //                     if (respsonse) {
    //                         console.log(respsonse);
    //                         rules = [];
    //                         this.getCountryRules();
    //                         this.utilitiesService.stopLoader();
    //                         this.utilitiesService.showSuccessToast(
    //                             "successMsg"
    //                         );

    //                         this.saveHander.emit();
    //                     }
    //                 }
    //             });
    //         } catch {
    //             this.utilitiesService.stopLoader();
    //         }
    //     }
    // }
    async save() {
        let deliverySlots = this.getDeliveryArray();
        // this.form.get("country_rules")
        // console.log("deliverySlots");
        // console.log(deliverySlots);
        let holiday = this.formdays.controls["holidays"].value;
        // console.log("holiday");
        // console.log(holiday);
        deliverySlots?.controls?.forEach((slot) => {
            let formSlot = slot as FormGroup;
            formSlot.controls["holidays"].setValue(holiday);
            // console.log("formSlot");
            // console.log(formSlot);
        });
        console.log("this.form.value");
        console.log(this.form.value);

        let countryRuleForm = this.form.value;
        let formValid = await this.checkFormIsValidOrNot();
        let scheduleDays = this.formdays.value;
        // console.log(formValid);
        if (formValid || scheduleDays) {
            let rules = [];

            if (countryRuleForm.country_rules.length > 0) {
                rules = countryRuleForm.country_rules.map((data, index) => {
                    return { ...data };
                });
            }
            console.log("rules");
            console.log(rules);
            let content = `Are you sure, Do you want to Save it ? `;
            let heading = "Save";
            let fromApp = false;
            let size = this.utilitiesService.isMobileAlertModal();
            const dialogRef = this.dialog.open(AlertModalComponent, {
                data: { content, heading, fromApp },
                maxWidth: "",
                width: `${size.width}`,
                height: `${size.height}`,
            });
            dialogRef.afterClosed().subscribe(async (resp) => {
                this.utilitiesService.autoSave.next(0);

                if (resp) {
                    console.log(resp.message);
                    this.utilitiesService.startLoader();
                    if (formValid) {
                        const respsonse = await this.appService
                            .addDeliverySlot(rules)
                            .toPromise();

                        if (respsonse) {
                            console.log("addDeliverySlot");

                            console.log(respsonse);
                            rules = [];
                            this.utilitiesService.showSuccessToast(
                                "successMsg addDeliverySlot"
                            );
                        }
                    }
                    if (scheduleDays) {
                        const respsonse1 = this.appService
                            .addAppSettings(scheduleDays)
                            .toPromise();

                        if (respsonse1) {
                            this.utilitiesService.showSuccessToast(
                                "successMsg scheduleDays"
                            );
                        }
                    }
                    this.getCountryRules();
                    this.utilitiesService.stopLoader();
                    this.saveHander.emit();
                }
            });
            this.utilitiesService.stopLoader();
        }
    }
}
