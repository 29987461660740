<div class=" cursor-pointer" fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayout="column">
    <div class="relative">
        <div class="relative">
            <div class="media">
                <img (click)="viewMedia()" *ngIf="type == mediaType.Video" class="w-64" src="assets/images/play.png">
            </div>
            <div class="mediaTitle">{{name}}</div>
        </div>
        <img (click)="startAudio()" *ngIf="type == mediaType.Audio && !isPlayed" class="w-64" src="assets/images/mic.png">
        <img (click)="pauseAudio()" *ngIf="type == mediaType.Audio && isPlayed" class="w-64" src="assets/images/sound.png">
    </div>
</div>