import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { BehaviorSubject } from "rxjs";
import { Breakpoints, BreakpointObserver } from "@angular/cdk/layout";
import { MatDialog } from "@angular/material/dialog";
import { DownloadTypes } from "../downloadTypes";
import { findFlagUrlByCountryName } from "country-flags-svg";
import { PermissionConstants } from "../permissions";
import { NavigationStart, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { MediaTypes } from "app/constants/media-type";
import * as moment from "moment";
import { FormGroup } from '@angular/forms';

@Injectable({
    providedIn: "root",
})
export class UtilitiesService {
    public formChanged$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public selectedImage: BehaviorSubject<any> = new BehaviorSubject("");
    public autoSave: BehaviorSubject<number> = new BehaviorSubject(0);
    constructor(
        private _snackBar: MatSnackBar,
        private ngxService: NgxUiLoaderService,
        private breakpointObserver: BreakpointObserver,
        private dialog: MatDialog,
        private sanitizer: DomSanitizer,
        private _router: Router
    ) { }

    getCurrentType(types: any[], color: string, type: string, format: string) {
        return {
            type:
                types.filter(
                    (data) =>
                        data.toLocaleLowerCase() == type.toLocaleLowerCase()
                )[0] || null,
            color: color,
            format: format,
        };
    }

    formatDate(date) {
        let formattedDate: any = '';
        if (date) {
            let actualDate = new Date(date);
            formattedDate = moment(actualDate).format('YYYY-MM-DD');
        }
        return formattedDate;
    }

    isAudio(type: string) {
        let types = [
            "AIF",
            "CDA",
            "MID",
            "MIDI",
            "MP3",
            "MPA",
            "OGG",
            "WAV",
            "WMA",
            "WPL",
        ];
        return this.getCurrentType(types, "#3663eb", type, MediaTypes.Audio);
    }

    isVideo(type) {
        let types = [
            "WEBM",
            "MPG",
            "MP2",
            "MPEG",
            "MPE",
            "MPV",
            "OGG",
            "MP4",
            "M4P",
            "M4V",
            "AVI",
            "WMV",
            "MOV",
            "QT",
            "FLV",
            "SWF",
            "AVCHD",
        ];
        return this.getCurrentType(types, "#d97721", type, MediaTypes.Video);
    }

    isImage(type) {
        let types = ["JPEG", "JPG", "PNG", "GIF", "SVG"];
        return this.getCurrentType(
            types,
            "rgb(243 192 54)",
            type,
            MediaTypes.Image
        );
    }

    isExcel(type) {
        let types = ["XLS", "XLSX", "XLSB", "MS-EXCEL", "CSV"];
        return this.getCurrentType(types, "#4ea44b", type, MediaTypes.Excel);
    }

    isDoc(type) {
        let types = ["DOC", "DOCX"];
        return this.getCurrentType(types, "#475569", type, MediaTypes.Doc);
    }

    isPDF(type) {
        let types = ["PDF"];
        return this.getCurrentType(types, "#36eb97", type, MediaTypes.PDF);
    }

    isText(type) {
        let types = ["TXT", "PLAIN"];
        return this.getCurrentType(types, "#475569", type, MediaTypes.Text);
    }

    checkMediaFormat(url) {
        let type = url.substring(url.lastIndexOf("."));
        type = type.split(".").join("");
        return type;
    }

    checkFormat(type: string) {
        if (this.isAudio(type).type) {
            return this.isAudio(type);
        } else if (this.isVideo(type).type) {
            return this.isVideo(type);
        } else if (this.isPDF(type).type) {
            return this.isPDF(type);
        } else if (this.isExcel(type).type) {
            return this.isExcel(type);
        } else if (this.isDoc(type).type) {
            return this.isDoc(type);
        } else if (this.isImage(type).type) {
            return this.isImage(type);
        } else if (this.isText(type).type) {
            return this.isText(type);
        } else {
            return {
                type: "Unknown",
                color: "#ef8888",
                format: "unknown",
            };
        }
    }

    public get getDownloadTypes(): typeof DownloadTypes {
        return DownloadTypes;
    }

    public get Permission(): typeof PermissionConstants {
        return PermissionConstants;
    }

    formChangedOrNot(value: boolean) {
        this.formChanged$.next(value);
    }

    showPermissionDeinedMessage() {
        this._snackBar.open(`Permission Denied`, "Error", {
            duration: 5000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ["error"],
        });
    }

    showErrorToast(msg) {
        this._snackBar.open(`${msg}`, "Error", {
            duration: 5000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ["error"],
        });
    }
    showSuccessToast(msg) {
        this._snackBar.open(`${msg}`, "Success", {
            duration: 5000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ["success"],
        });
    }

    showWarningToast(msg) {
        this._snackBar.open(`${msg}`, "Warning", {
            duration: 5000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ["warning"],
        });
    }
    async checkIfObjectIsEmpty(filterObject) {
        const length = Object.keys(filterObject).length;
        let count = 0;
        for (let key in filterObject) {
            if (!filterObject[key]) {
                count = count + 1;
            }
        }
        if (length == count) {
            return true;
        } else {
            return false;
        }
    }
    convertImageToBase64(image) {
        var reader = new FileReader();
        let file = image;
        reader.readAsDataURL(file);
        return reader;
    }
    async setAuthorizationToken(token: string) {
        if (token) {
            localStorage.setItem("access_token", token);
        }
    }
    async getAuthorizationToken() {
        return localStorage.getItem("access_token");
    }

    startLoader() {
        this.ngxService.start();
    }

    stopLoader() {
        this.ngxService.stop();
    }
    isMobile() {
        let isMobileObser = this.breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
        ]);
        let isMobile = false;
        isMobileObser.subscribe((data) => {
            isMobile = data.matches;
        });
        return isMobile;
    }

    isMobileAlertModal() {
        let size = {
            width: "25vw",
            height: "28vh",
        };
        let isMobileObser = this.breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
        ]);
        isMobileObser.subscribe((data) => {
            if (data.matches) {
                size.height = "50%";
                size.width = "80%";
            }
        });
        return size;
    }

    isMobileAddLinkModal(height?: string) {
        let size = {
            width: "50%",
            height: height ? height : "90%"
        };
        let isMobileObser = this.breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
        ]);
        isMobileObser.subscribe((data) => {
            if (data.matches) {
                size.height = "50%";
                size.width = "80%";
            }
        });
        return size;
    }

    checkIfISDCodeExist(contactNumber: string = "") {
        if (contactNumber.startsWith("+")) {
            return true;
        } else {
            return false;
        }
    }

    async getCountryFlag(
        list = [],
        page,
        objectname = "country",
        isCart = false
    ) {
        let flagImage = [];
        if (list.length > 0) {
            if (!isCart) {
                list.forEach((data, index) => {
                    if (data[objectname].name) {
                        let name = data[objectname].name.toLowerCase();
                        let flag = findFlagUrlByCountryName(name);
                        flagImage[page.size * page.pageNumber + index + 1] =
                            flag;
                    }
                });
            } else {
                list.forEach((data, index) => {
                    if (data.customer.contact_no_country.name) {
                        let name =
                            data.customer.contact_no_country.name.toLowerCase();
                        let flag = findFlagUrlByCountryName(name);
                        flagImage[page.size * page.pageNumber + index + 1] =
                            flag;
                    }
                });
            }
        }
        return flagImage;
    }

    bypassTrustedURL(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    checkValidations(formGroup: FormGroup) {
        for (const controlName in formGroup.controls) {
            if (formGroup.controls.hasOwnProperty(controlName)) {
                const control = formGroup.get(controlName);
                if (control.invalid) {
                    console.log(control)
                    const error = control?.errors?.error
                    let msg = error ? error : `${this.transformControlName(controlName)} is required.`
                    this.showErrorToast(msg)
                    break; // Stop the loop as soon as an invalid control is found
                }
            }
        }
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            control.markAsTouched({ onlySelf: true });
        })
    }

    transformControlName(controlName: string): string {
        return controlName
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }
}
