import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslationService } from "app/services/translationService";
import { UtilitiesService } from "app/services/utilitiesService";

@Component({
    selector: "app-icon-image-warning",
    templateUrl: "./icon-image-warning.component.html",
    styleUrls: ["./icon-image-warning.component.scss"],
})
export class IconImageWarningComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<IconImageWarningComponent>,
        private translationService: TranslationService,
        public utilitiesService: UtilitiesService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {}
}
