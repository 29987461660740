import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: "root",
})
export class NavMenuService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    getMenuList(limit = 10, offset = 0, search = "", form?) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        return this.apiService.get(
            this.sharedService.menuList,
            params,
            undefined
        );
    }
    getMenuItemList(limit = 10, offset = 0, search = "") {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        return this.apiService.get(
            this.sharedService.menuItem,
            params,
            undefined
        );
    }
    addMenuItems(form) {
        return this.apiService.post(
            this.sharedService.menuCreate,
            form,
            undefined
        );
    }
    updateMenuItems(form, id) {
        return this.apiService.put(
            this.sharedService.menuUpdate + `${id}/`,
            form,
            undefined
        );
    }
    getMenuDetail(id) {
        return this.apiService.get(
            this.sharedService.menuList + `${id}/`,
            undefined
        );
    }
    deleteNavItems(id) {
        return this.apiService.delete(
            this.sharedService.menuItem + `/${id}/`,
            undefined
        );
    }
    deleteNavMenu(id) {
        return this.apiService.delete(
            this.sharedService.menuList + `${id}/`,
            undefined
        );
    }
    addIndividualMenuItem(form) {
        return this.apiService.post(
            this.sharedService.addNavItems,
            form,
            undefined
        );
    }
    updateIndividualMenuItem(form) {
        return this.apiService.put(
            this.sharedService.addNavItems+`${form.id}/`,
            form,
            undefined
        );
    }
}
