import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { CatalogueService } from 'app/services/catalogue/catalogue.service';
import { productBrand, productCategory, productField } from 'app/main/types/productFields';
import { take, takeUntil } from 'rxjs/operators';
import { UtilitiesService } from 'app/services/utilitiesService';
import { Subject } from 'rxjs';
import { DynamicFieldTypes } from 'app/main/types/dynamicField';
import { PageService } from 'app/services/pageService';

@Component({
  selector: 'app-create-brand',
  templateUrl: './create-brand.component.html',
  styleUrls: ['./create-brand.component.scss']
})
export class CreateBrandComponent implements OnInit {

  @Input() id: any = "";
  @Output() cancelClick: EventEmitter<void> = new EventEmitter<any>();
  @Output() createProductBrand: EventEmitter<void> = new EventEmitter<any>();
  form: FormGroup;
  productForm: FormGroup
  isApiCallDone: boolean = false
  imgUrls: any[] = []
  productFields: any = productBrand.sections
  unSubscribe$ = new Subject<void>()
  pageElements: any
  rowData = []

  constructor(
    private fb: FormBuilder,
    private catalogueService: CatalogueService,
    private utilitiesService: UtilitiesService,
    private pageService: PageService
  ) {
  }

  ngOnInit(): void {
    this.formInitialize()
    this.getPageElements()
    if (this.id) {
      this.getBrandById()
    } else {
      this.isApiCallDone = true
    }
  }

  async formInitialize() {
    this.form = this.fb.group({});
    this.productFields.forEach(section => {
      section.fields.forEach(field => {
        this.form.addControl(
          field.name,
          new FormControl({
            value: typeof field.default_value === 'boolean' ?
              field?.default_value :
              (field?.default_value || ''),
            disabled: field?.disable
          },
            field?.required ? Validators.required : null
          ));
        if (field?.type === DynamicFieldTypes.TextEditor) {
          this.form.addControl(`${field?.name}_control`, new FormControl(''))
        }
      })
    })
  }

  setFormValue(brand) {
    for (let key in this.form.getRawValue()) {
      if (brand.hasOwnProperty(key)) {
        this.form.get(key).setValue(brand[key])
      }
    }
  }

  getBrandById() {
    this.utilitiesService.startLoader()
    this.catalogueService.getBrandById(this.id)
      .pipe(
        takeUntil(this.unSubscribe$)
      )
      .subscribe(brand => {
        this.rowData = brand
        this.setFormValue(brand)
        this.isApiCallDone = true
      })
    this.utilitiesService.stopLoader()
  }

  setFileToForm(file, type) {
    console.log(file)
    const control = type === 'image' ? this.form.get('image') : this.form.get('banner')
    const reader = new FileReader();
    reader.readAsDataURL(file.file);
    reader.onload = () => {
      control.setValue(reader.result as string)
    }
  }

  deleteFile(control: FormControl) {
    control.setValue('')
  }

  handleCancel() {
    this.createProductBrand.emit();
  }

  saveOrUpdate() {
    console.log(this.form.value)
    const form = this.form.value
    delete form.product_img

    if (this.form.valid) {
      if (this.id) {
        this.updateBrand(form)
      } else {
        this.saveBrand(form)
      }
    } else {
      this.checkValidations()
    }
  }

  saveBrand(form) {
    this.utilitiesService.startLoader()
    this.catalogueService.saveBrand(form).subscribe(res => {
      if (res) {
        this.utilitiesService.stopLoader()
        let msg = 'Brand saved successfully.'
        this.utilitiesService.showSuccessToast(msg)
        this.createProductBrand.emit();
      }
    })
    this.utilitiesService.stopLoader()
  }

  updateBrand(form) {
    this.utilitiesService.startLoader()
    this.catalogueService.updateBrand(form, this.id).subscribe(res => {
      if (res) {
        this.utilitiesService.stopLoader()
        let msg = 'Brand upated successfully.'
        this.utilitiesService.showSuccessToast(msg)
        this.createProductBrand.emit();
      }
    })
    this.utilitiesService.stopLoader()
  }

  checkValidations() {
    for (const controlName in this.form.controls) {
      if (this.form.controls.hasOwnProperty(controlName)) {
        const control = this.form.get(controlName);
        if (control.invalid) {
          let msg = `${this.transformControlName(controlName)} is required.`
          this.utilitiesService.showErrorToast(msg)
          break; // Stop the loop as soon as an invalid control is found
        }
      }
    }
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control.markAsTouched({ onlySelf: true });
    })
  }

  transformControlName(controlName: string): string {
    return controlName
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next()
    this.unSubscribe$.complete()
  }

  async getPageElements() {
    try {
      const pageElements = await this.pageService
        .getElements("page", '20')
        .toPromise();
      if (pageElements) {
        this.pageElements = pageElements;
      }
    } catch { }
  }
}



