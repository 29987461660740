<div cdkDropList (cdkDropListDropped)="itemDropped($event)" class="example-list" (cdkDropListDropped)="drop($event)"
    class="dropzone p-16" fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="start stretch">
    <mat-accordion class="mb-16" *ngFor="let field of elements; let fieldIndex = index;" cdkDrag>
        <mat-expansion-panel>
            <div *cdkDragPlaceholder class="field-placeholder"></div>
            <mat-expansion-panel-header cdkDragHandle>
                <mat-panel-title>{{ field.name }}</mat-panel-title>
            </mat-expansion-panel-header>

            <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">

                <div fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>{{'Name' | translate}}</mat-label>
                        <input matInput name="title">
                    </mat-form-field>
                </div>
                <!-- <app-element-types (formChange)="formChange($event)" [formGroup]="formGroup" [configuration]="field">
                </app-element-types> -->
            </div>
            <mat-action-row>
                <app-delete-button (deleteButtonClick)="delete(fieldIndex)"></app-delete-button>
            </mat-action-row>
        </mat-expansion-panel>
    </mat-accordion>
</div>