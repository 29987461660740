import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "../api.service";

@Injectable({
    providedIn: "root",
})
export class UserService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
    }

    getUserList(limit = 10, offset = 0, search = "", form?) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString())
            .set("search", search);
        if (form) {
            for (let key of Object.keys(form)) {
                params = params.set(
                    `${key}`,
                    `${form[key].value ? form[key].value : ""}`
                );
            }
        }
        return this.apiService.get(
            this.sharedService.userList,
            params,
            undefined
        );
    }

    deleteUser(id) {
        let params = new HttpParams();
        params = params.set("user_id", id);
        return this.apiService.get(this.sharedService.deleteUser, params);
    }
    addUser(form) {
        return this.apiService.post(
            this.sharedService.userCreate,
            form,
            undefined
        );
    }
    updateUser(form) {
        return this.apiService.post(
            this.sharedService.userUpdate,
            form,
            undefined
        );
    }
    userDeatils(id) {
        return this.apiService.get(
            this.sharedService.userDetail + `?id=${id}`,
            undefined,
            undefined
        );
    }
    changeUserPassword(form) {
        return this.apiService.post(
            this.sharedService.changePassword,
            form,
            undefined
        );
    }
    changeAdminPassword(form) {
        return this.apiService.post(
            this.sharedService.changeAdminPassword,
            form,
            undefined
        );
    }
}
