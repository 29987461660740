import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "../services/sharedService";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ApiService } from "./api.service";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class AppService {
    apiDomain: any;
    constructor(
        private sharedService: SharedService,
        private http: HttpClient,
        private apiService: ApiService
    ) {
        this.apiDomain = this.sharedService.apiDomain;
        this.getBaseCurrency();
    }

    getApp(fromApp = true) {
        return this.apiService.get(
            this.sharedService.appCURDUrl,
            undefined,
            undefined,
            fromApp
        );
    }

    getAppVisits(type, startDate, endDate) {
        let params: HttpParams = new HttpParams();
        params = params
            .set("type", type)
            .set("search_range_start", startDate)
            .set("search_range_end", endDate);
        return this.apiService.get(
            this.sharedService.dashBoardVisits,
            params,
            undefined
        );
    }

    getAppRevenue(type, startDate, endDate) {
        let params: HttpParams = new HttpParams();
        params = params
            .set("type", type)
            .set("search_range_start", startDate)
            .set("search_range_end", endDate);
        return this.apiService.get(
            this.sharedService.dashBoardRevenue,
            params,
            undefined
        );
    }

    getAppCustomers(type, startDate, endDate) {
        let params: HttpParams = new HttpParams();
        params = params
            .set("type", type)
            .set("search_range_start", startDate)
            .set("search_range_end", endDate);
        return this.apiService.get(
            this.sharedService.dashBoardCustomers,
            params,
            undefined
        );
    }

    getAppMisc(type, startDate, endDate) {
        let params: HttpParams = new HttpParams();
        params = params
            .set("type", type)
            .set("search_range_start", startDate)
            .set("search_range_end", endDate);
        return this.apiService.get(
            this.sharedService.dashBoardMisc,
            params,
            undefined
        );
    }

    getFeatures() {
        return this.apiService.get(this.sharedService.getFeatures, undefined);
    }

    getPermission() {
        return this.apiService.get(this.sharedService.getPermission, undefined);
    }

    getDeliveryMode() {
        return this.apiService.get(
            this.sharedService.deliveryMode,
            undefined,
            undefined
        );
    }

    getArea() {
        return this.apiService.get(
            this.sharedService.areas,
            undefined,
            undefined
        );
    }

    getCurrencies() {
        return this.apiService.get(
            this.sharedService.currency,
            undefined,
            undefined
        );
    }

    getBaseCurrency() {
        return this.getApp(false).pipe(
            map((data) => {
                let baseCurrency = data?.results[0]?.base_currency;
                let allCurrencies = data?.results[0]?.selected_currencies;
                let base = allCurrencies.filter((el) => el.id === baseCurrency);
                return { code: base[0].code };
            })
        );
    }

    getPaymentStatus() {
        return this.apiService.get(
            `api/app-payment_methods/`,
            undefined,
            undefined,
            false
        );
    }

    getCountries() {
        return this.apiService.get(
            this.sharedService.countries,
            undefined,
            undefined
        );
    }

    getCountryForManage() {
        return this.apiService.get(
            this.sharedService.manageCountry,
            undefined,
            undefined
        );
    }

    getCountryRules(limit = 99999, offset = 0) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString());
        return this.apiService.get(
            this.sharedService.countryRuleList,
            params,
            undefined
        );
    }

    getAppDetail(id) {
        return this.apiService.get(
            this.sharedService.appDetail + `${id}/`,
            undefined
        );
    }

    getShopifyAppDetails(id) {
        return this.apiService.get(
            this.sharedService.shopifyAppEdit + `${id}/`,
            undefined
        );
    }

    addAppSettings(form) {
        let id = localStorage.getItem("currentAppID");
        return this.apiService.patch(
            this.sharedService.appUpdate + `${id}/`,
            form,
            undefined
        );
    }

    getSocialMedia(limit = 99999, offset = 0) {
        let params = new HttpParams();
        params = params
            .set("limit", limit.toString())
            .set("offset", offset.toString());
        return this.apiService.get(
            this.sharedService.socialMediaListDelete,
            params
        );
    }

    addSocialMedia(form) {
        let socialMedia = form.social_media ? form.social_media : [];
        return this.apiService.post(
            this.sharedService.socialMediaBulk,
            socialMedia,
            undefined
        );
    }

    deleteSocialMedia(id) {
        return this.apiService.delete(
            this.sharedService.socialMediaListDelete + `${id}/`,
            undefined
        );
    }

    createApp(form) {
        return this.apiService.post(
            this.sharedService.appUpdate,
            form,
            undefined
        );
    }

    updateShopify(form, id) {
        return this.apiService.patch(
            this.sharedService.appUpdate + `${id}/`,
            form,
            undefined
        );
    }

    addCustomCountry(form) {
        return this.apiService.post(
            this.sharedService.addCustomCountry,
            form,
            undefined
        );
    }
    addDeliverySlot(form) {
        console.log("form");
        console.log(form);
        return this.apiService.post(
            this.sharedService.addDeliverySlot,
            form,
            undefined
        );
    }

    getDeliverySlot() {
        let params = new HttpParams();

        return this.apiService.get(
            this.sharedService.deliverySlot,

            undefined
        );
    }
    deleteDeliverySlot(id) {
        return this.apiService.delete(
            this.sharedService.deliverySlot + `${id}/`,
            undefined
        );
    }

    updateCustomCountry(form) {
        return this.apiService.put(
            this.sharedService.addCustomCountry + `${form.id}/`,
            form,
            undefined
        );
    }

    deleteCountryRules(id) {
        return this.apiService.delete(
            this.sharedService.countryRuleList + `${id}/`,
            undefined
        );
    }

    getEmailConfig() {
        return this.apiService.get(
            this.sharedService.getEmailConfig,
            undefined
        );
    }

    updateEmailConfig(form) {
        return this.apiService.put(
            this.sharedService.getEmailConfig + `${form.id}/`,
            form,
            undefined
        );
    }

    addEmailConfig(form) {
        return this.apiService.post(
            this.sharedService.getEmailConfig,
            form,
            undefined
        );
    }

    getSMSConfig() {
        return this.apiService.get(this.sharedService.getSMSConfig, undefined);
    }

    updateSMSConfig(form) {
        return this.apiService.put(
            this.sharedService.getSMSConfig + `${form.id}/`,
            form,
            undefined
        );
    }

    addSMSConfig(form) {
        return this.apiService.post(
            this.sharedService.getSMSConfig,
            form,
            undefined
        );
    }

    setDashBoardSettings(data) {
        return this.apiService.post(
            this.sharedService.mainDashBoard,
            data,
            undefined
        );
    }

    getDashBoardSettings() {
        return this.apiService.get(this.sharedService.mainDashBoard, undefined);
    }

    clearDashBoardCache() {
        return this.apiService.get(
            this.sharedService.dashBoardClearCache,
            undefined
        );
    }

    getProductsInsights(params) {
        return this.apiService.get(
            this.sharedService.productInsights,
            params,
            undefined
        );
    }

    manualFbCatalogUploadEn() {
        return this.apiService.post(
            this.sharedService.manualCatalogUploadEn,
            {},
            undefined
        );
    }

    manualFbCatalogUploadAr() {
        return this.apiService.post(
            this.sharedService.manualCatalogUploadAr,
            {},
            undefined
        );
    }

    activateFbScheduleEn() {
        return this.apiService.post(
            this.sharedService.activateFbScheduleEn,
            {},
            undefined
        );
    }

    activateFbScheduleAr() {
        return this.apiService.post(
            this.sharedService.activateFbScheduleAr,
            {},
            undefined
        );
    }

    checkFbScheduleStatus() {
        return this.apiService.get(this.sharedService.checkFbScheduleStatus);
    }

    validateCatalogProducts(path: string) {
        return this.apiService.get(path);
    }

    getCurrency(){
        return localStorage.getItem('base_currency')
    }
}
