import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MediaService } from 'app/services/media.service';
import { UtilitiesService } from 'app/services/utilitiesService';

@Component({
  selector: 'app-add-link',
  templateUrl: './add-link.component.html',
  styleUrls: ['./add-link.component.scss']
})
export class AddLinkComponent {
  openCustomLink = false;
  showButtonDetail = false;
  openfromEdit = false;

  platformName: string;
  platformImg: string;
  platformInfo: string = '';
  linkIconColor: string;
  customLinkImage: string;

  form: FormGroup;

  links = [
    { name: 'facebook', icon: 'bi bi-facebook', iconColor: '#316FF6' },
    { name: 'twitter', icon: 'bi bi-twitter', iconColor: '#1DA1F2' },
    { name: 'linkedIn', icon: 'bi bi-linkedin', iconColor: '#0077B5' },
    { name: 'snapChat', icon: 'bi bi-snapchat', iconColor: '#FFFC00' },
    { name: 'telegram', icon: 'bi bi-telegram', iconColor: '#229ED9' },
    { name: 'whatsapp', icon: 'bi bi-whatsapp', iconColor: '#25D366' },
    { name: 'telephone', icon: 'bi bi-telephone', iconColor: '#25D366' },
    { name: 'website', icon: localStorage.getItem('appLogo') },
    { name: 'tiktok', icon: 'bi bi-tiktok', iconColor: '#ff0050' },
    { name: 'youtube', icon: 'bi bi-youtube', iconColor: '#FF0000' },
    { name: 'instagram', icon: 'bi bi-instagram', iconColor: '#df5f44' }
  ]

  get isTelephone() {
    return this.platformName === 'telephone'
  }

  get isWebSite() {
    return this.platformName === 'website'
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddLinkComponent>,
    public utilitiesService: UtilitiesService,
    private mediaService: MediaService,
    private fb: FormBuilder
  ) {
    if (this.data.showButtonDetail) {
      this.platformName = data.platformName;
      this.platformImg = data.platformImg;
      this.showButtonDetail = true;
      this.openfromEdit = true;
      this.linkIconColor = data.linkIconColor
    }

    this.form = this.fb.group({
      customLinkImage: []
    })
  }

  createCustomLink() {
    this.openCustomLink = true;
    this.dialogRef.updateSize('575px', '400px');
  }

  closeCustomLink() {
    this.openCustomLink = false;
    let size = this.utilitiesService.isMobileAddLinkModal('70%');
    this.dialogRef.updateSize(size.width, size.height);
  }

  addButtonDetail(platformDetails) {
    this.showButtonDetail = true;
    this.platformName = platformDetails.name;
    this.platformImg = platformDetails.icon;
    this.linkIconColor = platformDetails.linkIconColor;
    this.dialogRef.updateSize('300px', '280px');
  }

  closeButtonDetails() {
    if (!this.openfromEdit) {
      this.showButtonDetail = false;
      let size = this.utilitiesService.isMobileAddLinkModal('70%');
      this.dialogRef.updateSize(size.width, size.height);
    } else {
      this.dialogRef.close()
    }
  }

  fileBrowseHandler(files) {
    this.addMedia(files);
  }

  addMedia(files) {
    let form = {
      name: files[0].name || null,
      file: files[0],
      altext: [null],
      type: files[0].type || null,
    };
    try {
      this.utilitiesService.startLoader();
      this.mediaService.getS3UploadURL(form.name).subscribe((data) => {
        let name = data.name;
        this.mediaService
          .uploadToS3(form, data.url)
          .subscribe((res) => {
            this.mediaService
              .updateName(name)
              .subscribe((response) => {
                this.form.setValue({
                  customLinkImage: response.file
                })
                this.utilitiesService.stopLoader();
              });
          });
      });
    } catch {
      this.utilitiesService.stopLoader();
    } finally {
    }
  }

  removeCustomImage() {
    this.form.setValue({
      customLinkImage: ''
    })

  }

  setFileToForm(files) {
    this.form.setValue({
      customLinkImage: files.file
    })
  }

  openOrClose() {
    this.linkIconColor
  }

  setColor() {
    this.linkIconColor
  }

}
