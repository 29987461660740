export enum OrderStatus {
    RECEIVED = 1,
    PAYMENT_PENDING = 2,
    CONFIRMED = 3,
    PACKED = 4,
    PICKING_STARTED = 5,
    SHIPPED = 6,
    DELIVERED = 7,
    CANCELLED = 8,
    RETURNED = 9,
    CALLBACK = 10,
}
