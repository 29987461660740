    <mat-toolbar class="p-0 mat-elevation-z1 primary-bg-color" id="toolbar">
        <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch">
            <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

                <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

                    <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                        (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                        <mat-icon>menu</mat-icon>
                    </button>

                    <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

                    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                        <div class="logo ml-16">
                            <img class="logo-icon" src="assets/images/logos/fuse.svg">
                        </div>
                    </div>
                    <!-- <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div> -->
                </div>
                <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                    <div fxFlex="0 1 auto" class="mr-16" fxLayout="row" fxLayoutAlign="start center">
                        <!-- [routerLink]="['/home']" [routerLinkActive]="['highlight-bg-color-with-border']" -->
                        <div [ngClass]="coreService.isHome.value ? 'highlight-bg-color-with-border' : ''"
                            fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px" class="p-4">
                            <!-- <a > -->
                            <mat-icon class="cursor-pointer " (click)="goToHome()">home</mat-icon>
                            <!-- </a> -->
                        </div>
                    </div>
                    <div fxFlex="0 0 auto" class="cursor-pointer" fxLayout="row" fxLayoutAlign="center center">
                        <span class="font-size-12 mr-16" (click)="changeLanguage()" *ngIf=" language=='ar'"
                            [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}">English</span>
                        <span class="font-size-12  mr-16" (click)="changeLanguage()" *ngIf=" language=='en'"
                            [@animate]="{value:'*',params:{duration:'300ms',y:'50px'}}">عربي</span>
                    </div>
                    <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0px">
                        <div class="fuse-card fuse-card-view-site" (click)="viewSiteFn()" fxLayout="row"
                            fxLayoutAlign="space-between center" fxLayoutGap="0px">
                            <div fxFlex="0 0 auto">
                                <mat-icon class="view-icon" matPostfix>remove_red_eye</mat-icon>
                            </div>
                            <div fxFlex="0 0 auto">
                                <span style="font-size: 15px !important;">{{'View Site' | translate}}</span>
                            </div>
                        </div>
                    </div>
                    <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <!-- <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/Velazquez.jpg"> -->
                            <i class="fas fa-user-circle"></i>
                            <!-- <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon> -->
                        </div>
                    </button>

                    <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                        <!-- <button mat-menu-item>
                            <mat-icon>account_circle</mat-icon>
                            <span>My Profile</span>
                        </button> -->
                        <!-- <button  *ngIf="coreService.checkPermission(utilitiesService.Permission.ChangePassowrd)" mat-menu-item class="" (click)="changePassword()">
                            <mat-icon>vpn_key</mat-icon>
                            <span>{{'Change Password' | translate}}</span>
                        </button>
                        <div class="toolbar-separator"></div> -->

                        <button mat-menu-item class="" (click)="logout()">
                            <mat-icon>exit_to_app</mat-icon>
                            <span>{{'Logout' | translate}}</span>
                        </button>

                    </mat-menu>

                    <!-- <div class="toolbar-separator"></div> -->

                    <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->

                    <!-- <div class="toolbar-separator"></div> -->

                    <!-- <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button> -->

                    <!-- <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu> -->

                </div>

            </div>
        </div>
    </mat-toolbar>