import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "orderstatus",
})
export class OrderStatusPipe implements PipeTransform {
    transform(val: number): unknown {
        let status = [
            "RECEIVED ",
            "PAYMENT_PENDING",
            "CONFIRMED",
            "PACKED",
            "PICKING_STARTED",
            "SHIPPED",
            "DELIVERED",
            "CANCELLED",
            "RETURNED",
            "CALLBACK",
        ];

        return status[val - 1];
    }
}
